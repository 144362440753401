import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import FloorPlanPreview from 'styles/images/widgets/floor-plan-widget-preview.png';

import FloorPlanWidget from './FloorPlanWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('Floor Plan', { ns: 'dashboard' }),
    type: WIDGET_TYPE.FLOOR_PLAN,
    featureFlags: [],
    Component: FloorPlanWidget,
    image: FloorPlanPreview,
    order: 6,
    gridConfig: {
        ...DEFAULT_GRID_ITEM_CONFIG,
        h: 2,
        w: 2,
    },
};

export default FloorPlanWidget;
