import { hasValue, isArray } from '../is';
import { BaseValidationError } from './BaseValidationError';

export class ParentValidationError extends BaseValidationError {
    constructor(...args) {
        super(...args);
        // If null, there are no nonFieldErrors
        //
        // Some parent validation errors cannot have nonFieldErrors (e.g. ListValidationError,
        //  since a list cannot have attributes in JSON). Helps keep things DRY, since the result
        //  of getError & firstError won't change if nonFieldErrors is always null.
        this.nonFieldErrors = null;
    }

    getError(fieldName, allowNonFields = false) {
        if (isArray(fieldName)) {
            let error = null;

            // Might want to add check to avoid empty errors
            fieldName.forEach((field, idx) => {
                if (!error && idx === 0) {
                    error = this.errors[field];
                } else if (error) {
                    error = error.errors[field] || null;
                }
            });

            return error;
        }

        if (
            (hasValue(fieldName) && this.errors[fieldName]) ||
            (allowNonFields && this.nonFieldErrors)
        ) {
            return (
                (hasValue(fieldName) ? this.errors[fieldName] : null) ||
                this.nonFieldErrors ||
                null
            );
        }

        return null;
    }

    firstError(allowNonField = false) {
        if (allowNonField && this.nonFieldErrors) {
            return this.nonFieldErrors;
        }

        return this.errorByIndex(0) || null;
    }
}
