import PropTypes from 'prop-types';
import { memo } from 'react';

import { TreePickerSelectFieldRaw } from 'forms/fields/TreePickerSelectField';

const defaultAttributes = {
    collectErrors: true,
    fieldNameFolders: 'folders',
    fieldNameSensors: 'sensors',
};

const Sensors = (props) => {
    const mergedAttributes = {
        ...defaultAttributes,
        ...props,
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <TreePickerSelectFieldRaw {...props} {...mergedAttributes} />;
};

Sensors.propTypes = {
    attributes: PropTypes.shape({}),
};

Sensors.defaultProps = {
    attributes: {},
};

export default memo(Sensors);
