import { makeStyles } from '@material-ui/core';

export const useInstallFlowStyles = makeStyles((theme) => ({
    container: {
        minWidth: 540,
        minHeight: 320,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            minWidth: 'auto',
            minHeight: 'auto',
            maxWidth: 'none',
            maxHeight: 'none',
            margin: 0,
        },
    },
    narrowContainer: { width: 600 },
}));
