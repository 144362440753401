import { makeStyles } from '@material-ui/core';

interface ContainerStylesProps {
    isManager: boolean;
}

export const useStylesPermissionsWrapper = makeStyles(() => ({
    container: ({ isManager }: ContainerStylesProps) => ({
        pointerEvents: !isManager ? 'none' : 'auto',
        // some possible styles like opacity ...
    }),
}));
