import { Icon, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { useStylesFloor, useStylesTooltip } from './styles';

interface Props {
    hubsCount: number;
    floorId: number;
    id: string;
    index: number;
    isActive: boolean;
    isBuildingEditable: boolean;
    name: string;
    onFloorClick: ({ id }: { id: number }) => void;
    sensorsCount: number;
}

const CollapsedFloor = ({
    hubsCount,
    floorId,
    id,
    index,
    isActive,
    isBuildingEditable,
    name,
    onFloorClick,
    sensorsCount,
}: Props) => {
    const styles = useStylesFloor();
    const tooltipStyles = useStylesTooltip();

    const { t } = useTranslation('floorplan');

    const selectFloor = useCallback(() => {
        if (!isActive) {
            onFloorClick({
                id: floorId,
            });
        }
    }, [floorId, onFloorClick, isActive]);

    return (
        <Draggable
            key={id}
            draggableId={id}
            index={index}
            isDragDisabled={!isBuildingEditable}
        >
            {(provided) => (
                <Tooltip
                    classes={tooltipStyles}
                    title={
                        <>
                            <Typography variant="body2" component="div">
                                {name}
                            </Typography>
                            <Typography variant="body2" component="div">
                                {t('{{count}} sensors', {
                                    count: sensorsCount,
                                    defaultValue___one: `${sensorsCount} sensor`,
                                    defaultValue___other: `${sensorsCount} sensors`,
                                    ns: 'common',
                                })}
                            </Typography>

                            <Typography variant="body2" component="div">
                                {t('{{count}} hubs', {
                                    count: hubsCount,
                                    defaultValue___one: '{{count}} hub',
                                    defaultValue___other: '{{count}} hubs',
                                })}
                            </Typography>
                        </>
                    }
                    placement="right"
                >
                    <div
                        className={classNames(styles.floor, {
                            [styles.activeFloor]: isActive,
                        })}
                        onClick={selectFloor}
                        onKeyDown={selectFloor}
                        ref={provided.innerRef}
                        role="button"
                        tabIndex={0}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.draggableProps}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.dragHandleProps}
                        data-cypress="collapsed-floor"
                    >
                        <Icon
                            className={classNames(
                                'fal fa-square',
                                styles.floorSquareIcon,
                            )}
                            data-cypress="collapsed-floor-icon"
                        />
                        <Typography
                            variant="caption"
                            component="div"
                            className={styles.floorName}
                            data-cypress="collapsed-floor-name"
                        >
                            {name}
                        </Typography>
                    </div>
                </Tooltip>
            )}
        </Draggable>
    );
};

export default memo(CollapsedFloor);
