import type { Floor, FloorDetail } from '@infogrid/locations-types';

export const convertFloorDetailToFloor = (floorDetail: FloorDetail): Floor => {
    const {
        id,
        name,
        order_index,
        sensors_count,
        spaces_count,
        user_actions,
        occupant_capacity,
    } = floorDetail;

    return {
        id,
        name,
        order_index,
        sensors_count,
        spaces_count,
        user_actions,
        occupant_capacity,
    };
};
