import { makeStyles } from '@material-ui/core';

import { SCROLLBAR_WIDTH } from 'components/material-ui/Table/constants';

export const useStylesSensorList = makeStyles((theme) => ({
    autoSizerContainer: {
        display: 'flex',
        flex: 1,
    },
    scrollbar: {
        scrollbarColor: `${theme.palette.common.gray4} transparent`,
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: SCROLLBAR_WIDTH,
            height: SCROLLBAR_WIDTH,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.common.gray4,
            borderRadius: theme.shape.borderRadius,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent',
        },
    },
    sensorsList: {
        marginBottom: 'auto',
        padding: 0,
        overflowY: 'auto',
    },
    progressWrapper: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
    },
}));
