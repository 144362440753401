import { useContext, useEffect, memo } from 'react';

import Context from './MapContext';
import type { MapContext } from './MapContext';

interface Props {
    onDrop: (event: DragEvent, coordinate: number[]) => void;
}

const DropInteraction = ({ onDrop }: Props) => {
    const { map } = useContext<MapContext>(Context);

    useEffect(() => {
        if (!map) {
            return () => {};
        }

        const viewPort = map.getViewport();

        const dragover = (event: DragEvent) => {
            event.preventDefault();
        };

        viewPort.addEventListener('dragover', dragover);

        const drop = (e: DragEvent) => {
            e.preventDefault();

            onDrop(e, map.getEventCoordinate(e));
        };

        viewPort.addEventListener('drop', drop);

        return () => {
            viewPort.removeEventListener('dragover', dragover);
            viewPort.removeEventListener('drop', drop);
        };
    }, [map, onDrop]);

    return null;
};

export default memo(DropInteraction);
