import { BackLink, Tooltip } from '@infogrid/components-material-ui';
import { Divider, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import type {
    IntegrationsMenuItem,
    IntegrationsMenuItemGroup,
} from 'views/integrations/pages/integrations/types';

import { useDesktopIntegrationsSidebarStyles } from './styles';

interface DesktopIntegrationsSidebarProps {
    menuitems: IntegrationsMenuItemGroup[];
}

const DesktopIntegrationsSidebar = ({
    menuitems,
}: DesktopIntegrationsSidebarProps): JSX.Element | null => {
    const styles = useDesktopIntegrationsSidebarStyles();
    const { t } = useTranslation('integrations');

    return (
        <Paper elevation={1} square>
            <BackLink
                to="/settings"
                data-cypress="solution-setup-settings-back-button"
                className={styles.backButton}
            >
                {t('Settings')}
            </BackLink>
            <Divider className={styles.dividerLine} />
            <List>
                {menuitems.map((value: IntegrationsMenuItemGroup) => (
                    <Fragment key={value[0]}>
                        <ListItem>
                            <ListItemText
                                primary={value[0]}
                                classes={{ root: styles.heading }}
                                disableTypography
                            />
                        </ListItem>
                        {value[1].map(({ name, url }: IntegrationsMenuItem) => (
                            <ListItem className={styles.noMargins} key={name}>
                                <Tooltip title={name} placement="right">
                                    <NavLink
                                        to={url}
                                        className={styles.integrationItem}
                                        activeClassName={styles.integrationItemActive}
                                    >
                                        <ListItemText className={styles.text}>
                                            {name}
                                        </ListItemText>
                                    </NavLink>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </Fragment>
                ))}
            </List>
        </Paper>
    );
};

export default memo(DesktopIntegrationsSidebar);
