import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { BuildingType } from '@infogrid/locations-types';
import type { UseQueryResult, UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getBuildingTypesKey } from '../getQueryKeys';

export const useBuildingTypes = (
    options: UseQueryOptions<BuildingType[], AxiosParsedError, BuildingType[]> = {},
): UseQueryResult<BuildingType[], AxiosParsedError> =>
    useQuery(
        getBuildingTypesKey(),
        () => wrapResponsePromise<BuildingType[]>(controllers.getBuildingTypes()),
        {
            ...options,
        },
    );
