import { FormErrorMessage, Modal } from '@infogrid/components-material-ui';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import type { FormikProps } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AddressAutocomplete from '../CreateBuildingModal/AddressAutocomplete/AddressAutocomplete';
import { useEditBuildingStyles } from './styles';
import type { EditBuildingModalValues } from './types';

interface Props
    extends Pick<
        FormikProps<EditBuildingModalValues>,
        | 'isSubmitting'
        | 'handleChange'
        | 'handleSubmit'
        | 'errors'
        | 'touched'
        | 'handleReset'
    > {
    onClose: () => void;
    isOpened: boolean;
    handleDelete: () => void;
    handleLocation: () => void;
    buildingName: string;
    buildingAddress: string;
    buildingCountry?: string | null;
    buildingLongitude?: number | null;
    buildingLatitude?: number | null;
}

const EditBuildingModal = ({
    onClose,
    isOpened,
    isSubmitting,
    buildingName,
    buildingAddress,
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleDelete,
    handleLocation,
    buildingCountry = null,
    buildingLongitude = null,
    buildingLatitude = null,
    handleReset,
}: Props) => {
    const stylesEditBuilding = useEditBuildingStyles();

    const { t } = useTranslation('floorplan');

    const renderAddressInput = useCallback(
        (params) => (
            <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                id="address"
                name="address"
                data-cypress="address"
                fullWidth
                variant="outlined"
                label={t('Address')}
                placeholder={t('Address')}
                disabled={isSubmitting}
                error={errors.address && touched.address}
                value={buildingAddress}
                onChange={handleChange}
            />
        ),
        [isSubmitting, errors.address, touched.address, buildingAddress, handleChange, t],
    );

    const classes = useMemo(
        () => ({
            container: stylesEditBuilding.container,
        }),
        [stylesEditBuilding.container],
    );

    const onExited = useCallback(() => {
        handleReset();
    }, [handleReset]);

    return (
        <Modal open={isOpened} onClose={onClose} onExited={onExited} classes={classes}>
            <form onSubmit={handleSubmit}>
                <Modal.Title>{t('Editing Building')}</Modal.Title>
                <Modal.Description>
                    {t('Update this building details:')}
                </Modal.Description>

                <Modal.Content>
                    <Modal.ContentItem>
                        <TextField
                            fullWidth
                            id="name"
                            name="name"
                            variant="outlined"
                            data-cypress="name"
                            label={t('Building name')}
                            placeholder={t('Building name')}
                            disabled={isSubmitting}
                            value={buildingName}
                            onChange={handleChange}
                            error={Boolean(errors.name && touched.name)}
                        />
                        <FormErrorMessage show={Boolean(errors.name && touched.name)}>
                            {errors.name}
                        </FormErrorMessage>
                    </Modal.ContentItem>

                    <Modal.ContentItem>
                        <AddressAutocomplete
                            address={buildingAddress}
                            buildingCountry={buildingCountry}
                            buildingLongitude={buildingLongitude}
                            buildingLatitude={buildingLatitude}
                            handleLocation={handleLocation}
                            renderInput={renderAddressInput}
                        />

                        <FormErrorMessage
                            show={Boolean(errors.address && touched.address)}
                        >
                            {errors.address}
                        </FormErrorMessage>
                    </Modal.ContentItem>
                </Modal.Content>

                <Modal.Actions justify="space-between">
                    <Button
                        onClick={handleDelete}
                        color="secondary"
                        data-cypress="delete-building"
                    >
                        <span>{t('delete building')}</span>
                    </Button>

                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        data-cypress="save"
                    >
                        {isSubmitting && (
                            <CircularProgress
                                className={stylesEditBuilding.spinner}
                                size={22}
                            />
                        )}
                        <span> {t('Save', { ns: 'common' })}</span>
                    </Button>
                </Modal.Actions>
            </form>
        </Modal>
    );
};

export default memo(EditBuildingModal);
