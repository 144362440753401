import { makeStyles } from '@material-ui/core';

export const useStylesFloorPlan = makeStyles((theme) => ({
    floorPlanContainer: {
        display: 'flex',
        flex: '1',
    },

    uploadCardContainer: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },

    cardContainer: {
        width: '400px',
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
    },
}));
