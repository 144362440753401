import type { LocationFilter } from '@infogrid/core-types';
import { LOCATION_FILTERS } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';

const getSpaceLabel = (
    t: TFunction<'dashboard'>,
    isMultipleLocations: boolean,
    isMultipleSensors: boolean,
) => {
    if (!isMultipleLocations) {
        if (!isMultipleSensors) {
            return t('Selected space');
        }

        return t('Average of selected space');
    }

    return t('Average of selected spaces');
};

const getFloorLabel = (t: TFunction<'dashboard'>, isMultipleLocations: boolean) => {
    if (isMultipleLocations) {
        return t('Average of selected floors');
    }

    return t('Average of selected floor');
};

const getBuildingLabel = (t: TFunction<'dashboard'>, isMultipleLocations: boolean) => {
    if (isMultipleLocations) {
        return t('Average of selected buildings');
    }

    return t('Average of selected building');
};

export const useLocationMetricLabel = (
    locationType: LocationFilter,
    isMultipleLocations: boolean,
    isMultipleSensors: boolean,
): string => {
    const { t } = useTranslation('dashboard');

    switch (locationType) {
        case LOCATION_FILTERS.SPACE:
            return getSpaceLabel(t, isMultipleLocations, isMultipleSensors);
        case LOCATION_FILTERS.FLOOR:
            return getFloorLabel(t, isMultipleLocations);
        default:
            return getBuildingLabel(t, isMultipleLocations);
    }
};
