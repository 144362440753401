import { isValidElectricityEventType } from '@infogrid/sensors-configuration';
import { SENSOR_EVENT_TYPES_V2, SENSOR_EVENT_V2_KEY } from '@infogrid/sensors-constants';
import type { SensorEventV2Shape } from '@infogrid/sensors-constants';
import round from 'lodash/round';

import type {
    AverageHourlyElectricityReadingsParams,
    AverageHourlyElectricityReadingsInHoursParams,
} from '../types';
import {
    getEventsInNonWorkingHours,
    getEventsInWorkingHours,
    sumEventsReadings,
} from '../utils';

const DEFAULT_INTERVAL_PER_HOUR = 4;

const getReadingsPerHour = (intervalInMinutes: number) => {
    if (!intervalInMinutes) {
        return DEFAULT_INTERVAL_PER_HOUR;
    }

    return 60 / intervalInMinutes;
};

export const averageHourlyElectricityReadings = ({
    events = [],
    eventTypeKey,
    intervalInMinutes,
}: AverageHourlyElectricityReadingsParams): number => {
    const readingsTotal = sumEventsReadings({
        events,
        eventTypeKey,
    });

    const readingPerHour = getReadingsPerHour(intervalInMinutes);

    return events.length > 0
        ? round(readingsTotal / (events.length / readingPerHour), 2)
        : 0;
};

const getAverageHourlyElectricityReadingsInWorkingHours = ({
    sensorEvents,
    timeZone,
    workingHoursConfiguration,
    intervalInMinutes,
}: AverageHourlyElectricityReadingsInHoursParams): number =>
    averageHourlyElectricityReadings({
        events: getEventsInWorkingHours({
            events: sensorEvents,
            timeZone,
            workingHoursConfiguration,
        }),
        intervalInMinutes,
        eventTypeKey: SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS,
    });

const getAverageHourlyElectricityReadingsInWorkingHoursV2 = ({
    sensorEvents,
    timeZone,
    workingHoursConfiguration,
    intervalInMinutes,
}: AverageHourlyElectricityReadingsInHoursParams): number =>
    averageHourlyElectricityReadings({
        events: getEventsInWorkingHours({
            events: (sensorEvents as SensorEventV2Shape[])?.filter((event) =>
                isValidElectricityEventType(event?.type),
            ),
            timeZone,
            workingHoursConfiguration,
        }),
        intervalInMinutes,
        eventTypeKey: SENSOR_EVENT_V2_KEY,
    });

export const getAverageHourlyElectricityReadingsInWorkingHoursCalculator = (
    isEventsV2: boolean,
): ((params: AverageHourlyElectricityReadingsInHoursParams) => number) => {
    return isEventsV2
        ? getAverageHourlyElectricityReadingsInWorkingHoursV2
        : getAverageHourlyElectricityReadingsInWorkingHours;
};

const getAverageHourlyElectricityReadingsInNonWorkingHours = ({
    sensorEvents,
    timeZone,
    workingHoursConfiguration,
    intervalInMinutes,
}: AverageHourlyElectricityReadingsInHoursParams): number =>
    averageHourlyElectricityReadings({
        events: getEventsInNonWorkingHours({
            events: sensorEvents,
            timeZone,
            workingHoursConfiguration,
        }),
        intervalInMinutes,
        eventTypeKey: SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS,
    });

const getAverageHourlyElectricityReadingsInNonWorkingHoursV2 = ({
    sensorEvents,
    timeZone,
    workingHoursConfiguration,
    intervalInMinutes,
}: AverageHourlyElectricityReadingsInHoursParams): number =>
    averageHourlyElectricityReadings({
        events: getEventsInNonWorkingHours({
            events: (sensorEvents as SensorEventV2Shape[])?.filter((event) =>
                isValidElectricityEventType(event?.type),
            ),
            timeZone,
            workingHoursConfiguration,
        }),
        intervalInMinutes,
        eventTypeKey: SENSOR_EVENT_V2_KEY,
    });

export const getAverageHourlyElectricityReadingsInNonWorkingHoursCalculator = (
    isEventsV2: boolean,
): ((params: AverageHourlyElectricityReadingsInHoursParams) => number) => {
    return isEventsV2
        ? getAverageHourlyElectricityReadingsInNonWorkingHoursV2
        : getAverageHourlyElectricityReadingsInNonWorkingHours;
};
