import type { NamedRouteConfig } from '@infogrid/core-types';
import { memo } from 'react';

import { ConvertFolderModal } from '../../components/modals/ConvertFolderModal';
import CreateBuildingModal from '../../components/modals/CreateBuildingModal';
import { CreateFloorModal } from '../../components/modals/CreateFloorModal';
import DeleteBuildingModal from '../../components/modals/DeleteBuildingModal';
import DeleteFloorModal from '../../components/modals/DeleteFloorModal';
import EditBuildingModal from '../../components/modals/EditBuildingModal';
import EditFloorModal from '../../components/modals/EditFloorModal';
import InstallFlow from '../installFlow/InstallFlow';
import FloorView from './FloorView';

interface Props {
    route: NamedRouteConfig;
}

const FloorViewContainer = ({ route }: Props): JSX.Element => {
    return (
        <>
            <CreateBuildingModal />
            <ConvertFolderModal />
            <CreateFloorModal />

            <EditBuildingModal />
            <DeleteBuildingModal />

            <EditFloorModal />
            <DeleteFloorModal />

            <FloorView route={route} />

            {/* This should go elsewhere if we plan to open the flow from other places */}
            <InstallFlow />
        </>
    );
};

export default memo(FloorViewContainer);
