import { fade, makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useMobileBuildingSidebarStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.common.white,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        position: 'absolute',
        transition: 'height .3s, box-shadow .3s',
        top: 56,
        width: '100%',
        zIndex: 1,
        [theme.breakpoints.down('xs')]: {
            top: 0,
        },
    },
    containerCollapsed: {
        boxShadow: `0px 3px 3px ${fade(theme.palette.common.black, 0.16)}`,
        height: 56,
    },
    header: {
        alignItems: 'center',
        boxShadow: `0px 1px 0px ${fade(theme.palette.common.black, 0.16)}`,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 2.5),
    },
    buildingContainer: {
        alignItems: 'center',
        display: 'flex',
        maxWidth: 'calc(100% - 50px)',
    },
    buildingName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    arrow: {
        alignItems: 'center',
        display: 'flex',
        fontSize: 16,
        height: 18,
        marginLeft: theme.spacing(1.5),
    },
    ellipsis: {
        color: theme.palette.common.gray4,
        fontSize: 16,
        marginTop: theme.spacing(0.5),
    },
    subheader: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        padding: theme.spacing(1),
    },
    actionButton: {
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
        whiteSpace: 'nowrap',
        '&, &:hover, &:active, &:focus': {
            textDecoration: 'none',
        },
    },
    actionButtonIcon: {
        fontSize: 16,
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(0.25),
    },
    leftArrow: {
        color: theme.palette.primary.main,
        height: 21,
        width: 21,
    },
    actionButtonBuildingName: {
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
        marginLeft: theme.spacing(1),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
    },
    buildingNameButtonContainer: {
        display: 'flex',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    createNewFloorContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    createFloorIcon: {
        marginRight: theme.spacing(0.5),
    },
    buildingsLink: {
        '&, &:hover, &:active, &:focus': {
            textDecoration: 'none',
        },
    },
}));
