import type { Floor, BuildingDetail } from '@infogrid/locations-types';
import { Divider } from '@material-ui/core';
import { memo } from 'react';

import BuildingInfo from '../components/BuildingInfo';
import BuildingsLink from '../components/BuildingsLink';
import FloorsList from '../components/FloorsList';
import { useDesktopBuildingSidebarStyles } from './styles';

interface Props {
    building: BuildingDetail;
    floorId?: number;
    floors: Floor[];
    setSelectedSpaceId?: (id: number | null) => void;
}

const DesktopBuildingSidebar = ({
    building,
    floorId,
    floors,
    setSelectedSpaceId,
}: Props): JSX.Element | null => {
    const styles = useDesktopBuildingSidebarStyles();

    const onFloorClick = () => {
        if (setSelectedSpaceId) {
            setSelectedSpaceId(null);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <BuildingsLink />
                <BuildingInfo building={building} />
            </div>
            <Divider className={styles.divider} />
            <FloorsList
                building={building}
                activeFloorId={Number(floorId)}
                floors={floors}
                onFloorClick={onFloorClick}
            />
        </div>
    );
};

export default memo(DesktopBuildingSidebar);
