import { Modal } from '@infogrid/components-material-ui';
import type { SensorType, SensorShape } from '@infogrid/sensors-constants';
import { Alert } from '@material-ui/lab';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import SensorDisplay from '../SensorDisplay';
import { useInstallationFlowStyles } from '../styles';

interface Props {
    sensor: SensorShape;
    sensorName: string;
    sensorType?: SensorType;
    renderActions: () => JSX.Element;
}

const TemperatureOffsetReminder = ({
    sensor,
    sensorName,
    sensorType,
    renderActions,
}: Props) => {
    const { t } = useTranslation('sensors');

    const installationFlowStyles = useInstallationFlowStyles();

    return (
        <>
            <Modal.Title>{t('Temperature offset')}</Modal.Title>
            <Modal.Content>
                {sensorType && (
                    <SensorDisplay
                        sensor={sensor}
                        label={sensorName}
                        deviceType={sensorType}
                    />
                )}

                <Alert severity="info" data-cypress="info-message">
                    <span>
                        {t(
                            `Set the temperature offset once the sensor has been installed and online for at least 15 minutes.`,
                        )}
                    </span>
                </Alert>
            </Modal.Content>
            <Modal.Actions
                justify="space-between"
                className={installationFlowStyles.actions}
            >
                {renderActions()}
            </Modal.Actions>
        </>
    );
};

export default memo(TemperatureOffsetReminder);
