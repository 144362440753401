import { wrapResponsePromise } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { Floor } from '@infogrid/locations-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

import {
    cancelFloorDetailQuery,
    cancelFloorsQuery,
    invalidateFloorDetail,
    invalidateFloors,
} from '../accessors';
import { controllers } from '../controllers';

interface UseDeleteFloorParams {
    buildingId: number;
    floorId: number;
}

export const useDeleteFloor = (): UseMutationResult<
    Floor[],
    AxiosParsedError,
    UseDeleteFloorParams
> => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('floorplan');

    return useMutation(
        ({ floorId }) => wrapResponsePromise(controllers.deleteFloor(floorId)),
        {
            onMutate({ floorId, buildingId }) {
                cancelFloorDetailQuery(floorId);
                cancelFloorsQuery(buildingId);
            },
            onSuccess(_, { buildingId, floorId }) {
                invalidateFloorDetail(floorId);
                invalidateFloors(buildingId);

                dispatch(toastSuccess({ message: t('Floor deleted successfully') }));
            },
            onError(err) {
                const errors = Object.values(err.parsedError.errors).join(' ');

                dispatch(
                    toastError({
                        message: `${t('Error while deleting the floor:')} ${errors}`,
                    }),
                );
            },
        },
    );
};
