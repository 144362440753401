import { TEMP_UNIT } from '@infogrid/utils-measurements';
import PropTypes from 'prop-types';

export const OrganizationFeatureShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
});
export const OrganizationalRoleShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    member: PropTypes.bool.isRequired,
    manager: PropTypes.bool.isRequired,
    logo: PropTypes.string,
    features: PropTypes.objectOf(OrganizationFeatureShape).isRequired,
});

const UserBaseShape = {
    id: PropTypes.number,
    email: PropTypes.string,
    name: PropTypes.string,
};

// TODO this seems unused
export const UserInfoShape = PropTypes.shape(UserBaseShape);

export const TempUnitType = PropTypes.oneOf(Object.values(TEMP_UNIT));
export const UserShape = PropTypes.shape({
    ...UserBaseShape,
    phone_number: PropTypes.string,
    is_superuser: PropTypes.bool,
    is_staff: PropTypes.bool,
    is_active: PropTypes.bool,
    last_login: PropTypes.string,
    date_joined: PropTypes.string,
    organizational_roles: PropTypes.arrayOf(OrganizationalRoleShape),
    time_zone: PropTypes.string,
    temp_unit: TempUnitType,
    has_accepted_latest_terms: PropTypes.bool,
    has_agreed_to_terms_before: PropTypes.bool,
    intercom_hash: PropTypes.string,
    installation_flow_config: PropTypes.shape({
        last_saved_building: PropTypes.number,
        last_saved_floor: PropTypes.number,
        last_saved_room: PropTypes.number,
    }),
});

export const OrgUserShape = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    last_login: PropTypes.string,
    invite_pending: PropTypes.bool.isRequired,
    manager_status: PropTypes.bool.isRequired,
    member_status: PropTypes.bool.isRequired,
    new: PropTypes.bool, // Set in OrgUser form when creating data to pre-fill the form for user not yet in backend
    isUniqName: PropTypes.bool, // Set in OrgUser with selector
});

export const ServiceAccountShape = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    secret_key: PropTypes.string,
    manager_status: PropTypes.bool,
    last_login: PropTypes.string,
});
