import { Typography } from '@material-ui/core';
import compact from 'lodash/compact';
import type { FC } from 'react';
import { useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import { useFloor } from 'apiHooks/floorPlan/floors/hooks';
import { useSpaceName } from 'apiHooks/floorPlan/spaces/hooks';

export interface SensorLocationStringProps {
    location: {
        building_id?: number;
        floor_id?: number;
        space_id?: number | null;
    };
    separator?: string;
}

/**
 * Renders a location string, eg `Building A / Floor B / Space C` from a location object
 */
const SensorLocationString: FC<SensorLocationStringProps> = ({
    location,
    separator = '/',
}) => {
    const { t } = useTranslation();
    // Fallback to 0 below because the hook requires a number, but won’t fetch if falsey
    const { building } = useBuilding(location.building_id || 0);
    const { data: floor } = useFloor(location.floor_id || 0);
    const spaceName = useSpaceName(location) || t('Not in space');

    const locationString = useMemo(() => {
        return compact([building?.name, floor?.name, spaceName]).join(` ${separator} `);
    }, [building, floor, spaceName, separator]);

    return (
        <Typography variant="body2" color="textSecondary" data-cypress="sensor-location">
            {locationString}
        </Typography>
    );
};

export default memo(SensorLocationString) as typeof SensorLocationString;
