import { LOCATION_FILTERS } from '@infogrid/core-types';
import type { LocationFilter } from '@infogrid/core-types';
import { MenuItem, Select, FormControl } from '@material-ui/core';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelectorStyles } from './styles';

export interface Props {
    value: LocationFilter;
    onChange: (value: LocationFilter) => void;
    enabledFilters: LocationFilter[];
    className?: string;
    disabled?: boolean;
}

const Selector = ({ className, value, onChange, disabled, enabledFilters }: Props) => {
    const styles = useSelectorStyles();
    const { t } = useTranslation('dashboard');

    const items: { value: LocationFilter; name: string }[] = useMemo(
        () => [
            {
                value: LOCATION_FILTERS.BUILDING,
                name: t('Buildings'),
            },
            {
                value: LOCATION_FILTERS.FLOOR,
                name: t('Floors'),
            },
            {
                value: LOCATION_FILTERS.SPACE,
                name: t('Spaces'),
            },
        ],
        [t],
    );

    return (
        <FormControl className={className}>
            <Select
                className={styles.select}
                disableUnderline
                disabled={disabled}
                fullWidth
                data-cypress="location-type-selector"
                data-testid="location-type-selector"
                value={value}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    elevation: 1,
                    MenuListProps: {
                        //@ts-expect-error invalid MenuListProps type
                        'data-cypress': 'location-type-dropdown',
                        className: styles.listContainer,
                    },
                }}
                onChange={(event) => {
                    onChange(event.target.value as LocationFilter);
                }}
            >
                {items.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        disabled={!enabledFilters.includes(option.value)}
                        className={styles.menuItem}
                    >
                        {t(option.name)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default memo(Selector);
