import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { getSensor } from '@infogrid/sensors-configuration';
import type { SensorShape } from '@infogrid/sensors-constants';
import type { ReactElement } from 'react';
import { useUpdateEffect } from 'react-use';

import { useSensor } from 'apiHooks/sensors/hooks';
import {
    INSTALL_STEPS,
    onSensorIdentified,
    selectIdentifiedSensor,
} from 'ducks/installFlow';

import { SENSOR_OFFLINE_POLLING_TIME } from '../consts';
import { useGoToStep } from '../utils/hooks/useGoToStep';
import SensorOffline from './SensorOffline';

const SensorOfflineContainer = (): ReactElement => {
    const dispatch = useAppDispatch();

    const onClickBack = useGoToStep(INSTALL_STEPS.BARCODE_SCAN);
    const onClickContinue = useGoToStep(INSTALL_STEPS.OFFLINE_CONFIRMATION);

    const identifiedSensor = useAppSelector(selectIdentifiedSensor) as SensorShape;

    const { data: latestSensor, isFetching: latestSensorLoading } = useSensor(
        identifiedSensor.device_name,
        {
            refetchInterval: SENSOR_OFFLINE_POLLING_TIME,
        },
    );

    useUpdateEffect(() => {
        if (latestSensor) {
            const sensorConfig = getSensor(latestSensor);

            if (sensorConfig.getIsOnline()) {
                dispatch(onSensorIdentified(latestSensor));
            }
        }
    }, [dispatch, latestSensor]);

    return (
        <SensorOffline
            statusLoading={latestSensorLoading}
            identifiedSensor={identifiedSensor}
            onClickBack={onClickBack}
            onClickContinue={onClickContinue}
        />
    );
};

export default SensorOfflineContainer;
