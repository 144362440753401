import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';
import { useDispatch } from 'react-redux';

import updateSingleEventsWebhookData from 'apiHooks/eventsWebhook/hooks/updateSingleEventsWebhookData';

import { controllers } from '../controllers';

type useResetEventsWebhookParams = {
    eventsWebhookId: number;
};

export const useResetEventsWebhook = (): UseMutationResult<
    useResetEventsWebhookParams,
    AxiosParsedError,
    useResetEventsWebhookParams
> => {
    const dispatch = useDispatch();

    const { t } = useTranslation('integrations');

    return useMutationWithToast(
        ({ eventsWebhookId }) =>
            wrapResponsePromise(controllers.deleteEventsWebhook(eventsWebhookId)),
        {
            onSuccess() {
                updateSingleEventsWebhookData(null);
                dispatch(toastSuccess({ message: t('Events webhook reset') }));
            },
            onError: () => {
                dispatch(
                    toastError({ message: t('Error while resetting Events webhook') }),
                );
            },
        },
        { toastMessage: t('Events webhook reset in progress') },
    );
};
