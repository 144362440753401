import { useAppSelector } from '@infogrid/core-ducks';
import { getLocation } from 'connected-react-router';

import { useSortableOrderBy } from '..';
import type { OrderByColumn, OrderBy } from '../types';
import { buildingQueryParamsManager } from './config';
import type { BuildingQueryParams, FormattedBuildingQueryParams } from './types';

export { BUILDING_PARAM_NAMES } from './consts';

export const useBuildingQueryBuilder = (initialQuery = {}): BuildingQueryParams => {
    const location = useAppSelector(getLocation);

    return buildingQueryParamsManager.buildQuery(location?.search, initialQuery);
};

export const useFormattedBuildingQueryParams = (): FormattedBuildingQueryParams => {
    const location = useAppSelector(getLocation);

    return buildingQueryParamsManager.formatQueryParams(location.search);
};

const getOrderBy = (search: string) => {
    const { ordering } = buildingQueryParamsManager.buildQuery(search);

    return ordering ?? null;
};

export const useBuildingSortableOrderBy = (): [
    OrderBy,
    (column: OrderByColumn) => void,
] => {
    return useSortableOrderBy(buildingQueryParamsManager, getOrderBy);
};

export const onBuildingSearchPath = buildingQueryParamsManager.onSearch;
