import type { WidgetType } from '@infogrid/dashboards-constants';
import { Analytic } from '@infogrid/utils-analytics';
import type { USER_ACTIONS } from '@infogrid/utils-analytics';

import { getWidgetMetadataByType } from '../utils/widgets';

type WidgetActionPayload = {
    widgetType: WidgetType;
};

type SensorActionPayload = {
    deviceName: string;
};

const PAGE_NAME = 'Dashboard Page';

const getWidgetActionName = (widgetType: WidgetType, action: string) => {
    const widgetConfig = getWidgetMetadataByType(widgetType);

    return `[${widgetConfig.label} Widget] ${action}`;
};

const trackWidgetEvent = (action: string, payload: WidgetActionPayload) => {
    const eventName = getWidgetActionName(payload.widgetType, action);

    Analytic.trackActivity(PAGE_NAME, eventName, {
        date: new Date(),
        ...payload,
    });
};

export const trackDashboardEvent = (
    userAction: USER_ACTIONS,
    eventName: string,
    properties?: Record<string, unknown>,
): void => {
    Analytic.trackActivity(PAGE_NAME, `${userAction} ${eventName}`, properties);
};

const trackSensorEvent = (eventName: string, payload: SensorActionPayload) => {
    Analytic.trackActivity(PAGE_NAME, eventName, {
        date: new Date(),
        ...payload,
    });
};

export const deleteWidgetEvent = (payload: WidgetActionPayload): void => {
    trackWidgetEvent('Delete', payload);
};

export const duplicateWidgetEvent = (payload: WidgetActionPayload): void => {
    trackWidgetEvent('Duplicate', payload);
};

export const openConfigurationWidgetModalEvent = (payload: WidgetActionPayload): void => {
    trackWidgetEvent('Open Configure Modal', payload);
};

export const changeMapStateEvent = (payload: WidgetActionPayload): void => {
    trackWidgetEvent('Change Map Configuration', payload);
};

export const openSensorCleaningHistoryModalEvent = (
    payload: SensorActionPayload,
): void => {
    trackSensorEvent('Opens Sensor Cleaning History Modal', payload);
};

export const confirmSmartCleaningSensorCleaningEvent = (
    payload: SensorActionPayload,
): void => {
    trackSensorEvent('Confirm Smart Cleaning Sensor Cleaning', payload);
};
