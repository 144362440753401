const STATE_KEY = 'floorPlan';

export const SET_EDIT_FLOOR_ID = `${STATE_KEY}/SET_EDIT_FLOOR_ID`;

export const SET_IS_DELETING_BUILDING = `${STATE_KEY}/SET_IS_DELETING_BUILDING`;
export const SET_IS_EDITING_BUILDING = `${STATE_KEY}/SET_IS_EDITING_BUILDING`;
export const SET_IS_CREATING_BUILDING = `${STATE_KEY}/SET_IS_CREATING_BUILDING`;

export const SET_IS_CREATING_FLOOR = `${STATE_KEY}/SET_IS_CREATING_FLOOR`;
export const SET_IS_EDITING_FLOOR = `${STATE_KEY}/SET_IS_EDITING_FLOOR`;
export const SET_IS_DELETING_FLOOR = `${STATE_KEY}/SET_IS_DELETING_FLOOR`;

export const SET_IS_REPLACING_FLOOR_IMAGE = `${STATE_KEY}/SET_IS_REPLACING_FLOOR_IMAGE`;
export const SET_IS_EDITING_FLOOR_IMAGE = `${STATE_KEY}/SET_IS_EDITING_FLOOR_IMAGE`;

export const SET_IS_CREATING_FOLDER = `${STATE_KEY}/SET_IS_CREATING_FOLDER`;

export const SET_SHOULD_KEEP_SENSORS_MAPPED = `${STATE_KEY}/SET_SHOULD_KEEP_SENSORS_MAPPED`;
