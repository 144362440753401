import { OrganizationDropdown } from '@infogrid/components-material-ui';
import { useAppSelector } from '@infogrid/core-ducks';
import { FeatureWrapper } from '@infogrid/core-feature-wrapper';
import { routesManager } from '@infogrid/core-routing';
import { selectEnabledSolutions } from '@infogrid/solution-views-ducks';
import { selectUserIsManager, selectOrganization } from '@infogrid/user-ducks';
import { useIsMobile } from '@infogrid/utils-hooks';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import getLogo from 'components/Navbar/getLogo';
import mapNavItemsByGroups, {
    NAVBAR_LINK_TYPES,
} from 'configuration/mapNavItemsByGroups';
import { useBackendVersion } from 'utils/hooks/useBackendVersion';

import NavbarButtonModal from '../NavbarButtonModal';
import NavbarLink from '../NavbarLink';
import NavbarLogo from '../NavbarLogo';
import NavbarText from '../NavbarText';
import {
    useNavbarContainerStyles,
    useNavbarGroupStyles,
    useNavbarLogoStyles,
} from './styles';

const disableBody = (navbarOpened) => {
    if (document) {
        document.documentElement.classList.toggle('disabled-html', navbarOpened);
    }
};

const NavbarContainer = (props) => {
    const {
        navbarOpened,
        transitionDuration,
        displayLogo,
        forceCollapse,
        toggleCollapsedState,
    } = props;
    const isManager = useAppSelector(selectUserIsManager);
    const { t } = useTranslation();
    // TODO: WEB-2887
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const org = useAppSelector(selectOrganization) || {};

    const enabledSolutions = useAppSelector(selectEnabledSolutions);

    const isMobile = useIsMobile();
    const [logo, logoText] = getLogo(org);

    const backendVersion = useBackendVersion();

    const history = useHistory();

    const navigationGroups = useMemo(
        () =>
            mapNavItemsByGroups({
                isManager,
                org,
                isMobile,
                backendVersion,
                enabledSolutions,
            }),
        [isManager, org, isMobile, backendVersion, enabledSolutions],
    );

    const navbarContainerClasses = useNavbarContainerStyles({ isMobile });
    const navbarGroupClasses = useNavbarGroupStyles({ isMobile });
    const navbarLogoClasses = useNavbarLogoStyles();

    // TODO: WEB-2887
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const linkClickHandler = useCallback(
        forceCollapse ? () => toggleCollapsedState(false) : null,
        [forceCollapse],
    );

    const goToMainPage = useCallback(() => {
        history.push('/');
    }, [history]);

    useEffect(() => {
        disableBody(isMobile && navbarOpened);
    }, [isMobile, navbarOpened]);

    return (
        <section className={navbarContainerClasses.root} data-cypress="sidebar">
            {displayLogo && (
                <NavbarLogo
                    classes={{
                        image: org.logo && navbarLogoClasses.image,
                        text: org.name && navbarLogoClasses.text,
                    }}
                    logo={logo}
                    logoText={logoText}
                    onClick={goToMainPage}
                    titleVisible={navbarOpened}
                    transitionDuration={transitionDuration}
                />
            )}
            {isMobile && <OrganizationDropdown />}
            <nav className={navbarContainerClasses.navContainer}>
                {navigationGroups.map((navigationGroup) => (
                    <FeatureWrapper
                        key={navigationGroup.id}
                        organizationFeature={navigationGroup.feature.org}
                        featureFlag={navigationGroup.feature.flag}
                    >
                        <ul className={navbarGroupClasses.root}>
                            {navigationGroup.items.map((navItem) => {
                                if (navItem.isHidden) {
                                    return null;
                                }

                                const cypressAtr = navItem.name?.toLowerCase();

                                if (navItem.type === NAVBAR_LINK_TYPES.DIALOG) {
                                    return (
                                        <NavbarButtonModal
                                            transitionDuration={transitionDuration}
                                            navbarOpened={navbarOpened}
                                            modalName={navItem.modalName}
                                            icon={navItem.icon}
                                            text={t(navItem.name)}
                                            key={navItem.name}
                                        />
                                    );
                                }

                                if (navItem.type === NAVBAR_LINK_TYPES.TEXT) {
                                    return (
                                        <NavbarText
                                            text={t(navItem.name)}
                                            navbarOpened={navbarOpened}
                                            key={navItem.name}
                                            truncate
                                        />
                                    );
                                }

                                return (
                                    <FeatureWrapper
                                        featureFlag={navItem.feature.flag}
                                        organizationFeature={navItem.feature.org}
                                        key={navItem.name}
                                    >
                                        <li
                                            className={navbarGroupClasses.item}
                                            data-cypress={cypressAtr}
                                        >
                                            <NavbarLink
                                                onClick={linkClickHandler}
                                                transitionDuration={transitionDuration}
                                                icon={navItem.icon}
                                                text={t(navItem.name)}
                                                navbarOpened={navbarOpened}
                                                to={routesManager.resolvePath(
                                                    navItem.path,
                                                )}
                                            />
                                        </li>
                                    </FeatureWrapper>
                                );
                            })}
                        </ul>
                    </FeatureWrapper>
                ))}
            </nav>
        </section>
    );
};

NavbarContainer.propTypes = {
    displayLogo: PropTypes.bool,
    forceCollapse: PropTypes.bool,
    toggleCollapsedState: PropTypes.func,
    navbarOpened: PropTypes.bool.isRequired,
    transitionDuration: PropTypes.number.isRequired,
};

NavbarContainer.defaultProps = {
    displayLogo: true,
    forceCollapse: false,
    toggleCollapsedState: null,
};

export default memo(NavbarContainer);
