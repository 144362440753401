import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { FloorSensor, FloorSensorLocation } from '@infogrid/locations-types';
import type { MappableSensor, SensorType } from '@infogrid/sensors-constants';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import type { UseQueryOptions, UseQueryResult } from 'react-query';

import { useLatestSensorsEvents } from 'apiHooks/sensors/hooks';
import { makeSensorsMappable } from 'utils/sensor';

import { controllers } from '../controllers';
import { getFloorSensorsKey } from '../getQueryKeys';

export interface UseFloorSensorsProps {
    floorId?: number;
    params?: { sensor_type?: SensorType[] };
    // If passed, a filtered list of mappable sensors named `mappedSensors` will be returned
    floorSensorDictionary?: Record<string, FloorSensorLocation>;
}

export type UseFloorSensorsResult = UseQueryResult<
    { sensors: FloorSensor[] },
    AxiosParsedError<{ detail: string }>
> & {
    mappedSensors: MappableSensor<FloorSensor>[];
};

export type UseFloorSensorsOptions = UseQueryOptions<
    { sensors: FloorSensor[] },
    AxiosParsedError<{ detail: string }>
>;

const EMPTY_SENSOR_IDS: string[] = [];

export const sortSensorsByName = <T extends { sensors: FloorSensor[] }>({
    sensors,
    ...data
}: T) => ({
    ...data,
    sensors: [...sensors]?.sort((a, b) => {
        const aName = a.name || a.device_name;
        const bName = b.name || b.device_name;

        return aName.localeCompare(bName);
    }),
});

export const useFloorSensors = <T extends UseFloorSensorsProps>(
    { floorId, params, floorSensorDictionary }: T,
    options?: UseFloorSensorsOptions,
): UseFloorSensorsResult => {
    const select = useCallback(
        (data) =>
            options?.select
                ? sortSensorsByName(options.select(data))
                : sortSensorsByName(data),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [options?.select],
    );

    const { refetch: refetchSensors, ...query } = useQuery(
        getFloorSensorsKey({ floorId, params }),
        ({ signal }) =>
            wrapResponsePromise(
                controllers.getFloorSensors({
                    // Will be number if enabled
                    floorId: floorId as number,
                    signal,
                    params,
                }),
            ),
        {
            ...options,
            enabled: options?.enabled ?? !!floorId,
            refetchOnMount: options?.refetchOnMount || false,
            select,
        },
    );

    // Filters out sensors without floor coordinates and adds required map properties
    const mappedSensors = useMemo(
        () =>
            (floorSensorDictionary &&
                makeSensorsMappable(query.data?.sensors, floorSensorDictionary, true)) ||
            [],
        [query.data?.sensors, floorSensorDictionary],
    );

    // Makes a list of sensor IDs and hits `/latest_events_v2` endpoint, regenerating the cache, then refetches
    // the sensors. `useLatestSensorsEvents` is polled so the cache is regularly updated before refecthing.
    // This is extremely temporary and will be replaced by proper cache invalidation on the backend.
    const sensorIds = useMemo(
        () => query.data?.sensors.map((sensor) => sensor.device_name) || EMPTY_SENSOR_IDS,
        [query.data?.sensors],
    );
    useLatestSensorsEvents(sensorIds, undefined, {
        enabled: !!sensorIds?.length,
        onSuccess: () => {
            refetchSensors();
        },
    });

    return useMemo(() => {
        return {
            ...query,
            mappedSensors,
            refetch: refetchSensors,
        };
    }, [query, mappedSensors, refetchSensors]);
};
