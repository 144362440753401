import { SearchFilterInput } from '@infogrid/components-material-ui';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { onSpaceSearchPath, useFormattedSpaceQueryParams } from 'utils/filtering/space';

import { useSearchFilterStyles } from './styles';

const SearchFilter = () => {
    const location = useLocation();
    const history = useHistory();
    const { q } = useFormattedSpaceQueryParams();
    const { t } = useTranslation('estate');

    const styles = useSearchFilterStyles();

    const onSearch = (searchVal: string) => {
        history.push(
            onSpaceSearchPath(location, {
                q: searchVal,
            }),
        );
    };

    const onClear = useCallback(() => {
        history.push(onSpaceSearchPath(location, { q: '' }));
    }, [history, location]);

    return (
        <SearchFilterInput
            classes={{
                root: styles.searchFilterInput,
            }}
            dataCypress="search-spaces"
            onClear={onClear}
            onSearch={onSearch}
            placeholder={t('Search spaces...')}
            value={q}
        />
    );
};

export default memo(SearchFilter);
