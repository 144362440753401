import { useLatest } from '@infogrid/utils-hooks';
import { getIn, useFormikContext } from 'formik';
import { useCallback } from 'react';

export const useSubmitFormIfDirty = (submitForm, dirty) =>
    useCallback(() => {
        if (dirty) {
            submitForm();
        }
    }, [submitForm, dirty]);

// This is basically what formik.setValues will hopefully do in the future
// Generally works exactly like formik.setValues
// You can optionally pass in a callback,
//  which gets previous values as argument and returns new values
// This is similar to how setState works
// See https://github.com/formik/formik/pull/1513
// If this gets merged into `formik` then all usages should be safe to replace with built-in setValues
export const useFormikUpdateSetValues = () => {
    const formik = useFormikContext();
    const { setValues } = formik;

    // We use this to avoid adding formik.values as dependency
    const latestValues = useLatest(formik.values);

    return useCallback(
        (newValuesOrCallback, shouldValidate) => {
            let newValues;

            if (typeof newValuesOrCallback === 'function') {
                const values = latestValues.current;

                newValues = newValuesOrCallback(values);
            } else {
                newValues = newValuesOrCallback;
            }

            setValues(newValues, shouldValidate);
        },
        [latestValues, setValues],
    );
};

/**
 * Get the formik field name used for specific sensor type.
 * If fieldName is a string it just returns fieldName.
 * If fieldName is an object it returns fieldName[sensorType].
 * @param {string|undefined} sensorType - Sensor type to get field name for.
 * @param {string|Object<string>} fieldName - Base string or object field name to use.
 * @returns {string}
 */
export const getFieldNameBySensorType = ({ sensorType, fieldName }) => {
    let fieldNameInner;

    if (typeof fieldName === 'string') {
        fieldNameInner = fieldName;
    } else if (typeof fieldName === 'object') {
        fieldNameInner = fieldName[sensorType];

        if (!fieldNameInner) {
            // eslint-disable-next-line no-console
            console.warn(`Did not find field name for sensor type ${sensorType}`);
        }
    }

    return fieldNameInner;
};

/**
 * Get the selected items from potentially multiple fields and merge them into a single list.
 * The values are in multiple fields for widgets that combine data from multiple types of sensors.
 *   In those cases the fieldName is an object.
 */
export const selectSelectedItems = (
    values,
    { fieldName, verboseFieldName, dedupe = true },
) => {
    if (typeof fieldName === 'string') {
        return getIn(values, fieldName, []);
    } else if (typeof fieldName === 'object') {
        const fieldNamesInner = Object.values(fieldName);
        const selectedItems = fieldNamesInner
            .map((fieldNameInner) => getIn(values, fieldNameInner, []))
            .flat();

        if (dedupe) {
            return [...new Set(selectedItems)];
        }

        return selectedItems;
    }

    throw new Error(`Expected ${verboseFieldName} to be a string or a list of strings`);
};
