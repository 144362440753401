import { makeStyles } from '@material-ui/core';

export const useWidgetBarStyles = makeStyles((theme) => ({
    barDraggable: {
        '&:hover': {
            cursor: 'move',
        },
    },
    container: {
        display: 'grid',
        gridGap: theme.spacing(2),
        gridTemplateColumns: '65% 35%',
        padding: theme.spacing(2, 2, 1),

        '&:hover': {
            '& > div > i': {
                opacity: 1,
            },
        },
    },
}));
