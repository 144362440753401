import { TruncatedText } from '@infogrid/components-material-ui';
import { Typography } from '@material-ui/core';
import type { Location } from 'history';
import type { MouseEvent } from 'react';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import type { FloorRowInfoProps } from '../FloorRowInfo/FloorRowInfo';
import FloorRowInfo from '../FloorRowInfo/FloorRowInfo';
import { useFloorRowContentStyles } from './styles';

export interface FloorRowContentProps extends FloorRowInfoProps {
    floorLink: Location;
    separateHubCount?: boolean;
    showAlerts?: boolean;
    onFloorClick?: (e: MouseEvent) => void;
    showCapacity?: boolean;
    showHubCount?: boolean;
    showSensors?: boolean;
}

/**
 * Basic floor data with metrics (number of spaces and sensors). Optionally display a separate hub count.
 * For use alongside FloorList (e.g. for including in the renderRow callback implementation).
 */
const FloorRowContent = ({
    floor,
    floorLink,
    separateHubCount,
    showAlerts = true,
    showCapacity,
    showHubCount = true,
    showSensors = true,
    onFloorClick,
    onAlertsClick,
}: FloorRowContentProps) => {
    const styles = useFloorRowContentStyles();

    return (
        <div className={styles.root}>
            <Link className={styles.floorLink} onClick={onFloorClick} to={floorLink}>
                <Typography component="div" className={styles.floorName} variant="body1">
                    <TruncatedText tooltipTitle={floor.name} placement="top-start">
                        {floor.name}
                    </TruncatedText>
                </Typography>
            </Link>

            <FloorRowInfo
                className={styles.floorInfo}
                floor={floor}
                separateHubCount={separateHubCount}
                onAlertsClick={onAlertsClick}
                showAlerts={showAlerts}
                showCapacity={showCapacity}
                showHubCount={showHubCount}
                showSensors={showSensors}
            />
        </div>
    );
};

export default memo(FloorRowContent);
