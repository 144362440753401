import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

interface Props {
    color: string;
}

export const useOccupancySectionStyles = makeStyles((theme) => ({
    colorCircle: ({ color }: Props) => ({
        color,
        fontSize: '12px',
        marginRight: theme.spacing(1),
    }),
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
    formControlLabel: {
        marginBottom: 0,
    },
    messageInput: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}));
