import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
} from '@infogrid/components-material-ui';
import { Button, Grid, Icon, List, ListItem } from '@material-ui/core';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { memo, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import BuildingDetails from 'views/estate/components/forms/BuildingDetails';

import { useAddFirstBuildingStyles } from './styles';

const AddFirstBuilding = () => {
    const styles = useAddFirstBuildingStyles();

    const { t } = useTranslation('estate');

    const { isSubmitting, handleSubmit, setFieldValue } = useFormikContext();

    const onSave = () => {
        setFieldValue('actionType', 'save');
    };

    const onSaveAndAddAnother = () => {
        setFieldValue('actionType', 'add-another');
    };

    const infoList = useMemo(
        () => [
            {
                icon: 'far fa-city',
                content: t('Add a building with a name, type and address'),
            },
            {
                icon: 'far fa-layer-group',
                content: t(
                    'Add the floors within your building where sensors will be installed',
                ),
            },
            {
                icon: 'far fa-cube',
                content: t(
                    'Create spaces on each floor that represent areas you want to track data on; offices, kitchens, bathrooms and more',
                ),
            },
            {
                icon: 'far fa-question-circle',
                content: (
                    <Trans t={t}>
                        Need more help? Visit our&nbsp;
                        <a
                            target="_blank"
                            href="https://help.infogrid.io/en"
                            rel="noreferrer"
                        >
                            Help Center
                        </a>
                    </Trans>
                ),
            },
        ],
        [t],
    );

    // The "info" box should appear first on mobile so going for a mobile-first approach.
    // Apparent order will be reversed on larger screens
    return (
        <Grid
            container
            spacing={3}
            className={styles.container}
            data-cypress="add-first-building"
        >
            <Grid item sm={12} md={6}>
                <Card className={classNames(styles.card, styles.infoCard)}>
                    <CardHeader
                        className={styles.cardHeader}
                        title={
                            <h5 className={styles.cardTitle}>{t('Setup your estate')}</h5>
                        }
                        disableTypography
                    />
                    <CardContent className={styles.cardContent}>
                        <List disablePadding>
                            {infoList.map(({ icon, content }, index) => (
                                <ListItem key={index} disableGutters={true}>
                                    <Icon className={classNames(icon, styles.infoIcon)} />
                                    {content}
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item sm={12} md={6}>
                <Card className={styles.card}>
                    <CardHeader
                        className={styles.cardHeader}
                        title={
                            <h5 className={styles.cardTitle}>
                                {t('Add your first building')}
                            </h5>
                        }
                        disableTypography
                    />
                    <form onSubmit={handleSubmit}>
                        <CardContent className={styles.cardContent}>
                            <BuildingDetails />
                        </CardContent>
                        <CardActions className={styles.cardActions}>
                            <Button
                                type="submit"
                                className={styles.saveAndAddAnotherButton}
                                color="primary"
                                data-cypress="save-and-add"
                                disabled={isSubmitting}
                                onClick={onSaveAndAddAnother}
                            >
                                {t('Save + add another')}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                className={styles.saveButton}
                                data-cypress="save"
                                variant="contained"
                                disabled={isSubmitting}
                                onClick={onSave}
                            >
                                {t('Save')}
                            </Button>
                        </CardActions>
                    </form>
                </Card>
            </Grid>
        </Grid>
    );
};

export default memo(AddFirstBuilding);
