import { makeStyles } from '@material-ui/core';

import { BUILDINGS_SIDEBAR_WIDTH } from 'views/estate/components/BuildingSidebar/consts';

export const useEstateFloorViewStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(7.5),
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(2),
        },
    },
    filtersContainer: {
        padding: theme.spacing(0, 3),
    },
    loader: {
        left: `calc(50% + ${BUILDINGS_SIDEBAR_WIDTH / 2}px)`,
        position: 'absolute',
        top: '50%',
    },
}));
