import { useFormik } from 'formik';
import type { FormikHelpers, FormikProps } from 'formik';
import { useCallback } from 'react';

import { BUILDING_FORM_VALIDATION_SCHEMA } from '../constants/buildingFormValidationSchema';

interface BuildingDetailsValues {
    name: string;
    address: string;
    countryCode?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    occupant_capacity?: number | null;
}

interface UseBuildingDetailsParams {
    onSubmit: (
        submitValues: BuildingDetailsValues,
        formik: FormikHelpers<BuildingDetailsValues>,
    ) => void;
    initialValues: BuildingDetailsValues;
}

interface UseBuildingDetailsReturnType
    extends Pick<
        FormikProps<BuildingDetailsValues>,
        | 'handleReset'
        | 'handleSubmit'
        | 'handleChange'
        | 'values'
        | 'errors'
        | 'touched'
        | 'isSubmitting'
    > {
    handleLocation: ({
        address,
        countryCode,
        coordinates: { latitude, longitude },
    }: {
        address: string;
        countryCode: string;
        coordinates: {
            latitude: string;
            longitude: string;
        };
    }) => void;
}

export const useBuildingDetails = ({
    onSubmit = () => {},
    initialValues = {
        name: '',
        address: '',
        countryCode: '',
        latitude: null,
        longitude: null,
        occupant_capacity: undefined,
    },
}: UseBuildingDetailsParams): UseBuildingDetailsReturnType => {
    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleReset,
    } = useFormik({
        initialValues,
        onSubmit,
        enableReinitialize: true,
        ...BUILDING_FORM_VALIDATION_SCHEMA,
    });

    const handleLocation = useCallback(
        ({ address, city, countryCode, coordinates: { latitude, longitude }, text }) => {
            setFieldValue('countryCode', countryCode);
            setFieldValue('latitude', latitude);
            setFieldValue('longitude', longitude);
            setFieldValue('address', address);
            setFieldValue('city', city || text);
        },
        [setFieldValue],
    );

    return {
        handleReset,
        handleLocation,
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
    };
};
