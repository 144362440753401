import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { useCallback, useState, useEffect, memo } from 'react';
import { useTimeoutFn } from 'react-use';

import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from 'components/InstallationFlow/reducer';
import type { InstallationFlowComponentProps } from 'components/InstallationFlow/types';
import { trackInstallFlowUserEvent } from 'views/buildings/pages/installFlow/utils/analytics';

import { useInstallationFlowContext } from '../../context';
import QRCodeScanner from '../QRCodeScanner';

const TEMPERATURE_TYPES: string[] = [
    SENSOR_TYPE.TYPE_COLD_STORAGE,
    SENSOR_TYPE.TYPE_TEMPERATURE,
];

type Props = Pick<InstallationFlowComponentProps, 'errorMessage'>;

const QRCodeScanContainer = ({ errorMessage = '' }: Props) => {
    const { registerInfo, sensor, lastSignalEvent } = useInstallationFlowContext();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    const [errorText, setErrorText] = useState('');

    const [_0, cancelErrorReset, resetError] = useTimeoutFn(() => {
        setErrorText('');
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.RESET_PROGRESS,
        });
    }, 5000);

    useEffect(() => {
        if (sensor && registerInfo) {
            if (TEMPERATURE_TYPES.includes(sensor.type_code)) {
                dispatchInstallationFlowContext({
                    type: INSTALLATION_FLOW_ACTIONS.SCAN_TEMPERATURE_SENSOR,
                });

                return;
            }

            trackInstallFlowUserEvent(USER_ACTIONS.SUBMIT, 'Scanned sensor', {
                sensorDeviceId: sensor.device_id,
                sensorTypeCode: sensor.type_code,
                vendor: sensor.vendor,
            });
            dispatchInstallationFlowContext({
                type: lastSignalEvent
                    ? INSTALLATION_FLOW_ACTIONS.CHECK_CONNECTION
                    : INSTALLATION_FLOW_ACTIONS.CONFIGURE_DEVICE,
            });
        }
    }, [sensor, registerInfo, dispatchInstallationFlowContext, lastSignalEvent]);

    useEffect(() => {
        cancelErrorReset();

        if (errorMessage) {
            setErrorText(errorMessage);
            resetError();
        } else {
            setErrorText('');
        }
    }, [errorMessage, cancelErrorReset, resetError]);

    const pushToManualStep = () =>
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.RETURN_TO_VENDOR_SELECTOR,
        });

    const onScan = useCallback(
        (data) => {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.SCAN,
                registerInfo: data,
            });
        },
        [dispatchInstallationFlowContext],
    );

    return (
        <QRCodeScanner
            onScan={onScan}
            apiError={errorText}
            onClickManualIdentify={pushToManualStep}
        />
    );
};

export default memo(QRCodeScanContainer);
