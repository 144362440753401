import { makeStyles } from '@material-ui/core';

export const useDeviceVendorCardStyles = makeStyles((theme) => ({
    deviceContainer: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        display: 'flex',
    },
    deviceDescription: {
        margin: theme.spacing(1.75, 0),
    },
    image: {
        width: '167px',
        height: 'auto',
    },
    selectButton: {
        textTransform: 'uppercase',
    },
}));
