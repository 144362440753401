import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { Building, BuildingDetailsModalValues } from '@infogrid/locations-types';
import type { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { useMemo, memo, useCallback } from 'react';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
    cancelBuildings,
    cancelPaginatedBuildings,
    invalidateBuildings,
    invalidateBuildingsFilters,
    invalidatePaginatedBuildings,
} from 'apiHooks/floorPlan/buildings/accessors';
import {
    useBuildingsFilters,
    useBuildingTypes,
    useEditOrCreateBuilding,
} from 'apiHooks/floorPlan/buildings/hooks';
import type { EditBuildingResponse } from 'apiHooks/floorPlan/buildings/types';

import {
    buildingDetailsValidationSchema,
    INITIAL_LOCATION,
    INITIAL_WORKING_HOURS,
} from '../forms/BuildingDetails/constants';

export type OnBuildingUpdate = (
    data: EditBuildingResponse,
    values: BuildingDetailsModalValues,
) => void;

export interface EditBuildingProviderProps {
    building: Building | null;
    children: ReactNode;
    onBuildingUpdate?: OnBuildingUpdate;
}

const EditBuildingProvider = ({
    children,
    building,
    onBuildingUpdate,
}: EditBuildingProviderProps) => {
    const isCreate = !building;
    const dispatch = useAppDispatch();
    const { data: buildingTypes } = useBuildingTypes();
    const { data: buildingsFilters } = useBuildingsFilters();

    const filtersCountryCodes = useMemo(
        () => buildingsFilters?.countries.map((c) => c.code) || [],
        [buildingsFilters],
    );

    const { t } = useTranslation('estate');

    const { mutate: editBuilding } = useEditOrCreateBuilding(
        isCreate ? { toastMessage: t('Building created successfully') } : undefined,
    );

    const getBuildingType = useCallback<() => string>(() => {
        if (building?.building_type) {
            return `${building.building_type.id}`;
        }

        if (buildingTypes?.[0]) {
            return `${buildingTypes[0].id}`;
        }

        return '';
    }, [building, buildingTypes]);

    const initialValues: BuildingDetailsModalValues = useMemo(
        () =>
            building
                ? {
                      actionType: 'save',
                      name: building.name,
                      folder: null,
                      building_type: getBuildingType(),
                      location: {
                          ...INITIAL_LOCATION,
                          ...building.location,
                      },
                      floors_count: 1,
                      auto_name_floors: true,
                      working_hours: building.working_hours ?? INITIAL_WORKING_HOURS,
                      occupant_capacity: building.occupant_capacity,
                  }
                : {
                      actionType: 'save',
                      name: '',
                      folder: null,
                      building_type: getBuildingType(),
                      location: INITIAL_LOCATION,
                      floors_count: 1,
                      auto_name_floors: true,
                      working_hours: INITIAL_WORKING_HOURS,
                  },
        [building, getBuildingType],
    );

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values: BuildingDetailsModalValues, formikHelpers) => {
                const { setSubmitting, resetForm } = formikHelpers;

                setSubmitting(true);

                const payload = {
                    name: values.name,
                    building_type_id: values.building_type,
                    folder: values.folder,
                    location: {
                        address: values.location.address,
                        latitude: values.location.latitude,
                        longitude: values.location.longitude,
                        city: values.location.city,
                        country_code: values.location.country_code,
                        address_line_1: values.location.address_line_1,
                        address_line_2: values.location.address_line_2,
                        postcode: values.location.postcode,
                        location_entry_mode: values.location.location_entry_mode,
                    },
                    working_hours: values.working_hours,
                    occupant_capacity: values.occupant_capacity,
                };

                editBuilding(
                    { buildingId: building?.id, data: payload },
                    {
                        onSuccess: async (
                            response: AxiosResponse<EditBuildingResponse>,
                        ) => {
                            if (onBuildingUpdate) {
                                onBuildingUpdate(response.data, values);
                            } else {
                                await cancelBuildings();
                                await invalidateBuildings();
                                await cancelPaginatedBuildings();
                                await invalidatePaginatedBuildings();
                            }

                            if (
                                !filtersCountryCodes.includes(
                                    values.location.country_code,
                                )
                            ) {
                                invalidateBuildingsFilters();
                            }

                            dispatch(
                                toastSuccess({
                                    message: t('Building {{op}} successfully', {
                                        op: isCreate ? 'created' : 'updated',
                                    }),
                                }),
                            );

                            resetForm();
                        },
                        onError(err) {
                            const errors = err.parsedError.errors
                                ? Object.values(err.parsedError.errors).join(' ')
                                : err?.response?.data?.error;

                            dispatch(
                                toastError({
                                    message: `${t('Error while {{op}} the building:', {
                                        op: isCreate ? 'creating' : 'updating',
                                    })} ${errors || ''}`,
                                }),
                            );
                        },
                        onSettled() {
                            setSubmitting(false);
                        },
                    },
                );
            }}
            validationSchema={buildingDetailsValidationSchema}
        >
            {children}
        </Formik>
    );
};

export default memo(EditBuildingProvider);
