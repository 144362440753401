import { MemoizedDivider } from '@infogrid/components-material-ui';
import type { DataFrequency as DataFrequencyType } from '@infogrid/dashboards-constants';
import type { SensorType } from '@infogrid/sensors-constants';
import type { FormikProps } from 'formik';
import type { Moment } from 'moment';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

import Container from 'views/dashboards/widgets/common/DisplayOptions/Container';
import DataFrequency from 'views/dashboards/widgets/common/DisplayOptions/DataFrequency';
import SensorsColors from 'views/dashboards/widgets/common/DisplayOptions/SensorsColors';

import type { TouchCountValues } from '../types';
import { useDisplayOptionsStyles } from './styles';

interface DisplayOptionsProps {
    calculateDisabledFrequencies: ({
        startDate,
        endDate,
    }: {
        startDate: Moment;
        endDate: Moment;
    }) => DataFrequencyType[];
    colorSelectableTypes: SensorType[];
    formik: FormikProps<TouchCountValues>;
    frequencies: DataFrequencyType[];
}

const DisplayOptions = ({
    calculateDisabledFrequencies,
    colorSelectableTypes,
    formik,
    frequencies,
}: DisplayOptionsProps) => {
    const styles = useDisplayOptionsStyles();
    const {
        displayOptions: { dataFrequency, sensorsAttributes },
        sensors = [],
        sensorsData = {},
    } = formik?.values || {};

    const { startDate, endDate } = formik?.values?.dateRange || {};

    const disabledFrequencies = useMemo(
        () => calculateDisabledFrequencies({ startDate, endDate }),
        [calculateDisabledFrequencies, startDate, endDate],
    );

    return (
        <Container>
            <DataFrequency
                className={styles.dataFrequency}
                disabledFrequencies={disabledFrequencies}
                frequencies={frequencies}
                handleChange={formik.handleChange}
                value={dataFrequency}
            />
            <MemoizedDivider />
            <SensorsColors
                colorSelectableTypes={colorSelectableTypes}
                handleChange={formik.handleChange}
                sensors={sensors}
                sensorsData={sensorsData}
                sensorsAttributes={sensorsAttributes}
            />
        </Container>
    );
};

DisplayOptions.propTypes = {
    calculateDisabledFrequencies: PropTypes.func,
    colorSelectableTypes: PropTypes.arrayOf(PropTypes.string),
    formik: PropTypes.shape({
        handleChange: PropTypes.func.isRequired,
        values: PropTypes.shape({
            dateRange: PropTypes.shape({
                endDate: PropTypes.shape({}),
                startDate: PropTypes.shape({}),
            }),
            displayOptions: PropTypes.shape({
                dataFrequency: PropTypes.string,
            }),
            sensors: PropTypes.arrayOf(PropTypes.string),
            sensorsAttributes: PropTypes.shape({}),
        }),
    }).isRequired,
    frequencies: PropTypes.arrayOf(PropTypes.shape({})),
};

DisplayOptions.defaultProps = {
    calculateDisabledFrequencies: () => [],
    colorSelectableTypes: [],
    frequencies: [],
};

export default memo(DisplayOptions);
