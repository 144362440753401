import type { SensorType } from '@infogrid/sensors-constants';
import { getSensorTypeUnit, SENSOR_TYPE } from '@infogrid/sensors-constants';
import { isNumber } from 'is-what';
import { useTranslation } from 'react-i18next';

interface Props {
    value: number;
    precision?: number;
}

const Humidity = ({ value, precision = 0 }: Props) => {
    const { t } = useTranslation('sensor-events');

    if (!isNumber(value)) {
        return null;
    }

    const displayValue = Math.round(value * 100); // events v2 returns this as a decimal, not a percentage

    const unit = t(getSensorTypeUnit(SENSOR_TYPE.TYPE_HUMIDITY as SensorType));

    return (
        <>
            {t('{{value: number}}{{unit}}', {
                value: displayValue,
                unit,
                maximumFractionDigits: precision,
                defaultValue: `${displayValue}${unit}`,
                ns: 'sensor-events',
            })}
        </>
    );
};

export default Humidity;
