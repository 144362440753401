import { Divider as DividerComponent } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';

import { useDividerStyles } from './styles';

interface Props {
    className?: string;
}

function Divider({ className }: Props): JSX.Element {
    const styles = useDividerStyles();

    const dividerClass = classNames(styles.root, className);

    return <DividerComponent className={dividerClass} />;
}

const MemoizedDividerComponent = memo(Divider) as typeof Divider;

export default memo(MemoizedDividerComponent);
