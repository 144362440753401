import { fade, makeStyles } from '@material-ui/core';

export const useDesktopSolutionsSidebarStyles = makeStyles((theme) => ({
    root: {
        width: 250,
        flexShrink: 0,
        height: 'calc(100vh - 56px)',
        overflowY: 'auto',
    },
    title: {
        padding: theme.spacing(2, 2, 0, 2),
    },
    heading: {
        '&:not(:first-child)': {
            marginTop: theme.spacing(3),
        },
    },
    tooltip: {
        width: '100%',
    },
    integrationItem: {
        display: 'flex',
        padding: theme.spacing(1.25, 2),
        textDecoration: 'none',

        '&:hover': {
            backgroundColor: fade(theme.palette.common.gray5, 0.9),
            textDecoration: 'none',
            outline: 'none',
        },
    },
    integrationItemActive: {
        backgroundColor: fade(theme.palette.primary.main, 0.2),

        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.2),
        },
    },
    text: {
        userSelect: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    noMargins: {
        margin: '0px',
        padding: '0px',
    },
    backButton: {
        padding: theme.spacing(1.5, 2),
        marginBottom: 0,
    },
    dividerLine: {
        margin: theme.spacing(0, 2),
    },
}));
