import isEqual from 'lodash/isEqual';
import moment from 'moment';

export const areSensorsOrFoldersChanged = (initialValues, values) => {
    const sensorsChanged = !isEqual(
        initialValues?.sensors?.sort(),
        values?.sensors?.sort(),
    );

    const foldersChanged = !isEqual(
        initialValues?.folders?.sort(),
        values?.folders?.sort(),
    );

    return sensorsChanged || foldersChanged;
};

export const isConfigurationChanged = (initialValues = {}, values = {}) => {
    const { dateRange: initialDateRange } = initialValues;
    const { dateRange } = values;

    const sensorsOrFoldersChanged = areSensorsOrFoldersChanged(initialValues, values);

    const displayOptionsChanged = !isEqual(
        initialValues?.displayOptions,
        values?.displayOptions,
    );

    const stoplightChanged = !isEqual(initialValues?.stoplight, values?.stoplight);

    const dateRangeChanged =
        initialDateRange?.excludeWeekends !== dateRange?.excludeWeekends ||
        initialDateRange?.onlyWorkHours !== dateRange?.onlyWorkHours ||
        initialDateRange?.workHoursFrom !== dateRange?.workHoursFrom ||
        initialDateRange?.workHoursTo !== dateRange?.workHoursTo ||
        initialDateRange?.timezone !== dateRange?.timezone ||
        initialDateRange?.period !== dateRange?.period ||
        !moment(initialDateRange.startDate).isSame(dateRange?.startDate) ||
        !moment(initialDateRange.endDate).isSame(dateRange?.endDate);

    return (
        sensorsOrFoldersChanged ||
        dateRangeChanged ||
        displayOptionsChanged ||
        stoplightChanged
    );
};
