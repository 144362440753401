import type { PopoverPosition } from '@blueprintjs/core';
import { Classes, Menu, OverflowList, Popover, Position } from '@blueprintjs/core';
import classNames from 'classnames';
import { useCallback } from 'react';

import Breadcrumb from './Breadcrumb';
import type { BreadcrumbProps } from './types';

interface PopoverProps {
    position: PopoverPosition;
}

interface BreadcrumbsClasses {
    breadcrumbs: string;
    popover: string;
}

interface BreadcrumbsProps {
    items?: BreadcrumbProps[];
    popoverProps?: PopoverProps;
    classes?: BreadcrumbsClasses;
}

/**
 * Breadcrumbs - Custom analog of BP Breadcrumbs component that uses react-router <Link />
 */
const Breadcrumbs = ({
    items = [],
    popoverProps = { position: Position.BOTTOM_LEFT },
    classes = { breadcrumbs: '', popover: '' },
}: BreadcrumbsProps): JSX.Element | null => {
    const popoverClass = classes?.popover;
    const renderOverflowBreadcrumb = useCallback(
        (props) => (
            <Breadcrumb
                className="bp4-menu-item bp4-text-overflow-ellipsis"
                key={props.id} // eslint-disable-line react/prop-types
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            />
        ),
        [],
    );
    const overflowRenderer = useCallback(
        (overFlowItems) => (
            <li>
                <Popover
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...popoverProps}
                >
                    <span
                        className={classNames(
                            Classes.BREADCRUMBS_COLLAPSED,
                            popoverClass,
                        )}
                    />
                    <Menu className="mw-90vw">
                        {overFlowItems.map(renderOverflowBreadcrumb)}
                    </Menu>
                </Popover>
            </li>
        ),
        [renderOverflowBreadcrumb, popoverProps, popoverClass],
    );

    if (!items || !items.length) {
        return null;
    }

    return (
        <OverflowList
            className={classNames(Classes.BREADCRUMBS, classes?.breadcrumbs)}
            items={items}
            visibleItemRenderer={Breadcrumb}
            overflowRenderer={overflowRenderer}
        />
    );
};

export default Breadcrumbs;
