import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type {
    LegionnaireSubtypes,
    SensorType,
    SensorVendor,
    SensorShape,
} from '@infogrid/sensors-constants';
import { useTranslation } from 'react-i18next';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

import { controllers } from '../controllers';

export interface DeviceConfiguration {
    pipe_type?: LegionnaireSubtypes;
}

export interface InstallSensorParams {
    planned_sensor_id: string;
    name: string;
    location: {
        building_id: number | null;
        floor_id: number | null;
        space_id: number | null;
        space_b_id?: number | null;
    };
    position: {
        x: number;
        y: number;
    };
    position_b?: {
        x: number;
        y: number;
    };
    device: {
        sensor_id: number;
    };
    sensor_type: SensorType;
    labels: { id: number }[];
    configuration?: DeviceConfiguration;
}

export interface InstallSensorResult extends InstallSensorParams {
    installed_at: string;
    installed_by: {
        id: number;
    };
}

export interface InstallSensorErrorResponse {
    error_code: string;
    user_title: string;
    user_message: string;
    can_retry?: boolean;
}

// Convert the response from the backend to a SensorShape
// This can be removed when the API call returns a SensorShape
const convertResponseToSensor = (
    response: InstallSensorResult,
    identifiedSensor: SensorShape,
): SensorShape => {
    // Pass through the event data if the sensor being
    // installed is the same type as the one we scanned
    const latest_events_v2 =
        identifiedSensor.type_code === response.sensor_type
            ? identifiedSensor.latest_events_v2
            : {};

    return {
        id: response.device.sensor_id,
        device_name: response.name,
        floorplan_location: {
            building_id: response.location.building_id || undefined,
            floor_id: response.location.floor_id || undefined,
            space_id: response.location.space_id || undefined,
        },
        reading_types: [],
        type: response.sensor_type, // No nice type name available
        type_code: response.sensor_type,
        profile: {
            id: response.device.sensor_id,
            name: response.name,
            uuid: response.planned_sensor_id,
            labels: [],
            sensor_labels: [],
            description: '',
        },
        // Misc stuff required by TS
        can_edit: false,
        is_mapped_to_floor: true,
        sensor_state_tooltip: '',
        smart_cleaning_category: '',
        vendor: '' as SensorVendor,
        cleaning_status_updated: '',
        latest_events_v2,
    };
};

// Custom options type because we’re calling onSuccess with a SensorShape, not an InstallSensorResult
// This can be removed when the API call returns a SensorShape
type InstallSensorOptions = Omit<
    UseMutationOptions<
        InstallSensorResult,
        AxiosParsedError<InstallSensorErrorResponse>,
        InstallSensorParams
    >,
    'onSuccess'
> & { onSuccess: (sensor: SensorShape) => void };

export const useInstallSensor = (
    options: InstallSensorOptions,
    identifiedSensor: SensorShape,
): UseMutationResult<
    InstallSensorResult,
    AxiosParsedError<InstallSensorErrorResponse>,
    InstallSensorParams
> => {
    const { t } = useTranslation('sensors');

    const mutation = useMutationWithToast<
        InstallSensorResult,
        AxiosParsedError<InstallSensorErrorResponse>,
        InstallSensorParams
    >(
        (data) =>
            wrapResponsePromise<InstallSensorResult>(controllers.installSensor({ data })),
        {
            ...options,
            onSuccess: (data) =>
                options.onSuccess?.(convertResponseToSensor(data, identifiedSensor)),
        },
        { toastMessage: t('Installing sensor is in progress') },
    );

    return mutation;
};
