import { Button } from '@blueprintjs/core';
import { PageError } from '@infogrid/components-material-ui';
import type { ErrorResponse } from '@infogrid/core-ducks';
import type { ErrorInfo } from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export interface ErrorComponentProps {
    error: ErrorResponse;
    errorInfo?: ErrorInfo;
    resetError?: () => void;
}

const ErrorComponent = ({
    error,
    errorInfo,
    resetError,
}: ErrorComponentProps): JSX.Element => {
    const history = useHistory();

    const onReload = useCallback(() => {
        window.location.reload();
    }, []);

    const onResetError = useCallback(() => {
        if (resetError) {
            resetError();
        }
    }, [resetError]);

    const onHistoryBack = useCallback(() => {
        history.goBack();
    }, [history]);

    return (
        <PageError statusCode={error.statusCode || 500}>
            {process.env.NODE_ENV !== 'production' ? (
                <>
                    <div>{error.message}</div>
                    {error.responseText ? (
                        <pre className="pre-scrollable small">{error.responseText}</pre>
                    ) : null}
                    {errorInfo ? <pre>{errorInfo.componentStack}</pre> : null}
                    <pre>{error.stack}</pre>
                </>
            ) : null}
            <Button onClick={onHistoryBack}>Go back</Button>
            &nbsp;or&nbsp;
            {resetError ? (
                <Button onClick={onResetError}>Try again</Button>
            ) : (
                <Button onClick={onReload}>Reload the page</Button>
            )}
        </PageError>
    );
};

export default ErrorComponent;
