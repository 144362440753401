import {
    Icon,
    Card,
    Button,
    Typography,
    CardContent,
    CircularProgress,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useStylesUploadCardContent } from './styles';

const SUPPORTED_FORMATS = 'jpeg, png, pdf'.toUpperCase();

const UploadCardContent = ({
    openFileDialog,
    isUploading,
    onCancelClick,
    onTryAgainClick,
    disabled,
    error,
}) => {
    const cardStyle = useStylesUploadCardContent();

    const { t } = useTranslation('floorplan');

    return (
        <Card className={cardStyle.card}>
            <CardContent className={classNames(cardStyle.uploadCardContent)}>
                {isUploading && (
                    <>
                        <div className={cardStyle.progressBarContainer}>
                            <CircularProgress
                                className={cardStyle.progressBar}
                                size={56}
                            />
                            <Typography
                                className={cardStyle.greySubtitleText}
                                variant="subtitle1"
                            >
                                {t('Uploading')}
                            </Typography>
                        </div>

                        <Button color="primary" onClick={onCancelClick}>
                            {t('Cancel', { ns: 'common' })}
                        </Button>
                    </>
                )}

                {!!error && (
                    <>
                        <div className={cardStyle.errorIconContainer}>
                            <Icon
                                className={classNames(
                                    'fal fa-exclamation-triangle',
                                    cardStyle.errorIcon,
                                )}
                            />
                            <Typography variant="h6" component="h6">
                                {t(error.error)}
                            </Typography>
                            <Typography
                                className={cardStyle.greySubtitleText}
                                variant="subtitle1"
                                data-cypress="uploading-error"
                            >
                                {t(error.description)}
                            </Typography>
                        </div>

                        <Button
                            color="primary"
                            onClick={onTryAgainClick}
                            data-cypress="select-file-button"
                        >
                            {t('Select Another File')}
                        </Button>
                    </>
                )}

                {!isUploading && !error && (
                    <>
                        <div className={cardStyle.uploadIconContainer}>
                            <Icon
                                className={classNames(
                                    'fal fa-arrow-to-top',
                                    cardStyle.uploadIcon,
                                )}
                            />
                        </div>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={openFileDialog}
                            disabled={disabled}
                            data-cypress="upload-button"
                        >
                            {t('Upload Floor Plan')}
                        </Button>

                        <section className={cardStyle.uploadDescriptionContainer}>
                            <Typography
                                className={cardStyle.greySubtitleText}
                                variant="body1"
                                data-cypress="supported-formats"
                            >
                                {`${t('Supported formats')}: ${SUPPORTED_FORMATS}`}
                            </Typography>

                            <Typography
                                className={cardStyle.greySubtitleText}
                                variant="body1"
                                data-cypress="file-size"
                            >
                                {`${t('max. file size')}: 10MB`}
                            </Typography>
                        </section>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

UploadCardContent.propTypes = {
    openFileDialog: PropTypes.func,
    isUploading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.shape({
        error: PropTypes.string,
        description: PropTypes.string,
    }),
    onCancelClick: PropTypes.func.isRequired,
    onTryAgainClick: PropTypes.func.isRequired,
};

UploadCardContent.defaultProps = {
    error: null,
    disabled: false,
    openFileDialog: null,
};

export default memo(UploadCardContent);
