import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from './controller';
import { getSmartCleaningActivatedCount } from './getQueryKeys';
import type {
    SmartCleaningActivatedCountResponse,
    SmartCleaningActivatedParams,
} from './types';

export const useGetSmartCleaningActivatedCount = (
    params: SmartCleaningActivatedParams,
    options?: UseQueryOptions<SmartCleaningActivatedCountResponse, AxiosParsedError>,
): UseQueryResult<SmartCleaningActivatedCountResponse, AxiosParsedError> => {
    return useQuery(
        getSmartCleaningActivatedCount(params),
        () => wrapResponsePromise(controllers.getSmartCleaningActivatedCount(params)),
        options,
    );
};
