import classNames from 'classnames';
import type { ReactNode } from 'react';
import { forwardRef, memo } from 'react';
import type { Layout } from 'react-grid-layout';

import { useWidgetContentStyles } from './styles';

interface WidgetContentProps {
    children?: ReactNode;
    className?: string;
    layout?: Layout;
}

const WidgetContent = forwardRef<HTMLDivElement, WidgetContentProps>(
    ({ children, className, layout }: WidgetContentProps, ref) => {
        const styles = useWidgetContentStyles({ layout });

        return (
            <div
                ref={ref}
                className={classNames(styles.root, className)}
                data-cypress="widget-content"
                data-testid="widget-content"
            >
                {children}
            </div>
        );
    },
);

export default memo(WidgetContent);
