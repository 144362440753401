import { Modal } from '@infogrid/components-material-ui';
import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import type { UrlParams } from '@infogrid/core-routing';
import { routesManager } from '@infogrid/core-routing';
import { Button } from '@material-ui/core';
import { push } from 'connected-react-router';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFloorPlanParams } from 'apiHooks/base/hooks';
import { useConvertSubfolders } from 'apiHooks/floorPlan/folders/hooks';
import ButtonSpinner from 'components/ButtonSpinner';
import {
    toggleCreatingFloor,
    selectCreatingFloorStatus,
    toggleCreatingFolder,
} from 'ducks/floorPlan';

import ConvertFolder from './ConvertFolder';
import { useCreatingFloorStyles } from './styles';

const ConvertFolderModal = () => {
    const { t } = useTranslation('floorplan');

    const dispatch = useAppDispatch();
    const stylesCreatingBuilding = useCreatingFloorStyles();

    const isOpened = useAppSelector(selectCreatingFloorStatus);

    const { buildingId } = useFloorPlanParams();

    const { mutate } = useConvertSubfolders({ buildingId });

    const onClose = useCallback(() => {
        dispatch(toggleCreatingFloor(false));
    }, [dispatch]);

    const openCreateFolder = useCallback(() => {
        dispatch(toggleCreatingFolder(true));
    }, [dispatch]);

    const classes = useMemo(
        () => ({
            container: stylesCreatingBuilding.container,
        }),
        [stylesCreatingBuilding.container],
    );

    const handleSubfoldersConverting = useCallback(
        (_submitValues, { setSubmitting }, selectedFolders) => {
            const data = {
                building: buildingId,
                folders: selectedFolders,
            };

            mutate(
                { data },
                {
                    onSuccess() {
                        onClose();

                        const redirectToId = data.folders[0];

                        dispatch(
                            push(
                                routesManager.resolvePath('buildings:floor', {
                                    buildingId,
                                    floorId: redirectToId,
                                    /* Type assertion below required as null isn't valid in types
                                     * but is how it worked before */
                                } as UrlParams),
                            ),
                        );
                    },
                    onSettled() {
                        setSubmitting(false);
                    },
                },
            );
        },
        [buildingId, dispatch, mutate, onClose],
    );

    const renderCreatingFloorActions = useCallback(
        ({ isSubmitting, selectedFoldersIds }) => (
            <Modal.Actions justify="space-between">
                <Button onClick={onClose} color="primary" variant="text">
                    {t('Close', { ns: 'common' })}
                </Button>

                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    data-cypress="next"
                    disabled={isSubmitting || !selectedFoldersIds.length}
                >
                    {isSubmitting && <ButtonSpinner />}

                    <span>{t('Convert')}</span>
                </Button>
            </Modal.Actions>
        ),
        [onClose, t],
    );

    return (
        <Modal onClose={onClose} open={isOpened} classes={classes}>
            <ConvertFolder
                buildingId={buildingId}
                title={t('Create New Floor')}
                openCreateFolder={openCreateFolder}
                renderActions={renderCreatingFloorActions}
                handleSubfoldersConverting={handleSubfoldersConverting}
            />
        </Modal>
    );
};

export default memo(ConvertFolderModal);
