import type { SensorType } from '@infogrid/sensors-constants';
import type { QueryKey } from 'react-query';

import {
    FLOORS_KEY,
    FLOORS_LIST_KEY,
    SMART_CLEANING_HISTORY_KEY,
    LENS_SENSORS_KEY,
    LENS_FLOORS_KEY,
} from 'apiHooks/base';

export const getFloorsKey = (buildingId?: number | null): QueryKey => [
    buildingId,
    FLOORS_LIST_KEY,
];
export const getFloorDetailKey = (floorId?: number): QueryKey => [FLOORS_KEY, floorId];

export const getSmartCleaningHistoryKey = (floorId: number): QueryKey => [
    SMART_CLEANING_HISTORY_KEY,
    floorId,
];

export const getLensSensorsKey = (floorId?: number): QueryKey => [
    LENS_SENSORS_KEY,
    floorId,
];

export const getLensFloorsKey = (
    buildingId?: number | null,
    params?: { sensor_type?: SensorType[] },
): QueryKey => [LENS_FLOORS_KEY, buildingId, params];
