import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { RouteConfigComponentProps } from 'react-router-config';

import PageContainer from 'components/PageContainer';
import withView from 'decorators/withView';
import type { SmartCleaningReport } from 'views/solutionSettings/apiHooks/types';
import { useGetSmartCleaningReports } from 'views/solutionSettings/apiHooks/useGetSmartCleaningReports';

import { BuildingSection } from './components';
import ReportConfigurationModal from './components/Modals/ReportConfigurationModal';
import { useReportsStyles } from './styles';

interface Props {
    route: RouteConfigComponentProps['route'];
}

type Modal = { isOpen: boolean; reportId?: number; buildingId?: number };

const Reports = ({ route }: Props) => {
    const { t } = useTranslation('solutions');

    const styles = useReportsStyles();

    const [modalState, setModalState] = useState<Modal>({ isOpen: false });

    const { data: reportsResponse, isLoading: reportsIsLoading } =
        useGetSmartCleaningReports();
    const buildings = reportsResponse || [];

    const reports = buildings.map((building) => building.building_reports).flat();
    const currentReport = reports.find((report) => modalState?.reportId === report.id) as
        | SmartCleaningReport
        | undefined;

    return (
        <>
            <Helmet defaultTitle={t(route?.pageName || 'Manage solutions')} />

            <PageContainer className={styles.container}>
                <div className={styles.topContainer}>
                    <Box
                        display="flex"
                        alignItems="center"
                        className={styles.titleContainer}
                    >
                        <Box flexGrow={1}>
                            <Typography
                                variant="h6"
                                data-cypress="solution-setup-reports-title"
                            >
                                {t('Schedule reports')}
                            </Typography>
                        </Box>
                        <Box ml={2} flexWrap="nowrap" display="flex" alignItems="center">
                            <Button
                                type="submit"
                                disabled={false}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    setModalState({ isOpen: true });
                                }}
                                data-cypress="create-report"
                            >
                                {t('New Report')}
                            </Button>
                        </Box>
                    </Box>
                    <Typography
                        className={styles.description}
                        color="textSecondary"
                        variant="body2"
                    >
                        {t(
                            'Cleaning reports provide your teams with the information they need to clean based on desk or space usage. Reports are sent as an email summary with a PDF attachment to the recipients you choose.',
                        )}
                    </Typography>
                </div>
                {!reportsIsLoading ? (
                    <div className={styles.buildingContainer}>
                        {buildings.map((building) => (
                            <BuildingSection
                                key={building.building_id}
                                building={building}
                                onEdit={(reportId: number) => {
                                    setModalState({
                                        isOpen: true,
                                        reportId,
                                        buildingId: building.building_id,
                                    });
                                }}
                            />
                        ))}
                    </div>
                ) : (
                    <CircularProgress
                        className={styles.loader}
                        color="primary"
                        size={30}
                    />
                )}
            </PageContainer>

            <ReportConfigurationModal
                open={modalState.isOpen}
                onClose={() => setModalState({ isOpen: false })}
                previousReport={currentReport}
                buildingId={modalState?.buildingId}
            />
        </>
    );
};

export default withView()(Reports);
