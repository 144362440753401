import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import { applicationSelectors, Constance, useAppDispatch } from '@infogrid/core-ducks';
import type {
    FloorDetail,
    FloorPlanLocation,
    SpaceType,
} from '@infogrid/locations-types';
import type { MappableSensor, SensorType } from '@infogrid/sensors-constants';
import { useIsMobile, useSelectorWithArgs } from '@infogrid/utils-hooks';
import { Icon, Button, Tooltip, Slider } from '@material-ui/core';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { memo, useCallback, useMemo, lazy, Suspense, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { useSensorsLiveUpdate } from 'apiHooks/floorPlan/sensors/hooks';
import SensorsChecklist from 'components/SensorsChecklist';
import { MapHints } from 'components/floorPlans';
import type { BaseControls } from 'components/floorPlans/types';
import { toggleEditFloorImage, toggleReplaceFloorImage } from 'ducks/floorPlan';

import { useStylesFloor } from './styles';

const QUESTION_ICON = 'far fa-question-circle';

const LegacyFloorMap = lazy(
    () =>
        import(
            /* webpackChunkName: "LegacyFloorMap", webpackPreload: true */ 'components/floorPlans/LegacyFloorMap'
        ),
);

const sensorTypesConfig = {
    keyExtractor: (item: { key: SensorType }) => item.key,
    valueExtractor: (item: { name: string }) => item.name,
};

const MAP_BASE_CONTROLS: BaseControls[] = [
    'ZOOM_IN',
    'ZOOM_OUT',
    'CENTER',
    'FULL_SCREEN',
    'EXPORT',
    'REPLACE_IMAGE',
    'EDIT_IMAGE',
];

const VIEW_ONLY_MAP_BASE_CONTROLS: BaseControls[] = [
    'ZOOM_IN',
    'ZOOM_OUT',
    'CENTER',
    'FULL_SCREEN',
    'EXPORT',
];

export interface FloorPlanMapProps {
    floorId: number;
    floorImage: string;
    widthResolution: number;
    heightResolution: number;
    mappedSensors: MappableSensor<{
        id: string | number;
        device_name: string;
    }>[];
    isEditAvailable?: boolean;
    floor: FloorDetail;
    onZoomChange?: (lvl: number) => void;
    onRotationChange?: (rotation: number) => void;
    deleteSensorFromMap: (deviceName: string) => void;
    addSensorOnMap: (deviceName: string, coordinates: FloorPlanLocation) => void;
    changeSensorPosition: (deviceName: string, coordinates: FloorPlanLocation) => void;
    sensorsTypes: {
        key: SensorType;
        name: string;
    }[];
    activeSensorTypes: SensorType[];
    changeActiveSensorTypes: (types: SensorType[]) => void;
    // Can be string or number as currently we use numeric IDs for installed sensors and string UUIDs for
    // planned sensors.
    activeSensorDeviceId?: string | number;
    onSensorSelect: (id: string | number | null) => void;
    changeSensorIconScale: (scale: string) => void;
    isSensorMoveEnabled?: boolean;
    setIsSensorMoveEnabled: (enabled: boolean) => void;
    spaceTypes: SpaceType[];
}

const FloorPlanMap = ({
    floorId,
    floorImage,
    floor,
    heightResolution,
    widthResolution,
    mappedSensors,
    isEditAvailable,
    onZoomChange = noop,
    onRotationChange = noop,
    deleteSensorFromMap,
    addSensorOnMap,
    changeSensorPosition,
    sensorsTypes,
    activeSensorTypes,
    changeActiveSensorTypes,
    activeSensorDeviceId,
    onSensorSelect,
    changeSensorIconScale,
    isSensorMoveEnabled,
    setIsSensorMoveEnabled,
    spaceTypes,
}: FloorPlanMapProps) => {
    const { t } = useTranslation('floorplan');

    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();

    const openReplaceFloorImage = useCallback(() => {
        dispatch(toggleReplaceFloorImage(true));
    }, [dispatch]);

    const openEditFloorImage = useCallback(() => {
        dispatch(toggleEditFloorImage(true));
    }, [dispatch]);

    const styles = useStylesFloor();
    const containerRef = useRef(null);

    const [scale, setScale] = useState(floor.scale);
    const [zoom, setZoom] = useState(floor.zoom);
    const [orientation, setOrientation] = useState(floor.orientation);

    const livePollingSeconds = useSelectorWithArgs(
        // @ts-expect-error typing needs to be refactored
        applicationSelectors.getConstanceValue,
        Constance.POLL_EVENTS_IN_FRONTEND,
    ) as number | undefined;

    const livePollingDelay = (livePollingSeconds || 10) * 1000;

    useSensorsLiveUpdate({ floorId }, { delay: livePollingDelay });

    const matchedSensor = useMemo(() => {
        return mappedSensors.find((sensor) => sensor.id === activeSensorDeviceId);
    }, [activeSensorDeviceId, mappedSensors]);

    const toggleSensorOption = useCallback(
        (key) => {
            const isAlreadyChecked = activeSensorTypes.includes(key);

            const types = isAlreadyChecked
                ? activeSensorTypes.filter((e) => e !== key)
                : [...activeSensorTypes, key];

            changeActiveSensorTypes(types);
        },
        [changeActiveSensorTypes, activeSensorTypes],
    );

    const toggleAllSensorsOptions = useCallback(
        (checkAll) => {
            const types = checkAll
                ? sensorsTypes.map(sensorTypesConfig.keyExtractor)
                : [];

            changeActiveSensorTypes(types);
        },
        [sensorsTypes, changeActiveSensorTypes],
    );

    const deleteActiveSensor = useCallback(() => {
        if (isEditAvailable) {
            if (matchedSensor) {
                deleteSensorFromMap(matchedSensor.device_name);
            }

            onSensorSelect(null);
        }
    }, [isEditAvailable, deleteSensorFromMap, matchedSensor, onSensorSelect]);

    const dropSensorOnMap = useCallback(
        (dropEvent, coordinates) => {
            if (isEditAvailable) {
                const sensorDeviceId = dropEvent.dataTransfer.getData('sensorDeviceId');

                if (sensorDeviceId) {
                    addSensorOnMap(sensorDeviceId, coordinates);
                }
            }
        },
        [isEditAvailable, addSensorOnMap],
    );

    const changeSensorCoordinate = useCallback(
        (sensorId, coordinates) => {
            if (isEditAvailable) {
                changeSensorPosition(sensorId, coordinates);
            }
        },
        [isEditAvailable, changeSensorPosition],
    );

    const zoomChange = useCallback(
        (lvl) => {
            setZoom(lvl);

            if (isEditAvailable) {
                onZoomChange(lvl);
            }
        },
        [isEditAvailable, onZoomChange],
    );

    const changeRotation = useCallback(
        (rotation) => {
            setOrientation(rotation);

            if (isEditAvailable) {
                onRotationChange(rotation);
            }
        },
        [isEditAvailable, onRotationChange],
    );

    const checkedSensorTypes = useMemo(() => {
        const types = sensorsTypes.map((x) => x.key);

        return activeSensorTypes.filter((type) => types.includes(type));
    }, [sensorsTypes, activeSensorTypes]);

    const enableSensorMove = useCallback(
        () => setIsSensorMoveEnabled(true),
        [setIsSensorMoveEnabled],
    );

    const [changeScale] = useDebouncedCallback((newValue) => {
        if (isEditAvailable) {
            changeSensorIconScale(newValue);
        }
    }, 500);

    const sensorsChecklistClasses = useMemo(
        () => ({ itemsList: styles.sensorsChecklist }),
        [styles.sensorsChecklist],
    );

    const controlsProps = useMemo(() => {
        return {
            enabled: !isMobile,
            baseControls: isEditAvailable
                ? MAP_BASE_CONTROLS
                : VIEW_ONLY_MAP_BASE_CONTROLS,
            onReplaceMapImage: openReplaceFloorImage,
            onEditImage: openEditFloorImage,
            customControls: (
                <div className={styles.mapCustomControlsContainer}>
                    {!!sensorsTypes.length && (
                        <SensorsChecklist
                            classes={sensorsChecklistClasses}
                            sensorTypes={sensorsTypes}
                            checkedSensorTypes={checkedSensorTypes}
                            toggleSensorTypeHandler={toggleSensorOption}
                            toggleAllSensorsTypesHandler={toggleAllSensorsOptions}
                            keyExtractor={sensorTypesConfig.keyExtractor}
                            valueExtractor={sensorTypesConfig.valueExtractor}
                        />
                    )}

                    <div className={styles.mapCustomControlsBtns}>
                        {isEditAvailable &&
                            activeSensorDeviceId &&
                            !matchedSensor?.is_planned && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className={styles.deleteSensorBtn}
                                    onClick={deleteActiveSensor}
                                    data-cypress="remove-sensor"
                                >
                                    {t('Remove sensor')}
                                </Button>
                            )}
                        {isEditAvailable &&
                            activeSensorDeviceId &&
                            !matchedSensor?.is_planned && (
                                <Button
                                    disabled={isSensorMoveEnabled}
                                    variant="outlined"
                                    color="primary"
                                    className={styles.deleteSensorBtn}
                                    onClick={enableSensorMove}
                                    data-cypress="move-sensor"
                                >
                                    {t('Move sensor')}
                                </Button>
                            )}
                        <Tooltip
                            arrow
                            PopperProps={{
                                container: containerRef.current,
                                disablePortal: true,
                            }}
                            leaveDelay={0}
                            title={<MapHints />}
                        >
                            <Icon
                                data-cypress="question-icon"
                                className={classNames(
                                    QUESTION_ICON,
                                    styles.controlIcon,
                                    styles.hintsIcon,
                                )}
                            />
                        </Tooltip>

                        {!!mappedSensors.length && (
                            <Slider
                                className={styles.sensorIconSizeSlider}
                                value={scale}
                                data-cypress="sensor-slider"
                                onChange={(_, newValue) => {
                                    setScale(newValue as number);
                                    changeScale(newValue);
                                }}
                                min={0.3}
                                max={3}
                                step={0.1}
                            />
                        )}
                    </div>
                </div>
            ),
        };
    }, [
        activeSensorDeviceId,
        changeScale,
        checkedSensorTypes,
        deleteActiveSensor,
        enableSensorMove,
        isEditAvailable,
        isSensorMoveEnabled,
        matchedSensor,
        openEditFloorImage,
        openReplaceFloorImage,
        scale,
        sensorsChecklistClasses,
        sensorsTypes,
        styles.controlIcon,
        styles.deleteSensorBtn,
        styles.hintsIcon,
        styles.mapCustomControlsBtns,
        styles.mapCustomControlsContainer,
        styles.sensorIconSizeSlider,
        t,
        toggleAllSensorsOptions,
        toggleSensorOption,
        mappedSensors,
        isMobile,
    ]);

    return (
        <div className={styles.floor}>
            <div className={styles.mapContainer} ref={containerRef}>
                <Suspense
                    fallback={
                        <LoadingPlaceholder
                            classes={{
                                container: styles.mapLoaderContainer,
                                title: styles.mapLoaderText,
                            }}
                            titleVariant="body1"
                            progressSize={56}
                            text={t('Loading Map...')}
                        />
                    }
                >
                    <LegacyFloorMap
                        imageUrl={floorImage}
                        imageWidth={widthResolution}
                        imageHeight={heightResolution}
                        onZoomChange={zoomChange}
                        onRotationChange={changeRotation}
                        zoom={zoom}
                        rotation={orientation}
                        activeSensorDeviceId={activeSensorDeviceId}
                        sensors={mappedSensors}
                        isDragSensorAvailable={isEditAvailable}
                        isAddSensorAvailable={isEditAvailable}
                        isSensorMoveEnabled={isSensorMoveEnabled}
                        setIsSensorMoveEnabled={setIsSensorMoveEnabled}
                        isSelectSensorAvailable
                        onSensorSelect={onSensorSelect}
                        onDropSensor={dropSensorOnMap}
                        onChangeSensorCoordinate={changeSensorCoordinate}
                        controlsProps={controlsProps}
                        featureScale={scale}
                        spaceTypes={spaceTypes}
                    />
                </Suspense>
            </div>
        </div>
    );
};

export default memo(FloorPlanMap);
