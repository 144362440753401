import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import type { SmartCleaningBuilding } from 'views/solutionSettings/apiHooks/types';

import { ReportCard } from './ReportCard';
import {
    useAccordionDetailsStyles,
    useAccordionStyles,
    useAccordionSummaryStyles,
    useBuildingSectionStyles,
} from './styles';

interface ReportCardProps {
    building: SmartCleaningBuilding;
    onEdit: (reportId: number) => void;
}

export const BuildingSection = ({ building, onEdit }: ReportCardProps): JSX.Element => {
    const styles = useBuildingSectionStyles();
    const accordionStyles = useAccordionStyles();
    const accordionDetailStyles = useAccordionDetailsStyles();
    const summaryStyles = useAccordionSummaryStyles();

    const hasReports = building.building_reports.length > 0;

    return (
        <Accordion
            classes={accordionStyles}
            key={building.building_id}
            data-cypress="building-section"
            disabled={!hasReports}
        >
            <AccordionSummary
                classes={summaryStyles}
                expandIcon={
                    hasReports && (
                        <ExpandMoreIcon
                            data-testid="expand-more-icon"
                            className={styles.expandMoreIcon}
                        />
                    )
                }
            >
                <Typography className={styles.title}>
                    {building.building_name} ({building.building_reports.length})
                </Typography>
            </AccordionSummary>
            <AccordionDetails classes={accordionDetailStyles}>
                <Box className={styles.accordionDetails}>
                    {building.building_reports.map((report) => (
                        <ReportCard
                            key={report.id}
                            report={report}
                            onEdit={() => {
                                onEdit(report.id);
                            }}
                        />
                    ))}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};
