import { useAppDispatch, toastError } from '@infogrid/core-ducks';
import { useCallback } from 'react';

import { extractNestedObject } from 'utils/extractNestedObject';
import { getFieldsList } from 'utils/getFieldsList';

export const useFormToast = (
    { message = 'Something went wrong' } = { message: 'Something went wrong' },
) => {
    const dispatch = useAppDispatch();

    const triggerErrorToast = useCallback(
        ({ message: customMessage } = {}) => {
            dispatch(toastError({ message: customMessage || message }));
        },
        [dispatch, message],
    );

    // Check if error contain fields are the same as in submitValues
    // If contains means server returned predictable error with fields user sent
    // Else it triggers default toast message that something wrong;
    const handleFormToast = useCallback(
        ({ formCallback, submitValues, error }) => {
            if (!error || !formCallback) {
                triggerErrorToast();
            }

            const extractedError = extractNestedObject(error);
            const submitFields = getFieldsList(submitValues);
            const errorFields = getFieldsList(error);

            const resultFields = [...submitFields, ...errorFields];
            const isFieldsContains = new Set(resultFields).size !== resultFields.length;

            if (isFieldsContains) {
                formCallback(error, extractedError);
            } else {
                triggerErrorToast();
            }
        },
        [triggerErrorToast],
    );

    return {
        triggerErrorToast,
        handleFormToast,
    };
};
