import { entitiesActions, EntityStatus, entitiesSelectors } from '@thorgate/spa-entities';
import { getLocation } from 'connected-react-router';
import Cache from 'lru-cache';
import { all, call, put, select } from 'redux-saga/effects';

const cache = new Cache({
    max: 100,
    maxAge: 1000 * 60 * 60,
});

export const invalidateInitialCache = (key = null) => {
    if (key instanceof RegExp) {
        cache.keys().forEach((cacheKey) => {
            if (key.test(cacheKey)) {
                cache.del(cacheKey);
            }
        });
    } else if (key) {
        cache.del(key);
    } else {
        cache.reset();
    }
};

const isStatusLoaded = (status) =>
    status !== EntityStatus.NotLoaded && status !== undefined;

function* setCollectionsNotLoaded(pattern = null) {
    const allStatuses = yield select(entitiesSelectors.selectStatuses);
    let statusEntriesToClear = [];

    if (typeof pattern === 'string') {
        const key = pattern;

        // should be taken into additional array to work correctly
        statusEntriesToClear = [[key, allStatuses[key]]];
    } else if (pattern instanceof RegExp) {
        statusEntriesToClear = Object.entries(allStatuses).filter(([key]) =>
            pattern.test(key),
        );
    } else if (pattern === null) {
        // Clear all
        statusEntriesToClear = Object.entries(allStatuses);
    } else {
        throw Error('pattern must be Regexp, string or null');
    }

    yield all(
        statusEntriesToClear
            .filter(([, status]) => isStatusLoaded(status)) // Ignore NonLoaded status
            .map(([key]) =>
                put(
                    entitiesActions.setEntitiesStatus({
                        key,
                        status: EntityStatus.NotLoaded,
                    }),
                ),
            ),
    );
}

/**
 * Invalidate cache for given pattern (string or regex).
 * This will:
 *  - call invalidateInitialCache (for fetchGuardInitial)
 *  - set entity collection status(es) to NotLoaded (if not already)
 * @param {RegExp|string|null} pattern - Regex pattern or string to match keys to invalidate.
 *                                       If this is null then everything will be cleared.
 */
export function* invalidateCache(pattern = null) {
    yield call(invalidateInitialCache, pattern);
    yield call(setCollectionsNotLoaded, pattern);
}

export function fetchGuardInitial(saga, keyFn) {
    return function* initial(match) {
        const location = yield select(getLocation);
        const key = yield call(keyFn, match, location);

        if (key && cache.has(key)) {
            return;
        }

        yield call(saga, match);

        if (key) {
            cache.set(key, true);
        }
    };
}
