import { useIsOpenState } from '@infogrid/utils-hooks';
import { Typography, Button } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { IconeIndex } from '../types';
import { getRiskMetadataByIconeIndex } from '../utils';
import Circle from './Circle';
import TutorialModal from './TutorialModal';
import { useContentStyles } from './styles';

interface Props {
    iconeIndex: IconeIndex;
}

const Content = ({ iconeIndex }: Props) => {
    const styles = useContentStyles();

    const { t } = useTranslation('dashboard');

    const [isTutorialModalOpen, handleTutorialModalClose, handleTutorialModalOpen] =
        useIsOpenState(false);

    const { color, label } = getRiskMetadataByIconeIndex(iconeIndex);

    return (
        <div className={styles.content}>
            <Typography color="textPrimary" variant="body2">
                {t('Last 24H')}
            </Typography>

            <Typography
                className={styles.riskLevelLabel}
                color="textPrimary"
                variant="h6"
            >
                {t(label)}
            </Typography>

            <div className={styles.circleContainer}>
                <Circle iconeIndex={iconeIndex} color={color} />
            </div>

            <Button className={styles.tutorialBtn} onClick={handleTutorialModalOpen}>
                {t('How does this work?')}
            </Button>

            <TutorialModal
                open={isTutorialModalOpen}
                onClose={handleTutorialModalClose}
            />
        </div>
    );
};

export default memo(Content);
