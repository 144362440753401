import {
    Box,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import type { ChangeEvent, FocusEvent } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import WebhookAuthMethodHelpText from './WebhookAuthMethodHelpText';
import {
    AUTH_METHOD_SECRET,
    AUTH_METHOD_SIGNATURE,
    useAuthMethod,
} from './eventsWebhookAuthMethods';
import { useWebhookAuthMethodFieldStyles } from './styles';

interface Props {
    name: string;
    canChange: boolean;
    value: boolean | undefined;
    stringValue: string;
    touched: boolean;
    error?: string;
    onChange: (e: ChangeEvent) => void;
    onBlur: (e: FocusEvent) => void;
}

const WebhookAuthMethodField = ({
    name,
    canChange,
    value,
    stringValue,
    touched,
    error,
    onChange,
    onBlur,
}: Props) => {
    const { t } = useTranslation('integrations');
    const styles = useWebhookAuthMethodFieldStyles();

    const authMethod = useAuthMethod(value);

    if (canChange) {
        return (
            <FormControl>
                <FormLabel
                    id={`${name}-group-label`}
                    classes={{ focused: styles.radioLabelFocused }}
                >
                    {t('Choose an authentication method')}
                </FormLabel>
                <RadioGroup
                    aria-labelledby={`${name}-group-label`}
                    value={stringValue}
                    onChange={onChange}
                    onBlur={onBlur}
                >
                    <Box mb={1}>
                        <FormControlLabel
                            value="false"
                            control={<Radio color="primary" />}
                            label={t(AUTH_METHOD_SECRET.label)}
                            classes={{ root: 'mb-0' }}
                            data-cypress="events-webhook-auth-method-false-radio"
                            data-testid="events-webhook-auth-method-false-radio"
                        />
                        <WebhookAuthMethodHelpText
                            value={false}
                            className={styles.authMethodHelpText}
                        />
                    </Box>
                    <Box>
                        <FormControlLabel
                            value="true"
                            control={<Radio color="primary" />}
                            label={t(AUTH_METHOD_SIGNATURE.label)}
                            classes={{ root: 'mb-0' }}
                            data-cypress="events-webhook-auth-method-true-radio"
                            data-testid="events-webhook-auth-method-true-radio"
                        />
                        <WebhookAuthMethodHelpText
                            value={true}
                            className={styles.authMethodHelpText}
                        />
                    </Box>
                </RadioGroup>
                {touched && error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
        );
    } else if (value !== undefined && authMethod !== undefined) {
        return (
            <>
                <Typography
                    variant="body1"
                    className={styles.authMethodLabel}
                    data-cypress="events-webhook-auth-method-label"
                    data-testid={`events-webhook-auth-method-label-${value}`}
                >
                    {t('Authentication method: {{authMethod}}', {
                        authMethod: t(authMethod.label),
                    })}
                </Typography>
                <WebhookAuthMethodHelpText
                    value={value}
                    className={styles.authMethodHelpText}
                />
            </>
        );
    }

    return null;
};

export default memo(WebhookAuthMethodField);
