import { Modal } from '@infogrid/components-material-ui';
import type { FloorSensorLocation } from '@infogrid/locations-types';
import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { Button } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import SensorsListItem from 'views/buildings/components/domain/Sensor/SensorsList/SensorsListItem/SensorsListItem';

import { useStylesSensorModal } from './styles';

interface ViewSensorModalSensor extends GenericSensorShape {
    id: string | number;
}

interface ViewSensorModalProps {
    isOpened: boolean;
    onClose: () => void;
    onRemoveSensor: (deviceName: string, coordinates?: FloorSensorLocation) => void;
    onMoveSensor: (state: boolean) => void;
    sensor: ViewSensorModalSensor;
}

const ViewSensorModal = ({
    isOpened,
    onClose,
    onMoveSensor,
    onRemoveSensor,
    sensor,
}: ViewSensorModalProps) => {
    const styles = useStylesSensorModal();

    const { t } = useTranslation('floorplan');

    const onClickRemove = () => {
        onRemoveSensor(sensor.device_name);
        onClose();
    };

    const onClickMove = () => {
        onMoveSensor(true);
    };

    return (
        <Modal onClose={onClose} open={isOpened}>
            <Modal.Content>
                <SensorsListItem className={styles.sensorItem} sensor={sensor} />
            </Modal.Content>
            <Modal.Actions justify="space-between">
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onClickRemove}
                    data-cypress="remove-sensor"
                >
                    {t('Remove sensor')}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClickMove}
                    data-cypress="move-sensor"
                >
                    {t('Move sensor')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(ViewSensorModal);
