import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type {
    SensorElectricityEventShape,
    SensorEventShape,
    SensorEventV2Shape,
    SensorType,
} from '@infogrid/sensors-constants';

const convertElectricityEventData = (
    event: SensorEventShape | SensorEventV2Shape,
): SensorElectricityEventShape | undefined => {
    // Type assertion to use electricity event type
    const electrictyEvent = event as SensorElectricityEventShape;

    return !!electrictyEvent?.kilowatt_hours?.toString()
        ? {
              ...electrictyEvent,
              kilowatt_hours: Number(electrictyEvent?.kilowatt_hours),
          }
        : undefined;
};

const convertMonnitHubEventData = (event: SensorEventV2Shape): SensorEventV2Shape => {
    const decimalValue = Number(event?.value);
    const percentageValue = decimalValue * 100;

    if (event.type === 'connection_signal') {
        return {
            ...event,
            value: percentageValue,
        };
    }

    return event;
};

/**
 * Match correct conversion function to sensor type
 */
const conversionHandlers: {
    [key: string]: (
        event: SensorEventShape | SensorEventV2Shape,
    ) => SensorEventShape | SensorEventV2Shape | undefined;
} = {
    [SENSOR_TYPE.TYPE_ELECTRICITY_DRY_PULSE_COUNTER]: convertElectricityEventData,
    [SENSOR_TYPE.TYPE_ELECTRICITY_CT_CLAMP]: convertElectricityEventData,
    [SENSOR_TYPE.TYPE_MONNIT_HUB]: (event) =>
        convertMonnitHubEventData(event as SensorEventV2Shape),
};

interface ConvertEventDataSensorArg {
    type_code: SensorType;
}

/**
 * Convert events data readings
 */
const convertEventData = (
    event: SensorEventShape | SensorEventV2Shape,
    sensor: ConvertEventDataSensorArg,
): SensorEventShape | undefined | SensorEventV2Shape => {
    const handler = conversionHandlers[sensor.type_code] ?? null;

    if (handler) {
        return handler(event);
    }

    return event;
};

export default convertEventData;
