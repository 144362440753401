import { wrapResponsePromise } from '@infogrid/core-api';
import type { IPagination, AxiosParsedError } from '@infogrid/core-types';
import type { Floor } from '@infogrid/locations-types';
import type { AxiosResponse } from 'axios';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getFloorsKey } from '../getQueryKeys';

const DEFAULT_FLOORS_DATA: Floor[] = [];

const mapper = (response: AxiosResponse<IPagination<Floor>>) => response.data.results;

// Rename the 'data' key to 'floors', and guarantee it always returns an array
interface UseFloorsResult
    extends Omit<UseQueryResult<Floor[], AxiosParsedError>, 'data'> {
    floors: Floor[];
}

export type UseFloorsOptions = UseQueryOptions<Floor[], AxiosParsedError>;
export type UseFloorsParams = { [key: string]: unknown };

export const useFloors = (
    buildingId?: number,
    params?: UseFloorsParams,
    options?: UseFloorsOptions,
): UseFloorsResult => {
    const { data, ...props } = useQuery(
        getFloorsKey(buildingId),
        ({ signal }) =>
            wrapResponsePromise<IPagination<Floor>, Floor[]>(
                // Will be number if enabled
                controllers.getFloors(buildingId as number, signal, params),
                mapper,
            ),
        {
            refetchOnMount: false,
            enabled: !!buildingId,
            ...options,
        },
    );

    const floors = data ?? DEFAULT_FLOORS_DATA;

    return {
        ...props,
        floors,
    };
};
