import { makeStyles } from '@material-ui/core';

import { MOBILE_NAVBAR_HEADER_HEIGHT } from './constants';

const MENU_ICON_BUTTON_PADDING = '8px';

export const useNavbarHeaderStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        boxShadow: theme.shadows[2],
        width: '100%',
        height: MOBILE_NAVBAR_HEADER_HEIGHT,
        padding: '12px 16px',
        background: theme.palette.background.navbar,
        zIndex: 3,
    },
    menuIconButton: {
        position: 'relative',
        left: `-${MENU_ICON_BUTTON_PADDING}`,
        padding: MENU_ICON_BUTTON_PADDING,
    },
    menuIcon: {
        color: '#FFFFFF',
    },
    logoContainer: {
        position: 'relative',
        padding: 0,
    },
    logoImage: {
        margin: '0 10px 0 4px',
    },
    logoText: {
        position: 'absolute',
        left: '100%',
        fontSize: '17px',
    },
    contentFromPage: {},
    installationButtons: {
        display: 'flex',
        marginLeft: 'auto',
    },
    identifyButton: {
        color: theme.palette.primary.contrastText,
        borderColor: '#918D85',
        padding: theme.spacing(0.75, 1.25),
        letterSpacing: '1.25px',
    },
    identifyButtonIcon: {
        marginRight: '8px',
        fontSize: '19px',
    },
}));
