import { makeStyles } from '@material-ui/core';

export const useFeedbackPanelTemplateSelectionStyles = makeStyles((theme) => ({
    name: {
        marginBottom: theme.spacing(2),
    },
    templateOptions: {
        color: theme.palette.common.gray6,
    },
    sensorsCount: {
        color: theme.palette.common.gray6,
        marginBottom: theme.spacing(2),
    },
    selectButton: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));
