import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { ParsedQs } from 'qs';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getEventsWebhookConfigKey } from '../getQueryKeys';
import type { EventsWebhooksConfigResult } from '../types';

export const useEventsWebhookConfig = (
    params: ParsedQs,
    options?: UseQueryOptions<EventsWebhooksConfigResult, AxiosParsedError>,
): UseQueryResult<EventsWebhooksConfigResult, AxiosParsedError> => {
    return useQuery(
        getEventsWebhookConfigKey(),
        ({ signal }) =>
            wrapResponsePromise(controllers.getEventsWebhookConfig(signal, params)),
        options,
    );
};
