import type { ParsedQs } from 'qs';
import type { QueryKey } from 'react-query';

import { FOLDER_QUERY_KEY, FOLDER_SUBFOLDERS_QUERY_KEY } from 'apiHooks/base';

export const getFolderKey = (folderId: number): QueryKey => [
    FOLDER_QUERY_KEY,
    `${folderId}`,
];

export const getSubFolderKey = (folderId: number): QueryKey => [
    FOLDER_SUBFOLDERS_QUERY_KEY,
    `${folderId}`,
];

export const getSubFolderFullKey = (
    folderId: number,
    params: ParsedQs = {},
): QueryKey => [FOLDER_SUBFOLDERS_QUERY_KEY, `${folderId}`, params];

export const getSubfoldersKey = (): QueryKey => [FOLDER_SUBFOLDERS_QUERY_KEY];

export const getFoldersKey = (): QueryKey => [FOLDER_QUERY_KEY];
