import { queryClient, wrapResponsePromise } from '@infogrid/core-api';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getBuildingsHierarchyKey } from '../getQueryKeys';
import { selectBuildingsHierarchy } from '../selectors';

const queryFn = ({ signal }) =>
    wrapResponsePromise(controllers.getBuildingHierarchy(signal));

export const useBuildingsHierarchy = () => {
    return useQuery(getBuildingsHierarchyKey(), queryFn, {
        initialData: [],
        select: selectBuildingsHierarchy,
    });
};

export const prefetchBuildingsHierarchy = () => {
    queryClient.prefetchQuery(getBuildingsHierarchyKey(), queryFn);
};
