import { Icon } from '@blueprintjs/core';
import { Tooltip } from '@infogrid/components-material-ui';
import { IconButton } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useShowSecretButtonStyles } from './styles';

interface Props {
    showSecretKey: boolean;
    toggleSecretKey: () => void;
}

const ShowSecretButton = ({ showSecretKey, toggleSecretKey }: Props): JSX.Element => {
    const { t } = useTranslation();
    const styles = useShowSecretButtonStyles();

    return (
        <Tooltip
            data-testid={'secret-key-tooltip'}
            title={showSecretKey ? t('Hide Secret Key') : t('Show Secret Key')}
            placement="right"
        >
            <IconButton onClick={toggleSecretKey}>
                <Icon
                    data-testid={'eye-icon'}
                    title={showSecretKey ? 'hide-secret-key' : 'show-secret-key'}
                    size={20}
                    className={styles.icon}
                    icon={showSecretKey ? 'eye-off' : 'eye-open'}
                />
            </IconButton>
        </Tooltip>
    );
};

export default memo(ShowSecretButton);
