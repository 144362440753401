import { memo } from 'react';

import { Widget } from 'views/dashboards/components/Widget';

import type { Detail } from '../types';
import { useGenericDetailsStyles } from './styles';

export interface GenericDetailsProps {
    details: Detail[];
}

const GenericDetails = ({ details }: GenericDetailsProps) => {
    const styles = useGenericDetailsStyles();

    return (
        <Widget.Details className={styles.container}>
            {details.map((detail) => (
                <Widget.Detail
                    key={detail.label}
                    label={detail.label}
                    value={detail.value}
                />
            ))}
        </Widget.Details>
    );
};

export default memo(GenericDetails);
