import { Modal, TimePicker } from '@infogrid/components-material-ui';
import { useAppSelector } from '@infogrid/core-ducks';
import type {
    SensorType,
    SensorShape,
    LegionnaireSubtypes,
} from '@infogrid/sensors-constants';
import { selectUserPreferredTempUnit } from '@infogrid/user-ducks';
import { getNowDateTime } from '@infogrid/utils-dates';
import { TEMP_UNIT_SYMBOL } from '@infogrid/utils-measurements';
import {
    Button,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Typography,
    FormHelperText,
    OutlinedInput,
    Box,
    TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';
import type { FormikProps } from 'formik';
import type { KeyboardEvent, ChangeEvent } from 'react';
import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { TemperatureOffsetParams } from 'utils/types/ts/pipeMonitoringSensors';

import SensorDisplay from '../SensorDisplay';
import { useInstallationFlowStyles } from '../styles';
import {
    useTemperatureOffsetConfigurationStyles,
    useCustomPickerClasses,
} from './styles';
import type { TempOffsetReadingType } from './utils';
import { validateTempOffsetWarnings, TEMP_OFFSET_READING_TYPES } from './utils';

interface Props {
    sensor: SensorShape;
    sensorName: string;
    sensorType?: SensorType;
    supportsLiveCalibration: boolean;
    formik: FormikProps<TemperatureOffsetParams>;
    renderActions: () => JSX.Element;
    onHelp: () => void;
}

const TemperatureOffsetConfigurationForm = ({
    sensor,
    sensorName,
    sensorType,
    supportsLiveCalibration,
    formik,
    renderActions,
    onHelp,
}: Props) => {
    const { t } = useTranslation('sensors');
    const userPreferredTempUnit = useAppSelector(selectUserPreferredTempUnit);

    const installationFlowStyles = useInstallationFlowStyles();
    const tempOffsetConfigurationClasses = useTemperatureOffsetConfigurationStyles();
    const customPickerClasses = useCustomPickerClasses();

    const temperatureLabel = supportsLiveCalibration
        ? t('Value of probe reading')
        : t('Temperature offset');

    const { handleChange, handleSubmit, setFieldValue, setFieldTouched, values, errors } =
        formik;
    const [temperatureWarning, setTemperatureWarning] = useState({
        errored: false,
        warning: '',
    });
    /**
     * Prevent the user from inserting non-numeric characters
     */
    const onKeyPress = (e: KeyboardEvent) => {
        if (!/[0-9.,-]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const setReadingTimeToNow = () => {
        const now = getNowDateTime();

        setFieldTouched('date_of_reading', true, false);
        setFieldValue('date_of_reading', now.date_of_reading);
        setFieldTouched('time_of_reading', true, false);
        setFieldValue('time_of_reading', now.time_of_reading);
    };

    const clearReadingTime = () => {
        setFieldTouched('date_of_reading', true, false);
        setFieldValue('date_of_reading', '');
        setFieldTouched('time_of_reading', true, false);
        setFieldValue('time_of_reading', '');
    };

    const onReadingTypeChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
        if (e.target.value === TEMP_OFFSET_READING_TYPES.CUSTOM.value) {
            clearReadingTime();
        }

        if (e.target.value === TEMP_OFFSET_READING_TYPES.NOW.value) {
            setReadingTimeToNow();
        }

        handleChange(e);
    };

    const onTemperatureChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
        // If the user is using "now" as the time. Set it to be the time at which they entered the temperature offset.
        // That should be the closest to the end of them taking the probe reading.
        if (
            supportsLiveCalibration &&
            values.reading_type === TEMP_OFFSET_READING_TYPES.NOW.value
        ) {
            setReadingTimeToNow();
        }

        handleChange(e);

        setTemperatureWarning(
            validateTempOffsetWarnings(
                e.target.value,
                supportsLiveCalibration,
                sensor?.sub_type as LegionnaireSubtypes,
                userPreferredTempUnit,
            ),
        );
    };

    const liveCalibrationFields = (
        <>
            <Typography className={installationFlowStyles.paragraph}>
                {t(`Define a temperature offset to allow for the difference in temperature between
                    the inside and outside of the pipe. Use a probe thermometer to do this.`)}
            </Typography>
            <Typography
                onClick={onHelp}
                color="primary"
                className={tempOffsetConfigurationClasses.helpLink}
                role="button"
            >
                {t('Need some help taking a reading?')}
            </Typography>

            <FormControl
                fullWidth
                variant="outlined"
                className={tempOffsetConfigurationClasses.input}
            >
                <InputLabel id="reading_type">{t('When are you reading')}</InputLabel>
                <Select
                    id="reading_type"
                    name="reading_type"
                    labelId="reading_type"
                    label={t('When are you reading')}
                    value={values.reading_type}
                    onChange={onReadingTypeChange}
                    variant="outlined"
                    fullWidth
                >
                    {Object.values(TEMP_OFFSET_READING_TYPES).map(
                        (type: TempOffsetReadingType) => (
                            <MenuItem value={type.value} key={type.value}>
                                {t(type.text)}
                            </MenuItem>
                        ),
                    )}
                </Select>
                <FormHelperText>
                    {t(
                        'Time of the probe reading is used to automatically calculate the offset against readings from the sensor',
                    )}
                </FormHelperText>
            </FormControl>
            {values.reading_type === 'custom' && (
                <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    className={tempOffsetConfigurationClasses.input}
                >
                    <FormControl
                        className={classNames(
                            customPickerClasses.root,
                            tempOffsetConfigurationClasses.datePicker,
                        )}
                    >
                        <InputLabel htmlFor="date">
                            {t('Date of probe reading')}
                        </InputLabel>
                        <TextField
                            id="date"
                            variant="outlined"
                            label={t('Date of probe reading')}
                            type="date"
                            name="date_of_reading"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={handleChange}
                            value={values.date_of_reading}
                            error={!!errors.date_of_reading || !!errors.time_of_reading}
                        />
                    </FormControl>
                    <TimePicker
                        label={t('Time of probe reading')}
                        value={values.time_of_reading}
                        name="time_of_reading"
                        size="medium"
                        onChange={handleChange}
                        classes={customPickerClasses}
                        error={!!errors.time_of_reading || !!errors.date_of_reading}
                    />
                    <FormHelperText
                        error={!!errors.date_of_reading || !!errors.time_of_reading}
                    >
                        {t(errors.date_of_reading ?? '')}{' '}
                        {errors.date_of_reading ? '' : t(errors.time_of_reading ?? '')}
                    </FormHelperText>
                </Box>
            )}
        </>
    );

    return (
        <>
            <Modal.Title>{t('Set temperature offset')}</Modal.Title>
            <Modal.Content>
                <form
                    onSubmit={handleSubmit}
                    className={installationFlowStyles.form}
                    id="temperatureOffsetConfigurationForm"
                >
                    {sensorType && (
                        <SensorDisplay
                            sensor={sensor}
                            label={sensorName}
                            deviceType={sensorType}
                        />
                    )}
                    {supportsLiveCalibration && liveCalibrationFields}
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="temperature">{temperatureLabel}</InputLabel>
                        <OutlinedInput
                            id="temperature"
                            label={temperatureLabel}
                            name="temperature"
                            value={values.temperature ?? ''}
                            onChange={onTemperatureChange}
                            endAdornment={TEMP_UNIT_SYMBOL[userPreferredTempUnit]}
                            inputProps={{ inputMode: 'decimal' }}
                            onKeyPress={onKeyPress}
                            error={!!errors.temperature}
                            className={`${tempOffsetConfigurationClasses.temperature}`}
                        />
                        {!supportsLiveCalibration && (
                            <FormHelperText>
                                {t(
                                    'Temperature offset is applied to the original measured temperature',
                                )}
                            </FormHelperText>
                        )}
                        {!!errors.temperature && (
                            <FormHelperText error data-cypress="error-message">
                                {t(errors.temperature)}
                            </FormHelperText>
                        )}
                        <Alert
                            severity="warning"
                            className={`${customPickerClasses.temperatureWarning} ${
                                !errors.temperature &&
                                !!temperatureWarning.errored &&
                                customPickerClasses.unhide
                            }`}
                            data-cypress="warning-message"
                        >
                            <span>{t(temperatureWarning.warning)}</span>
                        </Alert>
                    </FormControl>
                </form>
            </Modal.Content>
            <Modal.Actions
                justify="space-between"
                className={installationFlowStyles.actions}
            >
                {renderActions()}
                <Button
                    variant="contained"
                    color="primary"
                    data-cypress="save"
                    type="submit"
                    form="temperatureOffsetConfigurationForm"
                >
                    {t('Save', { ns: 'common' })}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(TemperatureOffsetConfigurationForm);
