import { makeStyles, fade } from '@material-ui/core';

export const useWidgetOptionsStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        flexShrink: 0,
        '& > div': {
            marginRight: theme.spacing(2),
            '&:after': {
                backgroundColor: fade(theme.palette.common.gray1, 0.4),
                content: '""',
                height: '14px',
                position: 'absolute',
                right: theme.spacing(-1),
                width: '1px',
            },
        },
        '& > div:last-of-type': {
            marginRight: 0,
            '&:after': {
                display: 'none',
            },
        },
    },
}));
