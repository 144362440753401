import type { QueryKey } from 'react-query';

import {
    REPORTS_KEY,
    REPORT_CONFIGURATIONS_KEY,
    SMART_CLEANING_REPORTS_QUERY_KEY,
} from 'apiHooks/base';

export const getReportsKey = (params?: { ordering: string }): QueryKey => [
    REPORTS_KEY,
    params,
];
export const getReportConfigurationsKey = (params: { ordering: string }): QueryKey => [
    REPORT_CONFIGURATIONS_KEY,
    params,
];

export const getSmartCleaningReportsKey = ({
    widgetId,
}: {
    widgetId: number;
}): QueryKey => [SMART_CLEANING_REPORTS_QUERY_KEY, widgetId];
