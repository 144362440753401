import type { Building } from '@infogrid/locations-types';
import isEmpty from 'lodash/isEmpty';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import GenericFooter from 'views/dashboards/components/Widget/GenericWidget/GenericFooter';
import BuildingsSelect from 'views/dashboards/widgets/common/BuildingsSelect';

import type {
    HealthyBuildingScoreWidgetDataWithPermissionError,
    HealthyBuildingScoreWidgetConfiguration,
    HealthyBuildingScoreWidget,
} from '../types';
import { useFooterStyles } from './styles';

interface Props {
    canUserEdit?: boolean;
    buildings: Building[];
    isBuildingLoading: boolean;
    widget: HealthyBuildingScoreWidget;
    widgetData: HealthyBuildingScoreWidgetDataWithPermissionError | undefined;
    isWidgetDataLoading: boolean;
    onUpdateConfiguration: (config: HealthyBuildingScoreWidgetConfiguration) => void;
}

const Footer = ({
    buildings,
    isBuildingLoading,
    onUpdateConfiguration,
    widget,
    widgetData,
    isWidgetDataLoading,
    canUserEdit = false,
}: Props) => {
    const styles = useFooterStyles();

    const { t } = useTranslation('dashboard');

    const selectedBuildingsIds = widget.configuration.healthy_building.buildings_ids;

    const numberOfSensors = widgetData?.all_buildings_score.sensor_count;

    const handleBuildingsSelectClose = useCallback(
        (ids: number[]) => {
            onUpdateConfiguration({ healthy_building: { buildings_ids: ids } });
        },
        [onUpdateConfiguration],
    );

    const sensorsLabel =
        !isWidgetDataLoading && !isEmpty(widgetData)
            ? `${t('{{count}} Sensors', {
                  count: numberOfSensors || 0,
                  defaultValue___one: `${numberOfSensors} Sensor`,
                  defaultValue___other: `${numberOfSensors} Sensors`,
              })}`
            : '';

    return (
        <GenericFooter
            className={styles.footer}
            justifyContent={isBuildingLoading ? 'flex-end' : 'space-between'}
            sensorsLabel={sensorsLabel}
        >
            {!isBuildingLoading && (
                <BuildingsSelect
                    canUserEdit={canUserEdit}
                    buildings={buildings}
                    selectedBuildingsIds={selectedBuildingsIds}
                    onClose={handleBuildingsSelectClose}
                />
            )}
        </GenericFooter>
    );
};

export default memo(Footer);
