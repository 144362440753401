export const { isArray } = Array;

export const hasValue = (value) => typeof value !== 'undefined' && value !== null;

export const isFunction = (value) => typeof value === 'function';

export const isObject = (value) =>
    !!value && !isArray(value) && typeof value === 'object';

export const isString = (value) => typeof value === 'string';

export const isStringArray = (value) =>
    isArray(value) && value.length > 0 && value.every((x) => isString(x));

export const isNumber = (value) => typeof value === 'number';

export const isStatusCode = (statusCodes, status) =>
    hasValue(status) &&
    ((isArray(statusCodes) && statusCodes.indexOf(status) !== -1) ||
        (isNumber(statusCodes) && statusCodes === status));
