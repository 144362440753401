import { Modal } from '@infogrid/components-material-ui';
import type { Floor } from '@infogrid/locations-types';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button, TextField, Tooltip, Grid, Typography } from '@material-ui/core';
import type { FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useEditFloorModalStyles } from './styles';
import type { EditFloorModalValues } from './types';

interface Props {
    floor: Floor;
    formik: FormikProps<EditFloorModalValues>;
    isSubmitting: boolean;
    onClose: () => void;
    onDelete: () => void;
    open: boolean;
}

const EditFloorModal = ({
    floor,
    formik,
    isSubmitting,
    onClose,
    onDelete,
    open,
}: Props) => {
    const styles = useEditFloorModalStyles();

    const { t } = useTranslation('estate');

    const isMobile = useIsMobile();

    const { errors, handleBlur, handleChange, handleSubmit, touched, values } = formik;

    return (
        <Modal fullScreen={isMobile} onClose={onClose} open={open}>
            <Modal.Title>{t('Edit floor')}</Modal.Title>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formContent} data-cypress="edit-floor-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                data-cypress="floor-name"
                                error={!!touched.name && !!errors.name}
                                fullWidth
                                helperText={
                                    !!touched.name && errors.name && t(errors.name)
                                }
                                id="name"
                                label={t('Floor name')}
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.name}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={styles.header} variant="subtitle1">
                                {t('Capacity')}
                            </Typography>
                            <Typography
                                className={styles.capacityText}
                                color="textSecondary"
                                variant="body2"
                            >
                                {t(
                                    'This value will be used to calculate floor utilisation.',
                                )}
                            </Typography>
                            <TextField
                                data-cypress="edit-floor-capacity"
                                type="number"
                                label={t('Capacity')}
                                id="floor_edit_occupant_capacity"
                                name="occupant_capacity"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: 1,
                                }}
                                value={values.occupant_capacity}
                                onChange={handleChange}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </div>
                <Modal.Actions justify="right">
                    <Tooltip
                        placement="top"
                        title={
                            !floor.user_actions?.delete?.allowed &&
                            floor.user_actions?.delete?.reason
                                ? floor.user_actions?.delete?.reason
                                : ''
                        }
                    >
                        <div>
                            <Button
                                color="secondary"
                                data-cypress="delete-floor"
                                disabled={!floor.user_actions?.delete?.allowed}
                                onClick={onDelete}
                            >
                                {t('Delete floor')}
                            </Button>
                        </div>
                    </Tooltip>
                    <Button
                        className={styles.saveButton}
                        color="primary"
                        data-cypress="save-floor"
                        disabled={isSubmitting || !isEmpty(errors)}
                        variant="contained"
                        type="submit"
                    >
                        {t('Save', { ns: 'common' })}
                    </Button>
                </Modal.Actions>
            </form>
        </Modal>
    );
};

export default memo(EditFloorModal);
