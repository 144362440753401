import { ErrorModal } from '@infogrid/components-material-ui';
import { Typography } from '@material-ui/core';
import ErrorOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMaxWebhooksAllowedModalStyles } from './styles';

const modalActionsProps = { justify: 'right' };

interface MaxWebhooksAllowedModalProps {
    open: boolean;
    onClose: () => void;
    maxWebhooksAllowed: number;
}

const MaxWebhooksAllowedModal = ({
    onClose,
    open,
    maxWebhooksAllowed,
}: MaxWebhooksAllowedModalProps) => {
    const styles = useMaxWebhooksAllowedModalStyles();
    const { t } = useTranslation('integrations');

    const content = useMemo(
        () => (
            <div className={styles.modalContent}>
                <ErrorOutlinedIcon className={classNames(styles.warningIcon)} />
                <div>
                    <Typography variant="body1" className={styles.text}>
                        {t('You’ve reached the maximum of {{count}} event webhooks.', {
                            count: maxWebhooksAllowed,
                        })}
                    </Typography>
                    <Typography variant="body1">
                        {t(
                            'You can delete a webhook to add another, or edit an existing one to meet your needs.',
                        )}
                    </Typography>
                </div>
            </div>
        ),
        [styles, t, maxWebhooksAllowed],
    );

    return (
        <ErrorModal
            maxWidth="xs"
            fullWidth
            title={t('No new webhooks can be added')}
            open={open}
            onClose={onClose}
            content={content}
            modalActionsProps={modalActionsProps}
            showDivider
            disableBackdropClick
        />
    );
};

export default memo(MaxWebhooksAllowedModal);
