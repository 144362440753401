import { useFormik } from 'formik';
import { memo, useCallback } from 'react';

import GenericConfigureWidgetModal from 'views/dashboards/components/modals/GenericConfigureWidgetModal';

import type { IconeIndexConfig } from '../types';

interface Props {
    initialValues: IconeIndexConfig;
    open: boolean;
    loading: boolean;
    onClose: () => void;
    onUpdateConfiguration: (values: IconeIndexConfig) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tabs: any;
}

const ConfigureWidgetModal = ({
    initialValues,
    loading,
    onClose,
    onUpdateConfiguration,
    open,
    tabs,
}: Props) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: onUpdateConfiguration,
    });

    const savingDisabled =
        loading ||
        !formik.dirty ||
        !(formik.values.folders.length || formik.values.sensors.length);

    const onUpdate = useCallback(
        () => onUpdateConfiguration(formik.values),
        [formik.values, onUpdateConfiguration],
    );

    return (
        <GenericConfigureWidgetModal
            formik={formik}
            initialValues={initialValues}
            loading={loading}
            onClose={onClose}
            onUpdateConfiguration={onUpdate}
            open={open}
            tabs={tabs}
            formValuesHaveChanged={formik.dirty}
            savingDisabled={savingDisabled}
        />
    );
};

export default memo(ConfigureWidgetModal);
