import { useFormik } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import CreateFolder from './CreateFolder';

interface Props {
    handleBack: () => void;
    handleCreateFolder: () => void;
}

const CreateFolderContainer = ({
    handleBack,
    handleCreateFolder,
    ...props
}: Props): JSX.Element => {
    const { t } = useTranslation('floorplan');

    const { values, handleChange, handleSubmit, errors, touched, isSubmitting } =
        useFormik({
            initialValues: {
                name: '',
            },
            onSubmit: handleCreateFolder,
            validationSchema: Yup.object().shape({
                name: Yup.string()
                    .required(t('This field may not be blank.'))
                    .min(3, t('Name should be more 3 symbols')),
            }),
        });

    return (
        <CreateFolder
            errors={errors}
            touched={touched}
            isSubmitting={isSubmitting}
            folderName={values.name}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleBack={handleBack}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
};

export default memo(CreateFolderContainer);
