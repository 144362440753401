import { getFlatEntitiesFromPages } from '@infogrid/core-api';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import omit from 'lodash/omit';

import { formatSensorEvent } from 'utils/sensor';

/**
 * Convert Date timestamps to numerical timestamps
 * Adds outsideRange field to events, false when filter is activated
 * Excludes events outside of range based on filter flag
 * Exclusion is to help with events outside of range.
 *
 * @param events {Array<Object>}
 * @param timeZone - timezone on the user's profile
 * @param timeRange {{ range: string, from: Date, to: Date }}
 * @param filter boolean - If true, then events that happened outside the range the user chose will be discarded.
 *      (These events are needed for some sensor graph types, such as knowing whether an occupancy sensor started the
 *      timerange out as occupied or not.)
 */

export const selectEventsWithNumericalTimestamps = (
    data,
    timeZone,
    timeRange,
    filter,
) => {
    const events = getFlatEntitiesFromPages({
        pages: data?.pages,
        entitiesKey: 'events',
    });

    events
        .map((event) => formatSensorEvent(event, timeZone, timeRange))
        .map((event, index, sensorEvents) => {
            if (index === 0) {
                return event;
            }

            const previousEvent = sensorEvents[index - 1];

            if (previousEvent.timestamp && event.timestamp) {
                return {
                    ...event,
                    duration: previousEvent.timestamp - event.timestamp,
                };
            }

            return event;
        })
        .filter((event) => !(event.outsideRange && filter));

    return {
        ...data,
        events,
    };
};

export const selectSeenSensors = (data) => {
    const seenSensors = getFlatEntitiesFromPages({
        pages: data?.pages,
        entitiesKey: 'sensors',
        uniqByKey: 'id',
    });

    const sensorsIds = seenSensors.map((sensor) => sensor.device_name);
    // the number is the same for every page, as it's a total number of
    // sensors from API that we set in useSeenSensors hook
    const seenSensorsCount = data.pages?.length ? data.pages[0].count : 0;

    return { ...data, seenSensors, sensorsIds, seenSensorsCount };
};

export const selectLatestEvents = (data) => {
    const events = getFlatEntitiesFromPages({
        pages: data?.pages,
        entitiesKey: 'events',
        uniqByKey: 'id',
    });

    return { ...data, events };
};

export const selectSensors = (data) => {
    const sensors = getFlatEntitiesFromPages({
        pages: data?.pages,
        entitiesKey: 'sensors',
        uniqByKey: 'device_name',
    });

    const sensorsIds = sensors.map((sensor) => sensor.device_name);
    const sensorsCount = data?.pages?.length ? data.pages[0].count : 0;

    return { ...data, sensors, sensorsIds, sensorsCount };
};

export const selectSensorCountsByTypes = (data) => {
    if (!data.sensorCountsByTypes) {
        return data;
    }

    const sensorCountsByTypes = omit(data.sensorCountsByTypes, 'unknown');

    return { ...data, sensorCountsByTypes };
};
