import { ButtonLink } from '@infogrid/components-material-ui';
import { routesManager } from '@infogrid/core-routing';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import type { AsyncStatus } from 'utils/asyncStatus';
import { ASYNC_STATUS } from 'utils/asyncStatus';
import { trackReportsUserEvent } from 'views/reports/analytics';

import ReportDownloadButton from '../ReportDownloadButton';

export interface ReportCTAToastProps {
    children: ReactNode;
    generationStatus?: AsyncStatus;
    id?: number;
}

const ReportCTAToast = ({
    children,
    id,
    generationStatus,
}: ReportCTAToastProps): JSX.Element => {
    const { t } = useTranslation('reports');

    const isReportGenerationComplete = id && generationStatus === ASYNC_STATUS.DONE;

    const button = isReportGenerationComplete ? (
        <ReportDownloadButton id={id} />
    ) : (
        <ButtonLink
            className="flex-shrink-0"
            to={routesManager.resolvePath('reports:list')}
            success={generationStatus !== ASYNC_STATUS.ERROR}
            color={generationStatus === ASYNC_STATUS.ERROR ? 'secondary' : 'primary'}
            variant="contained"
            onClick={() =>
                trackReportsUserEvent(USER_ACTIONS.PRESSED, 'open reports cta toast')
            }
        >
            {t('Open reports')}
        </ButtonLink>
    );

    return (
        <div>
            <div className="mb-2">{children}</div>
            {button}
        </div>
    );
};

export default ReportCTAToast;
