import * as Sentry from '@sentry/react';
import type { ReactNode } from 'react';
import { Component } from 'react';

import AppUpdatedError from './AppUpdatedError';

interface AppUpdatedErrorBoundaryProps {
    children: ReactNode;
}

interface AppUpdatedErrorBoundaryState {
    hasError: boolean;
}

/**
 * Error boundary that shows a warning alert to reload the page when a ChunkLoadError or
 * a Dynamic Module Load Error is raised.
 *
 * NB: It's not immediately clear what exactly raises the ChunkLoadError. See WEB-2781.
 * It's possible that this fix doesn't work.
 * It's not the cleanest solution either.
 */
// eslint-disable-next-line react-prefer-function-component/react-prefer-function-component
class LazyLoadErrorBoundary extends Component<
    AppUpdatedErrorBoundaryProps,
    AppUpdatedErrorBoundaryState
> {
    state: AppUpdatedErrorBoundaryState = {
        hasError: false,
    };

    static getDerivedStateFromError(error: Error): AppUpdatedErrorBoundaryState {
        if (error.message.match(/loading CSS chunk/i)) {
            Sentry.captureMessage(`LazyLoadError - ${error.message}`, 'warning');

            return { hasError: true };
        }

        // Deals with module hashes having changed within Vite deployments
        // See https://github.com/vitejs/vite/issues/11804
        if (error.message.match(/Failed to fetch dynamically imported module/i)) {
            Sentry.captureMessage(`LazyLoadError - ${error.message}`, 'warning');

            return { hasError: true };
        }

        if (error.name === 'ChunkLoadError') {
            Sentry.captureMessage(`LazyLoadError - ${error.name}`, 'warning');

            return { hasError: true };
        }

        throw error;
    }

    render(): ReactNode {
        if (this.state.hasError) {
            return <AppUpdatedError />;
        }

        return this.props.children;
    }
}

export default LazyLoadErrorBoundary;
