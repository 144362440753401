import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { getSensor } from '@infogrid/sensors-configuration';
import {
    SENSOR_TYPE,
    TEMPERATURE_OFFSET_MODES,
    TEMPERATURE_OFFSET_STATUSES,
} from '@infogrid/sensors-constants';
import type {
    LegionnaireSensorConfigurationShape,
    SensorType,
    TemperatureOffsetStatus,
    TemperatureOffsetMode,
} from '@infogrid/sensors-constants';
import { useCallback, useMemo, useEffect } from 'react';

import { useActiveSensorContext } from 'utils/contexts';
import { SENSOR_ID_FIELD } from 'utils/sensor';

type TempConfig = GenericSensorShape['sensor_configuration'] & {
    offset_status?: TemperatureOffsetStatus;
    offset_mode?: TemperatureOffsetMode;
};

interface SelectableSensorFromList {
    activeSensor: string;
    selectSensor: (columnIndex: number, rowIndex?: number | undefined) => void;
    activeSensorIdx: number;
    isManuallySelected: boolean;
}

const useSelectableSensorFromList = (
    sensors: { [key in typeof SENSOR_ID_FIELD]: string }[] = [],
): SelectableSensorFromList => {
    const { activeSensor, setActiveSensor, isManuallySelected, setIsManuallySelected } =
        useActiveSensorContext();

    useEffect(() => {
        const firstSensor = sensors[0];

        if (
            sensors.length &&
            firstSensor &&
            firstSensor[SENSOR_ID_FIELD] !== activeSensor &&
            !isManuallySelected
        ) {
            setActiveSensor(firstSensor[SENSOR_ID_FIELD]);
        }
    }, [sensors, activeSensor, setActiveSensor, isManuallySelected]);

    const selectSensor = useCallback(
        (
            rowIndex,
            // TODO this was called `shouldBeSelectedManually` and had a default value of true.
            // The actual values passed by the table when a row is selected correspond to
            // column indices, so the logic below doesn't make any sense (unless we want to set
            // setIsManuallySelected true when you click on any column but the first).
            columnIndex = 0,
        ) => {
            const sensor = sensors[rowIndex]?.[SENSOR_ID_FIELD];

            if (sensor) {
                setActiveSensor(sensor);

                if (columnIndex) {
                    setIsManuallySelected(true);
                }
            }
        },
        [sensors, setActiveSensor, setIsManuallySelected],
    );

    const activeSensorIdx = useMemo(
        () => sensors.findIndex((sensor) => sensor[SENSOR_ID_FIELD] === activeSensor),
        [sensors, activeSensor],
    );

    return { activeSensor, selectSensor, activeSensorIdx, isManuallySelected };
};

const useIsTempOffsetMissed = (sensor?: {
    type_code: SensorType;
    sensor_configuration?: TempConfig;
}): boolean => {
    if (!sensor || sensor.type_code !== SENSOR_TYPE.TYPE_LEGIONNAIRE) {
        return false;
    }

    const temperatureOffsetStatus =
        sensor.sensor_configuration && sensor.sensor_configuration?.offset_status;

    return temperatureOffsetStatus === TEMPERATURE_OFFSET_STATUSES.NOT_SET;
};

const useIsTempOffsetModeAutomatic = (sensor?: {
    sensor_configuration?: TempConfig;
}): boolean => {
    return (
        sensor?.sensor_configuration?.offset_mode === TEMPERATURE_OFFSET_MODES.AUTOMATIC
    );
};

interface TempOffsetSensorArg extends Omit<GenericSensorShape, 'sensor_configuration'> {
    sensor_configuration?: TempConfig;
}

const useIsTempOffsetFailed = (sensor?: TempOffsetSensorArg): boolean => {
    if (!sensor || sensor.type_code !== SENSOR_TYPE.TYPE_LEGIONNAIRE) {
        return false;
    }

    const supportsLiveCalibration = getSensor(sensor).supportsLiveCalibration();

    if (supportsLiveCalibration) {
        const temperatureOffsetStatus = sensor.sensor_configuration?.offset_status;

        return temperatureOffsetStatus === TEMPERATURE_OFFSET_STATUSES.FAILED;
    }

    return false;
};

const useIsTempOffsetCalibrating = (sensor?: TempOffsetSensorArg): boolean => {
    if (!sensor || sensor.type_code !== SENSOR_TYPE.TYPE_LEGIONNAIRE) {
        return false;
    }

    const temperatureOffsetStatus = (
        sensor.sensor_configuration as LegionnaireSensorConfigurationShape
    )?.offset_status;

    return temperatureOffsetStatus === TEMPERATURE_OFFSET_STATUSES.CALIBRATING;
};

export {
    useSelectableSensorFromList,
    useIsTempOffsetMissed,
    useIsTempOffsetFailed,
    useIsTempOffsetCalibrating,
    useIsTempOffsetModeAutomatic,
};
