import { makeStyles } from '@material-ui/core';

export const useWidgetActionStyles = makeStyles((theme) => ({
    root: {
        '& > i': {
            cursor: 'pointer',
            color: theme.palette.common.gray1,
            fontSize: '18px',
        },
    },
}));
