import { MenuList } from '@infogrid/components-material-ui';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ActionsMenu from 'components/ActionsMenu';

import { useFloorPlanControlsMethods } from '../hooks';
import { useActionsMenuStyles, useStylesMapControls } from './styles';

type MapControls =
    | 'ZOOM_IN'
    | 'ZOOM_OUT'
    | 'CENTER'
    | 'FULL_SCREEN'
    | 'EXPORT'
    | 'REPLACE_IMAGE'
    | 'EDIT_IMAGE';

export interface DefaultFloorPlanControlsProps {
    isFullScreen: boolean;
    maxZoom: number;
    minZoom: number;
    currentZoom: number;
    className?: string;
    toggleFullScreen: () => void;
    centerCoordinate: number[];
    onReplaceMapImage?: () => void;
    onEditImage?: () => void;
    isWidget?: boolean;
    customControls?: React.ReactNode;
    baseControls: MapControls[];
    classes?: {
        root?: string;
        customControls?: string;
    };
}

const DefaultFloorPlanControls = ({
    baseControls,
    centerCoordinate,
    isFullScreen,
    toggleFullScreen,
    customControls,
    maxZoom,
    minZoom,
    currentZoom,
    classes,
    className = '',
    onReplaceMapImage = () => {},
    onEditImage = () => {},
    isWidget = false,
}: DefaultFloorPlanControlsProps) => {
    const containerRef = useRef(null);
    const { t } = useTranslation('floor-map');
    const isMobile = useIsMobile();
    const styles = useStylesMapControls();
    const actionsMenuClasses = useActionsMenuStyles();

    const { map, zoomIn, zoomOut, moveToCenter, downloadMap } =
        useFloorPlanControlsMethods({ centerCoordinate });

    const WrapperTooltip = useCallback(
        ({ tooltipContent, children, leaveDelay = 0 }) => (
            <Tooltip arrow leaveDelay={leaveDelay} title={tooltipContent}>
                {children}
            </Tooltip>
        ),
        [],
    );

    const TitledTooltipContent = useCallback(
        ({ title, value }) => (
            <div>
                <Typography component="span" variant="subtitle2">
                    {title}
                </Typography>
                {value && (
                    <Typography component="span" variant="body2">
                        {value}
                    </Typography>
                )}
            </div>
        ),
        [],
    );

    const ZoomTooltipValue = useCallback(
        ({ direction }) => {
            const key = navigator.platform.includes('Mac') ? 'Cmd' : 'Ctrl';
            const mouseScrollDirectionText = t(`mouse scroll {{direction}}`, {
                direction,
                defaultValue: `mouse scroll ${direction}`,
            });

            return isWidget ? (
                <Typography component="span" variant="body2">
                    {' ('}
                    <kbd>{key}</kbd>
                    {` + ${mouseScrollDirectionText})`}
                </Typography>
            ) : (
                <>{` (${mouseScrollDirectionText})`}</>
            );
        },
        [isWidget, t],
    );

    if (!map || isMobile) {
        return null;
    }

    const replaceImageEnabled = baseControls.includes('REPLACE_IMAGE');
    const exportEnabled = baseControls.includes('EXPORT');
    const editImageEnabled = baseControls.includes('EDIT_IMAGE');

    const shouldRenderActionsMenu =
        replaceImageEnabled || exportEnabled || editImageEnabled;

    return (
        <div
            className={classNames(styles.controls, classes?.root, className)}
            ref={containerRef}
        >
            <div className={classNames(styles.customControls, classes?.customControls)}>
                {customControls}
            </div>
            <div className={styles.buttonsContainer} data-cypress="tooltip">
                {baseControls.includes('ZOOM_IN') && (
                    <WrapperTooltip
                        tooltipContent={
                            <TitledTooltipContent
                                title={t('Zoom In')}
                                value={<ZoomTooltipValue direction={t('up')} />}
                            />
                        }
                    >
                        {/* Disabled elements do not fire events https://github.com/mui-org/material-ui/issues/8416 */}
                        <div>
                            <IconButton
                                className={styles.controlIconButton}
                                data-cypress="zoom-in"
                                disabled={currentZoom >= maxZoom}
                                onClick={zoomIn}
                            >
                                <Icon
                                    className={classNames(
                                        'far fa-plus',
                                        styles.controlIcon,
                                        {
                                            [styles.disabledControlIcon]:
                                                currentZoom >= maxZoom,
                                        },
                                    )}
                                />
                            </IconButton>
                        </div>
                    </WrapperTooltip>
                )}

                {baseControls.includes('ZOOM_OUT') && (
                    <WrapperTooltip
                        tooltipContent={
                            <TitledTooltipContent
                                title={t('Zoom Out')}
                                value={<ZoomTooltipValue direction={t('down')} />}
                            />
                        }
                    >
                        {/* Disabled elements do not fire events https://github.com/mui-org/material-ui/issues/8416 */}
                        <div>
                            <IconButton
                                className={styles.controlIconButton}
                                data-cypress="zoom-out"
                                disabled={currentZoom <= minZoom}
                                onClick={zoomOut}
                            >
                                <Icon
                                    className={classNames(
                                        'far fa-minus',
                                        styles.controlIcon,
                                        {
                                            [styles.disabledControlIcon]:
                                                currentZoom <= minZoom,
                                        },
                                    )}
                                />
                            </IconButton>
                        </div>
                    </WrapperTooltip>
                )}

                {baseControls.includes('CENTER') && (
                    <WrapperTooltip tooltipContent={t('Centre Floor Plan')}>
                        <IconButton
                            className={styles.controlIconButton}
                            data-cypress="move-to-center"
                            onClick={moveToCenter}
                        >
                            <Icon
                                className={classNames(
                                    'far fa-expand',
                                    styles.controlIcon,
                                )}
                            />
                        </IconButton>
                    </WrapperTooltip>
                )}

                {baseControls.includes('FULL_SCREEN') && (
                    <WrapperTooltip tooltipContent={t('Fullscreen')}>
                        <IconButton
                            className={styles.controlIconButton}
                            data-cypress="fullscreen"
                            onClick={toggleFullScreen}
                        >
                            <Icon
                                className={classNames(styles.controlIcon, {
                                    'far fa-expand-alt': !isFullScreen,
                                    'far fa-compress-alt': isFullScreen,
                                })}
                            />
                        </IconButton>
                    </WrapperTooltip>
                )}

                {shouldRenderActionsMenu && (
                    <ActionsMenu classes={actionsMenuClasses}>
                        {() => (
                            <MenuList>
                                {editImageEnabled && (
                                    <MenuList.Item onClick={onEditImage}>
                                        <Typography>{t('edit image')}</Typography>
                                    </MenuList.Item>
                                )}
                                {replaceImageEnabled && (
                                    <MenuList.Item onClick={onReplaceMapImage}>
                                        <Typography>{t('replace image')}</Typography>
                                    </MenuList.Item>
                                )}

                                {exportEnabled && (
                                    <MenuList.Item onClick={downloadMap}>
                                        <Typography>
                                            {t('download floor plan')}
                                        </Typography>
                                    </MenuList.Item>
                                )}
                            </MenuList>
                        )}
                    </ActionsMenu>
                )}
            </div>
        </div>
    );
};

export default memo(DefaultFloorPlanControls);
