import { HEADER_HEIGHT } from '@infogrid/components-material-ui';
import { makeStyles } from '@material-ui/core';

export const useStylesSensorNavigation = makeStyles((theme) => ({
    sensorsListContainer: () => {
        const height = `calc(100vh - ${HEADER_HEIGHT}px)`;

        return {
            display: 'flex',
            flexDirection: 'column',
            height,
            maxHeight: height,
            overflow: 'hidden',
            backgroundColor: theme.palette.common.shell,
            position: 'sticky',
            top: HEADER_HEIGHT,
            boxShadow: theme.shadows[1],
        };
    },
    sensorsListTabsMenu: {
        position: 'relative',
        backgroundColor: theme.palette.background.default,

        '& .MuiTabs-indicator': {
            zIndex: 1,
        },

        '&:after': {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: 2,
            backgroundColor: theme.palette.common.shell,
            content: '""',
        },
    },
    planSensorsButtonWrapper: {
        display: 'flex',
        width: '100%',
    },
    planSensorsButton: {
        margin: theme.spacing(2),
        width: '100%',
    },
    spinner: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
}));
