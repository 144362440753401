import { queryClient } from '@infogrid/core-api';
import type { FloorDetail, NullableMapItemCoordinates } from '@infogrid/locations-types';
import type { SetDataOptions } from 'react-query';
import type { QueryFilters } from 'react-query/types/core/utils';

import { getFloorDetailKey, getFloorsKey } from './getQueryKeys';

export const setFloors = (buildingId: number, data: unknown, options?: SetDataOptions) =>
    queryClient.setQueryData(getFloorsKey(buildingId), data, options);

export const getFloors = (buildingId: number, filters?: QueryFilters) =>
    queryClient.getQueryData(getFloorsKey(buildingId), filters) ?? [];

export const setFloorDetail = (
    floorId: number,
    data: unknown,
    options?: SetDataOptions,
) => queryClient.setQueryData(getFloorDetailKey(floorId), data, options);

export const getFloorDetail = (floorId: number, filters?: QueryFilters) =>
    queryClient.getQueryData(getFloorDetailKey(floorId), filters);

export const cancelFloorDetailQuery = (floorId: number) =>
    queryClient.cancelQueries(getFloorDetailKey(floorId));

export const cancelFloorsQuery = (buildingId: number) =>
    queryClient.cancelQueries(getFloorsKey(buildingId));

export const removeFloorsQuery = (buildingId: number) =>
    queryClient.removeQueries(getFloorsKey(buildingId));

export const invalidateFloorDetail = (floorId: number) =>
    queryClient.invalidateQueries(getFloorDetailKey(floorId));

export const removeFloorDetailQuery = (floorId: number) =>
    queryClient.removeQueries(getFloorDetailKey(floorId));

export const cancelFloors = (buildingId: number) =>
    queryClient.cancelQueries(getFloorsKey(buildingId));

export const invalidateFloors = (buildingId: number | null) =>
    queryClient.invalidateQueries(getFloorsKey(buildingId));

export const addFloorSensor = (floorId: number, sensorData: NullableMapItemCoordinates) =>
    setFloorDetail(floorId, (floorDetail: FloorDetail) => ({
        ...floorDetail,
        sensors: [...floorDetail.sensors, sensorData],
    }));

export const removeFloorSensor = (floorId: number, deviceName: string) =>
    setFloorDetail(floorId, (floorDetail: FloorDetail) => ({
        ...floorDetail,
        sensors: floorDetail.sensors.filter(
            (sensor) => sensor.device_name !== deviceName,
        ),
    }));

export const updateResetTimes = (floorId: number, resetTime: string) =>
    setFloorDetail(floorId, (floorDetail: FloorDetail) => ({
        ...floorDetail,
        reset_times: [...floorDetail.reset_times, resetTime],
    }));
