import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import { spaceControllers, getSpaceKey } from '@infogrid/locations-api';
import type { Space } from '@infogrid/locations-types';
import type { UseQueryResult, UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

export const useSpace = <TData = Space>(
    id: number,
    options?: UseQueryOptions<Space, AxiosParsedError, TData>,
): UseQueryResult<TData, AxiosParsedError> => {
    return useQuery(
        getSpaceKey(id),
        ({ signal }) => wrapResponsePromise(spaceControllers.getSpace(id, signal)),
        {
            staleTime: 1000 * 60 * 5, // 5 mins
            enabled: !!id,
            refetchOnMount: true,
            ...options,
        },
    );
};
