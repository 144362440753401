import { useAppSelector } from '@infogrid/core-ducks';
import { useLatest, useSelectorWithArgs } from '@infogrid/utils-hooks';
import { connect, getIn } from 'formik';
import keyBy from 'lodash/keyBy';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAutoPaginatedSensorList } from 'apiHooks/sensors/hooks';
import { FolderTree } from 'components/Folders/FolderTree/FolderTree';
import { FormFieldError } from 'forms/fields/FormField';
import { selectAllEntityCollections } from 'schemas/entity';
import { relatedSubfoldersKeyFn } from 'schemas/folder';
import { selectSensorData } from 'schemas/sensor';
import {
    getFolderAllSensorsCascading,
    getFolderAllSubfoldersCascading,
    getFolderSensorsKey,
    getNodeItemId,
    getNodeItemType,
    selectFoldersAllSensorsCascading,
    selectTotalSelectedSensorsCount,
} from 'utils/folderTree';
import { getFieldNameBySensorType, selectSelectedItems } from 'utils/forms';
import { toggleArrayMember, toggleArrayMembers } from 'utils/functional';
import { SENSOR_ID_FIELD, getSensorsByType, useSensorTypeFilter } from 'utils/sensor';
import { getSensorAmountError } from 'utils/sensorTypeSelectionErrors';
import { FieldProps } from 'utils/types';

/**
 * Get an object where the key is the formik field name and value is the error field for it
 * The formik field name is returned because it should be unique and can be therefore used as the `key` prop later.
 */
const selectFieldErrors = (values, { fieldName }) => {
    if (typeof fieldName === 'string') {
        return {
            [fieldName]: getIn(values, fieldName, undefined),
        };
    } else if (typeof fieldName === 'object') {
        const fieldNamesInner = Object.values(fieldName);

        return fieldNamesInner.reduce(
            (map, fieldNameInner) => ({
                ...map,
                [fieldNameInner]: getIn(values, fieldNameInner, undefined),
            }),
            {},
        );
    }

    throw TypeError(`Expected fieldName (${fieldName}) to be string, array or object`);
};

const setSelectedFolders = ({ newSelectedFolders, fieldNameFolders, setFieldValue }) => {
    if (typeof fieldNameFolders === 'string') {
        setFieldValue(fieldNameFolders, newSelectedFolders);
    } else if (typeof fieldNameFolders === 'object') {
        // We need to update multiple fields in the form's values.
        Object.values(fieldNameFolders).forEach((fieldName) =>
            setFieldValue(fieldName, newSelectedFolders),
        );
    }
};

const toggleSelectedSensors = ({
    sensorsToToggle,
    add,
    values,
    sensorData,
    fieldNameSensors,
    setFieldValue,
}) => {
    if (typeof fieldNameSensors === 'string') {
        const fieldName = getFieldNameBySensorType({ fieldName: fieldNameSensors });

        if (fieldName) {
            const oldSelected = getIn(values, fieldName) || [];
            const newSelectedSensors = toggleArrayMembers(
                oldSelected,
                sensorsToToggle,
                add,
            );

            setFieldValue(fieldName, newSelectedSensors);
        }
    } else if (typeof fieldNameSensors === 'object') {
        const sensorsToToggleByType = getSensorsByType(sensorData, sensorsToToggle);

        Object.entries(sensorsToToggleByType).forEach(
            ([sensorType, typedSensorsToToggle]) => {
                const fieldName = getFieldNameBySensorType({
                    sensorType,
                    fieldName: fieldNameSensors,
                });
                const oldSelected = getIn(values, fieldName) || [];
                const newSelectedSensors = toggleArrayMembers(
                    oldSelected,
                    typedSensorsToToggle,
                    add,
                );

                setFieldValue(fieldName, newSelectedSensors);
            },
        );
    }
};

/**
 * Toggle whether a sensor is explicitly checked or not.
 */
const toggleExplicitSelectedSensor = ({
    sensorType,
    itemId,
    wasSelected,
    values,
    setFieldValue,
    fieldNameSensors,
}) => {
    const fieldName = getFieldNameBySensorType({
        sensorType,
        fieldName: fieldNameSensors,
    });

    if (fieldName) {
        const oldSelected = getIn(values, fieldName) || [];
        const newSelectedSensors = toggleArrayMember(oldSelected, itemId, !wasSelected);

        setFieldValue(fieldName, newSelectedSensors);
    }
};

/**
 * Toggle whether a folder is explicitly checked or not.
 *
 * When selecting a folder, it will also de-select all subfolders and sensors within that folder (WEB-674).
 */
const toggleExplicitSelectedFolder = ({
    itemId,
    wasSelected,
    allCollections,
    sensorData,
    values,
    setFieldValue,
    fieldNameFolders,
    fieldNameSensors,
    sensorQuery,
}) => {
    const oldSelectedFolders = selectSelectedItems(values, {
        fieldName: fieldNameFolders,
        verboseFieldName: 'fieldNameFolders',
    });

    if (wasSelected) {
        const newSelectedFolders = toggleArrayMember(
            oldSelectedFolders,
            itemId,
            !wasSelected,
        );

        setSelectedFolders({ newSelectedFolders, fieldNameFolders, setFieldValue });
    } else {
        let newSelectedFolders = oldSelectedFolders;

        // Add clicked folder
        newSelectedFolders = toggleArrayMember(newSelectedFolders, itemId, true);

        // De-select nested subfolders
        const subfoldersCascading = getFolderAllSubfoldersCascading({
            id: itemId,
            allCollections,
        });

        if (subfoldersCascading?.length) {
            newSelectedFolders = toggleArrayMembers(
                newSelectedFolders,
                subfoldersCascading,
                false,
            );
        }

        setSelectedFolders({ newSelectedFolders, fieldNameFolders, setFieldValue });

        // De-select nested sensors
        const sensorsCascading = getFolderAllSensorsCascading({
            id: itemId,
            allCollections,
            allSubfolderIds: subfoldersCascading,
            sensorQuery,
            dedupe: true,
        });

        if (sensorsCascading?.length) {
            toggleSelectedSensors({
                sensorsToToggle: sensorsCascading,
                add: false,
                values,
                sensorData,
                fieldNameSensors,
                setFieldValue,
            });
        }
    }
};

/**
 * De-select a node (folder or sensor) that was previously implicitly checked.
 * By implicitly checked we mean that the node itself was not explicitly checked but a parent folder of that node was.
 */
const deselectImplicitlySelectedNode = ({
    nodeId,
    entity,
    allCollections,
    sensorData,
    values,
    explicitSelected,
    setFieldValue,
    fieldNameFolders,
    fieldNameSensors,
    sensorQuery,
}) => {
    const breadcrumbIds = (entity?.breadcrumbs || []).map((breadcrumb) => breadcrumb.id);
    const explicitlyCheckedParentId = [...breadcrumbIds] // Clone because .reverse is in-place
        .reverse() // Reversing because we care about the 'closest' explicitly checked parent
        .find((folderId) => explicitSelected[`folder-${folderId}`]);

    if (explicitlyCheckedParentId) {
        const nodeType = getNodeItemType(nodeId);
        const itemId = getNodeItemId(nodeId);

        // Figure out which previously implicit folders and sensors to add explicitly
        let implicitFoldersToSelect = [];
        let implicitSensorsToSelect = [];

        // Parent folders of the de-selected item, until the explicitly checked parent (inclusive)
        // In the simplest case this is just [explicitlyCheckedParentId]
        const parentFolders = breadcrumbIds.slice(
            breadcrumbIds.findIndex((folderId) => folderId === explicitlyCheckedParentId),
        );

        parentFolders.forEach((parentFolder) => {
            const subfoldersCollectionKey = relatedSubfoldersKeyFn({
                folderId: parentFolder,
            });
            const sensorCollectionKey = getFolderSensorsKey(parentFolder, sensorQuery);

            const subfoldersToAdd = allCollections[subfoldersCollectionKey] || [];
            const sensorsToAdd = allCollections[sensorCollectionKey] || [];

            implicitFoldersToSelect.push(...subfoldersToAdd);
            implicitSensorsToSelect.push(...sensorsToAdd);
        });

        // Filter out the parent folders
        implicitFoldersToSelect = implicitFoldersToSelect.filter(
            (id) => !parentFolders.includes(id),
        );

        // Filter out the currently de-selected item
        if (nodeType === 'folder') {
            implicitFoldersToSelect = implicitFoldersToSelect.filter(
                (id) => id !== itemId,
            );
        } else if (nodeType === 'sensor') {
            implicitSensorsToSelect = implicitSensorsToSelect.filter(
                (id) => id !== itemId,
            );
        }

        let newSelectedFolders = selectSelectedItems(values, {
            fieldName: fieldNameFolders,
            verboseFieldName: 'fieldNameFolders',
        });

        // Remove explicitly checked parent
        newSelectedFolders = toggleArrayMembers(
            newSelectedFolders,
            [explicitlyCheckedParentId],
            false,
        );
        newSelectedFolders = toggleArrayMembers(
            newSelectedFolders,
            implicitFoldersToSelect,
            true,
        );
        setSelectedFolders({ newSelectedFolders, fieldNameFolders, setFieldValue });
        toggleSelectedSensors({
            sensorsToToggle: implicitSensorsToSelect,
            add: true,
            values,
            sensorData,
            fieldNameSensors,
            setFieldValue,
        });
    } else {
        // eslint-disable-next-line no-console
        console.error(
            'Found an implicitly selected node that has no explicitly selected parent. ' +
                'This should never happen.',
        );
    }
};

const useFolderTreeFieldToggleSelected = ({
    allCollections,
    sensorData,
    values,
    explicitSelected,
    setFieldValue,
    fieldNameFolders,
    fieldNameSensors,
    sensorQuery,
}) => {
    const latestAllCollections = useLatest(allCollections);
    const latestSensorData = useLatest(sensorData);
    const latestValues = useLatest(values);
    const latestExplicitSelected = useLatest(explicitSelected);

    return useCallback(
        /**
         * Toggle a node in the folder tree.
         * @param {string} nodeId - Id of node to toggle
         * @param {boolean} wasSelected - Whether node was selected before
         * @param {Object} entity - The selected entity (folder or sensor)
         */
        (nodeId, wasSelected, entity) => {
            if (nodeId === 'folder-0') {
                return;
            }

            const nodeType = getNodeItemType(nodeId);
            const itemId = getNodeItemId(nodeId);

            const wasExplicitlySelected = latestExplicitSelected.current?.[nodeId];
            const wasImplicitlySelected = wasSelected && !wasExplicitlySelected;

            if (wasImplicitlySelected) {
                deselectImplicitlySelectedNode({
                    nodeId,
                    entity,
                    allCollections: latestAllCollections.current,
                    sensorData: latestSensorData.current,
                    values: latestValues.current,
                    explicitSelected: latestExplicitSelected.current,
                    setFieldValue,
                    fieldNameFolders,
                    fieldNameSensors,
                    sensorQuery,
                });
            } else if (nodeType === 'folder' && fieldNameFolders) {
                toggleExplicitSelectedFolder({
                    itemId,
                    wasSelected,
                    allCollections: latestAllCollections.current,
                    sensorData: latestSensorData.current,
                    values: latestValues.current,
                    setFieldValue,
                    fieldNameFolders,
                    fieldNameSensors,
                    sensorQuery,
                });
            } else if (nodeType === 'sensor' && fieldNameSensors) {
                const sensorType = entity.type_code;

                toggleExplicitSelectedSensor({
                    sensorType,
                    itemId,
                    wasSelected,
                    values: latestValues.current,
                    setFieldValue,
                    fieldNameSensors,
                });
            }
        },
        [
            fieldNameFolders,
            fieldNameSensors,
            latestAllCollections,
            latestSensorData,
            latestValues,
            latestExplicitSelected,
            setFieldValue,
            sensorQuery,
        ],
    );
};

export const useVisibleSelectedSensors = ({
    values,
    fieldNameFolders,
    fieldNameSensors,
    sensorQuery,
}) => {
    const selectedFolders = useMemo(
        () =>
            fieldNameFolders
                ? selectSelectedItems(values, {
                      fieldName: fieldNameFolders,
                      verboseFieldName: 'fieldNameFolders',
                  })
                : [],
        [values, fieldNameFolders],
    );
    const explicitSelectedSensors = useMemo(
        () =>
            selectSelectedItems(values, {
                fieldName: fieldNameSensors,
                verboseFieldName: 'fieldNameSensors',
                dedupe: false,
            }),
        [values, fieldNameSensors],
    );
    // All sensors within selected folders (that we have available)
    const selectedFoldersSensorsCascading = useSelectorWithArgs(
        selectFoldersAllSensorsCascading,
        { ids: selectedFolders, sensorQuery, dedupe: false },
    );

    // Returns explicitly selected sensors and visible implicitly selected sensors
    return useMemo(
        () => [
            ...new Set([...selectedFoldersSensorsCascading, ...explicitSelectedSensors]),
        ],
        [selectedFoldersSensorsCascading, explicitSelectedSensors],
    );
};

/**
 * Formik-connected field for selecting folders and sensors from a tree.
 * The values that the user picks are saved into the Formik internal state (formik.values).
 */
const TreePickerSelectFieldRaw = ({
    formik,
    fieldNameFolders,
    fieldNameSensors,
    sensorTypeFilter,
    labelsFilter,
    readingTypesFilter,
    minSelectedSensors,
    maxSelectedSensors,
    highlightFoldersWithSelectedSensors,
    showTotalSelectedSensors,
    onSelectedSensorsCountChange,
    collectErrors,
    allowFoldersSelection,
    ...props
}) => {
    const { t } = useTranslation('common');
    const selectedFolders = useMemo(
        () =>
            fieldNameFolders
                ? selectSelectedItems(formik.values, {
                      fieldName: fieldNameFolders,
                      verboseFieldName: 'fieldNameFolders',
                  })
                : [],
        [formik.values, fieldNameFolders],
    );
    const explicitSelectedSensors = useMemo(
        () =>
            selectSelectedItems(formik.values, {
                fieldName: fieldNameSensors,
                verboseFieldName: 'fieldNameSensors',
            }),
        [formik.values, fieldNameSensors],
    );

    const {
        data: { sensors: explicitSelectedSensorsData },
    } = useAutoPaginatedSensorList(
        {
            device_names: explicitSelectedSensors,
        },
        { enabled: explicitSelectedSensors.length > 0 },
    );

    const totalSelectedSensorsCount = useSelectorWithArgs(
        selectTotalSelectedSensorsCount,
        { selectedFolders, selectedSensors: explicitSelectedSensors },
    );

    const { sensorQuery } = useSensorTypeFilter(sensorTypeFilter);
    const visibleSelectedSensors = useVisibleSelectedSensors({
        values: formik.values,
        fieldNameFolders,
        fieldNameSensors,
        sensorQuery,
    });

    const sensorData = useAppSelector(selectSensorData);
    const allCollections = useAppSelector(selectAllEntityCollections);

    // Convert selectedSensors/selectedFolders into an object, keyed by node id.
    // This makes our lookups cleaner when determining if something is selected or not
    const explicitSelected = useMemo(() => {
        const result = {};

        selectedFolders.forEach((x) => {
            result[`folder-${x}`] = true;
        });
        explicitSelectedSensors.forEach((x) => {
            result[`sensor-${x}`] = true;
        });

        return result;
    }, [selectedFolders, explicitSelectedSensors]);

    // creates the map of the breadcrumbs (folders) of all explicitly selected sensors
    const selectedSensorsBreadcrumbs = useMemo(() => {
        const explicitSelectedSensorsByName = keyBy(
            explicitSelectedSensorsData,
            SENSOR_ID_FIELD,
        );
        const computedSelectedSensorBreadcrumbs = explicitSelectedSensors.reduce(
            (breadcrumbs, sensorName) => {
                const explicitSelectedSensor = explicitSelectedSensorsByName[sensorName];
                const sensorBreadcrumbs = explicitSelectedSensor?.breadcrumbs || [];

                sensorBreadcrumbs.forEach((breadcrumb) => breadcrumbs.add(breadcrumb.id));

                return breadcrumbs;
            },
            new Set(),
        );

        return computedSelectedSensorBreadcrumbs;
    }, [explicitSelectedSensors, explicitSelectedSensorsData]);

    // highlights the folder with selected sensor if the folder hasn't been selected
    const getShouldNodeBeHighlighted = (entity) => {
        if (highlightFoldersWithSelectedSensors) {
            return selectedSensorsBreadcrumbs.has(entity?.id);
        }

        return false;
    };

    const selectIsSelected = useCallback(
        (nodeId, entity) =>
            // Folder or sensor is explicitly selected
            explicitSelected[nodeId] === true ||
            // Or parent folder is explicitly selected
            (entity?.breadcrumbs || []).some(
                (breadcrumb) => explicitSelected[`folder-${breadcrumb.id}`],
            ),
        [explicitSelected],
    );

    useEffect(() => {
        if (onSelectedSensorsCountChange) {
            onSelectedSensorsCountChange(totalSelectedSensorsCount);
        }
    }, [totalSelectedSensorsCount, onSelectedSensorsCountChange]);

    const toggleSelected = useFolderTreeFieldToggleSelected({
        allCollections,
        sensorData,
        values: formik.values,
        explicitSelected,
        setFieldValue: formik.setFieldValue,
        fieldNameFolders,
        fieldNameSensors,
        sensorQuery,
    });

    const latestSensorData = useLatest(sensorData);
    const minAmountErrors = useMemo(
        () =>
            getSensorAmountError({
                selectedSensors: visibleSelectedSensors,
                limitType: 'min',
                limit: minSelectedSensors,
                sensorData: latestSensorData.current,
            }),
        [visibleSelectedSensors, minSelectedSensors, latestSensorData],
    );
    const maxAmountErrors = useMemo(() => {
        return getSensorAmountError({
            selectedSensors: visibleSelectedSensors,
            limitType: 'max',
            limit: maxSelectedSensors,
            sensorData: latestSensorData.current,
        });
    }, [visibleSelectedSensors, maxSelectedSensors, latestSensorData]);

    useEffect(() => {
        if (collectErrors) {
            const errors = { ...formik.errors };

            if (minAmountErrors?.length > 0) {
                errors.minAmountErrors = minAmountErrors;
            } else {
                delete errors.minAmountErrors;
            }

            if (maxAmountErrors?.length > 0) {
                errors.maxAmountErrors = maxAmountErrors;
            } else {
                delete errors.maxAmountErrors;
            }

            formik.setErrors(errors);
        }
    }, [collectErrors, formik, minAmountErrors, maxAmountErrors]);

    // Note: Currently sensor type amount errors work by loading sensor types from state.
    //       Sensor type errors being like "You can select only up to %(max_limit)i %(sensor_type)s sensors".
    //       This means it will only consider the sensors we have in state (including those in selected folders).
    //       Note that errors are also returned from the backend.
    //       So these sensor type amount errors are more a 'best effort' than the final truth.

    const foldersErrors = fieldNameFolders
        ? selectFieldErrors(formik.errors, {
              fieldName: fieldNameFolders,
          })
        : {};
    const sensorsErrors = selectFieldErrors(formik.errors, {
        fieldName: fieldNameSensors,
    });

    return (
        <>
            <FolderTree
                toggleSelected={toggleSelected}
                selectIsSelected={selectIsSelected}
                sensorTypeFilter={sensorTypeFilter}
                labelsFilter={labelsFilter}
                readingTypesFilter={readingTypesFilter}
                getShouldNodeBeHighlighted={getShouldNodeBeHighlighted}
                allowFoldersSelection={allowFoldersSelection}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            />
            {showTotalSelectedSensors && (
                <p className="bp4-text-small mb-0 mt-2">
                    {t(`You selected {{count: number}} sensors`, {
                        count: totalSelectedSensorsCount,
                        defaultValue___one: `You selected ${totalSelectedSensorsCount} sensors`,
                        defaultValue___other: `You Selected ${totalSelectedSensorsCount} sensors`,
                    })}
                </p>
            )}

            {minAmountErrors &&
                minAmountErrors.map((minAmountError) => (
                    <FormFieldError key={minAmountError} error={minAmountError} />
                ))}
            {maxAmountErrors &&
                maxAmountErrors.map((maxAmountError) => (
                    <FormFieldError key={maxAmountError} error={maxAmountError} />
                ))}
            {Object.entries(foldersErrors).map(([fieldName, errorField]) => (
                <FormFieldError key={fieldName} error={errorField} />
            ))}
            {Object.entries(sensorsErrors).map(([fieldName, errorField]) => (
                <FormFieldError key={fieldName} error={errorField} />
            ))}
        </>
    );
};

TreePickerSelectFieldRaw.propTypes = {
    allowFoldersSelection: PropTypes.bool,
    fieldNameFolders: PropTypes.oneOfType([
        PropTypes.string,
        // Note that we currently don't really care about the object key
        // This used to be an array but for consistency it's an object (like fieldNameSensors)
        PropTypes.objectOf(PropTypes.string),
    ]),
    fieldNameSensors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.string),
    ]).isRequired,
    sensorTypeFilter: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    labelsFilter: PropTypes.arrayOf(PropTypes.number),
    readingTypesFilter: PropTypes.arrayOf(PropTypes.string),
    minSelectedSensors: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.objectOf(PropTypes.number),
        PropTypes.arrayOf(
            PropTypes.arrayOf(
                PropTypes.oneOfType([
                    PropTypes.arrayOf(PropTypes.string),
                    PropTypes.number,
                ]),
            ),
        ),
    ]),
    maxSelectedSensors: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.objectOf(PropTypes.number),
        PropTypes.arrayOf(
            PropTypes.arrayOf(
                PropTypes.oneOfType([
                    PropTypes.arrayOf(PropTypes.string),
                    PropTypes.number,
                ]),
            ),
        ),
    ]),
    showTotalSelectedSensors: PropTypes.bool,
    highlightFoldersWithSelectedSensors: PropTypes.bool,
    onSelectedSensorsCountChange: PropTypes.func,
    ...FieldProps.props,
    name: PropTypes.string, // Not required for this component since we have 2 field names
    collectErrors: PropTypes.bool,
};

TreePickerSelectFieldRaw.defaultProps = {
    allowFoldersSelection: true,
    fieldNameFolders: undefined,
    sensorTypeFilter: undefined, // With undefined, filtering by sensor type is not done
    labelsFilter: undefined, // With undefined, filtering by labels is not done
    readingTypesFilter: undefined, // With undefined, filtering by reading types is not done
    minSelectedSensors: undefined,
    maxSelectedSensors: undefined,
    highlightFoldersWithSelectedSensors: false,
    showTotalSelectedSensors: false,
    onSelectedSensorsCountChange: undefined,
    ...FieldProps.defaults,
    name: null,
    collectErrors: false,
};

const TreePickerSelectField = connect(TreePickerSelectFieldRaw);

export { TreePickerSelectFieldRaw, TreePickerSelectField as default };
