import { defaultFrequencies } from '@infogrid/dashboards-constants';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import DateRange from 'views/dashboards/components/modals/ConfigureWidgetModal/DateRange';
import Sensors from 'views/dashboards/components/modals/ConfigureWidgetModal/Sensors';

import DisplayOptions from './DisplayOptions';
import { calculateDisabledFrequencies } from './utils';

export const widgetConfigurationModalTabs = [
    {
        id: 'sensors',
        name: registerTranslationKey('Sensors'),
        component: Sensors,
        attributes: {
            sensorTypeFilter: [
                SENSOR_TYPE.TYPE_PROXIMITY,
                SENSOR_TYPE.TYPE_PROXIMITY_COUNTER,
            ],
            maxSelectedSensors: 250,
        },
    },
    {
        id: 'date-range',
        name: registerTranslationKey('Date Range'),
        component: DateRange,
    },
    {
        id: 'display-options',
        name: registerTranslationKey('Display Options'),
        component: DisplayOptions,
        attributes: {
            calculateDisabledFrequencies,
            frequencies: defaultFrequencies,
        },
    },
];
