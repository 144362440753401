import { createRequest } from '@infogrid/core-api';
import type { IPagination } from '@infogrid/core-types';
import type {
    Building,
    BuildingDetail,
    BuildingType,
    HierarchyBuilding,
    LocationBuildingForUser,
} from '@infogrid/locations-types';
import type { SensorType } from '@infogrid/sensors-constants';
import type { AxiosPromise } from 'axios';
import qs from 'qs';

import type {
    CreateBuildingParams,
    CreateBuildingResponse,
    EditBuildingParams,
    EditBuildingResponse,
    SetDefaultBuildingResponse,
    GetBuildingCountParams,
    BuildingCountResponse,
    BuildingsFiltersResponse,
} from './types';

export const getBuildings = (
    signal: AbortSignal | undefined,
    params?: { [key: string]: unknown },
): AxiosPromise<IPagination<Building>> =>
    createRequest({
        method: 'get',
        url: '/floorplan/buildings',
        signal,
        options: { params },
    });

export const getBuilding = (
    buildingId: number,
    signal: AbortSignal | undefined,
): AxiosPromise<BuildingDetail> =>
    createRequest({ method: 'get', url: `/floorplan/buildings/${buildingId}`, signal });

export const getDefaultBuilding = (
    signal: AbortSignal | undefined,
): AxiosPromise<BuildingDetail> =>
    createRequest({ method: 'get', url: '/floorplan/buildings/default', signal });

export const getBuildingHierarchy = (
    signal: AbortSignal | undefined,
): AxiosPromise<HierarchyBuilding[]> =>
    createRequest({ method: 'get', url: 'floorplan/buildings/hierarchy', signal });

export const getBuildingHierarchyForUser = (
    signal: AbortSignal | undefined,
    params: unknown,
): AxiosPromise<IPagination<LocationBuildingForUser>> =>
    createRequest({
        method: 'get',
        url: 'floorplan/buildings/user-hierarchy-v2',
        signal,
        options: {
            params,
            paramsSerializer: (queryParams) =>
                qs.stringify(queryParams, { arrayFormat: 'repeat' }),
        },
    });

const setDefaultBuilding = (
    buildingId: number,
): AxiosPromise<SetDefaultBuildingResponse> =>
    createRequest({
        method: 'put',
        url: `floorplan/buildings/${buildingId}/change-default`,
        options: {
            data: {
                is_default: true,
            },
        },
    });

const createBuilding = (
    buildingData: CreateBuildingParams,
): AxiosPromise<CreateBuildingResponse> =>
    createRequest({
        method: 'post',
        url: 'floorplan/buildings',
        options: {
            data: buildingData,
        },
    });

const deleteBuilding = (buildingId: number): AxiosPromise<BuildingDetail> =>
    createRequest({ method: 'delete', url: `floorplan/buildings/${buildingId}` });

const editBuilding = (
    buildingId: number,
    data: EditBuildingParams,
): AxiosPromise<EditBuildingResponse> =>
    createRequest({
        method: 'patch',
        url: `floorplan/buildings/${buildingId}`,
        options: {
            data,
        },
    });

const getBuildingCount = (
    signal: AbortSignal | undefined,
    params: GetBuildingCountParams,
): AxiosPromise<BuildingCountResponse> =>
    createRequest({
        method: 'get',
        url: 'floorplan/count-buildings',
        signal,
        options: {
            params,
        },
    });

export const getBuildingAlertCounts = (
    buildingId: number,
    signal: AbortSignal | undefined,
    params?: { sensor_type?: SensorType[] },
): AxiosPromise<BuildingDetail> =>
    createRequest({
        method: 'get',
        url: `/floorplan/buildings/${buildingId}/count-alerts`,
        signal,
        options: {
            params,
        },
    });

const getBuildingsFilters = (): AxiosPromise<BuildingsFiltersResponse> =>
    createRequest({ method: 'GET', url: '/floorplan/buildings/available-filters' });

const getBuildingTypes = (): AxiosPromise<BuildingType[]> =>
    createRequest({ method: 'get', url: '/floorplan/buildings/types' });

export const controllers = {
    getBuilding,
    getDefaultBuilding,
    getBuildings,
    createBuilding,
    deleteBuilding,
    getBuildingHierarchy,
    getBuildingHierarchyForUser,
    setDefaultBuilding,
    editBuilding,
    getBuildingAlertCounts,
    getBuildingCount,
    getBuildingsFilters,
    getBuildingTypes,
};
