import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import {
    cancelBuildings,
    cancelPaginatedBuildings,
    invalidateBuildings,
    invalidatePaginatedBuildings,
} from '../accessors';
import { controllers } from '../controllers';

export const useCreateBuilding = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('estate');

    return useMutation((buildingData) => controllers.createBuilding(buildingData), {
        onSuccess: async () => {
            await cancelBuildings();
            await invalidateBuildings();
            await cancelPaginatedBuildings();
            await invalidatePaginatedBuildings();

            dispatch(
                toastSuccess({
                    message: t('Building created successfully'),
                }),
            );
        },
        onError(err) {
            const errors = Object.values(err.parsedError.errors).join(' ');

            dispatch(
                toastError({
                    message: `${t('Error while creating the building:')} ${errors}`,
                }),
            );
        },
    });
};
