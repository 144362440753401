import { makeStyles } from '@material-ui/core';

export const useNavbarTextStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.common.white1,
        opacity: 0.7,
        wordBreak: 'break-all',
        padding: theme.spacing(0, 1.75),
        transition: '0.3s ease-out',
        fontSize: 14,
    },
    large: {
        padding: theme.spacing(0, 3.25, 0, 2.5),
        wordBreak: 'normal',
    },
}));
