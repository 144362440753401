import { getLocation } from 'connected-react-router';
import qs from 'qs';
import { select } from 'redux-saga/effects';

/**
 * Map OrganizationUser filter state from location to props.
 *
 * @param state
 * @return {{userName: string, location: }}
 */
export const mapOrganizationUserFilterState = (state) => {
    const location = getLocation(state);

    const { userName = '' } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });

    return {
        location,
        userNameFilter: userName,
    };
};

export const hasOrganizationUserFilter = (state) => {
    const search = mapOrganizationUserFilterState(state);

    return !!search.userNameFilter;
};

/**
 * Format next search for `React Router`
 * @param newFilter - New filtering options
 * @param newFilter.userName - User name filter to apply
 * @param search - Old search
 * @return {string|null}
 */
const formatSearch = ({ userName }, search) => {
    const prev = qs.parse(search, { ignoreQueryPrefix: true });
    const result = { ...prev };

    if (userName) {
        result.user_name = userName;
    } else if (!userName) {
        delete result.user_name;
    }

    return Object.keys(result).length ? qs.stringify(result) : null;
};

export const onOrganizationUserSearchPath = (location, userName = null) => ({
    pathname: location.pathname,
    search: formatSearch({ userName }, location.search),
});

export const selectQuery = (state, initialQuery = null) => {
    const location = getLocation(state);

    if (location?.search) {
        const { user_name: userName } = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        if (!userName) {
            return { ...(initialQuery || {}) };
        }

        return {
            ...(initialQuery || {}),
            user_name: userName,
        };
    }

    return initialQuery;
};

/**
 * Read location from store and parse search for backend.
 * @return {IterableIterator<*>}
 */
export function* buildQueryFromLocation(initialQuery = {}) {
    // Get currently applied filters from URL
    return yield select(selectQuery, initialQuery);
}
