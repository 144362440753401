import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import ProximityCountPreview from 'styles/images/widgets/proximity-count.png';

import ProximityCountWidget from './ProximityCountWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('Proximity Count', { ns: 'dashboard' }),
    type: WIDGET_TYPE.PROXIMITY_COUNT,
    featureFlags: [],
    Component: ProximityCountWidget,
    image: ProximityCountPreview,
    order: 2,
    gridConfig: DEFAULT_GRID_ITEM_CONFIG,
};

export default ProximityCountWidget;
