import { queryClient } from '@infogrid/core-api';
import { useAppDispatch, toastError } from '@infogrid/core-ducks';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type { FeedbackPanelTemplate, SensorShape } from '@infogrid/sensors-constants';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import type { Dispatch } from 'redux';

import { getSensorKey } from 'apiHooks/sensors/getQueryKeys';
import { querySensor } from 'apiHooks/sensors/hooks';

import FeedbackPanelSensorOptions from './FeedbackPanelSensorOptions';
import type {
    FeedbackPanelSensorOptionFormValue,
    IdentifySensorOptionPayload,
} from './types';

/**
 * Function to check whether a sensor with deviceName can be used to create a feedback panel.
 * This essentially checks if the sensor is a touch or touch counter type.
 */
const isSensorAllowedForFeedbackPanel = async (
    deviceName: string,
    dispatch: Dispatch,
    t: TFunction,
): Promise<boolean> => {
    let sensor: SensorShape;

    try {
        sensor = await queryClient.fetchQuery<SensorShape>(
            getSensorKey(deviceName),
            () => querySensor(deviceName, queryClient),
            {
                retry: false,
            },
        );
    } catch {
        dispatch(
            toastError({
                message: t(
                    'Failed to fetch identified sensor. Please retry and make sure you have permissions to this sensor.',
                ),
            }),
        );

        return false;
    }

    if (
        !getSensorConfig(SENSOR_TYPE.TYPE_FEEDBACK_PANEL).sourceSensorTypes?.includes(
            sensor.type_code,
        )
    ) {
        dispatch(
            toastError({
                message: t(
                    'The sensor you’ve attempted to connect is not a touch sensor. You can only identify touch sensors for this setup.',
                ),
            }),
        );

        return false;
    }

    return true;
};

interface FeedbackPanelSensorOptionsContainerProps {
    feedbackPanelTemplate: FeedbackPanelTemplate;
    values: FeedbackPanelSensorOptionFormValue[];
    onIdentifySuccess: (successPayload: {
        deviceName: string;
        sensorOptionIndex: number;
    }) => void;
}

const FeedbackPanelSensorOptionsContainer = ({
    feedbackPanelTemplate,
    values,
    onIdentifySuccess,
}: FeedbackPanelSensorOptionsContainerProps) => {
    const { t } = useTranslation('sensors');
    const dispatch = useAppDispatch();

    const [identifyingOption, setIdentifyingOption] = useState<number | null>(null);

    const [isConfirmAddModalOpen, onCloseConfirmAddModal, onOpenConfirmAddModalBase] =
        useIsOpenState();

    const [confirmAddPayload, setConfirmAddPayload] = useState<
        IdentifySensorOptionPayload | undefined
    >(undefined);

    const onIdentifyStartFor = useCallback((optionId: number) => {
        setIdentifyingOption(optionId);
    }, []);

    const onOpenConfirmAddModal = useCallback(
        (payload: IdentifySensorOptionPayload) => {
            setConfirmAddPayload(payload);
            onOpenConfirmAddModalBase();
        },
        [onOpenConfirmAddModalBase],
    );

    const alreadyIdentifiedSensors: string[] = useMemo(
        () =>
            values
                .filter((sensorOption) => sensorOption.device_name !== null)
                .map((sensorOption) => sensorOption.device_name as string),
        [values],
    );

    const onIdentifySensorOption = useCallback(
        async ({
            deviceName,
            sensorOptionIndex,
            alreadyInFeedbackPanel,
        }: IdentifySensorOptionPayload) => {
            setIdentifyingOption(null);

            if (alreadyInFeedbackPanel) {
                onOpenConfirmAddModal({
                    deviceName,
                    sensorOptionIndex,
                    alreadyInFeedbackPanel: false,
                });
            } else if (alreadyIdentifiedSensors.includes(deviceName)) {
                dispatch(
                    toastError({
                        message: t(
                            'This touch sensor is already added to the feedback panel you are creating',
                        ),
                    }),
                );
            } else {
                if (!(await isSensorAllowedForFeedbackPanel(deviceName, dispatch, t))) {
                    return;
                }

                onIdentifySuccess({
                    deviceName,
                    sensorOptionIndex,
                });
            }
        },
        [alreadyIdentifiedSensors, dispatch, onOpenConfirmAddModal, onIdentifySuccess, t],
    );

    const onConfirmAddModal = useCallback(() => {
        if (confirmAddPayload) {
            onIdentifySensorOption(confirmAddPayload);
        }

        onCloseConfirmAddModal();
    }, [onIdentifySensorOption, confirmAddPayload, onCloseConfirmAddModal]);

    return (
        <FeedbackPanelSensorOptions
            feedbackPanelTemplate={feedbackPanelTemplate}
            values={values}
            identifyingOption={identifyingOption}
            isConfirmAddModalOpen={isConfirmAddModalOpen}
            onCloseConfirmAddModal={onCloseConfirmAddModal}
            onConfirmAddModal={onConfirmAddModal}
            onIdentifyStartFor={onIdentifyStartFor}
            onIdentifySensorOption={onIdentifySensorOption}
        />
    );
};

export default memo(FeedbackPanelSensorOptionsContainer);
