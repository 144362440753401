import { makeStyles } from '@material-ui/core';

export const useConnectionCheckContentStyles = makeStyles(() => ({
    imgContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    img: {
        maxHeight: '150px',
        width: 'auto',
    },
}));
