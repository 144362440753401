import { createContext } from 'react';

interface NavBarMobileContext {
    headerRendered: boolean;
    setHeaderRenderStatus: (status: boolean) => void;
}

const defaultState = {
    headerRendered: false,
    setHeaderRenderStatus: () => {},
};

export default createContext<NavBarMobileContext>(defaultState);
