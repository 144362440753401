import type { QueryKey } from 'react-query';

import type { SmartCleaningActivatedParams, SmartCleaningHierarchyParams } from './types';

export const getSmartCleaningHierarchyKey = (
    params: SmartCleaningHierarchyParams,
): QueryKey => ['smart-cleaning-hierarchy', params];

export const getSmartCleaningHierarchyLocationsKey = (
    params: SmartCleaningHierarchyParams,
): QueryKey => ['smart-cleaning-hierarchy-locations', params];

export const setCleaningRulesKey = (params: SmartCleaningHierarchyParams): QueryKey => [
    'smart-cleaning-rules',
    params,
];

export const getSmartCleaningReportsKey = (): QueryKey => ['smart-cleaning-reports'];

export const getSmartCleaningReportKey = (id: number): QueryKey => [
    'smart-cleaning-reports',
    id,
];
export const getSmartCleaningActivatedCount = (
    params: SmartCleaningActivatedParams,
): QueryKey => ['smart-cleaning-activated-count', params];

export const getSmartCleaningActivatedSpaces = (
    params: SmartCleaningActivatedParams,
): QueryKey => ['smart-cleaning-activated-spaces', params];
