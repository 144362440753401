import type {
    FloorDetail,
    FloorSensorLocation,
    MapItemCoordinates,
} from '@infogrid/locations-types';
import type { SensorType } from '@infogrid/sensors-constants';
import { useIsMobile, useIsTablet } from '@infogrid/utils-hooks';
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import type { Properties } from 'csstype';
import type { MapBrowserEvent } from 'ol';
import { Collection } from 'ol';
import { never, pointerMove } from 'ol/events/condition';
import { getCenter } from 'ol/extent';
import { DragRotateAndZoom, Select } from 'ol/interaction';
import { Vector as LayerVector, Image as ImageLayer } from 'ol/layer';
import type { Options } from 'ol/layer/BaseVector';
import Projection from 'ol/proj/Projection';
import { ImageStatic, Vector } from 'ol/source';
import { Style } from 'ol/style';
import type { Component, ReactElement, ReactNode, RefObject } from 'react';
import { useMemo, memo, useState, useCallback } from 'react';
import { useToggle } from 'react-use';
import { useDebouncedCallback } from 'use-debounce/lib';

import DefaultFloorPlanControls from '../DefaultFloorPlanControls';
import type { DefaultFloorPlanControlsProps } from '../DefaultFloorPlanControls/DefaultFloorPlanControls';
import {
    Map,
    ClickInteraction,
    DropInteraction,
    Interaction,
    Layer,
    Rotation,
    Zoom,
    TooltipContainer,
    TranslateInteractionMode,
} from '../Map';
import type { DragInteractionProps } from '../Map/DragInteraction/DragInteraction';
import DragInteraction from '../Map/DragInteraction/DragInteraction';
import HoverInteraction from '../Map/HoverInteraction';
import OutsideSensorClick from '../Map/OutsideSensorClick';
import { useFloorPlanControls, useInitializeMapState } from '../hooks';
import type {
    FloorPlanControls,
    MapItemId,
    BaseMapItem,
    SelectEvent,
    MapItemMoveStrategy,
    MapFeature,
    BaseControls,
    RenderMapControlsProps,
    FeatureStyleFunc,
    ChangeItemCoordinateFunc,
} from '../types';
import {
    createItemFeature,
    BASE_MAP_INTERACTIONS,
    CUSTOM_SCROLL_INTERACTIONS,
    featureDataExtractor,
    scaleToNumber,
    selectInteractionOptions,
} from '../utils';
import { useStylesFloorMap } from './styles';

export interface FloorMapProps<T extends BaseMapItem> {
    activeItemId?: MapItemId;
    imageHeight: number;
    imageUrl: string;
    imageWidth: number;
    // TODO move out - multiple types of items have different requirements
    isAddItemAvailable?: boolean;
    isDisableZoomByScroll?: boolean;
    // TODO move out - make per item
    isDragItemAvailable?: boolean;
    isSelectItemAvailable?: boolean;
    isItemMoveEnabled?: boolean;
    isWidget?: boolean;
    mapClassName?: string;
    maxZoom?: FloorPlanControls['zoom'];
    minZoom?: FloorPlanControls['zoom'];
    moveStrategy?: MapItemMoveStrategy;
    // TODO move out
    mode?: 'smartCleaning' | 'default';
    items?: T[];
    selectedSensorType: SensorType | null;
    Controls?: Component<DefaultFloorPlanControlsProps>;
    floor: FloorDetail;
    isEditAvailable: boolean;
    outerContainerRef: RefObject<HTMLDivElement>;
    createFeatureStyle?: FeatureStyleFunc<T>;
    onChangeItemCoordinate?: ChangeItemCoordinateFunc;
    getIsItemDraggable?: (item: T) => boolean;
    getItemCursor?: (item: T) => Properties['cursor'];
    // START: These props will be investigated for deprecation in https://infogrid.atlassian.net/browse/DEP-836
    onRotationChange?: (rotation: FloorPlanControls['orientation']) => void;
    onItemSelect?: (id: MapItemId) => void;
    onMapClick?: (coordinates: MapItemCoordinates, feature: MapFeature<T>) => void;
    onZoomChange?: (value: FloorPlanControls['zoom']) => void;
    setIsItemMoveEnabled?: (enabled: boolean) => void;
    // Legacy Floor Plans use a drag and drop interface, but our new Floor Plan doesn't require this interaction
    // If we do decide to re-implement this, we may decide to manage the interaction outside of this component,
    // only exposing more basic interactions (e.g. onMouseUp). So this is probably deprecated.
    onDropItem?: (event: DragEvent, coordinate: FloorSensorLocation) => void;
    // END
    renderControls?: (props: RenderMapControlsProps) => ReactNode;
    renderItemTooltip?: (item: T) => ReactElement;
    renderItemResizeTooltip?: (
        item: MapFeature<T>,
        itemData: T,
        onResize: (feature: MapFeature<T>, coordinates: FloorSensorLocation) => void,
    ) => ReactElement;
    isLoadingFeatures?: boolean;
}

const MAP_BASE_CONTROLS = ['ZOOM_IN', 'ZOOM_OUT', 'CENTER'];

const FloorMap = <T extends BaseMapItem>({
    activeItemId = null,
    floor,
    imageHeight,
    imageUrl,
    imageWidth,
    isAddItemAvailable = false,
    isDisableZoomByScroll = false,
    isDragItemAvailable = false,
    isEditAvailable,
    isItemMoveEnabled = false,
    isSelectItemAvailable = false,
    isWidget = false,
    items = [],
    mapClassName = '',
    maxZoom = 4,
    minZoom = -2,
    moveStrategy = 'clickToEnable',
    outerContainerRef,
    // TODO collapse selectedSensorType, isDragItemAvailable, setIsItemMoveEnabled into SSOT
    selectedSensorType,
    createFeatureStyle,
    getIsItemDraggable,
    getItemCursor,
    onChangeItemCoordinate = () => {},
    onDropItem = () => {},
    onRotationChange = () => {},
    onItemSelect = () => {},
    onZoomChange = () => {},
    setIsItemMoveEnabled = () => {},
    onMapClick = () => {},
    renderControls = (props) => (
        <DefaultFloorPlanControls
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    ),
    renderItemTooltip,
    renderItemResizeTooltip,
    isLoadingFeatures,
}: FloorMapProps<T>) => {
    const styles = useStylesFloorMap();
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();

    useInitializeMapState({ floorId: floor.id });

    // TODO refactor
    const {
        scale: featureScale = 1,
        setScale,
        zoom = 0,
        orientation: rotation = 0,
        zoomChange: handleZoomChange,
        changeRotation: handleRotationChange,
    } = useFloorPlanControls({
        floorId: floor.id,
        initialState: floor,
        isEditAvailable,
        onRotationChange,
        onZoomChange,
    });

    const [isTooltipAvailable, setTooltipAvailability] = useState(true);
    const [hoverFeatureId, setHoverFeatureId] = useState<number | string>();

    const extent = useMemo<[number, number, number, number]>(
        () => [0, 0, imageWidth, imageHeight],
        [imageWidth, imageHeight],
    );

    const centerCoordinate = useMemo(() => getCenter(extent), [extent]);

    const projection = useMemo(
        () =>
            new Projection({
                code: 'xkcd-image',
                units: 'pixels',
                extent,
            }),
        [extent],
    );

    const viewOptions = useMemo(
        () => ({
            projection,
            center: centerCoordinate,
            minZoom,
            maxZoom,
        }),
        [centerCoordinate, projection, minZoom, maxZoom],
    );

    const imageLayerOptions = useMemo(
        () => ({
            source: new ImageStatic({
                url: imageUrl,
                projection,
                imageExtent: extent,
                crossOrigin: 'Anonymous',
            }),
        }),
        [imageUrl, projection, extent],
    );

    const { features, featuresSource, hoveredFeature } = useMemo(() => {
        const featureCollection: MapFeature<T>[] = [];
        let newHoveredFeature: T | undefined;

        items.forEach((item) => {
            const feature = createItemFeature(item);

            const isHover = hoverFeatureId === feature.getId();
            const isActive = activeItemId === feature.getId();

            if (isHover) {
                newHoveredFeature = item;
            }

            if (createFeatureStyle) {
                const style = createFeatureStyle({
                    item,
                    scale: featureScale,
                    isActive,
                    isHover,
                    zoom,
                });

                feature.setStyle(style);
            }

            featureCollection.push(feature);
        });

        const newFeatures = new Collection(featureCollection);
        const newFeaturesSource = new Vector({
            features: newFeatures,
        });

        return {
            features: newFeatures,
            featuresSource: newFeaturesSource,
            hoveredFeature: newHoveredFeature,
        };
    }, [activeItemId, createFeatureStyle, featureScale, hoverFeatureId, items, zoom]);

    const vectorLayerOptions = useMemo<Options>(
        () => ({
            source: featuresSource,
            projection,
            renderBuffer: 50,
        }),
        [projection, featuresSource],
    );

    const selectInteractionListeners = useMemo(
        () => ({
            select: (event: SelectEvent<T>) => {
                const selectedFeature = event.selected[0];

                if (!selectedFeature) {
                    onItemSelect(null);

                    return;
                }

                const item: T = featureDataExtractor(selectedFeature);
                const isActive = activeItemId === selectedFeature.getId();

                if (createFeatureStyle) {
                    const style = createFeatureStyle({
                        item,
                        scale: featureScale,
                        isActive,
                        zoom,
                    });

                    selectedFeature.setStyle(style);
                }

                if (item && activeItemId !== selectedFeature.getId()) {
                    onItemSelect(selectedFeature.getId() as MapItemId);
                }
            },
        }),
        [activeItemId, onItemSelect, createFeatureStyle, featureScale, zoom],
    );

    const selectTranslateListeners = useMemo(
        () => ({
            select: (event: SelectEvent<T>) => {
                const selectedFeature = event.selected[0];

                setTooltipAvailability(!selectedFeature);
            },
        }),
        [],
    );

    const changeItemCoordinate = useCallback(
        (
            ids: MapItemId[],
            coordinates: MapItemCoordinates,
            debounceWrapperFunc?: (callback: () => void) => void,
        ) => {
            setTooltipAvailability(true);

            if (ids.length) {
                onChangeItemCoordinate?.(ids[0], coordinates, debounceWrapperFunc);
            }

            onItemSelect(null);
        },
        [onChangeItemCoordinate, onItemSelect],
    );

    // TranslateInteractionMode props
    const translateModeSelectOptions = useMemo(
        () => ({
            // pointermove events happen on every mouse move, so we permanently add the condition to prevent toggling
            addCondition: (mapBrowserEvent: MapBrowserEvent) => {
                return isMobile || isTablet
                    ? never(mapBrowserEvent)
                    : pointerMove(mapBrowserEvent);
            },
            // Desktop causes pointermove events even when hovering, so select options happen during dragging
            condition: (mapBrowserEvent: MapBrowserEvent) => {
                const pointerMoveCondition =
                    isMobile || isTablet
                        ? pointerMove(mapBrowserEvent) && !mapBrowserEvent.dragging
                        : pointerMove(mapBrowserEvent) && mapBrowserEvent.dragging;

                return isItemMoveEnabled && pointerMoveCondition;
            },
            filter: (feature: MapFeature<T>) =>
                featureDataExtractor(feature)?.uuid === activeItemId,
            // remove the selection once mouse button is released
            removeCondition: (mapBrowserEvent: MapBrowserEvent) => {
                return isMobile || isTablet
                    ? never(mapBrowserEvent)
                    : mapBrowserEvent.type === 'pointerup';
            },
        }),
        [activeItemId, isItemMoveEnabled, isMobile, isTablet],
    );
    const translateFeatureOptions = useMemo(
        () => ({
            filter: (feature: MapFeature<T>) =>
                featureDataExtractor(feature)?.uuid === activeItemId,
        }),
        [activeItemId],
    );

    const [dragging, setDragging] = useState(false);

    // DragInteraction props
    const dragInteractionOptions = useMemo<DragInteractionProps<T>['options']>(
        () => ({
            features,
            // Hides the overlayed feature - can be removed on successful completion of DEP-201
            style: new Style({}),
            pixelTolerance: 20 * scaleToNumber(featureScale),
            condition: ({ map, pixel }) => {
                let movable = false;

                map.forEachFeatureAtPixel(pixel, (feature) => {
                    const featureData = featureDataExtractor(feature);

                    if (featureData) {
                        movable = getIsItemDraggable?.(featureData) || false;
                    }
                });

                return movable;
            },
            filter: (feature: MapFeature<T>) =>
                featureDataExtractor(feature).uuid === activeItemId,
        }),
        [activeItemId, featureScale, features, getIsItemDraggable],
    );
    const onDragInteractionChange = useCallback(
        (feature, [x, y]) => {
            setDragging(false);

            const { coordinates, uuid } = featureDataExtractor(feature);

            // Check if we've really moved
            if (coordinates.x === x && coordinates.y === y) {
                return;
            }

            const { rotation: r, scale } = coordinates;

            changeItemCoordinate([uuid], { x, y, rotation: r, scale });
        },
        [changeItemCoordinate],
    );

    const onOutsideClick = useCallback(() => {
        onItemSelect(null);
        setIsItemMoveEnabled(false);
    }, [setIsItemMoveEnabled, onItemSelect]);

    const onClickInteraction = useCallback(
        (_, coords: [number, number], clickedFeature) => {
            onMapClick({ x: coords[0], y: coords[1] }, clickedFeature);
        },
        [onMapClick],
    );

    const [onResizeDebounceWrapper] = useDebouncedCallback(
        (callback) => callback(),
        750,
        { leading: false },
    );

    const onResize = useCallback(
        (feature, coordinates) => {
            const { uuid } = featureDataExtractor(feature);
            changeItemCoordinate([uuid], coordinates, onResizeDebounceWrapper);
        },
        [changeItemCoordinate, onResizeDebounceWrapper],
    );

    // This can be removed after TooltipContainer is refactored and simplified
    const renderTooltip = useCallback(
        (item) => (renderItemTooltip ? renderItemTooltip(item) : null),
        [renderItemTooltip],
    );
    const renderResizeTooltip = useCallback(
        (item) =>
            renderItemResizeTooltip
                ? renderItemResizeTooltip(item, featureDataExtractor(item), onResize)
                : null,
        [renderItemResizeTooltip, onResize],
    );

    const [isFullScreen, toggleFullScreen] = useToggle(false);

    const cursor = useMemo(() => {
        if (hoveredFeature) {
            if (getItemCursor) {
                return getItemCursor(hoveredFeature);
            }

            if (getIsItemDraggable?.(hoveredFeature)) {
                return 'pointer';
            }
        }

        return undefined;
    }, [getIsItemDraggable, getItemCursor, hoveredFeature]);

    const controls = useMemo<ReactNode>(
        () =>
            renderControls?.({
                centerCoordinate,
                isFullScreen,
                toggleFullScreen,
                maxZoom,
                minZoom,
                currentZoom: zoom,
                isWidget,
                baseControls: MAP_BASE_CONTROLS as BaseControls[],
                scale: featureScale,
                setScale,
                containerRef: outerContainerRef,
            }),
        [
            centerCoordinate,
            featureScale,
            isFullScreen,
            isWidget,
            maxZoom,
            minZoom,
            renderControls,
            toggleFullScreen,
            setScale,
            zoom,
            outerContainerRef,
        ],
    );

    return (
        <div className={styles.mapContainer}>
            <Map
                className={classNames(mapClassName)}
                cursor={cursor}
                viewOptions={viewOptions}
                interactions={
                    isDisableZoomByScroll
                        ? CUSTOM_SCROLL_INTERACTIONS
                        : BASE_MAP_INTERACTIONS
                }
                // @ts-expect-error ReactNode vs ReactNodeLike - incompatibility between proptypes and TS
                controls={controls}
            >
                <Zoom zoom={zoom} onChange={handleZoomChange} />
                <Rotation rotation={rotation} onChange={handleRotationChange} />

                <Layer
                    // @ts-expect-error // TODO
                    Layer={ImageLayer}
                    options={imageLayerOptions}
                />
                <Layer
                    // @ts-expect-error // TODO
                    Layer={LayerVector}
                    options={vectorLayerOptions}
                />

                <Interaction
                    // @ts-expect-error // TODO
                    Interaction={DragRotateAndZoom}
                />

                {isSelectItemAvailable && (
                    <Interaction
                        // @ts-expect-error // TODO
                        Interaction={Select}
                        options={selectInteractionOptions}
                        listeners={selectInteractionListeners}
                    />
                )}

                {moveStrategy === 'clickToEnable'
                    ? isDragItemAvailable &&
                      isItemMoveEnabled && (
                          <TranslateInteractionMode
                              onCoordinateChange={changeItemCoordinate}
                              selectOptions={translateModeSelectOptions}
                              selectListeners={selectTranslateListeners}
                              setInteractionEnabled={setIsItemMoveEnabled}
                              translateFeatureOptions={translateFeatureOptions}
                          />
                      )
                    : !selectedSensorType && (
                          <DragInteraction
                              options={dragInteractionOptions}
                              onChange={onDragInteractionChange}
                              onDragStart={() => setDragging(true)}
                          />
                      )}

                {isAddItemAvailable && (
                    <DropInteraction onDrop={(e, [x, y]) => onDropItem(e, { x, y })} />
                )}

                <ClickInteraction onClick={onClickInteraction} />

                {!isItemMoveEnabled && !dragging && (
                    <HoverInteraction
                        changeActiveFeature={(feature) => setHoverFeatureId(feature)}
                    />
                )}

                {
                    // TODO rename OutsideMapItemClick
                    isSelectItemAvailable && (
                        <OutsideSensorClick
                            activeSensorDeviceId={activeItemId}
                            onOutsideClick={onOutsideClick}
                        />
                    )
                }

                {isTooltipAvailable && !isItemMoveEnabled && (
                    <>
                        <TooltipContainer featureDataExtractor={featureDataExtractor}>
                            {renderTooltip}
                        </TooltipContainer>
                        <TooltipContainer featureDataExtractor={(feature) => feature}>
                            {renderResizeTooltip}
                        </TooltipContainer>
                    </>
                )}
            </Map>
            {isLoadingFeatures && (
                <div className={styles.spinner}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export type FloorMapType = typeof FloorMap;
// Assignment required to preserve Generic inference as TypeScript doesn't have Higher Kinded Types.
export default memo(FloorMap) as FloorMapType;
