import type { AxiosParsedError } from '@infogrid/core-types';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from './controller';
import { getSmartCleaningReportsKey } from './getQueryKeys';
import type { SmartCleaningBuilding } from './types';

export const useGetSmartCleaningReports = (
    options?: UseQueryOptions<SmartCleaningBuilding[], AxiosParsedError>,
): UseQueryResult<SmartCleaningBuilding[], AxiosParsedError> => {
    return useQuery(
        getSmartCleaningReportsKey(),
        () => controllers.getSmartCleaningReports(),
        options,
    );
};
