import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type {
    PartialElectricitySensorUpdate,
    PartialElectricityLEDPulseSensorUpdate,
    SensorShape,
    SensorType,
} from '@infogrid/sensors-constants';
import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

import { controllers } from 'apiHooks/sensors/ts/controllers';

interface UpdateSensorConfigurationParams {
    id: string;
    data: PartialElectricitySensorUpdate | PartialElectricityLEDPulseSensorUpdate;
}

export const useUpdateElectricitySensorConfiguration = (
    typeCode: SensorType,
): UseMutationResult<SensorShape, AxiosParsedError, UpdateSensorConfigurationParams> => {
    return useMutation<SensorShape, AxiosParsedError, UpdateSensorConfigurationParams>(
        ({ id, data }) =>
            wrapResponsePromise(
                controllers.updateElectricitySensorConfiguration({
                    id,
                    typeCode,
                    data,
                }),
            ),
    );
};
