import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import type { FormikErrors } from 'formik';
import { useCallback } from 'react';

import { useFormToast, useFloorPlanParams } from 'apiHooks/base/hooks';
import {
    invalidateBuilding,
    invalidatePaginatedBuildings,
} from 'apiHooks/floorPlan/buildings/accessors';
import { useEditOrCreateBuilding, useBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import {
    selectEditingBuildingStatus,
    toggleEditingBuilding,
    toggleDeletingBuilding,
} from 'ducks/floorPlan';
import { extractNestedObject } from 'utils/extractNestedObject';
import { PaginatedBuildingsParams } from 'views/buildings/pages/floorPlan/FloorPlan';

import { useBuildingDetails } from '../../../hooks/useBuildingDetails';
import EditBuildingModal from './EditBuildingModal';
import type { EditBuildingModalValues } from './types';

const EditBuildingContainer = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const isOpened = useAppSelector(selectEditingBuildingStatus);

    const { buildingId } = useFloorPlanParams();

    const { mutate: updateBuilding } = useEditOrCreateBuilding();
    // TODO: https://infogrid.atlassian.net/browse/WEB-4112
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { building } = useBuilding(buildingId);
    const { handleFormToast } = useFormToast();

    const onClose = useCallback(() => {
        dispatch(toggleEditingBuilding(false));
    }, [dispatch]);

    const handleDelete = useCallback(() => {
        dispatch(toggleDeletingBuilding(true));
    }, [dispatch]);

    const handleEditBuilding = useCallback(
        (submitValues, formik) => {
            const data = {
                name: submitValues.name,
                location: {
                    address: submitValues.address,
                    latitude: submitValues.latitude,
                    longitude: submitValues.longitude,
                    country_code: submitValues.countryCode,
                    city: submitValues.city,
                },
                working_hours: submitValues.working_hours,
            };

            updateBuilding(
                { buildingId: building?.id, data },
                {
                    onSuccess: ({ data: buildingData }) => {
                        invalidatePaginatedBuildings(PaginatedBuildingsParams);
                        invalidateBuilding({ buildingId: buildingData.id });
                        onClose();
                    },
                    onError: (err) => {
                        handleFormToast({
                            formCallback: ({
                                errors,
                                message,
                            }: {
                                errors: FormikErrors<EditBuildingModalValues>;
                                message: string;
                            }) => {
                                const error = extractNestedObject(errors);

                                if (error) {
                                    formik.setErrors(error);
                                }

                                if (message) {
                                    formik.setStatus({ message });
                                }
                            },
                            error: err.parsedError,
                            submitValues,
                        });
                    },
                    onSettled: () => {
                        formik.setSubmitting(false);
                    },
                },
            );
        },
        [updateBuilding, building, onClose, handleFormToast],
    );

    const {
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        handleChange,
        handleLocation,
        values,
        handleReset,
    } = useBuildingDetails({
        onSubmit: handleEditBuilding,
        initialValues: {
            name: building?.name || '',
            countryCode: building?.location?.country_code || '',
            address: building?.location?.address || '',
            latitude: building?.location?.latitude,
            longitude: building?.location?.longitude,
        },
    });

    return (
        <EditBuildingModal
            errors={errors}
            touched={touched}
            isSubmitting={isSubmitting}
            buildingName={values.name}
            buildingAddress={values.address}
            buildingLongitude={values.longitude}
            buildingLatitude={values.latitude}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleDelete={handleDelete}
            onClose={onClose}
            isOpened={isOpened}
            // TODO: fix when "AddressAutocomplete" is rewritten to TS
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            handleLocation={handleLocation}
            handleReset={handleReset}
        />
    );
};

export default EditBuildingContainer;
