import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const useFoldersPickerStyles = makeStyles((theme) => ({
    selectAllContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    checkboxLabel: () => ({
        margin: 0,
    }),
    newFolderButton: {
        marginBottom: theme.spacing(0.5),
    },
    newFolderButtonIcon: {
        fontSize: '20px',
        marginTop: theme.spacing(0.25),
    },
    newFolderButtonLabel: {
        marginLeft: theme.spacing(2),
        textTransform: 'initial',
    },
    folderContainer: ({ active }) => ({
        alignItems: 'center',
        backgroundColor: active ? fade(theme.palette.primary.main, 0.1) : 'initial',
        borderRadius: '4px',
        display: 'flex',
        height: '36px',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        '&:last-of-type': {
            marginBottom: 0,
        },
    }),
    folderInnerWrapper: {
        alignItems: 'center',
        display: 'flex',
        width: `calc(100% - ${theme.spacing(5)}px)`,
    },
    folderIcon: ({ active }) => ({
        color: fade(
            active ? theme.palette.primary.main : theme.palette.text.primary,
            0.8,
        ),
        fontSize: '21px',
        lineHeight: '24px',
        overflow: 'unset',
    }),
    folderLabel: ({ active }) => ({
        color: active ? theme.palette.primary.main : 'inherit',
        marginLeft: theme.spacing(1.5),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }),
    foldersLoader: {
        display: 'block',
        margin: `${theme.spacing(4)}px auto`,
    },
    foldersContainer: {
        maxHeight: '500px',
        overflowY: 'auto',
    },
    folderCheck: {
        padding: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
