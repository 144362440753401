import { makeStyles } from '@material-ui/core';

export const useInstallationFlowButtonStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.primary.contrastText,
        borderColor: '#918D85',
        padding: theme.spacing(0.75, 2),
        marginLeft: 'auto',
        marginRight: theme.spacing(1),
        letterSpacing: '1.25px',
        whiteSpace: 'nowrap',
    },
}));
