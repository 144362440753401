import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import PeopleOccupancyStoplightPreview from 'styles/images/widgets/people-occupancy-stoplight.png';

import PeopleCountingStoplightWidget from './PeopleCountingStoplightWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('People Counting Stoplight', { ns: 'dashboard' }),
    type: WIDGET_TYPE.PEOPLE_COUNTING_STOPLIGHT,
    featureFlags: [],
    Component: PeopleCountingStoplightWidget,
    image: PeopleOccupancyStoplightPreview,
    order: 5,
    gridConfig: DEFAULT_GRID_ITEM_CONFIG,
};

export default PeopleCountingStoplightWidget;
