import { Modal } from '@infogrid/components-material-ui';
import { Button, TextField, Typography, CircularProgress } from '@material-ui/core';
import type { FormikErrors, FormikTouched } from 'formik';
import type { ChangeEvent, FormEvent } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardRenameModalStyles } from './styles';

export interface RenameDashboardModalProps {
    onClose: () => void;
    open: boolean;
    isSubmitting: boolean;
    dashboardName: string;
    submitDisabled: boolean;
    handleChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void;

    errors: FormikErrors<{
        name: string;
    }>;

    touched: FormikTouched<{
        name: string;
    }>;
}

const RenameDashboardModal = ({
    onClose,
    open,
    isSubmitting,
    dashboardName,
    handleChange,
    handleSubmit,
    errors,
    submitDisabled,
    touched,
}: RenameDashboardModalProps) => {
    const styles = useDashboardRenameModalStyles();
    const { t } = useTranslation('dashboard');

    return (
        <Modal onClose={onClose} open={open} fullWidth maxWidth="xs">
            <form onSubmit={handleSubmit}>
                <Modal.Title className={styles.renameModalTitle}>
                    <Typography component="span" variant="h6">
                        {t('Rename Dashboard')}
                    </Typography>
                </Modal.Title>
                <Modal.Content className={styles.content}>
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        variant="outlined"
                        label={t('Dashboard name')}
                        placeholder={t('Enter name')}
                        disabled={isSubmitting}
                        value={dashboardName}
                        onChange={handleChange}
                        error={!!(errors.name && touched.name)}
                    />
                    {errors.name && touched.name && (
                        <Typography color="error" variant="caption">
                            {t(errors.name)}
                        </Typography>
                    )}
                </Modal.Content>
                <Modal.Actions justify="space-between">
                    <Button color="default" onClick={onClose}>
                        <span>{t('Cancel', { ns: 'common' })}</span>
                    </Button>
                    <Button
                        color="primary"
                        data-testid="rename-dashboard-submit"
                        disabled={submitDisabled}
                        variant="contained"
                        type="submit"
                    >
                        {isSubmitting && (
                            <CircularProgress className={styles.spinner} size={22} />
                        )}
                        <span>{t('Save', { ns: 'common' })}</span>
                    </Button>
                </Modal.Actions>
            </form>
        </Modal>
    );
};

export default memo(RenameDashboardModal);
