import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import { useAppSelector } from '@infogrid/core-ducks';
import { ORGANIZATION_FEATURE_FLAG } from '@infogrid/core-feature-flags';
import type { EventsWebhook } from '@infogrid/core-types';
import { selectOrganization } from '@infogrid/user-ducks';
import { useOrganizationFeature } from '@infogrid/user-hooks';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useEventsWebhooks } from 'apiHooks/eventsWebhook/hooks';
import PageContainer from 'components/PageContainer';
import TitleBar from 'components/TitleBar';

import {
    CreateEventsWebhookForm,
    UpdateEventsWebhookForm,
} from '../../components/EventsWebhookForm';
import FilterableWebhookView from '../../components/FilterableWebhookView';
import { useIntegrationsEventsWebhookStyles } from './styles';

const IntegrationsEventsWebhook = (): JSX.Element => {
    const styles = useIntegrationsEventsWebhookStyles();
    const { t } = useTranslation('integrations');

    const isEnabled = useOrganizationFeature(ORGANIZATION_FEATURE_FLAG.EVENTS_WEBHOOK);
    const isFilterableWebhooksEnabled = useOrganizationFeature(
        ORGANIZATION_FEATURE_FLAG.EVENTS_WEBHOOK_FILTERING,
    );
    const organization = useAppSelector(selectOrganization);

    const {
        data: eventsWebhooksData,
        isLoading: loadingEventsWebhooksData,
        isSuccess: loadingEventsWebhooksDataSuccess,
        error: loadingEventsWebhooksDataError,
    } = useEventsWebhooks(
        { organization_id: organization?.id.toString() || '0' },
        { enabled: isEnabled && !isFilterableWebhooksEnabled },
    );

    if (isEnabled && isFilterableWebhooksEnabled) {
        return (
            <PageContainer className={styles.container}>
                <FilterableWebhookView />
            </PageContainer>
        );
    }

    if (!isEnabled) {
        return (
            <PageContainer className={styles.container}>
                <TitleBar large title={t('Events Webhook')} />
                <Alert severity="info">
                    <Typography>
                        {t(
                            'Events webhook can be used to send events data to your HTTPS server.',
                        )}
                    </Typography>
                    <Typography>
                        {t(
                            'Please contact your Infogrid representative to enable this feature.',
                        )}
                    </Typography>
                </Alert>
            </PageContainer>
        );
    }

    if (loadingEventsWebhooksData || !organization) {
        return (
            <PageContainer className={styles.container}>
                <TitleBar large title={t('Events Webhook')} />
                <LoadingPlaceholder />
            </PageContainer>
        );
    }

    if (loadingEventsWebhooksDataSuccess && eventsWebhooksData) {
        // The webhook service backend supports multiple webhooks in future,
        // select only the first for the current org for now
        const eventsWebhook: EventsWebhook | null =
            (eventsWebhooksData.count > 0 && eventsWebhooksData.results[0]) || null;

        return (
            <PageContainer className={styles.container}>
                {eventsWebhook ? (
                    <UpdateEventsWebhookForm eventsWebhook={eventsWebhook} />
                ) : (
                    <CreateEventsWebhookForm />
                )}
            </PageContainer>
        );
    }

    throw new Error(
        loadingEventsWebhooksDataError?.message || 'Unable to contact Webhook Service',
    );
};

export default memo(IntegrationsEventsWebhook);
