import { Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSidebarHeaderStyles } from './styles';

interface Props {
    onCloseSidebar: () => void;
}

const SidebarHeader = ({ onCloseSidebar }: Props) => {
    const styles = useSidebarHeaderStyles();

    const { t } = useTranslation('estate');

    return (
        <div className={styles.container}>
            <div className={styles.closeButtonContainer}>
                <Button
                    className={styles.closeButton}
                    color="primary"
                    data-cypress="close-panel"
                    onClick={onCloseSidebar}
                >
                    {t('Close', { ns: 'common' })}
                    <CloseIcon className={styles.closeIcon} />
                </Button>
            </div>
            <Typography className={styles.title} variant="h6">
                {t('Not in spaces')}
            </Typography>
            <Alert data-cypress="unassigned-sensors-info" severity="info">
                {t(
                    'Sensors without an assigned Space are listed here. Move sensors into a Space to make it easier to view data and target alerts.',
                )}
            </Alert>
        </div>
    );
};

export default memo(SidebarHeader);
