import { ProgressRing } from '@infogrid/components-material-ui';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { Grid, Button } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { MIN_CHART_POINTS } from '../constants';
import type {
    HealthyBuildingScoreWidgetData,
    HealthyBuildingScoreWidget,
} from '../types';
import CompareModal from './CompareModal';
import PillarScores from './PillarScores';
import ScoreTrend from './ScoreTrend';
import TutorialModal from './TutorialModal';
import { useContentStyles, useProgressRingStyles } from './styles';

interface Props {
    data: HealthyBuildingScoreWidgetData;
    widget: HealthyBuildingScoreWidget;
    isLoading: boolean;
}

const Content = ({ data, widget, isLoading }: Props) => {
    const { t } = useTranslation('dashboard');

    const styles = useContentStyles();
    const progressRingClasses = useProgressRingStyles();

    const { all_buildings_score: allBuildingsScore } = data;

    const [isTutorialModalOpen, handleTutorialModalClose, handleTutorialModalOpen] =
        useIsOpenState(false);

    const [isCompareModalOpen, handleCompareModalClose, handleCompareModalOpen] =
        useIsOpenState(false);

    const trendsChartData = allBuildingsScore.historical_scores.map((x) => x.score);

    return (
        <Grid
            className={styles.content}
            container
            alignItems="center"
            data-testid="healthy-building-widget-content"
        >
            <Grid className={styles.ringContainer} item xs={6}>
                <ProgressRing
                    value={allBuildingsScore.score_today}
                    classes={progressRingClasses}
                />

                <Button className={styles.tutorialBtn} onClick={handleTutorialModalOpen}>
                    {t('How does this work?')}
                </Button>
            </Grid>
            <Grid item xs={6}>
                {trendsChartData.length >= MIN_CHART_POINTS && (
                    <ScoreTrend
                        className={styles.scoreTrend}
                        chartData={trendsChartData}
                        scoreToday={allBuildingsScore.score_today}
                        scoreYesterday={allBuildingsScore.score_yesterday}
                        scoreThirtyDaysAgo={allBuildingsScore.score_thirty_days_ago}
                    />
                )}

                <PillarScores pillars={allBuildingsScore.pillars} />

                {!isLoading && (
                    <Button
                        className={styles.compareBuildingsBtn}
                        color="primary"
                        onClick={handleCompareModalOpen}
                    >
                        {t('Compare Buildings')}
                    </Button>
                )}
            </Grid>

            {isCompareModalOpen && (
                <CompareModal onClose={handleCompareModalClose} widget={widget} />
            )}

            <TutorialModal
                open={isTutorialModalOpen}
                onClose={handleTutorialModalClose}
            />
        </Grid>
    );
};

export default memo(Content);
