import type {
    ComponentErrorCallback,
    ErrorComponent,
} from '@infogrid/components-material-ui';
import { ErrorBoundary } from '@infogrid/components-material-ui';
import { useAppSelector } from '@infogrid/core-ducks';
import type { ErrorType } from '@infogrid/core-ducks';
import type { ComponentType } from 'react';

const getError = <T extends { error: ErrorType }>(state: T): ErrorType => state.error;

export interface ViewProps {
    NotFoundComponent?: ComponentType;
    Fallback?: ErrorComponent;
    onComponentError?: ComponentErrorCallback;
    children?: React.ReactNode;
}

const View = ({
    NotFoundComponent,
    Fallback,
    onComponentError,
    children,
}: ViewProps): JSX.Element => {
    const error = useAppSelector(getError);

    if (NotFoundComponent && error?.statusCode === 404) {
        return <NotFoundComponent />;
    }

    return (
        <ErrorBoundary
            onComponentError={onComponentError}
            ErrorHandler={Fallback}
            error={error}
        >
            {children}
        </ErrorBoundary>
    );
};

export default View;
