import { GetKeyValue, mergeKeyOptions } from '@thorgate/spa-entities';
import { call } from 'redux-saga/effects';

const DEFAULT_FILTER_NAME = 'filtered';

export const createFilteredKey = (
    key,
    isFiltered = false,
    filterName = DEFAULT_FILTER_NAME,
) => (isFiltered ? `${key}-${filterName}` : key);
// TODO: Consider changing this to `${key}?${filterName}` (? instead of -)

export const createRelatedKey = (
    parentKey,
    childKey,
    objectId,
    isFiltered = false,
    filterName = DEFAULT_FILTER_NAME,
) => createFilteredKey(`${parentKey}-${objectId}-${childKey}`, isFiltered, filterName);

export const createFilteredKeyFn =
    (key) =>
    (keyOptions = {}) =>
        createFilteredKey(key, !!keyOptions?.filtered, keyOptions?.filterName);

/**
 * TODO what does this do?
 */
export const createRelatedKeyFn =
    (parentKey, childKey, idSelector, filteredSeparate = false) =>
    (keyOptions) => {
        const objectId = idSelector(keyOptions);

        if (objectId === undefined || objectId === null) {
            const keyOptionsJson = JSON.stringify(keyOptions);
            const fakeKey = createRelatedKey(
                parentKey,
                childKey,
                '?',
                filteredSeparate && !!keyOptions?.filtered,
                keyOptions?.filterName,
            );

            throw new Error(
                `Could not select related object id from keyOptions ${keyOptionsJson} for ${fakeKey}`,
            );
        }

        return createRelatedKey(
            parentKey,
            childKey,
            objectId,
            filteredSeparate && !!keyOptions?.filtered,
            keyOptions?.filterName,
        );
    };

export const getFilteredKeyOptions = (keyOptions, filterName = DEFAULT_FILTER_NAME) => ({
    ...keyOptions,
    filtered: true,
    filterName,
});

export const getKeyOptionsWithFiltered = (
    keyOptions,
    location,
    filterName = DEFAULT_FILTER_NAME,
) => {
    if (location?.search) {
        return getFilteredKeyOptions(keyOptions, filterName);
    }

    return keyOptions;
};

export const getKeyValue = (key, match, keyOptions) =>
    GetKeyValue(key, mergeKeyOptions(match, keyOptions));

export const createDetailKey = (baseKey, objectId) => `${baseKey}-${objectId}`;
export const createDetailKeyFn = (baseKey, idSelector) => (keyOptions) => {
    const objectId = idSelector(keyOptions);

    if (objectId === undefined || objectId === null) {
        const keyOptionsJson = JSON.stringify(keyOptions);
        const fakeKey = createDetailKey(baseKey, '?');

        throw new Error(
            `Could not select detail object id from keyOptions ${keyOptionsJson} for ${fakeKey}`,
        );
    }

    return createDetailKey(baseKey, objectId);
};

/**
 * Create related entities fetching helper.
 *
 * @param options
 * @param options.resource {Resource}
 * @param options.createKwargs {function=null}
 * @param options.createQuery {function=null}
 * @param options.mergeResponse {function}
 * @param options.additionalHooks {Array=[]}
 */
export const createFetchRelatedHook = ({
    resource,
    createKwargs,
    createQuery,
    mergeResponse,
    additionalHooks = [],
}) =>
    function* fetchRelatedData(result, match, action) {
        let kwargs = null;
        let query = null;

        if (createKwargs) {
            kwargs = yield call(createKwargs, result, match, action);
        }

        if (createQuery) {
            query = yield call(createQuery, result, match, action);
        }

        let response = yield resource.fetch(kwargs, query);

        response = yield call(mergeResponse, response, result);

        // eslint-disable-next-line no-restricted-syntax
        for (const saga of additionalHooks) {
            response = yield call(saga, response, match, action);
        }

        return response;
    };
