import { wrapResponsePromise } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { convertFloorDetailToFloor } from 'utils/floors';

import { setFloorDetail, setFloors } from '../accessors';
import { controllers } from '../controllers';

export const useCreateFloor = (buildingId) => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('estate');

    return useMutation(
        (payload) => wrapResponsePromise(controllers.createFloor(payload)),
        {
            onSuccess(newFloorDetail) {
                setFloorDetail(newFloorDetail.id, newFloorDetail);

                setFloors(buildingId, (floors) => [
                    ...floors,
                    convertFloorDetailToFloor(newFloorDetail),
                ]);

                dispatch(toastSuccess({ message: t('Floor added successfully') }));
            },
            onError(err) {
                const errors = Object.values(err.parsedError.errors).join(' ');

                dispatch(
                    toastError({
                        message: `${t('Error while adding floor:')} ${errors}`,
                    }),
                );
            },
        },
    );
};
