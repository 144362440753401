import { routesManager } from '@infogrid/core-routing';
import type { Breadcrumb } from '@infogrid/core-types';
import { useMemo } from 'react';

import Breadcrumbs from 'components/Breadcrumbs';

import type { BreadcrumbProps } from './Breadcrumbs/types';

interface FolderBreadcrumbsProps {
    items?: Breadcrumb[];
    classes?: {
        breadcrumbs: string;
        popover: string;
    };
    disabled?: boolean;
}

const FolderBreadcrumbs = ({
    items = [],
    classes = { breadcrumbs: '', popover: '' },
    disabled = false,
}: FolderBreadcrumbsProps): JSX.Element | null => {
    const formattedBreadcrumbs: BreadcrumbProps[] = useMemo(
        () =>
            items.map((item) => ({
                id: item.id,
                text: item.name,
                to: disabled
                    ? '#'
                    : routesManager.resolvePath('folders:details', { folderId: item.id }),
                icon: 'folder-close',
            })),
        [items, disabled],
    );

    if (!items || !items.length) {
        return null;
    }

    return <Breadcrumbs items={formattedBreadcrumbs} classes={classes} />;
};

export default FolderBreadcrumbs;
