import type { LegionnaireSubtypes } from '@infogrid/sensors-constants';
import { LEGIONNAIRE_SUB_TYPES } from '@infogrid/sensors-constants';
import {
    compareDates,
    convertDateTimeInputsToISO,
    CURSOR_TIMESTAMP_PATTERN,
} from '@infogrid/utils-dates';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import type { TempUnit } from '@infogrid/utils-measurements';
import {
    convertCelsiusToFahrenheit,
    TEMP_UNIT,
    TEMP_UNIT_SYMBOL,
} from '@infogrid/utils-measurements';
import { formatToTimeZone } from 'date-fns-timezone';
import type { FormikErrors, FormikValues } from 'formik';
import round from 'lodash/round';
import * as Yup from 'yup';

import { SENSOR_LIMITS } from 'components/InstallationFlow/TemperatureOffsetConfiguration/constants';
import type { TemperatureOffsetParams } from 'utils/types/ts/pipeMonitoringSensors';

export const temperatureOffsetConfigurationSchema = Yup.object().shape({
    temperature: Yup.number()
        .required(registerTranslationKey('This field is required'))
        .default(0.0)
        .typeError(registerTranslationKey('Offset must be a number', { ns: 'sensors' }))
        .test(
            'is-decimal',
            registerTranslationKey(
                'Please ensure the number has no more than 2 decimal places and no more than 5 digits total',
                { ns: 'sensors' },
            ),
            (value) =>
                value !== 0 && !value
                    ? true
                    : !!`${value}`.match(/^[+-]?\d{1,5}(?:\.\d{1,2})?$/),
        ),
    time_of_reading: Yup.string().required(
        registerTranslationKey('This field is required'),
    ),
    date_of_reading: Yup.string().required(
        registerTranslationKey('This field is required'),
    ),
});

export const validateTempOffsetDateOfReading = (
    formValues: FormikValues,
    timeZone: string,
): FormikErrors<TemperatureOffsetParams> => {
    const timeOfReading = convertDateTimeInputsToISO(
        formValues.date_of_reading as string,
        formValues.time_of_reading,
        timeZone,
    );
    const errors: FormikErrors<TemperatureOffsetParams> = {};

    if (
        compareDates(
            timeOfReading,
            formatToTimeZone(new Date(), CURSOR_TIMESTAMP_PATTERN, { timeZone }),
        ) > 0
    ) {
        errors.date_of_reading = registerTranslationKey(
            'There was a problem saving the probe reading time. Please check that your device time is correct',
            { ns: 'sensors' },
        );
    }

    return errors;
};

const getMinTemperature = (subtype: LegionnaireSubtypes): number =>
    SENSOR_LIMITS[subtype].minTemperature ||
    SENSOR_LIMITS[LEGIONNAIRE_SUB_TYPES.GENERIC].minTemperature;

const getMaxTemperature = (subtype: LegionnaireSubtypes): number =>
    SENSOR_LIMITS[subtype].maxTemperature ||
    SENSOR_LIMITS[LEGIONNAIRE_SUB_TYPES.GENERIC].maxTemperature;

export const validateTempOffsetErrors = (
    formValues: FormikValues,
): FormikErrors<TemperatureOffsetParams> => {
    const errors: FormikErrors<TemperatureOffsetParams> = {};

    if (parseFloat(formValues.temperature) === 0) {
        errors.temperature = registerTranslationKey(
            'Probe reading cannot be 0. Please check reading.',
        );

        return errors;
    }

    if (!formValues.temperature) {
        errors.temperature = registerTranslationKey('This field is required');

        return errors;
    }

    return errors;
};

export const TEMP_OFFSET_READING_TYPES = Object.freeze({
    CUSTOM: {
        value: 'custom',
        text: registerTranslationKey('Enter date and time'),
    },
    NOW: {
        value: 'now',
        text: registerTranslationKey('Now'),
    },
});

export const validateTempOffsetWarnings = (
    value: string | unknown,
    supportsLiveCalibration: boolean,
    subtype: LegionnaireSubtypes,
    unit: TempUnit,
): { errored: boolean; warning: string } => {
    const temperature = parseFloat(value as string);

    if (
        temperature &&
        Object.values(LEGIONNAIRE_SUB_TYPES).includes(subtype) &&
        supportsLiveCalibration
    ) {
        const isFahrenheit = unit !== TEMP_UNIT.CELSIUS;
        const minTemperature = isFahrenheit
            ? round(convertCelsiusToFahrenheit(getMinTemperature(subtype)), 2)
            : getMinTemperature(subtype);
        const maxTemperature = isFahrenheit
            ? round(convertCelsiusToFahrenheit(getMaxTemperature(subtype)), 2)
            : getMaxTemperature(subtype);

        if (temperature < minTemperature || temperature > maxTemperature) {
            return {
                errored: true,
                warning: registerTranslationKey(
                    `Probe reading outside expected range (${minTemperature}${TEMP_UNIT_SYMBOL[unit]} - ${maxTemperature}${TEMP_UNIT_SYMBOL[unit]}). Please check reading.`,
                ),
            };
        }
    }

    return {
        errored: false,
        warning: ``,
    };
};

export type TempOffsetReadingType =
    typeof TEMP_OFFSET_READING_TYPES[keyof typeof TEMP_OFFSET_READING_TYPES];
