import { makeStyles } from '@material-ui/core';

const DEFAULT_TRANSITION = 'none';

export const useNavbarLinkStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',

        display: 'flex',
        alignItems: 'center',

        height: '100%',

        width: '100%',

        padding: '12px 26px 12px 20px',

        color: theme.palette.common.white1,
        opacity: 0.7,

        textDecoration: 'none',
        overflow: 'hidden',

        '&:hover': {
            background: 'rgba(255,255,255, 0.2)',
            color: theme.palette.common.white1,
            textDecoration: 'none',
        },

        '&:focus': {
            background: 'rgba(255,255,255, 0.2)',
            color: theme.palette.common.white1,
            textDecoration: 'none',
            outline: 'none',
        },

        '&:before': {
            position: 'absolute',
            top: 0,
            left: -4,

            display: 'none',
            content: '""',

            height: '100%',
            width: '8px',

            borderRadius: '4px',

            background: theme.palette.primary.main,
        },
    },
    textVisible: {
        '& $text': {
            opacity: 1,
        },
    },
    linkActive: {
        background: '#FFFFFF4D',

        color: '#FFFFFF',
        opacity: 1,

        '&:hover': {
            background: '#FFFFFF4D',
        },
        '&:focus': {
            background: '#FFFFFF4D',
        },

        '&:before': {
            display: 'block',
        },
    },
    icon: {
        fontSize: '19px',
        overflow: 'unset',
        textAlign: 'center',
        width: '24px',
    },
    text: ({ textTransition }) => ({
        marginLeft: '10px',

        fontSize: '14px',
        userSelect: 'none',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        opacity: 0,

        transition: textTransition || DEFAULT_TRANSITION,
    }),
    tooltip: {
        fontSize: '14px',
    },
}));
