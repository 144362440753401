import { makeStyles } from '@material-ui/core';

export const useTooltipStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    label: {
        maxWidth: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    value: {
        marginLeft: theme.spacing(1),
    },
}));
