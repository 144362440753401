import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { isSortLabelActive?: boolean }>((theme) => ({
    sortableHeader: {
        height: 'fit-content',
        display: 'flex',
        flexWrap: 'nowrap',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
    },
    headerTitle: (props) => ({
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        maxWidth: '100%',
        color: props.isSortLabelActive ? 'rgba(0, 0, 0, 0.54)' : 'inherit',
    }),
    sortableHeaderTitle: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        maxWidth: 'calc(100% - 15px)',
    },
    icon: (props) => ({
        opacity: props.isSortLabelActive ? 0.5 : 1,
    }),
    infoIcon: {
        fontSize: theme.typography.body1.fontSize,
        marginLeft: theme.spacing(0.25),
        color: theme.palette.common.gray11,
    },
}));
