import { routesManager } from '@infogrid/core-routing';
import { useCreateDashboard } from '@infogrid/dashboards-hooks';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import type { FormikHelpers } from 'formik';
import { useFormik } from 'formik';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { useFormToast } from 'apiHooks/base/hooks';
import { trackDashboardEvent } from 'views/dashboards/utils/analytics';
import { getDashboardNameValidationSchema } from 'views/dashboards/utils/validators';

import CreateDashboardModal from './CreateDashboardModal';

interface Props {
    open: boolean;
    toggleCreateModal: (state: boolean) => void;
    dashboards: Set<string>;
}

type FormValues = {
    name: string;
};

const CreateDashboardContainer = ({ dashboards, open, toggleCreateModal }: Props) => {
    const { handleFormToast } = useFormToast();

    const history = useHistory();

    const { mutate: createDashboard } = useCreateDashboard();

    const handleCreateDashboard = (
        submitValues: FormValues,
        formik: FormikHelpers<FormValues>,
    ) => {
        trackDashboardEvent(USER_ACTIONS.PRESSED, 'create dashboard', {
            name: submitValues.name,
        });

        createDashboard(submitValues.name, {
            onSuccess: (dashboard) => {
                toggleCreateModal(false);
                formik.resetForm();

                history.push(
                    routesManager.resolvePath('dashboards:details', {
                        dashboardId: dashboard.id,
                    }),
                );
            },
            onError: (err) => {
                handleFormToast({
                    submitValues,
                    error: err.parsedError,
                    // @ts-expect-error: we have to rewrite handleError to typescript
                    formCallback({ errors }) {
                        formik.setErrors(errors);
                    },
                });
            },
            onSettled: () => {
                formik.setSubmitting(false);
            },
        });
    };

    const {
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        resetForm,
        values,
    } = useFormik<FormValues>({
        onSubmit: handleCreateDashboard,
        enableReinitialize: true,
        initialValues: {
            name: '',
        },
        validationSchema: getDashboardNameValidationSchema(dashboards),
    });

    const onClose = () => {
        trackDashboardEvent(USER_ACTIONS.PRESSED, 'close add dashboard modal');
        toggleCreateModal(false);
        resetForm();
    };

    return (
        <CreateDashboardModal
            onClose={onClose}
            open={open}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            errors={errors}
            submitDisabled={!values.name.trim() || isSubmitting}
            touched={touched}
        />
    );
};

export default memo(CreateDashboardContainer);
