import {
    Icon,
    Button,
    Checkbox,
    Typography,
    FormControlLabel,
    CircularProgress,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Folder from './Folder/Folder';
import { useFoldersPickerStyles } from './styles';

const FoldersPicker = ({
    folders,
    onCreateNewFolder,
    isFetchingFolders,
    onSelectAllFolders,
    onSelectFolder,
    selectedFoldersIds,
    typeSelector,
    isAllSelected,
    className,
}) => {
    const styles = useFoldersPickerStyles();

    const { t } = useTranslation('floorplan');

    return (
        <div className={classNames(className, styles.container)}>
            <div className={styles.selectAllContainer}>
                <Button
                    classes={{ root: styles.newFolderButton }}
                    color="primary"
                    data-cypress="create-folder"
                    onClick={onCreateNewFolder}
                >
                    <Icon
                        className={classNames('fal fa-plus', styles.newFolderButtonIcon)}
                    />
                    <Typography
                        className={styles.newFolderButtonLabel}
                        variant="subtitle1"
                    >
                        {t('Create New Floor')}
                    </Typography>
                </Button>

                <FormControlLabel
                    classes={{
                        root: styles.checkboxLabel,
                    }}
                    control={
                        <Checkbox
                            checked={isAllSelected}
                            color="primary"
                            data-cypress="select-all"
                            onChange={onSelectAllFolders}
                            size="small"
                        />
                    }
                    label={t('All')}
                    labelPlacement="start"
                    value="selectAll"
                />
            </div>

            {isFetchingFolders && (
                <CircularProgress classes={{ root: styles.foldersLoader }} />
            )}

            {!isFetchingFolders && (
                <div className={styles.foldersContainer}>
                    {folders?.map((folder) => (
                        <Folder
                            active={selectedFoldersIds.includes(folder.id)}
                            isFloor={typeSelector(folder) === 'floor'}
                            id={folder.id}
                            key={folder.id}
                            name={folder.name}
                            onSelect={onSelectFolder}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

FoldersPicker.propTypes = {
    isAllSelected: PropTypes.bool,
    folders: PropTypes.arrayOf(PropTypes.object),
    isFetchingFolders: PropTypes.bool,
    className: PropTypes.string,
    onCreateNewFolder: PropTypes.func.isRequired,
    typeSelector: PropTypes.func.isRequired,
    onSelectFolder: PropTypes.func.isRequired,
    onSelectAllFolders: PropTypes.func.isRequired,
    selectedFoldersIds: PropTypes.arrayOf(PropTypes.number),
};

FoldersPicker.defaultProps = {
    isAllSelected: false,
    className: '',
    folders: [],
    isFetchingFolders: false,
    selectedFoldersIds: [],
};

export default memo(FoldersPicker);
