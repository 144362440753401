import type { ErrorComponentProps } from '@infogrid/components-material-ui';
import { ErrorBoundary, Header } from '@infogrid/components-material-ui';
import { ORGANIZATION_FEATURE_FLAG } from '@infogrid/core-feature-flags';
import { routesManager } from '@infogrid/core-routing';
import type { NamedRouteConfig } from '@infogrid/core-types';
import { useOrganizationFeature } from '@infogrid/user-hooks';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Alert } from '@material-ui/lab';
import * as Sentry from '@sentry/react';
import { memo, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import RenderChildren from 'components/RenderChildren';
import DesktopSolutionsSidebar from 'views/solutionSettings/components/DesktopSolutionsSidebar';
import MobileSolutionsSidebar from 'views/solutionSettings/components/MobileSolutionsSidebar';
import type { SolutionSettingsMenuItemGroup } from 'views/solutionSettings/pages/solutionSettings/types';

import { usePipeMonitoringStyles } from './styles';

interface Props {
    route: NamedRouteConfig;
}

const RootSolutionSettingsViewContainer = ({ route }: Props): JSX.Element => {
    const { t } = useTranslation('solutions');
    const styles = usePipeMonitoringStyles();

    const isMobile = useIsMobile();

    const smartCleaningSetupEnable = useOrganizationFeature(
        ORGANIZATION_FEATURE_FLAG.SMART_CLEANING,
    );

    const menuItems = useMemo(() => {
        const items: SolutionSettingsMenuItemGroup[] = [];

        if (smartCleaningSetupEnable) {
            items.push([
                t('Smart Cleaning'),
                [
                    {
                        name: t('Select Smart Cleaning locations'),
                        url: routesManager.resolvePath(
                            'solution-settings:smart-cleaning-locations',
                        ),
                    },
                    {
                        name: t('Manage cleaning rules'),
                        url: routesManager.resolvePath(
                            'solution-settings:smart-cleaning-rules',
                        ),
                    },
                    {
                        name: t('Schedule sensor reset time'),
                        url: routesManager.resolvePath(
                            'solution-settings:smart-cleaning-reset-time',
                        ),
                    },
                    {
                        name: t('Schedule reports'),
                        url: routesManager.resolvePath(
                            'solution-settings:smart-cleaning-reports',
                        ),
                    },
                ],
            ]);
        }

        items.push([
            t('Pipe Monitoring'),
            [
                {
                    name: t('Manage Thresholds'),
                    url: routesManager.resolvePath(
                        'solution-settings:solution-thresholds',
                    ),
                },
            ],
        ]);

        return items;
    }, [smartCleaningSetupEnable, t]);

    return (
        <>
            <Helmet defaultTitle={t('Manage solutions')} />

            {!isMobile && (
                <Header pageName={t('Manage solutions')} enableIdentifySensor={false} />
            )}

            {isMobile && <MobileSolutionsSidebar menuitems={menuItems} route={route} />}

            <div className={styles.container}>
                {!isMobile && <DesktopSolutionsSidebar menuitems={menuItems} />}
                <div className={styles.content}>
                    <ErrorBoundary
                        ErrorHandler={({ error }: ErrorComponentProps) => {
                            // As we're handling the error, ensure that we
                            // log it to sentry
                            Sentry.captureException(error);

                            // Render the alert, but within a SolutionBlock
                            // to retain some of the page shape
                            return (
                                <Alert severity="warning">
                                    {t(
                                        'We have been unable to load this section due to an error. Please refresh the page and try again.',
                                    )}
                                </Alert>
                            );
                        }}
                    >
                        <RenderChildren routes={route?.routes} />
                    </ErrorBoundary>
                </div>
            </div>
        </>
    );
};

export default memo(RootSolutionSettingsViewContainer);
