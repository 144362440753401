import { queryClient } from '@infogrid/core-api';
import find from 'lodash/find';
import flow from 'lodash/fp/flow';
import property from 'lodash/fp/property';
import some from 'lodash/some';

import { setSensorsInPages } from '../accessors';
import { COUNT_OF_PAGES_TO_TRIGGER_LOOKUP, SENSORS_IN_THE_LIST_KEYS } from './constants';

const getIsSomeSensorInPages = (pages, sensorsMap) =>
    !!find(
        pages,
        flow(property('sensors'), (sensors) =>
            some(sensors, (sensor) => sensorsMap[sensor.device_name]),
        ),
    );

export const updateSensorsInList = (sensorsMap) => {
    const queryCache = queryClient.getQueryCache();

    SENSORS_IN_THE_LIST_KEYS.forEach((listKey) => {
        queryClient.removeQueries(listKey, { active: false, inactive: true });

        const queries = queryCache.findAll(listKey, { active: true });

        queries.forEach(({ state, queryKey }) => {
            const pages = state.data?.pages ?? [];

            if (pages.length > COUNT_OF_PAGES_TO_TRIGGER_LOOKUP) {
                const isSomeSensorInPages = getIsSomeSensorInPages(pages, sensorsMap);

                if (!isSomeSensorInPages) {
                    return;
                }
            }

            setSensorsInPages(sensorsMap, queryKey);
        });
    });
};
