import { makeStyles } from '@material-ui/core';

interface StyleProps {
    isLoading?: boolean;
}

export const usePaginatedTreeSelectorStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '14px',
    },
    parentTable: ({ isLoading }: StyleProps) => ({
        zIndex: 1,
        left: '-100%',
        minWidth: '100%',
        position: 'relative',
        marginTop: theme.spacing(1),
        transition: 'transform 200ms linear',
        transform: isLoading ? 'translateX(+100%)' : 'none',
    }),
    childTable: ({ isLoading }: StyleProps) => ({
        zIndex: 1,
        minWidth: '100%',
        left: '100%',
        position: 'relative',
        marginTop: theme.spacing(1),
        transition: 'transform 200ms linear',
        transform: isLoading ? 'translateX(-100%)' : 'none',
    }),
    table: {
        tableLayout: 'fixed',
    },
    tableContainer: {
        minWidth: '100%',
        minHeight: '100%',
        left: 0,
        boxShadow: 'none',
        border: 'none',
    },
    loadingTablePlaceholder: {
        width: '100%',
        height: '100%',
    },
    checkboxCell: {
        borderBottom: 'none',
        textAlign: 'center',
        width: '5%',
        padding: theme.spacing(0, 1),
    },
    transitionButtonCell: {
        borderBottom: 'none',
        textAlign: 'center',
        padding: theme.spacing(1),
        width: '5%',
    },
    tableCell: {
        borderBottom: 'none',
        padding: 0,
    },
    nameCell: {
        borderBottom: 'none',
        padding: 0,
        width: '50%',
    },
    tableHeadingRow: {
        borderBottom: `1px solid ${theme.palette.common.shell}`,
        height: theme.spacing(6),
    },
    loadingTableBody: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    tableSelectAllRow: {
        borderBottom: `1px solid ${theme.palette.common.shell}`,
    },
    loader: {
        margin: 'auto',
    },
    container: {
        width: '100%',
        position: 'relative',
        height: '100%',
        display: 'flex',
        overflowX: 'hidden',
    },
    sliderShifted: {
        tranform: 'none',
    },
    stickyHeader: {
        top: 0,
        left: 0,
        zIndex: 2,
        position: 'sticky',
        background: '#FFFFFF',
        '& th': {
            borderBottom: `1px solid ${theme.palette.common.shell}`,
        },
    },
}));
