import { useAppSelector } from '@infogrid/core-ducks';
import { selectActiveTimeZone } from '@infogrid/user-ducks';
import { useDateUpdate } from '@infogrid/utils-hooks';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';

import { usePublicDisplayHeaderStyles } from './styles';

interface PublicDisplayHeaderProps {
    buildingName: string;
    floorName: string;
}

export const PublicDisplayHeader = ({
    buildingName,
    floorName,
}: PublicDisplayHeaderProps): JSX.Element => {
    const styles = usePublicDisplayHeaderStyles();

    const date = useDateUpdate(1000);
    const timeZone = useAppSelector(selectActiveTimeZone);
    const formattedTime = format(convertToTimeZone(date, { timeZone }), 'HH:mm');

    return (
        <div className={styles.titleBlockContainer}>
            <div>
                <Typography className={styles.title} variant="h1">
                    {floorName}
                </Typography>
                <Typography className={styles.subtitle} variant="h3">
                    {buildingName}
                </Typography>
            </div>
            <div>
                <Typography className={styles.time} variant="h1">
                    {formattedTime}
                </Typography>
            </div>
        </div>
    );
};
