import { TrendDelta } from '@infogrid/components-material-ui';
import { Typography, Icon, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import TrendLineChart from '../TrendLineChart';
import TrendTooltip from '../TrendTooltip';
import type { TrendTooltipProps } from '../TrendTooltip';
import { getScoreDelta } from '../utils';
import { useScoreTrendStyles } from './styles';

interface ScoreTrendProps {
    className?: string;
    chartData: number[];
}

type Props = ScoreTrendProps & Omit<TrendTooltipProps, 'children'>;

const ScoreTrend = ({
    className = '',
    chartData,
    scoreToday,
    scoreYesterday,
    scoreThirtyDaysAgo,
}: Props) => {
    const { t } = useTranslation('dashboard');

    const styles = useScoreTrendStyles();

    const trendTitle = t(
        `Use the line to see how your score has trended over the last 30 days. The number shows how much it has changed since yesterday.`,
    );

    return (
        <div className={className} data-cypress="score-trend">
            <Typography color="textSecondary" variant="caption">
                {t('Score Trend')}
                <Tooltip title={trendTitle} enterTouchDelay={50} leaveTouchDelay={3000}>
                    <Icon
                        data-cypress="score-trend-info-icon"
                        className={classNames('fal fa-info-circle', styles.aboutIcon)}
                    />
                </Tooltip>
            </Typography>

            <div className={styles.scoreTrendsChartWrapper}>
                <TrendLineChart data={chartData} className={styles.chartContainer} />

                <TrendTooltip
                    scoreToday={scoreToday}
                    scoreYesterday={scoreYesterday}
                    scoreThirtyDaysAgo={scoreThirtyDaysAgo}
                >
                    <div className={styles.trendDeltaContainer}>
                        <TrendDelta
                            fontSize={12}
                            delta={getScoreDelta(scoreToday, scoreYesterday)}
                        />
                    </div>
                </TrendTooltip>
            </div>
        </div>
    );
};

export default memo(ScoreTrend);
