import { makeStyles } from '@material-ui/core';

const DEFAULT_TRANSITION = 'none';

export const useNavbarButtonStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        width: '100%',
        padding: '14px 26px 14px 20px',

        borderRadius: 0,
        color: theme.palette.common.white1,
        opacity: 0.7,

        textDecoration: 'none',

        overflow: 'hidden',

        '& $icon': {
            fontSize: '19px',
            width: '24px',
            lineHeight: '1.2em',
            textAlign: 'center',
        },

        '&:hover': {
            backgroundColor: 'rgba(255,255,255, 0.2)',
            color: theme.palette.common.white1,
            textDecoration: 'none',
        },
        '&:focus': {
            backgroundColor: 'rgba(255,255,255, 0.2)',
            color: theme.palette.common.white1,
            textDecoration: 'none',
            outline: 'none',
        },

        '&:before': {
            position: 'absolute',
            top: 0,
            left: -4,

            display: 'none',
            content: '""',

            height: '100%',
            width: '8px',

            borderRadius: '4px',

            backgroundColor: '#009FE3',
        },
    },
    textVisible: {
        '& $text': {
            opacity: 1,
        },
    },
    linkActive: {
        background: '#FFFFFF4D',

        color: '#FFFFFF',
        opacity: 1,

        '&:hover': {
            background: '#FFFFFF4D',
        },
        '&:focus': {
            background: '#FFFFFF4D',
        },

        '&:before': {
            display: 'block',
        },
    },
    icon: {
        fontSize: '19px',
        overflow: 'unset',
        textAlign: 'center',
        width: '24px',
    },
    text: ({ textTransition }) => ({
        marginLeft: '10px',

        fontSize: '14px',
        fontWeight: 'initial',
        userSelect: 'none',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        opacity: 0,
        lineHeight: '100%',
        transition: textTransition || DEFAULT_TRANSITION,
        textTransform: 'none',
    }),
    tooltip: {
        fontSize: '14px',
    },
    label: {
        justifyContent: 'initial',
    },
}));
