import { Box, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { InstallationFlowComponentProps } from 'components/InstallationFlow/types';
import { InstallationFlowBTLDeviceBack } from 'utils/Images';

import { useInstallationFlowStyles } from '../../styles';
import DeviceIdentifier from '../DeviceIdentifier';
import { useDeviceIdentifierStyles } from './styles';

type Props = Pick<InstallationFlowComponentProps, 'errorMessage'>;

const BTLIdentifier = ({ errorMessage }: Props) => {
    const { t } = useTranslation('sensors');
    const installationFlowStyles = useInstallationFlowStyles();
    const deviceIdentifierStyles = useDeviceIdentifierStyles();

    return (
        <DeviceIdentifier
            errorMessage={errorMessage}
            renderFormContent={({ onChangeSerialNumber, errorText }) => (
                <Box>
                    <Typography
                        variant="body1"
                        data-cypress="instruction-text"
                        className={installationFlowStyles.paragraph}
                    >
                        {t(
                            'Enter the MAC address, which can be found on the back of the device.',
                        )}
                    </Typography>
                    <Box className={deviceIdentifierStyles.imageContainer}>
                        <img
                            src={InstallationFlowBTLDeviceBack}
                            alt="Pointer to BTL MAC address"
                            height={267}
                            className={classNames(installationFlowStyles.centeredImage)}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        label={t('MAC address')}
                        variant="outlined"
                        name="serialNumber"
                        onInput={onChangeSerialNumber}
                        error={!!errorText}
                        helperText={errorText}
                        className={deviceIdentifierStyles.input}
                        data-cypress="mac-address"
                    />
                </Box>
            )}
        />
    );
};

export default memo(BTLIdentifier);
