import { makeStyles } from '@material-ui/core';

const HEADER_HEIGHT = 57;
const FOOTER_HEIGHT = 61;

export const useAddSpaceModalStyles = makeStyles((theme) => ({
    column: {
        [theme.breakpoints.up('sm')]: {
            width: '50%',
            '&:not(:last-child)': {
                marginRight: theme.spacing(3),
            },
        },

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    columnsContainer: {
        display: 'flex',
        flexDirection: 'row',

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    content: {
        overflowY: 'auto',

        [theme.breakpoints.down('xs')]: {
            height: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`,
        },
    },
    loadingSpinner: {
        margin: theme.spacing(4),
    },
    spaceList: {
        margin: theme.spacing(3, 0),
    },
    title: {
        alignItems: 'center',
        display: 'flex',
    },
    titleIcon: {
        marginRight: theme.spacing(1.5),
        overflow: 'unset',
    },
}));
