import { makeStyles } from '@material-ui/core';

export const useStylesBuildingCard = makeStyles((theme) => ({
    buttonArrow: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: '14px',
        marginRight: '10px',
        lineHeight: '1.1em',
    },
}));
