import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import { cleanInactiveAlertConfigurationsQuery } from 'apiHooks/alertConfigurations/accessors';

import { controllers } from './controller';
import type { SmartCleaningReportPayload } from './types';

export const useCreateSmartCleaningReport = (): UseMutationResult<
    SmartCleaningReportPayload,
    AxiosParsedError,
    SmartCleaningReportPayload
> => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('solutions');

    return useMutationWithToast<
        SmartCleaningReportPayload,
        AxiosParsedError,
        SmartCleaningReportPayload
    >(
        (data) => wrapResponsePromise(controllers.createSmartCleaningReport(data)),
        {
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error creating smart cleaning report'),
                    }),
                );
            },
            onSuccess: () => {
                cleanInactiveAlertConfigurationsQuery();
                dispatch(
                    toastSuccess({
                        message: t('Smart cleaning report was created'),
                    }),
                );
            },
        },
        {
            toastMessage: t('Creating smart cleaning report'),
        },
    );
};
