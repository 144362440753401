import { Icon, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSettingsPlaceholderStyles } from './styles';

interface SettingsPlaceholderProps {
    label: string;
    icon: string;
    customStyles?: {
        container?: string;
        label?: string;
        icon?: string;
    };
}

const SettingsPlaceholder = ({
    label,
    icon,
    customStyles,
}: SettingsPlaceholderProps): JSX.Element => {
    const styles = useSettingsPlaceholderStyles();

    const { t } = useTranslation('integrations');

    return (
        <div className={customStyles?.container || styles.container}>
            <Icon className={classNames(icon, customStyles?.icon || styles.icon)} />
            <Typography
                className={customStyles?.label || styles.label}
                variant="subtitle2"
            >
                {t(label)}
            </Typography>
        </div>
    );
};

export default memo(SettingsPlaceholder);
