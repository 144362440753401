import { makeStyles } from '@material-ui/core';

export const useEndUsePickerStyles = makeStyles((theme) => ({
    listHeader: {
        backgroundColor: theme.palette.background.default,
        ...theme.typography.overline,
        borderTop: `1px solid ${theme.palette.borders.listItem}`,
        paddingTop: theme.spacing(2),
        paddingInline: theme.spacing(1.5),
        '&:first-of-type': {
            borderTop: 'none',
        },
    },
    loadingWrapper: {
        width: '100%',
    },
    skeleton: {
        height: 28,
        marginBottom: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
}));
