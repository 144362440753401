import { makeStyles } from '@material-ui/core';

export const useUsersAndTeamsPickerStyles = makeStyles((theme) => ({
    container: {
        border: '2px solid #EDEDED',
        borderRadius: '4px',
        marginTop: theme.spacing(4),
        padding: theme.spacing(1),
    },
    divider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    list: {
        maxHeight: '200px',
        overflowY: 'auto',
        scrollbarWidth: 'none' /* Firefox */,
        '-ms-overflow-style': 'none' /* IE and Edge */,
        '&::-webkit-scrollbar': {
            display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
        },
    },
}));
