import type { SensorShape } from '@infogrid/sensors-constants';
import head from 'lodash/head';

import { updateSingleSensor } from '../updaters';
import { useLatestSensorsEvents } from './useLatestSensorsEvents';

const EMPTY_DEVICE_NAMES: string[] = [];
const EMPTY_SENSORS: SensorShape[] = [];

export const useLatestSensorEvent = (
    sensor?: SensorShape,
    params: Record<string, unknown> = {},
) => {
    // useLatestSensorsEvents expect a single device name or array as a first arg
    // and a sensor array as a second. If we don't have a sensor here (i.e. still
    // loading), then we must pass empty arrays for both.
    const deviceNames = sensor?.device_name || EMPTY_DEVICE_NAMES;
    const sensors = sensor ? [sensor] : EMPTY_SENSORS;

    return useLatestSensorsEvents(deviceNames, sensors, {
        onFetch: ({ events }) => {
            const [mutatedSensor] = events;

            const deviceNameKey = Array.isArray(sensor?.device_name)
                ? head(sensor?.device_name)
                : sensor?.device_name;

            const sensorEventsMap = {
                [deviceNameKey]: mutatedSensor,
            };

            updateSingleSensor(sensorEventsMap);
        },
        enabled: !!sensor?.device_name,
        ...params,
    });
};
