import { routesManager } from '@infogrid/core-routing';
import type { FloorDetail, Floor, BuildingDetail } from '@infogrid/locations-types';
import { Alert } from '@material-ui/lab';
import { memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Select from '../Select';
import { useFloorPlanWrapperStyles } from './styles';

interface Props {
    canUserEdit: boolean;
    isFreezeSettingsConfig?: boolean;
    selectedBuildingId?: number;
    buildings?: BuildingDetail[];
    onSelectBuilding: (id: number) => void;
    selectedFloorId?: number;
    floor?: FloorDetail;
    onSelectFloor: (id: number) => void;
    floors?: Floor[];
    children: () => JSX.Element;
}

const DEFAULT_FLOORS: Floor[] = [];
const DEFAULT_BUILDINGS: BuildingDetail[] = [];

const FloorPlanWrapper = ({
    canUserEdit,
    isFreezeSettingsConfig,
    selectedBuildingId,
    onSelectBuilding,
    selectedFloorId,
    floor,
    onSelectFloor,
    buildings = DEFAULT_BUILDINGS,
    floors = DEFAULT_FLOORS,
    children,
}: Props): JSX.Element => {
    const styles = useFloorPlanWrapperStyles();
    const { t } = useTranslation('dashboard');

    const isValidBuilding = !!(
        selectedBuildingId && buildings.find((b) => b.id === selectedBuildingId)
    );

    const isValidFloor = !!(
        selectedFloorId && floors.find((b) => b.id === selectedFloorId)
    );

    const isFloorPlanWithoutImage = !floor?.image?.image_file;

    const isDisabledSelect =
        (isFreezeSettingsConfig &&
            isValidBuilding &&
            isValidFloor &&
            !isFloorPlanWithoutImage) ||
        !canUserEdit;

    const buildingSelect = (
        <Select
            items={buildings}
            selectedItemId={selectedBuildingId}
            onSelect={onSelectBuilding}
            defaultLabel={t('Select Building')}
            disabled={isDisabledSelect}
        />
    );

    const floorSelect = (
        <Select
            items={floors}
            selectedItemId={selectedFloorId}
            onSelect={onSelectFloor}
            defaultLabel={t('Select Floor')}
            disabled={isDisabledSelect}
        />
    );

    if (!selectedBuildingId) {
        if (!buildings.length) {
            return (
                <div className={styles.alertContainer}>
                    <Trans t={t}>
                        <Alert severity="info" className={styles.alert}>
                            {"You don't have access to any buildings. Please "}
                            <NavLink
                                target="_blank"
                                to={routesManager.resolvePath('buildings')}
                            >
                                {'go into Floor View '}
                            </NavLink>
                            {
                                'to create one or request access from your organization administrator.'
                            }
                        </Alert>
                    </Trans>
                </div>
            );
        }

        return (
            <>
                <div className={styles.controlsContainer}>{buildingSelect}</div>
                <div className={styles.alertContainer}>
                    <Alert severity="info" className={styles.alert}>
                        {t(
                            'Try selecting an alternative building using the filters above.',
                        )}
                    </Alert>
                </div>
            </>
        );
    }

    if (!isValidBuilding) {
        if (buildings.length) {
            return (
                <>
                    <div className={styles.controlsContainer}>{buildingSelect}</div>
                    <div className={styles.alertContainer}>
                        <Alert severity="error" className={styles.alert}>
                            {t(
                                'Invalid configuration. Try selecting an alternative floor and{{divider}}or building using the filters above.',
                                {
                                    divider: '/',
                                    defaultValue:
                                        'Invalid configuration. Try selecting an alternative floor and/or building using the filters above.',
                                },
                            )}
                        </Alert>
                    </div>
                </>
            );
        }

        return (
            <div className={styles.alertContainer}>
                <Alert severity="error" className={styles.alert}>
                    {t('No buildings or floors to display. ')}
                    <NavLink target="_blank" to={routesManager.resolvePath('buildings')}>
                        {t('Create a new building')}
                    </NavLink>
                    .
                </Alert>
            </div>
        );
    }

    if (!floors?.length) {
        return (
            <>
                <div className={styles.controlsContainer}>{buildingSelect}</div>
                <div className={styles.alertContainer}>
                    <Trans t={t}>
                        <Alert severity="info" className={styles.alert}>
                            {
                                'The selected building does not have any floors yet. Please '
                            }
                            <NavLink
                                target="_blank"
                                to={routesManager.resolvePath('buildings', {
                                    buildingId: selectedBuildingId,
                                })}
                            >
                                {'go into Floor View'}
                            </NavLink>
                            {' to create new floors.'}
                        </Alert>
                    </Trans>
                </div>
            </>
        );
    }

    if (!selectedFloorId) {
        return (
            <>
                <div className={styles.controlsContainer}>
                    {buildingSelect}
                    {floorSelect}
                </div>

                <div className={styles.alertContainer}>
                    <Alert severity="info" className={styles.alert}>
                        {t(
                            'Try selecting a floor and{{divider}}or building using the filters above.',
                            {
                                divider: '/',
                                defaultValue:
                                    'Try selecting a floor and/or building using the filters above.',
                            },
                        )}
                    </Alert>
                </div>
            </>
        );
    }

    if (!isValidFloor) {
        return (
            <>
                <div className={styles.controlsContainer}>
                    {buildingSelect}
                    {floorSelect}
                </div>
                <div className={styles.alertContainer}>
                    <Alert severity="error" className={styles.alert}>
                        {t(
                            'Invalid configuration. Try selecting an alternative floor and{{divider}}or building using the filters above.',
                            {
                                divider: '/',
                                defaultValue:
                                    'Invalid configuration. Try selecting an alternative floor and/or building using the filters above.',
                            },
                        )}
                    </Alert>
                </div>
            </>
        );
    }

    if (isFloorPlanWithoutImage) {
        return (
            <>
                <div className={styles.controlsContainer}>
                    {buildingSelect}
                    {floorSelect}
                </div>

                <div className={styles.alertContainer}>
                    <Trans t={t}>
                        <Alert severity="info" className={styles.alert}>
                            {
                                'The selected floor does not have an image. Please go to the '
                            }
                            <NavLink
                                target="_blank"
                                to={routesManager.resolvePath('buildings:floor', {
                                    buildingId: selectedBuildingId,
                                    floorId: selectedFloorId,
                                })}
                            >
                                {'floor view'}
                            </NavLink>
                            {' to upload a floor plan image.'}
                        </Alert>
                    </Trans>
                </div>
            </>
        );
    }

    return children();
};

export default memo(FloorPlanWrapper);
