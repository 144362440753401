import { makeStyles } from '@material-ui/core';

export const usePublicDisplayWidgetGridStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        padding: theme.spacing(10),
    },
    bottomRowValue: {
        fontSize: 90,
    },
}));
