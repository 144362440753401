import { makeStyles } from '@material-ui/core';

export const useFloorSectionStyles = makeStyles((theme) => ({
    spaceHeader: {
        fontWeight: 'bold',
    },
    link: {
        color: theme.palette.primary.main,
    },
}));
