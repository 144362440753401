import type { Floor } from '@infogrid/locations-types';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { useFormik } from 'formik';
import { memo, useCallback, useState } from 'react';

import { useEditFloor } from 'apiHooks/floorPlan/floors/hooks';

import DeleteFloorModal from '../DeleteFloorModal';
import EditFloorModal from './EditFloorModal';
import { editFloorValidationSchema } from './consts';
import type { EditFloorModalValues } from './types';

interface Props {
    buildingId: number;
    floor: Floor;
    onClose: () => void;
    open: boolean;
}

const EditFloorModalContainer = ({ buildingId, floor, onClose, open }: Props) => {
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const [isDeleteFloorModalOpen, _1, _2, toggleDeleteFloorModal] = useIsOpenState();

    const { mutate: updateFloor } = useEditFloor();

    const formik = useFormik<EditFloorModalValues>({
        enableReinitialize: true,
        initialValues: {
            name: floor.name,
            occupant_capacity: floor.occupant_capacity,
        },
        onSubmit(values) {
            setSubmitting(true);

            const floorValues = {
                name: values.name,
                occupant_capacity: values.occupant_capacity,
            };

            updateFloor(
                /* Remove after useEditFloor hook is rewritten to TypeScript */
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                /* @ts-ignore */
                { floorId: floor.id, buildingId, data: floorValues },
                {
                    onSuccess() {
                        setSubmitting(false);
                        onClose();
                    },
                },
            );
        },
        validationSchema: editFloorValidationSchema,
    });

    const handleDeleteConfirm = useCallback(() => {
        toggleDeleteFloorModal();
        onClose();
    }, [onClose, toggleDeleteFloorModal]);

    return (
        <>
            <EditFloorModal
                floor={floor}
                formik={formik}
                isSubmitting={isSubmitting}
                onClose={onClose}
                onDelete={toggleDeleteFloorModal}
                open={open}
            />
            <DeleteFloorModal
                floor={floor}
                onClose={toggleDeleteFloorModal}
                onConfirm={handleDeleteConfirm}
                open={isDeleteFloorModalOpen}
            />
        </>
    );
};

export default memo(EditFloorModalContainer);
