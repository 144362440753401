import type { SensorShape } from '@infogrid/sensors-constants';
import { i18n } from '@infogrid/utils-i18n';
import { isNumber } from 'is-what';

import { getSensorsByType } from 'utils/sensor';

/**
 * @param minLimit
 * @param sensorType
 * @returns {string} - Returns the error text to show if the user does not select enough sensors of a certain type
 */
export const getMinAmountErrorMsg = (minLimit: number, sensorType: string): string =>
    i18n.t('You need to select at least {{count}} {{sensorType}} sensor', {
        ns: 'sensors',
        count: minLimit,
        sensorType,
    });

/**
 * @param maxLimit
 * @param sensorType
 * @returns {string} - Returns the error text to show if the user has selected too many sensors of a certain type
 */
export const getMaxAmountErrorMsg = (maxLimit: number, sensorType: string): string =>
    i18n.t('You can select only up to {{count}} {{sensorType}} sensor', {
        ns: 'sensors',
        count: maxLimit,
        sensorType,
    });

export const getAmountErrorMsg = (
    limitType: 'min' | 'max',
    selectedSensorsCount: number,
    sensorType: string,
): string | null => {
    if (limitType === 'min') {
        return getMinAmountErrorMsg(selectedSensorsCount, sensorType);
    }

    if (limitType === 'max') {
        return getMaxAmountErrorMsg(selectedSensorsCount, sensorType);
    }

    return null;
};

export const sensorsCountViolatesLimit = (
    sensorsCount: number,
    limitType: 'min' | 'max',
    limit: number,
): boolean => {
    return (
        (limitType === 'min' && sensorsCount < limit) ||
        (limitType === 'max' && sensorsCount > limit)
    );
};

interface SensorsByTypeObj {
    [key: string]: SensorShape[];
}

interface SensorAmountErrorSensorData {
    [key: string]: SensorShape;
}

interface SensorAmountLimitObj {
    // TODO technically the key is a sensor type
    [key: string]: number;
}

interface SensorAmountErrorParams {
    selectedSensors: string[];
    limitType: 'min' | 'max';
    limit: number | [string[], number][] | SensorAmountLimitObj;
    sensorData: SensorAmountErrorSensorData;
}

const getSensorAmountErrorFromArray = (
    limit: [string[], number][],
    sensorsByType: SensorsByTypeObj,
    limitType: SensorAmountErrorParams['limitType'],
) => {
    return limit.reduce((msgs: string[], config) => {
        const [types, count] = config;

        const selectedSensorsByTypes = types.reduce((acc, type: string) => {
            return acc + (sensorsByType[type] || []).length;
        }, 0);

        if (sensorsCountViolatesLimit(selectedSensorsByTypes, limitType, count)) {
            const errorMsg = getAmountErrorMsg(limitType, count, types.join(', '));

            if (errorMsg !== null) {
                msgs.push(errorMsg);
            }
        }

        return msgs;
    }, []);
};

const getSensorAmountErrorFromObject = (
    limit: SensorAmountLimitObj,
    sensorsByType: SensorsByTypeObj,
    limitType: SensorAmountErrorParams['limitType'],
) => {
    return Object.entries(limit)
        .filter(([sensorType, limitValue]) =>
            sensorsCountViolatesLimit(
                (sensorsByType[sensorType] || []).length,
                limitType,
                limitValue,
            ),
        )
        .map(([sensorType, limitValue]) =>
            getAmountErrorMsg(limitType, limitValue, sensorType),
        )
        .filter((errorMsg) => errorMsg !== null) as string[];
};

/**
 *
 * @param selectedSensors - sensors the user has selected
 * @param limitType - 'min' or 'max'
 * @param limit - the minimum or maximum amount of selected sensors (by type if necessary)
 *      For example: {touch: 1, proximity: 1}, or 1 (if all sensors in selectedSensors are of the same type)
 * @param sensorData - sensor data like {[id]: { type_code: '', ... }}
 * @returns - if any of the sensors selected violate either the min or max amount limit for that type,
 *      then returns the error message to display. If the selected sensor counts are within limits, then returns null.
 */
export const getSensorAmountError = ({
    selectedSensors,
    limitType,
    limit,
    sensorData,
}: SensorAmountErrorParams): string[] | null => {
    if (!selectedSensors) {
        return null;
    }

    if (isNumber(limit)) {
        // The picker handles only one type of sensors, so we can just count
        // the number of selected sensors and compare that to the allowed max
        if (sensorsCountViolatesLimit(selectedSensors.length, limitType, limit)) {
            const errorMsg = getAmountErrorMsg(limitType, limit, '');

            return errorMsg ? [errorMsg] : null;
        }

        return null;
    }

    const sensorsByType: SensorsByTypeObj = getSensorsByType(sensorData, selectedSensors);

    if (Array.isArray(limit)) {
        return getSensorAmountErrorFromArray(limit, sensorsByType, limitType);
    }

    if (typeof limit === 'object' && limit !== null) {
        // Check for each type if the selected sensors are violating the min/max requirement
        return getSensorAmountErrorFromObject(limit, sensorsByType, limitType);
    }

    return null;
};
