import PropTypes from 'prop-types';

import { useBuildingDetails } from '../../../../hooks/useBuildingDetails';
import BuildingDetails from './BuildingDetails';

const BuildingDetailsContainer = ({
    title,
    handleBack,
    buildingName,
    handleCreateBuilding,
}) => {
    const {
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        handleChange,
        handleLocation,
        values,
    } = useBuildingDetails({
        onSubmit: handleCreateBuilding,
        initialValues: {
            name: buildingName,
            address: '',
            countryCode: '',
            latitude: null,
            longitude: null,
            occupant_capacity: undefined,
        },
    });

    return (
        <BuildingDetails
            title={title}
            errors={errors}
            touched={touched}
            isSubmitting={isSubmitting}
            buildingName={values.name}
            buildingAddress={values.address}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleBack={handleBack}
            handleLocation={handleLocation}
        />
    );
};

BuildingDetailsContainer.propTypes = {
    title: PropTypes.string,
    buildingName: PropTypes.string,
    handleBack: PropTypes.func.isRequired,
    handleCreateBuilding: PropTypes.func.isRequired,
};

BuildingDetailsContainer.defaultProps = {
    title: '',
    buildingName: '',
};

export default BuildingDetailsContainer;
