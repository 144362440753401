import { makeStyles, fade } from '@material-ui/core';

export const useDateRangePickerStyles = makeStyles((theme) => ({
    periodsList: {
        display: 'flex',
        margin: theme.spacing(0, 2.5),
        '& > .MuiChip-root': {
            marginRight: theme.spacing(1),
            '&:last-child': {
                marginRight: 0,
            },
        },
        flexWrap: 'wrap',
    },
    period: {
        backgroundColor: fade(theme.palette.common.gray1, 0.15),
        marginBottom: theme.spacing(2.5),
    },
    periodActive: {
        '&, &:hover, &:focus': {
            backgroundColor: fade(theme.palette.primary.main, 0.1),
            color: theme.palette.primary.main,
        },
    },
}));
