import { useAppSelector } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { COLD_LEGIONNAIRE_SUB_TYPES } from '@infogrid/remote-monitoring-types';
import { getSensor } from '@infogrid/sensors-configuration';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type {
    LegionnaireSubtypes,
    SensorConfigurationShape,
} from '@infogrid/sensors-constants';
import { ELECTRICITY_SENSOR_TYPES } from '@infogrid/solution-views-electricity';
import { selectUserPreferredTempUnit } from '@infogrid/user-ducks';
import { TEMP_UNIT, convertFahrenheitDeltaToCelsius } from '@infogrid/utils-measurements';
import omit from 'lodash/omit';
import round from 'lodash/round';
import { memo } from 'react';

import { useBuildingsHierarchy } from 'apiHooks/floorPlan/buildings/hooks';
import { useLabels } from 'apiHooks/labels/hooks';
import { invalidateSensor } from 'apiHooks/sensors/accessors';
import { useLatestSensorEvent } from 'apiHooks/sensors/hooks';
import { useConfigureSensor } from 'apiHooks/sensors/installation/hooks';
import type { InstallationFlowComponentProps } from 'components/InstallationFlow/types';
import { SENSOR_ID_FIELD } from 'utils/sensor';

import { useInstallationFlowContext } from '../../context';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../../reducer';
import Configuration from '../ConfigurationComponent';
import { useConfigurationFormik } from '../utils';
import { NO_SPACE_OPTION } from '../utils/consts';
import type {
    ConfigurationFormValues,
    ConfigurationFormSubmitData,
} from '../utils/types';

type Props = Pick<InstallationFlowComponentProps, 'resetProgress'>;

const ConfigurationContainer = ({ resetProgress }: Props) => {
    const { sensor, sensorName } = useInstallationFlowContext();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    if (!sensor) {
        throw new Error('Sensor must not be undefined');
    }

    const deviceName = sensor?.device_name;
    const deviceType = sensor.type_code;

    const userPreferredTempUnit = useAppSelector(selectUserPreferredTempUnit);

    // Set up polling
    useLatestSensorEvent(sensor);

    const {
        // @ts-expect-error - WEB-4196 fix this when typescripting useLabels
        data: { labels },
    } = useLabels();

    const { data: buildingHierarchy } = useBuildingsHierarchy();

    const { floorMap = {}, buildingsData = [] } = buildingHierarchy ?? {};

    const { mutate: configureSensor } = useConfigureSensor();

    const sensorConfig = getSensor(sensor);
    const supportsLiveCalibration = sensorConfig?.supportsLiveCalibration();
    const isOnline = sensorConfig?.getIsOnline();

    const formik = useConfigurationFormik({
        deviceType,
        sensorName,
        floorMap,
        buildingsData,
        onSubmit: (formValues: ConfigurationFormValues) => {
            const { building, floor, space, labels: labelsValues, sub_type } = formValues;
            const folderId = space?.id || floor?.id || building?.id;

            const data: ConfigurationFormSubmitData = {
                ...omit(formValues, 'space', 'temperature_offset'),
                // The backend API endpoint expects only IDs
                building: building?.id,
                floor: floor?.id,
                /**
                 * INFO: we are mapping 'space' to 'room' here
                 * because in the nearest future we plan to get
                 * rid of 'room' and use 'space' instead but
                 * its not changed on the API side yet
                 */
                room: NO_SPACE_OPTION.id === space?.id ? null : space?.id,
                labels: labelsValues.map((label) => label.id),
            };

            if (formik.values.temperature_offset) {
                const offset = Number(formik.values.temperature_offset);
                const convertedOffset =
                    userPreferredTempUnit === TEMP_UNIT.FAHRENHEIT
                        ? convertFahrenheitDeltaToCelsius(offset)
                        : offset;

                data.temperature_offset = round(convertedOffset, 2);
            }

            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
            });

            configureSensor(
                // @ts-expect-error - fix this when typescripting useConfigureSensor
                {
                    deviceName,
                    data,
                },
                {
                    onSuccess: () => {
                        invalidateSensor(sensor?.[SENSOR_ID_FIELD]);

                        if (
                            deviceType === SENSOR_TYPE.TYPE_LEGIONNAIRE &&
                            supportsLiveCalibration &&
                            isOnline !== false
                        ) {
                            const legionnaireAction = COLD_LEGIONNAIRE_SUB_TYPES.includes(
                                sub_type as LegionnaireSubtypes,
                            )
                                ? INSTALLATION_FLOW_ACTIONS.SELECT_TEMPERATURE_OFFSET_MODE
                                : INSTALLATION_FLOW_ACTIONS.SET_TEMPERATURE_OFFSET;
                            const sensorConfiguration = {
                                ...sensor.sensor_configuration,
                                sub_type,
                            } as SensorConfigurationShape;
                            dispatchInstallationFlowContext({
                                type: legionnaireAction,
                                sensor: {
                                    ...sensor,
                                    sensor_configuration: sensorConfiguration,
                                },
                            });

                            return;
                        }

                        if (ELECTRICITY_SENSOR_TYPES.includes(deviceType)) {
                            const nextStep = {
                                [SENSOR_TYPE.TYPE_ELECTRICITY_CT_CLAMP]:
                                    INSTALLATION_FLOW_ACTIONS.MONNIT_CONFIGURATION,
                                [SENSOR_TYPE.TYPE_ELECTRICITY_LED_PULSE_METER]:
                                    INSTALLATION_FLOW_ACTIONS.FLUDIA_CONFIGURATION,
                                [SENSOR_TYPE.TYPE_ELECTRICITY_DRY_PULSE_COUNTER]:
                                    INSTALLATION_FLOW_ACTIONS.MONNIT_DRY_PULSE_CONFIGURATION,
                            } as const;

                            dispatchInstallationFlowContext({
                                type: nextStep[deviceType as keyof typeof nextStep],
                                sensor: {
                                    ...sensor,
                                    floorplan_location: {
                                        building_id: building?.id,
                                        building_name: building?.name,
                                        floor_id: floor?.id,
                                        floor_name: floor?.name,
                                    },
                                },
                                sensorName: formik.values.device_name,
                                folderId,
                            });

                            return;
                        }

                        dispatchInstallationFlowContext({
                            type: INSTALLATION_FLOW_ACTIONS.INSTALLATION_SUCCEED,
                            folderId,
                        });
                    },
                    onError: (err: AxiosParsedError<ConfigurationFormValues>) => {
                        if (err.parsedError) {
                            const { errors: formikErrors, message } = err.parsedError;

                            if (formikErrors) {
                                formik.setErrors(formikErrors);
                            }

                            if (message) {
                                formik.setStatus({ message });
                            }
                        }
                    },
                    onSettled: () => {
                        dispatchInstallationFlowContext({
                            type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
                        });
                    },
                },
            );
        },
    });

    return (
        <Configuration
            deviceType={deviceType}
            buildingsData={buildingsData}
            labels={labels}
            supportsLiveCalibration={supportsLiveCalibration}
            floorMap={floorMap}
            resetProgress={resetProgress}
            formik={formik}
            isOnline={isOnline}
        />
    );
};

export default memo(ConfigurationContainer);
