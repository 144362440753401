import type { PipeMonitoringFailureReason } from '@infogrid/remote-monitoring-types';
import Typography from '@material-ui/core/Typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCauseOfRiskStyles } from './styles';

interface Props {
    label: string;
    pipesCount: number;
    failureReason: PipeMonitoringFailureReason;
}

const CauseOfRisk = ({ label, pipesCount, failureReason }: Props) => {
    const styles = useCauseOfRiskStyles();
    const { t } = useTranslation('dashboard');

    const pipesDisplay = t(`{{count}} {{pipeDisplay}}`, {
        count: pipesCount,
        pipeDisplay: pipesCount === 1 ? 'pipe' : 'pipes',
        defaultValue___one: '{{count}} pipe',
        defaultValue___other: '{{count}} pipes',
    });

    return (
        <div
            className={styles.causesOfRiskContainer}
            data-cypress={`cause-of-risk-${failureReason}`}
        >
            <Typography variant="caption">{t(label)}:</Typography>
            <Typography variant="caption" className={styles.causesOfRiskLink}>
                {pipesDisplay}
            </Typography>
        </div>
    );
};

export default memo(CauseOfRisk);
