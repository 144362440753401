import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useNoResultsStyles } from './styles';

const NoResults = () => {
    const styles = useNoResultsStyles();
    const { t } = useTranslation('dashboard');

    return (
        <Container className={styles.contentNoRiskContainer}>
            <Typography variant="subtitle2">
                {t('No buildings or pipes at risk')}
            </Typography>
            <Typography variant="caption">
                {t(
                    'Based on the data coming from your pipe monitoring sensors there are no pipes that need attention in any of your selected buildings',
                )}
            </Typography>
        </Container>
    );
};

export default memo(NoResults);
