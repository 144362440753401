import { useMutationWithToast, wrapResponsePromise } from '@infogrid/core-api';
import { toastError, toastSuccess, useAppDispatch } from '@infogrid/core-ducks';
import { useTranslation } from 'react-i18next';

import { controllers } from './controller';
import type {
    SetActivatedDeActivatedSpacesPayload,
    SmartCleaningActivatedCountResponse,
} from './types';

export const useSetSmartCleaningActivatedDeactivatedSpaces = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('solutions');

    return useMutationWithToast<
        SmartCleaningActivatedCountResponse,
        unknown,
        SetActivatedDeActivatedSpacesPayload
    >(
        (data) =>
            wrapResponsePromise(
                controllers.setSmartCleaningActivatedDeactivatedSpaces(data),
            ),
        {
            onSuccess: (_data, variables) => {
                const activatedArray = variables.locations_to_activate?.split(',') || [];
                const deactivatedArray =
                    variables.locations_to_deactivate?.split(',') || [];

                if (
                    deactivatedArray.length > 0 &&
                    (!activatedArray.length ||
                        activatedArray.length < deactivatedArray.length)
                ) {
                    dispatch(
                        toastSuccess({
                            message: t(
                                'You have successfully removed your selected locations from the Smart Cleaning solution. Cleaning data from those sensors will no longer show in the Smart Cleaning Solution page or the floorplan view in Buildings.',
                            ),
                        }),
                    );
                }
            },

            onError: () => {
                dispatch(
                    toastError({
                        message: t(
                            'Sorry - there’s been a problem and we couldn’t complete your request. Please reload the page or try again later.',
                        ),
                    }),
                );
            },
        },
        {
            toastMessage: t('Updating locations'),
        },
    );
};
