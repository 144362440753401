import { setUser, resetUser, getUser } from '@infogrid/user-ducks';
import { call, put, select } from 'redux-saga/effects';

import { fetchOrgUserWorkerBase } from 'sagas/account/fetchOrgUser';
import { refreshToken } from 'sagas/auth/authMiddleware';
import { invalidateCache } from 'sagas/helpers/fetchingGuard';
import { closeSocket } from 'sagas/socket/actionTypes';
import { orgUserSchema } from 'schemas/orgUser';

export function* userWorker({ action, user }) {
    const currentUser = yield select(getUser);

    if (currentUser.id === user?.id) {
        const token = yield call(refreshToken);

        if (!token) {
            // If no token could be fetched, then reset user and close websocket
            yield put(resetUser());
            yield put(closeSocket());
        } else {
            yield put(
                setUser({
                    user: { ...currentUser, ...user },
                    isAuthenticated: !!user?.id,
                }),
            );
        }
    }

    if (action === 'update') {
        // Merge entities to avoid issue whith service account secret key getting
        // overwritten when the user change websocket message comes in
        yield fetchOrgUserWorkerBase.saveEffect(user, { mergeEntities: true });
    } else {
        yield call(invalidateCache, orgUserSchema.key);
    }
}
