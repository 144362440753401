export const initialModalsState = {
    // to allow close all unnecessary modals
    isCreatingFolder: false,
    isCreatingBuilding: false,
    isCreatingFloor: false,

    isEditingFloorImage: false,
    isReplacingFloorImage: false,

    isOpenEditingFloorImageFromReplaceModal: false,

    isDeletingBuilding: false,
    isDeletingFloor: false,

    isEditingBuilding: false,
    isEditingFloor: false,
};

export const initialState = {
    ...initialModalsState,

    currentBuildingId: null,
    currentFloorId: null,
    editBuildingId: null,
    currentFloorPlan: null,

    fetchingCurrentFloorPlan: false,

    shouldKeepSensorsMapped: false,
};
