import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';

import type { Rating } from '../../../types';

type Props = {
    rating: Rating | null;
};

export const useMetricStateStyles = makeStyles<Theme, Props>((theme) => ({
    metricValueContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    metricValue: {
        marginTop: theme.spacing(0.5),
        color: theme.palette.common.black,
    },

    rating: ({ rating }) => {
        const colors = {
            GOOD: theme.palette.success.main,
            FAIR: theme.palette.common.yellow5,
            POOR: theme.palette.secondary.main,
        };

        return {
            marginLeft: theme.spacing(2.5),
            color: theme.palette.common.white,
            backgroundColor: rating ? colors[rating] : 'none',
        };
    },
}));

export const useIconStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(2),
        fontSize: 14,
        overflow: 'visible',
        cursor: 'pointer',
        lineHeight: '1.2em',
    },
}));
