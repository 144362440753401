import { Grid, Tab, Tabs } from '@material-ui/core';
import type { ChangeEvent } from 'react';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageContainer from 'components/PageContainer';

import TitleBar from '../../../../components/TitleBar';
import { tabs } from './constants';
import { useIntegrationsEventsWebhookStyles } from './styles';

const IntegrationsCorrigo = (): JSX.Element => {
    const styles = useIntegrationsEventsWebhookStyles();

    const { t } = useTranslation('integrations');

    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabSelect = (_: ChangeEvent<Record<string, never>>, index: number) => {
        setSelectedTab(index);
    };

    const TabComponent = tabs[selectedTab].component;

    return (
        <PageContainer className={styles.container}>
            <TitleBar
                large
                className={styles.titleBar}
                title={t('Corrigo Integration')}
            />
            <Tabs
                indicatorColor="primary"
                className={styles.tabBar}
                onChange={handleTabSelect}
                textColor="primary"
                value={selectedTab}
            >
                {tabs.map((tab) => (
                    <Tab key={tab.id} data-cypress="reports-tab" label={t(tab.name)} />
                ))}
            </Tabs>
            <Grid className={styles.childrenWrapper}>
                <TabComponent />
            </Grid>
        </PageContainer>
    );
};

export default memo(IntegrationsCorrigo);
