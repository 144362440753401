import { useAppSelector } from '@infogrid/core-ducks';
import { THRESHOLD_TYPE } from '@infogrid/remote-monitoring-types';
import { LEGIONNAIRE_SUB_TYPES, PipeTypes } from '@infogrid/sensors-constants';
import { selectUserPreferredTempUnit } from '@infogrid/user-ducks';
import {
    convertFahrenheitToCelsius,
    convertCelsiusToFahrenheit,
    TEMP_UNIT,
    TEMP_UNIT_SYMBOL,
} from '@infogrid/utils-measurements';
import { Grid } from '@material-ui/core';
import { Formik, Form } from 'formik';
import type { FormikProps } from 'formik';
import type { Location } from 'history';
import isNull from 'lodash/isNull';
import round from 'lodash/round';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useUpdateThresholds } from 'apiHooks/settings/solutions/hooks';
import NavigationPrompt from 'components/material-ui/NavigationPrompt';
import type {
    TemperatureThreshold,
    ThresholdValues,
    WaterThreshold,
} from 'utils/types/ts/threshold';

import ThresholdCard from '../ThresholdCard';
import ThresholdsDialog from '../ThresholdsDialog';
import { VALIDATION_SCHEMA } from '../validationSchema';

interface ThresholdFormProps {
    isDialogOpen?: boolean;
    isUpdated?: boolean;
    closeDialog: () => void;
    setAffectedPipes: (arg: number) => void;
    setIsSaveDisabled: (arg: boolean) => void;
    affectedPipes: number;
    temperatureThresholds: TemperatureThreshold[];
    waterMovementThreshold?: WaterThreshold;
}

const ThresholdForm = ({
    isDialogOpen,
    isUpdated = false,
    closeDialog,
    setAffectedPipes,
    setIsSaveDisabled,
    affectedPipes,
    temperatureThresholds,
    waterMovementThreshold,
}: ThresholdFormProps): JSX.Element => {
    const history = useHistory();

    const location: Location = useLocation();

    const { t } = useTranslation('solutions');

    const { mutate: updateThresholds } = useUpdateThresholds();

    const userPreferredTempUnit = useAppSelector(selectUserPreferredTempUnit);

    const formRef = useRef<FormikProps<ThresholdValues>>(null);

    const convertValueToUnit = (value: number | null): number | null => {
        if (value === null || userPreferredTempUnit === TEMP_UNIT.CELSIUS) {
            return value;
        }

        return round(convertCelsiusToFahrenheit(value), 2);
    };

    const convertValueToSystem = useCallback(
        (value: number | null): number | null => {
            if (value === null || userPreferredTempUnit === TEMP_UNIT.CELSIUS) {
                return value;
            }

            return round(convertFahrenheitToCelsius(value), 2);
        },
        [userPreferredTempUnit],
    );

    const getThresholdType = (
        threshold: TemperatureThreshold,
    ): typeof THRESHOLD_TYPE[keyof typeof THRESHOLD_TYPE] => {
        if (isNull(threshold.lower_threshold) && !isNull(threshold.upper_threshold)) {
            return THRESHOLD_TYPE.SINGLE_LESS_THAN;
        }

        if (!isNull(threshold.lower_threshold) && isNull(threshold.upper_threshold)) {
            return THRESHOLD_TYPE.SINGLE_MORE_THAN;
        }

        return THRESHOLD_TYPE.RANGE;
    };

    const initialValues: Partial<ThresholdValues> = temperatureThresholds.reduce(
        (prev, threshold) => ({
            ...prev,
            [threshold.pipe_subtype]: {
                lower: convertValueToUnit(threshold.lower_threshold),
                upper: convertValueToUnit(threshold.upper_threshold),
                sensorCount: threshold.pipe_subtype_sensor_count,
                thresholdType: getThresholdType(threshold),
            },
        }),
        {},
    );

    const onFieldChange = useCallback(
        (values: ThresholdValues) => {
            setIsSaveDisabled(Object.keys(formRef?.current?.errors || {}).length !== 0);

            let pipes = 0;

            Object.entries(values).forEach((field) => {
                const [subType, value] = field;
                const initialValue = initialValues[subType as keyof ThresholdValues];

                if (
                    initialValue &&
                    (initialValue.lower !== value?.lower ||
                        initialValue.upper !== value?.upper)
                ) {
                    pipes = pipes + (initialValue?.sensorCount || 0);
                }
            });
            setAffectedPipes(pipes);
        },
        [initialValues, setAffectedPipes, setIsSaveDisabled],
    );

    const handleSubmit = useCallback(
        (values: ThresholdValues) => {
            const newPipeThresholds = Object.entries(values).map((field) => {
                const [subType, value] = field;

                return {
                    pipe_subtype: subType,
                    upper_threshold: convertValueToSystem(value?.upper ?? null),
                    lower_threshold: convertValueToSystem(value?.lower ?? null),
                };
            });

            updateThresholds(
                {
                    pipe_monitoring_water_movement_threshold: waterMovementThreshold,
                    pipe_monitoring_temperature_thresholds: newPipeThresholds,
                },
                {
                    onSuccess: () => {
                        closeDialog();
                        setAffectedPipes(0);

                        if (location.state?.shouldRedirectBack) {
                            history.goBack();
                        }
                    },
                    onError: () => {
                        closeDialog();
                    },
                },
            );
        },
        [
            convertValueToSystem,
            updateThresholds,
            waterMovementThreshold,
            closeDialog,
            setAffectedPipes,
            history,
            location,
        ],
    );

    return (
        <Formik
            data-testid="solution-threshold"
            onSubmit={handleSubmit}
            validationSchema={VALIDATION_SCHEMA}
            initialValues={initialValues}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            innerRef={formRef as any}
            enableReinitialize
        >
            {(formik) => {
                return (
                    <>
                        <Form
                            aria-label="form-solution-threshold"
                            data-testid="solution-threshold-form"
                        >
                            <NavigationPrompt
                                when={formik.dirty}
                                title={t('You have unsaved changes')}
                                content={t(
                                    'If you leave this page now, your changes to thresholds will be discarded.',
                                )}
                            />
                            <Grid container spacing={2}>
                                {Object.keys(LEGIONNAIRE_SUB_TYPES).map((subTypeKey) => {
                                    const subType =
                                        LEGIONNAIRE_SUB_TYPES[
                                            subTypeKey as keyof typeof LEGIONNAIRE_SUB_TYPES
                                        ];

                                    if (subTypeKey === 'GENERIC') {
                                        return null;
                                    }

                                    return (
                                        <Grid item xs={12} key={subTypeKey}>
                                            <ThresholdCard
                                                testId={subTypeKey}
                                                subType={subType}
                                                formik={formik}
                                                onFieldChange={onFieldChange}
                                                name={t(
                                                    PipeTypes[
                                                        subTypeKey as keyof typeof LEGIONNAIRE_SUB_TYPES
                                                    ],
                                                    { ns: 'dashboard' },
                                                )}
                                                threshold={initialValues[subType]}
                                                keyInForm={subType}
                                                unitSymbol={
                                                    TEMP_UNIT_SYMBOL[
                                                        userPreferredTempUnit
                                                    ]
                                                }
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Form>
                        <ThresholdsDialog
                            formik={formik}
                            sensorsCount={affectedPipes}
                            isOpen={isDialogOpen}
                            isUpdated={isUpdated}
                            onCancel={closeDialog}
                        />
                    </>
                );
            }}
        </Formik>
    );
};

export default ThresholdForm;
