import { makeStyles } from '@material-ui/core';

export const useSidebarHeaderStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2, 2, 0, 3),
    },
    closeButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    closeButton: {
        marginLeft: 'auto',
    },
    closeIcon: {
        marginLeft: theme.spacing(0.5),
        width: 20,
    },
    title: {
        margin: theme.spacing(3, 0, 2),
        textAlign: 'center',
    },
}));
