import { THRESHOLD_TYPE } from '@infogrid/remote-monitoring-types';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import * as Yup from 'yup';

const minValueMustBeNumber = registerTranslationKey('Minimum value must be a number');
const maxValueMustBeNumber = registerTranslationKey('Maximum value must be a number');

const thresholdSchema = Yup.object().shape({
    thresholdType: Yup.string().oneOf(Object.values(THRESHOLD_TYPE)),
    lower: Yup.number().when('thresholdType', {
        is: (thresholdType) =>
            [THRESHOLD_TYPE.RANGE, THRESHOLD_TYPE.SINGLE_MORE_THAN].includes(
                thresholdType,
            ),
        then: Yup.number()
            .typeError(minValueMustBeNumber)
            .required(registerTranslationKey('Minimum value is required.')),
        otherwise: Yup.number().typeError(minValueMustBeNumber).nullable(true),
    }),
    upper: Yup.number()
        .when('thresholdType', {
            is: (thresholdType) =>
                [THRESHOLD_TYPE.RANGE, THRESHOLD_TYPE.SINGLE_LESS_THAN].includes(
                    thresholdType,
                ),
            then: Yup.number()
                .required(registerTranslationKey('Maximum value is required.'))
                .when('thresholdType', {
                    is: (thresholdType) => thresholdType === THRESHOLD_TYPE.RANGE,
                    then: Yup.number()
                        .typeError(maxValueMustBeNumber)
                        .moreThan(
                            Yup.ref('lower'),
                            registerTranslationKey(
                                'Maximum value must be greater than minimum value.',
                            ),
                        ),
                })
                .typeError(maxValueMustBeNumber),
            otherwise: Yup.number().typeError(maxValueMustBeNumber).nullable(true),
        })
        .typeError(registerTranslationKey('Maximum value must be a number')),
});

export const VALIDATION_SCHEMA = Yup.object().shape({
    blended: thresholdSchema,
    calorifierFlow: thresholdSchema,
    calorifierReturn: thresholdSchema,
    cold: thresholdSchema,
    coldStorage: thresholdSchema,
    hot: thresholdSchema,
    hotPouHeater: thresholdSchema,
});
