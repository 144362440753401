import { Modal } from '@infogrid/components-material-ui';
import { useIsMobile } from '@infogrid/utils-hooks';
import { CircularProgress } from '@material-ui/core';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAllBuildings } from 'apiHooks/floorPlan/buildings/hooks';

import type { ScheduleReportModalProps } from '../../types';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ReportConfigurationForm from './ReportConfigurationForm';
import { useReportConfigurationContainerStyles } from './styles';

interface Props extends ScheduleReportModalProps {
    onClose: () => void;
    open: boolean;
    isNew: boolean;
}

const ReportConfigurationFormContainer = ({
    onClose,
    open,
    handleSubmit,
    handleChange,
    handleDelete,
    isSubmitting,
    setFieldValue,
    handleBlur,
    setFieldTouched,
    resetForm,
    enabledSections,
    errors,
    touched,
    isNew,
    name,
    language,
    emailRecipients,
    emailReportAt,
    showSensorList,
    pageSize,
    floorplanPageConfiguration,
    zoom,
    sensorIconScale,
    disabled,
    floorIds,
    buildingId,
    hideOptionalSectionDescriptions,
}: Props) => {
    const styles = useReportConfigurationContainerStyles();
    const isMobile = useIsMobile();
    const { t } = useTranslation('solutions');

    const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false);

    const handleClose = useCallback(
        (reason?: 'escapeKeyDown' | 'backdropClick') => {
            if (reason === 'backdropClick') {
                return;
            }

            onClose();
            resetForm();
        },
        [resetForm, onClose],
    );

    const onRequestDeletion = useCallback(() => {
        setIsConfirmingDeletion(true);
    }, [setIsConfirmingDeletion]);

    const confirmDeletion = useCallback(() => {
        setIsConfirmingDeletion(false);
        handleDelete();
    }, [handleDelete, setIsConfirmingDeletion]);

    const { buildings, isLoading } = useAllBuildings(
        {},
        {
            refetchOnMount: true,
            enabled: true,
        },
    );

    return (
        <Modal
            onClose={(_e, reason) => handleClose(reason)}
            open={open}
            fullScreen={isMobile}
            maxWidth="sm"
            fullWidth
        >
            <Modal.Title>
                {isNew
                    ? t('Schedule a new cleaning report')
                    : t('Edit this scheduled cleaning report')}
            </Modal.Title>

            {isLoading && (
                <div className={styles.progress}>
                    <CircularProgress color="primary" size={40} />
                </div>
            )}

            {!isLoading && (
                <ReportConfigurationForm
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleDelete={onRequestDeletion}
                    isSubmitting={isSubmitting}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    resetForm={resetForm}
                    enabledSections={enabledSections}
                    errors={errors}
                    touched={touched}
                    showDeleteButton={!isNew}
                    name={name}
                    language={language}
                    emailRecipients={emailRecipients}
                    emailReportAt={emailReportAt}
                    showSensorList={showSensorList}
                    pageSize={pageSize}
                    floorplanPageConfiguration={floorplanPageConfiguration}
                    zoom={zoom}
                    sensorIconScale={sensorIconScale}
                    disabled={disabled}
                    floorIds={floorIds}
                    buildingId={buildingId}
                    buildings={buildings}
                    hideOptionalSectionDescriptions={hideOptionalSectionDescriptions}
                />
            )}

            <DeleteConfirmationModal
                open={isConfirmingDeletion}
                onClose={() => {
                    setIsConfirmingDeletion(false);
                }}
                onSubmit={confirmDeletion}
                isSubmitting={isSubmitting}
            />
        </Modal>
    );
};

export default memo(ReportConfigurationFormContainer);
