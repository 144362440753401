interface SelectedItemsCountParams {
    allSelected: boolean;
    excludedItemsCount: number;
    selectedCount: number;
    totalCount: number;
}

/**
 * Calculate total selected count of items based on total count,
 * selected and deselected counts, allSelected flag.
 */
export const getSelectedItemsCount = ({
    allSelected,
    excludedItemsCount,
    selectedCount,
    totalCount,
}: SelectedItemsCountParams): number => {
    if (allSelected && !excludedItemsCount) {
        return totalCount;
    }

    return excludedItemsCount ? totalCount - excludedItemsCount : selectedCount;
};
