import type { UneditableCapacityReason } from '@infogrid/locations-types';
import { registerTranslationKey } from '@infogrid/utils-i18n';

export const CAPACITY_MESSAGE = {
    NOT_APPLICABLE: registerTranslationKey('Capacity cannot be changed.', {
        ns: 'estate',
    }),
    DESK_CAPACITY: registerTranslationKey(
        'Desk areas use the number of desk sensors in the space as the capacity.',
        {
            ns: 'estate',
        },
    ),
    FIXED_CAPACITY: registerTranslationKey('Capacity is fixed to 1 for this space.', {
        ns: 'estate',
    }),
    SPACE_CAPACITY: registerTranslationKey(
        'This value will be used to calculate space utilisation. Some space types do not allow capacity to be set manually.',
        {
            ns: 'estate',
        },
    ),
};

export const getCapacityMessageKey = (
    NoCapacityReason: UneditableCapacityReason,
): string => {
    if (!NoCapacityReason) {
        return CAPACITY_MESSAGE.SPACE_CAPACITY;
    }

    switch (NoCapacityReason) {
        case 'derived_capacity':
            return CAPACITY_MESSAGE.DESK_CAPACITY;
        case 'fixed_capacity':
            return CAPACITY_MESSAGE.FIXED_CAPACITY;

        case 'NOT_APPLICABLE':
        default:
            return CAPACITY_MESSAGE.NOT_APPLICABLE;
    }
};
