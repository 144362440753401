import { routesManager } from '@infogrid/core-routing';
import type { FloorSensorLocation } from '@infogrid/locations-types';
import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { ListItem, Typography } from '@material-ui/core';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { memo, useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import SensorBatteryLevel from 'components/material-ui/Sensors/SensorBatteryLevel';
import SensorSignalLevel from 'components/material-ui/Sensors/SignalLevel';
import { getSensorName } from 'utils/sensor';

import SensorIcon from '../SensorIcon';
import SensorStatus from '../SensorStatus';
import { useSensorsListItemStyles } from './styles';

interface SensorsListItemSensor extends GenericSensorShape {
    id: string | number;
    coordinates?: FloorSensorLocation;
}

export interface SensorsListItemProps {
    className?: string;
    isActive?: boolean;
    isDraggable?: boolean;
    onClick?: (id: string | number) => void;
    sensor: SensorsListItemSensor;
}

const SensorsListItem = ({
    className,
    isActive = false,
    isDraggable = false,
    onClick = noop,
    sensor,
}: SensorsListItemProps) => {
    const styles = useSensorsListItemStyles();

    const onItemClick = () => {
        onClick(sensor.id);
    };

    const onSensorNameClick = useCallback((ev) => {
        ev.stopPropagation();
    }, []);

    const dragStartHandler = useCallback(
        (dragEvent) => {
            dragEvent.dataTransfer.setData('sensorDeviceId', sensor.id);
        },
        [sensor.id],
    );

    const listItemContainerClasses = useMemo(
        () =>
            classNames(
                styles.listItemContainer,
                {
                    [styles.listItemActive]: isActive,
                },
                className,
            ),
        [className, isActive, styles],
    );
    const sensorBatteryClasses = useMemo(
        () => ({
            container: styles.listItemMetaIcon,
        }),
        [styles.listItemMetaIcon],
    );

    return (
        <ListItem
            className={listItemContainerClasses}
            onClick={onItemClick}
            onKeyDown={onItemClick}
        >
            <SensorIcon
                isDraggable={isDraggable}
                dragStartHandler={dragStartHandler}
                sensor={sensor}
                isActive={isActive}
            />

            <section className={styles.listItemTextContainer}>
                <div className={styles.listItemHeadingContainer}>
                    <NavLink
                        onClick={onSensorNameClick}
                        className={styles.listItemTitleLink}
                        to={routesManager.resolvePath('sensors:details', {
                            deviceName: sensor.device_name,
                        })}
                        draggable={isDraggable}
                        onDragStart={dragStartHandler}
                    >
                        <Typography
                            className={styles.listItemTitle}
                            variant="subtitle1"
                            data-cypress="sensor-name"
                        >
                            {getSensorName(sensor)}
                        </Typography>
                    </NavLink>

                    <div className={styles.listItemMeta}>
                        <SensorBatteryLevel
                            classes={sensorBatteryClasses}
                            sensor={sensor}
                            size={15}
                            withTooltip
                        />

                        <SensorSignalLevel
                            className={styles.listItemMetaIcon}
                            sensor={sensor}
                            size={15}
                        />
                    </div>
                </div>

                <SensorStatus sensor={sensor} />
            </section>
        </ListItem>
    );
};

export default memo(SensorsListItem);
