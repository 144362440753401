import { registerTranslationKey } from '@infogrid/utils-i18n';

import AssetMapping from '../../components/corrigo/AssetMapping';
import Authentication from '../../components/corrigo/Authentication';

export const tabs = [
    {
        id: 'authentication',
        name: registerTranslationKey('Authentication', { ns: 'integrations' }),
        component: Authentication,
    },
    {
        id: 'assetMapping',
        name: registerTranslationKey('Asset mapping', { ns: 'integrations' }),
        component: AssetMapping,
    },
];
