import { useAppSelector, useAppDispatch, toastError } from '@infogrid/core-ducks';
import { useCallback, useEffect } from 'react';

import {
    selectReplacingFloorImageStatus,
    toggleReplaceFloorImage,
} from 'ducks/floorPlan';

import ReplaceFloorImageModal from './ReplaceFloorImageModal';

interface Props {
    error?: {
        description: string;
    };
    isUploading: boolean;
    onCancel: () => void;
    onSubmit: (files: File[]) => void;
}

const ReplaceFloorImageModalContainer = ({
    error,
    onCancel,
    ...rest
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();

    const isOpened = useAppSelector(selectReplacingFloorImageStatus) ?? false;

    const closeModalHandler = useCallback(() => {
        dispatch(toggleReplaceFloorImage(false));
        onCancel();
    }, [dispatch, onCancel]);

    useEffect(() => {
        if (error?.description) {
            dispatch(toastError({ message: error.description }));
        }
    }, [dispatch, error]);

    return (
        <ReplaceFloorImageModal
            isOpened={isOpened}
            onCancel={closeModalHandler}
            onClose={closeModalHandler}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
        />
    );
};

export default ReplaceFloorImageModalContainer;
