import { useIsOpenState } from '@infogrid/utils-hooks';
import { Button, Icon, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AddSpaceModal from 'components/estates/AddSpaceModal';

import { useAddSpacesStyles } from './styles';

interface Props {
    buildingId: number;
    canAddSpaces: boolean;
    floorId: number;
}

const AddSpaces = ({ buildingId, canAddSpaces, floorId }: Props): JSX.Element => {
    const styles = useAddSpacesStyles();

    const { t } = useTranslation('estate');

    const [isAddSpaceModalOpen, handleCloseAddSpaceModal, handleOpenAddSpaceModal] =
        useIsOpenState();

    return (
        <div className={styles.container}>
            <Icon className={classNames(styles.icon, 'far fa-cube')} />
            <Typography
                className={styles.noSpacesDescription}
                variant="body2"
                data-cypress="no-space-description"
            >
                {t(
                    'Identify spaces in your buildings where sensors will be grouped together, such as Desk Areas, Kitchens or Bathrooms.',
                )}
            </Typography>
            <Tooltip
                placement="top"
                title={!canAddSpaces ? t('You don’t have permission to add spaces') : ''}
            >
                <div>
                    <Button
                        color="primary"
                        data-cypress="open-add-space-view"
                        disabled={!canAddSpaces}
                        onClick={handleOpenAddSpaceModal}
                        variant="contained"
                    >
                        {t('Add spaces')}
                    </Button>
                </div>
            </Tooltip>
            <AddSpaceModal
                buildingId={buildingId}
                floorId={floorId}
                isOpen={isAddSpaceModalOpen}
                onClose={handleCloseAddSpaceModal}
            />
        </div>
    );
};

export default memo(AddSpaces);
