import PropTypes from 'prop-types';

import { ColumnShape, COLUMN_TYPES } from '../constants';

// mostly copied from react-virtualized ColumnSizer
// (https://github.com/bvaughn/react-virtualized/blob/master/source/ColumnSizer/ColumnSizer.js)
// but with redefined logic for column width counter
// because react-virtualized split grid width into identical columns
const ColumnSizer = ({ children, columnMinWidth, columns, width, getScrollbarWidth }) => {
    const registerChild = (child) => {
        if (child && typeof child.recomputeGridSize !== 'function') {
            throw Error(
                'Unexpected child type registered; only Grid/MultiGrid children are supported.',
            );
        }

        if (child) {
            child.recomputeGridSize();
        }
    };

    const [fixedColumnsWidth, flexiblePartsNumber] = columns.reduce(
        (acc, column) => {
            const result = [...acc];
            const { type } = column;

            if (type === COLUMN_TYPES.FIXED) {
                result[0] += column.width;
            }

            if (type === COLUMN_TYPES.FLEXIBLE) {
                result[1] += column.width;
            }

            return result;
        },
        [0, 0],
    );

    const restWidth = width - fixedColumnsWidth - getScrollbarWidth();
    const onePartWidth = Math.floor(restWidth / flexiblePartsNumber);

    const columnWidthMeasurer = ({ index }) => {
        const column = columns[index];
        let computedWidth = column.width;

        if (column.type === COLUMN_TYPES.FLEXIBLE) {
            const calculatedWidth = onePartWidth * column.width;

            computedWidth =
                columnMinWidth > calculatedWidth ? columnMinWidth : calculatedWidth;
        }

        return computedWidth;
    };

    return children({
        columnWidthMeasurer,
        registerChild,
    });
};

ColumnSizer.propTypes = {
    children: PropTypes.func.isRequired,
    columnMinWidth: PropTypes.number.isRequired,
    columns: PropTypes.arrayOf(ColumnShape).isRequired,
    width: PropTypes.number.isRequired,
    getScrollbarWidth: PropTypes.func.isRequired,
};

export default ColumnSizer;
