import { makeStyles } from '@material-ui/core';

export const useResetTimeStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3, 3.75),
        width: '100%',
        height: '100%',

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2.5, 2),
        },
    },
    header: {
        paddingTop: theme.spacing(1),
    },
    description: {
        marginTop: theme.spacing(1),
    },
    locationPickerContainer: {
        padding: theme.spacing(3, 0),
        height: '100%',
        maxHeight: `calc(100vh - ${theme.spacing(32)}px)`,

        [theme.breakpoints.down('sm')]: {
            maxHeight: `calc(100vh - ${theme.spacing(34)}px)`,
        },
    },
    loader: {
        margin: 'auto',
    },
    skeleton: {
        width: '100%',
        height: '100%',
        display: 'flex',
        transform: 'initial',
    },
    loadingSpinnerContainer: {
        position: 'absolute',
        left: 'calc(50% - 15px)',
        top: 'calc(50% - 15px)',
    },
    skeletonContainer: {
        position: 'relative',
        height: '100%',
    },
}));
