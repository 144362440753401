import { routesManager } from '@infogrid/core-routing';
import { selectBuildings } from '@infogrid/locations-api';
import type { Building } from '@infogrid/locations-types';
import { memo, useCallback, useMemo, useState } from 'react';

import {
    cancelBuildingQuery,
    cancelPaginatedBuildings,
    invalidateBuilding,
    invalidatePaginatedBuildings,
} from 'apiHooks/floorPlan/buildings/accessors';
import { usePaginatedBuildings } from 'apiHooks/floorPlan/buildings/hooks';
import { useBuildingsUserActions } from 'apiHooks/floorPlan/userActions/hooks';
import BuildingsTable, { useBuildingsTableData } from 'components/estates/BuildingsTable';
import { DIALOG_NAMES } from 'utils/dialogs';
import { useConnectedIsOpenState } from 'utils/hooks';
import type { OnBuildingUpdate } from 'views/estate/components/EditBuildingProvider/EditBuildingProvider';
import EditBuildingProvider from 'views/estate/components/EditBuildingProvider/EditBuildingProvider';
import EditBuildingModal from 'views/estate/components/modals/EditBuildingModal';

import AddFirstBuilding from '../components/AddFirstBuilding';
import EstateViewHeader from '../components/EstateViewHeader';
import { useEstateViewStyles } from './styles';

const unfilteredQueryParams = { page_size: 1 };

const EstateView = () => {
    const styles = useEstateViewStyles();

    const {
        queryParams,
        buildings,
        buildingsCount,
        fetchMoreBuildings,
        isLoadingBuildings,
        isFetchingBuildings,
    } = useBuildingsTableData();

    // Handles state for AddBuildingModal (as opposed to EditBuildingModal)
    const [_0, _1, openBuildingModal] = useConnectedIsOpenState(
        DIALOG_NAMES.ADD_BUILDING,
    );

    // Handles state for EditBuildingModal - we might consider moving to using useConnectedIsOpenState
    // for this but we would need to ensure buildingToModify state is synced with the connected state.
    // Maybe we should be able to pass some config in the connected state action.
    const [buildingToModify, setBuildingToModify] = useState<Building | null>(null);

    const onBuildingUpdate = useCallback<OnBuildingUpdate>(
        (data, values) => {
            const buildingId = data?.id;

            if (buildingId) {
                cancelBuildingQuery({ buildingId });
                invalidateBuilding({ buildingId });
            }

            setBuildingToModify(null);

            // Invalidate both the global and filtered building list. Global list is required for
            // determining whether the AddFirstBuilding should be displayed.
            cancelPaginatedBuildings();
            cancelPaginatedBuildings(queryParams);
            invalidatePaginatedBuildings();
            invalidatePaginatedBuildings(queryParams);
            invalidatePaginatedBuildings(unfilteredQueryParams);

            if (values.actionType === 'add-another') {
                openBuildingModal();
            }
        },
        [openBuildingModal, queryParams],
    );

    // Check whether to display Add First Building
    const { data: userActions } = useBuildingsUserActions();
    // We need to check the unfiltered list of buildings so we're semi-duplicating the call
    // in useBuildingsTableData(), but we've put in the minimum page size to minimise the
    // response
    const { data: unfilteredBuildings, isFetching: loadingUnfilteredBuildings } =
        usePaginatedBuildings(unfilteredQueryParams);
    const hasNoBuildings = useMemo(
        () =>
            !loadingUnfilteredBuildings &&
            !selectBuildings(unfilteredBuildings).buildingsCount,
        [unfilteredBuildings, loadingUnfilteredBuildings],
    );

    const buildingPath = useCallback(
        (buildingId) =>
            routesManager.resolvePath('estate:estate-building', { buildingId }),
        [],
    );

    if (userActions?.add_building?.allowed && hasNoBuildings) {
        return (
            <>
                <EstateViewHeader
                    buildingsCount={buildingsCount}
                    canAddBuilding={userActions?.add_building}
                />
                <div className={styles.container}>
                    <EditBuildingProvider
                        building={null}
                        onBuildingUpdate={onBuildingUpdate}
                    >
                        <AddFirstBuilding />
                    </EditBuildingProvider>
                </div>
            </>
        );
    }

    return (
        <>
            <EstateViewHeader
                buildingsCount={buildingsCount}
                canAddBuilding={userActions?.add_building}
            />
            <div className={styles.container}>
                <BuildingsTable
                    buildings={buildings}
                    buildingPath={buildingPath}
                    clearFiltersPath={routesManager.resolvePath('estate:estate')}
                    fetchNextPage={fetchMoreBuildings}
                    isLoading={isLoadingBuildings}
                    isFetching={isFetchingBuildings}
                    setBuildingToModify={setBuildingToModify}
                />
                <EditBuildingModal
                    building={buildingToModify}
                    onClose={() => setBuildingToModify(null)}
                    onBuildingUpdate={onBuildingUpdate}
                    open={!!buildingToModify}
                />
            </div>
        </>
    );
};

export default memo(EstateView);
