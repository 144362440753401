import { makeStyles } from '@material-ui/core';

export const useSpacesTableStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3),
    },
    noSearchResultsText: {
        color: theme.palette.common.gray6,
        marginTop: theme.spacing(4),
        textAlign: 'center',
    },
    tableContainer: {
        flexGrow: 1,
        minHeight: `calc(100vh - ${theme.spacing(47)}px)`,
    },
    spaceTypeLabel: {
        color: theme.palette.common.gray6,
        fontSize: 14,
    },
    sensorsCountWrapper: {
        alignItems: 'center',
        display: 'flex',
    },
    sensorsCountIcon: {
        alignItems: 'center',
        color: theme.palette.common.gray6,
        display: 'flex',
        fontSize: 16,
        height: 18,
    },
    sensorsCountLabel: {
        color: theme.palette.common.gray6,
        fontSize: 14,
        marginLeft: theme.spacing(1),
    },
    contextMenuContent: {
        left: 'unset',
        right: 0,
        '& > div': {
            minWidth: 'unset',
        },
    },
    menuListContainer: {
        minWidth: 'unset',
    },
    contextMenuItem: {
        textTransform: 'unset',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    deleteLabel: {
        color: theme.palette.secondary.main,
    },
    nameHeader: {
        paddingLeft: theme.spacing(1),
    },
    nameContent: {
        marginLeft: theme.spacing(1),
    },
}));
