import type { FeedbackPanelTemplate } from '@infogrid/sensors-constants';
import { useFormik } from 'formik';
import { memo, useCallback } from 'react';

import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from 'components/InstallationFlow/reducer';
import { FEEDBACK_PANEL_SENSOR_CONFIGURATION_CREATE_VALIDATION_SCHEMA } from 'components/sensors/FeedbackPanelSensorOptions';
import type { CreateFeedbackPanelSensorConfigurationFormValues } from 'components/sensors/FeedbackPanelSensorOptions';

import { useInstallationFlowContext } from '../../context';
import FeedbackPanelTouchIdentifier from './FeedbackPanelTouchIdentifier';

const getDefaultInitialValues = (feedbackPanelTemplate: FeedbackPanelTemplate) => {
    return {
        sensor_configuration: {
            feedback_panel_template: feedbackPanelTemplate.id,
            sensor_options: feedbackPanelTemplate.template_options.map(
                (templateOption) => ({
                    option_id: templateOption.id,
                    device_name: null,
                }),
            ),
        },
    };
};

const FeedbackPanelTouchIdentifierContainer = () => {
    const {
        feedbackPanelTemplate,
        // createFeedbackPanelValues is preserved when navigating back to this step but does not exist initially
        createFeedbackPanelValues,
    } = useInstallationFlowContext();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    const onPrevious = useCallback(() => {
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.RESET_FEEDBACK_PANEL_TEMPLATE_SELECTION,
        });
    }, [dispatchInstallationFlowContext]);

    const onSubmit = useCallback(
        (submitValues: CreateFeedbackPanelSensorConfigurationFormValues) => {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.IDENTIFY_FEEDBACK_PANEL,
                createFeedbackPanelValues: submitValues,
            });
        },
        [dispatchInstallationFlowContext],
    );

    const { values, isValid, setFieldValue, handleSubmit } =
        useFormik<CreateFeedbackPanelSensorConfigurationFormValues>({
            initialValues:
                createFeedbackPanelValues ??
                getDefaultInitialValues(feedbackPanelTemplate as FeedbackPanelTemplate),
            onSubmit,
            validationSchema:
                FEEDBACK_PANEL_SENSOR_CONFIGURATION_CREATE_VALIDATION_SCHEMA,
            validateOnMount: true,
        });

    const onIdentifySuccess = useCallback(
        ({
            deviceName,
            sensorOptionIndex,
        }: {
            deviceName: string;
            sensorOptionIndex: number;
        }) => {
            setFieldValue(
                `sensor_configuration.sensor_options[${sensorOptionIndex}].device_name`,
                deviceName,
            );
        },
        [setFieldValue],
    );

    return (
        <FeedbackPanelTouchIdentifier
            feedbackPanelTemplate={feedbackPanelTemplate}
            sensorOptionValues={values.sensor_configuration.sensor_options}
            onPrevious={onPrevious}
            onIdentifySuccess={onIdentifySuccess}
            canClickNext={isValid}
            onNext={handleSubmit}
        />
    );
};

export default memo(FeedbackPanelTouchIdentifierContainer);
