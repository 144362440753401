import { makeStyles } from '@material-ui/core';

interface WebhookCardStylesProps {
    isMobileView?: boolean;
    isTabletView?: boolean;
}

export const useWebhookCardStyles = makeStyles((theme) => ({
    activeSwitch: {
        margin: 0,
    },
    authField: {
        display: 'flex',
        gap: theme.spacing(3),
    },
    authFieldInput: {
        width: 'min(400px, 100%)',
    },
    authFieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: theme.spacing(1, 0),
        gap: theme.spacing(1.5),
    },
    textFieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: ({ isMobileView, isTabletView }: WebhookCardStylesProps) =>
            isMobileView || isTabletView ? '100%' : '50%',
        padding: theme.spacing(1, 0),
    },
    textFieldTitle: {
        fontWeight: 500,
        fontSize: 16,
        letterSpacing: 0.15,
    },
    textFieldValue: {
        color: theme.palette.common.gray6,
    },
    titleContainer: {
        padding: theme.spacing(2, 3),
        display: 'flex',
        alignItems: ({ isMobileView, isTabletView }: WebhookCardStylesProps) =>
            isMobileView || isTabletView ? 'start' : 'center',
        justifyContent: 'space-between',
        flexDirection: ({ isMobileView, isTabletView }: WebhookCardStylesProps) =>
            isMobileView || isTabletView ? 'column' : 'row',
    },
    webhookActions: {
        alignItems: 'center',
        display: 'flex',
        gap: theme.spacing(2),
    },
    webhookCardContainer: {
        padding: 0,
    },
    webhookInfoContainer: {
        padding: theme.spacing(2, 3),
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: ({ isMobileView, isTabletView }: WebhookCardStylesProps) =>
            isMobileView || isTabletView ? 'column' : 'row',
    },
    webhookTitle: {
        fontWeight: 500,
        fontSize: 20,
        letterSpacing: 0.15,
    },
    webhookNameContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
    },
}));
