import { registerTranslationKey } from '@infogrid/utils-i18n';
import * as Yup from 'yup';

export const DASHBOARD_NAME_MAX_LENGTH = 255;

const methodName = 'duplicateDashboard';

export const getDashboardNameValidationSchema = (dashboards: Set<string>) => {
    Yup.addMethod(Yup.string, methodName, function testDuplicateItem(items, message) {
        // @ts-expect-error: Not sure why test doesn't exist, it does in documentation
        // https://github.com/jquense/yup#schematestoptions-object-schema
        return this.test({
            name: methodName,
            message,
            test: (value: string) => !items.has(value?.toLowerCase()),
        });
    });

    return Yup.object().shape({
        name: Yup.string()
            .required(registerTranslationKey('Name is required', { ns: 'dashboard' }))
            .max(
                DASHBOARD_NAME_MAX_LENGTH,
                registerTranslationKey('Name should be less than 255 characters', {
                    ns: 'dashboard',
                }),
            )
            // @ts-expect-error: Couldn't get yup type extension working as per documentation
            // https://github.com/jquense/yup#extending-built-in-schema-with-new-methods
            .duplicateDashboard(
                dashboards,
                registerTranslationKey('A dashboard with that name already exists', {
                    ns: 'dashboard',
                }),
            ),
    });
};
