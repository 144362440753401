import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { sensorPlanningLockKey } from '../getQueryKeys';
import type { LockResponse } from '../types';

type UseSensorPlanningLockResult = UseQueryResult<LockResponse, AxiosParsedError>;

export const useSensorPlanningLock = (
    floorId: number,
    options?: UseQueryOptions<LockResponse, AxiosParsedError>,
): UseSensorPlanningLockResult => {
    return useQuery(
        sensorPlanningLockKey({ floorId }),
        ({ signal }) =>
            wrapResponsePromise(controllers.getSensorPlanningLock({ floorId, signal })),
        { ...options },
    );
};
