import type { AutocompleteRenderInputParams } from '@infogrid/components-material-ui';
import type { BuildingDetailsModalValues } from '@infogrid/locations-types';
import { ADDRESS_INPUT_MODES } from '@infogrid/locations-types';
import { WEEKDAYS } from '@infogrid/utils-dates';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import type { ChangeEvent } from 'react';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useBuildingTypes } from 'apiHooks/floorPlan/buildings/hooks';
import AutocompleteAddress from 'views/estate/components/AutocompleteAddress';

import { WorkingHoursInput } from './WorkingHoursInput';
import { WEEKDAY_KEYS } from './constants';
import { useBuildingDetailsStyles } from './styles';

const BuildingDetails = () => {
    const styles = useBuildingDetailsStyles();

    const { t } = useTranslation();

    const {
        errors,
        touched,
        isSubmitting,
        values,
        handleBlur,
        handleChange,
        setFieldValue,
    } = useFormikContext<BuildingDetailsModalValues>();

    const { data: buildingTypes } = useBuildingTypes();

    const renderAddressInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
            }}
            data-cypress="address"
            error={!!touched.location?.address && !!errors.location?.address}
            fullWidth
            helperText={
                !!touched.location?.address &&
                errors.location?.address &&
                t(errors.location?.address)
            }
            id="address"
            label={t('Building address')}
            name="address"
            onBlur={handleBlur}
            type="text"
            variant="outlined"
        />
    );

    const renderCityInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
            }}
            data-cypress="city"
            error={!!touched.location?.city && !!errors.location?.city}
            fullWidth
            helperText={
                !!touched.location?.city &&
                errors.location?.city &&
                t(errors.location?.city)
            }
            id="city"
            label={t('Town/City')}
            name="location.city"
            onBlur={handleBlur}
            type="text"
            variant="outlined"
        />
    );

    const renderCountryInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
            }}
            data-cypress="country"
            error={!!touched.location?.country_name && !!errors.location?.country_name}
            fullWidth
            helperText={
                !!touched.location?.country_name &&
                errors.location?.country_name &&
                t(errors.location?.country_name)
            }
            id="country"
            label={t('Country')}
            name="location.country_name"
            type="text"
            onBlur={handleBlur}
            variant="outlined"
        />
    );

    const renderPostcodeInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
            }}
            error={!!touched.location?.postcode && !!errors.location?.postcode}
            fullWidth
            helperText={
                !!touched.location?.postcode &&
                errors.location?.postcode &&
                t(errors.location?.postcode)
            }
            data-cypress="postcode"
            id="postcode"
            label={t('ZIP/Postcode (optional)')}
            name="postcode"
            onBlur={handleBlur}
            type="text"
            variant="outlined"
        />
    );

    const handleAddressLocation = useCallback(
        (_, location) => {
            setFieldValue('location.address', location?.address || '');
            setFieldValue('location.city', location?.city);
            setFieldValue('location.latitude', location?.coordinates?.latitude);
            setFieldValue('location.longitude', location?.coordinates?.longitude);
            setFieldValue('location.country_name', location?.country_name);
            setFieldValue('location.country_code', location?.country_code);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleCityLocation = useCallback(
        (_, location) => {
            setFieldValue('location.address', location?.address || '');
            setFieldValue('location.city', location?.text || '');
            setFieldValue('location.latitude', location?.coordinates?.latitude);
            setFieldValue('location.longitude', location?.coordinates?.longitude);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleCountryLocation = useCallback(
        (_, location) => {
            setFieldValue('location.address', '');
            setFieldValue('location.city', '');
            setFieldValue('location.country_name', location?.text || '');
            setFieldValue('location.country_code', location?.country_code || '');
            setFieldValue('location.postcode', undefined);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handlePostcodeLocation = useCallback(
        (_, location) => {
            setFieldValue('location.address', location?.address);
            setFieldValue('location.latitude', location?.coordinates?.latitude);
            setFieldValue('location.longitude', location?.coordinates?.longitude);
            setFieldValue('location.city', location?.city || '');
            setFieldValue('location.postcode', location?.text);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const isAddressExpanded =
        values.location?.location_entry_mode === ADDRESS_INPUT_MODES.MANUAL;

    const toggleAddressExpanded = () => {
        return isAddressExpanded
            ? setFieldValue('location.location_entry_mode', ADDRESS_INPUT_MODES.LOOKUP)
            : setFieldValue('location.location_entry_mode', ADDRESS_INPUT_MODES.MANUAL);
    };

    const createOnEnabledChangeHandler = (dayKey: string) => {
        return (_: ChangeEvent<HTMLInputElement>, checked: boolean) =>
            setFieldValue(`working_hours.${dayKey}.enabled`, checked);
    };

    const createOnChangeTimeFromHandler = (dayKey: string) => {
        return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setFieldValue(`working_hours.${dayKey}.from_hour`, event.target.value);
    };

    const createOnChangeTimeToHandler = (dayKey: string) => {
        return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setFieldValue(`working_hours.${dayKey}.to_hour`, event.target.value);
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        data-cypress="building-name"
                        error={!!touched.name && !!errors.name}
                        fullWidth
                        helperText={!!touched.name && errors.name && t(errors.name)}
                        id="name"
                        label={t('Building name')}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.name}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        error={!!touched.building_type && !!errors.building_type}
                        fullWidth
                        variant="outlined"
                    >
                        <InputLabel id="building_type">{t('Building type')}</InputLabel>

                        {!!buildingTypes && (
                            <Select
                                data-cypress="building-type"
                                fullWidth
                                id="building_type"
                                label={t('Building type')}
                                labelId="building_type"
                                MenuProps={{
                                    anchorOrigin: {
                                        horizontal: 'left',
                                        vertical: 'bottom',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                name="building_type"
                                onChange={handleChange}
                                value={values.building_type}
                                variant="outlined"
                            >
                                {buildingTypes?.map((buildingType) => (
                                    <MenuItem
                                        data-cypress="building-type-item"
                                        key={buildingType.id}
                                        value={buildingType.id}
                                    >
                                        {buildingType.display_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}

                        {!!touched.building_type &&
                            errors.building_type &&
                            t(errors.building_type) && (
                                <FormHelperText>{t(errors.building_type)}</FormHelperText>
                            )}
                    </FormControl>
                </Grid>
                {!isAddressExpanded && (
                    <Grid item xs={12}>
                        <AutocompleteAddress
                            address={values.location.address || ''}
                            disabled={isSubmitting}
                            onChange={handleAddressLocation}
                            renderInput={renderAddressInput}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography
                        className={styles.linkLabel}
                        onClick={toggleAddressExpanded}
                        variant="subtitle2"
                        data-cypress="enter-address-manually"
                    >
                        {isAddressExpanded
                            ? t('Hide manual address')
                            : t('Enter address manually')}
                    </Typography>
                </Grid>
                {isAddressExpanded && (
                    <>
                        <Grid item xs={12}>
                            <AutocompleteAddress
                                address={values.location.country_name || ''}
                                disabled={isSubmitting}
                                noOptionsText={t('Start typing to search countries')}
                                onChange={handleCountryLocation}
                                renderInput={renderCountryInput}
                                placeTypes={['country']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                data-cypress="address1"
                                fullWidth
                                id="manual-address1"
                                label={t('Address Line 1 (optional)')}
                                name="location.address_line_1"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.location.address_line_1}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                data-cypress="address2"
                                fullWidth
                                id="manual-address2"
                                label={t('Address Line 2 (optional)')}
                                name="location.address_line_2"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.location.address_line_2}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteAddress
                                address={values.location.city || ''}
                                buildingCountry={values.location.country_code}
                                disabled={isSubmitting || !values.location.country_code}
                                noOptionsText={t('Start typing to search towns / cities')}
                                onChange={handleCityLocation}
                                renderInput={renderCityInput}
                                placeTypes={['place', 'locality']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteAddress
                                address={values.location.postcode || ''}
                                buildingCountry={values.location.country_code}
                                disabled={isSubmitting || !values.location.country_code}
                                onChange={handlePostcodeLocation}
                                placeTypes={['postcode']}
                                renderInput={renderPostcodeInput}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} data-cypress="add-building-capacity">
                    <Typography className={styles.header} variant="subtitle1">
                        {t('Capacity')}
                    </Typography>
                    <Typography
                        className={styles.capacityText}
                        color="textSecondary"
                        variant="body2"
                    >
                        {t('This value will be used to calculate building utilisation.')}
                    </Typography>
                    <TextField
                        data-cypress="edit-capacity"
                        type="number"
                        label="Capacity"
                        name="occupant_capacity"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: 1,
                        }}
                        value={values.occupant_capacity}
                        onChange={handleChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={styles.header} variant="subtitle1">
                        {t('Working hours in this building')}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                        {t(
                            '‘Working hours’ are the hours each day when this building is being used by your workforce. The times shown are in the building’s local timezone. Times must be rounded to the nearest 15 minutes.',
                        )}
                    </Typography>
                    {Object.values(values.working_hours).map((day, index) => {
                        const dayKey = WEEKDAY_KEYS[index];

                        return (
                            <WorkingHoursInput
                                key={`working-hours-${dayKey}`}
                                className={styles.workingHoursInput}
                                data-cypress={`working-hours-${dayKey}`}
                                enabled={day.enabled}
                                error={errors?.working_hours?.[dayKey]}
                                label={t(WEEKDAYS[index])}
                                onChangeChecked={createOnEnabledChangeHandler(dayKey)}
                                onChangeTimeFrom={createOnChangeTimeFromHandler(dayKey)}
                                onChangeTimeTo={createOnChangeTimeToHandler(dayKey)}
                                timeFrom={day.from_hour}
                                timeTo={day.to_hour}
                            />
                        );
                    })}
                </Grid>
            </Grid>
        </div>
    );
};

export default memo(BuildingDetails);
