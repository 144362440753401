import { makeStyles } from '@material-ui/core';

export const useUnassignedSensorsBannerStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(3, 3, 0),
        padding: theme.spacing(0.75, 1.5),
    },
    subcontainer: {
        alignItems: 'center',
        display: 'flex',
    },
    warningIcon: {
        color: theme.palette.common.yellow5,
        marginRight: theme.spacing(1.5),
    },
    viewSensorsButton: {
        whiteSpace: 'nowrap',
    },
}));
