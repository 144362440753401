import { makeStyles } from '@material-ui/core';

export const useSensorsListItemStyles = makeStyles((theme) => ({
    listItemContainer: {
        cursor: 'pointer',
        backgroundColor: theme.palette.common.gray5,
        borderBottom: `2px solid ${theme.palette.borders.listItem}`,
        height: '100%',
        padding: theme.spacing(2),
    },
    listItemActive: {
        backgroundColor: theme.palette.background.default,
    },
    listItemHeadingContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    listItemTitleLink: {
        overflow: 'hidden',

        width: '100%',
        marginRight: theme.spacing(0.5),
    },
    listItemTitle: {
        overflow: 'hidden',

        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    listItemTextContainer: {
        display: 'flex',
        flexDirection: 'column',

        margin: 0,
        minWidth: 0,

        flex: '1 1 auto',
    },
    listItemMeta: {
        display: 'flex',
        alignItems: 'center',

        marginLeft: 'auto',

        flexShrink: 0,
    },
    listItemMetaIcon: {
        marginRight: theme.spacing(1.25),
        '&:not(:last-child)': {
            marginRight: theme.spacing(1.25),
        },
    },
}));
