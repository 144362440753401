import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { hideStage?: boolean }>((theme) => ({
    alert: {
        margin: theme.spacing(1, 0),
    },
    checkboxLabelText: {
        display: 'flex',
        alignItems: 'center',
    },
    modalDescriptiveText: {
        margin: theme.spacing(1, 0),
    },
    countText: {
        marginRight: 'auto',
        paddingLeft: theme.spacing(1),
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    stage: {
        display: ({ hideStage }) => (hideStage ? 'none' : 'flex'),
        flexDirection: 'column',
    },
}));

export const useModalStyles = makeStyles(() => ({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        height: '100%',
        width: '40%',
    },
}));
