import type { QueryKey } from 'react-query';

import {
    ALERT_CONFIGURATION_DETAIL_QUERY_KEY,
    ALERT_CONFIGURATIONS_QUERY_KEY,
    ALERT_WEBHOOKS_QUERY_KEY,
    ALERT_TEMPLATES_QUERY_KEY,
} from 'apiHooks/base';
import { ALERT_CONFIGURATION_RECIPIENTS_SEARCH_QUERY_KEY } from 'apiHooks/queryKeys';

export const getAlertConfigurationsKey = (): QueryKey => [ALERT_CONFIGURATIONS_QUERY_KEY];
export const getFullAlertTemplatesKey = (): QueryKey => [ALERT_TEMPLATES_QUERY_KEY];

export const getFullAlertConfigurationsKey = (filter: {
    [key: string]: unknown;
}): QueryKey => [ALERT_CONFIGURATIONS_QUERY_KEY, filter];

export const getAlertConfigurationKey = (id: string): QueryKey => [
    ALERT_CONFIGURATION_DETAIL_QUERY_KEY,
    id,
];
export const getAlertConfigurationSearchKey = (params: {
    [key: string]: unknown;
}): QueryKey => [ALERT_CONFIGURATION_RECIPIENTS_SEARCH_QUERY_KEY, params];
export const getAlertWebhooksKey = (): QueryKey => [ALERT_WEBHOOKS_QUERY_KEY];
