import type { SpaceTypeGroup } from '@infogrid/locations-types';
import { Typography } from '@material-ui/core';
import { memo } from 'react';

import AddSpaceListItem from '../AddSpaceListItem';
import { useAddSpaceListStyles } from './styles';

export interface AddSpaceListProps {
    className?: string;
    group: SpaceTypeGroup;
}

const AddSpaceList = ({ className, group }: AddSpaceListProps) => {
    const styles = useAddSpaceListStyles();

    const { children, parent } = group;

    return (
        <div className={className}>
            <Typography
                className={styles.title}
                data-cypress="space-parent-type"
                variant="subtitle1"
            >
                {parent}
            </Typography>
            {children.map((spaceType) => (
                <AddSpaceListItem key={spaceType.id} spaceType={spaceType} />
            ))}
        </div>
    );
};

export default memo(AddSpaceList);
