import { useAppDispatch } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { selectOrganizationId } from '@infogrid/user-ducks';
import { createFetchAction, createFetchSaga } from '@thorgate/spa-entities';
import { takeLatestWithMatch } from '@thorgate/spa-view-manager';
import { getLocation } from 'connected-react-router';
import { useCallback } from 'react';
import { call, select } from 'redux-saga/effects';

import { createPaginationSuccessHook } from 'ducks/pagination';
import { fetchGuardInitial } from 'sagas/helpers/fetchingGuard';
import { orgUserSchema, orgUsersKeyFn } from 'schemas/orgUser';
import api from 'services/api';
import { buildQueryFromLocation } from 'utils/filtering/legacy/orgUserFiltering';
import { getKeyOptionsWithFiltered } from 'utils/relations';
import { serializeData } from 'utils/serializeData';

export const fetchOrgUsers = createFetchAction('@@sagas/account/orgUsers/fetch');

export const useFetchMoreOrgUsers = (location) => {
    const dispatch = useAppDispatch();

    return useCallback(
        (payload, meta) => {
            dispatch(
                fetchOrgUsers(payload, {
                    keyOptions: getKeyOptionsWithFiltered({}, location),
                    ...meta,
                }),
            );
        },
        [location, dispatch],
    );
};

export const fetchOrgUsersWorkerBase = createFetchSaga({
    key: orgUsersKeyFn,
    listSchema: [orgUserSchema],
    resource: api.organization.user.list,
    successHook: createPaginationSuccessHook(orgUsersKeyFn, true),
    serializeData,

    *mutateQuery(match, action) {
        return yield call(buildQueryFromLocation, action?.payload?.query);
    },
    *mutateKwargs() {
        const organizationId = yield select(selectOrganizationId);

        return { org_pk: organizationId };
    },
});

export const fetchOrgUsersWorker = fetchGuardInitial(
    fetchOrgUsersWorkerBase.asInitialWorker(function* initial() {
        const location = yield select(getLocation);

        return fetchOrgUsers({}, { keyOptions: getKeyOptionsWithFiltered({}, location) });
    }),
    (_0, location) => (location?.search ? false : orgUserSchema.key),
);

// TODO: Paginate users in team view to avoid hard-coded 1000 page size
export const fetchAllOrgUsersWorker = fetchOrgUsersWorkerBase.asInitialWorker(() =>
    fetchOrgUsers({ query: { page_size: 1000 } }),
);

export function* fetchOrgUsersWatcher() {
    yield takeLatestWithMatch(
        fetchOrgUsers.getType(),
        routesManager.resolvePattern('organization:accounts'),
        fetchOrgUsersWorkerBase,
    );
}
