import { makeStyles } from '@material-ui/core';

export const useWidgetTitleIconStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '20px',
        marginRight: theme.spacing(1),
        '& > i:nth-child(2)': {
            fontSize: '12px',
            margin: theme.spacing(0, 0.5),
        },
    },
}));
