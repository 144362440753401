import { makeStyles } from '@material-ui/core';

export const usePublicDisplayWidgetStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.common.black,
        borderRadius: 4,
        color: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: theme.spacing(8, 7),
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
    },
    icon: {
        fontSize: 52,
    },
    titleRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
