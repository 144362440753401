import PropTypes from 'prop-types';
import { Component } from 'react';
import { v4 as uuid } from 'uuid';

const HEARTBEAT_STREAM = 'heartbeat';

// eslint-disable-next-line react-prefer-function-component/react-prefer-function-component
class WebSocketContainer extends Component {
    state = {
        connected: true,
    };

    socket = null;

    socketHeartbeat = null;

    socketAutoReconnect = null;

    mounted = false;

    componentDidMount() {
        // Keep track if component is mounted
        this.mounted = true;
        this.connectSocket();
    }

    componentWillUnmount() {
        // Keep track if component is mounted
        // This helps if component is already un-mounted and we want to prevent usage of setState
        this.mounted = false;
        this.disconnectSocket();

        if (this.socketAutoReconnect) {
            clearTimeout(this.socketAutoReconnect);
            this.socketAutoReconnect = null;
        }
    }

    onOpen = () => {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('Websocket connected!');
        }

        this.setState({
            connected: true,
        });

        // Send heartbeat message every 10 sec to keep the connection open
        // Otherwise Nginx will kill it in 30 seconds or so
        // TODO edit 08.2020: we don't use nginx any more. not sure if the connection would still be killed.
        this.socketHeartbeat = setInterval(
            this.sendHeartbeat,
            this.props.heartbeatInterval,
        );

        if (this.props.onConnect) {
            this.props.onConnect();
        }
    };

    onClose = (event) => {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log('Websocket disconnected!');
        }

        if (this.mounted) {
            this.setState({ connected: false }, () => {
                this.socket = null;
            });

            if (this.props.onDisconnect) {
                this.props.onDisconnect(event.code);
            }

            if (this.props.autoReconnect) {
                this.socketAutoReconnect = setTimeout(
                    this.connectSocket,
                    this.props.autoReconnectTimeout,
                );
            }
        } else {
            this.socket = null;
        }
    };

    onError = () => {
        this.props.onError();
    };

    onMessage = (evt) => {
        const { stream, payload } = JSON.parse(evt.data);

        if (stream === HEARTBEAT_STREAM) {
            return;
        }

        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.log(`Processing message from stream ${stream} payload:`, payload);
        }

        this.props.onMessage(
            stream,
            payload,
            this.sendMessage,
            this.connectSocket,
            this.disconnectSocket,
        );
    };

    connectSocket = () => {
        if (!this.socket) {
            const url = this.props.socketURL
                .replace('http://', 'ws://')
                .replace('https://', 'wss://');

            // Create Websocket instance
            this.socket = new WebSocket(`${url}`);

            if (this.socketAutoReconnect) {
                clearTimeout(this.socketAutoReconnect);
            }

            this.socketAutoReconnect = null;

            // Add event listeners
            this.socket.addEventListener('open', this.onOpen);
            this.socket.addEventListener('message', this.onMessage);
            this.socket.addEventListener('close', this.onClose);
            this.socket.addEventListener('error', this.onError);
        }
    };

    disconnectSocket = () => {
        if (this.socketHeartbeat) {
            clearInterval(this.socketHeartbeat);
            this.socketHeartbeat = null;
        }

        if (this.socket) {
            this.socket.close();
        }
    };

    sendMessage = (payload, stream = null) => {
        this.socket.send(
            JSON.stringify({
                stream: stream || this.props.streamName,
                payload: {
                    request_id: uuid(),
                    ...payload,
                },
            }),
        );
    };

    sendHeartbeat = () => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.sendMessage({ ping: true }, HEARTBEAT_STREAM);
        }
    };

    render() {
        if (this.props.render) {
            return this.props.render(
                this.state,
                this.sendMessage,
                this.connectSocket,
                this.disconnectSocket,
            );
        }

        return null;
    }
}

WebSocketContainer.propTypes = {
    socketURL: PropTypes.string.isRequired,
    streamName: PropTypes.string.isRequired,
    onMessage: PropTypes.func.isRequired,
    onConnect: PropTypes.func,
    onDisconnect: PropTypes.func,
    onError: PropTypes.func,
    render: PropTypes.func,
    autoReconnect: PropTypes.bool,
    autoReconnectTimeout: PropTypes.number,
    heartbeatInterval: PropTypes.number,
};

WebSocketContainer.defaultProps = {
    heartbeatInterval: 15000,
    onConnect: null,
    onDisconnect: null,
    onError: null,
    render: null,
    autoReconnect: false,
    autoReconnectTimeout: 5000,
};

export default WebSocketContainer;
