import { setConstanceInBulk } from '@infogrid/core-ducks';
import { put } from 'redux-saga/effects';

import { fetchGuardInitial } from 'sagas/helpers/fetchingGuard';
import api from 'services/api';

export const loadConstance = fetchGuardInitial(
    function* loadConstance() {
        const constanceList = yield api.constanceConfig.fetch();

        yield put(setConstanceInBulk(constanceList));
    },
    () => 'constance-config',
);
