import { wrapResponsePromise } from '@infogrid/core-api';
import { useAppSelector, useAppDispatch, toastError } from '@infogrid/core-ducks';
import type { IPagination, AxiosParsedError } from '@infogrid/core-types';
import type { BuildingAtRiskWithFloors } from '@infogrid/remote-monitoring-types';
import { selectOrganizationId } from '@infogrid/user-ducks';
import type { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import type { UseInfiniteQueryResult, UseInfiniteQueryOptions } from 'react-query';
import { useInfiniteQuery } from 'react-query';

import { controllers } from 'apiHooks/remote_monitoring/controllers';
import type { AtRiskBuildingsPaginated } from 'apiHooks/remote_monitoring/controllers';
import { getAtRiskBuildingKey } from 'apiHooks/remote_monitoring/getQueryKeys';

const mapper = (
    response: AxiosResponse<AtRiskBuildingsPaginated>,
): IPagination<BuildingAtRiskWithFloors> => {
    return {
        results: response.data.results.buildings ?? [],
        next: response.data.next,
        previous: response.data.previous,
    };
};

export const useAtRiskBuilding = (
    query: {
        building_id: string;
        time_period_range: string;
        pipe_monitoring_failure_types: string[];
    },
    options?: UseInfiniteQueryOptions<
        IPagination<BuildingAtRiskWithFloors>,
        AxiosParsedError,
        IPagination<BuildingAtRiskWithFloors>
    >,
): UseInfiniteQueryResult<IPagination<BuildingAtRiskWithFloors>, AxiosParsedError> => {
    const dispatch = useAppDispatch();
    const organizationId = useAppSelector(selectOrganizationId);
    const { t } = useTranslation('common');

    return useInfiniteQuery(
        getAtRiskBuildingKey(query),
        ({ signal, pageParam = {} }) =>
            wrapResponsePromise(
                controllers.getAtRiskBuilding(organizationId, signal, {
                    ...query,
                    ...pageParam,
                }),
                mapper,
            ),
        {
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error fetching pipe monitoring risk for building'),
                    }),
                );
            },
            keepPreviousData: false,
            refetchOnMount: false,
            getNextPageParam: (lastPage) => lastPage?.next,
            getPreviousPageParam: (previousPage) => previousPage?.previous,
            ...options,
        },
    );
};
