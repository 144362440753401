/* eslint-disable id-denylist */

import { makeStyles } from '@material-ui/core';

type StyleProps = {
    isMobile: boolean;
};

export const useDashboardSelectStyles = makeStyles((theme) => ({
    container: ({ isMobile }: StyleProps) => ({
        maxWidth: isMobile ? '100%' : 500,
        width: '100%',

        order: 2,

        ...(isMobile && {
            border: '1px solid rgba(145, 141, 133, 0.4)',
            borderRadius: '4px',
        }),
    }),
    content: ({ isMobile }: StyleProps) => ({
        '&:before': {
            display: isMobile ? 'none' : 'block',
        },
        '&:after': {
            display: isMobile ? 'none' : 'block',
        },
    }),
    renderValueIcon: ({ isMobile }: StyleProps) => ({
        marginLeft: isMobile ? 'auto' : undefined,
    }),
    actionsSection: {
        paddingBottom: 0,
    },
    actionsSectionItem: {
        color: theme.palette.primary.main,
    },
    actionsSectionItemIcon: {
        fontSize: '18px',
    },
    itemText: {
        textTransform: 'capitalize',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',

        overflow: 'hidden',

        display: '-webkit-box',

        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
    },
    activeDashboard: {
        pointerEvents: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1),
    },
}));

export const useActionsMenuStyles = makeStyles((theme) => ({
    menuContainer: ({ isMobile }: StyleProps) => ({
        order: isMobile ? 1 : 3,

        marginBottom: isMobile ? theme.spacing(2.25) : undefined, // equal to header title
        marginLeft: 'auto',
    }),
    menuButtonIcon: ({ isMobile }: StyleProps) => ({
        fontSize: isMobile ? '24px' : '',
    }),
    menuContent: ({ isMobile }: StyleProps) => ({
        ...(isMobile && {
            right: 0,
            left: 'unset',
        }),
    }),
}));
