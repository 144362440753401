import { queryClient } from '@infogrid/core-api';
import type { SensorShape } from '@infogrid/sensors-constants';

import { getLensSensorsKey } from '../floors/getQueryKeys';
import {
    getMappedSensorsKey,
    getPlannedSensorsKey,
    acquireSensorPlanningLockKey,
    sensorPlanningLockKey,
    getFloorSensorsKey,
} from './getQueryKeys';
import type { FloorSensorsParams } from './types';

export const setMappedSensors = ({
    floorId,
    data,
}: {
    floorId: number;
    data?: SensorShape[];
}) => queryClient.setQueryData(getMappedSensorsKey({ floorId }), data);

export const invalidateFloorSensors = ({
    floorId,
    params,
}: {
    floorId: number;
    params?: FloorSensorsParams;
}) => queryClient.invalidateQueries(getFloorSensorsKey({ floorId, params }));

export const removeFloorSensors = ({
    floorId,
    params,
}: {
    floorId: number;
    params?: FloorSensorsParams;
}) => queryClient.removeQueries(getFloorSensorsKey({ floorId, params }));

export const invalidatePlannedSensorsQueries = ({ floorId }: { floorId: number }) =>
    queryClient.invalidateQueries(getPlannedSensorsKey({ floorId }));

export const cancelPlannedSensorsQueries = ({ floorId }: { floorId: number }) =>
    queryClient.cancelQueries(getPlannedSensorsKey({ floorId }));

export const invalidateSensorPlanningLock = ({ floorId }: { floorId: number }) =>
    queryClient.invalidateQueries(sensorPlanningLockKey({ floorId }));

export const cancelSensorPlanningLock = ({ floorId }: { floorId: number }) =>
    queryClient.cancelQueries(sensorPlanningLockKey({ floorId }));

export const cancelAcquireSensorPlanningLock = ({ floorId }: { floorId: number }) =>
    queryClient.cancelQueries(acquireSensorPlanningLockKey({ floorId }));

export const invalidateLensSensors = (floorId: number): Promise<void> =>
    queryClient.invalidateQueries(getLensSensorsKey(floorId));
