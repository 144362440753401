import { SignalLevel, Tooltip } from '@infogrid/components-material-ui';
import isNaN from 'lodash/isNaN';
import { memo } from 'react';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const getTooltipContent = (
    hasWiredConnection: boolean,
    isBoostMode: boolean,
    isOffline: boolean,
    level: number,
    t: TFunction,
) => {
    if (hasWiredConnection) {
        return t('Connected via Ethernet', { ns: 'sensors' });
    }

    if (isOffline) {
        return t('Sensor is offline', {
            ns: 'sensors',
        });
    }

    if (isBoostMode) {
        return t(
            'Sensor is in Boost mode which has a negative impact on battery life. ' +
                'We recommend improving signal strength.',
            { ns: 'sensors' },
        );
    }

    if (isNaN(level)) {
        return t('Cellular signal strength: Not available', { ns: 'sensors' });
    }

    return t('Cellular signal strength: {{level}}%', {
        level,
        defaultValue: `Cellular signal strength: ${level}%`,
        ns: 'sensors',
    });
};

export interface SignalLevelProps {
    hasWiredConnection?: boolean;
    isBoostMode?: boolean;
    isOffline?: boolean;
    isLoading?: boolean;
    signalLevel?: number;
    size?: number;
    classes?: {
        tooltip?: Record<string, unknown>;
        signalLevel?: string;
        signalLevelContainer?: string;
    };
}

const SensorSignalLevel = ({
    hasWiredConnection = false,
    isBoostMode = false,
    isOffline = false,
    isLoading = false,
    signalLevel = 0,
    size = 20,
    classes = {
        tooltip: {},
        signalLevel: '',
        signalLevelContainer: '',
    },
}: SignalLevelProps) => {
    const { t } = useTranslation();

    return (
        <Tooltip
            title={getTooltipContent(
                hasWiredConnection,
                isBoostMode,
                isOffline,
                signalLevel,
                t,
            )}
        >
            <div className={classes.signalLevelContainer}>
                <SignalLevel
                    className={classes.signalLevel}
                    level={Math.round(signalLevel)}
                    isBoostMode={isBoostMode}
                    isOffline={isOffline}
                    isLoading={isLoading}
                    size={size}
                />
            </div>
        </Tooltip>
    );
};

export default memo(SensorSignalLevel);
