import { makeStyles } from '@material-ui/core';

// Regular xs breakpoint, 768px, was too large for this
const MOBILE_BREAKPOINT = 450;

export const useWorkingHoursInputStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            flexDirection: 'column',
        },
    },
    dayBlock: {
        alignItems: 'center',
        display: 'flex',
    },
    divider: {
        margin: theme.spacing(1),
    },
    error: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    errorText: {
        width: 260,
    },
    input: {
        width: 120,
    },
}));
