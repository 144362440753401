import { Modal } from '@infogrid/components-material-ui';
import { Button, Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteFloorModalStyles } from './styles';

interface Props {
    isLoading: boolean;
    onClose: () => void;
    onConfirm: () => void;
    open: boolean;
}

const DeleteFloorModal = ({ isLoading, onClose, onConfirm, open }: Props) => {
    const styles = useDeleteFloorModalStyles();

    const { t } = useTranslation('estate');

    return (
        <Modal onClose={onClose} open={open} data-cypress="confirm-delete-floor-modal">
            <Modal.Title>{t('Confirm delete floor')}</Modal.Title>
            <Modal.Content className={styles.modalContent}>
                <Typography variant="body1">
                    {t(
                        'Deleting a floor removes it permanently and can’t be undone. Are you sure you want to delete this floor?',
                    )}
                </Typography>
            </Modal.Content>
            <Modal.Actions justify="space-between">
                <Button color="primary" onClick={onClose}>
                    {t("No, don't delete")}
                </Button>
                <Button
                    color="secondary"
                    data-cypress="delete-floor"
                    disabled={isLoading}
                    onClick={onConfirm}
                    variant="contained"
                >
                    {t('Yes, delete floor')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(DeleteFloorModal);
