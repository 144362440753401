import type { QueryRunner } from '@infogrid/core-cube';
import { CubeContext, cubeQueryRunner } from '@infogrid/core-cube';

import SETTINGS from 'settings';

interface WrapperProps {
    children: JSX.Element;
}

const loadQueryRunner = async () => {
    const setting = SETTINGS.CUBE_SHOULD_USE_SQUARE;
    const shouldUseSquare = String(setting) === 'true';
    if (shouldUseSquare) {
        const mod = await import(
            /* webpackChunkName: 'utils-square' */ '@infogrid/utils-square'
        );
        return mod.squareQueryRunner;
    }

    return cubeQueryRunner;
};

// load it once (SETTINGS are decided at build time)
const runnerPromise = loadQueryRunner();

const wrappedQueryRunner: QueryRunner = {
    runQuery: async (builder, context, endpointKey) => {
        // keep all useCube queries in {isLoading: true} until we know which runner to use
        const resolvedRunner = await runnerPromise;
        return resolvedRunner.runQuery(builder, context, endpointKey);
    },
};

const CubeProvider = ({ children }: WrapperProps) => {
    return (
        <CubeContext.Provider value={wrappedQueryRunner}>{children}</CubeContext.Provider>
    );
};

export default CubeProvider;
