import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { memo } from 'react';

import {
    useChecklistItemStyles,
    useChecklistCheckboxStyles,
    listItemIconStyles,
} from './styles';

type Props = {
    id: string | number;
    onOptionCheck: (id: string | number) => void;
    text: string;
    checked?: boolean;
    disabled?: boolean;
    indeterminate?: boolean;
};

const ChecklistItem = ({
    checked = false,
    id,
    indeterminate,
    onOptionCheck,
    text,
    disabled = false,
}: Props) => {
    const checklistItemStyles = useChecklistItemStyles();
    const checklistCheckboxClasses = useChecklistCheckboxStyles();
    const listItemIconClasses = listItemIconStyles();

    return (
        <ListItem
            className={checklistItemStyles.checklistItem}
            dense
            disableGutters
            data-cypress={`list-item-${id}`}
            button
            disabled={disabled}
            onClick={() => {
                onOptionCheck(id);
            }}
        >
            <ListItemIcon classes={listItemIconClasses}>
                <Checkbox
                    classes={checklistCheckboxClasses}
                    size="small"
                    data-cypress="sensor-checkbox"
                    checked={checked}
                    color="primary"
                    inputProps={{ 'aria-labelledby': `${id}` }}
                    disableRipple
                    indeterminate={indeterminate}
                    disabled={disabled}
                />
            </ListItemIcon>
            <ListItemText
                id={`${id}`}
                primary={text}
                primaryTypographyProps={{ variant: 'body2' }}
            />
        </ListItem>
    );
};

export default memo(ChecklistItem);
