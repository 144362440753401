import { makeStyles } from '@material-ui/core';

export const useStylesContainerBuildings = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',

        flex: '1 100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',

        flex: '1 100%',
    },
    floorPlanLoaderContainer: {
        flexGrow: 1,

        backgroundColor: theme.palette.common.gray5,
    },
    errorContainer: {
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
