import { ProgressBubble } from '@infogrid/components-material-ui';
import { List, ListItem, Typography, Tooltip } from '@material-ui/core';
import isNil from 'lodash/isNil';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    useMenuItemStyles,
    useTrendTooltipStyles,
    useProgressBubbleStyles,
    useTooltipStyles,
} from './styles';

export interface Props {
    scoreToday: number;
    scoreYesterday: number | null;
    scoreThirtyDaysAgo: number | null;
    children: React.ReactElement;
}

const TrendTooltip = ({
    scoreToday,
    scoreYesterday,
    scoreThirtyDaysAgo,
    children,
}: Props) => {
    const styles = useTrendTooltipStyles();
    const menuItemClasses = useMenuItemStyles();
    const progressBubbleClasses = useProgressBubbleStyles();
    const tooltipClasses = useTooltipStyles();

    const { t } = useTranslation('dashboard');

    const items = [
        { label: t('Today'), value: scoreToday },
        {
            label: t('Yesterday'),
            value: scoreYesterday,
        },
        {
            label: t('30 days ago'),
            value: scoreThirtyDaysAgo,
        },
    ];

    return (
        <Tooltip
            classes={tooltipClasses}
            enterTouchDelay={50}
            leaveTouchDelay={3000}
            title={
                <List disablePadding>
                    {items.map(({ label, value }, idx) => (
                        <ListItem
                            key={idx}
                            disableGutters
                            classes={menuItemClasses}
                            divider={idx !== items.length - 1}
                        >
                            <Typography color="textPrimary" variant="caption">
                                {t(label)}
                            </Typography>
                            {isNil(value) ? (
                                <Typography
                                    color="textPrimary"
                                    variant="caption"
                                    className={styles.value}
                                >
                                    –
                                </Typography>
                            ) : (
                                <ProgressBubble
                                    fontSize={12}
                                    value={value}
                                    classes={progressBubbleClasses}
                                />
                            )}
                        </ListItem>
                    ))}
                </List>
            }
        >
            {children}
        </Tooltip>
    );
};

export default memo(TrendTooltip);
