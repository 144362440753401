import type { FloorplanSpacesResponse } from '@infogrid/locations-api';
import type { SpaceTypeId } from '@infogrid/solution-views-types';

import { MODAL_STAGES } from './types';
import type { ModalStage } from './types';

export const mapToExistingSpaceTypes = (
    response: FloorplanSpacesResponse,
): SpaceTypeId[] =>
    response.groups
        .flatMap((spaceTypeGroup) => spaceTypeGroup.children)
        .filter((spaceTypes) => spaceTypes.space_count > 0)
        .map((existingSpaceTypes) => existingSpaceTypes.name);

export const getModalStage = (
    currentStage: ModalStage,
    direction: 'next' | 'back',
): ModalStage => {
    switch (currentStage) {
        case MODAL_STAGES.SELECT_SPACE_TYPES:
            return MODAL_STAGES.SELECT_RULES_APPLICATION;
        case MODAL_STAGES.SELECT_RULES_APPLICATION:
            return direction === 'next'
                ? MODAL_STAGES.SET_RULES
                : MODAL_STAGES.SELECT_SPACE_TYPES;
        default:
            return MODAL_STAGES.SELECT_RULES_APPLICATION;
    }
};

export const selectedSpaceTypesToArray = (
    selectedSpaceTypes: Record<string, boolean>,
) => {
    return Object.keys(selectedSpaceTypes).filter((key) => selectedSpaceTypes[key]);
};

interface IsNextbuttonDisabledProps {
    currentStage: ModalStage;
    selectedSpaceTypes?: Record<string, boolean>;
    selectedLocations?: number[];
}

export const isNextbuttonDisabled = ({
    currentStage,
    selectedSpaceTypes,
    selectedLocations,
}: IsNextbuttonDisabledProps): boolean => {
    switch (currentStage) {
        case MODAL_STAGES.SELECT_SPACE_TYPES:
            return selectedSpaceTypesToArray(selectedSpaceTypes || {}).length === 0;
        case MODAL_STAGES.SELECT_RULES_APPLICATION:
            return !(selectedLocations?.length && selectedLocations.length > 0);
        default:
            return true;
    }
};
