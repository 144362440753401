import type { Building } from '@infogrid/locations-types';
import { Button, CircularProgress, Icon, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import classNames from 'classnames';
import type { Location } from 'history';
import compact from 'lodash/compact';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BuildingsTableFilters } from 'components/estates/BuildingsTable/BuildingsTableFilters';
import Table, { COLUMN_TYPES } from 'components/material-ui/Table';
import type { Column } from 'components/material-ui/Table/constants';
import { DIRECTION } from 'utils/filtering';
import {
    useBuildingSortableOrderBy,
    useFormattedBuildingQueryParams,
} from 'utils/filtering/building';

import { useBuildingsTableStyles } from './styles';

export interface BuildingsTableProps {
    buildings: Building[];
    buildingPath: (buildingId: number) => Location | string;
    clearFiltersPath: Location | string;
    fetchNextPage: () => Promise<void>;
    isError?: boolean;
    errorMessage?: string;
    isLoading: boolean;
    isFetching: boolean;
    includeAlerts?: boolean;
    separateHubCount?: boolean;
    setBuildingToModify?: (building: Building | null) => void;
}

const BuildingsTable = ({
    buildings,
    buildingPath,
    clearFiltersPath,
    errorMessage,
    fetchNextPage,
    isError,
    isLoading,
    isFetching,
    includeAlerts,
    separateHubCount,
    setBuildingToModify,
}: BuildingsTableProps) => {
    const styles = useBuildingsTableStyles();
    const { t } = useTranslation('estate');

    const [orderBy, onOrderChange] = useBuildingSortableOrderBy();

    const {
        building_types: buildingTypesFromQuery,
        countries: countriesFromQuery,
        q: searchParam,
    } = useFormattedBuildingQueryParams();

    const areFiltersApplied = useMemo(
        () => countriesFromQuery || searchParam || buildingTypesFromQuery,
        [buildingTypesFromQuery, countriesFromQuery, searchParam],
    );

    const columns: Column<Building>[] = useMemo(() => {
        // Hooks must be run every render so we can't return the error markup before here, however
        // we can short-circuit all the following logic.
        if (isError) {
            return [];
        }

        const renderHeader = (
            label: string,
            name: string,
            {
                isNumeric,
                className,
                tooltip,
            }: { isNumeric?: boolean; className?: string; tooltip?: string } = {},
        ) => (
            <Table.SortableTableHeader
                name={name}
                onOrderChange={onOrderChange}
                orderBy={orderBy}
                classes={className ? { sortableHeader: className } : undefined}
                initialDirection={isNumeric ? DIRECTION.DESC : DIRECTION.ASC}
                tooltip={tooltip}
            >
                {label}
            </Table.SortableTableHeader>
        );

        const renderCell = (label?: string | number, dataCypress?: string) => (
            <Typography
                className={styles.cellLabel}
                data-cypress={dataCypress}
                variant="body2"
            >
                {label}
            </Typography>
        );

        const renderEditBuildingButton = (building: Building) => (
            <Button
                disabled={!building?.user_actions?.edit?.allowed}
                color="primary"
                data-cypress="edit-building"
                onClick={() => setBuildingToModify?.(building)}
                className={styles.edit}
            >
                {t('Edit')}
            </Button>
        );

        return compact([
            {
                renderHeader: () =>
                    renderHeader(t('Building name'), 'name', {
                        className: styles.nameHeader,
                    }),
                render: (building: Building) => (
                    <Link
                        className={styles.link}
                        data-cypress="sensor-name"
                        to={buildingPath(building.id)}
                    >
                        <Typography
                            className={styles.buildingName}
                            data-cypress="building-name"
                            variant="subtitle1"
                        >
                            {building.name}
                        </Typography>
                    </Link>
                ),
                type: COLUMN_TYPES.FLEXIBLE,
                width: 2,
            },
            {
                renderHeader: () => renderHeader(t('Type'), 'building_type'),
                render: (building: Building) =>
                    renderCell(building.building_type?.display_name, 'building-type'),
                type: COLUMN_TYPES.FLEXIBLE,
                width: 2,
            },
            {
                renderHeader: () => renderHeader(t('Address'), 'address'),
                render: (building: Building) =>
                    renderCell(building.location?.address, 'building-address'),
                type: COLUMN_TYPES.FLEXIBLE,
                width: 2,
            },
            {
                renderHeader: () => renderHeader(t('Country'), 'country'),
                render: (building: Building) =>
                    renderCell(building.location?.country_name, 'building-country'),
                type: COLUMN_TYPES.FLEXIBLE,
                width: 2,
            },
            {
                renderHeader: () =>
                    renderHeader(t('Floors'), 'floors_count', { isNumeric: true }),
                render: (building: Building) =>
                    renderCell(building.floors_count, 'building-floors'),
                type: COLUMN_TYPES.FIXED,
                width: 100,
            },
            {
                renderHeader: () =>
                    renderHeader(t('Spaces'), 'spaces_count', {
                        isNumeric: true,
                        tooltip: t(
                            'Spaces represent areas of a floor, such as meeting rooms. They are used to group sensors together.',
                        ),
                    }),
                render: (building: Building) =>
                    renderCell(building.spaces_count, 'building-spaces'),
                type: COLUMN_TYPES.FIXED,
                width: 100,
            },
            !!separateHubCount && {
                renderHeader: () =>
                    renderHeader(t('Hubs'), 'hubs_count', {
                        isNumeric: true,
                        tooltip: t(
                            'Hubs are devices that  provide connectivity to sensors. These include Cloud Connectors.',
                        ),
                    }),
                render: (building: Building) =>
                    renderCell(building.hubs_count, 'building-hubs'),
                type: COLUMN_TYPES.FIXED,
                width: 100,
            },
            {
                renderHeader: () =>
                    renderHeader(t('Sensors'), 'sensors_count', { isNumeric: true }),
                render: (building: Building) =>
                    renderCell(
                        separateHubCount
                            ? building.sensors_count
                            : building.sensors_count + (building.hubs_count || 0),
                        'building-sensors',
                    ),
                type: COLUMN_TYPES.FIXED,
                width: 100,
            },
            !!includeAlerts && {
                renderHeader: () => t('Alerts'),
                render: (building: Building) =>
                    renderCell(building.unacknowledged_alerts_count, 'building-sensors'),
                type: COLUMN_TYPES.FIXED,
                width: 100,
            },
            {
                renderHeader: () =>
                    renderHeader(t('Capacity'), 'capacity', { isNumeric: true }),
                render: (building: Building) =>
                    renderCell(building.occupant_capacity || '-', 'building-capacity'),
                type: COLUMN_TYPES.FIXED,
                width: 100,
            },
            !!setBuildingToModify && {
                renderHeader: () => null,
                render: renderEditBuildingButton,
                type: COLUMN_TYPES.FIXED,
                width: 100,
            },
        ]);
    }, [
        buildingPath,
        includeAlerts,
        isError,
        onOrderChange,
        orderBy,
        separateHubCount,
        setBuildingToModify,
        styles,
        t,
    ]);

    if (isError) {
        return (
            <Alert severity="warning" className={styles.errorMessage}>
                <AlertTitle>
                    {t('We can’t load any information right now. Try again later.')}
                </AlertTitle>
                {errorMessage || t('The server encountered an internal error.')}
            </Alert>
        );
    }

    const emptyStateRenderer = () => (
        <div className={styles.emptyStateRenderer}>
            <Typography
                className={styles.noBuildings}
                data-cypress="no-buildings"
                variant="body2"
            >
                {!areFiltersApplied
                    ? t('No buildings added')
                    : t('No matching search results. Try broadening your search.')}
            </Typography>
            {!areFiltersApplied && (
                <Button color="primary" variant="outlined">
                    <Icon className={classNames('far fa-plus', styles.addBuildingIcon)} />
                    {t('Add building')}
                </Button>
            )}
        </div>
    );

    return (
        <div className={styles.tableRoot}>
            <div className={styles.filtersContainer}>
                <BuildingsTableFilters clearFiltersPath={clearFiltersPath} />
            </div>
            <div className={styles.overlayContainer}>
                {isFetching && !isLoading && (
                    <div className={styles.overlayLoader}>
                        <CircularProgress />
                    </div>
                )}
                <Table<Building>
                    classes={{
                        container: styles.tableContainer,
                    }}
                    columns={columns}
                    data={buildings}
                    dataCypress="buildings-table"
                    EmptyStateRenderer={emptyStateRenderer}
                    isLoading={isLoading}
                    onFetchMore={fetchNextPage}
                />
            </div>
        </div>
    );
};

export default memo(BuildingsTable);
