import { makeStyles } from '@material-ui/core';

export const useDataFrequencyStyles = makeStyles((theme) => ({
    formControl: {
        width: '235px',
        '& > .MuiInputLabel-outlined': {
            backgroundColor: theme.palette.common.white,
            padding: theme.spacing(0, 0.5),
        },
    },
}));
