import { registerTranslationKey } from '@infogrid/utils-i18n';

import SettingsPlaceholder from 'views/integrations/components/SettingsPlaceholder/SettingsPlaceholder';

const IntegrationsIndex = () => {
    return (
        <SettingsPlaceholder
            label={registerTranslationKey('Select an integration to configure')}
            icon={'fal fa-wrench'}
        />
    );
};

export default IntegrationsIndex;
