import type { User } from '@infogrid/user-types';

import SETTINGS from 'settings';

export const isIntercomAvailable = (): boolean =>
    !!SETTINGS.INTERCOM_APP_ID && !!window?.Intercom;

export const isIntercomAvailableForUser = (user: User): boolean =>
    isIntercomAvailable() &&
    user.has_accepted_latest_terms &&
    !!user.intercom_hash &&
    user.intercom_hash !== '-1';

/**
 * Either shows or hides the Intercom bubble based on hideLauncher value
 */
export const toggleIntercomButton = (hideLauncher: boolean): void => {
    if (isIntercomAvailable()) {
        window.Intercom('update', {
            hide_default_launcher: hideLauncher,
        });
    }
};
