export const selectEditFloorId = (state) => state.floorPlan?.editFloorId;

export const selectFetchingCurrentFloorPlanStatus = (state) =>
    state.floorPlan?.fetchingCurrentFloorPlan;

export const selectCreatingBuildingStatus = (state) =>
    state.floorPlan?.isCreatingBuilding;
export const selectEditingBuildingStatus = (state) => state.floorPlan?.isEditingBuilding;
export const selectDeletingBuildingStatus = (state) =>
    state.floorPlan?.isDeletingBuilding;

export const selectEditingFloorStatus = (state) => state.floorPlan?.isEditingFloor;
export const selectDeletingFloorStatus = (state) => state.floorPlan?.isDeletingFloor;
export const selectReplacingFloorImageStatus = (state) =>
    state.floorPlan?.isReplacingFloorImage;

export const selectEditingFloorImageStatus = (state) => ({
    isOpen: state.floorPlan?.isEditingFloorImage,
    isOpenFromReplaceModal: state.floorPlan?.isOpenEditingFloorImageFromReplaceModal,
});

export const selectCreatingFloorStatus = (state) => state.floorPlan?.isCreatingFloor;
export const selectCreatingFolderStatus = (state) => state.floorPlan?.isCreatingFolder;

export const selectShouldKeepSensorsMapped = (state) =>
    state.floorPlan?.shouldKeepSensorsMapped;
