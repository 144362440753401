import { useAppDispatch, toastError } from '@infogrid/core-ducks';
import {
    TEMPERATURE_OFFSET_MODES,
    TEMPERATURE_OFFSET_STATUSES,
} from '@infogrid/sensors-constants';
import type {
    TemperatureOffsetMode as TemperatureOffsetModeType,
    SensorShape,
    SensorConfigurationShape,
} from '@infogrid/sensors-constants';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { invalidateSensor } from 'apiHooks/sensors/accessors';
import { useUpdateSensorConfiguration } from 'apiHooks/sensors/hooks';
import { TemperatureOffsetZeroCalibration } from 'components/SensorSideCard/base/components/TemperatureOffsetSetup';

import { useInstallationFlowContext } from '../context';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../reducer';

const ZERO_OFFSET_CONFIGURATION = {
    temperature_offset: 0,
    offset_mode: TEMPERATURE_OFFSET_MODES.AUTOMATIC,
    offset_status: TEMPERATURE_OFFSET_STATUSES.SET,
};

const TemperatureOffsetMode = () => {
    const { sensor } = useInstallationFlowContext();
    const offsetSensor = sensor as SensorShape;
    const sensorConfiguration =
        offsetSensor.sensor_configuration as SensorConfigurationShape;
    const dispatch = useAppDispatch();
    const { t } = useTranslation('sensors');

    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    const { mutate: updateSensorConfiguration } = useUpdateSensorConfiguration(
        offsetSensor.device_name,
    );

    const onChange = useCallback(
        (value: TemperatureOffsetModeType) => {
            if (value === TEMPERATURE_OFFSET_MODES.AUTOMATIC) {
                dispatchInstallationFlowContext({
                    type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
                });
                const updatedSensorConfiguration = {
                    ...sensorConfiguration,
                    ...ZERO_OFFSET_CONFIGURATION,
                };

                updateSensorConfiguration(
                    {
                        data: {
                            sensor_configuration: { ...updatedSensorConfiguration },
                        },
                    },
                    {
                        onSuccess: () => {
                            invalidateSensor(offsetSensor.device_name);
                            const updatedSensor = {
                                ...offsetSensor,
                                sensor_configuration: { ...updatedSensorConfiguration },
                            };
                            dispatchInstallationFlowContext({
                                type: INSTALLATION_FLOW_ACTIONS.INSTALLATION_SUCCEED,
                                sensor: updatedSensor,
                            });
                            dispatchInstallationFlowContext({
                                type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
                            });
                        },
                        onError: () => {
                            dispatch(
                                toastError(
                                    t(
                                        'There was problem automatically calibrating the sensor.',
                                    ),
                                ),
                            );
                        },
                    },
                );
            }

            if (value === TEMPERATURE_OFFSET_MODES.MANUAL) {
                dispatchInstallationFlowContext({
                    type: INSTALLATION_FLOW_ACTIONS.SET_TEMPERATURE_OFFSET,
                });
            }
        },
        [
            dispatch,
            dispatchInstallationFlowContext,
            offsetSensor,
            sensorConfiguration,
            t,
            updateSensorConfiguration,
        ],
    );

    const onCancel = useCallback(() => {
        dispatchInstallationFlowContext({ type: INSTALLATION_FLOW_ACTIONS.GO_BACK });
    }, [dispatchInstallationFlowContext]);

    return (
        <TemperatureOffsetZeroCalibration
            onCancel={onCancel}
            onChange={onChange}
            cancelDisplayText={t('Back')}
        />
    );
};

export default memo(TemperatureOffsetMode);
