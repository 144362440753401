import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
    useState,
    useCallback,
    memo,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';

import { selectors as uiSelectors, setSidebarWidthState } from 'ducks/ui';

import SidebarCollapseButton, {
    SIDEBAR_COLLAPSE_BUTTON_DIRECTION,
} from './SidebarCollapseButton';
import { SIDEBAR_COLLAPSE_BUTTON_POSITION } from './constants';
import { useSidebarStyles } from './styles';

const transitionResolver = (transitionValue) => `width ${transitionValue}ms`;
const collapseButtonTransitionResolver = (transitionValue) =>
    `opacity ${transitionValue}ms`;

/**
 * @param {import("./types").SidebarProps} props
 */
const Sidebar = forwardRef((props, ref) => {
    const {
        disableCollapseButton,
        uncollapsedWidth,
        collapsedWidth,
        transitionDuration,
        className,
        defaultOpened,
        children,
        collapseButton,
        isCollapseButtonAlwaysVisible,
        collapseButtonPosition,
        sidebarName,
    } = props;

    const dispatch = useAppDispatch();

    const sidebarWidthSelector = useCallback(
        (state) => uiSelectors.sidebarWidth(state, sidebarName),
        [sidebarName],
    );
    const width = useAppSelector(sidebarWidthSelector);

    const opened = width === uncollapsedWidth;
    const [isTransitioning, setTransitioning] = useState(false);

    // Keep a reference to the timeout set so it can be cleared before resetting if necessary
    const transitionTimeoutRef = useRef();

    useEffect(() => {
        // Sidebar width is stored in redux but initial state is passed to the component.
        // On first render propagate initial state to redux state
        if (width === null || width === undefined) {
            const initialWidth = defaultOpened ? uncollapsedWidth : collapsedWidth;

            dispatch(setSidebarWidthState(sidebarName, initialWidth));
        } else if (width !== uncollapsedWidth && width !== collapsedWidth) {
            dispatch(setSidebarWidthState(sidebarName, uncollapsedWidth));
        }
    }, [width, sidebarName, defaultOpened, uncollapsedWidth, collapsedWidth, dispatch]);

    useEffect(() => {
        if (transitionTimeoutRef.current) {
            clearTimeout(transitionTimeoutRef.current);
        }

        transitionTimeoutRef.current = setTimeout(() => {
            setTransitioning(false);
        }, transitionDuration);

        // Returns a clean up function that will ensure the timeout is cleared to prevent memory leaks
        return () => {
            if (transitionTimeoutRef.current) {
                clearTimeout(transitionTimeoutRef.current);
            }
        };
    }, [isTransitioning, transitionDuration, uncollapsedWidth, width]);

    const toggleCollapsedState = useCallback(
        (payload) => {
            if (isTransitioning) {
                return;
            }

            let newWidth;

            if (typeof payload === 'boolean') {
                newWidth = payload ? uncollapsedWidth : collapsedWidth;
            } else {
                newWidth = width === collapsedWidth ? uncollapsedWidth : collapsedWidth;
            }

            dispatch(setSidebarWidthState(sidebarName, newWidth));
            setTransitioning(true);
        },
        [isTransitioning, collapsedWidth, uncollapsedWidth, width, dispatch, sidebarName],
    );

    useImperativeHandle(ref, () => ({
        toggle: () => toggleCollapsedState(),
        opened,
    }));

    const sidebarClasses = useSidebarStyles({
        buttonTransition: collapseButtonTransitionResolver(transitionDuration),
        buttonTransitionDelay: transitionDuration,
        transition: transitionResolver(transitionDuration),
        width: width ?? 0,
        isCollapseButtonAlwaysVisible,
    });

    const renderCollapseButton = (displayPosition) => {
        if (displayPosition !== collapseButtonPosition) {
            return null;
        }

        const button =
            collapseButton &&
            collapseButton({
                navbarOpened: opened,
                toggleCollapsedState,
                isTransitioning,
                transitionDuration,
                onClick: toggleCollapsedState,
                ButtonComponent: SidebarCollapseButton,
                className: sidebarClasses.collapseButton,
            });

        return (
            button ||
            (!disableCollapseButton && !collapseButton && (
                <SidebarCollapseButton
                    className={sidebarClasses.collapseButton}
                    onClick={toggleCollapsedState}
                    navbarOpened={opened}
                    transitionDuration={transitionDuration}
                    location={SIDEBAR_COLLAPSE_BUTTON_DIRECTION.LEFT}
                    position={collapseButtonPosition}
                />
            ))
        );
    };

    return (
        <aside
            className={classNames(sidebarClasses.root, className)}
            data-testid={`Sidebar-container-${sidebarName}`}
            data-cypress={`Sidebar-container-${sidebarName}`}
            ref={ref}
        >
            {renderCollapseButton(SIDEBAR_COLLAPSE_BUTTON_DIRECTION.LEFT)}

            {children({
                opened,
                toggleCollapsedState,
                isTransitioning,
            })}

            {renderCollapseButton(SIDEBAR_COLLAPSE_BUTTON_DIRECTION.RIGHT)}
        </aside>
    );
});

Sidebar.propTypes = {
    disableCollapseButton: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    collapsedWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    defaultOpened: PropTypes.bool,
    collapseButton: PropTypes.func,
    collapseButtonPosition: PropTypes.oneOf(
        Object.values(SIDEBAR_COLLAPSE_BUTTON_POSITION),
    ),
    transitionDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    uncollapsedWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    isCollapseButtonAlwaysVisible: PropTypes.bool,
    sidebarName: PropTypes.string.isRequired,
};

Sidebar.defaultProps = {
    className: '',
    children: null,
    disableCollapseButton: false,
    collapseButton: null,
    collapseButtonPosition: SIDEBAR_COLLAPSE_BUTTON_POSITION.RIGHT,
    defaultOpened: false,
    transitionDuration: 0,
    isCollapseButtonAlwaysVisible: false,
};

export default memo(Sidebar);
