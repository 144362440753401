import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import type { FC } from 'react';
import { useState, useEffect, useCallback } from 'react';

import { useRegisterSensor } from 'apiHooks/sensors/installation/hooks';
import QRCodeScanner from 'components/InstallationFlow/QRCodeScanner';
import {
    INSTALL_STEPS,
    goToStep,
    handleInstallError,
    onSensorIdentified,
    selectPlannedSensor,
} from 'ducks/installFlow';

const BarcodeScan: FC = () => {
    const dispatch = useAppDispatch();
    const plannedSensor = useAppSelector(selectPlannedSensor);
    const deviceType = plannedSensor?.device_type.name
        ? getSensorConfig(plannedSensor?.device_type.name).label
        : undefined;
    const [scannedCode, setScannedCode] = useState<string>();

    const { mutate: identifySensor, isLoading } = useRegisterSensor({
        onSuccess: (data) => {
            dispatch(onSensorIdentified(data));
        },
        onError: ({ response }) => {
            dispatch(handleInstallError(response));
        },
    });

    const onScan = useCallback((code) => setScannedCode(code), [setScannedCode]);

    // Calling `identifySensor` directly inside `onScan` causes bugs with
    // stale values, so just store the scanned code and use an effect instead
    useEffect(() => {
        if (scannedCode && !isLoading) {
            identifySensor({ qr_code: scannedCode });
        }
    }, [isLoading, identifySensor, scannedCode]);

    const onClickManualIdentify = useCallback(
        () => dispatch(goToStep(INSTALL_STEPS.MANUAL_IDENTIFY)),
        [dispatch],
    );

    return (
        <QRCodeScanner
            onScan={onScan}
            deviceName={plannedSensor?.name}
            deviceType={deviceType}
            onClickManualIdentify={onClickManualIdentify}
        />
    );
};

export default BarcodeScan;
