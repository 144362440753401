import type { Dashboard } from '@infogrid/dashboards-constants';
import { useSetDefaultDashboard } from '@infogrid/dashboards-hooks';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import type { MouseEvent } from 'react';
import { memo } from 'react';

import { trackDashboardEvent } from 'views/dashboards/utils/analytics';

import { useSelectOutputStyles } from './styles';

const DEFAULT_DASHBOARD_ICON = 'fas fa-star';
const NON_DEFAULT_DASHBOARD_ICON = 'far fa-star';

export interface DashboardSelectOutputProps {
    selectedDashboard: Dashboard;
}

const DashboardSelectOutput = ({ selectedDashboard }: DashboardSelectOutputProps) => {
    const { mutate: mutateDefaultDashboard } = useSetDefaultDashboard();

    const selectDefaultDashboard = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        trackDashboardEvent(USER_ACTIONS.PRESSED, 'set default dashboard', {
            name: selectedDashboard.name,
            id: selectedDashboard.id,
        });

        if (!selectedDashboard.is_default) {
            mutateDefaultDashboard(selectedDashboard);
        }
    };

    const selectOutputClasses = useSelectOutputStyles();

    return (
        <>
            <IconButton
                onClick={selectDefaultDashboard}
                className={selectOutputClasses.dashboardSelectIconContainer}
                data-cypress="favourite-icon"
            >
                <Icon
                    className={classNames(selectOutputClasses.dashboardSelectIcon, {
                        [DEFAULT_DASHBOARD_ICON]: selectedDashboard.is_default,
                        [NON_DEFAULT_DASHBOARD_ICON]: !selectedDashboard.is_default,
                    })}
                />
            </IconButton>

            <Tooltip title={selectedDashboard.name}>
                <Typography
                    className={selectOutputClasses.dashboardSelectText}
                    component="h6"
                    data-cypress="selected-dashboard"
                >
                    {selectedDashboard.name}
                </Typography>
            </Tooltip>
        </>
    );
};

export default memo(DashboardSelectOutput);
