import { makeStyles } from '@material-ui/core';

export const useStylesButtonSpinner = makeStyles((theme) => ({
    primary: {
        color: theme.palette.primary.main,
    },
    default: {
        color: theme.palette.common.white,
    },
    container: {
        marginRight: '4px',
    },
}));
