import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Widget } from 'views/dashboards/components/Widget';

interface FooterProps {
    spaceCount: number;
}

export const Footer = ({ spaceCount }: FooterProps): JSX.Element => {
    const { t } = useTranslation('dashboard');

    return (
        <Widget.Footer justifyContent="flex-end">
            <Widget.Options>
                <Widget.Option data-testid="sensors-count-label">
                    <i className="fal fa-cube" />
                    <Typography variant="caption">
                        {t('{{count}} Spaces', {
                            count: spaceCount,
                            defaultValue___one: `${spaceCount} Space`,
                            defaultValue___other: `${spaceCount} Spaces`,
                        })}
                    </Typography>
                </Widget.Option>
                <Widget.Option>
                    <i className="fal fa-calendar-alt" />
                    <Typography variant="caption">{t('Live')}</Typography>
                </Widget.Option>
            </Widget.Options>
        </Widget.Footer>
    );
};
