import { applicationSelectors, Constance } from '@infogrid/core-ducks';
import { useUpdateWidget } from '@infogrid/dashboards-hooks';
import { useSpaceTypes } from '@infogrid/locations-api';
import { selectSpaceTypes } from '@infogrid/locations-ducks';
import { useSelectorWithArgs } from '@infogrid/utils-hooks';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useAllBuildings } from 'apiHooks/floorPlan/buildings/hooks';
import { useFloor, useFloors } from 'apiHooks/floorPlan/floors/hooks';
import { useMappedSensors } from 'apiHooks/floorPlan/sensors/hooks';
import getAppURI from 'utils/getAppURI';

import FloorPlanWrapper from '../../common/FloorPlan/FloorPlanWrapper';
import Footer from '../../common/FloorPlan/Footer';
import FloorPlan from './FloorPlan';
import { useFloorPlanWidgetContentStyles } from './styles';

const Content = ({ canUserEdit, widget, inView }) => {
    const { dashboardId } = useParams();

    const styles = useFloorPlanWidgetContentStyles();

    const {
        building_id: buildingId,
        floor_id: floorId,
        orientation,
        zoom,
    } = widget?.configuration?.calculators?.floor_plan || {};

    const livePollingDelay =
        useSelectorWithArgs(
            applicationSelectors.getConstanceValue,
            Constance.POLL_EVENTS_IN_FRONTEND,
        ) * 1000;

    const { mutate: updateWidget } = useUpdateWidget({
        displayPositiveNotifications: false,
    });

    const { buildings, isLoading: isBuildingsLoading } = useAllBuildings(
        {},
        {
            refetchOnMount: true,
            enabled: inView,
        },
    );

    const { floors, isLoading: isFloorsLoading } = useFloors(
        buildingId,
        {},
        {
            refetchOnMount: true,
            enabled:
                inView &&
                !!buildingId &&
                !!buildings &&
                !!buildings.find((x) => x.id === buildingId),
        },
    );

    const { data: floor, isLoading: isFloorLoading } = useFloor(floorId, {
        refetchOnMount: true,
        enabled:
            inView && !!floorId && !!floors && !!floors.find((x) => x.id === floorId),
    });

    const { mappedSensors, isLoading: isSensorsLoading } = useMappedSensors(
        { floorId },
        {
            refetchOnMount: true,
            enabled: inView && !!floorId && !!floor,
            refetchInterval: livePollingDelay || false,
        },
    );

    const sensorsWithCoordinates = useMemo(() => {
        if (!floor?.sensors || !mappedSensors.length) {
            return [];
        }

        const sensorsById = mappedSensors.reduce((a, x) => ({ ...a, [x.id]: x }), {});

        return floor.sensors.reduce((accum, { sensor_id, x, y }) => {
            if (sensorsById[sensor_id]) {
                return [
                    ...accum,
                    {
                        ...sensorsById[sensor_id],
                        coordinates: { x, y },
                    },
                ];
            }

            return accum;
        }, []);
    }, [floor, mappedSensors]);

    const onUpdateConfiguration = useCallback(
        (config) => {
            const prevConfig = widget?.configuration?.calculators?.floor_plan || {};

            updateWidget({
                dashboardId: +dashboardId,
                widgetId: widget.id,
                data: {
                    ...widget,
                    configuration: {
                        calculators: {
                            floor_plan: {
                                ...prevConfig,
                                ...config,
                            },
                        },
                    },
                },
            });
        },
        [dashboardId, updateWidget, widget],
    );

    const onSelectBuilding = useCallback(
        (id) => {
            if (id !== buildingId) {
                onUpdateConfiguration({ building_id: id });
            }
        },
        [buildingId, onUpdateConfiguration],
    );

    const onSelectFloor = useCallback(
        (id) => {
            if (id !== floorId) {
                onUpdateConfiguration({ floor_id: id });
            }
        },
        [floorId, onUpdateConfiguration],
    );

    const { data: spaceTypes } = useSpaceTypes({
        select: selectSpaceTypes,
        refetchOnMount: false,
    });

    if (spaceTypes === undefined) {
        throw new Error('You have to load the space types in the base component');
    }

    const isDataLoading =
        isBuildingsLoading || isFloorsLoading || isFloorLoading || isSensorsLoading;

    return (
        <>
            <div className={styles.container}>
                {isDataLoading && (
                    <div className={styles.progress}>
                        <CircularProgress color="primary" size={40} />
                    </div>
                )}
                {inView && !isDataLoading && (
                    <FloorPlanWrapper
                        canUserEdit={canUserEdit}
                        selectedBuildingId={buildingId}
                        buildings={buildings}
                        onSelectBuilding={onSelectBuilding}
                        selectedFloorId={floorId}
                        floors={floors}
                        floor={floor}
                        onSelectFloor={onSelectFloor}
                    >
                        {() => (
                            <FloorPlan
                                buildingId={buildingId}
                                buildings={buildings}
                                canUserEdit={canUserEdit}
                                floorId={floorId}
                                floors={floors}
                                imageUrl={getAppURI(floor.image.image_file)}
                                imageWidth={floor.image.width}
                                imageHeight={floor.image.height}
                                orientation={orientation}
                                zoom={zoom}
                                sensors={sensorsWithCoordinates}
                                activeSensorTypes={
                                    widget?.configuration?.calculators?.floor_plan
                                        ?.selected_sensor_types || []
                                }
                                saveConfig={onUpdateConfiguration}
                                sensorIconScale={
                                    widget.configuration.calculators.floor_plan.scale
                                }
                                spaceTypes={spaceTypes}
                            />
                        )}
                    </FloorPlanWrapper>
                )}
            </div>

            <Footer
                buildingId={buildingId}
                floorId={floorId}
                sensorsCount={mappedSensors.length}
                isSensorsCountVisible={!isDataLoading && !!floor?.image?.image_file}
            />
        </>
    );
};

Content.propTypes = {
    inView: PropTypes.bool.isRequired,
    canUserEdit: PropTypes.bool,
    widget: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
        configuration: PropTypes.shape({
            calculators: PropTypes.shape({
                floor_plan: PropTypes.shape({
                    building_id: PropTypes.number,
                    floor_id: PropTypes.number,
                    selected_sensor_types: PropTypes.arrayOf(PropTypes.string),
                    scale: PropTypes.number,
                }),
            }),
        }),
    }).isRequired,
};

Content.defaultProps = {
    canUserEdit: false,
};

export default memo(Content);
