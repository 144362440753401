// createReducer expects you to mutate the state object
/*eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }]*/
// Note: Using Redux
import { createAction, createReducer } from '@reduxjs/toolkit';
import noop from 'lodash/noop';

export const loadingActions = {
    setLoadedView: createAction(
        '@@tg-spa-pending-data/FINISH_LOADING_VIEW',
        (key: string | undefined) => ({ payload: key }),
    ),
    startLoadingResource: createAction(
        '@@tg-spa-pending-data/START_LOADING_DATA',
        (key: string) => ({ payload: key }),
    ),
    finishLoadingResource: createAction(
        '@@tg-spa-pending-data/FINISH_LOADING_DATA',
        (key: string) => ({ payload: key }),
    ),
} as const;

export type LoadingActions =
    | typeof loadingActions.setLoadedView
    | typeof loadingActions.startLoadingResource
    | typeof loadingActions.finishLoadingResource;

interface DataLoadingState {
    [key: string]: boolean;
}

export interface LoadingStateType {
    view: string | undefined;
    data: DataLoadingState;
}

export interface LoadingState {
    loading: LoadingStateType;
}

const initialState: LoadingStateType = {
    data: {},
    view: undefined,
};

export const loadingReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(loadingActions.setLoadedView, (state, action) => {
            state.view = action.payload;
        })
        .addCase(loadingActions.startLoadingResource, (state, action) => {
            state.data[action.payload] = true;
        })
        .addCase(loadingActions.finishLoadingResource, (state, action) => {
            if (state.data[action.payload]) {
                delete state.data[action.payload];
            }
        })
        .addDefaultCase(noop);
});

export const getLoadedView = <T extends LoadingState>(state: T): string | undefined =>
    state.loading.view;

export const isLoading = <T extends LoadingState>(state: T): boolean =>
    Object.values(state.loading.data).some(Boolean);
