import {
    Checkbox,
    CircularProgress,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { IndeterminateCheckBox } from '@material-ui/icons';
import { memo, useCallback, useState } from 'react';

import { usePaginatedTreeSelectorStyles } from './styles';
import type {
    PaginatedTreeChildNode,
    PaginatedTreeCurrentNode,
    CustomColumn,
} from './types';

export interface PaginatedTreeSelectorProps<
    ChildNodeType extends PaginatedTreeChildNode,
    NodeType extends PaginatedTreeCurrentNode<ChildNodeType>,
> {
    /*
     * tree to be rendered on screen, with selectable nodes
     */
    content: NodeType;
    /*
     * will be inserted before the final column (Unused until HB-1187)
     */
    customColumns?: CustomColumn<ChildNodeType>[];
    /*
     * Cypress data tag
     */
    dataCypress?: string;
    /*
     * a method that converts the type to a string, used for anything that refers to the nodes
     * in a generic way e.g. the "all" selector
     */
    getLabelFromType: (type: string | null) => string;
    /*
     * dont allow the use to traverse down to spaces
     */
    hideSpaces?: boolean;
    /*
     * is the passed in data still being loaded
     */
    isLoading?: boolean;
    /*
     * called whenever the user clicks on a button to traverse up or down the tree (Unused until HB-1184)
     */
    onChangePage?: (id: number) => void;
    /*
     * called whenever a node is selected or deselected. passed the node changed and the selection state
     */
    onChangeSelection: ({
        nodeIds,
        selected,
    }: {
        nodeIds: number[];
        selected: boolean;
    }) => void;
}

const NODE = {
    PARENT: 'parent',
    CHILD: 'child',
};

const getAllTypesLabel = (type: string | null) => `All ${type}s`;

const PaginatedTreeSelector = <
    ChildNodeType extends PaginatedTreeChildNode,
    NodeType extends PaginatedTreeCurrentNode<ChildNodeType>,
>({
    onChangePage,
    onChangeSelection,
    content,
    customColumns = [],
    dataCypress,
    getLabelFromType,
    hideSpaces,
    isLoading,
}: PaginatedTreeSelectorProps<ChildNodeType, NodeType>): JSX.Element => {
    const [activeTab, setActiveTab] = useState('');

    const styles = usePaginatedTreeSelectorStyles({
        isLoading,
    });

    const onChangePageClick = useCallback(
        (id) => {
            if (onChangePage) {
                setActiveTab(id === content.parent?.id ? NODE.PARENT : NODE.CHILD);
                onChangePage(id);
            }
        },
        [onChangePage, content.parent?.id],
    );

    const allSelected =
        content.children.filter((child) => child.selected === true).length ===
        content.children.length;

    const isSomeSelected =
        content.children.filter(
            (child) => child.selected === 'indeterminate' || child.selected === true,
        ).length > 0;

    const selectOrDeselectAllNodes = () => {
        onChangeSelection({
            nodeIds: [...content.children.map((child) => child.id)],
            selected: allSelected ? false : isSomeSelected ? false : true,
        });
    };

    // TODO: turn this into a util func, or a hook (end of epic or post-mvp)
    const columns = [
        {
            HeaderCell: () =>
                content.parent && (
                    <IconButton
                        onClick={() => onChangePageClick(content.parent?.id)}
                        aria-label="parent-button"
                        size="small"
                    >
                        <Icon className={`far fa-chevron-left ${styles.icon}`} />
                    </IconButton>
                ),
            DataCell: (row: ChildNodeType) => (
                <Checkbox
                    data-testid={`paginated-tree-selector-checkbox-${row.id}`}
                    checked={row.selected === true}
                    onChange={() =>
                        onChangeSelection({ nodeIds: [row.id], selected: !row.selected })
                    }
                    indeterminate={row.selected === 'indeterminate'}
                    indeterminateIcon={<IndeterminateCheckBox color="primary" />}
                    color="primary"
                />
            ),
            key: 'checkboxHeaderCell',
            className: styles.checkboxCell,
        },
        {
            HeaderCell: () => content.name,
            DataCell: (row: ChildNodeType) => row.name,
            key: 'nameColumn',
            className: styles.nameCell,
        },
        ...customColumns.map((column) => ({
            HeaderCell: () => (column.HeaderCell ? column.HeaderCell() : null),
            DataCell: (row: ChildNodeType) => column.DataCell(row),
            key: column.key,
            className: styles.tableCell,
        })),
        {
            HeaderCell: () => null,
            DataCell: (row: ChildNodeType) =>
                !row.hasChildren || (row.type === 'floor' && hideSpaces) ? null : (
                    <IconButton
                        size="small"
                        onClick={() => onChangePageClick(row.id)}
                        aria-label="child-button"
                    >
                        <Icon
                            className={`far fa-chevron-right ${styles.icon}`}
                            fontSize="small"
                        />
                    </IconButton>
                ),
            key: 'transitionColumn',
            className: styles.transitionButtonCell,
        },
    ];

    const overflowTable = (className: string | undefined) => (
        <TableContainer
            data-cypress={dataCypress}
            component={Paper}
            className={className}
        >
            <Table aria-label="table" className={styles.table}>
                <TableHead>
                    <TableRow className={styles.tableHeadingRow}>
                        <TableCell
                            key="checkboxCell"
                            className={styles.transitionButtonCell}
                        >
                            <IconButton size="small" aria-label="child-button">
                                <Icon
                                    className={`far fa-chevron-left ${styles.icon}`}
                                    fontSize="small"
                                />
                            </IconButton>
                        </TableCell>
                        <TableCell key="nameHeaderCell" className={styles.tableCell}>
                            Loading data...
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            <div className={styles.loadingTableBody}>
                <CircularProgress className={styles.loader} color="primary" size={30} />
            </div>
        </TableContainer>
    );

    const allChildrenTypesString = getAllTypesLabel(
        getLabelFromType(content.children[0].type),
    );

    return (
        <div className={styles.container}>
            {isLoading && activeTab === NODE.PARENT && overflowTable(styles.parentTable)}
            <TableContainer
                data-cypress={dataCypress}
                component={Paper}
                className={styles.tableContainer}
            >
                <Table aria-label="table" stickyHeader>
                    {!isLoading && (
                        <>
                            <TableHead className={styles.stickyHeader}>
                                <TableRow className={styles.tableHeadingRow}>
                                    {columns.map((col) => (
                                        <TableCell
                                            key={col.key}
                                            className={col.className}
                                        >
                                            {col.HeaderCell()}
                                        </TableCell>
                                    ))}
                                </TableRow>
                                <TableRow className={styles.tableSelectAllRow}>
                                    <TableCell className={styles.checkboxCell}>
                                        <Checkbox
                                            checked={allSelected}
                                            onChange={selectOrDeselectAllNodes}
                                            indeterminate={!allSelected && isSomeSelected}
                                            indeterminateIcon={
                                                <IndeterminateCheckBox color="primary" />
                                            }
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell
                                        key="nameHeaderCell"
                                        className={styles.tableCell}
                                        colSpan={columns.length - 1}
                                    >
                                        {allChildrenTypesString}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {content.children.map((row) => (
                                    <TableRow key={row.id}>
                                        {columns.map((col) => (
                                            <TableCell
                                                key={col.key}
                                                className={col.className}
                                            >
                                                {col.DataCell(row as ChildNodeType)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </>
                    )}
                </Table>
            </TableContainer>
            {content.children.filter((child) => child.hasChildren) &&
                isLoading &&
                activeTab === NODE.CHILD &&
                overflowTable(styles.childTable)}
        </div>
    );
};

export default memo(PaginatedTreeSelector) as typeof PaginatedTreeSelector;
