import type {
    MeetingRoomOccupancyInitialValues,
    MeetingRoomOccupancyWidget,
    MeetingRoomOccupancyWidgetConfiguration,
} from './types';

export const prepareConfigurationPayload = (
    values: MeetingRoomOccupancyInitialValues,
): MeetingRoomOccupancyWidgetConfiguration => ({
    calculators: {
        space_occupancy: values,
    },
});

export const prepareInitialValues = (
    widget: MeetingRoomOccupancyWidget,
): MeetingRoomOccupancyInitialValues => ({
    locations: widget.configuration.calculators.space_occupancy.locations,
});
