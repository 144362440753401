import { createRequest } from '@infogrid/core-api';
import type { OrganizationIdType } from '@infogrid/core-types';
import type { AxiosPromise } from 'axios';

import type { Thresholds } from 'utils/types/ts/threshold';

const getThresholds = (
    organizationId: OrganizationIdType,
    signal: AbortSignal | undefined,
): AxiosPromise<Thresholds> =>
    createRequest({
        method: 'get',
        url: `org/${organizationId}/remote_monitoring_configuration`,
        signal,
    });

const updateThresholds = (
    organizationId: OrganizationIdType,
    data: Thresholds,
): AxiosPromise<Thresholds> =>
    createRequest({
        method: 'post',
        url: `org/${organizationId}/remote_monitoring_configuration`,
        options: {
            data,
        },
    });

export const controllers = {
    getThresholds,
    updateThresholds,
};
