import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericWidgetContainer } from 'views/dashboards/components/Widget/GenericWidget';
import ConfigureWidgetModal from 'views/dashboards/components/modals/ConfigureWidgetModal';
import {
    extractTotalEventsCountLabelType,
    extractTotalOpenEventsCount,
} from 'views/dashboards/widgets/ProximityCountWidget/utils';
import { extractTotalTouchEventsCount } from 'views/dashboards/widgets/TouchCountWidget/utils';

import Content from './Content';
import { widgetConfigurationModalTabs } from './constants';
import { prepareConfigurationPayload, prepareInitialValues } from './utils';

const titleProps = {
    titleIcon: (
        <>
            <i className="far fa-door-open" />
            <i className="far fa-plus" />
            <i className="far fa-hand-point-up" />
        </>
    ),
};

const widgetConfigurationModalProps = {
    prepareConfigurationPayload,
    prepareInitialValues,
    tabs: widgetConfigurationModalTabs,
};

const ProximityAndTouchCountWidget = ({ widget, ...props }) => {
    const { t } = useTranslation('dashboard');

    const widgetDetails = useMemo(
        () => [
            {
                labelExtractor: (options) => {
                    const type = extractTotalEventsCountLabelType(options);

                    return t('Total {{type}} event count:', {
                        type: t(type),
                        defaultValue: `Total ${type} event count:`,
                    });
                },
                valueExtractor: extractTotalOpenEventsCount,
            },
            {
                labelExtractor: () => t('Total touch event count:'),
                valueExtractor: extractTotalTouchEventsCount,
            },
        ],
        [t],
    );

    return (
        <GenericWidgetContainer
            contentComponent={Content}
            configureWidgetModal={ConfigureWidgetModal}
            details={widgetDetails}
            titleProps={titleProps}
            widget={widget}
            widgetConfigurationModalProps={widgetConfigurationModalProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
};

ProximityAndTouchCountWidget.propTypes = {
    widget: PropTypes.shape({}).isRequired,
};

export default memo(ProximityAndTouchCountWidget);
