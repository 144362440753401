import {
    LocationPickerField,
    generateDisplayValueStringWithinBuilding,
} from '@infogrid/components-locations';
import { Modal } from '@infogrid/components-material-ui';
import type { AxiosParsedError } from '@infogrid/core-types';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { invalidateSensor } from 'apiHooks/sensors/accessors';
import { useUpdateElectricitySensorConfiguration } from 'apiHooks/sensors/hooks';
import EndUsePicker from 'components/material-ui/Sensors/EndUsePicker';
import { SENSOR_ID_FIELD } from 'utils/sensor';

import SensorDisplay from '../SensorDisplay';
import { useInstallationFlowContext } from '../context';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../reducer';
import { useInstallationFlowStyles } from '../styles';
import { useMonnitConfigurationStyles } from './styles';
import type { MonnitConfigurationFormValues } from './types';
import { useMonnitConfigurationFormik } from './useMonnitConfigurationFormik';

const MonnitConfiguration = () => {
    const isMobile = useIsMobile();
    const classes = useMonnitConfigurationStyles({ isMobileView: isMobile });
    const { t } = useTranslation('sensors');
    const { sensor, folderId } = useInstallationFlowContext();
    const installationFlowStyles = useInstallationFlowStyles();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    const { mutate: updateSensorConfiguration } = useUpdateElectricitySensorConfiguration(
        SENSOR_TYPE.TYPE_ELECTRICITY_CT_CLAMP,
    );

    const formik = useMonnitConfigurationFormik({
        onSubmit: (formValues: MonnitConfigurationFormValues) => {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
            });

            if (formValues.end_use) {
                updateSensorConfiguration(
                    {
                        id: sensor?.[SENSOR_ID_FIELD] as string,
                        data: {
                            sensor_configuration: {
                                end_use: formValues.end_use,
                                voltage: formValues.voltage,
                            },
                            profile: {
                                monitoring_locations: formValues.monitoring_locations,
                            },
                        },
                    },
                    {
                        onSuccess: () => {
                            invalidateSensor(sensor?.[SENSOR_ID_FIELD]);

                            dispatchInstallationFlowContext({
                                type: INSTALLATION_FLOW_ACTIONS.INSTALLATION_SUCCEED,
                                folderId,
                            });
                        },
                        onError: (
                            err: AxiosParsedError<MonnitConfigurationFormValues>,
                        ) => {
                            if (err.parsedError) {
                                const { errors: formikErrors, message } = err.parsedError;

                                if (formikErrors) {
                                    formik.setErrors(formikErrors);
                                }

                                if (message) {
                                    formik.setStatus({ message });
                                }
                            }
                        },
                        onSettled: () => {
                            dispatchInstallationFlowContext({
                                type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
                            });
                        },
                    },
                );
            }
        },
    });

    const { values, errors, setFieldValue, handleSubmit } = formik;

    const onBack = useCallback(() => {
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.GO_BACK,
        });
    }, [dispatchInstallationFlowContext]);

    const sensorConfig = useMemo(
        () => getSensorConfig(SENSOR_TYPE.TYPE_ELECTRICITY_CT_CLAMP),
        [],
    );

    const onMonitoringLocationChange = useCallback(
        (value: number[]) => {
            setFieldValue('monitoring_locations', value);
        },
        [setFieldValue],
    );
    const onEndUseChange = useCallback(
        (value: string) => {
            setFieldValue('end_use', value);
        },
        [setFieldValue],
    );

    return (
        <>
            <Modal.Title>{t('Configure the device')}</Modal.Title>

            <Modal.Content>
                <form
                    id="configurationForm"
                    onSubmit={handleSubmit}
                    className={installationFlowStyles.form}
                >
                    {sensor && sensorConfig ? (
                        <SensorDisplay
                            sensor={sensor}
                            label={sensorConfig.label}
                            deviceType={SENSOR_TYPE.TYPE_ELECTRICITY_CT_CLAMP}
                        />
                    ) : null}
                    {sensor?.floorplan_location?.building_id && (
                        <LocationPickerField
                            label={t('Monitoring location')}
                            helperText={t(
                                'Select the locations this sensor is measuring',
                            )}
                            targetLocation={sensor.floorplan_location.building_id}
                            onChange={onMonitoringLocationChange}
                            errorMessage={errors.monitoring_locations as string}
                            generateDisplayValueString={
                                generateDisplayValueStringWithinBuilding
                            }
                        />
                    )}
                    <Alert color="info" severity="info" className={classes.info}>
                        {t(
                            'This represents the locations the sensor is measuring, not the physical installation location. For example, a sensor may be installed in the basement but be measuring consumption for the 3rd floor lighting. In this example the monitoring location would be ‘Floor 3’',
                        )}
                    </Alert>
                    <EndUsePicker
                        value={values.end_use}
                        onChange={onEndUseChange}
                        helperText={t('Select the end use the sensor is measuring')}
                        errorMessage={errors.end_use}
                        className={classes.input}
                    />
                </form>
            </Modal.Content>

            <Modal.Actions
                justify="space-between"
                className={installationFlowStyles.actions}
            >
                <Button onClick={onBack} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    data-cypress="save"
                    type="submit"
                    form="configurationForm"
                >
                    {t('Save')}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(MonnitConfiguration);
