import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import Sensors from 'views/dashboards/components/modals/ConfigureWidgetModal/Sensors';
import OccupancyStoplightDisplayOptions from 'views/dashboards/widgets/PeopleCountingStoplightWidget/DisplayOptions/DisplayOptions';

import Stoplight from '../common/DisplayOptions/Stoplight';

export const widgetConfigurationModalTabs = [
    {
        id: 'sensors',
        name: registerTranslationKey('Sensors', { ns: 'common' }),
        component: Sensors,
        attributes: {
            allowFoldersSelection: false,
            sensorTypeFilter: SENSOR_TYPE.TYPE_SPACE,
            maxSelectedSensors: 1,
        },
    },
    {
        id: 'display-options',
        name: registerTranslationKey('Display Options', { ns: 'common' }),
        component: OccupancyStoplightDisplayOptions,
    },
    {
        id: 'stoplight',
        name: registerTranslationKey('Stoplight Text', { ns: 'common' }),
        component: Stoplight,
    },
];
