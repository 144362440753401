import { makeStyles } from '@material-ui/core';

import { SIDEBAR_COLLAPSE_BUTTON_POSITION } from './constants';

const DEFAULT_TRANSITION = 'none';
const DEFAULT_COLLAPSE_ICON_TRANSITION = 'none';

interface SidebarStylesProps {
    transition: string;
    width: number;
    buttonTransition: string;
    buttonTransitionDelay: number;
    isCollapseButtonAlwaysVisible: boolean;
}

export const useSidebarStyles = makeStyles({
    root: ({ transition, width }: SidebarStylesProps) => ({
        transition: transition || DEFAULT_TRANSITION,
        flexShrink: 0,

        width,

        '&:hover $collapseButton': {
            opacity: 1,
            transitionDelay: '0s',
        },

        // Hide on page print
        '@media print': {
            display: 'none',
        },
    }),
    collapseButton: ({
        buttonTransition = DEFAULT_COLLAPSE_ICON_TRANSITION,
        buttonTransitionDelay = 0,
        isCollapseButtonAlwaysVisible,
    }) => ({
        opacity: isCollapseButtonAlwaysVisible ? 1 : 0,
        transitionDelay: `${buttonTransitionDelay}ms`,
        transition: isCollapseButtonAlwaysVisible ? 'none' : buttonTransition,
    }),
});

interface SidebarCollapseButtonStylesProps {
    position: string;
}

export const useSidebarCollapseButtonStyles = makeStyles((theme) => ({
    root: ({ position }: SidebarCollapseButtonStylesProps) => ({
        position: 'absolute',
        top: '50%',
        right: position === SIDEBAR_COLLAPSE_BUTTON_POSITION.RIGHT ? 0 : undefined,
        left: position === SIDEBAR_COLLAPSE_BUTTON_POSITION.LEFT ? 0 : undefined,
        transform:
            position === SIDEBAR_COLLAPSE_BUTTON_POSITION.RIGHT
                ? 'translate(50%, -25%)'
                : 'translate(-50%, -25%)',
        zIndex: 1,
    }),
    icon: {
        transition: DEFAULT_COLLAPSE_ICON_TRANSITION,
        width: '1.2em',
        lineHeight: '1.2em',
        textAlign: 'center',
        height: '1.2em',
    },
    iconClosed: ({ position }: SidebarCollapseButtonStylesProps) => ({
        transform:
            position === SIDEBAR_COLLAPSE_BUTTON_POSITION.RIGHT
                ? 'translate(1px, 1px)'
                : 'translate(-1px, 1px)',
    }),
    iconOpen: ({ position }: SidebarCollapseButtonStylesProps) => ({
        transform:
            position === SIDEBAR_COLLAPSE_BUTTON_POSITION.RIGHT
                ? 'translate(-1px, 1px)'
                : 'translate(1px, 1px)',
    }),
    solidRoot: {
        padding: theme.spacing(0.5),
        backgroundColor: theme.palette.common.white,
        color: '#CDD0DD',
        boxShadow: theme.shadows[1],
        '&:hover': {
            backgroundColor: theme.palette.common.gray5,
        },
    },
    solidIcon: {
        fontSize: '26px',
        color: '#828AA4',
    },
}));
