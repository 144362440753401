import { getFlatEntitiesFromPages } from '@infogrid/core-api';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

// When Typescripting this file, there's some types in ./types.ts that can be used

export const selectBuildingsHierarchy = (data) => {
    const floorMap = {};
    const roomMap = {};
    const buildingsData = [];

    (data ?? []).forEach((building) => {
        floorMap[building.id] = building.floors;
        (building.floors ?? []).forEach((floor) => {
            roomMap[floor.id] = floor.rooms;
        });
        buildingsData.push({ id: building.id, name: building.name });
    });

    return { ...data, floorMap, roomMap, buildingsData };
};

export const selectBuildingsHierarchyForUser = (data, translationFunc) => {
    if (!data) {
        return { buildings: [], totalBuildingsCount: 0, pages: [], pageParams: [] };
    }

    const buildingsFlat = getFlatEntitiesFromPages({
        pages: data?.pages,
        entitiesKey: 'results',
        uniqByKey: 'id',
    });

    const buildings = buildingsFlat.map((building) => {
        const floors = building.floors.map((floor) => ({
            id: floor.id,
            name: floor.name,
            notInSpaceCount: floor.notInSpaceCount,
            sensorCount: floor.sensor_count,
            spaces: floor.rooms,
        }));

        if (building.not_in_floor_count > 0) {
            floors.push({
                id: -building.id,
                name: translationFunc(registerTranslationKey('Not in floor')),
                sensorCount: building.not_in_floor_count,
            });
        }

        return {
            id: building.id,
            name: building.name,
            sensorCount: building.sensor_count,
            notInFloorCount: building.not_in_floor_count,
            floors,
        };
    });

    const buildingsDictionary = keyBy(buildings, 'id');

    const totalBuildingsCount = get(data, 'pages[0].count') ?? 0;

    return {
        ...data,
        buildings: buildingsDictionary,
        buildingsOrder: buildings,
        totalBuildingsCount,
    };
};
