import type { FeedbackPanelTemplateOption } from '@infogrid/sensors-constants';
import { memo, useCallback } from 'react';

import { useSensor } from 'apiHooks/sensors/hooks';
import type { IdentifySensorSuccessCallback } from 'components/sensors/IdentifySensor';

import type { IdentifySensorOptionPayload } from '../types';
import FeedbackPanelSensorOption from './FeedbackPanelSensorOption';

interface FeedbackPanelSensorOptionContainerProps {
    deviceName: string | null;
    templateOption: FeedbackPanelTemplateOption;
    sensorOptionIndex: number;
    canIdentify: boolean;
    isIdentifying: boolean;
    onIdentifyStartFor: (optionId: number) => void;
    onIdentifySensorOption: (payload: IdentifySensorOptionPayload) => void;
}

const FeedbackPanelSensorOptionContainer = ({
    deviceName,
    templateOption,
    sensorOptionIndex,
    canIdentify,
    isIdentifying,
    onIdentifyStartFor,
    onIdentifySensorOption,
}: FeedbackPanelSensorOptionContainerProps) => {
    // we assume deviceName has to will be always here
    // if its not - we set enabled: false anyway
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { data: sensor, isLoading } = useSensor(deviceName!, {
        enabled: deviceName !== null,
        initialData: undefined,
    });

    const onIdentifyStart = useCallback(
        () => onIdentifyStartFor(templateOption.id),
        [onIdentifyStartFor, templateOption.id],
    );
    const onIdentifySuccess: IdentifySensorSuccessCallback = useCallback(
        ({ deviceName: identifyDeviceName, sourceDeviceName }) => {
            if (identifyDeviceName === undefined || sourceDeviceName === undefined) {
                // IdentifySensor will show error toast
                return;
            }

            onIdentifySensorOption({
                deviceName: sourceDeviceName,
                sensorOptionIndex,
                // Touch sensors already in a feedback panel return the feedback panel deviceName
                // sourceDeviceName is always the underlying sensor identified (the touch sensor)
                // See WEB-3440.
                alreadyInFeedbackPanel: identifyDeviceName !== sourceDeviceName,
            });
        },
        [onIdentifySensorOption, sensorOptionIndex],
    );

    return (
        <FeedbackPanelSensorOption
            // @ts-expect-error: todo
            sensor={sensor}
            isLoading={isLoading}
            templateOption={templateOption}
            canIdentify={canIdentify}
            isIdentifying={isIdentifying}
            onIdentifyStart={onIdentifyStart}
            onIdentifySuccess={onIdentifySuccess}
        />
    );
};

export default memo(FeedbackPanelSensorOptionContainer);
