import { createRequest } from '@infogrid/core-api';

import { registerSensor, installSensor, calibratePipeOffset } from './ts/controllers';

const configureSensor = ({ data, id }) =>
    createRequest({
        method: 'put',
        url: `sensors/${id}/configure-sensor`,
        options: {
            data,
        },
    });

export const controllers = {
    registerSensor,
    installSensor,
    calibratePipeOffset,
    configureSensor,
};
