import { useFloorSensors } from './useFloorSensors';

interface UseSensorsLiveUpdateProps {
    floorId?: number;
}

export const useSensorsLiveUpdate = (
    { floorId }: UseSensorsLiveUpdateProps,
    { delay = 10 * 1000 } = { delay: 10 * 1000 },
) => {
    const enabled = !!floorId && !!delay;

    useFloorSensors({ floorId }, { refetchInterval: delay, enabled });
};
