import { Modal } from '@infogrid/components-material-ui';
import { useAppDispatch } from '@infogrid/core-ducks';
import { Button, Typography } from '@material-ui/core';
import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { INSTALL_STEPS, handleOfflineConfirmed } from 'ducks/installFlow';

import { useGoToStep } from '../utils/hooks/useGoToStep';

const OfflineConfirmation: FC = () => {
    const { t } = useTranslation('sensors');
    const dispatch = useAppDispatch();

    const onClickBack = useGoToStep(INSTALL_STEPS.BARCODE_SCAN);
    const onClickContinue = useCallback(() => {
        dispatch(handleOfflineConfirmed());
    }, [dispatch]);

    return (
        <>
            <Modal.Title>{t('Continue offline')}</Modal.Title>
            <Modal.Content>
                <Typography variant="body1" paragraph>
                    {t(
                        'No data will be sent from this sensor until it is successfully connected and comes online.',
                    )}
                </Typography>
                <Typography variant="body1" paragraph>
                    {t(
                        'Are you sure you want to continue installing this offline sensor?',
                    )}
                </Typography>
            </Modal.Content>

            <Modal.Actions justify="space-between">
                <Button
                    data-cypress="install-offline-confirm-back"
                    onClick={onClickBack}
                    color="primary"
                >
                    {t('Back', { ns: 'common' })}
                </Button>
                <Button
                    data-cypress="install-offline-confirm-continue"
                    onClick={onClickContinue}
                    variant="outlined"
                    color="primary"
                >
                    {t('Continue', { ns: 'common' })}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(OfflineConfirmation);
