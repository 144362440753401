import { memo } from 'react';
import { useMeasure } from 'react-use';

import type { IconeIndex } from '../../types';
import { useCircleStyles } from './styles';

interface Props {
    iconeIndex: IconeIndex;
    color: string;
}

const Circle = ({ iconeIndex, color }: Props) => {
    const styles = useCircleStyles();

    const [ref, { width, height }] = useMeasure();

    const size = width > height ? height : width;

    return (
        //@ts-expect-error invalid useMeasure type
        <div ref={ref} className={styles.container}>
            <div
                className={styles.circle}
                style={{ background: color, width: size, height: size }}
                data-testid="icone-index-circle"
                data-cypress="icone-index-circle"
            >
                {iconeIndex}
            </div>
        </div>
    );
};

export default memo(Circle);
