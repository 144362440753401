import { useMutationWithToast } from '@infogrid/core-api';
import {
    useAppSelector,
    useAppDispatch,
    toastError,
    toastSuccess,
} from '@infogrid/core-ducks';
import { selectOrganizationId } from '@infogrid/user-ducks';
import type { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import { updateThresholdsAfterUpdate } from 'apiHooks/settings/solutions/accessors';
import { controllers } from 'apiHooks/settings/solutions/controllers';
import type { Thresholds } from 'utils/types/ts/threshold';

export const useUpdateThresholds = (): UseMutationResult<
    AxiosResponse<Thresholds>,
    Thresholds,
    Thresholds,
    unknown
> => {
    const dispatch = useAppDispatch();
    const organizationId = useAppSelector(selectOrganizationId);
    const { t } = useTranslation('solutions');

    return useMutationWithToast(
        (data: Thresholds) => controllers.updateThresholds(organizationId, data),
        {
            onSuccess: async (data: AxiosResponse<Thresholds>) => {
                updateThresholdsAfterUpdate(data);
                dispatch(toastSuccess({ message: t('Thresholds updated') }));
            },
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error updating thresholds'),
                    }),
                );
            },
        },
        {
            toastMessage: t('Updating of thresholds is in progress'),
        },
    );
};
