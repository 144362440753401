import {
    SET_IS_CREATING_BUILDING,
    SET_IS_CREATING_FLOOR,
    SET_IS_DELETING_BUILDING,
    SET_IS_EDITING_BUILDING,
    SET_IS_DELETING_FLOOR,
    SET_IS_EDITING_FLOOR,
    SET_EDIT_FLOOR_ID,
    SET_IS_CREATING_FOLDER,
    SET_IS_EDITING_FLOOR_IMAGE,
    SET_IS_REPLACING_FLOOR_IMAGE,
    SET_SHOULD_KEEP_SENSORS_MAPPED,
} from './floorplan.actions';

export const toggleCreatingBuilding = (isCreatingBuilding) => ({
    type: SET_IS_CREATING_BUILDING,
    isCreatingBuilding,
});

export const toggleDeletingBuilding = (isDeletingBuilding) => ({
    type: SET_IS_DELETING_BUILDING,
    isDeletingBuilding,
});

export const toggleEditingBuilding = (isEditingBuilding) => ({
    type: SET_IS_EDITING_BUILDING,
    isEditingBuilding,
});

export const toggleCreatingFloor = (isCreatingFloor) => ({
    type: SET_IS_CREATING_FLOOR,
    isCreatingFloor,
});

export const toggleCreatingFolder = (isCreatingFolder) => ({
    type: SET_IS_CREATING_FOLDER,
    isCreatingFolder,
});

export const toggleEditingFloor = (isEditingFloor) => ({
    type: SET_IS_EDITING_FLOOR,
    isEditingFloor,
});

export const toggleDeletingFloor = (isDeletingFloor) => ({
    type: SET_IS_DELETING_FLOOR,
    isDeletingFloor,
});

export const toggleReplaceFloorImage = (isReplacingFloorImage) => ({
    type: SET_IS_REPLACING_FLOOR_IMAGE,
    isReplacingFloorImage,
});

export const toggleEditFloorImage = (isEditingFloorImage, isToggleFromReplaceModal) => ({
    type: SET_IS_EDITING_FLOOR_IMAGE,
    isEditingFloorImage,
    isToggleFromReplaceModal,
});

export const putEditFloorId = (editFloorId) => ({
    type: SET_EDIT_FLOOR_ID,
    editFloorId,
});

export const toggleShouldKeepSensorsMapped = (shouldKeepSensorsMapped) => ({
    type: SET_SHOULD_KEEP_SENSORS_MAPPED,
    shouldKeepSensorsMapped,
});
