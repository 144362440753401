// REPORTS
export const REPORTS_QUERY_KEY = 'reports';

// SMART CLEANING
export const SMART_CLEANING_REPORTS_QUERY_KEY = 'smart-cleaning-reports';
export const SMART_CLEANING_RESET_LOG_KEY = 'smart-cleaning-reset-log';
export const SMART_CLEANING_REPORT_HISTORY_KEY = 'smart-cleaning-report-history';

// SENSORS
export const SENSORS_QUERY_KEY = 'sensors';
export const SENSOR_COUNTS_BY_TYPES_KEY = 'sensor-counts-by-types';
export const SENSORS_SEEN_BY_QUERY_KEY = 'sensors-seen-by';
export const LATEST_SENSORS_EVENTS_QUERY_KEY = 'polling-sensors';
export const SENSORS_EVENTS_QUERY_KEY = 'sensors-events';
export const SENSORS_LIST_QUERY_KEY = 'sensors-list';
export const FOLDER_SENSORS_LIST_QUERY_KEY = 'folder-sensors-list';
export const SEEN_SENSORS_QUERY_KEY = 'seen-sensors-list';
export const SOURCE_FOR_SENSORS_LIST_KEY = 'source-for-sensors-list';
export const ORG_SENSORS_QUERY_KEY = 'org-sensors';
export const SENSORS_TYPES_AND_HUBS_KEY = 'sensors-types-and-hubs';

// SENSOR HEALTH
export const SENSOR_HEALTH_OVERVIEW_QUERY_KEY = 'sensor-health-overview';
export const SENSOR_HEALTH_LOCATIONS_QUERY_KEY = 'sensor-health-locations';
export const SENSOR_HEALTH_OFFLINE_SENSORS_KEY = 'sensor-health-offline-sensors';
export const SENSOR_HEALTH_OFFLINE_HUBS_KEY = 'sensor-health-offline-hubs';
export const SENSOR_HEALTH_AVAILABLE_FILTERS_KEY = 'sensor-health-available-filters-key';
export const SENSOR_HEALTH_NOT_CONFIGURED_KEY = 'sensor-health-not-configured-key';
export const SENSOR_HEALTH_CONFIGURED_KEY = 'sensor-health-configured-key';

// FEEDBACK PANELS
export const FEEDBACK_PANEL_TEMPLATES_QUERY_KEY = 'feedback-panel-templates';

// CHARTS
export const SENSOR_CHART = 'sensor-chart';
export const REMOTE_MONITORING_ERROR_EQUATION = 'remote-monitoring-error-equation';

// EVENT_LOG
export const EVENT_LOG_QUERY_KEY = 'event-log';

// FOLDERS
export const FOLDER_QUERY_KEY = 'folder';
export const FOLDER_SUBFOLDERS_QUERY_KEY = 'folder-subfolders';

// LABELS
export const LABELS_QUERY_KEY = 'labels';
export const UNATTACHED_LABELS_QUERY_KEY = 'unattached_labels';
export const SENSORS_LABELS_KEY = 'sensors_labels';

// USERS
export const USER_LIST_KEY = 'user-list-token';
export const USER_SIGNED_TOKEN = 'user-signed-token';
export const USER_FROM_OTHER_ORGS_QUERY_KEY = 'user-from-other-orgs';

// ORGS
export const ORG_QUERY_KEY = 'org';

// PERMISSIONS
export const PERMISSIONS_QUERY_KEY = 'permissions';
export const SENSOR_TEAMS_PERMISSIONS_QUERY_KEY = 'sensor-teams-permissions';
export const SENSOR_USERS_PERMISSIONS_QUERY_KEY = 'sensor-users-permissions';
export const FOLDER_TEAMS_PERMISSIONS_QUERY_KEY = 'folder-teams-permissions';
export const FOLDER_USERS_PERMISSIONS_QUERY_KEY = 'folder-users-permissions';
export const DASHBOARD_TEAMS_PERMISSIONS_QUERY_KEY = 'dashboard-teams-permissions';
export const DASHBOARD_PERMISSIONS_QUERY_KEY = 'dashboard-users-permissions';

// TIMEZONES
export const TIMEZONES_QUERY_KEY = 'timezones';

// ALERT LOG
export const ALERT_LOG_QUERY_KEY = 'alert-log';

// ALERT_TEMPLATE
export const ALERT_TEMPLATES_QUERY_KEY = 'alert-template';

// ALERT CONFIGURATIONS
export const ALERT_CONFIGURATION_DETAIL_QUERY_KEY = 'alert-configuration';
export const ALERT_CONFIGURATIONS_QUERY_KEY = 'alert-configurations';

// ALERT FEED
export const ALERT_FEED_QUERY_KEY = 'alert-feed';

// ALERT UNAUTH ACKNOWLEDGE
export const ALERT_UNAUTH_ACKNOWLEDGE_QUERY_KEY = 'alert-unauth-acknowledge';

// ALERT WEBHOOKS
export const ALERT_WEBHOOKS_QUERY_KEY = 'alert-webhooks';

// FLOORPLAN
export const BUILDINGS_HIERARCHY_QUERY_KEY = 'buildings-hierarchy';
export const BUILDINGS_HIERARCHY_FOR_USER_QUERY_KEY = 'buildings-hierarchy-for-user';

export const BUILDINGS_COUNT_QUERY_KEY = 'buildings-count';
export const BUILDINGS_ALERT_COUNTS_QUERY_KEY = 'building-alert-counts';
export const BUILDINGS_FILTERS_QUERY_KEY = 'buildings-filters';
export const BUILDING_TYPES_QUERY_KEY = 'building-types';

export const BUILDINGS_KEY = 'buildings';
export const DEFAULT_BUILDING = 'default-building';
export const PAGINATED_BUILDINGS_KEY = 'paginated-buildings';
export const BUILDING_SCORES_KEY = 'building-scores';

export const FLOORS_KEY = 'floors';

export const FLOOR_PLAN_FOLDERS = 'floorplan-folders';
export const FLOOR_PLAN_SUBFOLDERS = 'floorplan-subfolders';
export const FLOORS_LIST_KEY = 'floors_list';
export const FLOOR_SENSORS_KEY = 'floor-sensors';
export const FLOORPLAN_MAPPED_SENSORS_KEY = 'floorplan-mapped-sensors';
export const FLOORPLAN_SENSOR_ALERTS = 'floorplan-sensor-alerts';
export const PLANNED_SENSORS_QUERY_KEY = 'planned-sensors';
export const ACQUIRE_SENSOR_PLANNING_LOCK_KEY = 'acquire-sensor-planning-lock';
export const SENSOR_PLANNING_LOCK_KEY = 'sensor-planning-lock';

export const LENS_SENSORS_KEY = 'lens-sensors';
export const LENS_FLOORS_KEY = 'lens-floors';

export const REPORTS_KEY = 'reports-reports';
export const REPORT_CONFIGURATIONS_KEY = 'report-configurations';

export const SMART_CLEANING_HISTORY_KEY = 'smart-cleaning-history';

export const BATCH_CREATE_PLANNED_SENSORS_KEY = 'batch-create-planned-sensors';

// SOLUTIONS
export const SOLUTIONS_KEY = 'solutions';

// THRESHOLDS
export const THRESHOLDS_KEY = 'thresholds';

//AT_RISK_BUILDING
export const AT_RISK_BUILDING = 'at-risk-building';

// TASKS
export const TASKS_FEED_QUERY_KEY = 'tasks-feed';
export const TASK_COMMENTS_QUERY_KEY = 'task-comments';

// EXTERNAL INTEGRATIONS
export const CORRIGO_CREDENTIALS_RETRIEVE_QUERY_KEY = 'corrigo-credentials-retrieve';

// USER ACTIONS
export const BUILDINGS_USER_ACTIONS = 'buildings-user-actions';

// EVENTS WEBHOOK
export const EVENTS_WEBHOOKS_KEY = 'events-webhooks';
export const EVENTS_WEBHOOK_CONFIG_KEY = 'events-webhook-config';
