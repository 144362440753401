import type { BuildingDetailsWorkingDay } from '@infogrid/locations-types';
import type { CheckboxProps, TextFieldProps } from '@material-ui/core';
import { Checkbox, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import type { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';

import { useWorkingHoursInputStyles } from './styles';

export interface WorkingHoursInputProps {
    className?: string;
    ['data-cypress']?: string;
    enabled: boolean;
    error?: FormikErrors<BuildingDetailsWorkingDay>;
    label: string;
    onChangeChecked: CheckboxProps['onChange'];
    onChangeTimeFrom: TextFieldProps['onChange'];
    onChangeTimeTo: TextFieldProps['onChange'];
    timeFrom: string;
    timeTo: string;
}

export const WorkingHoursInput = ({
    className,
    'data-cypress': dataCypress,
    enabled,
    error,
    label,
    onChangeChecked,
    onChangeTimeFrom,
    onChangeTimeTo,
    timeFrom,
    timeTo,
}: WorkingHoursInputProps) => {
    const styles = useWorkingHoursInputStyles();
    const { t } = useTranslation();

    const { from_hour: fromHourError, to_hour: toHourError } = error ?? {};
    const hasError = fromHourError || toHourError || error;

    return (
        <div data-cypress={dataCypress}>
            <div className={classNames(styles.container, className)}>
                <div className={styles.dayBlock}>
                    <Checkbox
                        checked={enabled}
                        data-cypress="working-hours-enabled"
                        color="primary"
                        onChange={onChangeChecked}
                    />
                    <Typography variant="body1">{label}</Typography>
                </div>
                <div className={styles.dayBlock}>
                    <TextField
                        className={styles.input}
                        data-cypress="working-hours-from"
                        disabled={!enabled}
                        error={!!fromHourError}
                        onChange={onChangeTimeFrom}
                        type="time"
                        variant="outlined"
                        value={timeFrom}
                    />
                    <Typography className={styles.divider}>-</Typography>
                    <TextField
                        className={styles.input}
                        data-cypress="working-hours-to"
                        disabled={!enabled}
                        error={!!toHourError ?? !!hasError}
                        onChange={onChangeTimeTo}
                        type="time"
                        variant="outlined"
                        value={timeTo}
                    />
                </div>
            </div>

            {hasError && (
                <div className={styles.error}>
                    <Typography
                        className={styles.errorText}
                        color="error"
                        variant="caption"
                    >
                        {fromHourError
                            ? t(fromHourError)
                            : toHourError
                            ? t(toHourError)
                            : t(error as string)}
                    </Typography>
                </div>
            )}
        </div>
    );
};
