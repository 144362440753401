import { useDashboard } from '@infogrid/dashboards-hooks';
import { useIsMobile } from '@infogrid/utils-hooks';
import { CircularProgress } from '@material-ui/core';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { memo, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useFullscreen } from 'react-use';

import { Widget } from 'views/dashboards/components/Widget';
import DeleteWidgetModal from 'views/dashboards/components/modals/DeleteWidgetModal';
import { canChangeDashboard, hasUserPermissions } from 'views/dashboards/utils/helpers';

import GenericActions from './GenericActions';
import GenericDetails from './GenericDetails';
import GenericFooter from './GenericFooter';
import GenericTitle from './GenericTitle';
import {
    configurationDefaults,
    genericWidgetProps,
    genericWidgetDefaultProps,
} from './constants';
import { useGenericWidgetStyles } from './styles';

const GenericWidget = ({
    actionsProps,
    canDragAndResize,
    configuration,
    configureWidgetModal,
    containerClassName,
    loaderComponent,
    contentClassName,
    contentComponent,
    contentProps,
    details,
    footerComponent,
    footerProps,
    inView,
    isFetchingData,
    isLoadingData,
    layout,
    refetchData,
    setWidgetDataQueryParams,
    titleProps,
    widget,
    widgetConfigurationModalProps,
    widgetData,
    widgetDeletionModalProps,
}) => {
    const { dashboardId } = useParams();

    const isMobile = useIsMobile();

    const { data: dashboard } = useDashboard(+dashboardId);

    const styles = useGenericWidgetStyles();

    const {
        actionsEnabled,
        isActionsEnabled,
        footerEnabled,
        isEnabled: isConfigureWidgetModalEnabled,
    } = {
        ...configurationDefaults,
        ...configuration,
    };

    const widgetDetails = useMemo(
        () =>
            details.map((detail) => ({
                label: detail.labelExtractor({ widget, widgetData }),
                value: detail.valueExtractor({ widget, widgetData }),
            })),
        [details, widget, widgetData],
    );

    const ConfigureWidgetModal = configureWidgetModal;
    const ContentComponent = contentComponent;
    const FooterComponent = footerComponent;
    const LoaderComponent = loaderComponent;

    const { configurableProps, fullscreenProps } = actionsProps;

    const widgetCardRef = useRef(null);

    const shouldEnterFullscreen =
        fullscreenProps.fullscreen && fullscreenProps.fullscreen === widget.id;
    const isDashboardFullscreen = !!fullscreenProps.fullscreen;

    const shouldRenderContent = useMemo(() => {
        if (isLoadingData) {
            return false;
        }

        if (isDashboardFullscreen && !shouldEnterFullscreen) {
            return false;
        }

        return true;
    }, [isLoadingData, isDashboardFullscreen, shouldEnterFullscreen]);

    // Request fullscreen from Fullscreen API
    useFullscreen(widgetCardRef, fullscreenProps.fullscreen, {
        onClose: fullscreenProps.onFullscreenExit,
    });

    const canUserEdit = useMemo(() => {
        if (!isNil(configurableProps.canUserEdit)) {
            return configurableProps.canUserEdit;
        }

        return hasUserPermissions(dashboard) && canChangeDashboard(dashboard);
    }, [configurableProps.canUserEdit, dashboard]);

    return (
        <Widget className={containerClassName}>
            <Widget.Bar canDragAndResize={canDragAndResize}>
                <GenericTitle
                    titleEditable={canUserEdit}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...titleProps}
                />
                {((actionsEnabled && !isMobile) || isActionsEnabled) && (
                    <GenericActions
                        canUserEdit={canUserEdit}
                        widget={widget}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...actionsProps}
                    />
                )}
            </Widget.Bar>

            {isLoadingData && LoaderComponent && <LoaderComponent />}
            {isLoadingData && !LoaderComponent && (
                <div className={styles.progress}>
                    <CircularProgress
                        color="primary"
                        data-testid="widget-circular-loader"
                        size={40}
                    />
                </div>
            )}

            {inView && shouldRenderContent && (
                <>
                    {details?.length > 0 && <GenericDetails details={widgetDetails} />}
                    <Widget.Content
                        ref={widgetCardRef}
                        className={contentClassName}
                        layout={layout}
                    >
                        <ContentComponent
                            canUserEdit={canUserEdit}
                            data={widgetData}
                            refetchData={refetchData}
                            isFetchingData={isFetchingData}
                            layout={layout}
                            setWidgetDataQueryParams={setWidgetDataQueryParams}
                            widget={widget}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...contentProps}
                        />
                    </Widget.Content>
                </>
            )}
            {footerEnabled && footerComponent && (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <FooterComponent canUserEdit={canUserEdit} {...footerProps} />
            )}
            {footerEnabled && !footerComponent && (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <GenericFooter {...footerProps} />
            )}
            {isConfigureWidgetModalEnabled && widgetConfigurationModalProps.open && (
                <ConfigureWidgetModal
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...widgetConfigurationModalProps}
                />
            )}
            <DeleteWidgetModal
                dashboardId={widgetDeletionModalProps.dashboardId}
                open={widgetDeletionModalProps.open}
                onClose={widgetDeletionModalProps.onClose}
                widgetId={widgetDeletionModalProps.widgetId}
                widgetName={widgetDeletionModalProps.widgetName}
                widgetType={widgetDeletionModalProps.widgetType}
            />
        </Widget>
    );
};

GenericWidget.propTypes = {
    ...genericWidgetProps,
    canDragAndResize: PropTypes.bool,
    isFetchingData: PropTypes.bool,
    isLoadingData: PropTypes.bool,
    setWidgetDataQueryParams: PropTypes.func,
    widgetData: PropTypes.shape({}),
    inView: PropTypes.bool.isRequired,
};

GenericWidget.defaultProps = {
    ...genericWidgetDefaultProps,
    canDragAndResize: true,
    isFetchingData: false,
    isLoadingData: false,
    setWidgetDataQueryParams() {},
    widgetData: {},
};

export default memo(GenericWidget);
