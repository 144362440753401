import { Modal } from '@infogrid/components-material-ui';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import type { SensorType } from '@infogrid/sensors-constants';
import { TEMP_OFFSET_USE_CASES, SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Button, RadioGroup } from '@material-ui/core';
import { memo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { invalidateAllSensorLists } from 'apiHooks/sensors/accessors';
import type { InstallationFlowComponentProps } from 'components/InstallationFlow/types';

import InstallationFlowOverrideWarning from '../InstallationFlowOverrideWarning';
import { useInstallationFlowContext } from '../context';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../reducer';
import { getSensorLastSignalEvent } from '../utils';
import TemperatureUseCaseOption from './TemperatureUseCaseOption';
import { useTempUseCaseSubmitHandlers } from './hooks';
import { useTempUseCaseSelectorStyles } from './styles';
import type { TemperatureUseCaseOption as TemperatureUseCaseOptionInterface } from './types';

const getUseCaseOption = (sensorType: SensorType): TemperatureUseCaseOptionInterface => {
    const config = getSensorConfig(sensorType);

    return {
        icon: config.icon,
        label:
            sensorType === SENSOR_TYPE.TYPE_TEMPERATURE
                ? registerTranslationKey('Ambient temperature')
                : config.label,
        value: sensorType,
    };
};

const TemperatureUseCaseSelector = ({
    resetProgress,
}: Pick<InstallationFlowComponentProps, 'resetProgress'>) => {
    const { t } = useTranslation('sensors');
    const { isOverwriteConfirmed, sensor, isLoading } = useInstallationFlowContext();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();
    const tempUseCaseSelectorStyles = useTempUseCaseSelectorStyles();
    const submitHandler = useTempUseCaseSubmitHandlers({
        onSuccess: (fetchedSensor) => {
            const lastSignalEvent = getSensorLastSignalEvent(fetchedSensor);

            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.SELECT_TEMPERATURE_USE_CASE,
                sensor: fetchedSensor,
                lastSignalEvent,
            });
            //remove converted virtual sensor and add new virtual one
            invalidateAllSensorLists();
        },
        onSettled: () => {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
            });
        },
    });

    const [useCase, setUseCase] = useState('');

    const [isPreviouslyInstalledWarningOpen, setPreviouslyInstalledWarningOpen] =
        useState(!isOverwriteConfirmed && !!sensor?.installation_time);

    const onResume = useCallback(() => {
        setPreviouslyInstalledWarningOpen(false);
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.TOGGLE_OVERWRITE,
        });
    }, [dispatchInstallationFlowContext]);

    const onDiscardPreviouslyInstalledWarning = useCallback(() => {
        setPreviouslyInstalledWarningOpen(false);
        resetProgress();
    }, [resetProgress]);

    const onSubmit = useCallback(() => {
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
        });

        if (sensor?.device_name) {
            submitHandler[useCase]?.({ deviceName: sensor.device_name });

            return;
        }

        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
        });
    }, [useCase, submitHandler, sensor?.device_name, dispatchInstallationFlowContext]);

    const renderTempUseCaseOption = (sensorType: SensorType) => {
        const option = getUseCaseOption(sensorType);

        return (
            <TemperatureUseCaseOption
                key={sensorType}
                onChange={setUseCase}
                option={option}
                selected={option.value === useCase}
            />
        );
    };

    return (
        <>
            <Modal.Title>{t('Choose sensor use case')}</Modal.Title>
            <Modal.Content className={tempUseCaseSelectorStyles.root}>
                <RadioGroup value={useCase}>
                    {TEMP_OFFSET_USE_CASES.map((sensorType) =>
                        renderTempUseCaseOption(sensorType),
                    )}
                </RadioGroup>
            </Modal.Content>
            <Modal.Actions justify="right">
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!useCase || isLoading}
                    onClick={onSubmit}
                >
                    {t('Next', { ns: 'common' })}
                </Button>
            </Modal.Actions>
            <InstallationFlowOverrideWarning
                isOpen={isPreviouslyInstalledWarningOpen}
                onResume={onResume}
                onDiscard={onDiscardPreviouslyInstalledWarning}
                sensor={sensor}
            />
        </>
    );
};

export default memo(TemperatureUseCaseSelector);
