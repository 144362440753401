import { selectOrganizationId } from '@infogrid/user-ducks';
import { call, select } from 'redux-saga/effects';

import { invalidateCache } from 'sagas/helpers/fetchingGuard';

export function* organizationControlWorker({ organization_id: organizationId }) {
    const userOrgId = yield select(selectOrganizationId);

    if (userOrgId === organizationId) {
        yield call(invalidateCache);
    }
}
