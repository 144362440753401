import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme, { hideStage?: boolean }>((theme) => ({
    container: {
        display: ({ hideStage }) => (hideStage ? 'none' : 'flex'),
        flexDirection: 'column',
        flexFlow: 'column',
        height: '100%',
    },
    locationPicker: {
        overflowY: 'auto',
        flex: 1,
        marginBottom: theme.spacing(2),
    },
}));
