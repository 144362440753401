import { makeStyles } from '@material-ui/core';

export const useStylesHeaderContent = makeStyles((theme) => ({
    actionMenusContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing(0),
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            marginBottom: theme.spacing(1),
        },
    },
    editBuildingMenu: {
        order: 3,
        [theme.breakpoints.down('xs')]: {
            order: 1,
        },
    },
    floorSwitcher: {
        marginTop: theme.spacing(1),
    },
}));
