import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import type { SpaceType } from '@infogrid/locations-types';
import { useMemo, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { useFloorPlanStyles } from './styles';

const LegacyFloorMap = lazy(
    () =>
        import(
            /* webpackChunkName: "LegacyFloorMap", webpackPreload: true */ 'components/floorPlans/LegacyFloorMap'
        ),
);

interface PublicDisplayFloorMapProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    activeSensorTypes: any[];
    imageHeight: number;
    imageUrl: string;
    imageWidth: number;
    orientation: number;
    sensorIconScale: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sensors: any[];
    spaceTypes: SpaceType[];
    zoom: number;
}

export const PublicDisplayFloorMap = ({
    activeSensorTypes: activeSensorTypesOnServer,
    imageHeight,
    imageUrl,
    imageWidth,
    orientation,
    sensorIconScale,
    sensors,
    spaceTypes,
    zoom,
}: PublicDisplayFloorMapProps): JSX.Element => {
    const styles = useFloorPlanStyles();

    const { t } = useTranslation('floor-map');

    const sensorsTypesSet = useMemo(() => {
        return new Set(sensors.map(({ type_code }) => type_code));
    }, [sensors]);
    const activeSensorTypes = activeSensorTypesOnServer.filter((x) =>
        sensorsTypesSet.has(x),
    );

    const activeSensors = useMemo(() => {
        return (activeSensorTypes ?? []).length
            ? sensors.filter((x) => activeSensorTypes.includes(x.type_code))
            : sensors;
    }, [activeSensorTypes, sensors]);

    return (
        <Suspense
            fallback={
                <LoadingPlaceholder
                    titleVariant="body1"
                    progressSize={56}
                    text={t('Loading Map...')}
                />
            }
        >
            <LegacyFloorMap
                mapClassName={styles.mapContainer}
                imageUrl={imageUrl}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                zoom={zoom}
                rotation={orientation}
                sensors={activeSensors}
                isDragSensorAvailable={false}
                isAddSensorAvailable={false}
                isSelectSensorAvailable={false}
                featureScale={sensorIconScale}
                isDisableZoomByScroll
                spaceTypes={spaceTypes}
            />
        </Suspense>
    );
};
