import { useIsMobile } from '@infogrid/utils-hooks';
import { Button } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useConnectedIsOpenState } from 'utils/hooks';

import { useInstallationFlowButtonStyles } from './styles';

const InstallationFlowButton = (): JSX.Element => {
    const { t } = useTranslation('sensors');

    const [_0, _1, handleOpen] = useConnectedIsOpenState('installationFlow');
    const isMobile = useIsMobile();
    const classes = useInstallationFlowButtonStyles();

    const buttonText = useMemo(
        () => (isMobile ? t('Install') : t('Start installation')),
        [isMobile, t],
    );

    return (
        <Button
            data-cypress="installation-flow-button"
            onClick={handleOpen}
            variant={isMobile ? 'outlined' : 'contained'}
            color={isMobile ? 'default' : 'primary'}
            classes={classes}
        >
            {buttonText}
        </Button>
    );
};

export default InstallationFlowButton;
