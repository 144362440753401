import { makeStyles } from '@material-ui/core';

import { PRIMARY_GREEN, PRIMARY_YELLOW, PRIMARY_RED } from '../constants';

export const useLeftCard = makeStyles((theme) => ({
    leftCard: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: theme.spacing(1),
    },
    largeIconContainer: ({ layout }) => ({
        fontSize: layout?.h === 1 ? '52px' : '89px',
        width: '1.2em',
        height: '1.2em',
        lineHeight: '1.2em',
        marginBottom: layout?.h === 1 ? theme.spacing(0) : theme.spacing(2.5),
        color: theme.palette.common.white,
        [theme.breakpoints.down('xs')]: {
            fontSize: '36px',
        },
    }),
    titleText: ({ layout }) => ({
        fontSize:
            layout?.h === 1 ? theme.typography.h5.fontSize : theme.typography.h4.fontSize,
        color: theme.palette.common.white,
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.typography.h6.fontSize,
        },
    }),
    occupancyText: ({ layout }) => ({
        fontSize:
            layout?.w === 1
                ? theme.typography.subtitle1.fontSize
                : theme.typography.h5.fontSize,
        color: theme.palette.common.white,
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
        },
    }),
    recommendedActionsText: ({ layout }) => {
        const regularFontSize =
            layout?.w === 1
                ? theme.typography.subtitle1.fontSize
                : theme.typography.h6.fontSize;

        return {
            color: theme.palette.common.white,
            fontSize: regularFontSize,
            lineHeight: theme.typography.h6.lineHeight,
            [theme.breakpoints.down('xs')]: {
                fontSize: '16px',
            },
        };
    },
    // Functions lead to higher specificity compared to objects, this is fixed in MUI v5
    primaryGoodAvailabilityBackground: () => ({
        background: PRIMARY_GREEN,
    }),
    primaryNearCapacityBackground: () => ({
        background: PRIMARY_YELLOW,
    }),
    primaryOutOfCapacityBackground: () => ({
        background: PRIMARY_RED,
    }),
}));
