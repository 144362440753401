import { calendarPeriods, dataFrequencies } from '@infogrid/dashboards-constants';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import moment from 'moment';

import {
    calculateTotalEventsCount,
    getSensorsByType,
    periodDurationInDays,
    prepareBaseConfigurationPayload,
    prepareBaseInitialValues,
} from 'views/dashboards/widgets/utils';

const defaultValues = {
    dateRange: {
        period: calendarPeriods.week,
        startDate: moment().subtract(7, 'days'),
        endDate: moment().subtract(1, 'day'),
        workHoursFrom: '09:00',
        workHoursTo: '17:00',
    },
};

export const prepareInitialValues = (widget) => {
    const { data_frequency: dataFrequency = dataFrequencies.daily.value } =
        widget?.configuration;

    const {
        count_if_present: countEventsWhenSensor = false,
        folders = [],
        sensors: proximityCountSensors = [],
    } = widget?.configuration?.calculators?.proximity_count || {};

    const { sensors: proximityCounterSensors = [] } =
        widget?.configuration?.calculators?.proximity_counter || {};

    const sensors = [...proximityCountSensors, ...proximityCounterSensors];

    return {
        ...prepareBaseInitialValues(widget, defaultValues),
        folders,
        sensors,
        displayOptions: {
            dataFrequency,
            countEventsWhenSensor,
        },
    };
};

export const prepareConfigurationPayload = (values, sensorsData) => {
    const payload = prepareBaseConfigurationPayload(values);

    const { dataFrequency, countEventsWhenSensor } = values?.displayOptions;

    const proximityCountSensors = getSensorsByType(
        values?.sensors,
        sensorsData,
        SENSOR_TYPE.TYPE_PROXIMITY,
    );
    const proximityCounterSensors = getSensorsByType(
        values?.sensors,
        sensorsData,
        SENSOR_TYPE.TYPE_PROXIMITY_COUNTER,
    );

    return {
        ...payload,
        data_frequency: dataFrequency,
        calculators: {
            proximity_count: {
                count_if_present: countEventsWhenSensor,
                folders: values?.folders,
                sensors: proximityCountSensors,
            },
            proximity_counter: {
                count_if_present: countEventsWhenSensor,
                folders: values?.folders,
                sensors: proximityCounterSensors,
            },
        },
    };
};

export const generateProximityCountData = (rows = [], sensorsNames = {}) =>
    rows.reduce((data, row) => {
        const sum = Object.keys(sensorsNames).reduce(
            (total, sensorName) => total + row[sensorName],
            0,
        );

        return [...data, [row.timestamp, sum]];
    }, []);

export const generateChartData = (data) => {
    const {
        proximity_count_graph_rows,
        proximity_count_sensor_names,
        proximity_counter_graph_rows,
        proximity_counter_sensor_names,
    } = data;

    const proximityCountData = generateProximityCountData(
        proximity_count_graph_rows,
        proximity_count_sensor_names,
    );

    const proximityCounterData = generateProximityCountData(
        proximity_counter_graph_rows,
        proximity_counter_sensor_names,
    );

    const rowsCount =
        Math.max(proximityCountData?.length, proximityCounterData?.length) || 0;

    return Array.from(Array(rowsCount)).reduce((entries, _, index) => {
        const proximityCountRow = proximityCountData[index] || [];
        const proximityCounterRow = proximityCounterData[index] || [];

        const proximityCountTotal = proximityCountRow[1] || 0;
        const proximityCounterTotal = proximityCounterRow[1] || 0;

        return [
            ...entries,
            [
                +new Date(proximityCountRow[0] || proximityCounterRow[0]),
                proximityCountTotal + proximityCounterTotal,
            ],
        ];
    }, []);
};

export const extractTotalOpenEventsCount = ({ widgetData = {} }) => {
    const {
        proximity_count_graph_rows = [],
        proximity_count_sensor_names = {},
        proximity_counter_graph_rows = [],
        proximity_counter_sensor_names = {},
    } = widgetData;

    const proximityCountTotal = calculateTotalEventsCount(
        proximity_count_graph_rows,
        proximity_count_sensor_names,
    );

    const proximityCounterTotal = calculateTotalEventsCount(
        proximity_counter_graph_rows,
        proximity_counter_sensor_names,
    );

    return proximityCountTotal + proximityCounterTotal || 0;
};

export const extractTotalEventsCountLabelType = ({ widget }) => {
    const { proximity_count, proximity_counter } =
        widget?.configuration?.calculators || {};

    const countEventsWhenPresent =
        proximity_count?.count_if_present && proximity_counter?.count_if_present;

    return countEventsWhenPresent === true
        ? registerTranslationKey('closed')
        : registerTranslationKey('open');
};

export const calculateDisabledFrequencies = ({ startDate, endDate }) => {
    const duration = periodDurationInDays(startDate, endDate);

    return [
        duration > 90 && dataFrequencies.hourly, // INFO: more than 90 days
        duration > 6 * 365 && dataFrequencies.daily, // INFO: more than 6 years
    ].filter(Boolean);
};
