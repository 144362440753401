import { toastWarning, removeToast } from '@infogrid/core-ducks';
import { i18n } from '@infogrid/utils-i18n';
import { takeEvery, select, put } from 'redux-saga/effects';

const UPDATE_NETWORK_CONNECTION = 'sagas/application/UPDATE_NETWORK_CONNECTION';

export const updateNetworkConnection = (isOnline) => ({
    type: UPDATE_NETWORK_CONNECTION,
    payload: { isOnline },
});

function* networkConnectionWorker({ payload }) {
    const isOnline = payload?.isOnline;

    if (isOnline) {
        const error = yield select((state) => state.error);

        yield put(removeToast('application-network-disconnected'));

        if (error) {
            window.location.reload();
        }

        return;
    }

    yield put(
        toastWarning({
            id: 'application-network-disconnected',
            message: (
                <div className="d-flex align-items-center">
                    <span>{i18n.t('Internet connection seems to be lost')}</span>
                </div>
            ),
            timeout: 0,
        }),
    );
}

export default function* networkConnectionWatcher() {
    yield takeEvery(UPDATE_NETWORK_CONNECTION, networkConnectionWorker);
}
