import { call, put, take } from 'redux-saga/effects';

import { initWSChannel } from './createSocket';

export function* readSocket(socket) {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('starting socket read worker');
    }

    const channel = yield call(initWSChannel, socket);

    while (true) {
        const action = yield take(channel);

        yield put(action);
    }
}
