import type { FormikHelpers } from 'formik';
import { useFormik } from 'formik';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import { useSubfolders } from 'apiHooks/floorPlan/folders/hooks';

import CreateFloorModal from './ConvertFolder';

export interface CreatingFloorContainerProps {
    title?: string;
    renderActions: (args: {
        selectedFoldersIds: number[];
        isSubmitting: boolean;
    }) => JSX.Element | null;
    openCreateFolder: () => void;
    buildingId: number | null;
    handleSubfoldersConverting: <T>(
        submitValues: T,
        formikProps: FormikHelpers<T>,
        selectedFolders: number[],
    ) => void;
}

const CreatingFloorContainer = ({
    title = '',
    renderActions,
    openCreateFolder,
    handleSubfoldersConverting,
    buildingId,
}: CreatingFloorContainerProps) => {
    const { t } = useTranslation('floorplan');

    const [selectedFolders, setSelectedFolders] = useState<number[]>([]);

    const { building } = useBuilding(buildingId);

    const {
        data: subfolders,
        isFetching,
        isFetched,
    } = useSubfolders(
        {
            buildingId,
        },
        {
            refetchOnMount: true,
        },
    );

    const folders = useMemo(
        () => subfolders?.filter((f) => f.floorplan_type !== 'floor'),
        [subfolders],
    );

    const primaryDescription =
        (!!folders?.length && t(`You have subfolders inside ${building?.name}`)) || '';

    const { isSubmitting, handleSubmit } = useFormik({
        initialValues: {},
        onSubmit: (submitValues, formikProps) => {
            handleSubfoldersConverting(submitValues, formikProps, selectedFolders);
        },
    });

    const selectSubFolder = useCallback(
        ({ newSelectedIds }: { newSelectedIds: number[] }) => {
            setSelectedFolders(newSelectedIds);
        },
        [setSelectedFolders],
    );

    return (
        <CreateFloorModal
            title={title}
            folders={folders}
            isFetching={isFetching || !isFetched}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            renderActions={renderActions}
            openCreateFolder={openCreateFolder}
            selectedFoldersIds={selectedFolders}
            primaryDescription={primaryDescription}
            handleSelectFolder={selectSubFolder}
        />
    );
};

export default memo(CreatingFloorContainer);
