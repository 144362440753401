import { makeStyles, fade } from '@material-ui/core';

export const usePickerRowStyles = makeStyles((theme) => ({
    listItem: {
        paddingBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
    },
    baseUserIcon: {
        color: theme.palette.text.primary,
        fontSize: '16px',
        marginRight: theme.spacing(1),
    },
    singleUserIcon: {
        marginLeft: theme.spacing(0.375),
    },
    checkbox: {
        '& > .MuiIconButton-label > .MuiSvgIcon-root > path': {
            fill: fade(theme.palette.common.gray1, 0.7),
        },
    },
    checkboxChecked: {
        '& > .MuiIconButton-label > .MuiSvgIcon-root > path': {
            fill: theme.palette.primary.main,
        },
    },
    checkboxDisabled: {
        '&$checkboxChecked': {
            '& > .MuiIconButton-label > .MuiSvgIcon-root > path': {
                fill: fade(theme.palette.primary.main, 0.7),
            },
        },
    },
}));
