import { Modal } from '@infogrid/components-material-ui';
import { ORGANIZATION_FEATURE_FLAG } from '@infogrid/core-feature-flags';
import { FeatureWrapper } from '@infogrid/core-feature-wrapper';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Button, Divider } from '@material-ui/core';
import { useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    InstallationFlowAirthingsDevice,
    InstallationFlowBEADevice,
    InstallationFlowDTDevice,
    InstallationFlowBTLDevice,
    InstallationFlowFeedbackPanelDevice,
    SiluxHubImage,
    EmergencyLightImage,
    MonnitCTClampImage,
    InstallationFlowFludiaDevice,
    InstallationFlowMonnitDryPulse,
    InstallationFlowUrbanHub,
    InstallationFlowSpaceMiniMax,
} from 'utils/Images';

import { INSTALLATION_FLOW_STEPS } from '../../constants';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../../reducer';
import { useInstallationFlowStyles } from '../../styles';
import DeviceVendorCard from '../DeviceVendorCard';

const VendorSelector = () => {
    const { t } = useTranslation('sensors');
    const installationFlowStyles = useInstallationFlowStyles();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    const onPrevious = () => {
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.INSTALL_WITH_SCANNER,
        });
    };

    const onVendorSelect = useCallback(
        (vendorTypeStep) => {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.SELECT_VENDOR,
                vendorTypeStep,
            });
        },
        [dispatchInstallationFlowContext],
    );

    const steps = [
        {
            id: 1,
            image: InstallationFlowAirthingsDevice,
            deviceTitle: registerTranslationKey('Air Quality', { ns: 'sensors' }),
            deviceDescription: registerTranslationKey(
                'Install an Airthings hub or sensor.',
                {
                    ns: 'sensors',
                },
            ),
            nextStep: INSTALLATION_FLOW_STEPS.airthingsIdentifier,
            cypressID: 'air_quality',
        },
        {
            id: 2,
            image: InstallationFlowBEADevice,
            deviceTitle: registerTranslationKey('BEA People Counting', {
                ns: 'sensors',
            }),
            deviceDescription: registerTranslationKey('Install a BEA sensor.', {
                ns: 'sensors',
            }),
            nextStep: INSTALLATION_FLOW_STEPS.BEAIdentifier,
            cypressID: 'people_counting',
        },
        {
            id: 3,
            image: InstallationFlowBTLDevice,
            deviceTitle: registerTranslationKey('Butlr People Counting{{slash}}Space', {
                ns: 'sensors',
                slash: '/',
                defaultValue: 'Butlr People Counting/Space',
            }),
            deviceDescription: registerTranslationKey('Install a Butlr sensor.', {
                ns: 'sensors',
            }),
            nextStep: INSTALLATION_FLOW_STEPS.BTLIdentifier,
            cypressID: 'btl_people_counting',
        },
        {
            id: 4,
            image: InstallationFlowDTDevice,
            deviceTitle: registerTranslationKey('Other device', { ns: 'sensors' }),
            deviceDescription: registerTranslationKey(
                'Install a DT sensor or Cloud Connector.',
                { ns: 'sensors' },
            ),
            nextStep: INSTALLATION_FLOW_STEPS.DTIdentifier,
            cypressID: 'other_device',
        },
        {
            id: 5,
            image: InstallationFlowFeedbackPanelDevice,
            deviceTitle: registerTranslationKey('Feedback panel', { ns: 'sensors' }),
            deviceDescription: registerTranslationKey(
                'Install a DT sensor feedback panel.',
                {
                    ns: 'sensors',
                },
            ),
            nextStep: INSTALLATION_FLOW_STEPS.feedbackPanelTemplateSelector,
            cypressID: 'feedback_panel',
        },
        {
            id: 6,
            image: SiluxHubImage,
            deviceTitle: registerTranslationKey('Hub - Silux', { ns: 'sensors' }),
            deviceDescription: registerTranslationKey('Install a Silux hub.', {
                ns: 'sensors',
            }),
            nextStep: INSTALLATION_FLOW_STEPS.siluxHubIdentifier,
            cypressID: 'silux_hub',
        },
        {
            id: 7,
            image: EmergencyLightImage,
            deviceTitle: registerTranslationKey('Emergency light', { ns: 'sensors' }),
            deviceDescription: registerTranslationKey('Install an emergency light.', {
                ns: 'sensors',
            }),
            nextStep: INSTALLATION_FLOW_STEPS.emergencyLightIdentifier,
            cypressID: 'emergency_light',
        },
        {
            id: 8,
            image: MonnitCTClampImage,
            deviceTitle: registerTranslationKey('Monnit CT Clamp', { ns: 'sensors' }),
            deviceDescription: registerTranslationKey(
                'Install a Monnit CT Clamp for electricity monitoring.',
                {
                    ns: 'sensors',
                },
            ),
            nextStep: INSTALLATION_FLOW_STEPS.monnitIdentifier,
            cypressID: 'monnit_ct_clamp',
        },
        {
            id: 9,
            image: InstallationFlowFludiaDevice,
            deviceTitle: registerTranslationKey('Fludia LED Pulse Meter', {
                ns: 'sensors',
            }),
            deviceDescription: registerTranslationKey(
                'Install a Fludia LED Pulse Meter for electricity monitoring.',
                {
                    ns: 'sensors',
                },
            ),
            nextStep: INSTALLATION_FLOW_STEPS.fludiaIdentifier,
            cypressID: 'fludia_led_pulse',
        },
        {
            id: 10,
            image: InstallationFlowMonnitDryPulse,
            deviceTitle: registerTranslationKey('Monnit Dry Pulse Meter', {
                ns: 'sensors',
            }),
            deviceDescription: registerTranslationKey(
                'Install a Monnit Dry Pulse Meter for electricity monitoring.',
                {
                    ns: 'sensors',
                },
            ),
            nextStep: INSTALLATION_FLOW_STEPS.monnitIdentifier,
            cypressID: 'monnit_dry_pulse',
        },
        {
            id: 11,
            image: InstallationFlowUrbanHub,
            deviceTitle: registerTranslationKey('Smart Space Hub', {
                ns: 'sensors',
            }),
            deviceDescription: registerTranslationKey(
                'Install a Smart Cleaning Pro Space Hub.',
                {
                    ns: 'sensors',
                },
            ),
            nextStep: INSTALLATION_FLOW_STEPS.urbanHubIdentifier,
            cypressID: 'smart_space_hub',
            organizationFeature: ORGANIZATION_FEATURE_FLAG.SMART_CLEANING_PRO,
        },
        {
            id: 12,
            image: InstallationFlowSpaceMiniMax,
            deviceTitle: registerTranslationKey('Smart Space Sensor', {
                ns: 'sensors',
            }),
            deviceDescription: registerTranslationKey(
                'Install a Smart Cleaning Pro Space Sensor.',
                {
                    ns: 'sensors',
                },
            ),
            nextStep: INSTALLATION_FLOW_STEPS.smartSpaceIdentifier,
            cypressID: 'smart_space',
            organizationFeature: ORGANIZATION_FEATURE_FLAG.SMART_CLEANING_PRO,
        },
    ];

    return (
        <>
            <Modal.Title>{t('Choose a device to install')}</Modal.Title>
            <Modal.Content>
                {steps.map((step, idx) => (
                    <FeatureWrapper
                        organizationFeature={step.organizationFeature}
                        key={step.id}
                    >
                        {idx !== 0 && (
                            <Divider className={installationFlowStyles.divider} />
                        )}
                        <DeviceVendorCard
                            image={step.image}
                            deviceTitle={t(step.deviceTitle)}
                            deviceDescription={t(step.deviceDescription)}
                            nextStep={step.nextStep}
                            onVendorSelect={onVendorSelect}
                            cypressID={step.cypressID}
                        />
                    </FeatureWrapper>
                ))}
            </Modal.Content>
            <Modal.Actions
                justify="space-between"
                className={installationFlowStyles.actions}
            >
                <Button data-cypress="back" onClick={onPrevious} color="primary">
                    {t('Back')}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(VendorSelector);
