import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import WidgetAlert from '../../common/WidgetAlert';
import type {
    HealthyBuildingScoreWidgetDataWithPermissionError,
    HealthyBuildingScoreWidgetData,
    HealthyBuildingScoreWidget,
} from '../types';
import Content from './Content';

interface Props {
    isLoadingDataError: boolean;

    isFetchingData: boolean;
    data: HealthyBuildingScoreWidgetDataWithPermissionError | undefined;
    widget: HealthyBuildingScoreWidget;
}

const ContentContainer = ({
    isLoadingDataError,
    isFetchingData,
    data,
    widget,
}: Props) => {
    const { t } = useTranslation('dashboard');

    if (isLoadingDataError || !data) {
        return (
            <WidgetAlert severity="error">
                {t(
                    `Error loading widget data. Please reload the page or check the widget's configuration.`,
                )}
            </WidgetAlert>
        );
    }

    if (!Object.keys(data).length) {
        return (
            <WidgetAlert severity="warning">
                {t(
                    'You do not have permissions to view data yet. Please speak to your Infogrid admin.',
                )}
            </WidgetAlert>
        );
    }

    return (
        <Content
            data={data as HealthyBuildingScoreWidgetData}
            widget={widget}
            isLoading={isFetchingData}
        />
    );
};

export default memo(ContentContainer);
