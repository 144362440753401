import type { Building } from '@infogrid/locations-types';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../Select';

interface Props {
    canUserEdit: boolean;
    buildings: Building[];
    onClose: (ids: number[]) => void;
    selectedBuildingsIds: number[];
}

const BuildingsSelect = ({
    buildings,
    canUserEdit,
    onClose,
    selectedBuildingsIds,
}: Props) => {
    const { t } = useTranslation('dashboard');

    const renderValue = useCallback(
        (items: Building[], selectedItemsIds: number[]) => {
            const checkedItemsCount = selectedItemsIds.length;

            const buildingLabel = t('Building', {
                count: checkedItemsCount,
                defaultValue___one: 'Building',
                defaultValue___other: 'Buildings',
            });

            switch (checkedItemsCount) {
                case 0:
                    return buildingLabel;
                case 1: {
                    const building = items.find(
                        (item) => selectedItemsIds[0] === item.id,
                    );

                    return building ? building.name : buildingLabel;
                }
                case items.length:
                    return t('All Buildings');
                default:
                    return t('{{count}} buildings', {
                        count: checkedItemsCount,
                        defaultValue___one: `${checkedItemsCount} building`,
                        defaultValue___other: `${checkedItemsCount} buildings`,
                    });
            }
        },
        [t],
    );

    return (
        <Select<Building>
            canUserEdit={canUserEdit}
            items={buildings}
            allSelectedLabel={t('All Buildings')}
            renderValue={renderValue}
            onClose={onClose}
            selectedIds={selectedBuildingsIds}
        />
    );
};

export default memo(BuildingsSelect);
