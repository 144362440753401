import { EditableText } from '@infogrid/components-material-ui';
import type { FloorDetail } from '@infogrid/locations-types';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button, Typography } from '@material-ui/core';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useEditFloor } from 'apiHooks/floorPlan/floors/hooks';

import { useEstateFloorViewHeaderStyles } from './styles';

interface Props {
    buildingId: number;
    buildingName: string | undefined;
    floor: FloorDetail;
}

const EstateFloorViewHeader = ({
    buildingId,
    buildingName,
    floor,
}: Props): JSX.Element => {
    const styles = useEstateFloorViewHeaderStyles();
    const isMobile = useIsMobile();

    const { t } = useTranslation('estate');

    const { mutate: updateFloor } = useEditFloor();

    const handleFloorNameUpdate = useCallback(
        (name: string) => {
            /* Remove after useEditFloor hook is rewritten to TypeScript */
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */
            updateFloor({ floorId: floor.id, buildingId, data: { name } });
        },
        [buildingId, floor, updateFloor],
    );

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                {/* Remove after EditableText is rewritten to TypeScript */}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <EditableText
                    allowEditOnMobile
                    alwaysShowEditIcon
                    editable={floor.user_actions.edit?.allowed}
                    onUpdate={handleFloorNameUpdate}
                    value={floor.name}
                    cypressId="-floor-name"
                >
                    <Typography
                        className={styles.floorName}
                        data-cypress="floor-title"
                        variant="body1"
                    >
                        {buildingName ? buildingName + ' / ' : ''}
                        {floor.name}
                    </Typography>
                </EditableText>
            </div>
            {!isMobile && (
                <Button
                    color="primary"
                    data-cypress="view-floorplan-button"
                    component={Link}
                    to={`/buildings/${buildingId}/floors/${floor.id}`}
                    size="small"
                    variant="contained"
                    className={styles.viewFloorPlanButton}
                >
                    {t('View floor plan')}
                </Button>
            )}
        </div>
    );
};

export default memo(EstateFloorViewHeader);
