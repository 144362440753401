import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import {
    useAppSelector,
    useAppDispatch,
    toastError,
    toastSuccess,
} from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { selectOrganizationId } from '@infogrid/user-ducks';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import { controllers } from '../controllers';

const TOAST_MESSAGES = {
    DEFAULT_ERROR_MESSAGE: registerTranslationKey(
        'Failed to upload sensor asset mapping',
        {
            ns: 'integrations',
        },
    ),
    SUCCESS_MESSAGE: registerTranslationKey('Upload complete', { ns: 'integrations' }),
    VALIDATION_ERROR_MESSAGE: registerTranslationKey('Invalid spreadsheet format/data.', {
        ns: 'integrations',
    }),
    IN_PROGRESS_MESSAGE: registerTranslationKey('Uploading sensor asset mapping', {
        ns: 'integrations',
    }),
};

export const useSensorAssetUpload = (): UseMutationResult<
    File,
    AxiosParsedError,
    File
> => {
    const organizationId = useAppSelector(selectOrganizationId);
    const dispatch = useAppDispatch();

    const { t } = useTranslation('integrations');

    return useMutationWithToast<File, AxiosParsedError, File>(
        (xlsx: File) => {
            const blob = new Blob([xlsx]);

            const formData = new FormData();

            formData.append('file', blob, xlsx.name);

            return wrapResponsePromise(
                controllers.uploadSensorSpreadsheet(organizationId, formData),
            );
        },
        {
            onSuccess: () => {
                dispatch(toastSuccess({ message: TOAST_MESSAGES.SUCCESS_MESSAGE }));
            },
            onError: (error: AxiosParsedError) => {
                dispatch(
                    toastError({
                        message:
                            error.response?.status === 400
                                ? t(TOAST_MESSAGES.VALIDATION_ERROR_MESSAGE)
                                : t(TOAST_MESSAGES.DEFAULT_ERROR_MESSAGE),
                    }),
                );
            },
        },
        {
            toastMessage: t(TOAST_MESSAGES.IN_PROGRESS_MESSAGE),
        },
    );
};
