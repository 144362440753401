export const STATE_KEY = 'permissionsForm';

export const SET_PERMISSIONS_DENIED = `${STATE_KEY}/SET_PERMISSIONS_DENIED`;
export const RESET = `${STATE_KEY}/RESET`;

const initialState = {
    isPermissionsAvailable: true,
};

/**
 * A feature reducer for component forms/PermissionsForms.
 * The main reason to create it is that if we have 404 error (treated as if it is 403)
 * then we need to hide not only PermissionsForms, but several parent components.
 */
const permissionsFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PERMISSIONS_DENIED:
            return {
                ...state,
                isPermissionsAvailable: action.payload.isPermissionsAvailable,
            };
        case RESET: {
            return initialState;
        }
        default:
            return state;
    }
};

export const setIsPermissionsAvailable = (isPermissionsAvailable) => ({
    type: SET_PERMISSIONS_DENIED,
    payload: { isPermissionsAvailable },
});

export const resetPermissionsForm = () => ({
    type: RESET,
});

export const isPermissionsFormAvailable = (state) =>
    state.permissionsForm.isPermissionsAvailable;

export default permissionsFormReducer;
