import type { Widget } from '@infogrid/dashboards-constants';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import maxBy from 'lodash/maxBy';
import omit from 'lodash/omit';
import type { Layouts, Layout } from 'react-grid-layout';

import { getWidgetMetadataByType } from '../../utils/widgets';

export const areLayoutsEmpty = (layouts: Layouts): boolean => {
    return layouts === undefined || layouts === null || isArray(layouts)
        ? true
        : !Object.keys(layouts).some((layout) =>
              layouts[layout] ? layouts[layout].length > 0 : true,
          );
};

export const areLayoutsEqual = (
    layouts1: Layouts = {},
    layouts2: Layouts = {},
): boolean => {
    const layouts1Breakpoints = Object.keys(layouts1);

    let areEqual = true;

    // eslint-disable-next-line consistent-return
    layouts1Breakpoints.forEach((breakpoint) => {
        const layout1ByBreakpoint = layouts1[breakpoint] || [];
        const layout2ByBreakpoint = layouts2[breakpoint] || [];

        if (!isEqual(layout1ByBreakpoint.sort(), layout2ByBreakpoint.sort())) {
            areEqual = false;
        }
    });

    return areEqual;
};

const calculateGridItemX = (position: number) => {
    if ((position - 1) % 3 === 0) {
        return 0;
    }

    if ((position - 2) % 3 === 0) {
        return 1;
    }

    return 2;
};

export const generateGridLayout = (widgets: Widget[]): Layouts => {
    const layouts = widgets?.map((widget, index) => {
        const { gridConfig } = getWidgetMetadataByType(widget.type);

        return {
            i: `${widget.id}`,
            x: calculateGridItemX(index + 1),
            y: Infinity,
            static: false,
            isDraggable: true,
            ...gridConfig,
        };
    });

    return {
        lg: layouts,
        /**
         * INFO: layouts for the rest of breakpoints
         * will be auto generated by react-grid-layout
         */
    };
};

export const appendWidgetToGrid = (layouts: Layout[] = [], widget: Widget): Layout[] => {
    const { y: maxY = 0 } = maxBy(layouts, 'y') || {};

    const lastRowLayouts = layouts.filter((layout) => layout.y === maxY);

    const { x: maxX = 0, w = 0 } = maxBy(lastRowLayouts, 'x') || {};

    const accurateX = maxX + w - 1;

    const newLayoutX = accurateX === 2 ? 0 : accurateX + 1;
    const newLayoutY = newLayoutX === 0 ? maxY + 1 : maxY;

    const { gridConfig } = getWidgetMetadataByType(widget.type);

    return [
        ...layouts,
        {
            i: `${widget.id}`,
            x: newLayoutX || 0,
            y: newLayoutY || 0,
            static: false,
            ...gridConfig,
        },
    ];
};

export const cleanLayouts = (layouts: Layouts): Layouts => {
    const cleanedLayouts: Layouts = {};

    const breakpoints = Object.keys(layouts);

    breakpoints.forEach((breakpoint) => {
        cleanedLayouts[breakpoint] = layouts[breakpoint]?.map((entry) =>
            omit(entry, [
                'isBounded',
                'resizeHandles',
                'moved',
                'isDraggable',
                'isResizable',
            ]),
        );
    });

    return cleanedLayouts;
};
