import { makeStyles } from '@material-ui/core';

export const useMetricContentStyles = makeStyles((theme) => ({
    metricState: {
        marginTop: theme.spacing(1.75),
        marginLeft: theme.spacing(1.75),
    },
    alert: {
        margin: theme.spacing(1, 0),
    },
    progress: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
