import { Typography } from '@material-ui/core';
import { memo } from 'react';

import { useTooltipStyles } from './styles';

interface Props<T> {
    data: {
        points: T[];
    };
    pointColorExtractor: (point: T) => string;
    pointKeyExtractor: (point: T) => string;
    pointNameExtractor: (point: T) => string;
    pointValueExtractor: (point: T) => string | number;
}

function Tooltip<T>({
    data,
    pointColorExtractor,
    pointKeyExtractor,
    pointNameExtractor,
    pointValueExtractor,
}: Props<T>): JSX.Element {
    const styles = useTooltipStyles();

    const { points } = data;

    return (
        <div>
            {points.map((point: T) => (
                <div
                    className={styles.row}
                    key={pointKeyExtractor(point)}
                    style={{ color: pointColorExtractor(point) }}
                >
                    <Typography className={styles.label} variant="body2">
                        {pointNameExtractor(point)}
                    </Typography>
                    <Typography className={styles.value} variant="body2">
                        {pointValueExtractor(point)}
                    </Typography>
                </div>
            ))}
        </div>
    );
}

const TooltipComponent = memo(Tooltip) as typeof Tooltip;

export default TooltipComponent;
