import { makeStyles } from '@material-ui/core';

export const useBuildingInfoStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    wrapper: {
        maxWidth: `calc(100% - ${theme.spacing(1)}px)`,
    },
    buildingName: {
        color: theme.palette.common.gray8,
        marginLeft: theme.spacing(1.5),
        maxWidth: `calc(100% - ${theme.spacing(1)}px)`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    buildingIcon: {
        color: theme.palette.common.gray8,
        fontSize: 16,
    },
    buildingAddress: {
        color: theme.palette.common.gray6,
        display: 'block',
        fontSize: 14,
        marginLeft: theme.spacing(1.5),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));
