import { queryClient } from '@infogrid/core-api';
import type { IPagination, EventsWebhook } from '@infogrid/core-types';

import { getEventsWebhooksKey } from '../getQueryKeys';

const getSingleEventsWebhookListData = (
    eventsWebhook: EventsWebhook,
): IPagination<EventsWebhook> => ({
    count: 1,
    results: [eventsWebhook],
    next: null,
    previous: null,
});

const getEmptyPaginationData = <T>(): IPagination<T> => ({
    count: 0,
    results: [],
    next: null,
    previous: null,
});

const updateSingleEventsWebhookData = (eventsWebhook: EventsWebhook | null): void => {
    const data: IPagination<EventsWebhook> = eventsWebhook
        ? getSingleEventsWebhookListData(eventsWebhook)
        : getEmptyPaginationData<EventsWebhook>();

    queryClient.setQueryData(getEventsWebhooksKey(), data);
};

export default updateSingleEventsWebhookData;
