import { Box, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { InstallationFlowSiluxHub } from 'utils/Images';

import { useInstallationFlowStyles } from '../../styles';
import DeviceIdentifier from '../DeviceIdentifier';

interface SiluxHubIdentifierProps {
    errorMessage: string;
}

const SiluxHubIdentifier = ({ errorMessage }: SiluxHubIdentifierProps): JSX.Element => {
    const { t } = useTranslation('sensors');
    const installationFlowStyles = useInstallationFlowStyles();

    return (
        <DeviceIdentifier
            errorMessage={errorMessage}
            renderFormContent={({ onChangeSerialNumber, errorText }) => (
                <Box>
                    <Typography
                        variant="body1"
                        data-cypress="instruction-text"
                        className={installationFlowStyles.paragraph}
                    >
                        {t(
                            'Enter the device ID found under the QR code on the bottom of the Intelli4G Hub',
                        )}
                    </Typography>
                    <Box>
                        <img
                            src={InstallationFlowSiluxHub}
                            alt="Pointer to device ID"
                            height={267}
                            className={classNames(installationFlowStyles.centeredImage)}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        label={t('Device ID')}
                        variant="outlined"
                        name="serialNumber"
                        onInput={onChangeSerialNumber}
                        error={!!errorText}
                        helperText={errorText}
                        data-cypress="device-id"
                    />
                </Box>
            )}
        />
    );
};

export default SiluxHubIdentifier;
