import { Typography, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { SmartCleaningHierarchyResponse } from 'views/solutionSettings/apiHooks/types';

import type { LocationSelectionMethod } from '../../types';
import LocationSelector from './LocationSelector.container';
import { useStyles } from './styles';

interface Props {
    hidden: boolean;
    locationsIsLoading: boolean;
    locations?: SmartCleaningHierarchyResponse;
    setSelectedLocations: (ids: number[]) => null;
    selectionMethod: LocationSelectionMethod;
    setSelectionMethod: (method: LocationSelectionMethod) => null;
}

const SelectLocations = ({
    hidden,
    locationsIsLoading,
    locations,
    setSelectedLocations,
    selectionMethod,
    setSelectionMethod,
}: Props) => {
    const { t } = useTranslation('solutions');
    const styles = useStyles({ hideStage: hidden });

    useEffect(() => {
        if (selectionMethod === 'all' && locations) {
            setSelectedLocations([locations.current.id]);
        } else if (selectionMethod === 'selection') {
            setSelectedLocations([]);
        }
    }, [selectionMethod, locations, setSelectedLocations]);

    return (
        <div className={styles.container}>
            <Typography variant="body2">
                {t('Now, choose whether to apply the new rule to the space types in:')}
            </Typography>
            <RadioGroup
                aria-labelledby="select locations to apply rules to"
                name="select-locations-radio-group"
                value={selectionMethod}
                onChange={(e) =>
                    setSelectionMethod(e.target.value as LocationSelectionMethod)
                }
            >
                <FormControlLabel
                    value="all"
                    disabled={locationsIsLoading}
                    data-testid="bySpaceTypeModal-selectionType-all"
                    control={<Radio color="primary" />}
                    label={
                        <Typography color="textPrimary" variant="body2">
                            {t('All buildings and floors in my estate')}
                        </Typography>
                    }
                />
                <FormControlLabel
                    value="selection"
                    data-testid="bySpaceTypeModal-selectionType-select"
                    control={<Radio color="primary" />}
                    label={
                        <Typography color="textPrimary" variant="body2">
                            {t('A selection of buildings or floors in my estate')}
                        </Typography>
                    }
                />
            </RadioGroup>
            {selectionMethod === 'selection' && (
                <div className={styles.locationPicker}>
                    <LocationSelector setSelectedSpaceIds={setSelectedLocations} />
                </div>
            )}
        </div>
    );
};

export default SelectLocations;
