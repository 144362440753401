import { theme } from '@infogrid/components-material-ui';
import { registerTranslationKey } from '@infogrid/utils-i18n';

export const AIR_QUALITY_VALUES = {
    FAIR: 'FAIR',
    GOOD: 'GOOD',
    POOR: 'POOR',
};

export const RATING_COLOURS = {
    [AIR_QUALITY_VALUES.FAIR]: theme.palette.common.yellow5,
    [AIR_QUALITY_VALUES.GOOD]: theme.palette.success.main,
    [AIR_QUALITY_VALUES.POOR]: '#EF5350',
};

export const AIR_QUALITY_LABELS = {
    [AIR_QUALITY_VALUES.FAIR]: registerTranslationKey('Fair'),
    [AIR_QUALITY_VALUES.GOOD]: registerTranslationKey('Good'),
    [AIR_QUALITY_VALUES.POOR]: registerTranslationKey('Poor'),
};
