import { makeStyles } from '@material-ui/core';

export const useSelectorStyles = makeStyles((theme) => ({
    select: {
        ...theme.typography.caption,
    },

    menuItem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        ...theme.typography.body2,
    },

    listContainer: {
        minWidth: 200,
    },
}));
