/* eslint-disable id-denylist */

import { makeStyles } from '@material-ui/core';

export const useActionsMenuStyles = makeStyles({
    menuButtonIcon: ({ isMobile }) => ({
        fontSize: isMobile && '24px',
    }),
    menuContent: ({ isMobile }) => ({
        ...(isMobile && {
            right: 0,
            left: 'unset',
        }),
    }),
});
