import { makeStyles } from '@material-ui/core';

export const useFolderTreeStyles = makeStyles((theme) => ({
    treeViewRoot: {
        color: theme.palette.text.primary,
    },
    container: {
        width: '100%',
    },
}));
