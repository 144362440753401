import type { AxiosError } from 'axios';

export const handleFetchingError = (
    err: AxiosError,
): { error: string; status: number } | null => {
    if (!err) {
        return null;
    }

    const error = err?.response?.data?.detail || '';
    const status = err?.response?.status || 500;

    return {
        error,
        status,
    };
};

export const generateErrorsForCard = ({
    isFloorInList,
    customBuildingError,
    customFloorError,
}: {
    isFloorInList: boolean;
    customBuildingError?: { status: number; error: string };
    customFloorError: { status: number; error: string };
}): { type: string; status: number; message: string } => {
    if (isFloorInList) {
        const currentError = customBuildingError || customFloorError;

        return {
            type: customBuildingError ? 'buildings' : 'floors',
            status: currentError?.status,
            message: currentError.error,
        };
    }

    return {
        message: "This floor doesn't exist in current building.",
        status: 404,
        type: 'floors',
    };
};
