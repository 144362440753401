import { NO_LOCATIONS_SELECTED } from '@infogrid/components-locations';
import type { LocationFilter } from '@infogrid/core-types';
import { LOCATION_FILTERS } from '@infogrid/core-types';
import type {
    PipeMonitoringFailureReason,
    FloorPipeMonitoringSensor,
} from '@infogrid/remote-monitoring-types';
import { ALL_BUILDINGS, FAILURE_REASON } from '@infogrid/remote-monitoring-types';
import { LEGIONNAIRE_SUB_TYPES } from '@infogrid/sensors-constants';
import type { LegionnaireSubtypes } from '@infogrid/sensors-constants';
import { calendarPeriods } from '@infogrid/utils-dates';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import { MONITORING_TYPES_ALL_KEYS } from 'components/material-ui/PipeMonitoringTypeSelection/utils';

import { CHIP_TYPE } from './constants';
import type {
    ChipType,
    PipeMonitoringAtRiskConfiguration,
    WidgetSettings,
} from './types';

export const getEnabledLocationFilters = (queryString: string): LocationFilter[] => {
    if (queryString === NO_LOCATIONS_SELECTED) {
        return [];
    }

    if (!queryString) {
        return [
            LOCATION_FILTERS.BUILDING,
            LOCATION_FILTERS.FLOOR,
            LOCATION_FILTERS.SPACE,
        ];
    }

    const selections = queryString.split(',');

    const buildings = new Set();
    const floors = new Set();

    selections.forEach((selection) => {
        const parts = selection.split(':');

        buildings.add(Math.abs(+parts[0]));

        if (parts.length > 1) {
            floors.add(parts[1]);
        }
    });

    if (buildings.size !== 1) {
        return [
            LOCATION_FILTERS.BUILDING,
            LOCATION_FILTERS.FLOOR,
            LOCATION_FILTERS.SPACE,
        ];
    }

    if (floors.size === 1) {
        return [LOCATION_FILTERS.SPACE];
    }

    return [LOCATION_FILTERS.FLOOR, LOCATION_FILTERS.SPACE];
};

export const prepareConfigurationPayload = (
    values: WidgetSettings,
): PipeMonitoringAtRiskConfiguration => {
    const enabledLocationFilters = getEnabledLocationFilters(values.locations ?? '');
    const locations = values.locations ?? ALL_BUILDINGS;

    const isLocationTypeValid = enabledLocationFilters.includes(values.floorplan_type);

    const locationType = isLocationTypeValid
        ? values.floorplan_type
        : enabledLocationFilters[0] || LOCATION_FILTERS.BUILDING;
    const pipeMonitoringFailureTypes = values.pipe_monitoring_failure_types ?? [];

    return {
        calculators: {
            pipe_monitoring_risk: {
                locations,
                floorplan_type: locationType,
                pipe_monitoring_failure_types:
                    pipeMonitoringFailureTypes.length > 0
                        ? pipeMonitoringFailureTypes
                        : MONITORING_TYPES_ALL_KEYS,
            },
        },
        time_period_range: values?.time_period_range ?? calendarPeriods.week,
        time_zone: values?.time_zone,
    };
};

export const getSubTypeDetails = (
    subtype: LegionnaireSubtypes,
): { chipType: ChipType; subtype: string } => {
    switch (subtype) {
        case LEGIONNAIRE_SUB_TYPES.HOT_POU_HEATER:
            return {
                chipType: CHIP_TYPE.HOT,
                subtype: registerTranslationKey('Hot pou heater'),
            };
        case LEGIONNAIRE_SUB_TYPES.HOT:
            return {
                chipType: LEGIONNAIRE_SUB_TYPES.HOT,
                subtype: registerTranslationKey('Hot'),
            };
        case LEGIONNAIRE_SUB_TYPES.COLD_STORAGE:
            return {
                chipType: CHIP_TYPE.COLD,
                subtype: registerTranslationKey('Cold storage'),
            };
        case LEGIONNAIRE_SUB_TYPES.COLD:
            return { chipType: CHIP_TYPE.COLD, subtype: registerTranslationKey('Cold') };
        case LEGIONNAIRE_SUB_TYPES.CALORIFIER_FLOW:
            return {
                chipType: CHIP_TYPE.CALORIFIER,
                subtype: registerTranslationKey('Calorifier flow'),
            };
        case LEGIONNAIRE_SUB_TYPES.CALORIFIER_RETURN:
            return {
                chipType: CHIP_TYPE.CALORIFIER,
                subtype: registerTranslationKey('Calorifier return'),
            };
        case LEGIONNAIRE_SUB_TYPES.BLENDED:
            return {
                chipType: CHIP_TYPE.DEFAULT,
                subtype: registerTranslationKey('Blended'),
            };
        case LEGIONNAIRE_SUB_TYPES.GENERIC:
            return {
                chipType: CHIP_TYPE.DEFAULT,
                subtype: registerTranslationKey('Generic'),
            };
        default:
            return { chipType: CHIP_TYPE.DEFAULT, subtype };
    }
};

export const getFailReasons = (reasons: PipeMonitoringFailureReason[]): string[] => {
    const unknownReason = registerTranslationKey('Unknown Fail');

    if (reasons.length <= 0) {
        return [unknownReason];
    }

    return reasons.map((r) => {
        switch (r) {
            case FAILURE_REASON.WATER_MOVING:
                return registerTranslationKey('Water Movement Fail');
            case FAILURE_REASON.TEMPERATURE:
                return registerTranslationKey('Temperature Fail');
            default:
                return unknownReason;
        }
    });
};

export const validateConfiguration = (locations: string | undefined): boolean => {
    return locations !== NO_LOCATIONS_SELECTED;
};

export const getInitialConfiguration = ({
    locations,
    ...rest
}: WidgetSettings): WidgetSettings => {
    const locationsConfig = locations ?? NO_LOCATIONS_SELECTED;
    const time_period_range = rest.time_period_range ?? calendarPeriods.week;

    return {
        locations: locationsConfig === ALL_BUILDINGS ? undefined : locationsConfig,
        time_period_range,
        ...rest,
    };
};

export const filterSensorsWithFailure = (sensor: FloorPipeMonitoringSensor) => {
    return sensor.failure_reasons.length > 0;
};
