import type { AlertProps } from '@material-ui/lab';
import { Alert } from '@material-ui/lab';
import { memo } from 'react';

import { useWidgetAlertStyles } from './styles';

const WidgetAlert = ({ children, ...alertProps }: AlertProps) => {
    const styles = useWidgetAlertStyles();

    return (
        <div className={styles.container}>
            <Alert
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...alertProps}
            >
                {children}
            </Alert>
        </div>
    );
};

export default memo(WidgetAlert);
