import PropTypes from 'prop-types';

export const FloorShape = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sensors: PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
            sensor_id: PropTypes.number,
            device_name: PropTypes.string,
        }),
    ),
    image: PropTypes.shape({
        image_file: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    sensors_count: PropTypes.number,
    order_index: PropTypes.number,
    orientation: PropTypes.number,
    zoom: PropTypes.number,
    scale: PropTypes.number,
    sensor_types: PropTypes.arrayOf(PropTypes.string),
});

export const FloorListViewShape = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sensors_count: PropTypes.number,
    hubs_count: PropTypes.number,
    order_index: PropTypes.number,
    editable: PropTypes.bool,
});

export const FloorListShape = PropTypes.arrayOf(FloorShape);
