import { DisabledTooltipButton } from '@infogrid/components-material-ui';
import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { Alert } from '@material-ui/lab';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useConnectedIsOpenState } from 'utils/hooks';
import { useIsTempOffsetMissed, useIsTempOffsetFailed } from 'utils/hooks/sensors';
import useSensorSignal from 'utils/hooks/useSensorSignal';

import { useTemperatureOffsetSetupAlertStyles } from './styles';

interface Props {
    sensor: Pick<
        GenericSensorShape,
        'type_code' | 'device_name' | 'reading_types' | 'type'
    >;
}

const TemperatureOffsetSetupAlert = ({ sensor }: Props) => {
    const { t } = useTranslation('sensors');
    const [_0, _1, handleOpen] = useConnectedIsOpenState('temperatureOffset');
    const isTempOffsetMissed = useIsTempOffsetMissed(sensor);
    const isTempOffsetFailed = useIsTempOffsetFailed(sensor);
    const styles = useTemperatureOffsetSetupAlertStyles();
    const { isOnline } = useSensorSignal(sensor);

    const setOffsetButton = (
        <DisabledTooltipButton
            variant="text"
            color="default"
            size="small"
            onClick={handleOpen}
            className={styles.button}
            data-cypress="temp-offset-set-now"
            disabled={isOnline === false}
            tooltipTitle={t('Unable to record offset while sensor is offline.')}
        >
            {t('Set now')}
        </DisabledTooltipButton>
    );

    if (isTempOffsetMissed) {
        return (
            <Alert severity="error" action={setOffsetButton}>
                {t('Missing temp off-set')}
            </Alert>
        );
    }

    if (isTempOffsetFailed) {
        return (
            <Alert severity="error" action={setOffsetButton}>
                {t(
                    'Pipe monitoring calibration offset failed. Please take a new probe reading.',
                )}
            </Alert>
        );
    }

    return null;
};

export default memo(TemperatureOffsetSetupAlert);
