import type { FloorDetail } from '@infogrid/locations-types';
import classNames from 'classnames';
import { memo, useCallback } from 'react';

import Sidebar from 'components/Sidebar';
import { SIDEBAR_COLLAPSE_BUTTON_DIRECTION } from 'components/Sidebar/SidebarCollapseButton';
import { SIDEBAR_COLLAPSE_BUTTON_POSITION } from 'components/Sidebar/constants';

import SidebarContent from './SidebarContent';
import { COLLAPSED_SPACE_SIDEBAR_WIDTH, DEFAULT_SPACE_SIDEBAR_WIDTH } from './consts';
import { useUnassignedSensorsSidebarStyles } from './styles';

interface Props {
    buildingId: number;
    floor: FloorDetail;
    onCloseSidebar: () => void;
}

const UnassignedSensorsSidebar = ({ buildingId, floor, onCloseSidebar }: Props) => {
    const styles = useUnassignedSensorsSidebarStyles();

    const renderCollapseSensorsButton = useCallback(
        ({ ButtonComponent, className, ...rest }) => (
            <ButtonComponent
                className={classNames(className, styles.sidebarCollapseButton)}
                cypressId="-unassigned-sensors"
                iconClassName={styles.sidebarCollapseIcon}
                location={SIDEBAR_COLLAPSE_BUTTON_DIRECTION.RIGHT}
                position={SIDEBAR_COLLAPSE_BUTTON_POSITION.LEFT}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
            />
        ),
        [styles.sidebarCollapseButton, styles.sidebarCollapseIcon],
    );

    return (
        // Refactor Sidebar to TypeScript: https://infogrid.atlassian.net/browse/WEB-4072
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Sidebar
            className={styles.sidebar}
            collapsedWidth={COLLAPSED_SPACE_SIDEBAR_WIDTH}
            collapseButton={renderCollapseSensorsButton}
            collapseButtonPosition={SIDEBAR_COLLAPSE_BUTTON_POSITION.LEFT}
            defaultOpened
            isCollapseButtonAlwaysVisible
            sidebarName="UnassignedSensorsSidebar"
            transitionDuration={300}
            uncollapsedWidth={DEFAULT_SPACE_SIDEBAR_WIDTH}
        >
            {({
                opened,
                isTransitioning,
            }: {
                opened: boolean;
                isTransitioning: boolean;
            }) =>
                opened || isTransitioning ? (
                    <SidebarContent
                        buildingId={buildingId}
                        floor={floor}
                        onCloseSidebar={onCloseSidebar}
                    />
                ) : (
                    <div className={styles.collapsedSidebar} />
                )
            }
        </Sidebar>
    );
};

export default memo(UnassignedSensorsSidebar);
