import { wrapResponsePromise } from '@infogrid/core-api';
import type { IPagination, AxiosParsedError } from '@infogrid/core-types';
import type { Building } from '@infogrid/locations-types';
import type { AxiosResponse } from 'axios';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getBuildingsKey } from '../getQueryKeys';

const mapper = (response: AxiosResponse<IPagination<Building>>) => response.data.results;

interface UseBuildingsResult
    extends Omit<UseQueryResult<Building[], AxiosParsedError>, 'data'> {
    buildings: Building[];
}

export const useAllBuildings = (
    params?: { [key: string]: unknown },
    options?: UseQueryOptions<Building[], AxiosParsedError>,
): UseBuildingsResult => {
    const { data, ...rest } = useQuery(
        getBuildingsKey(params),
        ({ signal }) =>
            wrapResponsePromise<IPagination<Building>, Building[]>(
                controllers.getBuildings(signal, params),
                mapper,
            ),
        {
            refetchOnMount: false,
            ...options,
        },
    );

    const buildings = data ?? [];

    return {
        buildings,
        ...rest,
    };
};
