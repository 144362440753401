/* eslint-disable id-denylist */

import { makeStyles } from '@material-ui/core';

export const useStylesContainer = makeStyles({
    container: ({ isMobile }) => ({
        display: 'flex',
        width: '100%',

        ...(isMobile && {
            flexDirection: 'column',
            width: '100vw',
        }),
    }),
    content: ({ isMobile, sidebarWidth = 0 }) => ({
        width: isMobile ? '100%' : `calc(100% - ${sidebarWidth}px)`,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',

        flexGrow: 1,
    }),
});
