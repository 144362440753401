import type { SensorTypeObject } from '@infogrid/sensors-constants';
import { Typography } from '@material-ui/core';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ChecklistWithAllSelect from 'components/ChecklistWithAllSelect';

import { useSensorChecklistStyles } from './styles';

const SENSORS_CHECKLIST_ITEMS_LIST_HEIGHT = 300;

interface Props {
    classes?: { itemsList?: string };
    sensorTypes: SensorTypeObject[];
    checkedSensorTypes: string[];
    valueExtractor: (x: SensorTypeObject) => string;
    keyExtractor: (x: SensorTypeObject) => string;
    toggleSensorTypeHandler: (type: string | number) => void;
    toggleAllSensorsTypesHandler: (x: boolean) => void;
}

const SensorsChecklist = ({
    sensorTypes,
    checkedSensorTypes,
    toggleSensorTypeHandler,
    toggleAllSensorsTypesHandler,
    keyExtractor,
    valueExtractor,
    ...checklistProps
}: Props) => {
    const sensorChecklistStyles = useSensorChecklistStyles();

    const { t } = useTranslation();

    const renderValue = useCallback(
        () => (
            <Typography
                component="span"
                className={sensorChecklistStyles.sensorChecklistOutput}
                variant="body2"
                data-cypress="sensor-checklist"
            >
                {t('Sensor Type')}
            </Typography>
        ),
        [sensorChecklistStyles.sensorChecklistOutput, t],
    );

    return (
        <ChecklistWithAllSelect<SensorTypeObject>
            maxHeight={SENSORS_CHECKLIST_ITEMS_LIST_HEIGHT}
            allItemsCheckedText={t('Select all')}
            checkedItemsKeys={checkedSensorTypes}
            items={sensorTypes}
            renderValue={renderValue}
            checkHandler={toggleSensorTypeHandler}
            checkAllHandler={toggleAllSensorsTypesHandler}
            keyExtractor={keyExtractor}
            valueExtractor={valueExtractor}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...checklistProps}
        />
    );
};

export default memo(SensorsChecklist);
