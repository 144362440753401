import type { WidgetDataFrequency } from '@infogrid/dashboards-constants';
import type { SelectProps } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import classNames from 'classnames';
import some from 'lodash/some';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataFrequencyStyles } from './styles';

interface DataFrequencyItem {
    label: string;
    value: string;
}

interface Props<T> {
    className?: string;
    disabled?: boolean;
    value?: WidgetDataFrequency | null;
    handleChange: SelectProps['onChange'];
    disabledFrequencies?: T[];
    frequencies: T[];
}

function DataFrequency<T extends DataFrequencyItem>({
    className = '',
    disabled = false,
    value = null,
    disabledFrequencies = [],
    frequencies,
    handleChange,
}: Props<T>) {
    const { t } = useTranslation('dashboard');
    const styles = useDataFrequencyStyles();

    const formControlClass = classNames(styles.formControl, className);

    return (
        <FormControl
            className={formControlClass}
            size="small"
            variant="outlined"
            disabled={disabled}
        >
            <InputLabel id="data-frequency-label">{t('Data frequency')}</InputLabel>
            <Select
                id="data-frequency"
                labelId="data-frequency-label"
                name="displayOptions.dataFrequency"
                onChange={handleChange}
                variant="outlined"
                value={value}
                data-cypress="data-frequency"
                inputProps={{
                    'data-testid': 'data-frequency-select-input',
                }}
            >
                {frequencies.map((frequency) => (
                    <MenuItem
                        disabled={some(disabledFrequencies, frequency)}
                        key={frequency.value}
                        value={frequency.value}
                    >
                        {t(frequency.label)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default memo(DataFrequency);
