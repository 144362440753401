import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { toastSuccess, toastError } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { SensorType, SensorShape } from '@infogrid/sensors-constants';
import { useTranslation } from 'react-i18next';
import type { UseMutateFunction, UseMutationResult, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { controllers } from '../ts/controllers';

interface Params {
    deviceName: string;
}

export type CreateDeskOccupancySensorMutation = UseMutateFunction<
    SensorShape,
    AxiosParsedError,
    Params
>;

interface HookConfig {
    sensorType: SensorType;
    toastMessages: {
        DEFAULT_ERROR_MESSAGE: string;
        SUCCESS_MESSAGE: string;
        IN_PROGRESS_MESSAGE: string;
    };
}

export const useUpdateSensorType = (
    { sensorType, toastMessages }: HookConfig,
    options: UseQueryOptions<SensorShape, AxiosParsedError>,
): UseMutationResult<SensorShape, AxiosParsedError, Params> => {
    const { t } = useTranslation('sensors');
    const dispatch = useDispatch();

    return useMutationWithToast(
        ({ deviceName }: Params) =>
            wrapResponsePromise(
                controllers.updateSensorType({ id: deviceName, typeCode: sensorType }),
            ),
        {
            ...options,
            onSuccess: (sensor) => {
                dispatch(
                    toastSuccess({
                        message: sensor?.profile?.name
                            ? `${t(toastMessages.SUCCESS_MESSAGE)} - ${
                                  sensor.profile.name
                              }`
                            : t(toastMessages.SUCCESS_MESSAGE),
                    }),
                );
                options?.onSuccess?.(sensor);
            },
            onError: ({ parsedError }: AxiosParsedError) => {
                dispatch(
                    toastError({
                        message:
                            parsedError.message || t(toastMessages.DEFAULT_ERROR_MESSAGE),
                    }),
                );
                options?.onError?.(parsedError);
            },
        },
        { toastMessage: t(toastMessages.IN_PROGRESS_MESSAGE) },
    );
};
