import type { RouteConfig } from 'react-router-config';

import RenderChildren from 'components/RenderChildren';
import { userIsAuthenticated, userIsManager } from 'decorators/auth';
import Estate from 'views/estate/pages/estate';
import EstateFloor from 'views/estate/pages/estateFloor';

export const createEstateRoutes = (PageNotFoundRoute: RouteConfig): RouteConfig => ({
    path: '/estate/',
    name: 'estate',
    component: userIsAuthenticated(RenderChildren),
    routes: [
        {
            path: '/estate/',
            exact: true,
            name: 'estate',
            component: userIsManager(Estate),
        },
        {
            path: '/estate/:buildingId',
            exact: true,
            name: 'estate-building',
            component: userIsManager(EstateFloor),
        },
        {
            path: '/estate/:buildingId/floors/:floorId',
            exact: true,
            name: 'estate-building-floor',
            component: userIsManager(EstateFloor),
        },
        PageNotFoundRoute,
    ],
});
