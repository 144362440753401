import { useAppSelector } from '@infogrid/core-ducks';
import { SENSOR_TYPE, SENSOR_VENDORS } from '@infogrid/sensors-constants';
import type { PlannedSensor } from '@infogrid/sensors-constants';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { selectPlannedSensor } from 'ducks/installFlow';
import {
    InstallationFlowAirthingsSerial,
    InstallationFlowUrbanHubBack,
} from 'utils/Images';

import IdentifyDT from './IdentifyDT';
import IdentifyForm from './IdentifyForm';
import IdentifyURB from './IdentifyURB';

const ManualIdentify: FC = () => {
    const { t } = useTranslation('sensors');
    const plannedSensor = useAppSelector(selectPlannedSensor) as PlannedSensor;
    const sensorType = plannedSensor.device_type.name;

    switch (sensorType) {
        case SENSOR_TYPE.TYPE_TEMPERATURE:
        case SENSOR_TYPE.TYPE_CCON:
        case SENSOR_TYPE.TYPE_LEGIONNAIRE:
        case SENSOR_TYPE.TYPE_DESK_OCCUPANCY:
        case SENSOR_TYPE.TYPE_PROXIMITY:
            return <IdentifyDT />;

        case SENSOR_TYPE.TYPE_AIRTHINGS_HUB:
        case SENSOR_TYPE.TYPE_AIR_QUALITY:
            return (
                <IdentifyForm
                    vendor={SENSOR_VENDORS.AIRTHINGS}
                    helpText={t(
                        'Enter the Serial Number and Device ID which can be found under the mounting on the reverse of both the Hub and Wave Plus devices.',
                    )}
                    helpImage={InstallationFlowAirthingsSerial}
                    hasSerialNumber
                />
            );
        case SENSOR_TYPE.TYPE_URBAN_HUB:
        case SENSOR_TYPE.TYPE_SMART_SPACE:
        case SENSOR_TYPE.TYPE_SMART_SPACE_MAX:
            return (
                <IdentifyURB
                    vendor={SENSOR_VENDORS.URB}
                    helpText={t(
                        'Enter the Serial Number and Device ID which can be found under the mounting on the reverse of both the Hub and Wave Plus devices.',
                    )}
                    helpImage={InstallationFlowUrbanHubBack}
                />
            );
        default: {
            console.error(
                'No manual installation process is available for this type of sensor.',
            );

            return null;
        }
    }
};

export default ManualIdentify;
