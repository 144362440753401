import { Modal } from '@infogrid/components-material-ui';
import type { Folder } from '@infogrid/core-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import FoldersPicker from '../../shared/FoldersPicker';
import { useChoosingFolderFloorStyles } from './styles';

export interface ConvertFolderProps {
    selectedFoldersIds: number[];
    folders?: Folder[];
    isSubmitting: boolean;
    isFetching: boolean;
    title: string;
    primaryDescription: string | null;
    handleSubmit: () => void;
    renderActions: (args: {
        selectedFoldersIds: number[];
        isSubmitting: boolean;
    }) => JSX.Element | null;
    openCreateFolder: () => void;
    handleSelectFolder: (payload: { newSelectedIds: number[] }) => void;
}

const ConvertFolder = ({
    handleSubmit,
    renderActions,
    handleSelectFolder,
    openCreateFolder,
    title = '',
    folders = [],
    selectedFoldersIds = [],
    isSubmitting = false,
    isFetching = false,
    primaryDescription = null,
}: ConvertFolderProps) => {
    const styles = useChoosingFolderFloorStyles();

    const { t } = useTranslation('floorplan');

    return (
        <form onSubmit={handleSubmit}>
            <Modal.Title>{title}</Modal.Title>

            {primaryDescription && (
                <Modal.Description className={styles.primaryDescription}>
                    {primaryDescription}
                </Modal.Description>
            )}

            <Modal.Description className={styles.secondaryDescription}>
                {t(
                    'These subfolders will be converted to floors. If there are any folders listed that you' +
                        ' do not want to be converted to floors, deselect them from the list.',
                )}
            </Modal.Description>

            <Modal.Content>
                <Modal.ContentItem>
                    <FoldersPicker
                        selectedFoldersIds={selectedFoldersIds}
                        onCreateNewFolder={openCreateFolder}
                        onSelectFolders={handleSelectFolder}
                        isFetching={isFetching}
                        folders={folders}
                    />
                </Modal.ContentItem>
            </Modal.Content>

            {renderActions({ selectedFoldersIds, isSubmitting })}
        </form>
    );
};

export default memo(ConvertFolder);
