/* global __APP_ENV__ */
// NB: If you are adding env-variable based settings, consider adding them to .env.development (and .env.production)
const SETTINGS = {
    COREAPI_BASE: '/api/',
    AUTH_TOKEN_NAME: 'infogrid_token',
    ORGANIZATION_ID_COOKIE_NAME: 'infogrid_organization',
    ORGANIZATION_ID_HEADER_NAME: 'X-Infogrid-Organization',
    // KEEP `AUTH_TOKEN_LIFETIME` IN SYNC WITH backend ACCESS_TOKEN_LIFETIME
    AUTH_TOKEN_LIFETIME: 30, // This value is in minutes
    AUTH_REFRESH_TOKEN_NAME: 'infogrid_refresh_token',
    CSRF_COOKIE_NAME: 'csrftoken',
    DEFAULT_LANGUAGE: 'en',

    SITE_URL: null,
    BACKEND_SITE_URL: null,
    AUTH_SERVICE_URL: null,
    WEBHOOK_SERVICE_URL: null,
    CUBE_SERVICE_URL: null,
    CUBE_SERVICE_SECRET: null,
    CUBE_SHOULD_USE_SQUARE: false,
    SENSOR_PLANNING_BACKEND_URL: null,
    COOKIE_DOMAIN: null,

    // 3rd party integration settings
    SENTRY_DSN: null,
    SENTRY_ENVIRONMENT: null,
    SENTRY_RELEASE: null,
    SEGMENT_ANALYTICS_KEY: null,
    MAPBOX_ACCESS_TOKEN: null,
    INTERCOM_APP_ID: null,
    AUTH0_DOMAIN: null,
    AUTH0_CLIENT_ID: null,

    VERSION_HASH: null,

    SOCKET_URL: '/api/stream/',
    SOCKET_RECONNECT_TIMEOUT: 10 * 1000, // wait 10 sec to auto-reconnect
    SOCKET_HEARTBEAT_INTERVAL: 20 * 1000, // every 20 sec send heartbeat message to backend

    LONG_SAGA_TIMEOUT: 60000,

    SELECTED_PREVIEW_MAX_ITEMS: 5,

    VERBOSE: false,

    // See utils/featureFlags.js for feature flags

    // Overwrite client settings from server runtime
    // See also https://gitlab.com/minigrid/tf-services/tf-webclient/-/blob/master/app/modules/webclient/frontend_deployment/config.json
    ...(typeof window !== 'undefined' && window.__settings__),
    // Jest doesn't have `__APP_ENV__` as it's injected by Vite and jest doesn't
    // use it. Globals don't seem to be definable using
    // setupTestsGlobal with our current version of jest so to get round that
    // we check if __APP_ENV__ exists and if not, use process.env
    // If we upgrade to jest 29, we can hopefully do this in setupTestsGlobal
    ...(typeof __APP_ENV__ !== 'undefined' ? __APP_ENV__ : process.env),
};

export default Object.freeze(SETTINGS);
