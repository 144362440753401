import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import reduce from 'lodash/reduce';

import { parseArrayParams, parseNonArrayParams } from 'utils/filtering/parsers';

import { SENSOR_PARAM_NAMES, SENSOR_PARAMS_SET } from '../../sensor/consts';

const PARSING_PARAMS_HANDLERS = {
    [SENSOR_PARAM_NAMES.LOCATION]: parseArrayParams,
    [SENSOR_PARAM_NAMES.LABELS]: parseArrayParams,
    [SENSOR_PARAM_NAMES.TYPE]: parseArrayParams,
    [SENSOR_PARAM_NAMES.QUERY]: parseNonArrayParams,
};

export const cleanParams = (params) =>
    reduce(
        params,
        (acc, param, key) => {
            if (!SENSOR_PARAMS_SET.has(key) || isNil(param) || param === '') {
                return acc;
            }

            return { ...acc, [key]: param };
        },
        {},
    );

export const parseParams = (params) =>
    reduce(
        params,
        (acc, value, key) => {
            if (!SENSOR_PARAMS_SET.has(key) || isUndefined(value)) {
                return acc;
            }

            const handlers = PARSING_PARAMS_HANDLERS;

            return {
                ...acc,
                [key]: handlers[key] ? handlers[key](value) : value,
            };
        },
        {},
    );
