import { makeStyles } from '@material-ui/core';

const SECONDARY_TEXT = '#918D85';
const BLACK_TEXT = '#393F4D';

export const useStylesFloor = makeStyles((theme) => ({
    floor: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.common.gray5,
        padding: theme.spacing(2, 2.25),
        borderBottom: `2px solid ${theme.palette.common.shell}`,
        '&:hover': {
            backgroundColor: '#FFF',
            '& $editFloorIcon': {
                display: 'block',
            },
        },
        cursor: 'pointer',
    },
    activeFloor: {
        backgroundColor: '#FFF',
        '& $editFloorIcon': {
            display: 'block',
        },
    },
    floorInfoContainer: {
        flexGrow: 1,
    },
    dragIcon: {
        fontSize: '16px',
        color: SECONDARY_TEXT,
        opacity: 0.4,
    },
    dragIconContainer: {
        padding: theme.spacing(1),
    },

    floorNameContainer: {
        display: 'flex',
        alignItems: 'center',
        color: BLACK_TEXT,
    },

    floorName: {
        wordBreak: 'break-all',
    },

    editFloorIcon: {
        display: 'none',
        fontSize: '15px',
        lineHeight: '18px',
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(0.75),
        padding: theme.spacing(0.75),
    },

    editFloorIconContainer: {
        minWidth: '40px',
    },

    sensorsInfoContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        color: SECONDARY_TEXT,
    },

    sensorsCount: {
        borderRight: `1px solid ${SECONDARY_TEXT}`,
        paddingRight: theme.spacing(0.75),
        marginRight: theme.spacing(0.75),
    },
}));
