import { makeStyles } from '@material-ui/core';

export const useSelectStyles = makeStyles((theme) => ({
    checklist: {
        margin: 0,
        overflow: 'hidden',
    },
    renderValue: {
        marginRight: theme.spacing(1.5),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));
