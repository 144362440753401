import { registerTranslationKey } from '@infogrid/utils-i18n';
import { useMemo } from 'react';

interface EventsWebhookAuthMethod {
    value: boolean;
    label: string;
    helpText: string;
}

export const AUTH_METHOD_SECRET: EventsWebhookAuthMethod = {
    value: false,
    label: registerTranslationKey('Send key in headers', { ns: 'integrations' }),
    helpText: registerTranslationKey(
        'The secret key will be included in the headers we send to you (X-Infogrid-Secret-Key). Make sure the key we send in the headers matches your copy of the secret key.',
        { ns: 'integrations' },
    ),
};

export const AUTH_METHOD_SIGNATURE: EventsWebhookAuthMethod = {
    value: false,
    label: registerTranslationKey('Send signature in headers', { ns: 'integrations' }),
    helpText: registerTranslationKey(
        'The headers we send to you will include an HMAC signature (X-Infogrid-Signature) and the hash algorithm used to generate it (X-Infogrid-Signature-Algorithm). You will need to verify the signature using your local copy of the secret key.',
        { ns: 'integrations' },
    ),
};

export const getAuthMethod = (value?: boolean): EventsWebhookAuthMethod | undefined => {
    switch (value) {
        case false:
            return AUTH_METHOD_SECRET;
        case true:
            return AUTH_METHOD_SIGNATURE;
        case undefined:
            return undefined;
        default:
            throw Error('Events webhook auth method must be a boolean or undefined.');
    }
};

export const useAuthMethod = (value?: boolean): EventsWebhookAuthMethod | undefined =>
    useMemo(() => getAuthMethod(value), [value]);
