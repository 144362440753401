import type { EventsWebhook } from '@infogrid/core-types';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Form, useFormikContext } from 'formik';
import type { ReactNode } from 'react';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ButtonSpinner from 'components/ButtonSpinner';
import TitleBar from 'components/TitleBar';

import IsEnabledSwitch from '../IsEnabledSwitch';
import SecretWithCopyField from '../SecretWithCopyField';
import UrlWithButtonsField from '../UrlWithButtonsField';
import WebhookAuthMethodField from '../WebhookAuthMethodField';
import { useEventsWebhookFormStyles } from './styles';

const EVENTS_WEBHOOK_HELP_ARTICLE_LINK =
    'https://help.infogrid.io/en/articles/6554831-how-to-use-webhooks';

interface EventsWebhookFormProps {
    isDeactivationConfirmModalOpen: boolean;
    closeDeactivationConfirmModal: () => void;
    openDeactivationConfirmModal: () => void;
    onReset: () => void;
    canChangeSecret: boolean;
    showSecret: boolean;
    canChangeURL: boolean;
    canChangeSendSignature: boolean;
    showCreateButton: boolean;
    disableIsEnabledField: boolean;
    children?: ReactNode;
}

const EventsWebhookForm = ({
    isDeactivationConfirmModalOpen,
    closeDeactivationConfirmModal,
    openDeactivationConfirmModal,
    onReset,
    canChangeSecret,
    showSecret,
    canChangeURL,
    canChangeSendSignature,
    showCreateButton,
    disableIsEnabledField,
    children,
}: EventsWebhookFormProps): JSX.Element => {
    const styles = useEventsWebhookFormStyles();

    const { t } = useTranslation('integrations');

    const formik = useFormikContext<EventsWebhook>();

    return (
        <Form>
            <TitleBar
                large
                className={styles.titleBar}
                title={t('Events Webhook')}
                rightElement={
                    <IsEnabledSwitch
                        isConfirmModalOpen={isDeactivationConfirmModalOpen}
                        closeConfirmModal={closeDeactivationConfirmModal}
                        openConfirmModal={openDeactivationConfirmModal}
                        disabled={disableIsEnabledField}
                    />
                }
            />
            <Card variant="elevation" elevation={0} data-cypress="event-webhook-card">
                <CardContent>
                    <Alert
                        severity="info"
                        classes={{
                            root: styles.infoMessage,
                        }}
                    >
                        <Typography variant="body2">
                            {t(
                                'Enable ingestion of all sensor event data ' +
                                    'into an external system. Enter a url to ' +
                                    'send the data to and generate a secret key ' +
                                    'to authorise the connection.',
                            )}
                        </Typography>
                        <Typography variant="body2">
                            <Trans t={t}>
                                For more help with Webhooks visit this{' '}
                                <a href={EVENTS_WEBHOOK_HELP_ARTICLE_LINK}>
                                    help article
                                </a>
                                .
                            </Trans>
                        </Typography>
                    </Alert>
                    <UrlWithButtonsField
                        formik={formik}
                        name="url"
                        classes={{
                            root: styles.fieldGroup,
                            field: styles.formItem,
                            buttonReset: styles.button,
                        }}
                        showReset={!canChangeURL}
                        onReset={onReset}
                    />
                    <Box className={styles.fieldGroup}>
                        <WebhookAuthMethodField
                            name="send_signature"
                            canChange={canChangeSendSignature}
                        />
                    </Box>
                    {showSecret && (
                        <SecretWithCopyField
                            formik={formik}
                            name="secret"
                            classes={{
                                root: styles.fieldGroup,
                                field: styles.formItem,
                                button: styles.button,
                            }}
                            disabled={!canChangeSecret}
                        />
                    )}
                    {typeof formik.status === 'string' && (
                        <Box className={styles.fieldGroup}>
                            <Alert severity="error">{formik.status}</Alert>
                        </Box>
                    )}
                    {children}
                    {showCreateButton && (
                        <Button
                            data-cypress="events-webhook-secret-generate"
                            data-testid="events-webhook-secret-generate"
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="large"
                            disabled={!formik.isValid}
                        >
                            {formik.isSubmitting ? (
                                <ButtonSpinner />
                            ) : (
                                t('Generate Secret Key')
                            )}
                        </Button>
                    )}
                </CardContent>
            </Card>
        </Form>
    );
};

export default memo(EventsWebhookForm);
