import {
    cleanInactiveCachedSingleSensorQueries,
    cleanInactiveCachedSensorListQueries,
} from './cleanSensorsCache';
import { updateSensorsInList } from './updateSensorsInList';
import { updateSingleSensor } from './updateSingleSensor';

export * from './updateSensorsInList';
export * from './updateSingleSensor';

// Update sensor without touching order, will only use latest sensor data currently known
export const updateAllSensors = (sensorsMap) => {
    updateSensorsInList(sensorsMap);
    updateSingleSensor(sensorsMap);
};

export const cleanInactiveSensors = (deviceNames) => {
    cleanInactiveCachedSensorListQueries();

    if (deviceNames) {
        const deviceNamesArray = Array.isArray(deviceNames) ? deviceNames : [deviceNames];

        deviceNamesArray.forEach((deviceName) =>
            cleanInactiveCachedSingleSensorQueries(deviceName),
        );
    }
};
