import { ViewWrapper } from '@infogrid/components-material-ui';
import { Icon, IconButton, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo, useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { useStylesFloor } from './styles';

interface Props {
    hubsCount: number;
    floorId: number;
    id: string;
    index: number;
    isActive: boolean;
    isBuildingEditable: boolean;
    isFloorEditable: boolean;
    name: string;
    onEditClick: ({ id }: { id: number }) => void;
    onFloorClick: ({ id }: { id: number }) => void;
    sensorsCount: number;
}

const Floor = ({
    hubsCount,
    floorId,
    id,
    index,
    isActive,
    isBuildingEditable,
    isFloorEditable,
    name,
    onEditClick,
    onFloorClick,
    sensorsCount,
}: Props) => {
    const styles = useStylesFloor();

    const { t } = useTranslation('floorplan');

    const selectFloor = useCallback(() => {
        if (!isActive) {
            onFloorClick({
                id: floorId,
            });
        }
    }, [floorId, onFloorClick, isActive]);

    const editFloor = useCallback(
        (e) => {
            e.stopPropagation();
            onEditClick({
                id: floorId,
            });
        },
        [floorId, onEditClick],
    );

    return (
        <Draggable
            key={id}
            draggableId={id}
            index={index}
            isDragDisabled={!isBuildingEditable}
        >
            {(provided) => (
                <div
                    className={classNames(styles.floor, {
                        [styles.activeFloor]: isActive,
                    })}
                    onKeyDown={selectFloor}
                    onClick={selectFloor}
                    ref={provided.innerRef}
                    tabIndex={0}
                    data-cypress="floor"
                    role="button"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...provided.draggableProps}
                >
                    <div className={styles.floorInfoContainer}>
                        <Typography
                            variant="subtitle1"
                            component="div"
                            className={styles.floorNameContainer}
                        >
                            <span className={styles.floorName}>{name}</span>

                            <ViewWrapper
                                isVisible={isFloorEditable}
                                className={styles.editFloorIconContainer}
                            >
                                <IconButton
                                    aria-hidden
                                    className={classNames(
                                        'far fa-pen',
                                        styles.editFloorIcon,
                                    )}
                                    onClick={editFloor}
                                    data-cypress="edit-btn"
                                />
                            </ViewWrapper>
                        </Typography>

                        <Typography
                            variant="body2"
                            component="div"
                            className={styles.sensorsInfoContainer}
                        >
                            <div className={styles.sensorsCount}>
                                {t('{{count}} sensors', {
                                    count: sensorsCount,
                                    defaultValue___one: `${sensorsCount} sensor`,
                                    defaultValue___other: `${sensorsCount} sensors`,
                                })}
                            </div>
                            <div>
                                {t('{{count}} hubs', {
                                    count: hubsCount,
                                    defaultValue___one: '{{count}} hub',
                                    defaultValue___other: '{{count}} hubs',
                                })}
                            </div>
                        </Typography>
                    </div>

                    <ViewWrapper isVisible={isBuildingEditable}>
                        <div
                            className={styles.dragIconContainer}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.dragHandleProps}
                            data-cypress="dragging-icon"
                        >
                            <Icon
                                className={classNames(
                                    'fas fa-grip-lines',
                                    styles.dragIcon,
                                )}
                            />
                        </div>
                    </ViewWrapper>
                </div>
            )}
        </Draggable>
    );
};

export default memo(Floor);
