// createSlice allows you to mutate the state object
/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */

import type { RootState } from '@infogrid/core-ducks';
import { getSensor } from '@infogrid/sensors-configuration';
import {
    MANUAL_SYNC_VENDORS,
    SENSOR_TYPE,
    SUPPORTED_DEVICES,
} from '@infogrid/sensors-constants';
import type { SensorType, PlannedSensor, SensorShape } from '@infogrid/sensors-constants';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, createSelector } from '@reduxjs/toolkit';
import type { AxiosResponse } from 'axios';

import type {
    DeviceConfiguration,
    InstallSensorErrorResponse,
} from 'apiHooks/sensors/installation/hooks';
import type { Breadcrumbs } from 'utils/errors/installErrors';
import {
    getInstallError,
    getAlreadyInstalledError,
    INSTALL_ERRORS,
} from 'utils/errors/installErrors';

export const INSTALL_STEPS = {
    BARCODE_SCAN: 'BARCODE_SCAN',
    INSTALL_PENDING: 'INSTALL_PENDING',
    INSTALL_SUCCESS: 'INSTALL_SUCCESS',
    INSTALL_ERROR: 'INSTALL_ERROR',
    MANUAL_IDENTIFY: 'MANUAL_IDENTIFY',
    SENSOR_OFFLINE: 'SENSOR_OFFLINE',
    OFFLINE_CONFIRMATION: 'OFFLINE_CONFIRMATION',
    CONFIGURE_DEVICE: 'CONFIGURE_DEVICE',
} as const;

export type InstallStep = typeof INSTALL_STEPS[keyof typeof INSTALL_STEPS];

export const INSTALL_STEPS_CLOSE_DISABLED: InstallStep[] = [
    INSTALL_STEPS.INSTALL_PENDING,
];

const CONFIGURABLE_TYPES: SensorType[] = [SENSOR_TYPE.TYPE_LEGIONNAIRE];

export const INSTALL_STEPS_CLOSE_CONFIRMATION: InstallStep[] = [
    INSTALL_STEPS.INSTALL_PENDING,
    INSTALL_STEPS.INSTALL_ERROR,
    INSTALL_STEPS.MANUAL_IDENTIFY,
    INSTALL_STEPS.SENSOR_OFFLINE,
    INSTALL_STEPS.OFFLINE_CONFIRMATION,
];

export interface InstallState {
    isOpen: boolean;
    step: InstallStep;
    plannedSensor: PlannedSensor | null;
    identifiedSensor: SensorShape | null;
    installedSensor: SensorShape | null;
    installError: InstallSensorErrorResponse | null;
    deviceConfiguration: DeviceConfiguration | undefined;
}

export const initialState: InstallState = {
    isOpen: false,
    step: INSTALL_STEPS.BARCODE_SCAN,
    plannedSensor: null,
    identifiedSensor: null,
    installedSensor: null,
    installError: null,
    deviceConfiguration: undefined,
};

const { actions, reducer } = createSlice({
    name: 'installFlow',
    initialState,
    reducers: {
        openInstallFlow: (state, action: PayloadAction<PlannedSensor>) => {
            state.isOpen = true;
            state.step = INSTALL_STEPS.BARCODE_SCAN;
            state.plannedSensor = action.payload;
        },
        closeInstallFlow: (state) => {
            state.isOpen = false;
        },
        goToStep: (state, action: PayloadAction<InstallStep>) => {
            state.step = action.payload;
        },
        onSensorIdentified: (state, action: PayloadAction<SensorShape>) => {
            const sensor = getSensor(action.payload);

            if (!state.plannedSensor) {
                console.error(
                    'Attempting to identify a sensor without a selected planned sensor',
                );

                return;
            }

            // Overwrite some of the identified sensor info with the
            // planned sensor info, so it appears correctly in the UI
            state.identifiedSensor = {
                ...action.payload,
                type_code: state.plannedSensor.device_type.name,
                floorplan_location: state.plannedSensor?.location,
                profile: {
                    ...action.payload.profile,
                    name: state.plannedSensor.name,
                },
            };

            const shouldShowOfflineStatus = !MANUAL_SYNC_VENDORS.includes(
                action.payload.vendor,
            );

            const supportedDevices =
                SUPPORTED_DEVICES[state.plannedSensor.device_type.name];

            if (sensor.sensorData.installation_time) {
                state.installError = sensor.sensorData.breadcrumbs
                    ? getAlreadyInstalledError(
                          sensor.sensorData.breadcrumbs as Breadcrumbs,
                      )
                    : null;
                state.step = INSTALL_STEPS.INSTALL_ERROR;
            } else if (!supportedDevices?.includes(action.payload.type_code)) {
                state.installError = INSTALL_ERRORS.device_match_error;
                state.step = INSTALL_STEPS.INSTALL_ERROR;
            } else if (!sensor.getIsOnline() && shouldShowOfflineStatus) {
                state.step = INSTALL_STEPS.SENSOR_OFFLINE;
            } else if (
                CONFIGURABLE_TYPES.includes(state.plannedSensor.device_type.name)
            ) {
                state.step = INSTALL_STEPS.CONFIGURE_DEVICE;
            } else {
                state.step = INSTALL_STEPS.INSTALL_PENDING;
            }
        },
        onSensorInstalled: (state, action: PayloadAction<SensorShape>) => {
            state.installedSensor = action.payload;
            state.step = INSTALL_STEPS.INSTALL_SUCCESS;
        },
        handleInstallError: (
            state,
            action: PayloadAction<Partial<AxiosResponse> | undefined>,
        ) => {
            state.installError = getInstallError(action.payload);
            state.step = INSTALL_STEPS.INSTALL_ERROR;
        },
        clearInstallError: (state) => {
            state.installError = null;
        },
        saveDeviceConfiguration: (state, action: PayloadAction<DeviceConfiguration>) => {
            state.deviceConfiguration = action.payload;
            state.step = INSTALL_STEPS.INSTALL_PENDING;
        },
        handleOfflineConfirmed: (state) => {
            if (
                state.identifiedSensor &&
                CONFIGURABLE_TYPES.includes(state.identifiedSensor.type_code)
            ) {
                state.step = INSTALL_STEPS.CONFIGURE_DEVICE;
            } else {
                state.step = INSTALL_STEPS.INSTALL_PENDING;
            }
        },
    },
});

export default reducer;

export const {
    openInstallFlow,
    closeInstallFlow,
    goToStep,
    onSensorIdentified,
    onSensorInstalled,
    handleInstallError,
    clearInstallError,
    saveDeviceConfiguration,
    handleOfflineConfirmed,
} = actions;

export const selectStore = (state: RootState): InstallState => state.installFlow;
export const selectIsOpen = createSelector(selectStore, (state) => state.isOpen);
export const selectStep = createSelector(selectStore, (state) => state.step);
export const selectPlannedSensor = createSelector(
    selectStore,
    (state) => state.plannedSensor,
);
export const selectIdentifiedSensor = createSelector(
    selectStore,
    (state) => state.identifiedSensor,
);
export const selectInstalledSensor = createSelector(
    selectStore,
    (state) => state.installedSensor,
);
export const selectInstallError = createSelector(
    selectStore,
    ({ installError }) => installError,
);
export const selectDeviceConfiguration = createSelector(
    selectStore,
    ({ deviceConfiguration }) => deviceConfiguration,
);
