import { Modal } from '@infogrid/components-material-ui';
import type { Floor, BuildingDetail, Space } from '@infogrid/locations-types';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button } from '@material-ui/core';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MoveSensors from './MoveSensors';
import type { MoveSensorsProps } from './MoveSensors/MoveSensors';
import { useMoveSensorsModalStyles } from './styles';

interface Props {
    buildingId: number;
    floorId: number;
    onClose: () => void;
    onMoveSensors: (buildingId: number, floorId: number, spaceId: number | null) => void;
    open: boolean;
    originSpaceId?: number;
    sensors: MoveSensorsProps['sensors'];
}

const MoveSensorsModal = ({
    buildingId,
    floorId,
    onClose,
    onMoveSensors,
    open,
    originSpaceId,
    sensors,
}: Props) => {
    const styles = useMoveSensorsModalStyles();

    const { t } = useTranslation();

    const [selectedBuilding, setSelectedBuilding] = useState<
        BuildingDetail | null | undefined
    >();
    const [selectedFloor, setSelectedFloor] = useState<Floor | null | undefined>();
    const [selectedSpace, setSelectedSpace] = useState<Space | null | undefined>();

    const isMobile = useIsMobile();

    const onMoveClick = useCallback(() => {
        if (selectedBuilding && selectedFloor && selectedSpace !== undefined) {
            onMoveSensors(
                selectedBuilding.id,
                selectedFloor.id,
                selectedSpace?.id || null,
            );
        }
    }, [onMoveSensors, selectedBuilding, selectedFloor, selectedSpace]);

    return (
        <Modal fullScreen={isMobile} onClose={onClose} open={open}>
            <Modal.Title>
                {t('Move {{count}} sensors to...', {
                    count: sensors.length,
                    defaultValue___one: `Move ${sensors.length} sensor to...`,
                    defaultValue___other: `Move ${sensors.length} sensors to...`,
                })}
            </Modal.Title>
            <MoveSensors
                buildingId={buildingId}
                floorId={floorId}
                originSpaceId={originSpaceId}
                selectedBuilding={selectedBuilding}
                selectedFloor={selectedFloor}
                selectedSpace={selectedSpace}
                sensors={sensors}
                setSelectedBuilding={setSelectedBuilding}
                setSelectedFloor={setSelectedFloor}
                setSelectedSpace={setSelectedSpace}
            />
            <Modal.Actions className={styles.modalActions} justify="space-between">
                <Button
                    className={styles.cancelButton}
                    color="primary"
                    data-cypress="cancel-moving"
                    onClick={onClose}
                >
                    {t('Cancel')}
                </Button>
                <Button
                    color="primary"
                    data-cypress="move-sensors"
                    disabled={selectedSpace === undefined}
                    onClick={onMoveClick}
                    variant="contained"
                >
                    {t('Move')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(MoveSensorsModal);
