import { getAirQualityOverallRating } from '@infogrid/integrations-airthings';
import type {
    AirQualityReadings,
    AirQualitySensorEventsV2Shape,
} from '@infogrid/sensors-constants';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface AirQualityLastReadingValueProps {
    events: AirQualitySensorEventsV2Shape;
    availableReadings: AirQualityReadings[];
    className?: string;
}

const AirQualityLastReadingValue = ({
    events,
    availableReadings,
    className = 'tw-mr-2',
}: AirQualityLastReadingValueProps): JSX.Element => {
    const { t } = useTranslation('sensor-events');
    const rating = getAirQualityOverallRating(events, availableReadings);

    return (
        <div className="tw-flex">
            <div
                style={{ backgroundColor: rating.colorMain }}
                className={classNames(
                    'airthings-reading-dot tw-mt-auto tw-mb-auto',
                    className,
                )}
            />
            <div>{t(rating.text)}</div>
        </div>
    );
};

export default AirQualityLastReadingValue;
