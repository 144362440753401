import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { TIME_RANGE_OPTIONS } from '@infogrid/utils-dates';
import { isWithinRange } from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';
import { useMemo } from 'react';

export const SENSOR_ID_FIELD = 'device_name';

export const AutoUpdateEventGraph = [
    TIME_RANGE_OPTIONS.LAST_HOUR,
    TIME_RANGE_OPTIONS.LAST_DAY,
];

/**
 * Calculates whether the given timestamp falls into the timeRange or not
 * @param timestamp
 * @param timeZone
 * @param timeRange
 * @returns {boolean}
 */
const isOutsideRange = (timestamp, timeZone, timeRange) => {
    let outsideRange = false;

    if (timestamp) {
        let timeZonedTimeStamp;

        if (timeRange.range === 'custom') {
            timeZonedTimeStamp = convertToTimeZone(new Date(timestamp), {
                timeZone,
            }).getTime();
        } else {
            // The other types of timeranges do not require timezone information
            timeZonedTimeStamp = new Date(timestamp).getTime();
        }

        outsideRange = !isWithinRange(timeZonedTimeStamp, timeRange.from, timeRange.to);
    }

    return outsideRange;
};

export const formatSensorEvent = (event, timeZone, timeRange) => ({
    ...event,
    timestamp: event.timestamp ? new Date(event.timestamp).getTime() : null,
    outsideRange: isOutsideRange(event.timestamp, timeZone, timeRange),
});

/**
 * Puts a list of sensors into an object where they key is the sensor type
 * and value is a list of selected sensors with that type
 * @returns {{}}
 */
export const getSensorsByType = (sensorData, sensorIds) =>
    sensorIds.reduce((result, sensorId) => {
        const sensor = sensorData[sensorId];
        const sensorType = sensor?.type_code;

        if (sensorType) {
            if (Array.isArray(result[sensorType])) {
                result[sensorType].push(sensorId);
            } else {
                // eslint-disable-next-line no-param-reassign
                result[sensorType] = [sensorId];
            }
        }

        return result;
    }, {});

export const getSensorTypeFilterTypes = (sensorTypeFilter) => {
    if (sensorTypeFilter === undefined) {
        return [];
    } else if (typeof sensorTypeFilter === 'string') {
        return [sensorTypeFilter];
    } else if (Array.isArray(sensorTypeFilter)) {
        return sensorTypeFilter;
    }

    throw TypeError(
        'sensorTypeFilter must be undefined, string or an array. ' +
            `Not ${typeof sensorTypeFilter} ${sensorTypeFilter}`,
    );
};

export const useSensorTypeFilter = (sensorTypeFilter) => {
    const sensorTypes = useMemo(
        () => getSensorTypeFilterTypes(sensorTypeFilter),
        [sensorTypeFilter],
    );
    const sensorQuery = useMemo(
        () =>
            sensorTypes.length > 0
                ? {
                      // Sorting to keep this deterministic
                      sensor_type: sensorTypes.sort().join(','),
                  }
                : {},
        [sensorTypes],
    );

    return {
        sensorTypes,
        sensorQuery,
    };
};

export const useSensorSubTypeFilter = (sensorSubTypeFilter) => {
    return useMemo(() => {
        return !!sensorSubTypeFilter && sensorSubTypeFilter.length > 0
            ? {
                  sub_type: sensorSubTypeFilter,
              }
            : {};
    }, [sensorSubTypeFilter]);
};

export const useSensorLabelsFilter = (labelsFilter) => {
    return useMemo(() => {
        return !!labelsFilter && labelsFilter.length > 0
            ? {
                  sensor_labels_or: labelsFilter,
              }
            : {};
    }, [labelsFilter]);
};

export const useSensorReadingTypesFilter = (readingTypesFilter) => {
    return useMemo(() => {
        return !!readingTypesFilter && readingTypesFilter.length > 0
            ? {
                  reading_types: readingTypesFilter,
              }
            : {};
    }, [readingTypesFilter]);
};

export const getSensorName = (sensor) => sensor?.profile?.name || sensor.device_name;

const VIRTUAL_SENSOR_TYPES = [
    SENSOR_TYPE.TYPE_AVERAGE_TEMPERATURE,
    SENSOR_TYPE.TYPE_SPACE,
];

export const isSensorVirtual = (sensor) =>
    VIRTUAL_SENSOR_TYPES.includes(sensor?.type_code);
