import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { DEFAULT_CT_CLAMP_VOLTAGE } from './constants';
import { monnitConfigurationFormValidationSchema } from './monnitConfigurationFormValidationSchema';
import type { MonnitConfigurationFormValues } from './types';

export const useMonnitConfigurationFormik = ({
    onSubmit,
}: {
    onSubmit: (formValues: MonnitConfigurationFormValues) => void;
}): FormikContextType<MonnitConfigurationFormValues> => {
    const { t } = useTranslation('sensors');

    const initialValues: MonnitConfigurationFormValues = {
        monitoring_locations: [],
        end_use: undefined,
        voltage: DEFAULT_CT_CLAMP_VOLTAGE,
    };

    return useFormik<MonnitConfigurationFormValues>({
        initialValues,
        enableReinitialize: true,
        validationSchema: monnitConfigurationFormValidationSchema(t),
        onSubmit,
    });
};
