import { memo, useCallback, useMemo } from 'react';

import { useFeedbackPanelTemplates } from 'apiHooks/sensors/feedbackPanels/hooks/useFeedbackPanelTemplates';
import { selectFeedbackPanelTemplates } from 'apiHooks/sensors/feedbackPanels/selectors';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from 'components/InstallationFlow/reducer';

import FeedbackPanelTemplateSelector from './FeedbackPanelTemplateSelector';

const FeedbackPanelTemplateSelectorContainer = () => {
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    const {
        data: feedbackPanelTemplatesResponse,
        isLoading,
        hasNextPage,
        fetchNextPage,
    } = useFeedbackPanelTemplates();
    const { feedbackPanelTemplates } = useMemo(
        () => selectFeedbackPanelTemplates(feedbackPanelTemplatesResponse),
        [feedbackPanelTemplatesResponse],
    );

    const onSelect = useCallback(
        (feedbackPanelTemplate) => {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.SELECT_FEEDBACK_PANEL_TEMPLATE,
                feedbackPanelTemplate,
            });
        },
        [dispatchInstallationFlowContext],
    );

    const goBack = useCallback(
        () =>
            dispatchInstallationFlowContext({ type: INSTALLATION_FLOW_ACTIONS.GO_BACK }),
        [dispatchInstallationFlowContext],
    );

    return (
        <FeedbackPanelTemplateSelector
            isLoading={isLoading}
            feedbackPanelTemplates={feedbackPanelTemplates}
            hasMore={hasNextPage ?? false}
            onFetchMore={fetchNextPage}
            onSelect={onSelect}
            onPrevious={goBack}
        />
    );
};

export default memo(FeedbackPanelTemplateSelectorContainer);
