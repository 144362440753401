import qs from 'qs';

import type { AirQualityWidgetTab, EventSeriesPoint, SeriesPoint } from '../types';

export const tooltipPointColorExtractor = (point: SeriesPoint): string => point.color;

export const tooltipPointNameExtractor = (point: SeriesPoint): string =>
    point.series.name;

export const tooltipPointDisplayNameExtractor = (point: SeriesPoint): string =>
    point.series.options.displayName;

export const generateFilteredSensorsUrl = ({
    baseUrl,
    widgetId,
    rating,
    timestamp,
    airType,
}: {
    baseUrl: string;
    widgetId: number;
    rating: string;
    timestamp: string;
    airType: string | undefined;
}): string => {
    const query = qs.stringify({
        iaq_widget_id: widgetId,
        iaq_rating: rating.toUpperCase(),
        iaq_timestamp: timestamp,
        iaq_air_type: airType,
    });

    return `${baseUrl}/sensors?${query}`;
};

export const openPointInNewTab = (
    point: EventSeriesPoint,
    widgetId: number,
    tabId: AirQualityWidgetTab,
): void => {
    const {
        index,
        series: {
            name,
            options: { timestamps },
        },
    } = point;

    const link = generateFilteredSensorsUrl({
        // eslint-disable-next-line no-restricted-globals
        baseUrl: location.origin,
        widgetId,
        rating: name,
        timestamp: timestamps[index],
        airType: tabId === 'overall' ? undefined : tabId,
    });

    window.open(link, '_blank');
};
