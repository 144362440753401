import { makeStyles } from '@material-ui/core';

export const useDeletingFloorStyles = makeStyles((theme) => ({
    container: {
        width: '450px',
    },
    spinner: {
        color: theme.palette.common.white,
        marginRight: '10px',
    },
    cancelButton: {
        color: theme.palette.common.gray1,
    },
}));
