import { FormHelperText, Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthMethod } from './eventsWebhookAuthMethods';

interface Props {
    className?: string;
    value: boolean;
}

const WebhookAuthMethodHelpText = ({ className, value }: Props) => {
    const { t } = useTranslation('integrations');

    const authMethod = useAuthMethod(value);

    if (authMethod === undefined) {
        return null;
    }

    return (
        <FormHelperText
            className={className}
            data-testid={`events-webhook-auth-method-help-text-${value}`}
        >
            <Typography variant="caption">{t(authMethod.helpText)}</Typography>
        </FormHelperText>
    );
};

export default memo(WebhookAuthMethodHelpText);
