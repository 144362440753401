import { ConfirmModal } from '@infogrid/components-material-ui';
import { Typography } from '@material-ui/core';
import type { MouseEvent } from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const modalActionsProps = { justify: 'space-between' };

export interface DeleteDashboardModalProps {
    dashboardName: string;
    open: boolean;
    onClose: () => void;
    onConfirm: (e: MouseEvent<HTMLButtonElement>) => void;
    isSubmitting: boolean;
}

const DeleteDashboardModal = ({
    dashboardName,
    open,
    onClose,
    onConfirm,
    isSubmitting,
}: DeleteDashboardModalProps) => {
    const { t } = useTranslation('dashboard');

    const content = useMemo(
        () => (
            <>
                <Typography color="secondary" variant="body1">
                    {t('You are about to delete {{dashboardName}} dashboard.', {
                        dashboardName,
                        defaultValue: `You are about to delete ${dashboardName} dashboard.`,
                    })}
                </Typography>
                <Typography color="textSecondary">
                    {t('This action cannot be undone.')}
                </Typography>
                <Typography color="textSecondary">
                    {t('Are you sure you want to continue?')}
                </Typography>
            </>
        ),
        [t, dashboardName],
    );

    const confirmButtonProps = useMemo(
        () => ({ color: 'secondary', disabled: isSubmitting }),
        [isSubmitting],
    );

    return (
        <ConfirmModal
            fullWidth
            maxWidth="xs"
            title={t('Delete Dashboard')}
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            content={content}
            modalActionsProps={modalActionsProps}
            confirmButtonProps={confirmButtonProps}
            confirmButtonText={
                isSubmitting
                    ? t('Processing', { ns: 'common' })
                    : t('Confirm', { ns: 'common' })
            }
        />
    );
};

export default memo(DeleteDashboardModal);
