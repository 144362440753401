import { makeStyles } from '@material-ui/core';

export const useDeleteSpaceModalStyles = makeStyles(() => ({
    modalContent: {
        maxWidth: 600,
    },
    message: {
        letterSpacing: '0.15px',
    },
}));
