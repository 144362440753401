import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { useStylesButtonSpinner } from './styles';

const ButtonSpinner = memo(({ className, color, size }) => {
    const styles = useStylesButtonSpinner();

    const classNameValue = classNames(className, styles.container, {
        [styles.primary]: color === 'primary',
        [styles.default]: color === 'default',
    });

    return <CircularProgress className={classNameValue} size={size} />;
});

ButtonSpinner.propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'default']),
    size: PropTypes.number,
};

ButtonSpinner.defaultProps = {
    className: '',
    size: 22,
    color: 'default',
};

export default ButtonSpinner;
