import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { PlannedSensor } from '@infogrid/sensors-constants';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import type { UseQueryOptions, UseQueryResult } from 'react-query';

import { controllers } from '../controllers';
import { getPlannedSensorsKey } from '../getQueryKeys';

const DEFAULT_PLANNED_SENSORS_DATA: PlannedSensor[] = [];

export interface PlannedSensorsData<T = PlannedSensor> {
    results: T[];
    count: number;
}

interface UsePlannedSensorsResult<T extends PlannedSensor>
    extends Omit<UseQueryResult<PlannedSensorsData, AxiosParsedError>, 'data'> {
    sensors: T[];
}

export const usePlannedSensors = <T extends PlannedSensor>(
    floorId?: number,
    options?: UseQueryOptions<
        PlannedSensorsData,
        AxiosParsedError,
        PlannedSensorsData<T>
    >,
): UsePlannedSensorsResult<T> => {
    const query = useQuery(
        getPlannedSensorsKey({ floorId }),
        ({ signal }) =>
            wrapResponsePromise(
                controllers.getPlannedSensors({
                    // Will be number if enabled
                    floorId: floorId as number,
                    signal,
                }),
            ),
        {
            enabled: !!floorId,
            ...options,
        },
    );

    return useMemo(
        () => ({
            ...query,
            // Assignment here because, even though it's an empty array, we need to type it and then override
            // the typing with the generic argument. It's important that we use a constant for the fallback
            // here to prevent dependants from rerendering repeatedly.
            sensors: query.data?.results || (DEFAULT_PLANNED_SENSORS_DATA as T[]),
        }),
        [query],
    );
};
