import { useAppSelector } from '@infogrid/core-ducks';
import { selectUserIsManager } from '@infogrid/user-ducks';
import classNames from 'classnames';
import { memo, useCallback, useMemo } from 'react';

import { useStylesPermissionsWrapper } from './styles';

interface Props {
    isVisible?: boolean;
    children?: React.ReactNode;
    className?: string;
    fallback?: React.ReactNode;
}

const PermissionsWrapper = ({
    children,
    className = '',
    fallback,
    isVisible = false,
}: Props): JSX.Element | null => {
    const isManager = useAppSelector(selectUserIsManager);

    const childrenProps = useMemo(() => ({ isAvailable: isManager }), [isManager]);

    const renderChildren = useCallback(() => {
        if (typeof children === 'function') {
            return children(childrenProps);
        }

        return children;
    }, [children, childrenProps]);

    const styles = useStylesPermissionsWrapper({ isManager });
    const classNameValue = classNames(styles.container, className);

    if (isManager) {
        return <div className={classNameValue}>{renderChildren()}</div>;
    }

    if (isVisible) {
        return <div className={classNameValue}>{renderChildren()}</div>;
    }

    if (fallback) {
        return <div className={classNameValue}>{fallback}</div>;
    }

    return null;
};

export const selectIsAvailable = selectUserIsManager;

export default memo(PermissionsWrapper);
