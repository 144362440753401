import { makeStyles } from '@material-ui/core';

const SECONDARY_TEXT = '#918D85';
const TOOLTIP_BACKGROUND_COLOR = '#333745';

export const useStylesFloor = makeStyles((theme) => ({
    floor: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(1.5, 0.25),
        backgroundColor: theme.palette.common.gray5,
        borderBottom: `2px solid ${theme.palette.common.shell}`,
        '&:hover': {
            backgroundColor: '#FFF',
        },
    },
    activeFloor: {
        backgroundColor: '#FFF',
    },
    floorSquareIcon: {
        fontSize: '20px',
        transform: 'rotate(45deg)',
        opacity: '0.7',
        lineHeight: '1.3em',
        height: '1.3em',
        color: SECONDARY_TEXT,
    },
    floorName: {
        marginTop: theme.spacing(1),
        maxWidth: '50px',
        color: '#918D85',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

export const useStylesTooltip = makeStyles((theme) => ({
    tooltip: {
        padding: theme.spacing(0.75, 1.5),
        backgroundColor: TOOLTIP_BACKGROUND_COLOR,
    },
}));
