import { Icon, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSolutionSettingsStyles } from './styles';

const SolutionSettingsIndex = (): JSX.Element => {
    const styles = useSolutionSettingsStyles();

    const { t } = useTranslation('solutions');

    return (
        <div className={styles.container}>
            <Icon className={classNames('fal fa-chart-line', styles.icon)} />
            <Typography className={styles.label} variant="subtitle2">
                {t('Select a solution to manage')}
            </Typography>
        </div>
    );
};

export default memo(SolutionSettingsIndex);
