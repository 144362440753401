import '@tg-resources/fetch-runtime';
import { routesManager } from '@infogrid/core-routing';
import { HomePage } from '@infogrid/home-page-view';
import loadable from '@loadable/component';
import type { RouteConfig } from 'react-router-config';

import App from 'containers/AppShell';
import { userIsAuthenticated } from 'decorators/auth';
import agreeToTermsWatcherSaga from 'sagas/account/agreeToTerms';
import { fetchActiveTermsWorker } from 'sagas/account/fetchActiveTerms';
import { fetchTimeZonesWorker } from 'sagas/account/fetchTimeZones';
import saveProfileWatcherSaga from 'sagas/account/saveProfile';
import { loadConstance } from 'sagas/application/constance';
import fileDownloadWatcher from 'sagas/application/signedDownloads';
import obtainTokenWatcher from 'sagas/auth/obtainTokenSaga';
import { authRequired } from 'sagas/helpers/initialSaga';
import networkConnectionWatcher from 'sagas/network/updateConnection';
import pollSagaWatcher from 'sagas/pollSaga';
import fetchSolutions from 'sagas/solutions/fetchSolutions';
import fetchUserDetails from 'sagas/user/fetchUserDetails';
import PageNotFound from 'views/PageNotFound';
import PermissionDenied from 'views/PermissionDenied';
import { withWelcomeRedirect } from 'views/welcome/withWelcomeRedirect';

import {
    createAlertRoutes,
    createAcknowledgeRoutes,
    createAlertConfigurationRoutes,
} from './routes/alerts';
import { createAuthenticationRoutes } from './routes/authentication';
import { createBuildingRoutes } from './routes/buildings';
import { createDashboardRoutes } from './routes/dashboards';
import { createEstateRoutes } from './routes/estate';
import { createFolderRoutes } from './routes/folders';
import { createIntegrationsRoutes } from './routes/integrations';
import { createOrganizationRoutes } from './routes/organization';
import { createPublicDisplaysRoutes } from './routes/publicDisplays';
import { createReportRoutes } from './routes/reports';
import { createSensorRoutes } from './routes/sensors';
import { createSettingsRoutes } from './routes/settings';
import { createSitesRoutes } from './routes/sites';
import { createSolutionSettingsRoutes } from './routes/solutionSettings';
import { createSolutionsRoutes } from './routes/solutions';

// Group account views into single chunk
const ProfileView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-accounts-profile' */ 'views/accounts/pages/profile'
        ),
);
const TermsView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-accounts-terms' */ 'views/accounts/pages/terms'
        ),
);

const PageNotFoundRoute = {
    path: '*',
    name: '404',
    component: PageNotFound,
};

const routes: RouteConfig[] = [
    {
        component: App,
        initial: [fetchUserDetails, loadConstance, fetchSolutions],
        watcher: [
            fileDownloadWatcher,
            obtainTokenWatcher,
            pollSagaWatcher,
            networkConnectionWatcher,
        ],
        routes: [
            {
                path: '/',
                exact: true,
                name: 'landing',
                component: withWelcomeRedirect(userIsAuthenticated(HomePage)),
            },
            {
                path: '/profile/',
                exact: true,
                name: 'profile',
                component: userIsAuthenticated(ProfileView),
                pageName: 'Profile',
                initial: authRequired(fetchTimeZonesWorker),
                watcher: saveProfileWatcherSaga,
            },
            {
                path: '/terms/',
                exact: true,
                name: 'terms',
                pageName: 'Terms',
                initial: authRequired(fetchActiveTermsWorker),
                watcher: agreeToTermsWatcherSaga,
                component: userIsAuthenticated(TermsView),
            },
            createOrganizationRoutes(PageNotFoundRoute),
            createFolderRoutes(PageNotFoundRoute),
            createSensorRoutes(PageNotFoundRoute),
            createDashboardRoutes(PageNotFoundRoute),
            createIntegrationsRoutes(PageNotFoundRoute),
            createSolutionSettingsRoutes(PageNotFoundRoute),
            createSettingsRoutes(PageNotFoundRoute),
            createEstateRoutes(PageNotFoundRoute),
            createSolutionsRoutes(PageNotFoundRoute),
            createReportRoutes(PageNotFoundRoute),
            createAuthenticationRoutes(PageNotFoundRoute),
            createBuildingRoutes(PageNotFoundRoute),
            createAlertRoutes(PageNotFoundRoute),
            createAcknowledgeRoutes(PageNotFoundRoute),
            createPublicDisplaysRoutes(PageNotFoundRoute),
            createSitesRoutes(PageNotFoundRoute),
            createAlertConfigurationRoutes(),
            {
                path: '/permission-denied/',
                exact: true,
                name: 'permission-denied',
                component: PermissionDenied,
            },
            PageNotFoundRoute,
        ],
    },
];

export function configureRoutes(): void {
    routesManager.addRoutes(routes);
}
