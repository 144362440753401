import { all, call } from 'redux-saga/effects';

import { getRequestToken } from 'sagas/helpers/token';

export const runParallel = (sagas) =>
    function* initialParallel(match) {
        yield all(sagas.map((saga) => call(saga, match)));
    };

export const authRequired = (...args) => {
    return function* validation(...params) {
        const token = yield call(getRequestToken, true);

        if (token) {
            const sagas = [].concat(...args); // flattens sagas a single level deep.

            /* eslint-disable no-restricted-syntax */
            for (const saga of sagas) {
                yield call(saga, ...params);
            }
            /* eslint-enable */
        }
    };
};
