import {
    invalidateBuilding,
    invalidateBuildings,
    removeBuildingsQueries,
} from 'apiHooks/floorPlan/buildings/accessors';
import {
    invalidateFloorDetail,
    invalidateFloors,
    removeFloorsQuery,
    removeFloorDetailQuery,
} from 'apiHooks/floorPlan/floors/accessors';
import {
    invalidateFloorSensors,
    removeFloorSensors,
} from 'apiHooks/floorPlan/sensors/accessors';
import {
    invalidateSpace,
    invalidateSpaceSensors,
    invalidateSpaces,
} from 'apiHooks/floorPlan/spaces/accessors';

interface PopulateMoveSensorsParams {
    buildingId: number;
    floorId: number;
    selectedBuildingId: number;
    selectedFloorId: number;
    selectedSpaceId: number | null;
}

/**
 * INFO: this function is not very efficient way
 * to propagate the data properly but enough for
 * the beginning, after Cypress tests for
 * Move Sensors functionality is done we should
 * consider optimizing it
 */
export const populateMoveSensorsData = ({
    buildingId,
    floorId,
    selectedBuildingId,
    selectedFloorId,
    selectedSpaceId,
}: PopulateMoveSensorsParams): void => {
    invalidateBuilding({ buildingId: selectedBuildingId });
    invalidateFloors(buildingId);

    invalidateFloorDetail(floorId);

    if (floorId !== selectedFloorId) {
        removeFloorDetailQuery(selectedFloorId);
        invalidateFloorDetail(selectedFloorId);
    }

    removeFloorSensors({ floorId });
    invalidateFloorSensors({ floorId });
    removeFloorSensors({ floorId: selectedFloorId });
    invalidateFloorSensors({ floorId: selectedFloorId });

    if (selectedFloorId === floorId) {
        invalidateSpaces({
            floor: floorId,
            'include-user-actions': true,
        });
    } else {
        invalidateSpaces({
            floor: selectedFloorId,
            'include-user-actions': true,
        });
    }

    if (selectedSpaceId) {
        invalidateSpace(selectedSpaceId);
        invalidateSpaceSensors(selectedSpaceId);
    }

    if (selectedBuildingId !== buildingId) {
        invalidateBuildings({ 'include-user-actions': true });
        removeBuildingsQueries({ 'include-user-actions': true });
        invalidateFloors(selectedBuildingId);
        removeFloorsQuery(selectedBuildingId);
    }
};
