import { registerTranslationKey } from '@infogrid/utils-i18n';

export const Headers = [
    {
        id: 'total-sensors',
        label: registerTranslationKey('Total sensors', { ns: 'dashboard' }),
    },
    {
        id: 'total-fail-count',
        label: registerTranslationKey('Total fail count', { ns: 'dashboard' }),
    },
    {
        id: 'fail-percent',
        label: registerTranslationKey('Fail percent', { ns: 'dashboard' }),
    },
    {
        id: 'water-movement-fail-count',
        label: registerTranslationKey('Water movement fail count', { ns: 'dashboard' }),
        tooltip: registerTranslationKey(
            'Pipe monitoring sensors are able to detect the movement of water within a pipe. If no water has moved through a pipe it will show here as a fail. This could be an indication that a pipe has not been used and may require manual flushing',
            { ns: 'dashboard' },
        ),
    },
    {
        id: 'temp-fail-count',
        label: registerTranslationKey('Temp fail count', { ns: 'dashboard' }),
    },
];
