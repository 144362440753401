import { useAppSelector } from '@infogrid/core-ducks';
import { selectActiveTimeZone } from '@infogrid/user-ducks';
import { useFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import { memo, useCallback, useEffect, useMemo } from 'react';

import { useAutoPaginatedSensorList } from 'apiHooks/sensors/hooks';
import GenericConfigureWidgetModal from 'views/dashboards/components/modals/GenericConfigureWidgetModal';

import {
    configureWidgetModalProps,
    configureWidgetModalDefaultProps,
} from '../GenericConfigureWidgetModal/constants';
import { isConfigurationChanged } from './utils';

const ConfigureWidgetModal = ({
    initialValues: baseInitialValues,
    loading,
    onClose,
    onUpdateConfiguration,
    open,
    tabs,
    validationSchema,
}) => {
    const timezone = useAppSelector(selectActiveTimeZone);

    const initialValues = useMemo(
        () => ({
            ...baseInitialValues,
            dateRange: {
                ...baseInitialValues.dateRange,
                timezone: baseInitialValues.dateRange.timezone || timezone,
            },
        }),
        [baseInitialValues, timezone],
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
    });

    const periodDates = useMemo(() => {
        const { startDate, endDate, period } = formik?.values?.dateRange || {};

        return { startDate, endDate, period };
    }, [formik]);

    const dataFrequency = useMemo(
        () => formik?.values?.displayOptions?.dataFrequency,
        [formik],
    );

    useEffect(() => {
        const displayOptionsTab = tabs.find((tab) => tab.id === 'display-options');

        const { calculateDisabledFrequencies, frequencies } =
            displayOptionsTab?.attributes || {};

        if (calculateDisabledFrequencies) {
            const disabledFrequencies = calculateDisabledFrequencies(periodDates);

            const selectedAndDisabledFrequencyIndex = frequencies.findIndex(
                (freq) =>
                    freq.value === dataFrequency &&
                    disabledFrequencies.some((disabledFreq) =>
                        isEqual(freq, disabledFreq),
                    ),
            );

            if (selectedAndDisabledFrequencyIndex !== -1) {
                formik.setFieldValue(
                    'displayOptions.dataFrequency',
                    frequencies[selectedAndDisabledFrequencyIndex + 1]?.value,
                );
            }
        }
    }, [dataFrequency, periodDates, tabs, formik]);

    const {
        values: { folders, sensors },
    } = formik;

    const foldersOrSensorsSelected = useMemo(
        () => folders?.length > 0 || sensors?.length > 0,
        [folders, sensors],
    );

    const formValuesHaveChanged = useMemo(
        () => isConfigurationChanged(initialValues, formik?.values),
        [formik, initialValues],
    );

    const anyErrors = useMemo(() => {
        const { errors } = formik;

        return errors.maxAmountErrors?.length > 0 || errors?.minAmountErrors?.length > 0;
    }, [formik]);

    const savingDisabled = useMemo(
        () =>
            loading ||
            !foldersOrSensorsSelected ||
            !formik.isValid ||
            !formValuesHaveChanged ||
            ((!periodDates.startDate || !periodDates.endDate) && periodDates.period) ||
            anyErrors,
        [
            anyErrors,
            foldersOrSensorsSelected,
            formik.isValid,
            formValuesHaveChanged,
            loading,
            periodDates,
        ],
    );

    const {
        data: { sensors: selectedSensors },
    } = useAutoPaginatedSensorList(
        {
            device_names: sensors,
        },
        { enabled: sensors?.length > 0 },
    );

    const sensorsData = useMemo(
        () =>
            selectedSensors?.reduce(
                // This syntax is much faster than spread operator
                /* eslint-disable-next-line */
                (obj, item) => ((obj[item.device_name] = item), obj),
                {},
            ),
        [selectedSensors],
    );

    useEffect(() => {
        formik.setFieldValue('sensorsData', sensorsData);
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [sensors, sensorsData]);

    const onUpdate = useCallback(
        () => onUpdateConfiguration(formik.values, sensorsData),
        [formik.values, sensorsData, onUpdateConfiguration],
    );

    return (
        <GenericConfigureWidgetModal
            formik={formik}
            initialValues={initialValues}
            loading={loading}
            onClose={onClose}
            onUpdateConfiguration={onUpdate}
            open={open}
            tabs={tabs}
            formValuesHaveChanged={formValuesHaveChanged}
            savingDisabled={savingDisabled}
        />
    );
};

ConfigureWidgetModal.propTypes = configureWidgetModalProps;
ConfigureWidgetModal.defaultProps = configureWidgetModalDefaultProps;

export default memo(ConfigureWidgetModal);
