import { Modal } from '@infogrid/components-material-ui';
import { Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeletingFloorStyles } from './styles';

const DeleteFloorModal = memo(
    ({ handleSubmit, onClose, isOpened, floorName, handleCancel, isSubmitting }) => {
        const stylesDeletingFloor = useDeletingFloorStyles();

        const classes = useMemo(
            () => ({
                container: stylesDeletingFloor.container,
            }),
            [stylesDeletingFloor.container],
        );

        const { t } = useTranslation('floorplan');

        return (
            <Modal onClose={onClose} open={isOpened} classes={classes}>
                <form onSubmit={handleSubmit}>
                    <Modal.Title>{t('Delete Floor?')}</Modal.Title>

                    <Modal.Description>
                        <span>{t(`You are about to delete ${floorName} floor.`)}</span>
                        <span>
                            {t(
                                'This action cannot be undone. Are you sure you want to contiue?',
                            )}
                        </span>
                    </Modal.Description>

                    <Modal.Actions justify="space-between">
                        <Button
                            classes={{
                                root: stylesDeletingFloor.cancelButton,
                            }}
                            color="primary"
                            onClick={handleCancel}
                            data-cypress="delete-cancel"
                        >
                            {t('Cancel', { ns: 'common' })}
                        </Button>

                        <Button
                            color="secondary"
                            variant="contained"
                            type="submit"
                            data-cypress="delete-floor-btn"
                        >
                            {isSubmitting && (
                                <CircularProgress
                                    className={stylesDeletingFloor.spinner}
                                    size={22}
                                />
                            )}

                            <span>{t('Delete', { ns: 'common' })}</span>
                        </Button>
                    </Modal.Actions>
                </form>
            </Modal>
        );
    },
);

DeleteFloorModal.propTypes = {
    floorName: PropTypes.string,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
};

DeleteFloorModal.defaultProps = {
    floorName: null,
};

export default DeleteFloorModal;
