import { SENSOR_LIST_ACTION_FILTERS, SENSOR_PARAM_NAMES } from 'utils/filtering/sensor';

export const FOLDER_FILTERS = [
    SENSOR_PARAM_NAMES.QUERY,
    SENSOR_PARAM_NAMES.RECURSIVE,
] as const;

export const FOLDER_MOVE_PARAM_NAMES = {
    ...SENSOR_LIST_ACTION_FILTERS,
    MOVE_SENSORS: 'move_sensors',
    FOLDERS_TO_MOVE: 'folders_to_move',
} as const;

export const MOVE_FOLDER_PARAMS_SET = new Set(Object.values(FOLDER_MOVE_PARAM_NAMES));
