import { createDetailSchemaSelector, createSchemaSelector } from '@thorgate/spa-entities';
import { schema } from 'normalizr';

import { createFilteredKeyFn } from 'utils/relations';

export const teamsSchema = new schema.Entity('teams', {}, {});

export const teamsKeyFn = createFilteredKeyFn(teamsSchema.key);

export const selectTeams = createSchemaSelector(teamsSchema);
export const selectTeam = createDetailSchemaSelector(teamsSchema);
