import { useState, useContext, createContext } from 'react';

const ActiveSensorContext = createContext();

const ActiveSensorProvider = ({ children }) => {
    const [activeSensor, setActiveSensor] = useState('');
    const [isManuallySelected, setIsManuallySelected] = useState(false);

    const value = {
        activeSensor,
        setActiveSensor,
        isManuallySelected,
        setIsManuallySelected,
    };

    return (
        <ActiveSensorContext.Provider value={value}>
            {children}
        </ActiveSensorContext.Provider>
    );
};

const useActiveSensorContext = () => {
    const context = useContext(ActiveSensorContext);

    if (!context) {
        throw new Error(
            'useActiveSensorContext must be used within ActiveSensorProvider',
        );
    }

    return context;
};

export { ActiveSensorProvider, useActiveSensorContext };
