import { queryClient } from '@infogrid/core-api';
import isBefore from 'date-fns/is_before';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';

import { getSensorKey, getSensorListKey } from '../getQueryKeys';

export const getSensor = (id) => queryClient.getQueryData(getSensorKey(id));

export const invalidateSensor = (id) => queryClient.invalidateQueries(getSensorKey(id));
export const invalidateAllSensorLists = () =>
    queryClient.invalidateQueries(getSensorListKey());

export const setSensorUnacknowledgedAlertCount = (id, count) => {
    const sensor = getSensor(id);

    if (sensor && isNumber(count)) {
        const sensorKey = getSensorKey(id);

        queryClient.setQueryData(sensorKey, (old) => ({
            ...old,
            unacknowledged_alert_count: count,
        }));
    }
};

export const resetSensorUnacknowledgedAlertCount = (id) => {
    const sensor = getSensor(id);

    if (sensor) {
        const sensorKey = getSensorKey(id);

        queryClient.setQueryData(sensorKey, (old) => ({
            ...old,
            unacknowledged_alert_count: 0,
        }));
    }
};

export const setSensorBreadcrumbs = (id, breadcrumbs) => {
    const sensor = getSensor(id);

    if (sensor) {
        const sensorKey = getSensorKey(id);

        queryClient.setQueryData(sensorKey, (old) => ({
            ...old,
            breadcrumbs,
        }));
    }
};

export const setSensorFloorplanLocation = (id, breadcrumbs) => {
    const sensor = getSensor(id);

    if (sensor) {
        const sensorKey = getSensorKey(id);

        const floor = find(breadcrumbs, { folder_type: 'floor' });
        const building = find(breadcrumbs, { folder_type: 'building' });

        queryClient.setQueryData(sensorKey, (old) => ({
            ...old,
            floorplan_location: {
                ...(building && {
                    building_id: building.id,
                    building_name: building.name,
                }),
                ...(floor && { floor_id: floor.id, floor_name: floor.name }),
            },
        }));
    }
};

export const setSensor = (sensor, queryKey) => {
    queryClient.setQueryData(queryKey, (old) => {
        if (!old) {
            return old;
        }

        const isOlder = old.update_time && isBefore(sensor.update_time, old.update_time);

        if (isOlder) {
            return old;
        }

        return { ...old, ...sensor };
    });
};

export const setSensorsInPages = (sensorsMap, queryKey) => {
    queryClient.setQueryData(queryKey, (old) => {
        if (!old?.pages) {
            return old;
        }

        const newPages = old.pages.map((page) => {
            const sensors = (page?.sensors ?? []).map((sensor) => {
                const isOlder =
                    old.update_time && isBefore(sensor.update_time, old.update_time);

                if (isOlder) {
                    return sensor;
                }

                return sensorsMap[sensor.device_name]
                    ? {
                          ...sensor,
                          ...sensorsMap[sensor.device_name],
                      }
                    : sensor;
            });

            return { ...page, sensors };
        });

        return {
            ...old,
            pages: newPages,
        };
    });
};
