import { memo, useCallback } from 'react';

import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../../reducer';
import TemperatureOffsetHelp from './TemperatureOffsetHelp';

const TemperatureOffsetHelpContainer = () => {
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();
    const onBack = useCallback(() => {
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.SET_TEMPERATURE_OFFSET,
        });
    }, [dispatchInstallationFlowContext]);

    return <TemperatureOffsetHelp onBack={onBack} />;
};

export default memo(TemperatureOffsetHelpContainer);
