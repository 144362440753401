import { makeStyles } from '@material-ui/core';

export const useDeviceIdentifierStyles = makeStyles((theme) => ({
    imageContainer: {
        marginBottom: theme.spacing(2.25),
    },

    input: {
        marginBottom: theme.spacing(1.25),
    },
}));
