import { Icon, Tooltip, Typography, Button } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useNavbarButtonStyles } from './styles';

const textTransitionResolver = (transitionValue) => `opacity ${transitionValue}ms`;

const NavbarButton = ({
    icon,
    IconComponent,
    className,
    navbarOpened,
    onClick,
    text,
    transitionDuration,
}) => {
    const textTransition = textTransitionResolver(transitionDuration);
    const navbarButtonClasses = useNavbarButtonStyles({ textTransition });

    const rootClasses = classNames(
        navbarButtonClasses.root,
        navbarOpened && navbarButtonClasses.textVisible,
    );
    const iconClasses = classNames(icon, navbarButtonClasses.icon, className);

    const TooltipTitle = useMemo(
        () => (navbarOpened ? '' : <Typography variant="caption">{text}</Typography>),
        [text, navbarOpened],
    );

    return (
        <Tooltip title={TooltipTitle} placement="right">
            <Button
                className={rootClasses}
                classes={{ label: navbarButtonClasses.label }}
                onClick={onClick}
            >
                {IconComponent || (icon && <Icon className={iconClasses} />)}

                <span className={navbarButtonClasses.text}>{text}</span>
            </Button>
        </Tooltip>
    );
};

NavbarButton.propTypes = {
    icon: PropTypes.string,
    IconComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    className: PropTypes.string,
    navbarOpened: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
    transitionDuration: PropTypes.number.isRequired,
};

NavbarButton.defaultProps = {
    className: '',
    onClick: null,
    icon: '',
    IconComponent: null,
};

export default NavbarButton;
