import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import { cleanInactiveAlertConfigurationsQuery } from 'apiHooks/alertConfigurations/accessors';

import { controllers } from './controller';
import type { ResetTimesPayload } from './types';

export const useSetSensorResetTimes = (): UseMutationResult<
    ResetTimesPayload,
    AxiosParsedError,
    ResetTimesPayload
> => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('solutions');

    return useMutationWithToast<ResetTimesPayload, AxiosParsedError, ResetTimesPayload>(
        (data) => wrapResponsePromise(controllers.setSensorResetTimes(data)),
        {
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error creating alert configuration'),
                    }),
                );
            },
            onSuccess: () => {
                cleanInactiveAlertConfigurationsQuery();
                dispatch(
                    toastSuccess({
                        message: t('Sensor reset time updated successfully.'),
                    }),
                );
            },
        },
        {
            toastMessage: t('Updating sensor reset settings'),
        },
    );
};
