import isNumber from 'lodash/isNumber';
import round from 'lodash/round';

export const average = (array: number[]): number | null =>
    array.length ? array.reduce((a, b) => a + b) / array.length : null;

export const prettifyCoordinate = (
    coordinate: number | null,
    precision = 3,
): number | null => {
    return isNumber(coordinate) ? round(coordinate, precision) : null;
};
