import type { CalendarPeriod } from '@infogrid/utils-dates';
import { defaultRollingCalendarPeriods } from '@infogrid/utils-dates';
import { useDateUpdate } from '@infogrid/utils-hooks';
import { Chip } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import { memo, useCallback, useState } from 'react';
import type { DateRangePickerShape, FocusedInputShape } from 'react-dates';
import {
    DateRangePicker as DateRangePickerComponent,
    isInclusivelyBeforeDay,
} from 'react-dates';
import { useTranslation } from 'react-i18next';

import { useDateRangePickerStyles } from './styles';

export type DateRangePickerProps = Omit<
    DateRangePickerShape,
    | 'focusedInput'
    | 'hideKeyboardShortcutsPanel'
    | 'isOutsideRange'
    | 'onFocusChange'
    | 'renderMonthText'
> & {
    onPeriodChange: (period: CalendarPeriod) => void;
    period: string | null;
    periods?: CalendarPeriod[];
};

const DATE_TICK_RATE = 60 * 1000; // 60 seconds

const DateRangePicker = ({
    endDate = null,
    onDatesChange,
    onPeriodChange,
    period = null,
    periods = defaultRollingCalendarPeriods,
    startDate = null,
    ...props
}: DateRangePickerProps) => {
    const styles = useDateRangePickerStyles();

    const { t } = useTranslation();

    const date = useDateUpdate(DATE_TICK_RATE);

    const todaysDay = date.getUTCDate();

    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);

    const renderCalendarInfo = useCallback(
        () => (
            <div className={styles.periodsList} data-cypress="date-range-periods">
                {periods.map((periodItem) => {
                    const chipClasses = classNames(styles.period, {
                        [styles.periodActive]: periodItem.value === period,
                    });

                    const onChipClick = () => {
                        onPeriodChange(periodItem);
                    };

                    return (
                        <Chip
                            className={chipClasses}
                            disabled={!!periodItem?.disabled}
                            clickable
                            key={periodItem.label}
                            label={t(periodItem.label)}
                            onClick={onChipClick}
                        />
                    );
                })}
            </div>
        ),
        [
            period,
            onPeriodChange,
            periods,
            styles.period,
            styles.periodActive,
            styles.periodsList,
            t,
        ],
    );

    const getIsOutsideRange = useCallback(
        (day) => !isInclusivelyBeforeDay(day, moment()),
        /**
         * INFO: we need to watch for "todaysDay" changes otherwise
         * if web session rolls over to the next day the functionality
         * would break and show disabled dates incorrectly
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [todaysDay],
    );

    return (
        <DateRangePickerComponent
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            focusedInput={focusedInput}
            hideKeyboardShortcutsPanel
            isOutsideRange={getIsOutsideRange}
            onDatesChange={onDatesChange}
            onFocusChange={setFocusedInput}
            renderCalendarInfo={renderCalendarInfo}
            renderMonthText={undefined}
            // Use moment.utc to ensure correct date is selected regardless of system timezone
            endDate={endDate ? moment.utc(endDate) : null}
            startDate={startDate ? moment.utc(startDate) : null}
        />
    );
};

export default memo(DateRangePicker);
