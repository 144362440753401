import { useSpaceTypes } from '@infogrid/locations-api';
import type { Space } from '@infogrid/locations-types';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { useFormik } from 'formik';
import { memo, useMemo, useState } from 'react';

import { useUpdateSpace } from 'apiHooks/floorPlan/spaces/hooks';

import DeleteSpaceModal from '../DeleteSpaceModal';
import EditSpaceModal from './EditSpaceModal';
import { editSpaceValidationSchema } from './consts';
import type { EditSpaceModalValues } from './types';

interface Props {
    buildingId: number;
    floorId: number;
    onClose: () => void;
    open: boolean;
    setSelectedSpaceId: (id: number | null) => void;
    space: Space;
}

const EditSpaceModalContainer = ({
    buildingId,
    floorId,
    onClose,
    open,
    setSelectedSpaceId,
    space,
}: Props) => {
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const [isDeleteModalOpen, _0, _1, toggleDeleteModal] = useIsOpenState();

    const { data: spaceTypesGroups } = useSpaceTypes();

    const { mutate: updateSpace } = useUpdateSpace({ floor: floorId });

    const onConfirmDeleteModal = () => {
        onClose();
        setSelectedSpaceId(null);
    };

    /**
     * TODO: remove in https://infogrid.atlassian.net/browse/VIZ-436
     * when https://infogrid.atlassian.net/browse/VIZ-435 is done
     */
    const spaceTypes = useMemo(() => {
        return (
            spaceTypesGroups
                ?.map((group) =>
                    group.children.map((child) => ({
                        id: child.id,
                        name: child.display_name,
                        uneditable_capacity_reason: child.uneditable_capacity_reason,
                    })),
                )
                .flat() || []
        );
    }, [spaceTypesGroups]);

    const formik = useFormik<EditSpaceModalValues>({
        enableReinitialize: true,
        initialValues: {
            name: space.name,
            space_type:
                spaceTypes.find((type) => type.name === space.space_type_display)?.id ||
                spaceTypes[0]?.id,
            occupant_capacity: space.occupant_capacity,
            spaceEditDisabled: !!space.uneditable_capacity_reason,
        },
        onSubmit(values) {
            setSubmitting(true);

            const spaceValues = {
                name: values.name,
                type: values.space_type,
                ...(values.occupant_capacity &&
                    !values.spaceEditDisabled && {
                        occupant_capacity: values.occupant_capacity,
                    }),
            };

            updateSpace(
                { id: space.id, data: spaceValues },
                {
                    onSuccess() {
                        setSubmitting(false);
                        onClose();
                    },
                },
            );
        },
        validationSchema: editSpaceValidationSchema,
    });

    if (spaceTypes.length === 0) {
        return null;
    }

    return (
        <>
            <EditSpaceModal
                formik={formik}
                isSubmitting={isSubmitting}
                onClose={onClose}
                onDelete={toggleDeleteModal}
                open={open}
                space={space}
                spaceTypes={spaceTypes}
            />
            <DeleteSpaceModal
                buildingId={buildingId}
                floorId={floorId}
                onClose={toggleDeleteModal}
                onConfirm={onConfirmDeleteModal}
                open={isDeleteModalOpen}
                spaceId={space.id}
            />
        </>
    );
};

export default memo(EditSpaceModalContainer);
