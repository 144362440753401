import { ICONS } from '@infogrid/components-icons';
import { THRESHOLD_TYPE } from '@infogrid/remote-monitoring-types';
import { LEGIONNAIRE_SUB_TYPES } from '@infogrid/sensors-constants';
import {
    Card,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import { useField } from 'formik';
import type { FormikProps } from 'formik';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Threshold, ThresholdValues } from 'utils/types/ts/threshold';

import ThresholdDescription from './ThresholdDescription';
import ThresholdRangeInput from './ThresholdRangeInput';
import ThresholdSingleInput from './ThresholdSingleInput';
import { useThresholdCardStyles } from './styles';

export const THRESHOLD_OPTIONS = Object.freeze({
    RANGE: 'range',
    SINGLE: 'single',
} as const);

interface ThresholdCardProps {
    name: string;
    formik: FormikProps<ThresholdValues>;
    keyInForm: string;
    subType: string;
    threshold?: Threshold;
    unitSymbol: string;
    testId: string;
    onFieldChange: (values: ThresholdValues) => void;
}

const ThresholdCard = ({
    name,
    formik,
    keyInForm,
    subType,
    threshold,
    unitSymbol,
    testId,
    onFieldChange,
}: ThresholdCardProps): JSX.Element => {
    const { t } = useTranslation('solutions');
    const styles = useThresholdCardStyles();

    const [lowerField, _0, lowerHelper] = useField({
        name: `${keyInForm}.lower`,
    });

    const [upperField, _1, upperHelper] = useField({
        name: `${keyInForm}.upper`,
    });

    const [thresholdTypeField, _2, thresholdTypeHelper] = useField({
        name: `${keyInForm}.thresholdType`,
    });

    const isRangeThreshold = thresholdTypeField.value === THRESHOLD_TYPE.RANGE;
    const isColdPipe =
        subType === LEGIONNAIRE_SUB_TYPES.COLD ||
        subType === LEGIONNAIRE_SUB_TYPES.COLD_STORAGE;

    useEffect(() => {
        onFieldChange(formik.values);
    }, [
        lowerField.value,
        upperField.value,
        thresholdTypeField.value,
        formik,
        onFieldChange,
    ]);

    const onChangeThresholdType = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string;
                value: unknown;
            }>,
        ) => {
            if (event.target.value === THRESHOLD_OPTIONS.RANGE) {
                thresholdTypeHelper.setValue(THRESHOLD_TYPE.RANGE);
            } else {
                const thresholdType = isColdPipe
                    ? THRESHOLD_TYPE.SINGLE_LESS_THAN
                    : THRESHOLD_TYPE.SINGLE_MORE_THAN;

                if (thresholdType === THRESHOLD_TYPE.SINGLE_MORE_THAN) {
                    upperHelper.setValue(null);
                }

                if (thresholdType === THRESHOLD_TYPE.SINGLE_LESS_THAN) {
                    lowerHelper.setValue(null);
                }

                thresholdTypeHelper.setValue(thresholdType);
            }
        },
        [upperHelper, lowerHelper, thresholdTypeHelper, isColdPipe],
    );

    return (
        <Card elevation={0} data-testid={testId} className={styles.card}>
            <CardHeader
                avatar={
                    <Icon
                        className={classNames(
                            'fal',
                            ICONS.SENSORS.TEMPERATURE,
                            styles.icon,
                        )}
                        data-cypress="sensor-icon"
                    />
                }
                title={name}
                titleTypographyProps={{ variant: 'h6' }}
            />
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item>
                        <FormControl variant="outlined" className={styles.shouldBe}>
                            <InputLabel id="threshold-type-label">
                                {t('Threshold type')}
                            </InputLabel>
                            <Select
                                labelId="threshold-type-label"
                                id="threshold-type"
                                data-cypress="threshold-type"
                                value={
                                    isRangeThreshold
                                        ? THRESHOLD_OPTIONS.RANGE
                                        : THRESHOLD_OPTIONS.SINGLE
                                }
                                label={t('Threshold type')}
                                onChange={onChangeThresholdType}
                            >
                                <MenuItem
                                    value={THRESHOLD_OPTIONS.SINGLE}
                                    data-cypress="threshold-type-single"
                                >
                                    {t('Single')}
                                </MenuItem>
                                <MenuItem
                                    value={THRESHOLD_OPTIONS.RANGE}
                                    data-cypress="threshold-type-range"
                                >
                                    {t('Range')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {isRangeThreshold && (
                        <ThresholdRangeInput
                            formik={formik}
                            keyInForm={keyInForm}
                            unitSymbol={unitSymbol}
                        />
                    )}
                    {!isRangeThreshold && (
                        <ThresholdSingleInput
                            formik={formik}
                            keyInForm={keyInForm}
                            unitSymbol={unitSymbol}
                        />
                    )}
                </Grid>
            </CardContent>
            <CardContent className={styles.description}>
                <ThresholdDescription
                    sensorCount={threshold?.sensorCount || 0}
                    lowerField={lowerField}
                    upperField={upperField}
                    unitSymbol={unitSymbol}
                    thresholdType={thresholdTypeField.value}
                />
            </CardContent>
        </Card>
    );
};

export default ThresholdCard;
