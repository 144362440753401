import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { Button, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { trackInstallFlowUserEvent } from 'views/buildings/pages/installFlow/utils/analytics';

import { useInstallationFlowStyles } from '../../styles';
import { useDeviceVendorCardStyles } from './styles';

const DeviceVendorCard = ({
    image,
    nextStep,
    deviceTitle,
    deviceDescription,
    onVendorSelect,
    cypressID,
}) => {
    const { t } = useTranslation('sensors');
    const deviceVendorCardStyles = useDeviceVendorCardStyles();
    const installationFlowStyles = useInstallationFlowStyles();

    const onSelect = useCallback(() => {
        trackInstallFlowUserEvent(USER_ACTIONS.PRESSED, 'Select device', {
            device: deviceTitle,
        });

        onVendorSelect(nextStep);
    }, [nextStep, onVendorSelect, deviceTitle]);

    return (
        <Grid
            container
            direction="row"
            spacing={1}
            className={deviceVendorCardStyles.deviceContainer}
        >
            <Grid item xs>
                <img
                    src={image}
                    alt={deviceTitle}
                    className={deviceVendorCardStyles.image}
                />
            </Grid>
            <Grid item xs>
                <Typography variant="h6" data-cypress={`${cypressID}-title`}>
                    {deviceTitle}
                </Typography>
                <Typography
                    data-cypress={`${cypressID}-description`}
                    className={classNames(
                        deviceVendorCardStyles.deviceDescription,
                        installationFlowStyles.paragraph,
                    )}
                >
                    {deviceDescription}
                </Typography>
                <Button
                    onClick={onSelect}
                    variant="contained"
                    data-cypress={`${cypressID}-button`}
                    color="primary"
                    className={deviceVendorCardStyles.selectButton}
                >
                    {t('Select Device')}
                </Button>
            </Grid>
        </Grid>
    );
};

DeviceVendorCard.propTypes = {
    image: PropTypes.node.isRequired,
    deviceTitle: PropTypes.string.isRequired,
    deviceDescription: PropTypes.string.isRequired,
    onVendorSelect: PropTypes.func.isRequired,
    nextStep: PropTypes.string.isRequired,
    cypressID: PropTypes.string.isRequired,
};

export default DeviceVendorCard;
