import { ORGANIZATION_FEATURE_FLAG } from '@infogrid/core-feature-flags';
import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import WidgetPreview from 'styles/images/widgets/co2-concentration.png';

import CO2ConcentrationWidget from './CO2ConcentrationWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('CO2 Concentration', { ns: 'dashboard' }),
    type: WIDGET_TYPE.CO2_CONCENTRATION,
    featureFlags: [ORGANIZATION_FEATURE_FLAG.ICONE_INDEX_WIDGET],
    Component: CO2ConcentrationWidget,
    image: WidgetPreview,
    order: 14,
    gridConfig: {
        ...DEFAULT_GRID_ITEM_CONFIG,
        maxH: 1,
        maxW: 1,
        isResizable: false,
    },
};

export default CO2ConcentrationWidget;
