import { DropdownFilter } from '@infogrid/components-material-ui';
import type { BuildingCountry } from '@infogrid/locations-types';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import {
    BUILDING_PARAM_NAMES,
    onBuildingSearchPath,
    useFormattedBuildingQueryParams,
} from 'utils/filtering/building';

interface Props {
    countries: BuildingCountry[];
}

const CountryFilter = ({ countries }: Props) => {
    const location = useLocation();
    const history = useHistory();

    const { t } = useTranslation();

    const { countries: countriesFromQuery } = useFormattedBuildingQueryParams();

    const mappedCountries = useMemo(() => {
        return countries.map((country) => ({
            id: country.code,
            name: country.name,
            display_name: country.name,
        }));
    }, [countries]);

    const onSearch = useCallback(
        (attributes) => {
            const codes = attributes.map(
                (attribute: string) => countries.find((c) => c.name === attribute)?.code,
            );

            history.push(
                onBuildingSearchPath(location, {
                    [BUILDING_PARAM_NAMES.COUNTRIES]: codes.join(','),
                }),
            );
        },
        [countries, history, location],
    );

    return (
        <DropdownFilter
            allItemLabel={t('All Countries')}
            dataCypress="country-filter"
            items={mappedCountries}
            itemsFromQuery={countriesFromQuery}
            label={t('Country')}
            noOptionsLabel={t('No filters available')}
            onSearch={onSearch}
        />
    );
};

export default memo(CountryFilter);
