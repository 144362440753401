import { Modal } from '@infogrid/components-material-ui';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useResetEventsWebhook } from 'apiHooks/eventsWebhook/hooks';

import { useResetEventsWebhookModalStyles } from './styles';

interface Props {
    eventsWebhookId: number;
    onClose: () => void;
    onConfirm: () => void;
    open: boolean;
}

const ResetEventsWebhookModal = ({
    eventsWebhookId,
    onClose,
    onConfirm,
    open,
}: Props) => {
    const styles = useResetEventsWebhookModalStyles();

    const { t } = useTranslation('integrations');

    const { isLoading: isSubmitting, mutate: deleteEventsWebhook } =
        useResetEventsWebhook();

    const handleConfirm = () => {
        deleteEventsWebhook({ eventsWebhookId });
        onConfirm();
    };

    return (
        <Modal onClose={onClose} open={open}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Modal.Title>{t('Confirm reset url')}</Modal.Title>
                <Modal.Content className={styles.modalContent}>
                    <Typography className={styles.message} variant="body1">
                        {t(
                            'Resetting the url while disconnect that url from the Events webhook data stream. ' +
                                'In order to resume the connection you will have to generate a new secret key. ' +
                                'Are you sure you wish to reset the url?',
                        )}
                    </Typography>
                </Modal.Content>
                <Modal.Actions justify="space-between">
                    <Button
                        color="primary"
                        disabled={isSubmitting}
                        data-cypress="not-reset-events-webhook"
                        data-testid="not-reset-events-webhook"
                        onClick={onClose}
                    >
                        {t("NO, DON'T RESET")}
                    </Button>
                    <Button
                        color="secondary"
                        disabled={isSubmitting}
                        onClick={handleConfirm}
                        type="submit"
                        variant="contained"
                        data-cypress="submit-reset-events-webhook"
                        data-testid="submit-reset-events-webhook"
                    >
                        {isSubmitting && <CircularProgress size={22} />}
                        <span>{t('YES, RESET')}</span>
                    </Button>
                </Modal.Actions>
            </form>
        </Modal>
    );
};

export default memo(ResetEventsWebhookModal);
