import { makeStyles } from '@material-ui/core';

export const useChecklistItemStyles = makeStyles((theme) => ({
    checklistItem: {
        padding: theme.spacing(0.5, 1),
    },
}));

export const useChecklistCheckboxStyles = makeStyles({
    root: {
        padding: 6,
    },
});

export const listItemIconStyles = makeStyles({
    root: {
        minWidth: 0,
        marginRight: '5px',
    },
});
