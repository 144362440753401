import type { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export const electricityMonitoringConfigurationFormValidationSchema = (
    t: TFunction,
): Yup.ObjectSchema =>
    Yup.object().shape({
        monitoring_locations: Yup.array<number>()
            .min(1, t('Monitoring location is required', { ns: 'sensors' }))
            .required(t('Monitoring location is required', { ns: 'sensors' }))
            .label(t('Monitoring location')),
        end_use: Yup.string()
            .required(t('End use is required', { ns: 'sensors' }))
            .label(t('End use', { ns: 'sensors' })),
    });
