import { MenuList } from '@infogrid/components-material-ui';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const EditBuildingMenuController = ({ editBuilding }) => {
    const { t } = useTranslation('floorplan');

    return (
        <MenuList>
            <MenuList.Item onClick={editBuilding}>
                <Typography>{t('Edit Building')}</Typography>
            </MenuList.Item>
        </MenuList>
    );
};

EditBuildingMenuController.propTypes = {
    editBuilding: PropTypes.func,
};

EditBuildingMenuController.defaultProps = {
    editBuilding: () => {},
};

export default memo(EditBuildingMenuController);
