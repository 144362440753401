import { Modal } from '@infogrid/components-material-ui';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { Button, Box, Typography, Fade } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useCallback, useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import type { InstallationFlowComponentProps } from 'components/InstallationFlow/types';
import IdentifySensor, {
    useIdentifySensorStyles,
} from 'components/sensors/IdentifySensor';
import { InstallationFlowDT1, InstallationFlowDT2 } from 'utils/Images';

import { useInstallationFlowContext } from '../../context';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../../reducer';
import { useInstallationFlowStyles } from '../../styles';
import { useDTIdentifierStyles } from './styles';

type Props = Pick<InstallationFlowComponentProps, 'errorMessage'>;

const TEMPERATURE_TYPES: string[] = [
    SENSOR_TYPE.TYPE_COLD_STORAGE,
    SENSOR_TYPE.TYPE_TEMPERATURE,
];

const DTIdentifier = ({ errorMessage }: Props) => {
    const { t } = useTranslation('sensors');
    const installationFlowStyles = useInstallationFlowStyles();
    const DTIdentifierStyles = useDTIdentifierStyles();
    const identifySensorStyles = useIdentifySensorStyles();

    const { registerInfo, sensor, lastSignalEvent } = useInstallationFlowContext();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    const [websocketKey, setWebsocketKey] = useState(uuidv4());

    useEffect(() => {
        if (sensor && registerInfo) {
            if (TEMPERATURE_TYPES.includes(sensor.type_code)) {
                dispatchInstallationFlowContext({
                    type: INSTALLATION_FLOW_ACTIONS.SCAN_TEMPERATURE_SENSOR,
                });

                return;
            }

            dispatchInstallationFlowContext({
                type: lastSignalEvent
                    ? INSTALLATION_FLOW_ACTIONS.CHECK_CONNECTION
                    : INSTALLATION_FLOW_ACTIONS.CONFIGURE_DEVICE,
            });
        }
    }, [sensor, dispatchInstallationFlowContext, registerInfo, lastSignalEvent]);

    const onRestartIdentification = useCallback(() => {
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.REGISTER_MANUALLY,
            registerInfo: '',
        });
        // trigger websocket component unmount
        setWebsocketKey(uuidv4());
    }, [dispatchInstallationFlowContext]);

    const onSuccess = useCallback(
        ({ deviceName }) => {
            if (deviceName === undefined) {
                // IdentifySensor will show error toast
                return;
            }

            // API expects deviceId without vendor prefix
            const parsedDeviceName = deviceName.split('_');

            if (parsedDeviceName.length !== 2) {
                return;
            }

            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.REGISTER_MANUALLY,
                registerInfo: parsedDeviceName[1],
            });
        },
        [dispatchInstallationFlowContext],
    );

    const onPrevious = () =>
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.RETURN_TO_VENDOR_SELECTOR,
        });

    return (
        <>
            <Modal.Title>{t('Manually identify a device')}</Modal.Title>
            <Modal.Content>
                {errorMessage && (
                    <Fade in>
                        <Alert
                            severity="error"
                            action={
                                <Button
                                    color="secondary"
                                    className={identifySensorStyles.tryAgainButton}
                                    onClick={onRestartIdentification}
                                    data-cypress="try-again"
                                >
                                    {t('Try again')}
                                </Button>
                            }
                        >
                            <Typography
                                className={identifySensorStyles.alertText}
                                variant="body2"
                                data-cypress="error-message"
                            >
                                {errorMessage}
                            </Typography>
                        </Alert>
                    </Fade>
                )}
                {!errorMessage && (
                    <IdentifySensor key={websocketKey} onSuccess={onSuccess} />
                )}

                <Box className={DTIdentifierStyles.helpImagesContainer}>
                    <Box>
                        <img
                            src={InstallationFlowDT1}
                            alt="Disruptive Technologies"
                            className={installationFlowStyles.centeredImage}
                        />
                        <Typography
                            align="center"
                            className={DTIdentifierStyles.tip}
                            data-cypress="img-text1"
                        >
                            {t('Touch here on sensors')}
                        </Typography>
                    </Box>
                    <Box>
                        <img
                            src={InstallationFlowDT2}
                            alt="Disruptive Technologies"
                            className={installationFlowStyles.centeredImage}
                        />
                        <Typography
                            align="center"
                            className={DTIdentifierStyles.tip}
                            data-cypress="img-text2"
                        >
                            {t('Touch here on cloud connectors')}
                        </Typography>
                    </Box>
                </Box>
            </Modal.Content>

            <Modal.Actions
                justify="space-between"
                className={installationFlowStyles.actions}
            >
                <Button onClick={onPrevious} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(DTIdentifier);
