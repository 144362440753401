import { useAppSelector } from '@infogrid/core-ducks';
import type { Floor, BuildingDetail } from '@infogrid/locations-types';
import { selectOrganization } from '@infogrid/user-ducks';
import { Icon, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';

import { useBreadcrumbsStyles } from './styles';

interface Props {
    selectedBuilding?: BuildingDetail | null;
    selectedFloor?: Floor | null;
    onGoToBuildings: () => void;
    onGoToFloors: () => void;
}

const Breadcrumbs = ({
    onGoToBuildings,
    onGoToFloors,
    selectedBuilding,
    selectedFloor,
}: Props) => {
    const styles = useBreadcrumbsStyles();

    const organization = useAppSelector(selectOrganization);

    return (
        <div className={styles.container} data-cypress="breadcrumbs-panel">
            <Icon
                className={classNames('far fa-globe', styles.icon)}
                data-cypress="org-icon"
                data-testid="go-to-buildings-icon"
                onClick={onGoToBuildings}
            />
            {selectedBuilding ? (
                <Typography className={styles.separator}> / </Typography>
            ) : (
                <Typography className={styles.label}>{organization?.name}</Typography>
            )}

            {selectedBuilding && (
                <>
                    <Icon
                        className={classNames('far fa-building', styles.icon)}
                        data-cypress="building-icon"
                        data-testid="go-to-floors-icon"
                        onClick={onGoToFloors}
                    />
                    {!selectedFloor ? (
                        <Typography className={styles.label}>
                            {selectedBuilding.name}
                        </Typography>
                    ) : (
                        <Typography className={styles.separator}> / </Typography>
                    )}
                </>
            )}

            {selectedFloor && (
                <>
                    <Icon className={classNames('far fa-layer-group', styles.icon)} />
                    <Typography className={styles.label} data-cypress="floor">
                        {selectedFloor.name}
                    </Typography>
                </>
            )}
        </div>
    );
};

export default memo(Breadcrumbs);
