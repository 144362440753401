import { registerTranslationKey } from '@infogrid/utils-i18n';
import type { RouteConfig } from 'react-router-config';

import Thresholds from 'components/Thresholds';
import { userIsManager } from 'decorators/auth';
import SmartCleaningRules from 'views/solutionSettings/pages/smartCleaning/CleaningRules';
import SmartCleaningLocations from 'views/solutionSettings/pages/smartCleaning/Locations';
import SmartCleaningReports from 'views/solutionSettings/pages/smartCleaning/Reports';
import SmartCleaningResetTime from 'views/solutionSettings/pages/smartCleaning/ResetTime';
import RootSolutionSettings from 'views/solutionSettings/pages/solutionSettings';
import SolutionSettingsIndex from 'views/solutionSettings/pages/solutionSettingsIndex';

export const createSolutionSettingsRoutes = (
    PageNotFoundRoute: RouteConfig,
): RouteConfig => ({
    path: '/settings/solutions',
    name: 'solution-settings',
    component: userIsManager(RootSolutionSettings),
    routes: [
        {
            path: '/settings/solutions',
            exact: true,
            name: 'solution-settings',
            component: SolutionSettingsIndex,
        },
        {
            path: '/settings/solutions/thresholds',
            exact: true,
            name: 'solution-thresholds',
            pageName: registerTranslationKey('Manage Thresholds', {
                ns: 'solutions',
            }),
            component: Thresholds,
        },
        {
            path: '/settings/solutions/smart-cleaning/locations',
            exact: true,
            name: 'smart-cleaning-locations',
            pageName: registerTranslationKey('Select Smart Cleaning locations', {
                ns: 'solutions',
            }),
            component: SmartCleaningLocations,
        },
        {
            path: '/settings/solutions/smart-cleaning/rules',
            exact: true,
            name: 'smart-cleaning-rules',
            pageName: registerTranslationKey('Manage cleaning rules', {
                ns: 'solutions',
            }),
            component: SmartCleaningRules,
        },
        {
            path: '/settings/solutions/smart-cleaning/reset-time',
            exact: true,
            name: 'smart-cleaning-reset-time',
            pageName: registerTranslationKey('Schedule sensor reset time', {
                ns: 'solutions',
            }),
            component: SmartCleaningResetTime,
        },
        {
            path: '/settings/solutions/smart-cleaning/reports',
            exact: true,
            name: 'smart-cleaning-reports',
            pageName: registerTranslationKey('Schedule reports', {
                ns: 'solutions',
            }),
            component: SmartCleaningReports,
        },
        PageNotFoundRoute,
    ],
});
