import { LoadingPlaceholder, Modal } from '@infogrid/components-material-ui';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

const InstallPending: FC = () => {
    const { t } = useTranslation('sensors');

    return (
        <>
            <Modal.Title>{t('Setting up device…')}</Modal.Title>
            <Modal.Content>
                <LoadingPlaceholder />
            </Modal.Content>
        </>
    );
};

export default InstallPending;
