import { wrapResponsePromise } from '@infogrid/core-api';
import { toastError, toastSuccess } from '@infogrid/core-ducks';
import type { EventsWebhook } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { controllers } from '../controllers';

export const useDeleteEventsWebhook = (
    eventsWebhook: EventsWebhook | undefined,
): UseMutationResult => {
    const dispatch = useDispatch();

    const { t } = useTranslation('integrations');

    return useMutation(
        () =>
            wrapResponsePromise(
                controllers.deleteEventsWebhook((eventsWebhook as EventsWebhook).id),
            ),
        {
            onSuccess() {
                dispatch(
                    toastSuccess({
                        message: t("'{{webhookName}}' was successfully deleted.", {
                            webhookName: (eventsWebhook as EventsWebhook).name,
                        }),
                    }),
                );
            },
            onError() {
                dispatch(
                    toastError({
                        message: t("Error while deleting '{{webhookName}}'", {
                            webhookName: (eventsWebhook as EventsWebhook).name,
                        }),
                    }),
                );
            },
        },
    );
};
