import { makeStyles } from '@material-ui/core';

export const useUpdateImageConfirmModalStyles = makeStyles((theme) => ({
    cancelButton: {
        color: theme.palette.common.gray1,
    },
    keepSensorsButton: {
        marginRight: theme.spacing(2),
    },
    modalContainer: {
        width: 450,
    },
    primaryTextContainer: {
        marginBottom: theme.spacing(2),
    },
    spinner: {
        color: theme.palette.primary.contrastText,
    },
}));
