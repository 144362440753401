import { DropdownFilter } from '@infogrid/components-material-ui';
import type { BuildingType } from '@infogrid/locations-types';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import {
    BUILDING_PARAM_NAMES,
    onBuildingSearchPath,
    useFormattedBuildingQueryParams,
} from 'utils/filtering/building';

interface Props {
    types: BuildingType[];
}

const BuildingTypeFilter = ({ types }: Props) => {
    const location = useLocation();
    const history = useHistory();

    const { t } = useTranslation();

    const { building_types: buildingTypesFromQuery } = useFormattedBuildingQueryParams();

    const mappedTypes = useMemo(() => {
        return types.map((type) => ({
            id: type.id,
            name: type.name,
            display_name: type.display_name,
        }));
    }, [types]);

    const onSearch = useCallback(
        (attributes) => {
            const typeNames = attributes.map(
                (attribute: string) =>
                    types.find((type) => type.name === attribute)?.name,
            );

            history.push(
                onBuildingSearchPath(location, {
                    [BUILDING_PARAM_NAMES.TYPES]: typeNames.join(','),
                }),
            );
        },
        [history, location, types],
    );

    return (
        <DropdownFilter
            allItemLabel={t('All Types')}
            dataCypress="building-type-filter"
            items={mappedTypes}
            itemsFromQuery={buildingTypesFromQuery}
            label={t('Type')}
            noOptionsLabel={t('No filters available')}
            onSearch={onSearch}
        />
    );
};

export default memo(BuildingTypeFilter);
