import { i18n, registerTranslationKey } from '@infogrid/utils-i18n';
import * as Yup from 'yup';

import {
    prepareBaseConfigurationPayload,
    prepareBaseInitialValues,
    prepareBaseStoplightValues,
} from 'views/dashboards/widgets/utils';

import type {
    PeopleCountingStoplightValues,
    PeopleCountingStoplightInitialValues,
    PeopleCountingStoplightWidget,
    PeopleCountingStoplightWidgetConfiguration,
} from './types';

const GREEN_MESSAGE = registerTranslationKey('Welcome', { ns: 'dashboard' });
const YELLOW_MESSAGE = registerTranslationKey('Near capacity', { ns: 'dashboard' });
const RED_MESSAGE = registerTranslationKey('Please wait', { ns: 'dashboard' });

const GREEN_MAX_THRESHOLD = 10;
const GREEN_MIN_THRESHOLD = 0;

const YELLOW_MAX_THRESHOLD = 15;
const YELLOW_MIN_THRESHOLD = 11;

const RED_MAX_THRESHOLD = 16;
const RED_MIN_THRESHOLD = 16;

const MAX_COLOR_MESSAGE_LENGTH = 50;

// IPW TODO: Translate this
const colorSchema = Yup.object().shape({
    message: Yup.string().max(
        MAX_COLOR_MESSAGE_LENGTH,
        `Must be ${MAX_COLOR_MESSAGE_LENGTH} characters or less`,
    ),
});

export const validationSchema = Yup.object().shape({
    displayOptions: Yup.object().shape({
        green: colorSchema,
        yellow: colorSchema,
        red: colorSchema,
    }),
});

export const prepareInitialValues = (
    widget: PeopleCountingStoplightWidget,
): PeopleCountingStoplightInitialValues => {
    const {
        displays,
        folders = [],
        sensors = [],
        stoplight,
    } = widget?.configuration?.calculators?.space_stoplight;

    return {
        ...prepareBaseInitialValues(widget),
        folders,
        sensors,
        displayOptions: {
            green: {
                maxThreshold: displays?.greenScreen.highLimit ?? GREEN_MAX_THRESHOLD,
                minThreshold: displays?.greenScreen.lowLimit ?? GREEN_MIN_THRESHOLD,
                message:
                    displays?.greenScreen.message ??
                    i18n.t(GREEN_MESSAGE, { ns: 'dashboard' }),
                showOccupancyCount: displays?.greenScreen?.showOccupancy ?? true,
            },
            yellow: {
                maxThreshold: displays?.yellowScreen.highLimit ?? YELLOW_MAX_THRESHOLD,
                minThreshold: displays?.yellowScreen.lowLimit ?? YELLOW_MIN_THRESHOLD,
                message:
                    displays?.yellowScreen.message ??
                    i18n.t(YELLOW_MESSAGE, { ns: 'dashboard' }),
                showOccupancyCount: displays?.yellowScreen.showOccupancy ?? true,
            },
            red: {
                maxThreshold: displays?.redScreen.highLimit ?? RED_MAX_THRESHOLD,
                minThreshold: displays?.redScreen.lowLimit ?? RED_MIN_THRESHOLD,
                message:
                    displays?.redScreen.message ??
                    i18n.t(RED_MESSAGE, { ns: 'dashboard' }),
                showOccupancyCount: displays?.redScreen.showOccupancy ?? false,
            },
        },
        stoplight: prepareBaseStoplightValues(stoplight),
    };
};

export const prepareConfigurationPayload = (
    values: PeopleCountingStoplightValues,
): PeopleCountingStoplightWidgetConfiguration => {
    const payload = prepareBaseConfigurationPayload(values);

    const { green, yellow, red } = values?.displayOptions;

    return {
        ...payload,
        calculators: {
            space_stoplight: {
                displays: {
                    greenScreen: {
                        highLimit: green.maxThreshold,
                        lowLimit: green.minThreshold,
                        message: green.message,
                        showOccupancy: green.showOccupancyCount,
                    },
                    yellowScreen: {
                        highLimit: yellow.maxThreshold,
                        lowLimit: yellow.minThreshold,
                        message: yellow.message,
                        showOccupancy: yellow.showOccupancyCount,
                    },
                    redScreen: {
                        highLimit: red.maxThreshold,
                        lowLimit: red.minThreshold,
                        message: red.message,
                        showOccupancy: red.showOccupancyCount,
                    },
                },
                folders: values?.folders,
                sensors: values?.sensors,
                stoplight: values?.stoplight,
            },
        },
    };
};
