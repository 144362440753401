import { makeStyles } from '@material-ui/core';

export const useInstallSuccessStyles = makeStyles((theme) => ({
    content: {
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    contentHeader: {
        textAlign: 'center',
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    successIcon: {
        overflow: 'unset',
        color: theme.palette.common.green1,
        fontSize: theme.typography.h4.fontSize,
    },
    paragraph: {
        color: theme.palette.text.grey.default,
        marginBottom: theme.spacing(1.5),
    },
    divider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    centeredImage: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    inlineHelpLink: {
        marginLeft: theme.spacing(0.5),
    },
    warning: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
}));
