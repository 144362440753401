import type { AutocompleteRenderInputParams } from '@infogrid/components-material-ui';
import { Autocomplete } from '@infogrid/components-material-ui';
import type {
    DashboardUserPermission,
    DashboardTeamPermission,
} from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';

import { useAutocompleteStyles } from './styles';

const TEAMS_TYPE = registerTranslationKey('Teams', { ns: 'dashboard' });
const USERS_TYPE = registerTranslationKey('Users', { ns: 'dashboard' });
const DEFAULT_OPTIONS_LIMIT = 5;

type OptionType = {
    id: number;
    name: string;
    type: string;
    typeLabel: string;
};

interface Props {
    usersTotalCount?: number;
    teamsTotalCount?: number;
    renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
    optionsLimit?: number;
    teams?: DashboardTeamPermission[];
    users?: DashboardUserPermission[];
    onSelectTeam: (option: { id: number; name: string }) => void;
    onSelectUser: (option: { id: number; name: string }) => void;
}

const DEFAULT_TEAMS: DashboardTeamPermission[] = [];
const DEFAULT_USERS: DashboardUserPermission[] = [];

const optionMapper = (
    item: DashboardTeamPermission | DashboardUserPermission,
    count: number,
    totalCount: number | undefined,
    maxCount: number,
    type: string,
    t: TFunction<'dashboard'>,
): OptionType => {
    return {
        id: item.id,
        name: item.name,
        type,
        typeLabel:
            totalCount && totalCount > maxCount
                ? t(type) +
                  ' ' +
                  t('({{count}} out of {{totalCount}} are shown)', {
                      count,
                      totalCount,
                      defaultValue___one: `(${count} out of ${totalCount} is shown)`,
                      defaultValue___other: `(${count} out of ${totalCount} are shown)`,
                  })
                : t(type),
    };
};

const optionLabelExtractor = (option: OptionType) => option.name;
const groupExtractor = (option: OptionType) => option.typeLabel;

const UsersAndTeamsAutocomplete = ({
    onSelectTeam,
    onSelectUser,
    optionsLimit = DEFAULT_OPTIONS_LIMIT,
    renderInput,
    teams = DEFAULT_TEAMS,
    teamsTotalCount,
    users = DEFAULT_USERS,
    usersTotalCount,
}: Props) => {
    const styles = useAutocompleteStyles();
    const { t } = useTranslation('dashboard');

    const options = useMemo(
        () => [
            ...teams.map((team) =>
                optionMapper(
                    team,
                    teams.length,
                    teamsTotalCount,
                    optionsLimit,
                    TEAMS_TYPE,
                    t,
                ),
            ),
            ...users.map((user) =>
                optionMapper(
                    user,
                    users.length,
                    usersTotalCount,
                    optionsLimit,
                    USERS_TYPE,
                    t,
                ),
            ),
        ],
        [optionsLimit, teams, teamsTotalCount, users, usersTotalCount, t],
    );

    const handleChange = useCallback(
        (_, value) => {
            if (value?.type) {
                const option = {
                    id: value.id,
                    name: value.name,
                };

                if (value.type === TEAMS_TYPE) {
                    onSelectTeam(option);
                }

                if (value.type === USERS_TYPE) {
                    onSelectUser(option);
                }
            }
        },
        [onSelectTeam, onSelectUser],
    );

    const optionRenderer = useCallback(
        (option: OptionType) => {
            const iconClass = classNames(
                'fa',
                {
                    'fa-user': option.type === USERS_TYPE,
                    'fa-users': option.type === TEAMS_TYPE,
                },
                styles.optionGroupIcon,
            );

            return (
                <div className={styles.option}>
                    <div className={styles.optionWrapper}>
                        <i className={iconClass} />
                        <Typography variant="body2">{option.name}</Typography>
                    </div>
                    <i className="fal fa-plus-circle" />
                </div>
            );
        },
        [styles.option, styles.optionGroupIcon, styles.optionWrapper],
    );

    return (
        <Autocomplete
            freeSolo
            getOptionLabel={optionLabelExtractor}
            groupBy={groupExtractor}
            onChange={handleChange}
            options={options}
            renderInput={renderInput}
            renderOption={optionRenderer}
        />
    );
};

export default memo(UsersAndTeamsAutocomplete);
