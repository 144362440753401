import { makeStyles, fade } from '@material-ui/core';

export const useTreeNodeStyles = makeStyles((theme) => ({
    treeViewItem: {
        fontSize: 12,

        '& > .MuiTreeItem-group': {
            marginLeft: theme.spacing(3.75),
        },

        '& > .MuiTreeItem-content': {
            borderRadius: '4px',

            padding: '2px 6px',

            opacity: 0.3,
        },

        '& .MuiTreeItem-iconContainer': {
            width: 'auto',

            margin: 0,
            padding: '4px 8px 4px 0',

            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
    },
    treeViewItemEditable: {
        '& > .MuiTreeItem-content:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.08),
        },
        '& > .MuiTreeItem-content': {
            opacity: 1,
        },
    },
    treeViewItemExpandable: {
        '& > .MuiTreeItem-content': {
            opacity: 0.7,
        },
    },
    treeViewItemSelected: {
        '&.MuiTreeItem-root > .MuiTreeItem-content': {
            backgroundColor: fade(theme.palette.primary.main, 0.1),
            color: theme.palette.primary.main,
        },

        '& .MuiTreeItem-iconContainer': {
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
    },
    listIcon: {
        width: 12,
        fontSize: 'inherit',

        opacity: 0.8,

        fontWeight: 600,
    },
}));
