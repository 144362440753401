import { Typography } from '@material-ui/core';
import type { ReactNode } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Widget } from 'views/dashboards/components/Widget';

const SENSORS_ICON = 'fal fa-sensor';
const PERIOD_ICON = 'fal fa-calendar-alt';
const REFRESH_RATE_ICON = 'fal fa-stopwatch';

export interface GenericFooterProps {
    children?: ReactNode;
    className?: string;
    justifyContent?: string;
    periodLabel?: string;
    refreshRateLabel?: string;
    sensorsLabel?: string;
}

const GenericFooter = ({
    children,
    className,
    justifyContent = 'flex-end',
    periodLabel,
    refreshRateLabel,
    sensorsLabel,
}: GenericFooterProps) => {
    const { t } = useTranslation('dashboard');

    return (
        <Widget.Footer className={className} justifyContent={justifyContent}>
            {children}
            <Widget.Options>
                {sensorsLabel && (
                    <Widget.Option data-testid="sensors-count-label">
                        <i className={SENSORS_ICON} />
                        <Typography variant="caption">{t(sensorsLabel)}</Typography>
                    </Widget.Option>
                )}
                {periodLabel && (
                    <Widget.Option>
                        <i className={PERIOD_ICON} />
                        <Typography variant="caption">{t(periodLabel)}</Typography>
                    </Widget.Option>
                )}
                {refreshRateLabel && (
                    <Widget.Option>
                        <i className={REFRESH_RATE_ICON} />
                        <Typography variant="caption">{t(refreshRateLabel)}</Typography>
                    </Widget.Option>
                )}
            </Widget.Options>
        </Widget.Footer>
    );
};

export default memo(GenericFooter);
