import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const fileInputConfig = {
    accept: 'image/jpeg, image/png, application/pdf',
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true,
};

const FileUploader = ({
    className,
    children,
    onDropSuccess,
    onDropFailure,
    config,
    disabled,
}) => {
    const onDropRejected = useCallback(
        (rejectedFiles) => {
            const errors = rejectedFiles
                .map((file) => file.errors.map((error) => error.code))
                .flat();

            onDropFailure(errors);
        },
        [onDropFailure],
    );

    const dropzoneConfig = {
        ...fileInputConfig,
        ...config,
        disabled,
        onDropAccepted: onDropSuccess,
        onDropRejected,
    };
    const { getRootProps, getInputProps, open } = useDropzone(dropzoneConfig);

    return (
        <>
            <div
                className={className}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getRootProps()}
            >
                {children({ onOpen: open })}
                <input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getInputProps()}
                />
            </div>
        </>
    );
};

FileUploader.propTypes = {
    className: PropTypes.string,
    children: PropTypes.func.isRequired,
    onDropSuccess: PropTypes.func,
    onDropFailure: PropTypes.func,
    config: PropTypes.shape({}),
    disabled: PropTypes.bool,
};

FileUploader.defaultProps = {
    className: '',
    config: fileInputConfig,
    onDropSuccess: () => null,
    onDropFailure: () => null,
    disabled: false,
};

export default memo(FileUploader);
