import { fade, makeStyles } from '@material-ui/core';

export const useFloorRowCardStyles = makeStyles((theme) => ({
    container: () => ({
        alignItems: 'center',
        display: 'flex',
        height: 72,
        padding: theme.spacing(0, 2),
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    }),
    activeContainer: {
        background: `linear-gradient(0deg, ${fade(
            theme.palette.primary.main,
            0.1,
        )}, ${fade(theme.palette.primary.main, 0.1)}), ${theme.palette.common.white}`,
    },
    clickableContainer: {
        cursor: 'pointer',
    },
}));
