import type { Location, LocationDescriptorObject } from 'history';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import reduce from 'lodash/reduce';
import type { ParsedQs } from 'qs';
import qs from 'qs';

import type { ParamsHandlers, FormatParamsHandlers } from './types';

const isParamValid = (param: unknown): boolean => {
    if (
        isNil(param) ||
        isUndefined(param) ||
        param === '' ||
        (Array.isArray(param) && param.length === 0)
    ) {
        return false;
    }

    return true;
};

class QueryParamsManager<TParams, TFormattedParams> {
    private formattingParamsHandlers: FormatParamsHandlers;

    private parsingParamsHandlers: ParamsHandlers;

    private paramsSet: Set<string>;

    orderByParamName?: 'ordering' | 'order_by';

    constructor(
        paramsSet: Set<string>,
        formattingParamsHandlers: FormatParamsHandlers,
        parsingParamsHandlers: ParamsHandlers,
        orderByParamName?: 'ordering' | 'order_by',
    ) {
        this.paramsSet = paramsSet;
        this.orderByParamName = orderByParamName;
        this.parsingParamsHandlers = parsingParamsHandlers;
        this.formattingParamsHandlers = formattingParamsHandlers;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private cleanParams<TCleanedParams>(params: { [key: string]: any }): TCleanedParams {
        return reduce(
            params,
            (acc, param, key) => {
                if (
                    !this.paramsSet.has(key) ||
                    isNil(param) ||
                    param === '' ||
                    (Array.isArray(param) && param.length === 0)
                ) {
                    return acc;
                }

                return { ...acc, [key]: param };
            },
            {} as TCleanedParams,
        );
    }

    private formatParams(params: ParsedQs): TFormattedParams {
        return reduce(
            params,
            (acc, value, key) => {
                if (!this.paramsSet.has(key) || !isParamValid(value)) {
                    return acc;
                }

                return {
                    ...acc,
                    [key]: this.formattingParamsHandlers[key]
                        ? this.formattingParamsHandlers[key](value)
                        : value,
                };
            },
            {} as TFormattedParams,
        );
    }

    private parseParams(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: { [key: string]: any },
        options?: Record<string, unknown>,
    ): TParams {
        return reduce(
            params,
            (acc, value, key) => {
                if (!this.paramsSet.has(key) || !isParamValid(value)) {
                    return acc;
                }

                const handlers = this.parsingParamsHandlers;

                return {
                    ...acc,
                    [key]: handlers[key]
                        ? handlers[key](value, undefined, undefined, options)
                        : value,
                };
            },
            {} as TParams,
        );
    }

    private getLocationSearch = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        searchParams: { [key: string]: any },
        search: string,
    ): string => {
        const previousParams = qs.parse(search, { ignoreQueryPrefix: true, comma: true });

        const result = this.cleanParams<TParams>({
            ...previousParams,
            ...searchParams,
        });

        return Object.keys(result).length
            ? qs.stringify(result, { arrayFormat: 'comma', encode: false })
            : '';
    };

    buildQuery = (
        search: string,
        initialQuery?: TParams | null,
        options?: Record<string, unknown>,
    ): TParams => {
        if (search) {
            const params = qs.parse(search, {
                ignoreQueryPrefix: true,
                comma: true,
            });

            const parsedParams = this.parseParams(params, options);
            const resultParams = this.cleanParams<TParams>(parsedParams);

            if (isEmpty(resultParams)) {
                return initialQuery || ({} as TParams);
            }

            return { ...(initialQuery || ({} as TParams)), ...resultParams };
        }

        return initialQuery || ({} as TParams);
    };

    formatQueryParams = (search: string): TFormattedParams => {
        if (search) {
            const params = qs.parse(search, {
                ignoreQueryPrefix: true,
                comma: true,
            });

            const parsedParams = this.formatParams(params);
            const resultParams = this.cleanParams<TFormattedParams>(parsedParams);

            if (isEmpty(resultParams)) {
                return {} as TFormattedParams;
            }

            return resultParams;
        }

        return {} as TFormattedParams;
    };

    onSearch = (
        { pathname, search }: Location,
        searchParams: TParams,
    ): LocationDescriptorObject => {
        return {
            pathname,
            search: this.getLocationSearch(searchParams, search),
        };
    };

    onOrder = (
        { pathname, search }: Location,
        orderValue?: string | null,
    ): LocationDescriptorObject => {
        if (!this.orderByParamName) {
            throw new Error('[order_by] param name is not provided!');
        }

        return {
            pathname,
            search: this.getLocationSearch(
                { [this.orderByParamName]: orderValue },
                search,
            ),
        };
    };
}

export default QueryParamsManager;
