import {
    useBuildingSelect,
    LocationPicker,
    generateQueryString,
    generateSelectionFromQueryString,
} from '@infogrid/components-locations';
import type { ILocationSelection } from '@infogrid/components-locations';
import type { LocationValues } from '@infogrid/dashboards-constants';
import type { SensorType } from '@infogrid/sensors-constants';
import { CircularProgress, Typography } from '@material-ui/core';
import type { FormikProps } from 'formik';
import { useMeasure } from 'react-use';

import { useLocationsStyles } from './styles';

interface LocationsProps<T> {
    formik: FormikProps<T>;
    sensorTypes?: SensorType[];
    isMappedSensors?: boolean;
    buildingsOnly?: boolean;
    filterSensorTypesExist?: boolean;
    description?: string;
}

const IS_NOT_IN_SPACE_OPTION_HIDDEN = false;

export const Locations = <T extends LocationValues>({
    formik,
    sensorTypes,
    isMappedSensors = true,
    buildingsOnly = false,
    filterSensorTypesExist = false,
    description,
}: LocationsProps<T>): JSX.Element => {
    const styles = useLocationsStyles();

    const {
        setFieldValue,
        values: { locations },
    } = formik;

    const { buildings, fetchMore, hasNextPage, isFetchedAfterMount, isLoading } =
        useBuildingSelect({
            fetchAllInitially: true,
            isMappedSensors,
            sensorTypes,
            filterSensorTypesExist,
        });

    const selection = generateSelectionFromQueryString(
        locations,
        buildings,
        IS_NOT_IN_SPACE_OPTION_HIDDEN,
    );

    const [ref, { width }] = useMeasure<HTMLDivElement>();

    const onChange = (newSelection: ILocationSelection) => {
        const newSelectionString = generateQueryString(
            newSelection,
            buildings,
            IS_NOT_IN_SPACE_OPTION_HIDDEN,
        );

        if (newSelectionString !== locations) {
            setFieldValue('locations', newSelectionString);
        }
    };

    if (isLoading) {
        return (
            <div className={styles.loadingSpinner}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <>
            {description && (
                <Typography
                    variant="body2"
                    color="textSecondary"
                    className={styles.description}
                >
                    {description}
                </Typography>
            )}
            <div ref={ref}>
                <LocationPicker
                    buildings={buildings}
                    fetchMore={fetchMore}
                    hasNextPage={hasNextPage}
                    isFetchedAfterMount={isFetchedAfterMount}
                    isLoading={false}
                    onChange={onChange}
                    selection={selection}
                    width={width}
                    isNotInSpaceOptionHidden={IS_NOT_IN_SPACE_OPTION_HIDDEN}
                    buildingsOnly={buildingsOnly}
                />
            </div>
        </>
    );
};
