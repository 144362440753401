import type {
    HierarchyBuilding,
    HierarchyFloor,
    HierarchySpace,
} from '@infogrid/locations-types';
import type { Label } from '@infogrid/sensors-constants';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import omit from 'lodash/omit';
import { memo } from 'react';

import { useBuildingsHierarchy } from 'apiHooks/floorPlan/buildings/hooks';
import { useLabels } from 'apiHooks/labels/hooks';
import { useCreateFeedbackPanel } from 'apiHooks/sensors/feedbackPanels/hooks/useCreateFeedbackPanel';
import type { InstallationFlowComponentProps } from 'components/InstallationFlow/types';

import { useInstallationFlowContext } from '../../context';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../../reducer';
import Configuration from '../ConfigurationComponent';
import { useConfigurationFormik } from '../utils';
import { NO_SPACE_OPTION } from '../utils/consts';
import type { ConfigurationFormValues } from '../utils/types';

type Props = Pick<InstallationFlowComponentProps, 'resetProgress'>;

const FeedbackPanelConfigurationContainer = ({ resetProgress }: Props) => {
    const { mutate: createFeedbackPanel } = useCreateFeedbackPanel();
    const { createFeedbackPanelValues } = useInstallationFlowContext();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    if (!createFeedbackPanelValues) {
        throw new Error('createFeedbackPanelValues must not be undefined');
    }

    const deviceType = SENSOR_TYPE.TYPE_FEEDBACK_PANEL;

    const {
        // @ts-expect-error - WEB-4196 fix this when typescripting useLabels
        data: { labels },
    } = useLabels();

    const { data: buildingHierarchy } = useBuildingsHierarchy();

    const { floorMap = {}, buildingsData = [] } = buildingHierarchy ?? {};

    const formik = useConfigurationFormik({
        deviceType,
        floorMap,
        buildingsData,
        onSubmit: (formValues: ConfigurationFormValues) => {
            const {
                building,
                floor,
                space,
                labels: labelsValues,
            } = formValues as {
                building: HierarchyBuilding;
                floor: HierarchyFloor;
                space: HierarchySpace | null;
                labels: Label[];
            };

            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
            });

            createFeedbackPanel(
                {
                    ...omit(formValues, ['space', 'temperature_offset', 'sub_type']),
                    // The backend API endpoint expects only IDs
                    building: building.id,
                    floor: floor?.id,
                    /**
                     * INFO: we are mapping 'space' to 'room' here
                     * because in the nearest future we plan to get
                     * rid of 'room' and use 'space' instead but
                     * its not changed on the API side yet
                     */
                    room: NO_SPACE_OPTION.id === space?.id ? null : space?.id,
                    labels: labelsValues.map((label) => label.id),
                    sensor_configuration: createFeedbackPanelValues.sensor_configuration,
                },
                {
                    onSuccess: () => {
                        dispatchInstallationFlowContext({
                            type: INSTALLATION_FLOW_ACTIONS.FEEDBACK_PANEL_INSTALLATION_SUCCEED,
                        });
                    },
                    onError: (err) => {
                        if (err.parsedError) {
                            const { errors: formikErrors, message } = err.parsedError;

                            if (formikErrors) {
                                formik.setErrors(formikErrors);
                            }

                            if (message) {
                                formik.setStatus({ message });
                            }
                        }
                    },
                    onSettled: () => {
                        dispatchInstallationFlowContext({
                            type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
                        });
                    },
                },
            );
        },
    });

    return (
        <Configuration
            formik={formik}
            deviceType={deviceType}
            buildingsData={buildingsData}
            labels={labels}
            floorMap={floorMap}
            resetProgress={resetProgress}
        />
    );
};

export default memo(FeedbackPanelConfigurationContainer);
