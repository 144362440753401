import { Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import type { CorrigoCredentials } from 'views/integrations/components/corrigo/types';

import { useCorrigoStyling } from './styles';

interface Props {
    credentials: Omit<CorrigoCredentials, 'client_secret'> | undefined;
    reset: () => void;
}

const CorrigoCredentialsDisplay = ({ credentials, reset }: Props): JSX.Element => {
    const { t } = useTranslation('integrations');

    const styles = useCorrigoStyling();

    const CorrigoCredentialRow = ({
        children,
    }: {
        children: React.ReactNode | React.ReactNode[];
    }): JSX.Element => {
        return (
            <Typography className={styles.credentialDisplayData}>{children}</Typography>
        );
    };

    return (
        <>
            <Alert severity="success">
                {t('You have successfully authenticated your details with Corrigo.')}
            </Alert>
            <>
                <CorrigoCredentialRow>
                    Client ID: {credentials?.client_id}
                </CorrigoCredentialRow>
                <CorrigoCredentialRow>
                    Client secret: *****************
                </CorrigoCredentialRow>
                <CorrigoCredentialRow>
                    Company name: {credentials?.company_name}
                </CorrigoCredentialRow>
                <CorrigoCredentialRow>
                    Authentication URL: {credentials?.auth_url}
                </CorrigoCredentialRow>
                <CorrigoCredentialRow>
                    Base URL: {credentials?.base_url}
                </CorrigoCredentialRow>
            </>
            <Button onClick={reset} variant="outlined" color="primary">
                {t('Reset details')}
            </Button>
        </>
    );
};

export default CorrigoCredentialsDisplay;
