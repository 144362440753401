import { theme } from '@infogrid/components-material-ui';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import type {
    DataDisplayNames,
    IndoorAirQualityData,
    IndoorAirQualityChartRow,
} from 'views/dashboards/widgets/IndoorAirQualityWidget/types';

export const generateChartData = (
    data: IndoorAirQualityData,
    dataDisplayNames: DataDisplayNames = {
        highRiskLabel: '',
        mediumRiskLabel: '',
        lowRiskLabel: '',
    },
): IndoorAirQualityChartRow[] => {
    const { airthings_quality_graph_rows } = data || {};

    const timestamps = airthings_quality_graph_rows?.map((row) => row.timestamp) || [];

    const transformedData: IndoorAirQualityChartRow[] = [
        {
            name: registerTranslationKey('Poor'),
            displayName: dataDisplayNames.highRiskLabel,
            data: [],
            color: theme.palette.common.red2,
            timestamps,
            turboThreshold: 0,
        },
        {
            name: registerTranslationKey('Fair'),
            displayName: dataDisplayNames.mediumRiskLabel,
            data: [],
            color: theme.palette.common.yellow2,
            timestamps,
            turboThreshold: 0,
        },
        {
            name: registerTranslationKey('Good'),
            displayName: dataDisplayNames.lowRiskLabel,
            data: [],
            color: theme.palette.common.green2,
            timestamps,
            turboThreshold: 0,
        },
    ];

    /* eslint-disable no-unused-expressions */
    airthings_quality_graph_rows?.forEach((row) => {
        const { POOR = 0, FAIR = 0, GOOD = 0 } = row?.quality || {};

        const timestamp = +new Date(row.timestamp);

        transformedData[0].data.push([timestamp, POOR]);
        transformedData[1].data.push([timestamp, FAIR]);
        transformedData[2].data.push([timestamp, GOOD]);
    });
    /* eslint-enable no-unused-expressions */

    return transformedData;
};
