import { useEffectOnMount, useSelectorWithArgs } from '@infogrid/utils-hooks';
import { entitiesSelectors, EntityStatus } from '@thorgate/spa-entities';
import { useEffect } from 'react';

/**
 * Hook to do initial fetch for an entity.
 *
 * Will only run if entity status is not set / NotLoaded.
 * If fetchOnMount 0 will run on mount.
 * If fetchOnReset 0 will run whenever [fetchOnReset, notLoaded, onFetch] changes.
 * @param onFetch - Functioned called as initial fetch.
 * @param fetchOnMount - Whether to enable fetching during mounting.
 * @param fetchOnReset - Whether to enable fetching when dependencies change.
 * @param statusKey - Status key used to check if entity has loaded.
 */
export const useDoInitialFetchEffect = (
    onFetch: () => void,
    fetchOnMount = false,
    fetchOnReset = false,
    statusKey = '',
): void => {
    const status = useSelectorWithArgs(entitiesSelectors.selectEntitiesStatus, statusKey);
    const notLoaded = status === EntityStatus.NotLoaded;

    useEffectOnMount(() => {
        // Don't need this when using `fetchOnReset` since the next `useEffect` will trigger on mount too
        if (fetchOnMount && notLoaded && !fetchOnReset) {
            onFetch();
        }
    });
    useEffect(() => {
        if (fetchOnReset && notLoaded) {
            onFetch();
        }
    }, [fetchOnReset, notLoaded, onFetch]);
};
