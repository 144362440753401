import { routesManager } from '@infogrid/core-routing';
import { createFetchAction, createFetchSaga } from '@thorgate/spa-entities';
import { takeEveryWithMatch } from '@thorgate/spa-view-manager';
import { call } from 'redux-saga/effects';

import { createPaginationSuccessHook } from 'ducks/pagination';
import { relatedSensorsKeyFn } from 'schemas/folder';
import { sensorSchema } from 'schemas/sensor';
import api from 'services/api';
import {
    buildQueryFromLocation,
    SENSOR_PARAM_NAMES,
} from 'utils/filtering/legacy/sensor';
import { folderMutateKwargs } from 'utils/folder';
import { mutateSensorsResponse } from 'utils/sensor';
import { serializeData } from 'utils/serializeData';

export const fetchFolderSensors = createFetchAction('@@sagas/folders/sensors/fetch');

const toCommaArrayFormat = (value) => value.join(',');

const paginationSuccessHook = createPaginationSuccessHook(relatedSensorsKeyFn, true);

function* successHook(result, match, action) {
    yield paginationSuccessHook(result, match, action);
}

const fetchFolderSensorsWorker = createFetchSaga({
    resource: api.folder.sensors,
    successHook,
    key: relatedSensorsKeyFn,
    listSchema: [sensorSchema],
    serializeData,

    mutateResponse: mutateSensorsResponse,
    mutateKwargs: folderMutateKwargs,
    *mutateQuery(match, action) {
        if (action?.meta?.keyOptions?.filtered === false) {
            return action?.payload?.query;
        }

        // a hack for SensorConfiguration component
        // to prevent taking a location query instead of passed query
        const filterName = action?.meta?.keyOptions?.filterName ?? '';

        if (filterName.startsWith(`${SENSOR_PARAM_NAMES.TYPE}=`)) {
            return action?.payload?.query;
        }

        const params = yield call(buildQueryFromLocation, action?.payload?.query);

        if (params[SENSOR_PARAM_NAMES.LABELS]) {
            params[SENSOR_PARAM_NAMES.LABELS] = toCommaArrayFormat(
                params[SENSOR_PARAM_NAMES.LABELS],
            );
        }

        if (params[SENSOR_PARAM_NAMES.LABELS_OR]) {
            params[SENSOR_PARAM_NAMES.LABELS_OR] = toCommaArrayFormat(
                params[SENSOR_PARAM_NAMES.LABELS_OR],
            );
        }

        return params;
    },
});

export function* fetchFolderSensorsWatcher() {
    yield takeEveryWithMatch(
        fetchFolderSensors.getType(),
        routesManager.resolvePattern('folders:details'),
        fetchFolderSensorsWorker,
    );
}
