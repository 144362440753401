import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { TEMP_UNIT, TEMP_UNIT_SYMBOL } from '@infogrid/utils-measurements';
import {
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    FormHelperText,
    TextField,
    Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SensorSubTypesShape } from 'utils/types';
import { trackInstallFlowUserEvent } from 'views/buildings/pages/installFlow/utils/analytics';

import { useConfigurationStyles } from '../../styles';

const HelperText = ({ tempOffsetError, userPreferredTempUnit, isOnline }) => {
    const { t } = useTranslation('sensors');

    if (tempOffsetError) {
        return (
            <Typography color="error" variant="caption">
                {tempOffsetError}
            </Typography>
        );
    }

    if (isOnline === false) {
        return (
            <FormHelperText>
                {t('Unable to record offset while sensor is offline.')}
            </FormHelperText>
        );
    }

    return (
        <FormHelperText>
            {t('Set the temperature offset ({{unit}})', {
                unit: TEMP_UNIT_SYMBOL[userPreferredTempUnit],
                defaultValue: `Set the temperature offset (${TEMP_UNIT_SYMBOL[userPreferredTempUnit]})`,
            })}
        </FormHelperText>
    );
};

const LegionnaireConfiguration = ({
    subTypes,
    values,
    handleChange,
    handleBlur,
    subTypeError,
    userPreferredTempUnit,
    tempOffsetError,
    supportsLiveCalibration,
    isOnline,
}) => {
    const { t } = useTranslation('sensors');
    const classes = useConfigurationStyles();

    const subTypeOptions = useMemo(() => Object.keys(subTypes), [subTypes]);

    /**
     * Prevent the user from inserting non-numeric characters
     * TODO make this inputfield into a shared NumericField component
     */
    const onKeyPress = (e) => {
        if (!/[0-9.,-]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const handleChangeWithTracking = (event, child) => {
        trackInstallFlowUserEvent(USER_ACTIONS.SUBMIT, 'Changed Pipe type', {
            before: values.sub_type,
            after: event.target.value,
        });
        handleChange(event, child);
    };

    return (
        <>
            <FormControl
                fullWidth
                variant="outlined"
                className={classes.formField}
                error={!!subTypeError}
            >
                <InputLabel id="sub_type">{t('Pipe type *')}</InputLabel>
                <Select
                    id="sub_type"
                    name="sub_type"
                    label={t('Pipe type')}
                    data-cypress="sub-type"
                    value={values.sub_type}
                    onChange={handleChangeWithTracking}
                    onBlur={handleBlur}
                    fullWidth
                    variant="outlined"
                >
                    {subTypeOptions.map((option) => (
                        <MenuItem value={option} key={option} aria-label={option}>
                            {subTypes[option]}
                        </MenuItem>
                    ))}
                </Select>
                {subTypeError ? (
                    <Typography color="error" variant="caption">
                        {subTypeError}
                    </Typography>
                ) : (
                    <FormHelperText>{t('Select pipe type')}</FormHelperText>
                )}
            </FormControl>
            {!supportsLiveCalibration && (
                <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formField}
                    error={!!tempOffsetError}
                >
                    <TextField
                        id="temperature_offset"
                        name="temperature_offset"
                        label={t('Temperature offset')}
                        variant="outlined"
                        data-cypress="temperature-offset"
                        fullWidth
                        value={values.temperature_offset || ''}
                        inputProps={{ inputMode: 'decimal' }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!tempOffsetError}
                        onKeyPress={onKeyPress}
                        disabled={isOnline === false}
                    />
                    <HelperText
                        tempOffsetError={tempOffsetError}
                        userPreferredTempUnit={userPreferredTempUnit}
                        isOnline={isOnline}
                    />
                </FormControl>
            )}
        </>
    );
};

LegionnaireConfiguration.propTypes = {
    subTypes: SensorSubTypesShape,
    values: PropTypes.shape({
        temperature_offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        sub_type: PropTypes.string,
    }).isRequired,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    subTypeError: PropTypes.string,
    tempOffsetError: PropTypes.string,
    userPreferredTempUnit: PropTypes.oneOf(Object.values(TEMP_UNIT)),
    supportsLiveCalibration: PropTypes.bool,
};

LegionnaireConfiguration.defaultProps = {
    subTypes: {},
    handleChange: () => {},
    handleBlur: () => {},
    subTypeError: '',
    tempOffsetError: '',
    userPreferredTempUnit: TEMP_UNIT.CELSIUS,
    supportsLiveCalibration: false,
};

export default memo(LegionnaireConfiguration);
