import { makeStyles } from '@material-ui/core';

export const useCorrigoStyling = makeStyles((theme) => ({
    wrapper: {
        padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '24px',
    },
    credentialDisplayData: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
    },
    steps: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 20,
    },
    step: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `1px solid ${theme.palette.common.shell}`,
        padding: '20px',
    },
    credentialsForm: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        maxWidth: 400,
        width: '100%',
    },
    credentialsFormButton: {
        alignSelf: 'flex-start',
    },
    uploadInput: {
        display: 'none',
    },
}));
