import { theme, Tooltip } from '@infogrid/components-material-ui';
import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { getSensor } from '@infogrid/sensors-configuration';
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    sensor: GenericSensorShape;
    className?: string;
    classes?: {
        icon?: string;
        tooltipAnchor?: string;
    };
    size?: number;
    color?: string;
}

const defaultClasses = {
    icon: '',
    tooltipAnchor: '',
};

const SensorTypeIcon = ({
    sensor,
    className = '',
    size = 30,
    color = theme.palette.common.gray8,
    classes = defaultClasses,
}: Props) => {
    const { t } = useTranslation('sensors');
    const sensorConfig = getSensor(sensor);
    const Icon = sensorConfig.getIcon();

    const tooltipClasses = useMemo(
        () => ({
            anchor: classes.tooltipAnchor,
        }),
        [classes.tooltipAnchor],
    );

    return (
        <Tooltip title={t(sensorConfig.label)} classes={tooltipClasses}>
            <Icon
                className={classNames(className, classes.icon)}
                color={color}
                size={size}
                data-cypress={`sensor-icon-${sensor.type_code}`}
            />
        </Tooltip>
    );
};

export default memo(SensorTypeIcon);
