import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { useBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import { useFloor } from 'apiHooks/floorPlan/floors/hooks';
import logo from 'styles/images/logo-white-text.svg';
import { toggleIntercomButton } from 'utils/intercom';

import { PublicDisplayFloorPlan } from './components/PublicDisplayFloorPlan';
import { PublicDisplayHeader } from './components/PublicDisplayHeader/PublicDisplayHeader';
import { PublicDisplayWidgetGrid } from './components/PublicDisplayWidgetGrid';
import { usePublicDisplayStyles } from './styles';

export const PublicDisplay = (): JSX.Element => {
    const styles = usePublicDisplayStyles();

    const { t } = useTranslation();

    // Disable Intercom for this page (I don't think there's currently a better way)
    useEffectOnce(() => toggleIntercomButton(true));

    const { buildingId, floorId } = useParams<{
        buildingId: string;
        floorId: string;
    }>();

    const {
        building,
        error: buildingError,
        isLoading: isBuildingLoading,
    } = useBuilding(+buildingId);
    const {
        data: floor,
        error: floorError,
        isLoading: isFloorLoading,
    } = useFloor(+floorId);

    if (isBuildingLoading || isFloorLoading) {
        return (
            <div className={styles.loadingSpinner}>
                <CircularProgress />
            </div>
        );
    }

    if (buildingError?.response?.status === 404 || floorError?.response?.status === 404) {
        return <Redirect to="/not-found" />;
    }

    if (!building || !floor) {
        return <Alert severity="error">{t('Failed to load floor data')}</Alert>;
    }

    return (
        <div className={styles.container}>
            <Grid container className={styles.content}>
                <Grid className={styles.leftPanel}>
                    <PublicDisplayHeader
                        buildingName={building.name}
                        floorName={floor.name}
                    />
                    <PublicDisplayWidgetGrid
                        buildingId={building.id}
                        floorId={floor.id}
                    />
                </Grid>

                <Grid className={styles.rightPanel}>
                    <PublicDisplayFloorPlan floor={floor} />
                </Grid>
            </Grid>
            <div className={styles.footer}>
                <Typography variant="h4">{t('Powered by')}</Typography>
                <img alt={t('Infogrid logo')} className={styles.logo} src={logo} />
            </div>
        </div>
    );
};
