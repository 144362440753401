import { createContext, useContext } from 'react';

import StepsManager from './StepsManager';
import { INSTALLATION_FLOW_STEPS } from './constants';
import type { InstallationFlowContextStateType } from './types';

export const INSTALLATION_FLOW_INITIAL_PATH = [INSTALLATION_FLOW_STEPS.qrCodeScanner];
export const INSTALLATION_FLOW_INITIAL_CONTEXT = {
    registerInfo: '',
    isLoading: false,
    isOverwriteConfirmed: false,
    path: INSTALLATION_FLOW_INITIAL_PATH,
};

const InstallationFlowContext = createContext<
    Omit<InstallationFlowContextStateType, 'step'>
>(INSTALLATION_FLOW_INITIAL_CONTEXT);

export const useInstallationFlowContext = (): InstallationFlowContextStateType => {
    const context = useContext(InstallationFlowContext);

    return { ...context, step: StepsManager.getStep(context.path) };
};

export default InstallationFlowContext;
