import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { SensorShape } from '@infogrid/sensors-constants';
import { useRefetchOnLanguageChange } from '@infogrid/utils-i18n';
import { useQuery, useQueryClient } from 'react-query';
import type { QueryClient, UseQueryResult, UseQueryOptions } from 'react-query';

import { mutateSensor } from 'utils/sensor';

import { controllers } from '../controllers';
import { getSensorKey } from '../getQueryKeys';

export const querySensor = (
    deviceName: string,
    queryClient: QueryClient,
    signal?: AbortSignal,
) =>
    wrapResponsePromise<SensorShape>(
        controllers.getSensor(deviceName, signal),
        ({ data }) => {
            const mutatedSensor = mutateSensor(data);

            // retrieves previous data to save extra fields
            const oldSensor = queryClient.getQueryData(
                getSensorKey(deviceName),
            ) as SensorShape;

            return { ...oldSensor, ...mutatedSensor } as SensorShape;
        },
    );

export const useSensor = (
    deviceName?: string,
    params: UseQueryOptions = {},
): UseQueryResult<SensorShape, AxiosParsedError> => {
    const queryClient = useQueryClient();

    const queryResult = useQuery(
        getSensorKey(deviceName),
        // deviceName will be string if query enabled
        ({ signal }) => querySensor(deviceName as string, queryClient, signal),
        {
            keepPreviousData: true,
            retry: false,
            // TODO: Why is initialData null here? This marks query as successful, making status handling cumbersome.
            initialData: null,
            enabled: !!deviceName && params.enabled !== false,
            ...params,
        },
    ) as UseQueryResult<SensorShape, AxiosParsedError>;

    // Reload the sensor details on language change because we want to translate the sensor state information
    // that comes from back-end
    useRefetchOnLanguageChange(queryResult.refetch, params?.enabled ?? !!deviceName);

    return queryResult;
};
