import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo } from 'react';

import { useContainerStyles } from './styles';

interface Props {
    children: ReactNode;
    className?: string;
}

const Container = ({ children, className = '' }: Props) => {
    const styles = useContainerStyles();

    const containerClass = classNames(styles.container, className);

    return <div className={containerClass}>{children}</div>;
};

export default memo(Container);
