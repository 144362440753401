import { makeStyles } from '@material-ui/core';
import { red, green, orange } from '@material-ui/core/colors';

export const useBatteryLevelStyles = makeStyles(() => ({
    root: {
        width: '1.3em',
        height: '1.1em',
    },
    badLevel: {
        color: red[500],
    },
    middleLevel: {
        color: orange[600],
    },
    goodLevel: {
        color: green[700],
    },
}));
