import type { USER_ACTIONS } from '@infogrid/utils-analytics';
import { Analytic } from '@infogrid/utils-analytics';

export const trackReportsUserEvent = (
    userAction: USER_ACTIONS,
    eventName: string,
    properties?: Record<string, unknown>,
): void => {
    Analytic.trackActivity(`Reports`, `${userAction} ${eventName}`, properties);
};
