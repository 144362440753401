import { Modal } from '@infogrid/components-material-ui';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSpinner from 'components/ButtonSpinner';

import ConvertFolder from '../ConvertFolderModal/ConvertFolder';
import CreateFolder from '../shared/CreateFolder';
import BuildingDetails from './BuildingDetails';
import ChoosingFolderBuilding from './ChoosingFolderBuilding';
import CreatingPreview from './CreatingPreview';
import FinishingCreating from './FinishingCreating';
import { useCreatingBuildingStyles } from './styles';

const CreateBuildingModal = ({
    onClose,
    isOpened,
    selectedFolder,
    onExitedModal,
    //
    handleSubfoldersConverting,
    //
    openCreateFolderBuilding,
    openCreateFolderFloor,
    handleCreateBuilding,
    handleSelectFolder,
    handleCreatingFolder,
    handleCreatingFloor,
    //
    goToBuildingDetails,
    goToChoosingFolderFloor,
    goToChoosingFolderBuilding,
    //
    renderCreatingPreview,
    renderBuildingDetails,
    renderFinishingCreating,
    renderCreatingFolderFloor,
    renderCreatingFolderBuilding,
    renderChoosingFolderBuilding,
    renderChoosingFolderFloor,
    // ,
    expandedFolderPath,
    setExpandedFolderPath,
}) => {
    const stylesCreatingBuilding = useCreatingBuildingStyles();

    const classes = useMemo(
        () => ({
            container: stylesCreatingBuilding.container,
        }),
        [stylesCreatingBuilding.container],
    );

    const { t } = useTranslation('floorplan');

    const renderCreatingFloorActions = useCallback(
        ({ isSubmitting }) => (
            <Modal.Actions justify="right">
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    data-cypress="next"
                    disabled={isSubmitting}
                >
                    {isSubmitting && <ButtonSpinner />}
                    <span>{t('Next')}</span>
                </Button>
            </Modal.Actions>
        ),
        [t],
    );

    return (
        <Modal
            onClose={onClose}
            onExited={onExitedModal}
            open={isOpened}
            classes={classes}
        >
            {renderCreatingPreview && (
                <CreatingPreview
                    title={t('Create New Building')}
                    handleNext={goToChoosingFolderBuilding}
                />
            )}

            {renderChoosingFolderBuilding && (
                <ChoosingFolderBuilding
                    title={t('Create New Building')}
                    selectedFolder={selectedFolder}
                    expandedFolderPath={expandedFolderPath}
                    setExpandedFolderPath={setExpandedFolderPath}
                    handleNext={goToBuildingDetails}
                    handleSelectFolder={handleSelectFolder}
                    openCreateFolder={openCreateFolderBuilding}
                />
            )}

            {renderBuildingDetails && (
                <BuildingDetails
                    title={t('Create New Building')}
                    buildingName={selectedFolder?.name}
                    handleBack={goToChoosingFolderBuilding}
                    handleCreateBuilding={handleCreateBuilding}
                />
            )}

            {renderChoosingFolderFloor && (
                <ConvertFolder
                    buildingId={selectedFolder?.id}
                    title={t('Create Floor Folders')}
                    openCreateFolder={openCreateFolderFloor}
                    renderActions={renderCreatingFloorActions}
                    handleSubfoldersConverting={handleSubfoldersConverting}
                />
            )}

            {renderFinishingCreating && (
                <FinishingCreating
                    title={t('Create New Building')}
                    onClose={onClose}
                    createAnotherBuilding={goToChoosingFolderBuilding}
                />
            )}

            {/* It needs to separate to keep simplicity in terms of pulling handlers as props */}
            {renderCreatingFolderBuilding && (
                <CreateFolder
                    title={t('Create Folder Building')}
                    handleBack={goToChoosingFolderBuilding}
                    handleCreateFolder={handleCreatingFolder}
                />
            )}

            {renderCreatingFolderFloor && (
                <CreateFolder
                    title={t('Create New Floor')}
                    description={t('Enter floor name')}
                    label={t('Floor name')}
                    handleBack={goToChoosingFolderFloor}
                    handleCreateFolder={handleCreatingFloor}
                />
            )}
        </Modal>
    );
};

CreateBuildingModal.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    handleCreateBuilding: PropTypes.func.isRequired,
    onExitedModal: PropTypes.func.isRequired,

    handleSubfoldersConverting: PropTypes.func.isRequired,

    renderBuildingDetails: PropTypes.bool.isRequired,
    handleSelectFolder: PropTypes.func.isRequired,
    selectedFolder: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        contentLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    goToBuildingDetails: PropTypes.func.isRequired,
    //
    renderCreatingPreview: PropTypes.bool.isRequired,
    //
    goToChoosingFolderBuilding: PropTypes.func.isRequired,
    renderChoosingFolderBuilding: PropTypes.bool.isRequired,
    //
    renderChoosingFolderFloor: PropTypes.bool.isRequired,
    goToChoosingFolderFloor: PropTypes.func.isRequired,
    //
    renderFinishingCreating: PropTypes.bool.isRequired,
    //
    renderCreatingFolderBuilding: PropTypes.bool.isRequired,
    handleCreatingFolder: PropTypes.func.isRequired,
    //
    handleCreatingFloor: PropTypes.func.isRequired,

    renderCreatingFolderFloor: PropTypes.bool.isRequired,
    //
    openCreateFolderBuilding: PropTypes.func.isRequired,
    openCreateFolderFloor: PropTypes.func.isRequired,
    //
    expandedFolderPath: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ).isRequired,
    setExpandedFolderPath: PropTypes.func.isRequired,
};

CreateBuildingModal.defaultProps = {
    selectedFolder: null,
};

export default memo(CreateBuildingModal);
