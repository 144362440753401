import { makeStyles } from '@material-ui/core';

export const useOccupancySectionStyles = makeStyles((theme) => ({
    colorCircle: ({ color }) => ({
        color,
        fontSize: '12px',
        marginRight: theme.spacing(1),
    }),
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    title: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    formControlLabel: {
        marginBottom: 0,
    },
    gridItemCentered: {
        textAlign: 'center',
    },
    outerGrid: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}));
