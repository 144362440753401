import { useAppSelector } from '@infogrid/core-ducks';
import { getUser } from '@infogrid/user-ducks';
import { useIsMobile } from '@infogrid/utils-hooks';

import { isIntercomAvailable, isIntercomAvailableForUser } from 'utils/intercom';

export const useIsIntercomEnabled = (): boolean =>
    !useIsMobile() && isIntercomAvailable();

export const useIsIntercomEnabledForUser = (): boolean => {
    const isMobile = useIsMobile();
    const user = useAppSelector(getUser);

    if (user === null) {
        return false;
    }

    return !isMobile && isIntercomAvailableForUser(user);
};
