import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { RouteConfigComponentProps } from 'react-router-config';

import withView from 'decorators/withView';
import { useGetSmartCleaningHierarchy } from 'views/solutionSettings/apiHooks/useGetSmartCleaningHierarchy';

import ResetTime from './ResetTime';

interface Props {
    route: RouteConfigComponentProps['route'];
}

const ResetTimeContainer = ({ route }: Props) => {
    const { t } = useTranslation('solutions');
    const [currentPageId, setCurrentPageId] = useState<number>();

    const {
        data: locations,
        isLoading,
        isFetching,
        refetch,
    } = useGetSmartCleaningHierarchy({
        reset_times: true,
        folder_id: currentPageId,
    });

    const changePage = (id: number) => {
        setCurrentPageId(id);
    };

    return (
        <>
            <Helmet defaultTitle={t(route?.pageName || 'Manage solutions')} />
            <ResetTime
                locationsIsLoading={isLoading || isFetching}
                locations={locations}
                currentPageId={currentPageId}
                changePage={changePage}
                refetch={refetch}
            />
        </>
    );
};

export default withView()(ResetTimeContainer);
