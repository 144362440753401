import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useAddFloorModalStyles = makeStyles((theme) => ({
    modalTitle: {
        alignItems: 'center',
        display: 'flex',
        '& > h2': {
            alignItems: 'center',
            display: 'flex',
        },
    },
    modalTitleIcon: {
        alignItems: 'center',
        display: 'flex',
        fontSize: 24,
        height: 26,
        marginRight: theme.spacing(2),
    },
    form: {
        height: '100%',
    },
    formContent: {
        minHeight: 'calc(100% - 62px)',
        padding: theme.spacing(3),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '630px',
        },
    },
    saveButton: {
        marginLeft: theme.spacing(3),
        minWidth: 90,
    },
    header: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
    capacityText: {
        margin: theme.spacing(1, 0, 2, 0),
    },
}));
