import { useField } from 'formik';
import { memo, useCallback } from 'react';

import WebhookAuthMethodField from './WebhookAuthMethodField';

interface Props {
    name: string;
    canChange: boolean;
}

const getBooleanStringValue = (value: boolean | undefined) => {
    switch (value) {
        case undefined:
            return '';
        case true:
            return 'true';
        case false:
            return 'false';
        default:
            throw Error(`Invalid type for boolean field value: ${typeof value}`);
    }
};

const WebhookAuthMethodFieldContainer = ({ name, canChange }: Props) => {
    const [{ value, onBlur }, { touched, error }, { setValue }] = useField<boolean>(name);
    const stringValue = getBooleanStringValue(value);

    const onChange = useCallback(
        (e) => {
            setValue(e.target.value === 'true');
        },
        [setValue],
    );

    return (
        <WebhookAuthMethodField
            name={name}
            canChange={canChange}
            value={value}
            stringValue={stringValue}
            touched={touched}
            error={error}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};

export default memo(WebhookAuthMethodFieldContainer);
