import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import PipeMonitoringPreview from 'styles/images/widgets/pipe-monitoring.png';

import PipeMonitoringWidget from './PipeMonitoringWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('Pipe Monitoring', { ns: 'dashboard' }),
    type: WIDGET_TYPE.PIPE_MONITORING,
    featureFlags: [],
    Component: PipeMonitoringWidget,
    image: PipeMonitoringPreview,
    order: 9,
    gridConfig: DEFAULT_GRID_ITEM_CONFIG,
};

export default PipeMonitoringWidget;
