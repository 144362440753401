import { registerTranslationKey } from '@infogrid/utils-i18n';
import type { Color } from '@material-ui/lab';

import type { IdentifySensorHelpText } from './types';

export const TIMEOUT_HELP_MESSAGE: IdentifySensorHelpText = {
    info: [
        registerTranslationKey(
            'If you have tried touching your sensor for 3 second intervals a few times and nothing has happened, your sensor may be offline.',
            { ns: 'sensors' },
        ),
        registerTranslationKey(
            'Please check that a Cloud Connector has been installed and is online before trying again.',
            { ns: 'sensors' },
        ),
        <>
            <>
                {registerTranslationKey(
                    'Guidance for installing Cloud Connectors can be found ',
                    { ns: 'sensors' },
                )}
            </>
            <a href="https://help.infogrid.io/en/collections/2717814-disruptive-technologies-dt">
                {registerTranslationKey('here', { ns: 'sensors' })}
            </a>
            <>{'.'}</>
        </>,
        registerTranslationKey(
            'If a Cloud Connector has been installed and is online but touching your sensor is not working, please reach out to customer support via the chat icon in the bottom right hand corner of your screen for further troubleshooting advice.',
            { ns: 'sensors' },
        ),
    ],
    level: 'info' as Color,
};
