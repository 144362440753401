import { fade, makeStyles } from '@material-ui/core';

export const useMobileSolutionsSidebarStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
    },
    integrationItem: {
        width: '100%',
        padding: '14px 26px 14px 9px',

        color: theme.palette.text.primary,

        textDecoration: 'none',
        fontWeight: 'bold',

        overflow: 'hidden',

        '&:hover, &:focus': {
            color: theme.palette.text.primary,
            textDecoration: 'none',
            outline: 'none',
        },
    },
    integrationItemActive: {
        backgroundColor: fade(theme.palette.common.gray6, 0.2),
    },
    text: {
        marginLeft: '10px',
        fontSize: '14px',
        userSelect: 'none',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    noMargins: {
        margin: '0px',
        padding: '0px',
    },
}));
