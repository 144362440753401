import { createRequest } from '@infogrid/core-api';
import type { AxiosPromise } from 'axios';

import type {
    PaginatedTreeChildNode,
    PaginatedTreeCurrentNode,
} from '../components/PaginatedTreeSelector/types';
import { flattenToPaginatedTree } from '../pages/smartCleaning/Locations/utils';
import type { ActivatedDeactivedNodes } from '../types';
import type {
    SmartCleaningHierarchyParams,
    SmartCleaningHierarchyResponse,
    SmartCleaningReportPayload,
    SmartCleaningReportsResponse,
    ResetTimesPayload,
    CleaningRulesPayload,
    ResetTimesResponse,
    CleaningRulesResponseItem,
    SmartCleaningActivatedCountResponse,
    SmartCleaningActivatedParams,
    SetActivatedDeActivatedSpacesPayload,
} from './types';

const setSensorResetTimes = (
    data: ResetTimesPayload,
): AxiosPromise<ResetTimesResponse[]> =>
    createRequest<ResetTimesResponse[]>({
        method: 'post',
        url: `/smart-cleaning-reset-times`,
        options: { data },
    });

const setCleaningRules = (
    data: CleaningRulesPayload,
): AxiosPromise<CleaningRulesResponseItem[]> =>
    createRequest<CleaningRulesResponseItem[]>({
        method: 'post',
        url: `/smart-cleaning-rules`,
        options: { data },
    });

const getSmartCleaningHierarchy = (
    params: SmartCleaningHierarchyParams,
): Promise<SmartCleaningHierarchyResponse> => {
    const requestPromise = createRequest<SmartCleaningHierarchyResponse>({
        method: 'get',
        url: '/smart-cleaning-hierarchy',
        options: {
            params,
        },
    });

    return requestPromise.then(({ data }) => data);
};

const getSmartCleaningHierarchyLocations = (
    params: SmartCleaningHierarchyParams,
    newlySelectedOrDeselectedNodes: ActivatedDeactivedNodes,
): Promise<PaginatedTreeCurrentNode<PaginatedTreeChildNode>> => {
    const requestPromise = createRequest<SmartCleaningHierarchyResponse>({
        method: 'get',
        url: '/smart-cleaning-hierarchy',
        options: {
            params,
        },
    });

    return requestPromise.then(({ data }) =>
        flattenToPaginatedTree(data, newlySelectedOrDeselectedNodes),
    );
};

const getSmartCleaningReports = (): Promise<SmartCleaningReportsResponse> => {
    const requestPromise = createRequest<SmartCleaningReportsResponse>({
        method: 'get',
        url: '/smart-cleaning-reports',
    });

    return requestPromise.then(({ data }) => data);
};

const createSmartCleaningReport = (
    data: SmartCleaningReportPayload,
): AxiosPromise<SmartCleaningReportPayload> => {
    return createRequest<SmartCleaningReportPayload>({
        method: 'post',
        url: '/smart-cleaning-reports',
        options: { data },
    });
};

const updateSmartCleaningReport = (
    id: number,
    data: SmartCleaningReportPayload,
): AxiosPromise<SmartCleaningReportPayload> => {
    return createRequest<SmartCleaningReportPayload>({
        method: 'put',
        url: `/smart-cleaning-reports/${id}`,
        options: { data },
    });
};

const deleteSmartCleaningReport = (
    id: number,
): AxiosPromise<SmartCleaningReportPayload> => {
    return createRequest<SmartCleaningReportPayload>({
        method: 'delete',
        url: `/smart-cleaning-reports/${id}`,
    });
};

const getSmartCleaningActivatedCount = (
    params: SmartCleaningActivatedParams,
): AxiosPromise<SmartCleaningActivatedCountResponse> =>
    createRequest({
        method: 'get',
        url: '/smart-cleaning/setup/activated-spaces/count',
        options: {
            params,
        },
    });

const setSmartCleaningActivatedDeactivatedSpaces = (
    data: SetActivatedDeActivatedSpacesPayload,
): AxiosPromise<SmartCleaningActivatedCountResponse> =>
    createRequest({
        method: 'post',
        url: '/smart-cleaning/setup/activated-spaces',
        options: {
            data,
        },
    });

export const controllers = {
    getSmartCleaningActivatedCount,
    getSmartCleaningHierarchy,
    getSmartCleaningHierarchyLocations,
    setSmartCleaningActivatedDeactivatedSpaces,
    setSensorResetTimes,
    createSmartCleaningReport,
    deleteSmartCleaningReport,
    getSmartCleaningReports,
    setCleaningRules,
    updateSmartCleaningReport,
};
