import { toastSuccess, removeToast } from '@infogrid/core-ducks';
import { put, race, delay as delayEffect, join, fork } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

export function handleDelayingFormSave(formHandler, message, delay = 1000) {
    return function* showToastOnDelay(...args) {
        const handler = yield fork(formHandler, ...args);

        const { isHandlerDelaying } = yield race({
            handler: join(handler),
            isHandlerDelaying: delayEffect(delay),
        });

        if (isHandlerDelaying && message) {
            const toasterId = `progress-toast-${uuid()}`;

            yield put(toastSuccess({ message, id: toasterId }));

            const { isMessageShowingMore, isHandlingDone } = yield race({
                isHandlingDone: join(handler),
                isMessageShowingMore: delayEffect(500),
            });

            // if server handler is done in less than 500ms
            if ((!isMessageShowingMore && !isHandlingDone) || isHandlingDone) {
                // wait 500ms to prevent toaster flickering
                yield delayEffect(500);
                yield put(removeToast(toasterId));

                return;
            }

            // remove toaster after handler is done
            yield join(handler);
            yield put(removeToast(toasterId));
        }
    };
}
