import type { FloorDetail } from '@infogrid/locations-types';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { Button, Icon, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import AddSpaceModal from 'components/estates/AddSpaceModal';

import { useEstateFloorViewHeaderStyles } from './styles';

interface Props {
    buildingId: number;
    floor: FloorDetail;
    enableAddingSpaces?: boolean;
}

const SpaceContainer = ({ buildingId, floor, enableAddingSpaces = true }: Props) => {
    const styles = useEstateFloorViewHeaderStyles();

    const { t } = useTranslation('estate');

    const [isAddSpaceModalOpen, handleCloseAddSpaceModal, handleOpenAddSpaceModal] =
        useIsOpenState();

    return (
        <div className={styles.container}>
            <div className={styles.leftContainer}>
                <Typography
                    className={styles.counters}
                    data-cypress="space-sensor-count"
                    variant="body1"
                >
                    {t('{{count}} spaces', {
                        count: floor.spaces_count,
                        defaultValue___one: `${floor.spaces_count} space`,
                        defaultValue___other: `${floor.spaces_count} spaces`,
                    }) +
                        ' | ' +
                        t('{{count}} sensors', {
                            count: floor.sensors_count,
                            defaultValue___one: `${floor.sensors_count} sensor`,
                            defaultValue___other: `${floor.sensors_count} sensors`,
                        })}
                </Typography>
            </div>
            {enableAddingSpaces && (
                <Tooltip
                    placement="top"
                    title={
                        !floor.user_actions?.edit?.allowed &&
                        floor.user_actions?.edit?.reason
                            ? floor.user_actions?.edit?.reason
                            : ''
                    }
                >
                    <div>
                        <Button
                            className={styles.addSpaceButton}
                            color="primary"
                            data-cypress="open-add-space-view"
                            disabled={!floor.user_actions?.edit?.allowed}
                            onClick={handleOpenAddSpaceModal}
                            size="small"
                            variant="contained"
                        >
                            <Icon
                                className={classNames('far fa-plus', styles.addSpaceIcon)}
                            />
                            {t('Add spaces')}
                        </Button>
                    </div>
                </Tooltip>
            )}
            {isAddSpaceModalOpen && (
                <AddSpaceModal
                    buildingId={buildingId}
                    floorId={floor.id}
                    isOpen={isAddSpaceModalOpen}
                    onClose={handleCloseAddSpaceModal}
                />
            )}
        </div>
    );
};

export default memo(SpaceContainer);
