import {
    getElectricityIcon,
    getFormattedElectricityReadingByEventType,
    isValidElectricityEventType,
} from '@infogrid/sensors-configuration';
import { WATER_PRESENT, SENSOR_EVENT_TYPES_V2 } from '@infogrid/sensors-constants';
import { getElectricityUnit } from '@infogrid/solution-views-electricity';
import { formatShortDateTime } from '@infogrid/utils-dates';
import round from 'lodash/round';
import moment from 'moment';

import { average } from 'utils/math';

/**
 * @param data {array} - list of sensor events
 * @returns {number} - number of times this proximity sensor's state (present/not present) changed
 */
const countStateChanges = (data) => {
    if (data.length <= 1) {
        return 0;
    }

    let count = 0;

    for (let i = 0; i < data.length - 1; i++) {
        // The array also might include events not in the chosen time range
        // (see docstring of `OpenClosedGraph/calculateGraphState` for reasons)
        if (data[i].value !== data[i + 1].value && !data[i].outsideRange) {
            count += 1;
        }
    }

    return count;
};

/**
 * @param data {array} - list of sensor events
 * @param state {string} - sensor event value (e.g. "NOT_PRESENT")
 * @returns {number} - number of times this state happened
 */
const countStateEnterings = (data, state) => {
    let count = 0;

    for (let i = 0; i < data.length - 1; i++) {
        // TODO I am pretty sure that this list can also include events that didn't happen within the timerange,
        //      so shouldn't it also check for `!data[i].outsideRange` like countStateChanges?
        if (data[i].value !== data[i + 1].value && data[i].value === state) {
            count += 1;
        }
    }

    return count;
};

/**
 * @param timeRange {Object} - contains information about the range type and its start and end times
 * @param data {array} - list of sensor events
 * @param isLoading {boolean}
 * @returns {*} - returns a moment.duration object with the information about how long this desk
 *      was occupied for (total) in this timerange
 */
const getOccupationDuration = (timeRange, data, isLoading) => {
    if (isLoading) {
        // Default occupancy is 0 seconds
        return moment.duration();
    }

    // Get the timeRange end in milliseconds
    const to = timeRange.to.getTime();

    // See https://infogrid.atlassian.net/browse/SUPPORT-18
    // the duration used to be returned by the BE,
    // but disappeared while migrating data (removing DynamoDB and using TSDB instead)
    // the below recalculate the duration on the fly
    const filteredData = data.filter(
        (event) => event.type === SENSOR_EVENT_TYPES_V2.DESK_OCCUPANCY,
    );
    let sortedData = filteredData.sort((a, b) => {
        const timestampA = new Date(a.timestamp);
        const timestampB = new Date(b.timestamp);
        return +timestampA - +timestampB;
    });

    sortedData = sortedData.map((currentEvent, index) => {
        const newEvent = { ...currentEvent };
        const nextEventTimestamp =
            index === sortedData.length - 1
                ? to
                : new Date(sortedData[index + 1].timestamp);
        newEvent.duration = +nextEventTimestamp - +new Date(currentEvent.timestamp);
        return newEvent;
    });

    return (
        sortedData
            // Only occupied events can affect the duration of occupancy
            .filter((event) => event.value === true)
            .reduce((occupationDuration, event) => {
                occupationDuration.add(event.duration);
                return occupationDuration;
            }, moment.duration())
    );
};

export const StateChangeStatistic = ({ sensorEvents, t }) => ({
    icon: 'fa-door-open',
    label: t('State changes in selected period', { ns: 'sensor-events' }),
    reading: countStateChanges(sensorEvents),
    key: 'door_state_changes',
});

export const PresenceStateChangeStatistic = ({ sensorEvents, t }) => ({
    icon: 'fa-portrait',
    label: t('State changes in selected period', { ns: 'sensor-events' }),
    reading: countStateChanges(sensorEvents),
    key: 'presence_state_changes',
});

export const StateEnteringsStatistic = ({ sensorEvents, t }) => ({
    icon: 'fa-tint',
    label: t('Water detections in selected period', { ns: 'sensor-events' }),
    reading: countStateEnterings(sensorEvents, true),
    key: 'water_detections',
});

export const DeskOccupationDurationStatistic = ({
    sensorEvents,
    timeRange,
    isLoading,
    t,
}) => ({
    icon: <i className="icon icon-lg icon--desk-occupancy-occupied" />,
    label: t('Occupied in selected period', { ns: 'sensor-events' }),
    reading: getOccupationDuration(timeRange, sensorEvents, isLoading).format(
        'h [hours] m [minutes]',
    ),
    key: 'desk_occupation_duration',
});

export const TouchCountStatistic = ({ sensorEvents, t }) => ({
    icon: 'fa-hand-point-up',
    label: t('Touches in selected period', { ns: 'sensor-events' }),
    reading: sensorEvents.reduce((total, event) => (event?.value ?? 0) + total, 0),
    key: 'touch_count',
});

export const ProximityCountStatistics = ({ sensorEvents, t }) => ({
    icon: 'fa-door-open',
    label: t('Counting door events in selected period', { ns: 'sensor-events' }),
    reading: sensorEvents.reduce((total, event) => (event?.value ?? 0) + total, 0),
});

export const MaxSpaceOccupancyStatistic = ({ sensorEvents, t, locale }) => {
    const icon = 'fal fa-arrow-to-top';
    const label = t('Peak count in selected period', { ns: 'sensor-events' });
    const key = 'max_space_occupancy';

    const maxReadingEvent = sensorEvents.reduce(
        (prev, current) => (prev.value > current.value ? prev : current),
        { value: '-' },
    );
    const reading = maxReadingEvent.value;
    const readingFooter = maxReadingEvent.timestamp
        ? t('at {{timestamp}}', {
              timestamp: formatShortDateTime(maxReadingEvent.timestamp, locale),
          })
        : null;

    return { icon, label, reading, readingFooter, key };
};

export const AverageSpaceOccupancyStatistic = ({ sensorEvents, t }) => {
    const icon = 'fal fa-analytics';
    const label = t('Average count in selected period', { ns: 'sensor-events' });
    const key = 'average_space_occupancy';

    const averageReading = Math.max(0, average(sensorEvents.map((event) => event.value)));
    const reading = Number.isFinite(averageReading) ? round(averageReading) : '-';

    return { icon, label, reading, key };
};

export const SpaceFootfallStatistic = ({ sensorEvents, t }) => {
    const icon = 'fal fa-walking';
    const label = t('Footfall in selected period', { ns: 'sensor-events' });
    const key = 'space_footfall';

    /* sensor events are coming sorted by timestamp, newest first, for this algorithm we're using
     reverse() to revert events, so that oldest event will be first;
     data inside reduce will look like this (simplified):
     [{occupancy: 17, timestamp: "10:00"},
     {occupancy: 12, timestamp: "10:05"},
     {occupancy: 13, timestamp: "10:10"},
     {occupancy: 15, timestamp: "10:15"},
     {occupancy: 0, timestamp: "10:20"},
     {occupancy: 7, timestamp: "10:25"},
     {occupancy: 6, timestamp: "10:30"}]
     Footfall is a number of people who entered into space or room.
     1) We're skipping the first event, because we already have 17 people in room, this is a starting point,
      no one entered so far.
     2) Then 5 people exited, occupancy became 12, no one entered -> footfall is 0
     3) 1 person entered, occupancy - 13 -> footfall is 1
     4) 2 people entered, occupancy - 15 -> footfall is 15-13+1=3
     5) All people exited from the room, occupancy is 0 -> footfall 3
     6) 7 people entered the room -> footfall is 7-0+3=10
     7) 1 person exited, occupancy 6 -> footfall is still 10
    */

    const occupancyList = sensorEvents.map((event) => event?.value ?? 0).reverse();

    const footfall = occupancyList.reduce((acc, currentValue, index, array) => {
        const nextValue = array[index + 1] || 0;

        if (currentValue < nextValue) {
            return acc + (nextValue - currentValue);
        }

        return acc;
    }, 0);

    const reading = sensorEvents.length ? footfall : '-';

    return { icon, label, reading, key };
};

const getTotalEnergyConsumptionLabel = (selectedUnit, t) => {
    switch (selectedUnit) {
        case SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE_EQUIVALENT:
            return t('Total CO2e Emissions in selected period', { ns: 'sensor-events' });
        case SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS_PER_M2:
            return t('Total energy consumption per m2 in selected period', {
                ns: 'sensor-events',
            });
        default:
            return t('Total energy consumption in selected period', {
                ns: 'sensor-events',
            });
    }
};

// Energy consumption using events_v2 endpoint
export const TotalEnergyConsumptionStatisticsV2 = ({
    sensorEvents,
    t,
    selectedUnit,
    sensor,
}) => {
    const kWhTotal = sensorEvents.reduce(
        (total, event) =>
            (isValidElectricityEventType(event?.type) ? event.value : 0) + total,
        0,
    );

    return {
        icon: getElectricityIcon(selectedUnit),
        label: getTotalEnergyConsumptionLabel(selectedUnit, t),
        reading: getFormattedElectricityReadingByEventType(
            selectedUnit,
            kWhTotal,
            sensor.sensor_configuration,
        ),
        readingUnit: getElectricityUnit(selectedUnit, t),
        key: 'totalEnergyConsumptionStatistics',
    };
};

/**
 * Get the data key from the event key. This is so we know which event_v2 it corresponds to.
 * We split on `-` so we can add info after, e.g. space_occupancy-max will return the correct event type
 * `space_occupancy`.
 */
export const getDataKeyFromEventType = (event, eventTypeKey) =>
    event.type === eventTypeKey.split('-')[0];

export const formatEventStatistics = (
    sensor,
    eventStatisticsTypes,
    { userPreferences, sensorEvents, timeRange, isLoading, t, locale },
    selectedUnit,
) =>
    Object.fromEntries(
        Object.entries(eventStatisticsTypes || {}).map(
            ([eventTypeKey, getStatisticConf]) => {
                return [
                    eventTypeKey,
                    getStatisticConf({
                        sensorEvents: sensorEvents.filter((event) =>
                            getDataKeyFromEventType(event, eventTypeKey),
                        ),
                        userPreferences,
                        timeRange,
                        isLoading,
                        t,
                        locale,
                        selectedUnit,
                        sensor,
                    }),
                ];
            },
        ),
    );
