import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import TouchCountPreview from 'styles/images/widgets/touch-count.png';

import TouchCountWidget from './TouchCountWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('Touch Count', { ns: 'dashboard' }),
    type: WIDGET_TYPE.TOUCH_COUNT,
    featureFlags: [],
    Component: TouchCountWidget,
    image: TouchCountPreview,
    order: 1,
    gridConfig: DEFAULT_GRID_ITEM_CONFIG,
};

export default TouchCountWidget;
