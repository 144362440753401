import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

import { SCROLLBAR_WIDTH } from 'components/material-ui/Table/constants';

export const useSpaceSidebarSensorsStyles = makeStyles((theme) => ({
    autoSizerContainer: {
        display: 'flex',
        flex: 1,
        height: '100%',
    },
    progress: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    scrollbar: {
        scrollbarColor: `${theme.palette.common.gray4} transparent`,
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: SCROLLBAR_WIDTH,
            height: SCROLLBAR_WIDTH,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.common.gray4,
            borderRadius: theme.shape.borderRadius,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent',
        },
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0, 3),
    },
    sensorsLabel: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
    sensorsList: {
        overflow: 'auto',
        padding: theme.spacing(1.5, 3),
    },
}));
