import type { Building } from '@infogrid/locations-types';
import { memo } from 'react';

import EditBuildingProvider from '../../EditBuildingProvider';
import type { OnBuildingUpdate } from '../../EditBuildingProvider/EditBuildingProvider';
import EditBuildingModal from './EditBuildingModal';

interface Props {
    building: Building | null;
    open: boolean;
    onClose: () => void;
    onBuildingUpdate?: OnBuildingUpdate;
}

const EditBuildingModalContainer = ({
    building,
    open,
    onClose,
    onBuildingUpdate,
}: Props) => (
    <EditBuildingProvider building={building} onBuildingUpdate={onBuildingUpdate}>
        <EditBuildingModal onClose={onClose} open={open} />
    </EditBuildingProvider>
);

export default memo(EditBuildingModalContainer);
