import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useSensorInfoCardStyles = makeStyles((theme) => ({
    clickableCard: {
        cursor: 'pointer',
    },
    content: {
        padding: theme.spacing(3),
        textAlign: 'left',
    },
    header: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(2),
    },
    sensorTypeIcon: {
        marginRight: theme.spacing(2),
    },
    lastReadingWrapper: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    lastReading: {
        fontSize: theme.typography.h6.fontSize,
    },
    lastReadingDivider: {
        display: 'none',
    },
    lastReadingTime: {
        color: theme.palette.text.secondary,
    },
    statusIcons: {
        display: 'flex',
        '& > *': {
            marginLeft: theme.spacing(1),
        },
    },
    sensorName: {
        wordBreak: 'break-word',
        marginBottom: theme.spacing(0.5),
    },
    sensorType: {
        marginBottom: theme.spacing(2.5),
    },
    location: {
        color: theme.palette.text.secondary,
    },
    inlineHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    inlineContent: {
        display: 'flex',
        alignItems: 'center',
    },
    inlineText: {
        flexGrow: 1,
    },
    listContent: {
        backgroundColor: theme.palette.background.sand,
        padding: theme.spacing(2),
    },
    listSensorName: {
        fontSize: theme.typography.body2.fontSize,
        marginBottom: 0,
    },
    listLastReading: {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
        marginBottom: theme.spacing(0.5),
    },
    listLastReadingTime: {
        fontSize: theme.typography.caption.fontSize,
    },
}));
