import { makeStyles } from '@material-ui/core';

export const useFloorPlanFooterStyles = makeStyles((theme) => ({
    buildingLink: {
        color: theme.palette.text.primary,

        '&:hover': {
            textDecoration: 'none',
            color: theme.palette.text.primary,
        },
    },

    linkText: {
        display: 'flex',
        alignItems: 'center',
    },
    angleIcon: {
        marginLeft: theme.spacing(0.5),
        fontSize: '16px',
    },
}));
