import { makeStyles } from '@material-ui/core';

export const useCircleStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },

    circle: {
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '72px',
        color: theme.palette.common.white,
    },
}));
