import { routesManager } from '@infogrid/core-routing';
import { saveAuthTokens } from '@infogrid/user-cookies';
import {
    createSaveAction,
    createFormSaveSaga,
    formErrorsHandler,
} from '@thorgate/spa-forms';
import { getLocation, push } from 'connected-react-router';
import qs from 'qs';
import { select, takeLatest, put, call } from 'redux-saga/effects';

import api from 'services/api';

/**
 * Trigger obtain token worker saga.
 * @returns Created trigger action
 */
export const obtainToken = createSaveAction('@@sagas/auth/OBTAIN_TOKEN');

function* successHook(result) {
    const { access, refresh } = result;

    saveAuthTokens(access, refresh);

    const location = yield select(getLocation);
    let { next } = qs.parse(location.search || '', { ignoreQueryPrefix: true });

    if (!next) {
        next = routesManager.resolvePath('landing');
    }

    yield put(push(next));
}

function* errorHook(options) {
    yield call(formErrorsHandler, options);

    if (options.error.statusCode === 401) {
        options.setStatus({
            // Set the form error message to reflect the error coming from the backend
            message: JSON.parse(options.error.responseText).detail,
        });
    }
}

const obtainTokenSaga = createFormSaveSaga({
    resource: api.auth.obtain,
    successHook,
    errorHook,
});

export default function* loginWatcherSaga() {
    yield takeLatest(obtainToken.getType(), obtainTokenSaga, null);
}
