import { defaultFrequencies } from '@infogrid/dashboards-constants';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import DateRange from 'views/dashboards/components/modals/ConfigureWidgetModal/DateRange';
import Sensors from 'views/dashboards/components/modals/ConfigureWidgetModal/Sensors';

import DisplayOptions from './DisplayOptions';
import { calculateDisabledFrequencies } from './utils';

export const widgetConfigurationModalTabs = [
    {
        id: 'sensors',
        name: registerTranslationKey('Sensors', { ns: 'common' }),
        component: Sensors,
        attributes: {
            allowFoldersSelection: false,
            sensorTypeFilter: [
                SENSOR_TYPE.TYPE_PROXIMITY,
                SENSOR_TYPE.TYPE_PROXIMITY_COUNTER,
                SENSOR_TYPE.TYPE_TOUCH,
                SENSOR_TYPE.TYPE_TOUCH_COUNTER,
                SENSOR_TYPE.TYPE_CLEANING_CONFIRMATION,
            ],
            maxSelectedSensors: [
                [
                    [
                        SENSOR_TYPE.TYPE_TOUCH,
                        SENSOR_TYPE.TYPE_TOUCH_COUNTER,
                        SENSOR_TYPE.TYPE_CLEANING_CONFIRMATION,
                    ],
                    5,
                ],
                [[SENSOR_TYPE.TYPE_PROXIMITY, SENSOR_TYPE.TYPE_PROXIMITY_COUNTER], 1],
            ],
            minSelectedSensors: {
                [SENSOR_TYPE.TYPE_TOUCH]: 0,
                [SENSOR_TYPE.TYPE_TOUCH_COUNTER]: 0,
                [SENSOR_TYPE.TYPE_PROXIMITY]: 0,
                [SENSOR_TYPE.TYPE_PROXIMITY_COUNTER]: 0,
                [SENSOR_TYPE.TYPE_CLEANING_CONFIRMATION]: 0,
            },
        },
    },
    {
        id: 'date-range',
        name: registerTranslationKey('Date Range', { ns: 'common' }),
        component: DateRange,
    },
    {
        id: 'display-options',
        name: registerTranslationKey('Display Options', { ns: 'common' }),
        component: DisplayOptions,
        attributes: {
            calculateDisabledFrequencies,
            colorSelectableTypes: [
                SENSOR_TYPE.TYPE_TOUCH,
                SENSOR_TYPE.TYPE_TOUCH_COUNTER,
            ],
            frequencies: defaultFrequencies,
        },
    },
];
