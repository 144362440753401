import { Modal } from '@infogrid/components-material-ui';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import type { FormikProps } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ThresholdValues } from 'utils/types/ts/threshold';

import { useBuildRecurringReportModalStyles } from './styles';

interface ThresholdsDialogProps {
    isOpen?: boolean;
    isUpdated?: boolean;
    onCancel: () => void;
    sensorsCount: number;
    formik: FormikProps<ThresholdValues>;
}

const ThresholdsDialog = ({
    isOpen = false,
    isUpdated = false,
    onCancel,
    sensorsCount,
    formik,
}: ThresholdsDialogProps) => {
    const { t } = useTranslation('solutions');
    const styles = useBuildRecurringReportModalStyles();

    const isMobile = useIsMobile();

    const { handleSubmit } = formik;

    return (
        <Modal fullScreen={isMobile} onClose={onCancel} open={isOpen}>
            <form className={styles.form} onSubmit={handleSubmit}>
                <Modal.Title data-testid="emergency-light-title">
                    {t('Confirm threshold changes')}
                </Modal.Title>
                <Modal.Content className={styles.content}>
                    <Typography className={styles.confirmationText}>
                        {t('Are you sure you want to confirm these changes?')}
                    </Typography>
                    <Typography className={styles.numberText}>
                        {t(`Number of pipes affected: {{ sensorsCount }} pipes`, {
                            sensorsCount: !!isUpdated ? sensorsCount : t('all'),
                        })}
                    </Typography>
                    <Alert
                        severity="warning"
                        className={styles.alert}
                        data-testid={'thresholds-not-set'}
                    >
                        {t(
                            'Only confirm changes if you are the responsible individual for your organization. Changes to thresholds affect how data is displayed in the app and reports for all affected pipes after the change is confirmed.',
                        )}
                    </Alert>
                </Modal.Content>
                <Modal.Actions justify="space-between">
                    <Button
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        {t('Cancel', { ns: 'common' })}
                    </Button>
                    <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        data-cypress="threshold-dialog"
                    >
                        {t('Save', { ns: 'common' })}
                    </Button>
                </Modal.Actions>
            </form>
        </Modal>
    );
};

export default memo(ThresholdsDialog);
