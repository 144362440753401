import { makeStyles } from '@material-ui/core';

export const useSensorsColorsStyles = makeStyles((theme) => ({
    columnLabel: {
        color: theme.palette.common.gray1,
    },
    sensorLabel: {
        fontSize: '16px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    colorSelect: {
        minWidth: '115px',
        '& > .MuiSelect-root': {
            alignItems: 'center',
            display: 'flex',
        },
    },
    colorCircle: {
        fontSize: '10px',
        marginRight: theme.spacing(0.75),
        marginTop: theme.spacing(0.25),
    },
}));
