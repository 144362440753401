import { ItemsContainer } from '@infogrid/components-material-ui';
import { entitiesSelectors, EntityStatus } from '@thorgate/spa-entities';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';

import {
    paginationDefaultProps,
    paginationMapStateToProps,
    paginationPropTypes,
    useLoadMore,
} from 'ducks/pagination';
import { useDoInitialFetchEffect } from 'utils/hooks/entities';

const defaultScrollerProps = {
    threshold: 200,
    useWindow: false,
};

const InfiniteScrollContainer = (props) => {
    const {
        children,
        hasContent,
        hasLoaded,
        isLoading,
        hideLoader,
        emptyState,
        element,
        className,
    } = props;
    const {
        nextKwargs,
        hasNext,
        entityKey,
        onFetchMore,
        doInitialFetch,
        refetchOnReset,
        debounceDelay,
    } = props;
    const { scrollerProps } = props;

    const hasMore = hasNext && hasLoaded;
    const loadMore = useLoadMore(onFetchMore, nextKwargs, hasMore, debounceDelay);

    // Explicitly using onFetchMore for initial fetch as it's meant for 'starting over' not 'loading more'
    useDoInitialFetchEffect(onFetchMore, doInitialFetch, refetchOnReset, entityKey);

    const infiniteScrollStyle = useMemo(
        () => ({ opacity: hasLoaded ? 1 : 0.9 }),
        [hasLoaded],
    );
    const mergedScrollerProps = useMemo(
        () => ({ ...defaultScrollerProps, ...scrollerProps }),
        [scrollerProps],
    );

    return (
        <ItemsContainer
            hasContent={hasContent}
            hasLoaded={hasLoaded}
            isLoading={isLoading}
            hideLoader={hideLoader}
            emptyState={emptyState}
            noContentContainer={element}
            noContentClassName={className}
        >
            <InfiniteScroll
                key="infinite-scroll"
                hasMore={hasMore}
                loadMore={loadMore}
                style={infiniteScrollStyle}
                element={element}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...mergedScrollerProps}
            >
                {children}
            </InfiniteScroll>
        </ItemsContainer>
    );
};

InfiniteScrollContainer.propTypes = {
    // ItemsContainer
    children: PropTypes.node,
    hasContent: PropTypes.bool.isRequired,
    hasLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hideLoader: PropTypes.bool,
    emptyState: PropTypes.node,
    element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    className: PropTypes.string,

    entityKey: PropTypes.string.isRequired,
    onFetchMore: PropTypes.func.isRequired,
    doInitialFetch: PropTypes.bool,
    refetchOnReset: PropTypes.bool,
    debounceDelay: PropTypes.number,

    scrollerProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    ...paginationPropTypes,
};

InfiniteScrollContainer.defaultProps = {
    // ItemsContainer
    children: undefined,
    hideLoader: undefined,
    emptyState: undefined,
    element: 'div',
    className: null,

    doInitialFetch: false,
    refetchOnReset: false,
    debounceDelay: undefined,
    scrollerProps: defaultScrollerProps,

    ...paginationDefaultProps,
};

const mapStateToProps = (state, ownProps) => ({
    hasLoaded:
        entitiesSelectors.selectEntitiesStatus(state, ownProps.entityKey) ===
        EntityStatus.Fetched,
    isLoading:
        entitiesSelectors.selectEntitiesStatus(state, ownProps.entityKey) ===
        EntityStatus.Fetching,

    ...paginationMapStateToProps(state, ownProps.entityKey),
});

export default connect(mapStateToProps)(InfiniteScrollContainer);
