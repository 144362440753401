import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';

export const useInstallationFlowStyles = makeStyles<Theme>(
    (theme) => ({
        container: ({ isQRScannerOpen = false }: { isQRScannerOpen?: boolean }) => ({
            ...(isQRScannerOpen && {
                margin: 0,
                maxWidth: '100vw',
                maxHeight: '100vh',
            }),
            minWidth: isQRScannerOpen ? 'auto' : 600,
            [theme.breakpoints.down('xs')]: {
                minWidth: 'auto',
            },
        }),
        closeIconButton: ({
            isQRScannerOpen = false,
        }: {
            isQRScannerOpen?: boolean;
        }) => ({
            '& .MuiIcon-root': {
                color: isQRScannerOpen
                    ? theme.palette.common.white
                    : 'rgba(0, 0, 0, 0.54)',
            },
        }),
        errorMessage: {
            color: theme.palette.error.main,
            margin: theme.spacing(2, 0),
        },
        form: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        actions: {
            marginTop: 'auto',
        },
        paragraph: {
            color: theme.palette.text.grey.default,
            marginBottom: theme.spacing(1.5),
        },
        divider: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        centeredImage: {
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            maxWidth: '100%',
            maxHeight: '100%',
        },
        inlineHelpLink: {
            marginLeft: theme.spacing(0.5),
        },
        successIcon: {
            color: theme.palette.common.green1,
            overflow: 'visible',
            marginBottom: theme.spacing(1),
        },
        successMessage: {
            marginBottom: theme.spacing(1),
        },
        callToAction: {
            fontWeight: 'bold',
            marginBottom: theme.spacing(1),
        },
    }),
    // Using increased index to increase specificity
    // This is to make sure the divider margin is not over-ridden by the default margin
    // See https://cssinjs.org/jss-api/?v=v10.8.2#create-style-sheet
    { index: 1 },
);
