import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError } from '@infogrid/core-ducks';
import { useTranslation } from 'react-i18next';

import { cancelBuildingQuery, invalidateBuilding } from '../accessors';
import { controllers } from '../controllers';

export const useSetDefaultBuilding = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('floorplan');

    return useMutationWithToast(
        (building) => wrapResponsePromise(controllers.setDefaultBuilding(building.id)),
        {
            onSuccess: async (building) => {
                await cancelBuildingQuery({ buildingId: building.id });
                await invalidateBuilding({ buildingId: building.id });
            },
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error changing default building'),
                    }),
                );
            },
        },
        {
            toastMessage: t('Changing default building is in progress'),
        },
    );
};
