import { Modal } from '@infogrid/components-material-ui';
import { useAppDispatch } from '@infogrid/core-ducks';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import type { DropzoneRef } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import FileUploader from 'components/FileUploader/FileUploader';
import { toggleShouldKeepSensorsMapped } from 'ducks/floorPlan';

import { useEditFloorImageModalStyles } from './styles';

interface Props {
    isOpened: boolean;
    isUploading: boolean;
    onCancel: () => void;
    onClose: () => void;
    onSubmit: (files: File[]) => void;
}

const ReplaceFloorImageModal = ({
    isOpened,
    isUploading,
    onCancel,
    onClose,
    onSubmit,
}: Props): JSX.Element => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('floorplan');

    const onKeepSensorsMapped = useCallback(
        (onFileUploaderOpen) => {
            dispatch(toggleShouldKeepSensorsMapped(true));
            onFileUploaderOpen();
        },
        [dispatch],
    );

    const onFileSelect = useCallback(
        (ev) => {
            const files = [...ev.target.files];

            onSubmit(files);

            return files;
        },
        [onSubmit],
    );

    const styles = useEditFloorImageModalStyles();

    const fileUploaderConfig = useMemo(
        () => ({
            noDrag: true,
            getFilesFromEvent: onFileSelect,
        }),
        [onFileSelect],
    );

    const modalClasses = useMemo(
        () => ({ container: styles.modalContainer }),
        [styles.modalContainer],
    );

    return (
        <Modal classes={modalClasses} onClose={onClose} open={isOpened}>
            <Modal.Title>{t('Replace Floor Plan Image')}</Modal.Title>

            <Modal.Content>
                <Typography
                    variant="body2"
                    component="div"
                    className={styles.primaryTextContainer}
                >
                    <p>
                        {t(`You are about to replace the Floor Plan image. 
                        You can choose whether to unmap(remove) 
                        sensors marked on the Floor Plan or keep sensors mapped to the new Floor Plan image you upload.
                        `)}{' '}
                    </p>
                    <p>
                        {t(`
                        
                        In order to keep the sensors in the same location, 
                        the new image should be the same size as the current Floor Plan.
                        
                    `)}
                    </p>
                    <b>
                        {t('Do you want to keep or unmap sensors from your Floor Plan?.')}
                    </b>
                </Typography>
            </Modal.Content>

            <Modal.Actions justify="space-between">
                <Button
                    classes={{ root: styles.cancelButton }}
                    onClick={onCancel}
                    data-cypress="edit-floor-image-btn-cancel"
                >
                    {t('Cancel', { ns: 'common' })}
                </Button>

                <FileUploader
                    className={styles.fileUploaderWrapper}
                    config={fileUploaderConfig}
                >
                    {({ onOpen }: { onOpen: DropzoneRef['open'] }) => (
                        <>
                            <Button
                                className={styles.keepSensorsButton}
                                color="primary"
                                variant="contained"
                                data-cypress="edit-floor-image-btn-keep-sensors"
                                onClick={() => onKeepSensorsMapped(onOpen)}
                            >
                                {isUploading && (
                                    <CircularProgress
                                        className={styles.spinner}
                                        size={22}
                                    />
                                )}
                                {t('Keep sensors')}
                            </Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                data-cypress="edit-floor-image-btn-unmap-sensors"
                                onClick={onOpen}
                            >
                                {isUploading && (
                                    <CircularProgress
                                        className={styles.spinner}
                                        size={22}
                                    />
                                )}
                                {t('Unmap sensors')}
                            </Button>
                        </>
                    )}
                </FileUploader>
            </Modal.Actions>
        </Modal>
    );
};

export default ReplaceFloorImageModal;
