import '@infogrid/utils-bootstrap-process';
import '@tg-resources/fetch-runtime';
import { initializeHighcharts } from '@infogrid/components-highcharts';
import { store, history } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import * as Sentry from '@sentry/react';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
// @ts-expect-error we have to update momentjs
import momentDurationFormatSetup from 'moment-duration-format';
import momentTimezone from 'moment-timezone';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { defaultFallbackInView } from 'react-intersection-observer';
import { Provider, ReactReduxContext } from 'react-redux';
import type { RouteConfig } from 'react-router-config';
import { matchPath } from 'react-router-dom';

import RenderChildren from 'components/RenderChildren';
import { configureStore } from 'configuration/configureStore';
import { configureRoutes } from 'configuration/routes';
import SETTINGS from 'settings';
import { configureAxios } from 'utils/axios';

import '@infogrid/utils-analytics';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'react-circular-progressbar/dist/styles.css';

const { dist } = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG;
const { release } = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG;

// https://github.com/highcharts/highcharts-react/issues/118
window.moment = momentTimezone;

// The `moment-duration-format` package adds duration formatting to the `moment` object.
momentDurationFormatSetup(moment);

initializeHighcharts();

// Allow a graceful fallback if IntersectionObserver is disabled
// in the browser
defaultFallbackInView(true);

configureRoutes();
configureStore();
configureAxios();

// Configure Sentry only in production
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: SETTINGS.SENTRY_DSN,
        environment: SETTINGS.SENTRY_ENVIRONMENT,
        // release: SETTINGS.SENTRY_RELEASE,
        dist,
        release,
        ignoreErrors: ['ResizeObserver loop completed with undelivered notifications.'],
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(
                    history,
                    // @ts-expect-error Sentry has its own types for react-router and is not using react-router-config
                    // so it's not a perfect overlap.
                    routesManager.getRoutes(),
                    matchPath,
                ),
            }),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [new URL(SETTINGS.BACKEND_SITE_URL).host],
    });

    // Passes the FullStory session url to Sentry
    // @ts-expect-error __defineSetter__ should be available in modern browsers
    // eslint-disable-next-line no-underscore-dangle
    window._fs_ready = () => {
        Sentry.configureScope((scope) => {
            scope.addEventProcessor(async (event) => {
                if (event.extra == null) {
                    // eslint-disable-next-line no-param-reassign
                    event.extra = {};
                }

                // @ts-expect-error we're checking for FullStory using _fs_ready() however tracking blockers
                // might have prevented FullStory from instatiating fully so we use an optional chain
                // eslint-disable-next-line no-param-reassign
                event.extra.sessionURL = window.FS?.getCurrentSessionURL(true);

                return event;
            });
        });
    };
}

const renderAppLayout = (appRoutes: RouteConfig[]) => {
    return (
        <HelmetProvider>
            <Provider store={store}>
                <ConnectedRouter history={history} context={ReactReduxContext}>
                    <RenderChildren routes={appRoutes} />
                </ConnectedRouter>
            </Provider>
        </HelmetProvider>
    );
};

const renderClient = (appRoutes: RouteConfig[], callback?: () => void) => {
    ReactDOM.render(
        renderAppLayout(appRoutes),
        document.getElementById('root'),
        callback,
    );
};

renderClient(routesManager.getRoutes());

if (import.meta.hot) {
    import.meta.hot.accept('./configuration/routes', () => {
        renderClient(routesManager.getRoutes(), () => {
            // eslint-disable-next-line no-console
            console.log('✅ HMR reloaded client');
        });
    });
}
