import debounce from 'lodash/debounce';
import { useContext, useEffect, memo } from 'react';

import Context from './MapContext';
import type { MapContext } from './MapContext';

interface Props {
    onChange?: (zoom: number | undefined) => void;
    zoom?: number;
}

const Zoom = ({ onChange, zoom }: Props) => {
    const { map } = useContext<MapContext>(Context);

    useEffect(() => {
        if (!map) {
            return () => {};
        }

        const mapView = map.getView();
        const currZoom = mapView.getZoom();

        if (typeof zoom === 'number' && currZoom !== zoom) {
            mapView.setZoom(zoom);
        }

        return () => {};
    }, [map, zoom]);

    useEffect(() => {
        if (!map || !onChange) {
            return () => {};
        }

        const mapView = map.getView();

        const onMoveEnd = debounce(() => {
            onChange(mapView.getZoom());
        }, 250);

        mapView.on('change:resolution', onMoveEnd);

        return () => {
            mapView.un('change:resolution', onMoveEnd);
        };
    }, [map, onChange]);

    return null;
};

export default memo(Zoom);
