import { memo, useCallback } from 'react';
import ReactSlider from 'react-slider';

import { useSliderStyles } from './styles';

const colorsByIndex = {
    0: '#E57373',
    1: '#FFA726',
    2: '#66BB6A',
    3: '#66BB6A',
};

const Slider = (props) => {
    const styles = useSliderStyles();

    const Thumb = useCallback(
        (thumbProps, thumbState) => (
            <div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...thumbProps}
                className={styles.thumb}
            >
                {thumbState.valueNow}
            </div>
        ),
        [styles.thumb],
    );

    const Track = useCallback(
        (trackProps, trackState) => (
            <div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...trackProps}
                className={styles.track}
                style={{
                    backgroundColor: colorsByIndex[trackState.index],
                    ...trackProps.style,
                }}
            />
        ),
        [styles.track],
    );

    return (
        <ReactSlider
            className={styles.root}
            renderThumb={Thumb}
            renderTrack={Track}
            pearling
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
};

export default memo(Slider);
