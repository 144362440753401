import type { SensorShape } from '@infogrid/sensors-constants';
import { memo } from 'react';

import InstallationFlowWarning from '../InstallationFlowWarning';
import { PREVIOUSLY_INSTALLED_MODAL_SETTINGS } from '../constants';
import PreviouslyInstalledWarningContent from './PreviouslyInstalledWarningContent';

interface Props {
    isOpen?: boolean;
    onDiscard?: () => void;
    onResume?: () => void;
    sensor?: SensorShape | null;
}

const InstallationFlowOverrideWarning = ({
    isOpen = false,
    onDiscard = () => {},
    onResume = () => {},
    sensor = null,
}: Props) => {
    const previouslyInstalledWarningMessage = (
        <PreviouslyInstalledWarningContent
            breadcrumbs={sensor?.breadcrumbs}
            sensorName={sensor?.profile?.name}
        />
    );

    return (
        <InstallationFlowWarning
            isOpen={isOpen}
            onResume={onResume}
            onDiscard={onDiscard}
            warningMessage={previouslyInstalledWarningMessage}
            onDiscardButtonText={
                PREVIOUSLY_INSTALLED_MODAL_SETTINGS.ON_DISCARD_BUTTON_TEXT
            }
            onResumeButtonText={PREVIOUSLY_INSTALLED_MODAL_SETTINGS.ON_RESUME_BUTTON_TEXT}
            titleText={PREVIOUSLY_INSTALLED_MODAL_SETTINGS.TITLE_TEXT}
        />
    );
};

export default memo(InstallationFlowOverrideWarning);
