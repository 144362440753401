/* eslint-disable id-denylist */

import { makeStyles } from '@material-ui/core';

type StyleProps = { isMobile: boolean };

export const useStylesContainer = makeStyles((theme) => ({
    childrenContent: ({ isMobile }: StyleProps) => ({
        // fixes tables overflow
        overflowX: 'hidden',
        overflowY: isMobile ? 'hidden' : 'unset',

        padding: isMobile ? theme.spacing(2) : theme.spacing(5),
    }),
}));
