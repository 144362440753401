import { makeStyles } from '@material-ui/core';

export const useEditBuildingStyles = makeStyles((theme) => ({
    container: {
        width: '450px',
    },
    spinner: {
        color: theme.palette.common.white,
        marginRight: '10px',
    },
}));
