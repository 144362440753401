import { Divider, Typography } from '@material-ui/core';
import { memo } from 'react';
import type { ReactNode } from 'react';

import { useListTitleStyles } from './styles';

interface Props {
    children: ReactNode;
    dataCypress?: string;
}

const ListTitle = ({ children, dataCypress }: Props) => {
    const styles = useListTitleStyles();

    return (
        <div>
            <Typography
                className={styles.label}
                data-cypress={dataCypress}
                variant="subtitle2"
            >
                {children}
            </Typography>
            <Divider className={styles.divider} />
        </div>
    );
};

export default memo(ListTitle);
