import { makeStyles } from '@material-ui/core';

export const useBuildingsListStyles = makeStyles((theme) => ({
    container: {
        overflow: 'auto',
        padding: theme.spacing(2, 3),
        width: 600,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    list: {
        minHeight: 200,
    },
    spinnerWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        minHeight: 200,
    },
}));
