export const MODAL_STAGES = {
    SELECT_SPACE_TYPES: 'SELECT_SPACE_TYPES',
    SELECT_RULES_APPLICATION: 'SELECT_RULES_APPLICATION',
    SET_RULES: 'SET_RULES',
};

export type ModalStage = typeof MODAL_STAGES[keyof typeof MODAL_STAGES];

export type LocationSelectionMethod = 'all' | 'selection';
export interface BySpaceTypeModalFormValues {
    might_need_cleaning_value?: number | string;
    must_be_cleaned_value?: number;
    locations: number[];
    selected_space_types: Record<string, boolean>;
    selection_method: LocationSelectionMethod;
    modal_stage: ModalStage;
}
