import { useAppSelector } from '@infogrid/core-ducks';
import type {
    SensorConfigurationShape,
    SensorEventShape,
    SensorEventsV2,
    SensorEventType,
    SensorShape,
    SensorStatusEvent,
} from '@infogrid/sensors-constants';
import {
    SENSOR_EVENTS_OVERVIEW_TYPE,
    SENSOR_EVENT_TYPES_V2,
    SENSOR_TYPE,
    AirQualityReadingsOrder,
} from '@infogrid/sensors-constants';
import { selectUserPreferences } from '@infogrid/user-ducks';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';

import type { EventTypeConfigFactory } from 'components/sensors/SensorStatusCards/sensorStatusCardConfig';
import {
    AirPressureEvent,
    KilowattHoursEvent,
    BatteryStatusEvent,
    MonnitHubConnectionStatusEvent,
    NetworkHubSignalEvent,
    CO2Event,
    CConConnectionStatusEvent,
    DeskOccupancyEvent,
    FeedbackPanelTouchEvent,
    HumidityEvent,
    LightEvent,
    NetworkStatusEvent,
    NoiseEvent,
    ObjectPresentCountEvent,
    ObjectPresentEvent,
    PresenceDetectorObjectPresentEvent,
    PM10Event,
    PM1Event,
    PM25Event,
    RadonEvent,
    SpaceOccupancyEvent,
    TemperatureEvent,
    TouchCountEvent,
    TouchEvent,
    TVOCEvent,
    VirusRiskEvent,
    WaterMovingEvent,
    WaterPresentEvent,
    EmergencyLightTestEvent,
} from 'components/sensors/SensorStatusCards/sensorStatusCardConfig';
import {
    AverageSpaceOccupancyStatistic,
    DeskOccupationDurationStatistic,
    formatEventStatistics,
    MaxSpaceOccupancyStatistic,
    ProximityCountStatistics,
    SpaceFootfallStatistic,
    StateChangeStatistic,
    StateEnteringsStatistic,
    TotalEnergyConsumptionStatisticsV2,
    TouchCountStatistic,
    PresenceStateChangeStatistic,
} from 'utils/eventStatistics';
import {
    ElectricityAverageNonWorkingDaysConsumption,
    ElectricityAverageNonWorkingHoursConsumption,
    ElectricityAveragePercentageDaysConsumption,
    ElectricityAveragePercentageHoursConsumption,
    ElectricityAverageWorkingDaysConsumption,
    ElectricityAverageWorkingHoursConsumption,
} from 'utils/eventsOverview';
import type { TimeRange } from 'utils/types/ts/app';

/*
    Warning: should migrate configs to use sensorsConfiguration

    The following methods are used to gather all metadata for a sensor type in one place.
    The sensor types for which some of the information is dynamic depending on the latest reading value e.g.
    also allow the sensor to be passed as an argument. However, this should be optional with reasonable fallbacks
    to allow usage in places where a sensor is not available.

    All the labels in the metadata are already translated.
    This is done because many labels are returned as JSX.
    And translating JSX in consuming components would increase complexity.
    Though it's advised to try and avoid immediate translating when migrating to sensorsConfiguration .
    Instead, return labels as translation keys (wrapped in registerTranslationKey).
 */

export type ReadingEventTypes = {
    [K in keyof SensorEventsV2]: ReadingEventType<K>;
};

export type ReadingEventType<K extends keyof SensorEventsV2> =
    | EventTypeConfigFactory<K>
    | EventTypeConfigFactory<(K | keyof SensorEventsV2)[]>
    | EventTypeConfigFactory<
          K,
          [sensorConfiguration: SensorConfigurationShape, selectedUnit: SensorEventType]
      >;

export interface SensorMeta {
    readingEventTypes: ReadingEventTypes;
    readingsSortingTemplate?: string[];
    metaEventTypes?: {
        [K in keyof SensorEventsV2]:
            | EventTypeConfigFactory<K>
            | EventTypeConfigFactory<(K | keyof SensorEventsV2)[]>;
    };
    eventStatisticsTypes?: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [K in keyof SensorEventsV2]: (props: any) => any;
    };
    eventsOverviewHoursTypes?: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: (props: any) => SensorStatusEvent;
    } | null;
    eventsOverviewDaysTypes?: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: (props: any) => SensorStatusEvent;
    } | null;
}

export const TemperatureSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.TEMPERATURE]: TemperatureEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
}); // satisfies SensorMeta - uncomment when upgrading to TS 4.9 for interface satisfies

export const AverageTemperatureSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.TEMPERATURE]: TemperatureEvent,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const HumiditySensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.TEMPERATURE]: TemperatureEvent,
        [SENSOR_EVENT_TYPES_V2.HUMIDITY]: HumidityEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const ProximitySensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT]: ObjectPresentEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventStatisticsTypes: {
        [SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT]: StateChangeStatistic,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const PresenceDetectorSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT]: PresenceDetectorObjectPresentEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventStatisticsTypes: {
        [SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT]: PresenceStateChangeStatistic,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const ProximityCounterSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT_DELTA]: ObjectPresentCountEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventStatisticsTypes: {
        [SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT_DELTA]: ProximityCountStatistics,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const WaterDetectorSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.WATER_PRESENT]: WaterPresentEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventStatisticsTypes: {
        [SENSOR_EVENT_TYPES_V2.WATER_PRESENT]: StateEnteringsStatistic,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const TouchSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.TOUCH]: TouchEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventStatisticsTypes: {
        [SENSOR_EVENT_TYPES_V2.TOUCH]: TouchCountStatistic,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const TouchCounterSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.TOUCH]: TouchCountEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventStatisticsTypes: {
        [SENSOR_EVENT_TYPES_V2.TOUCH]: TouchCountStatistic,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const SigmaSensorMeta = (): SensorMeta => ({
    readingEventTypes: {},
    metaEventTypes: {},
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const DeskOccupancySensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.DESK_OCCUPANCY]: DeskOccupancyEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventStatisticsTypes: {
        [SENSOR_EVENT_TYPES_V2.DESK_OCCUPANCY]: DeskOccupationDurationStatistic,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const PipeMonitoringSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.TEMPERATURE]: TemperatureEvent,
        [SENSOR_EVENT_TYPES_V2.WATER_MOVING]: WaterMovingEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const SpaceSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.SPACE_OCCUPANCY]: SpaceOccupancyEvent,
    },
    eventStatisticsTypes: {
        [`${SENSOR_EVENT_TYPES_V2.SPACE_OCCUPANCY}-max`]: MaxSpaceOccupancyStatistic,
        [`${SENSOR_EVENT_TYPES_V2.SPACE_OCCUPANCY}-average`]:
            AverageSpaceOccupancyStatistic,
        [`${SENSOR_EVENT_TYPES_V2.SPACE_OCCUPANCY}-footfall`]: SpaceFootfallStatistic,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const FeedbackPanelMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.FEEDBACK_PANEL_TOUCH]: FeedbackPanelTouchEvent,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const CloudConnectorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_TYPE]: CConConnectionStatusEvent,
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkHubSignalEvent,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const MonnitHubSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_TYPE]: MonnitHubConnectionStatusEvent,
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkHubSignalEvent,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const AirQualitySensorMeta = (sensor: SensorShape): SensorMeta => {
    const allPossibleReadings: Partial<Record<SensorEventType, unknown>> = {
        [SENSOR_EVENT_TYPES_V2.TEMPERATURE]: TemperatureEvent,
        [SENSOR_EVENT_TYPES_V2.HUMIDITY]: HumidityEvent,
        [SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE]: CO2Event,
        [SENSOR_EVENT_TYPES_V2.TOTAL_VOLATILE_ORGANIC_COMPOUND]: TVOCEvent,
        [SENSOR_EVENT_TYPES_V2.RADON_SHORT_TERM_AVG]: RadonEvent,
        [SENSOR_EVENT_TYPES_V2.AIR_PRESSURE]: AirPressureEvent,
        [SENSOR_EVENT_TYPES_V2.LIGHT]: LightEvent,
        [SENSOR_EVENT_TYPES_V2.NOISE]: NoiseEvent,
        [SENSOR_EVENT_TYPES_V2.VIRUS_RISK]: VirusRiskEvent,
        [SENSOR_EVENT_TYPES_V2.PARTICULATE_MATTER_1]: PM1Event,
        [SENSOR_EVENT_TYPES_V2.PARTICULATE_MATTER_2_POINT_5]: PM25Event,
        [SENSOR_EVENT_TYPES_V2.PARTICULATE_MATTER_10]: PM10Event,
    };

    return {
        readingEventTypes: sensor?.reading_types?.reduce((dict, readingKey) => {
            // Filter out the ones that this sensor does not measure to not show it in the sidecard in detail view.
            // The keys don't matter much, they are just used as the HTML `key` field to differentiate divs
            const airQualityReading = allPossibleReadings[readingKey as SensorEventType];

            if (!isNil(airQualityReading)) {
                return { ...dict, [readingKey]: airQualityReading };
            }

            return dict;
        }, {}),
        metaEventTypes: {
            [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
            [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
        },
        readingsSortingTemplate: AirQualityReadingsOrder,
        eventsOverviewHoursTypes: null,
        eventsOverviewDaysTypes: null,
    };
};

export const HubMeta = (): SensorMeta => ({
    readingEventTypes: {},
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const ElectricitySensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: KilowattHoursEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventStatisticsTypes: {
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: TotalEnergyConsumptionStatisticsV2,
    },
    eventsOverviewHoursTypes: {
        [SENSOR_EVENTS_OVERVIEW_TYPE.energyConsumptionWorkingHours]:
            ElectricityAverageWorkingHoursConsumption,
        [SENSOR_EVENTS_OVERVIEW_TYPE.energyConsumptionOutOfHours]:
            ElectricityAverageNonWorkingHoursConsumption,
        [SENSOR_EVENTS_OVERVIEW_TYPE.energyConsumptionPercentageHours]:
            ElectricityAveragePercentageHoursConsumption,
    },
    eventsOverviewDaysTypes: {
        [SENSOR_EVENTS_OVERVIEW_TYPE.energyConsumptionWorkingDays]:
            ElectricityAverageWorkingDaysConsumption,
        [SENSOR_EVENTS_OVERVIEW_TYPE.energyConsumptionNonWorkingDays]:
            ElectricityAverageNonWorkingDaysConsumption,
        [SENSOR_EVENTS_OVERVIEW_TYPE.energyConsumptionPercentageDays]:
            ElectricityAveragePercentageDaysConsumption,
    },
});

export const ElectricityMeterSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: KilowattHoursEvent,
    },
    eventStatisticsTypes: {
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: TotalEnergyConsumptionStatisticsV2,
    },
});

export const EmergencyLightSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.EMERGENCY_LIGHT_TEST]: EmergencyLightTestEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const SmartSpaceSensorMeta = (): SensorMeta => ({
    readingEventTypes: {
        [SENSOR_EVENT_TYPES_V2.SPACE_OCCUPANCY]: SpaceOccupancyEvent,
    },
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

export const SmartSpaceHubMeta = (): SensorMeta => ({
    readingEventTypes: {},
    metaEventTypes: {
        [SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL]: NetworkStatusEvent,
        [SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL]: BatteryStatusEvent,
    },
    eventsOverviewHoursTypes: null,
    eventsOverviewDaysTypes: null,
});

const SENSOR_TYPE_META = {
    [SENSOR_TYPE.TYPE_TEMPERATURE]: TemperatureSensorMeta,
    [SENSOR_TYPE.TYPE_AVERAGE_TEMPERATURE]: AverageTemperatureSensorMeta,
    [SENSOR_TYPE.TYPE_PROXIMITY]: ProximitySensorMeta,
    [SENSOR_TYPE.TYPE_PRESENCE]: PresenceDetectorSensorMeta,
    [SENSOR_TYPE.TYPE_PROXIMITY_COUNTER]: ProximityCounterSensorMeta,
    [SENSOR_TYPE.TYPE_HUMIDITY]: HumiditySensorMeta,
    [SENSOR_TYPE.TYPE_TOUCH]: TouchSensorMeta,
    [SENSOR_TYPE.TYPE_TOUCH_COUNTER]: TouchCounterSensorMeta,
    [SENSOR_TYPE.TYPE_CLEANING_CONFIRMATION]: TouchSensorMeta,
    [SENSOR_TYPE.TYPE_SIGMA]: SigmaSensorMeta,
    [SENSOR_TYPE.TYPE_DESK_OCCUPANCY]: DeskOccupancySensorMeta,
    [SENSOR_TYPE.TYPE_LEGIONNAIRE]: PipeMonitoringSensorMeta,
    [SENSOR_TYPE.TYPE_CCON]: CloudConnectorMeta,
    [SENSOR_TYPE.TYPE_WATER_DETECTOR]: WaterDetectorSensorMeta,
    [SENSOR_TYPE.TYPE_SPACE]: SpaceSensorMeta,
    [SENSOR_TYPE.TYPE_AIR_QUALITY]: AirQualitySensorMeta,
    [SENSOR_TYPE.TYPE_AIRTHINGS_HUB]: HubMeta,
    [SENSOR_TYPE.TYPE_FEEDBACK_PANEL]: FeedbackPanelMeta,
    [SENSOR_TYPE.TYPE_ELECTRICITY_DRY_PULSE_COUNTER]: ElectricitySensorMeta,
    [SENSOR_TYPE.TYPE_ELECTRICITY_CT_CLAMP]: ElectricitySensorMeta,
    [SENSOR_TYPE.TYPE_MONNIT_HUB]: MonnitHubSensorMeta,
    [SENSOR_TYPE.TYPE_EMERGENCY_LIGHT]: EmergencyLightSensorMeta,
    [SENSOR_TYPE.TYPE_SILUX_HUB]: HubMeta,
    [SENSOR_TYPE.TYPE_COLD_STORAGE]: TemperatureSensorMeta,
    [SENSOR_TYPE.TYPE_FLUDIA_HUB]: HubMeta,
    [SENSOR_TYPE.TYPE_ELECTRICITY_LED_PULSE_METER]: ElectricitySensorMeta,
    [SENSOR_TYPE.TYPE_ELECTRICITY_METER]: ElectricityMeterSensorMeta,
    [SENSOR_TYPE.TYPE_GAS_METER]: ElectricityMeterSensorMeta,
    [SENSOR_TYPE.TYPE_SMART_SPACE]: SmartSpaceSensorMeta,
    [SENSOR_TYPE.TYPE_SMART_SPACE_MAX]: SmartSpaceSensorMeta,
    [SENSOR_TYPE.TYPE_URBAN_HUB]: SmartSpaceHubMeta,
};

/**
 *   Returns the correct meta-information (readingEventTypes, eventStatisticsTypes and etc) for a particular sensor.
 */
export const getSensorMetaInfo = (sensor: SensorShape): SensorMeta => {
    const generateSensorMetaInfo = SENSOR_TYPE_META[sensor?.type_code];

    return generateSensorMetaInfo?.(sensor);
};

/*
 * Not in eventStatistics.js to avoid circular dependency from depending on getSensorMetaInfo.
 * Returns translated labels.
 */
export const useEventStatistics = ({
    sensor,
    sensorEvents,
    timeRange,
    isLoading,
    selectedUnit,
}: {
    sensor: SensorShape;
    sensorEvents: SensorEventShape[];
    timeRange?: TimeRange;
    isLoading: boolean;
    selectedUnit?: string;
}) => {
    const { t, i18n } = useTranslation('sensor-events');
    const locale = i18n.language;
    const userPreferences = useAppSelector(selectUserPreferences, shallowEqual);
    const sensorMeta = useMemo(() => getSensorMetaInfo(sensor), [sensor]);

    return useMemo(
        () =>
            formatEventStatistics(
                sensor,
                sensorMeta?.eventStatisticsTypes,
                {
                    userPreferences,
                    sensorEvents,
                    timeRange,
                    isLoading,
                    t,
                    locale,
                },
                selectedUnit,
            ),
        [
            sensor,
            sensorEvents,
            sensorMeta,
            userPreferences,
            isLoading,
            timeRange,
            t,
            locale,
            selectedUnit,
        ],
    );
};
