import type { Floor } from '@infogrid/locations-types';
import classNames from 'classnames';
import type { KeyboardEvent, ReactElement, ReactNode } from 'react';
import { memo } from 'react';

import { useFloorRowCardStyles } from './styles';

interface FloorRowCardProps {
    children: ReactNode;
    isActive: boolean;
    onClick?: (floor: Floor) => void;
    floor?: Floor;
}

/**
 * Basic styling component for Floors, handling the active state. For use alongside FloorList
 * (e.g. for including in the renderRow callback implementation).
 */
export const FloorRowCard = ({
    children,
    isActive,
    onClick,
    floor,
}: FloorRowCardProps): ReactElement => {
    const styles = useFloorRowCardStyles();

    const handleClick = () => {
        if (onClick && floor) {
            onClick(floor);
        }
    };

    const handleKeyPress = (e: KeyboardEvent) => {
        if (onClick && floor && e.key === 'Enter') {
            onClick(floor);
        }
    };

    return (
        <div
            role="button"
            className={classNames(styles.container, {
                [styles.activeContainer]: isActive,
                [styles.clickableContainer]: !!onClick,
            })}
            onClick={handleClick}
            onKeyPress={handleKeyPress}
            data-cypress="floor-list-item"
            data-testid="floor-list-item"
        >
            {children}
        </div>
    );
};

export default memo(FloorRowCard);
