import type { Dashboard } from '@infogrid/dashboards-constants';
import { formatDateWithTimezone } from '@infogrid/utils-dates';

export function shortDate(
    timestamp: number,
    timeZone: string,
    locale: string | null,
): string {
    return formatDateWithTimezone(timestamp, timeZone, 'DD MMM YY', locale);
}

export function hasUserPermissions(dashboard: Dashboard): boolean {
    return Boolean(dashboard && dashboard.user_permissions?.length > 0);
}

export function canViewDashboard(dashboard: Dashboard): boolean {
    return dashboard.user_permissions.includes('view_dashboard');
}

export function canChangeDashboard(dashboard: Dashboard): boolean {
    return dashboard.user_permissions.includes('change_dashboard');
}

export function canDeleteDashboard(dashboard: Dashboard): boolean {
    return dashboard.user_permissions.includes('delete_dashboard');
}
