import { makeStyles } from '@material-ui/core';

export const useEventsWebhookFormStyles = makeStyles((theme) => ({
    titleBar: {
        marginBottom: `${theme.spacing(3)}px !important`,
        flexWrap: 'wrap',
    },
    infoMessage: {
        marginBottom: theme.spacing(3),
    },
    fieldGroup: {
        paddingBottom: theme.spacing(3),
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    formItem: {
        width: 'min(400px, 100%)',
        maxWidth: '400px',
        marginRight: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(1.25),
        justifyContent: 'left',
        minWidth: '150px',
    },
}));
