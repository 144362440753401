import { useDashboard, useUpdateWidget, useWidgetData } from '@infogrid/dashboards-hooks';
import { useIsMobile, useIsOpenState } from '@infogrid/utils-hooks';
import { CircularProgress } from '@material-ui/core';
import isNil from 'lodash/isNil';
import { memo, useCallback, useMemo, useRef } from 'react';
import type { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import {
    GenericActions,
    GenericDetails,
    GenericFooter,
    GenericTitle,
    Widget,
} from 'views/dashboards/components/Widget';
import type { ConfigurableProps } from 'views/dashboards/components/Widget/GenericWidget/GenericActions/types';
import { configurationDefaults } from 'views/dashboards/components/Widget/GenericWidget/constants';
import {
    useGenericWidgetContainerStyles,
    useGenericWidgetStyles,
} from 'views/dashboards/components/Widget/GenericWidget/styles';
import type { ConfigurationProps } from 'views/dashboards/components/Widget/GenericWidget/types';
import {
    ConfigureWidgetModal,
    DeleteWidgetModal,
} from 'views/dashboards/components/modals';
import { canChangeDashboard, hasUserPermissions } from 'views/dashboards/utils/helpers';
import { useConfigureWidgetModalOpenState } from 'views/dashboards/utils/hooks';

import { generateGenericFooterLabels } from '../utils';
import Content from './Content';
import { widgetConfigurationModalTabs } from './constants';
import type {
    DeskOccupancyData,
    DeskOccupancyValues,
    DeskOccupancyWidget as DeskOccupancyWidgetType,
} from './types';
import {
    prepareConfigurationPayload,
    prepareInitialValues,
    extractAverageOccupancy,
} from './utils';

export interface DeskOccupancyWidgetProps {
    canDragAndResize: boolean;
    configuration?: ConfigurationProps;
    containerClassName?: string;
    contentClassName?: string;
    layout?: Layout;
    useIntersectionObserverLoader?: boolean;
    widget: DeskOccupancyWidgetType;
}

const DeskOccupancyWidget = ({
    canDragAndResize,
    configuration = configurationDefaults,
    containerClassName,
    contentClassName,
    layout,
    useIntersectionObserverLoader = true,
    widget,
}: DeskOccupancyWidgetProps) => {
    const containerStyles = useGenericWidgetContainerStyles();
    const styles = useGenericWidgetStyles();

    const { t, i18n } = useTranslation('dashboard');

    const { dashboardId } = useParams<{ dashboardId: string }>();

    const isMobile = useIsMobile();

    const { data: dashboard } = useDashboard(+dashboardId);

    const {
        handleConfigureWidgetModalClose,
        handleConfigureWidgetModalOpen,
        isConfigureWidgetModalOpen,
    } = useConfigureWidgetModalOpenState(widget.id, widget.type);

    const isHardRefreshData = useRef(false);

    const [
        deleteWidgetModalOpen,
        handleDeleteWidgetModalClose,
        handleDeleteWidgetModalOpen,
    ] = useIsOpenState();

    const { mutate: updateWidget, isLoading: isUpdatingWidget } =
        useUpdateWidget<DeskOccupancyWidgetType>();

    /**
     * INFO: this check is enough for now, when user selects
     * sensor and clicks save - all the date range / display options
     * defaults are generated & saved as well
     */
    const isInitiallyConfigured = !!widget.configuration.time_period_range;

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: '100px 0px',
        skip: !useIntersectionObserverLoader,
        initialInView: !useIntersectionObserverLoader,
        triggerOnce: true,
    });

    const {
        data: widgetData,
        isFetching: fetchingWidgetData,
        isLoading: loadingWidgetData,
        isSuccess: loadingWidgetDataSuccess,
        refetch: refetchWidgetData,
        isError: isLoadingWidgetDataError,
    } = useWidgetData<DeskOccupancyData>(+dashboardId, widget.id, {
        getQueryParams: () => ({
            refresh: isHardRefreshData.current || undefined,
        }),
        options: {
            enabled: inView && isInitiallyConfigured,
            onSettled: () => {
                isHardRefreshData.current = false;
            },
        },
    });

    const details = useMemo(
        () => [
            {
                label: t('Average occupancy:'),
                value: extractAverageOccupancy({ widget, widgetData }, t),
            },
        ],
        [t, widget, widgetData],
    );

    const onUpdateWidgetTitle = useCallback(
        (title: string) => {
            updateWidget({
                dashboardId: +dashboardId,
                widgetId: widget.id,
                data: { name: title },
            });
        },
        [dashboardId, updateWidget, widget.id],
    );

    const onUpdateConfiguration = useCallback(
        (values: DeskOccupancyValues) => {
            updateWidget({
                dashboardId: +dashboardId,
                widgetId: widget.id,
                data: {
                    ...widget,
                    configuration: prepareConfigurationPayload(values),
                },
            });
        },
        [dashboardId, updateWidget, widget],
    );

    const widgetConfigurationInitialValues = useMemo(
        () => prepareInitialValues(widget),
        [widget],
    );

    const { periodLabel, refreshRateLabel, sensorsLabel } = generateGenericFooterLabels(
        widget,
        widgetConfigurationInitialValues,
        t,
        i18n,
    );

    const configurableProps: ConfigurableProps = {
        onConfigure: handleConfigureWidgetModalOpen,
        onDelete: handleDeleteWidgetModalOpen,
        isDuplicateDisabled: !isInitiallyConfigured,
        isConfigureWidgetAvailable: true,
    };

    const {
        actionsEnabled,
        isActionsEnabled,
        isEnabled: isConfigureWidgetModalEnabled,
    } = configuration;

    const widgetCardRef = useRef(null);

    const onRefresh = useCallback(() => {
        isHardRefreshData.current = true;
        refetchWidgetData();
    }, [refetchWidgetData]);

    const canUserEdit = useMemo(() => {
        if (!isNil(configurableProps.canUserEdit)) {
            return configurableProps.canUserEdit;
        }

        return (
            !!dashboard && hasUserPermissions(dashboard) && canChangeDashboard(dashboard)
        );
    }, [configurableProps.canUserEdit, dashboard]);

    return (
        <div ref={ref} className={containerStyles.container} data-cypress="widget">
            <Widget className={containerClassName}>
                <Widget.Bar canDragAndResize={canDragAndResize}>
                    <GenericTitle
                        onUpdateTitle={onUpdateWidgetTitle}
                        title={widget.name}
                        titleEditable={canUserEdit}
                        titleIcon={<i className="far fa-chair-office" />}
                    />
                    {((actionsEnabled && !isMobile) || isActionsEnabled) && (
                        <GenericActions
                            canUserEdit={canUserEdit}
                            configurableProps={configurableProps}
                            dashboardId={dashboardId}
                            refreshableProps={{
                                enabled: isInitiallyConfigured,
                                isLoading: fetchingWidgetData && loadingWidgetDataSuccess,
                                onRefresh,
                            }}
                            widgetConfiguration={widget.configuration}
                            widgetName={widget.name}
                            widgetType={widget.type}
                        />
                    )}
                </Widget.Bar>

                {loadingWidgetData && (
                    <div className={styles.progress}>
                        <CircularProgress
                            color="primary"
                            data-testid="widget-circular-loader"
                            size={40}
                        />
                    </div>
                )}

                {inView && !loadingWidgetData && (
                    <>
                        <GenericDetails details={details} />
                        <Widget.Content
                            ref={widgetCardRef}
                            className={contentClassName}
                            layout={layout}
                        >
                            <Content
                                data={widgetData}
                                isConfigured={isInitiallyConfigured}
                                isLoadingDataError={isLoadingWidgetDataError}
                                widget={widget}
                            />
                        </Widget.Content>
                    </>
                )}

                <GenericFooter
                    periodLabel={periodLabel}
                    refreshRateLabel={refreshRateLabel}
                    sensorsLabel={sensorsLabel}
                />

                {isConfigureWidgetModalEnabled && isConfigureWidgetModalOpen && (
                    <ConfigureWidgetModal
                        initialValues={widgetConfigurationInitialValues}
                        loading={isUpdatingWidget}
                        onClose={handleConfigureWidgetModalClose}
                        onUpdateConfiguration={onUpdateConfiguration}
                        open={isConfigureWidgetModalOpen}
                        prepareConfigurationPayload={prepareConfigurationPayload}
                        prepareInitialValues={prepareInitialValues}
                        tabs={widgetConfigurationModalTabs}
                    />
                )}

                <DeleteWidgetModal
                    dashboardId={dashboardId}
                    onClose={handleDeleteWidgetModalClose}
                    open={deleteWidgetModalOpen}
                    widgetId={widget.id}
                    widgetName={widget.name}
                    widgetType={widget.type}
                />
            </Widget>
        </div>
    );
};

export default memo(DeskOccupancyWidget);
