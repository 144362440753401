import { makeStyles } from '@material-ui/core';

export const useFeedbackPanelTemplateSelectorStyles = makeStyles((theme) => ({
    modalContent: {
        minHeight: 0,
        overflowY: 'auto',
    },
    divider: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
    },
    noTemplateText: {
        color: theme.palette.common.gray6,
    },
}));
