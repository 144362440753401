import { makeStyles } from '@material-ui/core';

interface FilterableWebhooksPageStylesProps {
    isMobileView?: boolean;
}

export const useFilterableWebhooksPageStyles = makeStyles((theme) => ({
    addWebhookButton: {
        marginTop: ({ isMobileView }: FilterableWebhooksPageStylesProps) =>
            isMobileView ? theme.spacing(2.5) : '0',
    },
    description: {
        marginTop: theme.spacing(1),
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: ({ isMobileView }: FilterableWebhooksPageStylesProps) =>
            isMobileView ? 'stretch' : 'flex-start',
        flexDirection: ({ isMobileView }: FilterableWebhooksPageStylesProps) =>
            isMobileView ? 'column' : 'row',
    },
    helpCenterLink: {
        color: '#0084BC',
        textDecoration: 'underline',
        fontWeight: 500,
    },
    pageTitle: {
        fontWeight: 500,
        fontSize: 20,
        letterSpacing: 0.15,
    },
    webhookViewContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2.5),
        margin: theme.spacing(3, 0),
    },
}));
