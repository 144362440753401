import { ConfirmModal } from '@infogrid/components-material-ui';
import type { Widget } from '@infogrid/dashboards-constants';
import { useDeleteWidget } from '@infogrid/dashboards-hooks';
import { Typography } from '@material-ui/core';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { deleteWidgetEvent } from 'views/dashboards/utils/analytics';

const modalActionsProps = { justify: 'space-between' };

export interface DeleteWidgetModalProps {
    dashboardId: string;
    open: boolean;
    onClose: () => void;
    widgetId: Widget['id'];
    widgetName: Widget['name'];
    widgetType: Widget['type'];
}

const DeleteWidgetModal = ({
    dashboardId,
    open,
    onClose,
    widgetId,
    widgetName,
    widgetType,
}: DeleteWidgetModalProps) => {
    const { t } = useTranslation('dashboard');

    const { mutate: deleteWidget, isLoading: isDeletingWidget } = useDeleteWidget({
        onSuccess: () => {
            deleteWidgetEvent({ widgetType });
        },
    });

    const onConfirmDelete = useCallback(() => {
        onClose();
        deleteWidget({
            dashboardId: +dashboardId,
            widgetId,
        });
    }, [dashboardId, deleteWidget, onClose, widgetId]);

    const content = useMemo(
        () => (
            <>
                <Typography color="secondary" variant="body1">
                    {t('You are about to delete {{widgetName}} widget', {
                        widgetName,
                        defaultValue: `You are about to delete ${widgetName} widget`,
                    })}
                </Typography>
                <Typography color="textSecondary">
                    {t('This action cannot be undone.')}
                </Typography>
                <Typography color="textSecondary">
                    {t('Are you sure you want to continue?')}
                </Typography>
            </>
        ),
        [t, widgetName],
    );

    const confirmButtonProps = useMemo(
        () => ({
            color: 'secondary',
            disabled: isDeletingWidget,
        }),
        [isDeletingWidget],
    );

    return (
        <ConfirmModal
            fullWidth
            maxWidth="xs"
            title={t('Delete Widget')}
            open={open}
            onClose={onClose}
            onConfirm={onConfirmDelete}
            content={content}
            modalActionsProps={modalActionsProps}
            confirmButtonProps={confirmButtonProps}
            confirmButtonText={t('Confirm', { ns: 'common' })}
        />
    );
};

export default memo(DeleteWidgetModal);
