import { wrapResponsePromise } from '@infogrid/core-api';
import type { Folder } from '@infogrid/core-types';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getBuildingSubFoldersKey } from '../getQueryKeys';

export const useSubfolders = (
    { buildingId }: { buildingId: number | null },
    { ...options } = {},
) => {
    return useQuery(
        getBuildingSubFoldersKey({ buildingId }),
        ({ signal }) =>
            wrapResponsePromise(controllers.getSubfolders(buildingId as number, signal)),
        {
            initialData: [] as Folder[],
            enabled: !!buildingId,
            refetchOnMount: false,
            ...options,
        },
    );
};
