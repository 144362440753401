import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useFloorPlanWrapperStyles = makeStyles((theme: Theme) => ({
    alert: {
        maxWidth: '420px',
    },
    alertContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    controlsContainer: {
        display: 'flex',
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        alignItems: 'center',
        minHeight: '47px',
        borderTop: `1px solid ${grey[300]}`,
        padding: `0 ${theme.spacing(1.25)}px 0 0 !important`,
        flexShrink: 0,
        flexWrap: 'wrap',
    },
}));
