import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';

export const useReportConfigurationStyles = makeStyles((theme: Theme) => ({
    content: {
        // make only content of modal scrollable
        flexGrow: 1,
        overflowY: 'auto',
    },
    formSection: {
        marginBottom: theme.spacing(3),
    },
    mapWrapper: {
        height: '500px',
        marginTop: theme.spacing(2),
    },
    form: {
        // make only content of modal scrollable
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        flexGrow: 1,

        [theme.breakpoints.down('xs')]: {
            maxHeight: 'none',
        },
    },
    title: {
        color: theme.palette.common.gray8,
        marginBottom: theme.spacing(1),
        fontWeight: 500,
        letterSpacing: '0.15px',
    },
    description: {
        color: theme.palette.common.gray6,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    scheduleDescription: {
        color: theme.palette.common.gray6,
        marginBottom: theme.spacing(2),
    },
    errorDescription: {
        color: theme.palette.secondary.main,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(2),
    },
    schedulingContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    schedulingContainerRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        marginBottom: theme.spacing(3),

        [theme.breakpoints.down('xs')]: {
            flexFlow: 'column nowrap',
            marginBottom: 0,
            marginTop: 0,
        },
    },
    selectInput: {
        marginTop: theme.spacing(2),
    },
    actionButtonContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    actionButtonContainerRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        gap: theme.spacing(2),
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
    },
    reportCheckbox: {
        marginLeft: theme.spacing(1),
    },
    paperSizeRadio: {
        marginLeft: theme.spacing(2.5),
    },
    letterPageSize: {
        marginRight: theme.spacing(5.5),
    },
    floorplanPageConfiguration: {
        marginRight: theme.spacing(9.8),
    },
    reportParagraph: {
        marginBottom: theme.spacing(1),
    },
    doNotSendReportSwitch: {
        marginLeft: theme.spacing(2),

        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(0),
        },
    },
}));

export const useFrequencyInputStyles = makeStyles((theme) => ({
    root: {
        marginRight: theme.spacing(2),
        width: '40%',

        [theme.breakpoints.down('xs')]: {
            width: '60%',
            marginTop: theme.spacing(2),
            marginRight: 0,
            marginBottom: theme.spacing(3),
        },
    },
}));

export const useTimeInputStyles = makeStyles((theme) => ({
    root: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        width: '40%',

        [theme.breakpoints.down('xs')]: {
            width: '60%',
            marginBottom: theme.spacing(2),
            marginRight: 0,
        },
    },
}));

export const useBuildReportModalEmailInputClasses = makeStyles((theme) => ({
    inputRoot: {
        padding: theme.spacing(1.5, 1.25, 0.5),
        marginBottom: theme.spacing(2),
    },
    helperText: {
        marginBottom: theme.spacing(-1),
    },
}));

export const useInputStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
}));

export const useReportConfigurationContainerStyles = makeStyles((theme) => ({
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        height: 595,

        [theme.breakpoints.down('xs')]: {
            height: '100%',
        },
    },
}));

export const useDeleteConfirmationStyles = makeStyles((theme) => ({
    description: {
        marginBottom: theme.spacing(1),
    },
    actionButtonContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    actionButtonContainerRow: {
        display: 'flex',
        flexFlow: 'row nowrap',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        gap: theme.spacing(2),
    },
}));
