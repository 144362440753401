import { makeStyles } from '@material-ui/core';

export const useSelectOutputStyles = makeStyles((theme) => ({
    buildingSwitcherText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        textTransform: 'capitalize',

        marginRight: theme.spacing(2),
    },
    buildingSwitcherIconContainer: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(0.25),
    },
    buildingSwitcherIcon: {
        color: theme.palette.common.yellow3,

        fontSize: 18,
        lineHeight: '1em',
        width: '1.1em',
    },
}));
