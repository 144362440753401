export const WEBHOOK_FORM_MODE = {
    EDIT: 'edit',
    CREATE: 'create',
};

export type WebhookFormMode = typeof WEBHOOK_FORM_MODE[keyof typeof WEBHOOK_FORM_MODE];

export const WEBHOOK_HEADER_TYPE = {
    KEY: 'key',
    SIGNATURE: 'signature',
};

export type WebhookHeaderType =
    typeof WEBHOOK_HEADER_TYPE[keyof typeof WEBHOOK_HEADER_TYPE];
