import type { Space } from '@infogrid/locations-types';
import { useState, createContext, useContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';

interface SpaceModalContextType {
    spaceToEdit: Space | null;
    setSpaceToEdit: Dispatch<SetStateAction<Space | null>>;
}

const SpaceModelContext = createContext<SpaceModalContextType>({
    spaceToEdit: null,
    setSpaceToEdit: () => {},
});

interface ProviderProps {
    children: JSX.Element;
}

export const SpaceModalProvider = ({ children }: ProviderProps) => {
    const [spaceToEdit, setSpaceToEdit] = useState<Space | null>(null);

    return (
        <SpaceModelContext.Provider
            value={{
                spaceToEdit,
                setSpaceToEdit,
            }}
        >
            {children}
        </SpaceModelContext.Provider>
    );
};

export const useSpaceModalContext = () => useContext(SpaceModelContext);
