import { toastSuccess } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { i18n } from '@infogrid/utils-i18n';
import { saveResults } from '@thorgate/spa-entities';
import { createSaveAction, createFormSaveSaga } from '@thorgate/spa-forms';
import { push } from 'connected-react-router';
import { takeLatest, put, call } from 'redux-saga/effects';

import { orgUserSchema } from 'schemas/orgUser';
import api from 'services/api';
import { handleDelayingFormSave } from 'utils/handleDelayingFormSave';
import { organizationMutateKwargs } from 'utils/organization';

/**
 * Trigger Create Organization Account worker saga.
 * @param {Object} payload Request info that is sent to server
 * @param {Object} FormikActions - Function to set form errors
 */
export const createOrgUser = createSaveAction('sagas/account/CREATE_USER');

const createOrgUserSagaWorker = createFormSaveSaga({
    resource: api.organization.user.list,

    *successHook(orgUser) {
        yield call(saveResults, orgUserSchema.key, [orgUser], [orgUserSchema], {
            updateOrder: true,
        });
        yield put(
            toastSuccess({
                message: `${i18n.t('User created')} - ${orgUser.email}`,
            }),
        );

        yield put(push(routesManager.resolvePath('organization:accounts')));
    },

    mutateKwargs: organizationMutateKwargs,
});

export default function* createOrgUserWatcherSaga() {
    yield takeLatest(
        createOrgUser.getType(),
        handleDelayingFormSave(
            createOrgUserSagaWorker,
            i18n.t('Creating a user is in progress'),
        ),
        null,
    );
}
