import type { QueryKey } from 'react-query';

import { FLOOR_PLAN_FOLDERS, FLOOR_PLAN_SUBFOLDERS } from 'apiHooks/base';

export const getBuildingFoldersKey = (): QueryKey => [FLOOR_PLAN_FOLDERS];

export const getBuildingSubFoldersKey = ({
    buildingId,
}: {
    buildingId: number | null;
}): QueryKey => [buildingId, FLOOR_PLAN_SUBFOLDERS];
