import { LineChart, htmlDefaultTooltip } from '@infogrid/components-highcharts';
import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { generateChartData } from 'views/dashboards/widgets/ProximityCountWidget/utils';
import {
    getAxisTimeFormatByDataFrequency,
    getTooltipTimeFormatByDataFrequency,
} from 'views/dashboards/widgets/utils';

import WidgetAlert from '../../common/WidgetAlert';

const Content = ({ data, widget, isConfigured, isLoadingDataError }) => {
    const { t } = useTranslation('dashboard');

    const {
        proximity_count_graph_rows,
        proximity_count_sensor_names,
        proximity_counter_graph_rows,
        proximity_counter_sensor_names,
        no_data: isEmptyChartData,
    } = data;

    const {
        data_frequency: dataFrequency,
        time_zone: timeZone,
        time_period_from: startDate,
        time_period_to: endDate,
    } = widget?.configuration || {};

    const transformedData = useMemo(() => {
        const proximityData = generateChartData({
            proximity_count_graph_rows,
            proximity_count_sensor_names,
            proximity_counter_graph_rows,
            proximity_counter_sensor_names,
        });

        return [
            {
                name: t('Count of open events'),
                data: proximityData,
                turboThreshold: 0,
            },
        ];
    }, [
        t,
        proximity_count_graph_rows,
        proximity_count_sensor_names,
        proximity_counter_graph_rows,
        proximity_counter_sensor_names,
    ]);

    const chartProps = useMemo(() => {
        const timeFormat = getAxisTimeFormatByDataFrequency(
            dataFrequency,
            startDate,
            endDate,
        );
        const tooltipTimeFormat = getTooltipTimeFormatByDataFrequency(dataFrequency);

        return {
            xAxis: {
                labels: {
                    formatter({ chart }) {
                        // eslint-disable-next-line react/no-this-in-sfc
                        return chart.time.dateFormat(timeFormat, this.value);
                    },
                },
                startOnTick: false,
                type: 'datetime',
            },
            tooltip: htmlDefaultTooltip({ dateFormat: tooltipTimeFormat }),
            time: {
                timezone: timeZone,
            },
            series: transformedData,
        };
    }, [dataFrequency, startDate, endDate, timeZone, transformedData]);

    if (!isConfigured) {
        return (
            <WidgetAlert severity="warning">
                {t(WidgetErrorMessages.notConfigured)}
            </WidgetAlert>
        );
    }

    if (isLoadingDataError) {
        return (
            <WidgetAlert severity="error">
                {t(WidgetErrorMessages.dataLoadError)}
            </WidgetAlert>
        );
    }

    if (isEmptyChartData) {
        return <WidgetAlert severity="info">{t(WidgetErrorMessages.noData)}</WidgetAlert>;
    }

    return <LineChart chartProps={chartProps} />;
};

Content.propTypes = {
    widget: PropTypes.shape({
        configuration: PropTypes.shape({
            data_frequency: PropTypes.string,
            time_zone: PropTypes.string,
        }),
    }).isRequired,
    data: PropTypes.shape({
        proximity_count_graph_rows: PropTypes.arrayOf(
            PropTypes.shape({
                timestamp: PropTypes.string,
            }),
        ),
        proximity_count_sensor_names: PropTypes.shape({}),
        proximity_counter_graph_rows: PropTypes.arrayOf(
            PropTypes.shape({
                timestamp: PropTypes.string,
            }),
        ),
        proximity_counter_sensor_names: PropTypes.shape({}),
        no_data: PropTypes.bool,
    }).isRequired,
    isConfigured: PropTypes.bool.isRequired,
    isLoadingDataError: PropTypes.bool.isRequired,
};

export default memo(Content);
