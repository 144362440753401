import { LoadingPlaceholder, Modal } from '@infogrid/components-material-ui';
import type { CreateSpaceFormValues, SpaceTypeGroup } from '@infogrid/locations-types';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import type { FormikProps } from 'formik';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AddSpaceList from '../AddSpaceList';
import { useAddSpaceModalStyles } from './styles';

export interface AddSpaceModalProps {
    formik: FormikProps<CreateSpaceFormValues>;
    groups: SpaceTypeGroup[];
    isLoading: boolean;
    isOpen: boolean;
    onClose: () => void;
}

const AddSpaceModal = ({
    formik,
    groups,
    isLoading,
    isOpen,
    onClose,
}: AddSpaceModalProps) => {
    const styles = useAddSpaceModalStyles();
    const isMobile = useIsMobile();

    const { t } = useTranslation('estate');

    const { handleSubmit, values: spaceTypes } = formik;

    const onSave = () => {
        handleSubmit();
        onClose();
    };

    const shouldDisableSave = Object.keys(spaceTypes).length === 0;

    const renderedContent = useMemo(() => {
        if (isLoading) {
            return <LoadingPlaceholder classes={{ container: styles.loadingSpinner }} />;
        }

        const midpoint = Math.ceil(groups.length / 2);
        const leftColumn = groups.slice(0, midpoint);
        const rightColumn = groups.slice(midpoint);
        const columns = [leftColumn, rightColumn];

        return (
            <div className={styles.columnsContainer}>
                {columns.map((column, index) => (
                    <div className={styles.column} key={index}>
                        {column.map((group) => (
                            <AddSpaceList
                                group={group}
                                className={styles.spaceList}
                                key={group.parent}
                            />
                        ))}
                    </div>
                ))}
            </div>
        );
    }, [
        groups,
        isLoading,
        styles.column,
        styles.columnsContainer,
        styles.loadingSpinner,
        styles.spaceList,
    ]);

    return (
        <Modal fullScreen={isMobile} onClose={onClose} open={isOpen}>
            <Modal.Title>{t('Add spaces')}</Modal.Title>
            <Modal.Content className={styles.content}>
                <Alert data-cypress="help-text" severity="info">
                    {t(
                        'Identify spaces in your buildings where sensors will be grouped together, such as Desk Areas, Kitchens or Bathrooms, making it simple to locate them, set-up Alerts and report on the data. Upload a floor plan to visualise where your sensors will be installed.',
                    )}
                </Alert>
                {renderedContent}
            </Modal.Content>
            <Modal.Actions justify="right">
                <Button
                    aria-label="Add spaces"
                    color="primary"
                    data-cypress="add-spaces"
                    disabled={shouldDisableSave}
                    onClick={onSave}
                    variant="contained"
                >
                    {t('Add spaces')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(AddSpaceModal);
