import { Icon } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';

import Arrow from '../Arrow';
import Mark from '../Mark';
import ScoreGradient from '../ScoreGradient';
import { useScoreScaleStyles } from './styles';

interface Props {
    classes?: { root?: string };
}

const ScoreScale = ({ classes }: Props) => {
    const styles = useScoreScaleStyles();

    return (
        <div className={classNames(styles.root, classes?.root)}>
            <div className={classNames(styles.axis, styles.satisfactionAxis)}>
                <Icon
                    className={classNames(
                        'fal fa-dizzy',
                        styles.breakpoint,
                        styles.smile,
                    )}
                />
                <Arrow className={classNames(styles.arrow, styles.breakpoint)} />
                <Icon
                    className={classNames(
                        'fal fa-frown',
                        styles.breakpoint,
                        styles.smile,
                    )}
                />
                <Icon
                    className={classNames(
                        'fal fa-smile',
                        styles.breakpoint,
                        styles.smile,
                    )}
                />
                <Arrow
                    className={classNames(
                        styles.arrow,
                        styles.rotatedArrow,
                        styles.breakpoint,
                    )}
                />
                <Icon
                    className={classNames(
                        'fal fa-laugh',
                        styles.breakpoint,
                        styles.smile,
                    )}
                />
            </div>
            <div className={classNames(styles.axis)}>
                <Mark className={styles.mark} />
                <Mark className={styles.mark} />
                <ScoreGradient />
            </div>
            <div className={classNames(styles.axis, styles.percentageAxis)}>
                <span className={styles.percentBreakpoint}>0%</span>
                <span className={styles.percentBreakpoint}>40%</span>
                <span className={styles.percentBreakpoint}>60%</span>
                <span className={styles.percentBreakpoint}>100%</span>
            </div>
        </div>
    );
};

export default memo(ScoreScale);
