import { MenuList, Select } from '@infogrid/components-material-ui';
import { useAppDispatch } from '@infogrid/core-ducks';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Typography, Icon } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, List, InfiniteLoader } from 'react-virtualized';

import PermissionsWrapper from 'components/PermissionsWrapper';
import { toggleCreatingBuilding } from 'ducks/floorPlan';
import { BuildingListShape, BuildingShape } from 'utils/types';

import BuildingSwitcherOutput from '../BuildingSwitcherOutput';
import { useBuildingSwitcherStyles } from './styles';

const MENU_ADD_ACTION_ICON = 'fal fa-plus';

const DEFAULT_OVERSCAN_ROW_COUNT = 0;
const SENSORS_LIST_ROW_HEIGHT_DEFAULT = 48;
const SENSORS_LIST_ROW_HEIGHT_LONG = 72;
const LIST_MIN_HEIGHT = 270;
const LIST_MIN_WIDTH = 300;

const BuildingSwitcher = ({
    onSelectHandler,
    buildings,
    totalBuildingsCount,
    selectedBuilding,
    fetchMoreBuildings,
}) => {
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();

    const buildingSwitcherStyles = useBuildingSwitcherStyles({ isMobile });

    const { t } = useTranslation('floorplan');

    const openCreatingBuildingHandler = useCallback(() => {
        dispatch(toggleCreatingBuilding(true));
    }, [dispatch]);

    const renderSelectOutput = useCallback(
        () =>
            !!selectedBuilding && (
                <BuildingSwitcherOutput currentBuilding={selectedBuilding} />
            ),
        [selectedBuilding],
    );

    const classes = useMemo(
        () => ({
            container: buildingSwitcherStyles.container,
            content: buildingSwitcherStyles.content,
            renderValueIcon: buildingSwitcherStyles.renderValueIcon,
        }),
        [buildingSwitcherStyles],
    );

    const menuListClasses = {
        menuListContainer: buildingSwitcherStyles.menuListContainer,
    };

    const renderContent = ({ onSelect, onToggle }) => {
        const createBuilding = () => {
            openCreatingBuildingHandler();
            onToggle(false);
        };

        const getRowHeight = ({ index }) =>
            buildings[index % buildings.length].name.length < 36
                ? SENSORS_LIST_ROW_HEIGHT_DEFAULT
                : SENSORS_LIST_ROW_HEIGHT_LONG;

        const isRowLoaded = ({ index }) => {
            return index < buildings.length;
        };

        const noRowsRenderer = () => {
            return <></>;
        };

        const rowRenderer = ({ index, key, style }) => {
            const className = classNames({
                [buildingSwitcherStyles.activeBuilding]:
                    buildings[index] === selectedBuilding?.id,
            });

            const selectBuilding = () => {
                onSelect(buildings[index]);
            };

            return !isRowLoaded({ index }) ? (
                <Skeleton key={key} style={style} variant="text" />
            ) : (
                <div key={key} style={style}>
                    <MenuList.Item
                        key={buildings[index]}
                        onClick={selectBuilding}
                        className={className}
                    >
                        <Typography
                            className={buildingSwitcherStyles.itemText}
                            component="span"
                        >
                            {buildings[index].name}
                        </Typography>
                    </MenuList.Item>
                </div>
            );
        };

        return (
            <>
                <PermissionsWrapper>
                    <MenuList className={buildingSwitcherStyles.actionsSection}>
                        <MenuList.Item
                            className={buildingSwitcherStyles.actionsSectionItem}
                            onClick={createBuilding}
                        >
                            <Icon
                                className={classNames(
                                    MENU_ADD_ACTION_ICON,
                                    buildingSwitcherStyles.actionsSectionItemIcon,
                                )}
                            />
                            <Typography component="span" variant="subtitle1">
                                {t('Create New Building')}
                            </Typography>
                        </MenuList.Item>
                    </MenuList>
                </PermissionsWrapper>

                <MenuList.Divider />

                <MenuList classes={menuListClasses}>
                    <div className={buildingSwitcherStyles.autoSizerContainer}>
                        <AutoSizer>
                            {({ height, width }) => (
                                <InfiniteLoader
                                    isRowLoaded={isRowLoaded}
                                    // @ts-expect-error loadMoreRows type signature does not match our RQ signature
                                    loadMoreRows={fetchMoreBuildings}
                                    rowCount={totalBuildingsCount}
                                >
                                    {({ onRowsRendered, registerChild }) => (
                                        <List
                                            ref={registerChild}
                                            className={classNames(
                                                buildingSwitcherStyles.buildingsList,
                                                buildingSwitcherStyles.scrollbar,
                                            )}
                                            height={height ? height : LIST_MIN_HEIGHT}
                                            noRowsRenderer={noRowsRenderer}
                                            onRowsRendered={onRowsRendered}
                                            overscanRowCount={DEFAULT_OVERSCAN_ROW_COUNT}
                                            rowCount={totalBuildingsCount}
                                            rowHeight={getRowHeight}
                                            rowRenderer={rowRenderer}
                                            width={width ? width : LIST_MIN_WIDTH}
                                        />
                                    )}
                                </InfiniteLoader>
                            )}
                        </AutoSizer>
                    </div>
                </MenuList>
            </>
        );
    };

    return (
        <Select
            classes={classes}
            onSelect={onSelectHandler}
            renderValue={renderSelectOutput}
        >
            {renderContent}
        </Select>
    );
};

BuildingSwitcher.propTypes = {
    buildings: BuildingListShape.isRequired,
    totalBuildingsCount: PropTypes.number.isRequired,
    onSelectHandler: PropTypes.func.isRequired,
    selectedBuilding: BuildingShape,
    fetchMoreBuildings: PropTypes.func.isRequired,
};
BuildingSwitcher.defaultProps = {};

export default memo(BuildingSwitcher);
