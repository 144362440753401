import { ICONS } from '@infogrid/components-icons';
import { ORGANIZATION_FEATURE_FLAG, orgHasFlag } from '@infogrid/core-feature-flags';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import { SHOULD_SHOW_BUILD_VERSION } from 'constants/buildVersion';
import settings from 'settings';

export const NAVBAR_LINK_TYPES = {
    LINK: 'LINK',
    DIALOG: 'DIALOG',
    TEXT: 'TEXT',
};

const buildNavigationGroups = (backendVersion, enabledSolutions) => {
    const frontendVersion = settings.VERSION_HASH;

    const dashboardGroup = {
        permissions: { orgRequired: true },
        feature: {},
        id: 'dashboardGroup',

        items: [
            {
                icon: `far ${ICONS.MAIN_MENU.DASHBOARD}`,
                name: registerTranslationKey('Dashboard'),
                path: 'dashboards:root-redirect',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {},
                feature: {},
                isMobileAvailable: true,
            },
        ],
    };

    const sensorsGroup = {
        permissions: { orgRequired: true },
        feature: {},
        id: 'sensorsGroup',

        items: [
            {
                icon: `far ${ICONS.MAIN_MENU.SOLUTIONS}`,
                name: registerTranslationKey('Solutions'),
                path: 'solutions',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {},
                feature: {},
                isMobileAvailable: true,
                isHidden: enabledSolutions.length === 0,
            },
            {
                icon: `far ${ICONS.MAIN_MENU.SITES}`,
                name: registerTranslationKey('Buildings'),
                path: 'sites',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {},
                feature: {},
                isMobileAvailable: true,
            },
            {
                icon: `far ${ICONS.MAIN_MENU.FOLDERS}`,
                name: registerTranslationKey('Folders'),
                path: 'folders',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {},
                feature: {},
                isMobileAvailable: true,
            },
            {
                icon: `far ${ICONS.MAIN_MENU.SENSORS}`,
                name: registerTranslationKey('Sensors'),
                path: 'sensors:list',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {},
                feature: {},
                isMobileAvailable: true,
            },
            {
                icon: `far ${ICONS.MAIN_MENU.FLOORS}`,
                name: registerTranslationKey('Floors'),
                path: 'buildings',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {},
                feature: {},
                isMobileAvailable: true,
            },
            {
                icon: `far ${ICONS.MAIN_MENU.REPORTS}`,
                name: registerTranslationKey('Reports'),
                path: 'reports',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {},
                feature: {},
                isMobileAvailable: true,
            },
        ],
    };

    const usersGroup = {
        permissions: { orgRequired: true },
        feature: {},
        id: 'usersGroup',

        items: [
            {
                icon: `far ${ICONS.MAIN_MENU.USERS}`,
                name: registerTranslationKey('Users'),
                path: 'organization:accounts',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {
                    managerRequired: true,
                },
                feature: {},
                isMobileAvailable: true,
            },
            {
                icon: `far ${ICONS.MAIN_MENU.TEAMS}`,
                name: registerTranslationKey('Teams'),
                path: 'organization:teams',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {},
                feature: {},
                isMobileAvailable: true,
            },
            {
                icon: `far ${ICONS.MAIN_MENU.ALERTS}`,
                name: registerTranslationKey('Alerts'),
                path: 'alert',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {},
                feature: {},
                isMobileAvailable: true,
            },
        ],
    };

    const supportGroup = {
        permissions: { orgRequired: false },
        feature: {},
        id: 'supportGroup',

        items: [
            ...(SHOULD_SHOW_BUILD_VERSION
                ? [
                      {
                          name: `FE: ${frontendVersion}`,
                          permissions: {},
                          feature: {},
                          type: NAVBAR_LINK_TYPES.TEXT,
                          isMobileAvailable: true,
                      },
                      {
                          name: `BE: ${backendVersion}`,
                          permissions: {},
                          feature: {},
                          type: NAVBAR_LINK_TYPES.TEXT,
                          isMobileAvailable: true,
                      },
                  ]
                : []),
            {
                icon: `far ${ICONS.MAIN_MENU.SUPPORT}`,
                name: registerTranslationKey('Support'),
                permissions: {},
                feature: {},
                type: NAVBAR_LINK_TYPES.DIALOG,
                modalName: 'Support',
                isMobileAvailable: true,
            },
            {
                icon: `far ${ICONS.MAIN_MENU.PROFILE}`,
                name: registerTranslationKey('Profile'),
                path: 'profile',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {},
                feature: {},
                isMobileAvailable: true,
            },
            {
                icon: `far ${ICONS.MAIN_MENU.ORG_SETTINGS}`,
                name: registerTranslationKey('Settings'),
                path: 'settings',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: { managerRequired: true },
                feature: {},
                isMobileAvailable: true,
            },
            {
                icon: `far ${ICONS.MAIN_MENU.LOGOUT}`,
                name: registerTranslationKey('Logout'),
                path: 'auth:logout',
                type: NAVBAR_LINK_TYPES.LINK,
                permissions: {},
                feature: {},
                isMobileAvailable: true,
            },
        ],
    };

    return [dashboardGroup, sensorsGroup, usersGroup, supportGroup];
};

const getNavigationGroups = ({
    org,
    isManager,
    isMobile,
    backendVersion,
    enabledSolutions,
}) =>
    buildNavigationGroups(backendVersion, enabledSolutions)
        .filter(({ permissions: { orgRequired, managerRequired } }) => {
            if (orgRequired && !org) {
                return false;
            }

            if (managerRequired && !isManager) {
                return false;
            }

            return true;
        })
        .map((group) => ({
            ...group,
            items: group.items.filter(
                ({
                    permissions: { managerRequired, requiredFeatureFlag },
                    isMobileAvailable,
                }) => {
                    if (managerRequired && !isManager) {
                        return false;
                    }

                    if (requiredFeatureFlag && !orgHasFlag(org, requiredFeatureFlag)) {
                        return false;
                    }

                    if (isMobile && !isMobileAvailable) {
                        return false;
                    }

                    return true;
                },
            ),
        }));

export default getNavigationGroups;
