import type {
    CurrentOccupancyResult,
    OccupiedMeetingRoomsResult,
} from '@infogrid/solution-views-occupancy';
import isNil from 'lodash/isNil';

export const calculateFreeDesks = (
    data: CurrentOccupancyResult | undefined,
): number | undefined => {
    if (!data || isNil(data.occupied) || isNil(data.total)) {
        return undefined;
    }

    return data.total - data.occupied;
};

export const calculateFreeMeetingRooms = (
    data: OccupiedMeetingRoomsResult | undefined,
): number | undefined => {
    if (!data || isNil(data.occupied) || isNil(data.total)) {
        return undefined;
    }

    return data.total - data.occupied;
};
