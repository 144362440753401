import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useFractionStyles = makeStyles((theme) => ({
    text: {
        fontWeight: theme.typography.fontWeightLight as FontWeightProperty,
    },
    total: {
        fontSize: 64,
    },
    value: {
        fontSize: 120,
        lineHeight: '151px',
    },
}));
