import { wrapResponsePromise } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { EventsWebhook, AxiosParsedError } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

import updateSingleEventsWebhookData from 'apiHooks/eventsWebhook/hooks/updateSingleEventsWebhookData';

import { controllers } from '../controllers';
import type { UpdateEventsWebhook } from '../types';

const useUpdateEventsWebhook = (
    eventsWebhookId?: number,
): UseMutationResult<EventsWebhook, AxiosParsedError, UpdateEventsWebhook> => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('integrations');

    return useMutation<EventsWebhook, AxiosParsedError, UpdateEventsWebhook>(
        (data: UpdateEventsWebhook) =>
            wrapResponsePromise(
                controllers.updateEventsWebhook(eventsWebhookId as number, data),
            ),
        {
            onSuccess: (data: EventsWebhook) => {
                updateSingleEventsWebhookData(data);
                const message = data.name
                    ? t(
                          "Your changes to '{{webhookName}}' have been successfully saved.",
                          {
                              webhookName: data.name,
                          },
                      )
                    : t('Events webhook updated');
                dispatch(toastSuccess({ message }));
            },
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error updating events webhook'),
                    }),
                );
            },
        },
    );
};

export default useUpdateEventsWebhook;
