import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useDeactivateWebhookModalStyles = makeStyles((theme) => ({
    confirmationText: {
        fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
    },
    modalContent: {
        display: 'flex',
    },
    text: {
        marginTop: theme.spacing(2),
    },
    warningIcon: {
        color: theme.palette.common.yellow5,
        fontSize: '22px',
        marginRight: theme.spacing(2),
        width: theme.spacing(3),
        height: theme.spacing(3),
        paddingTop: '1px',
    },
}));
