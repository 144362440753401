import { FormErrorMessage, Modal } from '@infogrid/components-material-ui';
import type { EditFloorRequestPayload } from '@infogrid/locations-types';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import type { FormikProps } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useEditBuildingStyles } from './styles';

interface Props
    extends Pick<
        FormikProps<EditFloorRequestPayload>,
        | 'isSubmitting'
        | 'handleSubmit'
        | 'handleChange'
        | 'handleReset'
        | 'errors'
        | 'touched'
    > {
    onClose: () => void;
    isOpened: boolean;
    floorName: string;
    handleDelete: () => void;
    isFetching: boolean;
}

const EditFolderModal = ({
    onClose,
    isOpened,
    isSubmitting,
    floorName,
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleDelete,
    handleReset,
    isFetching,
}: Props) => {
    const stylesEditBuilding = useEditBuildingStyles();

    const classes = useMemo(
        () => ({
            container: stylesEditBuilding.container,
            spinner: stylesEditBuilding.spinner,
        }),
        [stylesEditBuilding],
    );

    const { t } = useTranslation('floorplan');

    const onExited = useCallback(() => {
        handleReset();
    }, [handleReset]);

    return (
        <Modal onClose={onClose} open={isOpened} onExited={onExited} classes={classes}>
            {isFetching && (
                <CircularProgress className={classes.spinner} color="primary" size={56} />
            )}

            {!isFetching && (
                <form onSubmit={handleSubmit}>
                    <Modal.Title>{t('Editing Floor')}</Modal.Title>

                    <Modal.Description>
                        {t('Update this floor details:')}
                    </Modal.Description>

                    <Modal.Content>
                        <TextField
                            fullWidth
                            id="name"
                            name="name"
                            variant="outlined"
                            data-cypress="name"
                            label={t('Floor name')}
                            placeholder={t('Floor name')}
                            disabled={isSubmitting}
                            value={floorName}
                            onChange={handleChange}
                            error={Boolean(errors.name && touched.name)}
                        />
                        <FormErrorMessage show={Boolean(errors.name && touched.name)}>
                            {errors.name}
                        </FormErrorMessage>
                    </Modal.Content>

                    <Modal.Actions justify="space-between">
                        <Button
                            onClick={handleDelete}
                            color="secondary"
                            data-cypress="remove-floor"
                        >
                            <span>{t('delete floor')}</span>
                        </Button>

                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            data-cypress="save"
                        >
                            {isSubmitting && (
                                <CircularProgress
                                    className={stylesEditBuilding.spinner}
                                    size={22}
                                />
                            )}
                            <span> {t('Save', { ns: 'common' })}</span>
                        </Button>
                    </Modal.Actions>
                </form>
            )}
        </Modal>
    );
};

export default memo(EditFolderModal);
