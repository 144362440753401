import { getSensorConfig } from '@infogrid/sensors-configuration';
import type { SensorType, SensorEventsV2 } from '@infogrid/sensors-constants';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { useMemo } from 'react';

interface UseLastReadingTimestampProps {
    latest_events_v2?: SensorEventsV2;
    type_code: SensorType;
}

export const useLastReadingTimestamp: (
    sensor?: UseLastReadingTimestampProps,
) => string | number | Date | null | undefined = (
    sensor?: UseLastReadingTimestampProps,
) => {
    return useMemo(() => {
        // some devices require specific fields as their last reading
        switch (sensor?.type_code) {
            case SENSOR_TYPE.TYPE_MONNIT_HUB:
                const monnitHubTimestamp =
                    sensor?.latest_events_v2?.connection_signal?.timestamp;
                const monnitHubDatetime = !!monnitHubTimestamp
                    ? new Date(monnitHubTimestamp)
                    : null;

                return !!monnitHubDatetime ? monnitHubDatetime.toUTCString() : null;
            case SENSOR_TYPE.TYPE_CCON:
                return sensor?.latest_events_v2?.connection_type?.timestamp
                    ? sensor?.latest_events_v2?.connection_type?.timestamp
                    : null;
            default: {
                // default for every other sensor
                if (!sensor?.latest_events_v2) {
                    return null;
                }

                const { lastReadingEventType } = getSensorConfig(sensor.type_code);
                const latestEvent =
                    lastReadingEventType && sensor.latest_events_v2[lastReadingEventType];

                return latestEvent ? latestEvent.timestamp : null;
            }
        }
    }, [sensor]);
};
