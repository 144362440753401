import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo } from 'react';

import { draggableHandleClassName } from 'views/dashboards/components/WidgetsGrid/constants';

import { useWidgetBarStyles } from './styles';

interface WidgetBarProps {
    canDragAndResize: boolean;
    children: ReactNode;
    className?: string;
}

const WidgetBar = ({ canDragAndResize, children, className }: WidgetBarProps) => {
    const styles = useWidgetBarStyles();

    const baseClasses = classNames(
        styles.container,
        { [styles.barDraggable]: canDragAndResize },
        { [draggableHandleClassName]: canDragAndResize },
    );

    return (
        <div className={classNames(baseClasses, className)} data-cypress="widget-bar">
            {children}
        </div>
    );
};

export default memo(WidgetBar);
