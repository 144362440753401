import { makeStyles } from '@material-ui/core';

export const useLocationsStyles = makeStyles((theme) => ({
    loadingSpinner: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    },
    description: { lineHeight: `${theme.spacing(5)}px` },
}));
