import { Modal } from '@infogrid/components-material-ui';
import { Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeletingBuildingStyles } from './styles';

const DeleteBuildingModal = ({
    handleSubmit,
    onClose,
    isOpened,
    buildingName,
    handleCancel,
    isSubmitting,
    isFetching,
}) => {
    const styles = useDeletingBuildingStyles();

    const classes = useMemo(
        () => ({
            container: styles.container,
        }),
        [styles.container],
    );

    const { t } = useTranslation('floorplan');

    return (
        <Modal onClose={onClose} open={isOpened} classes={classes}>
            <form onSubmit={handleSubmit}>
                <Modal.Title>{t('Delete Building?')}</Modal.Title>
                <Modal.Description>
                    <span className={styles.description}>
                        {t(`You are about to delete ${buildingName} building.`)}
                    </span>
                    <span>
                        {t(
                            'This action cannot be undone. Are you sure you want to contiue?',
                        )}
                    </span>
                </Modal.Description>

                <Modal.Actions justify="space-between">
                    <Button
                        classes={{
                            root: styles.cancelButton,
                        }}
                        color="primary"
                        onClick={handleCancel}
                        data-cypress="cancel"
                    >
                        {t('Cancel', { ns: 'common' })}
                    </Button>

                    <Button
                        color="secondary"
                        disabled={isFetching || isSubmitting}
                        variant="contained"
                        type="submit"
                        data-cypress="delete-btn"
                    >
                        {isSubmitting && (
                            <CircularProgress className={styles.spinner} size={22} />
                        )}

                        <span>{t('Delete')}</span>
                    </Button>
                </Modal.Actions>
            </form>
        </Modal>
    );
};

DeleteBuildingModal.propTypes = {
    buildingName: PropTypes.string,
    handleCancel: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
};

DeleteBuildingModal.defaultProps = {
    buildingName: null,
};

export default memo(DeleteBuildingModal);
