import type { QueryKey } from 'react-query';

import {
    LABELS_QUERY_KEY,
    UNATTACHED_LABELS_QUERY_KEY,
    SENSORS_LABELS_KEY,
} from 'apiHooks/base/queryKeys';

export const getLabelsKey = (): QueryKey => [LABELS_QUERY_KEY];
export const getFullLabelsKey = (fromToQuery: { [key: string]: unknown }): QueryKey => [
    LABELS_QUERY_KEY,
    fromToQuery,
];
export const getUnattachedLabelsKey = (device_name: string): QueryKey => [
    UNATTACHED_LABELS_QUERY_KEY,
    device_name,
];

export const getSensorsLabelsKey = (params: { [key: string]: unknown }): QueryKey => [
    SENSORS_LABELS_KEY,
    params,
];
