import type { LocationFilter } from '@infogrid/core-types';
import { LOCATION_FILTERS } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';

export const useLocationsLabel = (type: LocationFilter, count: number): string => {
    const { t } = useTranslation('common');

    switch (type) {
        case LOCATION_FILTERS.SPACE:
            return t('{{count}} spaces', {
                count,
                defaultValue___one: `{{count}} space`,
                defaultValue___other: `{{count}} spaces`,
            });

        case LOCATION_FILTERS.FLOOR:
            return t('{{count}} floors', {
                count,
                defaultValue___one: `{{count}} floor`,
                defaultValue___other: `{{count}} floors`,
            });

        default:
            return t('{{count}} buildings', {
                count,
                defaultValue___one: `{{count}} building`,
                defaultValue___other: `{{count}} buildings`,
            });
    }
};
