import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const FolderTreeFolderEmptyState = ({ depth }) => {
    const { t } = useTranslation();

    return (
        <div className={Classes.TREE_NODE}>
            <div
                className={classNames(
                    Classes.TEXT_MUTED,
                    `${Classes.TREE_NODE_CONTENT}-${depth}`,
                    'tw-h-auto',
                    'tw-flex',
                )}
            >
                <div className={Classes.TREE_NODE_CARET_NONE} />
                <div className={Classes.TREE_NODE_LABEL}>
                    <div>{t('This folder is empty.')}</div>
                </div>
            </div>
        </div>
    );
};

FolderTreeFolderEmptyState.propTypes = {
    depth: PropTypes.number.isRequired,
};
