import { useIsDesktop } from '@infogrid/utils-hooks';
import { memo } from 'react';
import { useParams } from 'react-router-dom';

import { useBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import { useFloors } from 'apiHooks/floorPlan/floors/hooks';
import type { EstateFloorParams } from 'views/estate/pages/types';

import DesktopBuildingSidebar from './DesktopBuildingSidebar';
import MobileBuildingSidebar from './MobileBuildingSidebar';

interface Props {
    setSelectedSpaceId?: (id: number | null) => void;
}

const BuildingSidebarContainer = ({ setSelectedSpaceId }: Props): JSX.Element | null => {
    const isDesktop = useIsDesktop();

    const { buildingId, floorId } = useParams<EstateFloorParams>();

    const { building } = useBuilding(+buildingId);
    const { floors } = useFloors(+buildingId, { 'include-user-actions': true });

    if (!building) {
        return null;
    }

    return (
        <>
            {isDesktop && (
                <DesktopBuildingSidebar
                    building={building}
                    floorId={+floorId}
                    floors={floors}
                    setSelectedSpaceId={setSelectedSpaceId}
                />
            )}
            {!isDesktop && (
                <MobileBuildingSidebar
                    building={building}
                    floorId={+floorId}
                    floors={floors}
                    setSelectedSpaceId={setSelectedSpaceId}
                />
            )}
        </>
    );
};

export default memo(BuildingSidebarContainer);
