import { download } from '@infogrid/utils-file';
import type { Map } from 'ol';
import { useCallback, useContext } from 'react';

import type { MapContext } from 'components/floorPlans/Map';
import { Context } from 'components/floorPlans/Map';

const cloneMapCanvas = (map: Map) => {
    const mapCanvas = document.createElement('canvas');
    const mapSize = map.getSize();

    if (!mapSize) {
        throw new Error('Map have to have width and height');
    }

    const [width, height] = mapSize;

    mapCanvas.width = width;
    mapCanvas.height = height;

    const mapContext = mapCanvas.getContext('2d');

    if (!mapContext) {
        throw new Error(`2d context not supported or canvas already initialized`);
    }

    map.getTargetElement()
        .querySelectorAll('canvas')
        .forEach((canvas) => {
            if (canvas.width > 0) {
                // @ts-expect-error: parentNode should have style property
                const { opacity } = canvas.parentNode.style;

                mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);

                const { transform } = canvas.style;

                // Get the transform parameters from the style's transform matrix
                const coordinates = transform.match(/^matrix\(([^(]*)\)$/);

                if (coordinates && coordinates[1]) {
                    const matrix = coordinates[1].split(',').map(Number);

                    // Apply the transform to the export map context
                    CanvasRenderingContext2D.prototype.setTransform.apply(
                        mapContext,
                        // @ts-expect-error: ts can't manage overload of setTransform
                        matrix,
                    );
                }

                mapContext.drawImage(canvas, 0, 0);
            }
        });

    return mapCanvas;
};

interface UseFloorPlanControlsMethodsParams {
    centerCoordinate: number[];
}

export const useFloorPlanControlsMethods = ({
    centerCoordinate,
}: UseFloorPlanControlsMethodsParams) => {
    const { map } = useContext<MapContext>(Context);

    const zoomIn = useCallback(() => {
        const mapView = (map as Map).getView();
        const currZoom = mapView.getZoom();

        if (typeof currZoom === 'number') {
            mapView.setZoom(currZoom + 0.3);
        }
    }, [map]);

    const zoomOut = useCallback(() => {
        const mapView = (map as Map).getView();
        const currZoom = mapView.getZoom();

        if (typeof currZoom === 'number') {
            mapView.setZoom(currZoom - 0.3);
        }
    }, [map]);

    const moveToCenter = useCallback(() => {
        (map as Map).getView().setCenter(centerCoordinate);
    }, [map, centerCoordinate]);

    const downloadMap = useCallback(() => {
        const castMap = map as Map;

        castMap.once('rendercomplete', () => {
            const mapCanvas = cloneMapCanvas(castMap);

            download(mapCanvas.toDataURL(), 'floorPlan.png');
        });

        castMap.renderSync();
    }, [map]);

    return {
        map,
        zoomIn,
        zoomOut,
        moveToCenter,
        downloadMap,
    };
};
