import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';

import { useNavbarLogoStyles } from './styles';

const textTransitionResolver = (transitionValue = 0) => `opacity ${transitionValue}ms`;

export interface NavbarLogoProps {
    classes?: {
        container: string;
        image: string;
        text: string;
    };
    logo: string;
    logoText: string;
    titleVisible?: boolean;
    transitionDuration?: number;
    onClick?: () => void;
}

const NavbarLogo = ({
    logo,
    logoText,
    onClick,
    transitionDuration,
    classes = {
        container: '',
        image: '',
        text: '',
    },
    titleVisible = true,
}: NavbarLogoProps) => {
    const textTransition = textTransitionResolver(transitionDuration);

    const navbarLogoClasses = useNavbarLogoStyles({ textTransition });

    const logoContainerClasses = classNames(navbarLogoClasses.root, classes.container, {
        [navbarLogoClasses.textVisible]: titleVisible,
    });
    const logoImageClasses = classNames(navbarLogoClasses.logoImage, classes.image);
    const logoTextClasses = classNames(navbarLogoClasses.logoText, classes.text);

    return (
        <div onClick={onClick} onKeyDown={onClick} role="link" tabIndex={0}>
            <figure className={logoContainerClasses}>
                <img className={logoImageClasses} src={logo} alt="Infogrid Logo" />

                <Typography className={logoTextClasses} component="h6" variant="h6">
                    {logoText}
                </Typography>
            </figure>
        </div>
    );
};

export default memo(NavbarLogo);
