import { getLocation } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import qs from 'qs';
import { select } from 'redux-saga/effects';

import { SENSOR_PARAM_NAMES } from '../../sensor/consts';
import { cleanParams, parseParams } from './utils';

export {
    SENSOR_BASE_FILTER_NAMES,
    SENSOR_SORT_NAMES,
    SENSOR_PARAM_NAMES,
    SENSOR_LIST_ACTION_FILTERS,
} from '../../sensor/consts';

/**
 * Map Sensor filter state from location to props.
 *
 * @param state
 * @param initialQuery
 */
export const selectQuery = (state, initialQuery = {}) => {
    const location = getLocation(state);

    if (location?.search) {
        const params = qs.parse(location.search, {
            ignoreQueryPrefix: true,
            comma: true,
        });

        const resultParams = cleanParams(parseParams(params));

        if (resultParams[SENSOR_PARAM_NAMES.LOCATION]) {
            const value = resultParams[SENSOR_PARAM_NAMES.LOCATION];

            resultParams[SENSOR_PARAM_NAMES.LOCATION] = Array.isArray(value)
                ? value
                : [value];
        }

        // if not only sorting is applied, pass recursive param
        // to fire search in subfolders as well
        if (!isEmpty(omit(resultParams, SENSOR_PARAM_NAMES.ORDER_BY))) {
            resultParams[SENSOR_PARAM_NAMES.RECURSIVE] = true;
        }

        if (isEmpty(resultParams)) {
            return initialQuery || {};
        }

        return { ...(initialQuery || {}), ...resultParams };
    }

    return initialQuery || {};
};

/**
 * Read location from store and parse search for backend.
 * @return {IterableIterator<*>}
 */
export function* buildQueryFromLocation(initialQuery = {}) {
    // Get currently applied filters from URL
    return yield select(selectQuery, initialQuery);
}
