import { toastSetStatus, toastSuccess } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { i18n } from '@infogrid/utils-i18n';
import { entitiesActions } from '@thorgate/spa-entities';
import { createDeleteAction, createFormSaveSaga } from '@thorgate/spa-forms';
import { takeLatestWithMatch } from '@thorgate/spa-view-manager';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import { teamsSchema } from 'schemas/teams';
import api from 'services/api';
import { handleDelayingFormSave } from 'utils/handleDelayingFormSave';

export const deleteTeam = createDeleteAction('sagas/account/DELETE_TEAM', {
    setStatus: toastSetStatus,
});

function* successHook(_0, _1, { payload: { data } }) {
    yield put(toastSuccess({ message: `${i18n.t('Team deleted')} - ${data.name}` }));
    yield put(entitiesActions.purgeEntities({ key: teamsSchema.key, ids: [data.id] }));
    yield put(push(routesManager.resolvePath('organization:teams')));
}

const handleFormSave = createFormSaveSaga({
    resource: api.organization.team.detail,
    method: 'del',
    successHook,

    mutateKwargs: (_0, { payload: { data } }) => ({ pk: data.id }),
});

export default function* deleteTeamWatcher() {
    yield takeLatestWithMatch(
        deleteTeam.getType(),
        routesManager.resolvePattern('organization:teams'),
        handleDelayingFormSave(handleFormSave, i18n.t('Deleting of team is in progress')),
    );
}
