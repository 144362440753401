import { selectSpaceSensors } from '@infogrid/locations-ducks';
import type { FloorDetail } from '@infogrid/locations-types';
import type { LegacyFloorSensor } from '@infogrid/sensors-constants';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { Button, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer, List, InfiniteLoader } from 'react-virtualized';

import { invalidateFloorSensors } from 'apiHooks/floorPlan/sensors/accessors';
import { useSpaceSensors } from 'apiHooks/floorPlan/spaces/hooks';
import { useMoveFolder } from 'apiHooks/folders/hooks';
import MoveSensorsModal from 'views/estate/components/modals/MoveSensorsModal';

import { populateMoveSensorsData } from '../../utils';
import SensorItem from './SensorItem';
import { useSpaceSidebarSensorsStyles } from './styles';

const DEFAULT_OVERSCAN_ROW_COUNT = 20;
const SENSORS_LIST_ROW_HEIGHT = 44;
const LIST_MIN_HEIGHT = 300;
const LIST_MIN_WIDTH = 292;

interface Props {
    buildingId: number;
    floor: FloorDetail;
    spaceId: number;
}

const SpaceSidebarSensors = ({ buildingId, floor, spaceId }: Props) => {
    const styles = useSpaceSidebarSensorsStyles();

    const { t } = useTranslation();

    const [selectedSensors, setSelectedSensors] = useState<LegacyFloorSensor[]>([]);

    const [isMoveSensorsModalOpen, _1, _2, toggleMoveSensorsModal] = useIsOpenState();

    const { mutate: moveSensors } = useMoveFolder();

    const {
        data: spaceSensorsData,
        fetchNextPage,
        refetch: refetchSpaceSensors,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useSpaceSensors(spaceId);

    const fetchMoreSensors = useCallback(() => {
        if (isFetchingNextPage || !hasNextPage) {
            return;
        }

        fetchNextPage();
    }, [fetchNextPage, isFetchingNextPage, hasNextPage]);

    const spaceSensors = useMemo(() => {
        return selectSpaceSensors(spaceSensorsData);
    }, [spaceSensorsData]);

    const isRowLoaded = useCallback(
        ({ index }: { index: number }) => {
            return index < spaceSensors.sensors.length;
        },
        [spaceSensors.sensors],
    );

    const noRowsRenderer = () => {
        return <></>;
    };

    const onSelectSensor = useCallback(
        (sensor: LegacyFloorSensor) => {
            const selectedSensorsIds = selectedSensors.map((s) => s.id);

            if (selectedSensorsIds.includes(sensor.id)) {
                setSelectedSensors(selectedSensors.filter((s) => s.id !== sensor.id));
            } else {
                setSelectedSensors([...selectedSensors, sensor]);
            }
        },
        [selectedSensors],
    );

    const rowRenderer = useCallback(
        ({ index, key, style }) => {
            return !isRowLoaded({ index }) ? (
                <Skeleton key={key} style={style} variant="text" />
            ) : (
                <div key={key} style={style} data-cypress="sensors-list-item">
                    <SensorItem
                        data-cypress={`space-${spaceSensors.sensors[index].id}`}
                        isSelected={selectedSensors.includes(spaceSensors.sensors[index])}
                        key={spaceSensors.sensors[index].id}
                        onSelectSensor={onSelectSensor}
                        sensor={spaceSensors.sensors[index]}
                    />
                </div>
            );
        },
        [isRowLoaded, onSelectSensor, selectedSensors, spaceSensors.sensors],
    );

    const onMoveSensors = useCallback(
        (
            selectedBuildingId: number,
            selectedFloorId: number,
            selectedSpaceId: number | null,
        ) => {
            moveSensors(
                {
                    folderId: selectedSpaceId || selectedFloorId,
                    data: {
                        move_sensors: true,
                        sensors: selectedSensors.map((s) => s.device_name),
                    },
                },
                {
                    onSuccess() {
                        populateMoveSensorsData({
                            buildingId,
                            floorId: floor.id,
                            selectedBuildingId,
                            selectedFloorId,
                            selectedSpaceId,
                        });

                        refetchSpaceSensors();

                        toggleMoveSensorsModal();

                        setSelectedSensors([]);

                        invalidateFloorSensors({ floorId: floor.id });
                    },
                },
            );
        },
        [
            buildingId,
            floor,
            moveSensors,
            refetchSpaceSensors,
            selectedSensors,
            toggleMoveSensorsModal,
        ],
    );

    return (
        <>
            <div className={styles.wrapper}>
                {selectedSensors.length === 0 && (
                    <Typography
                        className={styles.sensorsLabel}
                        data-cypress="sensors-count"
                    >
                        {t('{{count}} sensors', {
                            count: spaceSensors.count,
                            defaultValue___one: `${spaceSensors.count} sensor`,
                            defaultValue___other: `${spaceSensors.count} sensors`,
                        })}
                    </Typography>
                )}
                {selectedSensors.length > 0 && (
                    <Tooltip
                        placement="top"
                        title={
                            !floor.user_actions?.edit?.allowed &&
                            floor.user_actions?.edit?.reason
                                ? floor.user_actions?.edit?.reason
                                : ''
                        }
                    >
                        <div>
                            <Button
                                color="primary"
                                data-cypress="move-sensors-view-open"
                                disabled={!floor.user_actions.edit?.allowed}
                                onClick={toggleMoveSensorsModal}
                                size="small"
                                variant="outlined"
                            >
                                {t('Move')}
                            </Button>
                        </div>
                    </Tooltip>
                )}
            </div>

            <div className={styles.autoSizerContainer} data-cypress="space-sensors-list">
                {isFetching && !isFetchingNextPage ? (
                    <div className={styles.progress}>
                        <CircularProgress color="primary" size={40} />
                    </div>
                ) : (
                    <AutoSizer>
                        {({ height, width }) => (
                            <InfiniteLoader
                                isRowLoaded={isRowLoaded}
                                // @ts-expect-error loadMoreRows type signature differs our approach
                                loadMoreRows={fetchMoreSensors}
                                rowCount={spaceSensors.count}
                            >
                                {({ onRowsRendered, registerChild }) => (
                                    <List
                                        ref={registerChild}
                                        className={classNames(
                                            styles.scrollbar,
                                            styles.sensorsList,
                                        )}
                                        height={height ? height : LIST_MIN_HEIGHT}
                                        noRowsRenderer={noRowsRenderer}
                                        onRowsRendered={onRowsRendered}
                                        overscanRowCount={DEFAULT_OVERSCAN_ROW_COUNT}
                                        rowCount={spaceSensors.count}
                                        rowHeight={SENSORS_LIST_ROW_HEIGHT}
                                        rowRenderer={rowRenderer}
                                        width={width ? width : LIST_MIN_WIDTH}
                                    />
                                )}
                            </InfiniteLoader>
                        )}
                    </AutoSizer>
                )}
            </div>
            {isMoveSensorsModalOpen && (
                <MoveSensorsModal
                    buildingId={buildingId}
                    floorId={floor.id}
                    onClose={toggleMoveSensorsModal}
                    onMoveSensors={onMoveSensors}
                    open={isMoveSensorsModalOpen}
                    originSpaceId={spaceId}
                    sensors={selectedSensors}
                />
            )}
        </>
    );
};

export default memo(SpaceSidebarSensors);
