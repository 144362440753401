import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import { invalidateSensor } from 'apiHooks/sensors/accessors';
import type { TemperatureOffsetParams } from 'utils/types/ts/pipeMonitoringSensors';

import { controllers } from '../controllers';

interface CalibratePipeOffsetRequestParams {
    deviceName: string;
    data: TemperatureOffsetParams;
}

interface CalibratePipeOffsetHookParams {
    successMessage?: string;
}

export const useCalibratePipeOffset = ({
    successMessage,
}: CalibratePipeOffsetHookParams = {}): UseMutationResult<
    { success: boolean },
    AxiosParsedError,
    CalibratePipeOffsetRequestParams
> => {
    const { t } = useTranslation('sensors');
    const dispatch = useAppDispatch();

    return useMutationWithToast<
        { success: boolean },
        AxiosParsedError,
        CalibratePipeOffsetRequestParams
    >(
        ({ deviceName, data }) =>
            wrapResponsePromise(
                controllers.calibratePipeOffset({ data, id: deviceName }),
            ),
        {
            onSuccess: (_0, params) => {
                invalidateSensor(params?.deviceName);

                if (successMessage) {
                    dispatch(toastSuccess({ message: successMessage }));
                }
            },
            onError: (error) => {
                let errorMessage = t('Error saving sensor configuration');

                if (error.response?.data?.error) {
                    errorMessage = error.response?.data?.error;
                }

                dispatch(toastError({ message: errorMessage }));
            },
        },
        { toastMessage: t('Updating sensor configuration is in progress') },
    );
};
