import { LoadingPlaceholder, Header } from '@infogrid/components-material-ui';
import { useAppDispatch } from '@infogrid/core-ducks';
import { selectBuildings, useSpaceTypes } from '@infogrid/locations-api';
import { selectSpaceTypes } from '@infogrid/locations-ducks';
import type { FloorSensorLocation } from '@infogrid/locations-types';
import { getSensorTypeGroup } from '@infogrid/sensors-configuration';
import type { MappableSensor } from '@infogrid/sensors-constants';
import type { SensorType } from '@infogrid/sensors-types';
import { useIsMobile, useIsTablet } from '@infogrid/utils-hooks';
import classNames from 'classnames';
import { useCallback, useState, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useFloorPlanParams } from 'apiHooks/base/hooks';
import { usePaginatedBuildings } from 'apiHooks/floorPlan/buildings/hooks';
import { useFloor, useFloors, useSelectFloor } from 'apiHooks/floorPlan/floors/hooks';
import { useFloorSensors, usePlannedSensors } from 'apiHooks/floorPlan/sensors/hooks';
import { MOBILE_NAVBAR_HEADER_HEIGHT } from 'components/Navbar/NavbarMobile/NavbarHeaderMobile/constants';
import PageContainer from 'components/PageContainer';
import Sidebar from 'components/Sidebar';
import { SIDEBAR_COLLAPSE_BUTTON_DIRECTION } from 'components/Sidebar/SidebarCollapseButton';
import { SIDEBAR_COLLAPSE_BUTTON_POSITION } from 'components/Sidebar/constants';
import { useActiveSensors } from 'components/floorPlans/hooks';
import withView from 'decorators/withView';
import { openInstallFlow } from 'ducks/installFlow';
import { makeSensorsMappable } from 'utils/sensor';
import { useIsSensorPlanningEnabled } from 'utils/sensorPlanning';
// TODO circular
import FloorNavigation from 'views/buildings/components/domain/Floor/FloorNavigation';
import type { FloorPlanSensor } from 'views/buildings/components/domain/Floor/FloorPlan';
import FloorPlanContainer from 'views/buildings/components/domain/Floor/FloorPlan';
import MobileSensorNavigation from 'views/buildings/components/domain/Sensor/MobileSensorNavigation';
import SensorNavigation from 'views/buildings/components/domain/Sensor/SensorNavigation';
import HeaderContent from 'views/buildings/components/shared/HeaderContent';

import { useStylesNavbarCollapseButton, useStylesFloorPage } from './styles';

const SENSORS_REFETCH_INTERVAL = 1000 * 60;

export const useActiveSensorTypes = (floorId: number | null) => {
    const [activeSensorTypes, setActiveSensorTypes] = useState([]);

    const changeActiveSensorTypes = useCallback((activeSensors) => {
        setActiveSensorTypes(activeSensors);
    }, []);

    useEffect(() => {
        setActiveSensorTypes([]);
    }, [floorId]);

    return {
        setActiveSensorTypes,
        changeActiveSensorTypes,
        activeSensorTypes,
    };
};

const sensorIsMapped = <T,>(sensor: T): sensor is MappableSensor<T> =>
    !!(sensor as MappableSensor<T>).coordinates;

// Filter out any planned sensor that has a matching active sensor, which
// can happen if the planned sensor service fails to update after an install
const filterPlannedSensors =
    (activeSensors: { type_code: SensorType; coordinates?: FloorSensorLocation }[]) =>
    (plannedSensor: { type_code: SensorType; coordinates?: FloorSensorLocation }) =>
        !activeSensors.find(
            (activeSensor) =>
                activeSensor.type_code === plannedSensor.type_code &&
                activeSensor.coordinates?.x === plannedSensor.coordinates?.x &&
                activeSensor.coordinates?.y === plannedSensor.coordinates?.y,
        );

export const PaginatedBuildingsParams = { page_size: 50 };

const FloorPlan = ({ route }: { route: { pageName: string } }) => {
    const styles = useStylesNavbarCollapseButton();
    const pageStyle = useStylesFloorPage();

    const { t } = useTranslation('floorplan');
    const dispatch = useAppDispatch();

    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const { floorId, buildingId } = useFloorPlanParams();
    const [activeSensorDeviceId, setActiveSensorDeviceId] = useState(undefined);

    const { sensors: plannedSensors } = usePlannedSensors(floorId ?? undefined, {
        refetchInterval: SENSORS_REFETCH_INTERVAL,
    });
    const sensorPlanningEnabled = useIsSensorPlanningEnabled();

    const onSensorSelect = useCallback(
        (id) => {
            const selectedPlannedSensor = plannedSensors.find((s) => s.id === id);

            // Open install flow when planned sensor is selected
            if (selectedPlannedSensor && sensorPlanningEnabled) {
                dispatch(openInstallFlow(selectedPlannedSensor));
            } else {
                setActiveSensorDeviceId(id);
            }
        },
        [plannedSensors, sensorPlanningEnabled, dispatch],
    );

    useEffect(() => {
        setActiveSensorDeviceId(undefined);
    }, [floorId, setActiveSensorDeviceId]);

    // floorPlan var here is only used in logic to display sidebars, but as this is derived from the same
    // API call as floorSensorDictionary, this isn't a major optimisation problem
    const { floorPlan, floorSensorDictionary } = useSelectFloor({
        floorId: floorId ?? undefined,
    });

    const {
        isLoading: isLoadingSensors,
        data: allSensorsData,
    }: { isLoading: boolean; data?: { sensors: FloorPlanSensor[] } } = useFloorSensors(
        { floorId: floorId ?? undefined },
        { refetchInterval: SENSORS_REFETCH_INTERVAL },
    );

    /**
     * INFO: we are mapping here PlannedSensor type
     * to SensorShapeWithCoordinates type to merge
     * both sensors arrays and display all sensors
     * on the map
     */
    const adjustedPlannedSensors = useMemo<MappableSensor<FloorPlanSensor>[]>(() => {
        return plannedSensors.map((sensor) => ({
            // Will be string if saved, and will only appear here if saved
            id: sensor.id as string,
            uuid: sensor.id as string,
            device_name: sensor.name,
            is_planned: true,
            is_saved: true,
            floorplan_location: {
                building_id: sensor.location.building_id,
                floor_id: sensor.location.floor_id,
                space_id: sensor.location.space_id,
            },
            reading_types: [],
            type: sensor.device_type.display_name,
            type_code: sensor.device_type.name,
            name: sensor.name,
            profile: {
                name: sensor.name,
                uuid: sensor.id || '',
            },
            coordinates: {
                x: sensor.coordinates.x,
                y: sensor.coordinates.y,
                rotation: sensor.coordinates.rotation,
                scale: sensor.coordinates.scale,
            },
            sensor_type_group: getSensorTypeGroup(sensor.device_type.name),
        }));
    }, [plannedSensors]);

    const sensors = useMemo(
        () => makeSensorsMappable(allSensorsData?.sensors, floorSensorDictionary),
        [allSensorsData?.sensors, floorSensorDictionary],
    );

    const {
        data: buildingsData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = usePaginatedBuildings(PaginatedBuildingsParams);

    const { buildings, buildingsCount } = useMemo(
        () => selectBuildings(buildingsData),
        [buildingsData],
    );

    const fetchMoreBuildings = useCallback(() => {
        if (isFetchingNextPage || !hasNextPage) {
            return;
        }

        fetchNextPage();
    }, [fetchNextPage, isFetchingNextPage, hasNextPage]);

    const { floors, isLoading: isFloorsLoading } = useFloors(buildingId ?? undefined, {
        'include-user-actions': true,
    });
    const { data: floor, isLoading: isFloorLoading } = useFloor(floorId ?? undefined);

    const sensorTypes = useMemo(
        () => (floor?.sensor_types as SensorType[]) || [],
        [floor],
    );

    const { activeSensorTypes, changeActiveSensorTypes } = useActiveSensorTypes(floorId);

    // TODO confirm we don't need to parse
    // TODO this is broken now as we've split out the mapped sensors from sensors. Will need to iterate through both.
    const { activeSensors } = useActiveSensors({
        sensors,
        activeSensorTypes,
    });

    const activeMappedSensors = useMemo<MappableSensor<FloorPlanSensor>[]>(
        () => [
            ...activeSensors.filter(sensorIsMapped),
            // Iterates through array within array iteration, but timings are <1ms so
            // not a major concern.
            ...adjustedPlannedSensors.filter(
                filterPlannedSensors(activeSensors as MappableSensor<FloorPlanSensor>[]),
            ),
        ],
        [activeSensors, adjustedPlannedSensors],
    );

    const renderCollapseButton = useCallback(
        ({ ButtonComponent, className, ...rest }) => (
            <ButtonComponent
                className={classNames(className, styles.root)}
                iconClassName={styles.icon}
                location={SIDEBAR_COLLAPSE_BUTTON_DIRECTION.LEFT}
                position={SIDEBAR_COLLAPSE_BUTTON_POSITION.RIGHT}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
            />
        ),
        [styles],
    );

    const renderCollapseSensorsButton = useCallback(
        ({ ButtonComponent, className, ...rest }) => (
            <ButtonComponent
                className={classNames(className, styles.root)}
                iconClassName={styles.icon}
                location={SIDEBAR_COLLAPSE_BUTTON_DIRECTION.RIGHT}
                position={SIDEBAR_COLLAPSE_BUTTON_POSITION.LEFT}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
            />
        ),
        [styles],
    );

    const renderPageNameRow = useCallback(
        () =>
            buildings?.length ? (
                <HeaderContent
                    buildings={buildings}
                    totalBuildingsCount={buildingsCount}
                    fetchMoreBuildings={fetchMoreBuildings}
                    floors={floors}
                    selectedFloorId={floorId ?? undefined}
                />
            ) : null,
        [buildings, buildingsCount, fetchMoreBuildings, floorId, floors],
    );

    const loaderClasses = useMemo(
        () => ({
            container: pageStyle.floorPlanLoaderContainer,
        }),
        [pageStyle.floorPlanLoaderContainer],
    );

    const { data: spaceTypes = [], isLoading: isSpaceTypesLoading } = useSpaceTypes({
        keepPreviousData: true,
        select: selectSpaceTypes,
    });

    if (isFloorsLoading) {
        return (
            <LoadingPlaceholder
                classes={loaderClasses}
                progressSize={48}
                text={t('Loading floor')}
            />
        );
    }

    const isLoading = isSpaceTypesLoading || isFloorLoading;

    return (
        <>
            <Helmet defaultTitle={t('Floor')} />

            <Header
                pageName={route.pageName}
                renderPageNameRowContent={renderPageNameRow}
            />

            <PageContainer className={pageStyle.pageContainer}>
                {!isMobile && (
                    // @ts-expect-error Sidebar needs to be migrated to TS
                    <Sidebar
                        defaultOpened
                        sidebarName="floorplansFloorsList"
                        className={pageStyle.floorsListContainer}
                        collapsedWidth={82}
                        uncollapsedWidth={280}
                        transitionDuration={300}
                        collapseButton={renderCollapseButton}
                        isCollapseButtonAlwaysVisible
                    >
                        {({
                            opened,
                            isTransitioning,
                        }: {
                            opened: boolean;
                            isTransitioning: boolean;
                        }) => <FloorNavigation isFullView={!isTransitioning && opened} />}
                    </Sidebar>
                )}

                {isLoading && (
                    <LoadingPlaceholder
                        classes={loaderClasses}
                        progressSize={48}
                        text={t('Loading map data')}
                    />
                )}

                {!isLoading && floor && (
                    <div className={styles.floorPlanContainer}>
                        <FloorPlanContainer
                            key={floorId}
                            sensorTypes={sensorTypes}
                            activeSensorTypes={activeSensorTypes}
                            changeActiveSensorTypes={changeActiveSensorTypes}
                            activeSensorDeviceId={activeSensorDeviceId}
                            mappedSensors={activeMappedSensors}
                            allSensors={sensors}
                            spaceTypes={spaceTypes}
                            onSensorSelect={onSensorSelect}
                        />
                        {!isMobile && floorPlan && (
                            // @ts-expect-error Sidebar needs to be migrated to TS
                            <Sidebar
                                sidebarName="floorplansSensorsList"
                                className={pageStyle.floorsListContainer}
                                collapsedWidth={40}
                                uncollapsedWidth={isTablet ? 300 : 350}
                                transitionDuration={300}
                                collapseButton={renderCollapseSensorsButton}
                                defaultOpened
                                isCollapseButtonAlwaysVisible
                                collapseButtonPosition={
                                    SIDEBAR_COLLAPSE_BUTTON_POSITION.LEFT
                                }
                            >
                                {({
                                    opened,
                                    isTransitioning,
                                }: {
                                    opened: undefined;
                                    isTransitioning: undefined;
                                }) =>
                                    opened || isTransitioning ? (
                                        <SensorNavigation
                                            activeSensorDeviceId={
                                                activeSensorDeviceId ?? null
                                            }
                                            isLoading={isLoadingSensors}
                                            onSensorSelect={onSensorSelect}
                                            sensors={activeSensors}
                                        />
                                    ) : (
                                        <div className={pageStyle.sensorCollapsedText}>
                                            <span>{t('Sensors', { ns: 'common' })}</span>
                                        </div>
                                    )
                                }
                            </Sidebar>
                        )}
                        {isMobile && floorPlan && (
                            <MobileSensorNavigation
                                activeSensorDeviceId={activeSensorDeviceId ?? null}
                                isLoading={isLoadingSensors}
                                sensors={activeSensors}
                                topMargin={MOBILE_NAVBAR_HEADER_HEIGHT}
                            />
                        )}
                    </div>
                )}
            </PageContainer>
        </>
    );
};

export default withView()(FloorPlan);
