/**
 * This file is deprecated. Use apiHooks/base/queryKeys instead.
 * Hooks that use these keys are not following our react-query conventions.
 */

// PERMISSIONS
export const PERMISSIONS_QUERY_KEY = 'permissions';

// ALERT CONFIGURATIONS
export const ALERT_CONFIGURATION_DETAIL_QUERY_KEY = 'alert-configuration';
export const ALERT_CONFIGURATION_RECIPIENTS_SEARCH_QUERY_KEY =
    'alert-configuration-recipients';
