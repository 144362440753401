import { Modal, TimePicker } from '@infogrid/components-material-ui';
import type { WeekdayWorkingHoursConfigurationValues } from '@infogrid/electricity-components';
import type { Weekday } from '@infogrid/sensors-constants';
import { useIsMobile } from '@infogrid/utils-hooks';
import {
    Button,
    Typography,
    FormControl,
    Box,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Alert } from '@material-ui/lab';
import type { FormikHelpers, FormikProps, FormikConfig } from 'formik';
import { useFormik } from 'formik';
import noop from 'lodash/noop';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useTimezones } from 'apiHooks/timezones/ts/hooks';
import TimezoneAutocomplete from 'components/material-ui/TimezoneAutocomplete';
import type { TimezoneAutocompleteProps } from 'components/material-ui/TimezoneAutocomplete';

import { useWorkingHoursConfigurationStyles, useModalStyles } from './styles';

interface Props
    extends Pick<FormikProps<WeekdayWorkingHoursConfigurationValues>, 'initialValues'> {
    onClose?: () => void;
    isOpened: boolean;
    validationSchema: FormikConfig<WeekdayWorkingHoursConfigurationValues>['validationSchema'];
    onSubmit: (
        values: WeekdayWorkingHoursConfigurationValues,
        formik: FormikHelpers<WeekdayWorkingHoursConfigurationValues>,
    ) => void;
}

const WorkingHoursConfigurationModal = ({
    onSubmit,
    initialValues,
    onClose = noop,
    isOpened,
    validationSchema,
}: Props): JSX.Element => {
    const { t } = useTranslation('sensors');
    const styles = useWorkingHoursConfigurationStyles();
    const modalClasses = useModalStyles();
    const { data: timezones = [] } = useTimezones();

    const isMobile = useIsMobile();

    const {
        values,
        errors,
        dirty,
        handleChange,
        submitForm,
        isSubmitting,
        isValid,
        handleBlur,
        setFieldTouched,
        setFieldValue,
        handleSubmit,
        resetForm,
    } = useFormik<WeekdayWorkingHoursConfigurationValues>({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit,
    });

    const handleClose = useCallback(() => {
        onClose();
        resetForm();
    }, [onClose, resetForm]);

    const handleTimezoneAutocompleteChange: TimezoneAutocompleteProps['onChange'] =
        useCallback(
            (_, timezoneOption) => {
                setFieldTouched('time_zone', true, false);
                setFieldValue('time_zone', timezoneOption?.value ?? null);
            },
            [setFieldTouched, setFieldValue],
        );

    return (
        <Modal
            open={isOpened}
            onClose={handleClose}
            fullWidth
            classes={modalClasses}
            fullScreen={isMobile}
            data-testid="working-hours-configuration-modal"
        >
            <Modal.Title>
                <Box display="flex" alignItems="center">
                    <AccessTimeIcon className={styles.timeIcon} /> {t('Working hours')}
                </Box>
            </Modal.Title>
            <form onSubmit={handleSubmit} className={styles.form}>
                <Modal.Content>
                    {(
                        [
                            'monday',
                            'tuesday',
                            'wednesday',
                            'thursday',
                            'friday',
                            'saturday',
                            'sunday',
                        ] as Weekday[]
                    ).map((weekDay) => (
                        <Box
                            key={weekDay}
                            justifyContent="space-between"
                            display="flex"
                            mb={1}
                        >
                            <FormControlLabel
                                name={`working_hours.${weekDay}.is_working_day`}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={
                                            values?.working_hours?.[weekDay]
                                                .is_working_day
                                        }
                                        onChange={handleChange}
                                        data-cypress={`working-hours-${weekDay}-checkbox`}
                                        data-testid={`working-hours-${weekDay}-checkbox`}
                                    />
                                }
                                label={
                                    <Typography className={styles.weekDay}>
                                        {t(weekDay)}
                                    </Typography>
                                }
                            />

                            <FormControl key={weekDay}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    alignSelf="flex-end"
                                >
                                    <TimePicker
                                        disabled={
                                            !values?.working_hours?.[weekDay]
                                                .is_working_day
                                        }
                                        value={
                                            values?.working_hours?.[weekDay].start_time ??
                                            ''
                                        }
                                        name={`working_hours.${weekDay}.start_time`}
                                        size="small"
                                        onChange={handleChange}
                                        error={
                                            !!(
                                                errors.working_hours as WeekdayWorkingHoursConfigurationValues['working_hours']
                                            )?.[weekDay]?.start_time
                                        }
                                    />

                                    <Typography variant="body2" color="textPrimary">
                                        &nbsp;–&nbsp;
                                    </Typography>
                                    <TimePicker
                                        disabled={
                                            !values?.working_hours?.[weekDay]
                                                .is_working_day
                                        }
                                        value={
                                            values?.working_hours?.[weekDay].end_time ??
                                            ''
                                        }
                                        name={`working_hours.${weekDay}.end_time`}
                                        error={
                                            !!(
                                                errors.working_hours as WeekdayWorkingHoursConfigurationValues['working_hours']
                                            )?.[weekDay]?.end_time
                                        }
                                        size="small"
                                        onChange={handleChange}
                                    />
                                </Box>
                            </FormControl>
                        </Box>
                    ))}
                    <TimezoneAutocomplete
                        data-cypress="working-hours-timezone"
                        timezone={values?.time_zone ?? ''}
                        inputName="time_zone"
                        inputId="time_zone"
                        timezones={timezones}
                        onChange={handleTimezoneAutocompleteChange}
                        onBlur={handleBlur}
                        loading={timezones.length === 0}
                        disablePortal={isMobile}
                        inputError={!!errors?.time_zone}
                    />
                    <Alert variant="standard" severity="info" className={styles.alert}>
                        <Typography variant="subtitle1">
                            {t(
                                'Working hours are the times you expect your building to be occupied and functioning. All times outside of this range will be considered out of hours. Adjusting these to be correct for your building will help keep our ‘out of hours’ calculations correct.',
                            )}
                        </Typography>
                    </Alert>
                </Modal.Content>
                <Modal.Actions justify="space-between">
                    <Button
                        variant="text"
                        color="primary"
                        onClick={handleClose}
                        data-cypress="working-hours-cancel-button"
                        data-testid="working-hours-cancel-button"
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting || !dirty || !isValid}
                        onClick={submitForm}
                        data-cypress="working-hours-apply-button"
                        data-testid="working-hours-apply-button"
                    >
                        {t('Apply')}
                    </Button>
                </Modal.Actions>
            </form>
        </Modal>
    );
};

export default memo(WorkingHoursConfigurationModal);
