import { Button, Icon, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { DIALOG_NAMES } from 'utils/dialogs';
import { useConnectedIsOpenState } from 'utils/hooks';
import AddBuildingModal from 'views/estate/components/modals/AddBuildingModal';

import { useEstateViewHeaderStyles } from './styles';

interface Props {
    buildingsCount: number;
    canAddBuilding?: { allowed: boolean; reason: string | null };
}

const EstateViewHeader = ({ buildingsCount, canAddBuilding }: Props) => {
    const styles = useEstateViewHeaderStyles();

    const { t } = useTranslation();

    const [addBuildingModalOpen, _1, _2, toggleBuildingModal] = useConnectedIsOpenState(
        DIALOG_NAMES.ADD_BUILDING,
    );

    return (
        <>
            <div className={styles.container}>
                <Typography variant="h6" data-cypress="buildings-count">
                    {t('{{count}} Buildings', {
                        count: buildingsCount,
                        defaultValue___one: `${buildingsCount} Building`,
                        defaultValue___other: `${buildingsCount} Buildings`,
                    })}
                </Typography>
                {
                    // If there are no buildings the AddFirstBuilding screen will be displayed
                    // so this button is unecessary.
                    buildingsCount ? (
                        <Tooltip placement="top" title={canAddBuilding?.reason || ''}>
                            <div>
                                <Button
                                    color="primary"
                                    data-cypress="add-building"
                                    disabled={!canAddBuilding?.allowed}
                                    onClick={toggleBuildingModal}
                                    size="small"
                                    variant="contained"
                                >
                                    <Icon
                                        className={classNames(
                                            'far fa-plus',
                                            styles.addBuildingIcon,
                                        )}
                                    />
                                    {t('Add building')}
                                </Button>
                            </div>
                        </Tooltip>
                    ) : null
                }
            </div>
            {addBuildingModalOpen && (
                <AddBuildingModal
                    onClose={toggleBuildingModal}
                    open={addBuildingModalOpen}
                />
            )}
        </>
    );
};

export default memo(EstateViewHeader);
