import { useCallback, useState, useEffect } from 'react';
import { InfiniteLoader, CellMeasurerCache } from 'react-virtualized';

import type { TableInfiniteLoaderProps, TableProps } from '../constants';
import { defaultCellMeasurerCache } from '../constants';
import TableContent from './TableContent';

const DEFAULT_FETCH_MORE_FUNC = () => Promise.resolve([]);

const TableInfiniteLoader = <Value,>({
    onScroll,
    scrollLeft,
    registerChild,
    columnWidthMeasurer,
    emptyStateRenderer,
    onFetchMore = DEFAULT_FETCH_MORE_FUNC,
    autosizerProps,
    columns,
    data,
    cellPadding,
    leftPadding,
    onScrollbarPresenceChange,
    isVerticalScrollPresent,
    width,
    selectedItemIdx,
    onCellClick,
    onEnterClick,
    headerMinHeight,
    getGridRef,
    variant,
    gridInstance,
}: TableInfiniteLoaderProps<Value> &
    Omit<TableProps<Value>, 'EmptyStateRenderer' | 'isLoading'>): JSX.Element => {
    const [cellMeasurerCache] = useState(
        new CellMeasurerCache({ ...defaultCellMeasurerCache }),
    );
    const isRowLoaded = ({ index }: { index: number }) =>
        !!data[Math.floor(index / columns.length)];
    const getRowHeight = useCallback(
        (index) => cellMeasurerCache.rowHeight(index),
        [cellMeasurerCache],
    );

    useEffect(() => {
        if (cellMeasurerCache && width) {
            cellMeasurerCache.clearAll();
        }
    }, [data, width, cellMeasurerCache]);

    return (
        <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={onFetchMore}
            rowCount={999999}
        >
            {(infiniteLoaderProps) => (
                <TableContent<Value>
                    autosizerProps={autosizerProps}
                    columnWidthMeasurer={columnWidthMeasurer}
                    rowHeight={getRowHeight}
                    scrollLeft={scrollLeft}
                    onScroll={onScroll}
                    columns={columns}
                    columnCount={columns.length}
                    data={data}
                    emptyStateRenderer={emptyStateRenderer}
                    headerRef={registerChild}
                    onRowsRendered={infiniteLoaderProps.onRowsRendered}
                    gridRef={getGridRef(registerChild, infiniteLoaderProps.registerChild)}
                    cellMeasurerCache={cellMeasurerCache}
                    cellPadding={cellPadding as [number, number]}
                    leftPadding={leftPadding}
                    onScrollbarPresenceChange={onScrollbarPresenceChange}
                    isVerticalScrollPresent={isVerticalScrollPresent}
                    selectedItemIdx={selectedItemIdx}
                    onCellClick={onCellClick}
                    onEnterClick={onEnterClick}
                    headerMinHeight={headerMinHeight ?? 0}
                    variant={variant}
                    gridInstance={gridInstance}
                />
            )}
        </InfiniteLoader>
    );
};

export default TableInfiniteLoader;
