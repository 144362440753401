import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo } from 'react';

import { useWidgetFooterStyles } from './styles';

interface WidgetFooterProps {
    children: ReactNode;
    className?: string;
    justifyContent?: string;
}

const WidgetFooter = ({
    children,
    className,
    justifyContent = 'flex-end',
}: WidgetFooterProps) => {
    const styles = useWidgetFooterStyles();

    return (
        <div className={classNames(styles.root, className)} style={{ justifyContent }}>
            {children}
        </div>
    );
};

export default memo(WidgetFooter);
