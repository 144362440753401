import { makeStyles } from '@material-ui/core';

export const useWidgetActionsStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        '& > div, & > section': {
            marginRight: theme.spacing(2),
        },
        justifyContent: 'flex-end',
    },
}));
