import { Modal } from '@infogrid/components-material-ui';
import { useAppDispatch } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { getSensor } from '@infogrid/sensors-configuration';
import { Button, Icon, Typography, Link } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import classnames from 'classnames';
import { push } from 'connected-react-router';
import { useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { SENSOR_INSTALLATION_GUIDE_URL } from 'constants/urls';
import { useIsTempOffsetMissed, useIsTempOffsetModeAutomatic } from 'utils/hooks/sensors';

import { useInstallationFlowContext } from '../context';
import { useInstallationFlowStyles } from '../styles';
import type { InstallationFlowComponentProps } from '../types';
import { useInstallationSuccessView } from './styles';

type InstallationSuccessViewProps = Pick<
    InstallationFlowComponentProps,
    'onComplete' | 'resetProgress'
>;

const InstallationSuccessView = ({
    onComplete,
    resetProgress,
}: InstallationSuccessViewProps) => {
    const { t } = useTranslation('sensors');
    const dispatch = useAppDispatch();
    const installationFlowStyles = useInstallationFlowStyles();
    const classes = useInstallationSuccessView();
    const { folderId, sensor } = useInstallationFlowContext();
    const isTempOffsetMissing = useIsTempOffsetMissed(sensor ?? undefined);
    const isOnline = sensor ? getSensor(sensor).getIsOnline() : false;
    const isAutoCalibrated = useIsTempOffsetModeAutomatic(sensor ?? undefined);

    const onViewFolderClick = useCallback(() => {
        // close installation flow on redirect
        onComplete();
        dispatch(push(routesManager.resolvePath('folders:details', { folderId })));
    }, [dispatch, folderId, onComplete]);

    return (
        <>
            <Modal.Title>{t('Setup successful!')}</Modal.Title>
            <Modal.Content>
                <Typography align="center">
                    <Icon
                        className={classnames(
                            'far fa-check-circle',
                            installationFlowStyles.successIcon,
                        )}
                    />
                </Typography>
                <Typography
                    className={installationFlowStyles.successMessage}
                    align="center"
                    variant="h5"
                >
                    {t('Device setup successful!')}
                </Typography>
                <Typography
                    className={installationFlowStyles.callToAction}
                    align="center"
                >
                    {t('Now physically install the device')}
                </Typography>
                <Typography
                    data-cypress="installation-instructions"
                    className={installationFlowStyles.paragraph}
                    align="center"
                >
                    {t('As a reminder, you can find')}
                    <Link
                        href={SENSOR_INSTALLATION_GUIDE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={installationFlowStyles.inlineHelpLink}
                    >
                        {t('installation instructions for all devices here.')}
                    </Link>
                </Typography>
                {isAutoCalibrated && (
                    <Alert severity="info">
                        <AlertTitle>{t('Auto offset in progress')}</AlertTitle>
                        <Typography align="center">
                            {t(
                                "This should take roughly 10 minutes. When we're done your data will appear in the app",
                            )}
                        </Typography>
                    </Alert>
                )}
                {isTempOffsetMissing && (
                    <Alert severity="warning">
                        <AlertTitle>
                            {isOnline
                                ? t('Offset is missing')
                                : t('Offset missing - sensor is offline')}
                        </AlertTitle>
                        <Typography>
                            {isOnline
                                ? t(
                                      'The temperature offset for this sensor has not been calibrated. Go to the Sensors view in the web app to enter an offset reading.',
                                  )
                                : t(
                                      'You cannot enter an offset while a sensor is offline. Please check the device’s signal and take a new offset reading later. Go to the Sensors view in the web app to enter an offset reading.',
                                  )}
                        </Typography>
                    </Alert>
                )}
            </Modal.Content>

            <Modal.Actions
                justify="space-between"
                className={classnames(installationFlowStyles.actions, classes.actions)}
            >
                {folderId && (
                    <Button
                        data-cypress="view-folder"
                        onClick={onViewFolderClick}
                        color="primary"
                    >
                        {t('View folder')}
                    </Button>
                )}
                <div className={classes.nextButtonsContainer}>
                    <Button
                        onClick={resetProgress}
                        variant="outlined"
                        color="primary"
                        data-cypress="add-device"
                        startIcon={<Icon className="fal fa-plus" />}
                    >
                        {t('Add another device')}
                    </Button>
                    <Button
                        onClick={onComplete}
                        variant="contained"
                        color="primary"
                        data-cypress="finish"
                        className={classes.finishButton}
                        autoFocus
                    >
                        {t('Finish')}
                    </Button>
                </div>
            </Modal.Actions>
        </>
    );
};

export default memo(InstallationSuccessView);
