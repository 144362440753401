import { routesManager } from '@infogrid/core-routing';
import type { FloorPipeMonitoringSensor } from '@infogrid/remote-monitoring-types';
import {
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import {
    getFailReasons,
    getSubTypeDetails,
} from 'views/dashboards/widgets/PipeMonitoringAtRiskWidget/utils';

import SubTypeChip from '../SubTypeChip/SubTypeChip';
import { useFloorSectionStyles } from './styles';

interface FloorSectionProps {
    floorName: string;
    sensors: FloorPipeMonitoringSensor[];
    showFloorSectionTitle?: boolean;
    appendFloorNameToSensor?: boolean;
}

const FloorSection = ({
    floorName,
    sensors,
    showFloorSectionTitle = false,
    appendFloorNameToSensor = false,
}: FloorSectionProps) => {
    const styles = useFloorSectionStyles();
    const { t } = useTranslation('dashboard');

    const getSensorDisplay = (sensor: FloorPipeMonitoringSensor) => {
        const sensorName = sensor.sensor_name || sensor.device_name;
        const spaceName = sensor.space_name ? ` / ${sensor.space_name}` : '';
        const displayFloorName = appendFloorNameToSensor ? ` / ${floorName}` : '';
        const textDisplay = sensorName + spaceName + displayFloorName;

        return textDisplay;
    };
    const getSensorFailReasons = (sensor: FloorPipeMonitoringSensor) =>
        getFailReasons(sensor.failure_reasons)
            .map((reason) => t(reason))
            .join(' | ');
    const renderSubTypeChip = (sensor: FloorPipeMonitoringSensor) => {
        const { subtype, chipType } = getSubTypeDetails(sensor.sensor_sub_type);

        return <SubTypeChip label={t(subtype)} chipType={chipType} />;
    };

    return (
        <List key={floorName} disablePadding>
            {showFloorSectionTitle && (
                <ListItem divider>
                    <ListItemText
                        primary={
                            <Typography
                                variant="subtitle1"
                                className={styles.spaceHeader}
                            >
                                {floorName}
                            </Typography>
                        }
                    />
                </ListItem>
            )}
            {sensors.map((sensor) => (
                <ListItem key={sensor.sensor_name} divider dense>
                    <ListItemText
                        primary={
                            <NavLink
                                to={routesManager.resolvePath('sensors:details', {
                                    deviceName: sensor.device_name,
                                })}
                                className={styles.link}
                            >
                                {getSensorDisplay(sensor)}
                            </NavLink>
                        }
                        secondary={
                            <Typography variant="caption" data-cypress="sensor-risk">
                                {getSensorFailReasons(sensor)}
                            </Typography>
                        }
                    />
                    <ListItemSecondaryAction data-cypress="sensor-sub-type">
                        {renderSubTypeChip(sensor)}
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
};

export default memo(FloorSection);
