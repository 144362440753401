import { ConfirmModal } from '@infogrid/components-material-ui';
import { Typography } from '@material-ui/core';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const modalActionsProps = { justify: 'space-between' };
const confirmButtonProps = { color: 'secondary' };

export interface DiscardConfigurationChangesModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const DiscardConfigurationChangesModal = ({
    open,
    onClose,
    onConfirm,
}: DiscardConfigurationChangesModalProps) => {
    const { t } = useTranslation('dashboard');

    const content = useMemo(
        () => (
            <Typography color="secondary" variant="body1">
                {t(
                    'You are about to discard changes to this dashboard widget. Details will not be saved. Are you sure you want to continue?',
                )}
            </Typography>
        ),
        [t],
    );

    return (
        <ConfirmModal
            fullWidth
            maxWidth="xs"
            title={t('Discard changes', { ns: 'common' })}
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            content={content}
            modalActionsProps={modalActionsProps}
            confirmButtonProps={confirmButtonProps}
            confirmButtonText={t('Discard', { ns: 'common' })}
            cancelButtonText={t('Cancel', { ns: 'common' })}
        />
    );
};

export default memo(DiscardConfigurationChangesModal);
