import { toastDefaultMeta, toastSuccess } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { setUser, getUser } from '@infogrid/user-ducks';
import { MOBILE_BREAKPOINT } from '@infogrid/utils-constants';
import { i18n } from '@infogrid/utils-i18n';
import { createSaveAction, createFormSaveSaga } from '@thorgate/spa-forms';
import { push } from 'connected-react-router';
import { takeLatest, put, select } from 'redux-saga/effects';

import { selectActiveTerms } from 'schemas/terms';
import api from 'services/api';
import SETTINGS from 'settings';
import { handleDelayingFormSave } from 'utils/handleDelayingFormSave';
import { isIntercomAvailableForUser, toggleIntercomButton } from 'utils/intercom';

export const agreeToTerms = createSaveAction(
    'sagas/account/AGREE_TO_TERMS',
    toastDefaultMeta,
);

const agreeToTermsWorker = createFormSaveSaga({
    resource: api.terms.accept,
    method: 'post',
    *successHook() {
        let user = yield select(getUser);

        yield put(
            setUser({
                user: {
                    ...user,
                    has_accepted_latest_terms: true,
                },
                isAuthenticated: !!(user && user.id),
            }),
        );

        yield put(push(routesManager.resolvePath('landing')));
        yield put(
            toastSuccess({
                message: i18n.t('Successfully agreed to user terms.'),
            }),
        );

        // Since we hide Intercom when the user hasn't accepted ToS, we show it after accepting
        if (isIntercomAvailableForUser(user)) {
            window.Intercom('boot', {
                app_id: SETTINGS.INTERCOM_APP_ID,
                name: user.name,
                email: user.email,
                created_at: user.date_joined,
                phone: user.phone,
                brand: 'infogrid',
                user_hash: user.intercom_hash,
            });

            // Hide intercom on mobile (WEB-3248)
            if (window.innerWidth < MOBILE_BREAKPOINT) {
                toggleIntercomButton(true);
            }
        }
    },

    *mutateKwargs() {
        const activeTerms = yield select(selectActiveTerms);

        return {
            pk: activeTerms.id,
        };
    },
});

export default function* agreeToTermsWatcherSaga(match) {
    yield takeLatest(
        agreeToTerms.getType(),
        handleDelayingFormSave(
            agreeToTermsWorker,
            i18n.t('Confirmation agreement is in progress'),
        ),
        match,
    );
}
