import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import type { FontWeightProperty } from 'csstype';

export const useStylesMapTooltip = makeStyles((theme) => ({
    tooltip: {
        position: 'absolute',
        bottom: '35px',
        whiteSpace: 'nowrap',
        maxWidth: '350px',
        minWidth: '155px',
        padding: theme.spacing(1, 1.5),
        color: theme.palette.common.white,
        borderRadius: '4px',
        backgroundColor: theme.palette.common.gray2,
        left: '50%',
        transform: 'translateX(-50%)',
    },
    tooltipLine: {
        display: 'flex',
    },
    sensorName: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    tooltipLinePrimary: {
        fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
    },
    tooltipLineSecondary: {
        color: grey[400],
    },
}));
