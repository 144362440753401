import { getFlatEntitiesFromPages } from '@infogrid/core-api';
import type { IPagination } from '@infogrid/core-types';
import type {
    BuildingAtRiskWithFloors,
    FloorPipeMonitoringSensor,
} from '@infogrid/remote-monitoring-types';
import type { InfiniteData } from 'react-query';

interface Props extends InfiniteData<IPagination<BuildingAtRiskWithFloors>> {
    results: BuildingAtRiskWithFloors[];
}

export const selectBuildingsAtRiskWithFloors = ({
    data,
    sensorsFilter,
}: {
    data: InfiniteData<IPagination<BuildingAtRiskWithFloors>>;
    sensorsFilter?: (sensor: FloorPipeMonitoringSensor) => boolean;
}): Props => {
    const flatData = getFlatEntitiesFromPages({
        pages: data?.pages,
        entitiesKey: 'results',
        uniqByKey: '',
    });

    // merge building floors from sequential requests into a single key/value pair
    const buildings: { [key: string]: BuildingAtRiskWithFloors } = {};

    flatData.forEach((building) => {
        const knownBuilding = buildings[building.name] ?? {
            name: building.name,
            floors: [],
        };

        Object.keys(building.floors).forEach((floor_name) => {
            const knownSensors = knownBuilding.floors[floor_name] ?? [];
            let sensors = building.floors[floor_name];

            if (sensorsFilter) {
                sensors = sensors.filter(sensorsFilter);
            }

            knownBuilding.floors[floor_name] = [...knownSensors, ...sensors];
        });
        buildings[building.name] = knownBuilding;
    });

    const results = Object.values(buildings);

    return {
        ...data,
        results,
    };
};
