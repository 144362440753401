import { toastSuccess } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { selectOrganizationId } from '@infogrid/user-ducks';
import { i18n } from '@infogrid/utils-i18n';
import { createSaveAction, createFormSaveSaga } from '@thorgate/spa-forms';
import { push } from 'connected-react-router';
import { takeLatest, put, select } from 'redux-saga/effects';

import api from 'services/api';
import { handleDelayingFormSave } from 'utils/handleDelayingFormSave';

/**
 * Trigger Create Team worker saga.
 * @param {Object} payload Payload to send to server
 * @param {Object} FormikMeta - Formik actions
 * @returns {{ type: string, payload: *, meta: * }} Created trigger action
 */
export const createTeam = createSaveAction('sagas/account/CREATE_TEAM');

function* apiSaveHook(_0, action) {
    const organizationId = yield select(selectOrganizationId);

    return yield api.organization.team.list.post(null, {
        ...action.payload.data,
        organization: organizationId,
    });
}

const handleFormSave = createFormSaveSaga({
    apiSaveHook,
    *successHook(_0, _1, { payload: { data } }) {
        yield put(
            toastSuccess({
                message: `${i18n.t('Team created')} - ${data.name}`,
            }),
        );
        yield put(push(routesManager.resolvePath('organization:teams')));
    },
});

export default function* createTeamWatcherSaga(match) {
    yield takeLatest(
        createTeam.getType(),
        handleDelayingFormSave(handleFormSave, i18n.t('Creating a team is in progress')),
        match,
    );
}
