import qs from 'qs';
import { takeEvery } from 'redux-saga/effects';

import api from 'services/api';

const DOWNLOAD_FILE = 'sagas/application/DOWNLOAD-FILE';

export const downloadFile = (href, callback = null) => ({
    type: DOWNLOAD_FILE,
    href,
    callback,
});

function* fileDownloadWorker({ href, callback }) {
    const extra = yield api.user.signedToken.post();

    const [url, query] = href.split('?');
    const queryString = qs.stringify({
        ...(query ? qs.parse(query) : {}),
        ...extra,
    });

    if (callback) {
        callback();
    }

    window.location = `${url}?${queryString}`;
}

export default function* fileDownloadWatcher() {
    yield takeEvery(DOWNLOAD_FILE, fileDownloadWorker);
}
