import type { CubeSchema, QueryResult, UseCubeOptions } from '@infogrid/core-cube';
import {
    convertIntToUuid,
    convertUuidToInt,
    DAILYBUILDINGPILLAR_SCHEMA,
    queryBuilder,
    useCube,
} from '@infogrid/core-cube';
import type { DateYMDString, AxiosParsedError } from '@infogrid/core-types';
import moment from 'moment';
import type { UseQueryResult } from 'react-query';

import type {
    PerBuildingInfoBase,
    BuildingScoreInfoBase,
    HistoricalScore,
    BuildingScoreInfo,
    PillarName,
} from 'views/dashboards/widgets/HealthyBuildingScoreWidget/types';

export type QueryParams = {
    building_ids?: number[];
};

export enum HEALTHY_BUILDING_PILLAR {
    THERMAL_HEALTH = 'Healthy Buildings: Thermal Health',
    AIR_QUALITY = 'Healthy Buildings: Air Quality',
}

type PillarScore = {
    sensor_count: number;
    score: number;
    score_date: DateYMDString;
    pillar: PillarName | 'Final Score';
};

const { MEASURES, DIMENSIONS } = DAILYBUILDINGPILLAR_SCHEMA;

const getDates = (): DateYMDString[] =>
    [...Array(30).keys()].map((i) =>
        moment()
            .subtract(i + 1, 'days')
            .format('YYYY-MM-DD'),
    ) as DateYMDString[];

export const allBuildingsQueryBuilder = (
    params: QueryParams,
    pillars: HEALTHY_BUILDING_PILLAR[] = [],
) => {
    const builder = queryBuilder<CubeSchema>(DAILYBUILDINGPILLAR_SCHEMA)
        .addMeasure(MEASURES.SCORE.name)
        .addMeasure(MEASURES.NUMBER_OF_SENSORS.name)
        .addDimension(DIMENSIONS.LOCAL_DATE.name)
        .addDimension(DIMENSIONS.PILLAR.name)
        .addFilter(DIMENSIONS.PILLAR.name, 'equals', [
            'Healthy Buildings: Final Score',
            ...pillars,
        ])
        .addFilter(DIMENSIONS.LOCAL_DATE.name, 'equals', getDates())
        .addOrderBy(DIMENSIONS.LOCAL_DATE.name, 'asc');

    const buildingIds = params.building_ids;
    if (buildingIds?.length) {
        builder.addFilter(
            DIMENSIONS.BUILDING_ID.name,
            'equals',
            buildingIds.map(convertIntToUuid),
        );
    }

    return builder;
};

const getBuildingScoreInfo = (scoreRows: PillarScore[]): BuildingScoreInfo => {
    const dates = getDates().reverse();
    const groupedScores = scoreRows.reduce((group, { pillar, ...item }) => {
        if (group[pillar]) {
            group[pillar].push(item);
        } else {
            // eslint-disable-next-line no-param-reassign
            group[pillar] = [item];
        }

        return group;
    }, {} as { [key: string]: HistoricalScore[] });

    Object.keys(groupedScores).forEach((pillar) => {
        for (let i = 0; i < dates.length; i++) {
            if (groupedScores[pillar][i]?.score_date !== dates[i]) {
                groupedScores[pillar].splice(i, 0, {
                    score: 30,
                    score_date: dates[i],
                });
            }
        }
    });

    const pillarScores = Object.fromEntries(
        Object.entries(groupedScores).map(([pillar, scores]) => {
            return [
                pillar,
                {
                    name: pillar as PillarName,
                    score_today: scores[scores.length - 1]?.score ?? 0,
                    score_yesterday: scores[scores.length - 2]?.score ?? 0,
                    score_thirty_days_ago: scores[0]?.score ?? 0,
                },
            ];
        }),
    );

    // Taking the highest daily sensor count as the total number of sensors
    const sensorCount = Math.max(
        0,
        ...scoreRows.filter((s) => s.pillar === 'Final Score').map((s) => s.sensor_count),
    );

    const { 'Final Score': finalScore, ...pillarMap } = pillarScores;

    return {
        ...(finalScore || {
            pillar: 'Final Score',
            score_today: 0,
            score_yesterday: null,
            score_thirty_days_ago: null,
        }),
        historical_scores: groupedScores['Final Score'] || [],
        sensor_count: sensorCount,
        pillars: Object.values(pillarMap),
    };
};

const getAllBuildingScoreInfo = (results: QueryResult): BuildingScoreInfoBase => {
    const allScores =
        results?.tablePivot().map((item) => ({
            sensor_count: Number(item[MEASURES.NUMBER_OF_SENSORS.name] as string) || 0,
            score: Math.round((item[MEASURES.SCORE.name] as number) * 100),
            score_date: moment(item[DIMENSIONS.LOCAL_DATE.name] as string).format(
                'YYYY-MM-DD',
            ) as DateYMDString,
            pillar: (item[DIMENSIONS.PILLAR.name] as string).replace(
                'Healthy Buildings: ',
                '',
            ) as PillarName,
        })) ?? [];
    return getBuildingScoreInfo(allScores);
};

const getPerBuildingScoreInfo = (
    results: QueryResult,
    buildingIds: number[],
): PerBuildingInfoBase => {
    const groupedResults =
        results?.tablePivot().reduce(
            (groupedScores, item) => ({
                ...groupedScores,
                [item[DIMENSIONS.BUILDING_ID.name] as string]: [
                    ...(groupedScores[item[DIMENSIONS.BUILDING_ID.name] as string] ?? []),
                    {
                        sensor_count: Number(item[MEASURES.SCORE.name] as string) || 0,
                        score: Math.round((item[MEASURES.SCORE.name] as number) * 100),
                        score_date: moment(
                            item[DIMENSIONS.LOCAL_DATE.name] as string,
                        ).format('YYYY-MM-DD') as DateYMDString,
                        pillar: (item[DIMENSIONS.PILLAR.name] as string).replace(
                            'Healthy Buildings: ',
                            '',
                        ) as PillarName,
                    },
                ],
            }),
            {} as Record<string, PillarScore[]>,
        ) ?? {};

    const perBuildingInfo = Object.entries(groupedResults).reduce(
        (perBuildingScoresAcc, [buildingUuid, buildingScores]) => ({
            ...perBuildingScoresAcc,
            [convertUuidToInt(buildingUuid)]: getBuildingScoreInfo(buildingScores),
        }),
        {} as PerBuildingInfoBase,
    );

    // Add missing buildings, since our cube query won't return a building if it doesn't have any data
    // in the past 30 days
    buildingIds.forEach((buildingId) => {
        if (!perBuildingInfo[buildingId]) {
            perBuildingInfo[buildingId] = {
                score_today: 0,
                score_yesterday: 0,
                score_thirty_days_ago: 0,
                historical_scores: [],
            };
        }
    });

    return perBuildingInfo;
};

export const useAllBuildingScore = (
    widgetId: number,
    params: {
        options?: UseCubeOptions<BuildingScoreInfoBase>;
        getQueryParams?: () => QueryParams;
    } = {},
    pillars: HEALTHY_BUILDING_PILLAR[] = [],
): UseQueryResult<BuildingScoreInfoBase, AxiosParsedError> => {
    const { options = {}, getQueryParams = () => ({} as QueryParams) } = params;
    const queryParams = getQueryParams();
    const allBuildingsQuery = allBuildingsQueryBuilder(queryParams, pillars);
    return useCube(
        `healthy-building-score-all-buildings-history-${widgetId}`,
        allBuildingsQuery,
        {
            ...options,
            select: getAllBuildingScoreInfo,
        },
    );
};

export const useBuildingScores = (
    widgetId: number,
    params: {
        options?: UseCubeOptions<PerBuildingInfoBase>;
        getQueryParams?: () => QueryParams;
    } = {},
): UseQueryResult<PerBuildingInfoBase, AxiosParsedError> => {
    const { options = {}, getQueryParams = () => ({} as QueryParams) } = params;
    const queryParams = getQueryParams();
    const perBuildingQuery = allBuildingsQueryBuilder(queryParams)
        .addDimension(DIMENSIONS.BUILDING_ID.name)
        .addDimension(DIMENSIONS.BUILDING_NAME.name);
    return useCube(
        `healthy-building-score-per-buildings-history-${widgetId}`,
        perBuildingQuery,
        {
            ...options,
            select: (result) =>
                getPerBuildingScoreInfo(result, queryParams.building_ids || []),
        },
    );
};
