import { useState, useEffect, useCallback } from 'react';

import { VERSION_KEY, UPDATE_BACKEND_VERSION_KEY } from 'constants/buildVersion';

export const useBackendVersion = () => {
    const [backendVersion, setBackendVersion] = useState(
        localStorage.getItem(VERSION_KEY) ?? '',
    );

    const getBackendVersion = useCallback(() => {
        const localStorageBackendVersion = localStorage.getItem(VERSION_KEY);

        if (localStorageBackendVersion && backendVersion !== localStorageBackendVersion) {
            setBackendVersion(localStorageBackendVersion);
        }
    }, [backendVersion]);

    useEffect(() => {
        // listens for storage events on other windows
        window.addEventListener('storage', getBackendVersion);
        // listens for storage update events on the current window
        window.addEventListener(UPDATE_BACKEND_VERSION_KEY, getBackendVersion);

        return () => {
            window.removeEventListener('storage', getBackendVersion);
            window.removeEventListener(UPDATE_BACKEND_VERSION_KEY, getBackendVersion);
        };
    }, [getBackendVersion]);

    return backendVersion;
};

export const setLocalStorageBackendVersion = (response) => {
    const version = response.headers[VERSION_KEY];

    const localStorageVersion = localStorage.getItem(VERSION_KEY);

    if (version && version !== localStorageVersion) {
        localStorage.setItem(VERSION_KEY, version);
        // dispatches the setLocalstorageBackendVersion event to force re-fetch latest version from localstorage
        window.dispatchEvent(new CustomEvent(UPDATE_BACKEND_VERSION_KEY));
    }
};
