import { toastSetStatus, toastSuccess } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { selectOrganizationId } from '@infogrid/user-ducks';
import { i18n } from '@infogrid/utils-i18n';
import { entitiesActions } from '@thorgate/spa-entities';
import { createDeleteAction, createFormSaveSaga } from '@thorgate/spa-forms';
import { takeLatestWithMatch } from '@thorgate/spa-view-manager';
import { push, getLocation } from 'connected-react-router';
import { put, select, call } from 'redux-saga/effects';

import removeFromOrder from 'sagas/helpers/removeFromOrder';
import { orgUserSchema } from 'schemas/orgUser';
import api from 'services/api';
import { handleDelayingFormSave } from 'utils/handleDelayingFormSave';
import { getKeyOptionsWithFiltered } from 'utils/relations';

function* successHook(_0, _1, { payload: { data } }) {
    yield put(toastSuccess({ message: `${i18n.t('User deleted')} - ${data.email}` }));

    const location = yield select(getLocation);
    const keyOptions = getKeyOptionsWithFiltered({}, location);
    const isFiltered = keyOptions?.filtered;

    if (isFiltered) {
        const { filterName } = keyOptions;

        yield call(removeFromOrder, `${orgUserSchema.key}-${filterName}`, [data.id]);
    }

    yield put(
        entitiesActions.purgeEntities({
            key: orgUserSchema.key,
            ids: [data.id],
        }),
    );

    yield put(
        push(routesManager.resolvePath('organization:accounts', null, location.search)),
    );
}

export const deleteOrgUser = createDeleteAction('sagas/account/DELETE_ORG_USER', {
    setStatus: toastSetStatus,
});

const deleteOrgUserWorker = createFormSaveSaga({
    resource: api.organization.user.detail,
    method: 'del',
    successHook,

    *mutateKwargs(match, action) {
        const organizationId = yield select(selectOrganizationId);

        return {
            pk: action.payload.data.id,
            org_pk: organizationId,
        };
    },
});

export default function* deleteOrgUserWatcher() {
    yield takeLatestWithMatch(
        deleteOrgUser.getType(),
        routesManager.resolvePattern('organization:accounts'),
        handleDelayingFormSave(
            deleteOrgUserWorker,
            i18n.t('Deleting of user is in progress'),
        ),
    );
}

export const deleteOrgServiceAccount = createDeleteAction(
    'sagas/account/DELETE_ORG_SERVICE_ACCOUNT',
    { setStatus: toastSetStatus },
);

const deleteOrgServiceAccountWorker = deleteOrgUserWorker.cloneSaga({
    resource: api.organization.serviceAccount.detail,
});

export function* deleteOrgServiceAccountWatcher() {
    yield takeLatestWithMatch(
        deleteOrgServiceAccount.getType(),
        routesManager.resolvePattern('organization:accounts'),
        handleDelayingFormSave(
            deleteOrgServiceAccountWorker,
            i18n.t('Deleting of service account is in progress'),
        ),
    );
}
