import { makeStyles } from '@material-ui/core';

export const useMaxWebhooksAllowedModalStyles = makeStyles((theme) => ({
    modalContent: {
        display: 'flex',
    },
    text: {
        marginBottom: theme.spacing(2),
    },
    warningIcon: {
        color: theme.palette.secondary.main,
        fontSize: '22px',
        marginRight: theme.spacing(1),
        width: '24px',
        paddingTop: '1px',
    },
}));
