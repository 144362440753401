import { Modify } from 'ol/interaction';
import type { Options } from 'ol/interaction/Modify';
import { useContext, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';

import type { BaseMapItem, MapFeature } from '../../types';
import MapContext from '../MapContext';

export interface DragInteractionProps<T extends BaseMapItem> {
    options: Options;
    onChange: (feature: MapFeature<T>, newCoordinates: [number, number]) => void;
    onDragStart?: (feature: MapFeature<T>) => void;
}

const DragInteraction = <T extends BaseMapItem>({
    options,
    onChange,
    onDragStart,
}: DragInteractionProps<T>): null => {
    const { map } = useContext(MapContext);

    const [dragStartHandler] = useDebouncedCallback(
        ({ target: feature }) => onDragStart?.(feature),
        100,
        {
            leading: true,
            trailing: false,
        },
    );

    const [changeHandler] = useDebouncedCallback(
        ({ target: feature }) => {
            if (!map) {
                return;
            }

            onChange(feature, feature.getGeometry().getCoordinates());
        },
        350,
        {
            leading: false,
        },
    );

    useEffect(() => {
        if (!map) {
            return;
        }

        const interaction = new Modify(options);

        map.addInteraction(interaction);

        options.features?.forEach((feature) => {
            feature.on('change', (e) => {
                dragStartHandler(e);
                changeHandler(e);
            });
        });

        return () => {
            map.removeInteraction(interaction);

            options.features?.forEach((feature) => {
                feature.un('change', changeHandler);
            });
        };
    }, [map, onChange, options, changeHandler, dragStartHandler]);

    return null;
};

export default DragInteraction;
