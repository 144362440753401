import { useCallback, useEffect, useRef } from 'react';

const DRAG_CLICK_DELTA = 6;

/**
 * Differentiates between clicks that preceed drags and real clicks
 */
export const useNonDragClick = (
    callback: (e: MouseEvent) => void,
    element?: HTMLElement,
): void => {
    const dragged = useRef(false);
    const startX = useRef(0);
    const startY = useRef(0);

    const mousedownHandler = useCallback((e) => {
        dragged.current = false;
        startX.current = e.pageX;
        startY.current = e.pageY;

        return true;
    }, []);

    const mousemoveHandler = useCallback(() => {
        dragged.current = true;

        return true;
    }, []);

    const mouseupHandler = useCallback(
        (e) => {
            const diffX = Math.abs(e.pageX - startX.current);
            const diffY = Math.abs(e.pageY - startY.current);

            if (
                !dragged.current ||
                (diffX < DRAG_CLICK_DELTA && diffY < DRAG_CLICK_DELTA)
            ) {
                callback(e);
            }

            return true;
        },
        [callback],
    );

    useEffect(() => {
        element?.addEventListener('mousedown', mousedownHandler);
        element?.addEventListener('mousemove', mousemoveHandler);
        element?.addEventListener('mouseup', mouseupHandler);

        return () => {
            element?.removeEventListener('mousedown', mousedownHandler);
            element?.removeEventListener('mousemove', mousemoveHandler);
            element?.removeEventListener('mouseup', mouseupHandler);
        };
    }, [element, mousedownHandler, mousemoveHandler, mouseupHandler]);
};
