import { Typography, TextField, useTheme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import type { FormikErrors } from 'formik';
import { memo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { CleaningValues } from '../RulesModal/types';
import { useStyles } from './styles';

interface Props {
    mightNeedCleaningValue?: number | string;
    mustBeCleanedValue?: number;
    setMightNeedCleaningValue: (value: number | undefined | 'N/A') => void;
    setMustBeCleanedValue: (value: number | undefined) => void;
    errors?: FormikErrors<CleaningValues>;
}

const RulesSection = ({
    mightNeedCleaningValue,
    mustBeCleanedValue,
    setMightNeedCleaningValue,
    setMustBeCleanedValue,
    errors,
}: Props): JSX.Element => {
    const { t } = useTranslation('solutions');
    const theme = useTheme();
    const styles = useStyles();

    const availableMightValues = [
        t('N/A'),
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
    ];
    const availableMustValues = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];

    const renderInput = useCallback(
        (params) => (
            <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                fullWidth
                variant="outlined"
            />
        ),
        [],
    );

    const onMightChange = (
        _e: React.ChangeEvent<Record<string, unknown>>,
        value: string | null,
    ) => {
        if (value && !isNaN(parseInt(value))) {
            setMightNeedCleaningValue(parseInt(value));
        } else if (value && value === 'N/A') {
            setMightNeedCleaningValue('N/A');
        } else {
            setMightNeedCleaningValue(undefined);
        }
    };

    const onMustChange = (
        _e: React.ChangeEvent<Record<string, unknown>>,
        value: string | null,
    ) => {
        const newValue = value && !isNaN(parseInt(value)) ? parseInt(value) : undefined;

        setMustBeCleanedValue(newValue);
    };

    return (
        <>
            <div className={styles.rulesRow}>
                <span
                    className={styles.ruleSymbol}
                    style={{ backgroundColor: theme.palette.common.yellow2 }}
                />
                <Typography
                    className={styles.labelText}
                    color="textPrimary"
                    variant="body2"
                >
                    <Trans t={t}>
                        <b>Might</b> need cleaning after
                    </Trans>
                </Typography>
                <Autocomplete
                    freeSolo
                    onChange={onMightChange}
                    onInputChange={onMightChange}
                    options={availableMightValues.map((v) => v.toString())}
                    value={mightNeedCleaningValue?.toString()}
                    renderInput={renderInput}
                    className={styles.autocomplete}
                    data-cypress="mightBeCleanedDropdown"
                    data-testid="mightBeCleanedDropdown"
                    forcePopupIcon
                />
                <Typography
                    className={styles.usesLabel}
                    color="textPrimary"
                    variant="body2"
                >
                    {t('uses')}
                </Typography>
            </div>
            <div className={styles.rulesRow}>
                <span
                    className={styles.ruleSymbol}
                    style={{ backgroundColor: theme.palette.common.red2 }}
                />
                <Typography
                    className={styles.labelText}
                    color="textPrimary"
                    variant="body2"
                >
                    <Trans t={t}>
                        <b>Must</b> be cleaned after
                    </Trans>
                </Typography>
                <Autocomplete
                    freeSolo
                    onChange={onMustChange}
                    onInputChange={onMustChange}
                    options={availableMustValues.map((v) => v.toString())}
                    value={mustBeCleanedValue?.toString()}
                    renderInput={renderInput}
                    className={styles.autocomplete}
                    data-cypress="mustBeCleanedDropdown"
                    data-testid="mustBeCleanedDropdown"
                    forcePopupIcon
                />
                <Typography
                    className={styles.usesLabel}
                    color="textPrimary"
                    variant="body2"
                >
                    {t('uses')}
                </Typography>
            </div>
            {errors?.must_be_cleaned_value && (
                <Typography
                    className={styles.errorMessage}
                    color="error"
                    variant="caption"
                >
                    {errors?.must_be_cleaned_value}
                </Typography>
            )}
            {errors?.might_need_cleaning_value && (
                <Typography
                    className={styles.errorMessage}
                    color="error"
                    variant="caption"
                >
                    {errors?.might_need_cleaning_value}
                </Typography>
            )}
        </>
    );
};

export default memo(RulesSection);
