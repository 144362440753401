import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import type { PlannedSensor, SensorShape } from '@infogrid/sensors-constants';
import type { FC } from 'react';
import { useEffect } from 'react';

import { invalidateFloorDetail } from 'apiHooks/floorPlan/floors/accessors';
import {
    invalidateFloorSensors,
    invalidatePlannedSensorsQueries,
} from 'apiHooks/floorPlan/sensors/accessors';
import { useInstallSensor } from 'apiHooks/sensors/installation/hooks';
import { isDualPlannedSensor } from 'components/floorPlans';
import {
    selectPlannedSensor,
    selectIdentifiedSensor,
    onSensorInstalled,
    handleInstallError,
    selectDeviceConfiguration,
} from 'ducks/installFlow';

import { FLOORPLAN_DUAL_SENSOR_SEPARATOR } from '../consts';
import InstallPending from './InstallPending';

const InstallPendingContainer: FC = () => {
    const dispatch = useAppDispatch();
    const plannedSensor = useAppSelector(selectPlannedSensor) as PlannedSensor;
    const identifiedSensor = useAppSelector(selectIdentifiedSensor) as SensorShape;
    const deviceConfiguration = useAppSelector(selectDeviceConfiguration);

    const { mutate: installSensor } = useInstallSensor(
        {
            onSuccess: (installedSensor) => {
                dispatch(onSensorInstalled(installedSensor));
                invalidateFloorSensors({ floorId: plannedSensor.location.floor_id });
                invalidatePlannedSensorsQueries({
                    floorId: plannedSensor.location.floor_id,
                });
                invalidateFloorDetail(plannedSensor.location.floor_id);
            },
            onError: ({ response }) => {
                dispatch(handleInstallError(response));
            },
        },
        identifiedSensor,
    );

    useEffect(() => {
        // For dual sensors, we place both around the centre point based on rotation
        const [xdelta, ydelta] = [
            FLOORPLAN_DUAL_SENSOR_SEPARATOR *
                Math.sin((plannedSensor.coordinates.rotation || 0) * (Math.PI / 180)),
            FLOORPLAN_DUAL_SENSOR_SEPARATOR *
                Math.cos((plannedSensor.coordinates.rotation || 0) * (Math.PI / 180)),
        ];
        const positions =
            isDualPlannedSensor(plannedSensor) &&
            plannedSensor.coordinates.rotation !== undefined
                ? {
                      position: {
                          x: Math.round(plannedSensor.coordinates.x + xdelta),
                          y: Math.round(plannedSensor.coordinates.y + ydelta),
                      },
                      position_b: {
                          x: Math.round(plannedSensor.coordinates.x - xdelta),
                          y: Math.round(plannedSensor.coordinates.y - ydelta),
                      },
                  }
                : {
                      position: {
                          x: Math.round(plannedSensor.coordinates.x),
                          y: Math.round(plannedSensor.coordinates.y),
                      },
                  };

        installSensor({
            ...positions,
            planned_sensor_id: plannedSensor.id as string,
            name: plannedSensor.name,
            location: plannedSensor.location,
            device: {
                sensor_id: identifiedSensor.id,
            },
            sensor_type: plannedSensor.device_type.name,
            labels: plannedSensor.labels || [],
            configuration: deviceConfiguration,
        });
    }, [installSensor, plannedSensor, identifiedSensor, deviceConfiguration]);

    return <InstallPending />;
};

export default InstallPendingContainer;
