import {
    wrapResponsePromise,
    INFINITE_QUERY_DEFAULT_INITIAL_DATA,
} from '@infogrid/core-api';
import type { IPagination, AxiosParsedError } from '@infogrid/core-types';
import { spaceControllers, getSpacesKey } from '@infogrid/locations-api';
import type { SpacesParams } from '@infogrid/locations-api';
import type { Space } from '@infogrid/locations-types';
import type { SensorType } from '@infogrid/sensors-constants';
import isNil from 'lodash/isNil';
import { useInfiniteQuery } from 'react-query';
import type { UseInfiniteQueryResult, UseInfiniteQueryOptions } from 'react-query';

interface Params extends SpacesParams {
    sensor_count_types?: SensorType[];
}

interface Config {
    params?: Params;
    options?: UseInfiniteQueryOptions<
        IPagination<Space>,
        AxiosParsedError,
        IPagination<Space>
    >;
}

export const useSpaces = ({
    params = { floor: null },
    options = {},
}: Config = {}): UseInfiniteQueryResult<IPagination<Space>, AxiosParsedError> =>
    useInfiniteQuery(
        getSpacesKey(params),
        ({ signal, pageParam = {} }) =>
            wrapResponsePromise(
                spaceControllers.getSpaces(signal, { ...pageParam, ...params }),
            ),
        {
            initialData: INFINITE_QUERY_DEFAULT_INITIAL_DATA,
            keepPreviousData: true,
            getNextPageParam: (lastPage) => lastPage?.next,
            enabled: !isNil(params?.floor),
            ...options,
        },
    );
