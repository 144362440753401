import { List, ListItem, ListItemText, Box, Typography, Radio } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePipeMonitoringTypeSelectionStyles } from './styles';
import { MONITORING_TYPES, MONITORING_TYPES_ALL_KEYS } from './utils';

export interface PipeMonitoringTypeSelectionProps {
    onChange: (value: string[]) => void;
    selectedMonitoringTypes: string[];
}

const PipeMonitoringTypeSelection = ({
    onChange,
    selectedMonitoringTypes,
}: PipeMonitoringTypeSelectionProps) => {
    const { t } = useTranslation('reports');
    const styles = usePipeMonitoringTypeSelectionStyles();

    const selectedMonitoringTypesSorted = useMemo(() => {
        return selectedMonitoringTypes.sort();
    }, [selectedMonitoringTypes]);

    const selectionOptions = useMemo(() => {
        const options = MONITORING_TYPES.map((item) => ({
            key: item.key,
            displayText: item.displayText,
            onClick: () => onChange([item.key]),
            isChecked: () => isEqual(selectedMonitoringTypesSorted, [item.key]),
        }));

        options.push({
            key: 'both',
            displayText: t('Both'),
            onClick: () => onChange(MONITORING_TYPES_ALL_KEYS),
            isChecked: () =>
                isEqual(selectedMonitoringTypesSorted, MONITORING_TYPES_ALL_KEYS),
        });

        return options;
    }, [onChange, selectedMonitoringTypesSorted, t]);

    return (
        <Box>
            <Typography
                variant="body2"
                color="textSecondary"
                className={styles.description}
            >
                {t('Select the water safety metric(s) you need')}
            </Typography>
            <List disablePadding>
                {selectionOptions.map((item) => {
                    return (
                        <ListItem
                            key={item.key}
                            dense
                            disableGutters
                            button
                            onClick={item.onClick}
                            data-cypress={`check-monitoring-type-${item.key}`}
                        >
                            <Radio
                                size="small"
                                data-testid={`check-monitoring-type-${item.key}`}
                                checked={item.isChecked()}
                                color="primary"
                                inputProps={{ 'aria-labelledby': `${item.key}` }}
                                disableRipple
                            />
                            <ListItemText
                                id={item.key}
                                primary={item.displayText}
                                primaryTypographyProps={{ variant: 'body2' }}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};

export default memo(PipeMonitoringTypeSelection);
