import type { OrganizationFeatureFlagType } from '@infogrid/core-feature-flags';
import type { OrganizationFeatures } from '@infogrid/user-types';

export const isWidgetEnabled = (
    widgetFeatureFlags: OrganizationFeatureFlagType[] = [],
    userFeatureFlags: OrganizationFeatures = {},
    widgetIsDeprecated = false,
): boolean => {
    if (widgetIsDeprecated) {
        return false;
    }

    return !widgetFeatureFlags.some((flag) => !userFeatureFlags[flag]?.enabled);
};
