import { makeStyles } from '@material-ui/core';

export const useBreadcrumbsStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        maxWidth: 600,
        padding: theme.spacing(2, 3),
    },
    icon: {
        alignItems: 'center',
        display: 'flex',
        fontSize: 17,
        height: 20,
        '&:not(&:last-of-type)': {
            cursor: 'pointer',
        },
    },
    separator: {
        margin: theme.spacing(0, 1),
    },
    label: {
        fontSize: 14,
        marginLeft: theme.spacing(1),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));
