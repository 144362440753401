import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import WidgetPreview from 'styles/images/widgets/meeting-room-occupancy.png';

import { MeetingRoomOccupancyWidget } from './MeetingRoomOccupancyWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('Meeting Room Occupancy – Live', { ns: 'dashboard' }),
    type: WIDGET_TYPE.SPACE_OCCUPANCY,
    featureFlags: [],
    Component: MeetingRoomOccupancyWidget,
    image: WidgetPreview,
    order: 16,
    gridConfig: DEFAULT_GRID_ITEM_CONFIG,
};

export default MeetingRoomOccupancyWidget;
