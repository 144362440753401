import { ConfirmModal } from '@infogrid/components-material-ui';
import { Typography } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteWebhookModalStyles } from './styles';

interface DeleteWebhookModalProps {
    onClose: () => void;
    onConfirm: () => void;
    loading: boolean;
}

const modalActionsProps = { justify: 'right' };

const DeleteWebhookModal = ({ loading, onClose, onConfirm }: DeleteWebhookModalProps) => {
    const styles = useDeleteWebhookModalStyles();
    const { t } = useTranslation('integrations');
    const content = useMemo(
        () => (
            <div className={styles.modalContent}>
                <ReportProblemOutlinedIcon className={classNames(styles.warningIcon)} />
                <div>
                    <Typography variant="body1">
                        {t('Are you sure you want to delete this event webhook?')}
                    </Typography>
                    <Typography variant="body1" className={styles.text}>
                        {t(
                            "Alternatively, you can temporarily stop data being sent to the specified url by clicking on the 'Deactivate webhook' toggle.",
                        )}
                    </Typography>
                </div>
            </div>
        ),
        [styles, t],
    );

    const confirmButtonProps = useMemo(
        () => ({
            color: 'primary',
            disabled: loading,
        }),
        [loading],
    );

    const cancelButtonProps = useMemo(
        () => ({
            color: 'primary',
            disabled: loading,
        }),
        [loading],
    );

    return (
        <ConfirmModal
            maxWidth="xs"
            fullWidth
            title={t('Delete this event webhook?')}
            onClose={onClose}
            onConfirm={onConfirm}
            open
            content={content}
            modalActionsProps={modalActionsProps}
            confirmButtonProps={confirmButtonProps}
            confirmButtonText={t('Continue')}
            cancelButtonProps={cancelButtonProps}
            showDivider
            disableBackdropClick
        />
    );
};

export default DeleteWebhookModal;
