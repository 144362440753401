import QueryParamsManager from '../QueryParamsManager';
import { formatSorting, parseArrayParams, parseNonArrayParams } from '../parsers';
import { SPACE_PARAM_NAMES, SPACE_PARAMS_SET } from './consts';
import type { SpaceQueryParams, FormattedSpaceQueryParams } from './types';

const FORMATTING_PARAMS_HANDLERS = {
    [SPACE_PARAM_NAMES.ORDER_BY]: formatSorting,
    [SPACE_PARAM_NAMES.LABELS]: parseArrayParams,
    [SPACE_PARAM_NAMES.TYPE]: parseArrayParams,
    [SPACE_PARAM_NAMES.SENSOR_TYPE]: parseArrayParams,
};

const PARSING_PARAMS_HANDLERS = {
    [SPACE_PARAM_NAMES.LABELS]: parseArrayParams,
    [SPACE_PARAM_NAMES.TYPE]: parseArrayParams,
    [SPACE_PARAM_NAMES.SENSOR_TYPE]: parseArrayParams,
    [SPACE_PARAM_NAMES.QUERY]: parseNonArrayParams,
};

const config = {
    paramsSet: SPACE_PARAMS_SET,
    formattingParamsHandlers: FORMATTING_PARAMS_HANDLERS,
    parsingParamsHandlers: PARSING_PARAMS_HANDLERS,
    orderByKey: SPACE_PARAM_NAMES.ORDER_BY,
};

export const spaceQueryParamsManager = new QueryParamsManager<
    SpaceQueryParams,
    FormattedSpaceQueryParams
>(
    config.paramsSet,
    config.formattingParamsHandlers,
    config.parsingParamsHandlers,
    config.orderByKey,
);
