import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useSolutionSettingsStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
    icon: {
        alignItems: 'center',
        color: theme.palette.common.gray4,
        display: 'flex',
        fontSize: 72,
        height: 80,
        justifyContent: 'center',
    },
    label: {
        color: theme.palette.common.gray6,
        fontWeight: theme.typography.fontWeightRegular as FontWeightProperty,
        marginTop: theme.spacing(2),
    },
}));
