import type { FloorplanFolder } from '@infogrid/core-types';

export const nodeContainsBuilding = (node: FloorplanFolder): boolean => {
    if (node.floorplan_type === 'building') {
        return true;
    }

    if (!node.subfolders.length) {
        return false;
    }

    // For loop required to keep early return in top-level scope
    // eslint-disable-next-line no-restricted-syntax
    for (const subfolder of node.subfolders) {
        const subfolderHasBuilding = nodeContainsBuilding(subfolder);

        if (subfolderHasBuilding) {
            return true;
        }
    }

    return false;
};
