import isNil from 'lodash/isNil';
import memoize from 'memoize-one';

import { DIRECTION } from './consts';
import type { OrderBy, OrderByColumn, ParsedQsValue } from './types';

export { DIRECTION } from './consts';

export type ParseArrayParamsResult = null | (string | number | ParsedQsValue)[];

/**
 * Parse array query parameter into valid array.
 *
 */
export const parseArrayParams = (
    value: ParsedQsValue | number | (string | number)[],
): ParseArrayParamsResult => {
    if (isNil(value)) {
        return null;
    }

    const paramsArray: (string | number | ParsedQsValue)[] = Array.isArray(value)
        ? value
        : [value];

    return paramsArray.map((id: string | number | ParsedQsValue) => {
        if (/^-?\d+$/.test(String(id))) {
            return Number.parseInt(`${id}`, 10);
        }

        return id;
    });
};

/**
 * Parse non-array query parameter into valid string.
 *
 */
export const parseNonArrayParams = (value: ParsedQsValue): null | string => {
    if (!value) {
        return null;
    }

    return Array.isArray(value) ? value.join(',') : `${value}`;
};

/**
 * Parse non-array boolean string query parameter ('true' or 'false') into boolean.
 *
 */
export const parseBooleanStringParams = (value: ParsedQsValue): null | boolean => {
    if (value === 'true') {
        return true;
    }

    if (value === 'false') {
        return false;
    }

    return null;
};

/**
 * Parse `order_by` query parameter into valid data for SensorTable.
 *
 */
export const formatSorting = memoize((column: OrderByColumn): OrderBy => {
    if (!column) {
        return {
            column: null,
            direction: null,
        };
    }

    if (/^-.*/.test(column)) {
        return {
            column: column.slice(1),
            direction: DIRECTION.DESC,
        };
    }

    return {
        column,
        direction: DIRECTION.ASC,
    };
});
