import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useScoreScaleStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    axis: {
        position: 'relative',
    },
    satisfactionAxis: {
        height: 40,

        display: 'flex',
        alignItems: 'center',

        marginBottom: theme.spacing(1),
    },
    percentageAxis: {
        height: 20,
        marginTop: theme.spacing(1),
    },
    mark: {
        position: 'absolute',
        top: '-35%',

        '&:nth-child(1)': {
            left: '40%',
        },
        '&:nth-child(2)': {
            left: '60%',
        },
    },
    arrow: {
        position: 'absolute',
        left: '10%',
    },
    rotatedArrow: {
        transform: 'rotate(180deg)',
    },
    percentBreakpoint: {
        position: 'absolute',

        '&:nth-child(1)': {
            left: 0,
            color: theme.palette.common.gray6,
        },
        '&:nth-child(2)': {
            left: '38%',
            color: theme.palette.common.gray8,
            fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
        },
        '&:nth-child(3)': {
            left: '59%',
            fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
            color: theme.palette.common.gray8,
        },
        '&:nth-child(4)': {
            right: 0,
            color: theme.palette.common.gray6,
        },
    },
    smile: {
        overflow: 'visible',
    },
    breakpoint: {
        position: 'absolute',
        fontSize: 16,

        '&:nth-child(1)': {
            left: 0,
            color: theme.palette.common.gray6,
        },
        '&:nth-child(2)': {
            left: `calc(0% + ${16}px)`,
            right: `calc(60% + ${16}px)`,
        },
        '&:nth-child(3)': {
            left: `calc(40% - ${8}px)`,
            color: theme.palette.common.gray8,
        },
        '&:nth-child(4)': {
            right: `calc(40% - ${8}px)`,
            color: theme.palette.common.gray8,
        },
        '&:nth-child(5)': {
            right: `calc(0% + ${16}px)`,
            left: `calc(60% + ${16}px)`,
        },
        '&:nth-child(6)': {
            right: 0,
            color: theme.palette.common.gray6,
        },
    },
}));
