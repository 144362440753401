import { HEADER_HEIGHT } from '@infogrid/components-material-ui';
import { makeStyles } from '@material-ui/core';

import { DEFAULT_SPACE_SIDEBAR_WIDTH } from '../consts';

export const useSidebarContentStyles = makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(3, 0),
    },
    drawer: {
        height: '100%',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            backgroundColor: theme.palette.common.white,
            boxShadow: theme.shadows[3],
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 0,
            height: `calc(100vh - ${HEADER_HEIGHT}px)`,
            overflowY: 'auto',
            width: DEFAULT_SPACE_SIDEBAR_WIDTH,
        },
    },
}));
