import { makeStyles, fade } from '@material-ui/core';

export const useContentStyles = makeStyles((theme) => ({
    tableHead: {
        position: 'relative',
        zIndex: 0,
    },
    tableBodyRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: fade('#F8F5F2', 0.75),
        },
    },
    totalCell: {
        fontWeight: 500,
        '&:first-child': {
            textTransform: 'uppercase',
        },
    },
    tableHeadCell: {
        border: 0,
        color: theme.palette.text.grey.default,
        fontSize: '12px',
        letterSpacing: '0.1px',
        lineHeight: '18px',
        padding: '5px',
        textAlign: 'center',
        textTransform: 'uppercase',
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'nowrap',
        },
        '& > i': {
            cursor: 'pointer',
            marginLeft: theme.spacing(0.5),
        },
    },
    tableCell: {
        border: 0,
        fontSize: '13px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    tableCellLeftAlign: {
        textAlign: 'left',
    },
}));
