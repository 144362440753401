import { registerTranslationKey } from '@infogrid/utils-i18n';
import loadable from '@loadable/component';
import type { RouteConfig } from 'react-router-config';

import RenderChildren from 'components/RenderChildren';
import { userIsAuthenticated } from 'decorators/auth';

const SitesView = loadable(
    () => import(/* webpackChunkName: 'views-sites' */ 'views/sites/pages/sitesList'),
);

const LensesView = loadable(
    () => import(/* webpackChunkName: 'views-single-site' */ 'views/sites/pages/lenses'),
);

const pageName = registerTranslationKey('Buildings', { ns: 'estate' });

export const createSitesRoutes = (PageNotFoundRoute: RouteConfig): RouteConfig => ({
    path: '/building-view/',
    name: 'sites',
    pageName,
    component: userIsAuthenticated(RenderChildren),
    routes: [
        {
            path: '/building-view/',
            exact: true,
            name: 'sites',
            pageName,
            component: SitesView,
        },
        {
            path: '/building-view/:buildingId/',
            exact: true,
            name: 'sites-building',
            pageName,
            component: LensesView,
        },
        {
            path: '/building-view/:buildingId/floor/:floorId/:lensId?',
            exact: true,
            name: 'sites-building-floor',
            pageName,
            component: LensesView,
        },
        PageNotFoundRoute,
    ],
});
