import { HEADER_HEIGHT } from '@infogrid/components-material-ui';
import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useStylesFloorPage = makeStyles((theme) => ({
    pageContainer: {
        display: 'flex',
        padding: 0,
        flexGrow: 1,
        flexDirection: 'row',
        overflowX: 'hidden',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    floorPlanLoaderContainer: {
        flexGrow: 1,
        backgroundColor: theme.palette.common.gray5,
    },
    floorsListContainer: {
        flexShrink: 0,
        position: 'sticky',
        top: HEADER_HEIGHT,
        height: '100%',
        zIndex: 1,
    },
    sensorCollapsedText: {
        display: 'flex',
        position: 'relative',
        width: '40px',
        height: '100%',
        '& > span': {
            position: 'relative',
            top: theme.spacing(3.25),
            fontSize: '16px',
            fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
            transform: 'rotate(-90deg)',
            paddingRight: theme.spacing(1.25),
            cursor: 'default',
        },
        justifyContent: 'center',
        alignItems: 'start',
        backgroundColor: theme.palette.common.shell,
    },
}));

export const useStylesNavbarCollapseButton = makeStyles((theme) => ({
    root: {
        padding: '4px',

        backgroundColor: theme.palette.common.gray5,
        color: '#CDD0DD',
        boxShadow: theme.shadows[1],
        '&:hover': {
            backgroundColor: theme.palette.common.gray5,
        },
    },
    floorPlanContainer: {
        display: 'flex',
        flex: '1 100%',
    },
    icon: {
        fontSize: '26px',
        color: '#828AA4',
    },
}));
