import { NO_LOCATIONS_SELECTED } from '@infogrid/components-locations';
import type { LocationFilter } from '@infogrid/core-types';
import { LOCATION_FILTERS } from '@infogrid/core-types';

import type { LiveIndoorAirQualityWidgetConfiguration, WidgetSettings } from './types';

export const getEnabledLocationFilters = (queryString: string): LocationFilter[] => {
    if (queryString === NO_LOCATIONS_SELECTED) {
        return [];
    }

    if (!queryString) {
        return [
            LOCATION_FILTERS.BUILDING,
            LOCATION_FILTERS.FLOOR,
            LOCATION_FILTERS.SPACE,
        ];
    }

    const selections = queryString.split(',');

    const buildings = new Set();
    const floors = new Set();

    selections.forEach((selection) => {
        const parts = selection.split(':');

        buildings.add(Math.abs(+parts[0]));

        if (parts.length > 1) {
            floors.add(parts[1]);
        }
    });

    if (buildings.size !== 1) {
        return [
            LOCATION_FILTERS.BUILDING,
            LOCATION_FILTERS.FLOOR,
            LOCATION_FILTERS.SPACE,
        ];
    }

    if (floors.size === 1) {
        return [LOCATION_FILTERS.SPACE];
    }

    return [LOCATION_FILTERS.FLOOR, LOCATION_FILTERS.SPACE];
};

export const prepareConfigurationPayload = (
    values: WidgetSettings,
): LiveIndoorAirQualityWidgetConfiguration => {
    const enabledLocationFilters = getEnabledLocationFilters(values.locations);

    const isLocationTypeValid = enabledLocationFilters.includes(values.floorplan_type);

    const locationType = isLocationTypeValid
        ? values.floorplan_type
        : enabledLocationFilters[0] || LOCATION_FILTERS.BUILDING;

    return {
        calculators: {
            live_indoor_air_quality: {
                ...values,
                floorplan_type: locationType,
            },
        },
    };
};

export const validateConfiguration = (settings: WidgetSettings): boolean => {
    return settings.locations !== NO_LOCATIONS_SELECTED;
};
