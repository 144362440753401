import {
    SENSOR_MODEL_TYPE,
    LEGIONNAIRE_SUB_TYPES,
    OBJECT_PRESENT_STATE,
    OCCUPIED_STATE,
    SENSOR_STATES,
    SENSOR_TYPE,
    WATER_PRESENT_STATE,
} from '@infogrid/sensors-constants';
import PropTypes from 'prop-types';

export const SensorType = PropTypes.oneOf(Object.values(SENSOR_TYPE));
export const SensorTypeOptionsShape = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
    }),
);

const SensorEventBase = {
    event_id: PropTypes.string.isRequired,
    event_type: PropTypes.string,
    timestamp: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
};

export const SensorTouchEventShape = PropTypes.shape(SensorEventBase);
export const SensorTemperatureEventShape = PropTypes.shape({
    ...SensorEventBase,

    temperature: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
});

export const SensorHumidityEventShape = PropTypes.shape({
    ...SensorEventBase,

    temperature: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    humidity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
});

export const SensorDeskOccupancyEventShape = PropTypes.shape({
    ...SensorEventBase,

    state: PropTypes.oneOf(Object.keys(OCCUPIED_STATE)).isRequired,
});

export const SensorObjectPresentEventShape = PropTypes.shape({
    ...SensorEventBase,

    state: PropTypes.oneOf(Object.keys(OBJECT_PRESENT_STATE)).isRequired,
    open: PropTypes.bool.isRequired,
});

export const SensorWaterPresentEventShape = PropTypes.shape({
    ...SensorEventBase,

    state: PropTypes.oneOf(Object.keys(WATER_PRESENT_STATE)).isRequired,
    open: PropTypes.bool.isRequired,
});

export const SensorCloudConnectorInfo = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.string.isRequired,
        signalStrength: PropTypes.number.isRequired,
    }),
);

export const SensorCloudConnectorDetail = PropTypes.shape({
    device_name: PropTypes.string.isRequired,
    name: PropTypes.string,
    signal_strength: PropTypes.number.isRequired,
    permissions: PropTypes.string,
});

export const ConnectorEthernetStatusEventShape = PropTypes.shape({
    ...SensorEventBase,

    mac_address: PropTypes.string,
    ip_address: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
});

export const AirQualityEventShape = PropTypes.shape({
    ...SensorEventBase,
    vendor: PropTypes.string.isRequired, // TODO don't know why this field ends up in here
    device_name: PropTypes.string.isRequired, // TODO don't know why this field ends up in here
    temperature: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    humidity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    co2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // Newer events have "voc", older ones "tvoc"
    tvoc: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    voc: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pressure: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pm1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pm10: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    pm25: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    radonShortTermAvg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    light: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    batteryPercentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    rssi: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
});

export const SensorEventShape = PropTypes.oneOfType([
    SensorTouchEventShape,
    SensorTemperatureEventShape,
    SensorHumidityEventShape,
    SensorDeskOccupancyEventShape,
    SensorObjectPresentEventShape,
    SensorWaterPresentEventShape,
    ConnectorEthernetStatusEventShape,
    AirQualityEventShape,
]);

export const SensorProfileShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    uuid: PropTypes.string,
});

export const SensorSubTypesShape = PropTypes.shape({
    generic: PropTypes.string.isRequired,
    cold: PropTypes.string.isRequired,
    coldStorage: PropTypes.string.isRequired,
    hot: PropTypes.string.isRequired,
    hotPouHeater: PropTypes.string.isRequired,
    blended: PropTypes.string.isRequired,
    calorifierFlow: PropTypes.string.isRequired,
    calorifierReturn: PropTypes.string.isRequired,
});

export const SensorConfigurationShape = PropTypes.shape({
    // Note: This is added by us as an id field
    device_name: PropTypes.string,

    electricity_sensor_model_type: PropTypes.oneOf(Object.values(SENSOR_MODEL_TYPE)),
    // TODO: switch string for sensor type when sensor configuration set up for EML sensors
    // sensor_configuration_type: PropTypes.oneOf(Object.values(SENSOR_TYPE)),
    sensor_configuration_type: PropTypes.string,
    temperature_offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sub_types_options: SensorSubTypesShape,
    sub_type: PropTypes.string,
    reset_time: PropTypes.string,
    reset_time_zone: PropTypes.string,
});

export const SensorLastReading = PropTypes.oneOfType([
    SensorTouchEventShape,
    SensorTemperatureEventShape,
    SensorHumidityEventShape,
    SensorObjectPresentEventShape,
    SensorWaterPresentEventShape,
    SensorDeskOccupancyEventShape,
]);

export const SimpleAlertConfigurationShape = PropTypes.shape({
    lower_threshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    upper_threshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    threshold_enabled: PropTypes.bool.isRequired,
    reading_types: PropTypes.arrayOf(PropTypes.string),
    reading_type: PropTypes.string,
});

export const SensorStateDetailShape = PropTypes.shape({
    alert_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
});

export const sensorShapeConfig = {
    alert_configuration: SimpleAlertConfigurationShape,
    breadcrumbs: PropTypes.arrayOf(PropTypes.object),
    can_edit: PropTypes.bool,
    current_user_permission: PropTypes.string,
    device_id: PropTypes.string,
    device_name: PropTypes.string.isRequired,
    device_ids: PropTypes.arrayOf(
        PropTypes.shape({
            vendor: PropTypes.string,
            sensor_name: PropTypes.string,
            vendor_device_id: PropTypes.string,
        }),
    ),
    id: PropTypes.number.isRequired,
    is_mapped_to_floor: PropTypes.bool.isRequired,
    kit: PropTypes.string,
    labels: PropTypes.object,
    latest_events_v2: PropTypes.object,
    floorplan_location: PropTypes.shape({
        building_id: PropTypes.number,
        building_name: PropTypes.string,
        floor_id: PropTypes.number,
        floor_name: PropTypes.string,
    }),
    profile: SensorProfileShape.isRequired,
    reading_types: PropTypes.arrayOf(PropTypes.string),
    sensor_configuration: SensorConfigurationShape,
    sub_type: PropTypes.oneOf(Object.values(LEGIONNAIRE_SUB_TYPES).concat([''])),
    sub_type_display: PropTypes.string,
    type: PropTypes.string.isRequired,
    type_code: PropTypes.string.isRequired,
    unacknowledged_alert_count: PropTypes.number,
    update_time: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    vendor: PropTypes.string.isRequired,
    vendor_info: PropTypes.object,
    sensor_state: PropTypes.oneOf(Object.values(SENSOR_STATES).concat([''])),
    sensor_state_detail: PropTypes.arrayOf(SensorStateDetailShape),
    smart_cleaning_category: PropTypes.string,
};

export const SensorShape = PropTypes.shape(sensorShapeConfig);

export const segmentSensorShapeConfig = {
    id: PropTypes.number.isRequired,
    device_name: PropTypes.string.isRequired,
    device_id: PropTypes.string,
    type: PropTypes.string.isRequired,
    type_code: PropTypes.string.isRequired,
    vendor: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
};

export const SegmentSensorShape = PropTypes.shape(segmentSensorShapeConfig);
