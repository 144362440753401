import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import CubicalOccupancyStoplightPreview from 'styles/images/widgets/cubical-occupancy-stoplight.png';

import CubicleOccupancyStoplightWidget from './CubicleOccupancyStoplightWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('Cubicle Occupancy Stoplight', { ns: 'dashboard' }),
    type: WIDGET_TYPE.CUBICLE_OCCUPANCY_STOPLIGHT,
    featureFlags: [],
    Component: CubicleOccupancyStoplightWidget,
    image: CubicalOccupancyStoplightPreview,
    order: 4,
    gridConfig: DEFAULT_GRID_ITEM_CONFIG,
};

export default CubicleOccupancyStoplightWidget;
