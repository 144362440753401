import { Slider } from '@material-ui/core';
import type { ChangeEvent } from 'react';
import { memo } from 'react';

import { useControlsStyles } from './styles';

interface Props {
    scale: number;
    changeScale: (scale: number) => void;
}

const FloorPlanControls = ({ scale, changeScale }: Props) => {
    const styles = useControlsStyles();

    return (
        <div className={styles.mapCustomControlsContainer}>
            <div className={styles.controlsWrapper}>
                <Slider
                    className={styles.sensorIconSizeSlider}
                    value={scale}
                    data-cypress="sensor-size-slider"
                    data-testid="icon-scale-control"
                    onChange={(
                        _: ChangeEvent<Record<string, never>>,
                        newValue: number | number[],
                    ) => {
                        changeScale(newValue as number);
                    }}
                    min={0.3}
                    max={2}
                    step={0.1}
                />
            </div>
        </div>
    );
};

export default memo(FloorPlanControls);
