import type { FrequencyValue } from '@infogrid/utils-dates';
import type { QueryKey } from 'react-query';

import { SENSOR_CHART, REMOTE_MONITORING_ERROR_EQUATION } from 'apiHooks/base/queryKeys';
import type { RemoteMonitoringParams } from 'utils/types/ts/charts';

export interface SensorDataQueryParams {
    time_period_from?: string | null;
    time_period_to?: string | null;
    data_frequency?: FrequencyValue | null;
    event_types?: string[];
}

export const getSensorSidecardChartKey = (
    id: string,
    query: SensorDataQueryParams,
): QueryKey => [SENSOR_CHART, id, query];

export const getRemoteMonitoringErrorBoundsKey = (
    query: RemoteMonitoringParams,
): QueryKey => [REMOTE_MONITORING_ERROR_EQUATION, query];
