import { fork } from 'redux-saga/effects';

import socketWorker from 'sagas/socket';
import socketRouter from 'sagas/socketRouter';
import SETTINGS from 'settings';

export default function* SocketManager() {
    const host = SETTINGS.BACKEND_SITE_URL.replace('http://', 'ws://').replace(
        'https://',
        'wss://',
    );

    yield fork(socketWorker, `${host}${SETTINGS.SOCKET_URL}`);
    yield fork(socketRouter);
}
