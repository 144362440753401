export const sensorHasCoordinates = <
    T extends {
        coordinates?: { x: number; y: number };
    },
>(
    sensor: T,
): sensor is T & {
    coordinates: { x: number; y: number };
} => {
    if (!('coordinates' in sensor)) {
        return false;
    }

    const { coordinates } = sensor;

    return !!coordinates && !(Number.isNaN(coordinates.x) && Number.isNaN(coordinates.y));
};
