import { Select, Translate } from 'ol/interaction';
import PropTypes from 'prop-types';
import { useContext, useEffect, memo } from 'react';

import MapContext from './MapContext';

const TranslateInteraction = ({ selectOptions, onCoordinateChange, selectListeners }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) {
            return () => {};
        }

        const translateSensorSelect = new Select(selectOptions);

        const selectEvents = Object.keys(selectListeners);

        selectEvents.forEach((eventName) => {
            translateSensorSelect.on(eventName, selectListeners[eventName]);
        });

        map.addInteraction(translateSensorSelect);

        const translateSensor = new Translate({
            features: translateSensorSelect.getFeatures(),
        });

        map.addInteraction(translateSensor);

        const onChangePosition = (event) => {
            if (event.features.getLength()) {
                const ids = event.features.getArray().map((x) => x.getId());

                onCoordinateChange(ids, event.coordinate);
            }
        };

        translateSensor.on('translateend', onChangePosition);

        return () => {
            selectEvents.forEach((eventName) => {
                translateSensorSelect.un(eventName, selectListeners[eventName]);
            });

            translateSensor.un('translateend', onChangePosition);

            map.removeInteraction(translateSensorSelect);
            map.removeInteraction(translateSensor);
        };
    }, [map, onCoordinateChange, selectOptions, selectListeners]);

    return null;
};

TranslateInteraction.propTypes = {
    selectOptions: PropTypes.shape({}),
    onCoordinateChange: PropTypes.func.isRequired,
    selectListeners: PropTypes.shape({}),
};

TranslateInteraction.defaultProps = {
    selectListeners: {},
    selectOptions: {},
};

export default memo(TranslateInteraction);
