import { routesManager } from '@infogrid/core-routing';
import { createFetchAction, createFetchSaga } from '@thorgate/spa-entities';
import { takeLatestWithMatch } from '@thorgate/spa-view-manager';

import { teamsSchema } from 'schemas/teams';
import api from 'services/api';
import { serializeData } from 'utils/serializeData';

export const fetchTeam = createFetchAction('sagas/account/FETCH_TEAM');

export const fetchTeamWorkerBase = createFetchSaga({
    key: teamsSchema.key,
    listSchema: [teamsSchema],
    resource: api.organization.team.detail,
    serializeData,

    useDetails: true,

    mutateKwargs: ({ params }, action) => ({
        ...action.payload.kwargs,
        pk: params.teamId,
    }),
});

export const fetchTeamWorker = fetchTeamWorkerBase.asInitialWorker(({ params }) =>
    fetchTeam({ kwargs: { pk: params.teamId } }),
);

export default function* fetchTeamWatcher() {
    yield takeLatestWithMatch(
        fetchTeam.getType(),
        routesManager.resolvePattern('organization:team-detail'),
        fetchTeamWorkerBase,
    );
}
