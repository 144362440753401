import type { LocationFilter } from '@infogrid/core-types';
import { LOCATION_FILTERS } from '@infogrid/core-types';
import { Typography } from '@material-ui/core';
import { memo } from 'react';

import { useLocationsLabel } from 'utils/hooks';
import { Widget } from 'views/dashboards/components/Widget';

interface Props {
    type: LocationFilter;
    count: number;
}

const getLocationIconClass = (type: LocationFilter): string => {
    switch (type) {
        case LOCATION_FILTERS.BUILDING:
            return 'fal fa-building';
        case LOCATION_FILTERS.FLOOR:
            return 'fal fa-layer-group';
        case LOCATION_FILTERS.SPACE:
            return 'fal fa-cube';
        default:
            return '';
    }
};

const Locations = ({ type, count }: Props) => {
    const locationsLabel = useLocationsLabel(type, count);

    return (
        <Widget.Options>
            <Widget.Option>
                <i className={getLocationIconClass(type)} data-testid="location-icon" />
                <Typography variant="caption">{locationsLabel}</Typography>
            </Widget.Option>
        </Widget.Options>
    );
};

export default memo(Locations);
