import { makeStyles } from '@material-ui/core';

export const useStylesUploadCardContent = makeStyles((theme) => ({
    card: {
        width: '100%',
        height: '100%',
    },

    uploadCardContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },

    uploadIconContainer: {
        margin: 'auto',
        opacity: '0.4',
    },

    progressBarContainer: {
        marginBottom: '35px',
        lineHeight: '1.1em',
        textAlign: 'center',
    },

    errorIconContainer: {
        marginBottom: '26px',
        textAlign: 'center',
    },

    progressBar: {
        marginBottom: '16px',
    },

    errorCardContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },

    uploadIcon: {
        fontSize: '82px',
        lineHeight: '1.1em',
        color: theme.palette.text.grey.default,
        textAlign: 'center',
    },

    uploadDescriptionContainer: {
        marginTop: theme.spacing(1),
        textAlign: 'center',

        '& > $greySubtitleText': {
            textTransform: 'capitalize',
        },
    },

    errorIcon: {
        width: '1.12em',
        height: '1.12em',
        fontSize: '49px',
        lineHeight: '1.2em',
        color: '#EF6C00',
    },

    greySubtitleText: {
        color: theme.palette.text.grey.default,
    },
}));
