import { Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { useTreeNodeContentStyles } from './styles';

const FOLDER_OPENED_ICON = 'fas fa-folder-open';
const FOLDER_FILLED_ICON = 'fas fa-folder';
const FOLDER_EMPTY_ICON = 'far fa-folder';

const TreeNodeContent = (props) => {
    const { isExpanded, isSelected, nodeContentLength, nodeIcon, nodeName } = props;
    const treeNodeStyles = useTreeNodeContentStyles({ isSelected });

    return (
        <div className={treeNodeStyles.treeItemContent}>
            <Icon
                className={classNames(
                    nodeIcon || {
                        [FOLDER_OPENED_ICON]: isExpanded,
                        [FOLDER_FILLED_ICON]: !isExpanded && nodeContentLength,
                        [FOLDER_EMPTY_ICON]: !isExpanded && !nodeContentLength,
                    },
                    treeNodeStyles.treeItemIcon,
                )}
            />

            <Typography
                variant="subtitle1"
                component="span"
                data-cypress="folder"
                className={treeNodeStyles.treeItemName}
            >
                {nodeName} ({nodeContentLength})
            </Typography>

            <div className={treeNodeStyles.treeItemToggle} />
        </div>
    );
};

TreeNodeContent.propTypes = {
    isExpanded: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    nodeIcon: PropTypes.string,
    nodeName: PropTypes.string.isRequired,
    nodeContentLength: PropTypes.number,
};

TreeNodeContent.defaultProps = {
    nodeIcon: '',
    nodeContentLength: 0,
};

export default memo(TreeNodeContent);
