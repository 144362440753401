import { THRESHOLD_TYPE } from '@infogrid/remote-monitoring-types';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import type { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { useField } from 'formik';
import type { FormikProps } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { ThresholdValues } from 'utils/types/ts/threshold';

import ThresholdInput from './ThresholdInput';
import { useThresholdCardStyles } from './styles';

export const THRESHOLD_COMPARISONS = Object.freeze({
    LESS_THAN: 'less_than',
    MORE_THAN: 'more_than',
} as const);

interface ThresholdSingleInputProps {
    formik: FormikProps<ThresholdValues>;
    keyInForm: string;
    unitSymbol: string;
}

const ThresholdSingleInput = ({
    formik,
    keyInForm,
    unitSymbol,
}: ThresholdSingleInputProps): JSX.Element => {
    const { t } = useTranslation('solutions');
    const styles = useThresholdCardStyles();

    const [lowerField, lowerMeta, lowerHelper] = useField({
        name: `${keyInForm}.lower`,
    });

    const [upperField, upperMeta, upperHelper] = useField({
        name: `${keyInForm}.upper`,
    });

    const [thresholdTypeField, _0, thresholdTypeHelper] = useField({
        name: `${keyInForm}.thresholdType`,
    });

    const onSingleThresholdTypeChange = useCallback<
        NonNullable<SelectInputProps['onChange']>
    >(
        (event) => {
            const thresholdType = event.target.value as string;

            thresholdTypeHelper.setValue(thresholdType);

            if (thresholdType === THRESHOLD_COMPARISONS.LESS_THAN) {
                const nextValue = lowerField.value;

                lowerHelper.setValue(null);
                upperHelper.setValue(nextValue);
            }

            if (thresholdType === THRESHOLD_COMPARISONS.MORE_THAN) {
                const nextValue = upperField.value;

                upperHelper.setValue(null);
                lowerHelper.setValue(nextValue);
            }
        },
        [lowerHelper, upperHelper, lowerField, upperField, thresholdTypeHelper],
    );

    const isLessThanSingleThresholdType =
        thresholdTypeField.value === THRESHOLD_TYPE.SINGLE_LESS_THAN;

    return (
        <>
            <Grid item>
                <FormControl variant="outlined" className={styles.shouldBe}>
                    <InputLabel id="should-be-label">{t('Should be')}</InputLabel>
                    <Select
                        labelId="should-be-label"
                        data-cypress="select-single-threshold-type"
                        id="should-be"
                        value={thresholdTypeField.value}
                        onChange={onSingleThresholdTypeChange}
                        label={t('Should be')}
                    >
                        <MenuItem
                            value={THRESHOLD_TYPE.SINGLE_LESS_THAN}
                            data-cypress="threshold-type-single-less-than"
                        >
                            {t('Less than')}
                        </MenuItem>
                        <MenuItem
                            value={THRESHOLD_TYPE.SINGLE_MORE_THAN}
                            data-cypress="threshold-type-single-more-than"
                        >
                            {t('More than')}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <ThresholdInput
                id={isLessThanSingleThresholdType ? 'upper' : 'lower'}
                cypressId="temperature-thresholds"
                label={t('Temperature')}
                field={isLessThanSingleThresholdType ? upperField : lowerField}
                meta={isLessThanSingleThresholdType ? upperMeta : lowerMeta}
                hasError={!!formik.errors}
                unitSymbol={unitSymbol}
            />
        </>
    );
};

export default ThresholdSingleInput;
