import { fade, makeStyles } from '@material-ui/core';

export const useDesktopIntegrationsSidebarStyles = makeStyles((theme) => ({
    heading: {
        color: theme.palette.common.gray6,
        marginTop: theme.spacing(2),
        textTransform: 'uppercase',
    },
    integrationItem: {
        display: 'flex',
        width: '280px',
        padding: '14px 26px 14px 9px',

        color: theme.palette.common.black,
        opacity: 0.7,

        textDecoration: 'none',
        fontWeight: 'bold',

        overflow: 'hidden',

        '&:hover': {
            backgroundColor: fade(theme.palette.common.gray6, 0.2),
            color: theme.palette.common.black,
            textDecoration: 'none',
        },
        '&:focus': {
            backgroundColor: fade(theme.palette.primary.main, 0.2),
            color: theme.palette.common.black,
            textDecoration: 'none',
            outline: 'none',
        },
    },
    integrationItemActive: {
        backgroundColor: fade(theme.palette.primary.main, 0.2),
    },
    text: {
        marginLeft: '10px',
        fontSize: '14px',
        userSelect: 'none',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    noMargins: {
        margin: '0px',
        padding: '0px',
    },
    backButton: {
        padding: theme.spacing(1.5, 2),
        marginBottom: 0,
    },
    dividerLine: {
        margin: theme.spacing(0, 2),
    },
}));
