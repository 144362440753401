import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

import LeftCard from './LeftCard';
import RightCard from './RightCard';
import { useOccupancyStoplightChart } from './styles';

const OccupancyStoplightChart = ({
    configuration,
    nearCapacity,
    outOfCapacity,
    layout,
}) => {
    const styles = useOccupancyStoplightChart();

    const gridClasses = useMemo(
        () => ({
            root: styles.container,
        }),
        [styles.container],
    );

    if (!configuration?.configured) {
        return <div className={styles.container} />;
    }

    return (
        <Grid classes={gridClasses} container>
            <Grid item xs={6}>
                <LeftCard
                    configuration={configuration}
                    nearCapacity={nearCapacity}
                    outOfCapacity={outOfCapacity}
                    layout={layout}
                />
            </Grid>
            <Grid item xs={6}>
                <RightCard
                    nearCapacity={nearCapacity}
                    outOfCapacity={outOfCapacity}
                    layout={layout}
                    stoplight={configuration.stoplight}
                />
            </Grid>
        </Grid>
    );
};

OccupancyStoplightChart.propTypes = {
    configuration: PropTypes.shape({
        id: PropTypes.number.isRequired,
        configured: PropTypes.bool.isRequired,
        occupancyText: PropTypes.string.isRequired,
        titleText: PropTypes.string.isRequired,
    }).isRequired,
    nearCapacity: PropTypes.bool.isRequired,
    outOfCapacity: PropTypes.bool.isRequired,
    layout: PropTypes.shape({}).isRequired,
};

export default memo(OccupancyStoplightChart);
