import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';

export const useTextField = (
    defaultValue = '',
): [string, (e: ChangeEvent<HTMLInputElement>) => void, () => void] => {
    const [value, setValue] = useState(defaultValue);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value);
    }, []);

    const clear = useCallback(() => {
        setValue('');
    }, []);

    return [value, handleChange, clear];
};
