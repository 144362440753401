import { useElectricityEndUses } from '@infogrid/electricity-components';
import { memo } from 'react';

import EndUsePicker from './EndUsePicker';

export interface EndUsesPickerProps {
    value: string | undefined;
    onChange: (newValue: string) => void;
    errorMessage?: string;
    className?: string;
    helperText?: string;
}

const EndUsePickerContainer = ({
    value,
    onChange,
    className,
    errorMessage,
    helperText,
}: EndUsesPickerProps) => {
    const { data: endUses, isLoading: isLoadingEndUses } = useElectricityEndUses();

    return (
        <EndUsePicker
            value={value}
            className={className}
            endUses={endUses}
            isLoadingEndUses={isLoadingEndUses}
            onChange={onChange}
            errorMessage={errorMessage}
            helperText={helperText}
        />
    );
};

export default memo(EndUsePickerContainer);
