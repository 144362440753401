export const SENSOR_SORT_NAMES = {
    TYPE: 'type',
    SENSOR_STATE: 'sensor_state',
    UNACKNOWLEDGED_COUNT: 'unacknowledged_count',
    NAME: 'name',
    LOCATION: 'location',
    LAST_READING: 'last_reading',
    SIGNAL_STRENGTH: 'signal_strength',
} as const;

export const SENSOR_BASE_FILTER_NAMES = {
    QUERY: 'q',
    TYPE: 'sensor_type',
    LABELS: 'sensor_labels',
    LOCATION: 'location',
} as const;

export const SENSOR_FILTERS = {
    ...SENSOR_BASE_FILTER_NAMES,
    FAILED_FOR_PIPE_WIDGET_ID: 'failed_for_pipe_widget_id',
    FAILED_TEMP_FOR_PIPE_WIDGET_ID: 'failed_temp_for_pipe_widget_id',
    FAILED_WATER_FOR_PIPE_WIDGET_ID: 'failed_water_for_pipe_widget_id',
    SENSORS_FOR_PIPE_WIDGET_ID: 'sensors_for_pipe_widget_id',
    IAQ_WIDGET_ID: 'iaq_widget_id',
    IAQ_RATING: 'iaq_rating',
    IAQ_TIMESTAMP: 'iaq_timestamp',
    IAQ_AIR_TYPE: 'iaq_air_type',
    SUB_TYPE: 'sub_type',
    LABELS_OR: 'sensor_labels_or',
} as const;

export const SENSOR_PARAM_NAMES = {
    ...SENSOR_FILTERS,
    RECURSIVE: 'recursive',
    ORDER_BY: 'order_by',
    PAGE: 'page',
} as const;

export const SENSOR_LIST_ACTION_FILTERS = {
    ...SENSOR_BASE_FILTER_NAMES,
    FOLDER: 'folder',
    RECURSIVE: SENSOR_PARAM_NAMES.RECURSIVE,
    SENSORS: 'sensors',
    EXCLUDE_SENSORS: 'exclude_sensors',
} as const;

export const SENSOR_PARAMS_SET = new Set(Object.values(SENSOR_PARAM_NAMES));
