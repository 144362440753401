import { calculateTimeRange } from '@infogrid/utils-dates';
import { EntitiesResource } from '@thorgate/spa-entities';

export const TimeRangeNames = {
    SensorEventsDownload: 'sensorEventsDownload',
    SensorEvents: 'sensorEvents',
    EventLog: 'eventLog',
    EventLogDownload: 'eventLogDownload',
    AlertLog: 'alertLog',
};

export const SET_TIME_RANGE = '@@ducks/timeRange/set';

export const setTimeRange = (
    name,
    range,
    from = null,
    to = null,
    triggerIgnore = false,
    fullDayCustom = true,
) => ({
    type: SET_TIME_RANGE,
    name,
    range,
    to,
    from,
    triggerIgnore,
    fullDayCustom,
    resourceType: EntitiesResource,
});

export const timeRangeActionFilter = (name) => (action) =>
    action.type === SET_TIME_RANGE && action.name === name && !action.triggerIgnore;

export default function reducer(state = {}, action) {
    switch (action.type) {
        case SET_TIME_RANGE:
            return {
                ...state,

                [action.name]: calculateTimeRange(action),
            };

        default:
            return state;
    }
}

export const selectTimeRange = (state, name, defaultRange = {}) =>
    state.timeRange[name] || calculateTimeRange(defaultRange);
