import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import { useTranslation } from 'react-i18next';

import { setFloors, setFloorDetail, invalidateFloorDetail } from '../accessors';
import { controllers } from '../controllers';

export const useEditFloor = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    return useMutationWithToast(
        ({ floorId, data }) => wrapResponsePromise(controllers.editFloor(floorId, data)),
        {
            onSuccess: (editedFloor, { buildingId, floorId }) => {
                setFloorDetail(floorId, editedFloor);
                setFloors(buildingId, (floors) =>
                    floors?.map((floor) => (floor.id === floorId ? editedFloor : floor)),
                );

                invalidateFloorDetail(floorId);

                dispatch(
                    toastSuccess({
                        message: t('Floor updated successfully'),
                    }),
                );
            },
            onError(err) {
                const errors = Object.values(err.parsedError.errors).join(' ');

                dispatch(
                    toastError({
                        message: `${t('Error while updating floor:')} ${errors}`,
                    }),
                );
            },
        },
        {
            toastMessage: t('Updating floor is in progress'),
        },
    );
};
