export const STATE_KEY = 'folders';

const SET_CREATE_DIALOG_OPEN = `${STATE_KEY}/SET_CREATE_DIALOG_OPEN`;
const SET_PERMISSIONS_DIALOG_OPEN = `${STATE_KEY}/SET_PERMISSIONS_DIALOG_OPEN`;

const initialState = {
    createDialogOpen: false,
    permissionsDialogOpen: false,
};

export default function foldersReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CREATE_DIALOG_OPEN:
            return {
                ...state,
                createDialogOpen: action.createDialogOpen,
            };
        case SET_PERMISSIONS_DIALOG_OPEN:
            return {
                ...state,
                permissionsDialogOpen: action.permissionsDialogOpen,
            };

        default:
            return state;
    }
}

export const openCreateDialog = () => ({
    type: SET_CREATE_DIALOG_OPEN,
    createDialogOpen: true,
});
export const closeCreateDialog = () => ({
    type: SET_CREATE_DIALOG_OPEN,
    createDialogOpen: false,
});

export const openPermissionsDialog = () => ({
    type: SET_PERMISSIONS_DIALOG_OPEN,
    permissionsDialogOpen: true,
});
export const closePermissionsDialog = () => ({
    type: SET_PERMISSIONS_DIALOG_OPEN,
    permissionsDialogOpen: false,
});

export const selectors = {
    createDialogOpen: (state) => state[STATE_KEY].createDialogOpen,
    permissionsDialogOpen: (state) => state[STATE_KEY].permissionsDialogOpen,
};
