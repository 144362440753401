import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { SensorVendor, SensorShape } from '@infogrid/sensors-constants';
import { useTranslation } from 'react-i18next';
import type { UseMutationOptions, UseMutationResult } from 'react-query';

import { controllers } from '../controllers';

export interface RegisterQueryParams {
    qr_code?: string;
    vendor?: SensorVendor;
    serial_number?: string;
    airthings_device_id?: string;
}

export interface RegisterErrorResponse {
    error: string;
    code: string;
}

export const useRegisterSensor = (
    options: UseMutationOptions<
        SensorShape,
        AxiosParsedError<RegisterErrorResponse>,
        RegisterQueryParams
    >,
): UseMutationResult<
    SensorShape,
    AxiosParsedError<RegisterErrorResponse>,
    RegisterQueryParams
> => {
    const { t } = useTranslation('sensors');

    return useMutationWithToast<
        SensorShape,
        AxiosParsedError<RegisterErrorResponse>,
        RegisterQueryParams
    >(
        (data) => wrapResponsePromise<SensorShape>(controllers.registerSensor({ data })),
        options,
        { toastMessage: t('Registering sensor is in progress') },
    );
};
