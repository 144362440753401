import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import isString from 'lodash/isString';
import type * as React from 'react';
import type { CellMeasurerCache, GridCellProps } from 'react-virtualized';
import { CellMeasurer } from 'react-virtualized';

export const TableHeaderCell = ({
    key,
    parent,
    rowIndex,
    columnIndex,
    style,
}: GridCellProps): React.ReactNode => {
    const { columns, classes, getCellClasses, deferredMeasurementCache, columnWidth } =
        parent.props;
    const { renderHeader } = columns[columnIndex];
    const width = columnWidth({ index: columnIndex });

    const cellClasses = getCellClasses({ rowIndex, columnIndex }, true);

    const content = renderHeader ? renderHeader() : '';
    const isStringContent = isString(content);

    return (
        <CellMeasurer
            cache={deferredMeasurementCache as CellMeasurerCache}
            columnIndex={columnIndex}
            key={key}
            parent={parent}
            rowIndex={rowIndex}
        >
            <Typography
                component="div"
                variant="caption"
                style={{ ...style, width }}
                className={classNames(
                    classes.cellWrapper,
                    classes.headerCell,
                    'ReactVirtualized__Table__rowColumn__wrapper',
                )}
            >
                <div className={cellClasses}>
                    {isStringContent ? (
                        <div title={content as string} className={classes.stringCell}>
                            {content}
                        </div>
                    ) : (
                        content
                    )}
                </div>
            </Typography>
        </CellMeasurer>
    );
};

export default TableHeaderCell;
