import { Button, Typography } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUnassignedSensorsBannerStyles } from './styles';

interface Props {
    isSidebarOpen: boolean;
    sensorsCount: number;
    toggleSidebar: () => void;
}

const UnassignedSensorsBanner = ({
    isSidebarOpen,
    sensorsCount,
    toggleSidebar,
}: Props) => {
    const styles = useUnassignedSensorsBannerStyles();

    const { t } = useTranslation('estate');

    return (
        <div className={styles.container}>
            <div className={styles.subcontainer}>
                <ReportProblemOutlinedIcon className={styles.warningIcon} />
                <Typography data-cypress="unassigned-sensors-text" variant="body2">
                    {t('{{count}} sensors have not been assigned a space.', {
                        count: sensorsCount,
                        defaultValue___one: `${sensorsCount} sensor has not been assigned a space.`,
                        defaultValue___other: `${sensorsCount} sensors have not been assigned a space.`,
                    })}
                </Typography>
            </div>
            <Button
                className={styles.viewSensorsButton}
                color="primary"
                data-cypress="open-unassigned-sensors-panel"
                onClick={toggleSidebar}
            >
                {isSidebarOpen ? t('Hide sensors') : t('View sensors')}
            </Button>
        </div>
    );
};

export default memo(UnassignedSensorsBanner);
