import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import { useTranslation } from 'react-i18next';

import WidgetAlert from '../../common/WidgetAlert';
import type { MeetingRoomOccupancyData } from '../types';
import { Table } from './Table';

interface ContentProps {
    areNoMeetingRooms: boolean;
    data?: MeetingRoomOccupancyData;
    isConfigured: boolean;
    isLoadingDataError: boolean;
}

export const Content = ({
    areNoMeetingRooms,
    data,
    isConfigured,
    isLoadingDataError,
}: ContentProps): JSX.Element => {
    const { t } = useTranslation('dashboard');

    if (!isConfigured) {
        return (
            <WidgetAlert severity="warning">
                {t(WidgetErrorMessages.notConfigured)}
            </WidgetAlert>
        );
    }

    if (areNoMeetingRooms) {
        return (
            <WidgetAlert severity="warning">
                {t(
                    'No meeting rooms selected. Please select a meeting room containing a space occupancy sensor in the widget configuration.',
                )}
            </WidgetAlert>
        );
    }

    if (isLoadingDataError) {
        return (
            <WidgetAlert severity="error">
                {t(WidgetErrorMessages.dataLoadError)}
            </WidgetAlert>
        );
    }

    if (!data || !data.results || data.results.length === 0) {
        return <WidgetAlert severity="info">{t(WidgetErrorMessages.noData)}</WidgetAlert>;
    }

    return <Table data={data} />;
};
