import { PageError } from '@infogrid/components-material-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const NotFound = (): JSX.Element => {
    const { t } = useTranslation('common');

    return (
        <PageError
            statusCode={404}
            title={t('Page not Found')}
            description={t('You seem to be lost. Requested page was not found.')}
        >
            <Helmet>
                <title>{t('Page not Found')}</title>
            </Helmet>
        </PageError>
    );
};

export default NotFound;
