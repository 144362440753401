import { makeStyles } from '@material-ui/core';

type StyleProps = {
    active: boolean;
};

export const useWidgetPreviewStyles = makeStyles((theme) => ({
    container: ({ active }: StyleProps) => ({
        alignItems: 'center',
        border: active ? `3px solid ${theme.palette.primary.main}` : 'none',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        margin: active ? '-3px' : 0,
    }),
    image: {
        backgroundColor: '#F8F5F2',
        width: '100%',
    },
    name: {
        textAlign: 'center',
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}));
