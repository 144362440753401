import { makeStyles } from '@material-ui/core';

export const usePublicDisplayStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#162029',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    content: {
        display: 'flex',
        flexGrow: 1,
    },
    footer: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(9, 10),
    },
    leftPanel: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width: '40%',
    },
    loadingSpinner: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    },
    logo: {
        height: 44,
        marginLeft: theme.spacing(3),
    },
    logoContainer: {
        display: 'flex',
    },
    rightPanel: {
        flexGrow: 1,
        padding: theme.spacing(10, 10, 10, 0),
        width: '60%',
    },
}));
