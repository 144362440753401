import { Modal } from '@infogrid/components-material-ui';
import { useIsMobile, useEffectOnMount } from '@infogrid/utils-hooks';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { prefetchBuildingsHierarchy } from 'apiHooks/floorPlan/buildings/hooks';
import { useIsIntercomEnabledForUser } from 'utils/hooks/intercomAnalytics';
import { toggleIntercomButton } from 'utils/intercom';

const InstallationFlowModal = ({
    onClose,
    children,
    isLoading,
    classes,
    maxWidth,
    fullScreenMode,
    hideCloseButton,
}) => {
    const isMobile = useIsMobile();
    const isIntercomEnabled = useIsIntercomEnabledForUser();

    useEffectOnMount(() => {
        prefetchBuildingsHierarchy();
    });
    useEffect(() => {
        toggleIntercomButton(true);

        return () => {
            if (!isIntercomEnabled) {
                return;
            }

            // if intercom was shut down, then update won't show it
            toggleIntercomButton(false);
        };
    }, [isIntercomEnabled]);
    useEffect(() => {
        // workaround for mobile chrome to prevent refresh-on-scroll
        if (isMobile) {
            document.body.style.overscrollBehaviorY = 'contain';
            document.documentElement.style.overscrollBehaviorY = 'contain';
        }

        return () => {
            if (isMobile) {
                document.body.style.overscrollBehaviorY = 'auto';
                document.documentElement.style.overscrollBehaviorY = 'auto';
            }
        };
    }, [isMobile]);

    return (
        <Modal
            open
            onClose={onClose}
            fullScreen={fullScreenMode}
            classes={classes}
            maxWidth={maxWidth}
            hideCloseButton={hideCloseButton}
            loading={isLoading}
        >
            {children}
        </Modal>
    );
};

InstallationFlowModal.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    classes: PropTypes.shape({}),
    maxWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    fullScreenMode: PropTypes.bool,
};
InstallationFlowModal.defaultProps = {
    classes: {},
    maxWidth: 'sm',
    fullScreenMode: false,
};

export default InstallationFlowModal;
