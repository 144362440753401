import type { SelectOption } from '@infogrid/components-material-ui';
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItem,
    ListSubheader,
    MenuItem,
    Select,
} from '@material-ui/core';
import type { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { Skeleton } from '@material-ui/lab';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useEndUsePickerStyles } from './styles';

export interface EndUsesPickerProps {
    value: string | undefined;
    endUses: SelectOption[] | undefined;
    isLoadingEndUses: boolean;
    onChange: (newValue: string) => void;
    errorMessage?: string;
    className?: string;
    helperText?: string;
}

const EndUsePicker = ({
    value = '',
    endUses,
    isLoadingEndUses,
    onChange,
    className,
    errorMessage,
    helperText,
}: EndUsesPickerProps) => {
    const classes = useEndUsePickerStyles();
    const { t } = useTranslation('sensors');
    const handleChange = useCallback<NonNullable<SelectInputProps['onChange']>>(
        (event) => {
            onChange(event.target.value as string);
        },
        [onChange],
    );

    return (
        <Box className={className}>
            <FormControl error={!!errorMessage} fullWidth variant="outlined">
                <InputLabel id="end-use">{t('End use')}</InputLabel>
                <Select
                    data-cypress="end-use"
                    fullWidth
                    id="end-use"
                    label={t('End use')}
                    labelId="end-use"
                    MenuProps={{
                        anchorOrigin: {
                            horizontal: 'left',
                            vertical: 'bottom',
                        },
                        getContentAnchorEl: null,
                    }}
                    name="end-use"
                    onChange={handleChange}
                    value={value}
                    variant="outlined"
                >
                    {isLoadingEndUses || !endUses ? (
                        <ListItem>
                            <div className={classes.loadingWrapper}>
                                <Skeleton classes={{ root: classes.skeleton }} />
                                <Skeleton classes={{ root: classes.skeleton }} />
                                <Skeleton classes={{ root: classes.skeleton }} />
                            </div>
                        </ListItem>
                    ) : (
                        endUses.map((endUseGroup) => [
                            <ListSubheader className={classes.listHeader}>
                                {endUseGroup.label}
                            </ListSubheader>,
                            ...(endUseGroup.children ?? []).map((endUseItem) => (
                                <MenuItem
                                    data-cypress="end-use-item"
                                    key={endUseItem.value}
                                    value={endUseItem.value}
                                >
                                    {endUseItem.label}
                                </MenuItem>
                            )),
                        ])
                    )}
                </Select>
                {(errorMessage || helperText) && (
                    <FormHelperText error={!!errorMessage}>
                        {errorMessage ?? helperText}
                    </FormHelperText>
                )}
            </FormControl>
        </Box>
    );
};

export default memo(EndUsePicker);
