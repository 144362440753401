import { makeStyles } from '@material-ui/core';

export const useStylesBuildingDetails = makeStyles((theme) => ({
    input: {
        marginTop: '32px',
    },
    spinner: {
        color: theme.palette.common.white,
        marginRight: '10px',
    },
}));
