import { createFetchAction, createFetchSaga } from '@thorgate/spa-entities';

import { fetchGuardInitial } from 'sagas/helpers/fetchingGuard';
import { timeZoneSchema } from 'schemas/timeZones';
import api from 'services/api';
import { serializeData } from 'utils/serializeData';

export const fetchTimeZones = createFetchAction('@@sagas/account/timezones/fetch');

const fetchTimeZonesWorkerBase = createFetchSaga({
    key: timeZoneSchema.key,
    resource: api.timeZones,
    listSchema: [timeZoneSchema],
    serializeData,
});

export const fetchTimeZonesWorker = fetchGuardInitial(
    fetchTimeZonesWorkerBase.asInitialWorker(() => fetchTimeZones()),
    () => 'time-zones',
);
