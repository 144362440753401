import { useScoreGradientRange } from '@infogrid/utils-hooks';
import { memo, useRef, useEffect } from 'react';

interface Props {
    className?: string;
}

const ScoreGradient = ({ className }: Props) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const scoreGradientRange = useScoreGradientRange();

    useEffect(() => {
        if (!canvasRef?.current) {
            return;
        }

        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        if (!context) {
            return;
        }

        canvas.style.width = '100%';
        canvas.style.height = '15px';
        canvas.style.imageRendering = '-moz-crisp-edges';
        canvas.style.imageRendering = 'pixelated';

        scoreGradientRange.forEach((color: string, idx: number) => {
            context.fillStyle = color;
            context.fillRect(idx, 0, 15, 15);
        });
    }, [canvasRef, scoreGradientRange]);

    return <canvas className={className} ref={canvasRef} height={15} width="100%" />;
};

export default memo(ScoreGradient);
