import { makeStyles } from '@material-ui/core';

export const useContentStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,
    },

    riskLevelLabel: {
        marginTop: theme.spacing(1),
    },

    tutorialBtn: {
        textTransform: 'none',
        fontSize: '12px',
        lineHeight: '16px',
        textDecoration: 'underline',
        color: theme.palette.text.secondary,
        fontWeight: 'normal',
    },

    circleContainer: {
        flexGrow: 1,
        width: '100%',
        margin: theme.spacing(1, 0),
    },
}));
