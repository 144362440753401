import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import type { FloorDetail } from '@infogrid/locations-types';
import { memo, Suspense, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { useDebounce } from 'use-debounce';

import { createSensorFeatureStyle } from 'components/floorPlans';
import DefaultFloorPlanControls from 'components/floorPlans/DefaultFloorPlanControls';
import FloorMap from 'components/floorPlans/FloorMap/FloorMap';
import type { MappedSensor } from 'components/floorPlans/types';
import { prettifyCoordinate } from 'utils/math';

import FloorPlanControls from './FloorPlanControls';
import { useFloorPlanStyles } from './styles';

const MAP_STATE_TIMEOUT = 500;

const createScaleSensorFeatureStyle = createSensorFeatureStyle('default', {
    showOfflineStatus: false,
    showDefaultRAGStatus: false,
    showInstalledFilterInteractions: false,
});

type SaveConfig = ({ zoom, scale }: { zoom: number; scale: number }) => void;

interface Props {
    floor: FloorDetail;
    saveConfig: SaveConfig;
    imageUrl: string;
    imageWidth: number;
    imageHeight: number;
    zoom: number;
    sensorIconScale: number;
    sensors: MappedSensor[];
}

const FloorPlanWrapper = ({
    floor,
    saveConfig,
    imageUrl,
    imageWidth,
    imageHeight,
    zoom,
    sensorIconScale,
    sensors,
}: Props) => {
    const ref = useRef(null);
    const styles = useFloorPlanStyles();

    const { t } = useTranslation('solutions');

    const [mapState, setMapState] = useState({
        zoom,
        scale: sensorIconScale,
    });

    const [debouncedState] = useDebounce(mapState, MAP_STATE_TIMEOUT);

    useUpdateEffect(() => {
        saveConfig({
            zoom: prettifyCoordinate(debouncedState.zoom, 6) ?? 1,
            scale: debouncedState.scale,
        });
    }, [debouncedState]);

    const changeZoom = useCallback((value) => {
        setMapState((state) => ({ ...state, zoom: value }));
    }, []);

    return (
        <Suspense
            fallback={
                <LoadingPlaceholder
                    titleVariant="body1"
                    progressSize={56}
                    text={t('Loading map...')}
                />
            }
        >
            <FloorMap
                outerContainerRef={ref}
                mapClassName={styles.mapContainer}
                floor={{ ...floor, scale: mapState.scale, zoom: mapState.zoom }}
                imageUrl={imageUrl}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                onZoomChange={changeZoom}
                items={sensors}
                isEditAvailable
                isDisableZoomByScroll
                isWidget
                selectedSensorType={null}
                createFeatureStyle={createScaleSensorFeatureStyle}
                renderControls={(props) => (
                    <DefaultFloorPlanControls
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        baseControls={['ZOOM_IN', 'ZOOM_OUT']}
                        customControls={
                            <FloorPlanControls
                                scale={mapState.scale}
                                changeScale={(newScale) => {
                                    setMapState((state) => ({
                                        ...state,
                                        scale: newScale,
                                    }));
                                    props.setScale(newScale);
                                }}
                            />
                        }
                        currentZoom={mapState.zoom}
                    />
                )}
            />
        </Suspense>
    );
};

export default memo(FloorPlanWrapper);
