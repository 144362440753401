import { saveResult, saveResults } from '@thorgate/spa-entities';
import isNil from 'lodash/isNil';
import { call, all } from 'redux-saga/effects';

import {
    updateFolderName,
    cleanAllInactiveSubfolders,
    cleanAllInactiveFolders,
    invalidateAllFolders,
    invalidateAllSubfolders,
} from 'apiHooks/folders/accessors';
import { invalidateCache } from 'sagas/helpers/fetchingGuard';
import { folderSchema, relatedSubfoldersKeyFn } from 'schemas/folder';

const folderKeyRegex = new RegExp(`${folderSchema.key}?`);

export function* folderWorker({ action, folder, parent }) {
    yield call(saveResult, folderSchema.key, folder, folderSchema, {
        mergeEntities: true,
    });

    if (action === 'update' && !isNil(folder.id) && folder.name) {
        yield call(updateFolderName, folder.id, folder.name);
    }

    if (action === 'subfolder') {
        yield call(
            saveResults,
            relatedSubfoldersKeyFn({ folderId: parent }),
            [folder],
            [folderSchema],
            { updateOrder: true },
        );
    }

    // If action is not a simple update, force folders to be fetched again
    if (action !== 'update') {
        yield call(invalidateCache, folderKeyRegex);

        yield all([call(cleanAllInactiveSubfolders), call(cleanAllInactiveFolders)]);

        yield all([call(invalidateAllSubfolders), call(invalidateAllFolders)]);
    }
}
