import { getOrganizationId, saveOrganizationId } from '@infogrid/user-cookies';
import { setUser, resetUser, getUser } from '@infogrid/user-ducks';
import { MOBILE_BREAKPOINT } from '@infogrid/utils-constants';
import { i18n } from '@infogrid/utils-i18n';
import * as Sentry from '@sentry/react';
import find from 'lodash/find';
import get from 'lodash/get';
import { call, put, select } from 'redux-saga/effects';

import { AUTHENTICATION_TYPE, setAuthenticationState } from 'ducks/ui';
import { fetchGuardInitial } from 'sagas/helpers/fetchingGuard';
import api from 'services/api';
import SETTINGS from 'settings';
import {
    isIntercomAvailable,
    isIntercomAvailableForUser,
    toggleIntercomButton,
} from 'utils/intercom';

export const getInitialOrganizationId = (user) => {
    const organizationId = getOrganizationId();

    if (
        organizationId &&
        find(user.organizational_roles, { id: parseInt(organizationId) }) // Ensure the user has access to the saved org
    ) {
        return parseInt(organizationId);
    }

    return get(user, 'organizational_roles[0].id', undefined);
};

export default fetchGuardInitial(
    function* fetchUserDetails() {
        try {
            const user = yield api.user.details.fetch();
            const authenticated = !!(user && user.id);

            const organizationId = getInitialOrganizationId(user);

            const enrichedUser = {
                ...user,
                // To avoid refactoring lots of user logic, we manually add active_organization_id here
                active_organization_id: organizationId,
            };

            yield put(setUser({ user: enrichedUser, isAuthenticated: authenticated }));

            if (authenticated) {
                Sentry.setUser({ id: user.id, email: user.email });

                if (!user?.organizational_roles?.length) {
                    Sentry.captureMessage(
                        `User doesn't have access to any organizations. This shouldn't happen.`,
                    );
                } else {
                    saveOrganizationId(organizationId);
                }

                yield put(setAuthenticationState(AUTHENTICATION_TYPE.AUTHENTICATED));

                /*
                    By using the VERBOSE environment variable we can determine which env is being used.
                    Production - VERBOSE is false
                    Dev/QA - VERBOSE is true
                */
                const isVerbose = String(SETTINGS.VERBOSE).toLowerCase() === 'true';
                const domain = window.location.host;
                // We can't send plain orgId to Intercom because there's no distinction
                // between dev/QA/prod and orgId-s have to be unique and cannot be changed.
                // So we're prefixing orgIds with env domain unless prod.
                const intercomOrgId = `${isVerbose ? domain + '-' : ''}${organizationId}`;

                if (isIntercomAvailableForUser(user)) {
                    window.Intercom('boot', {
                        app_id: SETTINGS.INTERCOM_APP_ID,
                        name: user.name,
                        email: user.email,
                        created_at: user.date_joined,
                        phone: user.phone,
                        brand: 'infogrid',
                        user_hash: user.intercom_hash,
                        company: {
                            id: intercomOrgId,
                            name: find(user.organizational_roles, {
                                id: parseInt(organizationId, 10),
                            })?.name,
                        },
                    });

                    // Hide intercom on mobile (WEB-3248)
                    if (window.innerWidth < MOBILE_BREAKPOINT) {
                        toggleIntercomButton(true);
                    }
                }

                // set language of UI to lang preference from BE
                const { language_preference: langFromBackend } = user;

                if (!!langFromBackend && langFromBackend !== i18n.language) {
                    const changeLanguage = i18n.changeLanguage.bind(i18n);

                    if (i18n.isInitialized) {
                        yield call(changeLanguage, langFromBackend);
                    } else {
                        const handler = () => {
                            changeLanguage(langFromBackend);
                            i18n.off('initialized', handler);
                        };

                        i18n.on('initialized', handler);
                    }
                }
            } else {
                Sentry.setUser(null);
                yield put(setAuthenticationState(AUTHENTICATION_TYPE.UNAUTHENTICATED));

                if (isIntercomAvailable()) {
                    window.Intercom('shutdown');
                }
            }
        } catch (err) {
            Sentry.setUser(null);
            yield put(setAuthenticationState(AUTHENTICATION_TYPE.UNAUTHENTICATED));

            if (isIntercomAvailable()) {
                window.Intercom('shutdown');
            }

            yield put(resetUser());
        }
    },
    function* createKey() {
        return Boolean(yield select(getUser)) && 'spa-permissions-user';
    },
);
