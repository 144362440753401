import { routesManager } from '@infogrid/core-routing';
import type { Floor, Building } from '@infogrid/locations-types';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Box } from '@material-ui/core';
import noop from 'lodash/noop';
import { memo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import { useNavigateFloor } from 'apiHooks/floorPlan/floors/hooks';
import type { FloorPlanParams } from 'views/buildings/types';

import BuildingSwitcher from '../../domain/Building/BuildingSwitcher';
import EditBuildingMenu from '../../domain/Building/EditBuildingMenu';
import FloorSwitcher from '../../domain/Floor/FloorSwitcher/FloorSwitcher';
import { useStylesHeaderContent } from './styles';

interface HeaderContentProps {
    buildings: Building[];
    totalBuildingsCount: number;
    floors?: Floor[];
    selectedFloorId?: number;
    fetchMoreBuildings?: () => void;
}

const defaultFloors: Floor[] = [];

const HeaderContent = ({
    buildings,
    totalBuildingsCount,
    floors = defaultFloors,
    selectedFloorId,
    fetchMoreBuildings = noop,
}: HeaderContentProps) => {
    const history = useHistory();
    const { buildingId } = useParams<FloorPlanParams>();
    const { building } = useBuilding(+buildingId);
    const isMobile = useIsMobile();
    const { navigateToFloor } = useNavigateFloor();

    const styles = useStylesHeaderContent();

    const selectBuilding = useCallback(
        (value = {}) => {
            const redirectUrl = routesManager.resolvePath('buildings:building', {
                buildingId: value.id,
            });

            history.push(redirectUrl);
        },
        [history],
    );

    return (
        <>
            {!isMobile && (
                <EditBuildingMenu
                    className={styles.editBuildingMenu}
                    isBuildingEditable={building?.user_actions.edit?.allowed}
                />
            )}
            <Box className={styles.actionMenusContainer}>
                <BuildingSwitcher
                    buildings={buildings}
                    totalBuildingsCount={totalBuildingsCount}
                    fetchMoreBuildings={fetchMoreBuildings}
                    selectedBuilding={building}
                    onSelectHandler={selectBuilding}
                />
                {isMobile && selectedFloorId && (
                    <FloorSwitcher
                        className={styles.floorSwitcher}
                        floors={floors}
                        onSelectHandler={navigateToFloor}
                        selectedFloorId={selectedFloorId}
                    />
                )}
            </Box>
        </>
    );
};

export default memo(HeaderContent);
