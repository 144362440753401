import { useMemo } from 'react';

import { useAllSpaces } from './useAllSpaces';
import { useSpace } from './useSpace';

interface Location {
    floor_id?: number | null;
    space_id?: number | null;
}

/**
 * Returns the name of a space according to the location object provided
 * @param location Object containing `space_id` and optional `floor_id`. Pass in `floor_id`
 * to fetch all spaces for that floor and avoid multiple requests for individual spaces
 * @returns string
 */
export const useSpaceName = (location?: Location): string | undefined => {
    // Fetch all floor spaces to avoid multiple requests for individual spaces
    const floorSpaces = useAllSpaces({
        params: {
            floor: location?.floor_id || null,
        },
    });

    // Only fetch individual space if there is no floor_id
    const { data: space } = useSpace(
        !location?.floor_id && location?.space_id ? location?.space_id : 0,
    );

    return useMemo(() => {
        if (space) {
            return space.name;
        }

        const floorSpace = floorSpaces.data?.results.find(
            (s) => s.id === location?.space_id,
        );

        return floorSpace?.name;
    }, [space, floorSpaces, location?.space_id]);
};
