import { useField } from 'formik';
import type { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import type { ThresholdValues } from 'utils/types/ts/threshold';

import ThresholdInput from './ThresholdInput';

interface ThresholdInputRangeProps {
    formik: FormikProps<ThresholdValues>;
    keyInForm: string;
    unitSymbol: string;
}

const ThresholdInputRange = ({
    formik,
    keyInForm,
    unitSymbol,
}: ThresholdInputRangeProps): JSX.Element => {
    const { t } = useTranslation('solutions');

    const [lowerField, lowerMeta] = useField({
        name: `${keyInForm}.lower`,
    });

    const [upperField, upperMeta] = useField({
        name: `${keyInForm}.upper`,
    });

    return (
        <>
            <ThresholdInput
                id="lower"
                cypressId="min-thresholds"
                label={t('Min')}
                field={lowerField}
                meta={lowerMeta}
                hasError={!!formik.errors}
                unitSymbol={unitSymbol}
            />
            <ThresholdInput
                id="upper"
                cypressId="max-thresholds"
                label={t('Max')}
                field={upperField}
                meta={upperMeta}
                hasError={!!formik.errors}
                unitSymbol={unitSymbol}
            />
        </>
    );
};

export default ThresholdInputRange;
