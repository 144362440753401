import { useAppSelector } from '@infogrid/core-ducks';
import { selectOrganization } from '@infogrid/user-ducks';
import type { ComponentType } from 'react';
import { Redirect } from 'react-router';

export const withWelcomeRedirect =
    <P extends Record<string, unknown>>(RouteComponent: ComponentType<P>) =>
    (props: P) => {
        const organization = useAppSelector(selectOrganization);

        if (organization?.manager && !organization.has_installed_sensor) {
            return <Redirect to="/settings" />;
        }

        return (
            <RouteComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
            />
        );
    };
