import { isArray, isString } from '../is';
import { BaseValidationError } from './BaseValidationError';

export class SingleValidationError extends BaseValidationError {
    constructor(errors) {
        if (process.env.NODE_ENV !== 'production') {
            if (!isArray(errors) || errors.filter((x) => !isString(x)).length > 0) {
                // eslint-disable-next-line no-console
                console.error(
                    'SingleValidationError: `errors` argument must be an array of strings',
                );
            }
        }

        super(errors);
    }

    hasError() {
        return this.errors.length > 0;
    }

    asString(glue = ' ') {
        return this.errors.join(glue);
    }
}
