import type { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export const fludiaConfigurationFormValidationSchema = (t: TFunction): Yup.ObjectSchema =>
    Yup.object().shape({
        meter_number: Yup.string()
            .max(
                50,
                t('Meter Number cannot be greater than 50 characters', { ns: 'sensors' }),
            )
            .required(t('Meter Number is required', { ns: 'sensors' }))
            .label(t('Meter Number', { ns: 'sensors' })),
        initial_meter_reading: Yup.number()
            .typeError(t('Initial Meter Reading must be a number', { ns: 'sensors' }))
            .required(t('Initial Meter Reading is required', { ns: 'sensors' }))
            .label(t('Initial Meter Reading', { ns: 'sensors' })),
    });
