import { routesManager } from '@infogrid/core-routing';
import { Typography, Icon } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import GenericFooter from 'views/dashboards/components/Widget/GenericWidget/GenericFooter';

import { useFloorPlanFooterStyles } from './styles';

const getFloorPlanUrl = (buildingId, floorId) =>
    floorId
        ? routesManager.resolvePath('buildings:floor', {
              buildingId,
              floorId,
          })
        : routesManager.resolvePath('buildings:building', {
              buildingId,
          });

const Footer = ({
    buildingId,
    floorId,
    sensorsCount,
    isSensorsCountVisible,
    className,
}) => {
    const styles = useFloorPlanFooterStyles();
    const { t } = useTranslation('dashboard');

    const sensorsLabel = isSensorsCountVisible
        ? t('{{count}} Sensors', {
              count: sensorsCount,
              defaultValue___one: `${sensorsCount} Sensor`,
              defaultValue___other: `${sensorsCount} Sensors`,
          })
        : null;

    return (
        <GenericFooter
            className={className}
            periodLabel={t('Live')}
            justifyContent="space-between"
            sensorsLabel={sensorsLabel}
        >
            {!buildingId && <div />}
            {buildingId && (
                <NavLink
                    className={styles.buildingLink}
                    target="_blank"
                    to={getFloorPlanUrl(buildingId, floorId)}
                    data-cypress="floor-link"
                >
                    <Typography variant="caption" className={styles.linkText}>
                        {t('Open in Floor Plan View')}

                        <Icon
                            className={classNames('fal fa-angle-right', styles.angleIcon)}
                        />
                    </Typography>
                </NavLink>
            )}
        </GenericFooter>
    );
};

Footer.propTypes = {
    buildingId: PropTypes.number,
    floorId: PropTypes.number,
    sensorsCount: PropTypes.number,
    isSensorsCountVisible: PropTypes.bool,
    className: PropTypes.string,
};

Footer.defaultProps = {
    buildingId: null,
    floorId: null,
    sensorsCount: null,
    isSensorsCountVisible: false,
    className: '',
};

export default memo(Footer);
