import type { FloorSensorLocation } from '@infogrid/locations-types';
import { useMemo } from 'react';

import getAppURI from 'utils/getAppURI';

import { useFloor } from './useFloor';
import { useFloors } from './useFloors';

interface UseSelectFloorProps {
    floorId?: number;
    buildingId?: number;
}

export const useSelectFloor = ({ floorId, buildingId }: UseSelectFloorProps) => {
    const { data: floor, isLoading } = useFloor(floorId);
    const { floors } = useFloors(buildingId, { 'include-user-actions': true });

    const floorSensorDictionary: Record<string, FloorSensorLocation> | null = useMemo(
        () =>
            floor?.sensors.reduce((acc, sensorData) => {
                return sensorData.device_name
                    ? { ...acc, [sensorData.device_name]: sensorData }
                    : acc;
            }, {}) || null,
        [floor],
    );

    const isFloorInList = useMemo(() => {
        let doesFloorExistInList = true;

        if (floors?.length && floor) {
            doesFloorExistInList = !!floors?.find(({ id }) => id === floor.id);
        }

        return doesFloorExistInList;
    }, [floors, floor]);

    const floorPlan = useMemo(() => {
        if (!floor?.image) {
            return null;
        }

        return {
            ...floor.image,
            image_file: getAppURI(floor.image.image_file),
            zoom: floor.zoom,
            orientation: floor.orientation,
        };
    }, [floor]);

    return {
        floor,
        floorPlan,
        isLoading,
        isFloorInList,
        floorSensorDictionary,
    };
};
