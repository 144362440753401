import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { calendarPeriods, defaultRollingCalendarPeriods } from '@infogrid/utils-dates';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import { Locations } from 'views/dashboards/components/modals/ConfigureWidgetModal/Locations';
import RollingPeriod from 'views/dashboards/components/modals/ConfigureWidgetModal/RollingPeriod';

import MonitoringTypeTab from './MonitoringTypeTab';

export const CHIP_TYPE = Object.freeze({
    CALORIFIER: 'calorifier',
    COLD: 'cold',
    DEFAULT: 'default',
    HOT: 'hot',
} as const);

export const widgetConfigurationModalTabs = [
    {
        id: 'locations',
        name: registerTranslationKey('Buildings', { ns: 'dashboard' }),
        component: Locations,
        attributes: {
            sensorTypes: [SENSOR_TYPE.TYPE_LEGIONNAIRE],
            isMappedSensors: false,
            buildingsOnly: true,
            filterSensorTypesExist: true,
            description: registerTranslationKey(
                'Select the buildings you would like to see in the widget',
                { ns: 'dashboard' },
            ),
        },
    },
    {
        id: 'monitoring-type',
        name: registerTranslationKey('Monitoring Type', { ns: 'dashboard' }),
        component: MonitoringTypeTab,
    },
    {
        id: 'rolling-period',
        name: registerTranslationKey('Time Period', { ns: 'dashboard' }),
        component: RollingPeriod,
        attributes: {
            periods: defaultRollingCalendarPeriods.filter(
                (period) =>
                    period.value === calendarPeriods.month ||
                    period.value === calendarPeriods.week,
            ),
        },
    },
];
