import { makeStyles } from '@material-ui/core';

export const useContentStyles = makeStyles((theme) => ({
    tableCell: {
        border: 0,
        fontSize: '13px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        '&, & > a': {
            color: theme.palette.text.primary,
        },
    },
    tableCellGreen: {
        '&, & > a': {
            color: '#388E3C',
        },
    },
    tableCellRed: {
        '&, & > a': {
            color: '#D32F2F',
        },
    },
    tableCellTotal: {
        fontWeight: 500,
        color: theme.palette.text.primary,
    },
}));
