import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import ProximityAndTouchCountPreview from 'styles/images/widgets/proximity-and-touch-count.png';

import ProximityAndTouchCountWidget from './ProximityAndTouchCountWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('Proximity and Touch Count', { ns: 'dashboard' }),
    type: WIDGET_TYPE.PROXIMITY_AND_TOUCH_COUNT,
    featureFlags: [],
    Component: ProximityAndTouchCountWidget,
    image: ProximityAndTouchCountPreview,
    order: 3,
    gridConfig: DEFAULT_GRID_ITEM_CONFIG,
};

export default ProximityAndTouchCountWidget;
