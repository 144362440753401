import { ConnectionStatus, setSocketStatus } from '@infogrid/core-ducks';
import { getOrganizationId } from '@infogrid/user-cookies';
import qs from 'qs';
import {
    all,
    call,
    delay,
    fork,
    join,
    race,
    put,
    select,
    take,
} from 'redux-saga/effects';

import {
    AUTHENTICATION_TYPE,
    selectors as uiSelectors,
    SET_AUTHENTICATION_STATE,
} from 'ducks/ui';
import { refreshToken } from 'sagas/auth/authMiddleware';
import { invalidateCache } from 'sagas/helpers/fetchingGuard';
import { onComponentError } from 'services/sentry';
import SETTINGS from 'settings';

import { closeSocket } from './CloseSocket';
import { readSocket } from './ReadSocket';
import { SocketDisconnected } from './SocketMonitor';
import { writeSocket } from './WriteSocket';
import { connect } from './createSocket';

export { SocketConnected, SocketDisconnected } from './SocketMonitor';

/**
 * Check if backend will be passed cookies from the frontend.
 * @returns {boolean}
 */
function willBackendGetFrontendCookies() {
    const backendURL = new URL(SETTINGS.BACKEND_SITE_URL);
    const backendHostname = backendURL.hostname;
    const cookiesHostname = SETTINGS.COOKIE_DOMAIN || window.location.hostname;

    return backendHostname.endsWith(cookiesHostname);
}

function* worker(socketUrl) {
    let socket;

    try {
        // Ensure user is authenticated
        let token = yield call(refreshToken);
        const authenticationState = yield select(uiSelectors.authenticationState);

        // If user is not authenticated, wait for them to be authenticated before connecting
        if (!token && authenticationState !== AUTHENTICATION_TYPE.AUTHENTICATED) {
            yield take(SET_AUTHENTICATION_STATE);
            token = yield call(refreshToken);
        }

        if (token) {
            let url = socketUrl;

            if (
                process.env.NODE_ENV === 'development' &&
                !willBackendGetFrontendCookies()
            ) {
                // If backend isn't hosted on a subdomain of frontend, auth cookie won't be passed to backend
                // In this case we need to authenticate the connection explicitly by passing token with querystring
                // This is only done locally to avoid the token in querystring from being logged in production
                const queryString = qs.stringify({
                    [SETTINGS.AUTH_TOKEN_NAME]: token,
                    [SETTINGS.ORGANIZATION_ID_COOKIE_NAME]: getOrganizationId(),
                });

                url = `${socketUrl}?${queryString}`;
            }

            socket = yield call(connect, url);

            yield put(setSocketStatus(ConnectionStatus.Connected));

            const ioTasks = yield all([
                fork(readSocket, socket),
                fork(writeSocket, socket),
            ]);

            yield join(ioTasks);
        }
    } catch (err) {
        // Only capture real errors, not websocket close events (See WEB-3555)
        if (!(err instanceof CloseEvent)) {
            onComponentError(err, 'Socket error');
        }
    } finally {
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.warn('Socket worker finished ...');
        }

        if (socket) {
            socket.close();
        }
    }
}

export default function* SocketSaga(socketUrl) {
    while (true) {
        try {
            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.log('Starting socket worker');
            }

            yield race({
                worker: call(worker, socketUrl),
                disconnected: call(SocketDisconnected), // Resolves when socket is closed by the server
                close: call(closeSocket), // Resolves when socket is closed via internal action
            });

            // When socket disconnects, invalidate full cache
            yield call(invalidateCache);

            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.log(
                    `Reconnecting socket in ${
                        SETTINGS.SOCKET_RECONNECT_TIMEOUT / 1000
                    } seconds ...`,
                );
            }
        } catch (error) {
            onComponentError(error);
        }

        yield delay(SETTINGS.SOCKET_RECONNECT_TIMEOUT);
    }
}
