import { Modal } from '@infogrid/components-material-ui';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import { CONNECTION_STATUSES, SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { useMemo, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLatestSensorEvent } from 'apiHooks/sensors/hooks';
import useSensorSignal from 'utils/hooks/useSensorSignal';

import { useInstallationFlowModalStyles } from '../InstallationFlowModal/styles';
import SensorDisplay from '../SensorDisplay';
import { INSTALLATION_FLOW_STEPS } from '../constants';
import { useInstallationFlowContext } from '../context';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../reducer';
import { useInstallationFlowStyles } from '../styles';
import ConnectionCheckContent from './ConnectionCheckContent';

const TITLE_TEXTS = {
    [CONNECTION_STATUSES.GOOD]: () => registerTranslationKey('This device is connected!'),
    [CONNECTION_STATUSES.EXPECTED_OFFLINE]: ({ isAirthingsHub }) =>
        isAirthingsHub
            ? registerTranslationKey('Check the device is connected', { ns: 'sensors' })
            : registerTranslationKey(
                  'This device takes up to 12 hours to appear as connected',
                  { ns: 'sensors' },
              ),
    [CONNECTION_STATUSES.UNEXPECTED_OFFLINE]: ({ isCloudConnector }) =>
        isCloudConnector
            ? registerTranslationKey('This Cloud Connector is offline!', {
                  ns: 'sensors',
              })
            : registerTranslationKey('This sensor is offline!', { ns: 'sensors' }),
    [CONNECTION_STATUSES.WEAK]: () =>
        registerTranslationKey('This sensor has weak signal!', { ns: 'sensors' }),
};

/**
 * Main entrypoint for the connection check step of the whole installation flow.
 * Here we decide whether the sensor's signal strength is good or bad and what text to display to the user.
 */
const ConnectionCheck = () => {
    const { t } = useTranslation('sensors');
    const { sensor, vendorTypeStep } = useInstallationFlowContext();

    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    const vendor = sensor?.vendor;
    const deviceType = sensor?.type_code;

    // Set up polling
    useLatestSensorEvent(sensor);

    const sensorConfig = useMemo(() => getSensorConfig(deviceType), [deviceType]);

    const modalClasses = useInstallationFlowModalStyles();
    const installationFlowStyles = useInstallationFlowStyles();

    const { isBoostMode, signalLevel } = useSensorSignal(sensor);

    const isCloudConnector = useMemo(
        () => deviceType === SENSOR_TYPE.TYPE_CCON,
        [deviceType],
    );

    const isAirthingsHub = useMemo(
        () => deviceType === SENSOR_TYPE.TYPE_AIRTHINGS_HUB,
        [deviceType],
    );

    /**
     * Determines which type of connection the sensor has to our WebApp currently
     */
    const connectionStatus = useMemo(() => {
        if (signalLevel > 0 && !isBoostMode) {
            return CONNECTION_STATUSES.GOOD;
        }

        if (vendor === 'AIR') {
            return CONNECTION_STATUSES.EXPECTED_OFFLINE;
        }

        if (signalLevel > 0 && isBoostMode) {
            // It's a DT or BEA sensor without a good signal
            return CONNECTION_STATUSES.WEAK;
        }

        return CONNECTION_STATUSES.UNEXPECTED_OFFLINE;
    }, [signalLevel, vendor, isBoostMode]);

    const nextButtonText = useMemo(() => {
        if (
            connectionStatus === CONNECTION_STATUSES.EXPECTED_OFFLINE &&
            deviceType === SENSOR_TYPE.TYPE_AIRTHINGS_HUB
        ) {
            return t('Continue');
        }

        return [
            CONNECTION_STATUSES.UNEXPECTED_OFFLINE,
            CONNECTION_STATUSES.EXPECTED_OFFLINE,
            CONNECTION_STATUSES.WEAK,
        ].includes(connectionStatus)
            ? t('Continue offline')
            : t('Next');
    }, [connectionStatus, deviceType, t]);

    const titleText = useMemo(
        () => TITLE_TEXTS[connectionStatus]({ isCloudConnector, isAirthingsHub }),
        [connectionStatus, isCloudConnector, isAirthingsHub],
    );

    const titleClasses = classNames({
        [modalClasses.titleSuccess]: connectionStatus === CONNECTION_STATUSES.GOOD,
        [modalClasses.titleError]: [
            CONNECTION_STATUSES.UNEXPECTED_OFFLINE,
            CONNECTION_STATUSES.WEAK,
        ].includes(connectionStatus),
    });

    const onClickBack = useCallback(() => {
        if (vendorTypeStep === INSTALLATION_FLOW_STEPS.qrCodeScanner) {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.RESET_PROGRESS,
            });

            return;
        }

        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.RETURN_TO_VENDOR_SELECTOR,
        });
    }, [dispatchInstallationFlowContext, vendorTypeStep]);

    return (
        <>
            <Modal.Title className={titleClasses}>{titleText}</Modal.Title>
            <Modal.Content>
                <SensorDisplay
                    sensor={sensor}
                    label={sensorConfig.label}
                    deviceType={deviceType}
                />
                <ConnectionCheckContent
                    connectionStatus={connectionStatus}
                    deviceType={deviceType}
                />
            </Modal.Content>
            <Modal.Actions
                justify="space-between"
                className={installationFlowStyles.actions}
            >
                <Button onClick={onClickBack} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
                <Button
                    onClick={() =>
                        dispatchInstallationFlowContext({
                            type: INSTALLATION_FLOW_ACTIONS.CONFIGURE_DEVICE,
                        })
                    }
                    color="primary"
                    variant="contained"
                    autoFocus
                    data-cypress="next"
                >
                    {nextButtonText}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(ConnectionCheck);
