import { makeStyles, fade } from '@material-ui/core';

export const useSliderStyles = makeStyles((theme) => ({
    root: {
        height: '14px',
        width: '100%',
    },
    thumb: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: '50%',
        boxShadow: `0px 4px 4px ${fade(theme.palette.common.black, 0.25)}`,
        cursor: 'pointer',
        display: 'flex',
        height: '30px',
        justifyContent: 'center',
        transform: 'translate(0, -50%)',
        top: '50%',
        width: '30px',
    },
    track: {
        borderRadius: '999px',
        height: '100%',
    },
}));
