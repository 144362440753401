import { makeStyles } from '@material-ui/core';

export const usePreviouslyInstalledWarningStyles = makeStyles((theme) => ({
    breadcrumbs: {
        '& li .bp4-breadcrumb': {
            color: theme.palette.error.main,
        },
    },
    popover: {
        background: theme.palette.error.main,
    },
    deviceName: {
        overflowWrap: 'break-word',
    },
}));
