export const bindAndCoerce = (error, fieldName) => {
    const res = error || null;

    if (res !== null) {
        res.bindToField(fieldName);
    }

    return res;
};

export class BaseValidationError {
    constructor(errors) {
        // Store errors
        this.errors = errors;
    }

    // Support for .. of loops
    [Symbol.iterator]() {
        const instance = this;
        let curKey = 0;
        let done = false;

        return {
            next() {
                const nextVal = instance.errorByIndex(curKey);

                // Note: If a custom error handler does not coerce undefined to null,
                //  the iterator will stop too early
                //
                // Feel free to submit a PR if this annoys you!
                if (nextVal === undefined) {
                    done = true;
                } else {
                    curKey += 1;
                }

                return {
                    done,
                    value: nextVal,
                };
            },
        };
    }

    /**
     * Used by firstError and iteration protocol
     */
    errorByIndex(index) {
        return this.errors[index];
    }

    hasError() {
        return this.errors.length > 0;
    }

    bindToField(fieldName) {
        if (process.env.NODE_ENV !== 'production') {
            if (this.fieldName && this.fieldName !== fieldName) {
                // eslint-disable-next-line no-console
                console.error(
                    `BaseValidationError: Unexpected rebind of ${this} as ${fieldName} (was ${this.fieldName})`,
                );
            }
        }

        this.fieldName = fieldName;
    }

    toString() {
        return this.asString();
    }

    map(callbackfn, thisArg) {
        return this.iter().map(callbackfn, thisArg);
    }

    forEach(callbackfn, thisArg) {
        this.iter().forEach(callbackfn, thisArg);
    }

    filter(callbackfn, thisArg) {
        return this.iter().filter(callbackfn, thisArg);
    }

    /**
     * Iterator used for .forEach/.filter/.map
     */
    iter() {
        return this.errors;
    }
}
