import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import isNil from 'lodash/isNil';

import { useFractionStyles } from './styles';

interface FractionProps {
    total?: number;
    value?: number;
}

export const Fraction = ({ total, value }: FractionProps): JSX.Element => {
    const styles = useFractionStyles();

    if (!total || isNil(value)) {
        return (
            <Typography
                className={classNames(styles.text, styles.value)}
                component="span"
            >
                -
            </Typography>
        );
    }

    return (
        <div>
            <Typography
                className={classNames(styles.text, styles.value)}
                component="span"
            >
                {value}
            </Typography>
            <Typography
                className={classNames(styles.text, styles.total)}
                component="span"
            >
                /{total}
            </Typography>
        </div>
    );
};
