import type { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

export const useActionNeededDialogStyles = makeStyles((theme: Theme) => ({
    modalTitle: {
        paddingLeft: '16px',
    },
    modalDescription: {
        borderBottom: '1px solid #E0E0E0',
        paddingBottom: '16px',
        paddingLeft: '16px',
        color: theme.palette.text.secondary,
    },
    modal: {
        padding: '0px',
        minHeight: '50vh',
        maxHeight: '50vh',
        [theme.breakpoints.down('xs')]: {
            minHeight: '100vh',
            maxHeight: '100vh',
        },
    },
    noContentMessageTitle: {
        fontSize: theme.typography.pxToRem(20),
        margin: theme.spacing(2),
    },
    error: {
        margin: theme.spacing(2),
    },
}));
