import type { QueryKey } from 'react-query';

import { EVENT_LOG_QUERY_KEY } from 'apiHooks/base/queryKeys';

export const getEventLogKey = (id: string): QueryKey => [EVENT_LOG_QUERY_KEY, id];

export const getFullEventLogKey = (
    id: string,
    fromToQuery: { from_time?: string; to_time?: string },
): QueryKey => [EVENT_LOG_QUERY_KEY, id, fromToQuery];
