import { fade, makeStyles } from '@material-ui/core';

export const useIconsPickerStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    input: {
        alignItems: 'center',
        border: `1px solid ${theme.palette.common.gray9}`,
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'flex',
        height: '56px',
        justifyContent: 'center',
        minWidth: '56px',
        width: '56px',
    },
    dropdown: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        boxShadow: `0px 8px 10px ${fade(
            theme.palette.common.black,
            0.14,
        )}, 0px 3px 14px ${fade(theme.palette.common.black, 0.12)}, 0px 5px 5px ${fade(
            theme.palette.common.black,
            0.2,
        )}`,
        columnGap: theme.spacing(1),
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        left: '-40px',
        maxHeight: '220px',
        overflowY: 'scroll',
        padding: theme.spacing(2),
        position: 'absolute',
        rowGap: theme.spacing(1),
        top: 'calc(100% + 10px)',
        width: '352px',
        zIndex: 100,
    },
    icon: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '24px',
        height: '40px',
        justifyContent: 'center',
        width: '40px',
    },
    iconHover: {
        '&:hover': {
            backgroundColor: theme.palette.common.gray7,
            borderRadius: '50%',
        },
    },
}));
