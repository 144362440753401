import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import type { FontWeightProperty } from 'csstype';

export const useTutorialModalStyles = makeStyles((theme: Theme) => ({
    modalContent: {
        overflow: 'auto',
        marginBottom: theme.spacing(3),
    },
    framedContent: {
        border: `1px solid ${theme.palette.common.gray7}`,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    subtitle: {
        fontWeight: theme.typography.fontWeightRegular as FontWeightProperty,
        marginBottom: theme.spacing(1),
    },
    typography: {
        marginBottom: theme.spacing(2),
    },
    highlightedCaption: {
        fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
    },
    scoreExplanation: {
        flexDirection: 'row',

        [`@media (max-width: ${440}px)`]: {
            flexDirection: 'column',
        },
    },
    ringBox: {
        margin: theme.spacing(0, 3),
    },
    section: {
        '& > p': {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },
    header: {
        marginBottom: theme.spacing(3),
        fontWeight: theme.typography.fontWeightRegular as FontWeightProperty,
    },
}));

export const useProgressRingStyles = makeStyles((theme: Theme) => ({
    root: {
        fontSize: 24,
        marginBottom: theme.spacing(1),
    },
}));
