import { makeStyles } from '@material-ui/core';

export const useThresholdsExplainerStyles = makeStyles((theme) => ({
    subtitle: { fontWeight: 500, marginBottom: theme.spacing(1) },
    subtitle2: {
        fontWeight: 500,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
    },
    body2: { marginBottom: theme.spacing(1) },
    chart: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        maxWidth: '100%',
    },
    caption: {
        width: 455,
        textAlign: 'justify',
        display: 'block',
        color: theme.palette.common.gray6,
        maxWidth: '100%',
    },
    content: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));
