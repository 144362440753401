import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useControlsStyles = makeStyles((theme) => ({
    mapCustomControlsContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',

        justifyContent: 'flex-start',

        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
        },
    },
    sensorIconSizeSlider: {
        width: '100px',
        marginLeft: theme.spacing(1.25),

        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    controlsWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export const useFloorPlanStyles = makeStyles((theme) => ({
    mapContainer: {
        border: `1px solid ${grey[300]}`,
        backgroundColor: theme.palette.common.white,
    },
}));
