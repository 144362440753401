import classNames from 'classnames';
import { memo, useCallback } from 'react';

import Sidebar from 'components/Sidebar';
import { SIDEBAR_COLLAPSE_BUTTON_DIRECTION } from 'components/Sidebar/SidebarCollapseButton';

import NavbarContainer from './NavbarContainer';
import { useNavbarCollapseButtonStyles, useNavbarStyles } from './styles';

const TRANSITION_DURATION = 300;
const NAVBAR_WIDTH = Object.freeze({
    COLLAPSED: 64,
    UNCOLLAPSED: 192,
});

const collapseButtonTransitionResolver = () => `opacity ${TRANSITION_DURATION}ms`;

const Navbar = () => {
    const navbarClasses = useNavbarStyles({
        buttonTransition: collapseButtonTransitionResolver(),
        buttonTransitionDelay: TRANSITION_DURATION,
    });

    const navbarCollapseButtonClasses = useNavbarCollapseButtonStyles();

    const renderCollapseButton = useCallback(
        ({ ButtonComponent, className, ...rest }) => (
            <ButtonComponent
                className={classNames(className, navbarCollapseButtonClasses.root)}
                iconClassName={navbarCollapseButtonClasses.icon}
                location={SIDEBAR_COLLAPSE_BUTTON_DIRECTION.LEFT}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
            />
        ),
        [navbarCollapseButtonClasses],
    );

    return (
        <Sidebar
            sidebarName="globalDesktop"
            className={navbarClasses.root}
            collapsedWidth={NAVBAR_WIDTH.COLLAPSED}
            uncollapsedWidth={NAVBAR_WIDTH.UNCOLLAPSED}
            transitionDuration={TRANSITION_DURATION}
            collapseButton={renderCollapseButton}
        >
            {({ opened, toggleCollapsedState, isTransitioning }) => (
                <NavbarContainer
                    navbarOpened={opened}
                    toggleCollapsedState={toggleCollapsedState}
                    isTransitioning={isTransitioning}
                    transitionDuration={TRANSITION_DURATION}
                />
            )}
        </Sidebar>
    );
};

export default memo(Navbar);
