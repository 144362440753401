import { routesManager } from '@infogrid/core-routing';
import { useIsDesktop } from '@infogrid/utils-hooks';
import { Button, Typography } from '@material-ui/core';
import qs from 'qs';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { SPACE_PARAM_NAMES } from 'utils/filtering/space/consts';

import SearchFilter from './SearchFilter';
import SpaceTypeFilter from './SpaceTypeFilter';
import { useSpacesTableFiltersStyles } from './styles';

interface Props {
    buildingId: number;
    floorId: number;
    forceMobileView: boolean;
}

const SpacesTableFilters = ({
    buildingId,
    floorId,
    forceMobileView,
}: Props): JSX.Element => {
    const styles = useSpacesTableFiltersStyles();

    const { t } = useTranslation('estate');

    const isDesktop = useIsDesktop();

    const history = useHistory();
    const location = useLocation();
    const query = useMemo(
        () => qs.parse(location.search, { ignoreQueryPrefix: true }),
        [location.search],
    );
    const hasFilters = useMemo(
        () => Object.values(SPACE_PARAM_NAMES).some((key) => query[key]),
        [query],
    );

    const onClearFilters = useCallback(() => {
        history.push(
            routesManager.resolvePath('estate:estate-building-floor', {
                buildingId,
                floorId,
            }),
        );
    }, [buildingId, floorId, history]);

    return (
        <div className={styles.container}>
            <div className={styles.filters}>
                <div className={styles.searchFilterWrapper}>
                    <SearchFilter />
                </div>
                <div className={styles.filterWrapper}>
                    <Typography className={styles.filterByLabel} variant="subtitle2">
                        {t('Filter by:')}
                    </Typography>
                    <SpaceTypeFilter />
                </div>
                {(!isDesktop || forceMobileView) && (
                    <Button
                        className={styles.clearFiltersButton}
                        color="primary"
                        disabled={!hasFilters}
                        onClick={onClearFilters}
                        size="small"
                        variant="outlined"
                        data-cypress="clear-filters"
                    >
                        {t('Clear filters')}
                    </Button>
                )}
            </div>
            {isDesktop && !forceMobileView && (
                <Button
                    className={styles.clearFiltersButton}
                    color="primary"
                    disabled={!hasFilters}
                    onClick={onClearFilters}
                    size="small"
                    variant="outlined"
                    data-cypress="clear-filters"
                >
                    {t('Clear filters')}
                </Button>
            )}
        </div>
    );
};

export default memo(SpacesTableFilters);
