import { wrapResponsePromise } from '@infogrid/core-api';
import { useAppSelector } from '@infogrid/core-ducks';
import { selectOrganizationId } from '@infogrid/user-ducks';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from 'apiHooks/settings/solutions/controllers';
import { getThresholdsKey } from 'apiHooks/settings/solutions/getQueryKeys';
import type { Thresholds } from 'utils/types/ts/threshold';

export const useThresholds = (): UseQueryResult<Thresholds> => {
    const organizationId = useAppSelector(selectOrganizationId);

    return useQuery(
        getThresholdsKey(),
        ({ signal }) =>
            wrapResponsePromise(
                controllers.getThresholds(organizationId, signal),
                ({ data }) => data,
            ),
        {
            enabled: organizationId != null,
        },
    );
};
