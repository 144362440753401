import type { WeekdayWorkingHoursConfiguration } from '@infogrid/sensors-constants';
import every from 'lodash/every';
import isEqual from 'lodash/isEqual';

import { DEFAULT_START_TIME, DEFAULT_END_TIME } from './consts';

const defaultWorkdaySetting = {
    is_working_day: true,
    start_time: DEFAULT_START_TIME,
    end_time: DEFAULT_END_TIME,
};

const defaultWeekendSetting = {
    is_working_day: false,
    start_time: DEFAULT_START_TIME,
    end_time: DEFAULT_END_TIME,
};

export const getIsDefaultSensorWorkingHoursSettings = (
    workingHours: WeekdayWorkingHoursConfiguration,
): boolean => {
    return every(workingHours, (value, key) => {
        if (['sunday', 'saturday'].includes(key)) {
            return value.is_working_day === false;
        }

        return isEqual(value, defaultWorkdaySetting);
    });
};

export const getInitialWorkingHours = (
    workingHours?: WeekdayWorkingHoursConfiguration,
): WeekdayWorkingHoursConfiguration => {
    if (!workingHours) {
        return {
            monday: defaultWorkdaySetting,
            tuesday: defaultWorkdaySetting,
            wednesday: defaultWorkdaySetting,
            thursday: defaultWorkdaySetting,
            friday: defaultWorkdaySetting,
            saturday: defaultWeekendSetting,
            sunday: defaultWeekendSetting,
        };
    }

    return workingHours;
};
