import { makeStyles } from '@material-ui/core';

export const useDTIdentifierStyles = makeStyles((theme) => ({
    helpImagesContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: theme.spacing(3),
    },
    tip: {
        color: theme.palette.text.grey.default,
        marginTop: theme.spacing(1.5),
        maxWidth: 145,
    },
}));
