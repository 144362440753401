import type { SensorType } from '@infogrid/sensors-constants';
import { useMemo } from 'react';

export const filterActiveSensors = <T extends { type_code: SensorType }>(
    sensors: T[],
    activeSensorTypes: SensorType[],
): T[] =>
    activeSensorTypes.length
        ? sensors.filter(({ type_code: typeCode }) =>
              activeSensorTypes.includes(typeCode),
          )
        : sensors;

export const useActiveSensors = <T extends { type_code: SensorType }>({
    sensors = [],
    activeSensorTypes,
}: {
    sensors?: T[];
    activeSensorTypes: SensorType[];
}): { activeSensors: T[] } => {
    return useMemo(
        () => ({
            activeSensors: filterActiveSensors(sensors, activeSensorTypes),
        }),
        [sensors, activeSensorTypes],
    );
};
