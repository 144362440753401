import type { FloorDetail, FloorSensor } from '@infogrid/locations-types';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFloorSensors } from 'apiHooks/floorPlan/sensors/hooks';
import { useMoveFolder } from 'apiHooks/folders/hooks';

import { selectUnassignedSensors } from '../../../../utils';
import { populateMoveSensorsData } from '../../../SpaceSidebar/utils';
import MoveSensorsModal from '../../../modals/MoveSensorsModal';
import SensorItem from './SensorItem';
import { useSidebarSensorsStyles } from './styles';

interface Props {
    buildingId: number;
    floor: FloorDetail;
}

const SidebarSensors = ({ buildingId, floor }: Props) => {
    const styles = useSidebarSensorsStyles();

    const { t } = useTranslation();

    const [selectedSensors, setSelectedSensors] = useState<FloorSensor[]>([]);

    const [isMoveSensorsModalOpen, _1, _2, toggleMoveSensorsModal] = useIsOpenState();

    const { mutate: moveSensors } = useMoveFolder();

    const { data: sensorsData } = useFloorSensors(
        { floorId: floor.id },
        { select: selectUnassignedSensors },
    );

    const onSelectSensor = useCallback(
        (sensor: FloorSensor) => {
            const selectedSensorsIds = selectedSensors.map((s) => s.id);

            if (selectedSensorsIds.includes(sensor.id)) {
                setSelectedSensors(selectedSensors.filter((s) => s.id !== sensor.id));
            } else {
                setSelectedSensors([...selectedSensors, sensor]);
            }
        },
        [selectedSensors],
    );

    const onMoveSensors = useCallback(
        (
            selectedBuildingId: number,
            selectedFloorId: number,
            selectedSpaceId: number | null,
        ) => {
            moveSensors(
                {
                    folderId: selectedSpaceId || selectedFloorId,
                    data: {
                        move_sensors: true,
                        sensors: selectedSensors.map((s) => s.device_name),
                    },
                },
                {
                    onSuccess() {
                        populateMoveSensorsData({
                            buildingId,
                            floorId: floor.id,
                            selectedBuildingId,
                            selectedFloorId,
                            selectedSpaceId,
                        });

                        toggleMoveSensorsModal();

                        setSelectedSensors([]);
                    },
                },
            );
        },
        [buildingId, floor, moveSensors, selectedSensors, toggleMoveSensorsModal],
    );

    return (
        <>
            <div className={styles.headerWrapper}>
                {selectedSensors.length === 0 && sensorsData?.sensors && (
                    <Typography
                        className={styles.sensorsLabel}
                        data-cypress="sensors-count"
                    >
                        {t('{{count}} sensors', {
                            count: sensorsData.sensors.length,
                            defaultValue___one: `${sensorsData.sensors.length} sensor`,
                            defaultValue___other: `${sensorsData.sensors.length} sensors`,
                        })}
                    </Typography>
                )}
                {selectedSensors.length > 0 && (
                    <Tooltip
                        placement="top"
                        title={
                            !floor.user_actions?.edit?.allowed &&
                            floor.user_actions?.edit?.reason
                                ? floor.user_actions?.edit?.reason
                                : ''
                        }
                    >
                        <div>
                            <Button
                                color="primary"
                                data-cypress="move-sensors-view-open"
                                disabled={!floor.user_actions.edit?.allowed}
                                onClick={toggleMoveSensorsModal}
                                size="small"
                                variant="outlined"
                            >
                                {t('Move')}
                            </Button>
                        </div>
                    </Tooltip>
                )}
            </div>
            <div className={styles.sensorsList}>
                {sensorsData?.sensors.map((sensor) => (
                    <SensorItem
                        isSelected={selectedSensors.includes(sensor)}
                        key={sensor.id}
                        onSelect={() => onSelectSensor(sensor)}
                        sensor={sensor}
                    />
                ))}
            </div>
            {isMoveSensorsModalOpen && (
                <MoveSensorsModal
                    buildingId={buildingId}
                    floorId={floor.id}
                    onClose={toggleMoveSensorsModal}
                    onMoveSensors={onMoveSensors}
                    open={isMoveSensorsModalOpen}
                    sensors={selectedSensors}
                />
            )}
        </>
    );
};

export default memo(SidebarSensors);
