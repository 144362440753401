import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useFloorPlanStyles = makeStyles((theme) => ({
    controlsContainer: {
        borderTop: `1px solid ${grey[300]}`,
        padding: `${theme.spacing(0.5, 1.25, 0.5, 0)} !important`,
        flexShrink: 0,
    },
    mapContainer: {
        backgroundColor: `${theme.palette.common.white} !important`,
    },
    mapCustomControlsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    sensorIconSizeSlider: {
        width: '100px',
        marginLeft: theme.spacing(1.25),
    },

    sensorsChecklist: {
        width: '220px',
    },
}));
