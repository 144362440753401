import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

import type { TABLE_VARIANTS_KEYS } from '../../constants';
import { SCROLLBAR_WIDTH, TABLE_VARIANTS } from '../../constants';

type StyleProps = {
    cellPadding: [number, number];
    leftPadding: number;
    variant: typeof TABLE_VARIANTS[TABLE_VARIANTS_KEYS];
};

export const useTableContentStyles = makeStyles((theme) => ({
    header: {
        overflowX: 'hidden !important' as 'hidden',
    },
    scrollableHeader: {
        overflowY: 'scroll !important' as 'scroll',
    },
    cellWrapper: {
        display: 'flex',
    },
    cell: ({ variant }: StyleProps) => ({
        margin:
            variant === TABLE_VARIANTS.REGULAR ? theme.spacing(0.5, 0) : theme.spacing(0),
        background: theme.palette.background.transparentPaper,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
    }),
    cellWithRegularPadding: ({ cellPadding }: StyleProps) => ({
        padding: theme.spacing(cellPadding[0], cellPadding[1]),
    }),
    cellWithLeftPadding: ({ cellPadding, leftPadding = 0 }: StyleProps) => ({
        padding: theme.spacing(
            cellPadding[0],
            cellPadding[1],
            cellPadding[0],
            leftPadding,
        ),
    }),
    shadedCell: {
        background: `${theme.palette.background.sand} !important`,
    },
    selectedCell: {
        background: `${theme.palette.background.paper} !important`,
        fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
        '& > *': {
            fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
        },
    },
    firstCellOfSelectedRow: {
        '&::before': {
            content: `''`,
            display: 'block',
            position: 'absolute',
            width: theme.spacing(0.5),
            background: theme.palette.primary.main,
            left: 0,
            top: theme.spacing(0.5),
            bottom: theme.spacing(0.5),
            borderRadius: theme.spacing(0, 0.25, 0.25, 0),
        },
    },
    stringCell: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflowX: 'hidden',
        maxWidth: '100%',
    },
    headerCell: {
        textAlign: 'left',
        fontWeight: 500,
    },
    grid: {
        scrollbarColor: `${theme.palette.common.gray4} transparent`,
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: SCROLLBAR_WIDTH,
            height: SCROLLBAR_WIDTH,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.common.gray4,
            borderRadius: theme.shape.borderRadius,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent',
        },
        '& .ReactVirtualized__Grid__innerScrollContainer': {
            overflow: 'visible !important',
        },
    },
}));
