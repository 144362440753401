import { Modal } from '@infogrid/components-material-ui';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdateImageConfirmModalStyles } from './styles';

interface Props {
    isOpened: boolean;
    onSubmit: (shouldUnmapSensor: boolean) => void;
    onCancel: () => void;
    isUploading: boolean;
    onClose: () => void;
}

const UpdateImageConfirmModal = ({
    isOpened,
    onSubmit,
    onCancel,
    isUploading,
    onClose,
}: Props): JSX.Element => {
    const styles = useUpdateImageConfirmModalStyles();
    const modalClasses = useMemo(
        () => ({ container: styles.modalContainer }),
        [styles.modalContainer],
    );

    const { t } = useTranslation('floorplan');

    return (
        <Modal classes={modalClasses} onClose={onClose} open={isOpened}>
            <Modal.Title>{t('Change Floor Plan Image')}</Modal.Title>

            <Modal.Content>
                <Typography
                    variant="body2"
                    component="div"
                    className={styles.primaryTextContainer}
                >
                    <p>
                        {t(
                            `You are about to change the Floor Plan image.
                        You can choose whether to unmap(remove)
                        sensors marked on the Floor Plan or keep sensors mapped to the changed Floor Plan image.
                        `,
                        )}{' '}
                    </p>
                    <p>
                        {t(`

                        In order to keep the sensors in the same location,
                        the changed image should be the same size as the current Floor Plan.

                    `)}
                    </p>
                    <b>
                        {t('Do you want to keep or unmap sensors from your Floor Plan?.')}
                    </b>
                </Typography>
            </Modal.Content>

            <Modal.Actions justify="space-between">
                <Button
                    classes={{ root: styles.cancelButton }}
                    onClick={onCancel}
                    data-cypress="edit-floor-image-btn-cancel"
                >
                    {t('Cancel', { ns: 'common' })}
                </Button>

                <div>
                    <Button
                        className={styles.keepSensorsButton}
                        color="primary"
                        variant="contained"
                        data-cypress="edit-floor-image-btn-keep-sensors"
                        onClick={() => onSubmit(false)}
                    >
                        {isUploading && (
                            <CircularProgress className={styles.spinner} size={22} />
                        )}
                        {!isUploading && <span>{t('Keep sensors')}</span>}
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        data-cypress="edit-floor-image-btn"
                        onClick={() => onSubmit(true)}
                        disabled={isUploading}
                    >
                        {isUploading && (
                            <CircularProgress className={styles.spinner} size={22} />
                        )}

                        {!isUploading && (
                            <span>{t('Unmap sensors', { ns: 'floorplan' })}</span>
                        )}
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default UpdateImageConfirmModal;
