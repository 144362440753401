import { routesManager } from '@infogrid/core-routing';
import { getUser, isAuthenticated } from '@infogrid/user-ducks';
import type { User } from '@infogrid/user-types';
import { connect } from 'react-redux';

import NavbarHeaderMobile, { NAVBAR_HEADER_PORTAL_TARGET_ID } from './NavbarHeaderMobile';

interface State {
    user: {
        user: User;
        isAuthenticated: boolean;
    };
}

const NavbarHeaderMobileContainer = connect(
    (state: State) => ({
        user: getUser(state),
        isLoggedIn: isAuthenticated(state),
        sensorsLink: routesManager.resolvePath('sensors:identify'),
    }),
    null,
)(NavbarHeaderMobile);

export { NavbarHeaderMobileContainer as default, NAVBAR_HEADER_PORTAL_TARGET_ID };
export type { NavbarHeaderMobileProps } from './NavbarHeaderMobile';
