import loadable from '@loadable/component';
import type { RouteConfig } from 'react-router-config';

import RenderChildren from 'components/RenderChildren';
import { userIsAuthenticated } from 'decorators/auth';

const ReportListView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-reports-report-list' */ 'views/reports/ReportsList'
        ),
);

export const createReportRoutes = (PageNotFoundRoute: RouteConfig): RouteConfig => ({
    path: '/reports/',
    name: 'reports',
    component: userIsAuthenticated(RenderChildren),
    routes: [
        {
            path: '/reports/',
            exact: true,
            name: 'list',
            pageName: 'Reports',
            component: ReportListView,
        },
        PageNotFoundRoute,
    ],
});
