import { AreaLineChart, buildHighchartsTooltip } from '@infogrid/components-highcharts';
import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import type { Options } from 'highcharts';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    getAxisTimeFormatByDataFrequency,
    getTooltipTimeFormatByDataFrequency,
} from 'views/dashboards/widgets/utils';

import Tooltip from '../../common/Tooltip';
import WidgetAlert from '../../common/WidgetAlert';
import type { DeskOccupancyData, DeskOccupancyWidget } from '../types';
import {
    tooltipPointColorExtractor,
    tooltipPointNameExtractor,
    tooltipPointValueExtractor,
} from './utils';

interface ContentProps {
    data?: DeskOccupancyData;
    isConfigured: boolean;
    isLoadingDataError: boolean;
    widget: DeskOccupancyWidget;
}

const Content = ({ data, isConfigured, isLoadingDataError, widget }: ContentProps) => {
    const { t } = useTranslation('dashboard');

    const {
        calculators,
        data_frequency: dataFrequency,
        time_zone: timeZone,
        time_period_from: startDate,
        time_period_to: endDate,
    } = widget?.configuration;

    const showDesksOccupied = calculators.desk_occupancy_percentage.show_desks_occupied;

    const transformedData = useMemo(
        () => [
            {
                name: t('Desk Occupancy'),
                data: data?.desk_occupancy_percentage_graph_rows?.map((item) => [
                    +new Date(item.timestamp),
                    Math.round((item.occupancy + Number.EPSILON) * 100) / 100,
                ]),
                desksOccupiedData: data?.desk_occupancy_percentage_graph_rows?.map(
                    (item) => item.desks_occupied,
                ),
                showDesksOccupied,
                turboThreshold: 0,
            },
        ],
        [data?.desk_occupancy_percentage_graph_rows, showDesksOccupied, t],
    );

    // @ts-expect-error: INS-781 Refactoring required for transformedData to conform to the
    // SeriesOptionsType type without breaking the chart
    const chartProps: Options = useMemo(() => {
        const timeFormat = getAxisTimeFormatByDataFrequency(
            dataFrequency,
            startDate,
            endDate,
        );
        const tooltipTimeFormat = getTooltipTimeFormatByDataFrequency(dataFrequency);

        return {
            xAxis: {
                labels: {
                    formatter({ chart }) {
                        // We know that this is a number, but the types don't as it can be a number or string
                        return chart.time.dateFormat(timeFormat, this.value as number);
                    },
                },
                startOnTick: false,
                type: 'datetime',
            },
            tooltip: {
                formatter({ chart }) {
                    return buildHighchartsTooltip({
                        content: (
                            <Tooltip
                                // @ts-expect-error: INS-781 Refactor tooltips to accept
                                // extended TooltipFormatterContextObject or pass in a smaller object
                                // related to the tooltip only
                                data={this}
                                pointColorExtractor={tooltipPointColorExtractor}
                                pointKeyExtractor={tooltipPointNameExtractor}
                                pointNameExtractor={tooltipPointNameExtractor}
                                pointValueExtractor={tooltipPointValueExtractor}
                            />
                        ),
                        periodLabel: chart.time.dateFormat(
                            tooltipTimeFormat,
                            this.points?.[0].x as number | undefined,
                        ),
                    });
                },
            },
            time: {
                timezone: timeZone,
            },
            series: transformedData,
        };
    }, [dataFrequency, startDate, endDate, timeZone, transformedData]);

    if (!isConfigured) {
        return (
            <WidgetAlert severity="warning">
                {t(WidgetErrorMessages.notConfigured)}
            </WidgetAlert>
        );
    }

    if (isLoadingDataError) {
        return (
            <WidgetAlert severity="error">
                {t(WidgetErrorMessages.dataLoadError)}
            </WidgetAlert>
        );
    }

    if (data?.no_data) {
        return <WidgetAlert severity="info">{t(WidgetErrorMessages.noData)}</WidgetAlert>;
    }

    return <AreaLineChart chartProps={chartProps} isPercentage />;
};

export default memo(Content);
