import { makeStyles } from '@material-ui/core';

export const useSelectStyles = makeStyles((theme) => ({
    selectContainer: {
        maxWidth: '200px',
    },
    selectContent: {
        '&:after': {
            display: 'none',
        },
        '&:before': {
            display: 'none',
        },
    },
    menuList: {
        paddingBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
        maxHeight: '380px',
        overflowY: 'auto',
    },
    renderValue: {
        marginRight: theme.spacing(1.5),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));
