import type { Floor, BuildingDetail } from '@infogrid/locations-types';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { Button, Divider, Icon, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AddFloorModal from 'views/estate/components/modals/AddFloorModal';

import FloorsList from '../components/FloorsList';
import { useMobileBuildingSidebarStyles } from './styles';

interface Props {
    building: BuildingDetail;
    floorId?: number;
    floors: Floor[];
    setSelectedSpaceId?: (id: number | null) => void;
}

const MobileBuildingSidebar = ({
    building,
    floorId,
    floors,
    setSelectedSpaceId,
}: Props): JSX.Element => {
    const styles = useMobileBuildingSidebarStyles();

    const { t } = useTranslation('estate');

    const [isOpen, _1, _2, toggleOpen] = useIsOpenState();

    const [isAddFloorModalOpen, _3, _4, toggleAddFloorModal] = useIsOpenState();

    const onFloorClick = () => {
        if (setSelectedSpaceId) {
            setSelectedSpaceId(null);
        }

        toggleOpen();
    };

    return (
        <div
            className={classNames(styles.container, {
                [styles.containerCollapsed]: !isOpen,
            })}
        >
            <div className={styles.header}>
                <div
                    className={styles.buildingContainer}
                    onClick={toggleOpen}
                    onKeyDown={toggleOpen}
                    role="button"
                >
                    <Typography className={styles.buildingName} variant="subtitle1">
                        {building.name}
                    </Typography>
                    <Icon
                        className={classNames(
                            `fal fa-chevron-${isOpen ? 'up' : 'down'}`,
                            styles.arrow,
                        )}
                    />
                </div>
                <Icon className={classNames('fa fa-ellipsis-h', styles.ellipsis)} />
            </div>
            <div className={styles.subheader}>
                <div className={styles.buildingNameButtonContainer}>
                    <Link className={styles.buildingsLink} to="/estate/">
                        <Button className={styles.actionButton} color="primary">
                            <ArrowBackIcon className={styles.leftArrow} />
                            <Typography className={styles.actionButtonBuildingName}>
                                {t('All buildings')}
                            </Typography>
                        </Button>
                    </Link>
                </div>
                <div className={styles.createNewFloorContainer}>
                    <Button
                        className={styles.actionButton}
                        color="primary"
                        onClick={toggleAddFloorModal}
                    >
                        <Icon
                            className={classNames(
                                'fal fa-plus',
                                styles.actionButtonIcon,
                                styles.createFloorIcon,
                            )}
                        />
                        {t('Add floor')}
                    </Button>
                </div>
            </div>
            <Divider />
            <FloorsList
                activeFloorId={floorId}
                building={building}
                floors={floors}
                onFloorClick={onFloorClick}
                showHeader={false}
            />
            {isAddFloorModalOpen && (
                <AddFloorModal
                    buildingId={building.id}
                    onClose={toggleAddFloorModal}
                    open={isAddFloorModalOpen}
                />
            )}
        </div>
    );
};

export default memo(MobileBuildingSidebar);
