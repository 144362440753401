import { MenuList } from '@infogrid/components-material-ui';
import type { NamedRouteConfig } from '@infogrid/core-types';
import { Select, ListItemText } from '@material-ui/core';
import type { Location } from 'history';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import type {
    IntegrationsMenuItem,
    IntegrationsMenuItemGroup,
} from 'views/integrations/pages/integrations/types';

import { useMobileIntegrationsSidebarStyles } from './styles';

interface MobileIntegrationsSidebarProps {
    menuitems: IntegrationsMenuItemGroup[];
    route?: NamedRouteConfig;
}

const MobileIntegrationsSidebar = ({
    menuitems,
    route,
}: MobileIntegrationsSidebarProps): JSX.Element | null => {
    const styles = useMobileIntegrationsSidebarStyles();

    const { t } = useTranslation('integrations');

    const location: Location = useLocation();

    const renderCurrentNavLink = useCallback(() => {
        const currentPath = location.pathname.replace(/\/$/, '');
        const activeRoute = route?.routes
            ? route.routes.find((r) => r.path === currentPath)
            : null;
        const linkText = activeRoute?.pageName || t('Select an integration to configure');

        return (
            <div className={styles.integrationItem}>
                <ListItemText className={styles.text}>{linkText}</ListItemText>
            </div>
        );
    }, [styles, location, route, t]);

    return (
        <Select
            renderValue={renderCurrentNavLink}
            displayEmpty={true}
            name="integrations-sidebar"
            inputProps={{ 'data-testid': 'integrations-sidebar' }}
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                marginThreshold: 0,
            }}
        >
            {menuitems.map((value: IntegrationsMenuItemGroup) => (
                <div key={value[0]}>
                    <MenuList>
                        <MenuList.Subheader text={value[0]} />
                        {value[1].map(({ name, url }: IntegrationsMenuItem) => (
                            <MenuList.Item
                                className={styles.noMargins}
                                key={name}
                                onClick={() => {}}
                            >
                                <NavLink
                                    to={url}
                                    className={styles.integrationItem}
                                    activeClassName={styles.integrationItemActive}
                                >
                                    <ListItemText className={styles.text}>
                                        {name}
                                    </ListItemText>
                                </NavLink>
                            </MenuList.Item>
                        ))}
                    </MenuList>
                </div>
            ))}
        </Select>
    );
};

export default memo(MobileIntegrationsSidebar);
