import { makeStyles } from '@material-ui/core';
import type { Layout } from 'react-grid-layout';

const DEFAULT_LAYOUT_HEIGHT_LEVEL = 1;
const BASE_HEIGHT = 240;
const WIDGET_MARGIN = 10;
const NON_CONTENT_WIDGET_HEIGHT = 115;

interface Props {
    layout?: Layout;
}

export const useWidgetContentStyles = makeStyles((theme) => ({
    root: ({ layout }: Props) => {
        const layoutHeight = layout?.h || DEFAULT_LAYOUT_HEIGHT_LEVEL;

        let maxHeight = BASE_HEIGHT * layoutHeight;

        if (layoutHeight > DEFAULT_LAYOUT_HEIGHT_LEVEL) {
            /**
             * INFO: include non chart areas (top / bottom of a widget)
             */
            maxHeight += (layoutHeight - 1) * NON_CONTENT_WIDGET_HEIGHT;
            /**
             * INFO: add margins between widgets
             */
            maxHeight += layoutHeight * WIDGET_MARGIN;
        }

        return {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
            maxHeight,
            padding: theme.spacing(2, 1.5, 0),
        };
    },
}));
