import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import type { EditFloorRequestPayload } from '@infogrid/locations-types';
import { useFormik } from 'formik';
import type { FormikErrors, FormikProps } from 'formik';
import { useCallback } from 'react';

import { useFormToast, useFloorPlanParams } from 'apiHooks/base/hooks';
import { useEditFloor, useFloor } from 'apiHooks/floorPlan/floors/hooks';
import {
    selectEditFloorId,
    selectEditingFloorStatus,
    toggleDeletingFloor,
    toggleEditingFloor,
} from 'ducks/floorPlan';

import { FLOOR_FORM_VALIDATION_SCHEMA } from '../../../constants/floorFormValidationSchema';
import EditFloorModal from './EditFloorModal';

const EditFloorContainer = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const isOpened = useAppSelector(selectEditingFloorStatus);

    const { handleFormToast } = useFormToast();

    const { buildingId } = useFloorPlanParams();
    const { mutate } = useEditFloor();

    const editFloorId = useAppSelector(selectEditFloorId);

    const { data: floor, isLoading } = useFloor(editFloorId);

    const onClose = useCallback(() => {
        dispatch(toggleEditingFloor(false));
    }, [dispatch]);

    const handleDelete = useCallback(() => {
        dispatch(toggleDeletingFloor(true));
    }, [dispatch]);

    const handleEditFloor = (
        values: EditFloorRequestPayload,
        {
            setErrors,
            setSubmitting,
        }: Pick<FormikProps<EditFloorRequestPayload>, 'setErrors' | 'setSubmitting'>,
    ) => {
        const payload = {
            name: values.name,
        };

        mutate(
            // TODO: remove when 'useEditFloor' is rewritten to TS
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            { data: payload, floorId: floor.id, buildingId },
            {
                onSuccess() {
                    onClose();
                },
                onError: (error: { parsedError: string }) => {
                    handleFormToast({
                        submitValues: values,
                        error: error.parsedError,
                        formCallback({
                            errors,
                        }: {
                            errors: FormikErrors<EditFloorRequestPayload>;
                        }) {
                            setErrors(errors);
                        },
                    });
                },
                onSettled() {
                    setSubmitting(false);
                },
            },
        );
    };

    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        handleReset,
    } = useFormik({
        onSubmit: handleEditFloor,
        enableReinitialize: true,
        initialValues: {
            name: floor?.name || '',
        },
        ...FLOOR_FORM_VALIDATION_SCHEMA,
    });

    return (
        <EditFloorModal
            errors={errors}
            touched={touched}
            isSubmitting={isSubmitting}
            isFetching={isLoading}
            floorName={values.name}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleDelete={handleDelete}
            onClose={onClose}
            isOpened={isOpened}
            handleReset={handleReset}
        />
    );
};

export default EditFloorContainer;
