import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getBuildingsUserActionsKey } from '../getQueryKeys';
import type { BuildingsUserActionsResponse } from '../types';

export const useBuildingsUserActions = (): UseQueryResult<
    BuildingsUserActionsResponse,
    AxiosParsedError
> => {
    return useQuery(getBuildingsUserActionsKey(), () =>
        wrapResponsePromise(controllers.getBuildingsUserActions()),
    );
};
