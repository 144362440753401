import { SELECTION_MODE } from '@infogrid/core-types';
import type { Folder, MoveFolder, SelectionMode } from '@infogrid/core-types';
import type { PayloadAction } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import takeRight from 'lodash/takeRight';
import type { match as Match } from 'react-router-dom';

import type { SensorQueryParams } from 'utils/filtering/sensor';

import { FOLDER_MOVE_PARAM_NAMES } from './filtering/folder';
import { SENSOR_BASE_FILTER_NAMES, SENSOR_PARAM_NAMES } from './filtering/sensor';

export const SHARED_WITH_ME_FOLDER_ID = 0;

interface MatchParams {
    folderId?: string;
}

interface ActionPayload {
    kwargs?: {
        pk: number;
    };
}

interface ActionMeta {
    keyOptions?: {
        folderId?: number;
    };
}

interface MutatedFolderKwargs {
    pk: number;
}

export const folderMutateKwargs = (
    match: Match<MatchParams>,
    action: PayloadAction<ActionPayload, string, ActionMeta>,
): MutatedFolderKwargs => {
    if (typeof action.payload?.kwargs?.pk !== 'undefined') {
        return action.payload.kwargs;
    }

    let folderId = SHARED_WITH_ME_FOLDER_ID;

    if (action?.meta?.keyOptions?.folderId !== undefined) {
        folderId = action.meta.keyOptions?.folderId;
    } else if (match?.params?.folderId) {
        folderId = parseInt(match?.params?.folderId);
    }

    return { pk: folderId };
};

export const isFolderNotEmpty = (folder?: Folder): boolean => {
    if (!folder) {
        return false;
    }

    const hasSubfolders = !!folder.subfolder_count;
    const hasSensors = !!folder.sensors_count;

    return hasSubfolders || hasSensors;
};

/**
 * Concatenate folder names to a specified depth.
 * @param {string[]} folders - Array of folder names
 * @param {number} depth - how many folders to add to array
 * @param {string} separator - string to use to separate the folder names
 * @returns {string}
 */
export const concatFolderNameFromFolders = (
    folders: string[],
    depth = 1,
    separator = '|',
): string => {
    return takeRight(folders, depth).join(` ${separator} `);
};

export interface MoveFolderPayloadSource {
    originId: number;
    selectionMode: SelectionMode;
    selectedSensorDeviceNames: string[];
    excludedSensorDeviceNames: string[];
    selectedFolderIds: number[];
    sensorQuery?: SensorQueryParams;
}

const isPayloadParamValid = (param: unknown): boolean => {
    if (
        isNil(param) ||
        isUndefined(param) ||
        param === '' ||
        (Array.isArray(param) && param.length === 0)
    ) {
        return false;
    }

    return true;
};

export const getMoveFolderDataPayload = ({
    originId,
    selectionMode,
    selectedSensorDeviceNames,
    excludedSensorDeviceNames,
    selectedFolderIds,
    sensorQuery = {},
}: MoveFolderPayloadSource): MoveFolder => {
    const isAllAvailableSelected = selectionMode === SELECTION_MODE.ALL_AVAILABLE;
    const isFilteringSensors = !isEmpty(omit(sensorQuery, SENSOR_PARAM_NAMES.ORDER_BY));

    const sensorFilters = pick(sensorQuery, Object.values(SENSOR_BASE_FILTER_NAMES));

    const folderParams = pickBy(
        {
            [FOLDER_MOVE_PARAM_NAMES.FOLDER]: isAllAvailableSelected ? originId : null,
            [FOLDER_MOVE_PARAM_NAMES.SENSORS]: isAllAvailableSelected
                ? undefined
                : selectedSensorDeviceNames,
            [FOLDER_MOVE_PARAM_NAMES.RECURSIVE]: isFilteringSensors ? true : undefined,
            [FOLDER_MOVE_PARAM_NAMES.EXCLUDE_SENSORS]: excludedSensorDeviceNames,
            [FOLDER_MOVE_PARAM_NAMES.FOLDERS_TO_MOVE]: selectedFolderIds,
            [FOLDER_MOVE_PARAM_NAMES.MOVE_SENSORS]:
                isAllAvailableSelected || !!selectedSensorDeviceNames.length,
        },
        isPayloadParamValid,
    );

    return {
        ...sensorFilters,
        ...folderParams,
    };
};
