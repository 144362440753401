import { Card, CardContent, Icon, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { useStylesBaseCard } from './styles';

const ErrorBaseCard = ({
    title,
    cardIcon,
    buttonsContainer,
    primaryDescription,
    secondaryDescription,
}) => {
    const cardStyle = useStylesBaseCard();

    return (
        <Card className={cardStyle.floorPlanCard}>
            <CardContent className={cardStyle.floorPlanCardContainer}>
                <div className={cardStyle.floorPlanCardContent}>
                    <Icon className={classNames(cardIcon, cardStyle.floorPlanIcon)} />

                    <Typography variant="h6" className={cardStyle.floorPlanCardTitle}>
                        {title}
                    </Typography>

                    <Typography variant="body1" className={cardStyle.floorPlanCardText}>
                        {primaryDescription}
                    </Typography>

                    <Typography variant="body1" className={cardStyle.floorPlanCardText}>
                        {secondaryDescription}
                    </Typography>

                    {buttonsContainer}
                </div>
            </CardContent>
        </Card>
    );
};

ErrorBaseCard.propTypes = {
    title: PropTypes.string,
    cardIcon: PropTypes.string,
    primaryDescription: PropTypes.string,
    secondaryDescription: PropTypes.string,
    buttonsContainer: PropTypes.node,
};
ErrorBaseCard.defaultProps = {
    cardIcon: '',
    title: null,
    primaryDescription: null,
    secondaryDescription: null,
    buttonsContainer: null,
};

export default memo(ErrorBaseCard);
