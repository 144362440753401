import { useAppSelector } from '@infogrid/core-ducks';
import {
    getFormattedElectricityReadingByEventType,
    getElectricityIcon,
} from '@infogrid/sensors-configuration';
import { SENSOR_EVENT_TYPES_V2 } from '@infogrid/sensors-constants';
import type {
    SensorEventType,
    SensorEventShape,
    SensorStatusEvent,
    SensorConfigurationShape,
    ElectricitySensorConfigurationShape,
    SensorConfigurationWithWorkingHours,
} from '@infogrid/sensors-constants';
import {
    ELECTRICITY_SENSOR_TYPES_EVENTSV2,
    getElectricityUnit,
} from '@infogrid/solution-views-electricity';
import { selectActiveTimeZone } from '@infogrid/user-ducks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getIsDefaultSensorWorkingHoursSettings } from 'forms/SensorConfiguration/SensorConfigurationComponent/ElectricitySensorConfiguration/WorkingHoursConfiguration';
import type { TimeRange } from 'utils/types/ts/app';

import {
    getAverageHourlyElectricityReadingsInWorkingHoursCalculator,
    getAverageHourlyElectricityReadingsInNonWorkingHoursCalculator,
    getAverageDailyElectricityReadingsInWorkingDaysCalculator,
    getAverageDailyElectricityReadingsInNonWorkingDaysCalculator,
} from './calculators';
import type {
    EventOverview,
    EventsOverview,
    ElectricityEventOverview,
    ElectricityEvent,
} from './types';
import {
    isRangeWithinWorkingHours,
    isRangeWithinNonWorkingHours,
    getReadingPercentage,
    isRangeLongerThanDay,
    getReadingIntervalInMinutes,
} from './utils';

export const ElectricityAverageWorkingHoursConsumption = ({
    sensorEvents,
    timeRange,
    t,
    selectedUnit,
    isEventsV2,
    sensorConfiguration,
}: ElectricityEventOverview): SensorStatusEvent => {
    const { working_hours: workingHoursConfiguration, time_zone: sensorTimeZone } =
        sensorConfiguration;

    const calculator = getAverageHourlyElectricityReadingsInWorkingHoursCalculator(
        !!isEventsV2,
    );

    const intervalInMinutes = getReadingIntervalInMinutes(sensorConfiguration);

    const reading = calculator({
        sensorEvents,
        workingHoursConfiguration,
        timeZone: sensorTimeZone,
        intervalInMinutes,
    });

    const showReading =
        timeRange &&
        isRangeWithinWorkingHours({
            from: timeRange.from,
            to: timeRange.to,
            timeZone: sensorTimeZone,
            workingHoursConfiguration,
        }) &&
        !!reading;

    const isDefaultWorkingHoursSettings = getIsDefaultSensorWorkingHoursSettings(
        (sensorConfiguration as SensorConfigurationWithWorkingHours)?.working_hours,
    );

    const labelTooltip = isDefaultWorkingHoursSettings
        ? t('This sensor’s working hours are set to between 9:00 and 17:00.')
        : t(
              'This sensor’s working hours are set to custom values. These can be seen on the Configuration & Details screen.',
          );

    const label: Record<ElectricityEvent, string> = {
        [SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE_EQUIVALENT]: t(
            'Working hours: Average CO2e emissions',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS_PER_M2]: t(
            'Working hours: Average consumption per m2',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: t('Working hours: Average consumption'),
    };

    const electricityUnit = (selectedUnit ??
        SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS) as ElectricityEvent;

    return {
        icon: getElectricityIcon(selectedUnit as SensorEventType),
        label: label[electricityUnit],
        labelTooltip,
        reading: showReading
            ? getFormattedElectricityReadingByEventType(
                  selectedUnit as SensorEventType,
                  reading,
                  sensorConfiguration as ElectricitySensorConfigurationShape,
              )
            : '-',
        readingExtra: showReading
            ? undefined
            : t('Select range covering events within working hours'),
        readingUnit: showReading
            ? getElectricityUnit(selectedUnit as SensorEventType, t)
            : undefined,
        key: 'electricityAverageWorkingHoursConsumption',
    };
};

export const ElectricityAverageNonWorkingHoursConsumption = ({
    sensorEvents,
    timeRange,
    timeZone,
    t,
    selectedUnit,
    isEventsV2,
    sensorConfiguration,
}: ElectricityEventOverview): SensorStatusEvent => {
    const { working_hours: workingHoursConfiguration, time_zone: sensorTimeZone } =
        sensorConfiguration;

    const intervalInMinutes = getReadingIntervalInMinutes(sensorConfiguration);

    const calculator = getAverageHourlyElectricityReadingsInNonWorkingHoursCalculator(
        !!isEventsV2,
    );

    const reading = calculator({
        sensorEvents,
        timeZone: sensorTimeZone,
        workingHoursConfiguration,
        intervalInMinutes,
    });

    const showReading =
        timeRange &&
        isRangeWithinNonWorkingHours({
            from: timeRange.from,
            to: timeRange.to,
            timeZone,
            workingHoursConfiguration: (
                sensorConfiguration as SensorConfigurationWithWorkingHours
            ).working_hours,
        }) &&
        !!reading;

    const isDefaultWorkingHoursSettings = getIsDefaultSensorWorkingHoursSettings(
        (sensorConfiguration as SensorConfigurationWithWorkingHours)?.working_hours,
    );

    const label: Record<ElectricityEvent, string> = {
        [SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE_EQUIVALENT]: t(
            'Non-working hours: Average CO2e emissions',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS_PER_M2]: t(
            'Non-working hours: Average consumption per m2',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: t(
            'Non-working hours: Average consumption',
        ),
    };

    const labelTooltip = isDefaultWorkingHoursSettings
        ? t('This sensor’s non-working hours are set to between 17:00 and 9:00.')
        : t(
              'This sensor’s non-working hours are set to custom values. These can be seen on the Configuration & Details screen.',
          );

    const electricityUnit = (selectedUnit ??
        SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS) as ElectricityEvent;

    return {
        icon: getElectricityIcon(selectedUnit as SensorEventType),
        label: label[electricityUnit],
        labelTooltip,
        reading: showReading
            ? getFormattedElectricityReadingByEventType(
                  selectedUnit as SensorEventType,
                  reading,
                  sensorConfiguration as ElectricitySensorConfigurationShape,
              )
            : '-',
        readingExtra: showReading
            ? undefined
            : t('Select range covering events within non-working hours'),
        readingUnit: showReading
            ? getElectricityUnit(selectedUnit as SensorEventType, t)
            : undefined,
        key: 'electricityAverageNonWorkingHoursConsumption',
    };
};

export const ElectricityAveragePercentageHoursConsumption = ({
    sensorEvents,
    t,
    selectedUnit,
    isEventsV2,
    sensorConfiguration,
}: ElectricityEventOverview): SensorStatusEvent => {
    const { working_hours: workingHoursConfiguration, time_zone: sensorTimeZone } =
        sensorConfiguration;

    const intervalInMinutes = getReadingIntervalInMinutes(sensorConfiguration);

    const workingHoursReadingCalculator =
        getAverageHourlyElectricityReadingsInWorkingHoursCalculator(!!isEventsV2);

    const workingHoursReading = workingHoursReadingCalculator({
        sensorEvents,
        timeZone: sensorTimeZone,
        workingHoursConfiguration,
        intervalInMinutes,
    });

    const outOfHoursReadingCalculator =
        getAverageHourlyElectricityReadingsInNonWorkingHoursCalculator(!!isEventsV2);

    const outOfHoursReading = outOfHoursReadingCalculator({
        sensorEvents,
        workingHoursConfiguration,
        timeZone: sensorTimeZone,
        intervalInMinutes,
    });

    const showReading = !!workingHoursReading && !!outOfHoursReading;

    const label: Record<ElectricityEvent, string> = {
        [SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE_EQUIVALENT]: t(
            'Non-working hours CO2e emissions as % of working hours CO2e emissions',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS_PER_M2]: t(
            'Non-working hours consumption per m2 as % of working hours consumption per m2',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: t(
            'Non-working hours consumption as % of working hours consumption',
        ),
    };

    const labelTooltip: Record<ElectricityEvent, string> = {
        [SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE_EQUIVALENT]: t(
            'Average CO2e emissions per hour during non-working hours as a % of average CO2e emissions per hour during working hours.',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS_PER_M2]: t(
            'Average consumption per m2 per hour during non-working hours as a % of average consumption per m2 per hour during working hours.',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: t(
            'Average consumption per hour during non-working hours as a % of average consumption per hour during working hours.',
        ),
    };

    const electricityUnit = (selectedUnit ??
        SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS) as ElectricityEvent;

    return {
        icon: 'fa-chart-pie-alt',
        label: label[electricityUnit],
        labelTooltip: labelTooltip[electricityUnit],
        reading: showReading
            ? getReadingPercentage({
                  value: outOfHoursReading,
                  source: workingHoursReading,
              })
            : '-',
        readingUnit: showReading ? t('%') : undefined,
        readingExtra: showReading
            ? undefined
            : t('Select range covering at least one day'),
        key: 'electricityAveragePercentageHoursConsumption',
    };
};

export const ElectricityAverageWorkingDaysConsumption = ({
    sensorEvents,
    timeRange,
    t,
    selectedUnit,
    isEventsV2,
    sensorConfiguration,
}: ElectricityEventOverview): SensorStatusEvent => {
    const { working_hours: workingHoursConfiguration, time_zone: sensorTimeZone } =
        sensorConfiguration as SensorConfigurationWithWorkingHours;

    const calculator = getAverageDailyElectricityReadingsInWorkingDaysCalculator(
        !!isEventsV2,
    );

    const reading = calculator({
        sensorEvents,
        timeZone: sensorTimeZone,
        timeRange,
        workingHoursConfiguration,
    });

    const showReading =
        timeRange &&
        isRangeLongerThanDay({
            from: timeRange.from,
            to: timeRange.to,
        }) &&
        !!reading;

    const label: Record<ElectricityEvent, string> = {
        [SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE_EQUIVALENT]: t(
            'Working day: Average emissions per day',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS_PER_M2]: t(
            'Working day: Average consumption per m2 per day',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: t(
            'Working day: Average consumption per day',
        ),
    };

    const electricityUnit = (selectedUnit ??
        SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS) as ElectricityEvent;

    return {
        icon: getElectricityIcon(selectedUnit as SensorEventType),
        label: label[electricityUnit],
        reading: showReading
            ? getFormattedElectricityReadingByEventType(
                  selectedUnit as SensorEventType,
                  reading,
                  sensorConfiguration as ElectricitySensorConfigurationShape,
              )
            : '-',
        readingUnit: showReading
            ? getElectricityUnit(selectedUnit as SensorEventType, t)
            : undefined,
        readingExtra: showReading
            ? undefined
            : t('Select range covering at least one day with events on working days'),
        key: 'electricityAverageWorkingDaysConsumption',
    };
};

export const ElectricityAverageNonWorkingDaysConsumption = ({
    sensorEvents,
    timeRange,
    t,
    selectedUnit,
    isEventsV2,
    sensorConfiguration,
}: ElectricityEventOverview): SensorStatusEvent => {
    const { working_hours: workingHoursConfiguration, time_zone: sensorTimeZone } =
        sensorConfiguration as SensorConfigurationWithWorkingHours;

    const calculator = getAverageDailyElectricityReadingsInNonWorkingDaysCalculator(
        !!isEventsV2,
    );
    const reading = calculator({
        sensorEvents,
        timeZone: sensorTimeZone,
        timeRange,
        workingHoursConfiguration,
    });
    const showReading =
        timeRange &&
        isRangeLongerThanDay({
            from: timeRange.from,
            to: timeRange.to,
        }) &&
        !!reading;

    const label: Record<ElectricityEvent, string> = {
        [SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE_EQUIVALENT]: t(
            'Non-working day: Average CO2e emissions per day',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS_PER_M2]: t(
            'Non-working day: Average consumption per m2 per day',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: t(
            'Non-working day: Average consumption per day',
        ),
    };

    const electricityUnit = (selectedUnit ??
        SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS) as ElectricityEvent;

    return {
        icon: getElectricityIcon(selectedUnit as SensorEventType),
        label: label[electricityUnit],
        reading: showReading
            ? getFormattedElectricityReadingByEventType(
                  selectedUnit as SensorEventType,
                  reading,
                  sensorConfiguration as ElectricitySensorConfigurationShape,
              )
            : '-',
        readingUnit: showReading
            ? getElectricityUnit(selectedUnit as SensorEventType, t)
            : undefined,
        readingExtra: showReading
            ? undefined
            : t('Select range covering at least one day with events on non-working days'),
        key: 'electricityAverageNonWorkingDayConsumption',
    };
};

export const ElectricityAveragePercentageDaysConsumption = ({
    sensorEvents,
    timeRange,
    t,
    selectedUnit,
    isEventsV2,
    sensorConfiguration,
}: ElectricityEventOverview): SensorStatusEvent => {
    const { working_hours: workingHoursConfiguration, time_zone: sensorTimeZone } =
        sensorConfiguration as SensorConfigurationWithWorkingHours;

    const workingDaysReadingCalculator =
        getAverageDailyElectricityReadingsInWorkingDaysCalculator(!!isEventsV2);

    const workingDaysReading = workingDaysReadingCalculator({
        sensorEvents,
        timeZone: sensorTimeZone,
        timeRange,
        workingHoursConfiguration,
    });

    const nonWorkingDaysReadingCalculator =
        getAverageDailyElectricityReadingsInNonWorkingDaysCalculator(!!isEventsV2);

    const nonWorkingDaysReading = nonWorkingDaysReadingCalculator({
        sensorEvents,
        timeZone: sensorTimeZone,
        timeRange,
        workingHoursConfiguration,
    });

    const showReading =
        timeRange &&
        isRangeLongerThanDay({
            from: timeRange.from,
            to: timeRange.to,
        }) &&
        !!workingDaysReading &&
        !!nonWorkingDaysReading;

    const isDefaultWorkingHoursSettings = getIsDefaultSensorWorkingHoursSettings(
        (sensorConfiguration as SensorConfigurationWithWorkingHours)?.working_hours,
    );

    const electricityUnit = (selectedUnit ??
        SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS) as ElectricityEvent;

    const labelTooltipForDefaultSettings: Record<ElectricityEvent, string> = {
        [SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE_EQUIVALENT]: t(
            'Average CO2e emissions on a non-working day as a % of average CO2e emissions on week day (Mon-Fri).',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS_PER_M2]: t(
            'Average consumption per m2 on a non-working day as a % of average consumption per m2 on week day (Mon-Fri).',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: t(
            'Average consumption on a non-working day as a % of average consumption on week day (Mon-Fri).',
        ),
    };

    const labelTooltipForCustomSettings: Record<ElectricityEvent, string> = {
        [SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE_EQUIVALENT]: t(
            'Average CO2e emissions on a non-working day as a % of average CO2e emissions on a working day.',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS_PER_M2]: t(
            'Average consumption per m2 on a non-working day as a % of average consumption per m2 on a working day.',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: t(
            'Average consumption on a non-working day as a % of average consumption on a working day.',
        ),
    };

    const labelTooltip = (
        isDefaultWorkingHoursSettings
            ? labelTooltipForDefaultSettings
            : labelTooltipForCustomSettings
    )[electricityUnit];

    const label: Record<ElectricityEvent, string> = {
        [SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE_EQUIVALENT]: t(
            'Total non-working day CO2e emissions as a % of total working day CO2e emissions',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS_PER_M2]: t(
            'Total non-working day consumption per m2 as a % of total working day consumption per m2',
        ),
        [SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS]: t(
            'Total non-working day consumption as a % of total working day consumption',
        ),
    };

    return {
        icon: 'fa-chart-pie-alt',
        label: label[electricityUnit],
        labelTooltip,
        reading: showReading
            ? getReadingPercentage({
                  value: nonWorkingDaysReading,
                  source: workingDaysReading,
              })
            : '-',
        readingUnit: showReading ? t('%') : undefined,
        readingExtra: showReading
            ? undefined
            : t(
                  'Select range covering at least one day with events on both working and non-working days',
              ),
        key: 'electricityAveragePercentageDayConsumption',
    };
};

export const useEventsOverview = ({
    sensorType,
    sensorEvents,
    eventsTypes,
    timeRange,
    selectedUnit,
    sensorConfiguration,
}: {
    sensorType: string;
    sensorEvents: SensorEventShape[];
    timeRange?: TimeRange;
    eventsTypes:
        | {
              [x: string]: (props: EventOverview) => SensorStatusEvent;
          }
        | null
        | undefined;
    selectedUnit?: SensorEventType;
    sensorConfiguration?: SensorConfigurationShape;
}): EventsOverview => {
    const { t, i18n } = useTranslation('sensor-events');
    const locale = i18n.language;
    const timeZone = useAppSelector(selectActiveTimeZone);
    const isEventsV2 = (ELECTRICITY_SENSOR_TYPES_EVENTSV2 as string[]).includes(
        sensorType,
    );

    return useMemo(
        () =>
            Object.fromEntries(
                Object.entries(eventsTypes || {}).map(
                    ([eventTypeKey, getEventConfiguration]) => {
                        return [
                            eventTypeKey,
                            getEventConfiguration({
                                sensorEvents,
                                timeRange,
                                timeZone,
                                t,
                                locale,
                                selectedUnit,
                                isEventsV2,
                                sensorConfiguration,
                            }),
                        ];
                    },
                ),
            ),
        [
            eventsTypes,
            sensorEvents,
            timeRange,
            timeZone,
            t,
            locale,
            selectedUnit,
            isEventsV2,
            sensorConfiguration,
        ],
    );
};
