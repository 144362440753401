import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useEstateFloorViewHeaderStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: 80,
        padding: theme.spacing(0, 3),
        background: theme.palette.common.white,
    },
    leftContainer: {
        alignItems: 'center',
        display: 'flex',
        padding: theme.spacing(0.5, 0),
    },
    floorName: {
        display: '-webkit-box',
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
        overflow: 'hidden',
        wordBreak: 'break-all',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
    },
    addSpaceButton: {
        whiteSpace: 'nowrap',
    },
    addSpaceIcon: {
        alignItems: 'center',
        display: 'flex',
        fontSize: 14,
        height: 16,
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
    },
    viewFloorPlanButton: {
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
}));
