import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import PipeMonitoringPreview from 'styles/images/widgets/pipemonitoring-atrisk.png';

import PipeMonitoringAtRiskWidget from './PipeMonitoringAtRiskWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('Building Water Safety', { ns: 'dashboard' }),
    type: WIDGET_TYPE.PIPE_MONITORING_RISK,
    featureFlags: [],
    Component: PipeMonitoringAtRiskWidget,
    image: PipeMonitoringPreview,
    order: 9,
    gridConfig: DEFAULT_GRID_ITEM_CONFIG,
};

export default PipeMonitoringAtRiskWidget;
