import type { InstallationFlowStep } from './constants';
import { INSTALLATION_FLOW_INITIAL_PATH } from './context';

const StepsManager = {
    setStep: (
        path: InstallationFlowStep[],
        step?: InstallationFlowStep,
    ): InstallationFlowStep[] => {
        if (!step) {
            throw Error('Step must be provided');
        }

        return path.concat(step);
    },
    goBack: (path: InstallationFlowStep[]): InstallationFlowStep[] => {
        if (path.length) {
            return path.slice(0, path.length - 1);
        }

        return INSTALLATION_FLOW_INITIAL_PATH;
    },
    getStep: (path: InstallationFlowStep[]): InstallationFlowStep => {
        return path[path.length - 1];
    },
};

export default StepsManager;
