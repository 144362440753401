import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import WidgetAlert from '../../common/WidgetAlert';
import type { CO2ConcentrationWidgetData, CO2ConcentrationWidget } from '../types';
import { isValidConfiguration } from '../utils';
import Content from './Content';

interface Props {
    isLoadingDataError: boolean;
    data: CO2ConcentrationWidgetData | undefined;
    widget: CO2ConcentrationWidget;
}

const ContentContainer = ({ isLoadingDataError, data, widget }: Props) => {
    const { t } = useTranslation('dashboard');

    if (!isValidConfiguration(widget)) {
        return (
            <WidgetAlert severity="warning">
                {t(WidgetErrorMessages.notConfigured)}
            </WidgetAlert>
        );
    }

    if (isLoadingDataError || !data) {
        return (
            <WidgetAlert severity="error">
                {t(WidgetErrorMessages.dataLoadError)}
            </WidgetAlert>
        );
    }

    if (data && data?.sensor_count === 0) {
        return (
            <WidgetAlert severity="warning">
                {t(
                    'Sensors for this widget have not been installed in any of the locations you have selected. Please make a new selection in the widget configuration.',
                )}
            </WidgetAlert>
        );
    }

    const iconeIndex = data?.icone_index;

    if (typeof iconeIndex !== 'number') {
        return (
            <WidgetAlert severity="warning">
                {t(
                    'We need to analyse 24 hours of data to show this score. Please check again tomorrow.',
                )}
            </WidgetAlert>
        );
    }

    return <Content iconeIndex={iconeIndex} />;
};

export default memo(ContentContainer);
