import { Header } from '@infogrid/components-material-ui';
import { routesManager } from '@infogrid/core-routing';
import {
    deleteDashboard,
    setDashboard,
    useDashboard,
    useDashboards,
} from '@infogrid/dashboards-hooks';
import { useIsMobile } from '@infogrid/utils-hooks';
import { CircularProgress } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { RouteConfig } from 'react-router-config';
import { useParams, useHistory } from 'react-router-dom';

import PageContainer from 'components/PageContainer';
import withView from 'decorators/withView';
import AddWidget from 'views/dashboards/components/AddWidget';
import DashboardHeaderContent from 'views/dashboards/components/DashboardHeaderContent';
import WidgetsGrid from 'views/dashboards/components/WidgetsGrid';
import { DashboardProvider } from 'views/dashboards/utils/context';
import { canChangeDashboard, hasUserPermissions } from 'views/dashboards/utils/helpers';

import { useDashboardPageStyles } from './styles';

interface Props {
    route: RouteConfig;
}

const DEFAULT_DASHBOARD_ID = 'default';

const Dashboard = ({ route }: Props) => {
    const isMobile = useIsMobile();
    const { t } = useTranslation('dashboard');

    const history = useHistory();
    const { dashboardId } = useParams<{ dashboardId: string }>();

    const styles = useDashboardPageStyles();

    const id = Number(dashboardId) || DEFAULT_DASHBOARD_ID;

    const {
        data: dashboard,
        isLoading,
        isSuccess: isDashboardFetched,
    } = useDashboard(id, {
        onSuccess: (d) => {
            if (id === DEFAULT_DASHBOARD_ID) {
                deleteDashboard(id);
                setDashboard(d.id, d);
                history.replace(
                    routesManager.resolvePath('dashboards:details', {
                        dashboardId: d.id,
                    }),
                );
            }
        },
        onError: (err) => {
            if (err?.response?.status === 404) {
                history.push('/not-found');
            }
        },
    });

    const { data: dashboards, isLoading: isDashboardsLoading } = useDashboards();

    return (
        <DashboardProvider>
            <Helmet defaultTitle={t('Dashboard')} />

            <Header
                enableIdentifySensor={false}
                pageName={t(route.pageName)}
                renderPageNameRowContent={() => {
                    return (
                        dashboard && (
                            <DashboardHeaderContent
                                isDashboardsLoading={isDashboardsLoading}
                                dashboards={dashboards}
                                dashboardId={dashboard.id}
                                selectedDashboard={dashboard}
                            />
                        )
                    );
                }}
            >
                {!isMobile &&
                    dashboard &&
                    hasUserPermissions(dashboard) &&
                    canChangeDashboard(dashboard) && (
                        <AddWidget dashboardId={dashboard.id} />
                    )}
            </Header>

            <PageContainer className={styles.pageContainer}>
                {isLoading && (
                    <div className={styles.spinnerContainer}>
                        <CircularProgress color="primary" />
                    </div>
                )}

                {!isLoading && dashboard && (
                    <WidgetsGrid
                        key={dashboard.id}
                        dashboard={dashboard}
                        isDashboardFetched={isDashboardFetched}
                    />
                )}
            </PageContainer>
        </DashboardProvider>
    );
};

export default withView()(Dashboard);
