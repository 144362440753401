import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const usePublicDisplayHeaderStyles = makeStyles((theme) => ({
    subtitle: {
        color: theme.palette.common.white,
        fontSize: 80,
        fontWeight: theme.typography.fontWeightLight as FontWeightProperty,
        lineHeight: '101px',
    },
    time: {
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightLight as FontWeightProperty,
        marginTop: theme.spacing(5.5),
    },
    title: {
        color: theme.palette.common.white,
        fontSize: 200,
        lineHeight: '251px',
        marginBottom: theme.spacing(2),
    },
    titleBlockContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(10),
    },
}));
