import noop from 'lodash/noop';
import PropTypes from 'prop-types';

export const configureWidgetModalProps = {
    initialValues: PropTypes.shape({}),
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    onUpdateConfiguration: PropTypes.func,
    open: PropTypes.bool,
    prepareConfigurationPayload: PropTypes.func,
    prepareInitialValues: PropTypes.func,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            component: PropTypes.elementType.isRequired,
            attributes: PropTypes.shape({}),
        }),
    ),
    validationSchema: PropTypes.shape({}),
    classes: PropTypes.shape({
        content: PropTypes.string,
    }),
};

export const configureWidgetModalDefaultProps = {
    initialValues: {},
    loading: false,
    onClose: noop,
    onUpdateConfiguration: noop,
    open: false,
    prepareConfigurationPayload: null,
    prepareInitialValues: null,
    tabs: [],
    validationSchema: null,
    classes: { content: '' },
};
