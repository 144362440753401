import { HEADER_HEIGHT } from '@infogrid/components-material-ui';
import { makeStyles } from '@material-ui/core';

export const useStylesFloor = makeStyles((theme) => ({
    floor: {
        display: 'flex',
        width: '100%',
    },
    mapContainer: {
        flexGrow: 1,
    },
    sensorsListContainer: () => {
        const height = `calc(100vh - ${HEADER_HEIGHT}px)`;

        return {
            display: 'flex',
            flexDirection: 'column',
            height,
            maxHeight: height,
            overflow: 'hidden',
            backgroundColor: theme.palette.common.shell,
            position: 'sticky',
            top: HEADER_HEIGHT,
            boxShadow: theme.shadows[1],
        };
    },

    sensorsListTabsMenu: {
        position: 'relative',
        backgroundColor: theme.palette.background.default,

        '& .MuiTabs-indicator': {
            zIndex: 1,
        },

        '&:after': {
            position: 'absolute',
            bottom: 0,

            width: '100%',
            height: 2,
            backgroundColor: theme.palette.common.shell,
            content: '""',
        },
    },

    mapLoaderContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    mapLoaderText: {
        color: theme.palette.text.grey.default,
        marginTop: theme.spacing(3),
    },

    mapCustomControlsContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    controlIcon: {
        fontSize: '21px',
        color: theme.palette.text.grey.default,
        lineHeight: '1.1em',
        width: '1.2em',
    },
    deleteSensorBtn: {
        fontSize: '14px',
        lineHeight: '20px',
        marginRight: '15px',
    },
    hintsIcon: {
        color: theme.palette.primary.main,
        margin: `0 ${theme.spacing(1.5)}px`,
    },
    mapCustomControlsBtns: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    sensorIconSizeSlider: {
        width: '110px',
    },
    sensorsChecklist: {
        width: '220px',
    },
}));
