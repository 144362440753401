import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppSelector, useAppDispatch, toastError } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { selectOrganizationId } from '@infogrid/user-ducks';
import { download } from '@infogrid/utils-file';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import { controllers } from '../controllers';

const TOAST_MESSAGES = {
    DEFAULT_ERROR_MESSAGE: registerTranslationKey(
        'Failed to download sensor asset template',
        {
            ns: 'integrations',
        },
    ),
    SUCCESS_MESSAGE: registerTranslationKey('Download complete', { ns: 'integrations' }),
    IN_PROGRESS_MESSAGE: registerTranslationKey('Preparing sensor asset template', {
        ns: 'integrations',
    }),
};

export const useSensorTemplateDownload = (): UseMutationResult<
    BlobPart,
    AxiosParsedError
> => {
    const organizationId = useAppSelector(selectOrganizationId);
    const dispatch = useAppDispatch();

    const { t } = useTranslation('integrations');

    return useMutationWithToast(
        () => wrapResponsePromise(controllers.downloadSensorSpreadsheet(organizationId)),
        {
            onSuccess: (result) => {
                const url = window.URL.createObjectURL(new Blob([result]));

                download(
                    url,
                    `Sensor asset template ${moment().format(
                        'YYYY[-]MM[-]DD HHmmss',
                    )}.xlsx`,
                );
            },
            onError: () => {
                dispatch(
                    toastError({
                        message: t(TOAST_MESSAGES.DEFAULT_ERROR_MESSAGE),
                    }),
                );
            },
        },
        {
            toastMessage: t(TOAST_MESSAGES.IN_PROGRESS_MESSAGE),
        },
    );
};
