import {
    wrapResponsePromise,
    INFINITE_QUERY_DEFAULT_INITIAL_DATA,
} from '@infogrid/core-api';
import type { AxiosParsedError, IPagination } from '@infogrid/core-types';
import type { SensorShape } from '@infogrid/sensors-constants';
import { useRefetchOnLanguageChange } from '@infogrid/utils-i18n';
import { useEffect } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import type { UseInfiniteQueryOptions, QueryKey, InfiniteData } from 'react-query';
import { useUpdateEffect } from 'react-use';

import { mutateSensor } from 'utils/sensor';

import { controllers } from '../controllers';
import { getSensorListFullKey, getSensorsLatestEventsKey } from '../getQueryKeys';
import { pollingSensorsEventsOnFetch } from '../polling';
import { selectSensors } from '../selectors';
import { useLatestSensorsEvents } from './useLatestSensorsEvents';
import type { SensorEvent } from './useLatestSensorsEvents';

type UseInfiniteSensorListOptions = UseInfiniteQueryOptions<
    IPagination<SensorShape>,
    AxiosParsedError
>;

const useInfiniteSensorListQuery = (
    queryKey: QueryKey,
    params?: { [key: string]: unknown },
    options?: UseInfiniteSensorListOptions,
) => {
    const { refetch, data, ...rest } = useInfiniteQuery(
        queryKey,
        ({ signal, pageParam = {} }) =>
            wrapResponsePromise(
                controllers.getSensorList(signal, { ...params, ...pageParam }),
                ({ data: responseData }) => ({
                    count: responseData.count,
                    sensors: (responseData.results ?? []).map((sensor) =>
                        mutateSensor(sensor),
                    ),
                    next: responseData.next,
                    results: responseData.results || [],
                    previous: responseData.previous,
                }),
            ),
        {
            initialData: INFINITE_QUERY_DEFAULT_INITIAL_DATA,
            keepPreviousData: true,
            getNextPageParam: (lastPage) => lastPage?.next ?? undefined,
            select: selectSensors,
            ...options,
        },
    );

    // Reload the sensor list on language change because we want to translate the sensor state information
    // that comes from back-end
    useRefetchOnLanguageChange(refetch, options?.enabled ?? true);

    return {
        refetch,
        // Our select function above exposes the sensors, sensorsCount and sensorsIds at the top level
        data: data as InfiniteData<IPagination<SensorShape>> & {
            sensors: SensorShape[];
            sensorsCount: number;
            sensorsIds: string[];
        },
        ...rest,
    };
};

export const useSensorList = (
    params: { [key: string]: unknown },
    options?: UseInfiniteSensorListOptions,
) => {
    const queryKey = getSensorListFullKey(params);
    const queryClient = useQueryClient();

    const infiniteQuery = useInfiniteSensorListQuery(queryKey, params, options);

    const sensorsIds = infiniteQuery.data?.sensorsIds ?? [];

    useUpdateEffect(() => {
        return () => {
            queryClient.cancelQueries(getSensorsLatestEventsKey(sensorsIds));
        };
    }, [sensorsIds]);

    // enable live polling
    useLatestSensorsEvents(infiniteQuery.data?.sensorsIds, infiniteQuery.data?.sensors, {
        onFetch: ({ events }: { events: SensorEvent[] }) => {
            pollingSensorsEventsOnFetch(events, queryKey);
        },
    });

    return {
        queryKey,
        ...infiniteQuery,
    };
};

export const useAutoPaginatedSensorList = (
    params?: { [key: string]: unknown },
    options?: UseInfiniteSensorListOptions,
) => {
    const queryKey = getSensorListFullKey(params);

    const {
        hasNextPage,
        isFetchingNextPage,
        isFetching,
        fetchNextPage,
        ...infiniteQuery
    } = useInfiniteSensorListQuery(queryKey, params, options);

    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, isFetching, fetchNextPage]);

    return {
        hasNextPage,
        isFetchingNextPage,
        isFetching,
        fetchNextPage,
        ...infiniteQuery,
    };
};
