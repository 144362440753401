import { createFetchAction, createFetchSaga } from '@thorgate/spa-entities';
import { takeLatest } from 'redux-saga/effects';

import { createPaginationSuccessHook } from 'ducks/pagination';
import { teamsSchema } from 'schemas/teams';
import api from 'services/api';
import { serializeData } from 'utils/serializeData';

export const fetchTeams = createFetchAction('@@sagas/account/teams/fetch');

const successHook = createPaginationSuccessHook(teamsSchema.key);

const fetchTeamsWorkerBase = createFetchSaga({
    key: teamsSchema.key,
    resource: api.organization.team.list,
    listSchema: [teamsSchema],
    successHook,
    serializeData,
});

export const fetchTeamsWorker = fetchTeamsWorkerBase.asInitialWorker(() => fetchTeams());

export default function* fetchTeamsWatcher() {
    yield takeLatest(fetchTeams.getType(), fetchTeamsWorkerBase, null);
}
