/* eslint-disable id-denylist */

import { makeStyles } from '@material-ui/core';

export const useFloorSearchInputStyles = makeStyles((theme) => ({
    clearSearchIcon: {
        color: theme.palette.common.gray1,
        cursor: 'pointer',
        fontSize: 16,
    },
    searchIcon: {
        color: theme.palette.common.gray1,
        fontSize: 14,
    },
    flexIcon: {
        alignItems: 'center',
        display: 'flex',
        height: 20,
    },
}));
