import { registerTranslationKey } from '@infogrid/utils-i18n';
import loadable from '@loadable/component';
import type { RouteConfig } from 'react-router-config';

import RenderChildren from 'components/RenderChildren';
import { folderDetailWatcherSagas } from 'configuration/routes/folders';
import { userIsAuthenticated, userIsManager } from 'decorators/auth';
import createOrgServiceAccountWatcherSaga from 'sagas/account/createOrgServiceAccount';
import createOrgUserWatcher from 'sagas/account/createOrgUser';
import createTeamWatcherSaga from 'sagas/account/createTeam';
import deleteOrgUserWatcher, {
    deleteOrgServiceAccountWatcher,
} from 'sagas/account/deleteOrgUser';
import deleteTeamWatcherSaga from 'sagas/account/deleteTeam';
import {
    fetchOrgServiceAccountWorker,
    fetchOrgUserWorker,
} from 'sagas/account/fetchOrgUser';
import {
    fetchAllOrgUsersWorker,
    fetchOrgUsersWatcher,
    fetchOrgUsersWorker,
} from 'sagas/account/fetchOrgUsers';
import { fetchTeamWorker } from 'sagas/account/fetchTeam';
import fetchTeamsWatcherSaga, { fetchTeamsWorker } from 'sagas/account/fetchTeams';
import regenerateSecretKeyWatcherSaga from 'sagas/account/regenerateSecretKey';
import saveOrgUserWatcher, {
    saveOrgServiceAccountWatcherSaga,
} from 'sagas/account/saveOrgUser';
import saveTeamWatcherSaga from 'sagas/account/saveTeam';
import { runParallel, authRequired } from 'sagas/helpers/initialSaga';

const OrgAccountsView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-accounts-org-accounts' */ 'views/accounts/pages/orgAccounts'
        ),
);
const OrganizationManagersView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-accounts-organization-managers' */ 'views/accounts/pages/orgManagers'
        ),
);
const OrgAccountDetailView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-accounts-org-account-detail' */ 'views/accounts/pages/orgAccountDetail'
        ),
);
const ServiceAccountDetailView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-accounts-service-account-detail' */ 'views/accounts/pages/serviceAccountDetail'
        ),
);
const OrgAccountCreateView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-accounts-org-account-create' */ 'views/accounts/pages/orgAccountCreate'
        ),
);
const OrgInvitationView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-accounts-org-account-create' */ 'views/accounts/pages/orgInvitation'
        ),
);
const TeamsView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-accounts-teams' */ 'views/accounts/pages/teams'
        ),
);
const TeamCreate = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-accounts-teams-create' */ 'views/accounts/pages/teamCreate'
        ),
);
const TeamDetail = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-accounts-team-detail' */ 'views/accounts/pages/teamDetail'
        ),
);

export const createOrganizationRoutes = (
    PageNotFoundRoute: RouteConfig,
): RouteConfig => ({
    path: '/organization',
    name: 'organization',
    component: RenderChildren,
    routes: [
        {
            path: '/organization/accounts/create',
            exact: true,
            name: 'account-create',
            pageName: registerTranslationKey('Users'),
            component: userIsManager(OrgAccountCreateView),
            watcher: [createOrgUserWatcher, ...folderDetailWatcherSagas],
        },
        {
            path: '/organization/invitation/:token',
            exact: true,
            name: 'organization-invitation',
            pageName: registerTranslationKey('Organization Invitation', {
                context: 'view',
                ns: 'account',
            }),
            component: OrgInvitationView,
        },
        {
            path: '/organization/accounts/',
            exact: true,
            name: 'accounts',
            pageName: 'Users',
            component: userIsManager(OrgAccountsView),
            initial: authRequired(fetchOrgUsersWorker),
            watcher: [
                deleteOrgUserWatcher,
                deleteOrgServiceAccountWatcher,
                fetchOrgUsersWatcher,
                createOrgServiceAccountWatcherSaga,
            ],
        },
        {
            path: '/organization/managers/',
            exact: true,
            pageName: registerTranslationKey('Managers'),
            name: 'managers',
            component: userIsAuthenticated(OrganizationManagersView),
        },
        {
            path: '/organization/accounts/:userId',
            exact: true,
            pageName: 'Users',
            name: 'account-detail',
            component: userIsManager(OrgAccountDetailView),
            initial: authRequired(fetchOrgUserWorker),
            watcher: [saveOrgUserWatcher, ...folderDetailWatcherSagas],
        },
        {
            path: '/organization/service-account/:userId',
            exact: true,
            pageName: 'Service Account Details',
            name: 'service-account-detail',
            component: userIsManager(ServiceAccountDetailView),
            initial: authRequired(fetchOrgServiceAccountWorker),
            watcher: [
                saveOrgServiceAccountWatcherSaga,
                regenerateSecretKeyWatcherSaga,
                ...folderDetailWatcherSagas,
            ],
        },
        {
            path: '/organization/teams/',
            exact: true,
            name: 'teams',
            pageName: registerTranslationKey('Teams'),
            initial: authRequired(fetchTeamsWorker),
            watcher: [deleteTeamWatcherSaga, fetchTeamsWatcherSaga],
            component: userIsAuthenticated(TeamsView),
        },
        {
            path: '/organization/teams/create',
            exact: true,
            name: 'team-create',
            pageName: 'Teams',
            component: userIsManager(TeamCreate),
            initial: authRequired(fetchAllOrgUsersWorker),
            watcher: createTeamWatcherSaga,
        },
        {
            path: '/organization/teams/:teamId',
            exact: true,
            pageName: 'Teams',
            name: 'team-detail',
            component: userIsManager(TeamDetail),
            initial: authRequired(runParallel([fetchTeamWorker, fetchAllOrgUsersWorker])),
            watcher: saveTeamWatcherSaga,
        },
        PageNotFoundRoute,
    ],
});
