import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { Folder, MoveFolder, AxiosParsedError } from '@infogrid/core-types';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { entitiesActions } from '@thorgate/spa-entities';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';
import { useQueryClient } from 'react-query';

import { invalidateBuildingsHierarchyForUser } from 'apiHooks/floorPlan/buildings/accessors';
import {
    setSensorBreadcrumbs,
    setSensorFloorplanLocation,
} from 'apiHooks/sensors/accessors';
import { getFolderSensorsFullKey } from 'apiHooks/sensors/getQueryKeys';
import { cleanInactiveSensors } from 'apiHooks/sensors/updaters';
import SuccessMovingToast from 'components/Folders/SuccessMovingToast';
import { resetAllPagination } from 'ducks/pagination';
import type { SensorQueryParams } from 'utils/filtering/sensor';

import { cleanInactiveSubfolders, invalidateSubfolders } from '../accessors';
import { controllers } from '../controllers';

const PROCESS_MESSAGE = registerTranslationKey('Moving items is in progress');
const DEFAULT_ERROR_MESSAGE = registerTranslationKey(
    'Error occurred while trying to move some items.',
);

interface MoveFolderParams {
    folderId: number;
    sensorQuery?: SensorQueryParams;
    data: MoveFolder;
    originId?: number;
}

export const useMoveFolder = (): UseMutationResult<
    Folder,
    AxiosParsedError,
    MoveFolderParams
> => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutationWithToast(
        ({ folderId, data }: MoveFolderParams) =>
            wrapResponsePromise(controllers.moveFolder(folderId, data)),
        {
            onSuccess: async (res, req) => {
                // TODO: Remove once FolderTree is migrated to react-query
                dispatch(entitiesActions.clearEntities());
                dispatch(resetAllPagination());

                const targetFolder = res;

                const sensors = req.data?.sensors ?? [];
                const folders = req.data?.folders_to_move ?? [];
                const sourceFolderId = req.originId ?? null;

                if (req.data.move_sensors) {
                    cleanInactiveSensors(sensors);

                    if (sourceFolderId) {
                        queryClient.refetchQueries(
                            getFolderSensorsFullKey(
                                sourceFolderId,
                                req.sensorQuery || {},
                            ),
                            { exact: true, active: true, inactive: false },
                        );
                    }

                    const newSensorBreadcrumbs = [
                        ...targetFolder.breadcrumbs,
                        {
                            id: targetFolder.id,
                            name: targetFolder.name,
                            folder_type: targetFolder.floorplan_type,
                        },
                    ];

                    (sensors || []).forEach((deviceName) => {
                        setSensorBreadcrumbs(deviceName, newSensorBreadcrumbs);
                        setSensorFloorplanLocation(deviceName, newSensorBreadcrumbs);
                    });
                }

                if (folders.length) {
                    invalidateSubfolders(targetFolder.id);
                    cleanInactiveSubfolders(targetFolder.id);

                    if (sourceFolderId) {
                        invalidateSubfolders(sourceFolderId);
                        cleanInactiveSubfolders(sourceFolderId);
                    }
                }

                invalidateBuildingsHierarchyForUser();

                dispatch(
                    toastSuccess({
                        message: (
                            <SuccessMovingToast
                                folderName={targetFolder.name}
                                folderId={targetFolder.id}
                            />
                        ),
                        className: 'align-items-center',
                    }),
                );
            },
            onError: ({ parsedError }) => {
                let errorMessage = t(DEFAULT_ERROR_MESSAGE);

                try {
                    if (parsedError?.origin && parsedError?.origin[0]) {
                        errorMessage = parsedError.origin[0];
                    }

                    if (parsedError?.errors?.folders) {
                        errorMessage = parsedError.errors.folders;
                    }
                } catch (err) {
                    errorMessage = t(DEFAULT_ERROR_MESSAGE);
                } finally {
                    dispatch(
                        toastError({
                            message: errorMessage,
                        }),
                    );
                }
            },
        },
        { toastMessage: t(PROCESS_MESSAGE) },
    );
};
