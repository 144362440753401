import { call, delay } from 'redux-saga/effects';

import { sendSocket } from 'sagas/socket/SendSocket';
import SETTINGS from 'settings';

import { StreamNames } from './constance';

// Run's heartbeat worker, e.g every 20 sec sends message to server
export function* heartBeat() {
    while (true) {
        yield delay(SETTINGS.SOCKET_HEARTBEAT_INTERVAL);

        yield call(sendSocket, StreamNames.Heartbeat, {
            ping: true,
        });
    }
}
