import { useIsDesktop } from '@infogrid/utils-hooks';
import { Button, Typography } from '@material-ui/core';
import type { Location } from 'history';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import { useBuildingsFilters } from 'apiHooks/floorPlan/buildings/hooks';

import BuildingTypeFilter from './BuildingTypeFilter';
import CountryFilter from './CountryFilter';
import SearchFilter from './SearchFilter';
import { useBuildingsTableFilters } from './styles';

interface BuildingsTableFiltersProps {
    clearFiltersPath: Location | string;
}

const BuildingsTableFilters = ({ clearFiltersPath }: BuildingsTableFiltersProps) => {
    const styles = useBuildingsTableFilters();

    const { t } = useTranslation();

    const isDesktop = useIsDesktop();

    const location = useLocation();
    const history = useHistory();

    const { data: buildingsFilters } = useBuildingsFilters();

    const onClearFilters = () => {
        history.push(clearFiltersPath);
    };

    return (
        <div className={styles.container}>
            <div className={styles.filterWrapper}>
                <SearchFilter />
                <Typography className={styles.filterByLabel} variant="subtitle2">
                    {t('Filter by:')}
                </Typography>
                {buildingsFilters?.countries && (
                    <CountryFilter countries={buildingsFilters.countries} />
                )}
                {buildingsFilters?.building_types && (
                    <BuildingTypeFilter
                        types={buildingsFilters.building_types.filter(
                            (building_type) => building_type.buildings_count > 0,
                        )}
                    />
                )}
                {!isDesktop && (
                    <Button
                        className={styles.clearFiltersButton}
                        color="primary"
                        data-cypress="clear-filters"
                        disabled={!location.search}
                        onClick={onClearFilters}
                        size="small"
                        variant="outlined"
                    >
                        {t('Clear filters')}
                    </Button>
                )}
            </div>
            {isDesktop && (
                <Button
                    className={styles.clearFiltersButton}
                    color="primary"
                    data-cypress="clear-filters"
                    disabled={!location.search}
                    onClick={onClearFilters}
                    size="small"
                    variant="outlined"
                >
                    {t('Clear filters')}
                </Button>
            )}
        </div>
    );
};

export default memo(BuildingsTableFilters);
