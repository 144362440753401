import { routesManager } from '@infogrid/core-routing';
import type { Building, Floor } from '@infogrid/locations-types';
import { Typography } from '@material-ui/core';
import type { Location } from 'history';
import type { ReactElement } from 'react';
import { useCallback, useMemo, memo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import FloorRowCard from '../FloorRowCard';
import FloorRowContent from '../FloorRowContent';
import type { FloorRowContentProps } from '../FloorRowContent/FloorRowContent';
import { useFloorListStyles } from './styles';

interface RenderRowProps extends FloorRowContentProps {
    isActive: boolean;
    index: number;
}

export interface FloorListProps<T extends Floor> {
    activeFloorId?: number;
    building: Building;
    filteredFloors: T[];
    hasFloors: boolean;
    generateFloorLink?: (props: { buildingId: number; floorId: number }) => Location;
    onFloorClick?: () => void;
    renderList?: (props: { baseList: ReactElement }) => ReactElement;
    renderRow?: (props: RenderRowProps) => ReactElement;
}

/**
 * List of floors with some metrics (number of spaces, sensors and hubs). Filterable with floorsSearchQuery.
 * Displays relevant text if no floors have been passed or if no floors match the filter. Use renderList or
 * renderRow to override markup.
 */
const FloorList = <T extends Floor>({
    activeFloorId,
    building,
    filteredFloors,
    hasFloors,
    generateFloorLink,
    onFloorClick,
    renderList,
    renderRow,
}: FloorListProps<T>) => {
    const styles = useFloorListStyles();
    const { t } = useTranslation('estate');

    const rowRenderer = useCallback(
        ({ floor, isActive, ...props }: RenderRowProps) =>
            renderRow?.({ floor, isActive, ...props }) || (
                <FloorRowCard isActive={isActive}>
                    <FloorRowContent
                        floor={floor}
                        floorLink={
                            generateFloorLink?.({
                                buildingId: building.id,
                                floorId: floor.id,
                            }) ||
                            routesManager.resolvePath('estate:estate-building-floor', {
                                buildingId: building.id,
                                floorId: floor.id,
                            })
                        }
                        onFloorClick={onFloorClick}
                    />
                </FloorRowCard>
            ),
        [building.id, generateFloorLink, onFloorClick, renderRow],
    );

    const list = useMemo(() => {
        const baseList = (
            <>
                {hasFloors &&
                    filteredFloors.map((floor, index) => {
                        const isActive = activeFloorId === floor.id;

                        const floorLink = routesManager.resolvePath(
                            'estate:estate-building-floor',
                            {
                                buildingId: building.id,
                                floorId: floor.id,
                            },
                        );

                        return (
                            <Fragment key={floor.id}>
                                {rowRenderer({
                                    floor,
                                    floorLink,
                                    index,
                                    isActive,
                                    onFloorClick,
                                })}
                            </Fragment>
                        );
                    })}
            </>
        );

        return renderList?.({ baseList }) || baseList;
    }, [
        activeFloorId,
        building.id,
        filteredFloors,
        hasFloors,
        onFloorClick,
        renderList,
        rowRenderer,
    ]);

    if (!hasFloors) {
        return (
            <Typography className={styles.noFloorsLabel} variant="subtitle2">
                {t('No floors added')}
            </Typography>
        );
    }

    if (hasFloors && filteredFloors?.length === 0) {
        return (
            <Typography className={styles.noFloorsLabel} variant="subtitle2">
                {t('No floors matching search. Try broadening your search.')}
            </Typography>
        );
    }

    return list;
};

export default memo(FloorList);
