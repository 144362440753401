import {
    Checkbox,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';

import { usePickerRowStyles } from './styles';

export interface PickerRowProps {
    forceChecked?: boolean;
    id: number;
    isGroup?: boolean;
    label: string;
    selected?: boolean;
    onSelect: (id: number) => void;
}

const PickerRow = ({
    forceChecked = false,
    id,
    isGroup = false,
    label,
    onSelect,
    selected = false,
}: PickerRowProps) => {
    const styles = usePickerRowStyles();

    const iconClass = classNames(
        'fas',
        isGroup ? 'fa-users' : 'fa-user',
        styles.baseUserIcon,
        {
            [styles.singleUserIcon]: !isGroup,
        },
    );

    const handleSelect = () => {
        onSelect(id);
    };

    return (
        <ListItem className={styles.listItem}>
            <i className={iconClass} />
            <ListItemText primary={label} primaryTypographyProps={{ variant: 'body2' }} />
            <ListItemSecondaryAction>
                <Checkbox
                    checked={selected || forceChecked}
                    classes={{
                        root: styles.checkbox,
                        checked: styles.checkboxChecked,
                        disabled: styles.checkboxDisabled,
                    }}
                    color="primary"
                    disabled={forceChecked}
                    edge="end"
                    onChange={handleSelect}
                    data-cypress="picker-checkbox"
                />
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default memo(PickerRow);
