import { Button, Typography, Box, Paper, Grid } from '@material-ui/core';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import logo from 'styles/images/logo-infogrid-new.svg';

import { useAppUpdatedErrorStyles } from './styles';

const AppUpdatedError = () => {
    const { t } = useTranslation();

    const styles = useAppUpdatedErrorStyles();

    return (
        <>
            <Helmet>
                <title>{t('Application Updated')}</title>
            </Helmet>

            <Box className={styles.container}>
                <Paper className={styles.paper} elevation={2}>
                    <Box className={styles.logoContainer}>
                        <img alt="Infogrid logo" className={styles.logo} src={logo} />
                    </Box>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Typography variant="body1" align="center">
                                {t('Application Updated')}
                            </Typography>
                            <Typography
                                variant="body2"
                                align="center"
                                color="textSecondary"
                            >
                                {t(
                                    'We have made some updates to the Infogrid platform which require a reload of your browser. None of your data will be lost.',
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item className={styles.buttonContainer}>
                        <Button
                            color="primary"
                            data-cypress="reload-page-btn"
                            data-testid="reload-page-btn"
                            variant="contained"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            {t('Reload the page')}
                        </Button>
                    </Grid>
                </Paper>
            </Box>
        </>
    );
};

export default memo(AppUpdatedError);
