import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { memo, useCallback } from 'react';
import { List, AutoSizer } from 'react-virtualized';

import SensorsListItem from './SensorsListItem';
import { useStylesSensorList } from './styles';

const DEFAULT_OVERSCAN_ROW_COUNT = 20;
const SENSORS_LIST_ROW_HEIGHT = 80;

interface SensorsListSensor extends GenericSensorShape {
    id: string | number;
}

interface SensorsListProps {
    activeSensorDeviceId: string | number | null;
    isDraggable?: boolean;
    isLoading?: boolean;
    minHeight?: number;
    minWidth?: number;
    onSensorClick?: (sensorDeviceId: string | number) => void;
    overscanRowCount?: number;
    placeholder: React.ReactNode;
    placeholderAlwaysVisible?: boolean;
    sensors: SensorsListSensor[];
}

const defaultSensors: SensorsListProps['sensors'] = [];

const SensorsList = ({
    activeSensorDeviceId = null,
    isDraggable = false,
    isLoading = false,
    minHeight = 500,
    minWidth = 300,
    onSensorClick = noop,
    overscanRowCount,
    placeholder,
    placeholderAlwaysVisible = false,
    sensors = defaultSensors,
}: SensorsListProps) => {
    const sensorListStyles = useStylesSensorList();

    const selectedIndex = sensors.findIndex(
        (sensor) => sensor.id === activeSensorDeviceId,
    );

    const noRowsRenderer = () => {
        return <></>;
    };

    const rowRenderer = useCallback(
        ({ index, key, style }) => {
            return (
                <div key={key} style={style} data-cypress="sensors-list-item">
                    <SensorsListItem
                        isDraggable={isDraggable}
                        isActive={sensors[index].id === activeSensorDeviceId}
                        onClick={onSensorClick}
                        sensor={sensors[index]}
                    />
                </div>
            );
        },
        [activeSensorDeviceId, isDraggable, sensors, onSensorClick],
    );

    const placeholderVisible = placeholderAlwaysVisible || !sensors.length;

    return (
        <>
            {placeholderVisible && placeholder}
            {isLoading && (
                <div className={sensorListStyles.progressWrapper}>
                    <CircularProgress color="primary" />
                </div>
            )}
            {!isLoading && (
                <div
                    className={sensorListStyles.autoSizerContainer}
                    data-cypress="sensors-list"
                >
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                className={classNames(
                                    sensorListStyles.scrollbar,
                                    sensorListStyles.sensorsList,
                                )}
                                height={height ? height : minHeight}
                                overscanRowCount={
                                    overscanRowCount ?? DEFAULT_OVERSCAN_ROW_COUNT
                                }
                                noRowsRenderer={noRowsRenderer}
                                rowCount={sensors.length}
                                rowHeight={SENSORS_LIST_ROW_HEIGHT}
                                rowRenderer={rowRenderer}
                                scrollToIndex={selectedIndex}
                                width={width ? width : minWidth}
                            />
                        )}
                    </AutoSizer>
                </div>
            )}
        </>
    );
};

export default memo(SensorsList);
