import { Box, Button, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageContainer from 'components/PageContainer';
import withView from 'decorators/withView';
import type { SmartCleaningHierarchyResponse } from 'views/solutionSettings/apiHooks/types';

import { BySpaceTypeModalContainer as BySpaceTypeModal } from './components/BySpaceTypeModal';
import CleaningRulesLocationSelector from './components/CleaningRulesLocationSelector/CleaningRulesLocationSelector';
import { LocationsSelectorCounter } from './components/LocationSelectorCounter';
import { RulesModalContainer } from './components/RulesModal';
import { useCleaningRulesStyles } from './styles';

interface Props {
    locationsIsLoading: boolean;
    locations: SmartCleaningHierarchyResponse;
    currentPageId: number;
    changePage: (id: number) => void;
    refetch: () => void;
}

const CleaningRules = ({
    locationsIsLoading,
    locations,
    changePage,
    currentPageId,
    refetch,
}: Props) => {
    const { t } = useTranslation('solutions');
    const styles = useCleaningRulesStyles();

    const [isRulesModalOpen, setRulesModalOpen] = useState(false);
    const [isBySpaceTypeModalOpen, setBySpaceTypeModalOpen] = useState(false);
    const [locationCount, setLocationCount] = useState({
        floors: 0,
        buildings: 0,
        spaces: 0,
    });
    const [selectedSpaceIds, setSelectedSpaceIds] = useState([]);
    const [knownNodes, setKnownNodes] = useState({});

    return (
        <>
            <PageContainer className={styles.container}>
                <div className={styles.header}>
                    <Typography variant="h6">
                        {t('Manage cleaning rules across your estate')}
                    </Typography>
                    <Typography
                        className={styles.description}
                        color="textSecondary"
                        variant="body2"
                    >
                        {t(
                            'Select the locations in your estate below to define how frequently they need to be cleaned, based on their usage. Or, set a cleaning rule by space type across your estate instead.',
                        )}
                    </Typography>
                </div>
                <div className={styles.locationPickerContainer}>
                    <CleaningRulesLocationSelector
                        locations={locations}
                        locationsIsLoading={locationsIsLoading}
                        changePage={changePage}
                        currentPageId={currentPageId}
                        setLocationCount={setLocationCount}
                        setSelectedSpaceIds={setSelectedSpaceIds}
                        setKnownNodesCallback={setKnownNodes}
                    />
                    <LocationsSelectorCounter
                        count={locationCount}
                        domain="location"
                        editSelectedCallback={() => setRulesModalOpen(true)}
                    />
                    <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        className={styles.section}
                    >
                        <Typography color="textPrimary" variant="body2">
                            {t(
                                'You can also set cleaning rules by space type. Note that this will override any cleaning rules previously applied to buildings or floors.',
                            )}
                        </Typography>
                        <Button
                            type="submit"
                            color="primary"
                            variant="outlined"
                            className={styles.manageByLocationButton}
                            onClick={() => setBySpaceTypeModalOpen(true)}
                        >
                            {t('Set by space type')}
                        </Button>
                    </Box>
                </div>
            </PageContainer>
            {isRulesModalOpen && (
                <RulesModalContainer
                    open={isRulesModalOpen}
                    refetch={refetch}
                    handleClose={() => setRulesModalOpen(false)}
                    locations={selectedSpaceIds}
                    knownNodes={knownNodes}
                />
            )}
            <BySpaceTypeModal
                open={isBySpaceTypeModalOpen}
                refetch={refetch}
                handleClose={() => setBySpaceTypeModalOpen(false)}
            />
        </>
    );
};

export default withView()(CleaningRules);
