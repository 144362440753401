import { CONNECTION_STATUSES, SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Link, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { HELP_SITE_URL } from 'constants/urls';
import airthingsHubImg from 'styles/images/airthings-hub-symbols.png';
import airthingsSensorImg from 'styles/images/airthings-symbol.svg';

import { useInstallationFlowStyles } from '../../styles';
import { useConnectionCheckContentStyles } from './styles';

const LOCATION_CHECK_TEXT = registerTranslationKey(
    'Make sure you are in the location where the device will be installed to test signal strength.',
    { ns: 'sensors' },
);

const READY_TO_INSTALL_TEXT = registerTranslationKey(
    'If so, the device is ready to configure and install.',
    { ns: 'sensors' },
);

const HUB_LOCATION_HELP_TEXT = registerTranslationKey(
    'Take the device to the installation location and plug in to power on.',
    { ns: 'sensors' },
);

const HUB_SYMBOLS_DESCRIPTION = registerTranslationKey(
    'The device is powered on and connected when the two symbols below are lit green on the Hub. This could take a few minutes.',
    { ns: 'sensors' },
);

const AIR_INSTALLATION_OFFLINE_TEXT = registerTranslationKey(
    'This device can take up to 12 hours to appear online in the Web App.',
    { ns: 'sensors' },
);

const AIR_INSTALLATION_LOCATION_HELP_TEXT = registerTranslationKey(
    'Take the device to the installation location, and remove the plastic tag from battery to power on. The sensor will start connecting to the nearest Hub.',
    { ns: 'sensors' },
);

const HUB_SYMBOLS_REMINDER_TEXT = registerTranslationKey(
    'Check the Airthings Hub (that you’ve previously installed) for the symbol below lit green. This shows this sensor has connected to the hub.',
    { ns: 'sensors' },
);

const CCON_INSTALLATION_HELP_TEXT = registerTranslationKey(
    'Plug the device in where you intend to install it.',
    { ns: 'sensors' },
);

const CONNECTION_STRENGTH_CHECK_TEXT = registerTranslationKey(
    'Check the signal of the device using the bars above. It could take a few minutes to come online.',
    { ns: 'sensors' },
);

const CCON_CONNECTION_STRENGTH_CHECK_TEXT = registerTranslationKey(
    'Check the signal of the device using the bars above. It could take a few minutes to appear online above, but if you can see a white cloud on the Cloud Connector, it’s online.',
    { ns: 'sensors' },
);

const CCON_TROUBLESHOOTING_TEXT = registerTranslationKey(
    'You’ve plugged in, waited some time, and still not registering?',
    { ns: 'sensors' },
);

const RANGE_EXTENDER_TEXT = registerTranslationKey(
    'If required, make sure the sensor is mounted in the range extender.',
    { ns: 'sensors' },
);

const DT_INSTALLATION_LOCATION_HELP_TEXT = registerTranslationKey(
    'Take it to the location you will install it. Press the sensor for 3 seconds to make it update.',
    { ns: 'sensors' },
);

const WEAK_CONNECTION_STATUS_TEXT = registerTranslationKey(
    'The signal is not good enough to ensure consistent data flow.',
    { ns: 'sensors' },
);
const WEAK_CONNECTION_HELP_TEXT = registerTranslationKey(
    'Try adjusting the location of this sensor slightly, or move a Cloud Connector closer to this sensor. Once done, touch the sensor to make it update.',
    { ns: 'sensors' },
);

const configBase = {
    image: null,
    imageDescription: null,
    paragraphs: [],
    helpText: null,
};

const contentConfig = {
    [CONNECTION_STATUSES.GOOD]: () => ({
        ...configBase,
        paragraphs: { LOCATION_CHECK_TEXT, READY_TO_INSTALL_TEXT },
    }),
    [CONNECTION_STATUSES.EXPECTED_OFFLINE]: (deviceType) =>
        deviceType === SENSOR_TYPE.TYPE_AIRTHINGS_HUB
            ? {
                  ...configBase,
                  paragraphs: { HUB_LOCATION_HELP_TEXT, HUB_SYMBOLS_DESCRIPTION },
                  image: airthingsHubImg,
                  imageDescription: registerTranslationKey(
                      'Power and cloud connectivity symbols on an Airthings hub',
                      { ns: 'sensors' },
                  ),
              }
            : {
                  ...configBase,
                  paragraphs: {
                      AIR_INSTALLATION_OFFLINE_TEXT,
                      AIR_INSTALLATION_LOCATION_HELP_TEXT,
                      HUB_SYMBOLS_REMINDER_TEXT,
                  },
                  image: airthingsSensorImg,
                  imageDescription: registerTranslationKey(
                      'Airthings sensor connectivity icon',
                      { ns: 'sensors' },
                  ),
              },
    [CONNECTION_STATUSES.UNEXPECTED_OFFLINE]: (deviceType) =>
        deviceType === SENSOR_TYPE.TYPE_CCON
            ? {
                  ...configBase,
                  paragraphs: {
                      CCON_INSTALLATION_HELP_TEXT,
                      CCON_CONNECTION_STRENGTH_CHECK_TEXT,
                  },
                  helpText: CCON_TROUBLESHOOTING_TEXT,
              }
            : {
                  ...configBase,
                  paragraphs: {
                      RANGE_EXTENDER_TEXT,
                      DT_INSTALLATION_LOCATION_HELP_TEXT,
                      CONNECTION_STRENGTH_CHECK_TEXT,
                  },
                  helpText: registerTranslationKey('Not online?', { ns: 'sensors' }),
              },
    [CONNECTION_STATUSES.WEAK]: () => ({
        ...configBase,
        paragraphs: { WEAK_CONNECTION_STATUS_TEXT, WEAK_CONNECTION_HELP_TEXT },
        helpText: registerTranslationKey('Still got issues?', { ns: 'sensors' }),
    }),
};

/**
 * Figures out what the contents of the installation flow modal are
 * based on the device's type and its connection strength.
 */
const ConnectionCheckContent = ({ connectionStatus, deviceType }) => {
    const { t } = useTranslation('sensors');
    const installationFlowClasses = useInstallationFlowStyles();
    const classes = useConnectionCheckContentStyles();
    const { image, imageDescription, paragraphs, helpText } = useMemo(
        () => contentConfig[connectionStatus](deviceType),
        [connectionStatus, deviceType],
    );

    return (
        <>
            {Object.keys(paragraphs).map((textKey) => (
                <Typography
                    className={installationFlowClasses.paragraph}
                    key={`${connectionStatus}-paragraph-${textKey}`}
                >
                    {t(paragraphs[textKey])}
                </Typography>
            ))}
            {image && (
                <div className={classes.imgContainer}>
                    <img alt={t(imageDescription)} src={image} className={classes.img} />
                </div>
            )}
            {helpText && (
                <Typography className={installationFlowClasses.paragraph}>
                    {t(helpText)}
                    <Link
                        href={HELP_SITE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={installationFlowClasses.inlineHelpLink}
                    >
                        {t('See troubleshooting')}
                    </Link>
                </Typography>
            )}
        </>
    );
};

ConnectionCheckContent.propTypes = {
    deviceType: PropTypes.string.isRequired,
    connectionStatus: PropTypes.oneOf(Object.values(CONNECTION_STATUSES)).isRequired,
};

export default memo(ConnectionCheckContent);
