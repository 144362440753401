import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import { isFeatureFlagEnabled } from '@infogrid/core-feature-flags';
import {
    getUser,
    isUserOrganizationFeatureEnabled,
    selectUserIsManager,
} from '@infogrid/user-ducks';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

import { AUTHENTICATION_TYPE, selectors as uiSelectors } from 'ducks/ui';

const locationHelper = locationHelperBuilder({});

// Used to show loader until we have determined if user is authenticated
const authenticatingSelector = (state) =>
    uiSelectors.authenticationState(state) === AUTHENTICATION_TYPE.UNKNOWN;

export const userIsAuthenticated = connectedRouterRedirect({
    redirectPath: '/auth/login',
    authenticatedSelector: (state) => state.user.isAuthenticated,
    authenticatingSelector,
    AuthenticatingComponent: LoadingPlaceholder,
    wrapperDisplayName: 'UserIsAuthenticated',
});

export const userIsNotAuthenticated = connectedRouterRedirect({
    redirectPath: (state, ownProps) =>
        locationHelper.getRedirectQueryParam(ownProps) || '/',
    allowRedirectBack: false,
    authenticatedSelector: (state) => !state.user.isAuthenticated,
    authenticatingSelector,
    AuthenticatingComponent: LoadingPlaceholder,
    wrapperDisplayName: 'UserIsNotAuthenticated',
});

export const userIsManager = connectedRouterRedirect({
    redirectPath: '/permission-denied',
    authenticatedSelector: selectUserIsManager,
    authenticatingSelector,
    AuthenticatingComponent: LoadingPlaceholder,
    wrapperDisplayName: 'UserIsManager',
});

export const featureEnabled = (featureFlag) =>
    connectedRouterRedirect({
        redirectPath: '/permission-denied',
        authenticatedSelector: (state) =>
            isUserOrganizationFeatureEnabled(state.user.user, featureFlag),
        authenticatingSelector,
        AuthenticatingComponent: LoadingPlaceholder,
        wrapperDisplayName: 'UserIsNotAuthenticated',
    });

export const globalFeatureEnabled = (featureFlag, allowInfogridDomain = false) =>
    connectedRouterRedirect({
        redirectPath: '/permission-denied',
        authenticatedSelector: (state) => {
            const user = getUser(state);

            return (
                isFeatureFlagEnabled(state.application.constance, featureFlag) ||
                (allowInfogridDomain && user.email.endsWith('@infogrid.io'))
            );
        },
        authenticatingSelector,
        AuthenticatingComponent: LoadingPlaceholder,
        wrapperDisplayName: 'UserIsNotAuthenticated',
    });
