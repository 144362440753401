import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useBuildRecurringReportModalStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '600px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    confirmationText: {
        fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(3),
    },
    numberText: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
        paddingBottom: theme.spacing(3),
    },
    alert: {
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
}));
