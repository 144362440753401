import { Autocomplete, Modal } from '@infogrid/components-material-ui';
import { useAppSelector } from '@infogrid/core-ducks';
import type { HierarchyFloor, HierarchyRoom } from '@infogrid/locations-types';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type {
    Label,
    SensorType,
    LegionnaireSensorConfigurationShape,
} from '@infogrid/sensors-constants';
import { ELECTRICITY_SENSOR_TYPES } from '@infogrid/solution-views-electricity';
import { selectUserPreferredTempUnit } from '@infogrid/user-ducks';
import { useIsMobile } from '@infogrid/utils-hooks';
import type { FormControlProps } from '@material-ui/core';
import { Button, TextField, FormControl, Typography, Chip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import type { FormikProps } from 'formik';
import { useMemo, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
    HierarchyBuildings,
    HierarchyFloorMap,
} from 'apiHooks/floorPlan/buildings/types';
import type { InstallationFlowComponentProps } from 'components/InstallationFlow/types';

import InstallationFlowOverrideWarning from '../InstallationFlowOverrideWarning';
import InstallationFlowWarning from '../InstallationFlowWarning';
import SensorDisplay from '../SensorDisplay';
import { DISCARD_MODAL_SETTINGS, INSTALLATION_FLOW_STEPS } from '../constants';
import { useInstallationFlowContext } from '../context';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../reducer';
import { useInstallationFlowStyles } from '../styles';
import LegionnaireConfiguration from './DefaultConfigurationContainer/LegionnaireConfiguration';
import { useConfigurationStyles } from './styles';
import { getRenderInputs } from './utils';
import { NO_SPACE_OPTION } from './utils/consts';
import type { ConfigurationFormValues } from './utils/types';

interface Props extends Pick<InstallationFlowComponentProps, 'resetProgress'> {
    deviceType: SensorType;
    buildingsData: HierarchyBuildings;
    labels: Label[];
    supportsLiveCalibration?: boolean;
    floorMap: HierarchyFloorMap;
    formik: FormikProps<ConfigurationFormValues>;
    isOnline?: boolean | null;
}

type HierarchyOptions = HierarchyFloor | HierarchyRoom;

const Configuration = ({
    resetProgress,
    deviceType,
    buildingsData,
    labels,
    supportsLiveCalibration,
    floorMap,
    formik,
    isOnline,
}: Props) => {
    const { t } = useTranslation('sensors');
    const { sensor, sensorName, isOverwriteConfirmed, lastSignalEvent, vendorTypeStep } =
        useInstallationFlowContext();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();
    const [isDiscardChangesWarningOpen, setIsDiscardChangesWarningOpen] = useState(false);
    const [isPreviouslyInstalledWarningOpen, setPreviouslyInstalledWarningOpen] =
        useState(!(isOverwriteConfirmed || !!sensorName) && !!sensor?.installation_time);

    const {
        handleChange,
        values,
        touched,
        errors,
        handleBlur,
        setFieldValue,
        handleSubmit,
    } = formik;

    const isMobile = useIsMobile();

    const installationFlowStyles = useInstallationFlowStyles();
    const classes = useConfigurationStyles({ isMobile });

    const autocompleteClasses = useMemo(
        () => ({ listbox: classes.autocompleteListbox }),
        [classes.autocompleteListbox],
    );

    const { renderBuildingInput, renderFloorInput, renderSpaceInput, renderLabelsInput } =
        getRenderInputs(errors, t);

    const sensorConfig = useMemo(() => getSensorConfig(deviceType), [deviceType]);

    const getOptionLabel = useCallback(
        (option: HierarchyOptions) => option?.name || '',
        [],
    );

    const getOptionSelected = useCallback(
        (option: HierarchyOptions, value: HierarchyOptions) => {
            return !value.id || value.id === option.id;
        },
        [],
    );

    const onResume = useCallback(() => {
        setIsDiscardChangesWarningOpen(false);
        setPreviouslyInstalledWarningOpen(false);
    }, []);

    const onDiscardChangesWarning = useCallback(() => {
        setIsDiscardChangesWarningOpen(false);

        /**
            when configuring a feedback panel it is possible they selected "Other Device"
            in which case we can't assume they are in feedback panel specific flow
         */
        if (deviceType === SENSOR_TYPE.TYPE_FEEDBACK_PANEL) {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.RETURN_TO_VENDOR_SELECTOR,
            });

            return;
        }

        if (lastSignalEvent) {
            dispatchInstallationFlowContext({ type: INSTALLATION_FLOW_ACTIONS.GO_BACK });

            return;
        }

        if (vendorTypeStep === INSTALLATION_FLOW_STEPS.qrCodeScanner) {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.SELECT_VENDOR,
                vendorTypeStep,
            });
        } else {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.RETURN_TO_VENDOR_SELECTOR,
            });
        }

        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.SET_SENSOR,
            sensor: null,
            lastSignalEvent: null,
        });
    }, [deviceType, lastSignalEvent, vendorTypeStep, dispatchInstallationFlowContext]);

    const onDiscardPreviouslyInstalledWarning = useCallback(() => {
        setPreviouslyInstalledWarningOpen(false);
        resetProgress();
    }, [resetProgress]);

    const onBack = () => {
        setIsDiscardChangesWarningOpen(true);
    };

    const renderLabelChips = (selectedLabels: Label[]) => {
        return selectedLabels.map(({ name, id }) => {
            return (
                <Chip
                    label={name}
                    key={id}
                    onDelete={() => {
                        setFieldValue(
                            'labels',
                            values.labels.filter((l) => l.id !== id),
                        );
                    }}
                    classes={{
                        deletable: classes.labelChip,
                    }}
                />
            );
        });
    };

    const buildingId = values?.building?.id;

    const floorOptions = !!buildingId ? floorMap[buildingId] : [];

    const floorId = values?.floor?.id;

    const spaceOptions = useMemo(() => {
        if (buildingId && floorId) {
            const floor = floorMap[buildingId].find((f) => f.id === floorId);

            return [NO_SPACE_OPTION, ...(floor?.rooms || []), ...(floor?.spaces || [])];
        }

        return [];
    }, [buildingId, floorId, floorMap]);

    const isFloorFieldDisabled =
        !errors.floor && (!values.building || floorOptions.length === 0);

    const isSpaceFieldDisabled = !values.floor;

    const deviceNameError = touched.device_name && !!errors.device_name;

    const onBuildingValueChange = useCallback(
        (_0, value) => {
            setFieldValue('building', value);
            setFieldValue('floor', null);
            setFieldValue('space', NO_SPACE_OPTION);
        },
        [setFieldValue],
    );

    const onFloorValueChange = useCallback(
        (_0, value) => {
            setFieldValue('floor', value);
            setFieldValue('space', NO_SPACE_OPTION);
        },
        [setFieldValue],
    );

    const onSpaceValueChange = useCallback(
        (_0, value) => {
            setFieldValue('space', value);
        },
        [setFieldValue],
    );

    const onLabelsValueChange = useCallback(
        (_0, value) => {
            setFieldValue('labels', value);
        },
        [setFieldValue],
    );

    const formControlProps: FormControlProps = {
        fullWidth: true,
        variant: 'outlined',
        className: classes.formField,
    };

    const formikAutocompleteProps = {
        values,
        onBlur: handleBlur,
        onChange: handleChange,
        getOptionSelected,
        getOptionLabel,
        formControlProps,
    };

    const userPreferredTempUnit = useAppSelector(selectUserPreferredTempUnit);

    return (
        <>
            <Modal.Title>{t('Configure the device')}</Modal.Title>

            <Modal.Content>
                <form
                    id="configurationForm"
                    onSubmit={handleSubmit}
                    className={installationFlowStyles.form}
                >
                    {sensor && sensorConfig ? (
                        <SensorDisplay
                            sensor={sensor}
                            label={sensorConfig.label}
                            deviceType={deviceType}
                        />
                    ) : null}
                    {ELECTRICITY_SENSOR_TYPES.includes(deviceType) && (
                        <Alert color="info" severity="info" className={classes.info}>
                            {t(
                                'The information below represents the physical location you are installing the sensor',
                            )}
                        </Alert>
                    )}
                    <Autocomplete
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formikAutocompleteProps}
                        id="building"
                        options={buildingsData}
                        onChange={onBuildingValueChange}
                        value={values.building}
                        data-cypress="building"
                        renderInput={renderBuildingInput}
                        classes={autocompleteClasses}
                    />
                    <Autocomplete
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formikAutocompleteProps}
                        id="floor"
                        options={floorOptions}
                        disabled={isFloorFieldDisabled}
                        onChange={onFloorValueChange}
                        value={values.floor}
                        data-cypress="floor"
                        renderInput={renderFloorInput}
                        classes={autocompleteClasses}
                    />
                    <Autocomplete
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formikAutocompleteProps}
                        id="space" // Sent as `room` in submit logic until API changes
                        options={spaceOptions}
                        disabled={isSpaceFieldDisabled}
                        onChange={onSpaceValueChange}
                        value={values.space}
                        data-cypress="space"
                        renderInput={renderSpaceInput}
                        classes={autocompleteClasses}
                    />
                    <FormControl
                        fullWidth
                        variant="outlined"
                        error={deviceNameError}
                        className={classes.formField}
                    >
                        <TextField
                            id="device_name"
                            name="device_name"
                            variant="outlined"
                            onChange={handleChange}
                            value={values.device_name}
                            onBlur={handleBlur}
                            data-cypress="device-name"
                            label={t('Device name *')}
                            error={deviceNameError}
                            autoComplete="off"
                            fullWidth
                        />
                        {deviceNameError && (
                            <Typography
                                color="error"
                                variant="caption"
                                data-cypress="name-error-text"
                            >
                                {errors.device_name}
                            </Typography>
                        )}
                    </FormControl>
                    {deviceType === SENSOR_TYPE.TYPE_LEGIONNAIRE && (
                        <LegionnaireConfiguration
                            userPreferredTempUnit={userPreferredTempUnit}
                            subTypes={
                                (
                                    sensor?.sensor_configuration as LegionnaireSensorConfigurationShape
                                )?.sub_types_options
                            }
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            values={values}
                            subTypeError={touched.sub_type ? errors.sub_type : ''}
                            tempOffsetError={
                                touched.temperature_offset
                                    ? errors.temperature_offset
                                    : ''
                            }
                            supportsLiveCalibration={supportsLiveCalibration}
                            isOnline={isOnline}
                        />
                    )}
                    <Autocomplete
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...formikAutocompleteProps}
                        id="labels"
                        multiple
                        options={labels}
                        value={values.labels}
                        data-cypress="labels"
                        onChange={onLabelsValueChange}
                        renderInput={renderLabelsInput}
                        classes={autocompleteClasses}
                        renderTags={renderLabelChips}
                    />
                </form>
            </Modal.Content>
            <Modal.Actions
                justify="space-between"
                className={installationFlowStyles.actions}
            >
                <Button onClick={onBack} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    data-cypress="save"
                    type="submit"
                    form="configurationForm"
                >
                    {t('Save')}
                </Button>
            </Modal.Actions>
            <InstallationFlowWarning
                isOpen={isDiscardChangesWarningOpen}
                onResume={onResume}
                onDiscard={onDiscardChangesWarning}
                warningMessage={DISCARD_MODAL_SETTINGS.WARNING_MESSAGE}
                onDiscardButtonText={DISCARD_MODAL_SETTINGS.ON_DISCARD_BUTTON_TEXT}
            />
            <InstallationFlowOverrideWarning
                isOpen={isPreviouslyInstalledWarningOpen}
                onResume={onResume}
                onDiscard={onDiscardPreviouslyInstalledWarning}
                sensor={sensor}
            />
        </>
    );
};

export default memo(Configuration);
