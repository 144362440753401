import type { FloorSensorLocation } from '@infogrid/locations-types';
import { getMostRecentEventTimestamp } from '@infogrid/sensors-configuration';
import type {
    SensorType,
    SensorEventsV2,
    SensorEventShape,
    SensorEventV2Shape,
    MappableSensor,
} from '@infogrid/sensors-constants';
import isEmpty from 'lodash/isEmpty';

import convertEventData from 'utils/conversions/convertEventData';
import { mutateResponseFactory } from 'utils/data';

interface MutateSensorArg {
    type_code: SensorType;
    latest_events_v2: SensorEventsV2;
}

export const mutateSensor = <T extends MutateSensorArg>(
    sensor: T,
): T & { update_time?: Date | null } => {
    if (isEmpty(sensor.latest_events_v2)) {
        return sensor;
    }

    return {
        ...sensor,
        update_time: getMostRecentEventTimestamp(sensor),
    };
};

export const mutateSensorsResponse = mutateResponseFactory(mutateSensor);

export const mutateSensors = <T extends MutateSensorArg>(sensors: T[] = []) =>
    sensors.map((sensor) => mutateSensor(sensor));

/* Sensor latest events helpers */

export const mutateSensorEvent = (
    event: SensorEventShape | SensorEventV2Shape,
    sensor: MutateSensorArg,
) => ({
    ...event,

    ...convertEventData(event, sensor),
});

export const expandSensorToMappedSensor = <T extends { profile: { uuid: string } }>(
    sensor: T,
    coordinates: FloorSensorLocation,
): MappableSensor<T> => ({
    ...sensor,
    coordinates,
    uuid: sensor.profile.uuid,
    is_planned: false,
    is_saved: true,
});

export const makeSensorsMappable = <
    T extends { device_name: string; profile: { uuid: string } },
>(
    sensors?: T[],
    floorSensorDictionary?: Record<string, FloorSensorLocation> | null,
    filterUnmapped?: boolean,
): MappableSensor<T>[] | undefined =>
    sensors?.reduce((acc, sensor) => {
        const coordinates = floorSensorDictionary?.[sensor.device_name];

        if (filterUnmapped && !coordinates) {
            return acc;
        }

        return acc.concat(
            expandSensorToMappedSensor(sensor, coordinates as FloorSensorLocation),
        );
    }, [] as MappableSensor<T>[]);
