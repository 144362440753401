import type { AxiosParsedError } from '@infogrid/core-types';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type { SensorShape } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import type { UseMutateFunction, UseMutationResult, UseQueryOptions } from 'react-query';

import { useCreateVirtualSensor, VIRTUAL_SENSOR_TYPE } from '.';

const TOAST_MESSAGES = Object.freeze({
    DEFAULT_ERROR_MESSAGE: registerTranslationKey(
        'Error creating a new desk occupancy sensor',
        {
            ns: 'sensors',
        },
    ),
    SUCCESS_MESSAGE: registerTranslationKey('New Desk Occupancy created', {
        ns: 'sensors',
    }),
    IN_PROGRESS_MESSAGE: registerTranslationKey(
        'Creating a new desk occupancy sensor is in progress',
        {
            ns: 'sensors',
        },
    ),
});

interface Params {
    deviceName: string;
}

export type CreateDeskOccupancySensorMutation = UseMutateFunction<
    SensorShape,
    AxiosParsedError,
    Params
>;

export const useCreateDeskOccupancySensors = (
    options: UseQueryOptions<SensorShape, AxiosParsedError>,
): UseMutationResult<SensorShape, AxiosParsedError, Params> =>
    useCreateVirtualSensor(
        {
            sensorType: VIRTUAL_SENSOR_TYPE[SENSOR_TYPE.TYPE_DESK_OCCUPANCY],
            toastMessages: TOAST_MESSAGES,
        },
        options,
    );
