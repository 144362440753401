import { i18n } from '@infogrid/utils-i18n';
import type { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export const monnitConfigurationFormValidationSchema = (t: TFunction): Yup.ObjectSchema =>
    Yup.object().shape({
        monitoring_locations: Yup.array<number>()
            .min(1, t('Monitoring location is required', { ns: 'sensors' }))
            .required(t('Monitoring location is required', { ns: 'sensors' }))
            .label(t('Monitoring location')),
        end_use: Yup.string()
            .required(t('End use is required', { ns: 'sensors' }))
            .label(t('End use', { ns: 'sensors' })),
        voltage: Yup.number()
            .integer(i18n.t('Voltage must be an integer', { ns: 'sensors' }))
            .min(1, i18n.t('Voltage must be greater than 0', { ns: 'sensors' }))
            .max(
                10000,
                i18n.t('Voltage must be equal to or less than 10000', { ns: 'sensors' }),
            )
            .required(i18n.t('Voltage is required', { ns: 'sensors' }))
            .label(t('Voltage')),
    });
