import { Icon, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import type { MouseEvent } from 'react';
import { useCallback } from 'react';

import { SIDEBAR_COLLAPSE_BUTTON_POSITION } from './constants';
import { useSidebarCollapseButtonStyles } from './styles';
import type { ArrowPosition } from './types';

export const SIDEBAR_COLLAPSE_BUTTON_DIRECTION = {
    LEFT: 'left',
    RIGHT: 'right',
};

export type ButtonLocation =
    typeof SIDEBAR_COLLAPSE_BUTTON_DIRECTION[keyof typeof SIDEBAR_COLLAPSE_BUTTON_DIRECTION];

const BUTTON_ICONS: Record<ButtonLocation, { openIcon: string; closedIcon: string }> = {
    left: {
        openIcon: 'far fa-angle-left',
        closedIcon: 'far fa-angle-right',
    },
    right: {
        openIcon: 'far fa-angle-right',
        closedIcon: 'far fa-angle-left',
    },
};

interface SidebarCollapseButtonProps {
    onClick: (event: MouseEvent) => void;
    location: ButtonLocation;
    position?: ArrowPosition;
    variant?: 'default' | 'solid';
    navbarOpened?: boolean;
    className?: string;
    iconClassName?: string;
    testId?: string;
    cypressId?: string;
}

const SidebarCollapseButton = ({
    onClick,
    location,
    position = SIDEBAR_COLLAPSE_BUTTON_POSITION.RIGHT,
    variant = 'default',
    navbarOpened,
    className,
    iconClassName,
    testId = '',
    cypressId = '',
}: SidebarCollapseButtonProps) => {
    const { openIcon, closedIcon } = BUTTON_ICONS[location];

    const clickHandler = useCallback(
        (event) => {
            event.stopPropagation();
            onClick(event);
        },
        [onClick],
    );

    const styles = useSidebarCollapseButtonStyles({ position });

    const buttonClasses = classNames(className, styles.root, {
        [styles.solidRoot]: variant === 'solid',
    });

    const iconClasses = classNames(
        navbarOpened ? openIcon : closedIcon,
        styles.icon,
        navbarOpened ? styles.iconOpen : styles.iconClosed,
        iconClassName,
        { [styles.solidIcon]: variant === 'solid' },
    );

    return (
        <IconButton
            color="inherit"
            className={buttonClasses}
            aria-label="switch navbar"
            component="span"
            onClick={clickHandler}
            data-testid={`sidebar-collapse-button${testId}`}
            data-cypress={`sidebar-collapse-button${cypressId}`}
        >
            <Icon className={iconClasses} data-testid="SidebarCollapseButton-icon" />
        </IconButton>
    );
};

export default SidebarCollapseButton;
