import { useMutation } from 'react-query';

import { setMappedSensors } from '../../sensors/accessors';
import { invalidateFloorDetail } from '../accessors';
import { controllers } from '../controllers';

export const useUploadFloorplan = () => {
    const { mutate, isLoading, error, reset } = useMutation(
        ({ floorId, data }) => controllers.uploadFloorplan(floorId, data),
        {
            onSuccess(_, { floorId, unmapSensors }) {
                invalidateFloorDetail(floorId);

                if (unmapSensors) {
                    setMappedSensors({ floorId, data: [] });
                }
            },
        },
    );

    return {
        uploadFloorPlan: mutate,
        isLoading,
        error,
        reset,
    };
};
