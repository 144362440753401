import { Modal } from '@infogrid/components-material-ui';
import type {
    UpdateDashboardPermission,
    PermissionType,
} from '@infogrid/dashboards-constants';
import {
    useDashboardTeams,
    useDashboardUsers,
    useSearchDashboardTeams,
    useSearchDashboardUsers,
    useUpdateDashboardPermissionsList,
} from '@infogrid/dashboards-hooks';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { Button, CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import type { ReactNode } from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { trackDashboardEvent } from 'views/dashboards/utils/analytics';

import UsersAndTeamsAutocomplete from './UsersAndTeamsAutocomplete';
import type { PickerItem } from './UsersAndTeamsPicker';
import UsersAndTeamsPicker from './UsersAndTeamsPicker';
import { useShareDashboardModalStyles } from './styles';

const PERMISSION_NO_ACCESS = 'no_access';
const PERMISSION_VIEW_DASHBOARD = 'view_dashboard';

export interface ShareDashboardModalProps {
    dashboardId: number;
    toggleShareModal: (state: boolean) => void;
}

const pickerMapping = <T extends PickerItem>(collection?: T[]) =>
    collection?.map((item) => ({ id: item.id, name: item.name })) || [];

const idMapping = <T extends PickerItem>(collection?: T[]) =>
    collection?.map((item) => item.id) || [];

const ShareDashboardModal = ({
    toggleShareModal,
    dashboardId,
}: ShareDashboardModalProps) => {
    const styles = useShareDashboardModalStyles();
    const { t } = useTranslation('dashboard');
    const { mutate: updatePermissions } = useUpdateDashboardPermissionsList();

    const [teams, setTeams] = useState<PickerItem[]>([]);
    const [users, setUsers] = useState<PickerItem[]>([]);

    const [selectedTeamsIds, setSelectedTeamsIds] = useState<number[]>([]);
    const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([]);

    // Used to disable sharing when no changes are detected
    const [teamsInitialSelection, setTeamsInitialSelection] = useState<number[]>([]);
    const [usersInitialSelection, setUsersInitialSelection] = useState<number[]>([]);

    const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

    const {
        data: { results: initialTeams } = {},
        remove: removeInitialDashboardTeamsCache,
        isLoading: isTeamsLoading,
    } = useDashboardTeams(dashboardId);

    const {
        data: { count: dashboardTeamsTotalCount, results: foundTeams } = {},
        isFetching: dashboardTeamsLoading,
    } = useSearchDashboardTeams(dashboardId, searchQuery);

    const {
        data: { results: initialUsers } = {},
        remove: removeInitialDashboardUsersCache,
        isLoading: isUsersLoading,
    } = useDashboardUsers(dashboardId);

    const {
        data: { count: dashboardUsersTotalCount, results: foundUsers } = {},
        isFetching: dashboardUsersLoading,
    } = useSearchDashboardUsers(dashboardId, searchQuery);

    useEffect(() => {
        return () => {
            removeInitialDashboardTeamsCache();
            removeInitialDashboardUsersCache();
        };
    }, [removeInitialDashboardTeamsCache, removeInitialDashboardUsersCache]);

    const initialTeamsIds = useMemo(() => idMapping(initialTeams), [initialTeams]);
    const initialUsersIds = useMemo(() => idMapping(initialUsers), [initialUsers]);

    const teamsIds = useMemo(() => idMapping(teams), [teams]);
    const usersIds = useMemo(() => idMapping(users), [users]);

    const areAllSelected = useMemo(
        () => selectedTeamsIds.includes(teams[0]?.id),
        [selectedTeamsIds, teams],
    );

    const dashboardTeams = useMemo(
        () =>
            foundTeams ? foundTeams.filter((team) => !teamsIds.includes(team.id)) : [],
        [foundTeams, teamsIds],
    );

    const dashboardUsers = useMemo(
        () =>
            foundUsers ? foundUsers.filter((user) => !usersIds.includes(user.id)) : [],
        [foundUsers, usersIds],
    );

    const hasSelectionChanged = useMemo(
        () =>
            !isEqual(teamsInitialSelection.sort(), selectedTeamsIds.sort()) ||
            !isEqual(usersInitialSelection.sort(), selectedUsersIds.sort()),
        [
            teamsInitialSelection,
            selectedTeamsIds,
            usersInitialSelection,
            selectedUsersIds,
        ],
    );

    const onSelectedTeamsChange = useCallback(
        (ids: number[]) => {
            setSelectedTeamsIds(ids);
        },
        [setSelectedTeamsIds],
    );

    const onSelectedUsersChange = useCallback(
        (ids: number[]) => {
            setSelectedUsersIds(ids);
        },
        [setSelectedUsersIds],
    );

    const [onDebouncedSearch] = useDebouncedCallback((query: string) => {
        setSearchQuery(query);
    }, 500);

    const handleSearchInputChange = useCallback(
        (e) => {
            onDebouncedSearch(e.target.value);
        },
        [onDebouncedSearch],
    );

    const handleAddTeam = useCallback(
        (team) => {
            setTeams([...teams, team]);
            setSelectedTeamsIds([...selectedTeamsIds, team.id]);
        },
        [teams, selectedTeamsIds, setSelectedTeamsIds, setTeams],
    );

    const handleAddUser = useCallback(
        (user) => {
            setUsers([...users, user]);
            setSelectedUsersIds([...selectedUsersIds, user.id]);
        },
        [users, selectedUsersIds, setSelectedUsersIds, setUsers],
    );

    const preparePayload = (
        items: PickerItem[],
        initialItemsIds: number[],
        selectedItemsIds: number[],
        type: PermissionType,
    ) => {
        const payload: UpdateDashboardPermission[] = [];

        items.forEach((item) => {
            const wasSelected = initialItemsIds.includes(item.id);
            const isSelected = selectedItemsIds.includes(item.id);

            const becameSelected = !wasSelected && isSelected;
            const becameUnselected = wasSelected && !isSelected;

            const viewDashboardCondition =
                becameSelected || (becameUnselected && areAllSelected);
            const noAccessCondition = becameUnselected && !areAllSelected;
            const forceViewDashboardCondition =
                !viewDashboardCondition && !noAccessCondition && areAllSelected;

            if (viewDashboardCondition || forceViewDashboardCondition) {
                payload.push({
                    id: item.id,
                    type,
                    explicit_permission: PERMISSION_VIEW_DASHBOARD,
                });
            }

            if (noAccessCondition) {
                payload.push({
                    id: item.id,
                    type,
                    explicit_permission: PERMISSION_NO_ACCESS,
                });
            }
        });

        return payload;
    };

    const persistTeams = useCallback(
        () =>
            setTeams(
                teams.filter(
                    (team, index) =>
                        [...selectedTeamsIds, teams[0]?.id].includes(team.id) ||
                        (areAllSelected && index !== 0),
                ),
            ),
        [areAllSelected, selectedTeamsIds, setTeams, teams],
    );

    const persistUsers = () =>
        setUsers(
            users.filter((user) => selectedUsersIds.includes(user.id) || areAllSelected),
        );

    const handleConfirm = () => {
        trackDashboardEvent(USER_ACTIONS.PRESSED, 'share dashboard', {
            id: dashboardId,
        });

        const teamsPayload = preparePayload(
            teams,
            initialTeamsIds,
            selectedTeamsIds,
            'team',
        );
        const usersPayload = preparePayload(
            users,
            initialUsersIds,
            selectedUsersIds,
            'user',
        );

        const payload = [...teamsPayload, ...usersPayload];

        if (payload.length > 0) {
            updatePermissions({ dashboardId, data: payload });
        }

        toggleShareModal(false);

        persistTeams();
        persistUsers();

        setTeamsInitialSelection(selectedTeamsIds);
        setUsersInitialSelection(selectedUsersIds);
    };

    useEffect(() => {
        if (teams.length === 0 && initialTeams && initialTeams.length > 0) {
            const teamsToAdd = initialTeams.filter((team) => !teamsIds.includes(team.id));

            if (teamsToAdd.length > 0) {
                const mappedTeams = pickerMapping(teamsToAdd);

                setTeams(mappedTeams);

                const newTeamsIds = idMapping(
                    teamsToAdd.filter(
                        (team) => team.explicit_permission === PERMISSION_VIEW_DASHBOARD,
                    ),
                );

                setTeamsInitialSelection([...selectedTeamsIds, ...newTeamsIds]);
                setSelectedTeamsIds([...selectedTeamsIds, ...newTeamsIds]);
            }
        }
    }, [
        initialTeams,
        selectedTeamsIds,
        setTeamsInitialSelection,
        setSelectedTeamsIds,
        teams,
        teamsIds,
    ]);

    useEffect(() => {
        if (users.length === 0 && initialUsers && initialUsers.length > 0) {
            const usersToAdd = initialUsers.filter((user) => !usersIds.includes(user.id));

            if (usersToAdd.length > 0) {
                const mappedUsers = pickerMapping(usersToAdd);

                setUsers(mappedUsers);

                setUsersInitialSelection([
                    ...selectedUsersIds,
                    ...idMapping(mappedUsers),
                ]);

                setSelectedUsersIds([...selectedUsersIds, ...idMapping(mappedUsers)]);
            }
        }
    }, [
        initialUsers,
        selectedUsersIds,
        setUsersInitialSelection,
        setSelectedUsersIds,
        users,
        usersIds,
    ]);

    const renderUsersSearchInput = useCallback(
        (params): ReactNode => (
            <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                fullWidth
                id="users-autocomplete"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <>
                            {(dashboardTeamsLoading || dashboardUsersLoading) && (
                                <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                        </>
                    ),
                    startAdornment: (
                        <InputAdornment position="start">
                            <i
                                className={classNames(
                                    'far fa-search',
                                    styles.inputSearchIcon,
                                )}
                            />
                        </InputAdornment>
                    ),
                }}
                name="users-autocomplete"
                onChange={handleSearchInputChange}
                placeholder={t('Search...')}
                size="small"
                value={searchQuery}
                variant="outlined"
            />
        ),
        [
            t,
            dashboardTeamsLoading,
            dashboardUsersLoading,
            handleSearchInputChange,
            searchQuery,
            styles.inputSearchIcon,
        ],
    );

    const closeModal = useCallback(() => {
        toggleShareModal(false);
    }, [toggleShareModal]);

    return (
        <Modal onClose={closeModal} open fullWidth maxWidth="sm">
            <Modal.Title>{t('Share Dashboard')}</Modal.Title>
            <Modal.Content>
                <Alert severity="info">
                    {t(
                        'Users/teams selected here will be able to see the dashboard but will not be able to make changes.',
                    )}
                </Alert>
                <div className={styles.autocompleteContainer} />
                <UsersAndTeamsAutocomplete
                    onSelectTeam={handleAddTeam}
                    onSelectUser={handleAddUser}
                    renderInput={renderUsersSearchInput}
                    teams={dashboardTeams}
                    teamsTotalCount={dashboardTeamsTotalCount}
                    users={dashboardUsers}
                    usersTotalCount={dashboardUsersTotalCount}
                />
                <div className={styles.usersPickerContainer}>
                    {!isTeamsLoading && !isUsersLoading && (
                        <UsersAndTeamsPicker
                            areAllSelected={areAllSelected}
                            onSelectedTeamsChange={onSelectedTeamsChange}
                            onSelectedUsersChange={onSelectedUsersChange}
                            selectedTeamsIds={selectedTeamsIds}
                            selectedUsersIds={selectedUsersIds}
                            teams={teams}
                            users={users}
                        />
                    )}
                </div>
            </Modal.Content>
            <Modal.Actions justify="space-between">
                <Button color="default" onClick={closeModal}>
                    {t('Cancel', { ns: 'common' })}
                </Button>
                <Button
                    color="primary"
                    onClick={handleConfirm}
                    variant="contained"
                    disabled={!hasSelectionChanged}
                    data-cypress="share-btn"
                >
                    {t('Share', { ns: 'common' })}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(ShareDashboardModal);
