import noop from 'lodash/noop';
import { createContext, useContext } from 'react';

import StepsManager from './StepsManager';
import { INSTALLATION_FLOW_STEPS } from './constants';
import { INSTALLATION_FLOW_INITIAL_CONTEXT } from './context';
import type {
    InstallationFlowAction,
    InstallationFlowContextDispatcherType,
    InstallationFlowContextStateType,
} from './types';

export const INSTALLATION_FLOW_ACTIONS = {
    GO_BACK: 'go_back',
    SET_SENSOR: 'set_sensor',
    INSTALL_WITH_SCANNER: 'install_with_scanner',
    SCAN: 'scan',
    SELECT_VENDOR: 'select_vendor',
    REGISTER_MANUALLY: 'register_manually',
    SCAN_TEMPERATURE_SENSOR: 'scan_temperature_sensor',
    SELECT_TEMPERATURE_USE_CASE: 'select_temperature_use_case',
    SELECT_FEEDBACK_PANEL_TEMPLATE: 'select_feedback_panel_template',
    RESET_FEEDBACK_PANEL_TEMPLATE_SELECTION: 'reset_feedback_panel_template_selection',
    IDENTIFY_FEEDBACK_PANEL: 'identify_feedback_panel',
    RETURN_TO_VENDOR_SELECTOR: 'return_to_vendor_selector',
    INSTALLATION_SUCCEED: 'installation_succeed',
    FEEDBACK_PANEL_INSTALLATION_SUCCEED: 'feedback_panel_installation_succeed',
    RESET_PROGRESS: 'reset_progress',
    TOGGLE_LOADING: 'toggleLoading',
    TOGGLE_OVERWRITE: 'toggleOverwrite',
    CHECK_CONNECTION: 'check_connection',
    CONFIGURE_DEVICE: 'configure_device',
    SET_TEMPERATURE_OFFSET: 'set_temperature_offset',
    SHOW_TEMPERATURE_OFFSET_HELP: 'show_temperature_offset_help',
    SELECT_TEMPERATURE_OFFSET_MODE: 'select_temperature_offset_mode',
    MONNIT_CONFIGURATION: 'monnit_configuration',
    FLUDIA_CONFIGURATION: 'fludia_configuration',
    FLUDIA_TECHNICAL_CONFIGURATION: 'fludia_technical_configuration',
    ELECTRICITY_MONITORING_CONFIGURATION: 'electricity_monitoring_configuration',
    MONNIT_DRY_PULSE_CONFIGURATION: 'monnit_dry_pulse_configuration',
};

const MOVE_TO_STEP = {
    [INSTALLATION_FLOW_ACTIONS.SCAN_TEMPERATURE_SENSOR]:
        INSTALLATION_FLOW_STEPS.tempUseCaseSelector,
    [INSTALLATION_FLOW_ACTIONS.CHECK_CONNECTION]: INSTALLATION_FLOW_STEPS.connectionCheck,
    [INSTALLATION_FLOW_ACTIONS.CONFIGURE_DEVICE]: INSTALLATION_FLOW_STEPS.configuration,
    [INSTALLATION_FLOW_ACTIONS.SET_TEMPERATURE_OFFSET]:
        INSTALLATION_FLOW_STEPS.tempOffsetConfiguration,
    [INSTALLATION_FLOW_ACTIONS.SELECT_TEMPERATURE_OFFSET_MODE]:
        INSTALLATION_FLOW_STEPS.tempOffsetMode,
    [INSTALLATION_FLOW_ACTIONS.SHOW_TEMPERATURE_OFFSET_HELP]:
        INSTALLATION_FLOW_STEPS.tempOffsetConfigurationHelp,
    [INSTALLATION_FLOW_ACTIONS.INSTALLATION_SUCCEED]: INSTALLATION_FLOW_STEPS.successView,
    [INSTALLATION_FLOW_ACTIONS.FEEDBACK_PANEL_INSTALLATION_SUCCEED]:
        INSTALLATION_FLOW_STEPS.feedbackPanelSuccessView,
    [INSTALLATION_FLOW_ACTIONS.MONNIT_CONFIGURATION]:
        INSTALLATION_FLOW_STEPS.monnitConfiguration,
    [INSTALLATION_FLOW_ACTIONS.FLUDIA_CONFIGURATION]:
        INSTALLATION_FLOW_STEPS.fludiaConfiguration,
    [INSTALLATION_FLOW_ACTIONS.ELECTRICITY_MONITORING_CONFIGURATION]:
        INSTALLATION_FLOW_STEPS.electricityMonitoringConfiguration,
    [INSTALLATION_FLOW_ACTIONS.FLUDIA_TECHNICAL_CONFIGURATION]:
        INSTALLATION_FLOW_STEPS.fludiaTechnicalConfiguration,
    [INSTALLATION_FLOW_ACTIONS.MONNIT_DRY_PULSE_CONFIGURATION]:
        INSTALLATION_FLOW_STEPS.monnitDryPulseConfiguration,
};

export const installationFlowReducer = (
    state: Omit<InstallationFlowContextStateType, 'step'>,
    action: InstallationFlowAction,
): Omit<InstallationFlowContextStateType, 'step'> => {
    switch (action.type) {
        case INSTALLATION_FLOW_ACTIONS.GO_BACK:
            return { ...state, path: StepsManager.goBack(state.path) };
        case INSTALLATION_FLOW_ACTIONS.SCAN_TEMPERATURE_SENSOR:
        case INSTALLATION_FLOW_ACTIONS.CHECK_CONNECTION:
        case INSTALLATION_FLOW_ACTIONS.CONFIGURE_DEVICE:
        case INSTALLATION_FLOW_ACTIONS.SET_TEMPERATURE_OFFSET:
        case INSTALLATION_FLOW_ACTIONS.SHOW_TEMPERATURE_OFFSET_HELP:
        case INSTALLATION_FLOW_ACTIONS.FEEDBACK_PANEL_INSTALLATION_SUCCEED:
            return {
                ...state,
                path: StepsManager.setStep(state.path, MOVE_TO_STEP[action.type]),
            };
        case INSTALLATION_FLOW_ACTIONS.MONNIT_CONFIGURATION:
        case INSTALLATION_FLOW_ACTIONS.FLUDIA_CONFIGURATION:
        case INSTALLATION_FLOW_ACTIONS.ELECTRICITY_MONITORING_CONFIGURATION:
        case INSTALLATION_FLOW_ACTIONS.FLUDIA_TECHNICAL_CONFIGURATION:
        case INSTALLATION_FLOW_ACTIONS.MONNIT_DRY_PULSE_CONFIGURATION:
        case INSTALLATION_FLOW_ACTIONS.SELECT_TEMPERATURE_OFFSET_MODE:
            return {
                ...state,
                folderId: action.folderId,
                path: StepsManager.setStep(state.path, MOVE_TO_STEP[action.type]),
                sensorName: action.sensorName ?? state.sensorName,
                sensor: action.sensor,
            };
        case INSTALLATION_FLOW_ACTIONS.SET_SENSOR:
            return {
                ...state,
                sensor: action.sensor,
                lastSignalEvent: action.lastSignalEvent,
                registerInfo: action.sensor ? state.registerInfo : '', // Reset registerInfo when sensor is being unset
            };
        case INSTALLATION_FLOW_ACTIONS.INSTALL_WITH_SCANNER: {
            return {
                ...state,
                vendorTypeStep: null,
                path: StepsManager.setStep(
                    state.path,
                    INSTALLATION_FLOW_STEPS.qrCodeScanner,
                ),
            };
        }
        case INSTALLATION_FLOW_ACTIONS.INSTALLATION_SUCCEED:
            return {
                ...state,
                sensor: action.sensor,
                folderId: action.folderId,
                path: StepsManager.setStep(state.path, MOVE_TO_STEP[action.type]),
            };
        case INSTALLATION_FLOW_ACTIONS.SCAN:
            return {
                ...state,
                registerInfo: action.registerInfo,
                vendorTypeStep: INSTALLATION_FLOW_STEPS.qrCodeScanner,
            };
        case INSTALLATION_FLOW_ACTIONS.SELECT_TEMPERATURE_USE_CASE:
            return {
                ...state,
                sensor: action.sensor,
                lastSignalEvent: action.lastSignalEvent,
                path: StepsManager.setStep(
                    state.path,
                    action.lastSignalEvent
                        ? INSTALLATION_FLOW_STEPS.connectionCheck
                        : INSTALLATION_FLOW_STEPS.configuration,
                ),
            };
        case INSTALLATION_FLOW_ACTIONS.SELECT_VENDOR:
            if (!action.vendorTypeStep) {
                throw Error('vendorTypeStep must be provided');
            }

            return {
                ...state,
                vendorTypeStep: action.vendorTypeStep,
                path: StepsManager.setStep(state.path, action.vendorTypeStep),
            };
        case INSTALLATION_FLOW_ACTIONS.REGISTER_MANUALLY:
            return {
                ...state,
                registerInfo: action.registerInfo,
            };
        case INSTALLATION_FLOW_ACTIONS.SELECT_FEEDBACK_PANEL_TEMPLATE: {
            return {
                ...state,
                feedbackPanelTemplate: action.feedbackPanelTemplate,
                path: StepsManager.setStep(
                    state.path,
                    INSTALLATION_FLOW_STEPS.feedbackPanelTouchIdentifier,
                ),
            };
        }
        case INSTALLATION_FLOW_ACTIONS.RESET_FEEDBACK_PANEL_TEMPLATE_SELECTION:
            return {
                ...state,
                feedbackPanelTemplate: null,
                createFeedbackPanelValues: null,
                path: StepsManager.goBack(state.path),
            };
        case INSTALLATION_FLOW_ACTIONS.IDENTIFY_FEEDBACK_PANEL:
            return {
                ...state,
                createFeedbackPanelValues: action.createFeedbackPanelValues,
                path: StepsManager.setStep(
                    state.path,
                    INSTALLATION_FLOW_STEPS.feedbackPanelConfiguration,
                ),
            };
        case INSTALLATION_FLOW_ACTIONS.RETURN_TO_VENDOR_SELECTOR:
            return {
                ...state,
                registerInfo: '',
                vendorTypeStep: null,
                path: StepsManager.setStep(
                    state.path,
                    INSTALLATION_FLOW_STEPS.vendorSelector,
                ),
            };
        case INSTALLATION_FLOW_ACTIONS.RESET_PROGRESS:
            return { ...INSTALLATION_FLOW_INITIAL_CONTEXT };
        case INSTALLATION_FLOW_ACTIONS.TOGGLE_OVERWRITE:
            return {
                ...state,
                isOverwriteConfirmed: !state.isOverwriteConfirmed,
            };
        case INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING:
            return {
                ...state,
                isLoading: !state.isLoading,
            };
        default:
            return state;
    }
};

const InstallationFlowContextDispatcher =
    createContext<InstallationFlowContextDispatcherType>(noop);

export const useInstallationFlowContextDispatcher =
    (): InstallationFlowContextDispatcherType =>
        useContext(InstallationFlowContextDispatcher);

export default InstallationFlowContextDispatcher;
