import { makeStyles } from '@material-ui/core';

export const useMapHintsStyles = makeStyles((theme) => ({
    hintsTitle: {
        marginBottom: theme.spacing(1.25),
        textTransform: 'capitalize',
    },
    hintRow: {
        '&:not(:last-child)': {
            marginBottom: theme.spacing(0.75),
        },
    },
    hintRowTitle: {
        fontWeight: 500,
        textTransform: 'capitalize',

        '&:after': {
            content: '":"',
            display: 'inline',
            marginRight: '0.5ch',
        },
    },
}));
