import type { ErrorComponentProps } from '@infogrid/components-material-ui';
import { ErrorBoundary, Header } from '@infogrid/components-material-ui';
import { routesManager } from '@infogrid/core-routing';
import type { NamedRouteConfig } from '@infogrid/core-types';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as Sentry from '@sentry/react';
import { useCallback, memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import RenderChildren from 'components/RenderChildren';
import DesktopIntegrationsSidebar from 'views/integrations/components/DesktopIntegrationsSidebar';
import MobileIntegrationsSidebar from 'views/integrations/components/MobileIntegrationsSidebar';
import type { IntegrationsMenuItemGroup } from 'views/integrations/pages/integrations/types';

import { useIntegrationsStyles } from './styles';

interface Props {
    route: NamedRouteConfig;
}

const IntegrationsViewContainer = ({ route }: Props): JSX.Element => {
    const { t } = useTranslation('integrations');

    const styles = useIntegrationsStyles();

    const isMobile = useIsMobile();

    const menuItems: IntegrationsMenuItemGroup[] = [
        [
            t('Third Party Integrations'),
            [
                {
                    name: t('Corrigo'),
                    url: routesManager.resolvePath('integrations:integrations-corrigo'),
                },
            ],
        ],
        [
            t('Webhooks'),
            [
                {
                    name: t('Event Webhooks'),
                    url: routesManager.resolvePath(
                        'integrations:integrations-events-webhook',
                    ),
                },
            ],
        ],
    ];

    const renderPageNameRow = useCallback(
        () => (
            <Typography className={styles.pageNameText} variant="subtitle1">
                {t('Integrations')}
            </Typography>
        ),
        [styles, t],
    );

    return (
        <>
            <Helmet defaultTitle={t('Integrations')} />

            {!isMobile && (
                <Header
                    renderPageNameRowContent={renderPageNameRow}
                    pageName={route.pageName}
                    enableIdentifySensor={false}
                />
            )}

            {isMobile && (
                <MobileIntegrationsSidebar menuitems={menuItems} route={route} />
            )}

            <div className={styles.container}>
                {!isMobile && <DesktopIntegrationsSidebar menuitems={menuItems} />}
                <div className={styles.content}>
                    <ErrorBoundary
                        ErrorHandler={({ error }: ErrorComponentProps) => {
                            // As we're handling the error, ensure that we
                            // log it to sentry
                            Sentry.captureException(error);

                            // Render the alert, but within a SolutionBlock
                            // to retain some of the page shape
                            return (
                                <Alert severity="warning">
                                    {t(
                                        'We have been unable to load this section due to an error. Please refresh the page and try again.',
                                    )}
                                </Alert>
                            );
                        }}
                    >
                        <RenderChildren routes={route?.routes} />
                    </ErrorBoundary>
                </div>
            </div>
        </>
    );
};

export default memo(IntegrationsViewContainer);
