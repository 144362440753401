import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useEstateStyles = makeStyles((theme) => ({
    pageNameText: {
        marginLeft: theme.spacing(1),
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('lg')]: {
            fontSize: 20,
            fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
            marginLeft: theme.spacing(2),
        },
    },
}));
