import QueryParamsManager from '../QueryParamsManager';
import { formatSorting, parseArrayParams, parseNonArrayParams } from '../parsers';
import { BUILDING_PARAM_NAMES, BUILDING_PARAMS_SET } from './consts';
import type { BuildingQueryParams, FormattedBuildingQueryParams } from './types';

const FORMATTING_PARAMS_HANDLERS = {
    [BUILDING_PARAM_NAMES.ORDER_BY]: formatSorting,
    [BUILDING_PARAM_NAMES.COUNTRIES]: parseArrayParams,
    [BUILDING_PARAM_NAMES.TYPES]: parseArrayParams,
};

const PARSING_PARAMS_HANDLERS = {
    [BUILDING_PARAM_NAMES.QUERY]: parseNonArrayParams,
    [BUILDING_PARAM_NAMES.COUNTRIES]: parseArrayParams,
    [BUILDING_PARAM_NAMES.TYPES]: parseArrayParams,
};

const config = {
    paramsSet: BUILDING_PARAMS_SET,
    formattingParamsHandlers: FORMATTING_PARAMS_HANDLERS,
    parsingParamsHandlers: PARSING_PARAMS_HANDLERS,
    orderByKey: BUILDING_PARAM_NAMES.ORDER_BY,
};

export const buildingQueryParamsManager = new QueryParamsManager<
    BuildingQueryParams,
    FormattedBuildingQueryParams
>(
    config.paramsSet,
    config.formattingParamsHandlers,
    config.parsingParamsHandlers,
    config.orderByKey,
);
