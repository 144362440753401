import { PageError } from '@infogrid/components-material-ui';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import withView from 'decorators/withView';

const PermissionDenied = () => {
    const { t } = useTranslation();

    return (
        <PageError
            statusCode={403}
            title={t('Insufficient permissions')}
            description={t("You don't have permissions to access this page")}
        >
            <Helmet>
                <title>{t('Insufficient permissions')}</title>
            </Helmet>
        </PageError>
    );
};

export default withView()(PermissionDenied);
