import { routesManager } from '@infogrid/core-routing';
import { createFetchAction, createFetchSaga } from '@thorgate/spa-entities';
import { takeLatestWithMatch } from '@thorgate/spa-view-manager';

import { termsSchema } from 'schemas/terms';
import api from 'services/api';
import { serializeData } from 'utils/serializeData';

export const fetchActiveTerms = createFetchAction('sagas/account/FETCH_ACTIVE_TERMS');

export const fetchActiveTermsWorkerBase = createFetchSaga({
    key: termsSchema.key,
    listSchema: [termsSchema],
    resource: api.terms.active,
    serializeData,

    useDetails: true,
    mutateResponse: (response) => ({
        ...response,
        active: 'active',
    }),
});

export const fetchActiveTermsWorker = fetchActiveTermsWorkerBase.asInitialWorker(() =>
    fetchActiveTerms(),
);

export default function* fetchActiveTermsWatcher() {
    yield takeLatestWithMatch(
        fetchActiveTerms.getType(),
        routesManager.resolvePattern('terms'),
        fetchActiveTermsWorkerBase,
    );
}
