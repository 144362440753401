import type { QueryKey } from 'react-query';

import {
    ACQUIRE_SENSOR_PLANNING_LOCK_KEY,
    BATCH_CREATE_PLANNED_SENSORS_KEY,
    FLOORPLAN_MAPPED_SENSORS_KEY,
    FLOOR_SENSORS_KEY,
    PLANNED_SENSORS_QUERY_KEY,
    SENSORS_TYPES_AND_HUBS_KEY,
    SENSOR_PLANNING_LOCK_KEY,
    FLOORPLAN_SENSOR_ALERTS,
} from 'apiHooks/base';

/**
 * @deprecated
 */
export const getMappedSensorsKey = ({ floorId }: { floorId?: number }): QueryKey => [
    FLOORPLAN_MAPPED_SENSORS_KEY,
    floorId,
];

export const getFloorSensorsKey = ({
    floorId,
    params,
}: {
    floorId?: number;
    params?: { [key: string]: unknown };
}): QueryKey => [FLOOR_SENSORS_KEY, floorId, params];

export const getSensorsTypesAndHubs = ({ floorId }: { floorId?: number }): QueryKey => [
    SENSORS_TYPES_AND_HUBS_KEY,
    floorId,
];

export const getPlannedSensorsKey = ({ floorId }: { floorId?: number }): QueryKey => [
    PLANNED_SENSORS_QUERY_KEY,
    floorId,
];

export const acquireSensorPlanningLockKey = ({
    floorId,
}: {
    floorId: number;
}): QueryKey => [ACQUIRE_SENSOR_PLANNING_LOCK_KEY, floorId];

export const getBatchCreatePlannedSensorsKey = ({
    floorId,
}: {
    floorId: number;
}): QueryKey => [BATCH_CREATE_PLANNED_SENSORS_KEY, floorId];

export const sensorPlanningLockKey = ({ floorId }: { floorId: number }): QueryKey => [
    SENSOR_PLANNING_LOCK_KEY,
    floorId,
];

export const getFloorSensorAlertsKey = ({
    floorId,
    params,
}: {
    floorId?: number;
    params?: { acknowledged: boolean };
}): QueryKey => [FLOORPLAN_SENSOR_ALERTS, floorId, params];
