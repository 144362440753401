import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import { memo } from 'react';
import type { RouteConfigComponentProps } from 'react-router-config';

import { useCustomError, useFloorPlanParams } from 'apiHooks/base/hooks';
import { useBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import { useFloor, useFloors, useSelectFloor } from 'apiHooks/floorPlan/floors/hooks';
import RenderChildren from 'components/RenderChildren';

import ErrorPageFloorplanCard from '../../components/cards/ErrorPageCards/ErrorPageFloorplanCard';
import { useStylesContainerBuildings } from './styles';
import { generateErrorsForCard, handleFetchingError } from './utils';

interface Props {
    route: RouteConfigComponentProps['route'];
}

const FloorView = ({ route }: Props) => {
    const stylesBuildingsRoot = useStylesContainerBuildings();

    const { buildingId, floorId } = useFloorPlanParams();

    // TODO: https://infogrid.atlassian.net/browse/WEB-4112
    // @ts-expect-error: buildingId can be null but useBuildingExpects a number
    useFloors(buildingId, { 'include-user-actions': true }, { refetchOnMount: true });

    // TODO: https://infogrid.atlassian.net/browse/WEB-4112
    // @ts-expect-error: floorId can be null but useBuildingExpects a number
    const { error: floorError, isLoading: isFloorLoading } = useFloor(floorId, {
        refetchOnMount: true,
    });

    const { error: buildingError, isLoading: isBuildingLoading } = useBuilding(
        buildingId,
        { refetchOnMount: true },
    );

    const { isFloorInList } = useSelectFloor({
        buildingId: buildingId || undefined,
        floorId: floorId || undefined,
    });

    const { error: customBuildingError } = useCustomError({
        error: buildingError,
        handleError: handleFetchingError,
    });

    const { error: customFloorError } = useCustomError({
        error: floorError,
        handleError: handleFetchingError,
    });

    if (isFloorLoading || isBuildingLoading) {
        return (
            <LoadingPlaceholder
                progressSize={48}
                classes={{
                    container: stylesBuildingsRoot.floorPlanLoaderContainer,
                }}
            />
        );
    }

    if (customBuildingError || customFloorError || !isFloorInList) {
        const { message, status, type } = generateErrorsForCard({
            customBuildingError,
            customFloorError,
            isFloorInList,
        });

        return (
            <div className={stylesBuildingsRoot.errorContainer}>
                <ErrorPageFloorplanCard type={type} status={status} message={message} />
            </div>
        );
    }

    return (
        <div className={stylesBuildingsRoot.container}>
            <div className={stylesBuildingsRoot.content}>
                <RenderChildren routes={route?.routes} />
            </div>
        </div>
    );
};

export default memo(FloorView);
