import { useDashboard, useUpdateWidget, useWidgetData } from '@infogrid/dashboards-hooks';
import { useIsMobile, useIsOpenState } from '@infogrid/utils-hooks';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { CircularProgress } from '@material-ui/core';
import { memo, useRef } from 'react';
import type { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router';

import SETTINGS from 'settings';
import { GenericActions, GenericTitle, Widget } from 'views/dashboards/components/Widget';
import { GenericFooter } from 'views/dashboards/components/Widget/GenericWidget';
import { configurationDefaults } from 'views/dashboards/components/Widget/GenericWidget/constants';
import {
    useGenericWidgetContainerStyles,
    useGenericWidgetStyles,
} from 'views/dashboards/components/Widget/GenericWidget/styles';
import type { ConfigurationProps } from 'views/dashboards/components/Widget/GenericWidget/types';
import { DeleteWidgetModal } from 'views/dashboards/components/modals';
import ConfigureWidgetModal from 'views/dashboards/components/modals/ConfigureWidgetModal';
import { canChangeDashboard, hasUserPermissions } from 'views/dashboards/utils/helpers';
import { useConfigureWidgetModalOpenState } from 'views/dashboards/utils/hooks';

import { generateGenericFooterLabels } from '../utils';
import Content from './Content';
import { widgetConfigurationModalTabs } from './constants';
import { useWidgetStyles } from './styles';
import type {
    PeopleCountingStoplightData,
    PeopleCountingStoplightValues,
    PeopleCountingStoplightWidget as PeopleCountingStoplightWidgetType,
} from './types';
import {
    prepareConfigurationPayload,
    prepareInitialValues,
    validationSchema,
} from './utils';

interface PeopleCountingStoplightWidgetProps {
    canDragAndResize: boolean;
    configuration?: ConfigurationProps;
    layout: Layout;
    useIntersectionObserverLoader?: boolean;
    widget: PeopleCountingStoplightWidgetType;
}

const PeopleCountingStoplightWidget = ({
    canDragAndResize,
    configuration = configurationDefaults,
    layout,
    useIntersectionObserverLoader = true,
    widget,
}: PeopleCountingStoplightWidgetProps) => {
    const containerStyles = useGenericWidgetContainerStyles();
    const genericWidgetStyles = useGenericWidgetStyles();
    const contentStyles = useWidgetStyles();

    const isMobile = useIsMobile();

    const { t, i18n } = useTranslation('dashboard');

    const { dashboardId } = useParams<{ dashboardId: string }>();

    const { data: dashboard } = useDashboard(+dashboardId);

    const handleOpenKioskMode = () => {
        if (widget?.kiosk_mode_url) {
            const kioskUrl = `${SETTINGS.BACKEND_SITE_URL}${widget.kiosk_mode_url}`;

            window.open(kioskUrl);
        }
    };

    const {
        handleConfigureWidgetModalClose,
        handleConfigureWidgetModalOpen,
        isConfigureWidgetModalOpen,
    } = useConfigureWidgetModalOpenState(widget.id, widget.type);

    const isHardRefreshData = useRef(false);

    const [
        deleteWidgetModalOpen,
        handleDeleteWidgetModalClose,
        handleDeleteWidgetModalOpen,
    ] = useIsOpenState();

    const { mutate: updateWidget, isLoading: isUpdatingWidget } =
        useUpdateWidget<PeopleCountingStoplightWidgetType>();

    /**
     * INFO: this check is enough for now, when user selects
     * sensor and clicks save - all the date range / display options
     * defaults are generated & saved as well
     */
    const isInitiallyConfigured = Boolean(widget.configuration.time_period_range);

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: '100px 0px',
        skip: !useIntersectionObserverLoader,
        initialInView: !useIntersectionObserverLoader,
        triggerOnce: true,
    });

    const {
        data: widgetData,
        isFetching: fetchingWidgetData,
        isLoading: loadingWidgetData,
        isSuccess: loadingWidgetDataSuccess,
        refetch: refetchWidgetData,
    } = useWidgetData<PeopleCountingStoplightData>(+dashboardId, widget.id, {
        getQueryParams: () => ({
            refresh: isHardRefreshData.current || undefined,
        }),
        options: {
            enabled: inView && isInitiallyConfigured,
            onSettled: () => {
                isHardRefreshData.current = false;
            },
        },
    });

    const onUpdateWidgetTitle = (title: string) =>
        updateWidget({
            dashboardId: +dashboardId,
            widgetId: widget.id,
            data: { name: title },
        });

    const onUpdateConfiguration = (values: PeopleCountingStoplightValues) =>
        updateWidget({
            dashboardId: +dashboardId,
            widgetId: widget.id,
            data: {
                ...widget,
                configuration: prepareConfigurationPayload(values),
            },
        });

    const widgetConfigurationInitialValues = prepareInitialValues(widget);

    const { refreshRateLabel, sensorsLabel } = generateGenericFooterLabels(
        widget,
        widgetConfigurationInitialValues,
        t,
        i18n,
    );

    const {
        actionsEnabled,
        isActionsEnabled,
        isEnabled: isConfigureWidgetModalEnabled,
    } = configuration;

    const widgetCardRef = useRef(null);

    const onRefresh = () => {
        isHardRefreshData.current = true;
        refetchWidgetData();
    };

    const canUserEdit =
        !!dashboard && hasUserPermissions(dashboard) && canChangeDashboard(dashboard);

    return (
        <div ref={ref} className={containerStyles.container} data-cypress="widget">
            <Widget>
                <Widget.Bar canDragAndResize={canDragAndResize}>
                    <GenericTitle
                        onUpdateTitle={onUpdateWidgetTitle}
                        title={widget.name}
                        titleEditable={canUserEdit}
                        titleIcon={<i className="far fa-hand-paper" />}
                    />
                    {((actionsEnabled && !isMobile) || isActionsEnabled) && (
                        <GenericActions
                            canUserEdit={canUserEdit}
                            configurableProps={{
                                isConfigureWidgetAvailable: true,
                                isDuplicateDisabled: !isInitiallyConfigured,
                                isKioskModeAvailable: true,
                                onConfigure: handleConfigureWidgetModalOpen,
                                onDelete: handleDeleteWidgetModalOpen,
                                onOpenKioskMode: handleOpenKioskMode,
                            }}
                            dashboardId={dashboardId}
                            fullscreenProps={{
                                enabled: true,
                                onFullscreenClick: handleOpenKioskMode,
                            }}
                            refreshableProps={{
                                enabled: false,
                                isLoading: fetchingWidgetData && loadingWidgetDataSuccess,
                                onRefresh,
                            }}
                            widgetConfiguration={widget.configuration}
                            widgetName={widget.name}
                            widgetType={widget.type}
                        />
                    )}
                </Widget.Bar>

                {loadingWidgetData && (
                    <div className={genericWidgetStyles.progress}>
                        <CircularProgress
                            color="primary"
                            data-testid="widget-circular-loader"
                            size={40}
                        />
                    </div>
                )}

                {inView && !loadingWidgetData && (
                    <Widget.Content
                        ref={widgetCardRef}
                        className={contentStyles.content}
                        layout={layout}
                    >
                        <Content
                            data={widgetData}
                            isConfigured={isInitiallyConfigured}
                            layout={layout}
                            widget={widget}
                        />
                    </Widget.Content>
                )}

                <GenericFooter
                    periodLabel={registerTranslationKey('Live', { ns: 'dashboard' })}
                    refreshRateLabel={refreshRateLabel}
                    sensorsLabel={sensorsLabel}
                />

                {isConfigureWidgetModalEnabled && isConfigureWidgetModalOpen && (
                    <ConfigureWidgetModal
                        initialValues={widgetConfigurationInitialValues}
                        loading={isUpdatingWidget}
                        onClose={handleConfigureWidgetModalClose}
                        onUpdateConfiguration={onUpdateConfiguration}
                        open={isConfigureWidgetModalOpen}
                        tabs={widgetConfigurationModalTabs}
                        validationSchema={validationSchema}
                    />
                )}
                <DeleteWidgetModal
                    dashboardId={dashboardId}
                    onClose={handleDeleteWidgetModalClose}
                    open={deleteWidgetModalOpen}
                    widgetId={widget.id}
                    widgetName={widget.name}
                    widgetType={widget.type}
                />
            </Widget>
        </div>
    );
};

export default memo(PeopleCountingStoplightWidget);
