import { useAppDispatch } from '@infogrid/core-ducks';
import { SENSOR_VENDORS } from '@infogrid/sensors-constants';
import type { FC } from 'react';
import { useCallback } from 'react';

import { useRegisterSensor } from 'apiHooks/sensors/installation/hooks';
import {
    INSTALL_STEPS,
    goToStep,
    onSensorIdentified,
    handleInstallError,
} from 'ducks/installFlow';

import IdentifyDT from './IdentifyDT';

const IdentifyDTContainer: FC = () => {
    const dispatch = useAppDispatch();

    const { mutate: identifySensor } = useRegisterSensor({
        onSuccess: (data) => {
            dispatch(onSensorIdentified(data));
        },
        onError: ({ response }) => {
            dispatch(handleInstallError(response));
        },
    });

    const onIdentifySuccess = useCallback(
        ({ sourceDeviceName }) => {
            const [_vendor, serialNumber] = sourceDeviceName.split('_');

            if (serialNumber) {
                identifySensor({
                    vendor: SENSOR_VENDORS.DT,
                    serial_number: serialNumber,
                });
            }
        },
        [identifySensor],
    );

    const onClickBack = useCallback(
        () => dispatch(goToStep(INSTALL_STEPS.BARCODE_SCAN)),
        [dispatch],
    );

    return <IdentifyDT onIdentifySuccess={onIdentifySuccess} onClickBack={onClickBack} />;
};

export default IdentifyDTContainer;
