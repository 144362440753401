import { Formik } from 'formik';
import { memo, useCallback } from 'react';

import type { CleaningRulesPayload } from 'views/solutionSettings/apiHooks/types';
import { useSetCleaningRules } from 'views/solutionSettings/apiHooks/useSetCleaningRules';
import type { FlattenedNodes } from 'views/solutionSettings/types';

import type { ManageRulesFlattenedNode } from '../../types';
import { RulesModal } from './RulesModal';
import { VALIDATION_SCHEMA } from './constants';
import type { CleaningValues } from './types';

type CleaningField = 'mightNeedCleaning' | 'doesNeedCleaning';

interface GetDefaultValueProps {
    knownNodes: FlattenedNodes<ManageRulesFlattenedNode>;
    locations: number[];
    field: CleaningField;
}

const getDefaultValue = ({
    knownNodes,
    locations,
    field,
}: GetDefaultValueProps): number | 'N/A' | undefined => {
    const selectedNodes = locations.map((k) => knownNodes[k]).filter((n) => n.id !== 1); // filter out top level org

    if (new Set(selectedNodes.map((s) => s[field])).size === 1) {
        if (selectedNodes[0][field] === 'mixed') {
            return undefined;
        }

        return selectedNodes[0][field] === null ? 'N/A' : Number(selectedNodes[0][field]);
    }

    return undefined;
};

interface RulesModalProps {
    open: boolean;
    handleClose: () => void;
    locations: number[];
    knownNodes: FlattenedNodes<ManageRulesFlattenedNode>;
    refetch: () => void;
}

export const RulesModalContainer = memo(function RulesModalContainer({
    open,
    handleClose,
    locations,
    refetch,
    knownNodes,
}: RulesModalProps): JSX.Element {
    const { mutate } = useSetCleaningRules();

    const submit = useCallback(
        (submitValues: CleaningValues, formik) => {
            const mutateValues: CleaningRulesPayload = {
                does_need_cleaning_threshold: submitValues.must_be_cleaned_value,
                locations: locations.join(','),
            };

            const mightNeedCleaningValue = submitValues.might_need_cleaning_value;
            const isNANotProvided =
                mightNeedCleaningValue &&
                mightNeedCleaningValue?.toString().toLowerCase() !== 'n/a';

            if (isNANotProvided) {
                mutateValues.might_need_cleaning_threshold =
                    Number(mightNeedCleaningValue) || 0;
            }

            mutate(mutateValues, {
                onSuccess: () => {
                    refetch();
                    formik.resetForm();
                    handleClose();
                },
            });
        },
        [handleClose, locations, mutate, refetch],
    );

    return (
        <Formik
            initialValues={{
                might_need_cleaning_value: getDefaultValue({
                    knownNodes,
                    locations,
                    field: 'mightNeedCleaning',
                }),
                must_be_cleaned_value: getDefaultValue({
                    knownNodes,
                    locations,
                    field: 'doesNeedCleaning',
                }) as number | undefined,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={submit}
        >
            <RulesModal open={open} handleClose={handleClose} />
        </Formik>
    );
});
