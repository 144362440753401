import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useFloorsListStyles = makeStyles((theme) => ({
    floorsListHeader: {
        alignItems: 'center',
        display: 'flex',
        padding: theme.spacing(2),
    },
    numberOfFloors: {
        color: theme.palette.common.gray8,
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
    addFloorButtonWrapper: {
        marginLeft: 'auto',
    },
    addFloorButtonIcon: {
        fontSize: 13,
        marginRight: theme.spacing(0.5),
    },
    divider: {
        backgroundColor: theme.palette.common.shell,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    floorsList: {
        height: '100%',
        overflow: 'auto',
    },
    searchInput: {
        margin: theme.spacing(2),
        width: `calc(100% - ${theme.spacing(4)}px)`,
    },
}));
