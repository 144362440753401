import { makeStyles } from '@material-ui/core';

import {
    PRIMARY_GREEN,
    SECONDARY_GREEN,
    SECONDARY_YELLOW,
    PRIMARY_RED,
    SECONDARY_RED,
} from '../constants';

export const useRightCard = makeStyles((theme) => ({
    rightCard: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    rightCardContent: ({ layout }) => ({
        paddingLeft: layout?.w === 1 ? theme.spacing(2) : theme.spacing(8),
        paddingRight: theme.spacing(1),
        paddingTop: layout?.h === 1 ? theme.spacing(2) : theme.spacing(10),
        [theme.breakpoints.down('xs')]: {
            margin: 'auto',
            paddingLeft: theme.spacing(0),
            textAlign: 'center',
        },
    }),
    cardRow: ({ layout }) => {
        const regularMarginBottom = layout?.h === 1 ? 3 : 6;

        return {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: theme.spacing(regularMarginBottom),
            [theme.breakpoints.down('xs')]: {
                marginBottom: theme.spacing(1),
                flexDirection: 'column',
                alignItems: 'center',
            },
        };
    },
    smallIconContainer: ({ layout }) => ({
        fontSize: layout?.w === 1 ? '20px' : '29px',
        width: '1.2em',
        height: '1.2em',
        lineHeight: '1.2em',
        color: theme.palette.common.white,
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2.5),
        },
    }),
    recommendedActionsText: ({ layout }) => {
        const regularFontSize =
            layout?.w === 1
                ? theme.typography.subtitle1.fontSize
                : theme.typography.h6.fontSize;

        return {
            color: theme.palette.common.white,
            fontSize: regularFontSize,
            lineHeight: theme.typography.h6.lineHeight,
            wordBreak: 'break-word',
            [theme.breakpoints.down('xs')]: {
                fontSize: '16px',
            },
        };
    },
    // Functions lead to higher specificity compared to objects, this is fixed in MUI v5
    secondaryGoodAvailabilityBackground: () => ({
        background: SECONDARY_GREEN,
    }),
    secondaryNearCapacityBackground: () => ({
        background: SECONDARY_YELLOW,
    }),
    secondaryOutOfCapacityBackground: () => ({
        background: SECONDARY_RED,
    }),
    primaryGoodAvailabilityColor: () => ({
        color: PRIMARY_GREEN,
    }),
    primaryNearCapacityColor: () => ({
        color: '#F57C00',
    }),
    primaryOutOfCapacityColor: () => ({
        color: PRIMARY_RED,
    }),
}));
