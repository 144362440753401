import { makeStyles } from '@material-ui/core';

export const useSpaceSidebarHeaderStyles = makeStyles((theme) => ({
    container: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    subcontainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    closeButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(2),
    },
    closeButton: {
        marginLeft: 'auto',
    },
    closeIcon: {
        marginLeft: theme.spacing(0.5),
        width: 20,
    },
    headerIcon: {
        alignItems: 'center',
        color: theme.palette.common.gray6,
        display: 'flex',
        fontSize: 48,
        height: 52,
    },
    spaceEditContainer: {
        alignItems: 'center',
        display: 'flex',
        marginTop: theme.spacing(3),
        maxWidth: '90%',
    },
    spaceEditContainerLoading: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: theme.spacing(3),
        maxWidth: '90%',
    },
    spaceName: {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
    },
    editSpaceInput: {
        fontSize: 20,
    },
    editSpaceIcon: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        fontSize: theme.typography.pxToRem(20),
        height: 22,
    },
    editingEditSpaceInput: {
        marginBottom: theme.spacing(-0.9),
        marginTop: theme.spacing(-0.9),
    },
    spaceTypeEditIcon: {
        color: theme.palette.common.gray11,
        cursor: 'pointer',
        fontSize: 20,
        height: 22,
        marginLeft: theme.spacing(1),
    },
    description: {
        color: theme.palette.common.gray6,
        fontSize: 14,
        marginTop: theme.spacing(2),
    },
    actionsContainer: {
        marginTop: theme.spacing(3),
        '& > button:nth-child(2)': {
            margin: theme.spacing(0, 2),
        },
    },
    cta: {
        margin: theme.spacing(0, 1),
    },
    tooltipWrapper: {
        display: 'inline-flex',
    },
}));
