import { Modal } from '@infogrid/components-material-ui';
import { useIsMobile, useIsOpenState } from '@infogrid/utils-hooks';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import rangeChartUrl from './images/range.svg';
import singleLessThanChartUrl from './images/single-less.svg';
import singleMoreThanChartUrl from './images/single-more.svg';
import { useThresholdsExplainerStyles } from './styles';

const ThresholdsExplainer = () => {
    // NOTE: chart images in this component contain words that aren't translated ('pass' and 'fail')

    const { t } = useTranslation('solutions');
    const styles = useThresholdsExplainerStyles();

    const isMobile = useIsMobile();
    const [isOpen, handleClose, handleOpen] = useIsOpenState();

    return (
        <>
            <Box>
                <Button color="primary" size="small" onClick={handleOpen}>
                    {t('How do thresholds work?')}
                </Button>
            </Box>
            <Modal
                fullScreen={isMobile}
                maxWidth="sm"
                open={isOpen}
                onClose={handleClose}
            >
                <Modal.Title>{t('How do thresholds work?')}</Modal.Title>
                <Modal.Content>
                    <Grid container spacing={3} className={styles.content}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className={styles.subtitle}>
                                {t('What is a threshold?')}
                            </Typography>
                            <Typography variant="body2" className={styles.body2}>
                                {t(
                                    'A threshold is a setting applied to each pipe type. It defines the temperature that water within the pipe must be in order for that pipe to pass or fail during a reporting period. ',
                                )}
                            </Typography>
                            <Typography variant="body2" className={styles.body2}>
                                <Trans t={t}>
                                    Thresholds can be set as a{' '}
                                    <strong>single threshold</strong> (less than or more
                                    than) or as a <strong>range threshold</strong> (any
                                    temperature between a minimum and maximum). See below
                                    for more detail.
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className={styles.subtitle}>
                                {t('Single thresholds')}
                            </Typography>
                            <Typography variant="body2" className={styles.body2}>
                                {t(
                                    'Single thresholds are useful for pipes where the water only needs to be less than or more than a specific temperature once during a reporting period. ',
                                )}
                            </Typography>
                            <Typography variant="subtitle2" className={styles.subtitle2}>
                                {t('‘Less than’ single threshold example')}
                            </Typography>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <img
                                    className={styles.chart}
                                    src={singleLessThanChartUrl}
                                    alt={t(
                                        'Chart illustrating the Single threshold for Less Than comparison',
                                    )}
                                />
                                <Typography variant="caption" className={styles.caption}>
                                    {t(
                                        'Chart shows a single threshold (less than) applied to a cold water pipe where the temperature needs to be less than 20°C once during the reporting period to pass.',
                                    )}
                                </Typography>
                            </Box>
                            <Typography variant="subtitle2" className={styles.subtitle2}>
                                {t('‘More than’ single threshold example')}
                            </Typography>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <img
                                    className={styles.chart}
                                    src={singleMoreThanChartUrl}
                                    alt={t(
                                        'Chart illustrating the Single threshold for More Than comparison',
                                    )}
                                />
                                <Typography variant="caption" className={styles.caption}>
                                    {t(
                                        'Chart shows a single threshold (more than) applied to a hot water pipe where the temperature needs to be more than 50°C once during the reporting period to pass.',
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className={styles.subtitle}>
                                {t('Range thresholds')}
                            </Typography>
                            <Typography variant="body2" className={styles.body2}>
                                {t(
                                    'Range thresholds are useful for pipes where the water must stay between a minimum and maximum temperature at all times during a reporting period.',
                                )}
                            </Typography>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <img
                                    className={styles.chart}
                                    src={rangeChartUrl}
                                    alt={t('Chart illustrating the Range threshold')}
                                />
                                <Typography variant="caption" className={styles.caption}>
                                    {t(
                                        'Chart shows a range threshold (min. and max.) applied to a Hot POU pipe that must stay between 50-60°C at all times during the reporting period to pass. If the temperature is outside the range once during the period it will fail.',
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default ThresholdsExplainer;
