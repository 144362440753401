import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface DashboardContextValues {
    configurationModalInitiallyOpened: boolean;
    configurationModalWidgetId: number | null;
    setConfigurationModalWidgetId: (
        widgetId: number | null,
        initiallyOpen?: boolean,
    ) => void;
}

export const DashboardContext = createContext<DashboardContextValues>({
    configurationModalInitiallyOpened: false,
    configurationModalWidgetId: null,
    setConfigurationModalWidgetId: () => {},
});

export const DashboardProvider = ({ children }: { children: ReactNode }) => {
    const [configurationModalInitiallyOpened, setConfigurationModalInitiallyOpened] =
        useState<boolean>(false);

    const [configurationModalWidgetId, setModalWidgetId] = useState<number | null>(null);

    const setConfigurationModalWidgetId = useCallback(
        (widgetId: number | null, initiallyOpen = false) => {
            setModalWidgetId(widgetId);
            setConfigurationModalInitiallyOpened(initiallyOpen);
        },
        [],
    );

    const value = useMemo(
        () => ({
            configurationModalInitiallyOpened,
            configurationModalWidgetId,
            setConfigurationModalWidgetId,
        }),
        [
            configurationModalInitiallyOpened,
            configurationModalWidgetId,
            setConfigurationModalWidgetId,
        ],
    );

    return (
        <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
    );
};

export const useDashboardContext = (): DashboardContextValues => {
    const context = useContext<DashboardContextValues>(DashboardContext);

    if (!context) {
        throw new Error('useDashboardContext must be used within DashboardProvider');
    }

    return context;
};
