import { makeStyles } from '@material-ui/core';

export const useLocationSelectorCounterStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 3),
        borderTop: `1px solid ${theme.palette.common.shell}`,
        background: theme.palette.background.default,
        borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    },
    countText: {
        margin: 'auto 0',
        paddingLeft: theme.spacing(1),
    },
}));
