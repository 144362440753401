import { formatDistance } from '@infogrid/utils-dates';
import { useMemo, useState } from 'react';
import { useInterval, useBoolean } from 'react-use';

interface useUpdateDateIntervalResult {
    currentDate: Date;
    initialDate: Date;
    diffValue: number;
    formattedDiffDate: string;
    toggleIsRunning: (isRunning: boolean) => void;
}

export const useUpdateDateInterval = ({
    initialDate,
    intervalTime = 10000,
    formatHandler = formatDistance,
    isRunning = true,
}: {
    initialDate: Date;
    intervalTime: number;
    formatHandler: (from: Date, to: Date) => string;
    isRunning: boolean;
}): useUpdateDateIntervalResult => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [isRunningState, toggleIsRunning] = useBoolean(true);

    const isRunningValue = useMemo(
        () => isRunningState && isRunning,
        [isRunning, isRunningState],
    );

    const diffValue = useMemo(
        () => +currentDate - +initialDate,
        [currentDate, initialDate],
    );

    const formattedDiffDate = useMemo(
        () => formatHandler(currentDate, initialDate),
        [currentDate, initialDate, formatHandler],
    );

    useInterval(
        () => {
            setCurrentDate(new Date());
        },
        isRunningValue ? intervalTime : null,
    );

    return {
        currentDate,
        initialDate,
        diffValue,
        formattedDiffDate,
        toggleIsRunning,
    };
};
