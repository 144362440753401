import { makeStyles } from '@material-ui/core';

export const useShareDashboardModalStyles = makeStyles((theme) => ({
    autocompleteContainer: {
        marginTop: theme.spacing(4),
    },
    usersPickerContainer: {
        marginTop: theme.spacing(4),
    },
    inputSearchIcon: {
        color: theme.palette.common.gray1,
        marginLeft: theme.spacing(0.5),
    },
}));
