import { useAppSelector } from '@infogrid/core-ducks';
import type { AxiosParsedError, EventsWebhook } from '@infogrid/core-types';
import { selectOrganization } from '@infogrid/user-ducks';
import { useState } from 'react';
import type { UseMutateFunction } from 'react-query';

import { useEventsWebhooks } from 'apiHooks/eventsWebhook/hooks';
import useCreateEventsWebhook from 'apiHooks/eventsWebhook/hooks/useCreateEventsWebhook';
import { useDeleteEventsWebhook } from 'apiHooks/eventsWebhook/hooks/useDeleteEventsWebhook';
import { useEventsWebhookConfig } from 'apiHooks/eventsWebhook/hooks/useEventsWebhookConfig';
import useToggleEventsWebhook from 'apiHooks/eventsWebhook/hooks/useToggleEventsWebhook';
import useUpdateEventsWebhook from 'apiHooks/eventsWebhook/hooks/useUpdateEventsWebhook';

import FilterableWebhookView from './FilterableWebhookView';

export type MutateWebhookFn<T> = UseMutateFunction<EventsWebhook, AxiosParsedError, T>;

const FilterableWebhookViewContainer = () => {
    const organization = useAppSelector(selectOrganization);
    const organizationId = organization?.id.toString() || '0';
    const [webhookToEdit, setWebhookToEdit] = useState<EventsWebhook | undefined>();

    const { mutate: createEventsWebhook, isLoading: isCreateSubmitting } =
        useCreateEventsWebhook();

    const { mutate: updateEventsWebhook, isLoading: isUpdateSubmitting } =
        useUpdateEventsWebhook(webhookToEdit?.id);

    const { mutate: deleteEventsWebhook, isLoading: isDeleteSubmitting } =
        useDeleteEventsWebhook(webhookToEdit);

    const { mutate: toggleEventsWebhook, isLoading: isToggleSubmitting } =
        useToggleEventsWebhook();

    const {
        data: webhookData,
        isLoading: webhooksLoading,
        refetch: refetchWebhooks,
    } = useEventsWebhooks({
        organization_id: organizationId,
    });

    const {
        data: webhookConfig,
        isLoading: configLoading,
        refetch: refetchWebhookConfig,
    } = useEventsWebhookConfig({
        organization_id: organizationId,
    });

    return (
        <FilterableWebhookView
            webhookToEdit={webhookToEdit}
            webhookData={webhookData}
            config={webhookConfig}
            isLoading={webhooksLoading || configLoading}
            createEventsWebhook={createEventsWebhook}
            deleteEventsWebhook={deleteEventsWebhook}
            toggleEventsWebhook={toggleEventsWebhook}
            updateEventsWebhook={updateEventsWebhook}
            setWebhookToEdit={setWebhookToEdit}
            refetchWebhooks={refetchWebhooks}
            refetchWebhookConfig={refetchWebhookConfig}
            isCreateSubmitting={isCreateSubmitting}
            isDeleteSubmitting={isDeleteSubmitting}
            isToggleSubmitting={isToggleSubmitting}
            isUpdateSubmitting={isUpdateSubmitting}
        />
    );
};

export default FilterableWebhookViewContainer;
