import { createDetailSchemaSelector, createSchemaSelector } from '@thorgate/spa-entities';
import { schema } from 'normalizr';
import { createSelector } from 'reselect';

import { sensorSchema } from 'schemas/sensor';
import { createRelatedKeyFn } from 'utils/relations';

export const folderSchema = new schema.Entity('folders');

export const selectFolders = createSchemaSelector(folderSchema);
export const selectFolder = createDetailSchemaSelector(folderSchema);

// All folders by id
// (state) => { [id]: { ... } }
export const selectFolderData = (state) => state.entities.data.folders || {};

export const folderIdSelector = (keyOptions) => keyOptions?.folderId || 0;

// `folders-${folderId}-sensors-${filterName}`
export const relatedSensorsKeyFn = createRelatedKeyFn(
    folderSchema.key,
    sensorSchema.key,
    folderIdSelector,
    true,
);

// `folders-${folderId}-folders`
export const relatedSubfoldersKeyFn = createRelatedKeyFn(
    folderSchema.key,
    folderSchema.key,
    folderIdSelector,
    true,
);
export const selectFolderSensors = createSchemaSelector(
    sensorSchema,
    relatedSensorsKeyFn,
);

export const selectFolderSubfolders = createSchemaSelector(
    folderSchema,
    relatedSubfoldersKeyFn,
);

// returns all folders-id-folders/sensors keys from store
export const selectFolderTreeKeys = createSelector(
    (state) => state.entities.order,
    (order) => Object.keys(order).filter((key) => key.startsWith('folder')),
);

// This is required only because the `createFetchSaga` needs a key to store stuff into.
// The real "tree" is stored in `ducks/folders`
