import { makeStyles } from '@material-ui/core';

export const useFloorRowStyles = makeStyles((theme) => ({
    menuListContainer: {
        minWidth: 'unset',
    },
    contextMenuContent: {
        left: 'unset',
        right: 0,
        '& > div': {
            minWidth: 'unset',
        },
    },
    lastRowMenuContent: {
        top: -60,
    },
    contextMenuItem: {
        textTransform: 'unset',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    dragHandle: {
        color: theme.palette.common.gray4,
        cursor: 'pointer !important',
        fontSize: 16,
        width: 16,
    },
    deleteLabel: {
        color: theme.palette.secondary.main,
    },
}));
