import { CircularProgress, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageContainer from 'components/PageContainer';
import type { SmartCleaningHierarchyResponse } from 'views/solutionSettings/apiHooks/types';
import { LocationsSelectorCounter } from 'views/solutionSettings/components/LocationSelectorCounter';
import type { PaginatedTreeCurrentNode } from 'views/solutionSettings/components/PaginatedTreeSelector';
import PaginatedTreeSelector from 'views/solutionSettings/components/PaginatedTreeSelector';
import type { LocationsCount } from 'views/solutionSettings/pages/smartCleaning/utils/selectionUtils';
import type { FlattenedNodes, NodeSelectionStruct } from 'views/solutionSettings/types';
import { SELECTION_STATES } from 'views/solutionSettings/types';

import {
    flattenResponseLocations,
    getCurrentNodeTree,
    updateLocationsCount,
    updateNodeSelectionStateOnPage,
    updateNodeSelectionStateOnSelect,
} from '../utils/selectionUtils';
import { SensorResetModal } from './components/SensorResetModal';
import { useResetTimeStyles } from './styles';
import type { ResetTimesFlattenedNode, ResetTimesChildNode } from './types';

interface Props {
    locationsIsLoading: boolean;
    locations?: SmartCleaningHierarchyResponse;
    currentPageId?: number;
    changePage: (id: number) => void;
    refetch: () => void;
}

const ResetTime = ({
    locationsIsLoading,
    locations,
    currentPageId,
    changePage,
    refetch,
}: Props) => {
    const { t } = useTranslation('solutions');
    const styles = useResetTimeStyles();

    const [isSensorResetModalOpen, setSensorResetModalOpen] = useState(false);
    const [flattenedLocations, setFlattenedLocations] =
        useState<FlattenedNodes<ResetTimesFlattenedNode>>();
    const [currentNode, setCurrentNode] =
        useState<PaginatedTreeCurrentNode<ResetTimesChildNode> | null>();
    const [selectedNodes, setSelectedNodes] = useState<NodeSelectionStruct>({});
    const [locationCount, setLocationCount] = useState({ floors: 0, buildings: 0 });

    useEffect(() => {
        if (locations) {
            setFlattenedLocations((oldFlattenedLocations) =>
                flattenResponseLocations({
                    response: locations,
                    currentFlattenedLocations: oldFlattenedLocations || {},
                }),
            );
        }
    }, [locations]);

    useEffect(() => {
        setCurrentNode(null);
    }, [currentPageId]);

    useEffect(() => {
        if (currentNode && flattenedLocations) {
            setLocationCount(
                updateLocationsCount({
                    knownNodes: flattenedLocations,
                    selectedNodes,
                }),
            );
        }
    }, [selectedNodes, currentNode, flattenedLocations]);

    useEffect(() => {
        if (flattenedLocations) {
            setSelectedNodes((oldSelectedNodes) =>
                updateNodeSelectionStateOnPage({
                    newKnownNodes: flattenedLocations,
                    currentlySelectedNodes: oldSelectedNodes,
                }),
            );
        }
    }, [flattenedLocations]);

    useEffect(() => {
        if (flattenedLocations) {
            setCurrentNode(
                getCurrentNodeTree({
                    flattenedLocations,
                    selections: selectedNodes,
                }),
            );
        }
    }, [selectedNodes, flattenedLocations]);

    const changeSelection = ({
        selected,
        nodeIds,
    }: {
        selected: boolean;
        nodeIds: number[];
    }) => {
        setSelectedNodes(
            updateNodeSelectionStateOnSelect({
                currentlySelectedNodes: selectedNodes,
                knownNodes: flattenedLocations || {},
                newSelectedState: selected ? SELECTION_STATES.ALL : SELECTION_STATES.NONE,
                selectedNodeIds: nodeIds,
            }),
        );
    };

    const customColumns = [
        {
            HeaderCell: () => <>{t('Sensor reset at (building local time) ')}</>,
            DataCell: (row: ResetTimesChildNode) => (
                <Typography variant="body2">
                    {row.resetTime &&
                        (row.resetTime === 'mixed'
                            ? t('Mixed')
                            : t('{{time}} (Daily)', { time: row.resetTime.slice(0, 5) }))}
                </Typography>
            ),
            key: 'customCell',
        },
    ];

    return (
        <>
            <PageContainer className={styles.container}>
                <div className={styles.header}>
                    <Typography variant="h6">
                        {t('Schedule sensor reset times')}
                    </Typography>
                    <Typography
                        className={styles.description}
                        color="textSecondary"
                        variant="body2"
                    >
                        {t(
                            'Choose the time that all sensors in a location automatically reset to the status ‘Doesn’t need cleaning’. You may want to set a time after your scheduled cleaning sessions.',
                        )}
                    </Typography>
                </div>
                <div className={styles.locationPickerContainer}>
                    {!locationsIsLoading && currentNode ? (
                        <PaginatedTreeSelector<
                            ResetTimesChildNode,
                            PaginatedTreeCurrentNode<ResetTimesChildNode>
                        >
                            content={currentNode}
                            getLabelFromType={() => 'option'}
                            onChangeSelection={changeSelection}
                            onChangePage={changePage}
                            customColumns={customColumns}
                            hideSpaces
                        />
                    ) : (
                        <div className={styles.skeletonContainer}>
                            <Skeleton
                                classes={{ root: styles.skeleton }}
                                animation={false}
                            />
                            <div className={styles.loadingSpinnerContainer}>
                                <CircularProgress
                                    className={styles.loader}
                                    color="primary"
                                    size={30}
                                />
                            </div>
                        </div>
                    )}
                    <LocationsSelectorCounter
                        count={locationCount}
                        domain="location"
                        buttonCallback={() => setSensorResetModalOpen(true)}
                        buttonText="Edit selected"
                        getIsSaveButtonDisabled={(count?: LocationsCount) =>
                            !count?.floors
                        }
                    />
                </div>
            </PageContainer>
            {isSensorResetModalOpen && (
                <SensorResetModal
                    open={isSensorResetModalOpen}
                    handleClose={() => setSensorResetModalOpen(false)}
                    refetch={refetch}
                    locations={Object.keys(selectedNodes)
                        .filter(
                            (nodeId) => selectedNodes[nodeId] === SELECTION_STATES.ALL,
                        )
                        .map((id) => Number(id))}
                    knownNodes={flattenedLocations || {}}
                />
            )}
        </>
    );
};

export default ResetTime;
