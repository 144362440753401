import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import { spaceControllers, getFloorSpaceCountByTypeKey } from '@infogrid/locations-api';
import type { FloorplanSpacesResponse } from '@infogrid/locations-api';
import type { SpaceTypeGroup } from '@infogrid/locations-types';
import type { AxiosResponse } from 'axios';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

const mapper = (response: AxiosResponse<FloorplanSpacesResponse>) => response.data.groups;

export const useFloorSpaceCountByType = (
    floorId: number,
    options?: UseQueryOptions<SpaceTypeGroup[], AxiosParsedError>,
): UseQueryResult<SpaceTypeGroup[], AxiosParsedError> =>
    useQuery(
        getFloorSpaceCountByTypeKey(floorId),
        ({ signal }) =>
            wrapResponsePromise(
                spaceControllers.getFloorSpaceCountByType(signal, floorId),
                mapper,
            ),
        options,
    );
