import { FormErrorMessage, Modal } from '@infogrid/components-material-ui';
import { Button, TextField, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import AddressAutocomplete from '../AddressAutocomplete';
import { useStylesBuildingDetails } from './styles';

const BuildingDetails = ({
    title,
    errors,
    touched,
    buildingName,
    buildingAddress,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBack,
    handleLocation,
}) => {
    const stylesBuildingDetails = useStylesBuildingDetails();

    const { t } = useTranslation('floorplan');

    const renderAddressInput = useCallback(
        (params) => (
            <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                id="address"
                name="address"
                data-cypress="address"
                fullWidth
                variant="outlined"
                label={t('Address')}
                placeholder={t('Address')}
                error={errors.address && touched.address}
                value={buildingAddress}
                onChange={handleChange}
            />
        ),
        [errors.address, touched.address, buildingAddress, handleChange, t],
    );

    return (
        <form onSubmit={handleSubmit} className={stylesBuildingDetails.container}>
            <Modal.Title>{title}</Modal.Title>

            <Modal.Description>{t('Update this building details')}</Modal.Description>

            <Modal.Content>
                <Modal.ContentItem>
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        variant="outlined"
                        data-cypress="name"
                        label={t('Building name')}
                        placeholder={t('Building name')}
                        disabled={isSubmitting}
                        value={buildingName}
                        onChange={handleChange}
                        error={errors.name && touched.name}
                    />

                    <FormErrorMessage show={errors.name && touched.name}>
                        {errors.name}
                    </FormErrorMessage>
                </Modal.ContentItem>

                <Modal.ContentItem>
                    <AddressAutocomplete
                        address={buildingAddress}
                        disabled={isSubmitting}
                        handleLocation={handleLocation}
                        renderInput={renderAddressInput}
                    />

                    <FormErrorMessage show={errors.address && touched.address}>
                        {errors.address}
                    </FormErrorMessage>
                </Modal.ContentItem>
            </Modal.Content>

            <Modal.Actions justify="space-between">
                <Button onClick={handleBack} color="primary" variant="text">
                    {t('Back')}
                </Button>

                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    data-cypress="next"
                    disabled={isSubmitting}
                >
                    {isSubmitting && (
                        <CircularProgress
                            className={stylesBuildingDetails.spinner}
                            size={22}
                        />
                    )}

                    <span>{t('Next')}</span>
                </Button>
            </Modal.Actions>
        </form>
    );
};

BuildingDetails.propTypes = {
    isSubmitting: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,

    handleLocation: PropTypes.func.isRequired,
    buildingName: PropTypes.string.isRequired,
    buildingAddress: PropTypes.string.isRequired,
    errors: PropTypes.shape({
        name: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
        address: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
    }).isRequired,
    touched: PropTypes.shape({
        address: PropTypes.bool,
        name: PropTypes.bool,
    }).isRequired,
    title: PropTypes.string,
};

BuildingDetails.defaultProps = {
    title: '',
    isSubmitting: false,
};

export default BuildingDetails;
