import { Tooltip } from '@infogrid/components-material-ui';
import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BatteryLevelIcon from 'components/material-ui/BatteryLevelIcon';
import useSensorBattery from 'utils/hooks/useSensorBattery';

import { useStylesSensorBattery } from './styles';

export interface SensorBatteryLevelProps {
    sensor: GenericSensorShape;
    withTooltip?: boolean;
    size?: number;
    classes?: {
        container?: string;
        content?: string;
    };
}

const SensorBatteryLevel = ({
    sensor,
    withTooltip = true,
    size = 20,
    classes = {
        container: '',
        content: '',
    },
}: SensorBatteryLevelProps) => {
    const { t } = useTranslation('sensors');
    const { isAvailable, level } = useSensorBattery(sensor);

    const styles = useStylesSensorBattery();
    const isTooltipDisabled = !withTooltip;

    const titleTooltip = useMemo(() => {
        if (Number.isNaN(level)) {
            return <span>{t('N/A')}</span>;
        }

        return (
            <span>
                {t('Battery Level: {{level}} %', {
                    level,
                    defaultValue: `Battery Level: ${level} %`,
                })}
            </span>
        );
    }, [level, t]);

    if (!isAvailable) {
        return null;
    }

    return (
        <Tooltip
            title={titleTooltip}
            disableFocusListener={isTooltipDisabled}
            disableHoverListener={isTooltipDisabled}
            disableTouchListener={isTooltipDisabled}
            data-testid="sensor-battery-level"
        >
            <span className={classNames(classes.container, styles.container)}>
                <BatteryLevelIcon size={size} level={level} className={classes.content} />
            </span>
        </Tooltip>
    );
};

export default memo(SensorBatteryLevel);
