import type { FloorDetail } from '@infogrid/locations-types';
import { Dialog } from '@material-ui/core';
import { memo } from 'react';

import SidebarContent from './SidebarContent';

interface Props {
    buildingId: number;
    floor: FloorDetail;
    onCloseSidebar: () => void;
}

const MobileUnassignedSensorsSidebar = ({ buildingId, floor, onCloseSidebar }: Props) => {
    return (
        <Dialog fullScreen open onClose={onCloseSidebar}>
            <SidebarContent
                buildingId={buildingId}
                floor={floor}
                onCloseSidebar={onCloseSidebar}
            />
        </Dialog>
    );
};

export default memo(MobileUnassignedSensorsSidebar);
