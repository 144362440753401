import type { FloorSensorLocation } from '@infogrid/locations-types';
import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { Button, Drawer, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SensorsList from '../SensorsList';
import { sensorHasCoordinates } from '../utils';
import { useMobileSensorNavigationStyles } from './styles';

interface MobileSensorNavigationSensor extends GenericSensorShape {
    id: string | number;
    coordinates?: FloorSensorLocation;
}

interface MobileSensorNavigationProps {
    activeSensorDeviceId: string | number | null;
    collapsedHeight?: number;
    isLoading?: boolean;
    sensors: MobileSensorNavigationSensor[];
    topMargin?: number;
}

const MobileSensorNavigation = ({
    activeSensorDeviceId,
    collapsedHeight = 70,
    isLoading = false,
    sensors,
    topMargin = 0,
}: MobileSensorNavigationProps) => {
    const [isOpen, handleClose, _0, toggleOpen] = useIsOpenState();

    const styles = useMobileSensorNavigationStyles({
        collapsedHeight,
        isOpen,
        topMargin,
    });

    const { t } = useTranslation('floorplan');

    const unmappedSensorCount = useMemo(
        () => sensors.filter((sensor) => !sensorHasCoordinates(sensor)).length,
        [sensors],
    );

    return (
        <div className={styles.root}>
            <Drawer
                anchor="bottom"
                open={isOpen}
                onClose={handleClose}
                ModalProps={{
                    keepMounted: true,
                }}
                // Only inline seems to work, applying drawer classes does not have the specificity
                style={{
                    zIndex: 2,
                }}
                classes={{
                    paper: styles.collapsedContainer,
                }}
            >
                <div className={styles.titleBar} data-cypress="drawer">
                    <Typography>
                        <span
                            className={classNames(styles.titleText, styles.totalSensors)}
                            data-cypress="drawer-title"
                        >
                            {t('{{count}} sensors', {
                                count: sensors.length,
                                defaultValue___one: `${sensors.length} sensor`,
                                defaultValue___other: `${sensors.length} sensors`,
                                ns: 'common',
                            })}
                        </span>
                        <span
                            className={styles.unmappedSensorsText}
                            data-cypress="drawer-unmapped-sensors"
                        >
                            {t('({{unmappedSensorCount}} unmapped)', {
                                unmappedSensorCount,
                                defaultValue: `(${unmappedSensorCount} unmapped)`,
                            })}
                        </span>
                    </Typography>
                    <Button
                        className={styles.showHideButton}
                        color="primary"
                        data-cypress="drawer-button"
                        onClick={toggleOpen}
                    >
                        {isOpen ? t('Hide') : t('Show')}
                    </Button>
                </div>
                <div className={styles.content}>
                    <section
                        className={styles.sensorsListContainer}
                        data-cypress="sensors-list-container"
                    >
                        <SensorsList
                            activeSensorDeviceId={activeSensorDeviceId}
                            isLoading={isLoading}
                            placeholder={
                                <Alert severity="info" data-cypress="alert-help">
                                    <Typography>
                                        {t('To add a sensor, go to the ')}
                                        <Link to="/folders">{t('Folders view')}</Link>
                                        {t(
                                            ", select the sensors for this floor, press the 'Move' button and navigate to this floor’s folder",
                                        )}
                                    </Typography>
                                </Alert>
                            }
                            sensors={sensors}
                        />
                    </section>
                </div>
            </Drawer>
        </div>
    );
};

export default memo(MobileSensorNavigation);
