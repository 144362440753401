import { TimeFromNow } from '@infogrid/components-dates';
import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLastReadingTimestamp } from 'utils/hooks/useLastReadingTimestamp';
import useSensorSignal from 'utils/hooks/useSensorSignal';

import SensorLastReadingTransition from './SensorLastReadingTransition';
import LastReadingValue from './SensorLastReadingValue';
import { useSensorLastReadingStyles } from './styles';
import { useCurrentLatestSensorEvent } from './useCurrentLatestSensorEvent';

/**
 * @returns {JSX.Element} - component displaying the last reading of sensor:
 *      * if not `withTime`, then format is `<last reading value>` or null
 *          * e.g. `14%RH`
 *      * if `withTime`, then format is `<last reading value> <divider> <timestamp>`
 *          * e.g. `14%RH | 2 min ago`
 */

interface SensorLastReadingType {
    sensor: GenericSensorShape;
    withTime?: boolean;
    divider?: string;
    useFallbackForSigma?: boolean;
    classes?: {
        wrapper?: string;
        divider?: string;
        reading?: string;
        readingTime?: string;
    };
    shouldShowOffline?: boolean;
    readingFallback?: string;
    timeFallback?: string;
}

const defaultClasses = {
    divider: '',
    reading: '',
    readingTime: '',
};

const SensorLastReadingWrapper = ({
    sensor,
    withTime = false,
    divider = '',
    useFallbackForSigma = false,
    classes = defaultClasses,
    shouldShowOffline = true,
    readingFallback = registerTranslationKey('N/A', { ns: 'common' }),
    timeFallback,
}: SensorLastReadingType) => {
    const { t } = useTranslation('sensors');
    const event = useCurrentLatestSensorEvent(sensor);

    const styles = useSensorLastReadingStyles();
    const lastReadingTimestamp = useLastReadingTimestamp(sensor);
    const { isOnline } = useSensorSignal(sensor);

    // TODO WEB-4250: The event is nil for old format events and empty for new format events.
    //  Once we've migrated over to new format events, we can delete the first condition.
    if (isNil(event) || isEmpty(event)) {
        return (
            <SensorLastReadingTransition
                className={classNames(classes.reading, classes.wrapper)}
                centerAlignItems={withTime}
            >
                {t(readingFallback)}
                {timeFallback && (
                    <Typography variant="body2" color="textSecondary">
                        {timeFallback}
                    </Typography>
                )}
            </SensorLastReadingTransition>
        );
    }

    return (
        <SensorLastReadingTransition
            event={event}
            centerAlignItems={withTime}
            className={classes.wrapper}
        >
            <LastReadingValue
                className={classes.reading}
                event={event}
                sensorType={sensor.type_code}
                sensorConfiguration={sensor.sensor_configuration}
                readingTypes={sensor.reading_types}
                useShortTouchReading={withTime}
                isOffline={!isNil(isOnline) && !isOnline}
                shouldShowOffline={shouldShowOffline}
                fallbackValue={t(readingFallback)}
                latestEvents={sensor.latest_events_v2}
            />
            {withTime && (
                <>
                    {sensor.type_code !== SENSOR_TYPE.TYPE_SIGMA && (
                        <span className={classNames(styles.divider, classes.divider)}>
                            {divider}
                        </span>
                    )}
                    {/* Sigma sensors don't have values for events */}
                    {/* So we just display "Latest reading - <timestamp>" to show the timestamp info nicely */}
                    {sensor.type_code === SENSOR_TYPE.TYPE_SIGMA && useFallbackForSigma && (
                        <>
                            <span className={classNames(classes.reading, styles.divider)}>
                                {t('Latest reading - ')}
                            </span>
                        </>
                    )}
                    <TimeFromNow
                        autoUpdate
                        timestamp={lastReadingTimestamp as string}
                        className={classes.readingTime}
                        tooltipProps={{ className: styles.lastReadingTimeTooltip }}
                    />
                </>
            )}
        </SensorLastReadingTransition>
    );
};

export default memo(SensorLastReadingWrapper);
