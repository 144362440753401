import { makeStyles } from '@material-ui/core';

const BROWN = '#5F2B01';

export const useSetThresholdsStyles = makeStyles((theme) => ({
    icon: {
        fontSize: theme.spacing(4),
        color: theme.palette.common.yellow5,
    },
    text: {
        margin: theme.spacing(2),
        color: BROWN,
        flex: 1,
    },
    root: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#FDF0E6',
        flexDirection: 'row',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: 'fit-content',
        width: '500px',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        color: BROWN,
        '&:hover': {
            color: BROWN,
        },
    },
}));
