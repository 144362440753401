import { makeStyles } from '@material-ui/core';

export const useSensorStatusStyles = makeStyles((theme) => ({
    descriptionContainer: {
        overflow: 'hidden',

        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',

        color: theme.palette.common.gray1,

        display: 'flex',
    },
    descriptionLabel: {
        display: 'flex',
        alignItems: 'center',

        '&:not(:last-child)': {
            marginRight: theme.spacing(0.75),

            '&:after': {
                marginLeft: theme.spacing(0.75),
                content: '""',

                width: 2,
                height: '0.9em',

                backgroundColor: theme.palette.common.gray1,
            },
        },
    },
}));
