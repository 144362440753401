import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { getSensor, getSensorTypeGroup } from '@infogrid/sensors-configuration';
import { memo, useMemo } from 'react';

import SensorIcon from './SensorIcon';
import type { SensorIconConfig, SensorIconProps } from './types';

interface SensorIconContainerProps {
    className?: string;
    sensor: GenericSensorShape & { alerts_count?: number };
    iconConfig?: SensorIconConfig;
    variant?: SensorIconProps['variant'];
    state?: SensorIconProps['state'];
    size?: SensorIconProps['size'];
    'aria-label'?: string;
}

const SensorIconContainer = ({
    className,
    sensor,
    iconConfig = {
        showDefaultRAGStatus: true,
        showAlerts: true,
        showOfflineStatus: true,
    },
    variant,
    state,
    size,
    ...props
}: SensorIconContainerProps) => {
    const fullSensor = getSensor(sensor);
    // Provides defaults if iconConfig partially defined
    const {
        showDefaultRAGStatus = true,
        showAlerts = true,
        showOfflineStatus = true,
    } = iconConfig;

    const iconVariant = useMemo(() => {
        if (variant) {
            return variant;
        }

        // The options for the icon state returned by getMapIconState vary slightly
        // from those the SensorIcon component expects.
        const iconState = fullSensor.getMapIconState();

        if (
            !showDefaultRAGStatus &&
            (iconState === 'green' || iconState === 'amber' || iconState === 'red')
        ) {
            return undefined;
        }

        if (
            // SensorIcon expects 'blue' or undefined for the base state
            iconState === 'base' ||
            // This will be overriden by the offline status
            iconState === 'gray'
        ) {
            return undefined;
        }

        return iconState;
    }, [variant, fullSensor, showDefaultRAGStatus]);

    return (
        <SensorIcon
            className={className}
            type={sensor.type_code}
            variant={iconVariant}
            state={state}
            size={size}
            isAlert={showAlerts && !!sensor.alerts_count}
            isHub={getSensorTypeGroup(sensor.type_code) === 'hub'}
            isOffline={showOfflineStatus && !fullSensor.getIsOnline()}
            aria-label={props['aria-label']}
        />
    );
};

export default memo(SensorIconContainer);
