import type { FloorPipeMonitoringSensor } from '@infogrid/remote-monitoring-types';
import { Typography } from '@material-ui/core';
import { memo } from 'react';

import FloorSection from '../FloorSection';
import { useBuildingSectionStyles } from './styles';

interface BuildingSectionProps {
    buildingName: string;
    floors: { [key: string]: FloorPipeMonitoringSensor[] };
    showBuildingName?: boolean;
}

const BuildingSection = ({
    buildingName,
    floors,
    showBuildingName = false,
}: BuildingSectionProps) => {
    const styles = useBuildingSectionStyles();

    return (
        <div>
            {showBuildingName && (
                <Typography variant="subtitle1" className={styles.spaceHeader}>
                    {buildingName}
                </Typography>
            )}
            <div>
                {Object.keys(floors).map((floor_name) => (
                    <FloorSection
                        key={floor_name}
                        floorName={floor_name}
                        sensors={floors[floor_name]}
                        showFloorSectionTitle={!showBuildingName}
                        appendFloorNameToSensor={showBuildingName}
                    />
                ))}
            </div>
        </div>
    );
};

export default memo(BuildingSection);
