/* eslint-disable id-denylist */

import { makeStyles } from '@material-ui/core';

export const useNavbarContainerStyles = makeStyles({
    root: ({ isMobile }) => ({
        display: 'flex',
        flexDirection: 'column',

        overflow: 'hidden auto',

        height: !isMobile && '100%',
    }),
    navContainer: {
        display: 'flex',
        flexDirection: 'column',

        flexGrow: 1,
    },
});

export const useNavbarGroupStyles = makeStyles((theme) => ({
    root: ({ isMobile }) => ({
        position: 'relative',

        borderTop: '1px solid rgb(255, 255, 255, 0.1)',
        margin: 0,
        padding: 0,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),

        listStyleType: 'none',

        '&:first-child': {
            border: 'none',
        },

        '&:last-child': {
            borderTop: '1px solid rgb(255, 255, 255, 0.1)',
            marginTop: 'auto',

            paddingBottom: isMobile ? theme.spacing(10) : theme.spacing(1.5),
        },
    }),
    item: {
        display: 'flex',
        alignItems: 'center',

        cursor: 'pointer',
    },
}));

export const useNavbarLogoStyles = makeStyles((theme) => ({
    image: {
        border: `2px solid ${theme.palette.common.white}`,
        borderRadius: '50%',
    },
    text: {
        display: '-webkit-box',
        fontSize: '16px',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        lineHeight: '18px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'unset',
    },
}));
