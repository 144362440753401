import { Modal } from '@infogrid/components-material-ui';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const InstallationFlowWarning = ({
    isOpen,
    onResume,
    onDiscard,
    onDiscardButtonText,
    onResumeButtonText,
    titleText,
    warningMessage,
}) => {
    const { t } = useTranslation('sensors');

    return (
        <Modal open={isOpen} maxWidth="xs" onClose={onResume}>
            <Modal.Title>{t(titleText)}</Modal.Title>
            <Modal.Content>
                <Typography
                    component="div"
                    variant="body2"
                    color="error"
                    data-cypress="warning-text"
                >
                    {warningMessage}
                </Typography>
            </Modal.Content>
            <Modal.Actions justify="space-between">
                <Button
                    variant="contained"
                    color="secondary"
                    data-cypress="warning-exit"
                    onClick={onDiscard}
                >
                    {onDiscardButtonText}
                </Button>
                <Button color="primary" onClick={onResume} data-cypress="resume">
                    {t(onResumeButtonText)}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

InstallationFlowWarning.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onDiscardButtonText: PropTypes.string.isRequired,
    onResumeButtonText: PropTypes.string,
    warningMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onResume: PropTypes.func,
    onDiscard: PropTypes.func,
    titleText: PropTypes.string,
};

InstallationFlowWarning.defaultProps = {
    onResume: () => {},
    onDiscard: () => {},
    onResumeButtonText: registerTranslationKey('Resume', { ns: 'sensors' }),
    titleText: registerTranslationKey('Device installation', { ns: 'sensors' }),
};

export default memo(InstallationFlowWarning);
