import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { SensorShape } from '@infogrid/sensors-constants';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import { mutateSensors } from 'utils/sensor';

import { controllers } from '../controllers';
import { getMappedSensorsKey } from '../getQueryKeys';

interface UseMappedSensorsProps {
    /**
     * If not provided, query will be disabled.
     */
    floorId?: number;
}

export const useMappedSensors = (
    { floorId }: UseMappedSensorsProps,
    {
        enabled = !!floorId,
        refetchOnMount = false,
        ...options
    }: UseQueryOptions<SensorShape[], AxiosParsedError> = {
        enabled: !!floorId,
    },
) => {
    const {
        isFetching,
        isLoading,
        data: mappedSensors,
    } = useQuery(
        getMappedSensorsKey({ floorId }),
        ({ signal }) =>
            wrapResponsePromise(
                controllers.getMappedSensors({
                    // Will be number if enabled
                    floorId: floorId as number,
                    signal,
                }),
                ({ data }) => mutateSensors(data),
            ),
        {
            enabled,
            refetchOnMount,
            ...options,
        },
    );

    return {
        mappedSensors: mappedSensors || [],
        isFetching,
        isLoading,
    };
};
