import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import { CircularProgress } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import WidgetAlert from '../../../common/WidgetAlert';
import LocationsTable from '../../LocationsTable';
import type {
    LiveIndoorAirQualityWidget,
    LiveIndoorAirQualityWidgetData,
} from '../../types';
import { validateConfiguration } from '../../utils';
import MetricState from './MetricState';
import { useMetricContentStyles } from './styles';

interface Props {
    widget: LiveIndoorAirQualityWidget;
    widgetData: LiveIndoorAirQualityWidgetData | undefined;
    isLoading: boolean;
    isLoadingDataError: boolean;
}

const MetricContent = ({ widget, widgetData, isLoading, isLoadingDataError }: Props) => {
    const styles = useMetricContentStyles();

    const { t } = useTranslation('dashboard');

    const widgetSettings = widget.configuration.calculators.live_indoor_air_quality;

    if (isLoading) {
        return (
            <div className={styles.progress}>
                <CircularProgress
                    color="primary"
                    data-testid="widget-circular-loader"
                    size={40}
                />
            </div>
        );
    }

    if (!validateConfiguration(widgetSettings)) {
        return (
            <WidgetAlert severity="warning">
                {t(WidgetErrorMessages.notConfigured)}
            </WidgetAlert>
        );
    }

    if (isLoadingDataError || !widgetData) {
        return (
            <WidgetAlert severity="error">
                {t(WidgetErrorMessages.dataLoadError)}
            </WidgetAlert>
        );
    }

    if (widgetData.calculation_status === 'error') {
        return (
            <WidgetAlert className={styles.alert} severity="warning">
                {t(
                    'The configuration of this widget does not include locations that contain air quality sensors. Please select appropriate locations to see data.',
                )}
            </WidgetAlert>
        );
    }

    if (widgetData.sensor_count && !widgetData.locations.length) {
        return (
            <WidgetAlert className={styles.alert} severity="warning">
                {t(
                    'We have been unable to get live air quality data for your selected locations; either because there is no data to show or data is more than 1 hour old. Please make another selection to see live data.',
                )}
            </WidgetAlert>
        );
    }

    return (
        <>
            <MetricState
                className={styles.metricState}
                metric={widgetSettings.metric}
                averageValue={widgetData.overall_average}
                rating={widgetData.overall_rating}
                isMultipleSensors={widgetData.sensor_count > 1}
                isMultipleLocations={widgetData.location_count > 1}
                locationType={widgetSettings.floorplan_type}
            />

            <LocationsTable
                data={widgetData}
                metric={widgetSettings.metric}
                spaceType={widgetSettings.floorplan_type}
                isMultipleSLocations={widgetData.location_count > 1}
            />
        </>
    );
};

export default memo(MetricContent);
