/* eslint-disable no-template-curly-in-string */
import { getOrganizationId } from '@infogrid/user-cookies';
import { i18n } from '@infogrid/utils-i18n';
import * as Sentry from '@sentry/react';
import { FetchResource as Resource } from '@tg-resources/fetch';
import { createSagaRouter } from '@tg-resources/redux-saga-router';
import { setBaseConfig as setBaseEntitiesConfig } from '@thorgate/spa-entities';
import { setBaseConfig as setBaseFormsConfig } from '@thorgate/spa-forms';
import Cookies from 'js-cookie';
import { call } from 'redux-saga/effects';

import { COREAPI_URL } from 'apiHooks/base/services';
import { getRequestToken } from 'sagas/helpers/token';
import SETTINGS from 'settings';
import { setLocalStorageBackendVersion } from 'utils/hooks/useBackendVersion';

setBaseEntitiesConfig('timeoutMs', SETTINGS.LONG_SAGA_TIMEOUT);
setBaseFormsConfig('timeoutMs', SETTINGS.LONG_SAGA_TIMEOUT);

function* mutateRequestConfig(origRequestConfig, resource) {
    const requestConfig = origRequestConfig || {};

    // When there is no token, do not refresh for `auth.*` and `constanceConfig` endpoints
    // `constanceConfig` endpoint does not require authentication and might add race condition if auth is used right now
    const shouldRunRefreshToken = !(
        resource.routeName.startsWith('auth.') || resource.routeName === 'constanceConfig'
    );

    const requestToken = yield call(getRequestToken, shouldRunRefreshToken);
    const organizationId = getOrganizationId();
    const token = SETTINGS.TOKEN ?? requestToken;

    if (SETTINGS.TOKEN) {
        console.debug('Overriding AuthToken');
    }

    if (token) {
        requestConfig.headers = () => ({
            Authorization: `Bearer ${token}`,
            // Pass selected organization id to backend
            ...(organizationId !== undefined
                ? { [SETTINGS.ORGANIZATION_ID_HEADER_NAME]: organizationId }
                : undefined),
        });
    }

    // Optional :: Adding valid statusCodes to specific routes via name
    if (resource.routeName === 'user.details') {
        requestConfig.statusSuccess = [200, 201, 204];
    }

    return requestConfig;
}

function onRequestError(error) {
    const shouldReportError = ![
        error.isInvalidResponseCode && [404, 403, 401].includes(error.statusCode),
        // ignore 5xx errors
        error.statusCode && error.statusCode >= 500,
        error.isValidationError,
        error.isNetworkError,
        error.isAbortError,
    ].some(Boolean);

    if (shouldReportError) {
        if (process.env.NODE_ENV === 'production') {
            Sentry.captureException(error);
        } else {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }
}

const api = createSagaRouter(
    {
        auth: {
            obtain: 'auth/token/',
            refresh: 'auth/token/refresh/',
            verify: 'auth/token/verify/',
        },
        solutions: 'user/solutions',
        user: {
            details: 'user/me',
            signedToken: 'user/signed-request',
            forgotPassword: 'user/forgot_password',
            forgotPasswordToken: 'user/forgot_password/token',
        },
        terms: {
            active: 'user/terms/active',
            accept: 'user/terms/${pk}/accept',
        },
        constanceConfig: 'constance/config',
        timeZones: 'user/timezones',
        organization: {
            user: {
                list: 'org/${org_pk}/users',
                detail: 'org/${org_pk}/users/${pk}',
                download: 'org/${org_pk}/users/download',
            },
            team: {
                list: 'user/teams',
                detail: 'user/teams/${pk}',
            },
            serviceAccount: {
                list: 'org/${org_pk}/service_accounts',
                detail: 'org/${org_pk}/service_accounts/${pk}',
                regenerate: 'org/${org_pk}/service_accounts/${pk}/regenerate_secret_key',
            },
        },
        folder: {
            subFolders: 'folders/${pk}/subfolders',
            sensors: 'folders/${pk}/sensors',
        },
        // Websocket URL resolving helpers to make it easier to render path with parameters
        webSockets: {
            identifyStream: {
                apiEndpoint: '/api/stream/sensor/identify/',
                apiRoot: SETTINGS.BACKEND_SITE_URL,
            },
        },
    },
    {
        apiRoot: COREAPI_URL,
        querySerializeOptions: { arrayFormat: 'repeat' },
        headers: () => ({
            Accept: 'application/json',
            'X-CSRFToken': Cookies.get(SETTINGS.CSRF_COOKIE_NAME),
            'Accept-Language': i18n.language || SETTINGS.DEFAULT_LANGUAGE,
        }),

        withCredentials: true,
        allowAttachments: true,

        mutateRawResponse(response) {
            setLocalStorageBackendVersion(response);

            return response;
        },

        mutateRequestConfig,
        onRequestError,
    },
    Resource,
);

export default api;
