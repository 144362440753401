import { makeStyles } from '@material-ui/core';

export const useLoadingBarStyle = makeStyles(() => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 10000,
        display: 'none',
        width: '100%',
        height: '2px',
        borderRadius: '0 1px 1px 0',
        background: '#00D8FF',
        transition: 'width 350ms linear',
    },
}));
