import { routesManager } from '@infogrid/core-routing';
import { Button, Icon } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ErrorBaseCard from '../ErrorBaseCard';
import { useStylesBuildingCard } from './styles';

const getIcon = (type) => {
    if (type === 'buildings') {
        return 'fal fa-building';
    }

    return 'far fa-layer-group';
};

const ErrorBackButton = () => {
    const history = useHistory();
    const styles = useStylesBuildingCard();

    const goToBuildings = () => {
        history.push(routesManager.resolvePath('buildings'));
    };

    return (
        <Button
            variant="contained"
            color="primary"
            data-cypress="add-new-building"
            onClick={goToBuildings}
        >
            <Icon className={classNames('far fa-arrow-left', styles.buttonArrow)} />
            Building view
        </Button>
    );
};

const ErrorPageFloorplanCard = ({ status, type, message }) => {
    const cardIcon = getIcon(type);

    return (
        <ErrorBaseCard
            cardIcon={cardIcon}
            title={`Error ${status}`}
            primaryDescription={message}
            buttonsContainer={<ErrorBackButton />}
        />
    );
};

ErrorPageFloorplanCard.propTypes = {
    status: PropTypes.number,
    message: PropTypes.string,
    type: PropTypes.oneOf(['buildings', 'floors']).isRequired,
};

ErrorPageFloorplanCard.defaultProps = {
    status: null,
    message: '',
};

export default ErrorPageFloorplanCard;
