import { selectSpaces } from '@infogrid/locations-ducks';
import type { Floor, BuildingDetail, Space } from '@infogrid/locations-types';
import { Divider } from '@material-ui/core';
import { memo, useCallback, useEffect, useMemo } from 'react';

import { useBuilding, useAllBuildings } from 'apiHooks/floorPlan/buildings/hooks';
import { useFloor, useFloors } from 'apiHooks/floorPlan/floors/hooks';
import { useFloorSensors } from 'apiHooks/floorPlan/sensors/hooks';
import { useSpaces } from 'apiHooks/floorPlan/spaces/hooks';

import Banner from './Banner';
import Breadcrumbs from './Breadcrumbs';
import BuildingsList from './Lists/BuildingsList';
import FloorsList from './Lists/FloorsList';
import SpacesList from './Lists/SpacesList';
import MappedSensorsWarning from './MappedSensorsWarning';
import { useMoveSensorsStyles } from './styles';

export interface MoveSensorsProps {
    buildingId: number;
    floorId: number;
    originSpaceId?: number;
    selectedBuilding?: BuildingDetail | null;
    selectedFloor?: Floor | null;
    selectedSpace?: Space | null;
    sensors: {
        id: number;
        is_mapped_to_floor?: boolean;
    }[];
    setSelectedBuilding: (building: BuildingDetail | null | undefined) => void;
    setSelectedFloor: (floor: Floor | null | undefined) => void;
    setSelectedSpace: (space: Space | null | undefined) => void;
}

const MoveSensors = ({
    buildingId,
    floorId,
    originSpaceId,
    selectedBuilding,
    selectedFloor,
    selectedSpace,
    sensors,
    setSelectedBuilding,
    setSelectedFloor,
    setSelectedSpace,
}: MoveSensorsProps) => {
    const styles = useMoveSensorsStyles();

    const { building } = useBuilding(buildingId);

    const { data: floor } = useFloor(floorId);

    const { buildings, isFetching: isFetchingBuildings } = useAllBuildings({
        'include-user-actions': true,
    });

    const {
        floors,
        isFetching: isFetchingFloors,
        refetch: refetchFloors,
    } = useFloors(selectedBuilding?.id || buildingId, { 'include-user-actions': true });

    const {
        data: spacesData,
        isFetching: isFetchingSpaces,
        refetch: refetchSpaces,
    } = useSpaces({
        params: {
            floor: selectedFloor?.id || floorId,
            'include-user-actions': true,
        },
    });

    const { refetch: refetchUnassignedSensors, data: unassignedSensorData } =
        useFloorSensors({
            floorId: selectedFloor?.id || floorId,
        });

    const allSelectedSensorsUnassigned = useMemo(() => {
        const unassignedSensorsIds = (unassignedSensorData?.sensors || []).map(
            (s) => s.id,
        );

        return sensors.every((s) => unassignedSensorsIds.includes(s.id));
    }, [sensors, unassignedSensorData?.sensors]);

    const shouldDisableNotInSpace = useMemo(() => {
        return allSelectedSensorsUnassigned && floorId === selectedFloor?.id;
    }, [floorId, selectedFloor, allSelectedSensorsUnassigned]);

    const showMappedSensorsWarning = useMemo(() => {
        return (
            sensors.some((sensor) => sensor?.is_mapped_to_floor) &&
            selectedFloor &&
            selectedFloor?.id !== floorId
        );
    }, [floorId, selectedFloor, sensors]);

    const spaces = useMemo(() => selectSpaces(spacesData).spaces, [spacesData]);

    useEffect(() => {
        if (selectedFloor && selectedFloor?.id !== floorId) {
            refetchUnassignedSensors();
        }
    }, [floorId, refetchUnassignedSensors, selectedFloor]);

    useEffect(() => {
        if (selectedBuilding === undefined && building) {
            setSelectedBuilding(building);
        }
    }, [building, selectedBuilding, setSelectedBuilding]);

    useEffect(() => {
        if (selectedFloor === undefined && floor) {
            setSelectedFloor(floor);
        }
    }, [floor, selectedFloor, setSelectedFloor]);

    useEffect(() => {
        if (floor?.id !== floorId || floor?.id !== selectedFloor?.id) {
            refetchSpaces();
        }
    }, [floor, floorId, refetchSpaces, selectedFloor]);

    useEffect(() => {
        if (building?.id !== buildingId || building?.id !== selectedBuilding?.id) {
            refetchFloors();
        }
    }, [building, buildingId, refetchFloors, selectedBuilding]);

    const moveToLocation = useMemo(() => {
        if (
            selectedBuilding === undefined ||
            selectedFloor === undefined ||
            selectedSpace === undefined
        ) {
            return null;
        }

        if (selectedSpace === null) {
            return `${selectedBuilding?.name} / ${selectedFloor?.name}`;
        }

        return `${selectedBuilding?.name} / ${selectedFloor?.name} / ${selectedSpace?.name}`;
    }, [selectedBuilding, selectedFloor, selectedSpace]);

    const onGoToBuildings = useCallback(() => {
        setSelectedBuilding(null);
        setSelectedFloor(null);
        setSelectedSpace(undefined);
    }, [setSelectedBuilding, setSelectedFloor, setSelectedSpace]);

    const onGoToFloors = useCallback(() => {
        setSelectedFloor(null);
        setSelectedSpace(undefined);
    }, [setSelectedFloor, setSelectedSpace]);

    return (
        <>
            <Breadcrumbs
                onGoToBuildings={onGoToBuildings}
                onGoToFloors={onGoToFloors}
                selectedBuilding={selectedBuilding}
                selectedFloor={selectedFloor}
            />

            <Divider className={styles.divider} />

            {!selectedBuilding && (
                <BuildingsList
                    buildings={buildings}
                    isLoading={isFetchingBuildings}
                    setSelectedBuilding={setSelectedBuilding}
                />
            )}

            {selectedBuilding && !selectedFloor && (
                <FloorsList
                    floors={floors}
                    isLoading={isFetchingFloors}
                    setSelectedFloor={setSelectedFloor}
                />
            )}

            {selectedFloor && (
                <SpacesList
                    disableNotInSpaces={shouldDisableNotInSpace}
                    floor={floor}
                    isLoading={isFetchingSpaces}
                    originSpaceId={originSpaceId}
                    selectedSpace={selectedSpace}
                    setSelectedSpace={setSelectedSpace}
                    spaces={spaces}
                    unassignedSensorsCount={unassignedSensorData?.sensors.length ?? 0}
                />
            )}

            <Divider className={styles.divider} />

            <Banner location={moveToLocation} />

            {showMappedSensorsWarning && <MappedSensorsWarning />}
        </>
    );
};

export default memo(MoveSensors);
