import { Analytic } from '@infogrid/utils-analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const usePageView = (): void => {
    const location = useLocation();

    useEffect(() => {
        Analytic.pageView(location);
    }, [location]);
};
