import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo } from 'react';

import { useWidgetDetailStyles } from './styles';

interface WidgetDetailProps {
    classes?: {
        label?: string;
        value?: string;
    };
    label: ReactNode;
    value: ReactNode;
}

const defaultClasses = {
    label: '',
    value: '',
};

const WidgetDetail = ({ classes = defaultClasses, label, value }: WidgetDetailProps) => {
    const styles = useWidgetDetailStyles();

    const labelClass = classNames(styles.label, classes.label);
    const valueClass = classNames(styles.value, classes.value);

    return (
        <div className={styles.container} data-cypress="widget-detail">
            <Typography className={labelClass} variant="body2">
                {label}
            </Typography>
            <Typography className={valueClass} variant="body2">
                {value}
            </Typography>
        </div>
    );
};

export default memo(WidgetDetail);
