import { isNumber } from 'is-what';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useFormToast, useFloorPlanParams } from 'apiHooks/base/hooks';
import { useFloors, useReorderFloor } from 'apiHooks/floorPlan/floors/hooks';

import CollapsedFloor from './CollapsedFloor';
import Floor from './Floor';
import { useStylesFloorsList } from './styles';

const FloorsList = ({
    activeFloorId,
    onFloorClick,
    onEditFloorClick,
    isFullView,
    isBuildingEditable,
}) => {
    const FloorComponent = isFullView ? Floor : CollapsedFloor;

    const styles = useStylesFloorsList();

    const { buildingId } = useFloorPlanParams();
    const { mutate: reorderFloor } = useReorderFloor(buildingId);
    const { triggerErrorToast } = useFormToast();

    const { floors } = useFloors(buildingId, { 'include-user-actions': true });

    const handleDragEnd = (result) => {
        if (!isNumber(result?.destination?.index) || !isNumber(result?.source?.index)) {
            return;
        }

        const {
            draggableId: floorId,
            destination: { index: floorDestinationIndex },
            source: { index: floorSourceIndex },
        } = result;

        if (floorDestinationIndex === floorSourceIndex) {
            return;
        }

        reorderFloor(
            {
                floorId,
                data: {
                    floorDestinationIndex,
                    floorSourceIndex,
                },
            },
            {
                onError() {
                    triggerErrorToast({ message: 'Error reorder floor.' });
                },
            },
        );
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="floors-droppable">
                {(provided) => (
                    <div
                        className={styles.floorsListContainer}
                        ref={provided.innerRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.droppableProps}
                    >
                        {floors.map((floor, index) => {
                            return (
                                <FloorComponent
                                    key={floor.id}
                                    index={index}
                                    id={`${floor.id}`}
                                    floorId={floor.id}
                                    name={floor.name}
                                    sensorsCount={floor?.sensors_count || 0}
                                    hubsCount={floor?.hubs_count || 0}
                                    isActive={floor.id === activeFloorId}
                                    onFloorClick={onFloorClick}
                                    onEditClick={onEditFloorClick}
                                    isFloorEditable={floor.user_actions?.edit?.allowed}
                                    isBuildingEditable={isBuildingEditable}
                                />
                            );
                        })}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

FloorsList.propTypes = {
    onFloorClick: PropTypes.func.isRequired,
    onEditFloorClick: PropTypes.func.isRequired,
    activeFloorId: PropTypes.number,
    isFullView: PropTypes.bool,
    isBuildingEditable: PropTypes.bool,
};

FloorsList.defaultProps = {
    isFullView: false,
    activeFloorId: null,
    isBuildingEditable: false,
};

export default memo(FloorsList);
