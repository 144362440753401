import { StackedBarChart, buildHighchartsTooltip } from '@infogrid/components-highcharts';
import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { Tab, Tabs, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { generateChartData } from 'utils/charts/AirQuality';
import { trackDashboardEvent } from 'views/dashboards/utils/analytics';
import {
    getAxisTimeFormatByDataFrequency,
    getTooltipTimeFormatByDataFrequency,
} from 'views/dashboards/widgets/utils';

import WidgetAlert from '../../common/WidgetAlert';
import Tooltip from './Tooltip';
import { useContentStyles } from './styles';
import {
    openPointInNewTab,
    tooltipPointColorExtractor,
    tooltipPointDisplayNameExtractor,
    tooltipPointNameExtractor,
} from './utils';

const Content = ({
    data,
    isFetchingData,
    setWidgetDataQueryParams,
    widget,
    isConfigured,
    isLoadingDataError,
}) => {
    const { t } = useTranslation('dashboard');
    const styles = useContentStyles();

    const [selectedTab, setSelectedTab] = useState(0);

    const { no_data: isEmptyChartData } = data;

    const {
        data_frequency: dataFrequency,
        time_zone: timeZone,
        time_period_from: startDate,
        time_period_to: endDate,
    } = widget?.configuration || {};

    const tabClassName = useCallback(
        (active) => classNames(styles.tab, { [styles.tabActive]: active }),
        [styles],
    );

    const tabs = useMemo(
        () => [
            {
                id: 'overall',
                label: (
                    <Typography
                        className={tabClassName(selectedTab === 0)}
                        variant="body1"
                    >
                        {t('Overall')}
                    </Typography>
                ),
            },
            {
                id: 'co2',
                label: (
                    <Typography
                        className={tabClassName(selectedTab === 1)}
                        variant="body1"
                    >
                        <i className={classNames('far fa-cloud', styles.tabIcon)} />
                        {t('CO')}
                        <sub>2</sub>
                    </Typography>
                ),
            },
            {
                id: 'virusRisk',
                label: (
                    <Typography
                        className={tabClassName(selectedTab === 2)}
                        variant="body1"
                    >
                        <i className={classNames('far fa-virus', styles.tabIcon)} />
                        {t('Virus Risk')}
                    </Typography>
                ),
            },
            {
                id: 'temperature',
                label: (
                    <Typography
                        className={tabClassName(selectedTab === 3)}
                        variant="body1"
                    >
                        <i
                            className={classNames(
                                'far fa-thermometer-half',
                                styles.tabIcon,
                            )}
                        />
                        {t('Temperature')}
                    </Typography>
                ),
            },
            {
                id: 'humidity',
                label: (
                    <Typography
                        className={tabClassName(selectedTab === 4)}
                        variant="body1"
                    >
                        <i className={classNames('far fa-tint', styles.tabIcon)} />
                        {t('Humidity')}
                        <Typography
                            className={styles.tabUnit}
                            component="span"
                            variant="body1"
                        >
                            {'(%)'}
                        </Typography>
                    </Typography>
                ),
            },
        ],
        [t, selectedTab, styles, tabClassName],
    );

    const handleTabSelect = (_, index) => {
        setSelectedTab(index);

        const tabId = tabs[index]?.id;

        trackDashboardEvent(
            USER_ACTIONS.PRESSED,
            `change indoor air quailty tab to ${tabs[index].id}`,
        );

        setWidgetDataQueryParams({
            air_type: tabId !== 'overall' ? tabId : undefined,
        });
    };

    const dataDisplayNames = useMemo(() => {
        // Virus Risk tab
        if (selectedTab === 2) {
            return {
                highRiskLabel: t('High', { ns: 'common' }),
                mediumRiskLabel: t('Medium', { ns: 'common' }),
                lowRiskLabel: t('Low', { ns: 'common' }),
            };
        }

        return {
            highRiskLabel: t('Poor', { ns: 'common' }),
            mediumRiskLabel: t('Fair', { ns: 'common' }),
            lowRiskLabel: t('Good', { ns: 'common' }),
        };
    }, [t, selectedTab]);

    const transformedData = useMemo(
        () => generateChartData(data, dataDisplayNames),
        [data, dataDisplayNames],
    );

    const chartProps = useMemo(() => {
        const timeFormat = getAxisTimeFormatByDataFrequency(
            dataFrequency,
            startDate,
            endDate,
        );
        const tooltipTimeFormat = getTooltipTimeFormatByDataFrequency(dataFrequency);

        return {
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    events: {
                        click(event) {
                            openPointInNewTab(
                                event.point,
                                widget.id,
                                tabs[selectedTab]?.id,
                            );
                        },
                    },
                },
            },
            xAxis: {
                labels: {
                    formatter({ chart }) {
                        // eslint-disable-next-line react/no-this-in-sfc
                        return chart.time.dateFormat(timeFormat, this.value);
                    },
                },
                startOnTick: false,
                type: 'datetime',
            },
            yAxis: {
                max: 100,
                visible: false,
            },
            tooltip: {
                formatter({ chart }) {
                    return buildHighchartsTooltip({
                        content: (
                            <Tooltip
                                data={this}
                                pointColorExtractor={tooltipPointColorExtractor}
                                pointDisplayNameExtractor={
                                    tooltipPointDisplayNameExtractor
                                }
                                pointKeyExtractor={tooltipPointNameExtractor}
                                pointNameExtractor={tooltipPointNameExtractor}
                                tabId={tabs[selectedTab]?.id}
                                widgetId={widget.id}
                            />
                        ),
                        periodLabel: chart.time.dateFormat(
                            tooltipTimeFormat,
                            // eslint-disable-next-line react/no-this-in-sfc
                            this.points[0].key,
                        ),
                    });
                },
                stickOnContact: true,
            },
            time: {
                timezone: timeZone,
            },
            series: transformedData,
        };
    }, [
        dataFrequency,
        endDate,
        selectedTab,
        startDate,
        tabs,
        timeZone,
        transformedData,
        widget.id,
    ]);

    if (!isConfigured) {
        return (
            <WidgetAlert severity="warning" className={styles.alertContainer}>
                {t(WidgetErrorMessages.notConfigured)}
            </WidgetAlert>
        );
    }

    if (isLoadingDataError) {
        return (
            <WidgetAlert severity="error" className={styles.alertContainer}>
                {t(WidgetErrorMessages.dataLoadError)}
            </WidgetAlert>
        );
    }

    return (
        <div className={styles.container}>
            <Tabs
                classes={{
                    scroller: styles.tabsBorder,
                }}
                indicatorColor="primary"
                onChange={handleTabSelect}
                scrollButtons="on"
                textColor="primary"
                value={selectedTab}
                variant="scrollable"
            >
                {tabs.map((tab) => (
                    <Tab
                        classes={{
                            root: styles.tabRoot,
                        }}
                        disabled={isFetchingData}
                        key={tab.id}
                        label={tab.label}
                    />
                ))}
            </Tabs>
            <div className={styles.chart}>
                {isEmptyChartData && (
                    <WidgetAlert severity="info">
                        {t(WidgetErrorMessages.noData)}
                    </WidgetAlert>
                )}
                {!isEmptyChartData && <StackedBarChart chartProps={chartProps} />}
            </div>
        </div>
    );
};

Content.propTypes = {
    data: PropTypes.shape({
        airthings_quality_graph_rows: PropTypes.arrayOf(
            PropTypes.shape({
                timestamp: PropTypes.string,
            }),
        ),
        no_data: PropTypes.bool,
    }).isRequired,
    isFetchingData: PropTypes.bool,
    setWidgetDataQueryParams: PropTypes.func.isRequired,
    widget: PropTypes.shape({
        configuration: PropTypes.shape({
            data_frequency: PropTypes.string,
            time_zone: PropTypes.string,
        }),
    }).isRequired,
    isConfigured: PropTypes.bool.isRequired,
    isLoadingDataError: PropTypes.bool.isRequired,
};

Content.defaultProps = {
    isFetchingData: false,
};

export default memo(Content);
