import { Modal, ProgressRing } from '@infogrid/components-material-ui';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Typography, Box, Button } from '@material-ui/core';
import noop from 'lodash/noop';
import random from 'lodash/random';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'react-use';

import ScoreScale from './ScoreScale';
import { useTutorialModalStyles, useProgressRingStyles } from './styles';

interface Props {
    onClose?: () => void;
    open?: boolean;
}

const TutorialModal = ({ onClose = noop, open = true }: Props) => {
    const isMobile = useIsMobile();
    const styles = useTutorialModalStyles();
    const progressRingClasses = useProgressRingStyles();

    const { t } = useTranslation('dashboard');

    const [scoreValue, setScoreValue] = useState(70);

    useInterval(() => {
        setScoreValue(random(0, 100));
    }, 3000);

    return (
        <Modal
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            disableEnforceFocus
            fullScreen={isMobile}
        >
            <Modal.Title>{t('How does it work?')}</Modal.Title>
            <Modal.Content className={styles.modalContent}>
                <section className={styles.section}>
                    <Typography variant="h6" className={styles.header} component="p">
                        {t('Healthy Building Score')}
                    </Typography>
                    <Typography className={styles.typography} variant="body2">
                        {t(
                            `A Healthy Building Score widget illustrates how well your building ensures the the health, well-being and satisfaction of building occupants. The score shows the proportion of time a building had healthy conditions for its occupants in the last 30 days.`,
                        )}
                    </Typography>
                    <Typography className={styles.typography} variant="body2">
                        {t(
                            `Scores are calculated on a rolling 30 day basis. This means that your score will update each day, but will be based on data gathered over the previous 30 days.`,
                        )}
                    </Typography>
                </section>
                <section className={styles.section}>
                    <Typography variant="body2">
                        {t('How do I read my score?')}
                    </Typography>
                    <Typography
                        className={styles.typography}
                        variant="body2"
                        color="textSecondary"
                    >
                        {t(
                            'Our widget visualises your score using a colour coded ring to help you:',
                        )}
                    </Typography>
                    <div className={styles.framedContent}>
                        <Typography variant="body2" className={styles.subtitle}>
                            {t('Use the ring to tell if your score is good or bad')}
                        </Typography>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            className={styles.scoreExplanation}
                        >
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                align="left"
                                component="p"
                                className={styles.typography}
                            >
                                {t(
                                    `A score between 60-100% means that your building is likely to have been in an optimal state over the last 30 days. A score below 40% means it is likely to have been sub-optimal over the last 30 days. The colour of the ring should also help you to gauge your score. See the diagram below for more detail.`,
                                )}
                            </Typography>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                className={styles.ringBox}
                            >
                                <ProgressRing
                                    value={scoreValue}
                                    classes={progressRingClasses}
                                />
                                <Typography
                                    align="center"
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    {t('Example')}
                                </Typography>
                            </Box>
                        </Box>
                        <ScoreScale />
                    </div>
                </section>
                <section className={styles.section}>
                    <Typography variant="body2" className={styles.subtitle}>
                        {t('What does my score measure?')}
                    </Typography>
                    <Typography
                        className={styles.typography}
                        variant="body2"
                        color="textSecondary"
                    >
                        {t(
                            'We calculate your scores by measuring two pillars of Healthy Buildings:',
                        )}
                    </Typography>
                    <div className={styles.framedContent}>
                        <Typography variant="body2" className={styles.subtitle}>
                            {t('Air Quality')}
                        </Typography>
                        <Typography
                            className={styles.typography}
                            variant="caption"
                            color="textSecondary"
                        >
                            {t(
                                `A measure of how clean or polluted the air is. You’ll need to install Air Quality sensors in a building for us to calculate this score. The more sensors you have installed, the more accurate your score will be.`,
                            )}
                        </Typography>
                    </div>
                    <div className={styles.framedContent}>
                        <Typography variant="body2" className={styles.subtitle}>
                            {t('Thermal Health')}
                        </Typography>
                        <Typography
                            className={styles.typography}
                            variant="caption"
                            color="textSecondary"
                        >
                            {t(
                                `A measure of temperature and humidity. Thermal health encompasses all of the impacts of thermal conditions on the health of building occupants. You’ll need to install sensors that measure Temperature and{{divider}}or Humidity for us to calculate this score. The more sensors you have installed, the more accurate your score will be.`,
                                {
                                    divider: '/',
                                    defaultValue: `A measure of temperature and humidity. Thermal health encompasses all of the impacts of thermal conditions on the health of building occupants. You’ll need to install sensors that measure Temperature and/or Humidity for us to calculate this score. The more sensors you have installed, the more accurate your score will be.`,
                                },
                            )}
                        </Typography>
                    </div>
                    <Typography
                        className={styles.typography}
                        variant="caption"
                        color="textSecondary"
                        component="p"
                    >
                        {t(
                            `We’re adding more pillars soon which will give you more ways to measure the score and make it more accurate. Watch out for updates!`,
                        )}
                    </Typography>
                </section>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} data-cypress="close-btn">
                    {t('Close', { ns: 'common' })}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(TutorialModal);
