import { wrapResponsePromise } from '@infogrid/core-api';
import { useAppSelector } from '@infogrid/core-ducks';
import { selectOrganizationId } from '@infogrid/user-ducks';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from 'apiHooks/settings/integrations/corrigo/controllers';
import { getCorrigoCredentialsQueryKey } from 'apiHooks/settings/integrations/corrigo/queryKeys';
import type { CorrigoCredentials } from 'views/integrations/components/corrigo/types';

export const useCorrigoCredentialsRetrieve = (
    refetch: Record<string, never>,
): UseQueryResult<Omit<CorrigoCredentials, 'client_secret'>> => {
    const organizationId = useAppSelector(selectOrganizationId);

    return useQuery(
        [...getCorrigoCredentialsQueryKey(organizationId as number), refetch],
        () =>
            wrapResponsePromise(
                controllers.retrieveCorrigoCredentials(organizationId as number),
            ),
        {
            enabled: organizationId != null,
        },
    );
};
