import type { Map } from 'ol';
import { createContext } from 'react';

export interface MapContext {
    map?: Map;
}

const context = createContext<MapContext>({});

export default context;
