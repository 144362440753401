import { makeStyles } from '@material-ui/core';

export const useGenericWidgetStyles = makeStyles({
    progress: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexGrow: 1,
    },
});

export const useGenericWidgetContainerStyles = makeStyles({
    container: {
        width: '100%',
        height: '100%',
    },
});
