import { TableCell } from '@material-ui/core';
import classNames from 'classnames';
import type { Location } from 'history';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import { useContentStyles } from './styles';

interface Props {
    colorize?: boolean;
    label?: string;
    value: number;
    link: Location;
    isTotalCell?: boolean;
}

const ValueTableCell = ({
    colorize = true,
    label = '',
    link,
    value,
    isTotalCell = false,
}: Props) => {
    const styles = useContentStyles();
    const text = label || value;

    return (
        <TableCell
            className={classNames(styles.tableCell, {
                [styles.tableCellGreen]: colorize && value === 0,
                [styles.tableCellRed]: colorize && value > 0,
                [styles.tableCellTotal]: isTotalCell,
            })}
        >
            <Link rel="noopener noreferrer" target="_blank" to={link}>
                {text}
            </Link>
        </TableCell>
    );
};

export default memo(ValueTableCell);
