import { FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import type { FormikErrors, FormikHandlers } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { StoplightDisplayOption } from '../../types';
import { useOccupancySectionStyles } from './styles';

interface CubicleOccupancySectionProps {
    color: string;
    errors?: FormikErrors<StoplightDisplayOption>;
    handleChange: FormikHandlers['handleChange'];
    message: string;
    name: string;
    showCubicleCount: boolean;
    title: string;
}

const CubicleOccupancySection = ({
    color,
    errors,
    handleChange,
    message,
    name,
    showCubicleCount,
    title,
}: CubicleOccupancySectionProps) => {
    const { t } = useTranslation('dashboard');
    const styles = useOccupancySectionStyles({ color });

    return (
        <div>
            <div className={styles.titleContainer}>
                <i
                    className={classNames('fas fa-circle', styles.colorCircle)}
                    style={{ color }}
                />
                <Typography className={styles.title} variant="subtitle1">
                    {title}
                </Typography>
            </div>
            <TextField
                className={styles.messageInput}
                error={!!errors?.message}
                fullWidth
                helperText={errors?.message}
                id={`${name}-message`}
                data-cypress={`${name}-message`}
                label={t('Message', { ns: 'common' })}
                name={`displayOptions.${name}.message`}
                onChange={handleChange}
                size="small"
                value={message}
                variant="outlined"
            />
            <FormControlLabel
                className={styles.formControlLabel}
                control={
                    <Switch
                        checked={showCubicleCount}
                        color="primary"
                        id="showCubicleCount"
                        name={`displayOptions.${name}.showCubicleCount`}
                        onChange={handleChange}
                    />
                }
                label={t('Show cubicle count')}
            />
        </div>
    );
};

export default memo(CubicleOccupancySection);
