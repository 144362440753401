import { makeStyles } from '@material-ui/core';

export const useStylesChoosingFolder = makeStyles((theme) => ({
    description: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
    folderTreeContainer: {
        maxHeight: 'calc(100vh - 350px)',
        minHeight: '100px',
        overflowY: 'auto',
        display: 'flex',

        paddingTop: 0,
    },
    folderTreeSpinner: {
        margin: 'auto',
    },
}));
