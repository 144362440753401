import type { AutocompleteRenderInputParams } from '@infogrid/components-material-ui';
import { Autocomplete } from '@infogrid/components-material-ui';
import type { MapBoxFeatureItem, Option, PlaceType } from '@infogrid/locations-types';
import { memo, useCallback, useMemo } from 'react';
import type { ReactNode } from 'react';

import useMapboxPlaces from 'utils/hooks/useMapboxPlaces';

const MAX_AUTOCOMPLETE_WINDOW_HEIGHT = '15.625rem';
const DEBOUNCE_DELAY = 500;
const EMPTY_COORDINATES = {
    latitude: null,
    longitude: null,
};

interface Props {
    address: string;
    buildingCountry?: string | null;
    buildingLongitude?: number | null;
    buildingLatitude?: number | null;
    optionLabelExtractor?: (option: Option) => string;
    placeTypes?: PlaceType[];
    renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
    handleLocation: (option: Option) => void;
    disabled?: boolean;
}

const getContextValue = (item: MapBoxFeatureItem, key: string) => {
    const value = item.context?.find((contextItem) => {
        return contextItem.id.startsWith(key);
    });

    return value?.text;
};

const getCountryCode = (item: MapBoxFeatureItem) => {
    if (item.place_type?.includes('country')) {
        return item.properties.short_code;
    }

    const country = item.context?.find((contextItem) => {
        return contextItem.id.startsWith('country');
    });

    return country ? country.short_code : null;
};

const getAddressAndCoordinates = (results: { features: MapBoxFeatureItem[] }) => {
    return results?.features?.length
        ? results.features.map((item) => {
              return {
                  address: item.place_name,
                  city: getContextValue(item, 'place'),
                  countryCode: getCountryCode(item),
                  country: getContextValue(item, 'country'),
                  coordinates: {
                      latitude: item.geometry.coordinates[1],
                      longitude: item.geometry.coordinates[0],
                  },
                  text: item.text,
              };
          })
        : [];
};

const defaultOptionLabelExtractor = (option: Option) => option.address;

const filterOptions = (options: Option[], params: { inputValue: string }) => {
    if (params?.inputValue) {
        options.unshift({
            address: params.inputValue,
            countryCode: null,
            coordinates: EMPTY_COORDINATES,
        });
    }

    return options;
};

const ListboxProps = {
    style: {
        overflow: 'scroll-y',
        maxHeight: MAX_AUTOCOMPLETE_WINDOW_HEIGHT,
    },
};

const AddressAutocomplete = ({
    address,
    buildingCountry,
    buildingLongitude = null,
    buildingLatitude = null,
    optionLabelExtractor = defaultOptionLabelExtractor,
    placeTypes,
    renderInput,
    handleLocation,
    disabled = false,
}: Props) => {
    const { results } = useMapboxPlaces({
        query: address,
        debounceTime: DEBOUNCE_DELAY,
        selector: getAddressAndCoordinates,
        emptyValue: [],
        placeTypes,
    });

    const onChange = useCallback(
        (_, locationValue) => {
            handleLocation({
                address: locationValue?.address || '',
                city: locationValue?.city || '',
                country: locationValue?.country || '',
                countryCode: locationValue?.countryCode || '',
                coordinates: locationValue?.coordinates || EMPTY_COORDINATES,
                text: locationValue?.text || '',
            });
        },
        [handleLocation],
    );

    const onClose = useCallback(
        (_, typeClose) => {
            if (typeClose !== 'select-option') {
                handleLocation({
                    address,
                    countryCode: '',
                    coordinates: EMPTY_COORDINATES,
                });
            }
        },
        [handleLocation, address],
    );

    const value = useMemo(
        () => ({
            address,
            countryCode: buildingCountry,
            latitude: buildingLatitude,
            longitude: buildingLongitude,
        }),
        [address, buildingLatitude, buildingCountry, buildingLongitude],
    );

    return (
        <Autocomplete
            filterSelectedOptions={true}
            autoHighlight={true}
            selectOnFocus={true}
            freeSolo={true}
            getOptionLabel={optionLabelExtractor}
            filterOptions={filterOptions}
            ListboxProps={ListboxProps}
            onChange={onChange}
            onClose={onClose}
            options={results}
            renderInput={renderInput}
            value={value}
            disabled={disabled}
        />
    );
};

export default memo(AddressAutocomplete);
