import { wrapResponsePromise } from '@infogrid/core-api';
import type { IPagination, AxiosParsedError } from '@infogrid/core-types';
import type { Building } from '@infogrid/locations-types';
import type { UseInfiniteQueryOptions, UseInfiniteQueryResult } from 'react-query';
import { useInfiniteQuery } from 'react-query';

import { controllers } from '../controllers';
import { getPaginatedBuildingsKey } from '../getQueryKeys';
import type { BuildingsParams } from '../types';

export const usePaginatedBuildings = (
    params?: BuildingsParams,
    options?: UseInfiniteQueryOptions<
        IPagination<Building>,
        AxiosParsedError<{ detail: string }>,
        IPagination<Building>
    >,
): UseInfiniteQueryResult<
    IPagination<Building>,
    AxiosParsedError<{ detail: string }>
> => {
    return useInfiniteQuery(
        getPaginatedBuildingsKey(params),
        ({ signal, pageParam = {} }) =>
            wrapResponsePromise<IPagination<Building>>(
                controllers.getBuildings(signal, {
                    page_size: params?.page_size ?? 25,
                    ...pageParam,
                    ...params,
                }),
            ),
        {
            getNextPageParam: (lastPage) => lastPage?.next,
            getPreviousPageParam: (previousPage) => previousPage?.previous,
            keepPreviousData: true,
            refetchOnMount: true,
            ...options,
        },
    );
};
