import type { DataFrequency as DataFrequencyType } from '@infogrid/dashboards-constants';
import type { FormikProps } from 'formik';
import { memo } from 'react';

import Container from 'views/dashboards/widgets/common/DisplayOptions/Container';
import DataFrequency from 'views/dashboards/widgets/common/DisplayOptions/DataFrequency';

import type { ConfigurationValues } from '../types';
import { useDisplayOptionsStyles } from './styles';

interface DisplayOptionsProps {
    formik: FormikProps<ConfigurationValues>;
    frequencies: DataFrequencyType[];
}

const defaultFrequencies: DataFrequencyType[] = [];

const DisplayOptions = ({
    formik,
    frequencies = defaultFrequencies,
}: DisplayOptionsProps) => {
    const styles = useDisplayOptionsStyles();

    const { dataFrequency } = formik?.values?.displayOptions || {};

    return (
        <Container>
            <DataFrequency
                className={styles.dataFrequency}
                frequencies={frequencies}
                handleChange={formik.handleChange}
                value={dataFrequency}
            />
        </Container>
    );
};

export default memo(DisplayOptions);
