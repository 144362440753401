import {
    LocationPickerField,
    generateDisplayValueStringWithinBuilding,
} from '@infogrid/components-locations';
import { Modal } from '@infogrid/components-material-ui';
import type { AxiosParsedError } from '@infogrid/core-types';
import { CustomTextField } from '@infogrid/electricity-components';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import type {
    SensorConfigurationShape,
    SensorProfileShape,
    SensorShape,
} from '@infogrid/sensors-constants';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button, InputAdornment } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { invalidateSensor } from 'apiHooks/sensors/accessors';
import { useUpdateElectricitySensorConfiguration } from 'apiHooks/sensors/hooks';
import EndUsePicker from 'components/material-ui/Sensors/EndUsePicker';
import { SENSOR_ID_FIELD } from 'utils/sensor';
import { trackInstallFlowUserEvent } from 'views/buildings/pages/installFlow/utils/analytics';

import SensorDisplay from '../SensorDisplay';
import { useInstallationFlowContext } from '../context';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../reducer';
import { useInstallationFlowStyles } from '../styles';
import { useElectricityMonitoringConfigurationStyles } from './styles';
import type { ElectricityMonitoringConfigurationFormValues } from './types';
import { useElectricityMonitoringConfigurationFormik } from './useElectricityMonitoringConfigurationFormik';

const ElectricityMonitoringConfiguration = () => {
    const isMobile = useIsMobile();
    const classes = useElectricityMonitoringConfigurationStyles({
        isMobileView: isMobile,
    });
    const { t } = useTranslation('sensors');
    const { sensor } = useInstallationFlowContext();
    const installationFlowStyles = useInstallationFlowStyles();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    const { mutate: updateSensorConfiguration } = useUpdateElectricitySensorConfiguration(
        (sensor as SensorShape)?.type_code,
    );

    const formik = useElectricityMonitoringConfigurationFormik({
        onSubmit: (formValues: ElectricityMonitoringConfigurationFormValues) => {
            if (!sensor) {
                return;
            }

            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
            });

            if (formValues.end_use) {
                updateSensorConfiguration(
                    {
                        id: sensor?.[SENSOR_ID_FIELD] as string,
                        data: {
                            sensor_configuration: {
                                end_use: formValues.end_use,
                                kwh_conversion_factor_sqm:
                                    formValues.kwh_conversion_factor_sqm,
                            },
                            profile: {
                                monitoring_locations: formValues.monitoring_locations,
                            },
                        },
                    },
                    {
                        onSuccess: () => {
                            const updatedSensor = {
                                ...sensor,
                                sensor_configuration: {
                                    ...sensor.sensor_configuration,
                                    end_use: formValues.end_use,
                                    kwh_conversion_factor_sqm:
                                        formValues.kwh_conversion_factor_sqm,
                                } as SensorConfigurationShape,
                                profile: {
                                    monitoring_locations: formValues.monitoring_locations,
                                } as SensorProfileShape,
                            };

                            invalidateSensor(sensor?.[SENSOR_ID_FIELD]);
                            dispatchInstallationFlowContext({
                                type: INSTALLATION_FLOW_ACTIONS.INSTALLATION_SUCCEED,
                                sensor: updatedSensor,
                                sensorName: sensor?.device_name,
                                folderId: sensor?.floorplan_location?.floor_id,
                            });
                        },
                        onError: (
                            err: AxiosParsedError<ElectricityMonitoringConfigurationFormValues>,
                        ) => {
                            if (err.parsedError) {
                                const { errors: formikErrors, message } = err.parsedError;

                                if (formikErrors) {
                                    formik.setErrors(formikErrors);
                                }

                                if (message) {
                                    formik.setStatus({ message });
                                }
                            }
                        },
                        onSettled: () => {
                            dispatchInstallationFlowContext({
                                type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
                            });
                        },
                    },
                );
            }
        },
    });

    const onClick = () => {
        trackInstallFlowUserEvent(
            USER_ACTIONS.SUBMIT,
            `${sensor?.vendor}: monitoring location & size/end use`,
        );
        trackInstallFlowUserEvent(
            USER_ACTIONS.SUBMIT,
            `${sensor?.vendor}: complete installation of ${sensor?.type} sensor`,
        );
    };

    const { values, errors, setFieldValue, handleSubmit } = formik;

    const onBack = useCallback(() => {
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.GO_BACK,
        });
    }, [dispatchInstallationFlowContext]);

    const sensorConfig = useMemo(
        () => (sensor?.type_code ? getSensorConfig(sensor?.type_code) : undefined),
        [sensor],
    );

    const onMonitoringLocationChange = useCallback(
        (value: number[]) => {
            setFieldValue('monitoring_locations', value);
        },
        [setFieldValue],
    );
    const onEndUseChange = useCallback(
        (value: string) => {
            setFieldValue('end_use', value);
        },
        [setFieldValue],
    );

    return (
        <>
            <Modal.Title>{t('Configure the device')}</Modal.Title>

            <Modal.Content>
                <form
                    id="configurationForm"
                    onSubmit={handleSubmit}
                    className={installationFlowStyles.form}
                >
                    {sensor && sensorConfig ? (
                        <SensorDisplay
                            sensor={sensor}
                            label={sensorConfig.label}
                            deviceType={sensor.type_code}
                        />
                    ) : null}
                    <Alert color="info" severity="info" className={classes.info}>
                        {t(
                            'Enter details of the location the sensor has been installed in.',
                        )}
                    </Alert>
                    {sensor?.floorplan_location?.building_id && (
                        <LocationPickerField
                            label={t('Monitoring location')}
                            helperText={t('The locations this sensor is measuring')}
                            targetLocation={sensor.floorplan_location.building_id}
                            onChange={onMonitoringLocationChange}
                            errorMessage={errors.monitoring_locations as string}
                            generateDisplayValueString={
                                generateDisplayValueStringWithinBuilding
                            }
                        />
                    )}
                    <EndUsePicker
                        value={values.end_use}
                        onChange={onEndUseChange}
                        helperText={t('The end use the sensor is measuring')}
                        errorMessage={errors.end_use}
                        className={classes.input}
                    />
                    <CustomTextField
                        formik={formik}
                        finder={(f) => f.kwh_conversion_factor_sqm}
                        label={t('Size of monitored space')}
                        name="kwh_conversion_factor_sqm"
                        helper={t('The size of the location the sensor is measuring')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {t('m2', {
                                        context: 'unit',
                                        ns: 'sensor-events',
                                    })}
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
            </Modal.Content>

            <Modal.Actions
                justify="space-between"
                className={installationFlowStyles.actions}
            >
                <Button onClick={onBack} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    data-cypress="save"
                    type="submit"
                    form="configurationForm"
                    onClick={onClick}
                >
                    {t('Save')}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(ElectricityMonitoringConfiguration);
