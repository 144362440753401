import { Icon, Intent, Position, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { TimeFromNow } from '@infogrid/components-dates';
import { ICONS } from '@infogrid/components-icons';
import { TestStatusIcon, TestStatusText } from '@infogrid/components-material-ui';
import type { MaybeArray, Values } from '@infogrid/core-types';
import {
    getSensor,
    getElectricityIcon,
    getFormattedElectricityReadingByEventType,
    getElectricityReadingType,
    getSensorDetailsTooltip,
} from '@infogrid/sensors-configuration';
import {
    SENSOR_EVENT_TYPES_V2,
    CONNECTION_TYPE_USER_READABALE_VALUE,
    CONNECTION_TYPE,
    OBJECT_PRESENT_STATE,
    OBJECT_PRESENCE_STATE,
    OCCUPIED_STATE,
    WATER_PRESENT_STATE,
} from '@infogrid/sensors-constants';
import type {
    SensorEventType,
    SensorShape,
    SensorEventsV2,
    ElectricitySensorConfigurationShape,
    SensorConfigurationShape,
} from '@infogrid/sensors-constants';
import { getElectricityUnit } from '@infogrid/solution-views-electricity';
import type { PotentiallyDangerousDate } from '@infogrid/utils-dates';
import { formatDateTimeShortMonth } from '@infogrid/utils-dates';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import type { TempUnit } from '@infogrid/utils-measurements';
import {
    decimalToPercentage,
    TEMP_UNIT_SYMBOL,
    getTempInUserUnits,
} from '@infogrid/utils-measurements';
import classNames from 'classnames';
import { convertToTimeZone } from 'date-fns-timezone';
import type { TFunction } from 'i18next';
import round from 'lodash/round';
import type { ReactNode } from 'react';

import BatteryLevelIcon from 'components/material-ui/BatteryLevelIcon';
import SignalLevel from 'components/material-ui/Sensors/SignalLevel/SignalLevel';

/**
 * Contains all configuration for the status cards on the sensor details pages.
 */

export interface SensorStatusCardConfig {
    icon?: string | ReactNode;
    label?: string;
    reading: ReactNode | number | string | null | undefined;
    readingUnit?: ReactNode;
    key?: string;
    labelElement?: ReactNode;
    readingExtra?: ReactNode;
    readingFooter?: string | null;
    updateTrigger?: Date | string | number | null;
    labelTooltip?: ReactNode;
}

type V2EventFormatKey = keyof SensorEventsV2;

export interface EventTransformerArgs {
    t: TFunction;
    locale?: string;
    timeZone?: string;
    sensor: SensorShape;
    temperatureUnit: TempUnit;
}

type EventDetails<EventKey extends MaybeArray<V2EventFormatKey>> = {
    eventKey: EventKey;
};

type EventValue<KeyOrKeys extends MaybeArray<keyof SensorEventsV2>> =
    KeyOrKeys extends (keyof SensorEventsV2)[]
        ? Pick<SensorEventsV2, KeyOrKeys[number]>
        : SensorEventsV2[KeyOrKeys & keyof SensorEventsV2];

export type EventTypeConfigFactory<
    KeyOrKeys extends MaybeArray<V2EventFormatKey> = MaybeArray<V2EventFormatKey>,
    ExtraArgs extends readonly unknown[] = [],
> = {
    (
        event: EventValue<KeyOrKeys>,
        args: EventTransformerArgs,
        ...rest: ExtraArgs
    ): SensorStatusCardConfig | null;
    eventKey: KeyOrKeys;
};

function defineEventTransformer<
    KeyOrKeys extends MaybeArray<V2EventFormatKey> = MaybeArray<V2EventFormatKey>,
    ExtraArgs extends readonly unknown[] = [SensorConfigurationShape?, SensorEventType?],
>(
    handler: (
        event: EventValue<KeyOrKeys>,
        args: EventTransformerArgs,
        ...qargs: ExtraArgs
    ) => SensorStatusCardConfig | null,
    { eventKey }: EventDetails<KeyOrKeys>,
): EventTypeConfigFactory<KeyOrKeys, ExtraArgs> {
    return Object.assign(handler, { eventKey }) as EventTypeConfigFactory<
        KeyOrKeys,
        ExtraArgs
    >;
}

export const getCountEventReading = (
    eventCount: number | undefined,
    timestamp: Date | undefined,
    { timeZone, t, locale }: Pick<EventTransformerArgs, 't' | 'timeZone' | 'locale'>,
): { reading: string | null; readingFooter: null | string } => {
    if (eventCount === undefined || !timestamp) {
        return { reading: null, readingFooter: null };
    }

    const reading = t('{{count: number}} events', {
        count: eventCount,
        defaultValue___one: `${eventCount} event`,
        defaultValue___other: `${eventCount} events`,
        ns: 'sensor-events',
    });
    const currentEventTime = formatDateTimeShortMonth(
        convertToTimeZone(new Date(timestamp ?? 0), {
            timeZone: timeZone as string,
        }),
        locale,
    );

    const readingFooter = t('between the previous event and {{currentEventTime}}', {
        currentEventTime,
        defaultValue: `between the previous event and ${currentEventTime}`,
        ns: 'sensor-events',
    });

    return { reading, readingFooter };
};

export const TemperatureEvent = defineEventTransformer(
    (event, { temperatureUnit, t }) => ({
        icon: ICONS.SENSORS.TEMPERATURE,
        label: t('Temperature', { ns: 'sensor-events' }),
        reading: !!event?.value
            ? getTempInUserUnits(event?.value as string | number, temperatureUnit)
            : '-',
        readingUnit: t(TEMP_UNIT_SYMBOL[temperatureUnit], { ns: 'sensor-events' }),
        key: SENSOR_EVENT_TYPES_V2.TEMPERATURE,
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.TEMPERATURE },
);

export const HumidityEvent = defineEventTransformer(
    (event, { t }) => ({
        icon: ICONS.SENSORS.HUMIDITY,
        label: t('Humidity', { ns: 'sensor-events' }),
        reading: decimalToPercentage(event?.value as number),
        readingUnit: t('%RH', { ns: 'sensor-events' }),
        key: SENSOR_EVENT_TYPES_V2.HUMIDITY,
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.HUMIDITY },
);

export const CO2Event = defineEventTransformer(
    (event, { t }) => ({
        icon: ICONS.SENSORS.CO2,
        label: t('CO₂', { ns: 'sensor-events' }),
        reading: event?.value ?? '',
        readingUnit: <>&nbsp;{t('ppm', { ns: 'sensor-events' })}</>,
        key: SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE,
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE },
);

export const TVOCEvent = defineEventTransformer(
    (event, { t }) => ({
        icon: ICONS.SENSORS.TVOC,
        label: t('TVOC', { ns: 'sensor-events' }),
        reading: event?.value,
        readingUnit: <>&nbsp;{t('ppb', { ns: 'sensor-events' })}</>,
        key: SENSOR_EVENT_TYPES_V2.TOTAL_VOLATILE_ORGANIC_COMPOUND,
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.TOTAL_VOLATILE_ORGANIC_COMPOUND },
);

export const AirPressureEvent = defineEventTransformer(
    (event, { t }) => ({
        icon: ICONS.SENSORS.AIR_PRESSURE,
        label: t('Air pressure', { ns: 'sensor-events' }),
        reading: event?.value,
        readingUnit: <>&nbsp;{t('mBar', { ns: 'sensor-events' })}</>,
        key: SENSOR_EVENT_TYPES_V2.AIR_PRESSURE,
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.AIR_PRESSURE },
);

export const LightEvent = defineEventTransformer(
    (event, { t }) => ({
        icon: ICONS.SENSORS.LIGHT,
        label: t('Light', { ns: 'sensor-events' }),
        reading: decimalToPercentage(event?.value as number),
        readingUnit: t('%', { ns: 'sensor-events' }),
        key: SENSOR_EVENT_TYPES_V2.LIGHT,
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.LIGHT },
);

export const NoiseEvent = defineEventTransformer(
    (event, { t }) => ({
        icon: ICONS.SENSORS.NOISE,
        label: t('Noise', { ns: 'sensor-events' }),
        reading: Math.round((event?.value as number) * 100),
        readingUnit: <>&nbsp;{t('dBA', { ns: 'sensor-events' })}</>,
        key: SENSOR_EVENT_TYPES_V2.NOISE,
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.NOISE },
);

export const VirusRiskEvent = defineEventTransformer(
    (event, { t }) => {
        const virusRiskValue = event?.value;

        const reading = Number.isInteger(virusRiskValue)
            ? t('{{virusRisk: number}}/{{maxVirusRisk: number}}', {
                  virusRisk: virusRiskValue,
                  maxVirusRisk: 10,
                  defaultValue: `${virusRiskValue}/10`,
                  ns: 'sensor-events',
              })
            : t('N/A', { ns: 'common' });

        return {
            icon: ICONS.SENSORS.VIRUS_RISK,
            label: t('Virus Risk', { ns: 'sensor-events' }),
            reading,
            key: SENSOR_EVENT_TYPES_V2.VIRUS_RISK,
        };
    },
    {
        eventKey: SENSOR_EVENT_TYPES_V2.VIRUS_RISK,
    },
);

export const PM1Event = defineEventTransformer(
    (event, { t }) => ({
        icon: ICONS.SENSORS.PARTICULATE_MATTER,
        labelElement: (
            <div>
                <span>{t('Particulate Matter', { ns: 'sensor-events' })}</span>
                <span className="status-card__row__label-postfix">
                    {t(' (<1µm)', { ns: 'sensor-events' })}
                </span>
            </div>
        ),
        reading: event?.value,
        readingUnit: <>&nbsp;{t('µg / m³', { ns: 'sensor-events' })}</>,
        key: SENSOR_EVENT_TYPES_V2.PARTICULATE_MATTER_1,
    }),
    {
        eventKey: SENSOR_EVENT_TYPES_V2.PARTICULATE_MATTER_1,
    },
);
export const PM25Event = defineEventTransformer(
    (event, { t }) => ({
        icon: ICONS.SENSORS.PARTICULATE_MATTER,
        labelElement: (
            <div>
                <span>{t('Particulate Matter', { ns: 'sensor-events' })}</span>
                <span className="status-card__row__label-postfix">
                    {t(' (<2.5µm)', { ns: 'sensor-events' })}
                </span>
            </div>
        ),
        reading: event?.value,
        readingUnit: <>&nbsp;{t('µg / m³', { ns: 'sensor-events' })}</>,
        key: SENSOR_EVENT_TYPES_V2.PARTICULATE_MATTER_2_POINT_5,
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.PARTICULATE_MATTER_2_POINT_5 },
);

export const PM10Event = defineEventTransformer(
    (event, { t }) => ({
        icon: ICONS.SENSORS.PARTICULATE_MATTER,
        labelElement: (
            <div>
                <span>{t('Particulate Matter', { ns: 'sensor-events' })}</span>
                <span className="status-card__row__label-postfix">
                    {t(' (<10µm)', { ns: 'sensor-events' })}
                </span>
            </div>
        ),
        reading: event?.value,
        readingUnit: <>&nbsp;{t('µg / m³', { ns: 'sensor-events' })}</>,
        key: SENSOR_EVENT_TYPES_V2.PARTICULATE_MATTER_10,
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.PARTICULATE_MATTER_10 },
);

export const RadonEvent = defineEventTransformer(
    (event, { t }) => ({
        icon: ICONS.SENSORS.RADON,
        label: t('Radon (Last 24h average)', { ns: 'sensor-events' }),
        reading: event?.value,
        readingUnit: <>&nbsp;{t('Bq/m³', { ns: 'sensor-events' })}</>,
        key: SENSOR_EVENT_TYPES_V2.RADON_SHORT_TERM_AVG,
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.RADON_SHORT_TERM_AVG },
);

export const ObjectPresentEvent = defineEventTransformer(
    (event, { t }) => {
        return {
            icon: ICONS.SENSORS.DOOR,
            label: t('Door status', { ns: 'sensor-events' }),
            reading: t(
                event?.value === true
                    ? OBJECT_PRESENT_STATE.PRESENT
                    : OBJECT_PRESENT_STATE.NOT_PRESENT,
            ),
            key: SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT,
        };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT },
);

export const PresenceDetectorObjectPresentEvent = defineEventTransformer(
    (event, { t }) => {
        return {
            icon: ICONS.SENSORS.PRESENCE,
            label: t('Presence', { ns: 'sensor-events' }),
            reading: t(
                event?.value === true
                    ? OBJECT_PRESENCE_STATE.PRESENT
                    : OBJECT_PRESENCE_STATE.NOT_PRESENT,
                { ns: 'sensor-events' },
            ),
            key: SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT,
        };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT },
);

export const ObjectPresentCountEvent = defineEventTransformer(
    (event, { timeZone, t, locale }) => {
        const icon = ICONS.SENSORS.DOOR;
        const label = t('Counting door', { ns: 'sensor-events' });
        const key = 'countingDoor';

        const { reading, readingFooter } = getCountEventReading(
            event?.value as number,
            event?.timestamp,
            {
                timeZone,
                t,
                locale,
            },
        );

        return { icon, label, reading, readingFooter, key };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT_DELTA },
);

export const WaterPresentEvent = defineEventTransformer(
    (event, { t }) => ({
        icon: ICONS.SENSORS.WATER_DETECTION,
        label: t('Water detection status', { ns: 'sensor-events' }),
        reading: t(
            event?.value === true
                ? WATER_PRESENT_STATE.PRESENT
                : WATER_PRESENT_STATE.NOT_PRESENT,
        ),
        key: SENSOR_EVENT_TYPES_V2.WATER_PRESENT,
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.WATER_PRESENT },
);

export const DeskOccupancyEvent = defineEventTransformer(
    (event, { t }) => {
        const isOccupied = event?.value === true;
        const iconClass = isOccupied
            ? 'icon--desk-occupancy-occupied'
            : 'icon--desk-occupancy-unoccupied';
        const icon = <i className={classNames('icon icon-lg', iconClass)} />;

        const label = t('Desk occupancy status', { ns: 'sensor-events' });
        const reading = t(
            event?.value === true ? OCCUPIED_STATE.OCCUPIED : OCCUPIED_STATE.UNOCCUPIED,
        );
        const key = SENSOR_EVENT_TYPES_V2.DESK_OCCUPANCY;

        return { icon, label, reading, key };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.DESK_OCCUPANCY },
);

export const TouchEvent = defineEventTransformer(
    (event, { t }) => {
        const icon = ICONS.SENSORS.TOUCH;
        const label = t('Last touched', { ns: 'sensor-events' });
        const reading = (
            <TimeFromNow
                autoUpdate
                timestamp={event?.timestamp as PotentiallyDangerousDate}
            />
        );
        const key = SENSOR_EVENT_TYPES_V2.TOUCH;

        return { icon, label, reading, updateTrigger: event?.timestamp, key };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.TOUCH },
);

export const TouchCountEvent = defineEventTransformer(
    (event, { timeZone, t, locale }) => {
        const icon = ICONS.SENSORS.TOUCH;
        const label = t('Counting touch', { ns: 'sensor-events' });
        const key = SENSOR_EVENT_TYPES_V2.TOUCH;
        const { reading, readingFooter } = getCountEventReading(
            event?.value as number,
            event?.timestamp,
            {
                timeZone,
                t,
                locale,
            },
        );

        return { icon, label, reading, readingFooter, key };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.TOUCH },
);

export const FeedbackPanelTouchEvent = defineEventTransformer(
    (event, { timeZone, t, locale }) => {
        const icon = ICONS.SENSORS.TOUCH;
        const label = t('Feedback panel touch', { ns: 'sensor-events' });
        const key = 'touch';

        const { reading, readingFooter } = getCountEventReading(
            event?.value.count as number,
            event?.timestamp,
            {
                timeZone,
                t,
                locale,
            },
        );

        return { icon, label, reading, readingFooter, key };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.FEEDBACK_PANEL_TOUCH },
);

export const WaterMovingEvent = defineEventTransformer(
    (event, { t }) => {
        const icon = ICONS.SENSORS.WATER_MOVING;
        const label = t('Last water moving event', { ns: 'sensor-events' });
        const key = SENSOR_EVENT_TYPES_V2.WATER_MOVING;
        const reading = <TimeFromNow autoUpdate timestamp={event?.timestamp} />;

        return { icon, label, reading, updateTrigger: event?.timestamp, key };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.WATER_MOVING },
);

export const SpaceOccupancyEvent = defineEventTransformer(
    (event, { t }) => {
        const icon = ICONS.SENSORS.OCCUPANCY;
        const label = t('Occupancy', { ns: 'sensor-events' });

        const reading = parseInt(event?.value as string, 10);
        const key = SENSOR_EVENT_TYPES_V2.SPACE_OCCUPANCY;
        const readingExtra =
            reading < 0 ? (
                <Tooltip
                    content={t(
                        'There must be a counting error. Please reset the occupancy under Configuration & Details.',
                        { ns: 'sensor-events' },
                    )}
                    position={Position.BOTTOM}
                    className="tw-ml-auto"
                >
                    <Icon
                        icon={IconNames.WARNING_SIGN}
                        intent={Intent.WARNING}
                        iconSize={20}
                        className="tw-self-center"
                    />
                </Tooltip>
            ) : null;

        return { icon, label, reading, readingExtra, key };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.SPACE_OCCUPANCY },
);

export const NetworkStatusEvent = defineEventTransformer(
    (events, { t, sensor }) => {
        const signalLevelEvent = events.connection_signal;
        const signalTypeEvent = events.connection_type;

        const sensorConfig = getSensor(sensor);

        const signalStrength = round(
            (parseFloat(signalLevelEvent?.value as string) || 0) * 100,
            0,
        );

        const isOffline = !sensorConfig.getIsOnline();

        const isBoostMode = signalTypeEvent?.value === CONNECTION_TYPE.RADIO_HIGH_POWER;
        const key = SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL;
        const icon = (
            <SignalLevel
                signalLevel={signalStrength}
                isOffline={isOffline}
                isBoostMode={isBoostMode}
            />
        );

        const label = t('Signal', { ns: 'sensor-events' });

        let reading;
        let readingUnit = '';

        if (isOffline) {
            reading = t('Offline', { ns: 'sensor-events' });
        } else if (!signalLevelEvent) {
            reading = null;
        } else {
            reading = signalStrength?.toFixed(0);
            readingUnit = t('%', { ns: 'sensor-events' });
        }

        return { icon, label, reading, readingUnit, key };
    },
    {
        eventKey: [
            SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL,
            SENSOR_EVENT_TYPES_V2.CONNECTION_TYPE,
        ],
    },
);

const getReadingLabel = (selectedUnit: string, t: TFunction): string => {
    switch (selectedUnit) {
        case SENSOR_EVENT_TYPES_V2.CARBON_DIOXIDE_EQUIVALENT:
            return t('CO2e Emissions', { ns: 'sensor-events' });
        case SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS_PER_M2:
            return t('Energy consumption per m2', { ns: 'sensor-events' });
        default:
            return t('Energy consumption', { ns: 'sensor-events' });
    }
};

export const KilowattHoursEvent = defineEventTransformer(
    (
        event,
        { t },
        sensorConfiguration: SensorConfigurationShape,
        selectedUnit: SensorEventType,
    ) => ({
        icon: getElectricityIcon(selectedUnit),
        label: getReadingLabel(selectedUnit, t),
        reading: getFormattedElectricityReadingByEventType(
            selectedUnit,
            parseFloat(event?.value as string) || 0,
            sensorConfiguration,
        ),
        readingUnit: getElectricityUnit(selectedUnit, t),
        key: getElectricityReadingType(selectedUnit),
        labelTooltip: getSensorDetailsTooltip(
            selectedUnit,
            sensorConfiguration as ElectricitySensorConfigurationShape,
        ),
    }),
    { eventKey: SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS },
);

// This is being used as a duplicate implementation of battery level for the sensor details screen when other
// battery level UI elements use BaseSensor.getBatteryLevel(). Source of truth issue. May be fixed in SCALE-74
export const BatteryStatusEvent = defineEventTransformer(
    (event, { t }) => {
        const batteryLevel = round((parseFloat(event?.value as string) || 0) * 100, 0);
        const icon = <BatteryLevelIcon level={batteryLevel} />;

        const label = t('Battery', { ns: 'sensor-events' });
        const reading = !!event?.value ? batteryLevel?.toFixed(0) : null;
        const readingUnit = t('%', { ns: 'sensor-events' });
        const key = 'batteryStatus';

        return { icon, label, reading, readingUnit, key };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.BATTERY_LEVEL },
);

export const CConConnectionStatusEvent = defineEventTransformer(
    (event, { t }) => {
        if (!event) {
            return null;
        }

        const reading = t(
            Object.values(CONNECTION_TYPE).includes(
                event.value as Values<typeof CONNECTION_TYPE>,
            )
                ? CONNECTION_TYPE_USER_READABALE_VALUE[
                      event.value as keyof typeof CONNECTION_TYPE_USER_READABALE_VALUE
                  ]
                : CONNECTION_TYPE_USER_READABALE_VALUE[CONNECTION_TYPE.OFFLINE],
        );

        let icon;

        switch (event.value) {
            case CONNECTION_TYPE.ETHERNET: {
                icon = ICONS.SENSORS.ETHERNET;
                break;
            }
            case CONNECTION_TYPE.CELLULAR: {
                icon = ICONS.SENSORS.CELLULAR;
                break;
            }
            case CONNECTION_TYPE.OFFLINE:
            default: {
                icon = <SignalLevel isOffline />;
            }
        }

        const label = t('Active connection', { ns: 'sensor-events' });
        const key = 'connectionStatus';

        return { icon, label, reading, key };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.CONNECTION_TYPE },
);

export const MonnitHubConnectionStatusEvent = defineEventTransformer(
    (events) => {
        const signalLevelEvent = events.connection_signal;
        const signalTypeEvent = events.connection_type;

        if (!signalLevelEvent) {
            return null;
        }

        const reading =
            signalTypeEvent?.value === CONNECTION_TYPE.OFFLINE
                ? CONNECTION_TYPE_USER_READABALE_VALUE[CONNECTION_TYPE.OFFLINE]
                : CONNECTION_TYPE_USER_READABALE_VALUE[CONNECTION_TYPE.CELLULAR];
        const icon = !!signalLevelEvent ? (
            ICONS.SENSORS.CELLULAR
        ) : (
            <SignalLevel isOffline />
        );

        const label = registerTranslationKey('Active connection');
        const key = 'connectionStatus';

        return { icon, label, reading, key };
    },

    {
        eventKey: [
            SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL,
            SENSOR_EVENT_TYPES_V2.CONNECTION_TYPE,
        ],
    },
);

export const NetworkHubSignalEvent = defineEventTransformer(
    (events, { t }) => {
        const signalLevelEvent = events.connection_signal;
        const signalTypeEvent = events.connection_type;

        // If there is no latest event, let's not show the event altogether.
        if (!signalLevelEvent || signalTypeEvent?.value === CONNECTION_TYPE.OFFLINE) {
            return null;
        }

        return {
            icon: (
                <SignalLevel
                    signalLevel={Math.round((signalLevelEvent.value as number) * 100)}
                />
            ),
            label: t('Cellular signal strength', { ns: 'sensor-events' }),
            reading: ((signalLevelEvent.value as number) * 100).toFixed(0),
            readingUnit: t('%', { ns: 'sensor-events' }),
            key: 'cellularStatus',
        };
    },

    {
        eventKey: [
            SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL,
            SENSOR_EVENT_TYPES_V2.CONNECTION_TYPE,
        ],
    },
);

export const EmergencyLightTestEvent = defineEventTransformer(
    (event) => {
        if (!event) {
            return null;
        }

        const passed = event.value.test_passed;

        return {
            icon: (
                <TestStatusIcon
                    isPassing={passed}
                    failureType={event.value.failure_type}
                />
            ),
            label: registerTranslationKey('Last test'),
            reading: (
                <TestStatusText
                    isPassing={passed}
                    failureType={event.value.failure_type}
                    isAllCaps
                />
            ),
            key: SENSOR_EVENT_TYPES_V2.EMERGENCY_LIGHT_TEST,
        };
    },
    { eventKey: SENSOR_EVENT_TYPES_V2.EMERGENCY_LIGHT_TEST },
);
