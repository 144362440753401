import { LEGIONNAIRE_SUB_TYPES } from '@infogrid/sensors-constants';

interface SensorLimit {
    minTemperature: number;
    maxTemperature: number;
}

export const SENSOR_LIMITS: { [key: string]: SensorLimit } = {
    [LEGIONNAIRE_SUB_TYPES.HOT]: {
        minTemperature: 40,
        maxTemperature: 80,
    },
    [LEGIONNAIRE_SUB_TYPES.HOT_POU_HEATER]: {
        minTemperature: 36,
        maxTemperature: 80,
    },
    [LEGIONNAIRE_SUB_TYPES.COLD]: {
        minTemperature: 5,
        maxTemperature: 25,
    },
    [LEGIONNAIRE_SUB_TYPES.COLD_STORAGE]: {
        minTemperature: 5,
        maxTemperature: 25,
    },
    [LEGIONNAIRE_SUB_TYPES.BLENDED]: {
        minTemperature: 30,
        maxTemperature: 60,
    },
    [LEGIONNAIRE_SUB_TYPES.CALORIFIER_FLOW]: {
        minTemperature: 40,
        maxTemperature: 90,
    },
    [LEGIONNAIRE_SUB_TYPES.CALORIFIER_RETURN]: {
        minTemperature: 40,
        maxTemperature: 90,
    },
    [LEGIONNAIRE_SUB_TYPES.GENERIC]: {
        minTemperature: 1,
        maxTemperature: 100,
    },
};
