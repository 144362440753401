import { getSensor } from '@infogrid/sensors-configuration';
import type { SensorEventV2Shape, SensorShape } from '@infogrid/sensors-constants';

export const getSensorLastSignalEvent = (
    sensor: SensorShape,
): SensorEventV2Shape | null => {
    const sensorConfig = getSensor(sensor);

    return sensorConfig?.getSignalLevelEvent() ?? null;
};
