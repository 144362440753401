/* eslint-disable babel/camelcase */
import type { AxiosParsedError } from '@infogrid/core-types';
import type {
    FloorDetail,
    FloorSensor,
    FloorSensorLocation,
    SpaceType,
} from '@infogrid/locations-types';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import type { MappableSensor, SensorType } from '@infogrid/sensors-constants';
import { useIsMobile } from '@infogrid/utils-hooks';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { CircularProgress } from '@material-ui/core';
import { memo, useCallback, useMemo } from 'react';

import { useCustomError } from 'apiHooks/base/hooks';
import { useUploadFloorplan } from 'apiHooks/floorPlan/floors/hooks';
import FileUploader from 'components/FileUploader/FileUploader';
import ReplaceFloorImageModal from 'views/buildings/components/modals/ReplaceFloorImageModal';

import UploadCardContent from '../../../cards/UploadCardContent';
import EditImageModal from '../../../modals/EditFloorImageModal';
import FloorPlanMap from '../FloorPlanMap';
import type { FloorPlanMapProps } from '../FloorPlanMap/FloorPlanMap';
import ViewSensorModal from '../ViewSensorModal';
import { useStylesFloorPlan } from './styles';

const handleUploadingError = (err: AxiosParsedError) => {
    if (!err) {
        return null;
    }

    const error = err?.parsedError?.errors;

    if (error?.raw_image_file) {
        return {
            error: 'Error',
            description: `${error.raw_image_file}`,
        };
    }

    return {
        error: registerTranslationKey('SELECT ANOTHER FILE', { ns: 'floorplan' }),
        description: registerTranslationKey('File upload failed', { ns: 'floorplan' }),
    };
};

export interface FloorPlanSensor
    extends Omit<
        FloorSensor,
        | 'id'
        | 'profile'
        | 'latest_events_v2'
        | 'sensor_configuration'
        | 'space_name'
        | 'vendor'
        | 'vendor_info'
    > {
    id: string | number;
    name: string;
    profile: { uuid: string };
}

interface FloorPlanProps
    extends Pick<
        FloorPlanMapProps,
        | 'onZoomChange'
        | 'onRotationChange'
        | 'addSensorOnMap'
        | 'changeSensorPosition'
        | 'changeSensorIconScale'
    > {
    floorId: number;
    floor?: FloorDetail;
    isFetching: boolean;
    floorPlan?: {
        height: number;
        image_file: string;
        width: number;
        zoom: number;
        orientation: number;
    };
    onImageUpdateSuccess: () => void;
    changeActiveSensorTypes: (types: SensorType[]) => void;
    activeSensorTypes: SensorType[];
    sensorTypes: SensorType[];
    mappedSensors?: MappableSensor<FloorPlanSensor>[];
    activeSensorDeviceId?: string | number;
    onSensorSelect: (id: string | number | null) => void;
    isEditAvailable?: boolean;
    deleteSensorFromMap: (
        sensorDeviceId: string,
        coordinates?: FloorSensorLocation,
    ) => void;
    isSensorMoveEnabled: boolean;
    setIsSensorMoveEnabled: (enabled: boolean) => void;
    shouldKeepSensorsMapped?: boolean;
    spaceTypes: SpaceType[];
}

const FloorPlan = ({
    floorId,
    isFetching,
    floorPlan,
    sensorTypes,
    activeSensorTypes,
    changeActiveSensorTypes,
    mappedSensors = [],
    activeSensorDeviceId,
    onSensorSelect,
    isEditAvailable,
    onImageUpdateSuccess,
    deleteSensorFromMap,
    shouldKeepSensorsMapped,
    isSensorMoveEnabled,
    setIsSensorMoveEnabled,
    spaceTypes,
    floor,
    ...mapEventHandlers
}: FloorPlanProps) => {
    const stylesFloorPlan = useStylesFloorPlan();
    const isMobile = useIsMobile();

    const { uploadFloorPlan, reset, isLoading, error } = useUploadFloorplan();

    const { error: floorPlanError, setError: setFloorPlanError } = useCustomError({
        error,
        handleError: handleUploadingError,
    });

    const sensorsTypesItems = useMemo(() => {
        return sensorTypes.map((type) => {
            const { label } = getSensorConfig(type);

            return { key: type, name: label };
        });
    }, [sensorTypes]);

    const uploadFloorPlanHandler = useCallback(
        ([file]) => {
            const formData = new FormData();

            formData.append('raw_image_file', file, file.name);
            formData.append('unmap_sensors', `${!shouldKeepSensorsMapped}`);

            uploadFloorPlan(
                // @ts-expect-error must be migrated to TS
                { floorId, data: formData, unmapSensors: !shouldKeepSensorsMapped },
                { onSuccess: onImageUpdateSuccess },
            );
        },
        [floorId, onImageUpdateSuccess, shouldKeepSensorsMapped, uploadFloorPlan],
    );

    const onDropFailure = useCallback(
        (err) => {
            let result = {
                error: registerTranslationKey('SELECT ANOTHER FILE', { ns: 'floorplan' }),
                description: registerTranslationKey('File upload failed', {
                    ns: 'floorplan',
                }),
            };

            if (err.includes('file-invalid-type')) {
                result = {
                    error: registerTranslationKey('File extension unsupported', {
                        ns: 'floorplan',
                    }),
                    description: `${registerTranslationKey('Supported formats', {
                        ns: 'floorplan',
                    })}: jpeg, jpg, png, pdf `,
                };
            }

            setFloorPlanError(result);
        },
        [setFloorPlanError],
    );

    const activeSensor = useMemo(
        () => mappedSensors.find((sensor) => sensor.id === activeSensorDeviceId),
        [activeSensorDeviceId, mappedSensors],
    );

    const onCloseViewSensorModal = useCallback(() => {
        onSensorSelect(null);
    }, [onSensorSelect]);

    return (
        <div className={stylesFloorPlan.floorPlanContainer}>
            <div
                className={stylesFloorPlan.uploadCardContainer}
                data-cypress="floor-card"
            >
                {!floorPlan && (
                    <FileUploader
                        onDropSuccess={uploadFloorPlanHandler}
                        onDropFailure={onDropFailure}
                        disabled={!isEditAvailable}
                    >
                        {({ onOpen }) => {
                            return (
                                <div className={stylesFloorPlan.cardContainer}>
                                    {!isFetching && (
                                        <UploadCardContent
                                            onCancelClick={reset}
                                            onTryAgainClick={reset}
                                            openFileDialog={onOpen}
                                            isUploading={isLoading}
                                            disabled={!isEditAvailable}
                                            error={floorPlanError}
                                        />
                                    )}

                                    {isFetching && (
                                        <CircularProgress
                                            size={50}
                                            color="primary"
                                            style={{ marginRight: 8 }}
                                        />
                                    )}
                                </div>
                            );
                        }}
                    </FileUploader>
                )}
            </div>

            {floorPlan && floor && (
                <>
                    <FloorPlanMap
                        floor={floor}
                        floorId={floorId}
                        floorImage={floorPlan.image_file}
                        heightResolution={floorPlan?.height}
                        widthResolution={floorPlan?.width}
                        mappedSensors={mappedSensors}
                        sensorsTypes={sensorsTypesItems}
                        activeSensorTypes={activeSensorTypes}
                        changeActiveSensorTypes={changeActiveSensorTypes}
                        activeSensorDeviceId={activeSensorDeviceId}
                        onSensorSelect={onSensorSelect}
                        isEditAvailable={isEditAvailable}
                        deleteSensorFromMap={deleteSensorFromMap}
                        isSensorMoveEnabled={isSensorMoveEnabled}
                        setIsSensorMoveEnabled={setIsSensorMoveEnabled}
                        spaceTypes={spaceTypes}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...mapEventHandlers}
                    />

                    <ReplaceFloorImageModal
                        error={floorPlanError}
                        isUploading={isLoading}
                        onSubmit={uploadFloorPlanHandler}
                        onCancel={reset}
                    />

                    <EditImageModal />

                    {isMobile && !!activeSensor && (
                        <ViewSensorModal
                            isOpened={!!activeSensor && !isSensorMoveEnabled}
                            onClose={onCloseViewSensorModal}
                            onMoveSensor={setIsSensorMoveEnabled}
                            onRemoveSensor={deleteSensorFromMap}
                            sensor={activeSensor}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default memo(FloorPlan);
