import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { createContext, useContext } from 'react';

export interface SensorsSelectionSensor extends GenericSensorShape {
    profile: { name?: string };
}

export interface ISensorsSelectionContext {
    allSelected: boolean;
    selectedRows: string[];
    excludedItems: string[];
    selectedItemsCount: number;
    // selectedSensor and excludedSensors are used to validate sensors by type before generating a report
    // via BuildReportFlow.
    selectedSensors: SensorsSelectionSensor[];
    excludedSensors: SensorsSelectionSensor[];
    select: (checked: boolean, item: SensorsSelectionSensor) => void;
    selectAll: (checked: boolean) => void;
    getIsItemSelected: (val: string) => boolean;
    resetSelection: () => void;
}

export const SensorsSelectionContext = createContext<
    Pick<
        ISensorsSelectionContext,
        | 'selectedRows'
        | 'allSelected'
        | 'excludedItems'
        | 'excludedSensors'
        | 'selectedItemsCount'
        | 'selectedSensors'
    > &
        Partial<
            Pick<
                ISensorsSelectionContext,
                'select' | 'selectAll' | 'getIsItemSelected' | 'resetSelection'
            >
        >
>({
    selectedRows: [],
    allSelected: false,
    excludedItems: [],
    excludedSensors: [],
    selectedSensors: [],
    selectedItemsCount: 0,
});

export const useSensorsSelectionContext = () => {
    const context = useContext(SensorsSelectionContext);

    if (!context) {
        throw new Error(
            'useSensorsSelectionContext must be used within SensorsSelectionContext.Provider',
        );
    }

    return context;
};
