import 'ol/ol.css';

import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Map from 'ol/Map';
import View from 'ol/View';
import { defaults as defaultInteractions } from 'ol/interaction';
import PropTypes from 'prop-types';
import { useRef, useState, useEffect, memo } from 'react';
import { useMeasure, useDebounce } from 'react-use';

import MapContext from './MapContext';

// viewOptions - https://openlayers.org/en/latest/apidoc/module-ol_View-View.html

const useStylesMap = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: '#f8f5f2',
        position: 'relative',
    },
    mapContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
        flexGrow: '1',
        minWidth: 1,
    },
});

const MapComponent = ({
    viewOptions,
    children,
    controls,
    interactions,
    className,
    cursor,
}) => {
    const styles = useStylesMap();

    const mapRef = useRef();

    const [map, setMap] = useState(null);

    const [ref, { width, height }] = useMeasure();

    useEffect(() => {
        const mapObject = new Map({
            interactions,
            target: mapRef.current,
            view: new View(viewOptions),
        });

        setMap(mapObject);

        return () => {
            mapObject.setTarget(undefined);
            setMap(null);
        };
    }, [viewOptions, interactions]);

    useDebounce(
        () => {
            if (map) {
                map.updateSize();
            }
        },
        100,
        [width, height],
    );

    return (
        <MapContext.Provider value={{ map }}>
            <div ref={ref} className={classNames(styles.root, className)}>
                {controls}
                <div
                    ref={mapRef}
                    style={{ cursor }}
                    className={classNames(styles.mapContainer, `ol-map`)}
                    data-cypress="map"
                >
                    {children}
                </div>
            </div>
        </MapContext.Provider>
    );
};

MapComponent.propTypes = {
    viewOptions: PropTypes.shape({}).isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
        .isRequired,
    controls: PropTypes.node,
    interactions: PropTypes.shape({}),
    className: PropTypes.string,
    cursor: PropTypes.oneOf([
        'url',
        'auto',
        'default',
        'none',
        'context-menu',
        'help',
        'pointer',
        'progress',
        'wait',
        'cell',
        'crosshair',
        'text',
        'vertical-text',
        'alias',
        'copy',
        'move',
        'no-drop',
        'not-allowed',
        'e-resize',
        'n-resize',
        'ne-resize',
        'nw-resize',
        's-resize',
        'se-resize',
        'sw-resize',
        'w-resize',
        'ew-resize',
        'ns-resize',
        'nesw-resize',
        'nwse-resize',
        'col-resize',
        'row-resize',
        'all-scroll',
        'zoom-in',
        'zoom-out',
        'grab',
        'grabbing',
    ]),
};

MapComponent.defaultProps = {
    controls: null,
    className: '',
    interactions: defaultInteractions(),
};

export default memo(MapComponent);
