import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { BuildingDetail } from '@infogrid/locations-types';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getBuildingKey } from '../getQueryKeys';

interface UseBuildingResult
    extends Omit<UseQueryResult<BuildingDetail, AxiosParsedError>, 'data'> {
    building: UseQueryResult<BuildingDetail, AxiosParsedError>['data'];
}

export const useBuilding = (
    buildingId: number | null,
    options?: UseQueryOptions<BuildingDetail, AxiosParsedError>,
): UseBuildingResult => {
    const { data: building, ...rest } = useQuery(
        getBuildingKey(buildingId),
        ({ signal }) =>
            wrapResponsePromise(controllers.getBuilding(buildingId as number, signal)),
        {
            enabled: !!buildingId,
            refetchOnMount: false,
            ...options,
        },
    );

    return {
        building,
        ...rest,
    };
};
