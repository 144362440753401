export const StreamNames = {
    Control: 'control',
    EventLogEvents: 'eventLogEvents',
    Folders: 'folders',
    Heartbeat: 'heartbeat',
    Reports: 'reports',
    Sensors: 'sensors',
    Team: 'team',
    User: 'user',
};
