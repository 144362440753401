import { queryClient } from '@infogrid/core-api';

import { SINGLE_SENSORS_KEYS_GETTERS, SENSORS_IN_THE_LIST_KEYS } from './constants';

export const cleanInactiveCachedSingleSensorQueries = (deviceName) => {
    const queryKeys = SINGLE_SENSORS_KEYS_GETTERS.map((getKey) => getKey(deviceName));

    queryKeys.forEach((sensorKey) =>
        queryClient.removeQueries(sensorKey, {
            exact: true,
            active: false,
            inactive: true,
        }),
    );
};

export const cleanInactiveCachedSensorListQueries = () => {
    SENSORS_IN_THE_LIST_KEYS.forEach((listKey) => {
        queryClient.removeQueries(listKey, { active: false, inactive: true });
    });
};
