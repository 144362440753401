import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type { SensorType, SensorShape } from '@infogrid/sensors-constants';
import { Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import SensorSignalLevel from 'components/material-ui/Sensors/SignalLevel';
import SensorTypeIcon from 'components/material-ui/Sensors/Type';
import useCurrentSensorState from 'utils/hooks/useCurrentSensorState';

import { useSensorDisplayStyles } from './styles';

export interface SensorDisplayProps {
    sensor: SensorShape;
    label?: string;
    deviceType: SensorType;
}

/**
 * Displays information about this sensor:
 *      * an icon according to its type
 *      * its device_name
 *      * network connection icon base don how strong its connection is
 */
const SensorDisplay = ({ sensor, label = '', deviceType }: SensorDisplayProps) => {
    const { t } = useTranslation('sensors');
    const classes = useSensorDisplayStyles();

    const sensorLabel = `${label}${
        deviceType !== SENSOR_TYPE.TYPE_CCON &&
        deviceType !== SENSOR_TYPE.TYPE_AIRTHINGS_HUB &&
        label
            ? t(' sensor')
            : ''
    }`;
    const sensorConfig = useCurrentSensorState(sensor);
    const lastSignalEvent = sensorConfig.getSignalLevelEvent();

    return (
        <div className={classes.root}>
            <SensorTypeIcon
                sensor={sensor}
                className={classes.sensorDisplayIcon}
                size={40}
            />
            <div className={classes.textContainer}>
                <Typography variant="subtitle1" data-cypress="title-device-type">
                    {sensorLabel}
                </Typography>
                <Typography
                    variant="body2"
                    className={classes.paragraph}
                    data-cypress="title-device-id"
                >
                    {sensor.device_name}
                </Typography>
            </div>
            {lastSignalEvent && <SensorSignalLevel sensor={sensor} size={30} />}
        </div>
    );
};

export default memo(SensorDisplay);
