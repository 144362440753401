import { toastSuccess } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { i18n } from '@infogrid/utils-i18n';
import { createSaveAction, createFormSaveSaga } from '@thorgate/spa-forms';
import { push } from 'connected-react-router';
import { takeLatest, put } from 'redux-saga/effects';

import api from 'services/api';
import { handleDelayingFormSave } from 'utils/handleDelayingFormSave';

/**
 * Trigger Save Team worker saga.
 * @param {Object} payload Payload to send to server
 * @param {function} setErrors - Function to set form errors
 * @param {function} setSubmitting - Function to call finish submitting
 * @param {function} setStatus - Function to set form status
 * @returns {{type: string, payload: *, setErrors: *, setSubmitting: *, setStatus: *}} Created trigger action
 */
export const saveTeam = createSaveAction('sagas/account/SAVE_TEAM');

function* successHook(result, match, action) {
    yield put(
        toastSuccess({
            message: `${i18n.t('Team saved')} - ${action.payload.data.name}`,
        }),
    );
    yield put(push(routesManager.resolvePath('organization:teams')));
}

const handleFormSave = createFormSaveSaga({
    method: 'patch',
    resource: api.organization.team.detail,
    successHook,

    mutateKwargs: ({ params: { teamId } }, kwargs) => ({
        ...kwargs,
        pk: teamId,
    }),
});

export default function* saveTeamWatcherSaga(match) {
    yield takeLatest(
        saveTeam.getType(),
        handleDelayingFormSave(handleFormSave, i18n.t('Updating a team is in progress')),
        match,
    );
}
