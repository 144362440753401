import { makeStyles } from '@material-ui/core';

export const useDeletingBuildingStyles = makeStyles((theme) => ({
    container: {
        width: '450px',
    },
    spinner: {
        color: theme.palette.secondary.main,
        marginRight: '10px',
    },
    cancelButton: {
        color: theme.palette.common.gray1,
    },
    description: {
        wordBreak: 'break-all',
    },
}));
