import { createRequest } from '@infogrid/core-api';
import type { OrganizationIdType } from '@infogrid/core-types';
import type { AxiosPromise } from 'axios';

import type { CorrigoCredentials } from 'views/integrations/components/corrigo/types';

const downloadSensorSpreadsheet = (
    organizationId: OrganizationIdType,
): AxiosPromise<BlobPart> => {
    return createRequest({
        method: 'get',
        url: `org/${organizationId}/tasks/mappings/corrigo`,
        options: {
            responseType: 'blob',
        },
    });
};

const uploadSensorSpreadsheet = (
    organizationId: OrganizationIdType,
    data: FormData,
): AxiosPromise<JSON> => {
    return createRequest({
        method: 'post',
        url: `org/${organizationId}/tasks/mappings/upload/corrigo`,
        options: {
            data,
        },
    });
};

const retrieveCorrigoCredentials = (organizationId: OrganizationIdType): AxiosPromise => {
    return createRequest({
        method: 'get',
        url: `org/${organizationId}/tasks/credentials/corrigo`,
        options: {
            responseType: 'json',
        },
    });
};

const uploadCorrigoCredentials = (
    organizationId: OrganizationIdType,
    credentials: CorrigoCredentials,
): AxiosPromise => {
    return createRequest({
        method: 'put',
        url: `org/${organizationId}/tasks/credentials/corrigo`,
        options: {
            data: credentials,
        },
    });
};

export const controllers = {
    downloadSensorSpreadsheet,
    uploadSensorSpreadsheet,
    retrieveCorrigoCredentials,
    uploadCorrigoCredentials,
};
