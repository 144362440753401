import { removeToast, toast } from '@infogrid/core-ducks';
import { i18n } from '@infogrid/utils-i18n';
import { put } from 'redux-saga/effects';

import { invalidateReports } from 'apiHooks/reports/accessors';
import ReportCTAToast from 'views/reports/components/ReportCTAToast';
import { getReportGenerationFinishedToast, reportCreatedKey } from 'views/reports/utils';

const REPORT_UPDATE_GENERATION_FINISHED = 'update_generation_finished';

export function* reportWorker({ action, report }) {
    const { t } = i18n;

    if (action === 'delete') {
        // TODO we need send report for delete action to clear store
        // queryClient.invalidateQueries(REPORTS_QUERY_KEY);
        return;
    }

    if (action === 'update' || action === REPORT_UPDATE_GENERATION_FINISHED) {
        invalidateReports();

        if (action === REPORT_UPDATE_GENERATION_FINISHED) {
            // Remove toast saying generation has started since it has already finished
            const toastId = reportCreatedKey(report.id);

            yield put(removeToast(toastId));

            const { icon, intent, messageBase } = getReportGenerationFinishedToast(
                t,
                report,
            );
            const message = (
                <ReportCTAToast
                    id={report.id}
                    generationStatus={report.generation_status}
                >
                    {messageBase}
                </ReportCTAToast>
            );

            // Use 'infinite' timeout since the user might be waiting for this toast
            // This way they'll see it even if they don't check the screen at all times
            yield put(toast({ icon, intent, message, timeout: 0 }));
        }
    }
}
