import { makeStyles } from '@material-ui/core';

export const useWidgetDetailsStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        '& > div': {
            marginRight: theme.spacing(3),
        },
        '& > div:last-child': {
            marginRight: 0,
        },
    },
}));
