import { routesManager } from '@infogrid/core-routing';
import { selectOrganizationId } from '@infogrid/user-ducks';
import { createFetchAction, createFetchSaga } from '@thorgate/spa-entities';
import { takeLatestWithMatch } from '@thorgate/spa-view-manager';
import { select } from 'redux-saga/effects';

import { orgUserSchema } from 'schemas/orgUser';
import api from 'services/api';
import { serializeData } from 'utils/serializeData';

const getInitial = (action) =>
    function* initial({ params }) {
        const organizationId = yield select(selectOrganizationId);

        return action(
            { kwargs: { org_pk: organizationId, pk: params.userId } },
            { mergeEntities: true },
        );
    };

export const fetchOrgUser = createFetchAction('sagas/account/FETCH_ORG_USER');
export const fetchOrgServiceAccount = createFetchAction(
    'sagas/account/FETCH_ORG_SERVICE_ACCOUNT',
);

export const fetchOrgUserWorkerBase = createFetchSaga({
    key: orgUserSchema.key,
    listSchema: [orgUserSchema],
    resource: api.organization.user.detail,
    serializeData,

    useDetails: true,
});

export const fetchOrgUserWorker = fetchOrgUserWorkerBase.asInitialWorker(
    getInitial(fetchOrgUser),
);

export default function* fetchOrgUserWatcher() {
    yield takeLatestWithMatch(
        fetchOrgUser.getType(),
        routesManager.resolvePattern('organization:account-detail'),
        fetchOrgUserWorkerBase,
    );
}

export const fetchOrgServiceAccountWorkerBase = fetchOrgUserWorkerBase.cloneSaga({
    resource: api.organization.serviceAccount.detail,
});

export const fetchOrgServiceAccountWorker =
    fetchOrgServiceAccountWorkerBase.asInitialWorker(getInitial(fetchOrgServiceAccount));

export function* fetchOrgServiceAccountWatcher() {
    yield takeLatestWithMatch(
        fetchOrgServiceAccount.getType(),
        routesManager.resolvePattern('organization:service-account-detail'),
        fetchOrgServiceAccountWorkerBase,
    );
}
