// We have to use direct theme importing here because theme context doesn't play
// nicely with the renderToStaticMarkup call used by highcharts tooltips
import { theme } from '@infogrid/components-material-ui';
import { makeStyles } from '@material-ui/core';

export const useTooltipStyles = makeStyles(() => ({
    row: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    label: {
        maxWidth: '250px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    value: {
        marginLeft: theme.spacing(4),
    },
    header: {
        color: theme.palette.common.gray6,
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
    },
    box: {
        height: '10px',
        marginRight: theme.spacing(1),
        width: '10px',
    },
    link: {
        '&, &:hover': {
            color: theme.palette.text.primary,
        },
    },
}));
