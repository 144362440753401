import type { WidgetType } from '@infogrid/dashboards-constants';
import { useCreateWidget } from '@infogrid/dashboards-hooks';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { CircularProgress } from '@material-ui/core';
import type { SyntheticEvent } from 'react';
import { useCallback, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ActionsMenu from 'components/ActionsMenu';
import { Widget } from 'views/dashboards/components/Widget';
import {
    duplicateWidgetEvent,
    trackDashboardEvent,
} from 'views/dashboards/utils/analytics';
import { useDashboardContext } from 'views/dashboards/utils/context';

import ConfigurationActionsList from './ConfigurationActionsList';
import { configurableDefaults, fullscreenDefaults, refreshableDefaults } from './consts';
import { ICONS, useGenericActionsStyles } from './styles';
import type { ConfigurableProps, FullscreenProps, RefreshableProps } from './types';

export interface GenericActionsProps {
    canUserEdit: boolean;
    configurableProps: ConfigurableProps;
    dashboardId: string;
    fullscreenProps?: FullscreenProps;
    refreshableProps: RefreshableProps;
    widgetConfiguration: unknown;
    widgetType: WidgetType;
    widgetName: string;
}

const GenericActions = ({
    canUserEdit,
    configurableProps,
    dashboardId,
    fullscreenProps,
    refreshableProps,
    widgetConfiguration,
    widgetType,
    widgetName,
}: GenericActionsProps) => {
    const styles = useGenericActionsStyles();

    const { mutate: createWidget } = useCreateWidget();

    const { setConfigurationModalWidgetId } = useDashboardContext();

    const onDuplicateWidget = useCallback(() => {
        createWidget(
            {
                dashboardId: +dashboardId,
                data: {
                    configuration: widgetConfiguration,
                    name: widgetName,
                    type: widgetType,
                },
            },
            {
                onSuccess: (duplicatedWidget) => {
                    setConfigurationModalWidgetId(duplicatedWidget.id);
                    duplicateWidgetEvent({ widgetType });
                },
            },
        );
    }, [
        createWidget,
        dashboardId,
        setConfigurationModalWidgetId,
        widgetConfiguration,
        widgetName,
        widgetType,
    ]);

    const configurable: ConfigurableProps = {
        ...configurableDefaults,
        ...configurableProps,
    };
    const fullscreen: FullscreenProps = {
        ...fullscreenDefaults,
        ...(fullscreenProps ?? {}),
    };
    const refreshable: RefreshableProps = {
        ...refreshableDefaults,
        ...refreshableProps,
    };

    const actionsMenuClasses = useMemo(
        () => ({ menuContent: styles.actionsMenuContent }),
        [styles.actionsMenuContent],
    );

    const handleActionMouseDown = (e: SyntheticEvent) => {
        e.stopPropagation();
    };

    const { t } = useTranslation('dashboard');

    return (
        <Widget.Actions>
            {fullscreen.enabled && (
                <Widget.Action>
                    <i
                        aria-label={t('Enter fullscreen')}
                        className={ICONS.FULLSCREEN_ICON}
                        onClick={() => {
                            if (fullscreen.onFullscreenClick) {
                                trackDashboardEvent(
                                    USER_ACTIONS.PRESSED,
                                    'make widget fullscreen',
                                    {
                                        type: widgetType,
                                        name: widgetName,
                                    },
                                );
                                fullscreen.onFullscreenClick();
                            }
                        }}
                        onKeyDown={fullscreen.onFullscreenClick}
                        onMouseDown={handleActionMouseDown}
                        role="button"
                        tabIndex={0}
                    />
                </Widget.Action>
            )}
            {refreshable.enabled && (
                <Widget.Action>
                    {refreshable.isLoading ? (
                        <CircularProgress color="primary" size={18} />
                    ) : (
                        <i
                            aria-label={t('Refresh')}
                            className={ICONS.REFRESH_ICON}
                            onClick={() => {
                                trackDashboardEvent(
                                    USER_ACTIONS.PRESSED,
                                    'refresh widget',
                                    {
                                        type: widgetType,
                                        name: widgetName,
                                    },
                                );

                                if (refreshable.onRefresh) {
                                    refreshable.onRefresh();
                                }
                            }}
                            onKeyDown={refreshable.onRefresh}
                            onMouseDown={handleActionMouseDown}
                            onTouchStart={refreshable.onRefresh}
                            role="button"
                            tabIndex={0}
                        />
                    )}
                </Widget.Action>
            )}
            {configurable.enabled && (
                <ActionsMenu
                    classes={actionsMenuClasses}
                    handler={({ onToggle }) => (
                        <Widget.Action>
                            <i
                                aria-label={t('Configure')}
                                className={ICONS.CONFIGURE_ICON}
                                // TODO: Refactor ActionsMenu so that onToggle provides an event handler
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                onClick={onToggle}
                                onKeyDown={handleActionMouseDown}
                                onMouseDown={handleActionMouseDown}
                                // TODO: Refactor ActionsMenu so that onToggle provides an event handler
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                onTouchStart={onToggle}
                                role="button"
                                tabIndex={0}
                            />
                        </Widget.Action>
                    )}
                >
                    {() => (
                        <ConfigurationActionsList
                            canUserEdit={canUserEdit}
                            isConfigureWidgetAvailable={
                                configurable.isConfigureWidgetAvailable
                            }
                            isDuplicateDisabled={configurable.isDuplicateDisabled}
                            isKioskModeAvailable={configurable.isKioskModeAvailable}
                            onConfigure={configurable.onConfigure}
                            onDelete={configurable.onDelete}
                            onDuplicate={onDuplicateWidget}
                            onOpenKioskMode={configurable.onOpenKioskMode}
                            actions={configurable.actions}
                        />
                    )}
                </ActionsMenu>
            )}
        </Widget.Actions>
    );
};

export default memo(GenericActions);
