import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { UseQueryResult } from 'react-query';

import { useCorrigoCredentialsRetrieve } from 'apiHooks/settings/integrations/corrigo/hooks/useCorrigoCredentialsRetrieve';
import { useCorrigoCredentialsUpload } from 'apiHooks/settings/integrations/corrigo/hooks/useCorrigoCredentialsUpload';
import CorrigoCredentialsDisplay from 'views/integrations/components/corrigo/CorrigoCredentialsDisplay';
import CorrigoCredentialsForm from 'views/integrations/components/corrigo/CorrigoCredentialsForm';
import type { CorrigoCredentials } from 'views/integrations/components/corrigo/types';

import { useCorrigoStyling } from './styles';

const credentialDisplayStates = {
    NEEDS_LOADING: 0,
    DISPLAY_FORM: 1,
    DISPLAY_CREDS: 2,
};

const Authentication = () => {
    const styles = useCorrigoStyling();
    const { t } = useTranslation('integrations');

    const [displayStatus, setDisplayStatus] = useState(
        credentialDisplayStates.NEEDS_LOADING,
    );
    const [refetch, setRefetch] = useState({});

    const { mutate: uploadCorrigoCredentials } = useCorrigoCredentialsUpload({
        onSuccess: () => {
            setRefetch(() => {});
        },
    });

    const apiResult: UseQueryResult<Omit<CorrigoCredentials, 'client_secret'>> =
        useCorrigoCredentialsRetrieve(refetch);

    useEffect(() => {
        if (apiResult.isFetching) {
            setDisplayStatus(credentialDisplayStates.NEEDS_LOADING);
        }
    }, [apiResult.isFetching]);

    useEffect(() => {
        if (!apiResult.isFetching && apiResult.isSuccess) {
            setDisplayStatus(credentialDisplayStates.DISPLAY_CREDS);
        }
    }, [apiResult.isSuccess, apiResult.isFetching]);

    useEffect(() => {
        if (!apiResult.isFetching && apiResult.isError) {
            setDisplayStatus(credentialDisplayStates.DISPLAY_FORM);
        }
    }, [apiResult.isError, apiResult.isFetching]);

    return (
        <Paper className={styles.wrapper}>
            <Typography variant="h5" data-cypress="template-name">
                {t('Authenticate Corrigo credentials')}
            </Typography>
            {displayStatus === credentialDisplayStates.NEEDS_LOADING && (
                <CircularProgress color="primary" />
            )}
            {displayStatus === credentialDisplayStates.DISPLAY_CREDS && (
                <CorrigoCredentialsDisplay
                    credentials={apiResult.data}
                    reset={() => setDisplayStatus(credentialDisplayStates.DISPLAY_FORM)}
                />
            )}
            {displayStatus === credentialDisplayStates.DISPLAY_FORM && (
                <CorrigoCredentialsForm onClickUpload={uploadCorrigoCredentials} />
            )}
        </Paper>
    );
};

export default memo(Authentication);
