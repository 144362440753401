import { makeStyles } from '@material-ui/core';

export const useBuildingSectionStyles = makeStyles(() => ({
    spaceHeader: {
        paddingLeft: '16px',
        marginTop: '20px',
        marginBottom: '-13px',
        fontWeight: 'bold',
    },
}));
