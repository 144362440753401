import { Typography } from '@material-ui/core';
import { memo } from 'react';

import IdentifyDeviceWebSocketContainer from 'containers/IdentifyDeviceWebSocketContainer';

import IdentifyHelpText from './IdentifyHelpText';
import { TIMEOUT_HELP_MESSAGE } from './constants';
import { useIdentifySensorStyles } from './styles';
import type { IdentifySensorHelpText, IdentifySensorSuccessCallback } from './types';

interface IdentifySensorProps {
    onSuccess: IdentifySensorSuccessCallback;
}

interface IdentifySensorRenderProps {
    isConfirmed: boolean;
    instruction: string;
    isConnected: boolean;
    onTryAgain: () => void;
    helpText?: IdentifySensorHelpText;
}

const IdentifySensor = ({ onSuccess }: IdentifySensorProps) => {
    const styles = useIdentifySensorStyles();

    return (
        <IdentifyDeviceWebSocketContainer
            timeoutHelpMessage={TIMEOUT_HELP_MESSAGE}
            onSuccess={onSuccess}
            render={({
                instruction,
                onTryAgain,
                isConfirmed,
                isConnected,
                helpText,
            }: IdentifySensorRenderProps) => (
                <>
                    {instruction && (
                        <Typography
                            variant="body1"
                            className={styles.instruction}
                            data-cypress="installation"
                        >
                            {instruction}
                        </Typography>
                    )}
                    <IdentifyHelpText
                        helpText={helpText}
                        isConfirmed={isConfirmed}
                        isConnected={isConnected}
                        onTryAgain={onTryAgain}
                    />
                </>
            )}
        />
    );
};

export default memo(IdentifySensor);
