import { Icon, Typography } from '@material-ui/core';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import LastUpdatedAt from '../LastUpdatedAt';
import { getAvailabilityClassname, getLargeIconClassname } from '../utils';
import { useLeftCard } from './styles';

const LeftCard = ({ configuration, nearCapacity, outOfCapacity, layout }) => {
    const { t } = useTranslation('dashboard');
    const styles = useLeftCard({ layout });

    const { id: widgetId, showOccupancy, titleText, occupancyText } = configuration;

    const availabilityPrimaryBackgroundClassnames = getAvailabilityClassname(
        styles,
        nearCapacity,
        outOfCapacity,
        'primary',
        'Background',
    );
    const leftCardClassnames = classnames(
        styles.leftCard,
        availabilityPrimaryBackgroundClassnames,
    );
    const iconClassnames = classnames(
        styles.largeIconContainer,
        styles.goodAvailabilityIcon,
        getLargeIconClassname(nearCapacity, outOfCapacity),
    );

    return (
        <div className={leftCardClassnames}>
            <Icon className={iconClassnames} />
            <Typography className={styles.titleText} variant="h6">
                {t(titleText)}
            </Typography>
            <Typography className={styles.occupancyText} variant="h6">
                {showOccupancy && t(occupancyText)}
            </Typography>
            <Typography className={styles.recommendedActionsText} variant="body1">
                <LastUpdatedAt widgetId={widgetId} />
            </Typography>
        </div>
    );
};

LeftCard.propTypes = {
    configuration: PropTypes.shape({
        id: PropTypes.number.isRequired,
        configured: PropTypes.bool.isRequired,
        occupancyText: PropTypes.string.isRequired,
        showOccupancy: PropTypes.bool.isRequired,
        titleText: PropTypes.string.isRequired,
    }).isRequired,
    nearCapacity: PropTypes.bool.isRequired,
    outOfCapacity: PropTypes.bool.isRequired,
    layout: PropTypes.shape({}).isRequired,
};

export default memo(LeftCard);
