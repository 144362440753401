import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useBuildingDetailsStyles = makeStyles((theme) => ({
    header: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
        marginTop: '5px',
    },
    linkLabel: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        display: 'inline',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    workingHoursInput: {
        margin: theme.spacing(1, 0, 1, 0),
    },
    capacityText: {
        margin: theme.spacing(1, 0, 2, 0),
    },
}));
