import { memo } from 'react';
import type { RouteConfig } from 'react-router-config';
import { renderRoutes } from 'react-router-config';

interface RenderChildrenProps {
    route?: RouteConfig;
    routes?: RouteConfig[];
}

const RenderChildren = ({ route, routes }: RenderChildrenProps) => {
    if (!route && !routes) {
        throw new Error('RenderChildren Requires one of [route, routes]');
    }

    if (routes && routes.length) {
        return renderRoutes(routes);
    }

    if (route && route.routes) {
        return renderRoutes(route.routes);
    }

    return null;
};

export default memo(RenderChildren);
