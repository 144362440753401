import { setSensorsInPages } from '../accessors';

export const pollingSensorsEventsOnFetch = (data, queryKey) => {
    const sensorEventsMap = data.reduce(
        (acc, sensorEvent) => ({ ...acc, [sensorEvent.device_name]: sensorEvent }),
        {},
    );

    setSensorsInPages(sensorEventsMap, queryKey);
};
