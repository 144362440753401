import type {
    SensorEventShape,
    SensorEventsV2,
    SensorEventV2Shape,
} from '@infogrid/sensors-constants';

/**
 * SensorLastReadingTransition needs to figure out if we have recently received a new event.
 * We use the timestamp to determine this.
 */
export const getLatestEventTimestamp = (
    event: SensorEventShape | SensorEventV2Shape | SensorEventsV2 | undefined,
): number => {
    if (!event) {
        return 0;
    }

    if ('timestamp' in event && event.timestamp) {
        const latestEventDate = event.timestamp;

        return new Date(latestEventDate).getTime();
    }

    let latestEventTimestamp = 0;

    // If the event does not have an ID field, it is a compound event (e.g. for Air Quality)
    // and the IDs are contained inside the object. See AirQualitySensorEventsV2Shape.
    if (!!event && !latestEventTimestamp) {
        Object.entries(event).forEach(([_0, value]) => {
            const eventTimestamp = new Date(value?.timestamp).getTime();

            if (eventTimestamp > latestEventTimestamp) {
                latestEventTimestamp = eventTimestamp;
            }
        });
    }

    return latestEventTimestamp;
};
