import {
    wrapResponsePromise,
    INFINITE_QUERY_DEFAULT_INITIAL_DATA,
} from '@infogrid/core-api';
import { useAppSelector, useAppDispatch, toastError } from '@infogrid/core-ducks';
import { selectOrganizationId } from '@infogrid/user-ducks';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';

import { controllers } from 'apiHooks/labels/controllers';
import { getFullLabelsKey } from 'apiHooks/labels/getQueryKeys';
import { selectDataWithPlainLabels } from 'apiHooks/labels/selectors';

export const useLabels = (params) => {
    const dispatch = useAppDispatch();
    const organizationId = useAppSelector(selectOrganizationId);
    const filter = params?.search ? { search: params?.search } : {};
    const { t } = useTranslation();

    return useInfiniteQuery(
        getFullLabelsKey(params?.search),
        ({ signal, pageParam = {} }) =>
            wrapResponsePromise(
                controllers.getLabels(organizationId, signal, {
                    params: { ...filter, ...pageParam },
                }),
                ({ data }) => ({
                    labels: data?.results,
                    next: data?.next,
                    previous: data?.previous,
                }),
            ),
        {
            onError: () => {
                dispatch(toastError({ message: t('Loading Error') }));
            },
            keepPreviousData: true,
            initialData: INFINITE_QUERY_DEFAULT_INITIAL_DATA,
            getNextPageParam: (lastPage) => lastPage?.next,
            select: selectDataWithPlainLabels,
        },
    );
};
