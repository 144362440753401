import { toastSuccess, toastError } from '@infogrid/core-ducks';
import { i18n } from '@infogrid/utils-i18n';
import {
    createSaveAction,
    createFormSaveSaga,
    formErrorsHandler,
} from '@thorgate/spa-forms';
import { takeLatest, put, call } from 'redux-saga/effects';

import api from 'services/api';

/**
 * Trigger Save Profile worker saga.
 * @param {Object} payload Payload to send to server
 * @param {Object} formikMeta - Formik bag
 * @returns {{type: string, payload: *, meta: *}} Created trigger action
 */
export const saveProfile = createSaveAction('sagas/account/SAVE_PROFILE');

function* successHook(_0, _1, { payload: data }) {
    const {
        data: { language_preference: newLang },
    } = data;

    if (!!newLang) {
        const changeLanguage = i18n.changeLanguage.bind(i18n);

        yield call(changeLanguage, newLang);
    }

    yield put(toastSuccess({ message: `${i18n.t('Profile saved')}` }));
}

function* errorHook(options) {
    yield call(formErrorsHandler, options);
    yield put(toastError({ message: i18n.t('Error saving profile') }));
}

const handleFormSave = createFormSaveSaga({
    method: 'put',
    resource: api.user.details,
    successHook,
    errorHook,
});

export default function* saveProfileWatcherSaga(match) {
    yield takeLatest(saveProfile.getType(), handleFormSave, match);
}
