import { makeStyles } from '@material-ui/core';

export const useWidgetStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.shell}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
    },
}));
