import { TIME_RANGE_OPTIONS, createFromToQuery } from '@infogrid/utils-dates';
import { select } from 'redux-saga/effects';

import {
    prependMessageToEventLog,
    invalidateEventLog,
} from 'apiHooks/eventLog/accessors';
import { selectTimeRange, TimeRangeNames } from 'ducks/timeRange';

export function* eventLogEventsWorker({ eventLogEvent, deviceName, created }) {
    if (created) {
        const {
            from = null,
            to = null,
            range = TIME_RANGE_OPTIONS.LAST_MONTH,
        } = yield select(selectTimeRange, TimeRangeNames.EventLog);

        const fromToQuery = yield createFromToQuery({ to, from, range });

        prependMessageToEventLog(eventLogEvent, deviceName, fromToQuery);
    } else {
        invalidateEventLog(deviceName);
    }
}
