import { makeStyles } from '@material-ui/core';

const BROWN = '#5F2B01';

export const useTemperatureOffsetConfigurationStyles = makeStyles((theme) => ({
    helpLink: {
        marginBottom: theme.spacing(3.5),
        cursor: 'pointer',
    },
    input: {
        marginBottom: theme.spacing(2.5),
    },
    datePicker: {
        marginRight: theme.spacing(2.5),
    },
    temperature: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        width: `calc(50% - ${theme.spacing(1.25)}px)`,
    },
}));

export const useCustomPickerClasses = makeStyles((theme) => ({
    root: {
        width: `calc(50% - ${theme.spacing(1.25)}px)`,
    },
    temperatureWarning: {
        '& span': {
            color: BROWN,
        },
        marginTop: '24px',
        visibility: 'hidden',
    },
    unhide: {
        visibility: 'visible',
    },
}));
