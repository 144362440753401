import { makeStyles } from '@material-ui/core';

export const useOccupancyStoplightChart = makeStyles(() => ({
    container: {
        flexWrap: 'nowrap',
        height: '100%',
        overflow: 'hidden',
        borderRadius: '4px',
    },
}));
