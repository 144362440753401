import { makeStyles } from '@material-ui/core';

interface ElectricityMonitoringConfigurationStyleProps {
    isMobileView?: boolean;
}

export const useElectricityMonitoringConfigurationStyles = makeStyles((theme) => ({
    info: {
        marginBottom: theme.spacing(3),
        marginTop: ({ isMobileView }: ElectricityMonitoringConfigurationStyleProps) =>
            isMobileView ? theme.spacing(1.5) : 0,
    },
    input: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
}));
