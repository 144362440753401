import { DropdownFilter } from '@infogrid/components-material-ui';
import { useSpaceTypes } from '@infogrid/locations-api';
import { selectSpaceTypes } from '@infogrid/locations-ducks';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import {
    SPACE_PARAM_NAMES,
    onSpaceSearchPath,
    useFormattedSpaceQueryParams,
} from 'utils/filtering/space';

const SpaceTypeFilter = () => {
    const location = useLocation();
    const history = useHistory();

    const { t } = useTranslation();

    const { space_types: spaceTypesFromQuery } = useFormattedSpaceQueryParams();

    const { data: spaceTypesGroups } = useSpaceTypes();

    const spaceTypes = useMemo(
        () => selectSpaceTypes(spaceTypesGroups),
        [spaceTypesGroups],
    );

    const onSearch = useCallback(
        (attributes) => {
            history.push(
                onSpaceSearchPath(location, {
                    [SPACE_PARAM_NAMES.TYPE]: attributes.join(','),
                }),
            );
        },
        [history, location],
    );

    return (
        <DropdownFilter
            allItemLabel={t('All Space Types')}
            dataCypress="space-type-filter"
            items={spaceTypes}
            itemsFromQuery={spaceTypesFromQuery}
            label={t('Space Type')}
            onSearch={onSearch}
        />
    );
};

export default memo(SpaceTypeFilter);
