import { queryClient } from '@infogrid/core-api';
import sortBy from 'lodash/sortBy';

import {
    getAlertConfigurationKey,
    getAlertConfigurationsKey,
    getFullAlertConfigurationsKey,
} from 'apiHooks/alertConfigurations/getQueryKeys';
import type { AlertConfiguration } from 'components/CustomAlertBuilder/configuration/types';

export const invalidateAlertConfigurations = (
    filter: Record<string, unknown>,
): Promise<void> => queryClient.invalidateQueries(getFullAlertConfigurationsKey(filter));

export const removeAlertConfigurationQuery = (id: string): void =>
    queryClient.removeQueries(getAlertConfigurationKey(id));

export const cleanInactiveAlertConfigurationsQuery = (): void =>
    queryClient.removeQueries(getAlertConfigurationsKey(), {
        active: false,
        inactive: true,
    });

export const mutateAlertConfiguration = (
    configurations: AlertConfiguration,
): AlertConfiguration => {
    const alertConfigurationApplications = (
        configurations?.alert_configuration_applications ?? []
    ).map((application) => ({
        ...application,
        day_time_configurations: sortBy(application?.day_time_configurations, 'weekday'),
    }));

    return {
        ...configurations,
        alert_configuration_applications: alertConfigurationApplications,
    };
};
