import { registerTranslationKey } from '@infogrid/utils-i18n';
import type { FormikProps } from 'formik';
import { memo } from 'react';

import Container from 'views/dashboards/widgets/common/DisplayOptions/Container';
import DataFrequency from 'views/dashboards/widgets/common/DisplayOptions/DataFrequency';

import { useDisplayOptionsStyles } from './styles';

const NOT_APPLICABLE = registerTranslationKey('Not applicable for this widget', {
    ns: 'dashboard',
});

const DisabledOption = {
    label: NOT_APPLICABLE,
    value: 'not_applicable',
};

const frequencies = [DisabledOption];

interface Props {
    // TODO change unknown to real type when rewrite dashboards
    formik: FormikProps<unknown>;
}

const DisplayOptions = ({ formik }: Props) => {
    const styles = useDisplayOptionsStyles();

    return (
        <Container>
            <DataFrequency
                className={styles.dataFrequency}
                disabled
                frequencies={frequencies}
                handleChange={formik.handleChange}
                value={DisabledOption.value}
            />
        </Container>
    );
};

export default memo(DisplayOptions);
