import type { FloorSensorLocation } from '@infogrid/locations-types';
import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import classNames from 'classnames';
import { memo } from 'react';

import MapSensorIcon from 'components/sensors/LegacySensorIcon';
import useCurrentSensorState from 'utils/hooks/useCurrentSensorState';
import useSensorSignal from 'utils/hooks/useSensorSignal';

import { sensorHasCoordinates } from '../../utils';
import { useSensorIconStyles } from './styles';

interface SensorIconSensor extends GenericSensorShape {
    coordinates?: FloorSensorLocation;
}

interface SensorIconProps {
    dragStartHandler: (dragEvent: React.SyntheticEvent) => void;
    sensor: SensorIconSensor;
    isActive: boolean;
    isDraggable: boolean;
}

const SensorIcon = ({
    dragStartHandler,
    isActive,
    isDraggable,
    sensor,
}: SensorIconProps) => {
    const styles = useSensorIconStyles();

    useSensorSignal(sensor);

    const sensorConfig = useCurrentSensorState(sensor);
    const IconComponent = sensorConfig.getIcon();

    const baseIconState = sensorConfig.getMapIconState();

    const isSensorMapped = sensorHasCoordinates(sensor);

    return (
        <div className={classNames(styles.iconContainer)}>
            <div
                className={styles.icon}
                draggable={isDraggable}
                onDragStart={dragStartHandler}
                data-cypress="sensor-icon"
                role="button"
                tabIndex={0}
            >
                <MapSensorIcon
                    IconComponent={IconComponent}
                    state={isSensorMapped ? baseIconState : 'gray'}
                    mode={isActive ? 'selected' : 'base'}
                    size={40}
                />
            </div>
        </div>
    );
};

export default memo(SensorIcon);
