import { makeStyles } from '@material-ui/core';

export const useSensorItemStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.gray5,
        display: 'flex',
        height: 40,
        marginBottom: theme.spacing(0.5),
        padding: theme.spacing(0, 0.5),
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
    sensorIconWrapper: {
        height: 24,
        width: 24,
    },
    sensorName: {
        fontSize: 14,
        marginLeft: theme.spacing(1),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&, &:hover': {
            color: theme.palette.text.primary,
        },
    },
}));
