import { makeStyles } from '@material-ui/core';

export const useSensorLastReadingStyles = makeStyles((theme) => ({
    divider: {
        margin: theme.spacing(0, 0.5),
    },
    lastReadingTimeTooltip: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    pendingStateWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& > span': {
            color: theme.palette.common.gray6,
        },
    },
}));
