import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useEstateFloorStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexFlow: 'row nowrap',
        flexGrow: 1,
        justifyContent: 'space-between',
        padding: 0,
        [`${theme.breakpoints.down('xs')} and (orientation: landscape)`]: {
            minHeight: '100vh',
        },
        [theme.breakpoints.down('md')]: {
            flexGrow: 'unset',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
    },
    pageNameText: {
        marginLeft: theme.spacing(1),
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('lg')]: {
            fontSize: 20,
            fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
            marginLeft: theme.spacing(2),
        },
    },
    loaderWrapper: {
        position: 'relative',
        width: '100%',
    },
    loader: {
        left: '50%',
        position: 'absolute',
        top: '50%',
    },
    errorView: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: `calc(50% - ${theme.spacing(10)}px)`,
        },
    },
}));
