import { i18n } from '@infogrid/utils-i18n';
import * as Yup from 'yup';

export const FLOOR_FORM_VALIDATION_SCHEMA = {
    validationSchema: Yup.object().shape({
        name: Yup.string()
            .required(
                i18n.t('This field may not be blank.', {
                    ns: 'floorplan',
                }),
            )
            .min(
                3,
                i18n.t('Name should be more 3 symbols', {
                    ns: 'floorplan',
                }),
            ),
    }),
};
