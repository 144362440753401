import loadable from '@loadable/component';
import type { RouteConfig } from 'react-router-config';

import RenderChildren from 'components/RenderChildren';
import { userIsNotAuthenticated } from 'decorators/auth';
import { logoutWatcher } from 'sagas/auth/logoutSaga';
import resetPasswordWatcher from 'sagas/auth/resetPasswordSaga';

const LoginView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-auth-login' */ 'views/auth/pages/login/LoginView'
        ),
);
const LoginWithSSOView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-auth-login-sso' */ 'views/auth/pages/login/LoginWithSSOView'
        ),
);
const LoginWithPortalView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-auth-login-portal' */ 'views/auth/pages/login/LoginWithPortalView'
        ),
);
const ForgotPasswordView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-auth-forgot-password' */ 'views/auth/pages/forgotPassword'
        ),
);
const ResetPasswordView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-auth-reset-password' */ 'views/auth/pages/resetPassword'
        ),
);

const LogoutView = loadable(
    () => import(/* webpackChunkName: 'views-auth-logout' */ 'views/auth/pages/logout'),
);

export const createAuthenticationRoutes = (
    PageNotFoundRoute: RouteConfig,
): RouteConfig => ({
    path: '/auth',
    name: 'auth',
    component: RenderChildren,
    routes: [
        {
            path: '/auth/login',
            exact: true,
            name: 'login',
            pageName: 'Login',
            component: userIsNotAuthenticated(LoginView),
        },
        {
            path: '/auth/sso',
            exact: true,
            name: 'login-sso',
            pageName: 'Login SSO',
            component: userIsNotAuthenticated(LoginWithSSOView),
        },
        {
            path: '/auth/portal/:token',
            exact: true,
            name: 'login-portal',
            pageName: 'Login Portal',
            component: LoginWithPortalView,
        },
        {
            path: '/auth/forgot-password',
            exact: true,
            pageName: 'Forgot Password',
            name: 'forgot-password',
            component: userIsNotAuthenticated(ForgotPasswordView),
        },
        {
            path: '/auth/reset-password/:token',
            exact: true,
            pageName: 'Reset Password',
            name: 'reset-password',
            component: userIsNotAuthenticated(ResetPasswordView),
            watcher: resetPasswordWatcher,
        },
        {
            path: '/auth/logout',
            exact: true,
            name: 'logout',
            component: LogoutView,
            watcher: logoutWatcher,
        },
        PageNotFoundRoute,
    ],
});
