import { MenuList } from '@infogrid/components-material-ui';
import { Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useActionItemStyles } from './styles';

export interface DashboardActionsListProps {
    canDeleteDashboard?: boolean;
    canRenameDashboard?: boolean;
    canShareDashboard?: boolean;
    toggleDeleteModal: (state: boolean) => void;
    toggleRenameModal: (state: boolean) => void;
    toggleShareModal: (state: boolean) => void;
}

const DashboardActionsList = ({
    canDeleteDashboard = false,
    canRenameDashboard = false,
    canShareDashboard = false,
    toggleDeleteModal,
    toggleRenameModal,
    toggleShareModal,
}: DashboardActionsListProps) => {
    const actionItemStyles = useActionItemStyles();
    const { t } = useTranslation('dashboard');

    return (
        <MenuList>
            <MenuList.Item
                disabled={!canRenameDashboard}
                onClick={() => toggleRenameModal(true)}
            >
                <Typography className={actionItemStyles.text}>
                    {t('Rename Dashboard')}
                </Typography>
            </MenuList.Item>

            <MenuList.Item
                disabled={!canShareDashboard}
                onClick={() => toggleShareModal(true)}
            >
                <Typography className={actionItemStyles.text}>
                    {t('Share Dashboard')}
                </Typography>
            </MenuList.Item>

            <MenuList.Item
                disabled={!canDeleteDashboard}
                onClick={() => toggleDeleteModal(true)}
            >
                <Typography className={actionItemStyles.text} color="error">
                    {t('Delete Dashboard')}
                </Typography>
            </MenuList.Item>
        </MenuList>
    );
};

export default memo(DashboardActionsList);
