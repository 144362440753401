import { MenuList } from '@infogrid/components-material-ui';
import { Tooltip, Typography } from '@material-ui/core';
import noop from 'lodash/noop';
import type { ReactNode } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

export interface ConfigurationActionsListProps {
    actions?: ReactNode;
    canUserEdit?: boolean;
    isConfigureWidgetAvailable: boolean;
    isDuplicateDisabled?: boolean;
    isKioskModeAvailable?: boolean;
    onConfigure: () => void;
    onDelete: () => void;
    onDuplicate: () => void;
    onOpenKioskMode?: () => void;
}

const ConfigurationActionsList = ({
    actions,
    canUserEdit = false,
    isConfigureWidgetAvailable,
    isDuplicateDisabled = false,
    isKioskModeAvailable = false,
    onConfigure,
    onDelete,
    onDuplicate,
    onOpenKioskMode = noop,
}: ConfigurationActionsListProps) => {
    const { t } = useTranslation('dashboard');

    const duplicateDisableMessage = t(
        'This widget needs to be configured before you can duplicate it.',
    );

    return (
        <MenuList>
            {isConfigureWidgetAvailable && (
                <MenuList.Item
                    disabled={!canUserEdit}
                    onClick={onConfigure}
                    stopEventPropagation
                    data-testid="configure"
                >
                    <Typography>{t('Configure')}</Typography>
                </MenuList.Item>
            )}
            {isKioskModeAvailable && (
                <MenuList.Item
                    data-testid="kiosk-mode"
                    onClick={onOpenKioskMode}
                    stopEventPropagation
                >
                    <Typography>{t('Full Screen Mode')}</Typography>
                </MenuList.Item>
            )}
            <MenuList.Item
                disabled={isDuplicateDisabled || !canUserEdit}
                onClick={onDuplicate}
                stopEventPropagation
                data-testid="duplicate"
            >
                <Tooltip
                    data-cypress="widget-action-tooltip"
                    title={isDuplicateDisabled ? duplicateDisableMessage : ''}
                >
                    <Typography>{t('Duplicate')}</Typography>
                </Tooltip>
            </MenuList.Item>
            {actions}
            <MenuList.Item
                data-testid="delete"
                disabled={!canUserEdit}
                onClick={onDelete}
                stopEventPropagation
            >
                <Typography color="error">{t('Delete')}</Typography>
            </MenuList.Item>
        </MenuList>
    );
};

export default memo(ConfigurationActionsList);
