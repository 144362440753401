import { useAppSelector } from '@infogrid/core-ducks';
import { getLocation } from 'connected-react-router';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import type QueryParamsManager from './QueryParamsManager';
import { formatSorting } from './parsers';
import { calculateSorting, parseSorting } from './sorting';
import type { OrderBy, OrderByColumn } from './types';

export { DIRECTION } from './consts';

export const useSortableOrderBy = <TParams, TFormattedParams>(
    queryParamsManager: QueryParamsManager<TParams, TFormattedParams>,
    getOrderBy: (search: string) => string | null,
    ascendingFirst?: boolean,
): [OrderBy, (column: OrderByColumn, ascendingFirstOverride?: boolean) => void] => {
    const location = useAppSelector(getLocation);
    const history = useHistory();

    const orderBy = formatSorting(getOrderBy(location.search));

    const changeOrder = useCallback(
        (column: OrderByColumn, ascendingFirstOverride?: boolean) => {
            const newOrderBy = calculateSorting(orderBy, {
                column,
                ascendingFirst: ascendingFirstOverride || ascendingFirst,
            });
            const newFormattedOrderBy = parseSorting(
                newOrderBy?.column ?? null,
                newOrderBy?.direction ?? null,
            );

            history.push(queryParamsManager.onOrder(location, newFormattedOrderBy));
        },
        [history, location, queryParamsManager, orderBy, ascendingFirst],
    );

    return [orderBy, changeOrder];
};
