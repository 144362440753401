import { makeStyles } from '@material-ui/core';

export const useAddWidgetModalStyles = makeStyles((theme) => ({
    spinner: {
        marginRight: theme.spacing(1),
    },
    gridItem: {
        '&:nth-of-type(odd)': {
            padding: theme.spacing(2, 2, 2, 0),
        },
        '&:nth-of-type(even)': {
            padding: theme.spacing(2, 0, 2, 2),
        },
    },
    content: {
        maxHeight: 'calc(100vh - 300px)',
        padding: theme.spacing(0, 3),
    },
}));
