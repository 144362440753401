import {
    Modal,
    InfiniteScrollLoader,
    LoadingPlaceholder,
} from '@infogrid/components-material-ui';
import type { IPagination } from '@infogrid/core-types';
import type { BuildingAtRiskWithFloors } from '@infogrid/remote-monitoring-types';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Typography } from '@material-ui/core';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { InfiniteData } from 'react-query';

import { useAtRiskBuilding } from 'apiHooks/remote_monitoring/hooks';
import { selectBuildingsAtRiskWithFloors } from 'apiHooks/remote_monitoring/selectors';
import WidgetAlert from 'views/dashboards/widgets/common/WidgetAlert';

import type { DialogBuildingData } from '../types';
import { filterSensorsWithFailure } from '../utils';
import BuildingSection from './BuildingSection';
import { useActionNeededDialogStyles } from './styles';

interface ActionNeededDialogProps {
    isOpen?: boolean;
    onCancel: () => void;
    atRiskBuilding: DialogBuildingData;
    timePeriodRange: string;
    pipeMonitoringFailureTypes: string[];
}

const ActionNeededDialog = ({
    isOpen = false,
    onCancel,
    atRiskBuilding,
    timePeriodRange,
    pipeMonitoringFailureTypes,
}: ActionNeededDialogProps) => {
    const styles = useActionNeededDialogStyles();
    const isMobile = useIsMobile();
    const { t } = useTranslation('dashboard');

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchedAfterMount,
        isFetching,
        isFetchingNextPage,
        isError,
    } = useAtRiskBuilding({
        building_id: atRiskBuilding.buildingId,
        time_period_range: timePeriodRange,
        pipe_monitoring_failure_types: pipeMonitoringFailureTypes,
    });

    // Call endpoint until current page has data or pagination is exhausted. Endpoint pages can return empty array
    // because it is paginated by sensor, and not all sensors will fail for the requested failure reason.
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            const pages = data?.pages;
            if (pages && pages[pages.length - 1].results.length === 0) {
                fetchNextPage();
            }
        }
    }, [hasNextPage, fetchNextPage, isFetching, isFetchingNextPage, data]);

    const { results: buildingsAtRisk } = selectBuildingsAtRiskWithFloors({
        data: data as InfiniteData<IPagination<BuildingAtRiskWithFloors>>,
        sensorsFilter: filterSensorsWithFailure,
    });

    const modalDescription = t(`{{count}} {{pipeDisplay}} at risk`, {
        count: atRiskBuilding?.pipes_at_risk,
        pipeDisplay: atRiskBuilding?.pipes_at_risk === 1 ? 'pipe' : 'pipes',
        defaultValue___one: `${atRiskBuilding?.pipes_at_risk} pipe at risk`,
        defaultValue___other: `${atRiskBuilding?.pipes_at_risk} pipes at risk`,
    });

    const hasContent = buildingsAtRisk.length > 0;

    return (
        <Modal
            fullScreen={isMobile}
            fullWidth
            maxWidth="sm"
            onClose={onCancel}
            open={isOpen}
            data-cypress="action-needed-dialog"
        >
            <Modal.Title
                className={styles.modalTitle}
                data-testid="action-needed-dialog-title"
            >
                {atRiskBuilding?.name}
            </Modal.Title>
            <Modal.Description className={styles.modalDescription}>
                {modalDescription}
            </Modal.Description>
            {isError ? (
                <WidgetAlert severity="error" className={styles.error}>
                    {t('Error loading building water safety data.')}
                </WidgetAlert>
            ) : (
                <Modal.Content className={styles.modal}>
                    {isFetching && !hasContent && (
                        <LoadingPlaceholder data-cypress="pipe-monitoring-at-risk-loading-placeholder" />
                    )}
                    <InfiniteScrollLoader
                        hasContent={hasContent}
                        onFetchMore={fetchNextPage}
                        hasLoaded={isFetchedAfterMount}
                        isLoading={isFetchingNextPage}
                        hasNext={hasNextPage}
                        hideLoader={false}
                        emptyState={
                            <Typography className={styles.noContentMessageTitle}>
                                {t('No pipes at risk have been found')}
                            </Typography>
                        }
                    >
                        {buildingsAtRisk.map((building) => (
                            <BuildingSection
                                key={building.name}
                                buildingName={building.name}
                                floors={building.floors}
                            />
                        ))}
                    </InfiniteScrollLoader>
                </Modal.Content>
            )}
        </Modal>
    );
};

export default memo(ActionNeededDialog);
