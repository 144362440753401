import { Modal } from '@infogrid/components-material-ui';
import { useGetSpaceCountByType } from '@infogrid/locations-api';
import { SPACE_TYPE_LABELS } from '@infogrid/solution-views-types';
import type { SpaceTypeId } from '@infogrid/solution-views-types';
import { CircularProgress, Typography, Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import SelectLocationsStage from './stages/SelectLocations';
import SetRules from './stages/SetRules';
import { useStyles, useModalStyles } from './styles';
import { MODAL_STAGES } from './types';
import type {
    BySpaceTypeModalFormValues,
    ModalStage,
    LocationSelectionMethod,
} from './types';
import {
    mapToExistingSpaceTypes,
    selectedSpaceTypesToArray,
    getModalStage,
    isNextbuttonDisabled,
} from './utils';

interface Props {
    open: boolean;
    handleClose: () => void;
}

export const BySpaceTypeModal = ({ open, handleClose }: Props): JSX.Element => {
    const {
        setFieldValue,
        errors,
        handleSubmit,
        resetForm,
        values: {
            might_need_cleaning_value,
            must_be_cleaned_value,
            locations,
            selected_space_types,
            selection_method,
            modal_stage,
        },
    } = useFormikContext<BySpaceTypeModalFormValues>();

    const onClose = () => {
        resetForm();
        handleClose();
    };

    const onSelectMightNeedCleaningValue = useCallback(
        (value: number | string | undefined) => {
            setFieldValue('might_need_cleaning_value', value);
        },
        [setFieldValue],
    );

    const onSelectMustBeCleanedValue = useCallback(
        (value: number | undefined) => {
            setFieldValue('must_be_cleaned_value', value);
        },
        [setFieldValue],
    );

    const onSelectSpaceType = useCallback(
        (value: string) => {
            setFieldValue('selected_space_types', {
                ...selected_space_types,
                [value]: !selected_space_types[value],
            });
        },
        [setFieldValue, selected_space_types],
    );

    const onChangeSelectionMethod = useCallback(
        (selectionMethod: LocationSelectionMethod) => {
            setFieldValue('selection_method', selectionMethod);
        },
        [setFieldValue],
    );

    const onSelectLocations = useCallback(
        (value: number[]) => {
            setFieldValue('locations', value);
        },
        [setFieldValue],
    );

    const onChangeModalStage = useCallback(
        (value: ModalStage) => {
            setFieldValue('modal_stage', value);
        },
        [setFieldValue],
    );

    const { t } = useTranslation('solutions');

    const { data: existingSpaceTypes = [], isFetching: isFetchingSpaceTypes } =
        useGetSpaceCountByType<SpaceTypeId[]>({
            staleTime: 1000 * 60 * 5,
            select: mapToExistingSpaceTypes,
        });

    const styles = useStyles({
        hideStage: modal_stage !== MODAL_STAGES.SELECT_SPACE_TYPES,
    });
    const modalStyles = useModalStyles();

    const areErrors = !errors.might_need_cleaning_value && !errors.must_be_cleaned_value;

    return (
        <Modal
            onClose={onClose}
            disableBackdropClick
            open={open}
            fullscreenMobile
            classes={{ container: modalStyles.container }}
            data-testid="manage-rules-by-space-type-modal"
        >
            <Modal.Title>{t('Set cleaning rule by space type')}</Modal.Title>
            <Modal.Content className={modalStyles.content}>
                {
                    // TODO: potentially break these stages out into smaller components
                    // to improve readability/maintainability
                }
                <div className={styles.stage}>
                    {isFetchingSpaceTypes && (
                        <CircularProgress color="primary" size={30} />
                    )}
                    {!isFetchingSpaceTypes &&
                        !existingSpaceTypes?.length &&
                        t(
                            'There has been an error fetching your estate space types. Please refresh the page and try again.',
                        )}
                    {!isFetchingSpaceTypes && (
                        <>
                            <Typography
                                className={styles.modalDescriptiveText}
                                color="textSecondary"
                                variant="body2"
                            >
                                {t(
                                    'Select the space types to which you’d like to apply a new cleaning rule.',
                                )}
                            </Typography>
                            <Alert
                                className={styles.alert}
                                icon={<InfoIcon fontSize="inherit" />}
                                color="info"
                            >
                                {t(
                                    'Note that this will overwrite any existing rules applied to all the locations you’ve selected.',
                                )}
                            </Alert>
                            {existingSpaceTypes.map((spaceType) => (
                                <div
                                    className={styles.checkboxLabelText}
                                    key={`${spaceType}-checkbox`}
                                >
                                    <Checkbox
                                        data-testid={`bySpaceTypeModal-SpaceType-${spaceType}`}
                                        checked={selected_space_types[spaceType] || false}
                                        onChange={() => onSelectSpaceType(spaceType)}
                                        color="primary"
                                    />
                                    <Typography variant="body1">
                                        {SPACE_TYPE_LABELS[spaceType]}
                                    </Typography>
                                </div>
                            ))}
                        </>
                    )}
                </div>
                <SelectLocationsStage
                    hidden={modal_stage !== MODAL_STAGES.SELECT_RULES_APPLICATION}
                    setSelectedLocations={onSelectLocations}
                    selectionMethod={selection_method}
                    setSelectionMethod={onChangeSelectionMethod}
                />
                <SetRules
                    hidden={modal_stage !== MODAL_STAGES.SET_RULES}
                    mightNeedCleaningValue={might_need_cleaning_value}
                    mustBeCleanedValue={must_be_cleaned_value}
                    setMightNeedCleaningValue={onSelectMightNeedCleaningValue}
                    setMustBeCleanedValue={onSelectMustBeCleanedValue}
                    errors={errors}
                />
            </Modal.Content>
            <Modal.Actions justify="right">
                {modal_stage === MODAL_STAGES.SELECT_SPACE_TYPES && (
                    <Typography
                        className={styles.countText}
                        color="textSecondary"
                        variant="body2"
                    >
                        {t(
                            selectedSpaceTypesToArray(selected_space_types).length === 0
                                ? `No space types selected`
                                : `${
                                      selectedSpaceTypesToArray(selected_space_types)
                                          .length
                                  } space types selected`,
                        )}
                    </Typography>
                )}
                {modal_stage === MODAL_STAGES.SELECT_SPACE_TYPES && (
                    <Button variant="text" color="primary" onClick={onClose}>
                        {t('Cancel')}
                    </Button>
                )}
                {modal_stage !== MODAL_STAGES.SELECT_SPACE_TYPES && (
                    <Button
                        variant="text"
                        color="primary"
                        onClick={() =>
                            onChangeModalStage(getModalStage(modal_stage, 'back'))
                        }
                    >
                        {t('Back')}
                    </Button>
                )}
                {modal_stage !== MODAL_STAGES.SET_RULES && (
                    <Button
                        data-testid="by-space-type-next-button"
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isNextbuttonDisabled({
                            currentStage: modal_stage,
                            selectedSpaceTypes: selected_space_types,
                            selectedLocations: locations,
                        })}
                        onClick={() =>
                            onChangeModalStage(getModalStage(modal_stage, 'next'))
                        }
                    >
                        {t('Next')}
                    </Button>
                )}
                {modal_stage === MODAL_STAGES.SET_RULES && (
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={() => handleSubmit()}
                        disabled={!areErrors}
                    >
                        {t('Save')}
                    </Button>
                )}
            </Modal.Actions>
        </Modal>
    );
};
