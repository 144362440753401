import { makeStyles } from '@material-ui/core';

export const useMoveSensorsModalStyles = makeStyles((theme) => ({
    modalActions: {
        marginTop: 'auto',
    },
    cancelButton: {
        marginRight: theme.spacing(1),
    },
}));
