import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import { memo, useMemo } from 'react';
import type { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';

import { isObjectEmpty } from 'utils/objects';
import OccupancyStoplightChart from 'views/dashboards/widgets/common/Charts/OccupancyStoplightChart';
import {
    getCurrentDisplay,
    prepareBaseStoplightValues,
} from 'views/dashboards/widgets/utils';

import WidgetAlert from '../../common/WidgetAlert';
import type {
    PeopleCountingStoplightData,
    PeopleCountingStoplightWidget,
} from '../types';

interface ContentProps {
    data?: PeopleCountingStoplightData;
    isConfigured: boolean;
    layout: Layout;
    widget: PeopleCountingStoplightWidget;
}

const Content = ({ data, isConfigured, layout, widget }: ContentProps) => {
    const { t } = useTranslation('dashboard');
    const { id: widgetId } = widget;
    const spaceStoplight = widget?.configuration?.calculators?.space_stoplight;
    const { displays, stoplight: stoplightValues } = spaceStoplight;

    const occupancy = data?.occupancy || 0;
    const capacity = Math.max(
        displays?.greenScreen?.highLimit || 0,
        displays?.yellowScreen?.highLimit || 0,
        displays?.redScreen?.highLimit || 0,
    );

    const nearCapacity = occupancy >= (displays?.yellowScreen?.lowLimit || 0);
    const outOfCapacity = occupancy >= (displays?.redScreen?.lowLimit || 0);

    const currentDisplay = getCurrentDisplay(displays, nearCapacity, outOfCapacity);

    const { message: titleText, showOccupancy } = currentDisplay;

    const occupancyText = t('Current room occupancy: {{occupancy}}/{{capacity}}', {
        occupancy,
        capacity,
        defaultValue: `Current room occupancy: ${occupancy}/${capacity}`,
    });

    const stoplight = useMemo(
        () => stoplightValues || prepareBaseStoplightValues(),
        [stoplightValues],
    );

    const configuration = {
        id: widgetId,
        configured: !isObjectEmpty(currentDisplay),
        occupancyText,
        showOccupancy,
        stoplight,
        titleText,
    };

    if (!isConfigured) {
        return (
            <WidgetAlert severity="warning">
                {t(WidgetErrorMessages.notConfigured)}
            </WidgetAlert>
        );
    }

    return (
        <OccupancyStoplightChart
            configuration={configuration}
            nearCapacity={nearCapacity}
            outOfCapacity={outOfCapacity}
            layout={layout}
        />
    );
};

export default memo(Content);
