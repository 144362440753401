import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import type { LocationsCount } from 'views/solutionSettings/pages/smartCleaning/utils/selectionUtils';

import { useLocationSelectorCounterStyles } from './styles';

interface LocationsSelectorCounterProps {
    domain: string;
    count: LocationsCount;
    editSelectedCallback: () => void;
}

export const LocationsSelectorCounter = ({
    domain,
    count,
    editSelectedCallback,
}: LocationsSelectorCounterProps): JSX.Element => {
    const styles = useLocationSelectorCounterStyles();
    const { t } = useTranslation('solutions');

    return (
        <footer className={styles.container}>
            <Typography
                className={styles.countText}
                color="textSecondary"
                variant="body2"
            >
                {t(
                    count.floors === 0
                        ? `No ${domain}s selected`
                        : `${count.spaces} spaces (in ${count.floors} floors in ${count.buildings} buildings)`,
                )}
            </Typography>
            <Button
                type="submit"
                disabled={count.spaces === 0}
                color="primary"
                variant="contained"
                onClick={editSelectedCallback}
            >
                {t('Edit Selected')}
            </Button>
        </footer>
    );
};
