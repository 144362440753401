import { useAppSelector } from '@infogrid/core-ducks';
import { getLocation } from 'connected-react-router';
import type { Location, LocationDescriptorObject } from 'history';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useMemo } from 'react';

import { useSortableOrderBy } from '..';
import type { OrderByColumn, OrderBy } from '../types';
import { sensorQueryParamsManager } from './config';
import { SENSOR_FILTERS } from './consts';
import type { SensorQueryParams, FormattedSensorQueryParams } from './types';

export * from './types';
export * from './consts';

export const useSensorQueryBuilder = (initialQuery = {}): SensorQueryParams => {
    const location = useAppSelector(getLocation);

    const query = sensorQueryParamsManager.buildQuery(location?.search, initialQuery);

    // if not only sorting is applied, pass recursive param
    // to fire search in subfolders as well
    if (!isEmpty(pick(query, Object.values(SENSOR_FILTERS)))) {
        query.recursive = true;
    }

    return query;
};

export const useFormattedSensorQueryParams = (): FormattedSensorQueryParams => {
    const location = useAppSelector(getLocation);

    return useMemo(
        () => sensorQueryParamsManager.formatQueryParams(location.search),
        [location.search],
    );
};

const getOrderBy = (search: string) => {
    const { order_by: ordering } = sensorQueryParamsManager.buildQuery(search);

    return ordering ?? null;
};

export const useSensorSortableOrderBy = (): [
    OrderBy,
    (column: OrderByColumn) => void,
] => {
    return useSortableOrderBy(sensorQueryParamsManager, getOrderBy);
};

export const onSensorSearchPath = sensorQueryParamsManager.onSearch;

export const onSensorClearFilters = (location: Location): LocationDescriptorObject =>
    sensorQueryParamsManager.onSearch(location, {
        q: '',
        location: [],
        sensor_type: [],
        sensor_labels: [],
        sensor_labels_or: [],
    });

/**
 * Return `true` if any filter is applied

 */
export const useIsAnySensorFilterApplied = (): boolean => {
    const location = useAppSelector(getLocation);

    const params = sensorQueryParamsManager.formatQueryParams(location?.search);

    return !isEmpty(pick(params, Object.values(SENSOR_FILTERS)));
};
