import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { fludiaConfigurationFormValidationSchema } from './fludiaConfigurationFormValidationSchema';
import type { FludiaConfigurationFormValues } from './types';

export const useFludiaConfigurationFormik = ({
    onSubmit,
}: {
    onSubmit: (formValues: FludiaConfigurationFormValues) => void;
}): FormikContextType<FludiaConfigurationFormValues> => {
    const { t } = useTranslation('sensors');

    const initialValues: FludiaConfigurationFormValues = {
        meter_number: undefined,
        initial_meter_reading: undefined,
    };

    return useFormik<FludiaConfigurationFormValues>({
        initialValues,
        enableReinitialize: true,
        validationSchema: fludiaConfigurationFormValidationSchema(t),
        onSubmit,
    });
};
