import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import type { FontWeightProperty } from 'csstype';

export const useTutorialModalStyles = makeStyles((theme: Theme) => ({
    modalContent: {
        overflow: 'auto',
        padding: theme.spacing(2, 6),
    },

    header: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(2),
        fontWeight: theme.typography.fontWeightRegular as FontWeightProperty,
    },

    section: {
        '& > p': {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
    },

    framedContent: {
        border: `1px solid ${theme.palette.common.gray7}`,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    subtitle: {
        fontWeight: theme.typography.fontWeightRegular as FontWeightProperty,
        marginBottom: theme.spacing(1),
    },

    description: {
        marginBottom: theme.spacing(4),
    },

    scoreDescription: {
        marginBottom: theme.spacing(2),
    },

    risksList: {
        display: 'flex',
        justifyContent: 'space-around',
        listStyleType: 'none',
        margin: theme.spacing(4, 'auto', 2),
        padding: 0,
        maxWidth: '440px',
    },

    riskLevelContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: 50,
    },

    riskLevel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 26,
        height: 26,
        borderRadius: '50%',
        color: theme.palette.common.white,
        marginBottom: theme.spacing(1),
    },
}));
