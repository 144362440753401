import { createRequest } from '@infogrid/core-api';
import type { AxiosPromise } from 'axios';

import type { BuildingsUserActionsResponse } from './types';

const getBuildingsUserActions = (): AxiosPromise<BuildingsUserActionsResponse> =>
    createRequest({
        method: 'get',
        url: 'floorplan/buildings/user-actions',
    });

export const controllers = { getBuildingsUserActions };
