import { createRequest } from '@infogrid/core-api';
import type { IPagination } from '@infogrid/core-types';
import type {
    CreateFeedbackPanelPayload,
    FeedbackPanelTemplate,
    SensorShape,
} from '@infogrid/sensors-constants';
import type { AxiosPromise } from 'axios';

const getFeedbackPanelTemplates = (
    signal: AbortSignal | undefined,
    params?: {
        [key: string]: unknown;
    },
): AxiosPromise<IPagination<FeedbackPanelTemplate>> =>
    createRequest({
        method: 'get',
        url: `feedback-panel-templates`,
        signal,
        options: { params },
    });

const createFeedbackPanel = (
    data: CreateFeedbackPanelPayload,
): AxiosPromise<SensorShape> =>
    createRequest({
        method: 'post',
        url: `sensors/virtual/feedback-panel`,
        options: { data },
    });

export const controllers = {
    getFeedbackPanelTemplates,
    createFeedbackPanel,
};
