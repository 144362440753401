import { isString } from '../is';
import { ResourceError } from './ResourceError';

function truncate(value, limit) {
    if (!value || value.length < limit) {
        return value;
    }

    let truncatedValue = value;

    if (!isString(value)) {
        truncatedValue = `${value}`;
    }

    return `${truncatedValue.substring(0, limit - 3)}...`;
}

export class InvalidResponseCode extends ResourceError {
    constructor(
        statusCode = null,
        responseText,
        statusText,
        type = 'InvalidResponseCode',
    ) {
        super(`${type} ${statusCode}: ${truncate(statusText, 256)}`);
        this.statusCode = statusCode;
        this.responseText = responseText;

        this.isInvalidResponseCode = true;
    }
}
