import random from 'lodash/random';
import type { CSSProperties } from 'react';
import { memo, useRef, useMemo, useState, useCallback } from 'react';
import { usePrevious, useUpdateEffect } from 'react-use';

import { useLoadingBarStyle } from './style';

export interface LoadingBarProps {
    isLoading?: boolean;
    simulate?: boolean;
    hideDelay?: number;
    simulateInterval?: number;
    className?: string;
    style?: CSSProperties;
}

const isBetween = (num: number, lower: number, upper: number): boolean =>
    num >= lower && num < upper;

/**
 * Progress here is a value between 0 and 1
 */
function calculateVisibleProgress(oldVal: number) {
    let rand = 0;

    if (isBetween(oldVal, 0, 0.25)) {
        rand = random(0.12);
    } else if (isBetween(oldVal, 0.25, 0.65)) {
        rand = random(0.03);
    } else if (isBetween(oldVal, 0.65, 0.9)) {
        rand = random(0.02);
    } else if (isBetween(oldVal, 0.9, 0.99)) {
        // When between 65% and less than 90% always use 0.5%
        rand = 0.005;
    }

    // New percent will be combination of previous and generated random value
    return oldVal + rand;
}

const LoadingBar = ({
    style,
    className,
    hideDelay = 500,
    isLoading = false,
    simulate = false,
    simulateInterval = 500,
    ...props
}: LoadingBarProps): JSX.Element => {
    const [progress, setProgress] = useState(0);
    const [delayShow, setDelayShow] = useState(false);
    const [delayHide, setDelayHide] = useState(false);
    const loadingBarStyle = useLoadingBarStyle();
    const prevLoading = usePrevious(isLoading);

    // use ReturnType<typeof setInterval> to make sure that the types line up with
    // our use case
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

    const loadingBarStyles = useMemo(() => {
        return {
            ...style,
            width: delayShow ? 0 : `${progress * 100}%`,
            display: delayHide || progress > 0 ? 'block' : 'none',
        };
    }, [delayHide, delayShow, progress, style]);

    const show = useCallback(() => {
        const oldProgress = progress;

        setProgress(calculateVisibleProgress);

        if (oldProgress === 0) {
            setDelayShow(true);
            // Clear the delay flag
            setTimeout(() => {
                setDelayShow(false);
            });
        }
    }, [progress]);

    const hide = useCallback(() => {
        setDelayHide(true);
        setProgress(1);

        setTimeout(() => {
            setDelayHide(false);
            setProgress(0);
        }, hideDelay);
    }, [hideDelay]);

    const stopSimulate = useCallback(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }, []);

    const startSimulate = useCallback(() => {
        if (simulateInterval > 0) {
            stopSimulate();

            intervalRef.current = setInterval(show, simulateInterval);
        }
    }, [show, simulateInterval, stopSimulate]);

    useUpdateEffect(() => {
        if (prevLoading !== isLoading) {
            if (isLoading) {
                show();

                if (simulate) {
                    startSimulate();
                }
            } else {
                hide();
                stopSimulate();
            }
        }

        return () => stopSimulate();
    }, [hide, isLoading, show, simulate, startSimulate, stopSimulate]);

    return (
        <div
            // to allow testid passthrough
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            className={`${loadingBarStyle.root} ${className ?? ''}`}
            style={loadingBarStyles}
        />
    );
};

export default memo(LoadingBar);
