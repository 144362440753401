import { useMutationWithToast } from '@infogrid/core-api';
import type { AxiosResponse } from 'axios';
import { isNumber } from 'is-what';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import { controllers } from '../controllers';
import type {
    CreateBuildingParams,
    EditBuildingParams,
    EditBuildingResponse,
} from '../types';

interface UseEditBuildingProps {
    toastMessage?: string;
}
interface EditBuildingMutateProps<ID extends number | undefined> {
    buildingId: ID;
    data: ID extends number ? EditBuildingParams : CreateBuildingParams;
}

interface EditBuildingError {
    parsedError: {
        message: string;
        errors?: {
            [key: string]: unknown;
        };
    };
    response?: {
        data?: {
            error?: string;
        };
    };
}

export const useEditOrCreateBuilding = <ID extends number | undefined>(
    props?: UseEditBuildingProps,
): UseMutationResult<
    AxiosResponse<EditBuildingResponse>,
    EditBuildingError,
    EditBuildingMutateProps<ID>
> => {
    const { t } = useTranslation('floorplan');

    return useMutationWithToast(
        ({ buildingId, data }: EditBuildingMutateProps<ID>) =>
            isNumber(buildingId)
                ? controllers.editBuilding(buildingId, data)
                : controllers.createBuilding(data as CreateBuildingParams),
        {},
        {
            toastMessage: props?.toastMessage || t('Updating a building is in progress'),
        },
    );
};
