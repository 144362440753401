import { queryClient } from '@infogrid/core-api';
import head from 'lodash/head';
import tail from 'lodash/tail';

import { mutateEventLog } from 'utils/eventLog';

import { getEventLogKey, getFullEventLogKey } from './getQueryKeys';

const addResultToTop = (old, data, params) => {
    if (!old) {
        return old;
    }

    const firstPage = head(old.pages) || { events: [] };
    const events = [mutateEventLog(data, params), ...firstPage.events];
    const pages = [{ ...firstPage, events }, ...tail(old.pages)];

    return { ...old, pages };
};

export const prependMessageToEventLog = (eventLogEvent, deviceName, fromToQuery) =>
    queryClient.setQueryData(getFullEventLogKey(deviceName, fromToQuery), (old) =>
        addResultToTop(old, eventLogEvent, { params: { deviceName } }),
    );

export const invalidateEventLog = (deviceName) =>
    queryClient.invalidateQueries(getEventLogKey(deviceName));
