import classNames from 'classnames';
import type { ReactNode } from 'react';

import WidgetAction from './common/WidgetAction';
import WidgetActions from './common/WidgetActions';
import WidgetBar from './common/WidgetBar';
import WidgetContent from './common/WidgetContent';
import WidgetDetail from './common/WidgetDetail';
import WidgetDetails from './common/WidgetDetails';
import WidgetFooter from './common/WidgetFooter';
import WidgetOption from './common/WidgetOption';
import WidgetOptions from './common/WidgetOptions';
import WidgetTitle from './common/WidgetTitle';
import WidgetTitleIcon from './common/WidgetTitleIcon';
import { useWidgetStyles } from './styles';

interface WidgetProps {
    children: ReactNode;
    className?: string;
}

const Widget = ({ children, className }: WidgetProps): JSX.Element => {
    const styles = useWidgetStyles();

    return <div className={classNames(styles.container, className)}>{children}</div>;
};

Widget.Action = WidgetAction;
Widget.Actions = WidgetActions;
Widget.Bar = WidgetBar;
Widget.Content = WidgetContent;
Widget.Detail = WidgetDetail;
Widget.Details = WidgetDetails;
Widget.Footer = WidgetFooter;
Widget.Option = WidgetOption;
Widget.Options = WidgetOptions;
Widget.Title = WidgetTitle;
Widget.TitleIcon = WidgetTitleIcon;

export default Widget;
