import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { EventsWebhook, AxiosParsedError } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import updateSingleEventsWebhookData from 'apiHooks/eventsWebhook/hooks/updateSingleEventsWebhookData';

import { controllers } from '../controllers';
import type { CreateEventsWebhookPayload } from '../types';

const useCreateEventsWebhook = (): UseMutationResult<
    EventsWebhook,
    AxiosParsedError,
    CreateEventsWebhookPayload
> => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('integrations');

    return useMutationWithToast<
        EventsWebhook,
        AxiosParsedError,
        CreateEventsWebhookPayload
    >(
        (data: CreateEventsWebhookPayload) =>
            wrapResponsePromise(controllers.createEventsWebhook(data)),
        {
            onSuccess: (data: EventsWebhook) => {
                updateSingleEventsWebhookData(data);
                dispatch(toastSuccess({ message: t('Events webhook created') }));
            },
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error creating events webhook'),
                    }),
                );
            },
        },
        {
            toastMessage: t('Creation of events webhook is in progress'),
        },
    );
};

export default useCreateEventsWebhook;
