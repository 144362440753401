import { registerTranslationKey } from '@infogrid/utils-i18n';
import type { RouteConfig } from 'react-router-config';

import { userIsAuthenticated, userIsManager } from 'decorators/auth';
import RootIntegrations from 'views/integrations/pages/integrations';
import IntegrationsCorrigo from 'views/integrations/pages/integrationsCorrigo';
import IntegrationsEventsWebhook from 'views/integrations/pages/integrationsEventsWebhook';
import IntegrationsIndex from 'views/integrations/pages/integrationsIndex';

export const createIntegrationsRoutes = (
    PageNotFoundRoute: RouteConfig,
): RouteConfig => ({
    path: '/settings/integrations',
    name: 'integrations',
    component: userIsAuthenticated(RootIntegrations),
    routes: [
        {
            path: '/settings/integrations',
            exact: true,
            name: 'integrations',
            pageName: registerTranslationKey('Select an integration to configure', {
                ns: 'integrations',
            }),
            component: userIsManager(IntegrationsIndex),
        },
        {
            path: '/settings/integrations/events-webhook',
            exact: true,
            name: 'integrations-events-webhook',
            pageName: registerTranslationKey('Event Webhooks', {
                ns: 'integrations',
            }),
            component: userIsManager(IntegrationsEventsWebhook),
        },
        {
            path: '/settings/integrations/corrigo',
            exact: true,
            name: 'integrations-corrigo',
            pageName: registerTranslationKey('Corrigo', {
                ns: 'integrations',
            }),
            component: userIsManager(IntegrationsCorrigo),
        },
        PageNotFoundRoute,
    ],
});
