import { useAppDispatch } from '@infogrid/core-ducks';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { Button } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { downloadFile } from 'sagas/application/signedDownloads';
import { trackReportsUserEvent } from 'views/reports/analytics';
import { getReportDownloadHref } from 'views/reports/utils';

export interface ReportDownloadButtonProps {
    id: number;
}

const ReportDownloadButton = ({ id }: ReportDownloadButtonProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('reports');

    const downloadHref = getReportDownloadHref(id);

    const onDownload = useCallback(() => {
        trackReportsUserEvent(USER_ACTIONS.PRESSED, 'download report cta toast');
        dispatch(downloadFile(downloadHref));
    }, [dispatch, downloadHref]);

    return (
        <Button
            color="inherit"
            onClick={onDownload}
            size="small"
            startIcon={<ArrowDownwardIcon />}
            variant="outlined"
        >
            {t('Download')}
        </Button>
    );
};

export default ReportDownloadButton;
