import { useAppSelector } from '@infogrid/core-ducks';
import { getUser } from '@infogrid/user-ducks';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Formik } from 'formik';
import { memo, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { getSmartCleaningReportsKey } from 'views/solutionSettings/apiHooks/getQueryKeys';
import type { SmartCleaningReport } from 'views/solutionSettings/apiHooks/types';
import { useCreateSmartCleaningReport } from 'views/solutionSettings/apiHooks/useCreateSmartCleaningReport';
import { useDeleteSmartCleaningReport } from 'views/solutionSettings/apiHooks/useDeleteSmartCleaningReport';
import { useUpdateSmartCleaningReport } from 'views/solutionSettings/apiHooks/useUpdateSmartCleaningReport';

import type { FloorplanPageConfiguration } from '../../types';
import ReportConfigurationForm from './ReportConfigurationForm.container';

const BLANK_FIELD_ERROR = registerTranslationKey('This field may not be blank.', {
    ns: 'reports',
});

export const REPORT_CONFIG_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required(BLANK_FIELD_ERROR),
    language: Yup.string().nullable(),
    emailReportAt: Yup.string().required(BLANK_FIELD_ERROR),
    emailRecipients: Yup.array()
        .of(
            Yup.string().email(
                registerTranslationKey('Enter a valid email address.', { ns: 'reports' }),
            ),
        )
        .min(1, BLANK_FIELD_ERROR),
    showSensorList: Yup.boolean(),
    pageSize: Yup.mixed().oneOf(['us_letter', 'a4']).required(BLANK_FIELD_ERROR),
    floorplanPageConfiguration: Yup.mixed()
        .oneOf(['one_page', 'multiple_pages'])
        .required(BLANK_FIELD_ERROR),
    buildingId: Yup.number().required(BLANK_FIELD_ERROR),
    floorIds: Yup.array().of(Yup.number()).required(BLANK_FIELD_ERROR),
    disabled: Yup.boolean(),
    sensorIconScale: Yup.number().required(BLANK_FIELD_ERROR),
    zoom: Yup.number().required(BLANK_FIELD_ERROR),
});

const DEFAULT_SEND_TIME = '19:00';

const getTimeWithoutMilliseconds = (time: string) => time.slice(0, 5);

interface Props {
    onClose: () => void;
    open: boolean;
    previousReport?: SmartCleaningReport;
    buildingId?: number;
}

const ReportConfigurationModal = ({
    open,
    onClose,
    previousReport,
    buildingId,
}: Props) => {
    const user = useAppSelector(getUser);
    const queryClient = useQueryClient();

    const { mutate: onCreate } = useCreateSmartCleaningReport();
    const { mutate: onDelete } = useDeleteSmartCleaningReport();
    const { mutate: onUpdate } = useUpdateSmartCleaningReport();

    const createReport = useCallback(
        (submitValues, formik) => {
            const data = {
                building: submitValues.buildingId,
                name: submitValues.name,
                send_time: submitValues.emailReportAt,
                send_timezone: submitValues.timezone,
                language: submitValues.language,
                recipients: submitValues.emailRecipients,
                show_sensor_list: submitValues.showSensorList,
                page_size: submitValues.pageSize,
                force_single_page_floorplan:
                    submitValues.floorplanPageConfiguration === 'one_page',
                zoom: submitValues.zoom,
                scale: submitValues.sensorIconScale,
                floor_ids: submitValues.floorIds,
                enabled: !submitValues.disabled,
            };

            onCreate(data, {
                onSuccess() {
                    onClose();
                    formik.resetForm();
                    queryClient.invalidateQueries({
                        queryKey: getSmartCleaningReportsKey(),
                    });
                },
                onError(err) {
                    if (err.parsedError) {
                        const { errors: formikErrors } = err.parsedError;

                        if (formikErrors) {
                            formik.setErrors(formikErrors);
                        }
                    }
                },
                onSettled: () => {
                    formik.setSubmitting(false);
                },
            });
        },
        [onClose, onCreate, queryClient],
    );

    const updateReport = useCallback(
        (submitValues, formik) => {
            if (!previousReport) {
                return;
            }

            const data = {
                building: submitValues.buildingId,
                name: submitValues.name,
                send_time: submitValues.emailReportAt,
                send_timezone: submitValues.timezone,
                language: submitValues.language,
                recipients: submitValues.emailRecipients,
                show_sensor_list: submitValues.showSensorList,
                page_size: submitValues.pageSize,
                force_single_page_floorplan:
                    submitValues.floorplanPageConfiguration === 'one_page',
                zoom: submitValues.zoom,
                scale: submitValues.sensorIconScale,
                floor_ids: submitValues.floorIds,
                enabled: !submitValues.disabled,
            };

            onUpdate(
                { id: previousReport.id, data },
                {
                    onSuccess() {
                        onClose();
                        formik.resetForm();
                        queryClient.invalidateQueries({
                            queryKey: getSmartCleaningReportsKey(),
                        });
                    },
                    onError(err) {
                        if (err.parsedError) {
                            const { errors: formikErrors } = err.parsedError;

                            if (formikErrors) {
                                formik.setErrors(formikErrors);
                            }
                        }
                    },
                    onSettled: () => {
                        formik.setSubmitting(false);
                    },
                },
            );
        },
        [onClose, onUpdate, previousReport, queryClient],
    );

    const deleteReport = useCallback(() => {
        if (!previousReport) {
            return;
        }

        onDelete(
            { id: previousReport.id },
            {
                onSuccess() {
                    onClose();
                    queryClient.invalidateQueries({
                        queryKey: getSmartCleaningReportsKey(),
                    });
                },
            },
        );
    }, [onClose, onDelete, previousReport, queryClient]);

    const initialValues = {
        name: previousReport?.name ? previousReport.name : '',
        language: previousReport?.language ? previousReport?.language : 'en',
        emailRecipients:
            previousReport?.mailing_list?.recipients?.map(
                (recipient) => recipient.email,
            ) || (user?.email ? [user.email] : []),
        emailReportAt: previousReport?.send_time
            ? getTimeWithoutMilliseconds(previousReport.send_time)
            : DEFAULT_SEND_TIME,
        showSensorList: previousReport?.show_sensor_list || false,
        pageSize: previousReport?.page_size || 'us_letter',
        floorplanPageConfiguration: (previousReport === undefined ||
        previousReport.force_single_page_floorplan
            ? 'one_page'
            : 'multiple_pages') as FloorplanPageConfiguration,
        zoom: previousReport?.zoom || 1,
        sensorIconScale: previousReport?.scale || 1,
        floorIds: previousReport?.floors?.map((floor) => floor.id) || [],
        buildingId,
        disabled: !(previousReport?.enabled !== undefined
            ? previousReport.enabled
            : true),
    };

    const onSubmit = previousReport ? updateReport : createReport;

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnBlur={false}
            validationSchema={REPORT_CONFIG_VALIDATION_SCHEMA}
        >
            {({
                values,
                handleChange,
                setFieldValue,
                handleSubmit,
                handleBlur,
                setFieldTouched,
                errors,
                touched,
                isSubmitting,
                resetForm,
            }) => (
                <ReportConfigurationForm
                    open={open}
                    errors={errors}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    isNew={!previousReport}
                    name={values.name}
                    language={values.language}
                    emailRecipients={values.emailRecipients}
                    emailReportAt={values.emailReportAt}
                    showSensorList={values.showSensorList}
                    pageSize={values.pageSize}
                    floorplanPageConfiguration={values.floorplanPageConfiguration}
                    zoom={values.zoom}
                    sensorIconScale={values.sensorIconScale}
                    disabled={values.disabled}
                    floorIds={values.floorIds}
                    buildingId={values.buildingId}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleDelete={deleteReport}
                    resetForm={resetForm}
                    setFieldTouched={setFieldTouched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    onClose={onClose}
                />
            )}
        </Formik>
    );
};

export default memo(ReportConfigurationModal);
