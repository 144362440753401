import { routesManager } from '@infogrid/core-routing';
import { createFetchAction, createFetchSaga } from '@thorgate/spa-entities';
import { takeEveryWithMatch } from '@thorgate/spa-view-manager';
import { call } from 'redux-saga/effects';

import { createPaginationSuccessHook } from 'ducks/pagination';
import { folderSchema, relatedSubfoldersKeyFn } from 'schemas/folder';
import api from 'services/api';
import { buildQueryFromLocation } from 'utils/filtering/legacy/sensor';
import { folderMutateKwargs } from 'utils/folder';
import { serializeData } from 'utils/serializeData';

export const fetchFolderSubfolders = createFetchAction(
    '@@sagas/folders/subfolders/fetch',
);

const fetchFolderSubfoldersWorker = createFetchSaga({
    resource: api.folder.subFolders,
    successHook: createPaginationSuccessHook(relatedSubfoldersKeyFn, true),
    key: relatedSubfoldersKeyFn,
    listSchema: [folderSchema],
    serializeData,

    mutateKwargs: folderMutateKwargs,
    *mutateQuery(match, action) {
        if (action?.meta?.keyOptions?.filtered === false) {
            return action?.payload?.query;
        }

        return yield call(buildQueryFromLocation, action?.payload?.query);
    },
});

export function* fetchFolderSubfoldersWatcher() {
    yield takeEveryWithMatch(
        fetchFolderSubfolders.getType(),
        routesManager.resolvePattern('folders:details'),
        fetchFolderSubfoldersWorker,
    );
}
