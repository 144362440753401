import { makeStyles } from '@material-ui/core';

export const useFloorRowContentStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: '100%',
    },
    floorLink: {
        display: 'block',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    floorName: {
        color: theme.palette.common.gray8,
        fontSize: 16,
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
    },
    floorInfo: {
        color: theme.palette.common.gray6,
        fontSize: 14,
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        },
    },
}));
