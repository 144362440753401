import type { ButtonProps } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { memo } from 'react';

import { useTreeNodeButtonStyles } from './styles';

interface Props {
    depth: number;
}

const TreeNodeButton = ({ depth, ...props }: Props & ButtonProps): JSX.Element => {
    const styles = useTreeNodeButtonStyles({ depth });

    return (
        <li>
            <span />
            <div className={styles.treeNodeContent}>
                <span className={styles.treeNodeCaretNone} />
                <Button
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                />
            </div>
        </li>
    );
};

export default memo(TreeNodeButton);
