import { useAppDispatch } from '@infogrid/core-ducks';
import type { SensorVendor } from '@infogrid/sensors-constants';
import type { FormikErrors } from 'formik';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useRegisterSensor } from 'apiHooks/sensors/installation/hooks';
import { handleInstallError, INSTALL_STEPS, onSensorIdentified } from 'ducks/installFlow';

import { useGoToStep } from '../../utils/hooks/useGoToStep';
import type { IdentifyFormValues } from './IdentifyForm';
import IdentifyForm from './IdentifyForm';

export interface Props {
    vendor: SensorVendor;
    helpText: string;
    helpImage: string;
    deviceIdLabel?: string;
    hasSerialNumber?: boolean;
}

const IdentifyFormContainer: FC<Props> = ({
    vendor,
    helpText,
    helpImage,
    deviceIdLabel,
    hasSerialNumber,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('sensors');

    const { mutate: identifySensor, isLoading } = useRegisterSensor({
        onSuccess: (data) => {
            dispatch(onSensorIdentified(data));
        },
        onError: ({ response }) => {
            dispatch(handleInstallError(response));
        },
    });

    const formik = useFormik<IdentifyFormValues>({
        initialValues: {
            serialNumber: '',
            deviceId: '',
        },
        onSubmit: ({ serialNumber, deviceId }) => {
            identifySensor({
                vendor,
                serial_number: serialNumber,
                airthings_device_id: deviceId || undefined,
            });
        },
        validate: ({ serialNumber, deviceId }) => {
            const errors: FormikErrors<IdentifyFormValues> = {};

            if (!deviceId) {
                errors.deviceId = t('Please enter a device ID');
            }

            if (hasSerialNumber && !serialNumber) {
                errors.serialNumber = t('Please enter a serial number');
            }

            return errors;
        },
    });

    const onClickBack = useGoToStep(INSTALL_STEPS.BARCODE_SCAN);

    return (
        <IdentifyForm
            formik={formik}
            helpText={helpText}
            helpImage={helpImage}
            deviceIdLabel={deviceIdLabel}
            hasSerialNumber={hasSerialNumber}
            onClickBack={onClickBack}
            isLoading={isLoading}
        />
    );
};

export default IdentifyFormContainer;
