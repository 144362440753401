import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useWidgetPreviewStyles } from './styles';

export interface WidgetPreviewProps {
    active?: boolean;
    widget: WidgetMetadataType;
    onClick: (widget: WidgetMetadataType) => void;
}

const WidgetPreview = ({ active = false, onClick, widget }: WidgetPreviewProps) => {
    const styles = useWidgetPreviewStyles({ active });
    const { t } = useTranslation('dashboard');

    const handleClick = () => {
        onClick(widget);
    };

    return (
        <div
            className={styles.container}
            onClick={handleClick}
            onKeyDown={handleClick}
            role="button"
            tabIndex={0}
            data-cypress="widget-type"
        >
            <img alt={t(widget.label)} className={styles.image} src={widget.image} />
            <Typography className={styles.name}>{t(widget.label)}</Typography>
        </div>
    );
};

export default memo(WidgetPreview);
