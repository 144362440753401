import { toastDefaultMeta, toastSuccess } from '@infogrid/core-ducks';
import { selectOrganizationId } from '@infogrid/user-ducks';
import { i18n } from '@infogrid/utils-i18n';
import { saveResults } from '@thorgate/spa-entities';
import { createSaveAction, createFormSaveSaga } from '@thorgate/spa-forms';
import { call, takeLatest, put, select } from 'redux-saga/effects';

import { orgUserSchema } from 'schemas/orgUser';
import api from 'services/api';
import { handleDelayingFormSave } from 'utils/handleDelayingFormSave';

export const regenerateSecretKey = createSaveAction(
    'sagas/account/REGENERATE_SECRET_KEY',
    toastDefaultMeta,
);

const regenerateSecretKeyWorker = createFormSaveSaga({
    resource: api.organization.serviceAccount.regenerate,
    method: 'patch',
    *successHook(serviceAccount, match) {
        yield put(toastSuccess({ message: i18n.t('Secret key regenerated') }));
        yield call(
            saveResults,
            orgUserSchema.key,
            [
                {
                    ...serviceAccount,
                    id: parseInt(match.params.userId, 10),
                },
            ],
            [orgUserSchema],
            {
                mergeEntities: true,
                updateOrder: true,
            },
        );
    },

    *mutateKwargs(match) {
        const organizationId = yield select(selectOrganizationId);

        return {
            org_pk: organizationId,
            pk: match.params.userId,
        };
    },
});

export default function* regenerateSecretKeyWatcherSaga(match) {
    yield takeLatest(
        regenerateSecretKey.getType(),
        handleDelayingFormSave(
            regenerateSecretKeyWorker,
            i18n.t('Regenerating a secret key is in progress'),
        ),
        match,
    );
}
