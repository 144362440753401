import { calendarPeriods, dataFrequencies } from '@infogrid/dashboards-constants';
import { days7ago, yesterday } from '@infogrid/utils-dates';

import {
    prepareConfigurationPayload as prepareProximityCountConfigurationPayload,
    prepareInitialValues as prepareProximityCountInitialValues,
} from 'views/dashboards/widgets/ProximityCountWidget/utils';
import {
    prepareConfigurationPayload as prepareTouchCountConfigurationPayload,
    prepareInitialValues as prepareTouchCountInitialValues,
} from 'views/dashboards/widgets/TouchCountWidget/utils';
import {
    periodDurationInDays,
    prepareBaseConfigurationPayload,
    prepareBaseInitialValues,
} from 'views/dashboards/widgets/utils';

const defaultValues = {
    dateRange: {
        period: calendarPeriods.week,
        startDate: days7ago,
        endDate: yesterday,
        workHoursFrom: '09:00',
        workHoursTo: '17:00',
    },
};

export const prepareInitialValues = (widget) => {
    const { data_frequency: dataFrequency = dataFrequencies.daily.value } =
        widget?.configuration;

    const touchCountInitialValues = prepareTouchCountInitialValues(widget);
    const proximityCountInitialValues = prepareProximityCountInitialValues(widget);

    const sensors = [
        ...new Set([
            ...touchCountInitialValues.sensors,
            ...proximityCountInitialValues.sensors,
        ]),
    ];

    /**
     * INFO: could be also proximityCountInitialValues.folders,
     * thery are the same
     */
    const { folders } = touchCountInitialValues;

    return {
        ...prepareBaseInitialValues(widget, defaultValues),
        folders,
        sensors,
        displayOptions: {
            ...touchCountInitialValues.displayOptions,
            ...proximityCountInitialValues.displayOptions,
            dataFrequency,
        },
    };
};

export const prepareConfigurationPayload = (values, sensorsData) => {
    const payload = prepareBaseConfigurationPayload(values);

    const { dataFrequency } = values?.displayOptions;

    const touchCountPayload = prepareTouchCountConfigurationPayload(values, sensorsData);
    const proximityCountPayload = prepareProximityCountConfigurationPayload(
        values,
        sensorsData,
    );

    return {
        ...payload,
        data_frequency: dataFrequency,
        calculators: {
            ...proximityCountPayload.calculators,
            ...touchCountPayload.calculators,
        },
    };
};

export const calculateDisabledFrequencies = ({ startDate, endDate }) => {
    const duration = periodDurationInDays(startDate, endDate);

    return [
        duration > 2 && dataFrequencies.hourly, // INFO: more than 2 days
        duration > 60 && dataFrequencies.daily, // INFO: more than 2 months
        duration > 365 && dataFrequencies.weekly, // INFO: more than 1 year
        duration > 5 * 365 && dataFrequencies.monthly, // INFO: more than 5 years
    ].filter(Boolean);
};
