import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo } from 'react';

import { useWidgetTitleStyles } from './styles';

interface WidgetTitleProps {
    children: ReactNode;
    className?: string;
}

const WidgetTitle = ({ children, className }: WidgetTitleProps) => {
    const styles = useWidgetTitleStyles();

    return (
        <Typography className={classNames(styles.root, className)} variant="subtitle1">
            {children}
        </Typography>
    );
};

export default memo(WidgetTitle);
