import SETTINGS from 'settings';

/**
 * Get the fully qualified URI based on the path.
 * Essentially just prepends the Webclient URL to the URI.
 */
const getWebclientURI = (uri: string): string =>
    uri.startsWith('/') ? `${SETTINGS.SITE_URL}${uri}` : uri;

export default getWebclientURI;
