import { Button, Icon, Slider, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { t } from 'i18next';
import type { ReactNode, RefObject } from 'react';
import { memo } from 'react';

import MapHints from '../MapHints';
import type {
    BaseMapItem,
    FloorPlanControls,
    FloorPlanControlSetters,
    MapItemId,
    MapScale,
} from '../types';
import { useStylesFloorPlanControl } from './styles';

// TODO import
const QUESTION_ICON = 'far fa-question-circle';

interface Props {
    activeItemId: MapItemId;
    containerRef: RefObject<HTMLDivElement>;
    filterComponent: ReactNode;
    isEditAvailable: boolean;
    isItemMoveEnabled: boolean;
    mappedItems: BaseMapItem[];
    scale: FloorPlanControls['scale'];
    deleteActiveItem?: () => void;
    enableItemMove?: () => void;
    setScale: FloorPlanControlSetters['setScale'];
    hasQuestionIcon?: boolean;
}

const FloorPlanControl: React.FC<Props> = ({
    activeItemId,
    containerRef,
    filterComponent,
    isEditAvailable,
    isItemMoveEnabled,
    mappedItems,
    scale,
    deleteActiveItem,
    enableItemMove,
    setScale,
    hasQuestionIcon = false,
}) => {
    const styles = useStylesFloorPlanControl();

    return (
        <div className={styles.mapCustomControlsContainer}>
            {filterComponent}

            <div className={styles.mapCustomControlsBtns}>
                {deleteActiveItem && isEditAvailable && activeItemId && (
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={styles.deleteItemBtn}
                        onClick={deleteActiveItem}
                        data-cypress="remove-sensor"
                    >
                        {t('Remove sensor')}
                    </Button>
                )}
                {enableItemMove && isEditAvailable && activeItemId && (
                    <Button
                        disabled={isItemMoveEnabled}
                        variant="outlined"
                        color="primary"
                        className={styles.deleteItemBtn}
                        onClick={enableItemMove}
                        data-cypress="move-sensor"
                    >
                        {t('Move sensor')}
                    </Button>
                )}
                {hasQuestionIcon && (
                    <Tooltip
                        arrow
                        PopperProps={{
                            container: containerRef.current,
                            disablePortal: true,
                        }}
                        leaveDelay={0}
                        title={<MapHints />}
                    >
                        <Icon
                            data-cypress="question-icon"
                            className={classNames(
                                QUESTION_ICON,
                                styles.controlIcon,
                                styles.hintsIcon,
                            )}
                        />
                    </Tooltip>
                )}
                {!!mappedItems.length && (
                    <Slider
                        className={styles.itemIconSizeSlider}
                        value={scale}
                        data-cypress="sensor-slider"
                        onChange={(_0, newValue) => {
                            // TODO assumption made here that we don't receive an arbitrary-length array
                            setScale(newValue as MapScale);
                        }}
                        min={0.3}
                        max={3}
                        step={0.1}
                    />
                )}
            </div>
        </div>
    );
};

export default memo(FloorPlanControl);
