import type { FloorDetail } from '@infogrid/locations-types';
import { Divider } from '@material-ui/core';
import { memo } from 'react';

import SidebarHeader from './SidebarHeader';
import SidebarSensors from './SidebarSensors';
import { useSidebarContentStyles } from './styles';

interface Props {
    buildingId: number;
    floor: FloorDetail;
    onCloseSidebar: () => void;
}

const SidebarContent = ({ buildingId, floor, onCloseSidebar }: Props) => {
    const styles = useSidebarContentStyles();

    return (
        <div className={styles.drawer} data-cypress="unassigned-sensors-sidebar">
            <SidebarHeader onCloseSidebar={onCloseSidebar} />
            <Divider className={styles.divider} />
            <SidebarSensors buildingId={buildingId} floor={floor} />
        </div>
    );
};

export default memo(SidebarContent);
