import { Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useBannerStyles } from './styles';

interface Props {
    location?: string | null;
}

const Banner = ({ location }: Props) => {
    const styles = useBannerStyles();

    const { t } = useTranslation('estate');

    return (
        <div className={styles.container} data-cypress="target-location-panel">
            {location ? (
                <Typography className={styles.moveToLabel} variant="subtitle1">
                    {t('Move to: ')}
                    <span>{location}</span>
                </Typography>
            ) : (
                <Typography className={styles.selectLocationLabel}>
                    {t('Select a location to move to')}
                </Typography>
            )}
        </div>
    );
};

export default memo(Banner);
