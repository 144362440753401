import { Modal } from '@infogrid/components-material-ui';
import { Button } from '@material-ui/core';
import type { FormEventHandler, ReactNode } from 'react';
import { useCallback, useEffect, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from 'components/InstallationFlow/reducer';
import type { InstallationFlowComponentProps } from 'components/InstallationFlow/types';

import { useInstallationFlowContext } from '../../context';
import { useInstallationFlowStyles } from '../../styles';

interface Props extends Pick<InstallationFlowComponentProps, 'errorMessage'> {
    renderFormContent: ({
        errorText,
        onChangeSerialNumber,
        onChangeDeviceId,
    }: {
        errorText: string;
        onChangeSerialNumber: FormEventHandler<HTMLDivElement>;
        onChangeDeviceId: FormEventHandler<HTMLDivElement>;
    }) => ReactNode;
    requiresDeviceId?: boolean;
}

const DeviceIdentifier = ({
    renderFormContent,
    requiresDeviceId = false,
    errorMessage = '',
}: Props) => {
    const { t } = useTranslation('sensors');
    const { registerInfo, sensor, lastSignalEvent } = useInstallationFlowContext();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();
    const [serialNumber, setSerialNumber] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const installationFlowStyles = useInstallationFlowStyles();
    const [errorText, setError] = useState('');

    useEffect(() => {
        if (sensor && registerInfo) {
            dispatchInstallationFlowContext({
                type: lastSignalEvent
                    ? INSTALLATION_FLOW_ACTIONS.CHECK_CONNECTION
                    : INSTALLATION_FLOW_ACTIONS.CONFIGURE_DEVICE,
            });
        }
    }, [sensor, registerInfo, dispatchInstallationFlowContext, lastSignalEvent]);

    useEffect(() => {
        if (errorMessage) {
            setError(errorMessage);
        } else {
            setError('');
        }
    }, [errorMessage]);

    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();

            if (serialNumber && (!requiresDeviceId || deviceId)) {
                dispatchInstallationFlowContext({
                    type: INSTALLATION_FLOW_ACTIONS.REGISTER_MANUALLY,
                    registerInfo: `${serialNumber} ${deviceId}`.trim(),
                });

                return;
            }

            setError(t('This field may not be blank.'));
        },
        [serialNumber, requiresDeviceId, deviceId, dispatchInstallationFlowContext, t],
    );

    const onPrevious = () =>
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.RETURN_TO_VENDOR_SELECTOR,
        });

    const onChangeSerialNumber = useCallback(
        (e) => {
            setError('');
            setSerialNumber(e.target.value.trim());
        },
        [setError, setSerialNumber],
    );

    const onChangeDeviceId = useCallback(
        (e) => {
            setError('');
            setDeviceId(e.target.value.trim());
        },
        [setError, setDeviceId],
    );

    return (
        <>
            <Modal.Title>{t('Manually identify a device')}</Modal.Title>
            <form
                onSubmit={onSubmit}
                noValidate
                autoComplete="off"
                className={installationFlowStyles.form}
            >
                <Modal.Content>
                    {renderFormContent({
                        errorText,
                        onChangeSerialNumber,
                        onChangeDeviceId,
                    })}
                </Modal.Content>
                <Modal.Actions
                    justify="space-between"
                    className={installationFlowStyles.actions}
                >
                    <Button onClick={onPrevious} color="primary" data-cypress="back">
                        {t('Back')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        autoFocus
                        disabled={!serialNumber || (requiresDeviceId && !deviceId)}
                        type="submit"
                        data-cypress="submit"
                    >
                        {t('Next')}
                    </Button>
                </Modal.Actions>
            </form>
        </>
    );
};

export default memo(DeviceIdentifier);
