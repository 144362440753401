import { Modal } from '@infogrid/components-material-ui';
import { Button, ButtonGroup } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface InstallErrorProps {
    errorTitle?: string;
    errorMessage: string;
    onRetry?: () => void;
    onRestart?: () => void;
}

const InstallError: FC<InstallErrorProps> = ({
    errorTitle,
    errorMessage,
    onRetry,
    onRestart,
}) => {
    const { t } = useTranslation('sensors');

    return (
        <>
            <Modal.Title>{t('Unable to install')}</Modal.Title>
            <Modal.Content>
                <Alert severity="error">
                    {errorTitle && <AlertTitle>{t(errorTitle)}</AlertTitle>}
                    {t(errorMessage)}
                </Alert>
            </Modal.Content>
            <Modal.Actions justify="right">
                <ButtonGroup>
                    {onRetry && (
                        <Button color="primary" variant="contained" onClick={onRetry}>
                            {t('Try again')}
                        </Button>
                    )}
                    {onRestart && (
                        <Button
                            color="primary"
                            variant="contained"
                            data-cypress="install-error-start-again"
                            onClick={onRestart}
                        >
                            {t('Start again')}
                        </Button>
                    )}
                </ButtonGroup>
            </Modal.Actions>
        </>
    );
};

export default InstallError;
