import { useAppDispatch } from '@infogrid/core-ducks';
import { useLatest, useIsOpenState } from '@infogrid/utils-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { push } from 'connected-react-router';
import { useCallback, useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

interface Props {
    when: boolean;
    title: string;
    content: string | Node;
}

const NavigationPrompt = ({ when, title, content }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const [isShowModal, closeModal, openModal] = useIsOpenState(false);
    const [isConfirmedNavigation, setConfirmedNavigation] = useState(false);
    const [navigatingToLocation, setNavigatingToLocation] = useState<Location>();

    const { t } = useTranslation();

    const handleBlockedNavigation = useCallback(
        (nextLocation) => {
            if (!isConfirmedNavigation) {
                setNavigatingToLocation(nextLocation);
                openModal();

                return false;
            }

            return true;
        },
        [isConfirmedNavigation, openModal, setNavigatingToLocation],
    );

    const handleConfirmNavigationClick = useCallback(() => {
        if (navigatingToLocation) {
            setConfirmedNavigation(true);
            closeModal();
        }
    }, [navigatingToLocation, setConfirmedNavigation, closeModal]);

    const latestNavigatingToLocation = useLatest(navigatingToLocation);

    useEffect(() => {
        const nextLocation = latestNavigatingToLocation.current;

        if (isConfirmedNavigation && nextLocation) {
            dispatch(push(`${nextLocation.pathname}${nextLocation.search}`));
        }

        setConfirmedNavigation(false);
    }, [dispatch, isConfirmedNavigation, latestNavigatingToLocation]);

    const DISCARD_MESSAGE = t(
        'If you leave this page now your changes will be discarded.',
    );

    const onUnload = useCallback(
        () => (when ? DISCARD_MESSAGE : false),
        [when, DISCARD_MESSAGE],
    );

    // Prevents the user to reload page if there are unsaved changes
    useBeforeunload(onUnload);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />

            <Dialog
                open={isShowModal}
                onClose={closeModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                data-cypress="nav-prompt-dialog"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmNavigationClick}
                        color="secondary"
                        data-cypress="nav-prompt-discard"
                    >
                        {t('Discard changes')}
                    </Button>
                    <Button
                        onClick={closeModal}
                        color="primary"
                        autoFocus
                        data-cypress="nav-prompt-cancel"
                    >
                        {t("Take me back, I'm not finished!")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NavigationPrompt;
