import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { stringifyObjectDeterministic } from '@infogrid/core-utils';
import { useEffectOnMount, useLatest, useSelectorWithArgs } from '@infogrid/utils-hooks';
import { entitiesSelectors, EntityStatus } from '@thorgate/spa-entities';
import { createCachedSelector } from 're-reselect';
import { useCallback, useMemo } from 'react';

import { selectTotalCount } from 'ducks/pagination';
import { fetchFolderSensors } from 'sagas/folders/fetchFolderSensors';
import { fetchFolderSensorsCount } from 'sagas/folders/fetchFolderSensorsCount';
import {
    resetEntity,
    selectAllEntityCollections,
    selectEntityCollectionIds,
} from 'schemas/entity';
import {
    folderSchema,
    relatedSensorsKeyFn,
    relatedSubfoldersKeyFn,
    selectFolderTreeKeys,
} from 'schemas/folder';
import { sensorsKeyFn } from 'schemas/sensor';
import { SENSOR_PARAM_NAMES } from 'utils/filtering/sensor';
import { toggleArrayMember } from 'utils/functional';

// Get node type (folder or sensor)
export const getNodeItemType = (nodeId) => nodeId.split('-')[0];

// Get node id (device name of a sensor or id of folder)
export const getNodeItemId = (nodeId) => {
    const [entityType, ...rest] = nodeId.split('-');

    if (entityType === 'folder') {
        return parseInt(rest[0], 10);
    }

    return rest.join('-');
};

/**
 * Check if a folder is a root folder.
 * Returns true if the folder has only one breadcrumb/parent folder ('Shared with me' folder).
 * @param folder
 * @returns {boolean}
 */
export const isRootFolder = (folder) => {
    return folder?.breadcrumbs?.length === 1;
};

export const getFolderSensorsKeyOptions = (folderId, sensorQuery = undefined) => {
    const filterName =
        Object.keys(sensorQuery).length > 0
            ? stringifyObjectDeterministic(sensorQuery)
            : undefined;

    return {
        folderId,
        filtered: Object.keys(sensorQuery).length > 0,
        filterName,
    };
};
export const getFolderSensorsKey = (folderId, sensorQuery = undefined) =>
    relatedSensorsKeyFn(getFolderSensorsKeyOptions(folderId, sensorQuery));

/**
 * Get the ids of all subfolders in a folder, cascadingly.
 * @param {number} id - Folder id
 * @param {Object<string[]>} allCollections - See selectAllEntityCollections.
 * @returns {number[]}
 */
export const getFolderAllSubfoldersCascading = ({ id, allCollections }) => {
    const allSubfolderIds = new Set();

    // Stack based iterative 'recursion'
    const foldersToCheck = [id];

    while (foldersToCheck.length > 0) {
        const folderId = foldersToCheck.pop();

        const subfoldersCollectionKey = relatedSubfoldersKeyFn({ folderId });
        const subfolderIds = allCollections[subfoldersCollectionKey];

        if (subfolderIds?.length) {
            subfolderIds.forEach((subfolderId) => allSubfolderIds.add(subfolderId));
            // Check the subfolders of all the subfolders
            foldersToCheck.push(...subfolderIds);
        }
    }

    return [...allSubfolderIds];
};

/**
 * Get the ids of all sensors in a folder, cascadingly.
 * This only returns the data we have in state, which might be in-complete.
 * @param {number} id - Folder id
 * @param {Object<string[]>} allCollections - See selectAllEntityCollections.
 * @param {number[]} allSubfolderIds - Ids of all subfolders in the given folder, cascadingly.
 *                                     See getFolderAllSubfoldersCascading.
 * @param {Object|null} sensorQuery - Object of query options for sensors.
 * @param {boolean} dedupe - Whether to de-duplicate the returned sensors.
 * @returns {string[]}
 */
export const getFolderAllSensorsCascading = ({
    id,
    allCollections,
    allSubfolderIds,
    sensorQuery = null,
    dedupe = false,
}) => {
    const foldersToCheck = [id, ...allSubfolderIds];
    const sensorCollectionKeys = foldersToCheck.map((folderId) =>
        getFolderSensorsKey(folderId, sensorQuery),
    );

    const ancestorFolderQuery = {
        ancestor_folder: id,
        ...sensorQuery,
    };
    const filterName = stringifyObjectDeterministic(ancestorFolderQuery);

    sensorCollectionKeys.push(
        sensorsKeyFn({
            ancestor_folder: id,
            filterName,
            filtered: Boolean(filterName),
        }),
    );

    const allSensorIds = sensorCollectionKeys
        .map((sensorCollectionKey) => allCollections[sensorCollectionKey] || [])
        .flat();

    if (dedupe) {
        return [...new Set(allSensorIds)];
    }

    return allSensorIds;
};

/**
 * Select the ids of all subfolders in a folder, cascadingly.
 * See getFolderAllSubfoldersCascading.
 */
const selectFolderAllSubfoldersCascading = createCachedSelector(
    (state, { id }) => id,
    selectAllEntityCollections,
    (id, allCollections) => getFolderAllSubfoldersCascading({ id, allCollections }),
)((state, { id }) => id);

/**
 * Select the ids of all sensors in a folder, cascadingly.
 * See getFolderAllSensorsCascading.
 */
export const selectFolderAllSensorsCascading = createCachedSelector(
    (state, { id }) => id,
    (state, { sensorQuery }) => sensorQuery,
    (state, { dedupe }) => dedupe,
    selectAllEntityCollections,
    (state, { id }) => selectFolderAllSubfoldersCascading(state, { id }),
    (id, sensorQuery, dedupe, allCollections, allSubfolderIds) =>
        getFolderAllSensorsCascading({
            id,
            allCollections,
            allSubfolderIds,
            sensorQuery,
            dedupe,
        }),
)((state, { id }) => id);

/**
 * Same as selectFolderAllSensorsCascading but for multiple folders.
 */
export const selectFoldersAllSensorsCascading = (state, { ids, sensorQuery, dedupe }) => {
    const allSensorIds = ids
        .map((id) =>
            selectFolderAllSensorsCascading(state, {
                id,
                sensorQuery,
                dedupe: false, // We will dedupe later if needed
            }),
        )
        .flat();

    if (dedupe) {
        return [...new Set(allSensorIds)];
    }

    return allSensorIds;
};

export const selectTotalSelectedSensorsCount = (
    state,
    { selectedFolders, selectedSensors },
) => {
    const folders = Object.values(
        entitiesSelectors.selectEntityType(state, folderSchema.key),
    );

    const totalSensorsInsideFoldersCount = folders.reduce((acc, folder) => {
        let newTotalCount = acc;
        const isParentSelected = (folder?.breadcrumbs || []).some((breadcrumb) =>
            selectedFolders.includes(breadcrumb.id),
        );

        if (selectedFolders.includes(folder.id) && folder.count && !isParentSelected) {
            newTotalCount += folder.count;
        }

        return newTotalCount;
    }, 0);

    return totalSensorsInsideFoldersCount + selectedSensors.length;
};

export const selectFolderCascadingSensorsCount = (state, id, sensorQuery) => {
    const folderSensorsKey = getFolderSensorsKey(id, {
        [SENSOR_PARAM_NAMES.RECURSIVE]: true,
        ...sensorQuery,
    });

    return selectTotalCount(state, folderSensorsKey) || 0;
};

export const selectFolderIsLoading = (state, id, sensorQuery) =>
    entitiesSelectors.selectEntitiesStatus(
        state,
        getFolderSensorsKey(id, sensorQuery),
    ) === EntityStatus.Fetching ||
    entitiesSelectors.selectEntitiesStatus(
        state,
        relatedSubfoldersKeyFn({ folderId: id }),
    ) === EntityStatus.Fetching;

export const selectFolderIsEmpty = (state, id, sensorQuery) => {
    const sensorsKey = getFolderSensorsKey(id, sensorQuery);
    const subfoldersKey = relatedSubfoldersKeyFn({ folderId: id });
    const sensorsStatus = entitiesSelectors.selectEntitiesStatus(state, sensorsKey);
    const subfoldersStatus = entitiesSelectors.selectEntitiesStatus(state, subfoldersKey);
    const sensorsCount = selectEntityCollectionIds(state, sensorsKey)?.length || 0;
    const subfoldersCount = selectEntityCollectionIds(state, subfoldersKey)?.length || 0;

    return (
        sensorsStatus === EntityStatus.Fetched &&
        subfoldersStatus === EntityStatus.Fetched &&
        sensorsCount === 0 &&
        subfoldersCount === 0
    );
};

export const usePrefetchFolderSensorsCount = ({ folderId, sensorQuery }) => {
    const dispatch = useAppDispatch();
    const query = useMemo(
        () => ({ [SENSOR_PARAM_NAMES.RECURSIVE]: true, ...sensorQuery }),
        [sensorQuery],
    );
    const sensorsKey = getFolderSensorsKey(folderId, query);
    const status = useSelectorWithArgs(
        entitiesSelectors.selectEntitiesStatus,
        sensorsKey,
    );
    const notLoaded = status === EntityStatus.NotLoaded;

    useEffectOnMount(() => {
        if (notLoaded) {
            const keyOptions = getFolderSensorsKeyOptions(folderId, query);

            dispatch(
                fetchFolderSensorsCount(
                    {
                        kwargs: { folderId },
                        query,
                    },
                    { keyOptions },
                ),
            );
        }
    });
};

// delete all folder-tree data from the store for the case when permissions has changed
export const useClearFolderTree = () => {
    const dispatch = useAppDispatch();

    const foldersTreeKeys = useAppSelector(selectFolderTreeKeys);

    useEffectOnMount(() => {
        foldersTreeKeys.forEach((key) => resetEntity(dispatch, key));
        dispatch(
            fetchFolderSensors(
                {
                    kwargs: { folderId: 0 },
                    query: {},
                },
                {
                    keyOptions: getFolderSensorsKeyOptions(0, {}),
                },
            ),
        );
    });
};
// Note: This is currently unused but you might find this useful when you are using FolderTree
//       In most cases using TreePickerSelectField should be easier.
//       But who knows what you need to do, future developer.
export const useFolderTreeToggleSelected = ({
    selectedFolders,
    selectedSensors,
    setSelectedFolders,
    setSelectedSensors,
}) => {
    // boxed optimizations
    const latestSelectedFolders = useLatest(selectedFolders);
    const latestSelectedSensors = useLatest(selectedSensors);

    // This handles something being clicked in the tree
    // We get passed in setSelected* which take an array
    // of the selected id's.
    return useCallback(
        /**
         * Toggle a node in the folder tree.
         * @param {string} nodeId - Id of node to toggle
         * @param {boolean} nodeWasSelected - Whether node was selected before
         */
        (nodeId, nodeWasSelected) => {
            if (!setSelectedFolders || !setSelectedSensors || nodeId === 'folder-0') {
                return;
            }

            const nodeType = getNodeItemType(nodeId);
            const itemId = getNodeItemId(nodeId);

            if (nodeType === 'folder') {
                const newSavedFolders = toggleArrayMember(
                    latestSelectedFolders.current,
                    itemId,
                    !nodeWasSelected,
                );

                setSelectedFolders(newSavedFolders);
            } else if (nodeType === 'sensor') {
                const newSensors = toggleArrayMember(
                    latestSelectedSensors.current,
                    itemId,
                    !nodeWasSelected,
                );

                setSelectedSensors(newSensors);
            }
        },
        [
            latestSelectedFolders,
            latestSelectedSensors,
            setSelectedFolders,
            setSelectedSensors,
        ],
    );
};
