import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type {
    CreateFeedbackPanelPayload,
    SensorShape,
} from '@infogrid/sensors-constants';
import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

import { controllers } from '../controllers';

export const useCreateFeedbackPanel = (): UseMutationResult<
    SensorShape,
    AxiosParsedError,
    CreateFeedbackPanelPayload
> => {
    return useMutation((feedbackPanelData: CreateFeedbackPanelPayload) =>
        wrapResponsePromise<SensorShape>(
            controllers.createFeedbackPanel(feedbackPanelData),
        ),
    );
};
