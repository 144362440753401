import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import { selectConstanceConfigLoaded, useAppSelector } from '@infogrid/core-ducks';
import { isAuthenticated as selectIsAuthenticated } from '@infogrid/user-ducks';
import { useIsMobile } from '@infogrid/utils-hooks';
import { memo, useEffect, useMemo } from 'react';
import type { RouteConfigComponentProps } from 'react-router-config';
import { matchPath, useLocation } from 'react-router-dom';

import { prefetchTimezones } from 'apiHooks/timezones/hooks';
import Navbar from 'components/Navbar';
import NavbarMobile from 'components/Navbar/NavbarMobile';
import PendingDataManager from 'components/PendingDataManager';
import RenderChildren from 'components/RenderChildren';
import ToastContainer from 'components/ToastContainer';
import { selectors as uiSelectors } from 'ducks/ui';
import { useIsIntercomEnabledForUser } from 'utils/hooks/intercomAnalytics';
import { toggleIntercomButton } from 'utils/intercom';

import { ROUTES_WITHOUT_NAVBAR } from './consts';
import { useStylesContainer } from './styles';

interface Props {
    route: RouteConfigComponentProps['route'];
}

const AppContainer = ({ route }: Props) => {
    const isMobile = useIsMobile();

    const sidebarWidth = useAppSelector((state) =>
        uiSelectors.sidebarWidth(state, 'globalDesktop'),
    );

    const stylesAppContainer = useStylesContainer({ isMobile, sidebarWidth });

    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const constanceLoaded = useAppSelector(selectConstanceConfigLoaded);

    const { pathname } = useLocation();

    const NavigationBar = isMobile ? NavbarMobile : Navbar;

    // Hide navigation bar when logging out
    // Helps avoid flickering when logging out with SSO
    const showNavigationBar = useMemo(
        () =>
            isAuthenticated &&
            !ROUTES_WITHOUT_NAVBAR.some((routePath) => {
                const match = matchPath(pathname, {
                    path: routePath,
                    exact: true,
                });

                return match?.isExact;
            }),
        [isAuthenticated, pathname],
    );

    useEffect(() => {
        prefetchTimezones();
    }, []);

    const isIntercomEnabled = useIsIntercomEnabledForUser();

    useEffect(() => {
        // Hide intercom on mobile (WEB-3248)
        toggleIntercomButton(!isIntercomEnabled);
    }, [isIntercomEnabled]);

    return (
        <>
            <div className={stylesAppContainer.container}>
                {showNavigationBar && <NavigationBar />}

                <div className={stylesAppContainer.content}>
                    {constanceLoaded ? (
                        <PendingDataManager>
                            <RenderChildren route={route} />
                        </PendingDataManager>
                    ) : (
                        <LoadingPlaceholder />
                    )}
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default memo(AppContainer);
