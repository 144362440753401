import { useState, useEffect, useRef } from 'react';
import { useDebounce } from 'react-use';

import { onComponentError } from 'services/sentry';
import SETTINGS from 'settings';

const mapboxPlacesEndpoint = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
const DEFAULT_PLACE_TYPES = [
    'address',
    'locality',
    'neighborhood',
    'place',
    'poi',
    'postcode',
];

const fetchClient = (resource = '', options = {}) => {
    return fetch(resource, options).then((response) => {
        if (response.status >= 200 && response.status < 400) {
            return response.json();
        }

        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
    });
};

// Premptive loose typing before we fully type this file so
// that typescript doesn't complain due to empty value being null
/**
 * @param {{
 *   query?: string,
 *   debounceTime?: number,
 *   selector?: Function,
 *   emptyValue?: unknown | null,
 *   country?: string,
 *   placeTypes?: string[]
 * }}
 */
const useMapboxPlaces = ({
    query = '',
    debounceTime = 100,
    selector = (v) => v,
    emptyValue = null,
    country = '',
    placeTypes = DEFAULT_PLACE_TYPES,
}) => {
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(false);

    // Use isMounted to avoid setResults being called after a dismount and causing render warnings
    // The fetchClient logic should be migrated to react-query, which would solve this problem
    const isMounted = useRef(true);

    useDebounce(
        async () => {
            try {
                if (query && !results[query]) {
                    setLoading(true);

                    const data = await fetchClient(
                        `${mapboxPlacesEndpoint}/${query}.json?access_token=${SETTINGS.MAPBOX_ACCESS_TOKEN}&country=${country}&types=${placeTypes}`,
                    );

                    if (isMounted.current) {
                        setResults((res) => ({ ...res, [query]: selector(data) }));
                        setLoading(false);
                    }
                }
            } catch (error) {
                onComponentError(error);
                setLoading(false);
            }
        },
        debounceTime,
        [query, setResults],
    );

    // Set isMounted to false when unmounting
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    return {
        results: results[query] || emptyValue,
        loading,
    };
};

export default useMapboxPlaces;
