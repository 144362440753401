import {
    calendarPeriods,
    dataFrequencies,
    sensorsColors,
} from '@infogrid/dashboards-constants';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import moment from 'moment';

import { DEFAULT_COLOR, GRAPH_COLOR } from 'components/graphs/constants';
import {
    calculateTotalEventsCount,
    getSensorsByType,
    periodDurationInDays,
    prepareBaseConfigurationPayload,
    prepareBaseInitialValues,
} from 'views/dashboards/widgets/utils';

const defaultValues = {
    dateRange: {
        period: calendarPeriods.week,
        startDate: moment().subtract(7, 'days'),
        endDate: moment().subtract(1, 'day'),
        workHoursFrom: '09:00',
        workHoursTo: '17:00',
    },
};

export const prepareInitialValues = (widget) => {
    const { data_frequency: dataFrequency = dataFrequencies.daily.value } =
        widget?.configuration;

    const {
        folders = [],
        sensors: touchCountSensors = [],
        colors: touchCountColors = {},
    } = widget?.configuration?.calculators?.touch_count || {};

    const { sensors: touchCounterSensors = [], colors: touchCounterColors = {} } =
        widget?.configuration?.calculators?.touch_counter || {};

    const sensors = [...touchCountSensors, ...touchCounterSensors];
    const colors = { ...touchCountColors, ...touchCounterColors };

    /* eslint-disable no-param-reassign */
    const sensorsAttributes = Object.keys(colors).reduce((attributes, sensor) => {
        attributes[sensor] = {
            color: colors[sensor] || sensorsColors.green.value,
        };

        return attributes;
    }, {});
    /* eslint-enable no-param-reassign */

    return {
        ...prepareBaseInitialValues(widget, defaultValues),
        folders,
        sensors,
        displayOptions: {
            dataFrequency,
            sensorsAttributes,
        },
    };
};

export const prepareConfigurationPayload = (values, sensorsData) => {
    const payload = prepareBaseConfigurationPayload(values);

    const { dataFrequency, sensorsAttributes = {} } = values?.displayOptions;

    const touchCountSensors = getSensorsByType(
        values?.sensors,
        sensorsData,
        SENSOR_TYPE.TYPE_TOUCH,
    );

    const cleaningConfirmationSensors = getSensorsByType(
        values?.sensors,
        sensorsData,
        SENSOR_TYPE.TYPE_CLEANING_CONFIRMATION,
    );
    const touchCountLikeSensors = [...touchCountSensors, ...cleaningConfirmationSensors];

    const touchCounterSensors = getSensorsByType(
        values?.sensors,
        sensorsData,
        SENSOR_TYPE.TYPE_TOUCH_COUNTER,
    );

    /* eslint-disable no-param-reassign */
    const touchCountColors = touchCountLikeSensors.reduce((colors, sensorId) => {
        colors[sensorId] =
            sensorsAttributes[sensorId]?.color || sensorsColors.green.value;

        return colors;
    }, {});

    const touchCounterColors = touchCounterSensors.reduce((colors, sensorId) => {
        colors[sensorId] =
            sensorsAttributes[sensorId]?.color || sensorsColors.green.value;

        return colors;
    }, {});
    /* eslint-enable no-param-reassign */

    return {
        ...payload,
        data_frequency: dataFrequency,
        calculators: {
            touch_count: {
                colors: touchCountColors,
                folders: values?.folders,
                sensors: touchCountLikeSensors,
            },
            touch_counter: {
                colors: touchCounterColors,
                folders: values?.folders,
                sensors: touchCounterSensors,
            },
        },
    };
};

export const extractTotalTouchEventsCount = ({ widgetData = {} }) => {
    const {
        touch_count_graph_rows = [],
        touch_count_sensor_names = {},
        touch_counter_graph_rows = [],
        touch_counter_sensor_names = {},
    } = widgetData;

    const touchCountTotal = calculateTotalEventsCount(
        touch_count_graph_rows,
        touch_count_sensor_names,
    );

    const touchCounterTotal = calculateTotalEventsCount(
        touch_counter_graph_rows,
        touch_counter_sensor_names,
    );

    return touchCountTotal + touchCounterTotal || 0;
};

export const generateChartData = (data) => {
    const {
        touch_count,
        touch_counter,
        touch_count_graph_rows,
        touch_counter_graph_rows,
        touch_count_sensor_names,
        touch_counter_sensor_names,
    } = data;

    const sensorNames = {
        ...touch_count_sensor_names,
        ...touch_counter_sensor_names,
    };

    const sensorsColorsMap = { ...touch_count?.colors, ...touch_counter?.colors };

    const rowsCount =
        Math.max(touch_count_graph_rows?.length, touch_counter_graph_rows?.length) || 0;

    return Object.keys(sensorNames)
        .reduce((entries, sensor) => {
            const matchedColorKey = Object.keys(sensorsColors).find(
                (sensorColor) =>
                    sensorsColors[sensorColor].value === sensorsColorsMap[sensor],
            );

            const matchedColor =
                sensorsColors[matchedColorKey]?.color ||
                GRAPH_COLOR[DEFAULT_COLOR].colorCode;

            return [
                ...entries,
                {
                    name: sensorNames[sensor],
                    data: Array.from(Array(rowsCount)).map((_, index) => {
                        const timestamp =
                            touch_count_graph_rows[index]?.timestamp ||
                            touch_counter_graph_rows[index]?.timestamp;

                        const touchCountRow = touch_count_graph_rows[index] || {};
                        const touchCounterRow = touch_counter_graph_rows[index] || {};

                        const total =
                            touchCountRow[sensor] || touchCounterRow[sensor] || 0;

                        return [+new Date(timestamp), total];
                    }),
                    color: matchedColor,
                },
            ];
        }, [])
        .sort((a, b) => {
            const colors = Object.keys(sensorsColors).map(
                (color) => sensorsColors[color].color,
            );

            const index1 = colors.indexOf(a.color);
            const index2 = colors.indexOf(b.color);

            return index1 < index2 ? 1 : -1;
        });
};

export const calculateDisabledFrequencies = ({ startDate, endDate }) => {
    const duration = periodDurationInDays(startDate, endDate);

    return [
        duration > 2 && dataFrequencies.hourly, // INFO: more than 2 days
        duration > 60 && dataFrequencies.daily, // INFO: more than 2 months
        duration > 365 && dataFrequencies.weekly, // INFO: more than 1 year
        duration > 5 * 365 && dataFrequencies.yearly, // INFO: more than 5 years
    ].filter(Boolean);
};
