import type { Space } from '@infogrid/locations-types';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { Button, Icon, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Skeleton from '@material-ui/lab/Skeleton';
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import DeleteSpaceModal from 'views/estate/components/modals/DeleteSpaceModal';
import { useSpaceModalContext } from 'views/estate/components/modals/EditSpaceModal/context';
import type { EstateFloorParams } from 'views/estate/pages/types';

import { useSpaceSidebarHeaderStyles } from './styles';

interface Props {
    space?: Space;
    setSelectedSpaceId: (id: number | null) => void;
}

const SpaceSidebarHeader = ({ space, setSelectedSpaceId }: Props) => {
    const styles = useSpaceSidebarHeaderStyles();

    const { t } = useTranslation();

    const { buildingId, floorId } = useParams<EstateFloorParams>();

    const [isDeleteModalOpen, _0, _1, toggleDeleteModal] = useIsOpenState();
    const { setSpaceToEdit } = useSpaceModalContext();

    const closeSidebar = () => {
        setSelectedSpaceId(null);
    };

    const toggleEditModal = () => {
        if (space) {
            setSpaceToEdit(space);
        }
    };

    const disableDeleteSpaceReason = useMemo(() => {
        if (!space?.user_actions?.edit?.allowed && space?.user_actions?.edit?.reason) {
            return (
                space?.user_actions?.edit?.reason ||
                t('This space cannot be deleted', { ns: 'estate' })
            );
        }

        if (
            !space?.user_actions?.delete?.allowed &&
            space?.user_actions?.delete?.reason
        ) {
            return (
                space?.user_actions?.delete?.reason ||
                t('This space cannot be deleted', { ns: 'estate' })
            );
        }

        return '';
    }, [space, t]);

    return (
        <div className={styles.container}>
            <div className={styles.closeButtonContainer}>
                <Button
                    className={styles.closeButton}
                    color="primary"
                    data-cypress="close-panel"
                    onClick={closeSidebar}
                >
                    {t('Close', { ns: 'common' })}
                    <CloseIcon className={styles.closeIcon} />
                </Button>
            </div>
            <div className={styles.subcontainer}>
                <Icon className={classNames('far fa-cube', styles.headerIcon)} />
                {space ? (
                    <>
                        <div className={styles.spaceEditContainer}>
                            {/* Remove after EditableText is rewritten to TypeScript */}
                            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}

                            <Typography
                                className={styles.spaceName}
                                data-cypress="space-title"
                                variant="h6"
                            >
                                {space.name}
                            </Typography>
                        </div>
                    </>
                ) : (
                    <div className={styles.spaceEditContainerLoading}>
                        <Skeleton variant="text" width={200} />
                        <br />
                        <Skeleton variant="text" width={200} />
                    </div>
                )}

                <div className={styles.actionsContainer}>
                    {/* TODO: https://infogrid.atlassian.net/browse/VIZ-594 */}
                    {false && (
                        <>
                            <Button
                                color="primary"
                                data-cypress="space-move"
                                disabled={!!!space}
                                variant="outlined"
                            >
                                {t('Move')}
                            </Button>
                            <Button
                                color="primary"
                                data-cypress="space-copy"
                                disabled={!!!space}
                                variant="outlined"
                            >
                                {t('Copy')}
                            </Button>
                        </>
                    )}
                    {space && (
                        <Tooltip placement="top" title={disableDeleteSpaceReason}>
                            <div
                                className={styles.tooltipWrapper}
                                data-cypress="space-buttons"
                            >
                                <Button
                                    color="primary"
                                    data-cypress="space-edit-sidebar"
                                    disabled={!space?.user_actions.edit?.allowed}
                                    onClick={toggleEditModal}
                                    variant="outlined"
                                    className={styles.cta}
                                >
                                    {t('Edit')}
                                </Button>
                                <Button
                                    color="secondary"
                                    data-cypress="space-delete"
                                    disabled={
                                        !space?.user_actions.edit?.allowed ||
                                        !space?.user_actions.delete?.allowed
                                    }
                                    onClick={toggleDeleteModal}
                                    variant="outlined"
                                    className={styles.cta}
                                >
                                    {t('Delete')}
                                </Button>
                            </div>
                        </Tooltip>
                    )}
                </div>
                {space && (
                    <DeleteSpaceModal
                        buildingId={+buildingId}
                        floorId={+floorId}
                        onClose={toggleDeleteModal}
                        onConfirm={closeSidebar}
                        open={isDeleteModalOpen}
                        spaceId={space.id}
                    />
                )}
            </div>
        </div>
    );
};

export default memo(SpaceSidebarHeader);
