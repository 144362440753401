import { Header } from '@infogrid/components-material-ui';

import ConnectedRedirect from 'components/ConnectedRedirect';
import { SHARED_WITH_ME_FOLDER_ID } from 'utils/folder';

const FoldersRoot = () => {
    return (
        <>
            <Header />
            <ConnectedRedirect to={`/folders/${SHARED_WITH_ME_FOLDER_ID}`} />
        </>
    );
};

export default FoldersRoot;
