import type { SortDirection } from '@infogrid/core-types';
import type { Building } from '@infogrid/locations-types';
import orderBy from 'lodash/orderBy';

import type { BuildingScoreInfoBase } from '../../types';
import { getScoreDelta } from '../utils';
import type { HealthyBuildingInfo } from './types';

export const calculateSortDirection = (
    column: string,
    prevSortColumn: string,
    prevSortDirection: SortDirection | undefined,
): SortDirection => {
    return !prevSortColumn || prevSortColumn !== column || prevSortDirection === 'desc'
        ? 'asc'
        : 'desc';
};

export const mapBuildingsScores = (
    buildingsScores: {
        [key: number]: BuildingScoreInfoBase;
    },
    buildings: Building[],
): HealthyBuildingInfo[] => {
    return orderBy(
        Object.entries<BuildingScoreInfoBase>(buildingsScores).map(
            ([key, buildingInfo]) => {
                const buildingId = +key;

                return {
                    id: buildingId,
                    name: buildings.find((b) => b.id === buildingId)?.name || '',
                    score: buildingInfo.score_today,
                    delta: getScoreDelta(
                        buildingInfo.score_today,
                        buildingInfo.score_yesterday,
                    ),
                    historicalScores: buildingInfo.historical_scores.map(
                        ({ score }) => score,
                    ),
                };
            },
        ),
        ['score'],
        ['desc'],
    ).map((b, index) => ({ ...b, rank: index + 1 }));
};

export const sortBuildings = (
    buildings: HealthyBuildingInfo[],
    sortColumn: string,
    sortDirection: SortDirection | undefined,
): HealthyBuildingInfo[] => {
    if (!sortColumn || !sortDirection) {
        return buildings;
    }

    return orderBy(
        buildings,
        [
            (building) => {
                const field = building[sortColumn as keyof HealthyBuildingInfo];

                if (typeof field === 'string') {
                    return field.toLowerCase();
                }

                return field;
            },
        ],
        [sortDirection],
    );
};
