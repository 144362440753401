import { Modal } from '@infogrid/components-material-ui';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useStylesCreatingPreview } from './styles';

const CreatingPreview = memo(({ title, handleNext }) => {
    const styles = useStylesCreatingPreview();

    const { t } = useTranslation('floorplan');

    return (
        <>
            <Modal.Title>{title}</Modal.Title>

            <Modal.Description className={styles.description}>
                {t(
                    'To help you organize and structure your sensors and data we use a simple folder hierarchy.',
                )}
            </Modal.Description>

            <Modal.Description className={styles.description}>
                {t(
                    'Both ‘buildings’ and ‘floors’ also exist as folders. ' +
                        'When creating a new building or floor you can map them to new or existing folders. ',
                )}
            </Modal.Description>

            <Modal.Actions justify="right">
                <Button
                    color="primary"
                    variant="contained"
                    data-cypress="next"
                    onClick={handleNext}
                >
                    {t('Next')}
                </Button>
            </Modal.Actions>
        </>
    );
});

CreatingPreview.propTypes = {
    title: PropTypes.string,
    handleNext: PropTypes.func.isRequired,
};

CreatingPreview.defaultProps = {
    title: '',
};

export default CreatingPreview;
