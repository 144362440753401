import PropTypes from 'prop-types';

import { BreadcrumbShape } from './breadcrumbs';

export const FolderChildlessShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
});

export const FolderShape = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    subfolder_count: PropTypes.number,
    sensors_count: PropTypes.number,
    has_tools: PropTypes.bool.isRequired,
    current_user_permission: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.arrayOf(BreadcrumbShape).isRequired,
});
