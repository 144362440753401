import { Header } from '@infogrid/components-material-ui';

import NotFound from 'components/NotFound';
import PageContainer from 'components/PageContainer';
import withView from 'decorators/withView';

const PageNotFound = () => (
    <>
        <Header />
        <PageContainer>
            <NotFound />
        </PageContainer>
    </>
);

export default withView()(PageNotFound);
