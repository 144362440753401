import { makeStyles } from '@material-ui/core';

export const usePillarScoresStyles = makeStyles((theme) => ({
    aboutIcon: {
        overflow: 'visible',
        cursor: 'pointer',
        fontSize: 14,
        marginLeft: theme.spacing(0.75),
    },
    pillarName: {
        marginRight: 'auto',
    },
}));

export const usePillarScoresMenuItemStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

export const usePillarScoresProgressBubbleStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0, 1),
    },
}));
