import { makeStyles } from '@material-ui/core';

export const useWidgetOptionStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        color: theme.palette.common.gray1,
        display: 'flex',
        position: 'relative',
        '& > i': {
            fontSize: '13px',
            marginRight: theme.spacing(0.5),
        },
    },
}));
