import { useAppSelector } from '@infogrid/core-ducks';
import type { FC } from 'react';

import type { InstallSensorErrorResponse } from 'apiHooks/sensors/installation/hooks';
import { INSTALL_STEPS, selectInstallError } from 'ducks/installFlow';

import { useGoToStep } from '../utils/hooks/useGoToStep';
import InstallError from './InstallError';

const InstallErrorContainer: FC = () => {
    const error = useAppSelector(selectInstallError) as InstallSensorErrorResponse;

    const handleRestart = useGoToStep(INSTALL_STEPS.BARCODE_SCAN, { clearError: true });
    const handleRetry = useGoToStep(INSTALL_STEPS.INSTALL_PENDING);

    return (
        <InstallError
            errorTitle={error.user_title}
            errorMessage={error.user_message}
            onRetry={error.can_retry ? handleRetry : undefined}
            onRestart={!error.can_retry ? handleRestart : undefined}
        />
    );
};

export default InstallErrorContainer;
