import type { FloorSensor } from '@infogrid/locations-types';
import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import useSensorSignal from 'utils/hooks/useSensorSignal';
import { getSensorName } from 'utils/sensor';

import { useStylesMapTooltip } from './styles';
import type { TooltipConfig, TooltipItem } from './types';

export interface TooltipProps<T extends GenericSensorShape = FloorSensor> {
    config: TooltipConfig<T>;
    data: T;
}

function Tooltip<T extends GenericSensorShape = FloorSensor>({
    config,
    data,
}: TooltipProps<T>): JSX.Element | null {
    const { t, i18n } = useTranslation('floor-map');
    const styles = useStylesMapTooltip();

    const renderItem = (tooltipItemInfo: TooltipItem<T>, index: number): ReactNode => {
        const { item, shouldShowItem, type } = tooltipItemInfo;

        // Show by default if shouldShowItem is not provided
        if (shouldShowItem && !shouldShowItem(data)) {
            return null;
        }

        return (
            <Typography
                key={index}
                className={classNames(styles.tooltipLine, {
                    [styles.tooltipLinePrimary]: type === 'primary',
                    [styles.tooltipLineSecondary]: type === 'secondary',
                })}
                variant="body2"
                component="div"
            >
                {typeof item === 'function' ? item(data, t, i18n.language) : item}
            </Typography>
        );
    };

    const { isAvailable, isOnline, signalLevel } = useSensorSignal(data);

    if (!config.shouldShowTooltip(data)) {
        return null;
    }

    const { items, shouldShowSignalLevel, shouldShowOfflineLevelOnly } = config;

    return (
        <div className={styles.tooltip} data-testid="floor-map-tooltip">
            <Typography
                className={classNames(styles.sensorName, styles.tooltipLinePrimary)}
                variant="body2"
                component="div"
            >
                {getSensorName(data)}
            </Typography>

            {items.map(renderItem)}

            {shouldShowSignalLevel && isAvailable && (
                <Typography
                    className={classNames(
                        styles.tooltipLine,
                        styles.tooltipLineSecondary,
                    )}
                    variant="body2"
                    component="div"
                >
                    {shouldShowOfflineLevelOnly &&
                        !isOnline &&
                        `${t('Signal')}: ${t('Offline')}`}

                    {!shouldShowOfflineLevelOnly &&
                        t('Signal: {{ value }}', {
                            value: isOnline ? `${signalLevel}%` : t('Offline'),
                            defaultValue: isOnline
                                ? `Signal: ${signalLevel}%`
                                : `Signal: Offline`,
                        })}
                </Typography>
            )}
        </div>
    );
}

export default memo(Tooltip) as typeof Tooltip;
