import { makeStyles } from '@material-ui/core';

export const useAddFirstBuildingStyles = makeStyles((theme) => ({
    container: {
        // places form first on larger screens
        flexDirection: 'row-reverse',
    },
    card: {
        borderColor: theme.palette.common.shell,
    },
    cardHeader: {
        borderBottom: 'inherit',
        padding: theme.spacing(2, 3),
    },
    cardTitle: {
        marginBottom: 0,
    },
    cardContent: {
        padding: theme.spacing(3),
    },
    cardActions: {
        justifyContent: 'flex-end',
        padding: theme.spacing(2, 3),
        borderTop: `1px solid ${theme.palette.common.shell}`,
    },
    saveAndAddAnotherButton: {
        marginRight: theme.spacing(2),
    },
    saveButton: {
        minWidth: 90,
    },
    infoCard: {
        backgroundColor: theme.palette.primary.light,
        // quick rgba alpha transparency
        borderColor: `${theme.palette.primary.main}14`,
    },
    infoIcon: {
        marginRight: theme.spacing(3),
        overflow: 'visible',
        color: theme.palette.primary.main,
    },
}));
