import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMapHintsStyles } from './styles';

interface Tip {
    title: string;
    value: () => { key: string; description: string };
}

const tips: Tip[] = [
    {
        title: registerTranslationKey('rotate', { ns: 'floor-map' }),
        value: () => ({
            key: 'Shift',
            description: registerTranslationKey(' + mouse drag', { ns: 'floor-map' }),
        }),
    },
    {
        title: registerTranslationKey('move sensor', { ns: 'floor-map' }),
        value: () => {
            const os = navigator.platform;

            return {
                key: os.includes('Mac') ? 'Option' : 'Alt',
                description: registerTranslationKey(' + mouse click on sensor icon', {
                    ns: 'floor-map',
                }),
            };
        },
    },
];

const MapHints = () => {
    const mapHintsStyles = useMapHintsStyles();
    const { t } = useTranslation('floor-map');

    return (
        <section>
            {tips.map(({ title, value }) => {
                const { key, description } = value();

                return (
                    <div key={title} className={mapHintsStyles.hintRow}>
                        <Typography
                            component="span"
                            variant="body2"
                            className={mapHintsStyles.hintRowTitle}
                            data-cypress="tooltip"
                        >
                            {t(title)}
                        </Typography>
                        <Typography component="span" variant="body2">
                            <kbd>{key}</kbd>
                        </Typography>
                        <Typography component="span" variant="body2">
                            {t(description)}
                        </Typography>
                    </div>
                );
            })}
        </section>
    );
};

export default memo(MapHints);
