import { Typography } from '@material-ui/core';
import type { FormikErrors } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import RulesSection from 'views/solutionSettings/pages/smartCleaning/CleaningRules/components/RulesSection';

import type { BySpaceTypeModalFormValues } from '../../types';
import { useStyles } from './styles';

interface Props {
    errors?: FormikErrors<BySpaceTypeModalFormValues>;
    hidden: boolean;
    mightNeedCleaningValue?: number | string;
    mustBeCleanedValue?: number;
    setMightNeedCleaningValue: (value: number | 'N/A' | undefined) => void;
    setMustBeCleanedValue: (value: number | undefined) => void;
}

const SetRules = ({
    errors,
    hidden,
    mightNeedCleaningValue,
    mustBeCleanedValue,
    setMightNeedCleaningValue,
    setMustBeCleanedValue,
}: Props) => {
    const { t } = useTranslation('solutions');
    const styles = useStyles({ hideStage: hidden });

    return (
        <div className={styles.container}>
            <Typography variant="body2">
                {t(
                    'Finally, set the rule for cleaning your selected space types, based on their usage.',
                )}
            </Typography>
            <RulesSection
                mightNeedCleaningValue={mightNeedCleaningValue}
                mustBeCleanedValue={mustBeCleanedValue}
                setMightNeedCleaningValue={setMightNeedCleaningValue}
                setMustBeCleanedValue={setMustBeCleanedValue}
                errors={errors}
            />
        </div>
    );
};

export default memo(SetRules);
