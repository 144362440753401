import { Icon, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';

import { useListItemStyles } from './styles';

interface Props {
    icon: string;
    isLocked: boolean;
    name: string;
    onSelectItem: () => void;
    hubsAndSensorsCount: number;
}

const ListItem = ({ icon, isLocked, name, onSelectItem, hubsAndSensorsCount }: Props) => {
    const styles = useListItemStyles();

    return (
        <div className={styles.container} data-cypress="location-item">
            <div className={styles.subcontainer}>
                <Icon className={classNames(icon, styles.icon)} />
                <Typography
                    className={styles.label}
                    data-cypress="location-name"
                    variant="body2"
                >
                    {name}
                </Typography>
            </div>
            <div className={styles.subcontainer}>
                <Icon className={classNames('far fa-sensor', styles.icon)} />
                <Typography
                    className={classNames(styles.label, styles.sensorsCount)}
                    data-cypress="location-sensors-count"
                    variant="caption"
                >
                    {hubsAndSensorsCount}
                </Typography>
                {isLocked ? (
                    <Icon
                        className={classNames('fas fa-lock-alt', styles.icon)}
                        data-cypress="location-lock-icon"
                        data-testid="location-lock-icon"
                    />
                ) : (
                    <Icon
                        className={classNames(
                            'far fa-chevron-right',
                            styles.icon,
                            styles.arrowIcon,
                        )}
                        data-cypress="select-location-arrow"
                        data-testid="select-location-arrow"
                        onClick={onSelectItem}
                    />
                )}
            </div>
        </div>
    );
};

export default memo(ListItem);
