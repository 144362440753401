import { Button, Fade, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useIdentifySensorStyles } from '../styles';
import type { IdentifySensorHelpText } from '../types';

export interface IdentifyHelpTextProps {
    helpText?: IdentifySensorHelpText;
    isConfirmed: boolean;
    isConnected: boolean;
    onTryAgain: () => void;
}

const IdentifyHelpText = ({
    helpText,
    isConfirmed,
    isConnected,
    onTryAgain,
}: IdentifyHelpTextProps) => {
    const { t } = useTranslation('sensors');
    const styles = useIdentifySensorStyles();

    return helpText?.info?.length ? (
        <Fade in>
            <Alert
                severity={helpText.level}
                action={
                    !isConfirmed &&
                    !isConnected && (
                        <Button
                            color="secondary"
                            className={styles.tryAgainButton}
                            onClick={onTryAgain}
                        >
                            {t('Try again')}
                        </Button>
                    )
                }
            >
                {helpText?.title && <AlertTitle>{t(helpText?.title)}</AlertTitle>}
                {helpText?.info?.map((text, index) => (
                    <Typography key={index} className={styles.alertText} variant="body2">
                        {text}
                    </Typography>
                ))}
                {helpText?.code && (
                    <Typography className={styles.code} variant="caption">
                        {`Error code: ${helpText?.code}`}
                    </Typography>
                )}
            </Alert>
        </Fade>
    ) : null;
};

export default memo(IdentifyHelpText);
