import { ProgressRing, TrendDelta } from '@infogrid/components-material-ui';
import type { SortDirection } from '@infogrid/core-types';
import { Typography, Grid, Box } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { MIN_CHART_POINTS } from '../../../constants';
import TrendLineChart from '../../TrendLineChart';
import type { BuildingScoreInfo, HealthyBuildingInfo } from '../types';
import SortableHeaderCell from './SortableHeaderCell';
import { useDesktopContentStyles, useProgressRingStyles } from './styles';

interface Props {
    sortDirection: SortDirection | undefined;
    changeSortDirection: (column: string) => void;
    activeSortColumn: string;
    allBuildingsInfo: BuildingScoreInfo;
    buildingsInfo: HealthyBuildingInfo[];
    selectedBuildings: number[];
}

const DesktopContent = ({
    sortDirection,
    changeSortDirection,
    activeSortColumn,
    allBuildingsInfo,
    buildingsInfo,
    selectedBuildings,
}: Props) => {
    const styles = useDesktopContentStyles();
    const progressRingStyles = useProgressRingStyles();
    const selectedBuilding = selectedBuildings.length === 1 ? selectedBuildings[0] : null;

    const { t } = useTranslation('dashboard');

    return (
        <>
            <Grid
                container
                className={styles.allBuildingsContainer}
                alignItems="center"
                spacing={1}
            >
                <Grid item xs>
                    <Typography variant="body2" component="p" color="textPrimary">
                        {t('All buildings')}
                    </Typography>
                </Grid>

                <Grid item xs={2}>
                    <Box display="flex">
                        <ProgressRing
                            value={allBuildingsInfo.score}
                            fontSize={12}
                            size="small"
                            classes={progressRingStyles}
                        />
                        <TrendDelta fontSize={12} delta={allBuildingsInfo.delta} />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    {allBuildingsInfo.historicalScores.length >= MIN_CHART_POINTS ? (
                        <TrendLineChart
                            data={allBuildingsInfo.historicalScores}
                            className={styles.trendsChart}
                        />
                    ) : (
                        <Typography variant="body2" component="p" color="textSecondary">
                            {t('N/A')}
                        </Typography>
                    )}
                </Grid>
            </Grid>

            <Grid
                container
                alignItems="center"
                spacing={1}
                className={styles.tableHeader}
            >
                <Grid item className={styles.rankContainer}>
                    <SortableHeaderCell
                        name="rank"
                        sortDirection={sortDirection}
                        changeSortDirection={changeSortDirection}
                        activeSortColumn={activeSortColumn}
                    >
                        <Typography variant="body2" component="p" color="textSecondary">
                            {t('Rank')}
                        </Typography>
                    </SortableHeaderCell>
                </Grid>
                <Grid item xs>
                    <SortableHeaderCell
                        name="name"
                        sortDirection={sortDirection}
                        changeSortDirection={changeSortDirection}
                        activeSortColumn={activeSortColumn}
                    >
                        <Typography variant="body2" component="p" color="textSecondary">
                            {t('Building Name')}
                        </Typography>
                    </SortableHeaderCell>
                </Grid>

                <Grid item xs={2}>
                    <SortableHeaderCell
                        name="score"
                        sortDirection={sortDirection}
                        changeSortDirection={changeSortDirection}
                        activeSortColumn={activeSortColumn}
                    >
                        <Typography variant="body2" component="p" color="textSecondary">
                            {t('Score')}
                        </Typography>
                    </SortableHeaderCell>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2" component="p" color="textSecondary">
                        {t('30 day trend')}
                    </Typography>
                </Grid>
            </Grid>

            {buildingsInfo.map((building) => {
                const isSelected = building.id === selectedBuilding;

                return (
                    <Grid
                        key={building.id}
                        container
                        alignItems="center"
                        spacing={1}
                        className={classNames(styles.buildingContainer, {
                            [styles.buildingContainerSelected]: isSelected,
                        })}
                        data-testid="compare-building-row"
                    >
                        <Grid item className={styles.rankContainer}>
                            <Typography
                                variant="overline"
                                component="p"
                                color="textPrimary"
                                className={styles.rank}
                            >
                                {building.rank}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2" component="p" color="textPrimary">
                                {building.name || t('N/A')}
                            </Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <Box display="flex">
                                <ProgressRing
                                    value={building.score}
                                    fontSize={12}
                                    size="small"
                                    classes={progressRingStyles}
                                />
                                <TrendDelta fontSize={12} delta={building.delta} />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            {building.historicalScores.length >= MIN_CHART_POINTS ? (
                                <TrendLineChart
                                    data={building.historicalScores}
                                    className={styles.trendsChart}
                                />
                            ) : (
                                <Typography
                                    variant="body2"
                                    component="p"
                                    color="textSecondary"
                                >
                                    {t('N/A')}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};

export default memo(DesktopContent);
