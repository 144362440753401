import { THRESHOLD_TYPE } from '@infogrid/remote-monitoring-types';
import { Typography } from '@material-ui/core';
import type { FieldInputProps } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

interface ThresholdDescriptionProps {
    sensorCount: number;
    lowerField: FieldInputProps<number>;
    upperField: FieldInputProps<number>;
    unitSymbol: string;
    thresholdType: typeof THRESHOLD_TYPE[keyof typeof THRESHOLD_TYPE];
}

const ThresholdDescription = ({
    sensorCount,
    lowerField,
    upperField,
    unitSymbol,
    thresholdType,
}: ThresholdDescriptionProps) => {
    const { t } = useTranslation('solutions');

    const unsetDisplayText = t('(not set yet)');
    const min = lowerField.value ?? unsetDisplayText;
    const max = upperField.value ?? unsetDisplayText;

    const lessText = t('less');
    const moreText = t('more');
    const singleThresholdDisplay =
        thresholdType === THRESHOLD_TYPE.SINGLE_LESS_THAN ? lessText : moreText;
    const singleValueDisplay =
        thresholdType === THRESHOLD_TYPE.SINGLE_LESS_THAN ? max : min;

    return (
        <Typography variant="body2">
            {thresholdType === THRESHOLD_TYPE.RANGE && (
                <Trans t={t}>
                    Range threshold applied to <strong>{{ sensorCount }} pipes</strong>.
                    Temperature in these pipes{' '}
                    <strong>
                        must remain between {{ min }}-{{ max }}
                        {{ unitSymbol }} at all times
                    </strong>{' '}
                    during reporting period.
                </Trans>
            )}
            {thresholdType !== THRESHOLD_TYPE.RANGE && (
                <Trans t={t}>
                    Single threshold applied to <strong>{{ sensorCount }} pipes</strong>.
                    Temperature in these pipes{' '}
                    <strong>
                        must be {{ singleThresholdDisplay }} than {{ singleValueDisplay }}
                        {unitSymbol} at least once
                    </strong>{' '}
                    during reporting period.
                </Trans>
            )}
        </Typography>
    );
};

export default ThresholdDescription;
