import { all, call, fork, join, race } from 'redux-saga/effects';

import { SocketConnected, SocketDisconnected } from 'sagas/socket';
import { subscribeSocket } from 'sagas/socket/SubscribeSocket';

import { StreamNames } from './constance';
import { organizationControlWorker } from './control';
import { eventLogEventsWorker } from './eventLogEvents';
import { folderWorker } from './folder';
import { heartBeat } from './heartbeat';
import { reportWorker } from './report';
import { sensorWorker } from './sensor';
import { teamWorker } from './team';
import { userWorker } from './user';

function* SocketRouterWorker() {
    const tasks = yield all([
        fork(heartBeat),
        subscribeSocket(sensorWorker, {
            stream: StreamNames.Sensors,
            processAll: true,
        }),
        subscribeSocket(folderWorker, { stream: StreamNames.Folders }),
        subscribeSocket(reportWorker, { stream: StreamNames.Reports }),
        subscribeSocket(teamWorker, { stream: StreamNames.Team }),
        subscribeSocket(userWorker, { stream: StreamNames.User }),
        subscribeSocket(organizationControlWorker, {
            stream: StreamNames.Control,
        }),
        subscribeSocket(eventLogEventsWorker, {
            stream: StreamNames.EventLogEvents,
        }),
    ]);

    yield join(tasks);
}

export default function* SocketRouter() {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('Started SocketRouter');
    }

    while (true) {
        yield call(SocketConnected);

        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log('SocketRouter :: Connected');
        }

        yield race({
            disconnected: call(SocketDisconnected),
            worker: call(SocketRouterWorker),
        });

        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.log('SocketRouter :: Disconnected');
        }
    }
}
