import { makeStyles } from '@material-ui/core';

export const useConfigureWidgetModalStyles = makeStyles((theme) => ({
    description: {
        color: theme.palette.text.grey.default,
        paddingBottom: theme.spacing(4),
    },
    modalContent: {
        height: '450px',
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
        },
    },
    tabsFlexContainer: {
        borderBottom: `2px solid ${theme.palette.common.gray7}`,
    },
    spinner: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(1),
    },
    tab: {
        display: 'none',
    },
    tabActive: {
        display: 'block',
        height: '100%',
    },
}));

export const useTabClasses = makeStyles(() => ({
    root: {
        textTransform: 'uppercase',
    },
}));
