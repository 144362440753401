import { useContext, memo, useMemo, useCallback } from 'react';

import type { BaseMapItem, MapFeature } from '../types';
import { isDuplicateFeatureFromModify } from '../utils';
import Context from './MapContext';
import type { MapContext } from './MapContext';
import { useNonDragClick } from './hooks';

interface Props<T extends BaseMapItem> {
    onClick: (
        event: MouseEvent,
        coordinate: [number, number],
        feature?: MapFeature<T>,
    ) => void;
}

const ClickInteraction = <T extends BaseMapItem>({ onClick }: Props<T>) => {
    const { map } = useContext<MapContext>(Context);

    const viewPort = useMemo(() => map?.getViewport(), [map]);

    const handleClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();

            const coordinates = map?.getEventCoordinate(e);
            const pixel = coordinates && map?.getPixelFromCoordinate(coordinates);
            const feature =
                pixel &&
                (map
                    ?.getFeaturesAtPixel(pixel)
                    // We can assign here as !isDuplicateFeatureFromModify type guards for feature
                    // being correct data shape
                    .find(
                        (currentFeature) => !isDuplicateFeatureFromModify(currentFeature),
                    ) as MapFeature<T> | undefined);

            onClick(e, coordinates as [number, number], feature);
        },
        [map, onClick],
    );

    useNonDragClick(handleClick, viewPort);

    return null;
};

export default memo(ClickInteraction);
