export const BASE_DATE_FORMAT = '%a %e %b';
export const MONTH_DAY_FORMAT = '%a %e';
export const MONTHLY_DATE_FORMAT = '%b %Y';
export const FULL_DATE_FORMAT = '%a %e %b %Y';
export const HOURLY_DATE_FORMAT = '%a %e %b, <br/> %k:%M';
export const LONG_HOURLY_DATE_FORMAT = '%a %e %b %k:%M';

export type CHART_DATE_FORMAT =
    | typeof BASE_DATE_FORMAT
    | typeof MONTH_DAY_FORMAT
    | typeof MONTHLY_DATE_FORMAT
    | typeof FULL_DATE_FORMAT
    | typeof HOURLY_DATE_FORMAT
    | typeof LONG_HOURLY_DATE_FORMAT;

export const DEFAULT_DATE_FORMAT = FULL_DATE_FORMAT;

export const WORKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
export const WEEKEND_DAYS = ['Saturday', 'Sunday'];
