import { wrapResponsePromise } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { EventsWebhook, AxiosParsedError } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

import { controllers } from '../controllers';
import type { ToggleWebhookPayload } from '../types';

const useToggleEventsWebhook = (): UseMutationResult<
    EventsWebhook,
    AxiosParsedError,
    ToggleWebhookPayload
> => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('integrations');

    return useMutation<EventsWebhook, AxiosParsedError, ToggleWebhookPayload>(
        (data: ToggleWebhookPayload) =>
            wrapResponsePromise(controllers.updateEventsWebhook(data.webhookId, data)),
        {
            onSuccess: (data: EventsWebhook) => {
                const message = data.is_enabled
                    ? t("'{{webhookName}}' was successfully activated.", {
                          webhookName: data.name,
                      })
                    : t("'{{webhookName}}' was successfully deactivated.", {
                          webhookName: data.name,
                      });
                dispatch(toastSuccess({ message }));
            },
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error toggling events webhook'),
                    }),
                );
            },
        },
    );
};

export default useToggleEventsWebhook;
