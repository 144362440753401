import type { PipeType } from '@infogrid/sensors-constants';
import { PipeTypes } from '@infogrid/sensors-constants';
import type { TempUnit } from '@infogrid/utils-measurements';
import { getDisplayTemp, Celsius } from '@infogrid/utils-measurements';
import type { TFunction } from 'react-i18next';

import type { TableRowAttributes } from '../types';

const generateHotSubTypeAttribute = (
    subType: PipeType,
    tempUnit: TempUnit = Celsius.UNIT,
    t: TFunction,
) => ({
    id: subType,
    label: t(subType),
    tooltip: t(
        "Sensors with '{{subType}}' sub-type need to reach {{temperature}} in their report period or they will fail the temperature check in pipe monitoring overview report.",
        {
            subType,
            temperature: getDisplayTemp(50, tempUnit),
            defaultValue:
                `Sensors with '${subType}' sub-type need to reach ` +
                `${getDisplayTemp(
                    50,
                    tempUnit,
                )} in their report period or they will fail ` +
                'the temperature check in pipe monitoring overview report.',
        },
    ),
});

export const getTableRowsAttributes = (
    t: TFunction,
    tempUnit: TempUnit = Celsius.UNIT,
): TableRowAttributes[] => [
    {
        id: PipeTypes.COLD,
        label: t('Cold'),
        tooltip: t(
            "Sensors with 'Cold' sub-type need to drop below {{temperature}} in their report period or they will fail the temperature check in pipe monitoring overview report.",
            {
                temperature: getDisplayTemp(20, tempUnit),
                defaultValue:
                    "Sensors with 'Cold' sub-type need to drop below " +
                    `${getDisplayTemp(20, tempUnit)} in their report period ` +
                    'or they will fail the temperature check in pipe monitoring overview report.',
            },
        ),
    },
    {
        id: PipeTypes.COLD_STORAGE,
        label: t('Cold storage'),
        tooltip: t(
            "Sensors with 'Cold storage' sub-type need to drop below {{temperature}} in their report period or they will fail the temperature check in pipe monitoring overview report. Water moving is not checked for this sub-type.",
            {
                temperature: getDisplayTemp(20, tempUnit),
                defaultValue:
                    "Sensors with 'Cold storage' sub-type need to drop below " +
                    `${getDisplayTemp(20, tempUnit)} in their report period ` +
                    'or they will fail the temperature check in pipe monitoring overview report. ' +
                    'Water moving is not checked for this sub-type.',
            },
        ),
    },
    generateHotSubTypeAttribute(PipeTypes.HOT, tempUnit, t),
    generateHotSubTypeAttribute(PipeTypes.HOT_POU_HEATER, tempUnit, t),
    {
        id: PipeTypes.BLENDED,
        label: t('Blended'),
        tooltip: t(
            "Sensors with 'Blended' sub-type need to stay below {{temperature}} in their report period or they will fail the temperature check in pipe monitoring overview report.",
            {
                temperature: getDisplayTemp(43, tempUnit),
                defaultValue:
                    "Sensors with 'Blended' sub-type need to stay below " +
                    `${getDisplayTemp(43, tempUnit)} in their report period ` +
                    'or they will fail the temperature check in pipe monitoring overview report.',
            },
        ),
    },
    {
        id: PipeTypes.CALORIFIER_FLOW,
        label: t('Calorifier flow'),
        tooltip: t(
            "Sensors with 'Calorifier flow' sub-type need to stay above {{temperature}} in their report period or they will fail the temperature check in pipe monitoring overview report.",
            {
                temperature: getDisplayTemp(60, tempUnit),
                defaultValue:
                    "Sensors with 'Calorifier flow' sub-type need to stay above " +
                    `${getDisplayTemp(60, tempUnit)} in their report period ` +
                    'or they will fail the temperature check in pipe monitoring overview report.',
            },
        ),
    },
    {
        id: PipeTypes.CALORIFIER_RETURN,
        label: t('Calorifier return'),
        tooltip: t(
            "Sensors with 'Calorifier return' sub-type need to stay above {{temperature}} in their report period or they will fail the temperature check in pipe monitoring overview report.",
            {
                temperature: getDisplayTemp(50, tempUnit),
                defaultValue:
                    "Sensors with 'Calorifier return' sub-type need to stay above " +
                    `${getDisplayTemp(50, tempUnit)} in their report period ` +
                    'or they will fail the temperature check in pipe monitoring overview report.',
            },
        ),
    },
    {
        id: PipeTypes.TOTAL,
        label: t('Total'),
    },
];
