import PropTypes from 'prop-types';

export const TeamDetailResultShape = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
});

export const TeamShape = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(PropTypes.number),
});
