import { makeStyles } from '@material-ui/core';

export const useAddSpacesStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.shell}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: theme.spacing(3),
        padding: theme.spacing(10, 0),
        textAlign: 'center',
    },
    icon: {
        alignItems: 'center',
        color: theme.palette.common.gray4,
        display: 'flex',
        fontSize: 72,
        height: 78,
    },
    noSpacesDescription: {
        color: theme.palette.common.gray6,
        margin: theme.spacing(2, 0),
        maxWidth: '450px',
    },
}));
