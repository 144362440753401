import { usePendingLocation } from '@infogrid/utils-hooks';
import { locationsAreEqual } from 'history';
import type { CSSProperties, ReactNode } from 'react';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter, Route } from 'react-router-dom';

import LoadingBar from 'components/LoadingBar';
import type { LoadingState } from 'utils/loadingReducer';
import { getLoadedView, isLoading as getIsLoading } from 'utils/loadingReducer';

export interface PendingDataManagerProps {
    children?: ReactNode;
    disabled?: boolean;
    loadingBarStyle?: CSSProperties;
}

interface LocationStoreKeys {
    loadingKey: string | undefined;
    isLoading: boolean;
}
interface PendingDataManagerInternalProps
    extends PendingDataManagerProps,
        RouteComponentProps,
        LocationStoreKeys {}

const selectLoadingState = (state: LoadingState) => ({
    loadingKey: getLoadedView(state),
    isLoading: getIsLoading(state),
});

const PendingDataManagerBase = ({
    loadingKey,
    isLoading,
    location,
    children,
    disabled,
    loadingBarStyle,
}: PendingDataManagerInternalProps) => {
    const storedLocation = usePendingLocation(location, loadingKey, disabled);
    const isViewPending = !locationsAreEqual(storedLocation, location) && !disabled;

    return (
        <>
            <LoadingBar
                key="loading-bar"
                simulate={true}
                isLoading={isViewPending || isLoading}
                style={loadingBarStyle}
            />
            <Route key="route" location={storedLocation} render={() => children} />
        </>
    );
};

// We're using connect() here because if we try using useLocation, the location
// is will be 1 state out of date.
export default connect(selectLoadingState)(withRouter(PendingDataManagerBase));
