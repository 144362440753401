import { MemoizedDivider } from '@infogrid/components-material-ui';
import type { DataFrequency as DataFrequencyType } from '@infogrid/dashboards-constants';
import { FormControlLabel, Switch } from '@material-ui/core';
import type { FormikProps } from 'formik';
import type { Moment } from 'moment';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Container from 'views/dashboards/widgets/common/DisplayOptions/Container';
import DataFrequency from 'views/dashboards/widgets/common/DisplayOptions/DataFrequency';

import type { DeskOccupancyValues } from '../types';
import { useDisplayOptionsStyles } from './styles';

interface DisplayOptionsProps {
    calculateDisabledFrequencies: ({
        startDate,
        endDate,
    }: {
        startDate: Moment;
        endDate: Moment;
    }) => DataFrequencyType[];
    formik: FormikProps<DeskOccupancyValues>;
    frequencies: DataFrequencyType[];
}

const DisplayOptions = ({
    calculateDisabledFrequencies = () => [],
    formik,
    frequencies = [],
}: DisplayOptionsProps) => {
    const styles = useDisplayOptionsStyles();

    const { t } = useTranslation('dashboard');

    const { dataFrequency, showCountOfDesksOccupied } =
        formik.values.displayOptions || {};

    const { startDate, endDate } = formik.values.dateRange || {};

    const disabledFrequencies = useMemo(
        () => calculateDisabledFrequencies({ startDate, endDate }),
        [calculateDisabledFrequencies, startDate, endDate],
    );

    return (
        <Container>
            <DataFrequency
                className={styles.dataFrequency}
                disabledFrequencies={disabledFrequencies}
                frequencies={frequencies}
                handleChange={formik.handleChange}
                value={dataFrequency}
            />
            <MemoizedDivider />
            <FormControlLabel
                control={
                    <Switch
                        checked={showCountOfDesksOccupied}
                        color="primary"
                        id="showCountOfDesksOccupied"
                        name="displayOptions.showCountOfDesksOccupied"
                        onChange={formik.handleChange}
                    />
                }
                label={t('Show count of desks occupied')}
            />
        </Container>
    );
};

export default memo(DisplayOptions);
