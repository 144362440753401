import type { SortDirection } from '@infogrid/core-types';
import { TableSortLabel } from '@material-ui/core';
import { memo } from 'react';

import { useSortableHeaderCellStyles } from './styles';

interface Props {
    name: string;
    sortDirection: SortDirection | undefined;
    changeSortDirection: (column: string) => void;
    activeSortColumn: string;
    children: React.ReactNode;
}

const getAriaSort = (
    activeSortColumn: string,
    sortDirection: SortDirection | undefined,
    column: string,
) => {
    if (activeSortColumn !== column || !sortDirection) {
        return 'none';
    }

    return sortDirection === 'asc' ? 'ascending' : 'descending';
};

const SortableHeaderCell = ({
    sortDirection,
    changeSortDirection,
    activeSortColumn,
    name,
    children,
}: Props) => {
    const styles = useSortableHeaderCellStyles();

    const isActive = activeSortColumn === name;

    return (
        <div
            className={styles.container}
            onClick={() => {
                changeSortDirection(name);
            }}
            onKeyPress={() => {
                changeSortDirection(name);
            }}
            tabIndex={0}
            role="columnheader"
            aria-sort={getAriaSort(activeSortColumn, sortDirection, name)}
            data-testid="sortable-header-cell"
        >
            <TableSortLabel
                active={isActive}
                direction={isActive ? sortDirection : 'asc'}
            >
                {children}
            </TableSortLabel>
        </div>
    );
};

export default memo(SortableHeaderCell);
