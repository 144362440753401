import { Modal } from '@infogrid/components-material-ui';
import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFloorPlanParams, useFormToast } from 'apiHooks/base/hooks';
import { useCreateFloor } from 'apiHooks/floorPlan/floors/hooks';
import {
    toggleCreatingFloor,
    toggleCreatingFolder,
    selectCreatingFolderStatus,
} from 'ducks/floorPlan';

import CreateFolder from '../shared/CreateFolder';

const CreateFloorModal = () => {
    const { t } = useTranslation('floorplan');

    const dispatch = useAppDispatch();

    const { handleFormToast } = useFormToast();

    const isOpened = useAppSelector(selectCreatingFolderStatus);
    const { buildingId } = useFloorPlanParams();

    const onClose = useCallback(() => {
        dispatch(toggleCreatingFolder(false));
    }, [dispatch]);

    const openCreatingFloor = useCallback(() => {
        dispatch(toggleCreatingFloor(true));
    }, [dispatch]);

    const { mutate } = useCreateFloor(buildingId);

    const handleCreateFolder = useCallback(
        (submitValues, { setSubmitting, setErrors }) => {
            const payload = {
                name: submitValues.name,
                folder: buildingId,
            };

            mutate(payload, {
                onSuccess() {
                    openCreatingFloor();
                },
                onError(err) {
                    handleFormToast({
                        submitValues,
                        error: err?.parsedError,
                        formCallback({ errors }) {
                            setErrors(errors);
                        },
                    });
                },
                onSettled: () => {
                    setSubmitting(false);
                },
            });
        },
        [buildingId, mutate, openCreatingFloor, handleFormToast],
    );

    return (
        <Modal onClose={onClose} open={isOpened}>
            <CreateFolder
                title={t('Create New Floor')}
                description={t('Enter floor name')}
                label={t('Floor name')}
                handleBack={openCreatingFloor}
                handleCreateFolder={handleCreateFolder}
            />
        </Modal>
    );
};

export default memo(CreateFloorModal);
