import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useEditSpaceModalStyles = makeStyles((theme) => ({
    modalTitle: {
        alignItems: 'center',
        display: 'flex',
        '& > h2': {
            alignItems: 'center',
            display: 'flex',
        },
    },
    formContent: {
        padding: theme.spacing(3),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '500px',
        },
    },
    header: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
    capacityText: {
        margin: theme.spacing(1, 0, 2, 0),
    },
    capacityWarning: {
        margin: theme.spacing(1, 0, 2, 0),
    },
}));
