import { queryClient } from '@infogrid/core-api';

import { BUILDINGS_HIERARCHY_FOR_USER_QUERY_KEY } from 'apiHooks/base/queryKeys';

import {
    getBuildingAlertCountsKey,
    getBuildingsKey,
    getBuildingKey,
    getBuildingsFiltersKey,
    getPaginatedBuildingsKey,
    getBuildingsHierarchyForUserKey,
} from './getQueryKeys';

export const setBuildings = ({ data, options } = {}) => {
    queryClient.setQueryData(getBuildingsKey(), data, options);
};

export const setBuilding = ({ buildingId, data, options } = {}) => {
    queryClient.setQueryData(getBuildingKey(buildingId), data, options);
};

export const getBuildings = ({ filters } = {}) =>
    queryClient.getQueryData(getBuildingsKey(), filters);

export const getBuilding = ({ buildingId, filter } = {}) =>
    queryClient.getQueryData(getBuildingKey(buildingId), filter);

export const cancelBuildingQuery = ({ buildingId } = {}) =>
    queryClient.cancelQueries(getBuildingKey(buildingId));

export const cancelBuildingsQuery = ({ buildingId } = {}) =>
    queryClient.cancelQueries(getBuildingsKey(buildingId));

export const invalidateBuilding = ({ buildingId } = {}) =>
    queryClient.invalidateQueries(getBuildingKey(buildingId));

export const cancelBuildings = () => queryClient.cancelQueries(getBuildingsKey());

export const invalidateBuildings = (params = {}) =>
    queryClient.invalidateQueries(getBuildingsKey(params));

export const removeBuildingsQueries = (params = {}) =>
    queryClient.removeQueries(getBuildingKey(params));

export const cancelPaginatedBuildings = (params) =>
    queryClient.cancelQueries(getPaginatedBuildingsKey(params));

export const invalidatePaginatedBuildings = (params) =>
    queryClient.invalidateQueries(getPaginatedBuildingsKey(params));

export const invalidateBuildingsFilters = () =>
    queryClient.invalidateQueries(getBuildingsFiltersKey());

export const invalidateBuildingsHierarchyForUser = () => {
    queryClient.invalidateQueries(getBuildingsHierarchyForUserKey(), {
        exact: false,
    });
};

/**
 * @param {{ buildingId: number, params?: { sensor_type?: SensorType[] } }}
 */
export const invalidateBuildingAlertCounts = ({ buildingId, params }) => {
    queryClient.invalidateQueries(getBuildingAlertCountsKey(buildingId, params));
};
