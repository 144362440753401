import { makeStyles } from '@material-ui/core';

export const useIntegrationsEventsWebhookStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0 !important',
    },
    titleBar: {
        padding: '40px',
        flexWrap: 'wrap',
        marginBottom: '0 !important',
    },
    tabBar: {
        borderBottom: `1px solid ${theme.palette.common.shell}`,
    },
    childrenWrapper: {
        padding: '40px',
    },
}));
