import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useFloorListStyles = makeStyles((theme) => ({
    divider: {
        backgroundColor: theme.palette.common.shell,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    noFloorsLabel: {
        color: theme.palette.common.gray6,
        fontWeight: theme.typography.fontWeightRegular as FontWeightProperty,
        margin: `${theme.spacing(8)}px auto 0`,
        maxWidth: '75%',
        textAlign: 'center',
    },
}));
