import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { SENSOR_VENDORS, CONNECTION_TYPE } from '@infogrid/sensors-constants';
import type { SensorVendor } from '@infogrid/sensors-constants';

import useCurrentSensorState from './useCurrentSensorState';

interface SensorSignalInfo {
    isAvailable: boolean;
    isOnline: boolean | null;
    isBoostMode?: boolean;
    hasWiredConnection?: boolean;
    signalLevel?: number;
}

type VendorSignals = {
    [key in SensorVendor]?: SensorSignalInfo;
};

const vendorSignals: VendorSignals = {
    // WEB-3550 - we can't tell if VGS sensors are online or not
    [SENSOR_VENDORS.VGS]: { isAvailable: false, isOnline: null },
    [SENSOR_VENDORS.BTL]: { isAvailable: false, isOnline: null },
    [SENSOR_VENDORS.HLT]: { isAvailable: false, isOnline: null },
};

const useSensorSignal = (sensor: GenericSensorShape): SensorSignalInfo => {
    const sensorConfig = useCurrentSensorState(sensor);

    if (sensor.vendor && vendorSignals[sensor.vendor]) {
        const customVendorSignal = vendorSignals[sensor.vendor];

        if (customVendorSignal) {
            return customVendorSignal;
        }
    }

    if (
        !sensorConfig.isNetworkConnectionAvailable &&
        !sensorConfig.isOfflineStatusAvailable
    ) {
        return {
            isAvailable: false,
            isOnline: null,
        };
    }

    const isOnline = !!sensorConfig.getIsOnline();

    if (
        !sensorConfig.isNetworkConnectionAvailable &&
        sensorConfig.isOfflineStatusAvailable
    ) {
        return {
            isAvailable: false,
            isOnline,
        };
    }

    const signalTypeEvent = sensorConfig.getSignalTypeEvent();
    const isBoostMode = signalTypeEvent?.value === CONNECTION_TYPE.RADIO_HIGH_POWER;
    const hasWiredConnection = sensorConfig.hasWiredConnection();
    const signalLevel = isOnline ? sensorConfig.getSignalLevel() : 0;

    // Do not show signal level when virtual sensor is assumed to be online
    const notAvailable =
        sensorConfig.isOnlineIfNoSignalEvent && !signalTypeEvent && !signalLevel;

    return {
        isAvailable: !notAvailable,
        isOnline,
        isBoostMode,
        hasWiredConnection,
        signalLevel,
    };
};

export default useSensorSignal;
