import { makeStyles } from '@material-ui/core';

import { QR_CODE_SIZE_PX } from '../consts';

export const useViewFinderStyles = makeStyles((theme) => ({
    viewFinder: {
        zIndex: 1,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: QR_CODE_SIZE_PX,
        height: QR_CODE_SIZE_PX,
        boxShadow: '0 0 0 30rem rgba(0, 0, 0, 0.4)',
    },
    viewFinderCorner: {
        position: 'absolute',
        width: theme.spacing(4),
        height: theme.spacing(4),
        borderColor: 'white',
        borderStyle: 'solid',
        borderRadius: '2px',
    },
    viewFinderTopLeft: {
        top: '-2px',
        left: '-2px',
        borderWidth: theme.spacing(0.5, 0, 0, 0.5),
    },
    viewFinderTopRight: {
        top: '-2px',
        right: '-2px',
        borderWidth: theme.spacing(0.5, 0.5, 0, 0),
    },
    viewFinderBottomRight: {
        bottom: '-2px',
        right: '-2px',
        borderWidth: theme.spacing(0, 0.5, 0.5, 0),
    },
    viewFinderBottomLeft: {
        bottom: '-2px',
        left: '-2px',
        borderWidth: theme.spacing(0, 0, 0.5, 0.5),
    },
}));
