import { Box, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { InstallationFlowComponentProps } from 'components/InstallationFlow/types';
import { InstallationFlowBEASerial } from 'utils/Images';

import { useInstallationFlowStyles } from '../../styles';
import DeviceIdentifier from '../DeviceIdentifier';
import { useDeviceIdentifierStyles } from './styles';

type Props = Pick<InstallationFlowComponentProps, 'errorMessage'>;

const BEAIdentifier = ({ errorMessage }: Props) => {
    const { t } = useTranslation('sensors');
    const installationFlowStyles = useInstallationFlowStyles();
    const deviceIdentifierStyles = useDeviceIdentifierStyles();

    return (
        <DeviceIdentifier
            errorMessage={errorMessage}
            renderFormContent={({ onChangeSerialNumber, errorText }) => (
                <Box>
                    <Typography
                        variant="body1"
                        data-cypress="instruction-text"
                        className={installationFlowStyles.paragraph}
                    >
                        {t(
                            'Enter the device ID on the QR code label, which can be found either on the front of the device (inside the white casing), or there should be a loose label in the packaging.',
                        )}
                    </Typography>
                    <Box className={deviceIdentifierStyles.helpImageContainer}>
                        <img
                            src={InstallationFlowBEASerial}
                            alt="Pointer to BEA device ID"
                            className={classNames(
                                installationFlowStyles.centeredImage,
                                deviceIdentifierStyles.image,
                            )}
                        />
                        <Typography align="center" variant="body1">
                            <span className={deviceIdentifierStyles.serialNumberExample}>
                                {t('Example ID: ')}
                            </span>
                            <span>H581F1</span>
                        </Typography>
                    </Box>
                    <TextField
                        fullWidth
                        label={t('Device ID')}
                        variant="outlined"
                        name="serialNumber"
                        onInput={onChangeSerialNumber}
                        error={!!errorText}
                        helperText={errorText}
                        className={deviceIdentifierStyles.serialNumberInput}
                        data-cypress="serial-number"
                    />
                </Box>
            )}
        />
    );
};

export default memo(BEAIdentifier);
