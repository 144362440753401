import { makeStyles } from '@material-ui/core';

export const useFloorPlanStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
    },
    progress: {
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
    },
}));
