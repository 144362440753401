import { theme } from '@infogrid/components-material-ui';
import { MIN_SENSOR_SCALE } from '@infogrid/sensors-constants';
import type { Properties } from 'csstype';

import {
    HUB_BORDER_RADIUS,
    OPAQUE_BORDER_VARIANTS,
    SENSOR_ICON_SCALE,
    SENSOR_ICON_SIZES,
    SENSOR_ICON_STYLES,
    SENSOR_TYPE_ICONS,
} from './consts';
import type { SensorIconParts, SensorIconProps } from './types';

export const getSensorIcon = ({
    type,
    size = 'medium',
    variant = 'blue',
    state = 'default',
    isHub = false,
    isOffline = false,
    isAlert = false,
    isDirectional = false,
    rotation = 0,
    scale = 0,
}: SensorIconProps): SensorIconParts => {
    const iconVariant = isOffline && variant === 'blue' ? 'offline' : variant;
    const { color, fill, strokeDasharray } = SENSOR_ICON_STYLES[iconVariant][state];
    const { color: lobeColor, strokeDasharray: lobeStrokeDasharray } =
        SENSOR_ICON_STYLES[iconVariant].default;
    const IconComponent = (type && SENSOR_TYPE_ICONS[type]) || null;
    const iconSize = SENSOR_ICON_SIZES[size];
    const borderRadius = isHub ? HUB_BORDER_RADIUS[size] : iconSize;
    const strokeOpacity = OPAQUE_BORDER_VARIANTS.includes(iconVariant) ? 1 : 0.5;
    const strikethruOffset = isHub ? 6 : 8.5;
    const alertDotPosition = isHub ? 6 : 8;
    const centerStyle: Properties = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    // Frame for the border and background fill
    const sensorIconFrame = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconSize}
            height={iconSize}
            viewBox={`0 0 40 40`}
            style={centerStyle}
            data-icon-part="frame"
        >
            <rect
                fill={fill}
                width={38}
                height={38}
                x="1"
                y="1"
                rx={borderRadius}
                stroke={color}
                strokeWidth={1}
                strokeDasharray={strokeDasharray}
                strokeOpacity={strokeOpacity}
            />
        </svg>
    );

    const extras = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconSize * (11 / 10)}
            height={iconSize * (11 / 10)}
            viewBox={`0 0 44 44`}
            style={centerStyle}
            data-icon-part="extras"
        >
            {isAlert && (
                <circle
                    r={6}
                    fill={theme.palette.common.red2}
                    cx={44 - alertDotPosition}
                    cy={alertDotPosition}
                />
            )}
            {isOffline && (
                <line
                    x1={strikethruOffset}
                    y1={strikethruOffset}
                    x2={44 - strikethruOffset}
                    y2={44 - strikethruOffset}
                    stroke={color}
                    strokeWidth={2}
                />
            )}
        </svg>
    );

    // Draws the view of the sensor
    const scaleValue = scale || MIN_SENSOR_SCALE;
    const drawCentre = scaleValue / 2;
    const drawAspect = 4 / 3;
    const lobeYOffset = (1 / (2 * drawAspect)) * drawCentre;
    const sensorIconView =
        scaleValue && isDirectional ? (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={scaleValue}
                height={scaleValue}
                viewBox={`0 0 ${scaleValue} ${scaleValue}`}
                style={centerStyle}
                data-icon-part="lobes"
            >
                <g transform={`rotate(${rotation} ${drawCentre} ${drawCentre})`}>
                    <ellipse
                        cx={drawCentre}
                        cy={drawCentre - lobeYOffset}
                        rx={drawCentre / 2}
                        ry={drawCentre / (2 * drawAspect)}
                        fill={lobeColor}
                        fillOpacity={0.33}
                        stroke={lobeColor}
                        strokeWidth={1}
                        strokeDasharray={lobeStrokeDasharray}
                        strokeOpacity={strokeOpacity}
                    />
                    <path
                        d={`M ${drawCentre * 0.92} ${
                            drawCentre - lobeYOffset + drawCentre * 0.07
                        }
                        L ${drawCentre} ${drawCentre - lobeYOffset - drawCentre * 0.07}
                        L ${drawCentre * 1.08} ${
                            drawCentre - lobeYOffset + drawCentre * 0.07
                        }
                        Z`}
                        fill={lobeColor}
                        stroke={lobeColor}
                        strokeWidth={1}
                        strokeOpacity={0.33}
                    />
                    <ellipse
                        cx={drawCentre}
                        cy={drawCentre + lobeYOffset}
                        rx={drawCentre / 2}
                        ry={drawCentre / (2 * drawAspect)}
                        fill={lobeColor}
                        fillOpacity={0.33}
                        stroke={lobeColor}
                        strokeWidth={1}
                        strokeDasharray={lobeStrokeDasharray}
                        strokeOpacity={strokeOpacity}
                    />
                    <circle
                        cx={drawCentre}
                        cy={drawCentre + lobeYOffset}
                        r={scaleValue * 0.03}
                        fill={lobeColor}
                        stroke={lobeColor}
                        strokeWidth={1}
                    />
                </g>
            </svg>
        ) : (
            <svg></svg>
        );

    return {
        iconSize,
        sensorIconFrame,
        extras,
        sensorIconView,
        sensorIcon: IconComponent ? (
            <IconComponent
                color={color}
                width={24}
                style={{
                    ...centerStyle,
                    transform: `${centerStyle.transform} scale(${SENSOR_ICON_SCALE[size]})`,
                }}
            />
        ) : (
            <></>
        ),
    };
};
