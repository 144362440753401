import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';

import FoldersPicker from './FoldersPicker';

const FolderPickerContainer = ({
    folders,
    typeSelector,
    onSelectFolders,
    onCreateNewFolder,
    isFetching,
    selectedFoldersIds,
}) => {
    const isAllSelected = useMemo(
        () =>
            folders?.length === selectedFoldersIds?.length &&
            selectedFoldersIds?.length > 0,
        [folders, selectedFoldersIds],
    );

    const handleAllFoldersSelect = useCallback(
        (e) => {
            const allSelected = e.target.checked;
            let selected = [];

            if (allSelected) {
                selected = folders.map((folder) => folder.id);
            }

            onSelectFolders({
                newSelectedIds: selected,
                oldSelectedIds: selectedFoldersIds,
                isAllSelected: allSelected,
            });
        },
        [folders, selectedFoldersIds, onSelectFolders],
    );

    const handleFolderSelect = useCallback(
        (id) => {
            let selectedIds = [];

            if (selectedFoldersIds.includes(id)) {
                selectedIds = selectedFoldersIds.filter((folderId) => folderId !== id);
            } else {
                selectedIds = [...selectedFoldersIds, id];
            }

            onSelectFolders({
                newSelectedIds: selectedIds,
                oldSelectedIds: selectedFoldersIds,
                isAllSelected,
            });
        },
        [selectedFoldersIds, onSelectFolders, isAllSelected],
    );

    return (
        <FoldersPicker
            folders={folders}
            isFetchingFolders={isFetching}
            onSelectFolder={handleFolderSelect}
            onSelectAllFolders={handleAllFoldersSelect}
            onCreateNewFolder={onCreateNewFolder}
            selectedFoldersIds={selectedFoldersIds}
            typeSelector={typeSelector}
            isAllSelected={isAllSelected}
        />
    );
};

FolderPickerContainer.propTypes = {
    typeSelector: PropTypes.func,
    onCreateNewFolder: PropTypes.func,
    onSelectFolders: PropTypes.func,
    isFetching: PropTypes.bool,
    selectedFoldersIds: PropTypes.arrayOf(PropTypes.number),
    folders: PropTypes.arrayOf(PropTypes.object),
};

FolderPickerContainer.defaultProps = {
    typeSelector: (folder) => folder.floorplan_type,
    onCreateNewFolder: () => {},
    onSelectFolders: () => {},
    selectedFoldersIds: [],
    isFetching: false,
    folders: [],
};

export default memo(FolderPickerContainer);
