import type { Floor } from '@infogrid/locations-types';
import { CircularProgress } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ListItem from '../components/ListItem';
import ListTitle from '../components/ListTitle';
import { useFloorsListStyles } from './styles';

interface Props {
    disablePermissionLock?: boolean;
    excludeHubCount?: boolean;
    floors: Floor[];
    isLoading: boolean;
    setSelectedFloor: (floor: Floor | null) => void;
}

const FloorsList = ({
    disablePermissionLock = false,
    excludeHubCount = false,
    floors,
    isLoading,
    setSelectedFloor,
}: Props) => {
    const styles = useFloorsListStyles();

    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <ListTitle dataCypress="floors-count">
                {t('{{count}} floors', {
                    count: floors.length,
                    defaultValue___one: `${floors.length} floor`,
                    defaultValue___other: `${floors.length} floors`,
                })}
            </ListTitle>
            {!isLoading && (
                <div className={styles.list}>
                    {floors.map((floor) => {
                        const hubsCount = (!excludeHubCount && floor?.hubs_count) || 0;
                        const hubsAndSensorsCount = floor.sensors_count + hubsCount;

                        return (
                            <ListItem
                                icon="far fa-layer-group"
                                isLocked={
                                    !disablePermissionLock &&
                                    !floor?.user_actions?.edit?.allowed
                                }
                                key={floor.id}
                                name={floor.name}
                                onSelectItem={() => setSelectedFloor(floor)}
                                hubsAndSensorsCount={hubsAndSensorsCount}
                            />
                        );
                    })}
                </div>
            )}
            {isLoading && (
                <div className={styles.spinnerWrapper}>
                    <CircularProgress color="primary" />
                </div>
            )}
        </div>
    );
};

export default memo(FloorsList);
