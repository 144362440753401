import { isArray } from '../is';
import { BaseValidationError, bindAndCoerce } from './BaseValidationError';
import { ParentValidationError } from './ParentValidationError';

export class ListValidationError extends ParentValidationError {
    constructor(errors, nonFieldErrors = null) {
        if (process.env.NODE_ENV !== 'production') {
            // Takes: anything thats not a ValidationError (besides null)
            const filterFn = (x) => {
                if (x === null) {
                    return false;
                }

                return !x || !(x instanceof BaseValidationError);
            };

            if (errors) {
                /* eslint-disable no-console */
                if (!isArray(errors) || errors.filter(filterFn).length > 0) {
                    console.error(
                        'ListValidationError: `errors argument` must be an array of BaseValidationError? instances',
                    );
                    console.error(
                        '    Supported Builtins: null/SingleValidationError/ListValidationError/ValidationError',
                    );
                }
            }
        }

        // MAP: falsy to null, bind all errors w/ their fieldName
        super((errors || []).map(bindAndCoerce));
        this.nonFieldErrors = nonFieldErrors || null;

        if (this.nonFieldErrors !== null) {
            this.nonFieldErrors.bindToField('nonFieldErrors');
        }
    }

    hasError() {
        return this.errors.filter((x) => x && x.hasError && x.hasError()).length > 0;
    }

    asString(glue = '; ') {
        return this.errors
            .map((value, key) => `${key}: ${value ? value.asString() : null}`)
            .join(glue);
    }
}
