import { ListValidationError, SingleValidationError, defaultMessages } from './errors';

const isValidationError = (error) => error.isValidationError;

const isStatusCodeError = (error) => error.isInvalidResponseCode;

const isNetworkError = (error) => error.isNetworkError;

export function reduceNestedErrors(error) {
    if (!error || !error.hasError()) {
        return null;
    }

    if (error instanceof SingleValidationError) {
        return error.toString();
    }

    if (error instanceof ListValidationError) {
        return error.errors.map(reduceNestedErrors);
    }

    const errors = Object.values(error.errors);

    return errors
        .map((e) => ({ field: e.fieldName, error: e }))
        .reduce(
            (result, current) => ({
                ...result,
                [current.field]: reduceNestedErrors(current.error),
            }),
            {},
        );
}

export function formErrorsHandler(options) {
    const { error, messages = defaultMessages } = options;

    if (isNetworkError(error)) {
        return { message: messages.network };
    }

    if (isStatusCodeError(error)) {
        return { message: messages.invalidResponseCode };
    }

    if (isValidationError(error)) {
        const { nonFieldErrors } = error.errors;

        const values = {};

        if (nonFieldErrors) {
            values.message = nonFieldErrors.toString();
        }

        const fields = error.errors
            .filter((e) => e.fieldName !== 'nonFieldErrors')
            .map((e) => ({ field: e.fieldName, error: e }))
            .reduce((result, current) => {
                const currentError = reduceNestedErrors(current.error);

                if (currentError === null) {
                    return null;
                }

                return { ...result, [current.field]: currentError };
            }, {});

        if (fields) {
            values.errors = fields;
        }

        return { ...values };
    }

    return { message: `${error}` };
}
