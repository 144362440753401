import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { useNavbarTextStyles } from './styles';

const NavbarText = ({ text, navbarOpened, truncate }) => {
    const navbarTextClasses = useNavbarTextStyles();

    const typographyClasses = classNames(navbarTextClasses.root, {
        [navbarTextClasses.large]: navbarOpened,
    });

    return (
        <Typography className={typographyClasses} noWrap={truncate}>
            {text}
        </Typography>
    );
};

NavbarText.propTypes = {
    navbarOpened: PropTypes.bool,
    text: PropTypes.string.isRequired,
    truncate: PropTypes.bool,
};

NavbarText.defaultProps = {
    truncate: false,
    navbarOpened: false,
};

export default memo(NavbarText);
