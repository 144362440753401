import queryString from 'qs';

import { getBaseUrl } from 'utils/axios';

export const EVENT_LOG_TYPES = {
    UNKNOWN: 'UNKNOWN',
    COMMENT: 'COMMENT',
    USER_ACTION: 'USER_ACTION',
};

export const USER_ACTION_TYPES = {
    FIELD_CHANGE: 'FIELD_CHANGE',
};

interface MutateEventLogArgs {
    params: {
        deviceName: string;
    };
}

// TODO based on looking at backend code and as such, there may be inaccuracies:
interface CommentEvent {
    comment_content: string;
    user_name: string;
    commented_by_deleted_user: boolean;
    id: number;
    event_time: Date | null;
}

// TODO based on looking at backend code and as such, there may be inaccuracies:
interface UserActionEvent {
    user_name: string;
    user_action_type: string;
    changed_field_name: string;
    changed_field_old_value: string;
    changed_field_new_value: string;
    action_by_deleted_user: boolean;
    id: number;
    event_time: Date | null;
}

interface MutatedCommentEvent extends CommentEvent {
    deviceName: string;
}

interface MutatedUserActionEvent extends UserActionEvent {
    deviceName: string;
}

export const mutateEventLog = (
    eventLogEvent: CommentEvent | UserActionEvent,
    { params: { deviceName } }: MutateEventLogArgs,
): MutatedCommentEvent | MutatedUserActionEvent => ({
    ...eventLogEvent,
    deviceName,
    event_time: eventLogEvent.event_time ? new Date(eventLogEvent.event_time) : null,
});

export const createDownloadLink = (
    deviceName: string,
    fromTime: Date,
    toTime: Date,
): string => {
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}sensors/${deviceName}/event_log/download`;

    const query = queryString.stringify({
        from_time: fromTime.toISOString(),
        to_time: toTime.toISOString(),
    });

    return `${url}?${query}`;
};
