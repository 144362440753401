import {
    useGetCurrentOccupancyData,
    useOccupiedMeetingRooms,
} from '@infogrid/solution-views-occupancy';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { PublicDisplayWidget } from '../PublicDisplayWidget';
import { Fraction } from '../PublicDisplayWidget/valueTypes';
import { REFETCH_INTERVAL } from './constants';
import { usePublicDisplayWidgetGridStyles } from './styles';
import { calculateFreeDesks, calculateFreeMeetingRooms } from './utils';

interface PublicDisplayWidgetGridProps {
    buildingId: number;
    floorId: number;
}

export const PublicDisplayWidgetGrid = ({
    buildingId,
    floorId,
}: PublicDisplayWidgetGridProps): JSX.Element => {
    const styles = usePublicDisplayWidgetGridStyles();

    const { t } = useTranslation();

    const params = { location: `${buildingId}:${floorId}` };
    const options = { refetchInterval: REFETCH_INTERVAL };

    const { data: deskUsageData } = useGetCurrentOccupancyData(params, options);
    const { data: occupiedMeetingRoomData } = useOccupiedMeetingRooms(params, options);

    return (
        <Grid className={styles.container} container spacing={10}>
            <Grid item xs={12}>
                <PublicDisplayWidget
                    icon="far fa-chair-office"
                    title={t('Desks available')}
                >
                    <Fraction
                        total={deskUsageData?.total}
                        value={calculateFreeDesks(deskUsageData)}
                    />
                </PublicDisplayWidget>
            </Grid>
            <Grid item xs={12}>
                <PublicDisplayWidget
                    icon="far fa-users-class"
                    title={t('Meeting rooms available')}
                >
                    <Fraction
                        total={occupiedMeetingRoomData?.total}
                        value={calculateFreeMeetingRooms(occupiedMeetingRoomData)}
                    />
                </PublicDisplayWidget>
            </Grid>
        </Grid>
    );
};
