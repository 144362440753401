import { queryClient } from '@infogrid/core-api';
import map from 'lodash/map';

import { setSensor } from '../accessors';
import { SINGLE_SENSORS_KEYS_GETTERS } from './constants';

export const updateSingleSensor = (sensorsMap) => {
    const queryCache = queryClient.getQueryCache();

    const queryKeys = map(sensorsMap, (sensor) =>
        SINGLE_SENSORS_KEYS_GETTERS.map((getKey) => getKey(sensor.device_name)),
    ).flat();

    queryKeys.forEach((listKey) => {
        const queries = queryCache.findAll(listKey, { active: true, exact: true });

        queries.forEach(({ state, queryKey }) => {
            const sensor = sensorsMap[state.data.device_name];

            if (sensor) {
                setSensor(sensor, queryKey);
            }
        });
    });
};
