import { useFormik } from 'formik';
import { memo } from 'react';

import { useCreateFloor } from 'apiHooks/floorPlan/floors/hooks';

import AddFloorModal from './AddFloorModal';
import { addFloorValidationSchema } from './consts';
import type { AddFloorModalValues } from './types';

interface Props {
    buildingId: number;
    enableSaveAndAddAnother?: boolean;
    onClose: () => void;
    open: boolean;
}

const AddFloorModalContainer = ({
    buildingId,
    enableSaveAndAddAnother = false,
    onClose,
    open,
}: Props) => {
    const { mutate: createFloor } = useCreateFloor(buildingId);

    const formik = useFormik<AddFloorModalValues>({
        initialValues: {
            name: '',
            actionType: 'save',
            occupant_capacity: undefined,
        },
        onSubmit(values, { resetForm, setSubmitting }) {
            setSubmitting(true);

            const payload = {
                name: values.name,
                folder: buildingId,
                occupant_capacity: values.occupant_capacity,
            };

            createFloor(
                /* Remove after useEditFloor hook is rewritten to TypeScript */
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                /* @ts-ignore */
                payload,
                {
                    onSuccess() {
                        setSubmitting(false);

                        if (values.actionType === 'add-another') {
                            resetForm();
                        } else {
                            onClose();
                        }
                    },
                    onError() {
                        setSubmitting(false);
                    },
                },
            );
        },
        validationSchema: addFloorValidationSchema,
    });

    return (
        <AddFloorModal
            enableSaveAndAddAnother={enableSaveAndAddAnother}
            formik={formik}
            onClose={onClose}
            open={open}
        />
    );
};

export default memo(AddFloorModalContainer);
