import { useSpaceTypes } from '@infogrid/locations-api';
import { selectSpaceTypes } from '@infogrid/locations-ducks';
import type { FloorDetail } from '@infogrid/locations-types';
import type { SensorShape } from '@infogrid/sensors-constants';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { CircularProgress } from '@material-ui/core';
import { useMemo } from 'react';

import { useMappedSensors } from 'apiHooks/floorPlan/sensors/hooks';
import getAppURI from 'utils/getAppURI';

import { PublicDisplayFloorMap } from './PublicDisplayFloorMap';
import { useFloorPlanStyles } from './styles';

interface PublicDisplayFloorPlanProps {
    floor: FloorDetail;
}

export const PublicDisplayFloorPlan = ({
    floor,
}: PublicDisplayFloorPlanProps): JSX.Element => {
    const styles = useFloorPlanStyles();

    const { mappedSensors, isLoading: areSensorsLoading } = useMappedSensors(
        { floorId: floor.id },
        {
            refetchOnMount: true,
            refetchInterval: 30 * 1000, // 30 seconds
        },
    );

    const sensorsWithCoordinates = useMemo(() => {
        if (!floor?.sensors || !mappedSensors.length) {
            return [];
        }

        const sensorsById = mappedSensors.reduce<Record<number, SensorShape>>(
            (a, x) => ({ ...a, [x.id]: x }),
            {},
        );

        // @ts-expect-error: Fix types later
        return floor.sensors.reduce((accum, { sensor_id, x, y }) => {
            if (!sensor_id || !sensorsById[sensor_id]) {
                return accum;
            }

            return [
                ...accum,
                {
                    ...sensorsById[sensor_id],
                    can_edit: false,
                    coordinates: { x, y },
                },
            ];
        }, []);
    }, [floor, mappedSensors]);

    const { data: spaceTypes, isLoading: isLoadingSpaceTypes } = useSpaceTypes({
        select: selectSpaceTypes,
        refetchOnMount: false,
    });

    if (!isLoadingSpaceTypes && spaceTypes === undefined) {
        throw new Error('You have to load the space types in the base component');
    }

    return (
        <div className={styles.container}>
            {areSensorsLoading && (
                <div className={styles.progress}>
                    <CircularProgress color="primary" size={40} />
                </div>
            )}
            {!areSensorsLoading && (
                <PublicDisplayFloorMap
                    activeSensorTypes={[
                        SENSOR_TYPE.TYPE_DESK_OCCUPANCY,
                        SENSOR_TYPE.TYPE_SPACE,
                    ]}
                    imageHeight={floor.image?.height ?? 0}
                    imageUrl={getAppURI(floor.image?.image_file ?? '')}
                    imageWidth={floor.image?.width ?? 0}
                    orientation={0}
                    sensorIconScale={1}
                    // @ts-expect-error: Fix types later
                    sensors={sensorsWithCoordinates}
                    spaceTypes={spaceTypes ?? []}
                    zoom={2.8}
                />
            )}
        </div>
    );
};
