import { makeStyles } from '@material-ui/core';

export const useSubtreeActionStyles = makeStyles((theme) => ({
    actionListItem: {
        padding: `${theme.spacing(0.25)} ${theme.spacing(0.75)}`,

        '& > .MuiTreeItem-content > .MuiTreeItem-label': {
            paddingLeft: theme.spacing(2.25),
        },

        '& .MuiTreeItem-content:hover': {
            backgroundColor: 'unset',
        },
    },
    actionButton: {
        padding: `0 ${theme.spacing(1)}px`,
    },
    actionButtonIcon: {
        fontSize: 21,
    },
}));
