import { makeStyles } from '@material-ui/core';

export const useLocationsTableStyles = makeStyles((theme) => ({
    bubble: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        display: 'inline-block',
        backgroundColor: theme.palette.common.black,
        marginRight: theme.spacing(0.5),
        marginLeft: '0.5em',
    },
    GOOD: {
        backgroundColor: theme.palette.success.main,
    },
    FAIR: {
        backgroundColor: theme.palette.common.yellow2,
    },
    POOR: {
        backgroundColor: theme.palette.secondary.main,
    },
    locationName: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    locationCount: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textAlign: 'right',
    },
    ellipsis: {
        position: 'relative',
        '&:before': {
            content: '&nbsp;',
            visibility: 'hidden',
        },
    },
    ellipsisSpan: {
        position: 'absolute',
        left: 0,
        right: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    table: {
        tableLayout: 'fixed',
    },
    tooltipIcon: {
        overflow: 'visible',
        cursor: 'pointer',
        fontSize: 14,
        marginLeft: theme.spacing(0.75),
    },
    error: {
        alignItems: 'center',
        marginTop: theme.spacing(4),
        textAlign: 'center',
    },
    atRiskLink: {
        cursor: 'pointer',
        color: '#009FE3',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));
