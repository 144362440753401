import { Select } from '@infogrid/components-material-ui';
import type { ReactNode } from 'react';
import { memo, useMemo } from 'react';

import { ChecklistMenu } from './ChecklistMenu';
import { useChecklistStyles } from './styles';

export type ChecklistPropsType<T> = {
    classes?: {
        container?: string;
        actionsList?: string;
        itemsList?: string;
    };
    checkedItemsKeys: (string | number)[];
    disabled?: boolean;
    selectionDisabled?: boolean;
    items: T[];
    keyExtractor: (x: T) => string | number;
    valueExtractor: (x: T) => string;
    onOptionCheck: (id: string | number) => void;
    onClose?: () => void;
    actions?: ReactNode;
    renderValue: () => ReactNode;
    renderInPortal?: boolean;
};

const defaultOnClose = () => {};
const defaultClasses = { container: '', actionsList: '', itemsList: '' };

function Checklist<T>({
    classes = defaultClasses,
    checkedItemsKeys,
    disabled = false,
    selectionDisabled = false,
    items,

    keyExtractor,
    valueExtractor,

    onOptionCheck,
    onClose = defaultOnClose,

    actions,
    renderValue,
    renderInPortal = false,
}: ChecklistPropsType<T>) {
    const checklistStyles = useChecklistStyles();
    const selectClasses = useMemo(
        () => ({
            container: classes.container || checklistStyles.checklistSelectContainer,
            content: checklistStyles.checklistSelectContent,
        }),
        [
            checklistStyles.checklistSelectContainer,
            checklistStyles.checklistSelectContent,
            classes,
        ],
    );

    return (
        <Select<T>
            classes={selectClasses}
            disabled={disabled}
            renderValue={renderValue}
            onClose={onClose}
            renderInPortal={renderInPortal}
        >
            {() => (
                <ChecklistMenu
                    actions={actions}
                    classes={classes}
                    checkedItemsKeys={checkedItemsKeys}
                    items={items}
                    selectionDisabled={selectionDisabled}
                    onOptionCheck={onOptionCheck}
                    keyExtractor={keyExtractor}
                    valueExtractor={valueExtractor}
                />
            )}
        </Select>
    );
}

const ChecklistComponent = memo(Checklist) as typeof Checklist;

export default ChecklistComponent;
