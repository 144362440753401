import { Box, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { FludiaLEDPulseImage } from 'utils/Images';

import { useInstallationFlowStyles } from '../../styles';
import DeviceIdentifier from '../DeviceIdentifier';

interface FludiaIdentifierProps {
    errorMessage: string;
}

const FludiaIdentifier = ({ errorMessage }: FludiaIdentifierProps): JSX.Element => {
    const { t } = useTranslation('sensors');
    const installationFlowStyles = useInstallationFlowStyles();

    return (
        <DeviceIdentifier
            errorMessage={errorMessage}
            renderFormContent={({ onChangeSerialNumber, errorText }) => (
                <Box>
                    <Typography
                        variant="body1"
                        data-cypress="instruction-text"
                        className={installationFlowStyles.paragraph}
                    >
                        {t(
                            'Enter the Device ID which can be found on the front of the Receiver.',
                        )}
                    </Typography>
                    <Box>
                        <img
                            src={FludiaLEDPulseImage}
                            alt="Pointer to device ID"
                            className={classNames(installationFlowStyles.centeredImage)}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        label={t('Device ID')}
                        variant="outlined"
                        name="serialNumber"
                        onInput={onChangeSerialNumber}
                        error={!!errorText}
                        helperText={
                            errorText && <p data-cypress="helper-text">{errorText}</p>
                        }
                        data-cypress="device-id"
                    />
                </Box>
            )}
        />
    );
};

export default FludiaIdentifier;
