import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useSidebarSensorsStyles = makeStyles((theme) => ({
    headerWrapper: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0, 3),
    },
    sensorsLabel: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
    sensorsList: {
        overflow: 'auto',
        padding: theme.spacing(1.5, 2, 1.5, 3),
        // scrollbarWidth: 'none',
        // '&::-webkit-scrollbar': {
        //     display: 'none',
        // },
    },
}));
