import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo } from 'react';

import { useWidgetActionsStyles } from './styles';

interface WidgetActionsProps {
    children: ReactNode;
    className?: string;
}

const WidgetActions = ({ children, className }: WidgetActionsProps) => {
    const styles = useWidgetActionsStyles();

    return <div className={classNames(styles.root, className)}>{children}</div>;
};

export default memo(WidgetActions);
