import {
    addReducer,
    applicationReducer,
    errorReducer,
    toastReducer,
} from '@infogrid/core-ducks';
import { solutionsReducer } from '@infogrid/solution-views-ducks';
import { userReducer } from '@infogrid/user-ducks';
import { entitiesReducer } from '@thorgate/spa-entities';

import floorPlan from 'ducks/floorPlan';
import folders from 'ducks/folders';
import installFlow from 'ducks/installFlow';
import pagination from 'ducks/pagination';
import permissionsForm from 'ducks/permissionsForm';
import sensors from 'ducks/sensors';
import timeRange from 'ducks/timeRange';
import ui from 'ducks/ui';
import { loadingReducer } from 'utils/loadingReducer';

// This typing gets autogenerated as we expand, adding explicit typing here
// makes this much harder to work with for now as the dependents are still js files in many cases
// although later we could be more explicit
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addReducers = () => {
    addReducer('entities', entitiesReducer);
    addReducer('error', errorReducer);
    addReducer('loading', loadingReducer);
    addReducer('application', applicationReducer);
    addReducer('floorPlan', floorPlan);
    addReducer('folders', folders);
    addReducer('installFlow', installFlow);
    addReducer('pagination', pagination);
    addReducer('permissionsForm', permissionsForm);
    addReducer('sensors', sensors);
    addReducer('solutions', solutionsReducer);
    addReducer('timeRange', timeRange);
    // @ts-expect-error toasts need converting to TypeScript
    addReducer('toasts', toastReducer);
    addReducer('ui', ui);
    addReducer('user', userReducer);
};
