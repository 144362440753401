import { Modal, theme } from '@infogrid/components-material-ui';
import { TEMPERATURE_OFFSET_MODES } from '@infogrid/sensors-constants';
import type { TemperatureOffsetMode } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Box, Button, Chip, Grid, Radio, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTemperatureOffsetModeStyles } from './styles';

interface TemperatureOffsetZeroCalibrationProps {
    value?: TemperatureOffsetMode;
    onChange: (value: TemperatureOffsetMode) => void;
    onCancel: () => void;
    cancelDisplayText?: string;
    nextDisplayText?: string;
}

const choices = [
    {
        value: TEMPERATURE_OFFSET_MODES.AUTOMATIC,
        title: registerTranslationKey('Auto offset', {
            ns: 'sensors',
        }),
        subtitle: registerTranslationKey('Time saver', {
            ns: 'sensors',
        }),
        color: theme.palette.success.main,
        description: registerTranslationKey(
            'Applies an offset instantly, removing the need for manual configuration. This option provides a typical reading accuracy of +/- 2 degrees celsius for 95% of cold pipes.',
            {
                ns: 'sensors',
            },
        ),
    },
    {
        value: TEMPERATURE_OFFSET_MODES.MANUAL,
        title: registerTranslationKey('Manual offset', {
            ns: 'sensors',
        }),
        subtitle: registerTranslationKey('10 mins per sensor', {
            ns: 'sensors',
        }),
        description: registerTranslationKey(
            'Setting a manual offset requires the use of a thermometer probe but is likely to be more accurate. This is done after installation is complete.',
            {
                ns: 'sensors',
            },
        ),
    },
];

const TemperatureOffsetZeroCalibration = ({
    value,
    onChange,
    onCancel,
    cancelDisplayText,
    nextDisplayText,
}: TemperatureOffsetZeroCalibrationProps) => {
    const { t } = useTranslation('sensors');
    const styles = useTemperatureOffsetModeStyles();

    const [offsetMode, setOffsetMode] = useState<TemperatureOffsetMode>(
        value ?? TEMPERATURE_OFFSET_MODES.AUTOMATIC,
    );

    return (
        <>
            <Modal.Title>{t('Select offset type')}</Modal.Title>
            <Modal.Content>
                <Grid container spacing={3}>
                    <Grid item>
                        <Typography variant="body2">
                            {t(
                                'A temperature offset accounts for the difference between the pipe and water temperature. Hot water pipe subtypes may require more accurate offsets than cold water ones.',
                            )}
                        </Typography>
                    </Grid>
                    {choices.map((choice) => (
                        <Grid
                            key={`temperature-offset-mode-${choice.value}`}
                            data-testid={`temperature-offset-mode-${choice.value}`}
                            item
                            onClick={() => setOffsetMode(choice.value)}
                            className={styles.choice}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography variant="h6">{choice.title}</Typography>
                                <Radio
                                    color="primary"
                                    onSelect={() => setOffsetMode(choice.value)}
                                    checked={offsetMode === choice.value}
                                    inputProps={{ role: 'input' }}
                                />
                            </Box>

                            <Chip
                                color={choice.color ? 'secondary' : 'default'}
                                style={{
                                    backgroundColor: choice.color,
                                }}
                                size="small"
                                label={choice.subtitle}
                                className={styles.chip}
                            />
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                component="p"
                            >
                                {choice.description}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Modal.Content>
            <Modal.Actions justify="space-between">
                <Button
                    variant="text"
                    color="primary"
                    data-cypress="cancel"
                    onClick={onCancel}
                >
                    {cancelDisplayText
                        ? cancelDisplayText
                        : t('Cancel', { ns: 'common' })}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    data-cypress="next"
                    data-testid="next"
                    onClick={() => onChange(offsetMode)}
                >
                    {nextDisplayText ? nextDisplayText : t('Next', { ns: 'common' })}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default TemperatureOffsetZeroCalibration;
