import { wrapResponsePromise } from '@infogrid/core-api';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getBuildingFoldersKey } from '../getQueryKeys';

export const useFolders = ({ ...options } = {}) => {
    return useQuery(
        getBuildingFoldersKey(),
        ({ signal }) => wrapResponsePromise(controllers.getFolders(signal)),
        {
            initialData: [],
            refetchOnMount: false,
            ...options,
        },
    );
};
