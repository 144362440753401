import { Box, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { InstallationFlowSpaceMiniMaxBack } from 'utils/Images';

import { useInstallationFlowStyles } from '../../styles';
import DeviceIdentifier from '../DeviceIdentifier/DeviceIdentifier';
import { useDeviceIdentifierStyles } from './styles';

interface SmartSpaceIdentifierProps {
    errorMessage: string;
}

const SmartSpaceIdentifier = ({
    errorMessage,
}: SmartSpaceIdentifierProps): JSX.Element => {
    const { t } = useTranslation('sensors');
    const installationFlowStyles = useInstallationFlowStyles();
    const deviceIdentifierStyles = useDeviceIdentifierStyles();

    return (
        <DeviceIdentifier
            errorMessage={errorMessage}
            requiresDeviceId
            renderFormContent={({
                onChangeSerialNumber,
                onChangeDeviceId,
                errorText,
            }) => (
                <Box>
                    <Typography
                        variant="body1"
                        className={installationFlowStyles.paragraph}
                        data-cypress="instruction-text"
                    >
                        {t(
                            'Enter the device Type and ID found under the data matrix on the bottom of the Space Mini/Max device',
                        )}
                    </Typography>
                    <Box className={deviceIdentifierStyles.helpImageContainer}>
                        <img
                            src={InstallationFlowSpaceMiniMaxBack}
                            alt="Pointer to device ID"
                            className={classNames(installationFlowStyles.centeredImage)}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        label={t('Device Type')}
                        variant="outlined"
                        name="deviceId"
                        onInput={onChangeDeviceId}
                        error={!!errorText}
                        helperText={errorText}
                        className={deviceIdentifierStyles.serialNumberInput}
                        data-cypress="device-id"
                    />
                    <TextField
                        fullWidth
                        label={t('Device ID')}
                        variant="outlined"
                        name="serialNumber"
                        onInput={onChangeSerialNumber}
                        error={!!errorText}
                        helperText={errorText}
                        className={deviceIdentifierStyles.deviceIdInput}
                        data-cypress="serial-number"
                    />
                </Box>
            )}
        />
    );
};

export default SmartSpaceIdentifier;
