import type { SensorType } from '@infogrid/sensors-constants';
import { FormControlLabel, Radio, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import { useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { TemperatureUseCaseOption as TemperatureUseCaseOptionInterface } from '../types';
import { useTempUseCaseOptionStyles, useTempUseCaseLabelStyles } from './styles';

interface Props {
    option: TemperatureUseCaseOptionInterface;
    onChange: (option: SensorType) => void;
    selected?: boolean;
}

const TemperatureUseCaseOption = ({ option, onChange, selected = false }: Props) => {
    const { t } = useTranslation('sensors');
    const theme = useTheme();
    const tempUseCaseOptionStyles = useTempUseCaseOptionStyles();
    const tempUseCaseLabelStyles = useTempUseCaseLabelStyles();
    const Icon = option.icon;

    const onSelect = useCallback(() => onChange(option.value), [onChange, option.value]);

    return (
        <FormControlLabel
            value={option.value}
            control={<Radio color="primary" />}
            onChange={onSelect}
            labelPlacement="start"
            className={classNames(tempUseCaseOptionStyles.root, {
                [tempUseCaseOptionStyles.selected]: selected,
            })}
            label={
                <div className={tempUseCaseLabelStyles.root}>
                    <Icon
                        color={theme.palette.primary.main}
                        className={tempUseCaseLabelStyles.icon}
                    />
                    <span>{t(option.label)}</span>
                </div>
            }
            color="primary"
        />
    );
};

export default memo(TemperatureUseCaseOption);
