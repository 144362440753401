import { makeStyles } from '@material-ui/core';

export const useChecklistMenuStyles = makeStyles((theme) => ({
    checklistSelectContainer: {},
    checklistSelectContent: {
        '&:after': {
            display: 'none',
        },
        '&:before': {
            display: 'none',
        },
    },
    checkListMenuList: {
        paddingBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
    },
}));
