import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { spaceControllers } from '@infogrid/locations-api';
import type { SpacesParams } from '@infogrid/locations-api';
import type { FloorDetail, Floor } from '@infogrid/locations-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';
import { useDispatch } from 'react-redux';

import {
    getFloors,
    getFloorDetail,
    setFloors,
    setFloorDetail,
} from '../../floors/accessors';
import { cancelSpaces, invalidateSpaces } from '../accessors';

type DeleteSpaceParams = {
    id: number;
    floorId: number;
};

export const useDeleteSpace = (
    buildingId: number,
    spacesParams: SpacesParams,
): UseMutationResult<DeleteSpaceParams, AxiosParsedError, DeleteSpaceParams> => {
    const dispatch = useDispatch();

    const { t } = useTranslation('estate');

    return useMutationWithToast(
        ({ id }) => wrapResponsePromise(spaceControllers.deleteSpace(id)),
        {
            onSuccess(_d, { floorId }) {
                cancelSpaces(spacesParams);
                invalidateSpaces(spacesParams);

                setFloors(
                    buildingId,
                    (getFloors(buildingId) as Floor[]).map((floorItem) =>
                        floorItem.id === floorId
                            ? {
                                  ...floorItem,
                                  spaces_count: floorItem.spaces_count - 1,
                              }
                            : floorItem,
                    ),
                );

                const floor = getFloorDetail(floorId) as FloorDetail;

                setFloorDetail(floorId, {
                    ...floor,
                    spaces_count: floor.spaces_count - 1,
                });

                dispatch(toastSuccess({ message: t('Space deleted') }));
            },
            onError: () => {
                dispatch(toastError({ message: t('Error while deleting space') }));
            },
        },
        { toastMessage: t('Space deleting in progress') },
    );
};
