export const stoplightIcons = [
    'far fa-wheelchair',
    'far fa-allergies',
    'far fa-arrow-alt-circle-left',
    'far fa-arrow-alt-circle-right',
    'far fa-arrow-alt-circle-up',
    'far fa-arrow-alt-circle-down',
    'far fa-arrow-down',
    'far fa-arrow-up',
    'far fa-arrow-right',
    'far fa-arrow-left',
    'far fa-clock',
    'far fa-user-clock',
    'far fa-ban',
    'far fa-bell',
    'far fa-blind',
    'far fa-bullhorn',
    'far fa-building',
    'far fa-business-time',
    'far fa-chair-office',
    'far fa-check-circle',
    'far fa-check',
    'far fa-child',
    'far fa-couch',
    'far fa-envelope',
    'far fa-faucet',
    'far fa-fire-extinguisher',
    'far fa-first-aid',
    'far fa-hand-holding-water',
    'far fa-hammer',
    'far fa-heartbeat',
    'far fa-hourglass-half',
    'far fa-map-marker-alt',
    'far fa-notes-medical',
    'far fa-briefcase-medical',
    'far fa-question-circle',
    'far fa-restroom',
    'far fa-smile',
    'far fa-street-view',
    'far fa-sync-alt',
    'far fa-times-circle',
    'far fa-shield-virus',
    'fad fa-biohazard',
    'far fa-people-arrows',
    'far fa-hands-wash',
    'far fa-life-ring',
    'far fa-exclamation-triangle',
];
