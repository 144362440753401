import { makeStyles } from '@material-ui/core';

export const useCompareModalStyles = makeStyles((theme) => ({
    modalContent: {
        overflow: 'auto',
        flexGrow: 1,
        paddingTop: 0,
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(4, 2),
    },
}));
