import type { DeskOccupancyData } from 'views/dashboards/widgets/DeskOccupancyWidget/types';
import type { IndoorAirQualityData } from 'views/dashboards/widgets/IndoorAirQualityWidget/types';

import CUBICLE_OCCUPANCY_DATA from './cubicle_occupancy_data.json';
import DESK_OCCUPANCY_DATA from './desk_occupancy_data.json';
import DESK_OCCUPANCY_EMPTY_DATA from './desk_occupancy_empty_data.json';
import HEALTHY_BUILDINGS_SCORES_DATA from './healthy_buildings_scores_data.json';
import INDOOR_AIR_QUALITY_DATA from './indoor_air_quality_data.json';
import MEETING_ROOM_OCCUPANCY_DATA from './meeting_room_occupancy_data.json';
import NO_WIDGET_DATA from './no_widget_data.json';
import PEOPLE_COUNTING_DATA from './people_counting_data.json';
import PIPE_MONITORING_DATA from './pipe_monitoring_data.json';
import PROXIMITY_AND_TOUCH_COUNT_DATA from './proximity_and_touch_count_data.json';
import PROXIMITY_COUNT_DATA from './proximity_count_data.json';
import SMART_CLEANING_DATA from './smart_cleaning_data.json';
import TOUCH_COUNT_DATA from './touch_count_data.json';

export const MOCK_CUBICLE_OCCUPANCY_DATA = Object.freeze(CUBICLE_OCCUPANCY_DATA);
export const MOCK_DESK_OCCUPANCY_DATA = Object.freeze(
    DESK_OCCUPANCY_DATA,
) as DeskOccupancyData;
export const MOCK_DESK_OCCUPANCY_EMPTY_DATA = Object.freeze(DESK_OCCUPANCY_EMPTY_DATA);
export const MOCK_HEALTHY_BUILDINGS_SCORES_DATA = Object.freeze(
    HEALTHY_BUILDINGS_SCORES_DATA,
);
export const MOCK_INDOOR_AIR_QUALITY_DATA = Object.freeze(
    INDOOR_AIR_QUALITY_DATA,
) as IndoorAirQualityData;
export const MOCK_MEETING_ROOM_OCCUPANCY_DATA = Object.freeze(
    MEETING_ROOM_OCCUPANCY_DATA,
);
export const MOCK_NO_WIDGET_DATA = Object.freeze(NO_WIDGET_DATA);
export const MOCK_PEOPLE_COUNTING_DATA = Object.freeze(PEOPLE_COUNTING_DATA);
export const MOCK_PROXIMITY_AND_TOUCH_COUNT_DATA = Object.freeze(
    PROXIMITY_AND_TOUCH_COUNT_DATA,
);
export const MOCK_PROXIMITY_COUNT_DATA = Object.freeze(PROXIMITY_COUNT_DATA);
export const MOCK_SMART_CLEANING_DATA = Object.freeze(SMART_CLEANING_DATA);
export const MOCK_TOUCH_COUNT_DATA = Object.freeze(TOUCH_COUNT_DATA);
export const MOCK_PIPE_MONITORING_DATA = Object.freeze(PIPE_MONITORING_DATA);
