import { makeStyles } from '@material-ui/core';

export const useEditBuildingModalStyles = makeStyles((theme) => ({
    form: {
        width: 520,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    checkboxFormControl: {
        margin: theme.spacing(1, 0, 0),
    },
    saveButton: {
        minWidth: 90,
    },
}));
