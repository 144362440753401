import { MenuList, Select } from '@infogrid/components-material-ui';
import { useAppDispatch } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import type { Dashboard } from '@infogrid/dashboards-constants';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Icon, Typography, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionsMenu from 'components/ActionsMenu';
import CreateDashboardContainer from 'views/dashboards/components/modals/CreateDashboardModal/CreateDashboardModal.container';
import DeleteDashboardContainer from 'views/dashboards/components/modals/DeleteDashboardModal/DeleteDashboardModal.container';
import RenameDashboardContainer from 'views/dashboards/components/modals/RenameDashboardModal/RenameDashboardModal.container';
import ShareDashboardModal from 'views/dashboards/components/modals/ShareDashboardModal/ShareDashboardModal';
import { trackDashboardEvent } from 'views/dashboards/utils/analytics';
import {
    canChangeDashboard,
    canDeleteDashboard,
    hasUserPermissions,
} from 'views/dashboards/utils/helpers';

import DashboardActionsList from './DashboardActionsList';
import DashboardSelectOutput from './DashboardSelectOutput';
import { useActionsMenuStyles, useDashboardSelectStyles } from './styles';
import { sortDashboards } from './utils';

const MENU_ADD_ACTION_ICON = 'fal fa-plus';

export interface DashboardHeaderContentProps {
    dashboardId: number;
    dashboards?: Dashboard[];
    isDashboardsLoading: boolean;
    selectedDashboard: Dashboard;
}

const DashboardHeaderContent = ({
    dashboardId,
    dashboards = [],
    isDashboardsLoading,
    selectedDashboard,
}: DashboardHeaderContentProps) => {
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();

    const { t } = useTranslation('dashboard');

    const [createModalOpen, toggleCreateModal] = useState(false);
    const [deleteModalOpen, toggleDeleteModal] = useState(false);
    const [renameModalOpen, toggleRenameModal] = useState(false);
    const [shareModalOpen, toggleShareModal] = useState(false);

    const sortedDashboards = useMemo(() => dashboards.sort(sortDashboards), [dashboards]);

    const [editableDashboards, viewOnlyDashboards] = useMemo(
        () => [
            sortedDashboards.filter(
                (dashboard) =>
                    hasUserPermissions(dashboard) && canChangeDashboard(dashboard),
            ),
            sortedDashboards.filter(
                (dashboard) =>
                    hasUserPermissions(dashboard) && !canChangeDashboard(dashboard),
            ),
        ],
        [sortedDashboards],
    );

    const onSelectItemClick = (
        dashboard: Dashboard | undefined,
        onToggle: (status: boolean) => void,
    ) => {
        if (dashboard) {
            const location = routesManager.resolvePath('dashboards:details', {
                dashboardId: dashboard.id,
            });

            trackDashboardEvent(USER_ACTIONS.PRESSED, 'change dashboard', {
                id: dashboard.id,
                name: dashboard.name,
            });

            dispatch(push(location));
        }

        onToggle(false);
    };

    const createDashboardHandler = useCallback(() => {
        toggleCreateModal(true);
    }, []);

    const dashboardNames = useMemo(() => {
        return new Set(sortedDashboards.map((d) => d.name.toLowerCase()));
    }, [sortedDashboards]);

    const actionsMenuStyles = useActionsMenuStyles({ isMobile });
    const dashboardSelectStyles = useDashboardSelectStyles({ isMobile });

    return (
        <>
            <Select
                classes={{
                    container: dashboardSelectStyles.container,
                    content: dashboardSelectStyles.content,
                    renderValueIcon: dashboardSelectStyles.renderValueIcon,
                }}
                renderValue={() => (
                    <DashboardSelectOutput selectedDashboard={selectedDashboard} />
                )}
            >
                {({ onToggle }) => (
                    <>
                        <MenuList
                            classes={{
                                menuList: dashboardSelectStyles.actionsSection,
                            }}
                        >
                            <MenuList.Item
                                className={dashboardSelectStyles.actionsSectionItem}
                                data-cypress="add-new-dashboard"
                                onClick={createDashboardHandler}
                            >
                                <Icon
                                    className={classNames(
                                        MENU_ADD_ACTION_ICON,
                                        dashboardSelectStyles.actionsSectionItemIcon,
                                    )}
                                />

                                <Typography component="span" variant="subtitle1">
                                    {t('add new dashboard')}
                                </Typography>
                            </MenuList.Item>
                        </MenuList>

                        <MenuList.Divider />

                        <MenuList>
                            {isDashboardsLoading && (
                                <div className={dashboardSelectStyles.loaderContainer}>
                                    <CircularProgress color="primary" />
                                </div>
                            )}

                            {!isDashboardsLoading && (
                                <>
                                    {!!editableDashboards.length && (
                                        <>
                                            <MenuList.Subheader
                                                text={t('My Dashboards')}
                                            />

                                            {editableDashboards.map((dashboard) => {
                                                const itemClassName = classNames({
                                                    [dashboardSelectStyles.activeDashboard]:
                                                        dashboard.id === dashboardId,
                                                });

                                                return (
                                                    <MenuList.Item<Dashboard>
                                                        className={itemClassName}
                                                        data-cypress={`my-dashboard-${dashboard.id}`}
                                                        key={dashboard.id}
                                                        onClick={(x) =>
                                                            onSelectItemClick(x, onToggle)
                                                        }
                                                        item={dashboard}
                                                    >
                                                        <Typography
                                                            className={
                                                                dashboardSelectStyles.itemText
                                                            }
                                                            component="span"
                                                        >
                                                            {dashboard.name}
                                                        </Typography>
                                                    </MenuList.Item>
                                                );
                                            })}
                                        </>
                                    )}

                                    {!!viewOnlyDashboards.length && (
                                        <>
                                            <MenuList.Subheader
                                                text={t('Shared with me')}
                                            />

                                            {viewOnlyDashboards.map((dashboard) => {
                                                const itemClassName = classNames({
                                                    [dashboardSelectStyles.activeDashboard]:
                                                        dashboard.id === dashboardId,
                                                });

                                                return (
                                                    <MenuList.Item<Dashboard>
                                                        className={itemClassName}
                                                        data-cypress={`shared-dashboard-${dashboard.id}`}
                                                        key={dashboard.id}
                                                        onClick={(x) =>
                                                            onSelectItemClick(x, onToggle)
                                                        }
                                                        item={dashboard}
                                                    >
                                                        <Typography
                                                            className={
                                                                dashboardSelectStyles.itemText
                                                            }
                                                            component="span"
                                                        >
                                                            {dashboard.name}
                                                        </Typography>
                                                    </MenuList.Item>
                                                );
                                            })}
                                        </>
                                    )}
                                </>
                            )}
                        </MenuList>
                    </>
                )}
            </Select>

            {hasUserPermissions(selectedDashboard) &&
                canChangeDashboard(selectedDashboard) && (
                    <ActionsMenu
                        classes={{
                            menuContainer: actionsMenuStyles.menuContainer,
                            menuButtonIcon: actionsMenuStyles.menuButtonIcon,
                            menuContent: actionsMenuStyles.menuContent,
                        }}
                    >
                        {() => (
                            <DashboardActionsList
                                canDeleteDashboard={canDeleteDashboard(selectedDashboard)}
                                canRenameDashboard={canChangeDashboard(selectedDashboard)}
                                canShareDashboard={canChangeDashboard(selectedDashboard)}
                                toggleDeleteModal={toggleDeleteModal}
                                toggleShareModal={toggleShareModal}
                                toggleRenameModal={toggleRenameModal}
                            />
                        )}
                    </ActionsMenu>
                )}

            <CreateDashboardContainer
                dashboards={dashboardNames}
                open={createModalOpen}
                toggleCreateModal={toggleCreateModal}
            />

            <RenameDashboardContainer
                dashboard={selectedDashboard}
                dashboards={dashboardNames}
                open={renameModalOpen}
                toggleRenameModal={toggleRenameModal}
            />

            <DeleteDashboardContainer
                open={deleteModalOpen}
                toggleDeleteModal={toggleDeleteModal}
                dashboard={selectedDashboard}
            />

            {shareModalOpen && (
                <ShareDashboardModal
                    dashboardId={selectedDashboard.id}
                    toggleShareModal={toggleShareModal}
                />
            )}
        </>
    );
};

export default memo(DashboardHeaderContent);
