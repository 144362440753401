import { Modal } from '@infogrid/components-material-ui';
import { LEGIONNAIRE_SUB_TYPES, PipeTypes } from '@infogrid/sensors-constants';
import type { LegionnaireSubtypes, SensorShape } from '@infogrid/sensors-constants';
import type { TempUnit } from '@infogrid/utils-measurements';
import {
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import type { FormikProps } from 'formik';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SensorInfoCard from 'components/material-ui/Sensors/SensorInfoCard';

import { useConfigurePipeMonitoringStyles } from './styles';

export interface ConfigurePipeMonitoringValues {
    pipeType?: LegionnaireSubtypes;
}

export interface ConfigurePipeMonitoringProps {
    formik: FormikProps<ConfigurePipeMonitoringValues>;
    userPreferredTempUnit: TempUnit;
    sensor: SensorShape;
    isSensorOffline?: boolean;
    onClickBack: () => void;
}

const ConfigurePipeMonitoring: FC<ConfigurePipeMonitoringProps> = ({
    formik: {
        values: { pipeType },
        errors,
        touched,
        isValid,
        handleChange,
        handleSubmit,
        handleBlur,
    },
    sensor,
    isSensorOffline = false,
    onClickBack,
}) => {
    const { t } = useTranslation('sensors');
    const styles = useConfigurePipeMonitoringStyles();

    const pipeOptions = useMemo(
        () =>
            Object.entries(LEGIONNAIRE_SUB_TYPES).map(([subType, value]) => (
                <MenuItem value={value} key={value} data-cypress="pipe-type-item">
                    {PipeTypes[subType as keyof typeof LEGIONNAIRE_SUB_TYPES]}
                </MenuItem>
            )),
        [],
    );

    const subtypeLabel = t('Type');

    return (
        <form
            className={styles.form}
            onSubmit={handleSubmit}
            id="pipe-monitoring-config"
            // Both needed for accessibility edge cases
            name={t('Pipe monitoring configuration')}
            title={t('Pipe monitoring configuration')}
        >
            <Modal.Title>{t('Configure the device')}</Modal.Title>
            <Modal.Content className={styles.content}>
                <SensorInfoCard sensor={sensor} variant="inline" />
                <FormControl variant="outlined">
                    <InputLabel id="pipe-monitoring-subtype-label">
                        {subtypeLabel}
                    </InputLabel>
                    <Select
                        fullWidth
                        name="pipeType"
                        value={pipeType || ''}
                        onChange={({ target: { value, ...restTarget }, ...e }) =>
                            handleChange({
                                target: {
                                    value: (value as LegionnaireSubtypes | '').length
                                        ? value
                                        : undefined,
                                    ...restTarget,
                                },
                                ...e,
                            })
                        }
                        onBlur={handleBlur}
                        error={touched.pipeType && !!errors.pipeType}
                        labelId="pipe-monitoring-subtype-label"
                        label={subtypeLabel}
                        data-cypress="pipe-type"
                    >
                        {pipeOptions}
                    </Select>
                    <FormHelperText error={touched.pipeType && !!errors.pipeType}>
                        {(touched.pipeType && errors.pipeType) ||
                            t('Select the type of pipe the sensor is being installed on')}
                    </FormHelperText>
                </FormControl>
            </Modal.Content>
            <Modal.Actions className={styles.actions}>
                <Button onClick={onClickBack} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant={isSensorOffline ? 'outlined' : 'contained'}
                    disabled={!pipeType || !isValid}
                    data-cypress="submit"
                >
                    {isSensorOffline ? t('Continue offline') : t('Next')}
                </Button>
            </Modal.Actions>
        </form>
    );
};

export default ConfigurePipeMonitoring;
