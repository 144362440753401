import { Modal } from '@infogrid/components-material-ui';
import type { Space, UneditableCapacityReason } from '@infogrid/locations-types';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Grid,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import type { FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useEditSpaceModalStyles } from './styles';
import type { EditSpaceModalValues } from './types';
import { getCapacityMessageKey } from './utils';

interface Props {
    formik: FormikProps<EditSpaceModalValues>;
    isSubmitting: boolean;
    onClose: () => void;
    onDelete: () => void;
    open: boolean;
    space: Space;
    spaceTypes: {
        id: number;
        name: string;
        uneditable_capacity_reason: UneditableCapacityReason;
    }[];
}

const EditSpaceModal = ({
    formik,
    isSubmitting,
    onClose,
    onDelete,
    open,
    space,
    spaceTypes,
}: Props) => {
    const styles = useEditSpaceModalStyles();

    const { t } = useTranslation('estate');

    const {
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
    } = formik;

    const [capacityMessageKey, setCapacityMessageKey] = useState(
        getCapacityMessageKey(space.uneditable_capacity_reason),
    );

    //update capacity when user try to edit the space type
    useEffect(() => {
        const selectedSpaceType = spaceTypes.find((st) => st.id === values.space_type);
        if (selectedSpaceType) {
            setCapacityMessageKey(
                getCapacityMessageKey(selectedSpaceType.uneditable_capacity_reason),
            );

            setFieldValue(
                'spaceEditDisabled',
                !!selectedSpaceType.uneditable_capacity_reason,
            );

            if (selectedSpaceType.uneditable_capacity_reason) {
                setFieldValue('occupant_capacity', null);
            }
        }
    }, [values.space_type, spaceTypes, setFieldValue]);

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Title className={styles.modalTitle}>{t('Edit space')}</Modal.Title>
            <form onSubmit={handleSubmit}>
                <div className={styles.formContent} data-cypress="edit-space-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                data-cypress="space-name"
                                error={!!touched.name && !!errors.name}
                                fullWidth
                                helperText={
                                    !!touched.name && errors.name && t(errors.name)
                                }
                                id="name"
                                label={t('Space name')}
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.name}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="spaceType">{t('Space type')}</InputLabel>
                                <Select
                                    data-cypress="space-type"
                                    fullWidth
                                    id="spaceType"
                                    label={t('Space type')}
                                    labelId="spaceType"
                                    MenuProps={{
                                        anchorOrigin: {
                                            horizontal: 'left',
                                            vertical: 'bottom',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    name="space_type"
                                    onChange={handleChange}
                                    value={values.space_type}
                                    variant="outlined"
                                >
                                    {spaceTypes.map((spaceType) => (
                                        <MenuItem
                                            data-cypress="space-type-item"
                                            key={spaceType.id}
                                            value={spaceType.id}
                                        >
                                            {spaceType.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography className={styles.header} variant="subtitle1">
                                {t('Capacity')}
                            </Typography>
                            {values.spaceEditDisabled ? (
                                <Alert severity="info" className={styles.capacityWarning}>
                                    {t(capacityMessageKey)}
                                </Alert>
                            ) : (
                                <Typography
                                    className={styles.capacityText}
                                    color="textSecondary"
                                    variant="body2"
                                    data-cypress="space-edit-capacity-message"
                                >
                                    {t(capacityMessageKey)}
                                </Typography>
                            )}
                            <TextField
                                id="space-edit-capacity"
                                type="number"
                                label="Capacity"
                                name="occupant_capacity"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: 1,
                                }}
                                onChange={handleChange}
                                value={values.occupant_capacity || ''}
                                data-cypress="space-edit-capacity"
                                variant="outlined"
                                disabled={values.spaceEditDisabled}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Modal.Actions justify="right">
                    <Tooltip
                        placement="top"
                        title={
                            !space.user_actions.delete?.allowed &&
                            space.user_actions.delete?.reason
                                ? space.user_actions.delete?.reason
                                : ''
                        }
                    >
                        <div>
                            <Button
                                color="secondary"
                                data-cypress="delete-space"
                                disabled={
                                    isSubmitting || !space.user_actions.delete?.allowed
                                }
                                onClick={onDelete}
                            >
                                {t('Delete space')}
                            </Button>
                        </div>
                    </Tooltip>
                    <Button
                        color="primary"
                        data-cypress="save-space"
                        disabled={isSubmitting || !isEmpty(errors)}
                        type="submit"
                        variant="contained"
                    >
                        {t('Save', { ns: 'common' })}
                    </Button>
                </Modal.Actions>
            </form>
        </Modal>
    );
};

export default memo(EditSpaceModal);
