import { Modal } from '@infogrid/components-material-ui';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button, Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSpinner from 'components/ButtonSpinner';

import { useDeleteConfirmationStyles } from './styles';

interface Props {
    onClose: () => void;
    onSubmit: () => void;
    isSubmitting: boolean;
    open: boolean;
}

const DeleteConfirmationModal = ({ onClose, onSubmit, isSubmitting, open }: Props) => {
    const styles = useDeleteConfirmationStyles();
    const isMobile = useIsMobile();
    const { t } = useTranslation('solutions');

    return (
        <Modal
            onClose={onClose}
            open={open}
            fullScreen={isMobile}
            maxWidth="xs"
            fullWidth
        >
            <Modal.Title>{t('Delete scheduled cleaning report?')}</Modal.Title>

            <Modal.Content>
                <Typography variant="body2" className={styles.description}>
                    {t('Are you sure you want to delete this scheduled cleaning report?')}
                </Typography>
                <Typography variant="body2" className={styles.description}>
                    {t(
                        'Alternatively, you can temporarily stop it being sent to recipients.To do this, press cancel then turn on the ‘Do not send report’ toggle.',
                    )}
                </Typography>
            </Modal.Content>

            <Modal.Actions justify="space-between">
                <div></div>
                <div className={styles.actionButtonContainer}>
                    <div className={styles.actionButtonContainerRow}>
                        <Button onClick={onClose} color="primary">
                            {t('Cancel')}
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={onSubmit}
                            disabled={isSubmitting}
                            data-cypress="submit-delete-report"
                        >
                            {isSubmitting ? <ButtonSpinner /> : t('Delete Report')}
                        </Button>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(DeleteConfirmationModal);
