import { getFlatEntitiesFromPages } from '@infogrid/core-api';

export const selectDataWithPlainLabels = (data) => {
    const labels = getFlatEntitiesFromPages({
        pages: data?.pages,
        entitiesKey: 'labels',
        uniqByKey: 'id',
    });

    return {
        ...data,
        labels,
    };
};
