import loadable from '@loadable/component';
import type { RouteConfig } from 'react-router-config';

import FoldersRoot from 'components/FoldersRoot';
import RenderChildren from 'components/RenderChildren';
import { userIsAuthenticated } from 'decorators/auth';
import saveProfileWatcherSaga from 'sagas/account/saveProfile';
import { fetchFolderSensorsWatcher } from 'sagas/folders/fetchFolderSensors';
import { fetchFolderSensorsCountWatcher } from 'sagas/folders/fetchFolderSensorsCount';
import { fetchFolderSubfoldersWatcher } from 'sagas/folders/fetchFolderSubFolders';

export const FolderDetailView = loadable(() => import('views/folders/FolderDetails'));

export const folderDetailWatcherSagas = [
    fetchFolderSensorsWatcher,
    fetchFolderSensorsCountWatcher,
    fetchFolderSubfoldersWatcher,
    saveProfileWatcherSaga,
];

export const createFolderRoutes = (PageNotFoundRoute: RouteConfig): RouteConfig => ({
    path: '/folders/',
    name: 'folders',
    component: userIsAuthenticated(RenderChildren),
    routes: [
        {
            path: '/folders/',
            exact: true,
            name: 'root-redirect',
            component: FoldersRoot,
        },
        {
            path: '/folders/:folderId/',
            exact: true,
            name: 'details',
            pageName: 'Folder structure',
            component: FolderDetailView,
            watcher: folderDetailWatcherSagas,
        },
        PageNotFoundRoute,
    ],
});
