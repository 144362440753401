import { makeStyles } from '@material-ui/core';

export const useFloorRowInfoStyles = makeStyles((theme) => ({
    floorInfoAlert: {
        color: theme.palette.error.main,
        textDecoration: 'underline',
    },
    infoSeparator: {
        margin: theme.spacing(0, 0.5),
    },
}));
