import { TextField, Typography } from '@material-ui/core';
import { memo } from 'react';
import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import IconsPicker from '../IconsPicker';
import { useStoplightSectionStyles } from './styles';

interface Props {
    icon?: string;
    icons: string[];
    id: string;
    label: string;
    name: string;
    onIconChange: (icon: string) => void;
    onLabelChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    title: string;
}

const StoplightTextSection = ({
    icon,
    icons,
    id,
    label,
    name,
    onIconChange,
    onLabelChange,
    title,
}: Props) => {
    const { t } = useTranslation('dashboard');
    const styles = useStoplightSectionStyles();

    return (
        <div data-cypress="stoplight-section">
            <Typography className={styles.title} variant="subtitle1">
                {t(title)}
            </Typography>
            <div className={styles.wrapper}>
                <Typography className={styles.imageText} variant="subtitle1">
                    {t('Image', { ns: 'common' })}
                </Typography>
                <div className={styles.iconsPickerWrapper} data-cypress="icon-picker">
                    <IconsPicker
                        icons={icons}
                        onChange={onIconChange}
                        selectedIcon={icon}
                    />
                </div>
                <TextField
                    fullWidth
                    helperText={t('Max 40 characters')}
                    id={id}
                    inputProps={{
                        maxLength: 40,
                    }}
                    label={t('Message', { ns: 'common' })}
                    name={name}
                    onChange={onLabelChange}
                    size="medium"
                    value={label}
                    variant="outlined"
                />
            </div>
        </div>
    );
};

export default memo(StoplightTextSection);
