import { makeStyles } from '@material-ui/core';

export const useSupportDialogStyles = makeStyles((theme) => ({
    modalContent: {
        maxWidth: theme.typography.pxToRem(600),
    },
    supportCentreBlock: {
        display: 'flex',
        marginBottom: theme.spacing(2),
    },
    supportCentreButton: {
        width: theme.typography.pxToRem(350),
        marginLeft: theme.spacing(2),
        padding: 0,
    },
    supportCentreLink: {
        color: theme.palette.common.white,
        width: '100%',
        height: '100%',
        padding: theme.spacing(1),

        '&:hover': {
            color: theme.palette.common.white,
        },
    },
}));
