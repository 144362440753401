import { makeStyles } from '@material-ui/core';

export const useBuildingSectionStyles = makeStyles(() => ({
    accordionDetails: {
        width: '100%',
    },
    title: {
        fontWeight: 500,
        letterSpacing: '0.15px',
        lineHeight: '20px',
    },
    expandMoreIcon: {
        width: '30px',
        height: '30px',
    },
}));

export const useAccordionStyles = makeStyles((theme) => ({
    root: {
        border: `0px`,
        backgroundColor: 'unset',
        boxShadow: 'none',
        minHeight: '56px',

        width: '100%',
        paddingLeft: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.common.shell}`,

        [theme.breakpoints.down('xs')]: {
            paddingLeft: theme.spacing(0),
        },

        '&$expanded': {
            margin: 0,

            '&:hover .collapsible-sb-icon-container': {
                backgroundColor: 'theme.palette.background.sand',
            },
        },
        '&:hover': {
            backgroundColor: 'rgba(63, 52, 35, 0.04)',

            '& .collapsible-sb-icon-container': {
                backgroundColor: 'unset',
            },
        },
        '&.Mui-disabled': {
            backgroundColor: 'unset',
        },
    },
    expanded: {
        margin: 0,
        '&:hover': {
            backgroundColor: 'unset',
        },
    },
}));

export const useAccordionDetailsStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 2),

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 0),
        },
    },
}));

export const useAccordionSummaryStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 2),
        width: '100%',
        height: '100%',
        color: theme.palette.common.gray8,
        minHeight: '56px',

        '&$expanded': {
            minHeight: '56px',
        },

        '&.Mui-disabled': {
            color: theme.palette.common.gray6,
            opacity: 1,
        },
    },
    content: {
        '&$expanded': {
            margin: theme.spacing(1.5, 0),
        },
    },
    // Necessary to remove the guttering effect. MUI v5 has a disableGutters prop that does this.
    expanded: {},
}));

export const useModalStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 420,

        [theme.breakpoints.down('xs')]: {
            maxWidth: 'none',
            width: '100%',
        },
    },
    content: {
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
}));

export const useReportCardStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.background.default,
        width: '100%',
        margin: theme.spacing(0, 0, 2, 0),
        padding: theme.spacing(2, 3),
        flexWrap: 'nowrap',

        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
    },
    chip: {
        margin: theme.spacing(1, 4),
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(1, 0),
        },
    },
    description: {
        flexDirection: 'column',
    },
    subtitle: {
        color: theme.palette.text.grey.default,
        fontWeight: 400,
    },
    editAction: {
        paddingTop: theme.spacing(0.5),
    },
    editButton: {
        padding: theme.spacing(1, 3),
    },
    wrappedContainer: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginTop: theme.spacing(2),
        },
    },
}));
