import { Select, Translate } from 'ol/interaction';
import PropTypes from 'prop-types';
import { useContext, useEffect, memo } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';

import MapContext from './MapContext';

const TranslateInteractionMode = ({
    onCoordinateChange,
    setInteractionEnabled,
    selectOptions,
    selectListeners,
    translateFeatureOptions,
}) => {
    const { map } = useContext(MapContext);

    // Debounced to prevent TranslateInteractionMode being disabled mid-event handling when mouse/touch is released
    const [debouncedDisableInteraction] = useDebouncedCallback(
        () => setInteractionEnabled(false),
        250,
        [setInteractionEnabled],
    );

    useEffect(() => {
        if (!map) {
            return () => {};
        }

        const translateFeatureSelect = new Select(selectOptions);

        translateFeatureSelect.on('select', selectListeners.select);

        map.addInteraction(translateFeatureSelect);

        const translateFeature = new Translate(translateFeatureOptions);

        map.addInteraction(translateFeature);

        const onChangePosition = (event) => {
            if (event.features.getLength()) {
                const ids = event.features.getArray().map((x) => x.getId());

                onCoordinateChange(ids, event.coordinate);
                debouncedDisableInteraction();
            }
        };

        translateFeature.on('translateend', onChangePosition);

        return () => {
            translateFeatureSelect.un('select', selectListeners.select);
            translateFeature.un('translateend', onChangePosition);

            map.removeInteraction(translateFeatureSelect);
            map.removeInteraction(translateFeature);
        };
    }, [
        debouncedDisableInteraction,
        map,
        onCoordinateChange,
        selectOptions,
        selectListeners,
        translateFeatureOptions,
    ]);

    return null;
};

TranslateInteractionMode.propTypes = {
    onCoordinateChange: PropTypes.func.isRequired,
    setInteractionEnabled: PropTypes.func.isRequired,
    selectOptions: PropTypes.shape({}),
    selectListeners: PropTypes.shape({}),
    translateFeatureOptions: PropTypes.shape({}),
};

TranslateInteractionMode.defaultProps = {
    selectListeners: {},
    selectOptions: {},
    translateFeatureOptions: {},
};

export default memo(TranslateInteractionMode);
