import { getEmergencyLightTestStatus } from '@infogrid/components-material-ui';
import {
    CONNECTION_TYPE_USER_READABALE_VALUE,
    getSensorTypeUnit,
    OBJECT_PRESENT_STATE,
    OBJECT_PRESENCE_STATE,
    OCCUPIED_STATE,
    SENSOR_EVENT_TYPES_V1,
    SENSOR_EVENT_TYPES_V2,
    SENSOR_TYPE,
    WATER_PRESENT_STATE,
} from '@infogrid/sensors-constants';
import {
    ELECTRICITY_SENSOR_TYPES,
    getFormattedElectricityReading,
} from '@infogrid/solution-views-electricity';
import { formatDistance } from '@infogrid/utils-dates';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import Humidity from 'components/values/Humidity';
import Legionnaire from 'components/values/Legionnaire';
import Temperature from 'components/values/Temperature';
import { getFeedbackPanelReading } from 'utils/feedbackPanelTouch';
import { SensorConfigurationShape, SensorEventShape } from 'utils/types';

import AirQualityLastReadingValue from './AirQualityLastReadingValue';

const TEMPERATURE_SENSOR_TYPES = [
    SENSOR_TYPE.TYPE_TEMPERATURE,
    SENSOR_TYPE.TYPE_AVERAGE_TEMPERATURE,
];

/**
 * @returns {JSX.Element} - returns a JSX Fragment that displays the most recent event user-friendlily
 */
const SensorLastReadingValue = ({
    event,
    sensorType,
    sensorConfiguration,
    readingTypes,
    useShortTouchReading,
    isOffline,
    className,
    shouldShowOffline,
    fallbackValue,
    latestEvents,
}) => {
    const { t, i18n } = useTranslation('sensor-events');

    const touchedAgo = formatDistance(new Date(), event?.timestamp, i18n.language);

    // If the timestamp is displayed after the last reading anyway, then no point in showing it twice
    const touchSensorText = useShortTouchReading
        ? t('Touched')
        : `${t('Touched')} ${touchedAgo}`;

    if (shouldShowOffline && isOffline) {
        return (
            <div data-testid="last-reading-value" className={className}>
                {t('Offline')}
            </div>
        );
    }

    return (
        <div data-testid="last-reading-value" className={className}>
            {sensorType === SENSOR_TYPE.TYPE_LEGIONNAIRE && (
                <Fragment key="legionnaire">
                    <Legionnaire
                        value={event?.value}
                        precision={2}
                        subtype={sensorConfiguration?.sub_type}
                    />
                </Fragment>
            )}
            {TEMPERATURE_SENSOR_TYPES.includes(sensorType) && (
                <Fragment key="temperature">
                    <Temperature value={event?.value} precision={2} />
                </Fragment>
            )}
            {sensorType === SENSOR_TYPE.TYPE_HUMIDITY && (
                <Fragment key="humidity">
                    <Humidity
                        value={latestEvents[SENSOR_EVENT_TYPES_V2.HUMIDITY]?.value}
                    />{' '}
                    {t('at', { ns: 'common' })}{' '}
                    <Temperature
                        value={latestEvents[SENSOR_EVENT_TYPES_V2.TEMPERATURE]?.value}
                        precision={2}
                    />
                </Fragment>
            )}
            {event?.type === SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT &&
                sensorType === SENSOR_TYPE.TYPE_PROXIMITY && (
                    <Fragment key="objectPresent">
                        {t(
                            event.value === true
                                ? OBJECT_PRESENT_STATE.PRESENT
                                : OBJECT_PRESENT_STATE.NOT_PRESENT,
                        )}
                    </Fragment>
                )}
            {event?.type === SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT &&
                sensorType === SENSOR_TYPE.TYPE_PRESENCE && (
                    <Fragment key="objectPresent">
                        {t(
                            event.value === true
                                ? OBJECT_PRESENCE_STATE.PRESENT
                                : OBJECT_PRESENCE_STATE.NOT_PRESENT,
                        )}
                    </Fragment>
                )}
            {event.type === SENSOR_EVENT_TYPES_V2.OBJECT_PRESENT_DELTA &&
                sensorType === SENSOR_TYPE.TYPE_PROXIMITY_COUNTER && (
                    <Fragment key="objectPresentCount">
                        {!isNil(event?.value)
                            ? t('{{count}} uses', {
                                  count: event.value,
                                  defaultValue___one: `${event?.value} use`,
                                  defaultValue___other: `${event?.value} uses`,
                              })
                            : fallbackValue}
                    </Fragment>
                )}
            {event?.type === SENSOR_EVENT_TYPES_V2.WATER_PRESENT && (
                <Fragment key="waterPresent">
                    {t(
                        event.value === true
                            ? WATER_PRESENT_STATE.PRESENT
                            : WATER_PRESENT_STATE.NOT_PRESENT,
                    )}
                </Fragment>
            )}
            {sensorType === SENSOR_TYPE.TYPE_DESK_OCCUPANCY && (
                <Fragment key="deskOccupancy">
                    {t(
                        event.value === true
                            ? OCCUPIED_STATE.OCCUPIED
                            : OCCUPIED_STATE.UNOCCUPIED,
                    )}
                </Fragment>
            )}
            {event.type === SENSOR_EVENT_TYPES_V2.TOUCH &&
                sensorType === SENSOR_TYPE.TYPE_TOUCH && (
                    <Fragment key="touch">{touchSensorText}</Fragment>
                )}
            {event.type === SENSOR_EVENT_TYPES_V1.touch &&
                sensorType === SENSOR_TYPE.TYPE_TOUCH_COUNTER && (
                    <Fragment key="touchCounter">
                        {!isNil(event?.value)
                            ? t('{{count}} touches', {
                                  count: event.value,
                                  defaultValue___one: `${event?.value} touch`,
                                  defaultValue___other: `${event?.value} touches`,
                              })
                            : fallbackValue}
                    </Fragment>
                )}
            {event.type === SENSOR_EVENT_TYPES_V2.SPACE_OCCUPANCY && (
                <Fragment key="spaceOccupancy">
                    {!isNil(event?.value)
                        ? t('{{count}} occupants', {
                              count: event.value,
                              defaultValue___one: `${event.value} occupant`,
                              defaultValue___other: `${event.value} occupants`,
                          })
                        : fallbackValue}
                </Fragment>
            )}
            {sensorType === SENSOR_TYPE.TYPE_AIR_QUALITY ? (
                <Fragment key="airQuality">
                    <AirQualityLastReadingValue
                        events={event}
                        availableReadings={readingTypes}
                    />
                </Fragment>
            ) : null}
            {event?.type === SENSOR_EVENT_TYPES_V2.CONNECTION_TYPE && (
                <Fragment key="connectionStatus">
                    {event.value === 'offline'
                        ? t('Offline')
                        : t('Connected via {{connectionType}}', {
                              connectionType: t(
                                  CONNECTION_TYPE_USER_READABALE_VALUE[event.value],
                              ),
                          })}
                </Fragment>
            )}
            {event?.type === SENSOR_EVENT_TYPES_V2.CONNECTION_SIGNAL && (
                <Fragment key="connectionSignal">{t('Connected via Cellular')}</Fragment>
            )}
            {event?.type === SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS &&
                ELECTRICITY_SENSOR_TYPES.includes(sensorType) && (
                    <Fragment key={sensorType}>
                        {isNil(event?.value)
                            ? ''
                            : getFormattedElectricityReading(event.value)}
                        {t(getSensorTypeUnit(sensorType))}
                    </Fragment>
                )}
            {event?.type === SENSOR_EVENT_TYPES_V2.FEEDBACK_PANEL_TOUCH && (
                <Fragment key={SENSOR_EVENT_TYPES_V2.FEEDBACK_PANEL_TOUCH}>
                    {getFeedbackPanelReading(event, sensorConfiguration)}
                </Fragment>
            )}
            {event?.type === SENSOR_EVENT_TYPES_V2.EMERGENCY_LIGHT_TEST && (
                <Fragment key="emergencyLightTest">
                    {getEmergencyLightTestStatus({
                        failureType: event.value.failure_type,
                        isAllCaps: true,
                        isPassing: event.value.test_passed,
                        t,
                    })}
                </Fragment>
            )}
            {!event && null}
        </div>
    );
};

SensorLastReadingValue.propTypes = {
    // "event" can be a compound event such as latest_events_v2 for AQ sensors.
    // The typing will be fixed when we switch over to new event format.
    event: PropTypes.oneOfType([SensorEventShape, PropTypes.object]).isRequired,
    sensorType: PropTypes.string.isRequired,
    sensorConfiguration: SensorConfigurationShape,
    fallbackValue: PropTypes.string,
    isOffline: PropTypes.bool.isRequired,
    readingTypes: PropTypes.arrayOf(PropTypes.string),
    useShortTouchReading: PropTypes.bool,
    className: PropTypes.string,
    shouldShowOffline: PropTypes.bool.isRequired,
    // We need to TS-ify this and all related last reading components.
    // This is defined as an object elsewhere so keepingf this here.
    // eslint-disable-next-line react/forbid-prop-types
    latestEvents: PropTypes.object,
};

SensorLastReadingValue.defaultProps = {
    sensorConfiguration: null,
    readingTypes: [],
    fallbackValue: '',
    useShortTouchReading: false,
    className: '',
};

export default memo(SensorLastReadingValue);
