import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useAddSpaceListStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.common.gray6,
        fontSize: '12px',
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
        textTransform: 'uppercase',
    },
}));
