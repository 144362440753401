import { makeStyles } from '@material-ui/core';

const ICON_SIZE = 40;

export const useSensorIconStyles = makeStyles((theme) => ({
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',

        marginRight: theme.spacing(2),
    },
    icon: {
        width: ICON_SIZE,
        height: ICON_SIZE,
    },
}));
