import { makeStyles } from '@material-ui/core';

export const useEstateViewHeaderStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.common.shell}`,
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 2),
        },
    },
    headerInfoIcon: {
        color: theme.palette.common.gray4,
        fontSize: 20,
        marginLeft: theme.spacing(1),
    },
    addBuildingIcon: {
        alignItems: 'center',
        display: 'flex',
        fontSize: 14,
        height: 16,
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
    },
}));
