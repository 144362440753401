import { makeStyles } from '@material-ui/core';

export const usePipeMonitoringStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 100%',
        paddingBottom: theme.spacing(5),
        height: 'calc(100vh - 56px)',
        overflowY: 'auto',
    },
}));
