import { makeStyles } from '@material-ui/core';

export const useWorkingHoursConfigurationStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        overflow: 'hidden',
    },
    timeIcon: {
        marginRight: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    weekDay: {
        textTransform: 'capitalize',
    },
    alert: {
        marginTop: theme.spacing(3),
    },
    value: {
        padding: theme.spacing(0, 1, 0, 1.5),
    },
}));

export const useModalStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 520,

        [theme.breakpoints.down('xs')]: {
            maxWidth: 'none',
        },
    },
}));
