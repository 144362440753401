import { Button, CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import type { LocationsCount } from 'views/solutionSettings/pages/smartCleaning/utils/selectionUtils';

import { useLocationSelectorCounterStyles } from './styles';

interface LocationsSelectorCounterProps {
    buttonCallback: () => void;
    buttonText: string;
    domain?: string;
    count?: LocationsCount;
    onCancel?: () => void;
    isLoading?: boolean;
    getIsSaveButtonDisabled?: (count: LocationsCount | undefined) => boolean;
}

const defaultGetIsButtonDisabled = (count?: LocationsCount) => count?.spaces === 0;

export const LocationsSelectorCounter = ({
    buttonCallback,
    buttonText,
    domain,
    count,
    onCancel,
    isLoading,
    getIsSaveButtonDisabled = defaultGetIsButtonDisabled,
}: LocationsSelectorCounterProps): JSX.Element => {
    const styles = useLocationSelectorCounterStyles(Boolean(count))();
    const { t } = useTranslation('solutions');

    return (
        <footer className={styles.container}>
            {count && (
                <Typography
                    className={styles.countText}
                    color="textSecondary"
                    variant="body2"
                >
                    {t(
                        count.floors === 0
                            ? `No ${domain}s selected`
                            : `${count.floors} floors (in ${count.buildings} buildings)`,
                    )}
                </Typography>
            )}

            <div>
                {onCancel && (
                    <Button
                        color="primary"
                        onClick={onCancel}
                        className={styles.cancelButton}
                        size="large"
                    >
                        {t('Cancel')}
                    </Button>
                )}
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={getIsSaveButtonDisabled(count)}
                    onClick={buttonCallback}
                >
                    {isLoading ? (
                        <CircularProgress className={styles.loadingSpinner} size={24} />
                    ) : (
                        buttonText
                    )}
                </Button>
            </div>
        </footer>
    );
};
