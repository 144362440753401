import { makeStyles } from '@material-ui/core';

export const useThresholdsStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(5),
    },
    saveContainer: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        padding: theme.spacing(2),
        paddingRight: theme.spacing(12),
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: theme.palette.common.gray1,
    },
    saveButton: {
        marginLeft: theme.spacing(1),
    },
    titleBar: {
        flexWrap: 'wrap',
        marginBottom: '8px !important',
    },
    description: {
        color: theme.palette.common.gray1,
        fontSize: '14px',
        marginBottom: theme.spacing(2),
    },
    alert: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },
}));
