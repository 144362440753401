import { useAppSelector } from '@infogrid/core-ducks';
import { ORGANIZATION_FEATURE_FLAG } from '@infogrid/core-feature-flags';
import { selectUserPreferredTempUnit } from '@infogrid/user-ducks';
import { useOrganizationFeature } from '@infogrid/user-hooks';
import {
    getTemperatureConverter,
    TEMP_UNIT,
    TEMP_UNIT_SYMBOL,
} from '@infogrid/utils-measurements';
import { isNumber } from 'is-what';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    useRemoteMonitoring,
    useRemoteMonitoringCalculateErrorBounds,
} from 'apiHooks/charts/hooks';

const Legionnaire = ({
    value,
    inputUnit = TEMP_UNIT.CELSIUS,
    outputUnit = null,
    precision = 0,
    subtype,
}) => {
    const { t } = useTranslation('sensor-events');

    const remoteMonitoringParams = {
        params: [{ subtype }],
    };
    const { data: errorBoundsEquation } = useRemoteMonitoring({
        ...remoteMonitoringParams,
    });
    const { calculateErrorBounds } = useRemoteMonitoringCalculateErrorBounds();

    const userPreferredTempUnit = useAppSelector(selectUserPreferredTempUnit);
    const temperatureUnit = useMemo(
        () => (outputUnit === null ? userPreferredTempUnit : outputUnit),
        [userPreferredTempUnit, outputUnit],
    );

    const isErrorBarEnabled = useOrganizationFeature(
        ORGANIZATION_FEATURE_FLAG.PIPE_MONITORING_ERROR_BAR,
    );

    if (!isNumber(value)) {
        return null;
    }

    let displayValue = value;

    if (inputUnit !== temperatureUnit) {
        const converter = getTemperatureConverter(inputUnit, temperatureUnit, false);

        if (converter) {
            displayValue = converter(displayValue);
        }
    }

    displayValue = displayValue.toFixed(precision);

    const unit = t(TEMP_UNIT_SYMBOL[temperatureUnit]);

    if (!subtype || !isErrorBarEnabled) {
        return t('{{value: number}}{{unit}}', {
            value: displayValue,
            unit,
            maximumFractionDigits: precision,
            defaultValue: `${displayValue}${unit}`,
        });
    }

    const errorBar = calculateErrorBounds(errorBoundsEquation, value).offset;

    let displayErrorBar = errorBar;

    if (inputUnit !== temperatureUnit) {
        const converterDelta = getTemperatureConverter(inputUnit, temperatureUnit, true);

        if (converterDelta) {
            displayErrorBar = converterDelta(errorBar);
        }
    }

    displayErrorBar = displayErrorBar.toFixed(precision);

    return t('{{value: number}}{{unit}} ± {{error: number}}{{unit}}', {
        value: displayValue,
        error: displayErrorBar,
        unit,
        maximumFractionDigits: precision,
        defaultValue: `${displayValue}${unit}`,
    });
};

Legionnaire.propTypes = {
    value: PropTypes.number.isRequired,
    inputUnit: PropTypes.oneOf([TEMP_UNIT.CELSIUS, TEMP_UNIT.FAHRENHEIT]),
    outputUnit: PropTypes.oneOf([TEMP_UNIT.CELSIUS, TEMP_UNIT.FAHRENHEIT]),
    precision: PropTypes.number,
    subtype: PropTypes.string,
};

export default Legionnaire;
