import type { StoplightValues } from '@infogrid/dashboards-constants';
import type { FormikProps } from 'formik';
import { Fragment, memo } from 'react';
import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import Divider from '../Divider';
import StoplightSection from '../StoplightSection';
import { stoplightIcons } from './constants';

interface Props {
    formik: FormikProps<StoplightValues>;
}

const Stoplight = ({ formik }: Props) => {
    const { t } = useTranslation('dashboard');
    const { values } = formik;

    return (
        <div>
            {values?.stoplight?.map((stoplight, index) => {
                const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(`stoplight[${index}].label`, e.target.value);
                };

                const onIconChange = (icon: string) => {
                    formik.setFieldValue(`stoplight[${index}].icon`, icon);
                };

                return (
                    <Fragment key={index}>
                        <StoplightSection
                            icon={stoplight.icon}
                            icons={stoplightIcons}
                            id={`stoplight-text-${index}`}
                            label={t(stoplight.label)}
                            name={`stoplight[${index}].label`}
                            onIconChange={onIconChange}
                            onLabelChange={onLabelChange}
                            title={`${t('Text Line')} ${index + 1}`}
                        />
                        {index < values?.stoplight?.length - 1 && <Divider />}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default memo(Stoplight);
