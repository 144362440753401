import type { FormikValues } from 'formik';
import { useCallback } from 'react';

import PipeMonitoringTypeSelection from 'components/material-ui/PipeMonitoringTypeSelection';
import { MONITORING_TYPES_ALL_KEYS } from 'components/material-ui/PipeMonitoringTypeSelection/utils';

export interface MonitoringTypeTabProps {
    formik: FormikValues;
}

const MonitoringTypeTab = ({ formik }: MonitoringTypeTabProps) => {
    const { pipe_monitoring_failure_types } = formik?.values || {};
    const types = pipe_monitoring_failure_types ?? MONITORING_TYPES_ALL_KEYS;

    const onChange = useCallback(
        (selection: string[]) => {
            formik.setFieldValue('pipe_monitoring_failure_types', selection);
        },
        [formik],
    );

    return (
        <PipeMonitoringTypeSelection
            onChange={onChange}
            selectedMonitoringTypes={types}
        />
    );
};

export default MonitoringTypeTab;
