import { ProgressBubble, TrendDelta } from '@infogrid/components-material-ui';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Typography, Icon, Tooltip, List, ListItem } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Pillar, PillarLabels } from '../../types';
import TrendTooltip from '../TrendTooltip';
import { getScoreDelta } from '../utils';
import {
    usePillarScoresStyles,
    usePillarScoresMenuItemStyles,
    usePillarScoresProgressBubbleStyles,
} from './styles';

interface Props {
    className?: string;
    pillars: Pillar[];
}

const PILLAR_LABELS: PillarLabels = Object.freeze({
    'Air Quality': registerTranslationKey('Air Quality', { ns: 'dashboard' }),
    'Thermal Health': registerTranslationKey('Thermal Health', { ns: 'dashboard' }),
});

const PillarScores = ({ pillars, className }: Props) => {
    const styles = usePillarScoresStyles();
    const menuItemClasses = usePillarScoresMenuItemStyles();
    const pillarScoresProgressBubbleClasses = usePillarScoresProgressBubbleStyles();

    const { t } = useTranslation('dashboard');

    const pillarTooltip = t(
        'Pillars are used to calculate a Healthy Building Score. The first number is the pillar score. The second number shows how much the score has changed since yesterday.',
    );

    return (
        <div className={className} data-cypress="pillar-scores">
            <Typography color="textSecondary" variant="caption">
                {t('Pillar Scores')}
                <Tooltip
                    title={pillarTooltip}
                    enterTouchDelay={50}
                    leaveTouchDelay={3000}
                >
                    <Icon
                        data-cypress="pillar-scores-info-icon"
                        className={classNames('fal fa-info-circle', styles.aboutIcon)}
                    />
                </Tooltip>
            </Typography>
            <List>
                {pillars.map((pillar) => (
                    <TrendTooltip
                        key={pillar.name}
                        scoreToday={pillar.score_today}
                        scoreYesterday={pillar.score_yesterday}
                        scoreThirtyDaysAgo={pillar.score_thirty_days_ago}
                    >
                        <ListItem classes={menuItemClasses} divider>
                            <Typography
                                noWrap
                                variant="caption"
                                className={styles.pillarName}
                            >
                                {t(PILLAR_LABELS[pillar.name])}
                            </Typography>

                            <ProgressBubble
                                fontSize={12}
                                value={pillar.score_today}
                                classes={pillarScoresProgressBubbleClasses}
                            />

                            <TrendDelta
                                fontSize={12}
                                delta={getScoreDelta(
                                    pillar.score_today,
                                    pillar.score_yesterday,
                                )}
                            />
                        </ListItem>
                    </TrendTooltip>
                ))}
            </List>
        </div>
    );
};

export default memo(PillarScores);
