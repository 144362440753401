import { createRequest } from '@infogrid/core-api';
import type { Folder, IPagination, OrganizationIdType } from '@infogrid/core-types';
import type {
    SensorEventShape,
    SensorEventsV2,
    SensorEventV2Shape,
    SensorShape,
} from '@infogrid/sensors-constants';
import type { AxiosPromise } from 'axios';

import type { SensorType } from 'utils/types';
import type { CreateSensorPayload } from 'views/sensors/AddSensor/CreateBuildingFloorSensor/types';

const getSensor = (
    id: string,
    signal: AbortSignal | undefined,
): AxiosPromise<SensorShape> =>
    createRequest({ method: 'get', url: `sensors/${id}`, signal });

const updateSensor = ({
    id,
    data,
}: {
    id: string;
    data: SensorShape;
}): AxiosPromise<SensorShape> =>
    createRequest({ method: 'put', url: `sensors/${id}`, options: { data } });

const getLatestEvents = (
    deviceNames: string[],
    signal: AbortSignal | undefined,
    params: Record<string, unknown>,
): AxiosPromise<IPagination<SensorEventsV2>> =>
    createRequest({
        method: 'post',
        url: 'sensors/latest_events_v2',
        signal,
        options: {
            data: { device_ids: deviceNames },
            params,
        },
    });

const getSensorCountsByTypes = (
    signal: AbortSignal,
): Record<keyof typeof SensorType & 'unknown', SensorEventV2Shape> =>
    createRequest({ method: 'get', url: '/sensors/count-by-type', signal });

const getSensorEvents = (
    deviceName: string,
    signal: AbortSignal | undefined,
    params: Record<string, unknown>,
): AxiosPromise<{ data: SensorEventV2Shape[]; next: string | null }> =>
    createRequest({
        method: 'get',
        url: `sensors/${deviceName}/events_v2`,
        signal,
        options: {
            params,
        },
    });

const getSensorList = (
    signal: AbortSignal | undefined,
    params: Record<string, unknown>,
): AxiosPromise<IPagination<SensorShape>> =>
    createRequest({
        method: 'get',
        url: `sensors`,
        signal,
        options: {
            params,
        },
    });

const getSeenSensors = (
    deviceName: string,
    signal: AbortSignal | undefined,
    params: Record<string, unknown>,
): AxiosPromise<IPagination<SensorShape>> =>
    createRequest({
        method: 'get',
        url: `sensors/${deviceName}/seen-sensors`,
        signal,
        options: {
            params,
        },
    });

const getSensorsSeenBy = (
    deviceName: string,
    signal: AbortSignal | undefined,
    params: Record<string, unknown>,
): AxiosPromise<{
    deviceName: string;
    seenBy: { device_name: string; name: string; permissions: string }[];
}> =>
    createRequest({
        method: 'get',
        url: `sensors/${deviceName}/seen-by`,
        signal,
        options: { params },
    });

const getSourceForSensors = (
    deviceName: string,
    signal: AbortSignal | undefined,
    params: Record<string, unknown>,
): AxiosPromise<IPagination<SensorEventShape>> =>
    createRequest({
        method: 'get',
        url: `sensors/${deviceName}/source_for`,
        signal,
        options: {
            params,
        },
    });

const getFolderSensors = ({
    folderId,
    signal,
    params,
}: {
    folderId: number;
    signal: AbortSignal | undefined;
    params: Record<string, unknown>;
}): AxiosPromise<IPagination<SensorShape>> =>
    createRequest({
        method: 'get',
        url: `folders/${folderId}/sensors`,
        signal,
        options: {
            params,
        },
    });

// TODO confirm this is a sensible approach
const getOrgSensors = (
    {
        organizationId,
        ...params
    }: Record<string, unknown> & { organizationId: OrganizationIdType },
    signal: AbortSignal | undefined,
): AxiosPromise<IPagination<SensorShape>> =>
    createRequest({
        method: 'get',
        url: `/sensors`,
        signal,
        options: {
            params: {
                ...params,
                ancestor_folder: organizationId,
            },
        },
    });

const moveSeenSensors = (
    deviceName: string,
    data: Record<string, unknown>,
): AxiosPromise<Folder> =>
    createRequest({
        method: 'patch',
        url: `sensors/${deviceName}/seen-sensors/move`,
        options: { data },
    });

const createSpaceSensor = (data: CreateSensorPayload): AxiosPromise<SensorEventShape> =>
    createRequest({ method: 'post', url: 'sensors/virtual/space', options: { data } });

const createAverageTemperatureSensor = (
    data: Record<string, unknown>,
): AxiosPromise<SensorEventShape> =>
    createRequest({
        method: 'post',
        url: 'sensors/virtual/avg-temperature',
        options: { data },
    });

const deleteVirtualSensor = (options: Record<string, unknown>): AxiosPromise =>
    createRequest({ method: 'delete', url: '/sensors/virtual/bulk-delete', options });

export const controllers = {
    getSensor,
    updateSensor,
    getLatestEvents,
    getSensorEvents,
    getSensorCountsByTypes,
    getSeenSensors,
    getSensorsSeenBy,
    getSourceForSensors,
    getSensorList,
    getOrgSensors,
    moveSeenSensors,
    createSpaceSensor,
    createAverageTemperatureSensor,
    deleteVirtualSensor,
    getFolderSensors,
};
