import PropTypes from 'prop-types';

export const BlueprintTimeDomainType = PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
);

export const graphAxisTickPropTypes = {
    x: PropTypes.number,
    y: PropTypes.number,
    payload: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)])
            .isRequired,
    }),
};
export const graphAxisTickDefaultProps = {
    x: null,
    y: null,
    payload: null,
};
