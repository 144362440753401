import type { AxiosParsedError } from '@infogrid/core-types';
import set from 'lodash/set';

import type { FastAPIErrorResponse } from './types';

/**
 * Handle error response from Webhook Service.
 *
 * Webhook Service uses FastAPI & Pydantic.
 * This results in a different error format to CoreAPI.
 * The rest of the codebase assumes CoreAPI DRF error response format.
 *
 * Note: This should be useful for other similar services.
 *       But this has only been tested with events webhooks.
 *
 * TODO: API-607 Webclient handles FastAPI errors
 */
export const handleEventsWebhookErrorResponse = (
    err: AxiosParsedError,
): AxiosParsedError => {
    const parsedErrorUpdate: AxiosParsedError['parsedError'] = {};

    const responseData: FastAPIErrorResponse | undefined = err.response?.data;

    if (typeof responseData?.detail === 'string') {
        parsedErrorUpdate.message = responseData.detail;
    } else if (Array.isArray(responseData?.detail)) {
        parsedErrorUpdate.errors = { ...err.parsedError?.errors };

        responseData?.detail?.forEach((errDetail) => {
            if (errDetail.loc.length >= 2) {
                const [, ...errorPath] = errDetail.loc;

                set(parsedErrorUpdate.errors, errorPath, errDetail.msg);
            }
        });
    }

    if (Object.keys(parsedErrorUpdate).length > 0) {
        return {
            ...err,
            parsedError: {
                ...err.parsedError,
                ...parsedErrorUpdate,
            },
        };
    }

    return err;
};
