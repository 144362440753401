import { makeStyles } from '@material-ui/core';

export const useSensorDisplayStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        margin: theme.spacing(1, 0, 3),
    },
    sensorDisplayIcon: {
        marginRight: theme.spacing(2),
    },
    paragraph: {
        color: theme.palette.text.grey.default,
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    textContainer: {
        maxWidth: `calc(100% - 60px)`,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
}));
