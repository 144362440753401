import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import isString from 'lodash/isString';
import type * as React from 'react';
import type { CellMeasurerCache, GridCellProps } from 'react-virtualized';
import { CellMeasurer } from 'react-virtualized';

const TableCell = ({
    key,
    parent,
    rowIndex,
    columnIndex,
    style,
}: GridCellProps): React.ReactNode => {
    const {
        columns,
        data,
        classes,
        getCellClasses,
        deferredMeasurementCache,
        columnWidth,
        onCellClick,
    } = parent.props;
    const columnData = columns[columnIndex];
    const cellData = data[rowIndex];
    const width = columnWidth({ index: columnIndex });

    const content = columnData.render ? columnData.render(cellData) : '';
    const isStringContent = isString(content);

    const cellClasses = getCellClasses({ rowIndex, columnIndex });

    const onClick = (e: React.SyntheticEvent) => {
        e.persist();

        const target = e.target as HTMLElement;

        if (target.tagName !== 'INPUT' && onCellClick) {
            onCellClick(rowIndex, columnIndex);
        }
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            e.stopPropagation();
            e.preventDefault();
        }
    };

    return (
        <CellMeasurer
            cache={deferredMeasurementCache as CellMeasurerCache}
            columnIndex={columnIndex}
            key={key}
            parent={parent}
            rowIndex={rowIndex}
        >
            <Typography
                component="div"
                variant="body2"
                style={{ ...style, width }}
                className={classNames(
                    classes.cellWrapper,
                    'ReactVirtualized__Table__rowColumn__wrapper',
                )}
                data-cypress={`cell-${columnIndex}`}
                data-row-index={rowIndex}
                onClick={onClick}
                onKeyDown={onKeyDown}
            >
                <div className={cellClasses}>
                    {isStringContent ? (
                        <div title={content as string} className={classes.stringCell}>
                            {content}
                        </div>
                    ) : (
                        content
                    )}
                </div>
            </Typography>
        </CellMeasurer>
    );
};

export default TableCell;
