import type { WidgetProps } from '@infogrid/dashboards-constants';
import { useDashboard, useUpdateWidget, useWidgetData } from '@infogrid/dashboards-hooks';
import { useIsMobile, useIsOpenState } from '@infogrid/utils-hooks';
import isNil from 'lodash/isNil';
import { memo, useCallback, useMemo, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import { GenericActions, GenericTitle, Widget } from 'views/dashboards/components/Widget';
import type { ConfigurableProps } from 'views/dashboards/components/Widget/GenericWidget/GenericActions/types';
import { useGenericWidgetContainerStyles } from 'views/dashboards/components/Widget/GenericWidget/styles';
import { DeleteWidgetModal } from 'views/dashboards/components/modals';
import { canChangeDashboard, hasUserPermissions } from 'views/dashboards/utils/helpers';
import { useConfigureWidgetModalOpenState } from 'views/dashboards/utils/hooks';

import ConfigureWidgetModal from './ConfigureWidgetModal';
import Content from './Content';
import Footer from './Footer';
import { widgetConfigurationModalTabs } from './constants';
import { useWidgetStyles } from './styles';
import type {
    LiveIndoorAirQualityWidget as LiveIndoorAirQualityWidgetType,
    WidgetSettings,
    LiveIndoorAirQualityWidgetData,
} from './types';
import { validateConfiguration, prepareConfigurationPayload } from './utils';

const LiveIndoorAirQualityWidget = ({
    canDragAndResize,
    layout,
    widget,
}: WidgetProps<LiveIndoorAirQualityWidgetType>) => {
    const containerStyles = useGenericWidgetContainerStyles();
    const widgetStyles = useWidgetStyles();

    const { dashboardId } = useParams<{ dashboardId: string }>();

    const isMobile = useIsMobile();

    const { data: dashboard } = useDashboard(+dashboardId);

    const {
        handleConfigureWidgetModalClose,
        handleConfigureWidgetModalOpen,
        isConfigureWidgetModalOpen,
    } = useConfigureWidgetModalOpenState(widget.id, widget.type);

    const isHardRefreshData = useRef(false);

    const [
        deleteWidgetModalOpen,
        handleDeleteWidgetModalClose,
        handleDeleteWidgetModalOpen,
    ] = useIsOpenState();

    const { mutate: updateWidget, isLoading: isUpdatingWidget } =
        useUpdateWidget<LiveIndoorAirQualityWidgetType>();

    const isValidConfiguration = validateConfiguration(
        widget.configuration.calculators.live_indoor_air_quality,
    );

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: '100px 0px',
        skip: false,
        initialInView: false,
        triggerOnce: true,
    });

    const {
        data: widgetData,
        isFetching: fetchingWidgetData,
        refetch: refetchWidgetData,
        isError: isLoadingWidgetDataError,
    } = useWidgetData<LiveIndoorAirQualityWidgetData>(+dashboardId, widget.id, {
        getQueryParams: () => ({
            refresh: isHardRefreshData.current || undefined,
        }),
        options: {
            enabled: inView && isValidConfiguration,
            onSettled: () => {
                isHardRefreshData.current = false;
            },
            refetchInterval: 5 * 60 * 1000, // 5 minutes
        },
    });

    const onUpdateWidgetTitle = useCallback(
        (title: string) => {
            updateWidget({
                dashboardId: +dashboardId,
                widgetId: widget.id,
                data: { name: title },
            });
        },
        [dashboardId, updateWidget, widget.id],
    );

    const onUpdateConfiguration = useCallback(
        (values: WidgetSettings) => {
            updateWidget({
                dashboardId: +dashboardId,
                widgetId: widget.id,
                data: {
                    ...widget,
                    configuration: prepareConfigurationPayload(values),
                },
            });
        },
        [dashboardId, updateWidget, widget],
    );

    const configurableProps: ConfigurableProps = {
        onConfigure: handleConfigureWidgetModalOpen,
        onDelete: handleDeleteWidgetModalOpen,
        isDuplicateDisabled: !isValidConfiguration,
        isConfigureWidgetAvailable: true,
    };

    const widgetCardRef = useRef(null);

    const onRefresh = useCallback(() => {
        isHardRefreshData.current = true;
        refetchWidgetData();
    }, [refetchWidgetData]);

    const canUserEdit = useMemo(() => {
        if (!isNil(configurableProps.canUserEdit)) {
            return configurableProps.canUserEdit;
        }

        return (
            !!dashboard && hasUserPermissions(dashboard) && canChangeDashboard(dashboard)
        );
    }, [configurableProps.canUserEdit, dashboard]);

    return (
        <div ref={ref} className={containerStyles.container} data-cypress="widget">
            <Widget>
                <Widget.Bar
                    canDragAndResize={canDragAndResize}
                    className={widgetStyles.header}
                >
                    <GenericTitle
                        onUpdateTitle={onUpdateWidgetTitle}
                        title={widget.name}
                        titleEditable={canUserEdit}
                        titleIcon={<i className="far fa-wind" />}
                    />
                    {!isMobile && (
                        <GenericActions
                            configurableProps={configurableProps}
                            dashboardId={dashboardId}
                            refreshableProps={{
                                enabled: isValidConfiguration,
                                onRefresh,
                            }}
                            canUserEdit={canUserEdit}
                            widgetConfiguration={widget.configuration}
                            widgetType={widget.type}
                            widgetName={widget.name}
                        />
                    )}
                </Widget.Bar>

                {inView && (
                    <Widget.Content
                        ref={widgetCardRef}
                        layout={layout}
                        className={widgetStyles.content}
                    >
                        <Content
                            onUpdateConfiguration={onUpdateConfiguration}
                            widget={widget}
                            widgetData={widgetData}
                            canUserEdit={canUserEdit}
                            isLoading={fetchingWidgetData}
                            isLoadingDataError={isLoadingWidgetDataError}
                        />
                    </Widget.Content>
                )}

                <Footer
                    onUpdateConfiguration={onUpdateConfiguration}
                    widget={widget}
                    widgetData={widgetData}
                    isWidgetDataLoading={fetchingWidgetData}
                    canUserEdit={canUserEdit}
                />

                {isConfigureWidgetModalOpen && (
                    <ConfigureWidgetModal
                        initialValues={
                            widget.configuration.calculators.live_indoor_air_quality
                        }
                        loading={isUpdatingWidget}
                        onClose={handleConfigureWidgetModalClose}
                        onUpdateConfiguration={onUpdateConfiguration}
                        open={isConfigureWidgetModalOpen}
                        tabs={widgetConfigurationModalTabs}
                    />
                )}

                <DeleteWidgetModal
                    dashboardId={dashboardId}
                    onClose={handleDeleteWidgetModalClose}
                    open={deleteWidgetModalOpen}
                    widgetId={widget.id}
                    widgetName={widget.name}
                    widgetType={widget.type}
                />
            </Widget>
        </div>
    );
};

export default memo(LiveIndoorAirQualityWidget);
