import classNames from 'classnames';
import { memo } from 'react';

import { useMarkStyles } from './styles';

interface Props {
    className?: string;
}

const Mark = ({ className }: Props) => {
    const styles = useMarkStyles();

    return (
        <svg
            className={classNames(styles.root, className)}
            viewBox="0 0 1 7"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line x1="0" y1="0" x2="0" y2="7" />
        </svg>
    );
};

export default memo(Mark);
