import defaultLogo from 'styles/images/infogrid-logo-grid-only-transparent.png';
import getAppURI from 'utils/getAppURI';

const DEFAULT_LOGO_TEXT = 'Infogrid';

const getLogo = ({ logo, name } = {}) => {
    const orgLogo = logo ? getAppURI(logo) : defaultLogo;
    const orgName = name || DEFAULT_LOGO_TEXT;

    return [orgLogo, orgName];
};

export default getLogo;
