import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';

import BaseTreeView from 'components/BaseTreeView';

import TreeNodeContent from './TreeNodeContent';
import { useTreeNodeStyles } from './styles';

const FOLDER_CLOSED_ICON = 'far fa-chevron-right';
const FOLDER_OPENED_ICON = 'far fa-chevron-down';

const TreeNode = (props) => {
    const {
        id,
        children,
        contentLength,
        isEditable,
        isExpandable,
        isExpanded,
        isSelected,
        nodeIcon,
        nodeName,
        toggleExpandedStateById,
        handleSelect,
    } = props;

    const iconClickHandler = useCallback(
        (ev) => {
            ev.preventDefault(); // disabling onNodeToggle
            ev.stopPropagation(); // disabling onNodeSelect

            if (isExpandable) {
                toggleExpandedStateById(id);
            }
        },
        [isExpandable, id, toggleExpandedStateById],
    );
    const labelClickHandler = useCallback(
        (ev) => {
            if (isEditable) {
                handleSelect(id, { contentLength, isEditable, nodeName });
            }

            ev.preventDefault();
        },
        [handleSelect, isEditable, contentLength, id, nodeName],
    );

    const TreeNodeLabel = useMemo(
        () => (
            <TreeNodeContent
                isExpanded={isExpanded}
                isSelected={isSelected}
                nodeContentLength={contentLength}
                nodeIcon={nodeIcon}
                nodeName={nodeName}
            />
        ),
        [isExpanded, isSelected, nodeName, nodeIcon, contentLength],
    );

    const treeNodeStyles = useTreeNodeStyles();
    const treeNodeClasses = classNames(treeNodeStyles.treeViewItem, {
        [treeNodeStyles.treeViewItemSelected]: isSelected,
        [treeNodeStyles.treeViewItemEditable]: isEditable,
        [treeNodeStyles.treeViewItemExpandable]: !isEditable && isExpandable,
    });

    const treeViewItemRootClasses = useMemo(
        () => ({
            treeViewItemRoot: treeNodeClasses,
        }),
        [treeNodeClasses],
    );

    const renderListIcon = useCallback(
        (iconName) => (
            <Icon
                className={classNames(
                    { [iconName]: isExpandable },
                    treeNodeStyles.listIcon,
                )}
                data-cypress="chevron"
            />
        ),
        [isExpandable, treeNodeStyles.listIcon],
    );

    return (
        <BaseTreeView.Item
            classes={treeViewItemRootClasses}
            collapseIcon={renderListIcon(FOLDER_OPENED_ICON)}
            expandIcon={renderListIcon(FOLDER_CLOSED_ICON)}
            LabelComponent={TreeNodeLabel}
            nodeId={id}
            onIconClick={iconClickHandler}
            onLabelClick={labelClickHandler}
        >
            {children}
        </BaseTreeView.Item>
    );
};

TreeNode.propTypes = {
    children: PropTypes.node.isRequired,
    contentLength: PropTypes.number.isRequired,
    handleSelect: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    isEditable: PropTypes.bool.isRequired,
    isExpandable: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    nodeIcon: PropTypes.string,
    nodeName: PropTypes.string.isRequired,
    toggleExpandedStateById: PropTypes.func.isRequired,
};

TreeNode.defaultProps = {
    nodeIcon: '',
};

export default memo(TreeNode);
