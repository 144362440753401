import { Modal } from '@infogrid/components-material-ui';
import type { BuildingDetailsModalValues } from '@infogrid/locations-types';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import BuildingDetails from 'views/estate/components/forms/BuildingDetails';

import { useEditBuildingModalStyles } from './styles';

const FORM_ID = 'edit-building-form';

interface Props {
    onClose: () => void;
    open: boolean;
}

const EditBuildingModal = ({ onClose, open }: Props) => {
    const styles = useEditBuildingModalStyles();

    const isMobile = useIsMobile();

    const { t } = useTranslation();

    const { isSubmitting, handleSubmit } = useFormikContext<BuildingDetailsModalValues>();

    return (
        <Modal fullScreen={isMobile} onClose={onClose} open={open}>
            <Modal.Title>{t('Edit building', { ns: 'estate' })}</Modal.Title>
            <Modal.Content>
                <form className={styles.form} id={FORM_ID} onSubmit={handleSubmit}>
                    <BuildingDetails />
                </form>
            </Modal.Content>
            <Modal.Actions justify="right">
                <Button
                    color="primary"
                    className={styles.saveButton}
                    data-cypress="save"
                    disabled={isSubmitting}
                    form={FORM_ID}
                    type="submit"
                    variant="contained"
                >
                    {t('Save')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(EditBuildingModal);
