import { queryClient, wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError, Timezones } from '@infogrid/core-types';
import type { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import type { UseQueryOptions, UseQueryResult, QueryFunction } from 'react-query';

import { controllers } from '../controllers';
import { getTimezonesKey } from '../getQueryKeys';
import type { ResponseTimezone } from '../types';

const queryParams = {
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
};

const mapper = (response: AxiosResponse<ResponseTimezone[]>): Timezones =>
    (response.data ?? []).map((t) => ({
        value: t.name,
        label: `${t.name} (${t.display_name})`,
    }));

const queryFn: QueryFunction<Timezones> = ({ signal }) =>
    wrapResponsePromise(controllers.getTimezones(signal), mapper);

export const useTimezones = (
    options: UseQueryOptions<Timezones, AxiosParsedError, Timezones> = {},
): UseQueryResult<Timezones, AxiosParsedError> => {
    const { i18n } = useTranslation();

    return useQuery(getTimezonesKey(i18n.language), queryFn, {
        ...queryParams,
        ...options,
    });
};

export const prefetchTimezones = (lang: string): void => {
    queryClient.prefetchQuery(getTimezonesKey(lang), queryFn, queryParams);
};
