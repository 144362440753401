import { Divider, List } from '@material-ui/core';
import { memo, useCallback, useMemo } from 'react';

import PickerRow from './PickerRow';
import { useUsersAndTeamsPickerStyles } from './styles';

export type PickerItem = {
    id: number;
    name: string;
};

export interface UsersAndTeamsPickerProps {
    areAllSelected: boolean;
    selectedTeamsIds: number[];
    selectedUsersIds: number[];
    teams: PickerItem[];
    users: PickerItem[];
    onSelectedTeamsChange: (ids: number[]) => void;
    onSelectedUsersChange: (ids: number[]) => void;
}

const UsersAndTeamsPicker = ({
    areAllSelected = false,
    onSelectedTeamsChange,
    onSelectedUsersChange,
    selectedTeamsIds,
    selectedUsersIds,
    teams,
    users,
}: UsersAndTeamsPickerProps) => {
    const styles = useUsersAndTeamsPickerStyles();

    const teamsToRender = useMemo(() => teams.slice(1, teams.length), [teams]);

    const handleTeamSelect = useCallback(
        (id) => {
            let newSelectedTeamsIds;

            if (selectedTeamsIds.includes(id)) {
                newSelectedTeamsIds = selectedTeamsIds.filter((teamId) => teamId !== id);
            } else {
                newSelectedTeamsIds = [...selectedTeamsIds, id];
            }

            onSelectedTeamsChange(newSelectedTeamsIds);
        },
        [onSelectedTeamsChange, selectedTeamsIds],
    );

    const handleUserSelect = useCallback(
        (id) => {
            let newSelectedUsersIds;

            if (selectedUsersIds.includes(id)) {
                newSelectedUsersIds = selectedUsersIds.filter((userId) => userId !== id);
            } else {
                newSelectedUsersIds = [...selectedUsersIds, id];
            }

            onSelectedUsersChange(newSelectedUsersIds);
        },
        [onSelectedUsersChange, selectedUsersIds],
    );

    return (
        <div className={styles.container}>
            <List>
                {teams?.length > 0 && (
                    <PickerRow
                        id={teams[0].id}
                        isGroup
                        label={teams[0].name}
                        onSelect={handleTeamSelect}
                        selected={
                            areAllSelected || selectedTeamsIds.includes(teams[0].id)
                        }
                    />
                )}

                <div className={styles.list}>
                    {teamsToRender.length > 0 && (
                        <>
                            <Divider className={styles.divider} />
                            {teamsToRender.map((team) => (
                                <PickerRow
                                    forceChecked={areAllSelected}
                                    id={team.id}
                                    isGroup
                                    key={team.id}
                                    label={team.name}
                                    onSelect={handleTeamSelect}
                                    selected={selectedTeamsIds.includes(team.id)}
                                />
                            ))}
                        </>
                    )}
                    {users.length > 0 && (
                        <>
                            <Divider className={styles.divider} />
                            {users.map((user) => (
                                <PickerRow
                                    forceChecked={areAllSelected}
                                    id={user.id}
                                    key={user.id}
                                    label={user.name}
                                    onSelect={handleUserSelect}
                                    selected={selectedUsersIds.includes(user.id)}
                                />
                            ))}
                        </>
                    )}
                </div>
            </List>
        </div>
    );
};

export default memo(UsersAndTeamsPicker);
