import type { Floor, BuildingDetail } from '@infogrid/locations-types';
import { useIsOpenState, useIsMobile } from '@infogrid/utils-hooks';
import { Icon, Button } from '@material-ui/core';
import classNames from 'classnames';
import type { Location } from 'history';
import { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { FloorRowContent as BaseFloorRowContent, FloorRowCard } from 'components/floors';
import DeleteFloorModal from 'views/estate/components/modals/DeleteFloorModal';
import EditFloorModal from 'views/estate/components/modals/EditFloorModal';

import { useFloorRowStyles } from './styles';

interface Props {
    building: BuildingDetail;
    floor: Floor;
    floorLink: Location;
    index: number;
    isActive: boolean;
    isLast: boolean;
    onFloorClick?: () => void;
}

const FloorRow = ({
    building,
    floor,
    floorLink,
    index,
    isActive,
    onFloorClick,
}: Props): JSX.Element => {
    const isMobile = useIsMobile();

    const styles = useFloorRowStyles({
        canDrag: !isMobile && building.user_actions?.edit?.allowed,
    });

    const { t } = useTranslation();

    const [isDeleteFloorModalOpen, _1, _2, toggleDeleteFloorModal] = useIsOpenState();
    const [isEditFloorModalOpen, _3, _4, toggleEditFloorModal] = useIsOpenState();

    return (
        <Draggable
            draggableId={`${floor.id}`}
            key={floor.id}
            index={index}
            isDragDisabled={isMobile || !building.user_actions?.edit?.allowed}
        >
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...provided.draggableProps}
                >
                    <FloorRowCard isActive={isActive}>
                        {!isMobile && building.user_actions?.edit?.allowed && (
                            <Icon
                                className={classNames(
                                    'fa fa-grip-lines',
                                    styles.dragHandle,
                                )}
                                data-cypress="floor-drag-handle"
                                data-testid="floor-drag-handle"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...provided.dragHandleProps}
                            />
                        )}
                        <BaseFloorRowContent
                            floor={floor}
                            floorLink={floorLink}
                            onFloorClick={onFloorClick}
                            showCapacity={true}
                        />
                        <Button
                            color="primary"
                            disabled={!floor.user_actions?.edit?.allowed}
                            data-cypress="floor-edit"
                            onClick={toggleEditFloorModal}
                        >
                            {t('Edit')}
                        </Button>
                    </FloorRowCard>
                    {isEditFloorModalOpen && (
                        <EditFloorModal
                            buildingId={building.id}
                            floor={floor}
                            onClose={toggleEditFloorModal}
                            open={isEditFloorModalOpen}
                        />
                    )}
                    {isDeleteFloorModalOpen && (
                        <DeleteFloorModal
                            floor={floor}
                            onClose={toggleDeleteFloorModal}
                            open={isDeleteFloorModalOpen}
                        />
                    )}
                </div>
            )}
        </Draggable>
    );
};

export default memo(FloorRow);
