import { MemoizedDivider } from '@infogrid/components-material-ui';
import { sensorsColors } from '@infogrid/dashboards-constants';
import type { FormikProps } from 'formik';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import PeopleOccupancySection from 'views/dashboards/widgets/common/DisplayOptions/PeopleOccupancySection';

import type { PeopleCountingStoplightValues } from '../types';

const debounceTime = 250;

interface DisplayOptionsProps {
    formik: FormikProps<PeopleCountingStoplightValues>;
}

const DisplayOptions = ({ formik }: DisplayOptionsProps) => {
    const { t } = useTranslation('dashboard');
    const {
        displayOptions: { green: greenDisplay, yellow: yellowDisplay, red: redDisplay },
    } = formik.values;

    const errors = formik?.errors;
    const redErrors = errors?.displayOptions?.red;
    const yellowErrors = errors?.displayOptions?.yellow;
    const greenErrors = errors?.displayOptions?.green;

    const [handleDebouncedGreenMaxChange] = useDebouncedCallback((greenMax) => {
        const newYellowMin = greenMax + 1;

        formik.setFieldValue('displayOptions.yellow.minThreshold', newYellowMin);

        if (yellowDisplay.maxThreshold <= newYellowMin) {
            const newYellowMax = newYellowMin + 1;

            formik.setFieldValue('displayOptions.yellow.maxThreshold', newYellowMax);

            if (redDisplay.minThreshold <= newYellowMax) {
                const newRedMin = newYellowMax + 1;

                formik.setFieldValue('displayOptions.red.minThreshold', newRedMin);

                if (redDisplay.maxThreshold < newRedMin) {
                    formik.setFieldValue(
                        'displayOptions.red.maxThreshold',
                        newRedMin + 1,
                    );
                }
            }
        }
    }, debounceTime);

    const handleGreenMaxChange = useCallback(
        (e) => {
            const greenMax = Number(e.target.value);

            formik.setFieldValue('displayOptions.green.maxThreshold', greenMax);

            handleDebouncedGreenMaxChange(greenMax);
        },
        [formik, handleDebouncedGreenMaxChange],
    );

    const [handleDebouncedYellowMaxChange] = useDebouncedCallback((yellowMax) => {
        if (yellowDisplay.minThreshold > yellowMax) {
            const newYellowMax = yellowDisplay.minThreshold + 1;
            const newRedMin = newYellowMax + 1;

            formik.setFieldValue('displayOptions.yellow.maxThreshold', newYellowMax);

            formik.setFieldValue('displayOptions.red.minThreshold', newRedMin);

            if (redDisplay.maxThreshold < newRedMin) {
                formik.setFieldValue('displayOptions.red.maxThreshold', newRedMin + 1);
            }
        } else {
            const newRedMin = yellowMax + 1;

            formik.setFieldValue('displayOptions.red.minThreshold', yellowMax + 1);

            if (redDisplay.maxThreshold < newRedMin) {
                formik.setFieldValue('displayOptions.red.maxThreshold', newRedMin + 1);
            }
        }
    }, debounceTime);

    const handleYellowMaxChange = useCallback(
        (e) => {
            const yellowMax = Number(e.target.value);

            formik.setFieldValue('displayOptions.yellow.maxThreshold', yellowMax);

            handleDebouncedYellowMaxChange(yellowMax);
        },
        [formik, handleDebouncedYellowMaxChange],
    );

    const [handleDebouncedRedMaxChange] = useDebouncedCallback((redMax) => {
        if (redMax < redDisplay.minThreshold) {
            formik.setFieldValue(
                'displayOptions.red.maxThreshold',
                redDisplay.minThreshold + 1,
            );
        }
    }, debounceTime);

    const handleRedMaxChange = useCallback(
        (e) => {
            const redMax = Number(e.target.value);

            formik.setFieldValue('displayOptions.red.maxThreshold', redMax);

            handleDebouncedRedMaxChange(redMax);
        },
        [formik, handleDebouncedRedMaxChange],
    );

    return (
        <div>
            <PeopleOccupancySection
                color={sensorsColors.green.color}
                disabledMin
                errors={greenErrors}
                handleChange={formik.handleChange}
                name="green"
                onMaxChange={handleGreenMaxChange}
                title={t('Green display')}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...greenDisplay}
            />
            <MemoizedDivider />
            <PeopleOccupancySection
                allowEqualThresholdLimits
                color={sensorsColors.yellow.color}
                disabledMin
                errors={yellowErrors}
                handleChange={formik.handleChange}
                name="yellow"
                onMaxChange={handleYellowMaxChange}
                title={t('Yellow display')}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...yellowDisplay}
            />
            <MemoizedDivider />
            <PeopleOccupancySection
                color={sensorsColors.red.color}
                disabledMin
                errors={redErrors}
                handleChange={formik.handleChange}
                onMaxChange={handleRedMaxChange}
                name="red"
                title={t('Red display')}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...redDisplay}
            />
        </div>
    );
};

DisplayOptions.propTypes = {
    formik: PropTypes.shape({
        errors: PropTypes.shape({
            displayOptions: PropTypes.shape({
                green: PropTypes.shape({
                    message: PropTypes.string,
                }),
                yellow: PropTypes.shape({
                    message: PropTypes.string,
                }),
                red: PropTypes.shape({
                    message: PropTypes.string,
                }),
            }),
        }),
        handleChange: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        values: PropTypes.shape({
            displayOptions: PropTypes.shape({
                green: PropTypes.shape({}),
                yellow: PropTypes.shape({}),
                red: PropTypes.shape({}),
            }),
        }),
    }).isRequired,
};

export default memo(DisplayOptions);
