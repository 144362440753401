export const DEFAULT_CHART_MARGIN = {
    top: 25,
    right: 25,
    left: 10,
    bottom: 10,
};
export const DEFAULT_CHART_COLOR = '#009FE3';
export const SECONDARY_CHART_COLOR = '#B34B8D';
export const PROXIMITY_DATA_GRAPH_COLOR = '#009FE3';
export const DEFAULT_Y_AXIS_WIDTH = 80;
export const DEFAULT_X_AXIS_HEIGHT = 80;
export const DEFAULT_MAX_BAR_SIZE = 40;

export const CONFIRMATION_AXIS_MARGIN = 16;
export const PERCENTAGE_Y_AXIS_WIDTH = 50;

export const DOMAIN_AUTO = ['auto', 'auto'];

/**
 * Holds information about the colors in the stacked bar graph on the dashboard:
 * - the color code
 * - the order of this colored bar in the entire stacked bar (order from bottom to top)
 */
export const GRAPH_COLOR = {
    GREEN: {
        order: 0,
        colorCode: '#3D8D27',
    },
    YELLOW: {
        order: 1,
        colorCode: '#FFA630',
    },
    RED: {
        order: 2,
        colorCode: '#AF3535',
    },
    BLUE: {
        order: 3,
        colorCode: '#5C7ACC',
    },
    GREY: {
        order: 4,
        colorCode: '#7A7A7A',
    },
};
export const COLOR_NAMES = Object.keys(GRAPH_COLOR);
export const DEFAULT_COLOR = COLOR_NAMES[0];
