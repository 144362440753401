import { TextField } from '@material-ui/core';
import type { TextFieldProps } from '@material-ui/core';
import type { FormikErrors } from 'formik';
import type { TFunction } from 'react-i18next';

import type { ConfigurationFormValues } from './types';

type RenderInputFunction = (params: TextFieldProps) => JSX.Element;

interface RenderInputFunctions {
    renderBuildingInput: RenderInputFunction;
    renderFloorInput: RenderInputFunction;
    renderSpaceInput: RenderInputFunction;
    renderLabelsInput: RenderInputFunction;
}

export const getRenderInputs = (
    errors: FormikErrors<ConfigurationFormValues>,
    t: TFunction,
): RenderInputFunctions => {
    const renderBuildingInput = (params: TextFieldProps) => (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={t('Building', { ns: 'sensors' })}
            variant="outlined"
            error={!!errors.building}
            helperText={
                <span data-cypress="building-error-text">
                    {errors.building ||
                        t('Select which building you are installing this device in', {
                            ns: 'sensors',
                        })}
                </span>
            }
        />
    );

    const renderFloorInput = (params: TextFieldProps) => (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={t('Floor', { ns: 'sensors' })}
            variant="outlined"
            error={!!errors.floor}
            helperText={
                <span data-cypress="floor-error-text">
                    {errors.floor ||
                        t('Select floor where this device is located', { ns: 'sensors' })}
                </span>
            }
        />
    );

    const renderSpaceInput = (params: TextFieldProps) => (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={t('Space', { ns: 'sensors' })}
            variant="outlined"
            error={!!errors.space}
            helperText={
                <span data-cypress="space-error-text">
                    {errors.space || t('Select a space on this floor', { ns: 'sensors' })}
                </span>
            }
        />
    );

    const renderLabelsInput = (params: TextFieldProps) => (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label={t('Labels', { ns: 'sensors' })}
            variant="outlined"
            error={!!errors.labels}
            helperText={errors.labels}
        />
    );

    return { renderBuildingInput, renderFloorInput, renderSpaceInput, renderLabelsInput };
};
