import { ConfirmModal } from '@infogrid/components-material-ui';
import type { FeedbackPanelTemplate } from '@infogrid/sensors-constants';
import { Divider, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { memo, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FeedbackPanelSensorOption from './FeedbackPanelSensorOption';
import { useFeedbackPanelSensorOptionsStyles } from './styles';
import type { FeedbackPanelSensorOptionFormValue } from './types';

interface FeedbackPanelSensorOptionsProps {
    feedbackPanelTemplate: FeedbackPanelTemplate;
    values: FeedbackPanelSensorOptionFormValue[];
    identifyingOption: number | null;
    isConfirmAddModalOpen: boolean;
    onCloseConfirmAddModal: () => void;
    onConfirmAddModal: () => void;
    onIdentifyStartFor: (optionId: number) => void;
    onIdentifySensorOption: (payload: {
        deviceName: string;
        sensorOptionIndex: number;
        alreadyInFeedbackPanel: boolean;
    }) => void;
}

const FeedbackPanelSensorOptions = ({
    feedbackPanelTemplate,
    values,
    identifyingOption,
    isConfirmAddModalOpen,
    onCloseConfirmAddModal,
    onConfirmAddModal,
    onIdentifyStartFor,
    onIdentifySensorOption,
}: FeedbackPanelSensorOptionsProps) => {
    const { t } = useTranslation('sensors');
    const styles = useFeedbackPanelSensorOptionsStyles();

    // Used to figure out the matching index of `templateOption.id` in `sensor_options`
    const templateOptionIdToSensorOptionIndex = useMemo(
        () =>
            Object.fromEntries(
                feedbackPanelTemplate.template_options.map((templateOption) => [
                    templateOption.id,
                    values.findIndex(
                        (sensorOption) => sensorOption.option_id === templateOption.id,
                    ),
                ]),
            ),
        [feedbackPanelTemplate.template_options, values],
    );

    return (
        <form>
            {feedbackPanelTemplate.template_options.map((templateOption, index) => {
                if (!templateOption) {
                    throw new Error(
                        'Feedback panel template is missing template option for sensor option',
                    );
                }

                const sensorOptionIndex =
                    templateOptionIdToSensorOptionIndex[templateOption.id];

                return (
                    <Fragment key={templateOption.id}>
                        {index !== 0 && <Divider className={styles.divider} />}
                        <FeedbackPanelSensorOption
                            deviceName={values[sensorOptionIndex].device_name ?? null}
                            sensorOptionIndex={sensorOptionIndex}
                            templateOption={templateOption}
                            canIdentify={identifyingOption === null}
                            isIdentifying={identifyingOption === templateOption.id}
                            onIdentifyStartFor={onIdentifyStartFor}
                            onIdentifySensorOption={onIdentifySensorOption}
                        />
                    </Fragment>
                );
            })}
            <ConfirmModal
                title={t('Are you sure?', { ns: 'common' })}
                open={isConfirmAddModalOpen}
                onClose={onCloseConfirmAddModal}
                onConfirm={onConfirmAddModal}
                content={
                    <Alert color="warning">
                        <Typography>
                            {t(
                                'The sensor you are trying to add is already part of another feedback panel.',
                            )}
                        </Typography>
                        <Typography>{t('Are you sure you want to continue?')}</Typography>
                    </Alert>
                }
                confirmButtonText={t('Yes', { ns: 'common' })}
            />
        </form>
    );
};

export default memo(FeedbackPanelSensorOptions);
