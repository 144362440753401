import { NO_LOCATIONS_SELECTED } from '@infogrid/components-locations';
import { useDashboard, useUpdateWidget, useWidgetData } from '@infogrid/dashboards-hooks';
import { useIsMobile, useIsOpenState } from '@infogrid/utils-hooks';
import { CircularProgress } from '@material-ui/core';
import isNil from 'lodash/isNil';
import { useCallback, useMemo, useRef } from 'react';
import type { Layout } from 'react-grid-layout';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import { GenericActions, GenericTitle, Widget } from 'views/dashboards/components/Widget';
import type { ConfigurableProps } from 'views/dashboards/components/Widget/GenericWidget/GenericActions/types';
import { configurationDefaults } from 'views/dashboards/components/Widget/GenericWidget/constants';
import {
    useGenericWidgetContainerStyles,
    useGenericWidgetStyles,
} from 'views/dashboards/components/Widget/GenericWidget/styles';
import type { ConfigurationProps } from 'views/dashboards/components/Widget/GenericWidget/types';
import { DeleteWidgetModal } from 'views/dashboards/components/modals';
import { canChangeDashboard, hasUserPermissions } from 'views/dashboards/utils/helpers';
import { useConfigureWidgetModalOpenState } from 'views/dashboards/utils/hooks';

import { ConfigureWidgetModal } from './ConfigureWidgetModal';
import { Content } from './Content';
import { Footer } from './Footer';
import { widgetConfigurationModalTabs } from './constants';
import { useMeetingRoomOccupancyWidgetStyles } from './styles';
import type {
    MeetingRoomOccupancyData,
    MeetingRoomOccupancyWidget as MeetingRoomOccupancyWidgetType,
} from './types';
import { prepareConfigurationPayload, prepareInitialValues } from './utils';

export interface MeetingRoomOccupancyWidgetProps {
    canDragAndResize: boolean;
    configuration?: ConfigurationProps;
    containerClassName?: string;
    layout?: Layout;
    useIntersectionObserverLoader?: boolean;
    widget: MeetingRoomOccupancyWidgetType;
}

export const MeetingRoomOccupancyWidget = ({
    canDragAndResize,
    configuration = configurationDefaults,
    containerClassName,
    layout,
    useIntersectionObserverLoader = true,
    widget,
}: MeetingRoomOccupancyWidgetProps): JSX.Element => {
    const genericContainerStyles = useGenericWidgetContainerStyles();
    const genericWidgetStyles = useGenericWidgetStyles();
    const styles = useMeetingRoomOccupancyWidgetStyles();

    const { dashboardId } = useParams<{ dashboardId: string }>();

    const isMobile = useIsMobile();

    const { data: dashboard } = useDashboard(+dashboardId);

    const {
        handleConfigureWidgetModalClose,
        handleConfigureWidgetModalOpen,
        isConfigureWidgetModalOpen,
    } = useConfigureWidgetModalOpenState(widget.id, widget.type);

    const isHardRefreshData = useRef(false);

    const [
        deleteWidgetModalOpen,
        handleDeleteWidgetModalClose,
        handleDeleteWidgetModalOpen,
    ] = useIsOpenState();

    const { mutate: updateWidget, isLoading: isUpdatingWidget } =
        useUpdateWidget<MeetingRoomOccupancyWidgetType>();

    const isInitiallyConfigured =
        widget.configuration.calculators.space_occupancy.locations !==
        NO_LOCATIONS_SELECTED;

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: '100px 0px',
        skip: !useIntersectionObserverLoader,
        initialInView: !useIntersectionObserverLoader,
        triggerOnce: true,
    });

    const {
        data: widgetData,
        error,
        isFetching: fetchingWidgetData,
        isLoading: loadingWidgetData,
        isSuccess: loadingWidgetDataSuccess,
        refetch: refetchWidgetData,
        isError: isLoadingWidgetDataError,
    } = useWidgetData<MeetingRoomOccupancyData>(+dashboardId, widget.id, {
        getQueryParams: () => ({
            refresh: isHardRefreshData.current || undefined,
        }),
        options: {
            enabled: inView && isInitiallyConfigured,
            onSettled: () => {
                isHardRefreshData.current = false;
            },
            refetchInterval: 5 * 60 * 1000, // 5 minutes
        },
    });

    const areNoMeetingRooms = error?.response?.status === 422;

    const onUpdateWidgetTitle = useCallback(
        (title: string) => {
            updateWidget({
                dashboardId: +dashboardId,
                widgetId: widget.id,
                data: { name: title },
            });
        },
        [dashboardId, updateWidget, widget.id],
    );

    const onUpdateConfiguration = useCallback(
        (values) => {
            updateWidget({
                dashboardId: +dashboardId,
                widgetId: widget.id,
                data: {
                    ...widget,
                    configuration: prepareConfigurationPayload(values),
                },
            });
        },
        [dashboardId, updateWidget, widget],
    );

    const widgetConfigurationInitialValues = useMemo(
        () => prepareInitialValues(widget),
        [widget],
    );

    const configurableProps: ConfigurableProps = {
        onConfigure: handleConfigureWidgetModalOpen,
        onDelete: handleDeleteWidgetModalOpen,
        isDuplicateDisabled: !isInitiallyConfigured,
        isConfigureWidgetAvailable: true,
    };

    const {
        actionsEnabled,
        isActionsEnabled,
        isEnabled: isConfigureWidgetModalEnabled,
    } = configuration;

    const widgetCardRef = useRef(null);

    const onRefresh = useCallback(() => {
        isHardRefreshData.current = true;
        refetchWidgetData();
    }, [refetchWidgetData]);

    const canUserEdit = useMemo(() => {
        if (!isNil(configurableProps.canUserEdit)) {
            return configurableProps.canUserEdit;
        }

        return (
            !!dashboard && hasUserPermissions(dashboard) && canChangeDashboard(dashboard)
        );
    }, [configurableProps.canUserEdit, dashboard]);

    return (
        <div ref={ref} className={genericContainerStyles.container} data-cypress="widget">
            <Widget className={containerClassName}>
                <Widget.Bar canDragAndResize={canDragAndResize}>
                    <GenericTitle
                        onUpdateTitle={onUpdateWidgetTitle}
                        title={widget.name}
                        titleEditable={canUserEdit}
                        titleIcon={<i className="far fa-users-class" />}
                    />
                    {((actionsEnabled && !isMobile) || isActionsEnabled) && (
                        <GenericActions
                            configurableProps={configurableProps}
                            dashboardId={dashboardId}
                            refreshableProps={{
                                enabled: isInitiallyConfigured,
                                isLoading: fetchingWidgetData && loadingWidgetDataSuccess,
                                onRefresh,
                            }}
                            canUserEdit={canUserEdit}
                            widgetConfiguration={widget.configuration}
                            widgetType={widget.type}
                            widgetName={widget.name}
                        />
                    )}
                </Widget.Bar>

                {loadingWidgetData && (
                    <div className={genericWidgetStyles.progress}>
                        <CircularProgress
                            color="primary"
                            data-testid="widget-circular-loader"
                            size={40}
                        />
                    </div>
                )}

                {inView && !loadingWidgetData && (
                    <Widget.Content
                        ref={widgetCardRef}
                        className={styles.content}
                        layout={layout}
                    >
                        <Content
                            areNoMeetingRooms={areNoMeetingRooms}
                            data={widgetData}
                            isConfigured={isInitiallyConfigured}
                            isLoadingDataError={isLoadingWidgetDataError}
                        />
                    </Widget.Content>
                )}

                <Footer spaceCount={widgetData?.count ?? 0} />

                {isConfigureWidgetModalEnabled && isConfigureWidgetModalOpen && (
                    <ConfigureWidgetModal
                        initialValues={widgetConfigurationInitialValues}
                        loading={isUpdatingWidget}
                        onClose={handleConfigureWidgetModalClose}
                        onUpdateConfiguration={onUpdateConfiguration}
                        open={isConfigureWidgetModalOpen}
                        tabs={widgetConfigurationModalTabs}
                    />
                )}

                <DeleteWidgetModal
                    dashboardId={dashboardId}
                    onClose={handleDeleteWidgetModalClose}
                    open={deleteWidgetModalOpen}
                    widgetId={widget.id}
                    widgetName={widget.name}
                    widgetType={widget.type}
                />
            </Widget>
        </div>
    );
};
