import { fade, makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

interface Props {
    collapsedHeight: number;
    isOpen: boolean;
    topMargin: number;
}

export const useMobileSensorNavigationStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: theme.palette.background.header,
        borderTop: `1px solid ${theme.palette.borders.listItem}`,
        height: '100%',
        overflow: 'auto',
    },
    root: {
        height: '100%',
    },
    sensorsListContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxHeight: '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.common.shell,
    },
    showHideButton: {
        fontSize: '13px',
    },
    titleBar: ({ collapsedHeight, isOpen }: Props) => ({
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        borderRadius: isOpen ? undefined : theme.spacing(2.5, 2.5, 0, 0),
        boxShadow: `0px -2px 8px 0px ${fade(theme.palette.common.black, 0.16)}`,
        display: 'flex',
        height: collapsedHeight,
        justifyContent: 'space-between',
        left: 0,
        padding: theme.spacing(3, 2.5),
        position: 'absolute',
        right: 0,
        top: -collapsedHeight,
        visibility: 'visible',
    }),
    titleText: {
        fontSize: '20px',
    },
    totalSensors: {
        fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
    },
    collapsedContainer: ({ collapsedHeight, topMargin }: Props) => ({
        height: `calc(100% - ${collapsedHeight}px - ${topMargin}px)`,
        overflow: 'visible',
    }),
    unmappedSensorsText: {
        fontSize: '20px',
        marginLeft: theme.spacing(1),
    },
}));
