import type { LegacyFloorSensor } from '@infogrid/sensors-constants';
import { Checkbox, Link, Tooltip } from '@material-ui/core';
import { memo, useCallback } from 'react';

import MapSensorIcon from 'components/sensors/LegacySensorIcon';
import useCurrentSensorState from 'utils/hooks/useCurrentSensorState';

import { useSensorItemStyles } from './styles';

interface Props {
    isSelected: boolean;
    onSelectSensor: (sensor: LegacyFloorSensor) => void;
    sensor: LegacyFloorSensor;
}

const SensorItem = ({ isSelected, onSelectSensor, sensor }: Props) => {
    const styles = useSensorItemStyles();

    const sensorConfig = useCurrentSensorState(sensor);
    const IconComponent = sensorConfig.getIcon();
    const baseIconState = sensorConfig.getMapIconState();

    const sensorName = sensor.profile?.name || sensor.device_name;

    const handleSensorSelect = useCallback(() => {
        onSelectSensor(sensor);
    }, [onSelectSensor, sensor]);

    return (
        <div className={styles.container}>
            <Checkbox
                checked={isSelected}
                color="primary"
                onChange={handleSensorSelect}
            />
            <div className={styles.sensorIconWrapper}>
                <MapSensorIcon
                    IconComponent={IconComponent}
                    state={baseIconState}
                    mode="base"
                    size={24}
                />
            </div>
            <Tooltip placement="top" title={sensorName}>
                <Link
                    className={styles.sensorName}
                    href={`/sensors/${sensor.device_name}`}
                    underline="hover"
                >
                    {sensorName}
                </Link>
            </Tooltip>
        </div>
    );
};

export default memo(SensorItem);
