import { theme } from '@infogrid/components-material-ui';
import { lighten } from '@material-ui/core/styles';
import { memo } from 'react';

export type SensorIconMode = 'base' | 'hover' | 'selected';
export type SensorIconState = 'base' | 'green' | 'amber' | 'red' | 'gray' | 'planned';

type IconStyle = {
    color: string;
    backgroundFill: string;
    lineDash?: [number, number];
};
type IconStyleStatesDef = Record<SensorIconState, IconStyle>;

const basePlannedSensorStyles: IconStyle = {
    color: theme.palette.primary.main,
    backgroundFill: theme.palette.common.white,
    lineDash: [6.8, 6.8],
};

const BaseIconStyles: IconStyleStatesDef = {
    planned: basePlannedSensorStyles,
    base: {
        color: theme.palette.primary.main,
        backgroundFill: lighten(theme.palette.primary.main, 0.9),
    },
    green: {
        color: theme.palette.common.green2,
        backgroundFill: lighten(theme.palette.common.green2, 0.9),
    },
    amber: {
        color: theme.palette.common.yellow2,
        backgroundFill: lighten(theme.palette.common.yellow2, 0.9),
    },
    red: {
        color: theme.palette.common.red2,
        backgroundFill: lighten(theme.palette.common.red2, 0.9),
    },
    gray: {
        color: theme.palette.common.gray4,
        backgroundFill: lighten(theme.palette.common.gray5, 0.9),
    },
};

const HoverIconStyles: IconStyleStatesDef = {
    base: {
        color: theme.palette.primary.main,
        backgroundFill: theme.palette.common.white,
    },
    green: {
        color: theme.palette.common.green2,
        backgroundFill: theme.palette.common.white,
    },
    amber: {
        color: theme.palette.common.yellow2,
        backgroundFill: theme.palette.common.white,
    },
    red: {
        color: theme.palette.common.red2,
        backgroundFill: theme.palette.common.white,
    },
    gray: {
        color: theme.palette.common.gray4,
        backgroundFill: theme.palette.common.gray5,
    },
    planned: {
        ...basePlannedSensorStyles,
        backgroundFill: lighten(theme.palette.primary.main, 0.9),
    },
};

const SelectedIconStyles: IconStyleStatesDef = {
    base: {
        color: theme.palette.common.white,
        backgroundFill: theme.palette.primary.main,
    },
    green: {
        color: theme.palette.common.white,
        backgroundFill: theme.palette.common.green2,
    },
    amber: {
        color: theme.palette.common.white,
        backgroundFill: theme.palette.common.yellow2,
    },
    red: {
        color: theme.palette.common.white,
        backgroundFill: theme.palette.common.red2,
    },
    gray: {
        color: theme.palette.common.white,
        backgroundFill: theme.palette.common.gray4,
    },
    planned: basePlannedSensorStyles,
};

export const IconStyles = {
    base: BaseIconStyles,
    hover: HoverIconStyles,
    selected: SelectedIconStyles,
};

interface IconComponentProps {
    color?: string;
    backgroundFill?: string;
    className?: string;
    size?: number;
}

interface Props {
    IconComponent: React.ComponentType<IconComponentProps>;
    size: number;
    mode: SensorIconMode;
    state: SensorIconState;
}

const LegacySensorIcon = ({ IconComponent, mode, state, size }: Props) => {
    const { color, backgroundFill } = IconStyles[mode][state];

    return <IconComponent color={color} backgroundFill={backgroundFill} size={size} />;
};

export default memo(LegacySensorIcon);
