import type { SensorVendor } from '@infogrid/sensors-constants';
import { SENSOR_VENDORS } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

export const INSTALLATION_FLOW_STEPS = {
    qrCodeScanner: 'QR_CODE_SCANNER',
    tempUseCaseSelector: 'TEMP_USE_CASE_SELECTOR',
    vendorSelector: 'VENDOR_SELECTOR',
    airthingsIdentifier: 'AIRTHINGS_IDENTIFIER',
    BEAIdentifier: 'BEA_IDENTIFIER',
    BTLIdentifier: 'BTL_IDENTIFIER',
    DTIdentifier: 'DT_IDENTIFIER',
    feedbackPanelTemplateSelector: 'FEEDBACK_PANEL_TEMPLATE_SELECTOR',
    feedbackPanelTouchIdentifier: 'FEEDBACK_PANEL_TOUCH_IDENTIFIER',
    successView: 'SUCCESS_VIEW',
    feedbackPanelSuccessView: 'FEEDBACK_PANEL_SUCCESS_VIEW',
    connectionCheck: 'CONNECTION_CHECK',
    configuration: 'CONFIGURATION',
    tempOffsetConfiguration: 'TEMPERATURE_OFFSET_CONFIGURATION',
    tempOffsetMode: 'TEMPERATURE_OFFSET_MODE',
    tempOffsetConfigurationHelp: 'TEMPERATURE_OFFSET_CONFIGURATION_HELP',
    feedbackPanelConfiguration: 'FEEDBACK_PANEL_CONFIGURATION',
    siluxHubIdentifier: 'EMERGENCY_LIGHT_HUB_IDENTIFIER',
    emergencyLightIdentifier: 'EMERGENCY_LIGHT_IDENTIFIER,',
    monnitIdentifier: 'MONNIT_CT_CLAMP_IDENTIFIER',
    monnitConfiguration: 'MONNIT_CT_CLAMP_CONFIGURATION',
    fludiaIdentifier: 'FLUDIA_LED_PULSE_IDENTIFIER',
    fludiaConfiguration: 'FLUDIA_LED_PULSE_CONFIGURATION',
    fludiaTechnicalConfiguration: 'FLUDIA_LED_PULSE_TECHNICAL_CONFIGURATION',
    electricityMonitoringConfiguration: 'ELECTRICITY_MONITORING_CONFIGURATION',
    monnitDryPulseConfiguration: 'MONNIT_DRY_PULSE_CONFIGURATION',
    urbanHubIdentifier: 'URBAN_HUB_IDENTIFIER',
    smartSpaceIdentifier: 'SMART_SPACE_IDENTIFIER',
} as const;
export type InstallationFlowStep =
    typeof INSTALLATION_FLOW_STEPS[keyof typeof INSTALLATION_FLOW_STEPS];

const QR_CODE_VENDOR = 'qr_code';

export const INSTALLATION_FLOW_VENDORS = {
    [INSTALLATION_FLOW_STEPS.qrCodeScanner]: QR_CODE_VENDOR,
    [INSTALLATION_FLOW_STEPS.airthingsIdentifier]: SENSOR_VENDORS.AIRTHINGS,
    [INSTALLATION_FLOW_STEPS.BEAIdentifier]: SENSOR_VENDORS.BEA,
    [INSTALLATION_FLOW_STEPS.BTLIdentifier]: SENSOR_VENDORS.BTL,
    [INSTALLATION_FLOW_STEPS.DTIdentifier]: SENSOR_VENDORS.DT,
    [INSTALLATION_FLOW_STEPS.siluxHubIdentifier]: SENSOR_VENDORS.SILUX,
    [INSTALLATION_FLOW_STEPS.emergencyLightIdentifier]: SENSOR_VENDORS.SILUX,
    [INSTALLATION_FLOW_STEPS.monnitIdentifier]: SENSOR_VENDORS.MON,
    [INSTALLATION_FLOW_STEPS.fludiaIdentifier]: SENSOR_VENDORS.FLU,
    [INSTALLATION_FLOW_STEPS.urbanHubIdentifier]: SENSOR_VENDORS.URB,
    [INSTALLATION_FLOW_STEPS.smartSpaceIdentifier]: SENSOR_VENDORS.URB,
} as { [K in InstallationFlowStep]: typeof QR_CODE_VENDOR | SensorVendor };
export type InstallationFlowVendorStep = keyof typeof INSTALLATION_FLOW_VENDORS;
export type InstallationFlowVendor =
    typeof INSTALLATION_FLOW_VENDORS[keyof typeof INSTALLATION_FLOW_VENDORS];

export const INSTALLATION_FLOW_STEPS_WITH_HIDDEN_CLOSE_BUTTON: InstallationFlowStep[] = [
    INSTALLATION_FLOW_STEPS.tempOffsetConfigurationHelp,
];

export const REQUIRE_DISCARD_WARNING_STEPS = [
    INSTALLATION_FLOW_STEPS.qrCodeScanner,
    INSTALLATION_FLOW_STEPS.vendorSelector,
    INSTALLATION_FLOW_STEPS.successView,
    INSTALLATION_FLOW_STEPS.feedbackPanelTemplateSelector,
] as InstallationFlowStep[];

// portrait mode of scanner should be turn on ONLY if it's enough space for all instructions
// and if the width is less that isMobile width
export const PORTRAIT_ORIENTATION_MEDIA =
    'only screen and (orientation: portrait) and (min-height: 563px) and (max-width: 767px)';

export const DISCARD_MODAL_DEFAULTS = Object.freeze({
    WARNING_MESSAGE: registerTranslationKey(
        'You are about to exit device installation. All progress will be lost. Are you sure you want to continue?',
        { ns: 'sensors' },
    ),
    ON_DISCARD_BUTTON_TEXT: registerTranslationKey('Exit', { ns: 'sensors' }),
});

export const DEVICE_IDENTIFICATION_ERRORS = Object.freeze({
    NOT_FOUND: registerTranslationKey('There is no such sensor in our system.', {
        ns: 'sensors',
    }),
    ACCESS_DENIED: registerTranslationKey(
        'You don’t have permission to configure this sensor',
        { ns: 'sensors' },
    ),
});

export const DISCARD_MODAL_SETTINGS = {
    WARNING_MESSAGE: registerTranslationKey(
        'Are you sure you want to go back? All configuration will be lost.',
        { ns: 'sensors' },
    ),
    ON_DISCARD_BUTTON_TEXT: registerTranslationKey('Back', { ns: 'sensors' }),
};

export const PREVIOUSLY_INSTALLED_MODAL_SETTINGS = {
    TITLE_TEXT: registerTranslationKey('Overwrite configuration', { ns: 'sensors' }),
    WARNING_MESSAGE: registerTranslationKey('The device has already been configured', {
        ns: 'sensors',
    }),
    ON_DISCARD_BUTTON_TEXT: registerTranslationKey('No', { ns: 'sensors' }),
    ON_RESUME_BUTTON_TEXT: registerTranslationKey('Yes, overwrite', { ns: 'sensors' }),
};
