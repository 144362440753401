import { makeStyles } from '@material-ui/core';

export const useFloorPlanWidgetStyles = makeStyles({
    widgetContainer: {
        width: '100%',
        height: '100%',
    },
    genericWidgetContainer: {
        justifyContent: 'initial',
    },
    contentContainer: {
        padding: 0,
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
});
