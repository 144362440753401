import { Autocomplete } from '@infogrid/components-material-ui';
import {
    FormControlLabel,
    InputAdornment,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';
import type { FormikValues } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTimezones } from 'apiHooks/timezones/hooks';
import DateRangePicker from 'components/DateRangePicker';
import type { DateRangePickerProps } from 'components/DateRangePicker/DateRangePicker';

import { useDateRangeStyles } from './styles';

export interface DateRangeProps {
    enableExcludeWeekends?: boolean;
    enableWorkHours?: boolean;
    formik: FormikValues;
    dateRangePickerProps?: DateRangePickerProps | Record<string, never>;
    enableDateRangeAlert?: boolean;
}

const DateRange = ({
    dateRangePickerProps = {},
    enableExcludeWeekends = true,
    enableWorkHours = true,
    enableDateRangeAlert = false,
    formik,
}: DateRangeProps) => {
    const styles = useDateRangeStyles();
    const { t } = useTranslation('dashboard');

    const { data: timezones } = useTimezones();

    const {
        endDate,
        excludeWeekends,
        onlyWorkHours,
        period,
        startDate,
        timezone,
        workHoursFrom,
        workHoursTo,
    } = formik?.values?.dateRange || {};

    const renderTimezonesInput = useCallback(
        (params) => (
            <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                id="timezone"
                InputProps={params.InputProps}
                name="dateRange.timezone"
                placeholder={t('Select timezone')}
                size="small"
                variant="outlined"
            />
        ),
        [t],
    );

    const handleDatesChange = useCallback(
        (dates) => {
            formik.setFieldValue('dateRange.startDate', dates.startDate);
            formik.setFieldValue('dateRange.endDate', dates.endDate);
            formik.setFieldValue('dateRange.period', 'custom');
        },
        [formik],
    );

    const handlePeriodChange = useCallback(
        (periodItem) => {
            formik.setFieldValue('dateRange.startDate', periodItem.startDate);
            formik.setFieldValue('dateRange.endDate', periodItem.endDate);
            formik.setFieldValue('dateRange.period', periodItem.value);
        },
        [formik],
    );

    const handleTimezoneAutocompleteChange = useCallback(
        (_, timezoneOption) => {
            formik.setFieldValue('dateRange.timezone', timezoneOption);
        },
        [formik],
    );

    const timePickerInputProps = useMemo(
        () => ({
            startAdornment: (
                <InputAdornment position="start">
                    <i className={classNames('far fa-clock', styles.timePickerIcon)} />
                </InputAdornment>
            ),
        }),
        [styles.timePickerIcon],
    );

    return (
        <div>
            <Typography className={styles.description} variant="body1">
                {t('Choose the date range of the data you will see in this widget.')}
            </Typography>
            <div className={styles.wrapper1} data-cypress="widget-date-range-box">
                <Typography variant="body2">{t('Date range:')}</Typography>
                <DateRangePicker
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dateRangePickerProps}
                    endDate={endDate}
                    endDateId="end-date-id"
                    endDatePlaceholderText={t('To')}
                    onDatesChange={handleDatesChange}
                    onPeriodChange={handlePeriodChange}
                    period={period}
                    small
                    startDate={startDate}
                    startDateId="start-date-id"
                    startDatePlaceholderText={t('From')}
                />
            </div>
            {enableExcludeWeekends && (
                <FormControlLabel
                    control={
                        <Switch
                            checked={!!excludeWeekends}
                            color="primary"
                            id="excludeWeekends"
                            name="dateRange.excludeWeekends"
                            onChange={formik.handleChange}
                            data-cypress="exclude-weekends-checkbox"
                        />
                    }
                    label={t('Exclude weekends')}
                />
            )}
            {enableWorkHours && (
                <div className={styles.wrapper2}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!!onlyWorkHours}
                                color="primary"
                                id="onlyWorkHours"
                                name="dateRange.onlyWorkHours"
                                onChange={formik.handleChange}
                                data-cypress="enable-work-hours"
                            />
                        }
                        label={t('Only on work hours')}
                    />
                    <div className={styles.wrapper3}>
                        <TextField
                            className={styles.timePicker}
                            disabled={!onlyWorkHours}
                            id="workHoursFrom"
                            InputProps={timePickerInputProps}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="dateRange.workHoursFrom"
                            onChange={formik.handleChange}
                            size="small"
                            type="time"
                            value={workHoursFrom}
                            variant="outlined"
                            data-cypress="work-hours-from-field"
                        />
                        <Typography className={styles.timeRangeSeparator} variant="body2">
                            —
                        </Typography>
                        <TextField
                            className={styles.timePicker}
                            disabled={!onlyWorkHours}
                            id="workHoursTo"
                            InputProps={timePickerInputProps}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="dateRange.workHoursTo"
                            onChange={formik.handleChange}
                            size="small"
                            type="time"
                            value={workHoursTo}
                            variant="outlined"
                            data-cypress="work-hours-to-field"
                        />
                    </div>
                </div>
            )}
            <div className={styles.wrapper4}>
                <Typography variant="body2">{t('Time zone:')}</Typography>
                <Autocomplete
                    disableClearable
                    options={timezones}
                    onChange={handleTimezoneAutocompleteChange}
                    renderInput={renderTimezonesInput}
                    value={timezone}
                    data-cypress="change-widget-timezone"
                />
            </div>
            {/* TODO: SM-287 remove this block and enableDateRangeAlert */}
            {enableDateRangeAlert && (
                <div className={styles.wrapperDateRangeAlert}>
                    <Alert severity="info">
                        {t(
                            'Dates before 1 October 2021 are temporarily unavailable in this widget. Please contact Support for more information.',
                        )}
                    </Alert>
                </div>
            )}
        </div>
    );
};

export default memo(DateRange);
