import { fade, makeStyles } from '@material-ui/core';

import { BUILDINGS_SIDEBAR_WIDTH } from '../consts';

export const useDesktopBuildingSidebarStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.common.white,
        boxShadow: `1px 0px 3px ${fade(theme.palette.common.black, 0.2)}`,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 56px)',
        maxWidth: BUILDINGS_SIDEBAR_WIDTH,
        overflow: 'hidden',
        position: 'sticky',
        top: 56,
        width: '100%',
    },
    headerContainer: {
        padding: theme.spacing(2.25),
        '& > div:first-child': {
            marginBottom: theme.spacing(3),
        },
    },
    divider: {
        backgroundColor: theme.palette.common.shell,
    },
}));
