import { Classes, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import { connect, getIn } from 'formik';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { FieldProps, FormGroupProps } from 'utils/types/forms';

export const FormFieldError = ({ className, error, showError, cypressAttribute }) =>
    showError && error ? (
        <p
            className={classNames(Classes.TEXT_SMALL, 'text-danger mt-2', className)}
            data-cypress={cypressAttribute}
        >
            {error}
        </p>
    ) : null;

FormFieldError.propTypes = {
    className: PropTypes.string,
    error: PropTypes.string,
    showError: PropTypes.bool,
    cypressAttribute: PropTypes.string,
};
FormFieldError.defaultProps = {
    className: null,
    error: null,
    showError: true,
    cypressAttribute: null,
};

const FormField = ({
    name,
    label,
    inline,
    large,
    inputInline,
    defaultValue,
    formik,
    render,
    children,
    afterOnChange,
    errorsRequireTouched,
    hideError,
    formGroupClassName,
    contentClassName,
    inputComponent,
    getNextValue,
    errorCypressAttribute,
    onBlur,
    ...props
}) => {
    const { handleChange, setFieldValue } = formik;
    const error = getIn(formik.errors, name, null);
    const value = getIn(formik.values, name, undefined);
    const touched = getIn(formik.touched, name, false);

    const intent = touched && error ? Intent.DANGER : null;
    const showError = !hideError && !!error && (!errorsRequireTouched || touched);

    // If getNextValue is specified, use that to calculate the next value with onChange arguments
    // This is useful if your inputComponent onChange isn't called with events
    // If afterOnChange is specified, call it after setting the value in formik
    const onChange = useMemo(() => {
        if (getNextValue === undefined) {
            if (afterOnChange === undefined) {
                return handleChange;
            }

            return (...args) => {
                handleChange(...args);
                afterOnChange(...args);
            };
        } else if (afterOnChange === undefined) {
            return (...args) => {
                const newValue = getNextValue(...args);

                setFieldValue(name, newValue);
            };
        }

        return (...args) => {
            const newValue = getNextValue(...args);

            setFieldValue(name, newValue);
            afterOnChange(...args);
        };
    }, [afterOnChange, getNextValue, name, handleChange, setFieldValue]);

    const combinedProps = {
        name,
        large,
        onChange,
        onBlur: (args) => {
            onBlur(args);
            formik.handleBlur(args);
        },
        touched: `${touched}`,
        intent,
        children,
    };

    if (defaultValue === null) {
        combinedProps.value = value;
    } else {
        combinedProps.defaultValue = defaultValue;
    }

    if (inputInline !== undefined) {
        combinedProps.inline = inputInline;
    }

    let inputElement;

    if (inputComponent) {
        const InputComponent = inputComponent;

        // eslint-disable-next-line react/jsx-props-no-spreading
        inputElement = <InputComponent {...props} {...combinedProps} />;
    } else if (render) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        inputElement = render({ formik, ...props, ...combinedProps });
    } else if (children) {
        inputElement = children;
    } else {
        // eslint-disable-next-line react/jsx-props-no-spreading
        inputElement = <InputGroup {...props} {...combinedProps} />;
    }

    return (
        <FormGroup
            label={label}
            inline={inline}
            className={formGroupClassName}
            contentClassName={contentClassName}
        >
            {inputElement}
            <FormFieldError
                error={error}
                showError={showError}
                cypressAttribute={errorCypressAttribute}
            />
        </FormGroup>
    );
};

FormField.propTypes = {
    ...FieldProps.props,
    ...FormGroupProps.props,

    large: PropTypes.bool,
    errorsRequireTouched: PropTypes.bool,
    hideError: PropTypes.bool,
    defaultValue: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    inputComponent: PropTypes.elementType,
    inputInline: PropTypes.bool,
    render: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
    getNextValue: PropTypes.func,
    afterOnChange: PropTypes.func,
    errorCypressAttribute: PropTypes.string,
    onBlur: PropTypes.func,
};

FormField.defaultProps = {
    ...FieldProps.defaults,
    ...FormGroupProps.defaults,

    large: true, // true for backwards compatibility
    errorsRequireTouched: true,
    hideError: false,
    defaultValue: null,
    inputComponent: null,
    inputInline: undefined,
    getNextValue: undefined,
    afterOnChange: undefined,
    render: undefined,
    errorCypressAttribute: null,
    onBlur: noop,
};

export default connect(FormField);
