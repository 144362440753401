import { saveResult } from '@thorgate/spa-entities';
import isBefore from 'date-fns/is_before';
import { all, call, select } from 'redux-saga/effects';

import { updateAllSensors } from 'apiHooks/sensors/updaters';
import { invalidateCache } from 'sagas/helpers/fetchingGuard';
import { sensorSchema, selectSensor } from 'schemas/sensor';
import { mutateSensor } from 'utils/sensor';

/**
 * Update all sensors that ignoring duplicate updates.
 *
 * @param sensorUpdates {Array}
 * @return {IterableIterator<*>}
 */
export function* sensorWorker(sensorUpdates) {
    const currentSensors = yield all(
        sensorUpdates.reduce(
            (selectors, { sensor: { device_name: deviceName } }) =>
                Object.assign(selectors, {
                    [deviceName]: select(selectSensor, deviceName),
                }),
            {},
        ),
    );

    // TODO: remove current sensors taken from redux
    const sensors = sensorUpdates.reduce(
        (data, { sensor }) => {
            const currentSensor = mutateSensor(sensor);
            const existingSensor = data[sensor.device_name];

            // Only override sensor data if there is no sensor or incoming data is newer
            const isOlder =
                existingSensor &&
                existingSensor.update_time &&
                isBefore(currentSensor.update_time, existingSensor.update_time);

            if (isOlder) {
                return data;
            }

            return Object.assign(data, {
                [currentSensor.device_name]: currentSensor,
            });
        },
        { ...currentSensors },
    );

    updateAllSensors(sensors);

    // Update sensor without touching order, will only use latest sensor data currently known
    yield all(
        Object.values(sensors)
            .map((sensor) =>
                call(saveResult, sensorSchema.key, mutateSensor(sensor), sensorSchema, {
                    mergeEntities: true,
                }),
            )
            .filter(Boolean),
    );

    // Invalidate sensor list view cache when new sensor info is loaded
    yield call(invalidateCache, sensorSchema.key);
}
