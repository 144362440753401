/* eslint-disable id-denylist */

import { makeStyles } from '@material-ui/core';

export const useFloorSwitcherStyles = makeStyles((theme) => ({
    container: ({ isMobile }) => ({
        maxWidth: isMobile ? '100%' : 500,
        width: '100%',

        order: 2,

        ...(isMobile && {
            border: '1px solid rgba(145, 141, 133, 0.4)',
            borderRadius: '4px',
        }),
    }),
    content: ({ isMobile }) => ({
        '&:before, &:after': {
            display: isMobile ? 'none' : 'block',
        },
    }),
    renderValueIcon: ({ isMobile }) => ({
        marginLeft: isMobile && 'auto',
    }),
    actionsSection: {
        paddingBottom: 0,
    },
    actionsSectionItem: {
        color: theme.palette.primary.main,
    },
    actionsSectionItemIcon: {
        fontSize: '18px',
    },
    itemText: {
        textTransform: 'capitalize',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',

        overflow: 'hidden',

        display: '-webkit-box',

        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
    },
    activeFloor: {
        pointerEvents: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
}));

export const useActionsMenuStyles = makeStyles({
    menuContainer: ({ isMobile }) => ({
        order: isMobile ? 1 : 3,

        marginBottom: isMobile && '18px', // equal to header title
        marginLeft: 'auto',
    }),
    menuButtonIcon: ({ isMobile }) => ({
        fontSize: isMobile && '24px',
    }),
    menuContent: ({ isMobile }) => ({
        ...(isMobile && {
            right: 0,
            left: 'unset',
        }),
    }),
});
