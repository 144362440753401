import { wrapResponsePromise } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { spaceControllers } from '@infogrid/locations-api';
import type { UseCreateBulkSpacesParams } from '@infogrid/locations-api';
import type { FloorDetail, Space } from '@infogrid/locations-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

import {
    cancelFloors,
    getFloorDetail,
    invalidateFloors,
    setFloorDetail,
} from '../../floors/accessors';
import {
    cancelSpaceCountByType,
    cancelSpaces,
    invalidateSpaceCountByType,
    invalidateSpaces,
} from '../accessors';

export const useCreateBulkSpaces = (
    buildingId: number,
): UseMutationResult<Space[], AxiosParsedError, UseCreateBulkSpacesParams> => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('estate');

    return useMutation(
        (data) => wrapResponsePromise(spaceControllers.createBulkSpaces(data)),
        {
            onSuccess: async (_d, { floor, spaces }) => {
                await cancelSpaceCountByType(floor);
                await invalidateSpaceCountByType(floor);
                await cancelSpaces({ floor });
                await invalidateSpaces({ floor });
                await cancelFloors(buildingId);
                await invalidateFloors(buildingId);

                const floorDetail = getFloorDetail(floor) as FloorDetail;

                const spaceCount = spaces.reduce(
                    (previousValue, currentValue) => previousValue + currentValue.count,
                    0,
                );

                setFloorDetail(floor, {
                    ...floorDetail,
                    spaces_count: floorDetail.spaces_count + spaceCount,
                });

                dispatch(
                    toastSuccess({
                        message: t('Successfully added {{count}} spaces', {
                            count: spaceCount,
                            defaultValue___one: `Successfully added ${spaceCount} space`,
                            defaultValue___other: `Successfully added ${spaceCount} spaces`,
                        }),
                    }),
                );
            },
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Failed to add spaces'),
                    }),
                );
            },
        },
    );
};
