import { makeStyles } from '@material-ui/core';

const DEFAULT_TRANSITION = 'none';

export const useNavbarLogoStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: '12px 0',

        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    textVisible: {
        '& $logoText': {
            opacity: 1,
        },
    },

    logoImage: {
        margin: '0 12px',
        maxHeight: theme.spacing(5),
        maxWidth: theme.spacing(5),
    },

    // Will be replaced by logo (svg)
    logoText: ({ textTransition }: { textTransition: string }) => ({
        color: theme.palette.primary.contrastText,
        opacity: 0,

        transition: textTransition || DEFAULT_TRANSITION,

        textTransform: 'uppercase',
        fontWeight: 500,
        letterSpacing: 2.15,
    }),
}));
