import { makeStyles } from '@material-ui/core';

export const useContentStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
}));

export const useSelectMenuStyles = makeStyles(() => ({
    paper: {
        maxHeight: 272,
    },
}));

export const useSelectStyles = makeStyles(() => ({
    root: {
        maxWidth: 250,
    },
    menuItem: {
        fontSize: 14,
        lineHeight: '21px',
    },
}));
