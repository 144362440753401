import PropTypes from 'prop-types';

/**
 * Generate type for each field defined
 * @param {string[]} fields - Array of field names
 * @param {Object} type - `PropType` for single field
 * @returns {Object} PropTypes for a form
 */
const getFieldMapping = (fields, type) =>
    fields.reduce((result, current) => {
        result[current] = type; // eslint-disable-line no-param-reassign

        return result;
    }, {});

export const StatusShape = PropTypes.shape({
    color: PropTypes.string,
    message: PropTypes.string,
    success: PropTypes.bool,
});

export const getFormPropTypes = (fields) => ({
    values: PropTypes.shape(
        getFieldMapping(
            fields,
            PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        ),
    ).isRequired,
    status: StatusShape,
    errors: PropTypes.shape(getFieldMapping(fields, PropTypes.string)).isRequired,
    touched: PropTypes.shape(getFieldMapping(fields, PropTypes.bool)).isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
});

export const FormikFieldShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
});

export const FormikShape = PropTypes.shape({
    errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    values: PropTypes.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
});

export const FieldProps = {
    props: {
        name: PropTypes.string.isRequired,
        formik: FormikShape.isRequired,

        id: PropTypes.string,
        label: PropTypes.node,
        placeholder: PropTypes.string,
    },
    defaults: {
        id: null,
        label: null,
        placeholder: null,
    },
};

export const FormGroupProps = {
    props: {
        formGroupClassName: PropTypes.string,
        contentClassName: PropTypes.string,
        label: PropTypes.node,
        inline: PropTypes.bool,
    },
    defaults: {
        formGroupClassName: undefined,
        contentClassName: undefined,
        label: undefined,
        inline: undefined,
    },
};
