import PropTypes from 'prop-types';

export const BuildingShape = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    location: PropTypes.shape({
        address: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }),
    is_default: PropTypes.bool,
});

export const BuildingListShape = PropTypes.arrayOf(BuildingShape);
