import type { Floor } from '@infogrid/locations-types';
import type { Location } from 'history';
import type { ReactElement } from 'react';
import { memo, useCallback } from 'react';
import { useHistory } from 'react-router';

import FloorRowCard from '../FloorRowCard';
import FloorRowContent from '../FloorRowContent';

interface FloorRowProps {
    floor: Floor;
    floorLink: Location;
    isActive: boolean;
    isWrapperLink?: boolean;
    showAlerts?: boolean;
    separateHubCount?: boolean;
    showHubCount?: boolean;
    onFloorClick?: () => void;
    onAlertsClick?: (id: number) => void;
    showCapacity?: boolean;
    showSensors?: boolean;
}

/**
 * Combines FloorRowCard and FloorRowContent with a `isWrapperLink` prop to
 * allow linking the entire wrapper to the `floorLink`
 */
export const FloorRow = ({
    floor,
    floorLink,
    isActive,
    isWrapperLink,
    showAlerts,
    separateHubCount,
    showHubCount,
    onFloorClick,
    onAlertsClick,
    showCapacity,
    showSensors,
}: FloorRowProps): ReactElement => {
    const history = useHistory();
    const handleClickFloorWrapper = useCallback(() => {
        if (isWrapperLink) {
            history.push(floorLink);
            onFloorClick?.();
        }
    }, [isWrapperLink, history, floorLink, onFloorClick]);

    const handleFloorClick = useCallback(
        (e) => {
            if (isWrapperLink) {
                // Prevent inner link clicks from triggering the wrapper click
                e.stopPropagation();
            }

            onFloorClick?.();
        },
        [isWrapperLink, onFloorClick],
    );

    const handleAlertsClick = useCallback(
        (id, e) => {
            if (isWrapperLink) {
                // Prevent inner link clicks from triggering the wrapper click
                e.stopPropagation();
            }

            onAlertsClick?.(id);
        },
        [isWrapperLink, onAlertsClick],
    );

    return (
        <FloorRowCard isActive={isActive} onClick={handleClickFloorWrapper} floor={floor}>
            <FloorRowContent
                floor={floor}
                onFloorClick={handleFloorClick}
                onAlertsClick={handleAlertsClick}
                floorLink={floorLink}
                showAlerts={showAlerts}
                separateHubCount={separateHubCount}
                showHubCount={showHubCount}
                showCapacity={showCapacity}
                showSensors={showSensors}
            />
        </FloorRowCard>
    );
};

export default memo(FloorRow);
