import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import { cleanInactiveAlertConfigurationsQuery } from 'apiHooks/alertConfigurations/accessors';

import { controllers } from './controller';
import type { CleaningRulesPayload } from './types';

export const useSetCleaningRules = (): UseMutationResult<
    CleaningRulesPayload,
    AxiosParsedError,
    CleaningRulesPayload
> => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('solutions');

    return useMutationWithToast<
        CleaningRulesPayload,
        AxiosParsedError,
        CleaningRulesPayload
    >(
        (data) => wrapResponsePromise(controllers.setCleaningRules(data)),
        {
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error applying cleaning rule to your selection'),
                    }),
                );
            },
            onSuccess: () => {
                cleanInactiveAlertConfigurationsQuery();
                dispatch(
                    toastSuccess({
                        message: t(
                            'Cleaning rule successfully applied to your selection',
                        ),
                    }),
                );
            },
        },
        {
            toastMessage: t('Updating cleaning rules'),
        },
    );
};
