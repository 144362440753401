import { queryClient } from '@infogrid/core-api';

import {
    getReportConfigurationsKey,
    getReportsKey,
    getSmartCleaningReportsKey,
} from './ts/getQueryKeys';

export const setReports = ({ data, options }) => {
    queryClient.setQueryData(getReportsKey(), data, options);
};

export const getReports = (filter) => queryClient.getQueryData(getReportsKey(), filter);

export const cancelReports = () => queryClient.cancelQueries(getReportsKey());

// The undefined in the second index of the reports key breaks this so have to target the 'reports-reports' part
export const invalidateReports = () =>
    queryClient.invalidateQueries([getReportsKey()[0]]);

export const cancelSmartCleaningReport = ({ widgetId }) =>
    queryClient.cancelQueries(getSmartCleaningReportsKey({ widgetId }));

export const invalidateSmartCleaningReport = ({ widgetId }) =>
    queryClient.invalidateQueries(getSmartCleaningReportsKey({ widgetId }));

export const cancelReportConfigurations = (params) =>
    queryClient.cancelQueries(getReportConfigurationsKey(params));

export const invalidateReportConfigurations = (params) =>
    queryClient.invalidateQueries(getReportConfigurationsKey(params));
