import { Modal, InfiniteScrollLoader } from '@infogrid/components-material-ui';
import type { FeedbackPanelTemplate } from '@infogrid/sensors-constants';
import { Button, Divider, Typography } from '@material-ui/core';
import { memo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useInstallationFlowStyles } from '../../styles';
import FeedbackPanelTemplateSelection from './FeedbackPanelTemplateSelection';
import { useFeedbackPanelTemplateSelectorStyles } from './styles';

interface FeedbackPanelTemplateSelectorProps {
    isLoading: boolean;
    feedbackPanelTemplates: FeedbackPanelTemplate[];
    hasMore: boolean;
    onFetchMore: () => void;
    onPrevious: () => void;
    onSelect: (feedbackPanelTemplate: FeedbackPanelTemplate) => void;
}

const FeedbackPanelTemplateSelector = ({
    isLoading,
    feedbackPanelTemplates,
    hasMore,
    onFetchMore,
    onPrevious,
    onSelect,
}: FeedbackPanelTemplateSelectorProps) => {
    const { t } = useTranslation('sensors');
    const styles = useFeedbackPanelTemplateSelectorStyles();
    const installationFlowStyles = useInstallationFlowStyles();

    return (
        <>
            <Modal.Title>{t('Choose a feedback panel template')}</Modal.Title>
            <Modal.Content className={styles.modalContent}>
                <InfiniteScrollLoader
                    hasContent={!!feedbackPanelTemplates?.length}
                    onFetchMore={onFetchMore}
                    hasLoaded={!isLoading}
                    isLoading={isLoading}
                    hasNext={hasMore}
                    emptyState={
                        <Typography
                            className={styles.noTemplateText}
                            data-cypress="feedback-panel-no-templates"
                        >
                            {t(
                                'There are no feedback panel templates available. Please contact your Infogrid support administrator.',
                            )}
                        </Typography>
                    }
                >
                    {feedbackPanelTemplates.map((feedbackPanelTemplate, index) => (
                        <Fragment key={feedbackPanelTemplate.id}>
                            {index !== 0 && <Divider className={styles.divider} />}
                            <FeedbackPanelTemplateSelection
                                feedbackPanelTemplate={feedbackPanelTemplate}
                                onSelect={onSelect}
                            />
                        </Fragment>
                    ))}
                </InfiniteScrollLoader>
            </Modal.Content>
            <Modal.Actions
                justify="space-between"
                className={installationFlowStyles.actions}
            >
                <Button onClick={onPrevious} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(FeedbackPanelTemplateSelector);
