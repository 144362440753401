import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { memo } from 'react';

import useSensorSignal from 'utils/hooks/useSensorSignal';

import type { SignalLevelProps } from './SignalLevel';
import SignalLevel from './SignalLevel';

export interface SensorSignalLevelProps extends SignalLevelProps {
    isLoading?: boolean;
    className?: string;
    sensor: GenericSensorShape;
}

const SensorSignalLevel = ({
    sensor,
    isLoading = false,
    ...props
}: SensorSignalLevelProps) => {
    const { isAvailable, isOnline, isBoostMode, hasWiredConnection, signalLevel } =
        useSensorSignal(sensor);

    if (!isAvailable) {
        return null;
    }

    return (
        <SignalLevel
            hasWiredConnection={hasWiredConnection}
            isBoostMode={isBoostMode}
            isOffline={!isOnline}
            isLoading={isLoading}
            signalLevel={signalLevel}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
};

export default memo(SensorSignalLevel);
