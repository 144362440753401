import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useWidgetTitleStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        display: 'flex',
        fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
        maxWidth: '100%',
    },
}));
