import { createRequest } from '@infogrid/core-api';
import type { IPagination } from '@infogrid/core-types';
import type { AxiosPromise } from 'axios';
import qs from 'qs';

import type {
    RemoteMonitoringParams,
    SensorChartData,
    MultipleSensorsChartData,
    RemoteMonitoringErrorBarEquation,
} from 'utils/types/ts/charts';

import { REMOTE_MONITORING_ERROR_BOUNDS_URL } from './constants';
import type { SensorDataQueryParams } from './getQueryKeys';

const getSensorChartData = (
    type: string,
    signal: AbortSignal | undefined,
    params: { [key: string]: unknown },
): AxiosPromise<IPagination<SensorChartData>> =>
    createRequest({
        method: 'get',
        url: `/widgets/${type}`,
        signal,
        options: {
            params,
        },
    });

const getSensorData = (
    id: string,
    signal: AbortSignal | undefined,
    params: SensorDataQueryParams,
): AxiosPromise<IPagination<MultipleSensorsChartData>> =>
    createRequest({
        method: 'get',
        url: `/sensors/${id}/aggregated-data`,
        signal,
        options: {
            params,
            paramsSerializer: (queryParams) =>
                qs.stringify(queryParams, { arrayFormat: 'repeat' }),
        },
    });

const getRemoteMonitoringErrorBoundsForChart = (
    signal: AbortSignal | undefined,
    params: RemoteMonitoringParams,
): AxiosPromise<RemoteMonitoringErrorBarEquation[]> =>
    createRequest({
        method: 'post', // NOTE: due to possible length of the parameters, will be sent as POST rather than GET
        url: REMOTE_MONITORING_ERROR_BOUNDS_URL,
        signal,
        options: {
            data: params,
        },
    });

export const controllers = {
    getSensorChartData,
    getSensorData,
    getRemoteMonitoringErrorBoundsForChart,
};
