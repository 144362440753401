import isNil from 'lodash/isNil';

export const getScoreDelta = (
    scoreToday: number,
    scoreYesterday: number | null,
): number => {
    if (isNil(scoreYesterday)) {
        return 0;
    }

    return scoreToday - scoreYesterday;
};
