import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';

export const useWebhookFormModalStyles = makeStyles((theme: Theme) => ({
    actionGroup: {
        flexDirection: 'row-reverse',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    buttonGroup: {
        display: 'flex',
        gap: theme.spacing(1),
    },
    content: {
        flexGrow: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        padding: theme.spacing(3),
    },
    description: {
        color: theme.palette.common.gray6,
        margin: theme.spacing(0.5, 0),
    },
    form: {
        // make only content of modal scrollable
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        flexGrow: 1,

        [theme.breakpoints.down('xs')]: {
            maxHeight: 'none',
        },
    },
    formSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2.5),
    },
    menuPaper: {
        maxHeight: '300px',
    },
    radioButtonControl: {
        alignItems: 'start',
    },
    radioButtonLabel: {
        position: 'relative',
        top: theme.spacing(1),
        left: theme.spacing(1),
    },
    title: {
        color: theme.palette.common.gray8,
        fontWeight: 500,
        letterSpacing: '0.15px',
    },
    titleContainer: {
        paddingTop: theme.spacing(3.5),
        paddingBottom: theme.spacing(3.5),
    },
    titleCloseButton: {
        top: theme.spacing(2),
    },
}));
