import { useAppSelector } from '@infogrid/core-ducks';
import { selectOrganization } from '@infogrid/user-ducks';
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';

import getLogo from 'components/Navbar/getLogo';
import Sidebar from 'components/Sidebar';

import NavbarContainer from '../NavbarContainer';
import { useNavbarStyles } from '../styles';
import NavbarHeaderMobile from './NavbarHeaderMobile';
import NavbarMobileContext from './context';
import { useMobileNavbarStyles } from './styles';

const TRANSITION_DURATION = 300;
const TRANSITION = `left ${TRANSITION_DURATION}ms`;
const OVERLAY_TRANSITION = `background-color ${TRANSITION_DURATION}ms`;

const NAVBAR_WIDTH__UNCOLLAPSED = '280px';

const NavbarMobile = () => {
    const [headerRendered, setHeaderRenderStatus] = useState(false);
    const [navbarVisible, setNavbarVisibility] = useState(false);

    const org = useAppSelector(selectOrganization) || {};
    const [logo, logoText] = getLogo(org);

    const navbarRootClasses = useNavbarStyles();
    const navbarMobileClasses = useMobileNavbarStyles({
        navbarVisible,
        transition: TRANSITION,
        overlayTransition: OVERLAY_TRANSITION,
    });
    const navbarClasses = classNames(navbarRootClasses.root, navbarMobileClasses.root);

    const toggleNavbarHandler = useCallback(
        (navbarOpened) => {
            setNavbarVisibility(navbarOpened);
        },
        [setNavbarVisibility],
    );

    const closeNavbarHandler = useCallback(() => {
        toggleNavbarHandler(false);
    }, [toggleNavbarHandler]);

    return (
        <NavbarMobileContext.Provider value={{ headerRendered, setHeaderRenderStatus }}>
            <div className={navbarMobileClasses.navbarContentWrapper}>
                <div
                    role="presentation"
                    className={navbarMobileClasses.backgroundOverlay}
                    onClick={closeNavbarHandler}
                />

                <NavbarHeaderMobile
                    classes={{
                        container: org.logo && navbarMobileClasses.logoContainer,
                        image: org.logo && navbarMobileClasses.logo,
                        text: org.name && navbarMobileClasses.logoText,
                    }}
                    logo={logo}
                    logoText={logoText}
                    navbarVisible={navbarVisible}
                    onButtonClick={toggleNavbarHandler}
                />

                <div className={navbarMobileClasses.sidebarWrapper}>
                    <Sidebar
                        defaultOpened
                        sidebarName="globalMobile"
                        className={navbarClasses}
                        collapsedWidth={0}
                        uncollapsedWidth={NAVBAR_WIDTH__UNCOLLAPSED}
                        transitionDuration={TRANSITION_DURATION}
                        disableCollapseButton
                    >
                        {({ isTransitioning }) => (
                            <NavbarContainer
                                forceCollapse
                                displayLogo={false}
                                navbarOpened={navbarVisible}
                                toggleCollapsedState={setNavbarVisibility}
                                isTransitioning={isTransitioning}
                                transitionDuration={TRANSITION_DURATION}
                            />
                        )}
                    </Sidebar>
                </div>
            </div>
        </NavbarMobileContext.Provider>
    );
};

export default memo(NavbarMobile);
