import { useUpdateWidget } from '@infogrid/dashboards-hooks';
import { useSpaceTypes } from '@infogrid/locations-api';
import { useIsOpenState } from '@infogrid/utils-hooks';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import GenericWidget from 'views/dashboards/components/Widget/GenericWidget';

import Content from './Content';
import { useFloorPlanWidgetStyles } from './styles';

const TITLE_ICON = <i className="far fa-layer-group" />;

const FloorPlanWidget = ({ widget }) => {
    const styles = useFloorPlanWidgetStyles();

    const { dashboardId } = useParams();

    const { mutate: updateWidget } = useUpdateWidget({
        displayPositiveNotifications: false,
    });

    const [
        deleteWidgetModalOpen,
        handleDeleteWidgetModalClose,
        handleDeleteWidgetModalOpen,
    ] = useIsOpenState();

    const onUpdateWidgetTitle = useCallback(
        (title) => {
            updateWidget({
                dashboardId: +dashboardId,
                widgetId: widget.id,
                data: { name: title },
            });
        },
        [dashboardId, updateWidget, widget.id],
    );

    const [isFullScreen, setIsFullScreen] = useLocalStorage(
        `widget-${widget.id}-fullscreen`,
        null,
    );

    const toggleFullScreen = useCallback(
        () => setIsFullScreen(!isFullScreen),
        [isFullScreen, setIsFullScreen],
    );

    const onFullscreenExit = useCallback(() => setIsFullScreen(false), [setIsFullScreen]);

    const { ref, inView } = useInView({
        rootMargin: '100px 0px',
        threshold: 0,
        triggerOnce: true,
    });

    const { isLoading: isSpaceTypesLoading } = useSpaceTypes({
        keepPreviousData: true,
    });

    return (
        <div ref={ref} className={styles.widgetContainer}>
            <GenericWidget
                isLoadingData={isSpaceTypesLoading}
                inView={inView}
                containerClassName={styles.genericWidgetContainer}
                contentComponent={Content}
                contentClassName={styles.contentContainer}
                contentProps={{ inView }}
                widget={widget}
                configuration={{
                    isEnabled: false,
                    footerEnabled: false,
                }}
                titleProps={{
                    onUpdateTitle: onUpdateWidgetTitle,
                    title: widget.name,
                    titleIcon: TITLE_ICON,
                }}
                actionsProps={{
                    configurableProps: {
                        onDelete: handleDeleteWidgetModalOpen,
                        isConfigureWidgetAvailable: false,
                    },
                    dashboardId,
                    fullscreenProps: {
                        fullscreen: isFullScreen,
                        onToggle: toggleFullScreen,
                        onClose: onFullscreenExit,
                    },
                    refreshableProps: {
                        enabled: false,
                    },
                    widgetConfiguration: widget.configuration,
                    widgetName: widget.name,
                    widgetType: widget.type,
                }}
                widgetDeletionModalProps={{
                    dashboardId,
                    onClose: handleDeleteWidgetModalClose,
                    open: deleteWidgetModalOpen,
                    widgetId: widget.id,
                    widgetName: widget.name,
                    widgetType: widget.type,
                }}
            />
        </div>
    );
};

FloorPlanWidget.propTypes = {
    widget: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
        type: PropTypes.string,
        configuration: PropTypes.shape({
            calculators: PropTypes.shape({
                floor_plan: PropTypes.shape({
                    building_id: PropTypes.number,
                    floor_id: PropTypes.number,
                }),
            }),
        }),
    }).isRequired,
};

export default memo(FloorPlanWidget);
