import { useAppSelector } from '@infogrid/core-ducks';
import { getLocation } from 'connected-react-router';
import type { Location } from 'history';
import type { RedirectProps, RouteComponentProps } from 'react-router';
import { Redirect, withRouter } from 'react-router-dom';

interface ConnectedRedirectProps extends RedirectProps, RouteComponentProps {
    routerLocation: Location;
}

export const ConnectedRedirect = withRouter(
    ({ location, ...rest }: ConnectedRedirectProps) => {
        const routerLocation = useAppSelector(getLocation);

        if (location.key !== routerLocation.key) {
            return null;
        }

        // We want to pass-through props
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Redirect {...rest} />;
    },
);

export default ConnectedRedirect;
