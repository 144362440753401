/**
 * Utility factory function that creates mutateResponse from mutateItem
 * @param mutateItem {function} - Function that mutates each item in response
 * @returns {function} - Created mutateResponse function from mutateItem
 */
export const mutateResponseFactory = (mutateItem) => (result, match) => ({
    ...result,
    results: Array.isArray(result.results)
        ? result.results.map((item) => mutateItem(item, match))
        : [],
});
