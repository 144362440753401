import { makeStyles } from '@material-ui/core';

export const useWebhookAuthMethodFieldStyles = makeStyles((theme) => ({
    radioLabelFocused: {
        // Need extra specificity due to more specific MUI rules.
        '&.Mui-focused': {
            color: theme.palette.text.secondary,
        },
    },
    authMethodLabel: {
        marginBottom: theme.spacing(0.5),
    },
    authMethodHelpText: {
        marginTop: 0,
    },
}));
