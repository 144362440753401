import { calendarPeriods, dataFrequencies } from '@infogrid/dashboards-constants';
import moment from 'moment';

import {
    prepareBaseConfigurationPayload,
    prepareBaseInitialValues,
} from 'views/dashboards/widgets/utils';

import type {
    ConfigurationValues,
    IndoorAirQualityWidget,
    IndoorAirQualityWidgetConfiguration,
} from './types';

const defaultValues = {
    dateRange: {
        period: calendarPeriods.week,
        startDate: moment().subtract(7, 'days'),
        endDate: moment().subtract(1, 'day'),
        workHoursFrom: '09:00',
        workHoursTo: '17:00',
    },
};

export const prepareInitialValues = (
    widget: IndoorAirQualityWidget,
): ConfigurationValues => {
    const { data_frequency: dataFrequency = dataFrequencies.daily.value } =
        widget?.configuration;

    const { folders = [], sensors = [] } =
        widget?.configuration?.calculators?.airthings_quality || {};

    return {
        ...prepareBaseInitialValues(widget, defaultValues),
        folders,
        sensors,
        displayOptions: {
            dataFrequency,
        },
    };
};

export const prepareConfigurationPayload = (
    values: ConfigurationValues,
): IndoorAirQualityWidgetConfiguration => {
    const payload = prepareBaseConfigurationPayload(values);

    const { dataFrequency } = values?.displayOptions;

    return {
        ...payload,
        data_frequency: dataFrequency,
        calculators: {
            airthings_quality: {
                folders: values?.folders,
                sensors: values?.sensors,
            },
        },
    };
};
