import { dataFrequencies } from '@infogrid/dashboards-constants';
import {
    useUpdateWidget,
    useWidgetData as useGenericWidgetData,
} from '@infogrid/dashboards-hooks';
import { defaultRollingCalendarPeriods } from '@infogrid/utils-dates';
import { useIsOpenState } from '@infogrid/utils-hooks';
import isNil from 'lodash/isNil';
import { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import { shortDate } from 'views/dashboards/utils/helpers';
import { useConfigureWidgetModalOpenState } from 'views/dashboards/utils/hooks';

import GenericWidget from './GenericWidget';
import { genericWidgetProps, genericWidgetDefaultProps } from './constants';
import { useGenericWidgetContainerStyles } from './styles';

const GenericWidgetContainer = (props) => {
    const { dashboardId } = useParams();

    const { t, i18n } = useTranslation('dashboard');

    const styles = useGenericWidgetContainerStyles();

    const {
        actionsProps = {},
        footerProps = {},
        titleProps = {},
        widget,
        widgetConfigurationModalProps = {},
        widgetDeletionModalProps = {},
        useIntersectionObserverLoader,
        baseQueryParams,
        useWidgetData = useGenericWidgetData,
    } = props;

    const { prepareConfigurationPayload, prepareInitialValues } =
        widgetConfigurationModalProps;

    const {
        handleConfigureWidgetModalClose,
        handleConfigureWidgetModalOpen,
        isConfigureWidgetModalOpen,
    } = useConfigureWidgetModalOpenState(widget.id, widget.type);

    const isHardRefreshData = useRef(false);

    const [widgetDataQueryParams, setWidgetDataQueryParams] = useState(null);

    const [
        deleteWidgetModalOpen,
        handleDeleteWidgetModalClose,
        handleDeleteWidgetModalOpen,
    ] = useIsOpenState();

    const { mutate: updateWidget, isLoading: isUpdatingWidget } = useUpdateWidget();

    const isInitiallyConfigured = useMemo(
        /**
         * INFO: this check is enough for now, when user selects
         * sensor and clicks save - all the date range / display options
         * defaults are generated & saved as well
         */
        () => Boolean(widget.configuration.time_period_range),
        [widget],
    );

    const isDuplicateDisabled = useMemo(
        () =>
            !isInitiallyConfigured &&
            !actionsProps?.configurableProps?.forceDuplicateEnabled,
        [actionsProps, isInitiallyConfigured],
    );

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: '100px 0px',
        skip: !useIntersectionObserverLoader,
        initialInView: !useIntersectionObserverLoader,
        triggerOnce: true,
    });

    const {
        data: widgetData,
        isFetching: fetchingWidgetData,
        isLoading: loadingWidgetData,
        isFetched: isWidgetDataFetched,
        isSuccess: loadingWidgetDataSuccess,
        refetch: refetchWidgetData,
        isError: isLoadingWidgetDataError,
        error: loadingWidgetDataError,
    } = useWidgetData(+dashboardId, widget.id, {
        getQueryParams: () => ({
            ...baseQueryParams,
            refresh: isHardRefreshData.current || undefined,
            ...widgetDataQueryParams,
        }),
        options: {
            enabled: inView && !isDuplicateDisabled,
            onSettled: () => {
                isHardRefreshData.current = false;
            },
        },
    });

    useEffect(() => {
        if (widgetDataQueryParams) {
            refetchWidgetData();
        }
    }, [refetchWidgetData, widgetDataQueryParams]);

    const onUpdateWidgetTitle = useCallback(
        (title, callbacks = {}) => {
            updateWidget(
                {
                    dashboardId: +dashboardId,
                    widgetId: widget.id,
                    data: { name: title },
                },
                callbacks,
            );
        },
        [dashboardId, updateWidget, widget.id],
    );

    const onUpdateConfiguration = useCallback(
        (values, sensorsData, callbacks = {}) => {
            const payload = prepareConfigurationPayload
                ? prepareConfigurationPayload(values, sensorsData)
                : values;

            updateWidget(
                {
                    dashboardId: +dashboardId,
                    widgetId: widget.id,
                    data: {
                        ...widget,
                        configuration: payload,
                    },
                },
                callbacks,
            );
        },
        [prepareConfigurationPayload, updateWidget, dashboardId, widget],
    );

    const widgetConfigurationInitialValues = useMemo(() => {
        const { initialValues } = widgetConfigurationModalProps;

        if (initialValues) {
            return initialValues;
        }

        if (prepareInitialValues) {
            return prepareInitialValues(widget);
        }

        return {};
    }, [prepareInitialValues, widget, widgetConfigurationModalProps]);

    const footerLabels = useMemo(() => {
        const { startDate, endDate, period } =
            widgetConfigurationInitialValues?.dateRange || {};
        const { dataFrequency } = widgetConfigurationInitialValues?.displayOptions || {};

        const sensorsCount = widget?.sensor_count || 0;

        const dataFrequencyKey = Object.keys(dataFrequencies).find(
            (freq) => dataFrequencies[freq].value === dataFrequency,
        );

        const refreshRateLabel = dataFrequencies[dataFrequencyKey]?.label;

        const rollingPeriodLabel = defaultRollingCalendarPeriods.find(
            (periodItem) => periodItem.value === period,
        )?.label;

        const dateRangeLabel = `${shortDate(
            startDate,
            'UTC',
            i18n.language,
        )} - ${shortDate(endDate, 'UTC', i18n.language)}`;

        return {
            periodLabel: rollingPeriodLabel ? t(rollingPeriodLabel) : dateRangeLabel,
            refreshRateLabel: refreshRateLabel ? t(refreshRateLabel) : '',
            sensorsLabel: isNil(widget?.sensor_count)
                ? undefined
                : t('{{count}} Sensors', {
                      count: sensorsCount,
                      defaultValue___one: `${sensorsCount} Sensor`,
                      defaultValue___other: `${sensorsCount} Sensors`,
                  }),
        };
    }, [widget, widgetConfigurationInitialValues, t, i18n]);

    const adjustedProps = {
        ...props,
        actionsProps: {
            ...actionsProps,
            configurableProps: {
                ...actionsProps.configurableProps,
                onConfigure: handleConfigureWidgetModalOpen,
                onDelete: handleDeleteWidgetModalOpen,
                isDuplicateDisabled,
            },
            dashboardId,
            fullscreenProps: {
                ...props.actionsProps.fullscreenProps,
            },
            refreshableProps: {
                ...actionsProps.refreshableProps,
                enabled: actionsProps.refreshableProps.enabled && !isDuplicateDisabled,
                isLoading: fetchingWidgetData && loadingWidgetDataSuccess,
                onRefresh: () => {
                    isHardRefreshData.current = true;
                    refetchWidgetData();
                },
            },
            widgetConfiguration: widget.configuration,
            widgetName: widget.name,
            widgetType: widget.type,
        },
        footerProps: {
            onUpdateConfiguration,
            widget,
            isWidgetDataLoading: loadingWidgetData,
            widgetData,
            ...footerLabels,
            ...footerProps,
        },
        isFetchingData: fetchingWidgetData,
        isLoadingData: loadingWidgetData,
        isWidgetDataFetched,
        titleProps: {
            onUpdateTitle: onUpdateWidgetTitle,
            title: widget.name,
            ...titleProps,
        },
        refetchData: refetchWidgetData,
        widgetData,
        widgetConfigurationModalProps: {
            ...widgetConfigurationModalProps,
            loading: isUpdatingWidget,
            initialValues: widgetConfigurationInitialValues,
            onUpdateConfiguration,
            onClose: handleConfigureWidgetModalClose,
            open: isConfigureWidgetModalOpen,
        },
        widgetDeletionModalProps: {
            ...widgetDeletionModalProps,
            dashboardId,
            onClose: handleDeleteWidgetModalClose,
            open: deleteWidgetModalOpen,
            widgetId: widget.id,
            widgetName: widget.name,
            widgetType: widget.type,
        },
    };

    return (
        <div ref={ref} className={styles.container} data-cypress="widget">
            <GenericWidget
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...adjustedProps}
                inView={inView}
                contentProps={{
                    inView,
                    isConfigured: !isDuplicateDisabled,
                    isLoadingDataError: isLoadingWidgetDataError,
                    loadingDataError: loadingWidgetDataError,
                }}
                setWidgetDataQueryParams={setWidgetDataQueryParams}
            />
        </div>
    );
};

GenericWidgetContainer.propTypes = genericWidgetProps;
GenericWidgetContainer.defaultProps = genericWidgetDefaultProps;

export default memo(GenericWidgetContainer);
