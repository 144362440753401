import type { SensorEventType } from '@infogrid/sensors-constants';
import { getEventConfig } from '@infogrid/sensors-events';
import { useIsMobile } from '@infogrid/utils-hooks';
import { TextField } from '@material-ui/core';
import type { FormikProps } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ChecklistWithAllSelect from 'components/ChecklistWithAllSelect';

import { useEventTypeSelectorStyles } from './styles';

type EventTypeSelectorProps = {
    allEventTypes: SensorEventType[];
    eventTypesSelected: SensorEventType[];
    error?: string | string[];
} & Pick<FormikProps<SensorEventType[]>, 'setFieldValue'>;

const keyExtractor = (x: SensorEventType): string => x;
const valueExtractor = (x: SensorEventType): string => {
    const config = getEventConfig(x);
    return config.label;
};

const EventTypeSelector = ({
    allEventTypes,
    error,
    eventTypesSelected,
    setFieldValue,
}: EventTypeSelectorProps) => {
    const { t } = useTranslation('integrations');
    const styles = useEventTypeSelectorStyles();
    const isMobile = useIsMobile();

    const handleChange = useCallback(
        (items: SensorEventType[]) => {
            setFieldValue('eventTypes', items);
        },
        [setFieldValue],
    );

    const toggleAllCheck = useCallback(
        (checkAll) => {
            if (!checkAll) {
                handleChange([]);
            } else {
                handleChange(allEventTypes.map((eventType) => eventType));
            }
        },
        [allEventTypes, handleChange],
    );

    const toggleOptionCheck = useCallback(
        (newEventType) => {
            if (eventTypesSelected.includes(newEventType)) {
                handleChange(
                    eventTypesSelected.filter(
                        (currentEventType) => currentEventType !== newEventType,
                    ),
                );
            } else {
                handleChange([...eventTypesSelected, newEventType]);
            }
        },
        [eventTypesSelected, handleChange],
    );

    const fieldValue = useMemo(() => {
        if (eventTypesSelected.length === 0) {
            return t('Select at least one event type');
        }

        if (eventTypesSelected.length === allEventTypes.length) {
            return t('All event types selected');
        }

        return t('{{count}} event types selected', { count: eventTypesSelected.length });
    }, [allEventTypes, eventTypesSelected, t]);

    const formatErrors = useMemo(() => {
        if (!error) {
            return '';
        }

        if (Array.isArray(error)) {
            return error.join('\n');
        }

        return error;
    }, [error]);

    // we need to sort the labelled events.
    // e.g. kilowatt_hours has a label of 'energy consumption' so sort by those instead
    const sortedEventTypes = useMemo(() => {
        const eventTypesWithValues = allEventTypes.map((eventType) => ({
            key: keyExtractor(eventType),
            value: valueExtractor(eventType),
        }));
        eventTypesWithValues.sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            } else if (a.value === b.value) {
                return 0;
            }

            return 1;
        });
        return eventTypesWithValues.map(
            (eventType) => eventType.key,
        ) as SensorEventType[];
    }, [allEventTypes]);

    return (
        <div data-testid="eventTypeSelector-container">
            <TextField
                id="select-event-type"
                name="eventTypes"
                className={styles.container}
                placeholder="test"
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                    multiple: true,
                    renderValue: () => `${fieldValue}`,
                    MenuProps: {
                        anchorOrigin: {
                            horizontal: 'left',
                            vertical: 'bottom',
                        },
                        getContentAnchorEl: null,
                    },
                }}
                select
                error={Boolean(error)}
                label={t('Event type')}
                variant="outlined"
                value={[fieldValue]}
                helperText={Boolean(error) && t(formatErrors)}
            >
                <div>
                    <ChecklistWithAllSelect
                        allItemsCheckedText={t('All event types')}
                        checkAllHandler={toggleAllCheck}
                        checkHandler={toggleOptionCheck}
                        checkedItemsKeys={eventTypesSelected}
                        disabled={isMobile || allEventTypes.length === 0}
                        selectionDisabled={false}
                        items={sortedEventTypes}
                        keyExtractor={keyExtractor}
                        valueExtractor={valueExtractor}
                        maxHeight={300}
                        onlyRenderChecklistMenu
                        renderInPortal
                    />
                </div>
            </TextField>
        </div>
    );
};

export default EventTypeSelector;
