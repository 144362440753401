import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useSuccessModalStyles = makeStyles((theme) => ({
    successModal: {
        width: 600,

        [theme.breakpoints.down('xs')]: {
            width: '100vw',
        },
    },
    successModalTitle: {
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(1.25),
    },
    successModalContent: {
        padding: 0,

        [theme.breakpoints.down('xs')]: {
            height: '100%',
        },
    },
    successModalMainText: {
        display: 'flex',
        alignItems: 'center',

        '& p': {
            paddingLeft: theme.spacing(1),
        },
    },
    icon: {
        color: theme.palette.success.main,
    },
    successModalInfo: {
        paddingLeft: theme.spacing(4),

        '& a': {
            color: '#0084BC',
            textDecoration: 'underline',
        },
    },
    successModalTextContent: {
        padding: theme.spacing(3.5, 2.5, 5, 2.5),
        [theme.breakpoints.down('xs')]: {
            height: '80%',
        },
    },
    successModalFooter: {
        padding: theme.spacing(2, 2.5),
        display: 'flex',
        justifyContent: 'flex-end',
        borderTop: `1px solid ${theme.palette.common.gray4}`,
    },
    boldText: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
}));
