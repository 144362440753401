import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import {
    toastSuccess,
    toastError,
    useAppSelector,
    useAppDispatch,
} from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { selectOrganizationId } from '@infogrid/user-ducks';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import { controllers } from 'apiHooks/settings/integrations/corrigo/controllers';
import type { CorrigoCredentials } from 'views/integrations/components/corrigo/types';

const TOAST_MESSAGES = {
    DEFAULT_ERROR_MESSAGE: registerTranslationKey('Failed to save Corrigo credentials', {
        ns: 'integrations',
    }),
    VALIDATION_ERROR: registerTranslationKey('Failed to validate Corrigo credentials', {
        ns: 'integrations',
    }),
    SUCCESS_MESSAGE: registerTranslationKey('Corrigo credentials successfully saved', {
        ns: 'integrations',
    }),
    IN_PROGRESS_MESSAGE: registerTranslationKey('Verifying Corrigo credentials', {
        ns: 'integrations',
    }),
};

export const useCorrigoCredentialsUpload = ({
    onSuccess = noop,
    onSettled = noop,
    onError = noop,
}): UseMutationResult<CorrigoCredentials, AxiosParsedError, CorrigoCredentials> => {
    const organizationId = useAppSelector(selectOrganizationId);
    const dispatch = useAppDispatch();

    const { t } = useTranslation('integrations');

    return useMutationWithToast<CorrigoCredentials, AxiosParsedError, CorrigoCredentials>(
        (credentials: CorrigoCredentials) =>
            wrapResponsePromise(
                controllers.uploadCorrigoCredentials(organizationId, credentials),
            ),
        {
            onSuccess: () => {
                dispatch(
                    toastSuccess({
                        message: t(TOAST_MESSAGES.SUCCESS_MESSAGE),
                    }),
                );
                onSuccess();
                onSettled();
            },
            onError: ({ response }) => {
                if (response?.status === 400) {
                    dispatch(
                        toastError({
                            message: t(TOAST_MESSAGES.VALIDATION_ERROR),
                        }),
                    );
                } else {
                    dispatch(
                        toastError({
                            message: t(TOAST_MESSAGES.DEFAULT_ERROR_MESSAGE),
                        }),
                    );
                }

                onError();
                onSettled();
            },
        },
        {
            toastMessage: t(TOAST_MESSAGES.IN_PROGRESS_MESSAGE),
        },
    );
};
