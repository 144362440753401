import PropTypes from 'prop-types';
import { memo } from 'react';

import { GenericWidgetContainer } from 'views/dashboards/components/Widget/GenericWidget';
import ConfigureWidgetModal from 'views/dashboards/components/modals/ConfigureWidgetModal';

import Content from './Content';
import { widgetConfigurationModalTabs } from './constants';
import { prepareConfigurationPayload, prepareInitialValues } from './utils';

const titleProps = {
    titleIcon: <i className="far fa-sink" />,
};

const widgetConfigurationModalProps = {
    prepareConfigurationPayload,
    prepareInitialValues,
    tabs: widgetConfigurationModalTabs,
};

const PipeMonitoringWidget = ({ widget, ...props }) => {
    return (
        <GenericWidgetContainer
            contentComponent={Content}
            configureWidgetModal={ConfigureWidgetModal}
            titleProps={titleProps}
            widget={widget}
            widgetConfigurationModalProps={widgetConfigurationModalProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
};

PipeMonitoringWidget.propTypes = {
    widget: PropTypes.shape({}).isRequired,
};

export default memo(PipeMonitoringWidget);
