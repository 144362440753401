import type {
    SensorEventShape,
    SensorEventsV2,
    SensorEventV2Shape,
} from '@infogrid/sensors-constants';
import useDidUpdate from '@rooks/use-did-update';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { useCallback, useState, memo } from 'react';
import { Transition } from 'react-transition-group';

import { useSensorLastReadingTransitionStyles } from './styles';
import { getLatestEventTimestamp } from './utils';

const TRANSITION_DURATION = 900;

interface SensorLastReadingTransitionProps {
    event?: SensorEventShape | SensorEventV2Shape | SensorEventsV2 | undefined;
    className?: string;
    centerAlignItems?: boolean;
    children?: ReactNode;
}

const SensorLastReadingTransition = ({
    event,
    className = '',
    centerAlignItems = false,
    children,
}: SensorLastReadingTransitionProps) => {
    const styles = useSensorLastReadingTransitionStyles();
    const [isTransitioning, setTransitioning] = useState(false);
    const setTransitionFinished = useCallback(
        () => setTransitioning(false),
        [setTransitioning],
    );

    const latestEventTimestamp = getLatestEventTimestamp(event);

    useDidUpdate(() => {
        setTransitioning(true);
    }, [event && latestEventTimestamp]);

    return (
        <Transition
            in={isTransitioning}
            timeout={TRANSITION_DURATION}
            onEntered={setTransitionFinished}
        >
            {(state) => (
                <div
                    className={classNames(`fade-color ${state}`, className, {
                        [styles.wrapper]: centerAlignItems,
                    })}
                    data-cypress="last-reading"
                >
                    {children}
                </div>
            )}
        </Transition>
    );
};

export default memo(SensorLastReadingTransition);
