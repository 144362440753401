import type { HeaderCellProps, IColumn, IRow } from '@infogrid/components-material-ui';
import { BaseTable } from '@infogrid/components-material-ui';
import { TableCell, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import type { MeetingRoomOccupancyData } from '../../types';
import { useTableStyles } from './styles';

interface TableProps {
    data: MeetingRoomOccupancyData;
}

export const Table = ({ data }: TableProps): JSX.Element => {
    const styles = useTableStyles();

    const { t } = useTranslation('dashboard');

    const { results, total_capacity, total_headcount } = data;

    const spaces = [
        ...results,
        {
            capacity: total_capacity,
            display_name: 'Total',
            headcount: total_headcount,
        },
    ];

    const isTotalRow = (row: IRow): boolean => row.display_name === 'Total';

    const HeaderCell = ({ column }: HeaderCellProps) => (
        <TableCell className={styles.headerCell} style={column.style}>
            {column.name}
        </TableCell>
    );

    const columns: IColumn[] = [
        {
            key: 'display_name',
            name: t('Meeting Room Name'),
            style: { width: '50%' },
            DataCell: ({ row, column }) => (
                <TableCell
                    className={styles.displayName}
                    key={column.key}
                    style={column.style}
                >
                    <Tooltip title={String(row[column.key])}>
                        <span
                            className={classNames({
                                [styles.totalRow]: isTotalRow(row),
                            })}
                        >
                            {String(row[column.key])}
                        </span>
                    </Tooltip>
                </TableCell>
            ),
            HeaderCell,
        },
        {
            key: 'headcount',
            name: t('Latest Headcount'),
            style: { width: '25%' },
            DataCell: ({ row, column }) => (
                <TableCell key={column.key} style={column.style}>
                    <span
                        className={classNames({
                            [styles.totalRow]: isTotalRow(row),
                        })}
                    >
                        {String(row[column.key])}
                    </span>
                </TableCell>
            ),
            HeaderCell,
        },
        {
            key: 'capacity',
            name: t('Room Capacity'),
            style: { width: '25%' },
            DataCell: ({ row, column }) => {
                // capacity should never be 0. If it is, we prompt users to reach out to customer success
                if (row[column.key] === 0) {
                    const tooltipText = t(
                        "The capacity of this meeting room hasn't been set yet. Get in touch with a member of the Infogrid team to resolve this.",
                    );

                    return (
                        <TableCell key={column.key} style={column.style}>
                            <Tooltip title={tooltipText}>
                                <span
                                    className={classNames({
                                        [styles.totalRow]: isTotalRow(row),
                                    })}
                                >
                                    {t('Unknown')}
                                </span>
                            </Tooltip>
                        </TableCell>
                    );
                }

                return (
                    <TableCell key={column.key} style={column.style}>
                        <span
                            className={classNames({
                                [styles.totalRow]: isTotalRow(row),
                            })}
                        >
                            {String(row[column.key])}
                        </span>
                    </TableCell>
                );
            },
            HeaderCell,
        },
    ];

    return (
        <BaseTable
            classes={{ table: styles.table }}
            columns={columns}
            rows={spaces as unknown as IRow[]}
        />
    );
};
