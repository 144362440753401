import { defaultFrequencies } from '@infogrid/dashboards-constants';
import type { WidgetConfigurationModalTab } from '@infogrid/dashboards-constants';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import DateRange from 'views/dashboards/components/modals/ConfigureWidgetModal/DateRange';
import Sensors from 'views/dashboards/components/modals/ConfigureWidgetModal/Sensors';

import DisplayOptions from './DisplayOptions';
import type { SensorsTab, DisplayOptionsTab } from './types';

const sensorsTab: SensorsTab = {
    id: 'sensors',
    name: registerTranslationKey('Sensors', { ns: 'common' }),
    component: Sensors,
    attributes: {
        sensorTypeFilter: SENSOR_TYPE.TYPE_AIR_QUALITY,
        maxSelectedSensors: 50,
    },
};
const dateRangeTab: WidgetConfigurationModalTab = {
    id: 'date-range',
    name: registerTranslationKey('Date Range', { ns: 'common' }),
    component: DateRange,
};
const displayOptionsTab: DisplayOptionsTab = {
    id: 'display-options',
    name: registerTranslationKey('Display Options', { ns: 'common' }),
    component: DisplayOptions,
    attributes: {
        frequencies: defaultFrequencies,
    },
};

export const widgetConfigurationModalTabs = [sensorsTab, dateRangeTab, displayOptionsTab];
