import { sensorsColors as sensorsColorsObject } from '@infogrid/dashboards-constants';
import {
    FormControl,
    Grid,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSensorsColorsStyles } from './styles';

const defaultSensorsColors = [
    sensorsColorsObject.green,
    sensorsColorsObject.yellow,
    sensorsColorsObject.red,
    sensorsColorsObject.blue,
    sensorsColorsObject.grey,
];

const SensorsColors = ({
    colorSelectableTypes,
    handleChange,
    sensors,
    sensorsData,
    sensorsAttributes,
    sensorsColors,
}) => {
    const { t } = useTranslation('common');
    const styles = useSensorsColorsStyles();

    const sensorsWithColors = sensors.filter((sensorId) =>
        colorSelectableTypes.includes(sensorsData[sensorId]?.type_code),
    );

    return (
        <Grid alignItems="center" container spacing={2}>
            <Grid item xs={7}>
                <Typography className={styles.columnLabel} variant="subtitle2">
                    {t('Sensor')}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography className={styles.columnLabel} variant="subtitle2">
                    {t('Color')}
                </Typography>
            </Grid>
            {sensorsWithColors?.length === 0 && (
                <>
                    <Grid item xs={7}>
                        -
                    </Grid>
                    <Grid item xs={3}>
                        -
                    </Grid>
                </>
            )}
            {sensorsWithColors.map((sensorId) => {
                const sensorName = sensorsData[sensorId]?.profile?.name || sensorId;

                return (
                    <Fragment key={sensorId}>
                        <Grid item xs={7}>
                            <Tooltip title={sensorName} placement="top">
                                <Typography
                                    className={styles.sensorLabel}
                                    variant="subtitle1"
                                >
                                    {sensorName}
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl size="small" variant="outlined">
                                <Select
                                    className={styles.colorSelect}
                                    id="sensor-color"
                                    data-cypress="chart-sensor-color"
                                    name={`displayOptions.sensorsAttributes.${sensorId}.color`}
                                    onChange={handleChange}
                                    variant="outlined"
                                    value={
                                        sensorsAttributes[sensorId]?.color ||
                                        sensorsColorsObject.green.value
                                    }
                                >
                                    {sensorsColors.map((sensorColor) => (
                                        <MenuItem
                                            data-cypress="sensor-color-menu-option"
                                            key={sensorColor.value}
                                            value={sensorColor.value}
                                        >
                                            <i
                                                className={classNames(
                                                    'fas fa-circle',
                                                    styles.colorCircle,
                                                )}
                                                style={{ color: sensorColor.color }}
                                            />
                                            {t(sensorColor.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Fragment>
                );
            })}
        </Grid>
    );
};

SensorsColors.propTypes = {
    colorSelectableTypes: PropTypes.arrayOf(PropTypes.string),
    handleChange: PropTypes.func.isRequired,
    sensors: PropTypes.arrayOf(PropTypes.string),
    sensorsData: PropTypes.shape({}),
    sensorsAttributes: PropTypes.shape({}),
    sensorsColors: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }),
    ),
};

SensorsColors.defaultProps = {
    colorSelectableTypes: [],
    sensors: [],
    sensorsData: {},
    sensorsAttributes: {},
    sensorsColors: defaultSensorsColors,
};

export default memo(SensorsColors);
