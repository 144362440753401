import { makeStyles } from '@material-ui/core';

export const useTempUseCaseOptionStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        margin: 0,
    },
    selected: {
        background: theme.palette.background.primary,
    },
}));

export const useTempUseCaseLabelStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    icon: {
        marginRight: '1rem',
    },
}));
