import { BaseTable } from '@infogrid/components-material-ui';
import type { IColumn } from '@infogrid/components-material-ui';
import { TableCell, Typography } from '@material-ui/core';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PipeMonitoringRiskWidgetData } from '../types';
import { useLocationsTableStyles } from './styles';

type LocationsTablePropTypes = {
    widgetData: PipeMonitoringRiskWidgetData | undefined;
    onShowAtRiskDetailsDialog: (buildingKey: string) => void;
};

type BuildingRow = {
    name?: string;
    pipes_at_risk?: number;
    buildingKey: string;
};

const LocationsTable = ({
    widgetData,
    onShowAtRiskDetailsDialog,
}: LocationsTablePropTypes) => {
    const styles = useLocationsTableStyles();
    const { t } = useTranslation('dashboard');

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'name',
                name: t('Buildings where action is needed'),
                sortable: true,
                DataCell: ({ row, column }) => (
                    <TableCell
                        className={styles.locationName}
                        key={column.key}
                        style={column.style}
                    >
                        <span>{String(row[column.key])}</span>
                    </TableCell>
                ),
                HeaderCell: ({ column }) => <TableCell key={column.key} />,
            },
            {
                key: 'pipes_at_risk',
                name: t('At Risk'),
                sortable: true,
                DataCell: ({ row, column }) => {
                    const content = t(`{{count}} {{pipeDisplay}} at risk`, {
                        count: row.pipes_at_risk as number,
                        pipeDisplay: row.pipes_at_risk === 1 ? 'pipe' : 'pipes',
                        defaultValue___one: '{{count}} pipe at risk',
                        defaultValue___other: '{{count}} pipes at risk',
                    });

                    const onClick = useCallback(
                        () => onShowAtRiskDetailsDialog(row.buildingKey as string),
                        [row],
                    );

                    return (
                        <TableCell
                            className={styles.locationCount}
                            key={column.key}
                            style={column.style}
                        >
                            {Boolean(row.pipes_at_risk) && row.buildingKey !== 'null' && (
                                <Typography
                                    variant="subtitle1"
                                    onClick={onClick}
                                    className={styles.atRiskLink}
                                    data-cypress="pipes-at-risk-link"
                                    data-testid="pipes-at-risk-link"
                                >
                                    {content}
                                </Typography>
                            )}
                            {(!Boolean(row.pipes_at_risk) ||
                                row.buildingKey === 'null') && (
                                <Typography variant="subtitle1">{content}</Typography>
                            )}
                        </TableCell>
                    );
                },
                HeaderCell: ({ column }) => <TableCell key={column.key} />,
            },
        ],
        [onShowAtRiskDetailsDialog, styles, t],
    );

    const allBuildings = useMemo(
        () =>
            Object.keys(widgetData?.buildings || {})
                .map((buildingKey): BuildingRow => {
                    return {
                        buildingKey,
                        ...widgetData?.buildings[buildingKey],
                    };
                })
                .sort((a, b) => {
                    const a_pipes_at_risk = a.pipes_at_risk ?? 0;
                    const b_pipes_at_risk = b.pipes_at_risk ?? 0;

                    if (a_pipes_at_risk === b_pipes_at_risk) {
                        return 0;
                    }

                    if (a_pipes_at_risk < b_pipes_at_risk) {
                        return 1;
                    }

                    return -1;
                }),
        [widgetData],
    );

    const unassignedBuilding = useMemo(
        () => allBuildings.find((b) => b.buildingKey === 'null'),
        [allBuildings],
    );

    const [atRiskBuildings, _notAtRiskBuildings] = useMemo(() => {
        const [buildingsAtRisk, buildingsNotAtRisk] = allBuildings
            .filter((b) => b.buildingKey !== 'null')
            .reduce(
                ([atRisk, notAtRisk], curr) => {
                    if (curr.pipes_at_risk) {
                        atRisk.push(curr);
                    } else {
                        notAtRisk.push(curr);
                    }

                    return [atRisk, notAtRisk];
                },
                [[], []] as BuildingRow[][],
            );

        if (unassignedBuilding?.pipes_at_risk) {
            buildingsAtRisk.push(unassignedBuilding);
        } else {
            if (unassignedBuilding) {
                buildingsNotAtRisk.push(unassignedBuilding);
            }
        }

        return [buildingsAtRisk, buildingsNotAtRisk];
    }, [allBuildings, unassignedBuilding]);

    return (
        <BaseTable
            classes={{
                table: styles.table,
            }}
            columns={columns}
            rows={[...atRiskBuildings]}
        />
    );
};

export default memo(LocationsTable);
