import { makeStyles } from '@material-ui/core';

export const useAddWidgetStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    addWidgetButton: {
        '& > .MuiButton-label > .MuiButton-startIcon > i': {
            fontSize: '14px',
            marginTop: theme.spacing(0.5),
        },
    },
}));
