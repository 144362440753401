import QueryParamsManager from '../QueryParamsManager';
import { formatSorting, parseArrayParams, parseNonArrayParams } from '../parsers';
import { SENSOR_PARAM_NAMES, SENSOR_PARAMS_SET } from './consts';
import type { SensorQueryParams, FormattedSensorQueryParams } from './types';

const FORMATTING_PARAMS_HANDLERS = {
    [SENSOR_PARAM_NAMES.ORDER_BY]: formatSorting,
    [SENSOR_PARAM_NAMES.LABELS]: parseArrayParams,
    [SENSOR_PARAM_NAMES.TYPE]: parseArrayParams,
    [SENSOR_PARAM_NAMES.LOCATION]: parseArrayParams,
};

const PARSING_PARAMS_HANDLERS = {
    [SENSOR_PARAM_NAMES.LABELS]: parseArrayParams,
    [SENSOR_PARAM_NAMES.TYPE]: parseArrayParams,
    [SENSOR_PARAM_NAMES.LOCATION]: parseArrayParams,
    [SENSOR_PARAM_NAMES.QUERY]: parseNonArrayParams,
};

const config = {
    paramsSet: SENSOR_PARAMS_SET,
    formattingParamsHandlers: FORMATTING_PARAMS_HANDLERS,
    parsingParamsHandlers: PARSING_PARAMS_HANDLERS,
    orderByKey: SENSOR_PARAM_NAMES.ORDER_BY,
};

export const sensorQueryParamsManager = new QueryParamsManager<
    SensorQueryParams,
    FormattedSensorQueryParams
>(
    config.paramsSet,
    config.formattingParamsHandlers,
    config.parsingParamsHandlers,
    config.orderByKey,
);
