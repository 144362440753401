import { Modal } from '@infogrid/components-material-ui';
import { useIsMobile, useIsOpenState } from '@infogrid/utils-hooks';
import { Button, CircularProgress, Tab, Tabs, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DiscardConfigurationChangesModal from 'views/dashboards/components/modals/DiscardConfigurationChangesModal';

import { configureWidgetModalProps, configureWidgetModalDefaultProps } from './constants';
import { useConfigureWidgetModalStyles, useTabClasses } from './styles';

const GenericConfigureWidgetModal = ({
    formik,
    initialValues,
    loading,
    onClose,
    onUpdateConfiguration,
    open,
    tabs,
    formValuesHaveChanged,
    savingDisabled,
    classes,
}) => {
    const isMobile = useIsMobile();
    const styles = useConfigureWidgetModalStyles();
    const tabClasses = useTabClasses();

    const { t } = useTranslation('dashboard');

    const [selectedTab, setSelectedTab] = useState(0);
    const [discardChangesModalOpen, closeDiscardChangesModal, openDiscardChangesModal] =
        useIsOpenState();

    const handleTabSelect = (_, index) => {
        setSelectedTab(index);
    };

    const handleConfirmClick = () => {
        onUpdateConfiguration();
        onClose();
    };

    const handleClose = useCallback(() => {
        if (formValuesHaveChanged) {
            openDiscardChangesModal();
        } else {
            onClose();
        }
    }, [formValuesHaveChanged, onClose, openDiscardChangesModal]);

    const handleDiscardChanges = useCallback(() => {
        closeDiscardChangesModal();
        onClose();
        formik.setValues(initialValues);
    }, [closeDiscardChangesModal, formik, initialValues, onClose]);

    const tabsClasses = useMemo(
        () => ({
            flexContainer: styles.tabsFlexContainer,
        }),
        [styles.tabsFlexContainer],
    );

    return (
        <>
            <Modal
                onClose={handleClose}
                open={open}
                fullScreen={isMobile}
                maxWidth="sm"
                fullWidth
                data-cypress="configure-widget-modal"
            >
                <Modal.Title>{t('Configure Widget')}</Modal.Title>
                <Tabs
                    classes={tabsClasses}
                    indicatorColor="primary"
                    onChange={handleTabSelect}
                    textColor="primary"
                    value={selectedTab}
                    variant="fullWidth"
                >
                    {tabs.map((tab) => (
                        <Tab
                            classes={tabClasses}
                            key={tab.id}
                            data-cypress="widget-modal-tab"
                            data-testid={tab.dataTestId}
                            label={t(tab.name)}
                        />
                    ))}
                </Tabs>
                <Modal.Content
                    className={classNames(styles.modalContent, classes?.content)}
                >
                    {tabs.map((tab) => {
                        const TabComponent = tab.component;
                        const tabAttributes = tab.attributes || {};

                        const isActive = tab.id === tabs[selectedTab]?.id;

                        const tabClass = classNames(styles.tab, {
                            [styles.tabActive]: isActive,
                        });

                        return (
                            <div className={tabClass} key={tab.id}>
                                {tab.description && (
                                    <Typography
                                        className={styles.description}
                                        variant="subtitle1"
                                    >
                                        {tab.description}
                                    </Typography>
                                )}

                                <TabComponent
                                    formik={formik}
                                    isTabActive={isActive}
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...tabAttributes}
                                />
                            </div>
                        );
                    })}
                </Modal.Content>
                <Modal.Actions justify="space-between">
                    <Button onClick={handleClose} data-cypress="cancel-btn">
                        {t('Cancel', { ns: 'common' })}
                    </Button>
                    <Button
                        color="primary"
                        disabled={savingDisabled}
                        onClick={handleConfirmClick}
                        variant="contained"
                        data-cypress="save-config-btn"
                    >
                        {loading && (
                            <CircularProgress className={styles.spinner} size={18} />
                        )}
                        {t('Save', { ns: 'common' })}
                    </Button>
                </Modal.Actions>
            </Modal>
            <DiscardConfigurationChangesModal
                onClose={closeDiscardChangesModal}
                onConfirm={handleDiscardChanges}
                open={discardChangesModalOpen}
            />
        </>
    );
};

GenericConfigureWidgetModal.propTypes = configureWidgetModalProps;
GenericConfigureWidgetModal.defaultProps = configureWidgetModalDefaultProps;

export default memo(GenericConfigureWidgetModal);
