import { makeStyles } from '@material-ui/core';

export const useTreeNodeContentStyles = makeStyles((theme) => ({
    treeItemContent: {
        display: 'flex',
        alignItems: 'center',

        position: 'relative',
    },
    treeItemName: {
        wordBreak: 'break-word',
    },
    treeItemToggle: ({ isSelected }) => ({
        display: 'block',
        height: 14,
        width: 14,
        marginLeft: 'auto',
        marginRight: '6px',

        '&:before': {
            content: '""',
            display: isSelected ? 'block' : 'none',

            backgroundColor: theme.palette.background.default,

            border: `4px solid ${theme.palette.primary.main}`,
            borderRadius: '50%',

            position: 'absolute',
            height: 14,
            width: 14,

            boxSizing: 'borderBox',
        },
    }),
    treeItemIcon: {
        display: 'flex',
        alignItems: 'center',

        opacity: 0.8,

        marginRight: theme.spacing(1),
        height: '1.1em',

        fontSize: 21,
    },
}));
