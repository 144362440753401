import { makeStyles } from '@material-ui/core';

export const useChoosingFolderFloorStyles = makeStyles((theme) => ({
    description: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(3),
    },
    primaryDescription: {
        marginBottom: theme.spacing(1),
    },
    secondaryDescription: {
        color: theme.palette.primary.main,
    },
}));
