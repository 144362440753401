import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getAvailabilityClassname } from '../utils';
import { useRightCard } from './styles';

const RightCard = ({ nearCapacity, outOfCapacity, layout, stoplight }) => {
    const { t } = useTranslation('dashboard');
    const styles = useRightCard({ layout });

    const availabilitySecondaryBackgroundClassnames = getAvailabilityClassname(
        styles,
        nearCapacity,
        outOfCapacity,
        'secondary',
        'Background',
    );
    const availabilityPrimaryColorClassnames = getAvailabilityClassname(
        styles,
        nearCapacity,
        outOfCapacity,
        'primary',
        'Color',
    );
    const rightCardClassnames = classNames(
        styles.rightCard,
        availabilitySecondaryBackgroundClassnames,
    );
    const recommendedActionsTextClassnames = classNames(
        styles.recommendedActionsText,
        availabilityPrimaryColorClassnames,
    );

    const iconClassnames = classNames(
        styles.smallIconContainer,
        availabilityPrimaryColorClassnames,
    );

    const stoplightSectionsWithLabels = useMemo(
        () => stoplight.filter((s) => s.label),
        [stoplight],
    );

    return (
        <div className={rightCardClassnames}>
            <div className={styles.rightCardContent}>
                {stoplightSectionsWithLabels.map((stoplightSection, index) => (
                    <div
                        className={styles.cardRow}
                        key={`${stoplightSection.label}-${index}`}
                    >
                        <i
                            className={classNames(iconClassnames, stoplightSection.icon)}
                        />
                        <Typography
                            className={recommendedActionsTextClassnames}
                            variant="h6"
                        >
                            {t(stoplightSection?.label)}
                        </Typography>
                    </div>
                ))}
            </div>
        </div>
    );
};

RightCard.propTypes = {
    nearCapacity: PropTypes.bool.isRequired,
    outOfCapacity: PropTypes.bool.isRequired,
    layout: PropTypes.shape({}).isRequired,
    stoplight: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
        }).isRequired,
    ),
};

export default memo(RightCard);
