import { Modal } from '@infogrid/components-material-ui';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type { SensorShape } from '@infogrid/sensors-constants';
import { Button, Icon, Link, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import type { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import SensorInfoCard from 'components/material-ui/Sensors/SensorInfoCard';
import { SENSOR_INSTALLATION_GUIDE_URL } from 'constants/urls';

import { useInstallSuccessStyles } from './styles';

interface InstallSuccessProps {
    installedSensor: SensorShape;
    isMissingOffset?: boolean;
    isSensorOffline?: boolean;
    isStatusLoading?: boolean;
    onClickClose: () => void;
}

const InstallSuccess = ({
    installedSensor,
    isMissingOffset,
    isSensorOffline,
    isStatusLoading,
    onClickClose,
}: InstallSuccessProps): ReactElement => {
    const { t } = useTranslation('sensors');
    const styles = useInstallSuccessStyles();

    const showOfflineMessage = useMemo(() => {
        return (
            isSensorOffline &&
            installedSensor.type_code !== SENSOR_TYPE.TYPE_AIR_QUALITY &&
            installedSensor.type_code !== SENSOR_TYPE.TYPE_AIRTHINGS_HUB
        );
    }, [installedSensor, isSensorOffline]);

    const showStatusIcons = useMemo(() => {
        return (
            installedSensor.type_code !== SENSOR_TYPE.TYPE_LEGIONNAIRE &&
            installedSensor.type_code !== SENSOR_TYPE.TYPE_DESK_OCCUPANCY
        );
    }, [installedSensor]);

    return (
        <>
            <Modal.Title>{t('Setup successful!')}</Modal.Title>
            <Modal.Content className={styles.content}>
                <div className={styles.contentHeader}>
                    <Icon
                        className={classNames(
                            'far',
                            'fa-check-circle',
                            styles.successIcon,
                        )}
                    />
                    <Typography variant="h5">{t('Device setup successful!')}</Typography>
                    <Typography variant="h6">
                        {t('Now physically install the device')}
                    </Typography>
                    <Typography data-cypress="install-success-reminder" variant="body2">
                        {t('As a reminder, you can find')}
                        <Link
                            href={SENSOR_INSTALLATION_GUIDE_URL}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.inlineHelpLink}
                        >
                            {t('installation instructions for all devices here.')}
                        </Link>
                    </Typography>
                </div>

                {showOfflineMessage && (
                    <Alert
                        severity="warning"
                        className={styles.warning}
                        data-cypress="warning-sensor-offline"
                    >
                        <AlertTitle>{t('Sensor is offline')}</AlertTitle>
                        {t(
                            'This sensor is offline. No data will be sent until sensor is online.',
                        )}
                    </Alert>
                )}

                {isMissingOffset && (
                    <Alert
                        severity="warning"
                        className={styles.warning}
                        data-cypress="warning-missing-offset"
                    >
                        <AlertTitle>{t('Offset is missing')}</AlertTitle>
                        <Trans t={t}>
                            The temperature offset for this sensor has not been
                            calibrated.
                            <br />
                            Once your device has been physically installed,{' '}
                            <strong>wait 10 minutes before setting the offset</strong>.
                            <br />
                            Go to the{' '}
                            <RouterLink to="/sensors" target="_blank">
                                Sensors
                            </RouterLink>{' '}
                            view in the web app to enter an offset reading.
                        </Trans>
                    </Alert>
                )}

                <SensorInfoCard
                    sensor={installedSensor}
                    showStatusIcons={showStatusIcons}
                    statusLoading={isStatusLoading}
                />
            </Modal.Content>
            <Modal.Actions justify="right">
                <Button
                    data-cypress="install-success-close"
                    onClick={onClickClose}
                    color="primary"
                >
                    {t('Close', { ns: 'common' })}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(InstallSuccess);
