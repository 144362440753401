import { makeStyles } from '@material-ui/core';
import type { MapBrowserEvent } from 'ol';
import type { FeatureLike } from 'ol/Feature';
import type { Point } from 'ol/geom';
import { useContext, useEffect, memo, useRef, useState } from 'react';

import type { FeatureId } from '.';
import Context from './MapContext';
import type { MapContext } from './MapContext';

const useStylesMapTooltip = makeStyles({
    tooltipContainer: {
        position: 'absolute',
        zIndex: 4,
    },
});

interface Props<T> {
    children: (featureData: T) => React.ReactNode;
    featureDataExtractor: (feature: FeatureLike) => T;
}

const TooltipContainer = <T,>({ children, featureDataExtractor }: Props<T>) => {
    const styles = useStylesMapTooltip();

    const { map } = useContext<MapContext>(Context);

    const tooltipContainerRef = useRef<HTMLDivElement>(null);
    const selectedFeatureId = useRef<FeatureId>();

    const [tooltipFeature, setActiveFeature] = useState<T>();

    useEffect(() => {
        if (!map) {
            return () => {};
        }

        const onPointerMove = (event: MapBrowserEvent<UIEvent>) => {
            const activeFeature = map.forEachFeatureAtPixel(event.pixel, (feature) => {
                const featureId = feature.getId();

                if (selectedFeatureId.current !== featureId) {
                    const mapCoordinates = feature.getGeometry() as Point;

                    const coordinates = map.getPixelFromCoordinate(
                        mapCoordinates.getCoordinates(),
                    );

                    if (tooltipContainerRef.current) {
                        tooltipContainerRef.current.style.left = `${coordinates[0]}px`;
                        tooltipContainerRef.current.style.top = `${coordinates[1]}px`;
                    }

                    selectedFeatureId.current = featureId;

                    setActiveFeature(featureDataExtractor(feature));
                }

                return feature;
            });

            if (!activeFeature && selectedFeatureId.current) {
                setActiveFeature(undefined);
                selectedFeatureId.current = undefined;
            }
        };

        map.on('pointermove', onPointerMove);

        return () => {
            map.un('pointermove', onPointerMove);
        };
    }, [featureDataExtractor, map]);

    return (
        <div className={styles.tooltipContainer} ref={tooltipContainerRef}>
            {tooltipFeature && children(tooltipFeature)}
        </div>
    );
};

export default memo(TooltipContainer);
