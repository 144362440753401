import { makeStyles } from '@material-ui/core';

interface MonnitDryPulseConfigurationStyleProps {
    isMobileView?: boolean;
}

export const useMonnitDryPulseConfigurationStyles = makeStyles((theme) => ({
    info: {
        marginBottom: theme.spacing(3),
        marginTop: ({ isMobileView }: MonnitDryPulseConfigurationStyleProps) =>
            isMobileView ? theme.spacing(1.5) : 0,
    },
    input: {
        marginBottom: theme.spacing(3),
    },
}));
