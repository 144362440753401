import { createSchemaSelector } from '@thorgate/spa-entities';
import { combineReducers } from 'redux';

import { toggleArrayMember } from 'utils/functional';

export const SET_DIALOG_OPEN = 'ui/SET_DIALOG_OPEN';
export const SET_SELECTION = 'ui/SET_SELECTION';
export const SET_LIST_SELECTION = 'ui/SET_LIST_SELECTION';
export const SET_AUTHENTICATION_STATE = 'ui/SET_AUTHENTICATION_STATE';
export const SET_SIDEBAR_WIDTH = 'ui/SET_SIDEBAR_WIDTH';

export const AUTHENTICATION_TYPE = {
    UNKNOWN: 'unknown',
    AUTHENTICATED: 'authenticated',
    UNAUTHENTICATED: 'unauthenticated',
};

const initialState = {
    dialogs: {},
    selections: {},
    authenticationState: AUTHENTICATION_TYPE.UNKNOWN,
    sidebarWidth: {
        globalDesktop: null,
        globalMobile: null,
        floorplansFloorsList: null,
        floorplansSensorsList: null,
    },
};

function dialogsReducer(state = initialState.dialogs, action) {
    switch (action.type) {
        case SET_DIALOG_OPEN:
            return {
                ...state,
                [action.dialogName]: {
                    ...state[action.dialogName],
                    open: action.open,
                },
            };

        default:
            return state;
    }
}

function selectionsReducer(state = initialState.selections, action) {
    switch (action.type) {
        case SET_LIST_SELECTION: {
            const selection = toggleArrayMember(
                state[action.schema.key] || [],
                action.selectedId,
                action.add,
            );

            return {
                ...state,
                [action.schema.key]: selection,
            };
        }

        case SET_SELECTION: {
            return {
                ...state,
                [action.selectionKey]: action.selection,
            };
        }

        default:
            return state;
    }
}

function authenticationStateReducer(state = initialState.authenticationState, action) {
    switch (action.type) {
        case SET_AUTHENTICATION_STATE:
            return action.authState;
        default:
            return state;
    }
}

function sidebarsReducer(state = initialState.sidebarWidth, action) {
    switch (action.type) {
        case SET_SIDEBAR_WIDTH:
            return {
                ...state,
                [action.sidebarName]: action.width,
            };

        default:
            return state;
    }
}

export default combineReducers({
    dialogs: dialogsReducer,
    selections: selectionsReducer,
    authenticationState: authenticationStateReducer,
    sidebarWidth: sidebarsReducer,
});

export const selectors = {
    dialog: (state, dialogName) => state.ui.dialogs[dialogName],
    listSelection: (state, schema) => {
        const selector = createSchemaSelector(schema);
        const selectedIds = state.ui.selections[schema.key];

        return selectedIds?.length ? selector(state, ...selectedIds) : [];
    },
    selection: (state, selectionKey) => state.ui.selections[selectionKey],
    authenticationState: (state) => state.ui.authenticationState,
    sidebarWidth: (state, sidebarName) => state.ui.sidebarWidth[sidebarName],
};

export const setDialog = (dialogName, open) => ({
    type: SET_DIALOG_OPEN,
    dialogName,
    open,
});

export const setListSelection = (schema, selectedId, add) => ({
    type: SET_LIST_SELECTION,
    schema,
    selectedId,
    add,
});
export const setSelection = (selectionKey, selection) => ({
    type: SET_SELECTION,
    selectionKey,
    selection,
});

export const setAuthenticationState = (authState) => ({
    type: SET_AUTHENTICATION_STATE,
    authState,
});

export const setSidebarWidthState = (sidebarName, width) => ({
    type: SET_SIDEBAR_WIDTH,
    sidebarName,
    width,
});
