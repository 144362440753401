import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import { memo } from 'react';
import type { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';

import { isObjectEmpty } from 'utils/objects';
import OccupancyStoplightChart from 'views/dashboards/widgets/common/Charts/OccupancyStoplightChart';
import {
    getCurrentDisplay,
    prepareBaseStoplightValues,
} from 'views/dashboards/widgets/utils';

import WidgetAlert from '../../common/WidgetAlert';
import type {
    CubicleOccupancyStoplightData,
    CubicleOccupancyStoplightWidget,
} from '../types';

interface ContentProps {
    data?: CubicleOccupancyStoplightData;
    isConfigured: boolean;
    layout: Layout;
    widget: CubicleOccupancyStoplightWidget;
}

const Content = ({ widget, data, layout, isConfigured }: ContentProps) => {
    const { t } = useTranslation('dashboard');

    if (!isConfigured) {
        return (
            <WidgetAlert severity="warning">
                {t(WidgetErrorMessages.notConfigured)}
            </WidgetAlert>
        );
    }

    const occupancyStoplight = widget?.configuration?.calculators?.occupancy_stoplight;

    const { displays, stoplight: stoplightValues, thresholds } = occupancyStoplight;

    const openSensorsCount = data?.open_sensors_count;
    const sensorsCount = data?.sensor_count;

    const [outOfCapacityThreshold, nearCapacityThreshold] = thresholds || [0, 0];
    const nearCapacity = (openSensorsCount ?? 0) <= (nearCapacityThreshold || 0);
    const outOfCapacity = (openSensorsCount ?? 0) <= (outOfCapacityThreshold || 0);

    const currentDisplay = getCurrentDisplay(displays, nearCapacity, outOfCapacity);

    const { message: titleText, showCubicle } = currentDisplay;

    const occupancyText = t('{{count}} out of {{sensorsCount}} cubicles available', {
        count: openSensorsCount,
        sensorsCount,
        defaultValue___one: `${openSensorsCount} cubicle available`,
        defaultValue___other: `${openSensorsCount} out of ${sensorsCount} cubicles available`,
    });

    const configuration = {
        id: widget.id,
        configured: !isObjectEmpty(currentDisplay),
        occupancyText,
        showOccupancy: showCubicle,
        stoplight: stoplightValues || prepareBaseStoplightValues(),
        titleText,
    };

    return (
        <OccupancyStoplightChart
            configuration={configuration}
            layout={layout}
            nearCapacity={nearCapacity}
            outOfCapacity={outOfCapacity}
        />
    );
};

export default memo(Content);
