import { useAppSelector } from '@infogrid/core-ducks';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type { PlannedSensor } from '@infogrid/sensors-constants';
import type { ReactElement } from 'react';

import { selectPlannedSensor } from 'ducks/installFlow';

import ConfigurePipeMonitoring from './ConfigurePipeMonitoring';

const ConfigureDevice = (): ReactElement | null => {
    const plannedSensor = useAppSelector(selectPlannedSensor) as PlannedSensor;
    const sensorType = plannedSensor.device_type.name;

    // Any types added here must be added to CONFIGURABLE_TYPES in ducks/installFlow.ts
    switch (sensorType) {
        case SENSOR_TYPE.TYPE_LEGIONNAIRE:
            return <ConfigurePipeMonitoring />;
    }

    return null;
};

export default ConfigureDevice;
