import { createDetailSchemaSelector } from '@thorgate/spa-entities';
import { schema } from 'normalizr';

export const termsSchema = new schema.Entity(
    'terms',
    {},
    {
        idAttribute: 'active',
    },
);

export const selectTerms = createDetailSchemaSelector(termsSchema);
export const selectActiveTerms = (state) => selectTerms(state, 'active');
