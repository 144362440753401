import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { Button, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { useFloorPlanParams } from 'apiHooks/base/hooks';
import { useSensorPlanningLock } from 'apiHooks/floorPlan/sensors/hooks';
import { useIsSensorPlanningEnabled } from 'utils/sensorPlanning';

import SensorsList from '../SensorsList';
import { useStylesSensorNavigation } from './styles';

interface SensorNavigationSensor extends GenericSensorShape {
    id: string | number;
}

interface SensorNavigationProps {
    activeSensorDeviceId: string | number | null;
    isLoading?: boolean;
    sensors: SensorNavigationSensor[];
    onSensorSelect: (sensorDeviceId: string | number) => void;
}

const SensorNavigation = ({
    activeSensorDeviceId,
    isLoading = false,
    onSensorSelect,
    sensors,
}: SensorNavigationProps) => {
    const styles = useStylesSensorNavigation();

    const { t } = useTranslation('floorplan');

    const { floorId, buildingId } = useFloorPlanParams() as {
        floorId: number;
        buildingId: number;
    };

    const { data: sensorPlanningLock, isLoading: isSensorPlanningLockLoading } =
        useSensorPlanningLock(floorId);

    const { push: redirectTo } = useHistory();

    const sensorPlanningEnabled = useIsSensorPlanningEnabled();

    const redirectToSensorsPlanning = useCallback(() => {
        redirectTo(`/buildings/${buildingId}/floors/${floorId}/plan`);
    }, [buildingId, floorId, redirectTo]);

    return (
        <section
            className={styles.sensorsListContainer}
            data-cypress="sensors-list-container"
        >
            <SensorsList
                activeSensorDeviceId={activeSensorDeviceId}
                isDraggable
                isLoading={isLoading}
                placeholder={
                    <>
                        <Alert severity="info" data-cypress="alert-help">
                            {sensorPlanningEnabled ? (
                                <Typography>
                                    {t(
                                        'Only sensors that have been installed appear in this list. Planned sensors will appear in this list once they’re installed.',
                                    )}
                                </Typography>
                            ) : (
                                <Typography>
                                    {t('To add a sensor, go to the ')}
                                    <Link to="/folders">{t('Folders view')}</Link>
                                    {t(
                                        ", select the sensors for this floor, press the 'Move' button and navigate to this floor’s folder",
                                    )}
                                </Typography>
                            )}
                        </Alert>
                        {sensorPlanningEnabled && (
                            <Tooltip
                                placement="bottom-start"
                                title={
                                    !isSensorPlanningLockLoading &&
                                    sensorPlanningLock &&
                                    !sensorPlanningLock.is_owner
                                        ? t(
                                              'Another user is currently planning sensors for this floor. Planning is locked until they have finished editing.',
                                          )
                                        : ''
                                }
                            >
                                <div className={styles.planSensorsButtonWrapper}>
                                    <Button
                                        className={styles.planSensorsButton}
                                        color="primary"
                                        disabled={Boolean(
                                            isSensorPlanningLockLoading ||
                                                (sensorPlanningLock &&
                                                    !sensorPlanningLock.is_owner),
                                        )}
                                        onClick={redirectToSensorsPlanning}
                                        variant="contained"
                                        data-cypress="plan-sensors"
                                    >
                                        {isSensorPlanningLockLoading && (
                                            <CircularProgress
                                                className={styles.spinner}
                                                size={22}
                                            />
                                        )}
                                        {t('Plan sensors')}
                                    </Button>
                                </div>
                            </Tooltip>
                        )}
                    </>
                }
                placeholderAlwaysVisible
                sensors={sensors}
                onSensorClick={onSensorSelect}
            />
        </section>
    );
};

export default memo(SensorNavigation);
