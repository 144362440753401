import { Modal } from '@infogrid/components-material-ui';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button, TextField, Grid, Typography } from '@material-ui/core';
import type { FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAddFloorModalStyles } from './styles';
import type { AddFloorModalValues } from './types';

interface Props {
    enableSaveAndAddAnother?: boolean;
    formik: FormikProps<AddFloorModalValues>;
    onClose: () => void;
    open: boolean;
}

const AddFloorModal = ({
    enableSaveAndAddAnother = false,
    formik,
    onClose,
    open,
}: Props) => {
    const styles = useAddFloorModalStyles();

    const { t } = useTranslation('estate');

    const isMobile = useIsMobile();

    const {
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        submitForm,
        touched,
        values,
    } = formik;

    const onSave = () => {
        setFieldValue('actionType', 'save');

        submitForm();
    };

    const onSaveAndAddAnother = () => {
        setFieldValue('actionType', 'add-another');

        submitForm();
    };

    return (
        <Modal fullScreen={isMobile} onClose={onClose} open={open}>
            <Modal.Title>{t('Add a floor')}</Modal.Title>
            <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formContent} data-cypress="edit-floor-content">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                data-cypress="floor-name"
                                error={!!touched.name && !!errors.name}
                                fullWidth
                                helperText={
                                    !!touched.name && errors.name && t(errors.name)
                                }
                                id="name"
                                label={t('Floor name')}
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.name}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={styles.header} variant="subtitle1">
                                {t('Capacity')}
                            </Typography>
                            <Typography
                                data-cypress={'add-floor-capacity'}
                                className={styles.capacityText}
                                color="textSecondary"
                                variant="body2"
                            >
                                {t(
                                    'This value will be used to calculate floor utilisation.',
                                )}
                            </Typography>
                            <TextField
                                data-cypress={'add-floor-capacity-input'}
                                type="number"
                                label={t('Capacity')}
                                id="floor_add_occupant_capacity"
                                name="occupant_capacity"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: 1,
                                }}
                                onChange={handleChange}
                                defaultValue={''}
                                value={values.occupant_capacity}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </div>
                <Modal.Actions justify="space-between">
                    <Button color="primary" onClick={onClose}>
                        {t('Cancel', { ns: 'common' })}
                    </Button>
                    <div>
                        {!isMobile && enableSaveAndAddAnother && (
                            <Button
                                color="primary"
                                data-cypress="save-floor-and-add-another"
                                disabled={isSubmitting || !isEmpty(errors)}
                                onClick={onSaveAndAddAnother}
                                variant="outlined"
                            >
                                {t('Save + add another', { ns: 'common' })}
                            </Button>
                        )}
                        <Button
                            className={styles.saveButton}
                            color="primary"
                            data-cypress="save-floor"
                            disabled={isSubmitting || !isEmpty(errors)}
                            onClick={onSave}
                            variant="contained"
                        >
                            {t('Save', { ns: 'common' })}
                        </Button>
                    </div>
                </Modal.Actions>
            </form>
        </Modal>
    );
};

export default memo(AddFloorModal);
