import { wrapResponsePromise } from '@infogrid/core-api';
import { useAppDispatch, toastError } from '@infogrid/core-ducks';
import type { IPagination, AxiosParsedError } from '@infogrid/core-types';
import type { FeedbackPanelTemplate } from '@infogrid/sensors-constants';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import type { UseInfiniteQueryOptions, UseInfiniteQueryResult } from 'react-query';

import { controllers } from '../controllers';
import { getFeedbackPanelTemplatesKey } from '../getQueryKeys';

export const useFeedbackPanelTemplates = (
    options?: UseInfiniteQueryOptions<
        IPagination<FeedbackPanelTemplate>,
        AxiosParsedError
    >,
): UseInfiniteQueryResult<IPagination<FeedbackPanelTemplate>, AxiosParsedError> => {
    const { t } = useTranslation('sensors');
    const dispatch = useAppDispatch();

    return useInfiniteQuery<IPagination<FeedbackPanelTemplate>, AxiosParsedError>(
        getFeedbackPanelTemplatesKey(),
        ({ signal, pageParam = {} }) =>
            wrapResponsePromise(controllers.getFeedbackPanelTemplates(signal, pageParam)),
        {
            onError() {
                dispatch(
                    toastError({
                        message: t('Failed to load feedback panel templates.'),
                    }),
                );
            },
            keepPreviousData: true,
            getNextPageParam: (lastPage) => lastPage?.next,
            ...options,
        },
    );
};
