import { registerTranslationKey } from '@infogrid/utils-i18n';

export const MONITORING_TYPE_WATER_MOVING = 'water_moving';
export const MONITORING_TYPE_TEMPERATURE = 'temperature';

export const MONITORING_TYPES = [
    {
        key: MONITORING_TYPE_TEMPERATURE,
        displayText: registerTranslationKey('Temperature', { ns: 'reports' }),
    },
    {
        key: MONITORING_TYPE_WATER_MOVING,
        displayText: registerTranslationKey('Water Movement', { ns: 'reports' }),
    },
];
export const MONITORING_TYPES_ALL_KEYS = MONITORING_TYPES.map((item) => item.key).sort();

// This is the same as `water_moving` but in the context of report
export const REPORT_MONITORING_TYPE_WATER_FLOW = 'water_flow';
export const mapReportToDashboardFormat = (type: string) =>
    type.replace(REPORT_MONITORING_TYPE_WATER_FLOW, MONITORING_TYPE_WATER_MOVING);
export const mapDashboardToReportFormat = (type: string) =>
    type.replace(MONITORING_TYPE_WATER_MOVING, REPORT_MONITORING_TYPE_WATER_FLOW);
