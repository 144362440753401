import { BackLink, Tooltip } from '@infogrid/components-material-ui';
import {
    Divider,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from '@material-ui/core';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import type {
    SolutionSettingsMenuItem,
    SolutionSettingsMenuItemGroup,
} from 'views/solutionSettings/pages/solutionSettings/types';

import { useDesktopSolutionsSidebarStyles } from './styles';

interface DesktopSolutionsSidebarProps {
    menuitems: SolutionSettingsMenuItemGroup[];
}

const DesktopSolutionsSidebar = ({
    menuitems,
}: DesktopSolutionsSidebarProps): JSX.Element | null => {
    const styles = useDesktopSolutionsSidebarStyles();
    const { t } = useTranslation('solutions');

    return (
        <Paper elevation={1} square className={styles.root}>
            <BackLink
                to="/settings"
                data-cypress="solution-setup-settings-back-button"
                className={styles.backButton}
            >
                {t('Settings')}
            </BackLink>
            <Divider className={styles.dividerLine} />
            <Typography
                className={styles.title}
                color="textPrimary"
                variant="caption"
                component="p"
            >
                {t('SOLUTION SETUP')}
            </Typography>
            <List>
                {menuitems.map((value: SolutionSettingsMenuItemGroup) => (
                    <Fragment key={value[0]}>
                        <ListItem className={styles.heading}>
                            <ListItemText primary={value[0]} />
                        </ListItem>
                        {value[1].map(({ name, url }: SolutionSettingsMenuItem) => (
                            <ListItem className={styles.noMargins} key={name}>
                                <Tooltip
                                    title={name}
                                    placement="right"
                                    className={styles.tooltip}
                                >
                                    <NavLink
                                        to={url}
                                        className={styles.integrationItem}
                                        activeClassName={styles.integrationItemActive}
                                    >
                                        <ListItemText>
                                            <Typography
                                                className={styles.text}
                                                color="textPrimary"
                                                variant="caption"
                                                component="p"
                                            >
                                                {name}
                                            </Typography>
                                        </ListItemText>
                                    </NavLink>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </Fragment>
                ))}
            </List>
        </Paper>
    );
};

export default memo(DesktopSolutionsSidebar);
