import { registerTranslationKey } from '@infogrid/utils-i18n';
import * as Yup from 'yup';

const BLANK_FIELD_ERROR = registerTranslationKey('This field may not be blank.', {
    ns: 'estate',
});
const CHAR_LIMIT_ERROR = registerTranslationKey('Must be 100 characters or less.', {
    ns: 'estate',
});

export const editSpaceValidationSchema = Yup.object().shape({
    name: Yup.string().required(BLANK_FIELD_ERROR).max(100, CHAR_LIMIT_ERROR).trim(),
});
