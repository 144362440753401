import { toastSuccess } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { selectOrganizationId } from '@infogrid/user-ducks';
import { i18n } from '@infogrid/utils-i18n';
import { createSaveAction, createFormSaveSaga } from '@thorgate/spa-forms';
import { push } from 'connected-react-router';
import { takeLatest, put, select } from 'redux-saga/effects';

import api from 'services/api';

/**
 * Trigger Save Organization Account worker saga.
 * @param {Object} payload Payload to send to server
 * @param {Object} Formik Meta - Formik meta args
 */
export const saveOrgUser = createSaveAction('sagas/account/SAVE_USER');

const saveOrgUserWorker = createFormSaveSaga({
    resource: api.organization.user.detail,
    method: 'put',
    *successHook(_0, _1, { payload: { data } }) {
        yield put(
            toastSuccess({
                message: `${i18n.t('User saved')} - ${data.email}`,
            }),
        );
        yield put(push(routesManager.resolvePath('organization:accounts')));
    },

    *mutateKwargs(match) {
        const organizationId = yield select(selectOrganizationId);

        return {
            org_pk: organizationId,
            pk: match.params.userId,
        };
    },
});

export default function* saveOrgUserWatcherSaga(match) {
    yield takeLatest(saveOrgUser.getType(), saveOrgUserWorker, match);
}

export const saveOrgServiceAccount = createSaveAction(
    'sagas/account/SAVE_SERVICE_ACCOUNT',
);

const saveOrgServiceAccountWorker = createFormSaveSaga({
    resource: api.organization.serviceAccount.detail,
    method: 'put',
    *successHook(_0, _1, { payload: { data } }) {
        yield put(
            toastSuccess({
                message: `${i18n.t('Service account saved')} - ${data.name}`,
            }),
        );
        yield put(push(routesManager.resolvePath('organization:accounts')));
    },

    *mutateKwargs(match) {
        const organizationId = yield select(selectOrganizationId);

        return {
            org_pk: organizationId,
            pk: match.params.userId,
        };
    },
});

export function* saveOrgServiceAccountWatcherSaga(match) {
    yield takeLatest(saveOrgServiceAccount.getType(), saveOrgServiceAccountWorker, match);
}
