import { makeStyles } from '@material-ui/core';

export const useWidgetFooterStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderTop: '1px solid #E0E0E0',
        display: 'flex',
        padding: theme.spacing(1.5, 3),
        width: '100%',
    },
}));
