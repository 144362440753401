import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { useEffectOnMount } from '@infogrid/utils-hooks';
import PropTypes from 'prop-types';
import { useCallback, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import WebSocketContainer from 'containers/WebSocketContainer';
import api from 'services/api';

import {
    IDENTIFY_INSTRUCTIONS,
    IDENTIFY_DISCONNECT_HELP_MESSAGE,
    IDENTIFY_DISCONNECT_TIMEOUT_HELP_MESSAGE,
    IDENTIFY_STATUS,
    IDENTIFY_STREAM_NAME,
} from './constants';

const IdentifyDeviceWebSocketContainer = ({
    onSuccess,
    delayTime,
    timeoutHelpMessage,
    render,
}) => {
    const { t } = useTranslation('sensors');
    const [instruction, setInstruction] = useState(IDENTIFY_INSTRUCTIONS.INITIAL);
    const [helpText, setHelpText] = useState(null);
    const [isInProgress, setIsInProgress] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [isError, setIsError] = useState(false);
    const [waitingFirstTouchTimeoutId, setWaitingFirstTouchTimeoutId] = useState(null);
    const [inProgressTimeoutId, setInProgressTimeoutId] = useState(null);

    const dispatch = useAppDispatch();

    useEffectOnMount(() => {
        return () => {
            if (waitingFirstTouchTimeoutId) {
                clearTimeout(waitingFirstTouchTimeoutId);
                setWaitingFirstTouchTimeoutId(null);
            }

            if (inProgressTimeoutId) {
                clearTimeout(inProgressTimeoutId);
                setInProgressTimeoutId(null);
            }
        };
    });

    const handleSuccess = useCallback(
        ({ nextUrl, disconnectSocket, deviceName, sourceDeviceName }) => {
            // Sensor has been confirmed, we can now close the socket
            onSuccess({ deviceName, sourceDeviceName, nextUrl });
            disconnectSocket();
        },
        [onSuccess],
    );

    const onMessage = useCallback(
        (stream, payload, sendMessage, connectSocket, disconnectSocket) => {
            if (stream !== IDENTIFY_STREAM_NAME) {
                return;
            }

            if (waitingFirstTouchTimeoutId && payload.status !== IDENTIFY_STATUS.BEGIN) {
                clearTimeout(waitingFirstTouchTimeoutId);
                setWaitingFirstTouchTimeoutId(null);
            }

            // Handle events correctly
            // E.g request first touch => request second touch => redirect
            // Keep status info for required events in state
            switch (payload.status) {
                case IDENTIFY_STATUS.IN_PROGRESS: {
                    setIsInProgress(true);

                    const timeoutId = setTimeout(
                        () => setInstruction(IDENTIFY_INSTRUCTIONS.IN_PROGRESS),
                        500,
                    );

                    setInProgressTimeoutId(timeoutId);

                    if (disconnectSocket) {
                        disconnectSocket();
                    }

                    break;
                }

                case IDENTIFY_STATUS.BEGIN: {
                    if (helpText?.level === 'error') {
                        setHelpText(null);
                    }

                    const timeoutId = setTimeout(() => {
                        setHelpText(timeoutHelpMessage);
                    }, delayTime);

                    setInstruction(IDENTIFY_INSTRUCTIONS.START);

                    setWaitingFirstTouchTimeoutId(timeoutId);

                    break;
                }

                case IDENTIFY_STATUS.IDENTIFY: {
                    sendMessage({ ack: IDENTIFY_STATUS.IDENTIFY });

                    // Update IDENTIFY_INSTRUCTIONS
                    setInstruction(IDENTIFY_INSTRUCTIONS.IDENTIFY);
                    setHelpText(null);

                    break;
                }

                case IDENTIFY_STATUS.CONFIRM: {
                    // Acknowledge server response
                    sendMessage({ ack: IDENTIFY_STATUS.CONFIRM });

                    // Update IDENTIFY_INSTRUCTIONS
                    setInstruction(IDENTIFY_INSTRUCTIONS.CONFIRM);

                    break;
                }

                case IDENTIFY_STATUS.SHOW_SENSOR_VIEW: {
                    // Mark as confirmed

                    setIsConfirmed(true);
                    // Show success message
                    dispatch(toastSuccess({ message: t('Sensor identified') }));

                    // Redirect user to edit view
                    const { deviceName, sourceDeviceName } = payload;

                    handleSuccess({
                        nextUrl: routesManager.resolvePath('sensors:details', {
                            deviceName,
                        }),
                        disconnectSocket,
                        deviceName,
                        sourceDeviceName,
                    });

                    break;
                }

                case IDENTIFY_STATUS.SHOW_MANAGERS: {
                    // Mark as confirmed
                    setIsConfirmed(true);

                    const errorMessage = t(
                        'You do not have access to this sensor. Please contact your manager.',
                    );

                    // Make sure to not remove this error toast or the user might be very confused.
                    dispatch(toastError({ message: errorMessage }));

                    const { managers } = payload;

                    handleSuccess({
                        nextUrl: routesManager.resolvePath(
                            'organization:managers',
                            null,
                            null,
                            {
                                managers,
                            },
                        ),
                        disconnectSocket,
                        deviceName: undefined,
                        sourceDeviceName: undefined,
                    });

                    break;
                }

                case IDENTIFY_STATUS.SHOW_UNKNOWN: {
                    const infoMessage = t(
                        'This sensor is linked to another organization you do not have permission to edit. Please check users with access and try again.',
                    );

                    dispatch(toastError({ message: infoMessage }));

                    break;
                }

                case IDENTIFY_STATUS.SHOW_OTHER_ORG: {
                    const { organization_name } = payload;
                    const infoMessage = t(
                        'The sensor you are identifying is already linked to another organization. Please switch to the organization "{{orgName}}" and try again.',
                        { orgName: organization_name },
                    );

                    dispatch(toastError({ message: infoMessage }));

                    break;
                }

                default:
                    break;
            }
        },
        [
            dispatch,
            handleSuccess,
            waitingFirstTouchTimeoutId,
            helpText,
            timeoutHelpMessage,
            delayTime,
            t,
        ],
    );

    const onClose = useCallback(
        (code) => {
            if (!isInProgress && !isConfirmed) {
                if (inProgressTimeoutId) {
                    clearTimeout(inProgressTimeoutId);
                    setInProgressTimeoutId(null);
                }

                setInstruction(IDENTIFY_INSTRUCTIONS.EMPTY);
                const helpMessage = isError
                    ? IDENTIFY_DISCONNECT_HELP_MESSAGE
                    : IDENTIFY_DISCONNECT_TIMEOUT_HELP_MESSAGE;
                setHelpText({ ...helpMessage, code });
            }
        },
        [isConfirmed, isError, isInProgress, inProgressTimeoutId],
    );

    const onError = useCallback(() => {
        setIsError(true);
    }, []);

    return (
        <WebSocketContainer
            streamName={IDENTIFY_STREAM_NAME}
            socketURL={api.webSockets.identifyStream.renderPath()}
            onMessage={onMessage}
            onDisconnect={onClose}
            onError={onError}
            render={({ connected }, _0, connectSocket) =>
                render({
                    isConfirmed,
                    isConnected: connected,
                    instruction: t(instruction),
                    helpText,
                    onTryAgain: () => connectSocket(),
                })
            }
        />
    );
};

IdentifyDeviceWebSocketContainer.propTypes = {
    render: PropTypes.func.isRequired,
    timeoutHelpMessage: PropTypes.shape({
        info: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.arrayOf(PropTypes.node),
        ]).isRequired,
        level: PropTypes.string.isRequired,
        title: PropTypes.string,
    }).isRequired,

    onSuccess: PropTypes.func.isRequired,
    delayTime: PropTypes.number,
};

IdentifyDeviceWebSocketContainer.defaultProps = {
    delayTime: 7000,
};

export default memo(IdentifyDeviceWebSocketContainer);
