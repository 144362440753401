interface AnyObject {
    [key: string]: unknown;
}

export const extractNestedObject = (obj: AnyObject = {}): AnyObject => {
    if (obj?.toString() !== '[object Object]') {
        throw new Error('Received arg should be an object.');
    }

    return Object.keys(obj).reduce((acc, value) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (obj[value]?.toString() === '[object Object]') {
            return {
                ...acc,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...extractNestedObject(obj[value]),
            };
        }

        return {
            ...acc,
            [value]: obj[value],
        };
    }, {});
};
