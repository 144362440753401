import PropTypes from 'prop-types';
import { useContext, useEffect, memo } from 'react';

import MapContext from './MapContext';

// Interaction - https://openlayers.org/en/latest/apidoc/module-ol_interaction.html

const MapInteraction = ({ Interaction, listeners, options }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) {
            return () => {};
        }

        const interaction = new Interaction(options);

        map.addInteraction(interaction);

        const events = Object.keys(listeners);

        events.forEach((eventName) => {
            interaction.on(eventName, (event) => {
                if (map.ol_uid === event.mapBrowserEvent.map.ol_uid) {
                    listeners[eventName](event);
                }
            });
        });

        return () => {
            map.removeInteraction(interaction);
        };
    }, [map, Interaction, listeners, options]);

    return null;
};

MapInteraction.propTypes = {
    Interaction: PropTypes.func.isRequired,
    listeners: PropTypes.shape({}),
    options: PropTypes.shape({}),
};

MapInteraction.defaultProps = {
    listeners: {},
    options: {},
};

export default memo(MapInteraction);
