import { wrapResponsePromise } from '@infogrid/core-api';
import { useMutation } from 'react-query';

import { cancelFloorsQuery, getFloors, setFloors } from '../accessors';
import { controllers } from '../controllers';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
};

export const useReorderFloor = (buildingId) =>
    useMutation(
        ({ floorId, data }) =>
            wrapResponsePromise(controllers.reorderFloor(floorId, data)),
        {
            async onMutate({ data: { floorDestinationIndex, floorSourceIndex } }) {
                cancelFloorsQuery(buildingId);

                const previousFloors = getFloors(buildingId);

                const newFloors = reorder(
                    previousFloors,
                    floorSourceIndex,
                    floorDestinationIndex,
                );

                setFloors(buildingId, newFloors);

                return { previousFloors, newFloors };
            },
            onError(_0, _1, { previousFloors }) {
                setFloors(buildingId, previousFloors);
            },
        },
    );
