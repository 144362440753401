import { EditableText } from '@infogrid/components-material-ui';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { Typography, Icon, Tooltip } from '@material-ui/core';
import noop from 'lodash/noop';
import type { ReactNode } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Widget } from 'views/dashboards/components/Widget';
import { trackDashboardEvent } from 'views/dashboards/utils/analytics';

import { useGenericTitleStyles, useIconStyles, useTooltipStyles } from './styles';

const TitleInputProps = {
    maxLength: 255,
};

export interface GenericTitleProps {
    helpInfo?: ReactNode;
    onUpdateTitle?: (title: string) => void;
    title: string;
    titleEditable?: boolean;
    titleIcon?: ReactNode;
}

const GenericTitle = ({
    onUpdateTitle = noop,
    title = '',
    titleEditable = true,
    titleIcon,
    helpInfo,
}: GenericTitleProps) => {
    const styles = useGenericTitleStyles();
    const iconClasses = useIconStyles();
    const tooltipClasses = useTooltipStyles();

    const { t } = useTranslation('dashboard');

    return (
        <div className={styles.container}>
            <Widget.Title>
                {titleIcon && <Widget.TitleIcon>{titleIcon}</Widget.TitleIcon>}
                <EditableText
                    editable={titleEditable}
                    onUpdate={(arg: string) => {
                        trackDashboardEvent(USER_ACTIONS.PRESSED, 'edit widget title', {
                            to: arg,
                            from: title,
                        });
                        onUpdateTitle(arg);
                    }}
                    value={title}
                    inputProps={TitleInputProps}
                >
                    <Typography
                        className={styles.titleText}
                        component="span"
                        variant="subtitle1"
                    >
                        {t(title)}
                    </Typography>
                </EditableText>
                {helpInfo && (
                    <Tooltip
                        title={helpInfo}
                        classes={tooltipClasses}
                        enterTouchDelay={50}
                        leaveTouchDelay={3000}
                    >
                        <Icon
                            data-cypress="additional-info-icon"
                            data-testid="additional-info-icon"
                            classes={iconClasses}
                            className="far fa-info-circle"
                            fontSize="small"
                        />
                    </Tooltip>
                )}
            </Widget.Title>
        </div>
    );
};

export default memo(GenericTitle);
