import type { Space } from '@infogrid/locations-types';
import { Icon, Radio, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo, useCallback } from 'react';

import { useSpaceItemStyles } from './styles';

interface Props {
    isLocked?: boolean;
    isOriginSpace?: boolean;
    isSelected: boolean;
    label?: string;
    onSelectSpace: (space: Space | null) => void;
    sensorsCount?: number;
    space?: Space;
}

const SpaceItem = ({
    isLocked = false,
    isOriginSpace = false,
    isSelected,
    label,
    onSelectSpace,
    sensorsCount,
    space,
}: Props) => {
    const styles = useSpaceItemStyles();

    const handleRadioClick = useCallback(() => {
        if (space) {
            onSelectSpace(space);
        } else {
            onSelectSpace(null);
        }
    }, [onSelectSpace, space]);

    return (
        <div className={styles.container} data-cypress="location-item">
            <div className={styles.subcontainer}>
                {!isLocked && !isOriginSpace ? (
                    <Radio
                        checked={isSelected}
                        color="primary"
                        data-cypress="location-radio-button"
                        data-testid="location-radio-button"
                        onChange={handleRadioClick}
                        size="small"
                    />
                ) : (
                    <Icon
                        className={classNames(
                            'fas fa-lock-alt',
                            styles.icon,
                            styles.lockIcon,
                        )}
                    />
                )}
                {space && <Icon className={classNames('far fa-dice-d6', styles.icon)} />}
                <Typography
                    className={styles.label}
                    data-cypress="location-name"
                    variant="body2"
                >
                    {space?.name || label}
                </Typography>
            </div>
            <div className={styles.subcontainer}>
                <Icon className={classNames('far fa-sensor', styles.icon)} />
                <Typography
                    className={classNames(styles.label, styles.sensorsCount)}
                    data-cypress="location-sensors-count"
                    variant="caption"
                >
                    {space?.sensors_count || sensorsCount || 0}
                </Typography>
            </div>
        </div>
    );
};

export default memo(SpaceItem);
