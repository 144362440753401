import type { SensorShape } from '@infogrid/sensors-constants';
import { memo } from 'react';

import { getSensor } from 'apiHooks/sensors/accessors';
import { useActiveSensorContext } from 'utils/contexts';
import { useConnectedIsOpenState } from 'utils/hooks';
import { useIsTempOffsetMissed } from 'utils/hooks/sensors';

import TemperatureOffsetModal from './TemperatureOffsetModal';

const TemperatureOffsetSetup = () => {
    const [isOpen, handleClose] = useConnectedIsOpenState('temperatureOffset');
    const { activeSensor } = useActiveSensorContext();
    const sensor = getSensor(activeSensor) as SensorShape;
    const isTempOffsetModalAvailable = useIsTempOffsetMissed(sensor as SensorShape);

    if (!sensor && !isTempOffsetModalAvailable) {
        return null;
    }

    return (
        <TemperatureOffsetModal
            key={sensor.id} // Use key to ensure form is reset between sensors
            sensor={sensor}
            isOpen={isOpen}
            onCancel={handleClose}
        />
    );
};

export default memo(TemperatureOffsetSetup);
