import type { Floor } from '@infogrid/locations-types';
import { Link as MuiLink } from '@material-ui/core';
import classNames from 'classnames';
import type { MouseEvent } from 'react';
import { useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFloorRowInfoStyles } from './styles';

export interface FloorRowInfoProps {
    className?: string;
    floor: Floor;
    separateHubCount?: boolean;
    showAlerts?: boolean;
    onAlertsClick?: (id: number, e: MouseEvent) => void;
    showCapacity?: boolean;
    showHubCount?: boolean;
    showSensors?: boolean;
}

const FloorRowInfo = ({
    className,
    floor,
    separateHubCount,
    showAlerts,
    onAlertsClick,
    showCapacity,
    showHubCount,
    showSensors,
}: FloorRowInfoProps) => {
    const styles = useFloorRowInfoStyles();
    const { t } = useTranslation('estate');

    const hubCount = floor.hubs_count || 0;
    const sensorCount = floor.sensors_count + (separateHubCount ? 0 : hubCount);

    const alertsCount = useMemo(() => {
        if (!showAlerts) {
            return null;
        }

        if (floor.alerts_count === null) {
            return t('N/A alerts');
        }

        const countContent = t('{{count}} alerts', {
            count: floor.alerts_count,
            defaultValue___one: `${floor.alerts_count} alert`,
            defaultValue___other: `${floor.alerts_count} alerts`,
        });

        if (floor.alerts_count === 0) {
            return countContent;
        }

        const handleClick = (e: MouseEvent) => onAlertsClick?.(floor.id, e);

        return (
            <MuiLink
                component="span"
                color="error"
                underline="always"
                onClick={handleClick}
                data-testid="alert-link-for-floor"
            >
                {countContent}
            </MuiLink>
        );
    }, [floor.alerts_count, floor.id, showAlerts, onAlertsClick, t]);

    return (
        <div className={className}>
            <span data-cypress="spaces-count-for-floor">
                {t('{{count}} spaces', {
                    count: floor.spaces_count,
                    defaultValue___one: `${floor.spaces_count} space`,
                    defaultValue___other: `${floor.spaces_count} spaces`,
                })}
            </span>
            {separateHubCount && showHubCount && (
                <>
                    <span className={styles.infoSeparator}>|</span>
                    <span data-cypress="hubs-count-for-floor">
                        {t('{{count}} hubs', {
                            count: hubCount,
                            defaultValue___one: `${hubCount} hub`,
                            defaultValue___other: `${hubCount} hubs`,
                        })}
                    </span>
                </>
            )}
            {showSensors && (
                <>
                    <span className={styles.infoSeparator}>|</span>
                    <span data-cypress="sensors-count-for-floor">
                        {t('{{count}} sensors', {
                            count: sensorCount,
                            defaultValue___one: `${sensorCount} sensor`,
                            defaultValue___other: `${sensorCount} sensors`,
                        })}
                    </span>
                </>
            )}
            {floor.alerts_count !== undefined && showAlerts && (
                <>
                    <span className={styles.infoSeparator}>|</span>
                    <span
                        className={classNames({
                            [styles.floorInfoAlert]: floor.alerts_count,
                        })}
                        data-cypress="alert-count-for-floor"
                    >
                        {alertsCount}
                    </span>
                </>
            )}
            {showCapacity && (
                <>
                    <span className={styles.infoSeparator}>|</span>
                    <span data-cypress="capacity-for-floor">{`Capacity: ${
                        floor.occupant_capacity || '-'
                    }`}</span>
                </>
            )}
        </div>
    );
};

export default memo(FloorRowInfo);
