import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import type {
    FeedbackPanelTemplateOption,
    SensorShape,
} from '@infogrid/sensors-constants';
import { Box, Button, Typography, Icon } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import SensorBatteryLevel from 'components/material-ui/Sensors/SensorBatteryLevel';
import SensorSignalLevel from 'components/material-ui/Sensors/SignalLevel';
import SensorIcon from 'components/material-ui/Sensors/Type/SensorTypeIcon';
import IdentifySensor from 'components/sensors/IdentifySensor';
import type { IdentifySensorSuccessCallback } from 'components/sensors/IdentifySensor';

import { useFeedbackPanelSensorOptionStyles } from './styles';

interface FeedbackPanelSensorOptionProps {
    sensor: SensorShape;
    isLoading: boolean;
    templateOption: FeedbackPanelTemplateOption;
    canIdentify: boolean;
    isIdentifying: boolean;
    onIdentifyStart: () => void;
    onIdentifySuccess: IdentifySensorSuccessCallback;
}

const FeedbackPanelSensorOption = ({
    sensor,
    isLoading,
    templateOption,
    canIdentify,
    isIdentifying,
    onIdentifyStart,
    onIdentifySuccess,
}: FeedbackPanelSensorOptionProps) => {
    const { t } = useTranslation('sensors');
    const styles = useFeedbackPanelSensorOptionStyles();

    return (
        <div>
            <Box display="flex" justifyContent="center">
                <Typography variant="h6" className={styles.optionTitle}>
                    {templateOption.display}
                </Typography>
            </Box>

            {sensor && (
                <Box display="flex">
                    <SensorIcon sensor={sensor} className={styles.sensorIcon} size={40} />
                    <Box flexGrow={1} display="flex" justifyContent="space-between">
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                        >
                            {sensor.profile.name && (
                                <Typography
                                    variant="subtitle1"
                                    className={styles.sensorName}
                                >
                                    {sensor.profile.name}
                                </Typography>
                            )}
                            <Typography
                                variant="body2"
                                className={styles.sensorDeviceName}
                            >
                                {sensor.device_name}
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <SensorBatteryLevel sensor={sensor} />
                            <SensorSignalLevel
                                sensor={sensor}
                                classes={{
                                    signalLevelContainer: styles.sensorSignalLevel,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
            {isLoading && <LoadingPlaceholder />}
            {!sensor && !isLoading && (
                <Box display="flex">
                    <Icon
                        className={`far fa-question-circle ${styles.sensorIcon}`}
                        fontSize="large"
                    />
                    <div className={styles.identificationContainer}>
                        {isIdentifying ? (
                            <IdentifySensor onSuccess={onIdentifySuccess} />
                        ) : (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={onIdentifyStart}
                                disabled={!canIdentify}
                                data-cypress={`identify-option-${templateOption.id}`}
                            >
                                {t('Identify sensor')}
                            </Button>
                        )}
                    </div>
                </Box>
            )}
        </div>
    );
};

export default memo(FeedbackPanelSensorOption);
