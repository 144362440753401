/* eslint-disable id-denylist */

import { makeStyles } from '@material-ui/core';
import type { CSSProperties } from '@material-ui/styles';

interface QRScannerStyleProps {
    isMobile: boolean;
    hasFooterContent?: boolean;
}

export const useQRCodeScannerStyles = makeStyles((theme) => ({
    root: ({ isMobile }: QRScannerStyleProps) => {
        return {
            position: 'relative',
            width: '100%',
            minWidth: isMobile ? 0 : '748px',
            height: isMobile ? '100%' : '415px',
            backgroundColor: theme.palette.common.gray8,
        };
    },
    header: ({ isMobile, hasFooterContent }: QRScannerStyleProps) => {
        const desktopHeight = hasFooterContent ? '50%' : '100%';

        return {
            position: 'absolute',
            top: 0,
            left: isMobile ? 0 : '50%',
            width: isMobile ? '100%' : '50%',
            height: isMobile ? '25%' : desktopHeight,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: hasFooterContent ? 'flex-end' : 'center',
            padding: theme.spacing(3, 4),
        };
    },
    footer: ({ isMobile }: QRScannerStyleProps) => {
        return {
            position: 'absolute',
            top: isMobile ? '75%' : '50%',
            left: isMobile ? 0 : '50%',
            width: isMobile ? '100%' : '50%',
            height: isMobile ? '25%' : '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: theme.spacing(3),
        };
    },
    text: {
        color: theme.palette.common.white,
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1.5),
        },
    },
    heading: {
        lineHeight: 1.2,
    },
    subtleText: {
        color: theme.palette.common.gray9,
    },
    error: ({ isMobile }: QRScannerStyleProps) => {
        return {
            zIndex: 1,
            position: 'absolute',
            top: isMobile ? `calc(25% + ${theme.spacing(2)}px)` : theme.spacing(2),
            left: theme.spacing(2),
            right: isMobile ? theme.spacing(2) : `calc(50% + ${theme.spacing(2)}px)`,
        };
    },
}));

interface ReaderStyles {
    CONTAINER: CSSProperties;
    VIDEO_CONTAINER: CSSProperties;
    VIDEO: CSSProperties;
}

export const useReaderStyles = ({ isMobile }: QRScannerStyleProps): ReaderStyles => {
    // Use style objects rather than makeStyles because react-qr-reader uses style props
    return {
        CONTAINER: {
            height: '100%',
        },
        VIDEO_CONTAINER: {
            position: 'absolute',
            top: isMobile ? '25%' : 0,
            width: isMobile ? '100%' : '50%',
            height: isMobile ? '50%' : '100%',
            paddingTop: 'auto', // Override react-qr-reader
        },
        VIDEO: {
            objectFit: 'cover',
            transform: isMobile ? '' : 'scaleX(-1)',
        },
    };
};
