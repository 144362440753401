import { makeStyles } from '@material-ui/core';

export const useAutocompleteStyles = makeStyles((theme) => ({
    option: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    optionWrapper: {
        alignItems: 'center',
        display: 'flex',
    },
    optionGroupIcon: {
        color: theme.palette.text.primary,
        marginRight: theme.spacing(1),
    },
}));
