import PropTypes from 'prop-types';

import type { ConfigurationProps } from 'views/dashboards/components/Widget/GenericWidget/types';
import {
    configureWidgetModalProps,
    configureWidgetModalDefaultProps,
} from 'views/dashboards/components/modals/GenericConfigureWidgetModal/constants';

import {
    configurableDefaults,
    fullscreenDefaults,
    refreshableDefaults,
} from './GenericActions/consts';
import type { GenericTitleProps } from './GenericTitle';

export const configurationDefaults: ConfigurationProps = {
    actionsEnabled: true,
    footerEnabled: true,
    isEnabled: true,
};

export const genericWidgetProps = {
    actionsProps: PropTypes.shape({
        configurableProps: PropTypes.shape({
            canUserEdit: PropTypes.bool,
            enabled: PropTypes.bool,
            forceDuplicateEnabled: PropTypes.bool,
            onConfigure: PropTypes.func,
            onDelete: PropTypes.func,
            isConfigureWidgetAvailable: PropTypes.bool,
            isDuplicateDisabled: PropTypes.bool,
            isKioskModeAvailable: PropTypes.bool,
            onOpenKioskMode: PropTypes.func,
        }),
        dashboardId: PropTypes.string,
        fullscreenProps: PropTypes.shape({
            enabled: PropTypes.bool,
            onFullscreenClick: PropTypes.func,
        }),
        refreshableProps: PropTypes.shape({
            enabled: PropTypes.bool,
            isLoading: PropTypes.bool,
            onRefresh: PropTypes.func,
        }),
        widgetConfiguration: PropTypes.shape({}),
        widgetName: PropTypes.string,
        widgetType: PropTypes.string,
    }),
    configuration: PropTypes.shape({
        actionsEnabled: PropTypes.bool,
        footerEnabled: PropTypes.bool,
        isEnabled: PropTypes.bool,
    }),
    configureWidgetModal: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
    containerClassName: PropTypes.string,
    loaderComponent: PropTypes.elementType,
    contentComponent: PropTypes.elementType.isRequired,
    contentClassName: PropTypes.string,
    contentProps: PropTypes.shape({}),
    details: PropTypes.arrayOf(
        PropTypes.shape({
            labelExtractor: PropTypes.func.isRequired,
            /**
             * INFO: ({ widget, widgetData, ...params }) => value
             */
            valueExtractor: PropTypes.func.isRequired,
        }),
    ),
    footerComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
    footerProps: PropTypes.shape({
        className: PropTypes.string,
        periodLabel: PropTypes.string,
        refreshRateLabel: PropTypes.string,
        sensorsLabel: PropTypes.string,
        children: PropTypes.node,
        justifyContent: PropTypes.string,
    }),
    layout: PropTypes.shape({
        i: PropTypes.string,
        x: PropTypes.number,
        y: PropTypes.number,
        w: PropTypes.number,
        h: PropTypes.number,
    }),
    titleProps: PropTypes.shape({
        onUpdateTitle: PropTypes.func,
        title: PropTypes.string,
        titleEditable: PropTypes.bool,
        titleIcon: PropTypes.node,
        helpInfo: PropTypes.node,
    }),
    widget: PropTypes.shape({}).isRequired,
    widgetConfigurationModalProps: PropTypes.shape(configureWidgetModalProps),
    widgetDeletionModalProps: PropTypes.shape({
        dashboardId: PropTypes.string.isRequired,
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        widgetId: PropTypes.number.isRequired,
        widgetName: PropTypes.string.isRequired,
        widgetType: PropTypes.string.isRequired,
    }),
    useIntersectionObserverLoader: PropTypes.bool,
    baseQueryParams: PropTypes.shape({}),
};

export const genericWidgetDefaultProps = {
    actionsProps: {
        configurableProps: configurableDefaults,
        fullscreenProps: fullscreenDefaults,
        refreshableProps: refreshableDefaults,
    },
    configuration: configurationDefaults,
    containerClassName: '',
    contentProps: {},
    details: [],
    footerComponent: null,
    loaderComponent: null,
    footerProps: {},
    titleProps: {
        onUpdateTitle: () => {},
        title: '',
        titleEditable: true,
        titleIcon: null,
        helpInfo: null,
    } as GenericTitleProps,
    widgetConfigurationModalProps: configureWidgetModalDefaultProps,
    contentClassName: '',
    useIntersectionObserverLoader: true,
    baseQueryParams: {},
};
