import { TreeNode } from '@blueprintjs/core';
import { useSelectorWithArgs } from '@infogrid/utils-hooks';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

import SensorTypeIcon from 'components/material-ui/Sensors/Type';
import { selectSensor } from 'schemas/sensor';

const FolderTreeSensor = ({
    sensorId,
    basePath,
    depth,
    nodeClassName,
    selectIsSelected,
    selectSecondaryLabel,
    onNodeClick,
}) => {
    const nodeId = `sensor-${sensorId}`;
    const path = basePath ? `${basePath}/${nodeId}` : `${nodeId}`;
    const sensor = useSelectorWithArgs(selectSensor, sensorId);
    const label = sensor.profile.name || sensor.device_name;
    const isSelected = selectIsSelected ? selectIsSelected(nodeId, sensor) : false;

    const secondaryLabel = selectSecondaryLabel
        ? selectSecondaryLabel(nodeId, sensor)
        : undefined;

    const handleClick = useCallback(() => {
        if (onNodeClick) {
            onNodeClick(nodeId, isSelected, sensor);
        }
    }, [onNodeClick, nodeId, isSelected, sensor]);

    // Note: You can extract this `TreeNode` component to be a prop/make it customizable instead
    return (
        <TreeNode
            id={nodeId}
            key={nodeId}
            depth={depth}
            path={path}
            icon={<SensorTypeIcon sensor={sensor} className="mr-2" size={23} />}
            label={label}
            secondaryLabel={secondaryLabel}
            className={nodeClassName}
            hasCaret={false}
            isExpanded={false}
            isSelected={isSelected}
            onClick={handleClick}
        />
    );
};

FolderTreeSensor.propTypes = {
    sensorId: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    depth: PropTypes.number.isRequired,
    nodeClassName: PropTypes.string,
    selectIsSelected: PropTypes.func,
    selectSecondaryLabel: PropTypes.func,
    onNodeClick: PropTypes.func,
};
FolderTreeSensor.defaultProps = {
    nodeClassName: undefined,
    selectIsSelected: undefined,
    selectSecondaryLabel: undefined,
    onNodeClick: undefined,
};

const FolderTreeSensorMemo = memo(FolderTreeSensor);

FolderTreeSensorMemo.propTypes = FolderTreeSensor.propTypes;
FolderTreeSensorMemo.defaultProps = FolderTreeSensor.defaultProps;

export default FolderTreeSensorMemo;
