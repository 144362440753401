/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

export const DayTimeConfigurationShape = PropTypes.shape({
    weekday: PropTypes.number.isRequired,
    from_hour: PropTypes.string.isRequired,
    to_hour: PropTypes.string.isRequired,
});

export const AlertRecipientShape = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    team: PropTypes.number,
    user: PropTypes.number,
    team_name: PropTypes.string,
    user_name: PropTypes.string,
    user_has_phone_number: PropTypes.bool,
    team_has_phone_number: PropTypes.bool,
    send_emails: PropTypes.bool,
    send_sms: PropTypes.bool,
    custom_email: PropTypes.string,
    custom_phone_number: PropTypes.string,
    is_deleted: PropTypes.bool,
});
