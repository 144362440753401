import { hasValue } from '../is';
import { InvalidResponseCode } from './InvalidResponseCode';

export class RequestValidationError extends InvalidResponseCode {
    constructor(statusCode = null, responseText, statusText, config) {
        super(statusCode, responseText, statusText, 'RequestValidationError');

        this.isValidationError = true;
        this.isInvalidResponseCode = false;
        this.errors = config.parseErrors(responseText, {
            parseErrors: config.parseErrors,
            prepareError: config.prepareError,
        });
    }

    hasError() {
        if (hasValue(this.errors)) {
            return true;
        }

        return false;
    }
}
