import PropTypes from 'prop-types';
import { memo, useMemo, useCallback } from 'react';

import BaseTreeView from 'components/BaseTreeView';

import FoldersSubtree from './FoldersSubtree';
import { useFolderTreeStyles } from './styles';

const FoldersTree = (props) => {
    const { folders, onAfterSelect, renderActions, ...treeProps } = props;

    const folderTreeStyles = useFolderTreeStyles();

    const folderTreeClass = useMemo(
        () => ({
            treeViewRoot: folderTreeStyles.treeViewRoot,
        }),
        [folderTreeStyles.treeViewRoot],
    );

    const renderTreeItem = useCallback(
        (treeItemProps) => (
            <FoldersSubtree
                renderActions={renderActions}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...treeItemProps}
                folderPath={[]}
            />
        ),
        [renderActions],
    );

    return (
        <section className={folderTreeStyles.container}>
            <BaseTreeView
                classes={folderTreeClass}
                data={folders}
                disableDefaultSelection
                TreeItemComponent={renderTreeItem}
                onAfterSelect={onAfterSelect}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...treeProps}
            />
        </section>
    );
};

FoldersTree.propTypes = {
    folders: PropTypes.arrayOf(PropTypes.object),
    onAfterSelect: PropTypes.func.isRequired,
    renderActions: PropTypes.func,
};

FoldersTree.defaultProps = {
    folders: [],
    renderActions: () => null,
};

export default memo(FoldersTree);
