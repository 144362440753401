import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import round from 'lodash/round';
import {
    create,
    evaluateDependencies,
    divideDependencies,
    maxDependencies,
    minDependencies,
    absDependencies,
} from 'mathjs';
import { useQuery } from 'react-query';
import type { UseQueryResult } from 'react-query';

import type {
    RemoteMonitoringErrorBarData,
    RemoteMonitoringErrorBarEquation,
    RemoteMonitoringParams,
} from 'utils/types/ts/charts';

import { controllers } from '../controllers';
import { getRemoteMonitoringErrorBoundsKey } from '../getQueryKeys';

const math = create({
    evaluateDependencies,
    divideDependencies,
    maxDependencies,
    minDependencies,
    absDependencies,
});
const limitedEvaluate = math.evaluate;

math.import(
    {
        import() {
            throw new Error('Function import is disabled');
        },
        createUnit() {
            throw new Error('Function createUnit is disabled');
        },
        parse() {
            throw new Error('Function parse is disabled');
        },
        evaluate() {
            throw new Error('Function evaluate is disabled');
        },
        simplify() {
            throw new Error('Function simplify is disabled');
        },
        derivative() {
            throw new Error('Function derivative is disabled');
        },
    },
    { override: true },
);

export const useRemoteMonitoringCalculateErrorBounds = (): {
    calculateErrorBounds: (
        equations: RemoteMonitoringErrorBarEquation[] | undefined,
        temperature: number,
    ) => RemoteMonitoringErrorBarData;
} => {
    const calculateErrorBounds = (
        equations: RemoteMonitoringErrorBarEquation[] | undefined,
        temperature: number,
    ): RemoteMonitoringErrorBarData => {
        let offset = 0;

        // NOTE: in future may return more than one equation for subtype
        if (equations && equations.length > 0) {
            let equationSubType: string = equations[0].equation;

            equationSubType = equationSubType.replace(
                'temperature',
                temperature.toString(),
            );

            // any errors in evaluation can bubble up
            offset = limitedEvaluate(equationSubType);
        }

        const returnData: RemoteMonitoringErrorBarData = {
            min: round(temperature - Math.abs(offset), 2),
            max: round(temperature + Math.abs(offset), 2),
            offset,
        };

        return returnData;
    };

    return {
        calculateErrorBounds,
    };
};

export const useRemoteMonitoring = <
    TRemoteMonitoringErrorBarEquation = RemoteMonitoringErrorBarEquation[],
>(
    query: RemoteMonitoringParams,
): UseQueryResult<TRemoteMonitoringErrorBarEquation, AxiosParsedError> => {
    return useQuery(
        getRemoteMonitoringErrorBoundsKey(query),
        ({ signal }) =>
            wrapResponsePromise(
                controllers.getRemoteMonitoringErrorBoundsForChart(signal, query),
                ({ data }) => data,
            ),
        {
            keepPreviousData: true,
            retry: false,
            initialData: null,
            enabled: !!query,
        },
    );
};
