import type { MapBrowserEvent } from 'ol';
import { useContext, useEffect, memo, useRef } from 'react';

import type { FeatureId } from '..';
import { isDuplicateFeatureFromModify } from '../../utils';
import type { MapContext } from '../MapContext';
import Context from '../MapContext';

interface Props {
    changeActiveFeature: (id: FeatureId) => void;
}

const HoverInteraction = ({ changeActiveFeature }: Props) => {
    const { map } = useContext<MapContext>(Context);

    const selectedFeatureId = useRef<FeatureId>();

    useEffect(() => {
        if (!map) {
            return () => {};
        }

        const onPointerMove = (event: MapBrowserEvent<UIEvent>) => {
            const activeFeature = map.forEachFeatureAtPixel(event.pixel, (feature) => {
                if (isDuplicateFeatureFromModify(feature)) {
                    return;
                }

                const featureId = feature.getId();

                if (selectedFeatureId.current !== featureId) {
                    changeActiveFeature(featureId);

                    selectedFeatureId.current = featureId;
                }

                return feature;
            });

            if (!activeFeature && selectedFeatureId.current) {
                selectedFeatureId.current = undefined;
                changeActiveFeature(undefined);
            }
        };

        map.on('pointermove', onPointerMove);

        return () => {
            map.un('pointermove', onPointerMove);
        };
    }, [map, changeActiveFeature]);

    return null;
};

export default memo(HoverInteraction);
