import TreeItem from '@material-ui/lab/TreeItem';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { useTreeViewItemStyles } from './styles';

const TreeViewItem = (props) => {
    const { classes, children, nodeId, LabelComponent, ...nativeProps } = props;
    const treeViewItemStyles = useTreeViewItemStyles();

    const treeViewItemClasses = classNames(
        treeViewItemStyles.treeViewItemRoot,
        classes.treeViewItemRoot,
    );

    return (
        <TreeItem
            className={treeViewItemClasses}
            key={nodeId}
            label={LabelComponent}
            nodeId={nodeId}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...nativeProps}
        >
            {children}
        </TreeItem>
    );
};

TreeViewItem.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.shape({
        treeViewItemRoot: PropTypes.string,
    }),
    nodeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    LabelComponent: PropTypes.node.isRequired,
};

TreeViewItem.defaultProps = {
    children: null,
    classes: {
        treeViewItemRoot: '',
    },
};

export default memo(TreeViewItem);
