import { useIsMobile } from '@infogrid/utils-hooks';
import { Typography } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import type * as React from 'react';

import ChecklistWithAllSelect from 'components/ChecklistWithAllSelect';

import { useSelectStyles } from './styles';

const CHECKLIST_ITEMS_LIST_HEIGHT = 300;

interface BaseItem {
    id: number;
    name: string;
}

interface Props<T> {
    canUserEdit: boolean;
    items: T[];
    onClose: (ids: number[]) => void;
    listHeight?: number;
    allSelectedLabel: string;
    renderValue: (items: T[], selectedItemsIds: number[]) => React.ReactNode;
    selectedIds: number[];
}

const keyExtractor = (x: BaseItem) => x.id;
const valueExtractor = (x: BaseItem) => x.name;

const Select = <T extends BaseItem>({
    items,
    onClose,
    canUserEdit,
    listHeight = CHECKLIST_ITEMS_LIST_HEIGHT,
    allSelectedLabel,
    selectedIds,
    renderValue,
}: Props<T>) => {
    const styles = useSelectStyles();

    const isMobile = useIsMobile();

    const [checkedItems, setCheckedItems] = useState<number[]>(selectedIds);

    useEffect(() => {
        setCheckedItems(selectedIds);
    }, [selectedIds]);

    const toggleOptionCheck = useCallback(
        (id) => {
            if (checkedItems.includes(id)) {
                setCheckedItems(checkedItems.filter((itemId) => itemId !== id));
            } else {
                setCheckedItems([...checkedItems, id]);
            }
        },
        [checkedItems],
    );

    const toggleAllCheck = useCallback(() => {
        if (checkedItems.length === items.length) {
            setCheckedItems([]);
        } else {
            setCheckedItems(items.map((item) => item.id));
        }
    }, [items, checkedItems]);

    const handleSelectClose = useCallback(() => {
        const areItemsEqual = isEqual(checkedItems.sort(), selectedIds.sort());

        if (!areItemsEqual) {
            onClose(checkedItems);
        }
    }, [checkedItems, onClose, selectedIds]);

    const renderSelectTitle = useCallback(
        () => (
            <Typography className={styles.renderValue} component="span" variant="body2">
                {renderValue(items, checkedItems)}
            </Typography>
        ),
        [checkedItems, items, renderValue, styles.renderValue],
    );

    const checklistClasses = useMemo(
        () => ({ container: styles.checklist }),
        [styles.checklist],
    );

    return (
        <ChecklistWithAllSelect
            allItemsCheckedText={allSelectedLabel}
            checkAllHandler={toggleAllCheck}
            checkHandler={toggleOptionCheck}
            checkedItemsKeys={checkedItems}
            classes={checklistClasses}
            disabled={isMobile || items.length === 0}
            selectionDisabled={!canUserEdit}
            items={items}
            keyExtractor={keyExtractor}
            maxHeight={listHeight}
            onClose={handleSelectClose}
            renderValue={renderSelectTitle}
            valueExtractor={valueExtractor}
            renderInPortal
        />
    );
};

const SelectComponent = memo(Select) as typeof Select;

export default SelectComponent;
