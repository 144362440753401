/*
 * These temp types should be removed once we add API types
 * or some will be moved/combined as the feature takes shape
 */

import type {
    SmartCleaningHierarchyNode,
    SmartCleaningHierarchyResponse,
} from '../../../apiHooks/types';
import type { PaginatedTreeCurrentNode } from '../../../components/PaginatedTreeSelector/types';
import type {
    FlattenedNode,
    FlattenedNodes,
    NodeSelectionStruct,
    SelectionStateId,
    SmartCleaningActivatedCount,
    SmartCleaningDescendantCount,
} from '../../../types';
import { SELECTION_STATES } from '../../../types';
import type { ManageRulesChildNode } from '../CleaningRules/types';
import type {
    ResetTimesChildNode,
    ResetTimesFlattenedNode,
    ResetTimesLocations,
} from '../ResetTime/types';

/*
 * end of temp types
 */

export interface LocationsCount {
    floors: number;
    buildings: number;
    spaces?: number;
}

export interface LocationsCount {
    floors: number;
    buildings: number;
}

interface UpdateNodeSelectionStateParams<FlattenedNodeType extends FlattenedNode> {
    selectedNodeIds: number[];
    currentlySelectedNodes: NodeSelectionStruct;
    newSelectedState: SelectionStateId;
    knownNodes: FlattenedNodes<FlattenedNodeType>;
}

const updateNodeSelectionStateOnSelect = <FlattenedNodeType extends FlattenedNode>({
    selectedNodeIds,
    currentlySelectedNodes,
    newSelectedState,
    knownNodes,
}: UpdateNodeSelectionStateParams<FlattenedNodeType>): NodeSelectionStruct => {
    // create a copy of the list of selected nodes
    let newSelectedNodes = { ...currentlySelectedNodes };

    // for each selected node, update the selection state and check the parent state
    selectedNodeIds.forEach((selectedNodeId: number) => {
        // get the node thats been clicked on
        const oldNode = knownNodes[selectedNodeId] || {};

        // create new version of the tree
        const newSelectedNodesForThisId: NodeSelectionStruct = {
            ...newSelectedNodes,
            [selectedNodeId]: newSelectedState,
        };

        // deal with any children
        const recursiveSetChildrenState = (id: number, state: SelectionStateId) => {
            const childNode = knownNodes[id];
            if (!childNode) {
                return;
            }

            newSelectedNodesForThisId[id] = state;
            childNode.children.forEach((childId) => {
                recursiveSetChildrenState(childId, state);
            });
        };

        if (oldNode.children) {
            oldNode.children.forEach((childId) => {
                recursiveSetChildrenState(childId, newSelectedState);
            });
        }

        // deal with parent/grandparents
        const recursiveSetParentState = (id: number | null) => {
            if (id === null) {
                return;
            }

            // get the node
            const node = knownNodes[id];

            // determine if all the sibling nodes have been selected
            let selectedStateOfAllChildren: SelectionStateId = SELECTION_STATES.SOME;

            const selectedChildren = node.children.filter((child) => {
                return newSelectedNodesForThisId[child];
            });

            if (
                node.children.filter(
                    (childId: number) =>
                        newSelectedNodesForThisId[childId] === SELECTION_STATES.ALL,
                ).length === node.children.length
            ) {
                selectedStateOfAllChildren = SELECTION_STATES.ALL;
            }

            // use selected children here, as we assume that if a node isn't in `selectedNodes`
            // it'll have a state of 'none'
            if (
                selectedChildren.filter(
                    (childId: number) =>
                        newSelectedNodesForThisId[childId] === SELECTION_STATES.NONE,
                ).length === selectedChildren.length
            ) {
                selectedStateOfAllChildren = SELECTION_STATES.NONE;
            }

            newSelectedNodesForThisId[id] = selectedStateOfAllChildren;

            if (node.parent !== null) {
                recursiveSetParentState(node.parent);
            }
        };
        recursiveSetParentState(oldNode.parent);
        newSelectedNodes = newSelectedNodesForThisId;
    });

    return newSelectedNodes;
};

interface UpdateNodeSelectionStateOnPageParams<FlattenedNodeType extends FlattenedNode> {
    newKnownNodes: FlattenedNodes<FlattenedNodeType>;
    currentlySelectedNodes: NodeSelectionStruct;
}

const updateNodeSelectionStateOnPage = <FlattenedNodeType extends FlattenedNode>({
    newKnownNodes,
    currentlySelectedNodes,
}: UpdateNodeSelectionStateOnPageParams<FlattenedNodeType>): NodeSelectionStruct => {
    const newSelectedNodes = {
        ...currentlySelectedNodes,
    };

    const currentNode = newKnownNodes[newKnownNodes.current.id];

    const nodeSelectionState = currentlySelectedNodes[currentNode.id];

    // create the child nodes, with empty arrays of children that may be populated later
    currentNode.children.forEach((childId) => {
        if (
            nodeSelectionState === SELECTION_STATES.ALL ||
            nodeSelectionState === SELECTION_STATES.NONE
        ) {
            newSelectedNodes[childId] = nodeSelectionState;
        }
    });

    return newSelectedNodes;
};

interface FlattenResponseLocationsProps {
    response: SmartCleaningHierarchyResponse;
    currentFlattenedLocations: FlattenedNodes<ResetTimesFlattenedNode>;
}

export const nodeHasDescendants = (node: SmartCleaningHierarchyNode) => {
    return (
        node.descendants?.length > 0 &&
        node.descendants?.filter((type) => type.count > 0).length > 0
    );
};

// TODO: move this to utils and test, maybe make it more generic so it can be shared
const flattenResponseLocations = ({
    response,
    currentFlattenedLocations,
}: FlattenResponseLocationsProps): FlattenedNodes<ResetTimesFlattenedNode> => {
    const currentNode = {
        id: response.current.id,
        name: response.current.name,
        type: response.current.floorplan_type || null,
        parent: response.parent?.id || null,
        children: response.folders.map((folder) => folder.id),
        resetTime: response.current.reset_time,
        hasChildren: response.folders.length > 0,
        descendants: {
            // TODO: ask BE to add descendants to current
            floors:
                response.current.descendants?.find(
                    (dsc) => dsc.floorplan_type === 'floor',
                )?.count || 0,
            buildings:
                response.current.descendants?.find(
                    (dsc) => dsc.floorplan_type === 'building',
                )?.count || 0,
        },
    };

    return {
        ...currentFlattenedLocations,
        current: currentNode, // store this for easy access
        [response.current.id]: currentNode,
        ...response.folders.reduce(
            (a, folder) => ({
                ...a,
                [folder.id]: {
                    id: folder.id,
                    name: folder.name,
                    type: folder.floorplan_type || null,
                    parent: response.current.id,
                    children: currentFlattenedLocations[folder.id]?.children || [],
                    resetTime: folder.reset_time,
                    hasChildren: nodeHasDescendants(folder),
                    descendants: {
                        floors:
                            folder.descendants.find(
                                (dsc) => dsc.floorplan_type === 'floor',
                            )?.count || 0,
                        buildings:
                            folder.descendants.find(
                                (dsc) => dsc.floorplan_type === 'building',
                            )?.count || 0,
                    },
                },
            }),
            {},
        ),
    };
};

interface GetCurrentNodeProps {
    flattenedLocations: FlattenedNodes<ResetTimesFlattenedNode>;
    selections: NodeSelectionStruct;
}

const getSelected = (selectedState: SelectionStateId): boolean | 'indeterminate' => {
    if (!selectedState) {
        return false;
    }

    if (selectedState === SELECTION_STATES.SOME) {
        return 'indeterminate';
    }

    return selectedState === SELECTION_STATES.ALL;
};

const getCurrentNodeTree = ({
    flattenedLocations,
    selections,
}: GetCurrentNodeProps): PaginatedTreeCurrentNode<ResetTimesChildNode> => {
    const currentLocation = flattenedLocations.current;
    const children = currentLocation.children.map((id) => ({
        id: flattenedLocations[id].id,
        name: flattenedLocations[id].name,
        type: flattenedLocations[id].type,
        hasChildren: flattenedLocations[id].hasChildren,
        selected: getSelected(selections[id]),
        resetTime: flattenedLocations[id].resetTime,
    }));
    const parent = currentLocation.parent && {
        id: flattenedLocations[currentLocation.parent].id,
        name: flattenedLocations[currentLocation.parent].name,
        type: flattenedLocations[currentLocation.parent].type,
        selected: getSelected(selections[currentLocation.parent]),
    };

    return {
        id: currentLocation.id,
        name: currentLocation.name,
        type: currentLocation.type,
        selected: getSelected(selections[currentLocation.id]),
        ...(parent && { parent }),
        children,
    };
};

const getCurrentNodeTreeLocations = ({
    flattenedLocations,
    selections,
}: GetCurrentNodeProps): PaginatedTreeCurrentNode<ResetTimesChildNode> => {
    const currentLocation = flattenedLocations.current;
    const children = currentLocation.children.map((id) => ({
        id: flattenedLocations[id].id,
        name: flattenedLocations[id].name,
        type: flattenedLocations[id].type,
        hasChildren: flattenedLocations[id].hasChildren,
        selected: getSelected(selections[id]),
        resetTime: flattenedLocations[id].resetTime,
    }));

    const parent = currentLocation.parent && {
        id: flattenedLocations[currentLocation.parent].id,
        name: flattenedLocations[currentLocation.parent].name,
        type: flattenedLocations[currentLocation.parent].type,
        selected: getSelected(selections[currentLocation.parent]),
    };

    return {
        id: currentLocation.id,
        name: currentLocation.name,
        type: currentLocation.type,
        selected: getSelected(selections[currentLocation.id]),
        ...(parent && { parent }),
        children,
    };
};

/*
 * Gets the building or floor label for the selection
 */
const getOptionLabelFromType = (
    currentNode: PaginatedTreeCurrentNode<ManageRulesChildNode> | null,
): string => {
    const firstChild = currentNode?.children[0];

    return firstChild?.type ? firstChild.type : 'Option';
};

interface UpdateLocationsCountParams {
    selectedNodes: NodeSelectionStruct;
    knownNodes: FlattenedNodes<ResetTimesFlattenedNode>;
}

/*
 * Calculates how many buildings and floors are selected from a node down
 * TODO: move this to the shared selection utils, which will involve creating a type which
 *       encompasses the ResetTimesLocations type
 */
const updateLocationsCount = ({
    selectedNodes,
    knownNodes,
}: UpdateLocationsCountParams): SmartCleaningDescendantCount =>
    Object.keys(selectedNodes).reduce(
        (newCount: SmartCleaningDescendantCount, nodeId: string) => {
            const thisNode = knownNodes[nodeId];

            if (
                // if no parent and selected
                (thisNode.parent === null &&
                    selectedNodes[thisNode.id] === SELECTION_STATES.ALL) ||
                // or the parent is not selected and this node is
                (typeof thisNode.parent === 'number' &&
                    selectedNodes[thisNode.parent] !== SELECTION_STATES.ALL &&
                    selectedNodes[thisNode.id] === SELECTION_STATES.ALL)
            ) {
                // add the descendants to the count
                return {
                    floors:
                        newCount.floors +
                        thisNode.descendants.floors +
                        (thisNode.type === 'floor' ? 1 : 0),
                    buildings:
                        newCount.buildings +
                        thisNode.descendants.buildings +
                        (thisNode.type === 'building' ? 1 : 0),
                };
            }

            // if it's set to `some`, we don't know what's been added from it's children, but it still counts.
            // e.g. if a floor has five spaces and one space is selected, we want to read `1 space in 1 floor`.
            if (selectedNodes[thisNode.id] === SELECTION_STATES.SOME) {
                if (!thisNode.type) {
                    return newCount;
                }

                const out = { ...newCount };
                switch (thisNode.type) {
                    case 'floor':
                        out.floors += 1;
                        break;
                    case 'building':
                        out.buildings += 1;
                        break;
                    default:
                        // do nothing
                        break;
                }

                return out;
            }

            // if the parent is selected, skip this node otherwise we get duplicate counts
            return newCount;
        },
        { floors: 0, buildings: 0 },
    );

interface UpdateLocationsSpacesCountParams {
    selectedNodes: NodeSelectionStruct;
    knownNodes: ResetTimesLocations;
    previousCount: SmartCleaningActivatedCount;
}

/*
 * Not used yet
  TODO: we need to merge this and the above together
 */
const updateLocationsCountWithSpaces = ({
    selectedNodes,
    knownNodes,
    previousCount,
}: UpdateLocationsSpacesCountParams): SmartCleaningActivatedCount => {
    return Object.keys(selectedNodes).reduce(
        (newCount: SmartCleaningActivatedCount, nodeId: string) => {
            const thisNode = knownNodes[nodeId];

            if (!thisNode) {
                return newCount;
            }

            // if the parent is not selected and this node is, add the descendants to the count
            if (
                typeof thisNode.parent === 'number' &&
                selectedNodes[thisNode.parent] !== SELECTION_STATES.ALL &&
                selectedNodes[thisNode.id] === SELECTION_STATES.ALL
            ) {
                return {
                    spaces:
                        newCount.spaces &&
                        thisNode.descendants.spaces &&
                        newCount.spaces +
                            thisNode.descendants.spaces +
                            (thisNode.type === 'space' ? 1 : 0),
                    floors:
                        newCount.floors +
                        thisNode.descendants.floors +
                        (thisNode.type === 'floor' ? 1 : 0),
                    buildings:
                        newCount.buildings +
                        thisNode.descendants.buildings +
                        (thisNode.type === 'building' ? 1 : 0),
                };
            }

            // if the parent is selected, skip this node otherwise we get duplicate counts
            return newCount;
        },
        previousCount,
    );
};

export {
    updateNodeSelectionStateOnSelect,
    updateNodeSelectionStateOnPage,
    flattenResponseLocations,
    getSelected,
    getCurrentNodeTree,
    getCurrentNodeTreeLocations,
    getOptionLabelFromType,
    updateLocationsCount,
    updateLocationsCountWithSpaces,
};
