import { queryClient } from '@infogrid/core-api';

import { getSmartCleaningHierarchyLocationsKey } from 'views/solutionSettings/apiHooks/getQueryKeys';
import type {
    SmartCleaningActivatedCountResponse,
    SmartCleaningHierarchyCount,
    StandardSmartCleaningHierarchyResponse,
} from 'views/solutionSettings/apiHooks/types';
import type {
    PaginatedTreeCurrentNode,
    PaginatedTreeChildNode,
} from 'views/solutionSettings/components/PaginatedTreeSelector/types';
import type {
    ActivatedDeactivedNodes,
    AddNewSelectedDeselectedNode,
    ChangedLocationsCount,
} from 'views/solutionSettings/types';

import { nodeHasDescendants } from '../utils/selectionUtils';

// Mutates set passed in
const findAndRemoveChildActivatedDeactivatedNodes = (
    nodeId: number,
    activatedDeactivedNodes: ActivatedDeactivedNodes,
) => {
    if (
        activatedDeactivedNodes.deactivated.has(nodeId) ||
        activatedDeactivedNodes.activated.has(nodeId)
    ) {
        // Its safe to delete both even if one doesn't have the id
        activatedDeactivedNodes.deactivated.delete(nodeId);
        activatedDeactivedNodes.activated.delete(nodeId);
    }
};

export const updateAllReleventChildren = (
    // Current location id
    nodeId: number,
    selected: boolean,
    activatedDeactivedNodes: ActivatedDeactivedNodes,
) => {
    const selectedChildQueryKey = getSmartCleaningHierarchyLocationsKey({
        folder_id: nodeId,
        show_activated_only: false,
    });

    const childCacheData =
        queryClient.getQueryData<PaginatedTreeCurrentNode<PaginatedTreeChildNode>>(
            selectedChildQueryKey,
        );

    // Check if child has been cached
    if (childCacheData) {
        return queryClient.setQueryData(
            selectedChildQueryKey,
            // @ts-expect-error can't be undefined as I'm checking
            // the data exists above
            (
                oldData: PaginatedTreeCurrentNode<PaginatedTreeChildNode>,
            ): PaginatedTreeCurrentNode<PaginatedTreeChildNode> => {
                const flattenedDataWithChildrenSelectedUpdated = {
                    ...oldData,
                    children: oldData.children.map((child) => {
                        // Directly update if children are spaces
                        if (child.type === 'space') {
                            // Remove any activated / deactivated children nodes if parent is activated / deactivated
                            findAndRemoveChildActivatedDeactivatedNodes(
                                child.id,
                                activatedDeactivedNodes,
                            );

                            return { ...child, selected };
                        }
                        // If building or floor and has spaces we need to update
                        // the spaces in cache also
                        else if (
                            (child.type === 'floor' || child.type === 'building') &&
                            child.hasChildren
                        ) {
                            findAndRemoveChildActivatedDeactivatedNodes(
                                child.id,
                                activatedDeactivedNodes,
                            );

                            const selectedFloorQueryKey =
                                getSmartCleaningHierarchyLocationsKey({
                                    folder_id: child.id,
                                    show_activated_only: false,
                                });
                            const previouslyCachedData =
                                queryClient.getQueryData(selectedFloorQueryKey);

                            if (previouslyCachedData) {
                                queryClient.setQueryData(
                                    selectedFloorQueryKey,
                                    // @ts-expect-error can't be undefined as I'm checking
                                    // the data exists above
                                    (
                                        cachedData: PaginatedTreeCurrentNode<PaginatedTreeChildNode>,
                                    ): PaginatedTreeCurrentNode<PaginatedTreeChildNode> => {
                                        return {
                                            ...cachedData,
                                            children: cachedData.children.map(
                                                (floorsChildNode) => {
                                                    // This step should be if we are on org level and change a
                                                    // building
                                                    // Remove any activated / deactivated childrens children nodes
                                                    //  if parent is activated / deactivated
                                                    findAndRemoveChildActivatedDeactivatedNodes(
                                                        floorsChildNode.id,
                                                        activatedDeactivedNodes,
                                                    );

                                                    if (
                                                        floorsChildNode.type ===
                                                            'space' &&
                                                        floorsChildNode.selected !==
                                                            selected
                                                    ) {
                                                        return {
                                                            ...floorsChildNode,
                                                            selected,
                                                        };
                                                    }

                                                    return floorsChildNode;
                                                },
                                            ),
                                        };
                                    },
                                );
                            }

                            return { ...child, selected };
                        }

                        return child;
                    }),
                };

                return flattenedDataWithChildrenSelectedUpdated;
            },
        );
    }

    return null;
};

const determineSelected = (
    areAllSelected: boolean,
    someSelected: boolean,
): boolean | 'indeterminate' => {
    const isIndeterminate = someSelected ? 'indeterminate' : false;
    return areAllSelected ? true : isIndeterminate;
};

export const updateParentSelectedCacheValue = (
    parentId: number,
    updatedNodeId: number,
    areAllSelected: boolean,
    someSelected: boolean,
) => {
    const parentQueryKey = getSmartCleaningHierarchyLocationsKey({
        folder_id: parentId,
        show_activated_only: false,
    });

    return queryClient.setQueryData(
        parentQueryKey,
        //@ts-expect-error https://github.com/TanStack/query/issues/506
        //  Looks like this can be fixed with RQ V4
        (
            oldData: PaginatedTreeCurrentNode<PaginatedTreeChildNode> | undefined,
        ): PaginatedTreeCurrentNode<PaginatedTreeChildNode> | void => {
            if (oldData) {
                const updatedParentsSelected: PaginatedTreeCurrentNode<PaginatedTreeChildNode> =
                    {
                        ...oldData,
                        selected: determineSelected(areAllSelected, someSelected),
                        children: oldData.children.map((child) => {
                            if (child.id === updatedNodeId) {
                                return {
                                    ...child,
                                    // allSelected true takes priority, and then isSomeSelected
                                    // for indeterminate and then false
                                    selected: determineSelected(
                                        areAllSelected,
                                        someSelected,
                                    ),
                                };
                            }

                            return child;
                        }),
                    };

                return updatedParentsSelected;
            }
        },
    );
};

export const areAllSelected = (nodes: PaginatedTreeChildNode[]) =>
    // If we find any not selected it means all are not selected
    nodes.find((nodeInView) => {
        // Make sure floor has spaces
        if (nodeInView.type === 'floor' && nodeInView.hasChildren === true) {
            return (
                nodeInView.selected === false || nodeInView.selected === 'indeterminate'
            );
        } else if (nodeInView.type === 'space') {
            return (
                nodeInView.selected === false || nodeInView.selected === 'indeterminate'
            );
        }

        return false;
    }) === undefined;

export const areSomeSelected = (nodes: PaginatedTreeChildNode[]) =>
    nodes.find((nodeInView) => {
        if (nodeInView.type === 'floor' && nodeInView.hasChildren === true) {
            return (
                nodeInView.selected === true || nodeInView.selected === 'indeterminate'
            );
        } else if (nodeInView.type === 'space') {
            return (
                nodeInView.selected === true || nodeInView.selected === 'indeterminate'
            );
        }

        return false;
    }) !== undefined;

// Write test for this which updates parent and children nodes at once
export const changeIsSelected = (
    flattenedLocations: PaginatedTreeCurrentNode<PaginatedTreeChildNode>,
    nodeIds: number[],
    selected: boolean,
    activatedDeactivedNodes: ActivatedDeactivedNodes,
) => {
    // This needs to be done to avoid any references to the previous object
    // so when we change the selected value it doesn't mutate the object stored in state
    const currentLocation: PaginatedTreeCurrentNode<PaginatedTreeChildNode> = JSON.parse(
        JSON.stringify(flattenedLocations),
    );
    const currentSelectedNodes = {
        activated: new Set(activatedDeactivedNodes.activated),
        deactivated: new Set(activatedDeactivedNodes.deactivated),
    };
    const nodesInView = currentLocation.children;

    const queryKey = getSmartCleaningHierarchyLocationsKey({
        folder_id: currentLocation.id,
        show_activated_only: false,
    });

    let allAreSelected = areAllSelected(nodesInView);
    let someSelected = areSomeSelected(nodesInView);

    nodeIds.forEach((id) => {
        const node = nodesInView.find((changedNode) => changedNode.id === id);

        if (node?.selected !== undefined && node.selected !== selected) {
            node.selected = selected;

            allAreSelected = areAllSelected(nodesInView);
            someSelected = areSomeSelected(nodesInView);

            // Update direct parent up the tree (Viewing a floors spaces this will be the Building
            // or a buildings floors this will be the Org)
            if (currentLocation.parent) {
                const parentCacheUpdateAndReturnedParentLocation =
                    updateParentSelectedCacheValue(
                        currentLocation.parent.id,
                        currentLocation.id,
                        allAreSelected,
                        someSelected,
                    );

                if (
                    !allAreSelected &&
                    (currentSelectedNodes.activated.has(currentLocation.id) ||
                        currentSelectedNodes.activated.has(currentLocation.parent.id) ||
                        currentSelectedNodes.deactivated.has(currentLocation.id) ||
                        currentSelectedNodes.deactivated.has(currentLocation.parent.id))
                ) {
                    currentSelectedNodes.activated.delete(currentLocation.id);
                    nodesInView.forEach((nodeInView) => {
                        if (nodeInView.selected === true) {
                            if (currentLocation.parent) {
                                // if this node has a parent and we were previously deactivating it
                                if (
                                    currentSelectedNodes.deactivated.has(
                                        currentLocation.parent.id,
                                    )
                                ) {
                                    // the siblings still need to be deactivated so they need deactivated separately
                                    nodesInView.forEach((n) => {
                                        // add the sibling to deactivated list, if it isnt selected
                                        if (!n.selected) {
                                            currentSelectedNodes.deactivated.add(n.id);
                                        }
                                    });
                                } else {
                                    // if the parent is not in the deactivated list, proceed as normal
                                    currentSelectedNodes.activated.add(nodeInView.id);
                                    currentSelectedNodes.deactivated.delete(
                                        currentLocation.id,
                                    );
                                }

                                // remove the parent from the deactivated list
                                currentSelectedNodes.deactivated.delete(
                                    currentLocation.parent.id,
                                );
                            } else {
                                // if the node does not have a parent in the deactivated list, proceed as normal
                                currentSelectedNodes.activated.add(nodeInView.id);
                                currentSelectedNodes.deactivated.delete(
                                    currentLocation.id,
                                );
                            }
                        }
                    });
                }

                // Update parents parent (Viewing a floors spaces it will be the Org)
                if (parentCacheUpdateAndReturnedParentLocation?.parent) {
                    const areAllFloorsSelected = areAllSelected(
                        parentCacheUpdateAndReturnedParentLocation.children,
                    );

                    const areSomeFloorsSelected = areSomeSelected(
                        parentCacheUpdateAndReturnedParentLocation.children,
                    );

                    updateParentSelectedCacheValue(
                        parentCacheUpdateAndReturnedParentLocation.parent.id,
                        currentLocation.parent.id,
                        areAllFloorsSelected,
                        areSomeFloorsSelected,
                    );

                    if (
                        !areAllFloorsSelected &&
                        currentSelectedNodes.activated.has(currentLocation.parent.id)
                    ) {
                        currentSelectedNodes.activated.delete(currentLocation.parent.id);
                        parentCacheUpdateAndReturnedParentLocation.children.forEach(
                            (nodeInView) => {
                                if (
                                    nodeInView.selected === true &&
                                    nodeInView.type === 'floor' &&
                                    nodeInView.hasChildren === true
                                ) {
                                    currentSelectedNodes.activated.add(nodeInView.id);
                                }
                            },
                        );
                    }
                }
            }

            // Update child nodes
            if (currentLocation.type === 'building' || currentLocation.type === null) {
                updateAllReleventChildren(id, selected, currentSelectedNodes);
            }
        }
    });

    // Update the current view cache to align with update
    queryClient.setQueryData(queryKey, () => currentLocation);

    // TODO: Potentially optimize by setting all queires at end?
    // Add everything to an object with parent and children and then run through and update
    // queryClient.setQueriesData('smart-cleaning-hierarchy', (oldData) => {
    //     console.log(oldData);

    //     return oldData;
    // });

    return { currentLocation, currentSelectedNodes };
};

export const checkIfSelected = (
    activatedSpaceCount: number,
    descendants: SmartCleaningHierarchyCount[],
    selectAll?: boolean,
    deselectAll?: boolean,
) => {
    if (selectAll) {
        return true;
    }

    if (deselectAll) {
        return false;
    }

    const numberOfSpaces =
        descendants.find((hierarchyCount) => hierarchyCount.floorplan_type === 'space')
            ?.count ?? 0;

    if (activatedSpaceCount > 0 && descendants.length === 0) {
        return true;
    } else if (activatedSpaceCount > 0 && numberOfSpaces === activatedSpaceCount) {
        return true;
    } else if (activatedSpaceCount > 0 && numberOfSpaces > activatedSpaceCount) {
        return 'indeterminate';
    }

    return false;
};

// If the child view hasn't been loaded the selection logic below applies after the data has been fetched from
// the api within the controller `getSmartCleaningHierarchy` however if the view has been loaded then we update
// the cache selection elsewhere.
// This removes all buildings / floors without spaces

// If the view has been cached then `changeIsSelected` handles the updates to parent / child
// by using the updateParentSelectedCacheValue and updateAllReleventChildren functions
export const flattenToPaginatedTree = (
    response: StandardSmartCleaningHierarchyResponse,
    activatedDeactivedNodes: ActivatedDeactivedNodes = {
        activated: new Set(),
        deactivated: new Set(),
    },
): PaginatedTreeCurrentNode<PaginatedTreeChildNode> => {
    // If parent is within the selected / deselected set the children should also be the same state
    // Org or Building or Floor
    const parentIsSelected = activatedDeactivedNodes.activated.has(response.current.id);
    // Org or Building
    const parentsParentIsSelected = Boolean(
        response.parent && activatedDeactivedNodes.activated.has(response.parent.id),
    );
    const parentIsDeselected = activatedDeactivedNodes.deactivated.has(
        response.current.id,
    );
    const parentsParentIsDeselected = Boolean(
        response.parent && activatedDeactivedNodes.deactivated.has(response.parent.id),
    );

    const children: PaginatedTreeCurrentNode<PaginatedTreeChildNode>['children'] =
        response.folders.flatMap((child) => {
            const childHasSpaces =
                child.descendants.find(
                    ({ floorplan_type }) => floorplan_type === 'space',
                ) !== undefined;

            if (
                (child.floorplan_type === 'building' ||
                    child.floorplan_type === 'floor') &&
                !childHasSpaces
            ) {
                return [];
            }

            return {
                id: child.id,
                name: child.name,
                type: child.floorplan_type || null,
                hasChildren: nodeHasDescendants(child),
                selected: checkIfSelected(
                    child.activated_space_count,
                    child.descendants,
                    parentIsSelected || parentsParentIsSelected,
                    parentIsDeselected || parentsParentIsDeselected,
                ),
            };
        });

    const parent = response.parent && {
        id: response.parent.id,
        name: response.parent.name,
        type: response.parent.floorplan_type || null,
        // This selected field is not needed here as it does nothing but kept in for types
        selected: false,
    };

    return {
        id: response.current.id,
        name: response.current.name,
        type: response.current.floorplan_type || null,
        selected: false,
        ...(parent && { parent }),
        children,
    };
};

export const addNewSelectedOrDeselectedNode = ({
    selected,
    nodeIds: [selectedNodeId, ...rest],
    activatedDeactivedNodes,
}: AddNewSelectedDeselectedNode) => {
    const allSelected = rest.length > 0;

    const activatedNodes = new Set(activatedDeactivedNodes.activated);
    const deactivatedNodes = new Set(activatedDeactivedNodes.deactivated);

    if (selected) {
        if (allSelected) {
            [selectedNodeId, ...rest].forEach((id) => {
                activatedNodes.add(id);

                if (deactivatedNodes.has(id)) {
                    deactivatedNodes.delete(id);
                }
            });
        } else {
            activatedNodes.add(selectedNodeId);

            if (deactivatedNodes.has(selectedNodeId)) {
                deactivatedNodes.delete(selectedNodeId);
            }
        }
    } else {
        if (allSelected) {
            [selectedNodeId, ...rest].forEach((id) => {
                deactivatedNodes.add(id);

                if (activatedNodes.has(id)) {
                    activatedNodes.delete(id);
                }
            });
        } else {
            deactivatedNodes.add(selectedNodeId);

            if (activatedNodes.has(selectedNodeId)) {
                activatedNodes.delete(selectedNodeId);
            }
        }
    }

    return { activated: activatedNodes, deactivated: deactivatedNodes };
};

export const changedLocationsCount = ({
    previousLocationCount: {
        space_count: previousSpaceCount,
        floor_count: previousFloorCount,
        building_count: previousBuildingCount,
    },
    newLocationCount: {
        space_count: newSpaceCount,
        floor_count: newFloorCount,
        building_count: newBuildingCount,
    },
}: {
    previousLocationCount: SmartCleaningActivatedCountResponse;
    newLocationCount: SmartCleaningActivatedCountResponse;
}): ChangedLocationsCount => {
    return {
        spaceCountChange: newSpaceCount - previousSpaceCount,
        floorCountChange: newFloorCount - previousFloorCount,
        buildingCountChange: newBuildingCount - previousBuildingCount,
    };
};
