import type { ConfigurableProps, FullscreenProps, RefreshableProps } from './types';

export const configurableDefaults: ConfigurableProps = {
    // Unless set, permissions are derived based on backend dashboard permissions.
    canUserEdit: undefined,
    enabled: true,
    forceDuplicateEnabled: false,
    isConfigureWidgetAvailable: true,
    isDuplicateDisabled: false,
    isKioskModeAvailable: false,
    onConfigure() {},
    onDelete() {},
};

export const fullscreenDefaults: FullscreenProps = {
    enabled: false,
    onFullscreenClick() {},
};

export const refreshableDefaults: RefreshableProps = {
    enabled: true,
    isLoading: false,
    onRefresh() {},
};
