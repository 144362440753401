import { useState, useCallback, useMemo } from 'react';

import { getSelectedItemsCount } from 'utils/getSelectedItemsCount';

export const useVerticalScrollbar = () => {
    const [isVerticalScrollPresent, setVerticalScrollPresence] = useState(false);
    const onScrollbarPresenceChange = useCallback(
        ({ vertical }) => setVerticalScrollPresence(vertical),
        [],
    );

    return { onScrollbarPresenceChange, isVerticalScrollPresent };
};

/**
 * manage selection logic in table/list data
 * @param data - the array from what items would be selected
 * @param selectBy - field that the items are selected based on
 * @param totalCount
 * @returns {selectedRows, select, allSelected, selectAll, excludedItems, getIsItemSelected}
 * selectedRows - list off selected entities from the list
 * select - handler to select the single item
 * allSelected - flag, that shows is all items selected or not
 * selectAll - handler to select all items from the list
 * excludedItems - items which has been excluded when selectAll mode was turned on
 * getIsItemSelected - function to check if particular item is selected or not
 * resetSelection - function to reset all applied selection
 * selectedItemsCount
 */
export const useSelectableTableColumn = <
    T extends { [key in K]: string },
    K extends string,
>(
    data: T[],
    selectBy: K,
    totalCount: number,
) => {
    const tableData = useMemo(() => data, [data]);
    const [allSelected, setAllSelected] = useState(false);
    const [selectedRows, setSelection] = useState<string[]>([]);
    const [excludedItems, setExcludedItems] = useState<string[]>([]);

    const selectAll = useCallback(
        (checked) => {
            const newSelectedRows = checked
                ? tableData.map((item) => item[selectBy])
                : [];

            setAllSelected(checked);
            setSelection(newSelectedRows);

            if (checked) {
                setExcludedItems([]);
            }
        },
        [tableData, selectBy],
    );

    const select = useCallback(
        (checked: boolean, item: T) => {
            const checkedVal = item[selectBy];

            if (allSelected || excludedItems.length) {
                const newExcludedItems = checked
                    ? excludedItems.filter((i) => i !== checkedVal)
                    : excludedItems.concat(checkedVal);

                setExcludedItems(newExcludedItems);
            }

            const newSelectedRows = checked
                ? selectedRows.concat(checkedVal)
                : selectedRows.filter((i) => i !== checkedVal);
            const newAllSelected = newSelectedRows.length === tableData.length;

            setSelection(newSelectedRows);

            if (newAllSelected !== allSelected) {
                setAllSelected(newAllSelected);
            }
        },
        [tableData, allSelected, selectedRows, selectBy, excludedItems],
    );

    const getIsItemSelected = useCallback(
        (val: string) =>
            allSelected ||
            (!allSelected &&
                ((!!excludedItems.length && !excludedItems.includes(val)) ||
                    selectedRows.includes(val))),
        [allSelected, excludedItems, selectedRows],
    );

    const resetSelection = useCallback(() => {
        setAllSelected(false);
        setSelection([]);
        setExcludedItems([]);
    }, []);

    const selectedItemsCount = getSelectedItemsCount({
        allSelected,
        excludedItemsCount: excludedItems.length,
        selectedCount: selectedRows.length,
        totalCount,
    });

    return {
        selectedRows,
        select,
        allSelected,
        selectAll,
        excludedItems,
        getIsItemSelected,
        resetSelection,
        selectedItemsCount,
    };
};
