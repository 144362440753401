import classNames from 'classnames';
import type { ReactElement } from 'react';
import { memo } from 'react';

import { SENSOR_ICON_SCALE } from './consts';
import { getSensorIcon } from './getSensorIcon';
import { useSensorIconStyles } from './styles';
import type { SensorIconProps } from './types';

const SensorIcon = ({
    type,
    size = 'medium',
    variant = 'blue',
    state = 'default',
    isHub = false,
    isOffline = false,
    isAlert = false,
    className,
    ...props
}: SensorIconProps): ReactElement | null => {
    const styles = useSensorIconStyles({ isHub, scale: SENSOR_ICON_SCALE[size] });
    const { iconSize, sensorIcon, sensorIconFrame, extras } = getSensorIcon({
        type,
        size,
        variant,
        state,
        isHub,
        isOffline,
        isAlert,
        className,
    });

    return (
        <div
            className={classNames(styles.wrapper, className)}
            data-cypress={`sensor-icon-${type}`}
            data-testid={`sensor-icon-${type}`}
            style={{ width: iconSize, height: iconSize }}
            aria-label={props['aria-label']}
        >
            {sensorIconFrame}
            {sensorIcon}
            {extras}
        </div>
    );
};

export default memo(SensorIcon);
