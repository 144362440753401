import type { Floor } from '@infogrid/locations-types';
import { memo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useDeleteFloor } from 'apiHooks/floorPlan/floors/hooks';
import type { EstateFloorParams } from 'views/estate/pages/types';

import DeleteFloorModal from './DeleteFloorModal';

interface Props {
    floor: Floor;
    onClose: () => void;
    onConfirm?: () => void;
    open: boolean;
}

const DeleteFloorModalContainer = ({ floor, onClose, onConfirm, open }: Props) => {
    const { buildingId, floorId } = useParams<EstateFloorParams>();

    const history = useHistory();

    const { mutate: deleteFloor, isLoading } = useDeleteFloor();

    const handleConfirm = useCallback(() => {
        deleteFloor({ buildingId: +buildingId, floorId: floor.id });

        if (onConfirm) {
            onConfirm();
        }

        if (+floorId === floor.id) {
            history.push(`/estate/${buildingId}`);
        }
    }, [buildingId, deleteFloor, floor, floorId, history, onConfirm]);

    return (
        <DeleteFloorModal
            isLoading={isLoading}
            onClose={onClose}
            onConfirm={handleConfirm}
            open={open}
        />
    );
};

export default memo(DeleteFloorModalContainer);
