import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useGenericTitleStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        height: '32px',
        width: '100%',
    },
    titleText: {
        fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
}));

export const useIconStyles = makeStyles(() => ({
    root: {
        fontSize: 16,
        paddingTop: 1,
        overflow: 'visible',
        cursor: 'pointer',
    },
}));

export const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        cursor: 'pointer',
        display: 'flex',
    },
}));
