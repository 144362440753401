import type { SpaceType } from '@infogrid/locations-types';
import { Button, Icon, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useField } from 'formik';
import kebabCase from 'lodash/kebabCase';
import { memo, useCallback } from 'react';

import { useAddSpaceListItemStyles } from './styles';

export interface AddSpaceListItemProps {
    spaceType: SpaceType;
}

const AddSpaceListItem = ({ spaceType }: AddSpaceListItemProps) => {
    const styles = useAddSpaceListItemStyles();

    const [_0, { value }, { setValue }] = useField(spaceType.id.toString());

    const additionalCount = value?.count ?? 0;

    const onChangeCount = useCallback(
        (count: number) => {
            if (count === 0) {
                return setValue(undefined);
            }

            return setValue({
                count,
                type: spaceType.id,
                type_display_name: spaceType.display_name,
            });
        },
        [setValue, spaceType.id, spaceType.display_name],
    );

    const onDecrement = () => onChangeCount(additionalCount - 1);
    const onIncrement = () => onChangeCount(additionalCount + 1);

    return (
        <div className={styles.container} data-cypress={kebabCase(spaceType.name)}>
            <Typography
                className={styles.text}
                data-cypress="space-type"
                variant="subtitle1"
            >
                <span className={styles.name}>{spaceType.display_name}</span>{' '}
                <span>{`(${spaceType.space_count})`}</span>
            </Typography>
            <div className={styles.countSection}>
                <Button
                    aria-label="Remove space"
                    classes={{ root: styles.changeCountButton }}
                    color="primary"
                    data-cypress="minus-space"
                    disabled={additionalCount === 0}
                    onClick={onDecrement}
                >
                    <Icon
                        classes={{
                            root: classNames(
                                'far fa-minus',
                                styles.changeCountButtonIcon,
                            ),
                        }}
                    />
                </Button>
                <Typography
                    aria-label="Space type count"
                    className={styles.additionalCount}
                    data-cypress="space-type-count"
                >
                    {additionalCount}
                </Typography>
                <Button
                    aria-label="Add space"
                    classes={{ root: styles.changeCountButton }}
                    color="primary"
                    data-cypress="plus-space"
                    onClick={onIncrement}
                >
                    <Icon
                        classes={{
                            root: classNames('far fa-plus', styles.changeCountButtonIcon),
                        }}
                    />
                </Button>
            </div>
        </div>
    );
};

export default memo(AddSpaceListItem);
