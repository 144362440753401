import * as Yup from 'yup';

import type {
    CreateFeedbackPanelSensorConfigurationFormValues,
    FeedbackPanelSensorOptionFormValue,
    UpdateFeedbackPanelSensorConfigurationFormValues,
} from './types';

export const SENSOR_OPTIONS_VALIDATION_SCHEMA = Yup.array()
    .of(
        Yup.object()
            .shape<FeedbackPanelSensorOptionFormValue>({
                option_id: Yup.number().required(),
                device_name: Yup.string().required(),
            })
            .required(),
    )
    .required();

export const FEEDBACK_PANEL_SENSOR_CONFIGURATION_CREATE_VALIDATION_SCHEMA =
    Yup.object().shape<CreateFeedbackPanelSensorConfigurationFormValues>({
        sensor_configuration: Yup.object()
            .shape({
                feedback_panel_template: Yup.number(),
                sensor_options: SENSOR_OPTIONS_VALIDATION_SCHEMA,
            })
            .required(),
    });

export const FEEDBACK_PANEL_SENSOR_CONFIGURATION_UPDATE_VALIDATION_SCHEMA =
    Yup.object().shape<UpdateFeedbackPanelSensorConfigurationFormValues>({
        sensor_configuration: Yup.object()
            .shape({
                sensor_options: SENSOR_OPTIONS_VALIDATION_SCHEMA,
            })
            .required(),
    });
