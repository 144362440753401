import type { SpaceType } from '@infogrid/locations-types';
import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import {
    getSensorConfig,
    getMostRecentEventTimestamp,
} from '@infogrid/sensors-configuration';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type { SensorType, SensorEventsV2 } from '@infogrid/sensors-constants';
import {
    convertTimestampToSecondsBeforeNow,
    formatDistance,
    formatSecondsToTimeDurationArray,
    formatTimeDurationArrayToDurationString,
} from '@infogrid/utils-dates';
import { memo } from 'react';

import SensorLastReading from 'components/material-ui/Sensors/SensorLastReading';

import Tooltip from '../Tooltip';
import type { TooltipConfig } from '../Tooltip';
import type { TooltipProps } from '../Tooltip/Tooltip';

interface ShouldShowDeskOccupancySensorArg {
    type_code: SensorType;
    latest_events_v2?: SensorEventsV2;
}

const shouldShowDeskOccupancy = (sensor: ShouldShowDeskOccupancySensorArg): boolean => {
    if (sensor.type_code !== SENSOR_TYPE.TYPE_DESK_OCCUPANCY) {
        return false;
    }

    if (!sensor.latest_events_v2) {
        return false;
    }

    const config = getSensorConfig(sensor.type_code);
    const latestEvent =
        config.lastReadingEventType &&
        sensor.latest_events_v2[config.lastReadingEventType];

    if (!latestEvent) {
        return false;
    }

    const secondsBeforeNow = latestEvent.timestamp
        ? convertTimestampToSecondsBeforeNow(latestEvent.timestamp.toString())
        : 0;

    if (secondsBeforeNow <= 0) {
        return false;
    }

    return 'value' in latestEvent && latestEvent.value === true;
};

interface ShouldShowCategorySensorArg {
    type_code: SensorType;
    smart_cleaning_category?: string;
}

const shouldShowCategory = (sensor: ShouldShowCategorySensorArg): boolean => {
    return (
        sensor.type_code === SENSOR_TYPE.TYPE_PROXIMITY &&
        !!sensor.smart_cleaning_category
    );
};

interface SensorTypeHandlersSensorArg {
    latest_events_v2?: SensorEventsV2;
}

const sensorTypeHandlers: {
    [key: string]: (sensor: SensorTypeHandlersSensorArg) => Date | string | undefined;
} = {
    [SENSOR_TYPE.TYPE_MONNIT_HUB]: (sensor) =>
        sensor?.latest_events_v2?.connection_signal?.timestamp,
    [SENSOR_TYPE.TYPE_ELECTRICITY_CT_CLAMP]: (sensor) =>
        sensor?.latest_events_v2?.kilowatt_hours?.timestamp,
    [SENSOR_TYPE.TYPE_ELECTRICITY_DRY_PULSE_COUNTER]: (sensor) =>
        sensor?.latest_events_v2?.kilowatt_hours?.timestamp,
    [SENSOR_TYPE.TYPE_ELECTRICITY_LED_PULSE_METER]: (sensor) =>
        sensor?.latest_events_v2?.kilowatt_hours?.timestamp,
    [SENSOR_TYPE.TYPE_EMERGENCY_LIGHT]: (sensor) =>
        sensor?.latest_events_v2?.emergency_light_test?.timestamp,
};

interface LastUpdatedAtFormatterSensorArg {
    type_code: SensorType;
    latest_events_v2?: SensorEventsV2;
}

const lastUpdatedAtFormatter = (sensor: LastUpdatedAtFormatterSensorArg): string => {
    const handler = sensorTypeHandlers[sensor.type_code] ?? null;

    let timestamp: string | Date | null | undefined = getMostRecentEventTimestamp(sensor);

    if (handler) {
        timestamp = handler(sensor);
    }

    if (!timestamp) {
        return '-';
    }

    return formatDistance(new Date(), timestamp);
};

export interface SensorTooltipData extends GenericSensorShape {
    smart_cleaning_category?: string;
}

const getConfig = <T extends SensorTooltipData>(
    spaceTypes: SpaceType[],
    tooltipConfig?: TooltipConfig<T>,
): TooltipConfig<T> => {
    if (tooltipConfig) {
        return tooltipConfig;
    }

    return {
        items: [
            {
                item: (sensor, t) => (
                    <>
                        {t('Reading')}:&nbsp;
                        <SensorLastReading sensor={sensor} shouldShowOffline={false} />
                    </>
                ),
            },
            {
                shouldShowItem: shouldShowDeskOccupancy,
                item: (sensor, t) => {
                    const timestamp = getMostRecentEventTimestamp(sensor);

                    let occupiedFor: string | number = 0;

                    if (timestamp) {
                        const secondsBeforeNow = convertTimestampToSecondsBeforeNow(
                            timestamp.toString(),
                        );

                        occupiedFor = formatTimeDurationArrayToDurationString(
                            formatSecondsToTimeDurationArray(secondsBeforeNow),
                        );
                    }

                    return `${t('Occupied For')}: ${occupiedFor}`;
                },
            },
            {
                shouldShowItem: shouldShowCategory,
                item: (sensor, t) => {
                    const space = spaceTypes.find(
                        (s) => s.name === sensor.smart_cleaning_category,
                    );

                    return `${t('Space')}: ${!space ? t('N/A') : space.display_name}`;
                },
            },
            {
                item: (sensor, t) => `${t('Updated')}: ${lastUpdatedAtFormatter(sensor)}`,
                type: 'secondary',
            },
        ],
        shouldShowSignalLevel: true,
        shouldShowOfflineLevelOnly: false,
        shouldShowTooltip: () => true,
    };
};

export interface MapTooltipProps<T extends SensorTooltipData> {
    data: TooltipProps<T>['data'];
    spaceTypes: SpaceType[];
    tooltipConfig?: TooltipProps<T>['config'];
}

const MapTooltip = <T extends SensorTooltipData>({
    data,
    spaceTypes,
    tooltipConfig,
}: MapTooltipProps<T>) => {
    const config = getConfig(spaceTypes, tooltipConfig);

    return <Tooltip config={config} data={data} />;
};

export default memo(MapTooltip);
