import { makeStyles } from '@material-ui/core';

export const useDisplayOptionsStyles = makeStyles((theme) => ({
    wrapper1: {
        alignItems: 'center',
        display: 'flex',
    },
    countEventsSelect: {
        marginLeft: theme.spacing(1),
        width: '110px',
    },
    dataFrequency: {
        marginBottom: theme.spacing(1),
    },
}));
