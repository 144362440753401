import PropTypes from 'prop-types';
import type { ReactNode } from 'react';
import type {
    AutoSizerProps,
    GridProps,
    Index,
    IndexRange,
    ScrollbarPresenceParams,
    ScrollParams,
    CellMeasurerCache,
} from 'react-virtualized';

export interface CellIndexes {
    rowIndex: number;
    columnIndex: number;
}

export interface TableProps<Value = Item> {
    columns: Column<Value>[];
    data: Value[];
    dataCypress?: string;
    EmptyStateRenderer?: () => JSX.Element;
    onFetchMore?: (params: IndexRange) => Promise<Value[]> | Promise<void>;
    classes?: {
        container: string;
    };
    cellPadding?: [number, number];
    leftPadding?: number;
    isLoading: boolean;
    selectedItemIdx?: number;
    onCellClick?: (columnIndex: number, rowIndex?: number) => void;
    onEnterClick?: (item: Value) => void;
    headerMinHeight?: number;
    variant?: string;
}

export interface TableInfiniteLoaderProps<Value = Item>
    extends Omit<GridProps, 'height' | 'rowHeight'> {
    emptyStateRenderer: () => JSX.Element;
    isVerticalScrollPresent: boolean;
    onScrollbarPresenceChange: (scrollData: ScrollbarPresenceParams) => void;
    width: number;
    gridInstance: GridProps | null;
    onEnterClick?: (item: Value) => void;
}

export interface TableContentProps<Value = Item>
    extends Omit<TableInfiniteLoaderProps<Value>, 'onScroll' | 'height' | 'width'> {
    columns: Column<Value>[];
    data: Value[];
    autosizerProps: AutoSizerProps;
    columnWidthMeasurer: number | ((params: Index) => number);
    scrollLeft?: number;
    columnCount: number;
    headerRef: () => void;
    onRowsRendered: (params: IndexRange) => void;
    gridRef: () => void;
    cellMeasurerCache: CellMeasurerCache;
    cellPadding: [number, number];
    leftPadding?: number;
    selectedItemIdx: number | null | undefined;
    onCellClick?: (columnIndex: number, rowIndex?: number) => void;
    headerMinHeight: number;
    rowHeight: number | ((params: Index) => number);
    onScroll?: (scrollData: ScrollParams) => void;
}

export const COLUMN_TYPES = Object.freeze({
    FLEXIBLE: 'flexible',
    FIXED: 'fixed',
} as const);

export const ColumnShape = PropTypes.shape({
    renderHeader: PropTypes.func,
    render: PropTypes.func,
    type: PropTypes.oneOf(Object.values(COLUMN_TYPES)).isRequired,
    width: PropTypes.number.isRequired,
});

export type Item = Record<string, unknown>;

type COLUMN_TYPES_KEYS = keyof typeof COLUMN_TYPES;

export interface Column<Value = Item> {
    renderHeader: () => ReactNode;
    render: (cellData: Value) => ReactNode;
    type: typeof COLUMN_TYPES[COLUMN_TYPES_KEYS];
    width: number;
}

export const defaultCellMeasurerCache = {
    minHeight: 0,
    fixedWidth: true,
};

export const SELECTABLE_COLUMN_WIDTH = 64;

export const SCROLLBAR_WIDTH = 6;

export const TABLE_VARIANTS = Object.freeze({
    REGULAR: 'regular',
    STRIPED: 'striped',
});

export type TABLE_VARIANTS_KEYS = keyof typeof TABLE_VARIANTS;
