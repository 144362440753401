import { createRequest } from '@infogrid/core-api';

export interface ConvertSubfoldersData {
    folders: number[];
    building: number | null;
}

export interface CreateFolderData {
    parent: number;
    name: string;
}

export interface GetSubfoldersResponse {
    id: number;
    name: string;
    sensors_count: number;
    hubs_count: number;
    order_index: number;
    user_actions: {
        edit: {
            reason: string;
            allowed: boolean;
        };
        delete: {
            reason: string;
            allowed: boolean;
        };
        plan_sensors: {
            reason: string;
            allowed: boolean;
        };
    };
    spaces_count: number;
}

const getSubfolders = (buildingId: number, signal?: AbortSignal) =>
    createRequest<GetSubfoldersResponse>({
        method: 'get',
        url: `floorplan/buildings/${buildingId}/folders`,
        signal,
    });

const getFolders = (signal?: AbortSignal) =>
    createRequest({ method: 'get', url: `folders/floorplan-folder-tree`, signal });

const createFolder = (data: CreateFolderData) =>
    createRequest({
        method: 'post',
        url: 'folders',
        options: {
            data,
        },
    });

const convertSubfolders = (data: ConvertSubfoldersData) =>
    createRequest({
        method: 'patch',
        url: 'floorplan/buildings/folders',
        options: {
            data,
        },
    });

export const controllers = {
    convertSubfolders,
    getSubfolders,
    createFolder,
    getFolders,
};
