import { makeStyles } from '@material-ui/core';

export const useWidgetsGridStyles = makeStyles((theme) => ({
    container: {
        margin: `-${theme.spacing(4)}px`,
        paddingBottom: theme.spacing(20),
    },
    gridLayout: {
        '& > .react-grid-item.react-grid-placeholder': {
            backgroundColor: theme.palette.common.shell,
            opacity: 0.5,
        },
    },
}));
