import { ConfirmModal } from '@infogrid/components-material-ui';
import { Typography } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeactivateWebhookModalStyles } from './styles';

interface DeactivateWebhookModalProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    loading: boolean;
}

const modalActionsProps = { justify: 'right' };

const DeactivateWebhookModal = ({
    loading,
    onClose,
    onConfirm,
    open,
}: DeactivateWebhookModalProps) => {
    const styles = useDeactivateWebhookModalStyles();
    const { t } = useTranslation('integrations');
    const content = useMemo(
        () => (
            <div className={styles.modalContent}>
                <ReportProblemOutlinedIcon className={classNames(styles.warningIcon)} />
                <div>
                    <Typography variant="body1">
                        {t('Are you sure you wish to deactivate the webhook?')}
                    </Typography>
                    <Typography variant="body1" className={styles.text}>
                        {t(
                            'Deactivating this webhook means data will no longer be sent to the specified url. You can reactivate it at any time by clicking on the switch again.',
                        )}
                    </Typography>
                </div>
            </div>
        ),
        [styles, t],
    );

    const confirmButtonProps = useMemo(
        () => ({
            color: 'primary',
            disabled: loading,
        }),
        [loading],
    );

    const cancelButtonProps = useMemo(
        () => ({
            color: 'primary',
            disabled: loading,
        }),
        [loading],
    );

    return (
        <ConfirmModal
            maxWidth="xs"
            fullWidth
            title={t('Deactivate this webhook?')}
            onClose={onClose}
            onConfirm={onConfirm}
            open={open}
            content={content}
            modalActionsProps={modalActionsProps}
            confirmButtonProps={confirmButtonProps}
            confirmButtonText={t('Continue')}
            cancelButtonProps={cancelButtonProps}
            showDivider
            disableBackdropClick
        />
    );
};

export default DeactivateWebhookModal;
