import { ErrorBoundary } from '@infogrid/components-material-ui';
import { WIDGET_TYPE, type Dashboard } from '@infogrid/dashboards-constants';
import { useUpdateDashboardGrid } from '@infogrid/dashboards-hooks';
import * as Sentry from '@sentry/react';
import Highcharts from 'highcharts';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import type { Layouts } from 'react-grid-layout';
import { Responsive, WidthProvider } from 'react-grid-layout';

import type { ErrorComponentProps } from 'components/ErrorComponent';
import { canChangeDashboard, hasUserPermissions } from 'views/dashboards/utils/helpers';

import { getWidgetMetadataByType } from '../../utils/widgets';
import ErrorWidget from './ErrorWidget';
import { draggableHandleClassName, gridBreakpoints, gridColumns } from './constants';
import { useWidgetsGridStyles } from './styles';
import {
    appendWidgetToGrid,
    areLayoutsEmpty,
    areLayoutsEqual,
    cleanLayouts,
    generateGridLayout,
} from './utils';

const ResponsiveGridLayout = WidthProvider(Responsive);

export const BASE_ROW_HEIGHT = 364;

const BASE_GRID_MARGINS: [number, number] = [30, 30];

interface Props {
    isDashboardFetched: boolean;
    dashboard: Dashboard;
}

const WidgetsGrid = ({ dashboard, isDashboardFetched }: Props) => {
    const styles = useWidgetsGridStyles();

    const { grid_configuration, widgets: unfilteredWidgets } = dashboard;

    const widgets = unfilteredWidgets?.filter(
        ({ type }) => type !== WIDGET_TYPE.DESK_OCCUPANCY,
    );

    const { mutate: updateDashboardGrid } = useUpdateDashboardGrid();

    const [layouts, setLayouts] = useState<Layouts>({});
    const [breakpoint, setBreakpoint] = useState('lg');

    const canEdit = useMemo(
        () => hasUserPermissions(dashboard) && canChangeDashboard(dashboard),
        [dashboard],
    );

    const canDragAndResize = breakpoint === 'lg' && canEdit;

    const widgetsIds = useMemo(
        () => widgets?.map((widget) => widget.id) || [],
        [widgets],
    );

    const layoutsIds = useMemo(
        () => layouts?.lg?.map((layout) => Number(layout.i)) || [],
        [layouts],
    );

    useEffect(() => {
        const shouldSetupLayout =
            areLayoutsEmpty(layouts) && isDashboardFetched && widgets?.length > 0;

        if (shouldSetupLayout) {
            const widgetsLayouts =
                areLayoutsEmpty(grid_configuration) || isArray(grid_configuration)
                    ? generateGridLayout(widgets)
                    : grid_configuration;

            setLayouts(cleanLayouts(widgetsLayouts));
        }
    }, [grid_configuration, isDashboardFetched, layouts, widgets]);

    useEffect(() => {
        const areSyncedLayoutsEmpty =
            areLayoutsEmpty(layouts) && areLayoutsEmpty(grid_configuration);

        const areSyncedLayoutsNotEmpty =
            !areLayoutsEmpty(layouts) && !areLayoutsEmpty(grid_configuration);

        if (isDashboardFetched && (areSyncedLayoutsEmpty || areSyncedLayoutsNotEmpty)) {
            let newLayout = layouts?.lg?.filter((l) => widgetsIds.includes(Number(l.i)));

            widgets.forEach((widget) => {
                if (!layoutsIds.includes(widget.id)) {
                    newLayout = appendWidgetToGrid(newLayout, widget);
                }
            });

            if (
                (!isEqual(newLayout, layouts?.lg) || areLayoutsEmpty(layouts)) &&
                !isEqual(grid_configuration.lg, newLayout)
            ) {
                const newLayouts = {
                    ...layouts,
                    xxs: newLayout || [],
                    xs: newLayout || [],
                    sm: newLayout || [],
                    md: newLayout || [],
                    lg: newLayout || [],
                };

                setLayouts(newLayouts);

                if (canEdit) {
                    updateDashboardGrid({
                        dashboardId: dashboard.id,
                        grid: newLayouts,
                    });
                }
            }
        }
    }, [
        canEdit,
        dashboard.id,
        grid_configuration,
        isDashboardFetched,
        layouts,
        layoutsIds,
        updateDashboardGrid,
        widgets,
        widgetsIds,
    ]);

    const handleLayoutChange = useCallback(
        (_, newLayouts) => {
            setTimeout(() => {
                Highcharts.charts.filter(Boolean).forEach((chart) => {
                    chart?.reflow();
                });
            });

            const cleanNewLayouts = cleanLayouts(newLayouts);

            if (isDashboardFetched && !areLayoutsEqual(cleanNewLayouts, layouts)) {
                setLayouts(cleanNewLayouts);

                if (canEdit) {
                    updateDashboardGrid({
                        dashboardId: dashboard.id,
                        grid: cleanNewLayouts,
                    });
                }
            }
        },
        [canEdit, dashboard.id, isDashboardFetched, layouts, updateDashboardGrid],
    );

    return (
        <div className={styles.container}>
            <ResponsiveGridLayout
                breakpoints={gridBreakpoints}
                className={styles.gridLayout}
                cols={gridColumns}
                draggableHandle={`.${draggableHandleClassName}`}
                isBounded={false}
                isDraggable={canDragAndResize}
                isResizable={canDragAndResize}
                layouts={layouts}
                margin={BASE_GRID_MARGINS}
                onBreakpointChange={setBreakpoint}
                onLayoutChange={handleLayoutChange}
                rowHeight={BASE_ROW_HEIGHT}
            >
                {layouts[breakpoint]?.map((layout, index) => {
                    const widget = (widgets && widgets[index]) || {};

                    if (!widget?.type) {
                        return <div key={layout?.i} />;
                    }

                    const { Component: WidgetComponent } = getWidgetMetadataByType(
                        widget.type,
                    );

                    return (
                        <div key={layout?.i}>
                            <ErrorBoundary
                                ErrorHandler={({ error }: ErrorComponentProps) => {
                                    // As we're handling the error, ensure that we
                                    // log it to sentry
                                    Sentry.captureException(error);
                                    return <ErrorWidget title={widget.name} />;
                                }}
                            >
                                <WidgetComponent
                                    canDragAndResize={canDragAndResize}
                                    layout={layout}
                                    widget={widget}
                                />
                            </ErrorBoundary>
                        </div>
                    );
                })}
            </ResponsiveGridLayout>
        </div>
    );
};

export default memo(WidgetsGrid);
