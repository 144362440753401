import { makeStyles, fade } from '@material-ui/core';

export const useDateRangeStyles = makeStyles((theme) => ({
    description: {
        color: theme.palette.common.gray1,
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
    },
    chip: {
        backgroundColor: fade(theme.palette.common.gray1, 0.15),
    },
    chipActive: {
        '&, &:focus, &:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.1),
            color: theme.palette.primary.main,
        },
    },
    wrapper1: {
        marginBottom: theme.spacing(2),
    },
    wrapper2: {
        alignItems: 'center',
        display: 'flex',
        '& > .MuiFormControlLabel-root': {
            marginBottom: 0,
        },
    },
    wrapper3: {
        alignItems: 'center',
        display: 'flex',
    },
    wrapper4: {
        marginTop: theme.spacing(3),
        maxWidth: '240px',
    },
    wrapperDateRangeAlert: {
        marginTop: theme.spacing(4),
    },
    timeRangeSeparator: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    timePicker: {
        '& > .MuiInputBase-root > input::-webkit-calendar-picker-indicator': {
            background: 'none',
            display: 'none',
        },
    },
    timePickerIcon: {
        marginTop: theme.spacing(0.125),
    },
}));
