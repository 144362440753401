import { Formik } from 'formik';
import { useCallback } from 'react';

import type { CleaningRulesPayload } from 'views/solutionSettings/apiHooks/types';
import { useSetCleaningRules } from 'views/solutionSettings/apiHooks/useSetCleaningRules';

import { BySpaceTypeModal } from './BySpaceTypeModal';
import { VALIDATION_SCHEMA, INITIAL_VALUES } from './constants';
import type { BySpaceTypeModalFormValues } from './types';

interface Props {
    open: boolean;
    handleClose: () => void;
    refetch: () => void;
}

export const BySpaceTypeModalContainer = ({
    open,
    handleClose,
    refetch,
}: Props): JSX.Element => {
    const { mutate } = useSetCleaningRules();

    const submit = useCallback(
        (submitValues: BySpaceTypeModalFormValues, formik) => {
            const mutateValues: CleaningRulesPayload = {
                does_need_cleaning_threshold: submitValues.must_be_cleaned_value,
                locations: submitValues.locations.join(','),
                space_types: Object.keys(submitValues.selected_space_types).filter(
                    (type) => submitValues.selected_space_types[type] === true,
                ),
            };

            const mightNeedCleaningValue = submitValues.might_need_cleaning_value;
            const isNANotProvided =
                mightNeedCleaningValue &&
                mightNeedCleaningValue?.toString().toLowerCase() !== 'n/a';

            if (isNANotProvided) {
                mutateValues.might_need_cleaning_threshold =
                    Number(mightNeedCleaningValue) || 0;
            }

            mutate(mutateValues, {
                onSuccess: () => {
                    refetch();
                    formik.resetForm();
                    handleClose();
                },
            });
        },
        [handleClose, mutate, refetch],
    );

    return (
        <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={submit}
        >
            <BySpaceTypeModal open={open} handleClose={handleClose} />
        </Formik>
    );
};
