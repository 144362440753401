import type { AxiosParsedError } from '@infogrid/core-types';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type { SensorShape } from '@infogrid/sensors-constants';
import type { UseQueryOptions } from 'react-query';

import {
    useCreateAmbientTemperatureSensors,
    useCreateColdStorageSensors,
    useCreateDeskOccupancySensors,
    useCreatePipeMonitoringSensors,
} from 'apiHooks/sensors/hooks';

import type { TempUseCaseSubmitHandlers } from './types';

export const useTempUseCaseSubmitHandlers = (
    handlerOptions: UseQueryOptions<SensorShape, AxiosParsedError>,
): TempUseCaseSubmitHandlers => {
    const { mutate: createPipeMonitoring } =
        useCreatePipeMonitoringSensors(handlerOptions);
    const { mutate: createDeskOccupancySensor } =
        useCreateDeskOccupancySensors(handlerOptions);
    const { mutate: createAmbientTemperatureSensor } =
        useCreateAmbientTemperatureSensors(handlerOptions);
    const { mutate: createColdStorageSensor } =
        useCreateColdStorageSensors(handlerOptions);

    return {
        [SENSOR_TYPE.TYPE_DESK_OCCUPANCY]: createDeskOccupancySensor,
        [SENSOR_TYPE.TYPE_LEGIONNAIRE]: createPipeMonitoring,
        [SENSOR_TYPE.TYPE_TEMPERATURE]: createAmbientTemperatureSensor,
        [SENSOR_TYPE.TYPE_COLD_STORAGE]: createColdStorageSensor,
    };
};
