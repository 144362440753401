import type { LocationFilter } from '@infogrid/core-types';
import { memo } from 'react';

import { Widget } from 'views/dashboards/components/Widget';

import type {
    LiveIndoorAirQualityWidget,
    WidgetSettings,
    LiveIndoorAirQualityWidgetData,
} from '../types';
import { validateConfiguration, getEnabledLocationFilters } from '../utils';
import Locations from './Locations';
import Selector from './Selector';
import { useFooterStyles } from './styles';

interface Props {
    canUserEdit?: boolean;
    widget: LiveIndoorAirQualityWidget;
    widgetData: LiveIndoorAirQualityWidgetData | undefined;
    isWidgetDataLoading: boolean;
    onUpdateConfiguration: (config: WidgetSettings) => void;
}

const Footer = ({
    onUpdateConfiguration,
    widget,
    widgetData,
    isWidgetDataLoading,
    canUserEdit = false,
}: Props) => {
    const styles = useFooterStyles();

    const widgetConfig = widget.configuration.calculators.live_indoor_air_quality;

    const changeLocationType = (locationFilter: LocationFilter) => {
        onUpdateConfiguration({
            ...widget.configuration.calculators.live_indoor_air_quality,
            floorplan_type: locationFilter,
        });
    };

    return (
        <Widget.Footer className={styles.footer} justifyContent={'space-between'}>
            {validateConfiguration(widgetConfig) && (
                <>
                    <Selector
                        className={styles.selector}
                        value={widgetConfig.floorplan_type}
                        onChange={changeLocationType}
                        disabled={!canUserEdit}
                        enabledFilters={getEnabledLocationFilters(widgetConfig.locations)}
                    />

                    {!isWidgetDataLoading &&
                        widgetData &&
                        widgetData.calculation_status === 'done' && (
                            <Locations
                                type={widgetConfig.floorplan_type}
                                count={widgetData.location_count}
                            />
                        )}
                </>
            )}
        </Widget.Footer>
    );
};

export default memo(Footer);
