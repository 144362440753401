import type { AutocompleteRenderInputParams } from '@infogrid/components-material-ui';
import { Autocomplete } from '@infogrid/components-material-ui';
import type { MapBoxFeatureItem, Option, PlaceType } from '@infogrid/locations-types';
import type { AutocompleteProps } from '@material-ui/lab';
import { memo, useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import useMapboxPlaces from 'utils/hooks/useMapboxPlaces';

const MAX_AUTOCOMPLETE_WINDOW_HEIGHT = '15.625rem';
const DEBOUNCE_DELAY = 500;

export interface Props<
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
> extends Omit<
        AutocompleteProps<
            string | Option | (string | Option)[] | null,
            Multiple,
            DisableClearable,
            FreeSolo
        >,
        'options' | 'renderInput'
    > {
    address: string;
    buildingCountry?: string | null;
    buildingLongitude?: number | null;
    buildingLatitude?: number | null;
    optionLabelExtractor?: (option: Option) => string;
    placeTypes?: PlaceType[];
    renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
    disabled?: boolean;
    noOptionsText?: string;
}

const getContextValue = (item: MapBoxFeatureItem, key: string) => {
    const value = item.context?.find((contextItem) => {
        return contextItem.id.startsWith(key);
    });

    return value?.text;
};

const getCountryCode = (item: MapBoxFeatureItem) => {
    if (item.place_type?.includes('country')) {
        return item.properties.short_code;
    }

    const country = item.context?.find((contextItem) => {
        return contextItem.id.startsWith('country');
    });

    return country ? country.short_code : null;
};

const getAddressAndCoordinates = (results: { features: MapBoxFeatureItem[] }) => {
    return results?.features?.length
        ? results.features.map((item) => {
              return {
                  address: item.place_name,
                  city: getContextValue(item, 'place') || item.text,
                  country_code: getCountryCode(item),
                  country_name: getContextValue(item, 'country'),
                  coordinates: {
                      latitude: item.geometry.coordinates[1],
                      longitude: item.geometry.coordinates[0],
                  },
                  text: item.text,
              };
          })
        : [];
};

const defaultOptionLabelExtractor = (option: Option) => option.address;

const getOptionSelected = (option: Option, value: Option) =>
    option.address === value.address;
const ListboxProps = {
    style: {
        overflow: 'scroll-y',
        maxHeight: MAX_AUTOCOMPLETE_WINDOW_HEIGHT,
    },
};

const AutocompleteAddress = ({
    address,
    buildingCountry,
    noOptionsText,
    optionLabelExtractor = defaultOptionLabelExtractor,
    placeTypes,
    renderInput,
    onChange,
    disabled = false,
}: Props) => {
    const { t } = useTranslation();

    const [inputValue, setInputValue] = useState('');
    const { results, loading } = useMapboxPlaces({
        query: inputValue,
        debounceTime: DEBOUNCE_DELAY,
        selector: getAddressAndCoordinates,
        emptyValue: [],
        placeTypes,
        country: buildingCountry || '',
    });

    const value = useMemo(
        () => ({
            address,
        }),
        [address],
    );

    return (
        <Autocomplete
            options={[value, ...results]}
            filterSelectedOptions
            filterOptions={(options) => options.filter((option) => option.address !== '')}
            autoHighlight
            selectOnFocus
            noOptionsText={noOptionsText}
            getOptionLabel={optionLabelExtractor}
            getOptionSelected={getOptionSelected}
            freeSolo={false}
            ListboxProps={ListboxProps}
            onChange={onChange}
            renderInput={renderInput}
            value={value}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
            disabled={disabled}
            loading={loading}
            loadingText={`${t('Loading')}...`}
        />
    );
};

export default memo(AutocompleteAddress);
