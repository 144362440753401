import { routesManager } from '@infogrid/core-routing';
import { ViewManager } from '@thorgate/spa-view-manager';
import { getLocation, replace } from 'connected-react-router';
import { call, fork, put, select } from 'redux-saga/effects';

import SocketManager from 'sagas/application/socketManager';
import refreshTokenManager from 'sagas/auth/refreshTokenSaga';

export default function* rootSaga(hot = false) {
    yield fork(refreshTokenManager);
    yield fork(ViewManager, routesManager.getRoutes(), {
        allowLogger: true,
        skipInitialsForFirstRendering: false,
    });

    if (hot) {
        const location = yield select(getLocation);

        yield put(replace(location));
    }

    yield call(SocketManager);
}
