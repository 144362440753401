import { ConnectionStatus, setSocketStatus } from '@infogrid/core-ducks';
import { eventChannel } from 'redux-saga';

import SETTINGS from 'settings';

import { CLOSED, recvSocketMessage } from './actionTypes';

export const connect = (socketUrl) => {
    const socket = new WebSocket(socketUrl);

    return new Promise((resolve, reject) => {
        const onReject = (evt) => {
            reject(evt);
        };

        const onOpen = () => {
            socket.removeEventListener('open', onOpen);
            socket.removeEventListener('close', onReject);

            resolve(socket);

            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.log('Socket connected');
            }
        };

        socket.addEventListener('close', onReject);
        socket.addEventListener('open', onOpen);
    });
};

export function initWSChannel(socket) {
    return eventChannel((emit) => {
        const onMessage = (event) => emit(recvSocketMessage(JSON.parse(event.data)));

        const onClose = (event) => {
            if (event.code === 4403) {
                // eslint-disable-next-line no-console
                console.error('Failed to authenticate ws connection.');
                // If you are debugging this then the problem might be with COOKIE_DOMAIN
                // Since ws connection uses `infogrid_token` cookie to authenticate, it needs to be passed.
                // However, if the backend is not a sub-domain of SETTINGS.COOKIE_DOMAIN, it won't be passed.
            }

            if (SETTINGS.VERBOSE) {
                // eslint-disable-next-line no-console
                console.warn('Socket disconnected', event);
            }

            emit({ type: CLOSED });
            emit(setSocketStatus(ConnectionStatus.Disconnected));
        };

        // send all messages to RECV_MESSAGE actionChannels
        socket.addEventListener('message', onMessage);
        socket.addEventListener('close', onClose);

        // unsubscribe function
        return () => {
            socket.removeEventListener('message', onMessage);
            socket.removeEventListener('close', onClose);
        };
    });
}
