import type { MapItemCoordinates } from '@infogrid/locations-types';
import { MIN_SENSOR_SCALE, MAX_SENSOR_SCALE } from '@infogrid/sensors-constants';
import type { PlannedSensor } from '@infogrid/sensors-constants';
import { Icon, IconButton, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo, useMemo, useCallback } from 'react';

import type { MapFeature, MappedSensor } from '../types';
import { useStylesMapTooltip } from './styles';

interface Props {
    sensor: MapFeature<PlannedSensor & MappedSensor>;
    sensorData: PlannedSensor & MappedSensor;
    onResize: (
        feature: MapFeature<PlannedSensor & MappedSensor>,
        coordinates: MapItemCoordinates,
    ) => void;
}

const FloorPlanSensorResizeTooltip = ({ sensor, sensorData, onResize }: Props) => {
    const styles = useStylesMapTooltip();

    const coordinates: MapItemCoordinates = useMemo(
        () => ({
            x: sensorData.coordinates.x,
            y: sensorData.coordinates.y,
            rotation: sensorData.coordinates?.rotation || 0,
            scale: sensorData.coordinates?.scale || MIN_SENSOR_SCALE,
        }),
        [sensorData],
    );

    const rotateLeft = useCallback(() => {
        coordinates.rotation = ((coordinates.rotation || 0) - 15) % 360;
        onResize(sensor, coordinates);
    }, [sensor, coordinates, onResize]);
    const rotateRight = useCallback(() => {
        coordinates.rotation = ((coordinates.rotation || 0) + 15) % 360;
        onResize(sensor, coordinates);
    }, [sensor, coordinates, onResize]);
    const decreaseRange = useCallback(() => {
        coordinates.scale = Math.max(
            MIN_SENSOR_SCALE,
            (coordinates.scale || MIN_SENSOR_SCALE) - 5,
        );
        onResize(sensor, coordinates);
    }, [sensor, coordinates, onResize]);
    const increaseRange = useCallback(() => {
        coordinates.scale = Math.min(
            MAX_SENSOR_SCALE,
            (coordinates.scale || MIN_SENSOR_SCALE) + 5,
        );
        onResize(sensor, coordinates);
    }, [sensor, coordinates, onResize]);

    return (
        <div
            className={styles.tooltipContainer}
            data-testid="floor-map-resize-tooltip-container"
        >
            <div className={styles.tooltip} data-testid="floor-map-resize-tooltip">
                <Typography variant="body2">
                    <IconButton data-testid="rotate-left-button" onClick={rotateLeft}>
                        <Icon className={classNames('far fa-undo', styles.controlIcon)} />
                    </IconButton>
                    <IconButton data-testid="rotate-right-button" onClick={rotateRight}>
                        <Icon className={classNames('far fa-redo', styles.controlIcon)} />
                    </IconButton>
                    <IconButton
                        data-testid="increase-range-button"
                        disabled={
                            coordinates.scale
                                ? coordinates.scale >= MAX_SENSOR_SCALE
                                : false
                        }
                        onClick={increaseRange}
                    >
                        <Icon
                            className={classNames('far fa-plus', styles.controlIcon, {
                                [styles.disabledControlIcon]: coordinates.scale
                                    ? coordinates.scale >= MAX_SENSOR_SCALE
                                    : false,
                            })}
                        />
                    </IconButton>
                    <IconButton
                        data-testid="decrease-range-button"
                        disabled={
                            !coordinates.scale || coordinates.scale <= MIN_SENSOR_SCALE
                        }
                        onClick={decreaseRange}
                    >
                        <Icon
                            className={classNames('far fa-minus', styles.controlIcon, {
                                [styles.disabledControlIcon]:
                                    !coordinates.scale ||
                                    coordinates.scale <= MIN_SENSOR_SCALE,
                            })}
                        />
                    </IconButton>
                </Typography>
            </div>
        </div>
    );
};

export default memo(FloorPlanSensorResizeTooltip);
