import { makeStyles } from '@material-ui/core';

export const useContentStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
    },
    tab: {
        alignItems: 'center',
        color: theme.palette.text.primary,
        display: 'flex',
        letterSpacing: '0.1px',
        textTransform: 'none',
    },
    tabRoot: {
        minWidth: 'initial',
    },
    tabActive: {
        color: theme.palette.primary.main,
        '& > $tabUnit': {
            color: theme.palette.primary.main,
        },
    },
    tabIcon: {
        marginRight: theme.spacing(0.5),
    },
    tabUnit: {
        color: theme.palette.text.grey.default,
        marginLeft: theme.spacing(0.5),
    },
    tabsBorder: {
        borderBottom: '2px solid #EDEDED',
    },
    chart: {
        height: 'calc(100% - 40px)',
        display: 'flex',
    },
    alertContainer: {
        margin: theme.spacing(2, 1.5, 0),
    },
}));
