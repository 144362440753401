import { makeStyles } from '@material-ui/core';

export const useCleaningRulesStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3, 3.75),
        width: '100%',
        height: '100%',
        overflowY: 'auto',

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2.5, 2),
        },
    },
    description: {
        marginTop: theme.spacing(1),
    },
    header: {
        paddingTop: theme.spacing(1),
    },
    locationPickerContainer: {
        padding: theme.spacing(3, 0),
        height: '100%',
        maxHeight: `calc(100vh - ${theme.spacing(40)}px)`,

        [theme.breakpoints.down(1500)]: {
            maxHeight: `calc(100vh - ${theme.spacing(45)}px)`,
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: `calc(100vh - ${theme.spacing(50)}px)`,
        },
    },
    loader: {
        margin: 'auto',
    },
    ruleSymbol: {
        width: 10,
        height: 10,
        marginRight: theme.spacing(1),
        borderRadius: '50%',
        display: 'inline-block',
        alignSelf: 'center',
    },
    section: {
        backgroundColor: theme.palette.background.default,
        width: '100%',
        margin: theme.spacing(2, 0, 2, 0),
        padding: theme.spacing(2, 3),
        flexWrap: 'nowrap',
    },
    manageByLocationButton: {
        margin: theme.spacing(0, 0, 0, 1),
        minWidth: '112px',
    },
}));
