import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { SensorShape } from '@infogrid/sensors-constants';
import type { UpdateSensorParams } from '@infogrid/sensors-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult, Updater } from 'react-query';
import { useQueryClient } from 'react-query';

import { getSensorKey } from '../getQueryKeys';
import { controllers } from '../ts/controllers';

export const useUpdateSensorConfiguration = (
    deviceName: string,
): UseMutationResult<SensorShape, AxiosParsedError, UpdateSensorParams> => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const { t } = useTranslation('sensors');

    return useMutationWithToast<SensorShape, AxiosParsedError, UpdateSensorParams>(
        ({ data }) =>
            wrapResponsePromise(
                controllers.updateSensorConfiguration({ id: deviceName, data }),
            ),
        {
            onSuccess: (result) => {
                const message = result.profile?.name
                    ? `${t('Sensor configuration updated')} - ${result.profile.name}`
                    : t('Sensor configuration updated');

                dispatch(toastSuccess({ message }));

                queryClient.setQueryData(
                    getSensorKey(deviceName),
                    (old): Updater<SensorShape | undefined, SensorShape> => ({
                        ...(old as SensorShape),
                        sensor_configuration: result.sensor_configuration,
                    }),
                );
            },
            onError: () => {
                dispatch(toastError({ message: t('Error saving sensor configuration') }));
            },
        },
        { toastMessage: t('Updating of sensor is in progress') },
    );
};
