import { MenuList } from '@infogrid/components-material-ui';
import type { NamedRouteConfig } from '@infogrid/core-types';
import { Select, ListItemText } from '@material-ui/core';
import type { Location } from 'history';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import type {
    SolutionSettingsMenuItem,
    SolutionSettingsMenuItemGroup,
} from 'views/solutionSettings/pages/solutionSettings/types';

import { useMobileSolutionsSidebarStyles } from './styles';

interface MobileSolutionsSidebarProps {
    menuitems: SolutionSettingsMenuItemGroup[];
    route?: NamedRouteConfig;
}

const MobileSolutionsSidebar = ({
    menuitems,
    route,
}: MobileSolutionsSidebarProps): JSX.Element | null => {
    const styles = useMobileSolutionsSidebarStyles();

    const { t } = useTranslation('solutions');

    const location: Location = useLocation();

    const renderCurrentNavLink = useCallback(() => {
        const currentPath = location.pathname.replace(/\/$/, '');
        const activeRoute = route?.routes
            ? route.routes.find((r) => r.path === currentPath)
            : null;
        const linkText = activeRoute?.pageName || t('Manage solutions');

        return (
            <div className={styles.integrationItem}>
                <ListItemText className={styles.text}>{linkText}</ListItemText>
            </div>
        );
    }, [styles, location, route, t]);

    return (
        <Select
            renderValue={renderCurrentNavLink}
            displayEmpty={true}
            name="integrations-sidebar"
            inputProps={{ 'data-testid': 'integrations-sidebar' }}
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                marginThreshold: 0,
            }}
            className={styles.root}
        >
            {menuitems.map((value: SolutionSettingsMenuItemGroup) => (
                <div key={value[0]}>
                    <MenuList>
                        <MenuList.Subheader text={value[0]} />
                        {value[1].map(({ name, url }: SolutionSettingsMenuItem) => (
                            <MenuList.Item
                                className={styles.noMargins}
                                key={name}
                                onClick={() => {}}
                            >
                                <NavLink
                                    to={url}
                                    className={styles.integrationItem}
                                    activeClassName={styles.integrationItemActive}
                                >
                                    <ListItemText className={styles.text}>
                                        {name}
                                    </ListItemText>
                                </NavLink>
                            </MenuList.Item>
                        ))}
                    </MenuList>
                </div>
            ))}
        </Select>
    );
};

export default memo(MobileSolutionsSidebar);
