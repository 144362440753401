import { Modal } from '@infogrid/components-material-ui';
import { i18n } from '@infogrid/utils-i18n';
import { Button, TextField, Typography } from '@material-ui/core';
import type { FormikProps } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSpinner from 'components/ButtonSpinner';

import { useStylesCreateFolder } from './styles';

interface Props
    extends Pick<
        FormikProps<{ name: string }>,
        'errors' | 'touched' | 'handleChange' | 'handleSubmit' | 'isSubmitting'
    > {
    description?: string;
    handleBack: () => void;
    folderName: string;
    label?: string;
    title?: string;
}

const CreateFolder = ({
    description = i18n.t('Enter folder name', { ns: 'floorplan' }),
    errors,
    folderName,
    handleBack,
    handleChange,
    handleSubmit,
    isSubmitting = false,
    label = i18n.t('Folder name', { ns: 'floorplan' }),
    title = i18n.t('Create New Floor', { ns: 'floorplan' }),
    touched,
}: Props) => {
    const styles = useStylesCreateFolder();

    const { t } = useTranslation('floorplan');

    return (
        <form onSubmit={handleSubmit} className={styles.container}>
            <Modal.Title>{title}</Modal.Title>

            <Modal.Description>{description}</Modal.Description>

            <Modal.Content>
                <Modal.ContentItem>
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        variant="outlined"
                        data-cypress="name"
                        label={label}
                        placeholder={label}
                        disabled={isSubmitting}
                        value={folderName}
                        onChange={handleChange}
                        error={Boolean(errors.name && touched.name)}
                    />
                    {errors.name && touched.name && (
                        <Typography
                            color="error"
                            variant="caption"
                            data-cypress="name-error"
                        >
                            {errors.name}
                        </Typography>
                    )}
                </Modal.ContentItem>
            </Modal.Content>
            <Modal.Actions justify="space-between">
                <Button color="primary" onClick={handleBack}>
                    {t('Back')}
                </Button>

                <Button
                    color="primary"
                    variant="contained"
                    data-cypress="create"
                    type="submit"
                >
                    {isSubmitting && <ButtonSpinner />}
                    {t('Create')}
                </Button>
            </Modal.Actions>
        </form>
    );
};

export default memo(CreateFolder);
