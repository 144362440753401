import { BackLink } from '@infogrid/components-material-ui';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

const BuildingsLink = (): JSX.Element => {
    const { t } = useTranslation('estate');

    return (
        <BackLink data-cypress="all-buildings" to="/estate/">
            {t('All buildings')}
        </BackLink>
    );
};

export default memo(BuildingsLink);
