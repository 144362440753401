import { ItemsContainer } from '@infogrid/components-material-ui';
import type { ButtonProps } from '@material-ui/core';
import { entitiesSelectors, EntityStatus } from '@thorgate/spa-entities';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import TreeNodeButton from 'components/Folders/FolderTree/TreeNodeButton';
import { paginationMapStateToProps, useLoadMore } from 'ducks/pagination';
import { useDoInitialFetchEffect } from 'utils/hooks/entities';

interface LoadMoreProps extends ButtonProps {
    depth: number;
}

interface Props {
    children?: ReactNode;
    hasContent: boolean;
    hasLoaded: boolean;
    isLoading: boolean;
    hideLoader: boolean;
    emptyState?: ReactNode;
    className?: string;

    entityKey: string;
    onFetchMore: () => void;
    doInitialFetch?: boolean;
    refetchOnReset?: boolean;

    loadMoreText?: ReactNode;
    loadMoreProps?: LoadMoreProps;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nextKwargs: any;
    hasNext: boolean;
}

const LoadMoreContainer = ({
    children,
    hasContent,
    hasLoaded,
    isLoading,
    hideLoader = false,
    emptyState,
    className,
    nextKwargs,
    hasNext,
    entityKey,
    onFetchMore,
    doInitialFetch = false,
    refetchOnReset,
    loadMoreText,
    loadMoreProps = {
        variant: 'contained',
        size: 'small',
        color: 'primary',
        depth: 1,
    },
}: Props): JSX.Element => {
    const { t } = useTranslation('common');
    const loadMore = useLoadMore(onFetchMore, nextKwargs, hasNext);
    const displayLoadMoreText = loadMoreText ?? t('Load more');

    // Explicitly using onFetchMore for initial fetch as it's meant for 'starting over' not 'loading more'
    useDoInitialFetchEffect(onFetchMore, doInitialFetch, refetchOnReset, entityKey);

    return (
        <ItemsContainer
            hasContent={hasContent}
            hasLoaded={hasLoaded}
            isLoading={isLoading}
            hideLoader={hideLoader}
            emptyState={emptyState}
            noContentContainer={'div'}
            noContentClassName={className}
        >
            <div
                key="load-more-content"
                style={{ opacity: hasLoaded ? 1 : 0.9 }}
                className={className}
            >
                {children}
                {hasNext ? (
                    <TreeNodeButton
                        onClick={loadMore}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...loadMoreProps}
                    >
                        {displayLoadMoreText}
                    </TreeNodeButton>
                ) : null}
            </div>
        </ItemsContainer>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any, ownProps: any) => ({
    hasLoaded:
        entitiesSelectors.selectEntitiesStatus(state, ownProps.entityKey) ===
        EntityStatus.Fetched,
    isLoading:
        entitiesSelectors.selectEntitiesStatus(state, ownProps.entityKey) ===
        EntityStatus.Fetching,

    ...paginationMapStateToProps(state, ownProps.entityKey),
});

export default connect(mapStateToProps)(LoadMoreContainer);
