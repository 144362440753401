import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo, useCallback, useMemo } from 'react';

import type { ChecklistPropsType } from 'components/Checklist';
import Checklist, { ChecklistItem } from 'components/Checklist';
import { ChecklistMenu } from 'components/Checklist/ChecklistMenu';

import { useChecklistWithAllSelectStyles } from './styles';

const ITEMS_LIST_DEFAULT_MAX_HEIGHT = 500;

type Props<T> = {
    allItemsCheckedText: string;
    checkedItemsKeys: (number | string)[];
    checkHandler: (id: string | number) => void;
    checkAllHandler: (x: boolean) => void;
    maxHeight: number | string;
    selectionDisabled?: boolean;
    onlyRenderChecklistMenu?: boolean;
    renderValue?: () => ReactNode;
} & Omit<ChecklistPropsType<T>, 'onOptionCheck' | 'renderValue'>;

function ChecklistWithAllSelect<T>({
    allItemsCheckedText,
    checkAllHandler,
    checkedItemsKeys,
    checkHandler,
    items,
    renderValue,
    maxHeight = ITEMS_LIST_DEFAULT_MAX_HEIGHT,
    classes,
    selectionDisabled,
    onlyRenderChecklistMenu = false,
    ...checklistProps
}: Props<T>) {
    const allChecked = checkedItemsKeys.length === items.length;
    const noneChecked = checkedItemsKeys.length === 0;
    const checklistWithAllSelectStyles = useChecklistWithAllSelectStyles({ maxHeight });

    const checklistWithAllSelectClasses = useMemo(
        () => ({
            actionsList: classNames(
                checklistWithAllSelectStyles.checklistActionsList,
                classes?.actionsList,
            ),
            itemsList: classNames(
                checklistWithAllSelectStyles.checklistItemsList,
                classes?.itemsList,
            ),
            container: classes?.container,
        }),
        [
            checklistWithAllSelectStyles.checklistActionsList,
            checklistWithAllSelectStyles.checklistItemsList,
            classes,
        ],
    );

    const toggleAllItems = useCallback(() => {
        // for consistency, only select all when we've fully deselected everything
        checkAllHandler(noneChecked);
    }, [noneChecked, checkAllHandler]);

    const memoizedActions = useMemo(
        () => (
            <ChecklistItem
                id="CHECK_ALL"
                checked={!noneChecked}
                disabled={selectionDisabled}
                indeterminate={!noneChecked && !allChecked}
                onOptionCheck={toggleAllItems}
                text={allItemsCheckedText}
            />
        ),
        [allChecked, allItemsCheckedText, toggleAllItems, selectionDisabled, noneChecked],
    );

    // The `Checklist` component below is also forced to render the corresponding select component
    // `onlyRenderChecklistMenu=true` allows us to ONLY render the dropdown menu, not the select around it.
    if (onlyRenderChecklistMenu || renderValue === undefined) {
        return (
            <ChecklistMenu<T>
                actions={memoizedActions}
                classes={checklistWithAllSelectClasses}
                checkedItemsKeys={checkedItemsKeys}
                items={items}
                selectionDisabled={selectionDisabled}
                onOptionCheck={checkHandler}
                keyExtractor={checklistProps.keyExtractor}
                valueExtractor={checklistProps.valueExtractor}
            />
        );
    }

    return (
        <Checklist<T>
            classes={checklistWithAllSelectClasses}
            actions={memoizedActions}
            onOptionCheck={checkHandler}
            renderValue={renderValue}
            checkedItemsKeys={checkedItemsKeys}
            selectionDisabled={selectionDisabled}
            items={items}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...checklistProps}
        />
    );
}

const ChecklistWithAllSelectComponent = memo(
    ChecklistWithAllSelect,
) as typeof ChecklistWithAllSelect;

export default ChecklistWithAllSelectComponent;
