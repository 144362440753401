import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { getUser, isAuthenticated } from '@infogrid/user-ducks';
import { getLocation, push } from 'connected-react-router';
import { useEffect, useMemo } from 'react';

export const useHasAcceptedTerms = (redirect = true): boolean => {
    const user = useAppSelector(getUser);
    const isLoggedIn = useAppSelector(isAuthenticated);
    const currentLocation = useAppSelector(getLocation);
    const dispatch = useAppDispatch();

    const mustRedirect = useMemo(
        () =>
            redirect &&
            currentLocation.pathname !== routesManager.resolvePath('terms').pathname &&
            isLoggedIn &&
            !user?.has_accepted_latest_terms,
        [user, isLoggedIn, currentLocation, redirect],
    );

    useEffect(() => {
        if (mustRedirect) {
            dispatch(push(routesManager.resolvePath('terms')));
        }
    }, [dispatch, mustRedirect]);

    return user?.has_accepted_latest_terms || false;
};
