import type { MapBrowserEvent } from 'ol';
import { useContext, useEffect, memo } from 'react';

import type { MapContext } from '../MapContext';
import Context from '../MapContext';

interface Props {
    activeSensorDeviceId?: string | number | null;
    onOutsideClick: () => void;
}

const OutsideSensorClick = ({ activeSensorDeviceId, onOutsideClick }: Props) => {
    const { map } = useContext<MapContext>(Context);

    useEffect(() => {
        if (!map) {
            return () => {};
        }

        const onMapClick = (e: MapBrowserEvent<UIEvent>) => {
            if (activeSensorDeviceId && !map.hasFeatureAtPixel(e.pixel)) {
                onOutsideClick();
            }
        };

        map.on('click', onMapClick);

        return () => {
            map.un('click', onMapClick);
        };
    }, [map, activeSensorDeviceId, onOutsideClick]);

    return null;
};

export default memo(OutsideSensorClick);
