import { HEADER_HEIGHT } from '@infogrid/components-material-ui';
import { makeStyles } from '@material-ui/core';

const CREATE_BTN_HEIGHT = '88px';

interface UseFloorNavigationStylesProps {
    isCreateFloorButtonAvailable?: boolean;
}

export const useFloorNavigationStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        background: theme.palette.common.shell,
        boxShadow: theme.shadows[1],
    },
    createFloorContainer: {
        padding: theme.spacing(2.5, 2),
        textAlign: 'center',
    },
    createFloorButtonLabel: {
        marginLeft: theme.spacing(1),
    },

    addFloorIcon: {
        fontSize: '20px',
        lineHeight: '22px',
    },

    floorsListContainer: ({
        isCreateFloorButtonAvailable = true,
    }: UseFloorNavigationStylesProps) => {
        const height = `calc(100vh - ${HEADER_HEIGHT}px - ${
            isCreateFloorButtonAvailable ? CREATE_BTN_HEIGHT : '0px'
        })`;

        return {
            height,
            maxHeight: height,
            overflowY: 'auto',
        };
    },
}));

export const useCreateFloorButtonStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minWidth: '50px',
        backgroundColor: theme.palette.common.gray5,
        padding: theme.spacing(1.5, 2),
        color: theme.palette.primary.main,
        textTransform: 'none',
    },
}));
