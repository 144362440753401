import { useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { memo, useMemo } from 'react';

import { useTrendLineChartStyles } from './styles';

export interface Props {
    data: number[];
    className?: string;
}

const MAX_CHART_POINTS = 30;

const TrendLineChart = ({ data, className = '' }: Props) => {
    const styles = useTrendLineChartStyles();
    const theme = useTheme();

    const chartOptions = useMemo(() => {
        const chartData = data.slice(-MAX_CHART_POINTS);
        const lastMarkerData = chartData.pop();

        return {
            chart: {
                backgroundColor: null,
                margin: [5, 5, 4, 5],
            },
            title: {
                text: null,
            },
            credits: {
                enabled: false,
            },
            xAxis: {
                visible: false,
            },
            yAxis: {
                visible: false,
                endOnTick: false,
                startOnTick: false,
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    states: {
                        hover: {
                            lineWidthPlus: 0,
                        },
                    },
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: false,
                            },
                        },
                    },
                    label: {
                        enabled: false,
                    },
                },
                line: {
                    color: theme.palette.common.gray8,
                },
                enableMouseTracking: false,
            },
            series: [
                {
                    data: [
                        ...chartData,
                        { y: lastMarkerData, marker: { enabled: true, radius: 3 } },
                    ],
                },
            ],
        };
    }, [data, theme.palette.common.gray8]);

    return (
        <div
            className={classNames(styles.chartContainer, className)}
            data-testid="trend-line-chart"
        >
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    );
};

export default memo(TrendLineChart);
