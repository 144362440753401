import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import { Locations } from 'views/dashboards/components/modals/ConfigureWidgetModal/Locations';

export const widgetConfigurationModalTabs = [
    {
        id: 'locations',
        name: registerTranslationKey('Locations', { ns: 'dashboard' }),
        description: registerTranslationKey(
            'Select the locations for which you’d like to see meeting room occupancy data.',
            { ns: 'dashboard' },
        ),
        component: Locations,
        attributes: {
            sensorTypes: [SENSOR_TYPE.TYPE_SPACE],
        },
    },
];
