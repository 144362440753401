import type { WidgetType } from '@infogrid/dashboards-constants';
import { useCallback } from 'react';

import { openConfigurationWidgetModalEvent } from 'views/dashboards/utils/analytics';

import { useDashboardContext } from '../context';

interface ConfigureWidgetModalOpenState {
    handleConfigureWidgetModalClose: () => void;
    handleConfigureWidgetModalOpen: () => void;
    isConfigureWidgetModalOpen: boolean;
}

export const useConfigureWidgetModalOpenState = (
    id: number,
    type: WidgetType,
): ConfigureWidgetModalOpenState => {
    const { configurationModalWidgetId, setConfigurationModalWidgetId } =
        useDashboardContext();

    const isConfigureWidgetModalOpen = configurationModalWidgetId === id;

    const handleConfigureWidgetModalClose = useCallback(() => {
        setConfigurationModalWidgetId(null);
    }, [setConfigurationModalWidgetId]);

    const handleConfigureWidgetModalOpen = useCallback(() => {
        setConfigurationModalWidgetId(id);
        openConfigurationWidgetModalEvent({ widgetType: type });
    }, [id, setConfigurationModalWidgetId, type]);

    return {
        handleConfigureWidgetModalClose,
        handleConfigureWidgetModalOpen,
        isConfigureWidgetModalOpen,
    };
};
