import type { LocationFilter } from '@infogrid/core-types';
import type { AirQualityMetric } from '@infogrid/solution-views-types';
import { Typography, Chip, Tooltip, Icon } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Rating } from '../../../types';
import { useMetricValue } from '../../hooks';
import { useLocationMetricLabel } from './hooks';
import { useMetricStateStyles, useIconStyles } from './styles';

interface Props {
    metric: AirQualityMetric;
    averageValue: number | null;
    rating: Rating | null;
    isMultipleSensors: boolean;
    isMultipleLocations: boolean;
    locationType: LocationFilter;
    className?: string;
}

const MetricState = ({
    metric,
    averageValue,
    rating,
    isMultipleSensors,
    isMultipleLocations,
    locationType,
    className,
}: Props) => {
    const styles = useMetricStateStyles({ rating });
    const iconClasses = useIconStyles();

    const { t } = useTranslation('dashboard');

    const locationLabel = useLocationMetricLabel(
        locationType,
        isMultipleLocations,
        isMultipleSensors,
    );

    const metricValueLabel = useMetricValue(metric, averageValue);

    const RatingLabel = {
        GOOD: t('Good'),
        POOR: t('Poor'),
        FAIR: t('Fair'),
    };

    return (
        <div className={className}>
            <Typography variant="subtitle2" color="textSecondary">
                {locationLabel}
            </Typography>

            <div className={styles.metricValueContainer}>
                <Typography variant="h3" className={styles.metricValue}>
                    {metricValueLabel}
                </Typography>

                {rating && (
                    <Chip
                        className={styles.rating}
                        label={RatingLabel[rating]}
                        data-testid="metric-rating"
                        data-cypress="metric-rating"
                    />
                )}

                {isMultipleSensors && (
                    <Tooltip
                        title={t(
                            'This reading is an average because the selection has more than 1 sensor',
                        )}
                        enterTouchDelay={50}
                        leaveTouchDelay={3000}
                        placement="right"
                    >
                        <Icon
                            classes={iconClasses}
                            data-cypress="reading-info-icon"
                            data-testid="reading-info-icon"
                            className="far fa-info-circle"
                        />
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

export default memo(MetricState);
