/*
    Inspired by https://blog.usejournal.com/polling-with-redux-saga-313cb9c5b34
    Based on https://github.com/devKR2911/redux-polling
 */
import { delay, fork, put, call, race, take } from 'redux-saga/effects';

export const POLL_START = 'POLL_START';
export const POLL_ERROR = 'POLL_ERROR';
export const POLL_STOP = 'POLL_STOP';

export const startPoll = (payload) => ({
    type: POLL_START,
    payload,
});

export const stopPoll = (payload) => ({
    type: POLL_STOP,
    payload,
});

export const pollError = (payload) => ({
    type: POLL_ERROR,
    payload,
});

/*
    `pollSaga` runs the background polling task by running a `while` loop until an error occurs or the polling is
    explicitly stopped.

    The `config` argument is the payload from `startPoll`, which must contain the following parameters:
    - action: the action called when this saga runs. Currently only used for live event updates,
              which does a POST request.
    - delay: the interval in milliseconds between each run of the `action`
    - payload: the payload passed to the POST request made in `action`
               (feel free to refactor this when extending to other use cases)
    - meta: the meta arguments containing information about how the action should run
    - errorAction (optional): possible action to run when an error occurs during the polling action

    Polling starts with the specified delay, after which the action is called.
 */
function* pollSaga(config) {
    while (true) {
        try {
            if (!config.delay) {
                throw new Error('Polling delay must be configured!');
            }

            if (!!config.startTimer && !!config.resetTimer) {
                config.resetTimer();
                config.startTimer();
            }

            // Run polling at the specified interval (in milliseconds)
            yield delay(config.delay);

            yield put(config.action(config.payload, config.meta));
        } catch (err) {
            // Once the polling has encountered an error, it should be stopped immediately
            yield put({
                type: POLL_STOP,
                err,
            });

            if (config.errorAction) {
                // Default error handling cation called.
                yield put({
                    type: config.errorAction,
                    err,
                });
            }
        }
    }
}

/*
    `pollController` starts the `pollSaga` and waits until this particular polling action is stopped.
    When started, the `pollSaga` will be called with the payload passed to `startPoll` that must specify the
    parameters for this specific polling task.
 */
function* pollController(startAction) {
    yield race([
        call(pollSaga, startAction.payload),
        take(
            (stopAction) =>
                stopAction.type === POLL_STOP &&
                stopAction.payload.key === startAction.payload.key,
        ),
    ]);
}

/*
    `pollSagaWatcher` listens for the `POLL_START` actions and forks a new saga
    that will run until this particular polling action stops.
 */
export default function* pollSagaWatcher() {
    while (true) {
        // Taking the POLL_START dispatch action.
        const action = yield take(POLL_START);

        // Custom payload will be available at action object.
        // Using fork allows us to run multiple polling methods simultaneously.
        yield fork(pollController, action);
    }
}
