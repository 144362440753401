import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { combineReducers } from 'redux';
import { put, putResolve } from 'redux-saga/effects';
import { useDebouncedCallback } from 'use-debounce';

import { getKeyValue } from 'utils/relations';

// Generic pagination action types
export const RESET_PAGINATION = '@@ducks/pagination/RESET';
export const RESET_ALL_PAGINATION = '@@ducks/pagination/RESET_ALL';

// Infinite pagination action types
export const SET_NEXT_KWARGS = '@@ducks/pagination/SET_NEXT_KWARGS';
export const SET_CURRENT_KWARGS = '@@ducks/pagination/SET_CURRENT_KWARGS';
export const SET_PREV_KWARGS = '@@ducks/pagination/SET_PREV_KWARGS';
export const SET_TOTAL_COUNT = '@@ducks/pagination/SET_TOTAL_COUNT';

export const setTotalCount = (name, totalCount) => ({
    type: SET_TOTAL_COUNT,
    name,
    totalCount,
});
export const setNextKwargs = (name, kwargs) => ({
    type: SET_NEXT_KWARGS,
    name,
    kwargs,
});
export const setCurrentKwargs = (name, kwargs) => ({
    type: SET_CURRENT_KWARGS,
    name,
    kwargs,
});
export const setPrevKwargs = (name, kwargs) => ({
    type: SET_PREV_KWARGS,
    name,
    kwargs,
});
export const resetPagination = (name) => ({ type: RESET_PAGINATION, name });
export const resetAllPagination = () => ({ type: RESET_ALL_PAGINATION });
export const resetNextKwargs = (name) => setNextKwargs(name, undefined);
export const resetPrevKwargs = (name) => setPrevKwargs(name, undefined);

const initialState = {
    totalCount: 0,
    nextKwargs: null,
    prevKwargs: null,
};

function totalCountReducer(state = {}, action) {
    switch (action.type) {
        case RESET_PAGINATION: {
            const newState = { ...state };

            delete newState[action.name];

            return newState;
        }

        case RESET_ALL_PAGINATION: {
            return {};
        }

        case SET_TOTAL_COUNT:
            return {
                ...state,

                [action.name]: action.totalCount,
            };

        default:
            return state;
    }
}

function nextKwargsReducer(state = {}, action) {
    switch (action.type) {
        case RESET_PAGINATION: {
            const newState = { ...state };

            delete newState[action.name];

            return newState;
        }

        case RESET_ALL_PAGINATION: {
            return {};
        }

        case SET_NEXT_KWARGS:
            return {
                ...state,

                [action.name]: action.kwargs,
            };

        default:
            return state;
    }
}

function currentKwargsReducer(state = {}, action) {
    switch (action.type) {
        case RESET_PAGINATION: {
            const newState = { ...state };

            delete newState[action.name];

            return newState;
        }

        case RESET_ALL_PAGINATION: {
            return {};
        }

        case SET_CURRENT_KWARGS:
            return {
                ...state,

                [action.name]: action.kwargs,
            };

        default:
            return state;
    }
}

function prevKwargsReducer(state = {}, action) {
    switch (action.type) {
        case RESET_PAGINATION: {
            const newState = { ...state };

            delete newState[action.name];

            return newState;
        }

        case RESET_ALL_PAGINATION: {
            return {};
        }

        case SET_PREV_KWARGS:
            return {
                ...state,

                [action.name]: action.kwargs,
            };

        default:
            return state;
    }
}

function hasNextReducer(state = {}, action) {
    switch (action.type) {
        case RESET_PAGINATION: {
            const newState = { ...state };

            delete newState[action.name];

            return newState;
        }

        case RESET_ALL_PAGINATION: {
            return {};
        }

        case SET_NEXT_KWARGS:
            return {
                ...state,

                [action.name]: !!action.kwargs,
            };

        default:
            return state;
    }
}

function hasPrevReducer(state = {}, action) {
    switch (action.type) {
        case RESET_PAGINATION: {
            const newState = { ...state };

            delete newState[action.name];

            return newState;
        }

        case RESET_ALL_PAGINATION: {
            return {};
        }

        case SET_PREV_KWARGS:
            return {
                ...state,

                [action.name]: !!action.kwargs,
            };

        default:
            return state;
    }
}

export default combineReducers({
    totalCount: totalCountReducer,
    nextKwargs: nextKwargsReducer,
    currentKwargs: currentKwargsReducer,
    prevKwargs: prevKwargsReducer,
    hasNext: hasNextReducer,
    hasPrev: hasPrevReducer,
});

export const selectPaginationData = (state) => state.pagination;
export const selectTotalCount = (state, name) =>
    state.pagination.totalCount[name] || initialState.totalCount;
export const selectNextKwargs = (state, name) =>
    state.pagination.nextKwargs[name] || initialState.nextKwargs;
export const selectCurrentKwargs = (state, name) =>
    state.pagination.currentKwargs[name] || initialState.currentKwargs;
export const selectPrevKwargs = (state, name) =>
    state.pagination.prevKwargs[name] || initialState.prevKwargs;
export const selectHasNext = (state, name) => state.pagination.hasNext[name];
export const selectHasPrev = (state, name) => state.pagination.hasPrev[name];

export const paginationMapStateToProps = (state, name) => ({
    nextKwargs: selectNextKwargs(state, name),
    hasNext: selectHasNext(state, name),
});

export const paginationMapStateToPropsFull = (state, name) => ({
    ...paginationMapStateToProps(state, name),
    currentKwargs: selectCurrentKwargs(state, name),
    prevKwargs: selectPrevKwargs(state, name),
    hasPrev: selectHasPrev(state, name),
});

export const paginationMergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    loadNextPage: () => dispatchProps.onFetchMore({ query: stateProps.nextKwargs }),
    reloadPage: () => dispatchProps.onFetchMore({ query: stateProps.currentKwargs }),
    loadPrevPage: () => dispatchProps.onFetchMore({ query: stateProps.prevKwargs }),
    // If you have issues with this, make sure `onFetchMore` accepts multiple arguments in mapDispatchToProps
    loadMore: (_0 = null, meta = {}) =>
        dispatchProps.onFetchMore(
            { query: stateProps.nextKwargs },
            { mergeEntities: true, updateOrder: true, ...meta },
        ),
});

export const useLoadMore = (onFetchMore, nextKwargs, hasMore, debounceDelay = 100) => {
    const callback = useCallback(
        (payload = {}, meta = {}) =>
            onFetchMore(
                {
                    ...payload,
                    query: { ...payload?.query, ...nextKwargs },
                },
                {
                    mergeEntities: true,
                    updateOrder: true,
                    ...meta,
                },
            ),
        [onFetchMore, nextKwargs],
    );
    // We use debounce with a short delay to avoid a race condition between react and redux-saga
    const [debouncedCallback, cancel] = useDebouncedCallback(callback, debounceDelay, {
        leading: true,
    });

    useEffect(() => {
        // Explicitly cancel any existing 'load more' callbacks when `!hasMore`
        // This helps against too many calls to `onFetchMore`
        // This is a bit hacky tho :/
        if (!hasMore) {
            cancel();
        }
    }, [hasMore, cancel]);

    return debouncedCallback;
};

export const createPaginationSuccessHook = (
    key,
    setNextOnly = false,
    fetchNextIfEmpty = false,
) =>
    function* paginationSuccessHook(result, match, action) {
        const name = getKeyValue(key, match, action?.meta?.keyOptions);

        if (result && !Array.isArray(result)) {
            yield put(setTotalCount(name, result.count));
            yield put(setNextKwargs(name, result.next));

            if (!setNextOnly) {
                yield put(setCurrentKwargs(name, action.query));
                yield put(setPrevKwargs(name, result.previous));
            }

            // Be careful, recursive calling ahead, make sure the exit condition works
            if (fetchNextIfEmpty && result.results?.length === 0 && result.next) {
                const nextAction = {
                    ...action,
                    payload: {
                        ...action?.payload,
                        query: { ...action?.payload?.query, ...result.next },
                    },
                };

                yield put(nextAction);
            }
        } else {
            yield putResolve(resetNextKwargs(name));
        }
    };

export const paginationPropTypes = {
    nextKwargs: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    hasNext: PropTypes.bool,
};

export const paginationPropTypesFull = {
    // Manually set in mapDispatchToProps
    onFetchMore: PropTypes.func.isRequired,
    // Use paginationMapStateToPropsFull
    ...paginationPropTypes,
    currentKwargs: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    prevKwargs: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    hasPrev: PropTypes.bool,
    // Use paginationMergeProps
    loadNextPage: PropTypes.func,
    reloadPage: PropTypes.func,
    loadPrevPage: PropTypes.func,
    loadMore: PropTypes.func,
};

export const paginationDefaultProps = {
    nextKwargs: null,
    hasNext: false,
};

export const paginationDefaultPropsFull = {
    ...paginationDefaultProps,
    currentKwargs: null,
    prevKwargs: null,
    loadNextPage: null,
    reloadPage: null,
    loadPrevPage: null,
    loadMore: null,
};
