import { ButtonLink } from '@infogrid/components-material-ui';
import { useAppSelector } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { selectUserIsManager } from '@infogrid/user-ducks';
import WarningIcon from '@material-ui/icons/Warning';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSetThresholdsStyles } from './styles';

const ThresholdsNotSetAlert = () => {
    const { t } = useTranslation('solutions');
    const styles = useSetThresholdsStyles();

    const isManager = useAppSelector(selectUserIsManager);

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <WarningIcon classes={{ root: styles.icon }} fontSize="large" />
                <p className={styles.text}>
                    {t(
                        'Your organization currently does not have pipe monitoring temperature thresholds set up. You have to set up thresholds for your organization to use this widget. ',
                    )}
                    {!isManager &&
                        t(
                            'Ask a manager in your organization to set up the pipe monitoring temperature thresholds.',
                        )}
                </p>
                {!!isManager && (
                    <div className={styles.buttons}>
                        <ButtonLink
                            to={routesManager.resolvePath(
                                'solution-settings:solution-thresholds',
                                {},
                                {},
                                { shouldRedirectBack: true },
                            )}
                            color="primary"
                            type="submit"
                            className={styles.button}
                            data-cypress={`redirect-button`}
                        >
                            {t('set thresholds')}
                        </ButtonLink>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(ThresholdsNotSetAlert);
