import { Modal } from '@infogrid/components-material-ui';
import { Typography, Button } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Alert from '@material-ui/lab/Alert';
import { useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import RulesSection from 'views/solutionSettings/pages/smartCleaning/CleaningRules/components/RulesSection';

import { useStyles, useModalStyles } from './styles';
import type { CleaningValues } from './types';

interface RulesModalProps {
    open: boolean;
    handleClose: () => void;
}

export const RulesModal = memo(function RulesModal({
    open,
    handleClose,
}: RulesModalProps): JSX.Element {
    const { t } = useTranslation('solutions');
    const styles = useStyles();
    const modalStyles = useModalStyles();

    const {
        setFieldValue,
        handleSubmit,
        values: { might_need_cleaning_value, must_be_cleaned_value },
        errors,
    } = useFormikContext<CleaningValues>();

    const onSelectMightNeedCleaningValue = (value: number | 'N/A' | undefined) => {
        setFieldValue('might_need_cleaning_value', value);
    };

    const onSelectMustBeCleanedValue = (value: number | undefined) => {
        setFieldValue('must_be_cleaned_value', value);
    };

    return (
        <Modal
            classes={modalStyles}
            onClose={handleClose}
            open={open}
            fullscreenMobile
            data-testid="insights-card-location-modal"
        >
            <Modal.Title>{t('Edit cleaning rule')}</Modal.Title>
            <Modal.Content className={modalStyles.content}>
                <Alert
                    className={styles.alert}
                    icon={<InfoIcon fontSize="inherit" />}
                    color="info"
                >
                    {t(
                        'Note that this will overwrite any existing rules applied to all the locations you’ve selected.',
                    )}
                </Alert>
                <Typography
                    className={styles.labelText}
                    color="textSecondary"
                    variant="body2"
                >
                    {t(
                        'Edit the rule for cleaning based on the usage of the locations you’ve selected.',
                    )}
                </Typography>
                <RulesSection
                    mightNeedCleaningValue={might_need_cleaning_value}
                    mustBeCleanedValue={must_be_cleaned_value}
                    setMightNeedCleaningValue={onSelectMightNeedCleaningValue}
                    setMustBeCleanedValue={onSelectMustBeCleanedValue}
                    errors={errors}
                />
            </Modal.Content>
            <Modal.Actions justify="right">
                <Button variant="text" color="primary" onClick={handleClose}>
                    {t('Cancel')}
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={() => handleSubmit()}
                    disabled={!isEmpty(errors)}
                >
                    {t('Save')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
});
