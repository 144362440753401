import type { RouteConfig } from 'react-router-config';

import RenderChildren from 'components/RenderChildren';
import { userIsAuthenticated } from 'decorators/auth';
import { PublicDisplay } from 'views/publicDisplays/PublicDisplay';

export const createPublicDisplaysRoutes = (
    PageNotFoundRoute: RouteConfig,
): RouteConfig => ({
    path: '/public-displays/',
    name: 'public-displays',
    component: userIsAuthenticated(RenderChildren),
    routes: [
        {
            path: '/public-displays/:buildingId/floors/:floorId',
            exact: true,
            name: 'details',
            pageName: 'Public Display',
            component: PublicDisplay,
        },
        PageNotFoundRoute,
    ],
});
