import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo } from 'react';

import { useWidgetOptionsStyles } from './styles';

interface WidgetOptionsProps {
    children: ReactNode;
    className?: string;
}

const WidgetOptions = ({ children, className }: WidgetOptionsProps) => {
    const styles = useWidgetOptionsStyles();

    return (
        <div className={classNames(styles.root, className)} data-cypress="widget-option">
            {children}
        </div>
    );
};

export default memo(WidgetOptions);
