import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import { makeStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import WidgetAlert from 'views/dashboards/widgets/common/WidgetAlert';

import { GenericFooter, GenericTitle } from '../Widget';
import Widget from '../Widget/Widget';

const useFooterStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(0.5, 3),
        minHeight: 41,
    },
}));

const ErrorWidget = ({ title }: { title: string }) => {
    const { t } = useTranslation('dashboard');
    const styles = useFooterStyles();

    return (
        <Widget>
            <Widget.Bar canDragAndResize={false}>
                <GenericTitle
                    title={title}
                    titleIcon={<ErrorOutline />}
                    titleEditable={false}
                />
            </Widget.Bar>
            <Widget.Content>
                <WidgetAlert severity="error">
                    {t(WidgetErrorMessages.unhandledError)}
                </WidgetAlert>
            </Widget.Content>
            <GenericFooter className={styles.footer} />
        </Widget>
    );
};

export default ErrorWidget;
