import { SearchFilterInput } from '@infogrid/components-material-ui';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import {
    onBuildingSearchPath,
    useFormattedBuildingQueryParams,
} from 'utils/filtering/building';

const SearchFilter = () => {
    const location = useLocation();
    const history = useHistory();
    const { q } = useFormattedBuildingQueryParams();

    const { t } = useTranslation('estate');

    const onSearch = (searchVal: string) => {
        history.push(
            onBuildingSearchPath(location, {
                q: searchVal,
            }),
        );
    };

    const onClear = useCallback(() => {
        history.push(onBuildingSearchPath(location, { q: '' }));
    }, [history, location]);

    return (
        <SearchFilterInput
            onClear={onClear}
            onSearch={onSearch}
            placeholder={t('Search buildings...')}
            value={q}
        />
    );
};

export default memo(SearchFilter);
