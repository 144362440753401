import { createRequest } from '@infogrid/core-api';
import type {
    IPagination,
    CreateFolder,
    DeleteFolders,
    Folder,
    MoveFolder,
    UpdateFolder,
} from '@infogrid/core-types';
import type { AxiosPromise } from 'axios';
import type { ParsedQs } from 'qs';

const moveFolder = (folderId: number, data: MoveFolder): AxiosPromise<Folder> =>
    createRequest({
        method: 'patch',
        url: `folders/${folderId}/move-v2`,
        options: { data },
    });

const getFolder = (
    folderId: number,
    signal: AbortSignal | undefined,
): AxiosPromise<Folder> =>
    createRequest({ method: 'get', url: `folders/${folderId}`, signal });

const getFolders = (
    folderIds: number[],
    signal: AbortSignal | undefined,
    params: ParsedQs,
): AxiosPromise<IPagination<Folder[]>> =>
    createRequest({
        method: 'get',
        url: `folders?ids=${folderIds.join(',')}`,
        signal,
        options: {
            params: { ...params, page_size: folderIds.length },
        },
    });

const getSubfolders = (
    folderId: number,
    signal: AbortSignal | undefined,
    params: ParsedQs,
): AxiosPromise<IPagination<Folder>> =>
    createRequest({
        method: 'get',
        url: `folders/${folderId}/subfolders`,
        signal,
        options: {
            params,
        },
    });

const createFolder = (data: CreateFolder): AxiosPromise<Folder> =>
    createRequest({
        method: 'post',
        url: 'folders',
        options: {
            data,
        },
    });

const updateFolder = (folderId: number, data: UpdateFolder): AxiosPromise<Folder> =>
    createRequest({
        method: 'patch',
        url: `folders/${folderId}`,
        options: {
            data,
        },
    });

const deleteFolders = (folderId: number, data: DeleteFolders): AxiosPromise<number> =>
    createRequest({
        method: 'delete',
        url: `folders/${folderId}/delete_subfolders`,
        options: {
            data,
        },
    });

export const controllers = {
    getFolder,
    getFolders,
    getSubfolders,
    moveFolder,
    createFolder,
    updateFolder,
    deleteFolders,
};
