import { selectSpaces } from '@infogrid/locations-ducks';
import type { FloorDetail, Space } from '@infogrid/locations-types';
import { CircularProgress, Divider } from '@material-ui/core';
import qs from 'qs';
import { memo, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useFloorSensors } from 'apiHooks/floorPlan/sensors/hooks';
import { useSpaces } from 'apiHooks/floorPlan/spaces/hooks';
import DeleteSpaceModal from 'views/estate/components/modals/DeleteSpaceModal';
import EditSpaceModal from 'views/estate/components/modals/EditSpaceModal';
import { useSpaceModalContext } from 'views/estate/components/modals/EditSpaceModal/context';
import { selectUnassignedSensors } from 'views/estate/utils';

import AddSpaces from './AddSpaces';
import EstateFloorViewHeader from './EstateFloorViewHeader';
import SpaceContainer from './SpaceContainer';
import SpacesTable from './SpacesTable';
import SpacesTableFilters from './SpacesTableFilters';
import UnassignedSensorsBanner from './UnassignedSensorsBanner';
import { useEstateFloorViewStyles } from './styles';

interface Props {
    buildingId: number;
    buildingName: string | undefined;
    floor: FloorDetail;
    floorId: number;
    isUnassignedSensorsSidebarOpen: boolean;
    selectedSpaceId: number | null;
    setSelectedSpaceId: (spaceId: number | null) => void;
    toggleUnassignedSensorsSidebar: () => void;
}

const EstateFloorView = ({
    buildingId,
    buildingName,
    floor,
    floorId,
    isUnassignedSensorsSidebarOpen,
    selectedSpaceId,
    setSelectedSpaceId,
    toggleUnassignedSensorsSidebar,
}: Props): JSX.Element => {
    const styles = useEstateFloorViewStyles();

    const location = useLocation();

    const [spaceToDelete, setSpaceToDelete] = useState<Space | null>(null);
    const { spaceToEdit, setSpaceToEdit } = useSpaceModalContext();

    const query = useMemo(
        () =>
            location.search
                ? qs.parse(location.search, { ignoreQueryPrefix: true })
                : null,
        [location.search],
    );

    const {
        data: spaces,
        fetchNextPage,
        hasNextPage,
        isFetchedAfterMount,
        isFetching,
        isFetchingNextPage,
        isPreviousData,
    } = useSpaces({
        params: { floor: +floorId, ...query, 'include-user-actions': true },
    });

    const { data: unassignedSensorData } = useFloorSensors(
        { floorId },
        { select: selectUnassignedSensors },
    );
    const unassignedSensorsCount = unassignedSensorData?.sensors.length ?? null;

    const spacesData = useMemo(() => selectSpaces(spaces), [spaces]);

    const isLoadingFirstPage = isFetching && !isFetchedAfterMount;

    const hasContent = spacesData.spaces && spacesData.spaces.length > 0;

    const fetchMoreSpaces = useCallback(() => {
        if (isFetchingNextPage || !hasNextPage) {
            return;
        }

        fetchNextPage();
    }, [fetchNextPage, isFetchingNextPage, hasNextPage]);

    const setActiveSpace = useCallback(
        (spaceIndex: number | null) =>
            setSelectedSpaceId(
                spaceIndex !== null ? spacesData.spaces[spaceIndex].id : null,
            ),
        [setSelectedSpaceId, spacesData],
    );
    const hasNoSpaces = spacesData?.spacesCount === 0 && query === null;

    const isFetchingSpaces = isFetching && !isFetchingNextPage;

    const onCloseDeleteModal = () => {
        setSpaceToDelete(null);
    };

    const onDeleteConfirm = useCallback(() => {
        if (selectedSpaceId === spaceToDelete?.id) {
            setSelectedSpaceId(null);
        }
    }, [selectedSpaceId, setSelectedSpaceId, spaceToDelete]);

    return (
        <div className={styles.container} data-cypress="spaces-container">
            <EstateFloorViewHeader
                buildingId={buildingId}
                buildingName={buildingName}
                floor={floor}
            />

            <Divider />

            <SpaceContainer
                buildingId={buildingId}
                floor={floor}
                enableAddingSpaces={!hasNoSpaces}
            />

            {isFetchingSpaces && (
                <div className={styles.loader}>
                    <CircularProgress />
                </div>
            )}

            {!hasNoSpaces && (
                <div className={styles.filtersContainer}>
                    <SpacesTableFilters
                        buildingId={buildingId}
                        floorId={+floorId}
                        forceMobileView={Boolean(
                            isUnassignedSensorsSidebarOpen || selectedSpaceId,
                        )}
                    />
                </div>
            )}

            {unassignedSensorsCount && (
                <UnassignedSensorsBanner
                    isSidebarOpen={isUnassignedSensorsSidebarOpen}
                    sensorsCount={unassignedSensorsCount}
                    toggleSidebar={toggleUnassignedSensorsSidebar}
                />
            )}

            {hasNoSpaces && !isFetchingSpaces && (
                <AddSpaces
                    buildingId={buildingId}
                    canAddSpaces={Boolean(floor.user_actions?.edit?.allowed)}
                    floorId={+floorId}
                />
            )}

            {!hasNoSpaces && !isFetchingSpaces && (
                <SpacesTable
                    fetchNextPage={fetchMoreSpaces}
                    isLoading={isLoadingFirstPage && !hasContent && !isPreviousData}
                    selectedSpaceId={selectedSpaceId}
                    setActiveSpace={setActiveSpace}
                    spaces={spacesData.spaces}
                />
            )}
            {spaceToEdit && (
                <EditSpaceModal
                    buildingId={+buildingId}
                    floorId={floorId}
                    onClose={() => setSpaceToEdit(null)}
                    open={!!spaceToEdit}
                    setSelectedSpaceId={setSelectedSpaceId}
                    space={spaceToEdit}
                />
            )}

            <DeleteSpaceModal
                buildingId={+buildingId}
                floorId={floor.id}
                onClose={onCloseDeleteModal}
                onConfirm={onDeleteConfirm}
                open={!!spaceToDelete}
                spaceId={spaceToDelete?.id}
            />
        </div>
    );
};

export default memo(EstateFloorView);
