import { Button, Icon, Typography } from '@material-ui/core';
import classNames from 'classnames';
import type { Location } from 'history';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useErrorViewStyles } from './styles';

interface Props {
    buttonLink?: Location;
    buttonText?: string;
    classes?: {
        container?: string;
    };
    icon: string;
    description: string;
}

const ErrorView = ({ buttonLink, buttonText, classes, icon, description }: Props) => {
    const styles = useErrorViewStyles();

    const { t } = useTranslation('estate');

    return (
        <div className={classNames(styles.errorContainer, classes?.container)}>
            <Icon
                className={classNames(icon, styles.errorIcon)}
                title={t('Error Icon')}
            />
            <Typography className={styles.errorText}>{description}</Typography>
            {buttonLink && buttonText && (
                <Link data-testid="button-link" to={buttonLink}>
                    <Button
                        className={styles.errorButton}
                        color="primary"
                        variant="contained"
                    >
                        <Typography variant="subtitle2">{buttonText}</Typography>
                    </Button>
                </Link>
            )}
        </div>
    );
};

export default memo(ErrorView);
