import { EntityStatus, entitiesActions } from '@thorgate/spa-entities';

import { resetPagination } from 'ducks/pagination';

// Collection of entity ids based on key (e.g. folders-0-sensors)
// (state, collectionKey) => [id, id]
export const selectEntityCollectionIds = (state, collectionKey) =>
    state.entities.order[collectionKey];

export const selectAllEntityCollections = (state) => state.entities.order;

// Note: This doesn't remove the entity data from state.entities.data
export const resetEntity = (dispatch, key) => {
    dispatch(entitiesActions.purgeOrder({ key }));
    dispatch(
        entitiesActions.setEntitiesStatus({
            key,
            status: EntityStatus.NotLoaded,
        }),
    );
    dispatch(resetPagination(key));
};
