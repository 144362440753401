import { Modal } from '@infogrid/components-material-ui';
import { queryClient } from '@infogrid/core-api';
import { Button, Card, CircularProgress, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { RouteConfigComponentProps } from 'react-router-config';

import PageContainer from 'components/PageContainer';
import withView from 'decorators/withView';
import { getSmartCleaningHierarchyLocationsKey } from 'views/solutionSettings/apiHooks/getQueryKeys';
import type { SmartCleaningActivatedCountResponse } from 'views/solutionSettings/apiHooks/types';
import { useGetSmartCleaningActivatedCount } from 'views/solutionSettings/apiHooks/useGetSmartCleaningActivatedCount';
import { useGetSmartCleaningHierarchyLocations } from 'views/solutionSettings/apiHooks/useGetSmartCleaningHierarchy';
import { useSetSmartCleaningActivatedDeactivatedSpaces } from 'views/solutionSettings/apiHooks/useSetSmartCleaningActivatedDeactivedSpaces';
import { LocationsSelectorCounter } from 'views/solutionSettings/components/LocationSelectorCounter';
import type {
    PaginatedTreeChildNode,
    PaginatedTreeCurrentNode,
} from 'views/solutionSettings/components/PaginatedTreeSelector';
import PaginatedTreeSelector from 'views/solutionSettings/components/PaginatedTreeSelector';
import type { NodeIdAndSelectedState } from 'views/solutionSettings/types';

import { getOptionLabelFromType } from '../utils/selectionUtils';
import { SuccessModal } from './components/SuccessModal';
import { useLocationsStyles } from './styles';
import {
    addNewSelectedOrDeselectedNode,
    changedLocationsCount,
    changeIsSelected,
} from './utils';

interface Props {
    route: RouteConfigComponentProps['route'];
}

const Locations = ({ route }: Props) => {
    const { t } = useTranslation('solutions');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentPageId, setCurrentPageId] = useState<number | null>(null);
    const [flattenedLocations, setFlattenedLocations] =
        useState<PaginatedTreeCurrentNode<PaginatedTreeChildNode> | null>(null);

    const [activatedDeactivedNodes, setActivatedDeactivatedNodes] = useState({
        activated: new Set<number>(),
        deactivated: new Set<number>(),
    });

    const [currentNode, setCurrentNode] =
        useState<PaginatedTreeCurrentNode<PaginatedTreeChildNode> | null>(null);

    const [prevLocationCount, setPrevLocationCount] =
        useState<SmartCleaningActivatedCountResponse>({
            building_count: 0,
            floor_count: 0,
            space_count: 0,
        });

    const {
        mutate,
        data: updatedLocationCount,
        isSuccess,
        isLoading: activatedDeactivedSubmitIsLoading,
        reset: resetActivatedDeactivatedSpacesMutation,
    } = useSetSmartCleaningActivatedDeactivatedSpaces();

    const styles = useLocationsStyles({ isSuccess });

    const { refetch: refetchActivatedCount } = useGetSmartCleaningActivatedCount(
        {},
        {
            onSuccess: (data) => {
                setPrevLocationCount(data);
            },
        },
    );

    const params = {
        show_activated_only: false,
        folder_id: currentPageId,
    };

    const { isLoading: locationsIsLoading } = useGetSmartCleaningHierarchyLocations(
        params,
        activatedDeactivedNodes,
        {
            onSuccess: (data) => {
                if (data) {
                    // On page load pageId isn't set
                    if (!currentPageId) {
                        setCurrentPageId(data.id);
                    }

                    setFlattenedLocations(data);
                }
            },
            staleTime: Infinity,
        },
    );

    const openModal = () => {
        // invalidate any old stale queries before we open the modal
        const queryKey = getSmartCleaningHierarchyLocationsKey(params);
        queryClient.invalidateQueries(queryKey);
        setModalIsOpen(true);
    };

    const onSave = () => {
        mutate({
            ...(activatedDeactivedNodes.activated.size > 0 && {
                locations_to_activate: Array.from(
                    activatedDeactivedNodes.activated,
                ).toString(),
            }),
            ...(activatedDeactivedNodes.deactivated.size > 0 && {
                locations_to_deactivate: Array.from(
                    activatedDeactivedNodes.deactivated,
                ).toString(),
            }),
        });
    };

    const resetAllOnClose = (reason?: 'escapeKeyDown' | 'backdropClick') => {
        if (reason === 'backdropClick') {
            return;
        }

        refetchActivatedCount();
        resetActivatedDeactivatedSpacesMutation();
        setModalIsOpen(false);
        setActivatedDeactivatedNodes({
            activated: new Set(),
            deactivated: new Set(),
        });
        setCurrentPageId(null);
        setCurrentNode(null);
        setFlattenedLocations(null);
        queryClient.invalidateQueries('smart-cleaning-hierarchy-locations');
    };

    useEffect(() => {
        setCurrentNode(null);
    }, [currentPageId]);

    const changePage = (id: number) => {
        setCurrentPageId(id);

        const queryKey = getSmartCleaningHierarchyLocationsKey({
            folder_id: id,
            show_activated_only: false,
        });
        const cachedData =
            queryClient.getQueryData<PaginatedTreeCurrentNode<PaginatedTreeChildNode>>(
                queryKey,
            );

        if (cachedData) {
            setFlattenedLocations(cachedData);
        }
    };

    const changeSelection = ({ selected, nodeIds }: NodeIdAndSelectedState) => {
        if (flattenedLocations) {
            // Mutates cache data, returns up to date view
            // and removes children / parent activated / deactivated ids
            // from the sets and returns updated values
            const changedData = changeIsSelected(
                flattenedLocations,
                nodeIds,
                selected,
                activatedDeactivedNodes,
            );

            setFlattenedLocations(changedData.currentLocation);

            setActivatedDeactivatedNodes(
                addNewSelectedOrDeselectedNode({
                    selected,
                    nodeIds,
                    activatedDeactivedNodes: changedData.currentSelectedNodes,
                }),
            );
        }
    };

    useEffect(() => {
        if (flattenedLocations) {
            setCurrentNode(flattenedLocations);
        }
    }, [flattenedLocations]);

    const isSaveButtonDisabled = () => {
        return (
            activatedDeactivedNodes.activated.size === 0 &&
            activatedDeactivedNodes.deactivated.size === 0
        );
    };

    return (
        <>
            <Helmet defaultTitle={t(route?.pageName || 'Manage solutions')} />
            <PageContainer className={styles.page}>
                <div className={styles.header}>
                    <Typography component="h1" variant="h4" className={styles.pageTitle}>
                        {t('Select Smart Cleaning locations')}
                    </Typography>
                    <Typography
                        className={styles.description}
                        color="textSecondary"
                        variant="body2"
                    >
                        {t(
                            'Data will be collected from sensors in the floors and spaces you select to help you understand what’s been used and needs cleaning.',
                        )}
                    </Typography>
                </div>
                <Card className={styles.selectedLocationsCard}>
                    <Typography>
                        {t(
                            '{{prevSpaceCount}} spaces (in {{prevFloorCount}} floors in {{prevBuildingCount}} buildings)',
                            {
                                prevSpaceCount: prevLocationCount.space_count,
                                prevFloorCount: prevLocationCount.floor_count,
                                prevBuildingCount: prevLocationCount.building_count,
                            },
                        )}
                    </Typography>
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={() => openModal()}
                    >
                        Edit locations
                    </Button>
                </Card>
                <Modal
                    scroll="paper"
                    onClose={(_e, reason) => resetAllOnClose(reason)}
                    maxWidth="md"
                    fullscreenMobile
                    open={modalIsOpen}
                    classes={styles}
                >
                    {isSuccess ? (
                        <SuccessModal
                            handleSuccessOnClick={resetAllOnClose}
                            locationChangeCount={changedLocationsCount({
                                previousLocationCount: prevLocationCount,
                                newLocationCount: updatedLocationCount,
                            })}
                        />
                    ) : (
                        <div className={styles.locationPicker}>
                            <Modal.Title className={styles.modalTitle}>
                                {t('Select Smart Cleaning locations')}
                            </Modal.Title>
                            <Modal.Description>
                                {t(
                                    'Select the locations to include in the Smart Cleaning solution.',
                                )}
                            </Modal.Description>
                            <Modal.Content className={styles.modalContent}>
                                {!locationsIsLoading && currentNode ? (
                                    <>
                                        <div className={styles.locationPickerContainer}>
                                            <PaginatedTreeSelector<
                                                PaginatedTreeChildNode,
                                                PaginatedTreeCurrentNode<PaginatedTreeChildNode>
                                            >
                                                content={currentNode}
                                                getLabelFromType={() =>
                                                    getOptionLabelFromType(currentNode)
                                                }
                                                onChangeSelection={changeSelection}
                                                onChangePage={changePage}
                                            />
                                        </div>
                                        <div className={styles.modalFooter}>
                                            <LocationsSelectorCounter
                                                buttonCallback={onSave}
                                                onCancel={resetAllOnClose}
                                                buttonText={t('Save')}
                                                isLoading={
                                                    activatedDeactivedSubmitIsLoading
                                                }
                                                getIsSaveButtonDisabled={
                                                    isSaveButtonDisabled
                                                }
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <CircularProgress color="primary" size={30} />
                                )}
                            </Modal.Content>
                        </div>
                    )}
                </Modal>
            </PageContainer>
        </>
    );
};

export default withView()(Locations);
