import { initialState, initialModalsState } from './floorPlan.state';
import {
    SET_IS_CREATING_BUILDING,
    SET_IS_CREATING_FLOOR,
    SET_IS_DELETING_BUILDING,
    SET_IS_EDITING_BUILDING,
    SET_IS_DELETING_FLOOR,
    SET_IS_EDITING_FLOOR,
    SET_EDIT_FLOOR_ID,
    SET_IS_CREATING_FOLDER,
    SET_IS_EDITING_FLOOR_IMAGE,
    SET_IS_REPLACING_FLOOR_IMAGE,
    SET_SHOULD_KEEP_SENSORS_MAPPED,
} from './floorplan.actions';

export function floorPlanReducer(state = initialState, action) {
    switch (action.type) {
        case SET_IS_CREATING_BUILDING: {
            return {
                ...state,
                ...initialModalsState,
                isCreatingBuilding: action.isCreatingBuilding,
            };
        }
        case SET_IS_CREATING_FLOOR: {
            return {
                ...state,
                ...initialModalsState,
                isCreatingFloor: action.isCreatingFloor,
            };
        }
        case SET_IS_DELETING_BUILDING: {
            return {
                ...state,
                ...initialModalsState,
                isDeletingBuilding: action.isDeletingBuilding,
            };
        }

        case SET_IS_REPLACING_FLOOR_IMAGE: {
            return {
                ...state,
                ...initialModalsState,
                isReplacingFloorImage: action.isReplacingFloorImage,
            };
        }
        case SET_IS_EDITING_FLOOR_IMAGE: {
            return {
                ...state,
                ...initialModalsState,
                isEditingFloorImage: action.isEditingFloorImage,
                isOpenEditingFloorImageFromReplaceModal: action.isToggleFromReplaceModal,
            };
        }
        case SET_IS_EDITING_BUILDING: {
            return {
                ...state,
                ...initialModalsState,
                isEditingBuilding: action.isEditingBuilding,
            };
        }
        case SET_IS_EDITING_FLOOR: {
            return {
                ...state,
                ...initialModalsState,
                isEditingFloor: action.isEditingFloor,
            };
        }
        case SET_IS_DELETING_FLOOR: {
            return {
                ...state,
                ...initialModalsState,
                isDeletingFloor: action.isDeletingFloor,
            };
        }
        case SET_IS_CREATING_FOLDER: {
            return {
                ...state,
                ...initialModalsState,
                isCreatingFolder: action.isCreatingFolder,
            };
        }
        case SET_EDIT_FLOOR_ID: {
            return {
                ...state,
                editFloorId: action.editFloorId,
            };
        }
        case SET_SHOULD_KEEP_SENSORS_MAPPED: {
            return {
                ...state,
                shouldKeepSensorsMapped: action.shouldKeepSensorsMapped,
            };
        }

        default:
            return state;
    }
}
