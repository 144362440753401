import type { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export const monnitDryPulseConfigurationFormValidationSchema = (
    t: TFunction,
): Yup.ObjectSchema =>
    Yup.object().shape({
        initial_meter_reading: Yup.number()
            .typeError(t('Initial Meter Reading must be a number', { ns: 'sensors' }))
            .required(t('Initial Meter Reading is required', { ns: 'sensors' }))
            .label(t('Initial Meter Reading', { ns: 'sensors' })),
        pulse_size: Yup.number()
            .typeError(t('Pulse Size must be a number', { ns: 'sensors' }))
            .required(t('Pulse Size is required', { ns: 'sensors' }))
            .label(t('Pulse Size', { ns: 'sensors' })),
    });
