import type {
    FeedbackPanelSensorConfiguration,
    FeedbackPanelTouchEventV2Shape,
} from '@infogrid/sensors-constants';

// TODO: Move this into one of the event descriptor files (utils/readingTypes, utils/eventTypes, igEvents).
//       This is not trivial and they are quite a mess right now.
//       So adding it here instead to avoid further spaghetti.
export const getFeedbackPanelReading = (
    eventV1: FeedbackPanelTouchEventV2Shape,
    sensorConfiguration: FeedbackPanelSensorConfiguration,
): string =>
    sensorConfiguration.sensor_options?.find(
        (sensorOption) => sensorOption.option.code === eventV1.value.option_code,
    )?.option?.display ?? '?';
