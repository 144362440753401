import { makeStyles } from '@material-ui/core';

export const useDashboardPageStyles = makeStyles({
    pageContainer: {
        height: '100%',
    },
    spinnerContainer: {
        alignItems: 'center',
        display: 'flex',
        height: 'calc(100vh - 96px)',
        justifyContent: 'center',
    },
});
