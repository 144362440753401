import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import { Typography } from '@material-ui/core';
import { memo } from 'react';

import SensorLastReading from 'components/material-ui/Sensors/SensorLastReading';
import TimeSpentTooltip from 'components/material-ui/TimeSpentTooltip';
import { useLastReadingTimestamp } from 'utils/hooks/useLastReadingTimestamp';

import { useSensorStatusStyles } from './styles';

interface SensorStatusProps {
    sensor: GenericSensorShape;
}

const SensorStatus = ({ sensor }: SensorStatusProps) => {
    const sensorDescriptionStyles = useSensorStatusStyles();

    const { label: sensorLabel } = getSensorConfig(sensor.type_code);
    const lastReadingTimestamp = useLastReadingTimestamp(sensor);

    return (
        <section className={sensorDescriptionStyles.descriptionContainer}>
            <Typography
                className={sensorDescriptionStyles.descriptionLabel}
                variant="body2"
                component="span"
                data-cypress="sensor-type"
            >
                {sensorLabel}
            </Typography>

            <TimeSpentTooltip
                arrow
                date={lastReadingTimestamp}
                disableHoverListener={!lastReadingTimestamp}
                timeZone={null}
                autoUpdate={true}
            >
                <Typography
                    className={sensorDescriptionStyles.descriptionLabel}
                    variant="body2"
                    component="span"
                    data-cypress="sensor-status"
                >
                    <SensorLastReading sensor={sensor} />
                </Typography>
            </TimeSpentTooltip>
        </section>
    );
};

export default memo(SensorStatus);
