import { makeStyles } from '@material-ui/core';
import type { Theme } from '@material-ui/core';

export const useTreeNodeButtonStyles = makeStyles<Theme, { depth?: number }>((theme) => ({
    treeNodeContent: {
        display: 'flex',
        alignItems: 'center',
        height: theme.spacing(4),
        paddingRight: theme.spacing(0.7),
        marginLeft: ({ depth }) => `calc(${depth ?? 1} * ${theme.spacing(3)}px)`,
    },
    treeNodeCaretNone: {
        marginLeft: theme.spacing(3.2),
    },
}));
