import { Modal } from '@infogrid/components-material-ui';
import { Button, Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    isOpen?: boolean;
    onDiscard?: () => void;
    onResume?: () => void;
}

const InstallFlowConfirmation = ({
    isOpen = false,
    onDiscard = () => {},
    onResume = () => {},
}: Props) => {
    const { t } = useTranslation('sensors');

    return (
        <Modal open={isOpen} maxWidth="xs" onClose={onResume}>
            <Modal.Title>{t('Exit installation')}</Modal.Title>
            <Modal.Content>
                <Typography component="div" variant="body2" data-cypress="warning-text">
                    {t(
                        'You are about to exit device installation. All progress will be lost. Are you sure you want to continue',
                    )}
                </Typography>
            </Modal.Content>
            <Modal.Actions justify="space-between">
                <Button color="primary" onClick={onResume} data-cypress="resume">
                    {t('Resume')}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    data-cypress="warning-exit"
                    onClick={onDiscard}
                >
                    {t('Exit')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(InstallFlowConfirmation);
