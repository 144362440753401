import { isObject } from '../is';
import { BaseValidationError, bindAndCoerce } from './BaseValidationError';
import { ParentValidationError } from './ParentValidationError';

export class ValidationError extends ParentValidationError {
    constructor(errors = null, nonFieldErrors = null) {
        if (process.env.NODE_ENV !== 'production') {
            // Takes: anything thats not a ValidationError (besides null)
            const filterFn = (key) => {
                if (!errors || errors[key] === null) {
                    return false;
                }

                return !errors[key] || !(errors[key] instanceof BaseValidationError);
            };

            if (errors) {
                if (
                    !isObject(errors) ||
                    Object.keys(errors).filter(filterFn).length > 0
                ) {
                    /* eslint-disable no-console */
                    console.error(
                        'ListValidationError: `errors argument` must be an object of BaseValidationError? instances',
                    );
                    console.error(
                        '    Supported Builtins: null/SingleValidationError/ListValidationError/ValidationError',
                    );
                    /* eslint-enable no-console */
                }
            }
        }

        // MAP: falsy to null, bind all errors w/ their fieldName
        const mutErrors = {};

        if (errors) {
            Object.keys(errors).forEach((fieldName) => {
                mutErrors[fieldName] = bindAndCoerce(errors[fieldName], fieldName);
            });
        }

        super(mutErrors);
        // Bind nonFieldErrors
        this.nonFieldErrors = nonFieldErrors || null;

        if (this.nonFieldErrors !== null) {
            this.nonFieldErrors.bindToField('nonFieldErrors');
        }

        // store a list of keys (for iteration)
        this.errKeys = Object.keys(this.errors);
    }

    hasError() {
        return this.nonFieldErrors !== null || this.errKeys.length > 0;
    }

    asString(glue = '; ') {
        let prefix = '';

        if (this.nonFieldErrors) {
            prefix = `${this.nonFieldErrors}${this.errKeys.length ? glue : ''}`;
        }

        return (
            prefix +
            this.errKeys.map((k) => `${k}: ${this.errors[k].asString()}`).join(glue)
        );
    }

    iter() {
        return Object.keys(this.errors).map((x) => this.errors[x]);
    }

    errorByIndex(index) {
        return this.errors[this.errKeys[index]];
    }
}
