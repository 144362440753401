import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import { formatDateWithDot } from '@infogrid/utils-dates';
import { Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useThresholds } from 'apiHooks/settings/solutions/hooks';
import PageContainer from 'components/PageContainer';
import TitleBar from 'components/TitleBar';

import ThresholdsForm from './ThresholdForm';
import ThresholdsExplainer from './ThresholdsExplainer/ThresholdsExplainer';
import { useThresholdsStyles } from './styles';

const Thresholds = (): JSX.Element => {
    const { t } = useTranslation('solutions');
    const { data: pipeThresholds, isLoading } = useThresholds();
    const styles = useThresholdsStyles();
    const [affectedPipes, setAffectedPipes] = useState(0);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);

    const temperatureThresholds =
        pipeThresholds?.pipe_monitoring_temperature_thresholds || [];
    const waterMovementThreshold =
        pipeThresholds?.pipe_monitoring_water_movement_threshold;

    const updatedBy = temperatureThresholds[0]?.updated_by;

    const updatedAt =
        temperatureThresholds[0]?.updated_at &&
        formatDateWithDot(new Date(temperatureThresholds[0]?.updated_at));

    const onSaveBtnClick = () => {
        setIsDialogOpen(true);
    };

    return (
        <div>
            <PageContainer className={styles.container}>
                <TitleBar
                    large
                    className={styles.titleBar}
                    title={t('Pipe Monitoring temperature thresholds')}
                />
                <Typography
                    variant="body1"
                    className={styles.description}
                    data-testid={'thresholds-description'}
                >
                    {t(
                        'Use thresholds to define whether a pipe passes or fails during a reporting period.',
                    )}
                </Typography>
                <ThresholdsExplainer />
                {!updatedBy ? (
                    <Alert
                        severity="warning"
                        className={styles.alert}
                        data-testid={'thresholds-not-set'}
                    >
                        {t(
                            'Your organisation is currently using Infogrid defaults. Define your own thresholds for more accuracy.',
                        )}
                    </Alert>
                ) : (
                    <Alert
                        severity="info"
                        className={styles.alert}
                        data-testid={'thresholds-last-edit'}
                    >
                        {t(`Thresholds were last edited by ${updatedBy} on ${updatedAt}`)}
                    </Alert>
                )}
                {isLoading && <LoadingPlaceholder />}
                {!isLoading && (
                    <ThresholdsForm
                        isDialogOpen={isDialogOpen}
                        isUpdated={!!updatedBy}
                        closeDialog={() => setIsDialogOpen(false)}
                        affectedPipes={affectedPipes}
                        setAffectedPipes={setAffectedPipes}
                        setIsSaveDisabled={setIsSaveDisabled}
                        temperatureThresholds={temperatureThresholds}
                        waterMovementThreshold={waterMovementThreshold}
                    />
                )}
            </PageContainer>
            <div className={styles.saveContainer}>
                <Typography>
                    {t(`CHANGES WILL AFFECT {{ affectedPipes }} PIPES`, {
                        affectedPipes: !!updatedBy ? affectedPipes : t('ALL'),
                    })}
                </Typography>
                <Button
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={onSaveBtnClick}
                    className={styles.saveButton}
                    disabled={isSaveDisabled}
                >
                    {t('Save', { ns: 'common' })}
                </Button>
            </div>
        </div>
    );
};

export default Thresholds;
