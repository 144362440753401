import { useAppSelector } from '@infogrid/core-ducks';
import { selectUserPreferredTempUnit } from '@infogrid/user-ducks';
import type { TempUnit } from '@infogrid/utils-measurements';
import {
    TEMP_UNIT,
    TEMP_UNIT_SYMBOL,
    getTemperatureConverter,
} from '@infogrid/utils-measurements';
import { isNumber } from 'is-what';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    value: number;
    inputUnit?: TempUnit;
    outputUnit?: TempUnit | null;
    precision?: number;
    isDelta?: boolean;
}

const Temperature = ({
    value,
    inputUnit = TEMP_UNIT.CELSIUS,
    outputUnit = null,
    precision = 0,
    isDelta = false,
}: Props) => {
    const { t } = useTranslation('sensor-events');

    const userPreferredTempUnit = useAppSelector(selectUserPreferredTempUnit);
    const temperatureUnit = useMemo(
        () => (outputUnit === null ? userPreferredTempUnit : outputUnit),
        [userPreferredTempUnit, outputUnit],
    );

    if (!isNumber(value)) {
        return null;
    }

    let displayValue: string | number = value;

    if (inputUnit !== temperatureUnit) {
        const converter = getTemperatureConverter(inputUnit, temperatureUnit, isDelta);

        if (converter) {
            displayValue = converter(displayValue);
        }
    }

    displayValue = displayValue.toFixed(precision);

    const unit = t(TEMP_UNIT_SYMBOL[temperatureUnit]);

    return (
        <>
            {t('{{value: number}}{{unit}}', {
                value: displayValue,
                unit,
                maximumFractionDigits: precision,
                defaultValue: `${displayValue}${unit}`,
            })}
        </>
    );
};

export default Temperature;
