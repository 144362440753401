import { routesManager } from '@infogrid/core-routing';
import { createFetchAction, createFetchSaga } from '@thorgate/spa-entities';
import { takeEveryWithMatch } from '@thorgate/spa-view-manager';

import { createPaginationSuccessHook } from 'ducks/pagination';
import { relatedSensorsKeyFn } from 'schemas/folder';
import { sensorSchema } from 'schemas/sensor';
import api from 'services/api';
import { folderMutateKwargs } from 'utils/folder';
import { mutateSensorsResponse } from 'utils/sensor';
import { serializeData } from 'utils/serializeData';

export const fetchFolderSensorsCount = createFetchAction(
    '@@sagas/folders/sensors/fetchCount',
);

const fetchFolderSensorsCountWorker = createFetchSaga({
    resource: api.folder.sensors,
    key: relatedSensorsKeyFn,
    successHook: createPaginationSuccessHook(relatedSensorsKeyFn, true),
    listSchema: [sensorSchema],
    serializeData,
    mutateResponse: (response) => mutateSensorsResponse({ ...response, results: [] }),
    mutateKwargs: folderMutateKwargs,
});

export function* fetchFolderSensorsCountWatcher() {
    yield takeEveryWithMatch(
        fetchFolderSensorsCount.getType(),
        routesManager.resolvePattern('folders:details'),
        fetchFolderSensorsCountWorker,
    );
}
