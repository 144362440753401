import { toastSuccess } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { i18n } from '@infogrid/utils-i18n';
import { saveResults } from '@thorgate/spa-entities';
import { createSaveAction, createFormSaveSaga } from '@thorgate/spa-forms';
import { push } from 'connected-react-router';
import { call, takeLatest, put } from 'redux-saga/effects';

import { orgUserSchema } from 'schemas/orgUser';
import api from 'services/api';
import { handleDelayingFormSave } from 'utils/handleDelayingFormSave';
import { organizationMutateKwargs } from 'utils/organization';

/**
 * Trigger Save Organization Service Account worker saga.
 * @param {Object} payload Request info that is sent to server
 * @param {Object} FormikActions - Function to set form errors
 */
export const createOrgServiceAccount = createSaveAction(
    'sagas/account/CREATE_SERVICE_ACCOUNT',
);

const createOrgServiceAccountSagaWorker = createFormSaveSaga({
    resource: api.organization.serviceAccount.list,

    *successHook(serviceAccount, _1, { payload: { data } }) {
        yield call(saveResults, orgUserSchema.key, [serviceAccount], [orgUserSchema], {
            updateOrder: true,
        });
        yield put(
            toastSuccess({
                message: `${i18n.t('Service account created')} - ${data.name}`,
            }),
        );
        yield put(
            push(
                routesManager.resolvePath('organization:service-account-detail', {
                    userId: `${serviceAccount.id}`,
                }),
            ),
        );
    },

    mutateKwargs: organizationMutateKwargs,
});

export default function* createOrgServiceAccountWatcherSaga() {
    yield takeLatest(
        createOrgServiceAccount.getType(),
        handleDelayingFormSave(
            createOrgServiceAccountSagaWorker,
            i18n.t('Creating a service account is in progress'),
        ),
        null,
    );
}
