import { LoadingPlaceholder, Tooltip } from '@infogrid/components-material-ui';
import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import { FAILURE_REASON } from '@infogrid/remote-monitoring-types';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import classNames from 'classnames';
import { useCallback, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useThresholds } from 'apiHooks/settings/solutions/hooks';
import {
    MONITORING_TYPE_TEMPERATURE,
    MONITORING_TYPE_WATER_MOVING,
} from 'components/material-ui/PipeMonitoringTypeSelection/utils';
import ThresholdsNotSetAlert from 'views/dashboards/widgets/PipeMonitoringWidget/Content/ThresholdsNotSetAlert';

import WidgetAlert from '../../common/WidgetAlert';
import CauseOfRisk from '../CauseOfRisk';
import ActionNeededDialog from '../Dialog/ActionNeededDialog';
import LocationsTable from '../LocationsTable';
import NoResults from '../NoResults';
import type {
    WidgetSettings,
    PipeMonitoringRiskWidgetData,
    DialogBuildingData,
} from '../types';
import { useContentStyles } from './styles';

interface Props {
    canUserEdit?: boolean;
    pipeMonitoringFailureTypes: string[];
    widgetData?: PipeMonitoringRiskWidgetData;
    onUpdateConfiguration: (config: WidgetSettings) => void;
    isLoading: boolean;
    isLoadingDataError: boolean;
    timePeriodRange: string;
}

const Content = ({
    pipeMonitoringFailureTypes,
    widgetData = {
        buildings: {},
        calculation_status: 'done',
        failed_buildings_count: 0,
        sensor_count: 0,
        water_movement_failed_count: 0,
        total_buildings_count: 0,
        temperature_failed_count: 0,
    },
    isLoading,
    isLoadingDataError,
    timePeriodRange,
}: Props) => {
    const styles = useContentStyles();
    const { t } = useTranslation('dashboard');

    const [showActionsNeededDialog, setShowActionsNeededDialog] = useState(false);
    const [buildingAtRisk, setBuildingAtRisk] = useState<DialogBuildingData>();
    const onShowAtRiskDetailsDialog = useCallback(
        (buildingId: string) => {
            setShowActionsNeededDialog(true);
            setBuildingAtRisk({
                ...widgetData?.buildings[buildingId],
                buildingId,
            });
        },
        [widgetData],
    );

    const [showCausesOfRiskDialog, setShowCausesOfRiskDialog] = useState(false);
    const { data: pipeThresholds, isFetching: isFetchingThresholds } = useThresholds();

    const onCancelDialog = () => {
        if (showActionsNeededDialog) {
            setShowActionsNeededDialog(false);
        }

        if (showCausesOfRiskDialog) {
            setShowCausesOfRiskDialog(false);
        }
    };

    const classNameRisk =
        widgetData?.failed_buildings_count === 0
            ? styles.buildingsAtRiskCircleWithNoRisk
            : styles.buildingsAtRiskCircleWithWithRisk;

    const isThresholdsNotSet =
        !pipeThresholds?.pipe_monitoring_temperature_thresholds[0]?.updated_by;

    if (!isFetchingThresholds && isThresholdsNotSet) {
        return <ThresholdsNotSetAlert />;
    }

    if (isLoading || isFetchingThresholds) {
        return <LoadingPlaceholder />;
    }

    if (isLoadingDataError) {
        return (
            <WidgetAlert severity="error">
                {t(WidgetErrorMessages.dataLoadError)}
            </WidgetAlert>
        );
    }

    const resultsExists = widgetData?.failed_buildings_count > 0;
    const displayTemperature =
        !pipeMonitoringFailureTypes ||
        pipeMonitoringFailureTypes.includes(MONITORING_TYPE_TEMPERATURE);
    const displayWaterMovement =
        !pipeMonitoringFailureTypes ||
        pipeMonitoringFailureTypes.includes(MONITORING_TYPE_WATER_MOVING);

    let riskTooltip = t(
        'Number of pipes that are at risk for temperature and water movement based on your selected time period',
    );

    if (displayTemperature && !displayWaterMovement) {
        riskTooltip = t(
            'Number of pipes that are at risk for temperature based on your selected time period',
        );
    }

    if (!displayTemperature && displayWaterMovement) {
        riskTooltip = t(
            'Number of pipes that are at risk for water movement based on your selected time period',
        );
    }

    return (
        <div className={styles.root}>
            {buildingAtRisk && (
                <ActionNeededDialog
                    onCancel={onCancelDialog}
                    isOpen={showActionsNeededDialog}
                    atRiskBuilding={buildingAtRisk}
                    timePeriodRange={timePeriodRange}
                    pipeMonitoringFailureTypes={pipeMonitoringFailureTypes}
                />
            )}
            <div className={styles.headerSection}>
                <div className={styles.headerInformationContainer}>
                    <div className={styles.buildingsAtRiskContainer}>
                        <Typography
                            variant="subtitle1"
                            className={styles.headerInformationTitle}
                        >
                            {t('Total buildings')}
                            <Tooltip
                                placement="top"
                                title={t(
                                    'Total number of buildings in your selection that are at risk based on your selected time period',
                                )}
                                className={styles.headerTooltip}
                            >
                                <InfoIcon className={styles.helpIcon} color="secondary" />
                            </Tooltip>
                        </Typography>
                        <div className={styles.buildingsAtRiskCircleContainer}>
                            <div
                                className={classNames([
                                    styles.buildingsAtRiskCircle,
                                    classNameRisk,
                                ])}
                                data-cypress="risk-widget-total-buildings-at-risk"
                            >
                                {widgetData?.failed_buildings_count?.toLocaleString()}
                            </div>
                            <div className={styles.buildingsAtRiskRemainder}>/</div>
                            <div className={styles.buildingsAtRiskRemainder}>
                                {widgetData?.total_buildings_count}
                            </div>
                        </div>
                    </div>
                    <div>
                        <Typography
                            variant="subtitle1"
                            className={styles.headerInformationTitle}
                        >
                            {t('Causes of risk')}
                            <Tooltip
                                placement="top"
                                title={riskTooltip}
                                className={styles.headerTooltip}
                            >
                                <InfoIcon className={styles.helpIcon} color="secondary" />
                            </Tooltip>
                        </Typography>
                        <div>
                            {displayWaterMovement && (
                                <CauseOfRisk
                                    label="Water Movement"
                                    pipesCount={widgetData?.water_movement_failed_count}
                                    failureReason={FAILURE_REASON.WATER_MOVING}
                                />
                            )}
                            {displayTemperature && (
                                <CauseOfRisk
                                    label="Temperature"
                                    pipesCount={widgetData?.temperature_failed_count}
                                    failureReason={FAILURE_REASON.TEMPERATURE}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <Typography className={styles.contentHeaderText} variant="subtitle1">
                    {t('Buildings where action is needed')}
                </Typography>
            </div>
            <div className={styles.contentContainer}>
                {resultsExists ? (
                    <LocationsTable
                        widgetData={widgetData}
                        onShowAtRiskDetailsDialog={onShowAtRiskDetailsDialog}
                    />
                ) : (
                    <NoResults />
                )}
            </div>
        </div>
    );
};

export default memo(Content);
