import { schema } from 'normalizr';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { createFilteredKeyFn } from 'utils/relations';

export const sensorSchema = new schema.Entity(
    'sensors',
    {},
    { idAttribute: 'device_name' },
);

export const sensorsKeyFn = createFilteredKeyFn(sensorSchema.key);

// (state) => { [id]: { ... } }
export const selectSensorData = (state) => state.entities.data.sensors || {};

// (state, id) => { ... }
export const selectSensor = createCachedSelector(
    (state, id) => id,
    selectSensorData,
    (id, sensorData) => sensorData[id],
)((state, id) => id);

// (state, ids) => [{ ... }, { ... }]
export const selectSensors = createSelector(
    (state, ids) => ids,
    selectSensorData,
    (ids, sensorData) => ids.map((id) => sensorData[id]),
);
