import { getAuthToken } from '@infogrid/user-cookies';
import { call } from 'redux-saga/effects';

import { refreshToken } from 'sagas/auth/authMiddleware';

export function* getRequestToken(shouldRunRefreshToken) {
    let token = yield call(getAuthToken);

    if (!token && shouldRunRefreshToken) {
        token = yield call(refreshToken);
    }

    return token;
}
