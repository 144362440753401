import { actionChannel, delay, take } from 'redux-saga/effects';

import { SEND_MESSAGE } from './actionTypes';

export function* writeSocket(socket) {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('starting socket write worker');
    }

    const channel = yield actionChannel(SEND_MESSAGE);

    while (true) {
        const { stream, payload, requestId } = yield take(channel);

        socket.send(
            JSON.stringify({
                stream,
                payload: {
                    payload,
                    request_id: requestId,
                },
            }),
        );

        // Do not block the main thread
        yield delay(10);
    }
}
