import { makeStyles } from '@material-ui/core';

export const useIdentifyFormStyles = makeStyles((theme) => ({
    content: {
        '& > *': {
            marginBottom: theme.spacing(2),
        },
    },
    image: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '100%',
    },
    actions: {
        justifyContent: 'space-between',
    },
}));
