import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { getSensor } from '@infogrid/sensors-configuration';
import type { SensorShape } from '@infogrid/sensors-constants';
import { selectUserPreferredTempUnit } from '@infogrid/user-ducks';
import type { FormikErrors } from 'formik';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
    INSTALL_STEPS,
    saveDeviceConfiguration,
    selectIdentifiedSensor,
} from 'ducks/installFlow';

import { useGoToStep } from '../../utils/hooks';
import type { ConfigurePipeMonitoringValues } from './ConfigurePipeMonitoring';
import ConfigurePipeMonitoring from './ConfigurePipeMonitoring';

const ConfigurePipeMonitoringContainer: FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('sensors');

    const userPreferredTempUnit = useAppSelector(selectUserPreferredTempUnit);
    const identifiedSensor = useAppSelector(selectIdentifiedSensor) as SensorShape;
    const isSensorOffline = !getSensor(identifiedSensor).getIsOnline();

    const formik = useFormik<ConfigurePipeMonitoringValues>({
        initialValues: {
            pipeType: undefined,
        },
        onSubmit: ({ pipeType }) => {
            dispatch(saveDeviceConfiguration({ pipe_type: pipeType }));
        },
        validate: ({ pipeType }) => {
            const errors: FormikErrors<ConfigurePipeMonitoringValues> = {};

            if (!pipeType) {
                errors.pipeType = t('Please select a pipe type');
            }

            return errors;
        },
    });

    const onClickBack = useGoToStep(INSTALL_STEPS.BARCODE_SCAN);

    return (
        <ConfigurePipeMonitoring
            formik={formik}
            sensor={identifiedSensor}
            isSensorOffline={isSensorOffline}
            userPreferredTempUnit={userPreferredTempUnit}
            onClickBack={onClickBack}
        />
    );
};

export default ConfigurePipeMonitoringContainer;
