import { makeStyles } from '@material-ui/core';

export const useDeviceIdentifierStyles = makeStyles((theme) => ({
    helpImageContainer: {
        marginBottom: theme.spacing(2.25),
        color: theme.palette.text.grey.default,
    },
    image: {
        marginBottom: theme.spacing(1.25),
    },
    serialNumberExample: {
        fontStyle: 'italic',
    },
    serialNumberInput: {
        marginBottom: theme.spacing(1.25),
    },
}));
