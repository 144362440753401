import { makeStyles } from '@material-ui/core';

export const useThresholdCardStyles = makeStyles((theme) => ({
    card: {
        '& .MuiCardHeader-avatar': {
            marginRight: 0,
        },
    },
    label: {
        fontSize: '20px',
        fontWeight: 500,
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1),
        },
    },
    icon: {
        overflow: 'inherit',
        fontWeight: 900,
    },
    description: {
        paddingTop: 0,
    },
    noValue: {
        color: theme.palette.common.gray3,
        fontSize: '14px',
        marginTop: theme.spacing(1),
    },
    minRequired: {
        color: theme.palette.common.red2,
        fontSize: '14px',
        marginTop: theme.spacing(1),
    },
    tempUnit: {
        color: theme.palette.common.gray1,
    },
    value: {
        width: 150,
    },
    shouldBe: {
        width: 160,
    },
}));
