import { Tooltip } from '@infogrid/components-material-ui';
import { routesManager } from '@infogrid/core-routing';
import type { PipeType } from '@infogrid/sensors-constants';
import { PipeTypes } from '@infogrid/sensors-constants';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import classNames from 'classnames';
import type { Location } from 'history';
import camelCase from 'lodash/camelCase';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { TableRowAttributes, PipeMonitoringGraphRows } from '../../types';
import ValueTableCell from './TableCell';
import { Headers } from './consts';
import { useContentStyles } from './styles';

interface Props {
    widgetId: number;
    rowsAttributes: TableRowAttributes[];
    rows: PipeMonitoringGraphRows;
}

const getLink = (
    widgetId: number,
    type: PipeType,
    key: string,
    isTotalRow: boolean,
): Location => {
    if (isTotalRow) {
        return routesManager.resolvePath('sensors:list', null, {
            [key]: widgetId,
        });
    }

    return routesManager.resolvePath('sensors:list', null, {
        [key]: widgetId,
        sub_type: camelCase(type),
    });
};

const PipeTable = ({ widgetId, rows, rowsAttributes }: Props) => {
    const { t } = useTranslation('dashboard');
    const styles = useContentStyles();

    return (
        <Table stickyHeader>
            <TableHead className={styles.tableHead}>
                <TableRow>
                    <TableCell className={styles.tableHeadCell} />
                    {Headers.map((header) => (
                        <TableCell className={styles.tableHeadCell} key={header.id}>
                            {t(header.label)}
                            {header.tooltip && (
                                <Tooltip title={t(header.tooltip)}>
                                    <i className="fas fa-info-circle" />
                                </Tooltip>
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rowsAttributes.map((attribute: TableRowAttributes) => {
                    const values = rows[attribute.id];

                    if (!values) {
                        return null;
                    }

                    const isTotalRow = attribute.id === PipeTypes.TOTAL;

                    const failedSensorsLink = getLink(
                        widgetId,
                        attribute.id,
                        'failed_for_pipe_widget_id',
                        isTotalRow,
                    );

                    const waterFailedSensorsLink = getLink(
                        widgetId,
                        attribute.id,
                        'failed_water_for_pipe_widget_id',
                        isTotalRow,
                    );

                    const temperatureFailedSensorsLink = getLink(
                        widgetId,
                        attribute.id,
                        'failed_temp_for_pipe_widget_id',
                        isTotalRow,
                    );

                    const sensorsLink = getLink(
                        widgetId,
                        attribute.id,
                        'sensors_for_pipe_widget_id',
                        isTotalRow,
                    );

                    const failPercentLabel =
                        values?.fail_percent !== undefined
                            ? `${values.fail_percent.toFixed(2).replace('.00', '')}%`
                            : '-';

                    return (
                        <TableRow className={styles.tableBodyRow} key={attribute.id}>
                            <TableCell
                                className={classNames(
                                    styles.tableCell,
                                    styles.tableCellLeftAlign,
                                    {
                                        [styles.totalCell]: isTotalRow,
                                    },
                                )}
                            >
                                {!!attribute.tooltip && (
                                    <Tooltip title={t(attribute.tooltip)}>
                                        <span>{t(attribute.label)}</span>
                                    </Tooltip>
                                )}
                                {!attribute.tooltip && <span>{t(attribute.label)}</span>}
                            </TableCell>

                            <ValueTableCell
                                isTotalCell={isTotalRow}
                                link={sensorsLink}
                                colorize={false}
                                value={values.total}
                            />
                            <ValueTableCell
                                isTotalCell={isTotalRow}
                                link={failedSensorsLink}
                                value={values.total_fail}
                            />
                            <ValueTableCell
                                isTotalCell={isTotalRow}
                                label={failPercentLabel}
                                link={failedSensorsLink}
                                value={values.fail_percent}
                            />
                            <ValueTableCell
                                isTotalCell={isTotalRow}
                                link={waterFailedSensorsLink}
                                value={values.water_fail}
                            />
                            <ValueTableCell
                                isTotalCell={isTotalRow}
                                link={temperatureFailedSensorsLink}
                                value={values.temp_fail}
                            />
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default memo(PipeTable);
