import { makeStyles } from '@material-ui/core';

export const useFeedbackPanelSensorOptionStyles = makeStyles((theme) => ({
    optionTitle: {
        marginBottom: theme.spacing(3),
    },
    sensorIcon: {
        marginRight: theme.spacing(2),
        overflow: 'visible',
    },
    sensorName: {
        textOverflow: 'ellipsis',
    },
    sensorDeviceName: {
        color: theme.palette.common.gray6,
        textOverflow: 'ellipsis',
    },
    sensorSignalLevel: {
        marginLeft: theme.spacing(1),
    },
    identificationContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
}));
