import type { WidgetConfigurationModalTab } from '@infogrid/dashboards-constants';
import { memo } from 'react';

import { GenericWidgetContainer } from 'views/dashboards/components/Widget/GenericWidget';
import ConfigureWidgetModal from 'views/dashboards/components/modals/ConfigureWidgetModal';

import Content from './Content';
import { widgetConfigurationModalTabs } from './constants';
import { useWidgetStyles } from './styles';
import type {
    ConfigurationValues,
    IndoorAirQualityWidget as IndoorAirQualityWidgetType,
    IndoorAirQualityWidgetConfiguration,
} from './types';
import { prepareConfigurationPayload, prepareInitialValues } from './utils';

export interface IndoorAirQualityWidgetProps {
    widget: IndoorAirQualityWidgetType;
}

interface WidgetConfigurationModalProps {
    prepareConfigurationPayload: (
        values: ConfigurationValues,
    ) => IndoorAirQualityWidgetConfiguration;
    prepareInitialValues: (widget: IndoorAirQualityWidgetType) => ConfigurationValues;
    tabs: WidgetConfigurationModalTab[];
}

const titleProps = {
    titleIcon: <i className="fas fa-wind" />,
};

const widgetConfigurationModalProps: WidgetConfigurationModalProps = {
    prepareConfigurationPayload,
    prepareInitialValues,
    tabs: widgetConfigurationModalTabs,
};

const IndoorAirQualityWidget = ({ widget, ...props }: IndoorAirQualityWidgetProps) => {
    const styles = useWidgetStyles();

    return (
        <GenericWidgetContainer
            contentClassName={styles.content}
            configureWidgetModal={ConfigureWidgetModal}
            contentComponent={Content}
            titleProps={titleProps}
            widget={widget}
            widgetConfigurationModalProps={widgetConfigurationModalProps}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
};

export default memo(IndoorAirQualityWidget);
