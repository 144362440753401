import { makeStyles } from '@material-ui/core';

export const useEditBuildingStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '200px',
        width: '450px',
    },
    spinner: {
        margin: 'auto',
    },
});
