import { makeStyles } from '@material-ui/core';

export const useDesktopContentStyles = makeStyles((theme) => ({
    allBuildingsContainer: {
        borderBottom: `1px solid ${theme.palette.common.gray4}`,
        paddingBottom: theme.spacing(2.5),
        paddingTop: theme.spacing(3),
    },
    trendsChart: {
        height: 21,
    },

    tableHeader: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: theme.palette.common.white,
        marginTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.common.gray7}`,
    },
    buildingContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1.5),
        borderBottom: `1px solid ${theme.palette.common.gray7}`,
    },
    buildingContainerSelected: {
        backgroundColor: theme.palette.background.primary,
        borderBottom: `1px solid ${theme.palette.background.primary}`,
    },
    rankContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexBasis: 70,
    },
    rank: {
        padding: theme.spacing(0, 1),
        borderRadius: 2,
        backgroundColor: theme.palette.common.gray5,
    },
}));

export const useProgressRingStyles = makeStyles((theme) => ({
    root: {
        marginRight: theme.spacing(1),
        width: 'auto',
    },
}));
