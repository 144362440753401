import classNames from 'classnames';
import { memo, useMemo, useState, useCallback } from 'react';
import { useUpdate } from 'react-use';
import type { GridProps } from 'react-virtualized';
import { AutoSizer, ScrollSync } from 'react-virtualized';

import { useVerticalScrollbar } from 'utils/hooks/table';

import ColumnSizer from './components/ColumnSizer';
import SelectableTableCell from './components/SelectableTableCell';
import SortableTableHeader from './components/SortableTableHeader';
import TableInfiniteLoader from './components/TableInfiniteLoader';
import TableLoader from './components/TableLoader';
import type { TableProps } from './constants';
import { TABLE_VARIANTS } from './constants';
import { useTableStyles } from './styles';

const DEFAULT_CLASSES = Object.freeze({
    container: '',
});

const DEFAULT_CELL_PADDING = [1, 0.5] as [number, number];

const Table = <Value,>({
    columns,
    data,
    dataCypress = 'table',
    EmptyStateRenderer = () => <div />,
    onFetchMore,
    classes = DEFAULT_CLASSES,
    cellPadding = DEFAULT_CELL_PADDING,
    leftPadding,
    isLoading,
    selectedItemIdx,
    onCellClick,
    onEnterClick,
    headerMinHeight,
    variant = TABLE_VARIANTS.REGULAR,
}: TableProps<Value>) => {
    const tableClasses = useTableStyles();
    const [gridContentRef, setGridContentRef] = useState<HTMLElement | null>(null);
    const [gridInstance, setGridInstance] = useState<GridProps | null>(null);
    const getGridRef = useCallback(
        (columnSizerFunc, infiniteLoaderFunc) =>
            (child: HTMLElement & GridProps & { _scrollingContainer: HTMLElement }) => {
                // eslint-disable-next-line no-underscore-dangle
                if (!gridContentRef && child?._scrollingContainer) {
                    // eslint-disable-next-line no-underscore-dangle
                    setGridContentRef(child?._scrollingContainer);
                }

                columnSizerFunc(child);
                infiniteLoaderFunc(child);

                if (!gridInstance && child) {
                    setGridInstance(child as GridProps);
                }
            },
        [gridContentRef, gridInstance],
    );

    const update = useUpdate();

    const { onScrollbarPresenceChange, isVerticalScrollPresent } = useVerticalScrollbar();

    const computedEmptyStateRenderer = useMemo(
        () => (isLoading ? TableLoader : () => <EmptyStateRenderer />),
        [isLoading, EmptyStateRenderer],
    );

    const getScrollbarWidth = () =>
        isVerticalScrollPresent && gridContentRef
            ? gridContentRef.offsetWidth - gridContentRef.clientWidth
            : 0;

    const onScrollbarChange = useCallback(
        (scrollData) => {
            onScrollbarPresenceChange(scrollData);

            // call rerender to prevent appearing of needless horizontal scroll
            if (scrollData?.vertical) {
                update();
            }
        },
        [update, onScrollbarPresenceChange],
    );

    return (
        <div
            data-cypress={dataCypress}
            className={classNames(tableClasses.container, classes.container)}
        >
            <AutoSizer>
                {({ width, height }) => (
                    <ColumnSizer
                        width={width}
                        columns={columns}
                        columnMinWidth={124}
                        getScrollbarWidth={getScrollbarWidth}
                    >
                        {({
                            columnWidthMeasurer,
                            registerChild,
                        }: {
                            columnWidthMeasurer: (index: number) => number;
                            registerChild: (child: JSX.Element) => void;
                        }) => (
                            <ScrollSync>
                                {({ onScroll, scrollLeft }) => (
                                    <TableInfiniteLoader
                                        onScroll={onScroll}
                                        scrollLeft={scrollLeft}
                                        registerChild={registerChild}
                                        columnWidthMeasurer={columnWidthMeasurer}
                                        emptyStateRenderer={computedEmptyStateRenderer}
                                        onFetchMore={onFetchMore}
                                        autosizerProps={{ width, height }}
                                        data={data}
                                        columns={columns}
                                        classes={classes}
                                        cellPadding={cellPadding}
                                        leftPadding={leftPadding}
                                        onScrollbarPresenceChange={onScrollbarChange}
                                        isVerticalScrollPresent={isVerticalScrollPresent}
                                        width={width}
                                        selectedItemIdx={selectedItemIdx}
                                        onCellClick={onCellClick}
                                        onEnterClick={onEnterClick}
                                        headerMinHeight={headerMinHeight}
                                        getGridRef={getGridRef}
                                        variant={variant}
                                        gridInstance={gridInstance}
                                    />
                                )}
                            </ScrollSync>
                        )}
                    </ColumnSizer>
                )}
            </AutoSizer>
        </div>
    );
};

const TableComponent = Object.assign(memo(Table) as typeof Table, {
    SortableTableHeader,
    SelectableTableCell,
});

export default TableComponent;
