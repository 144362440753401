import { useAppSelector } from '@infogrid/core-ducks';
import { getLocation } from 'connected-react-router';

import { useSortableOrderBy } from '..';
import type { OrderByColumn, OrderBy } from '../types';
import { spaceQueryParamsManager } from './config';
import type { SpaceQueryParams, FormattedSpaceQueryParams } from './types';

export { SPACE_PARAM_NAMES } from './consts';

export const useSpaceQueryBuilder = (initialQuery = {}): SpaceQueryParams => {
    const location = useAppSelector(getLocation);

    return spaceQueryParamsManager.buildQuery(location?.search, initialQuery);
};

export const useFormattedSpaceQueryParams = (): FormattedSpaceQueryParams => {
    const location = useAppSelector(getLocation);

    return spaceQueryParamsManager.formatQueryParams(location.search);
};

const getOrderBy = (search: string) => {
    const { ordering } = spaceQueryParamsManager.buildQuery(search);

    return ordering ?? null;
};

export const useSpaceSortableOrderBy = (): [OrderBy, (column: OrderByColumn) => void] => {
    return useSortableOrderBy(spaceQueryParamsManager, getOrderBy);
};

export const onSpaceSearchPath = spaceQueryParamsManager.onSearch;
