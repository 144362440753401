import * as Yup from 'yup';

import {
    prepareBaseConfigurationPayload,
    prepareBaseInitialValues,
    prepareBaseStoplightValues,
} from 'views/dashboards/widgets/utils';

import {
    GREEN_MESSAGE,
    MAX_COLOR_MESSAGE_LENGTH,
    RED_MESSAGE,
    YELLOW_MESSAGE,
} from './constants';
import type {
    CubicleOccupancyStoplightInitialValues,
    CubicleOccupancyStoplightValues,
    CubicleOccupancyStoplightWidget,
    CubicleOccupancyStoplightWidgetConfiguration,
} from './types';

const colorSchema = Yup.object().shape({
    message: Yup.string().max(
        MAX_COLOR_MESSAGE_LENGTH,
        `Must be ${MAX_COLOR_MESSAGE_LENGTH} characters or less`,
    ),
});

export const validationSchema = Yup.object().shape({
    displayOptions: Yup.object().shape({
        green: colorSchema,
        yellow: colorSchema,
        red: colorSchema,
    }),
});

export const prepareInitialValues = (
    widget: CubicleOccupancyStoplightWidget,
): CubicleOccupancyStoplightInitialValues => {
    const {
        displays,
        folders = [],
        sensors = [],
        stoplight,
        thresholds = [],
    } = widget?.configuration?.calculators?.occupancy_stoplight ?? {};

    return {
        ...prepareBaseInitialValues(widget),
        folders,
        sensors,
        displayOptions: {
            green: {
                maxThreshold: sensors.length,
                message: displays?.greenScreen.message || GREEN_MESSAGE,
                showCubicleCount: displays?.greenScreen.showCubicle ?? true,
            },
            yellow: {
                maxThreshold: thresholds[1],
                message: displays?.yellowScreen.message || YELLOW_MESSAGE,
                showCubicleCount: displays?.yellowScreen.showCubicle ?? true,
            },
            red: {
                maxThreshold: thresholds[0],
                message: displays?.redScreen.message || RED_MESSAGE,
                showCubicleCount: displays?.redScreen.showCubicle ?? false,
            },
        },
        stoplight: prepareBaseStoplightValues(stoplight),
    };
};

export const prepareConfigurationPayload = (
    values: CubicleOccupancyStoplightValues,
): CubicleOccupancyStoplightWidgetConfiguration => {
    const { green, yellow, red } = values?.displayOptions;

    return {
        ...prepareBaseConfigurationPayload(values),
        calculators: {
            occupancy_stoplight: {
                displays: {
                    greenScreen: {
                        message: green.message,
                        showCubicle: green.showCubicleCount,
                    },
                    yellowScreen: {
                        message: yellow.message,
                        showCubicle: yellow.showCubicleCount,
                    },
                    redScreen: {
                        message: red.message,
                        showCubicle: red.showCubicleCount,
                    },
                },
                folders: values?.folders,
                sensors: values?.sensors,
                stoplight: values?.stoplight,
                thresholds: [red.maxThreshold, yellow.maxThreshold],
            },
        },
    };
};
