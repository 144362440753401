import { routesManager } from '@infogrid/core-routing';
import type { Dashboard } from '@infogrid/dashboards-constants';
import { useDeleteDashboard } from '@infogrid/dashboards-hooks';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import type { MouseEvent } from 'react';
import { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { trackDashboardEvent } from 'views/dashboards/utils/analytics';

import DeleteDashboardModal from './DeleteDashboardModal';

interface Props {
    open: boolean;
    dashboard: Dashboard;
    toggleDeleteModal: (state: boolean) => void;
}

const DeleteDashboardContainer = ({ dashboard, open, toggleDeleteModal }: Props) => {
    const history = useHistory();

    const onClose = useCallback(() => {
        toggleDeleteModal(false);
    }, [toggleDeleteModal]);

    const { mutate, isLoading: deleteDashboardLoading } = useDeleteDashboard({
        onSuccess(nextDashboard) {
            onClose();

            history.push(
                routesManager.resolvePath('dashboards:details', {
                    dashboardId: nextDashboard.id,
                }),
            );
        },
    });

    const deleteDashboardHandler = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            trackDashboardEvent(USER_ACTIONS.PRESSED, 'delete dashboard', {
                from: dashboard.name,
                id: dashboard.id,
            });

            mutate(dashboard);
        },
        [dashboard, mutate],
    );

    return (
        <DeleteDashboardModal
            open={open}
            onClose={onClose}
            onConfirm={deleteDashboardHandler}
            dashboardName={dashboard.name}
            isSubmitting={deleteDashboardLoading}
        />
    );
};

export default memo(DeleteDashboardContainer);
