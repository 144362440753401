import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { FloorDetail } from '@infogrid/locations-types';
import type { UseQueryResult, UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getFloorDetailKey } from '../getQueryKeys';

export const useFloor = <TData = FloorDetail>(
    floorId?: number,
    options?: UseQueryOptions<FloorDetail, AxiosParsedError, TData>,
): UseQueryResult<TData, AxiosParsedError> => {
    return useQuery(
        getFloorDetailKey(floorId),
        ({ signal }) =>
            wrapResponsePromise(
                controllers.getFloor(
                    // Will be number if enabled
                    floorId as number,
                    signal,
                ),
            ),
        {
            enabled: !!floorId,
            refetchOnMount: false,
            ...options,
        },
    );
};
