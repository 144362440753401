import { makeStyles } from '@material-ui/core';

export const useStylesMapTooltip = makeStyles((theme) => ({
    tooltipContainer: {
        position: 'absolute',
        top: '20px',
        backgroundColor: 'transparent',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    tooltip: {
        marginTop: '15px',
        whiteSpace: 'nowrap',
        maxWidth: '350px',
        minWidth: '155px',
        padding: theme.spacing(1, 1.5),
        color: theme.palette.common.white,
        borderRadius: '4px',
        backgroundColor: theme.palette.common.gray2,
    },
    controlIcon: {
        color: theme.palette.text.grey.default,
        fontSize: '21px',
        lineHeight: '1.1em',
        width: '1.2em',
    },
    disabledControlIcon: {
        color: theme.palette.text.grey.light,
    },
}));
