import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { useFolders } from 'apiHooks/floorPlan/folders/hooks';

import ChoosingFolderBuilding from './ChoosingFolderBuilding';

const ChoosingFolderBuildingContainer = ({
    title,
    handleNext,
    handleSelectFolder,
    selectedFolder,
    openCreateFolder,
    expandedFolderPath,
    setExpandedFolderPath,
}) => {
    const isButtonDisabled = !selectedFolder && selectedFolder?.id !== 0;

    const selectedFolders = useMemo(
        () => (selectedFolder?.id ? [`${selectedFolder.id}`] : []),
        [selectedFolder],
    );

    const { data: folders, isFetching } = useFolders({ refetchOnMount: true });

    return (
        <ChoosingFolderBuilding
            title={title}
            folders={folders}
            isFetching={isFetching}
            handleNext={handleNext}
            selectedFolders={selectedFolders}
            openCreateFolder={openCreateFolder}
            isButtonDisabled={isButtonDisabled}
            handleSelectFolder={handleSelectFolder}
            expandedFolderPath={expandedFolderPath}
            setExpandedFolderPath={setExpandedFolderPath}
        />
    );
};

ChoosingFolderBuildingContainer.propTypes = {
    handleNext: PropTypes.func.isRequired,
    openCreateFolder: PropTypes.func.isRequired,
    handleSelectFolder: PropTypes.func.isRequired,
    title: PropTypes.string,
    selectedFolder: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        contentLength: PropTypes.number,
    }),
    expandedFolderPath: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ).isRequired,
    setExpandedFolderPath: PropTypes.func.isRequired,
};
ChoosingFolderBuildingContainer.defaultProps = {
    title: '',
    selectedFolder: null,
};

export default ChoosingFolderBuildingContainer;
