import type { Solution } from '@infogrid/solution-views-types';

/**
 * Sort solutions alphabetically by name
 */

interface GetSortedSolutionsProps {
    data: Solution[];
}

export const getSortedSolutions = ({ data = [] }: GetSortedSolutionsProps): Solution[] =>
    data.sort((a, b) => a.name.localeCompare(b.name));
