import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useEditFloorModalStyles = makeStyles((theme) => ({
    form: {
        height: '100%',
    },
    formContent: {
        minHeight: 'calc(100% - 62px)',
        padding: theme.spacing(3),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '630px',
        },
    },
    saveButton: {
        minWidth: 90,
    },
    header: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
    capacityText: {
        margin: theme.spacing(1, 0, 2, 0),
    },
}));
