import type { BuildingDetail } from '@infogrid/locations-types';
import { Tooltip, Typography } from '@material-ui/core';
import { memo } from 'react';

import { useBuildingInfoStyles } from './styles';

interface Props {
    building: BuildingDetail;
}

const BuildingInfo = ({ building }: Props): JSX.Element => {
    const styles = useBuildingInfoStyles();

    return (
        <div className={styles.container} data-cypress="building-details">
            <div className={styles.wrapper}>
                <Tooltip placement="top" title={building.name}>
                    <Typography className={styles.buildingName} variant="body1">
                        {building.name}
                    </Typography>
                </Tooltip>
                <Tooltip placement="top" title={building.location.address}>
                    <Typography className={styles.buildingAddress} variant="body2">
                        {building.location.address}
                    </Typography>
                </Tooltip>
            </div>
        </div>
    );
};

export default memo(BuildingInfo);
