import { makeStyles } from '@material-ui/core';

export const useDeviceIdentifierStyles = makeStyles((theme) => ({
    helpImageContainer: {
        marginBottom: theme.spacing(1),
    },
    serialNumberInput: {
        marginBottom: theme.spacing(3),
    },
    deviceIdInput: {
        marginBottom: theme.spacing(1),
    },
}));
