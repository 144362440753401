import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useBuildingsTableFilters = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    filterWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    filterByLabel: {
        fontWeight: theme.typography.fontWeightRegular as FontWeightProperty,
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(1),
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    clearFiltersButton: {
        marginLeft: 'auto',
        minWidth: 'unset',
        whiteSpace: 'nowrap',
    },
}));
