import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { INTERCOM_SCRIPT } from 'constants/intercomScript';
import SETTINGS from 'settings';
import { LogoGridOnly } from 'utils/Images';
import getWebclientURI from 'utils/getWebclientURI';

interface Props {
    canonical: string;
}

const DefaultHeader = ({ canonical }: Props) => {
    const { i18n } = useTranslation();

    return (
        <Helmet titleTemplate="%s - Infogrid" defaultTitle="Infogrid">
            <html lang={i18n.language} />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta charSet="utf-8" />
            <body />
            <meta name="description" content="Default description" />
            <link rel="canonical" href={getWebclientURI(canonical)} />
            <link href={LogoGridOnly} rel="shortcut icon" />

            {SETTINGS.INTERCOM_APP_ID ? <script>{INTERCOM_SCRIPT}</script> : null}
        </Helmet>
    );
};

export default memo(DefaultHeader);
