import type {
    SensorEventType,
    SensorEventV2Key,
    SensorEventShape,
    SensorShape,
    WeekdayWorkingHoursConfiguration,
    WeekdayWorkingHours,
    Weekday,
} from '@infogrid/sensors-constants';
import {
    format,
    differenceInCalendarDays,
    differenceInDays,
    addDays,
    differenceInSeconds,
} from 'date-fns';
import { convertToTimeZone } from 'date-fns-timezone';
import isNil from 'lodash/isNil';
import round from 'lodash/round';

import type { TimeRange } from 'utils/types/ts/app';

import type { GetEventsInRangeParams, ElectricityEventOverview } from './types';

const ORDERED_WEEKDAYS = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'sunday',
] as Weekday[];

const getWeekdaysByCondition = (
    workingHoursConfiguration: WeekdayWorkingHoursConfiguration,
    condition: (item: WeekdayWorkingHours) => boolean,
) => {
    return ORDERED_WEEKDAYS.map((day, index) => ({
        ...workingHoursConfiguration[day],
        weekday: index,
    }))
        .filter((item) => condition(item))
        .map((item) => item.weekday);
};

const isDayWithinWorkingDays = (
    day: number,
    workingHoursConfiguration: WeekdayWorkingHoursConfiguration,
) => {
    const workingWeekdays = getWeekdaysByCondition(
        workingHoursConfiguration,
        (item) => item.is_working_day,
    );

    return workingWeekdays.includes(day);
};

const isDayWithinNonWorkingDays = (
    day: number,
    workingHoursConfiguration: WeekdayWorkingHoursConfiguration,
) => {
    const nonWorkingWeekdays = getWeekdaysByCondition(
        workingHoursConfiguration,
        (item) => !item.is_working_day,
    );

    return nonWorkingWeekdays.includes(day);
};

const getOrderedWorkingHoursConfiguration = (
    workingHoursConfiguration: WeekdayWorkingHoursConfiguration,
) => {
    return ORDERED_WEEKDAYS.map((d) => workingHoursConfiguration[d]);
};

const isTimeInWorkingHours = (hour: string, dayConfiguration: WeekdayWorkingHours) => {
    if (!dayConfiguration.is_working_day) {
        return false;
    }

    if (
        dayConfiguration.start_time === '00:00' &&
        dayConfiguration.end_time === '00:00'
    ) {
        return true;
    }

    if (dayConfiguration.start_time > dayConfiguration.end_time) {
        return hour >= dayConfiguration.end_time && hour < dayConfiguration.start_time;
    }

    return hour >= dayConfiguration.start_time && hour < dayConfiguration.end_time;
};

const isTimeInNonWorkingHours = (
    hour: string,
    dayConfiguration: WeekdayWorkingHours,
): boolean => !isTimeInWorkingHours(hour, dayConfiguration);

export const isRangeLongerThanDay = ({ from, to }: Omit<TimeRange, 'range'>): boolean =>
    // Checking by seconds to account for 1 day period as selected as 00:00:00 - 23:59:59
    differenceInSeconds(to, from) >= 86399;

export const isRangeLongerThanWeek = ({ from, to }: Omit<TimeRange, 'range'>): boolean =>
    differenceInDays(to, from) >= 7;

export const isRangeWithinNonWorkingHours = ({
    from,
    to,
    timeZone,
    workingHoursConfiguration,
}: {
    from: TimeRange['from'];
    to: TimeRange['to'];
    workingHoursConfiguration: WeekdayWorkingHoursConfiguration;
    timeZone: string;
}): boolean => {
    if (isRangeLongerThanDay({ from, to })) {
        return true;
    }

    const fromRelativelyToConfigurationTimezone = convertToTimeZone(from, {
        timeZone,
    });
    const fromWeekDay = fromRelativelyToConfigurationTimezone.getDay();
    const fromTime = format(fromRelativelyToConfigurationTimezone, 'HH:00');

    const toRelativelyToConfigurationTimezone = convertToTimeZone(to, {
        timeZone,
    });
    const toWeekDay = toRelativelyToConfigurationTimezone.getDay();
    const toTime = format(toRelativelyToConfigurationTimezone, 'HH:00');

    const orderedConfiguration = getOrderedWorkingHoursConfiguration(
        workingHoursConfiguration,
    );

    return (
        isTimeInNonWorkingHours(fromTime, orderedConfiguration[fromWeekDay]) ||
        isTimeInNonWorkingHours(toTime, orderedConfiguration[toWeekDay])
    );
};

export const isRangeWithinWorkingHours = ({
    from,
    to,
    timeZone,
    workingHoursConfiguration,
}: {
    from: TimeRange['from'];
    to: TimeRange['to'];
    workingHoursConfiguration: WeekdayWorkingHoursConfiguration;
    timeZone: string;
    range?: string;
}): boolean => {
    if (isRangeLongerThanDay({ from, to })) {
        return true;
    }

    const fromRelativelyToConfigurationTimezone = convertToTimeZone(from, {
        timeZone,
    });
    const fromWeekDay = fromRelativelyToConfigurationTimezone.getDay();
    const fromTime = format(fromRelativelyToConfigurationTimezone, 'HH:00');

    const toRelativelyToConfigurationTimezone = convertToTimeZone(to, {
        timeZone,
    });
    const toWeekDay = toRelativelyToConfigurationTimezone.getDay();
    const toTime = format(toRelativelyToConfigurationTimezone, 'HH:00');

    const orderedConfiguration = getOrderedWorkingHoursConfiguration(
        workingHoursConfiguration,
    );

    return (
        isTimeInWorkingHours(fromTime, orderedConfiguration[fromWeekDay]) ||
        isTimeInWorkingHours(toTime, orderedConfiguration[toWeekDay])
    );
};

export const getWorkingDaysCountWithinRange = ({
    from,
    to,
    workingHoursConfiguration,
    timeZone,
}: {
    from: TimeRange['from'];
    to: TimeRange['to'];
    workingHoursConfiguration: WeekdayWorkingHoursConfiguration;
    timeZone: string;
}): number => {
    let fromRelativelyToConfigurationTimezone = convertToTimeZone(from, {
        timeZone,
    });
    const toRelativelyToConfigurationTimezone = convertToTimeZone(to, {
        timeZone,
    });

    const daysDifference = differenceInCalendarDays(
        toRelativelyToConfigurationTimezone,
        fromRelativelyToConfigurationTimezone,
    );
    let days = 0;

    [...Array(daysDifference + 1).keys()].forEach(() => {
        if (
            isDayWithinWorkingDays(
                fromRelativelyToConfigurationTimezone.getDay(),
                workingHoursConfiguration,
            )
        ) {
            days += 1;
        }

        fromRelativelyToConfigurationTimezone = addDays(
            fromRelativelyToConfigurationTimezone,
            1,
        );
    });

    return days;
};

export const getNonWorkingDaysWithinRange = ({
    from,
    to,
    workingHoursConfiguration,
    timeZone,
}: {
    from: TimeRange['from'];
    to: TimeRange['to'];
    timeZone: string;
    workingHoursConfiguration: WeekdayWorkingHoursConfiguration;
}): number => {
    let fromRelativelyToConfigurationTimezone = convertToTimeZone(from, {
        timeZone,
    });
    const toRelativelyToConfigurationTimezone = convertToTimeZone(to, {
        timeZone,
    });

    // not sure about calendar days
    const daysDifference = differenceInCalendarDays(
        toRelativelyToConfigurationTimezone,
        fromRelativelyToConfigurationTimezone,
    );
    let days = 0;

    [...Array(daysDifference + 1).keys()].forEach(() => {
        if (
            isDayWithinNonWorkingDays(
                fromRelativelyToConfigurationTimezone.getDay(),
                workingHoursConfiguration,
            )
        ) {
            days += 1;
        }

        fromRelativelyToConfigurationTimezone = addDays(
            fromRelativelyToConfigurationTimezone,
            1,
        );
    });

    return days;
};

export const getEventsInWorkingHours = ({
    events = [],
    timeZone,
    workingHoursConfiguration,
}: GetEventsInRangeParams): SensorEventShape[] => {
    const configuration = getOrderedWorkingHoursConfiguration(workingHoursConfiguration);

    return events.filter((event) => {
        const timeRelativelyToConfigurationTimezone = convertToTimeZone(
            event.timestamp ?? 0,
            {
                timeZone,
            },
        );

        const weekDay = timeRelativelyToConfigurationTimezone.getDay();

        if (!configuration[weekDay].is_working_day) {
            return false;
        }

        const time = format(timeRelativelyToConfigurationTimezone, 'HH:mm');

        return isTimeInWorkingHours(time, configuration[weekDay]);
    });
};

export const getEventsInNonWorkingHours = ({
    events = [],
    timeZone,
    workingHoursConfiguration,
}: GetEventsInRangeParams): SensorEventShape[] => {
    const configuration = getOrderedWorkingHoursConfiguration(workingHoursConfiguration);

    return events.filter((event) => {
        const timeRelativelyToConfigurationTimezone = convertToTimeZone(
            event.timestamp ?? 0,
            {
                timeZone,
            },
        );

        const weekDay = timeRelativelyToConfigurationTimezone.getDay();

        const time = format(timeRelativelyToConfigurationTimezone, 'HH:mm');

        return isTimeInNonWorkingHours(time, configuration[weekDay]);
    });
};

export const getEventsInWorkingDays = ({
    events = [],
    timeZone,
    workingHoursConfiguration,
}: GetEventsInRangeParams): SensorEventShape[] => {
    const configuration = getOrderedWorkingHoursConfiguration(workingHoursConfiguration);

    return events.filter((event) => {
        const timeRelativelyToConfigurationTimezone = convertToTimeZone(
            event.timestamp ?? 0,
            {
                timeZone,
            },
        );

        const weekDay = timeRelativelyToConfigurationTimezone.getDay();

        return configuration[weekDay].is_working_day;
    });
};

export const getEventsInNonWorkingDays = ({
    events = [],
    timeZone,
    workingHoursConfiguration,
}: GetEventsInRangeParams): SensorEventShape[] => {
    const configuration = getOrderedWorkingHoursConfiguration(workingHoursConfiguration);

    return events.filter((event) => {
        const timeRelativelyToConfigurationTimezone = convertToTimeZone(
            event.timestamp ?? 0,
            {
                timeZone,
            },
        );

        const weekDay = timeRelativelyToConfigurationTimezone.getDay();

        return !configuration[weekDay].is_working_day;
    });
};

/**
 * Sum values of all readings
 *
 */

export const sumEventsReadings = ({
    events = [],
    eventTypeKey,
}: {
    events: SensorEventShape[];
    eventTypeKey: SensorEventType | SensorEventV2Key;
}): number =>
    round(
        events.reduce(
            (total, event) =>
                (Number(event[eventTypeKey as keyof SensorEventShape]) ?? 0) + total,
            0,
        ),
        2,
    );

/**
 * Get reading percentage in relation to selected quantity
 *
 */

export const getReadingPercentage = ({
    value,
    source,
}: {
    value: number;
    source: number;
}): number | string => {
    if (value && source) {
        return round((value / source) * 100);
    }

    return '-';
};

export const getReadingIntervalInMinutes = (
    sensorConfiguration: ElectricityEventOverview['sensorConfiguration'],
): number => {
    const { readings_interval: readingsInterval } = sensorConfiguration;

    if (!isNil(readingsInterval)) {
        return +readingsInterval;
    }

    return 60;
};

export const noSensorDataExists = (sensor: SensorShape): boolean => {
    return sensor.latest_events_v2
        ? Object.keys(sensor.latest_events_v2).length === 0
        : true;
};
