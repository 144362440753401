import { dataFrequencies } from '@infogrid/dashboards-constants';
import { defaultRollingCalendarPeriods } from '@infogrid/utils-dates';
import { i18n } from '@infogrid/utils-i18n';
import isNil from 'lodash/isNil';
import moment from 'moment';

import {
    BASE_DATE_FORMAT,
    HOURLY_DATE_FORMAT,
    MONTHLY_DATE_FORMAT,
} from 'constants/charts';
import { isObjectEmpty } from 'utils/objects';

import { shortDate } from '../utils/helpers';

export const getSensorsByType = (sensorsIds, sensorsData, type) =>
    sensorsIds.filter((id) => sensorsData[id]?.type_code === type);

export const prepareDateRangeInitialValues = (widget, defaultValues = {}) => {
    let startDate;
    let endDate;

    const timePeriodRange = widget?.configuration?.time_period_range;

    if (timePeriodRange && timePeriodRange !== 'custom') {
        const { startDate: periodStartDate, endDate: periodEndDate } =
            defaultRollingCalendarPeriods.find(
                (p) => p.value === widget?.configuration?.time_period_range,
            ) || {};

        startDate = periodStartDate;
        endDate = periodEndDate;
    } else {
        startDate = widget?.configuration?.time_period_from
            ? moment(widget?.configuration?.time_period_from)
            : defaultValues.startDate;

        endDate = widget?.configuration?.time_period_to
            ? moment(widget?.configuration?.time_period_to)
            : defaultValues.endDate;
    }

    return {
        startDate,
        endDate,
        period: widget?.configuration?.time_period_range || defaultValues.period,
        excludeWeekends: widget?.configuration?.exclude_weekends,
        onlyWorkHours: widget?.configuration?.include_only_work_hours,
        workHoursFrom:
            widget?.configuration?.work_hours_from || defaultValues.workHoursFrom,
        workHoursTo: widget?.configuration?.work_hours_to || defaultValues.workHoursTo,
        timezone: widget?.configuration?.time_zone,
    };
};

export const prepareBaseInitialValues = (widget, defaultValues = {}) => {
    return {
        folders: [],
        sensors: [],
        dateRange: prepareDateRangeInitialValues(widget, defaultValues.dateRange),
        displayOptions: {},
    };
};

export const prepareBaseStoplightValues = (stoplight) => {
    return [
        {
            label: stoplight
                ? stoplight[0]?.label
                : i18n.t('Maintain social distancing', { ns: 'dashboard' }),
            icon: stoplight ? stoplight[0]?.icon : 'far fa-people-arrows',
        },
        {
            label: stoplight
                ? stoplight[1]?.label
                : i18n.t('Wash your hands', { ns: 'dashboard' }),
            icon: stoplight ? stoplight[1]?.icon : 'far fa-hands-wash',
        },
        {
            label: stoplight
                ? stoplight[2]?.label
                : i18n.t('Stay safe', { ns: 'dashboard' }),
            icon: stoplight ? stoplight[2]?.icon : 'far fa-shield-virus',
        },
    ];
};

export const prepareDateRangePayload = (values) => {
    return {
        exclude_weekends: values?.dateRange?.excludeWeekends || false,
        include_only_work_hours: values?.dateRange?.onlyWorkHours || false,
        work_hours_from: values?.dateRange?.workHoursFrom || '09:00',
        work_hours_to: values?.dateRange?.workHoursTo || '17:00',
        time_period_from: moment(values?.dateRange?.startDate).startOf('day').format(),
        time_period_to: moment(values?.dateRange?.endDate).startOf('day').format(),
        time_period_range: values?.dateRange?.period || 'custom',
        time_zone: values?.dateRange?.timezone,
    };
};

export const prepareBaseConfigurationPayload = (values) => {
    return {
        ...prepareDateRangePayload(values),
    };
};

export const getCurrentDisplay = (displays, nearCapacity, outOfCapacity) => {
    if (
        !displays ||
        (isObjectEmpty(displays.greenScreen) &&
            isObjectEmpty(displays.yellowScreen) &&
            isObjectEmpty(displays.redScreen))
    ) {
        return {};
    } else if (outOfCapacity) {
        return displays.redScreen;
    } else if (nearCapacity) {
        return displays.yellowScreen;
    }

    return displays.greenScreen;
};

export const calculateTotalEventsCount = (rows, sensorsNames) =>
    rows.reduce((total, row) => {
        Object.keys(sensorsNames).forEach((sensor) => {
            // eslint-disable-next-line no-param-reassign
            total += row[sensor];
        });

        return total;
    }, 0);

export const periodDurationInDays = (startDate, endDate) => {
    if (!startDate || !endDate) {
        return 0;
    }

    const duration = moment.duration(moment(endDate).diff(moment(startDate)));

    return duration.asDays();
};

export const getAxisTimeFormatByDataFrequency = (dataFrequency, startDate, endDate) => {
    if (!startDate || !endDate) {
        return BASE_DATE_FORMAT;
    }

    switch (dataFrequency) {
        case dataFrequencies.hourly.value: {
            const days = periodDurationInDays(startDate, endDate);

            if (days > 3) {
                return BASE_DATE_FORMAT;
            }

            return HOURLY_DATE_FORMAT;
        }

        case dataFrequencies.monthly.value:
            return MONTHLY_DATE_FORMAT;

        default:
            return BASE_DATE_FORMAT;
    }
};

export const getTooltipTimeFormatByDataFrequency = (dataFrequency) => {
    switch (dataFrequency) {
        case dataFrequencies.hourly.value:
            return '%a %e %b %Y, %k:%M';

        case dataFrequencies.monthly.value:
            return '%b %Y';

        default:
            return '%a %e %b %Y';
    }
};

export const generateGenericFooterLabels = (
    widget,
    widgetConfigurationInitialValues,
    t,
    i18nConfig,
) => {
    const { startDate, endDate, period } =
        widgetConfigurationInitialValues?.dateRange || {};
    const { dataFrequency } = widgetConfigurationInitialValues?.displayOptions || {};

    const sensorsCount = widget?.sensor_count || 0;

    const dataFrequencyKey = Object.keys(dataFrequencies).find(
        (freq) => dataFrequencies[freq].value === dataFrequency,
    );

    const refreshRateLabel = dataFrequencies[dataFrequencyKey]?.label;

    const rollingPeriodLabel = defaultRollingCalendarPeriods.find(
        (periodItem) => periodItem.value === period,
    )?.label;

    const dateRangeLabel = `${shortDate(
        startDate,
        'UTC',
        i18nConfig.language,
    )} - ${shortDate(endDate, 'UTC', i18nConfig.language)}`;

    return {
        periodLabel: rollingPeriodLabel ? t(rollingPeriodLabel) : dateRangeLabel,
        refreshRateLabel: refreshRateLabel ? t(refreshRateLabel) : '',
        sensorsLabel: isNil(widget?.sensor_count)
            ? undefined
            : t('{{count}} Sensors', {
                  count: sensorsCount,
                  defaultValue___one: `${sensorsCount} Sensor`,
                  defaultValue___other: `${sensorsCount} Sensors`,
              }),
    };
};
