import { createRequest } from '@infogrid/core-api';
import type { SensorShape } from '@infogrid/sensors-constants';
import type { AxiosPromise } from 'axios';

import type { TemperatureOffsetParams } from 'utils/types/ts/pipeMonitoringSensors';

import type {
    RegisterQueryParams,
    InstallSensorParams,
    InstallSensorResult,
} from '../hooks';

export const registerSensor = ({
    data,
}: {
    data: RegisterQueryParams;
}): AxiosPromise<SensorShape> =>
    createRequest({
        method: 'post',
        url: 'sensors/register',
        options: {
            data,
        },
    });

export const installSensor = ({
    data,
}: {
    data: InstallSensorParams;
}): AxiosPromise<InstallSensorResult> =>
    createRequest({
        method: 'post',
        url: 'installations',
        options: { data },
    });

export const calibratePipeOffset = ({
    data,
    id,
}: {
    data: TemperatureOffsetParams;
    id: string;
}): AxiosPromise<{ success: boolean }> =>
    createRequest({
        method: 'post',
        url: `sensors/${id}/calibrate-pipe-offset`,
        options: {
            data,
        },
    });
