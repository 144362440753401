import { queryClient } from '@infogrid/core-api';
import type { AxiosResponse } from 'axios';

import { getThresholdsKey } from 'apiHooks/settings/solutions/getQueryKeys';
import type { Thresholds } from 'utils/types/ts/threshold';

export const updateThresholdsAfterUpdate = (
    thresholds: AxiosResponse<Thresholds>,
): void => {
    const thresholdsKey = getThresholdsKey();

    if (!!queryClient.getQueryData(thresholdsKey)) {
        queryClient.setQueryData(thresholdsKey, () => thresholds.data);
    }
};
