import isEmpty from 'lodash/isEmpty';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import GenericFooter from 'views/dashboards/components/Widget/GenericWidget/GenericFooter';

import type { CO2ConcentrationWidgetData } from '../types';
import { useFooterStyles } from './styles';

interface Props {
    widgetData: CO2ConcentrationWidgetData | undefined;
    isWidgetDataLoading: boolean;
}

const Footer = ({ widgetData, isWidgetDataLoading }: Props) => {
    const styles = useFooterStyles();

    const { t } = useTranslation('dashboard');

    const numberOfSensors = widgetData?.sensor_count;

    const sensorsLabel =
        !isWidgetDataLoading && !isEmpty(widgetData)
            ? `${t('{{count}} Sensors', {
                  count: numberOfSensors || 0,
                  defaultValue___one: `${numberOfSensors} Sensor`,
                  defaultValue___other: `${numberOfSensors} Sensors`,
              })}`
            : '';

    return <GenericFooter className={styles.footer} sensorsLabel={sensorsLabel} />;
};

export default memo(Footer);
