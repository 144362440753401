import { makeStyles } from '@material-ui/core';

export const useTemperatureOffsetSetupAlertStyles = makeStyles({
    button: {
        whiteSpace: 'nowrap',
    },
});

export const useTemperatureOffsetModeStyles = makeStyles((theme) => ({
    choice: {
        cursor: 'pointer',
        '&:last-of-type': { marginBottom: theme.spacing(2) },
    },
    chip: {
        marginBottom: theme.spacing(2),
    },
}));
