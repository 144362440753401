import { wrapResponsePromise } from '@infogrid/core-api';
import { useAppDispatch } from '@infogrid/core-ducks';
import { entitiesActions } from '@thorgate/spa-entities';
import { useMutation } from 'react-query';

import {
    cleanInactiveSubfolders,
    invalidateSubfolders,
} from 'apiHooks/folders/accessors';
import { resetAllPagination } from 'ducks/pagination';

import { controllers } from '../controllers';
import type { CreateFolderData } from '../controllers';

export const useCreateFolder = () => {
    const dispatch = useAppDispatch();

    return useMutation(
        ({ data }: { data: CreateFolderData }) =>
            wrapResponsePromise(controllers.createFolder(data)),
        {
            onSuccess: (_0, { data: { parent } }) => {
                // TODO: Remove once FolderTree is migrated to react-query
                dispatch(entitiesActions.clearEntities());
                dispatch(resetAllPagination());

                cleanInactiveSubfolders(parent);
                invalidateSubfolders(parent);
            },
        },
    );
};
