import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { getSensor } from '@infogrid/sensors-configuration';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type { SensorShape } from '@infogrid/sensors-constants';
import type { ReactElement } from 'react';
import { useCallback, useMemo } from 'react';

import { selectInstalledSensor, closeInstallFlow } from 'ducks/installFlow';

import InstallSuccess from './InstallSuccess';

const InstallSuccessContainer = (): ReactElement => {
    const dispatch = useAppDispatch();
    const installedSensor = useAppSelector(selectInstalledSensor) as SensorShape;
    const onClickClose = useCallback(() => dispatch(closeInstallFlow()), [dispatch]);
    const isSensorOffline = useMemo(
        () => !getSensor(installedSensor).getIsOnline(),
        [installedSensor],
    );

    return (
        <InstallSuccess
            onClickClose={onClickClose}
            installedSensor={installedSensor}
            // We *can't* have the offset at this point (a newly installed Pipe Monitoring sensor shouldn't, even in
            // the case of a reused sensor) so we can shortcut the logic here.
            isMissingOffset={installedSensor.type_code === SENSOR_TYPE.TYPE_LEGIONNAIRE}
            isSensorOffline={isSensorOffline}
        />
    );
};

export default InstallSuccessContainer;
