import { Position, Toaster } from '@blueprintjs/core';
import {
    useAppSelector,
    useAppDispatch,
    clearToastQueue,
    clearToastRemoveQueue,
    selectToastQueue,
    selectToastRemoveQueue,
} from '@infogrid/core-ducks';
import { useEffect, useRef } from 'react';

const ToastContainer = () => {
    const dispatch = useAppDispatch();
    const toastQueue = useAppSelector(selectToastQueue);

    // See https://blueprintjs.com/docs/#core/components/toast.static-usage
    const toaster = useRef(null);

    useEffect(() => {
        if (toastQueue.length > 0) {
            toastQueue.forEach(({ id, ...toast }) => {
                toaster.current.show(toast, id);
            });
            dispatch(clearToastQueue());
        }
    }, [dispatch, toastQueue]);

    const toastRemoveQueue = useAppSelector(selectToastRemoveQueue);

    useEffect(() => {
        if (toastRemoveQueue.length > 0) {
            toastRemoveQueue.forEach((id) => {
                toaster.current.dismiss(id);
            });
            dispatch(clearToastRemoveQueue());
        }
    }, [dispatch, toastRemoveQueue]);

    return <Toaster className="toast" position={Position.TOP_RIGHT} ref={toaster} />;
};

export default ToastContainer;
