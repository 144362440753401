import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { memo } from 'react';

interface Props {
    error: string;
    className: string;
}

const QRCodeScannerError = ({ error, className }: Props) => {
    return (
        <Alert severity="error" className={className} data-cypress="qr-scanner-error">
            <Typography variant="body2">{error}</Typography>
        </Alert>
    );
};

export default memo(QRCodeScannerError);
