import { useEffect, useMemo, useState } from 'react';

export const useCustomError = ({ error, handleError }) => {
    const handledError = useMemo(() => {
        if (!error) {
            return null;
        }

        return handleError(error);
    }, [error, handleError]);

    const [customError, setCustomError] = useState(handledError);

    useEffect(() => {
        setCustomError(handledError);
    }, [handledError, setCustomError]);

    return {
        setError: setCustomError,
        error: customError,
    };
};
