import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useWidgetDetailStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
    },
    label: {
        color: theme.palette.common.gray1,
        display: '-webkit-box',
        marginRight: theme.spacing(0.5),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
    },
    value: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
}));
