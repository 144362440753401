import { Box, Button, Chip, Typography } from '@material-ui/core';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { SmartCleaningReport } from 'views/solutionSettings/apiHooks/types';

import { useReportCardStyles } from './styles';

interface ReportCardProps {
    report: SmartCleaningReport;
    onEdit: () => void;
}

export const ReportCard = ({ report, onEdit }: ReportCardProps): JSX.Element => {
    const { t } = useTranslation('solutions');

    const styles = useReportCardStyles();

    const getFloorSummary = useCallback((): string => {
        const floorNames = report.floors.map((floor) => floor.name);
        const floorCount = report.floors.length;
        const floorsDisplay = t('{{count: number}} {{floor}}', {
            count: floorCount,
            floor: floorCount === 1 ? 'floor' : 'floors',
            defaultValue__one: `${floorCount} floor`,
            defaultValue__other: `${floorCount} floors`,
        });

        if (floorCount > 0) {
            return `${floorsDisplay} (${floorNames.join(', ')})`;
        }

        return floorsDisplay;
    }, [report, t]);

    return (
        <Box
            className={styles.card}
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
        >
            <Box display="flex" flexGrow={1} className={styles.description}>
                <Typography>{report.name}</Typography>
                <Typography className={styles.subtitle} variant="subtitle2">
                    {getFloorSummary()}
                </Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                className={styles.wrappedContainer}
            >
                {!report.enabled ? (
                    <Box className={styles.chip}>
                        <Chip label={t('Report disabled')}></Chip>
                    </Box>
                ) : (
                    <div></div>
                )}
                <Box
                    display="flex"
                    className={styles.editAction}
                    alignItems="center"
                    flexDirection="column"
                >
                    <Button
                        className={styles.editButton}
                        type="submit"
                        disabled={false}
                        color="primary"
                        variant="outlined"
                        onClick={onEdit}
                        data-cypress="edit-report"
                    >
                        {t('Edit')}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
