import { LedPulseRatiosValidationSchema } from '@infogrid/electricity-components';
import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import type { FludiaTechnicalConfigurationFormValues } from './types';

export const useFludiaConfigurationFormik = ({
    onSubmit,
}: {
    onSubmit: (formValues: FludiaTechnicalConfigurationFormValues) => void;
}): FormikContextType<FludiaTechnicalConfigurationFormValues> => {
    const { t } = useTranslation('sensors');

    const initialValues: FludiaTechnicalConfigurationFormValues = {
        current_ratio: undefined,
        voltage_ratio: undefined,
        pulse_size: undefined,
    };

    return useFormik<FludiaTechnicalConfigurationFormValues>({
        initialValues,
        enableReinitialize: true,
        validationSchema: LedPulseRatiosValidationSchema(t),
        onSubmit,
    });
};
