import { makeStyles } from '@material-ui/core';

export const useCleaningRulesStyles = makeStyles((theme) => ({
    loader: {
        margin: 'auto',
    },
    ruleSymbol: {
        width: 10,
        height: 10,
        marginRight: theme.spacing(1),
        borderRadius: '50%',
        display: 'inline-block',
        alignSelf: 'center',
    },
    skeleton: {
        width: '100%',
        height: '100%',
        display: 'flex',
        transform: 'initial',
    },
    loadingSpinnerContainer: {
        position: 'absolute',
        left: 'calc(50% - 15px)',
        top: 'calc(50% - 15px)',
    },
    skeletonContainer: {
        position: 'relative',
        height: '100%',
    },
}));
