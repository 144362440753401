import { Modal } from '@infogrid/components-material-ui';
import { Button, Box, Typography } from '@material-ui/core';
import type { FC } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { IdentifySensorSuccessCallback } from 'components/sensors/IdentifySensor';
import IdentifySensor from 'components/sensors/IdentifySensor';
import { InstallationFlowDT1, InstallationFlowDT2 } from 'utils/Images';

import { useIdentifyDTStyles } from './styles';

interface IdentifyDTProps {
    onIdentifySuccess: IdentifySensorSuccessCallback;
    onClickBack: () => void;
}

const IdentifyDT: FC<IdentifyDTProps> = ({ onIdentifySuccess, onClickBack }) => {
    const { t } = useTranslation('sensors');
    const identifyDTStyles = useIdentifyDTStyles();

    return (
        <>
            <Modal.Title>{t('Manually identify a device')}</Modal.Title>
            <Modal.Content>
                <IdentifySensor onSuccess={onIdentifySuccess} />
                <Box className={identifyDTStyles.helpImagesContainer}>
                    <Box>
                        <img
                            src={InstallationFlowDT1}
                            alt="Disruptive Technologies"
                            className={identifyDTStyles.helpImage}
                        />
                        <Typography
                            align="center"
                            className={identifyDTStyles.tip}
                            data-cypress="img-text1"
                        >
                            {t('Touch here on sensors')}
                        </Typography>
                    </Box>
                    <Box>
                        <img
                            src={InstallationFlowDT2}
                            alt="Disruptive Technologies"
                            className={identifyDTStyles.helpImage}
                        />
                        <Typography
                            align="center"
                            className={identifyDTStyles.tip}
                            data-cypress="img-text2"
                        >
                            {t('Touch here on cloud connectors')}
                        </Typography>
                    </Box>
                </Box>
            </Modal.Content>

            <Modal.Actions>
                <Button onClick={onClickBack} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(IdentifyDT);
