import { makeStyles } from '@material-ui/core';

export const useScoreTrendStyles = makeStyles((theme) => ({
    aboutIcon: {
        overflow: 'visible',
        cursor: 'pointer',
        fontSize: 14,
        marginLeft: theme.spacing(0.75),
    },
    chartContainer: {
        height: 21,
        maxWidth: 140,
        marginRight: theme.spacing(1),
    },
    scoreTrendsChartWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    trendDeltaContainer: {
        cursor: 'pointer',
    },
}));
