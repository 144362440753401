import {
    NO_RISK_COLOR,
    LOW_RISK_COLOR,
    AVERAGE_RISK_COLOR,
    HIGH_RISK_COLOR,
    VERY_HIGH_RISK_COLOR,
    EXTREME_RISK_COLOR,
    NO_RISK,
    LOW_RISK,
    AVERAGE_RISK,
    HIGH_RISK,
    VERY_HIGH_RISK,
    EXTREME_RISK,
} from './constants';
import type {
    CO2ConcentrationWidget,
    CO2ConcentrationWidgetConfiguration,
    IconeIndexConfig,
    IconeIndex,
} from './types';

const RISK_METADATA = {
    0: { color: NO_RISK_COLOR, label: NO_RISK },
    1: { color: LOW_RISK_COLOR, label: LOW_RISK },
    2: { color: AVERAGE_RISK_COLOR, label: AVERAGE_RISK },
    3: { color: HIGH_RISK_COLOR, label: HIGH_RISK },
    4: { color: VERY_HIGH_RISK_COLOR, label: VERY_HIGH_RISK },
    5: { color: EXTREME_RISK_COLOR, label: EXTREME_RISK },
};

export const getRiskMetadataByIconeIndex = (
    level: IconeIndex,
): { color: string; label: string } => {
    return RISK_METADATA[level];
};

export const prepareInitialValues = (
    widget: CO2ConcentrationWidget,
): IconeIndexConfig => {
    const { folders = [], sensors = [] } =
        widget?.configuration?.calculators?.icone_index || {};

    return {
        folders,
        sensors,
    };
};

export const prepareConfigurationPayload = (
    values: IconeIndexConfig,
): CO2ConcentrationWidgetConfiguration => {
    return {
        calculators: {
            icone_index: {
                folders: values?.folders,
                sensors: values?.sensors,
            },
        },
    };
};

export const isValidConfiguration = (widget: CO2ConcentrationWidget): boolean => {
    const { folders, sensors } = widget.configuration.calculators.icone_index;

    return !!(folders?.length || sensors?.length);
};
