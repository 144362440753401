import { createRequest } from '@infogrid/core-api';

export const getLabels = (organizationId, signal, params) =>
    createRequest({
        method: 'get',
        url: `org/${organizationId}/labels`,
        signal,
        options: params,
    });

export const editLabels = (data) =>
    createRequest({ method: 'post', url: `sensors/edit_labels`, options: { data } });

export const getUnattachedLabels = (organizationId, signal, params) =>
    createRequest({
        method: 'get',
        url: `org/${organizationId}/labels/unattached_for_sensor`,
        signal,
        options: params,
    });

export const createLabel = (organizationId, data) =>
    createRequest({
        method: 'post',
        url: `org/${organizationId}/labels`,
        options: { data },
    });

export const deleteLabel = (organizationId, labelId) =>
    createRequest({ method: 'delete', url: `org/${organizationId}/labels/${labelId}` });

export const editLabel = (organizationId, labelId, data) =>
    createRequest({
        method: 'put',
        url: `org/${organizationId}/labels/${labelId}`,
        options: { data },
    });

export const getSensorsLabels = (signal, params) =>
    createRequest({ method: 'get', url: '/sensors/labels', signal, options: params });

export const controllers = {
    createLabel,
    deleteLabel,
    editLabel,
    getLabels,
    editLabels,
    getUnattachedLabels,
    getSensorsLabels,
};
