import type { Dashboard } from '@infogrid/dashboards-constants';
import { useEditDashboard } from '@infogrid/dashboards-hooks';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import type { FormikHelpers } from 'formik';
import { useFormik } from 'formik';
import { memo } from 'react';

import { useFormToast } from 'apiHooks/base/hooks';
import { trackDashboardEvent } from 'views/dashboards/utils/analytics';
import { getDashboardNameValidationSchema } from 'views/dashboards/utils/validators';

import RenameDashboardModal from './RenameDashboardModal';

interface Props {
    dashboard: Dashboard;
    open: boolean;
    toggleRenameModal: (state: boolean) => void;
    dashboards: Set<string>;
}

type FormValues = {
    name: string;
};

const RenameDashboardContainer = ({
    dashboard,
    dashboards,
    open,
    toggleRenameModal,
}: Props) => {
    const { handleFormToast } = useFormToast();

    const { mutate: updateDashboard } = useEditDashboard();

    const handleRenameDashboard = (
        submitValues: FormValues,
        formik: FormikHelpers<FormValues>,
    ) => {
        const data = {
            name: submitValues.name,
        };

        trackDashboardEvent(USER_ACTIONS.PRESSED, 'rename dashboard', {
            from: dashboard.name,
            to: submitValues.name,
            id: dashboard.id,
        });

        updateDashboard(
            { dashboardId: dashboard.id, data },
            {
                onSuccess: () => {
                    toggleRenameModal(false);
                    formik.resetForm();
                },
                onError: (err) => {
                    handleFormToast({
                        submitValues,
                        error: err.parsedError,
                        // @ts-expect-error: we have to rewrite handleError to typescript
                        formCallback({ errors }) {
                            formik.setErrors(errors);
                        },
                    });
                },
                onSettled: () => {
                    formik.setSubmitting(false);
                },
            },
        );
    };

    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        resetForm,
    } = useFormik<FormValues>({
        onSubmit: handleRenameDashboard,
        enableReinitialize: true,
        initialValues: {
            name: dashboard.name,
        },
        validationSchema: getDashboardNameValidationSchema(dashboards),
    });

    const onClose = () => {
        toggleRenameModal(false);
        resetForm();
    };

    return (
        <RenameDashboardModal
            onClose={onClose}
            open={open}
            isSubmitting={isSubmitting}
            dashboardName={values.name}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            errors={errors}
            submitDisabled={!values.name}
            touched={touched}
        />
    );
};

export default memo(RenameDashboardContainer);
