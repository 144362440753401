import { useIsMobile } from '@infogrid/utils-hooks';
import { Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';

import { useSetDefaultBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import { BuildingShape } from 'utils/types/buildings';

import { useSelectOutputStyles } from './styles';

const DEFAULT_ITEM_ICON = 'fas fa-star';
const NOT_DEFAULT_ITEM_ICON = 'far fa-star';

const BuildingSwitcherOutput = ({ currentBuilding }) => {
    const isMobile = useIsMobile();

    const styles = useSelectOutputStyles({ isMobile });

    const { mutate: mutateDefaultBuilding } = useSetDefaultBuilding();

    const selectFavoriteBuilding = (e) => {
        e.stopPropagation();

        if (!currentBuilding.is_default) {
            mutateDefaultBuilding(currentBuilding);
        }
    };

    return (
        <>
            <IconButton
                onClick={selectFavoriteBuilding}
                className={styles.buildingSwitcherIconContainer}
            >
                <Icon
                    className={classNames(styles.buildingSwitcherIcon, {
                        [DEFAULT_ITEM_ICON]: currentBuilding.is_default,
                        [NOT_DEFAULT_ITEM_ICON]: !currentBuilding.is_default,
                    })}
                />
            </IconButton>

            <Tooltip title={currentBuilding.name}>
                <Typography
                    className={styles.buildingSwitcherText}
                    data-cypress="building-name"
                    component="h6"
                >
                    {currentBuilding.name}
                </Typography>
            </Tooltip>
        </>
    );
};

BuildingSwitcherOutput.propTypes = {
    currentBuilding: BuildingShape.isRequired,
};

BuildingSwitcherOutput.defaultProps = {};

export default memo(BuildingSwitcherOutput);
