import type { EventsWebhook } from '@infogrid/core-types';
import pick from 'lodash/pick';
import { memo, useCallback } from 'react';

import useUpdateEventsWebhook from 'apiHooks/eventsWebhook/hooks/useUpdateEventsWebhook';
import type { UpdateEventsWebhook } from 'apiHooks/eventsWebhook/types';

import EventsWebhookFormContainer from './EventsWebhookForm.container';

const getEventsWebhookUpdateValues = (
    eventsWebhook: EventsWebhook,
): UpdateEventsWebhook => pick(eventsWebhook, ['is_enabled']);

interface Props {
    eventsWebhook: EventsWebhook;
}

const UpdateEventsWebhookForm = ({ eventsWebhook }: Props) => {
    const { mutate: updateEventsWebhook } = useUpdateEventsWebhook(eventsWebhook.id);

    const onSubmit = useCallback(
        (data, options) => {
            updateEventsWebhook(getEventsWebhookUpdateValues(data), options);
        },
        [updateEventsWebhook],
    );

    return (
        <EventsWebhookFormContainer<EventsWebhook>
            initialValues={eventsWebhook}
            onSubmit={onSubmit}
            canChangeSecret={false}
            showSecret
            canChangeURL={false}
            canChangeSendSignature={false}
            showCreateButton={false}
            disableIsEnabledField={false}
        />
    );
};

export default memo(UpdateEventsWebhookForm);
