import { Autocomplete } from '@infogrid/components-material-ui';
import type { CalendarPeriod } from '@infogrid/utils-dates';
import { defaultRollingCalendarPeriods } from '@infogrid/utils-dates';
import {
    TextField,
    Typography,
    List,
    Radio,
    ListItemText,
    ListItem,
    Grid,
} from '@material-ui/core';
import type { FormikValues } from 'formik';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useTimezones } from 'apiHooks/timezones/hooks';

import { useRollingPeriodStyles } from './styles';

export interface RollingPeriodProps {
    formik: FormikValues;
    periods?: CalendarPeriod[];
}

const RollingPeriod = ({
    formik,
    periods = defaultRollingCalendarPeriods,
}: RollingPeriodProps) => {
    const styles = useRollingPeriodStyles();
    const { t } = useTranslation('dashboard');

    const { data: timezones } = useTimezones();

    const { time_period_range, time_zone } = formik?.values || {};

    const renderTimezonesInput = useCallback(
        (params) => (
            <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                id="timezone"
                InputProps={params.InputProps}
                name="time_zone"
                placeholder={t('Select timezone')}
                variant="outlined"
                label={t('Time zone')}
            />
        ),
        [t],
    );

    const onPeriodChange = useCallback(
        (periodItem) => {
            formik.setFieldValue('time_period_range', periodItem.value);
        },
        [formik],
    );

    const handleTimezoneAutocompleteChange = useCallback(
        (_, timezoneOption) => {
            formik.setFieldValue('time_zone', timezoneOption);
        },
        [formik],
    );

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} data-cypress="widget-date-range-box">
                <Typography
                    variant="body2"
                    color="textSecondary"
                    className={styles.description}
                >
                    {t('Show buildings at risk for movement or temperature in the*')}
                </Typography>
                <List disablePadding data-cypress="date-range-periods">
                    {periods.map((periodItem) => {
                        return (
                            <ListItem
                                key={periodItem.label}
                                dense
                                disableGutters
                                button
                                onClick={() => onPeriodChange(periodItem)}
                                data-cypress={`rolling-period-${periodItem.label}`}
                            >
                                <Radio
                                    size="small"
                                    data-testid={`rolling-period-${periodItem.label}`}
                                    checked={periodItem.value === time_period_range}
                                    color="primary"
                                    inputProps={{
                                        'aria-labelledby': `${periodItem.label}`,
                                    }}
                                    disableRipple
                                />
                                <ListItemText
                                    id={periodItem.label}
                                    primary={t(periodItem.label)}
                                    primaryTypographyProps={{ variant: 'body2' }}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    disableClearable
                    options={timezones}
                    onChange={handleTimezoneAutocompleteChange}
                    renderInput={renderTimezonesInput}
                    value={time_zone}
                    data-cypress="change-widget-timezone"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary">
                    {t('* Data shown in the widget may be up to 2 hours old')}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default memo(RollingPeriod);
