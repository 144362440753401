import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { Checkbox, Tooltip, Link } from '@material-ui/core';
import { memo } from 'react';

import MapSensorIcon from 'components/sensors/LegacySensorIcon';
import useCurrentSensorState from 'utils/hooks/useCurrentSensorState';

import { useSensorItemStyles } from './styles';

interface SensorItemSensor extends Omit<GenericSensorShape, 'last_reading'> {
    profile: {
        name: string;
    };
}

export interface SensorItemProps {
    isSelected: boolean;
    onSelect: () => void;
    sensor: SensorItemSensor;
}

const SensorItem = ({ isSelected, onSelect, sensor }: SensorItemProps) => {
    const styles = useSensorItemStyles();

    const sensorConfig = useCurrentSensorState(sensor);
    const IconComponent = sensorConfig.getIcon();
    const baseIconState = sensorConfig.getMapIconState();

    const sensorName = sensor.profile?.name || sensor.device_name;

    return (
        <div className={styles.container} data-cypress="unassigned-sensor-item">
            <Checkbox checked={isSelected} color="primary" onChange={onSelect} />
            <div className={styles.sensorIconWrapper}>
                <MapSensorIcon
                    IconComponent={IconComponent}
                    state={baseIconState}
                    mode="base"
                    size={24}
                />
            </div>
            <Tooltip placement="top" title={sensorName}>
                <Link
                    className={styles.sensorName}
                    href={`/sensors/${sensor.device_name}`}
                    underline="hover"
                >
                    {sensorName}
                </Link>
            </Tooltip>
        </div>
    );
};

export default memo(SensorItem);
