import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { useCallback } from 'react';

import { selectors as uiSelectors, setDialog } from 'ducks/ui';

type ReturnValue = [
    isOpen: boolean,
    handleClose: () => void,
    handleOpen: () => void,
    toggleOpen: () => void,
];

export const useConnectedIsOpenState = (dialogName: string): ReturnValue => {
    const isOpenSelector = useCallback(
        (state) => uiSelectors.dialog(state, dialogName)?.open,
        [dialogName],
    );

    const isOpen = useAppSelector(isOpenSelector) || false;
    const dispatch = useAppDispatch();

    const handleOpen = useCallback(
        () => dispatch(setDialog(dialogName, true)),
        [dispatch, dialogName],
    );

    const handleClose = useCallback(
        () => dispatch(setDialog(dialogName, false)),
        [dispatch, dialogName],
    );

    const toggleOpen = useCallback(
        () => dispatch(setDialog(dialogName, !isOpen)),
        [dispatch, dialogName, isOpen],
    );

    return [isOpen, handleClose, handleOpen, toggleOpen];
};
