import { memo } from 'react';

import { GenericWidgetContainer } from 'views/dashboards/components/Widget/GenericWidget';

import ConfigureWidgetModal from './ConfigureWidgetModal';
import Content from './Content';
import Footer from './Footer';
import { WidgetConfigurationModalTabs } from './constants';
import { useWidgetStyles } from './styles';
import type { CO2ConcentrationWidget } from './types';
import {
    prepareInitialValues,
    prepareConfigurationPayload,
    isValidConfiguration,
} from './utils';

interface Props {
    widget: CO2ConcentrationWidget;
}

const WidgetConfigurationModalProps = {
    tabs: WidgetConfigurationModalTabs,
    prepareInitialValues,
    prepareConfigurationPayload,
};

const CO2Widget = ({ widget, ...props }: Props) => {
    const styles = useWidgetStyles();

    const isValidConfig = isValidConfiguration(widget);

    return (
        <GenericWidgetContainer
            actionsProps={{
                configurableProps: {
                    forceDuplicateEnabled: isValidConfig,
                },
                refreshableProps: {
                    enabled: isValidConfig,
                },
            }}
            configureWidgetModal={ConfigureWidgetModal}
            contentComponent={Content}
            contentClassName={styles.contentContainer}
            titleProps={{
                titleIcon: <i className="far fa-wind-warning" />,
            }}
            widgetConfigurationModalProps={WidgetConfigurationModalProps}
            footerComponent={Footer}
            widget={widget}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
};

export default memo(CO2Widget);
