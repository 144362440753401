/* eslint-disable id-denylist */

import { makeStyles } from '@material-ui/core';

export const useConfigurationStyles = makeStyles((theme) => ({
    formField: {
        marginBottom: theme.spacing(3),
    },
    autocompleteListbox: ({ isMobile }) =>
        isMobile
            ? {
                  maxHeight: 120,
              }
            : {},

    labelChip: {
        margin: theme.spacing(0.5),
    },
    info: {
        marginBottom: theme.spacing(3),
    },
}));
