import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import type { EventsWebhook } from '@infogrid/core-types';
import { useIsMobile, useIsOpenState } from '@infogrid/utils-hooks';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Button, Typography } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { UseMutateFunction } from 'react-query';

import type {
    CreateEventsWebhookPayload,
    EventsWebhooksConfigResult,
    EventsWebhooksResult,
    ToggleWebhookPayload,
    UpdateEventsWebhook,
} from 'apiHooks/eventsWebhook/types';

import SettingsPlaceholder from '../SettingsPlaceholder/SettingsPlaceholder';
import { DeactivateWebhookModal } from './DeactivateWebhookModal';
import type { MutateWebhookFn } from './FilterableWebhookView.container';
import MaxWebhooksAllowedModal from './MaxWebhooksAllowedModal/MaxWebhooksAllowedModal';
import WebhookCard from './WebhookCard/WebhookCard';
import { WebhookFormModal } from './WebhookFormModal';
import { WEBHOOK_FORM_MODE } from './WebhookFormModal/constants';
import { useFilterableWebhooksPageStyles } from './styles';

interface FilterableWebhookViewProps {
    webhookToEdit?: EventsWebhook;
    webhookData?: EventsWebhooksResult;
    config?: EventsWebhooksConfigResult;
    isLoading: boolean;
    isCreateSubmitting: boolean;
    isDeleteSubmitting: boolean;
    isToggleSubmitting: boolean;
    isUpdateSubmitting: boolean;
    setWebhookToEdit: (webhook: EventsWebhook | undefined) => void;
    refetchWebhooks: () => void;
    refetchWebhookConfig: () => void;
    createEventsWebhook: MutateWebhookFn<CreateEventsWebhookPayload>;
    deleteEventsWebhook: UseMutateFunction<unknown, unknown, unknown, unknown>;
    updateEventsWebhook: MutateWebhookFn<UpdateEventsWebhook>;
    toggleEventsWebhook: MutateWebhookFn<ToggleWebhookPayload>;
}

const WEBHOOK_HELP_CENTER_URL =
    'https://help.infogrid.io/en/articles/6554831-how-to-use-event-webhooks';

const FilterableWebhookView = ({
    webhookToEdit,
    webhookData,
    config,
    isLoading,
    isCreateSubmitting,
    isUpdateSubmitting,
    isToggleSubmitting,
    isDeleteSubmitting,
    setWebhookToEdit,
    refetchWebhooks,
    refetchWebhookConfig,
    createEventsWebhook,
    deleteEventsWebhook,
    toggleEventsWebhook,
    updateEventsWebhook,
}: FilterableWebhookViewProps) => {
    const { t } = useTranslation('integrations');
    const isMobile = useIsMobile();
    const styles = useFilterableWebhooksPageStyles({ isMobileView: isMobile });
    const [
        isMaxWebhooksAllowedModalOpen,
        closeMaxWebhooksAllowedModal,
        openMaxWebhooksAllowedModal,
    ] = useIsOpenState(false);
    const [isMutateWebhookModalOpen, closeMutateWebhookModal, openMutateWebhookModal] =
        useIsOpenState(false);

    const [
        isConfirmToggleWebhookModalOpen,
        closeConfirmToggleWebhookModalModal,
        openConfirmToggleWebhookModalModal,
    ] = useIsOpenState(false);

    const onMutateWebhookCallback = () => {
        closeMutateWebhookModal();
        refetchWebhooks();
        refetchWebhookConfig();
    };

    const onDeleteWebhook = () => {
        deleteEventsWebhook(null, {
            onSuccess: () => {
                onMutateWebhookCallback();
            },
        });
    };

    const onSubmitMutateWebhook = (
        values: CreateEventsWebhookPayload | UpdateEventsWebhook,
        resetForm: () => void,
    ) => {
        const opts = {
            onSuccess: () => {
                onMutateWebhookCallback();
                resetForm();
            },
        };

        if (webhookToEdit) {
            updateEventsWebhook(values as UpdateEventsWebhook, opts);
        } else {
            createEventsWebhook(values as CreateEventsWebhookPayload, opts);
        }
    };

    const onClickAddWebhook = () => {
        if (config && config.remaining_allowed_webhooks_count === 0) {
            openMaxWebhooksAllowedModal();
            return;
        }

        setWebhookToEdit(undefined);
        openMutateWebhookModal();
    };

    const onClickEditWebhook = useCallback(
        (webhook: EventsWebhook) => {
            setWebhookToEdit(webhook);
            openMutateWebhookModal();
        },
        [setWebhookToEdit, openMutateWebhookModal],
    );

    const onToggleWebhook = useCallback(
        (webhook: EventsWebhook) => {
            setWebhookToEdit(webhook);
            if (webhook.is_enabled) {
                openConfirmToggleWebhookModalModal();
                return;
            }

            toggleEventsWebhook(
                { is_enabled: !webhook.is_enabled, webhookId: webhook.id },
                {
                    onSuccess: () => {
                        refetchWebhooks();
                        closeConfirmToggleWebhookModalModal();
                    },
                },
            );
        },
        [
            openConfirmToggleWebhookModalModal,
            setWebhookToEdit,
            toggleEventsWebhook,
            refetchWebhooks,
            closeConfirmToggleWebhookModalModal,
        ],
    );

    const onConfirmDeactivateWebhook = useCallback(() => {
        if (!webhookToEdit) {
            // we should never hit this, but handle a weird error
            closeConfirmToggleWebhookModalModal();
            return;
        }

        toggleEventsWebhook(
            { is_enabled: !webhookToEdit.is_enabled, webhookId: webhookToEdit.id },
            {
                onSuccess: () => {
                    closeConfirmToggleWebhookModalModal();
                    refetchWebhooks();
                },
            },
        );
    }, [
        toggleEventsWebhook,
        webhookToEdit,
        refetchWebhooks,
        closeConfirmToggleWebhookModalModal,
    ]);

    const content = useMemo(() => {
        if (isLoading || !webhookData || !config) {
            return <LoadingPlaceholder />;
        }

        if (webhookData?.count === 0) {
            return (
                <SettingsPlaceholder
                    icon={'fal fa-plug'}
                    label={registerTranslationKey(
                        'You currently have no webhooks set up.',
                    )}
                />
            );
        }

        return (
            <div className={styles.webhookViewContainer}>
                {webhookData?.results.map((webhook: EventsWebhook) => {
                    return (
                        <WebhookCard
                            key={webhook.id}
                            webhook={webhook}
                            onClickEditWebhook={onClickEditWebhook}
                            onToggleWebhook={onToggleWebhook}
                            isToggleSubmitting={isToggleSubmitting}
                        />
                    );
                })}
            </div>
        );
    }, [
        isLoading,
        webhookData,
        config,
        styles,
        onClickEditWebhook,
        onToggleWebhook,
        isToggleSubmitting,
    ]);

    return (
        <>
            <div className={styles.headerContainer}>
                <div>
                    <Typography component="h1" variant="h4" className={styles.pageTitle}>
                        {t('Event Webhooks')}
                    </Typography>
                    <Typography
                        className={styles.description}
                        color="textSecondary"
                        variant="body2"
                        display={isMobile ? 'inline' : undefined}
                    >
                        {t(
                            'Set up a maximum of {{count}} webhooks to enable the ingestion of sensor event data into an external system. ',
                            { count: config?.max_allowed_webhooks_count || 5 },
                        )}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                        display={isMobile ? 'inline' : undefined}
                    >
                        {
                            <Trans t={t} i18nKey={'webhook-help-center-text'}>
                                <span>For more information on webhooks, see </span>
                                <a
                                    target="_blank"
                                    href={WEBHOOK_HELP_CENTER_URL}
                                    rel="noreferrer"
                                    className={styles.helpCenterLink}
                                >
                                    <span>this article in the Help Center.</span>
                                </a>
                            </Trans>
                        }
                    </Typography>
                </div>
                <Button
                    className={styles.addWebhookButton}
                    onClick={onClickAddWebhook}
                    variant={'contained'}
                    color={'primary'}
                >
                    {t('Add webhook')}
                </Button>
            </div>
            {content}

            <MaxWebhooksAllowedModal
                open={isMaxWebhooksAllowedModalOpen}
                onClose={closeMaxWebhooksAllowedModal}
                maxWebhooksAllowed={config?.max_allowed_webhooks_count || 5}
            />

            {config && (
                <WebhookFormModal
                    mode={
                        webhookToEdit ? WEBHOOK_FORM_MODE.EDIT : WEBHOOK_FORM_MODE.CREATE
                    }
                    existingWebhook={webhookToEdit}
                    open={isMutateWebhookModalOpen}
                    onClose={closeMutateWebhookModal}
                    onSubmit={onSubmitMutateWebhook}
                    webhookConfig={config}
                    deleteWebhook={onDeleteWebhook}
                    isSubmitting={
                        isCreateSubmitting || isUpdateSubmitting || isDeleteSubmitting
                    }
                />
            )}

            <DeactivateWebhookModal
                open={isConfirmToggleWebhookModalOpen}
                onClose={closeConfirmToggleWebhookModalModal}
                onConfirm={onConfirmDeactivateWebhook}
                loading={isToggleSubmitting}
            />
        </>
    );
};

export default FilterableWebhookView;
