import type { OrganizationIdType } from '@infogrid/core-types';
import type { QueryKey } from 'react-query';

import {
    SENSORS_QUERY_KEY,
    SENSOR_COUNTS_BY_TYPES_KEY,
    LATEST_SENSORS_EVENTS_QUERY_KEY,
    SENSORS_EVENTS_QUERY_KEY,
    SEEN_SENSORS_QUERY_KEY,
    SENSORS_SEEN_BY_QUERY_KEY,
    SENSORS_LIST_QUERY_KEY,
    SOURCE_FOR_SENSORS_LIST_KEY,
    FOLDER_SENSORS_LIST_QUERY_KEY,
    ORG_SENSORS_QUERY_KEY,
} from 'apiHooks/base/queryKeys';
import type { FromToQuery } from 'utils/types/ts/app';

export const getSensorKey = (id?: string): QueryKey => [SENSORS_QUERY_KEY, id];

export const getSensorCountsByTypeKey = (): QueryKey => [SENSOR_COUNTS_BY_TYPES_KEY];

export const getSensorsLatestEventsKey = (deviceNames: string[]): QueryKey => [
    LATEST_SENSORS_EVENTS_QUERY_KEY,
    deviceNames,
];

export const getSensorEventsFullKey = (
    deviceName: string,
    range: string,
    fromTo: FromToQuery | null = null,
): QueryKey => [SENSORS_EVENTS_QUERY_KEY, deviceName, range, fromTo];

export const getFolderSensorsFullKey = (
    folderId: string | number,
    params: { [key: string]: unknown },
): QueryKey => [FOLDER_SENSORS_LIST_QUERY_KEY, `${folderId}`, params];

export const getFolderSensorsKey = (folderId: string | number): QueryKey => [
    FOLDER_SENSORS_LIST_QUERY_KEY,
    `${folderId}`,
];

export const getSeenSensorsKey = (
    deviceName: string,
    params: { [key: string]: unknown },
): QueryKey => [SEEN_SENSORS_QUERY_KEY, deviceName, params];

export const getSensorsSeenByKey = (deviceName: string): QueryKey => [
    SENSORS_SEEN_BY_QUERY_KEY,
    deviceName,
];

export const getSensorListKey = (): QueryKey => [SENSORS_LIST_QUERY_KEY];

export const getSensorListFullKey = (params?: { [key: string]: unknown }): QueryKey => [
    SENSORS_LIST_QUERY_KEY,
    params,
];

export const getSourceForSensorsKey = (
    deviceName: string,
    query: { [key: string]: unknown },
): QueryKey => [SOURCE_FOR_SENSORS_LIST_KEY, deviceName, query];

export const getOrgSensorsKey = (organizationId: OrganizationIdType): QueryKey => [
    ORG_SENSORS_QUERY_KEY,
    organizationId,
];
