import { Modal } from '@infogrid/components-material-ui';
import { Button, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FoldersTree from 'components/FoldersTree';
import SubtreeAction from 'components/FoldersTree/FoldersSubtree/SubtreeAction';

import { useStylesChoosingFolder } from './styles';

const BUILDING_ICON = 'fal fa-building';
const FLOOR_ICON = 'far fa-layer-group';

const checkIsEnabled = (node) => {
    const { floorplan_type, editable } = node;

    return floorplan_type === 'folder' && editable;
};

const handleIconType = (floorplanType) => {
    const floorPlanEntityIcons = {
        building: BUILDING_ICON,
        floor: FLOOR_ICON,
    };

    return floorPlanEntityIcons[floorplanType] || null;
};

const expandableNodeTypes = ['root', 'folder'];

const treeExtractors = {
    keyExtractor: (node) => node.id?.toString(),
    valueExtractor: (node) => node.name?.toString(),
    nodeTypeExtractor: ({ floorplan_type }) => floorplan_type,
    subitemsExtractor: ({ subfolders }) => subfolders,
    labelIconExtractor: ({ floorplan_type }) => handleIconType(floorplan_type),
    nodePermissionsExtractor: checkIsEnabled,
};

const ChoosingFolderBuilding = ({
    title,
    handleNext,
    handleSelectFolder,
    folders,
    selectedFolders,
    isButtonDisabled,
    openCreateFolder,
    isFetching,
    expandedFolderPath,
    setExpandedFolderPath,
}) => {
    const styles = useStylesChoosingFolder();

    const { t } = useTranslation('floorplan');

    useEffect(() => {
        if (!expandedFolderPath.length && folders.length) {
            const rootFolder = folders[0];

            setExpandedFolderPath([treeExtractors.keyExtractor(rootFolder)]);
        }
    }, [expandedFolderPath, folders, setExpandedFolderPath]);

    const renderActions = useCallback(
        ({
            isOfExpandableType,
            nodeEditable,
            nodeId,
            nodeValue,
            nodeChildren,
            folderPath,
            nodeType,
        }) => {
            const createFolder = () => {
                setExpandedFolderPath(folderPath);

                openCreateFolder({
                    parentId: nodeId,
                    name: nodeValue,
                    nodeChildren,
                });
            };

            const createFolderActionAvailable =
                (isOfExpandableType && nodeEditable) || nodeType === 'root';

            return (
                createFolderActionAvailable && (
                    <SubtreeAction color="primary" handler={createFolder} />
                )
            );
        },
        [openCreateFolder, setExpandedFolderPath],
    );

    return (
        <>
            <Modal.Title>{title}</Modal.Title>

            <Modal.Description className={styles.description}>
                {t(
                    'Select an existing folder that represents this building, or create a new folder for this building.',
                )}
            </Modal.Description>

            <Modal.Content>
                <Modal.ContentItem className={styles.folderTreeContainer}>
                    {(isFetching || !expandedFolderPath.length) && (
                        <CircularProgress
                            size={50}
                            color="primary"
                            className={styles.folderTreeSpinner}
                        />
                    )}

                    {!isFetching && !!expandedFolderPath.length && (
                        <FoldersTree
                            expandableTypes={expandableNodeTypes}
                            defaultExpanded={expandedFolderPath}
                            defaultSelected={selectedFolders}
                            onAfterSelect={handleSelectFolder}
                            folders={folders}
                            renderActions={renderActions}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...treeExtractors}
                        />
                    )}
                </Modal.ContentItem>
            </Modal.Content>

            <Modal.Actions justify="right">
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleNext}
                    disabled={isButtonDisabled}
                    data-cypress="next"
                >
                    {t('Next')}
                </Button>
            </Modal.Actions>
        </>
    );
};

ChoosingFolderBuilding.propTypes = {
    title: PropTypes.string,
    isButtonDisabled: PropTypes.bool,
    isFetching: PropTypes.bool,
    folders: PropTypes.arrayOf(PropTypes.object),
    handleNext: PropTypes.func.isRequired,
    openCreateFolder: PropTypes.func.isRequired,
    handleSelectFolder: PropTypes.func.isRequired,
    selectedFolders: PropTypes.arrayOf(PropTypes.string),
    expandedFolderPath: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ).isRequired,
    setExpandedFolderPath: PropTypes.func.isRequired,
};
ChoosingFolderBuilding.defaultProps = {
    title: '',
    folders: [],
    isButtonDisabled: false,
    isFetching: false,
    selectedFolders: [],
};

export default ChoosingFolderBuilding;
