import { useState } from 'react';

import { useGetSmartCleaningHierarchy } from 'views/solutionSettings/apiHooks/useGetSmartCleaningHierarchy';

import CleaningRulesLocationSelector from '../../../CleaningRulesLocationSelector/CleaningRulesLocationSelector';

interface Props {
    setSelectedSpaceIds: (ids: number[]) => void;
}

const SelectLocationsContainer = ({ setSelectedSpaceIds }: Props) => {
    const [currentPageId, setCurrentPageId] = useState<number>();

    const { data: locations, isLoading: locationsIsLoading } =
        useGetSmartCleaningHierarchy({
            rules: true,
            folder_id: currentPageId,
        });

    const changePage = (id: number) => {
        setCurrentPageId(id);
    };

    return (
        <>
            <CleaningRulesLocationSelector
                locations={locations}
                locationsIsLoading={locationsIsLoading}
                changePage={changePage}
                currentPageId={currentPageId}
                setLocationCount={() => null}
                setSelectedSpaceIds={setSelectedSpaceIds}
                hideCustomColumns={true}
            />
        </>
    );
};

export default SelectLocationsContainer;
