import qs from 'qs';
import { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
    cancelPaginatedBuildings,
    invalidatePaginatedBuildings,
} from 'apiHooks/floorPlan/buildings/accessors';

import EditBuildingProvider from '../../EditBuildingProvider';
import type { OnBuildingUpdate } from '../../EditBuildingProvider/EditBuildingProvider';
import AddBuildingModal from './AddBuildingModal';

interface Props {
    onClose: () => void;
    open: boolean;
}

const AddBuildingModalContainer = ({ onClose, open }: Props) => {
    const location = useLocation();
    const query = useMemo(
        () =>
            location.search
                ? qs.parse(location.search, { ignoreQueryPrefix: true })
                : null,
        [location.search],
    );
    const queryParams = {
        q: query?.q,
        building_types: query?.building_types,
        countries: query?.countries,
    };

    const onBuildingUpdate: OnBuildingUpdate = (_, values) => {
        if (values.actionType === 'save') {
            onClose();
        }

        cancelPaginatedBuildings(queryParams);
        invalidatePaginatedBuildings(queryParams);
    };

    return (
        <EditBuildingProvider building={null} onBuildingUpdate={onBuildingUpdate}>
            <AddBuildingModal onClose={onClose} open={open} />
        </EditBuildingProvider>
    );
};

export default memo(AddBuildingModalContainer);
