import { Classes } from '@blueprintjs/core';
import { routesManager } from '@infogrid/core-routing';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SuccessMovingToast = ({ folderName, folderId }) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex align-items-center">
            <span>
                {t('Items successfully moved to')} {folderName}
            </span>
            <Link
                className={classNames(
                    Classes.BUTTON,
                    Classes.MINIMAL,
                    'ml-3',
                    'flex-shrink-0',
                )}
                to={routesManager.resolvePath('folders:details', {
                    folderId,
                })}
            >
                {t('View folder')}
            </Link>
        </div>
    );
};

SuccessMovingToast.propTypes = {
    folderName: PropTypes.string.isRequired,
    folderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default SuccessMovingToast;
