import { Modal } from '@infogrid/components-material-ui';
import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import classNames from 'classnames';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { InstallStep } from 'ducks/installFlow';
import {
    INSTALL_STEPS,
    INSTALL_STEPS_CLOSE_CONFIRMATION,
    INSTALL_STEPS_CLOSE_DISABLED,
    closeInstallFlow,
    selectIsOpen,
    selectStep,
} from 'ducks/installFlow';

import BarcodeScan from '../BarcodeScan';
import ConfigureDevice from '../ConfigureDevice';
import InstallError from '../InstallError';
import InstallFlowConfirmation from '../InstallFlowConfirmation';
import InstallPending from '../InstallPending';
import InstallSuccess from '../InstallSuccess';
import ManualIdentify from '../ManualIdentify';
import OfflineConfirmation from '../OfflineConfirmation';
import SensorOffline from '../SensorOffline';
import { trackInstallFlowUserEvent } from '../utils/analytics';
import { useInstallFlowStyles } from './styles';

// Map steps to components
const STEP_COMPONENTS: Record<InstallStep, FC> = {
    [INSTALL_STEPS.BARCODE_SCAN]: BarcodeScan,
    [INSTALL_STEPS.INSTALL_PENDING]: InstallPending,
    [INSTALL_STEPS.INSTALL_SUCCESS]: InstallSuccess,
    [INSTALL_STEPS.INSTALL_ERROR]: InstallError,
    [INSTALL_STEPS.MANUAL_IDENTIFY]: ManualIdentify,
    [INSTALL_STEPS.SENSOR_OFFLINE]: SensorOffline,
    [INSTALL_STEPS.OFFLINE_CONFIRMATION]: OfflineConfirmation,
    [INSTALL_STEPS.CONFIGURE_DEVICE]: ConfigureDevice,
};

const InstallFlow: FC = () => {
    const [confirmationModalOpened, setConfirmationModalOpen] = useState(false);
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(selectIsOpen);
    const step = useAppSelector(selectStep);
    const Component = STEP_COMPONENTS[step];
    const canClose = !INSTALL_STEPS_CLOSE_DISABLED.includes(step);
    const needsConfirmation = INSTALL_STEPS_CLOSE_CONFIRMATION.includes(step);
    const styles = useInstallFlowStyles();
    const openConfirmationModal = () => setConfirmationModalOpen(true);
    const closeConfirmationModal = () => setConfirmationModalOpen(false);

    useEffect(() => {
        if (isOpen) {
            trackInstallFlowUserEvent(USER_ACTIONS.VIEWED, step);
        }
    }, [isOpen, step]);

    const discardInstallationFlow = () => {
        closeConfirmationModal();
        dispatch(closeInstallFlow());
    };

    const onClose = useCallback(() => {
        if (needsConfirmation) {
            openConfirmationModal();

            return;
        }

        if (canClose) {
            dispatch(closeInstallFlow());
        }
    }, [canClose, needsConfirmation, dispatch]);

    const modalClasses = useMemo(
        () => ({
            container: classNames(
                styles.container,
                step !== INSTALL_STEPS.BARCODE_SCAN && styles.narrowContainer,
            ),
        }),
        [styles, step],
    );

    return (
        <>
            <InstallFlowConfirmation
                isOpen={confirmationModalOpened}
                onDiscard={discardInstallationFlow}
                onResume={closeConfirmationModal}
            />
            <Modal
                open={isOpen && !!Component}
                onClose={onClose}
                hideCloseButton={!canClose}
                invertCloseButton={step === INSTALL_STEPS.BARCODE_SCAN}
                classes={modalClasses}
            >
                <Component />
            </Modal>
        </>
    );
};

export default InstallFlow;
