import { Modal } from '@infogrid/components-material-ui';
import type { BuildingDetailsModalValues } from '@infogrid/locations-types';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import BuildingDetails from 'views/estate/components/forms/BuildingDetails';

import { useAddBuildingModalStyles } from './styles';

const FORM_ID = 'add-building-form';

interface Props {
    onClose: () => void;
    open: boolean;
}

const AddBuildingModal = ({ onClose, open }: Props) => {
    const styles = useAddBuildingModalStyles();

    const isMobile = useIsMobile();

    const { t } = useTranslation();

    const { isSubmitting, handleSubmit, setFieldValue } =
        useFormikContext<BuildingDetailsModalValues>();

    const onSave = () => {
        setFieldValue('actionType', 'save');
    };

    const onSaveAndAddAnother = () => {
        setFieldValue('actionType', 'add-another');
    };

    return (
        <Modal fullScreen={isMobile} onClose={onClose} open={open}>
            <Modal.Title>{t('Add a building', { ns: 'estate' })}</Modal.Title>
            <Modal.Content>
                <form className={styles.form} id={FORM_ID} onSubmit={handleSubmit}>
                    <BuildingDetails />
                </form>
            </Modal.Content>
            <Modal.Actions justify="space-between">
                <Button color="primary" disabled={isSubmitting} onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <div>
                    {!isMobile && (
                        <Button
                            className={styles.saveAndAddAnotherButton}
                            color="primary"
                            data-cypress="save-and-add-another"
                            disabled={isSubmitting}
                            form={FORM_ID}
                            onClick={onSaveAndAddAnother}
                            type="submit"
                            variant="outlined"
                        >
                            {t('Save + add another')}
                        </Button>
                    )}

                    <Button
                        color="primary"
                        className={styles.saveButton}
                        data-cypress="save"
                        disabled={isSubmitting}
                        form={FORM_ID}
                        onClick={onSave}
                        type="submit"
                        variant="contained"
                    >
                        {t('Save')}
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(AddBuildingModal);
