import type { CheckboxProps } from '@material-ui/core/Checkbox';
import Checkbox from '@material-ui/core/Checkbox';
import { useCallback, memo } from 'react';

interface SelectableTableCellProps<Value = undefined>
    extends Omit<CheckboxProps, 'onSelect' | 'checked' | 'inputProps'> {
    item: Value;
    onSelect: (checked: boolean, item: Value) => void;
    checked: boolean;
    inputProps?: CheckboxProps['inputProps'] & {
        'data-cypress'?: string;
    };
}

const SelectableTableCell = <Value,>({
    item,
    onSelect,
    checked,
    ...props
}: SelectableTableCellProps<Value>): JSX.Element => {
    const onChange = useCallback(
        (e) => {
            onSelect(e.target.checked, item);
        },
        [item, onSelect],
    );

    return (
        <Checkbox
            checked={checked}
            color="primary"
            onChange={onChange}
            size="small"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
};

export default memo(SelectableTableCell) as typeof SelectableTableCell;
