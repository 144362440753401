import { calendarPeriods } from '@infogrid/dashboards-constants';
import moment from 'moment';

import {
    prepareBaseConfigurationPayload,
    prepareBaseInitialValues,
} from 'views/dashboards/widgets/utils';

const defaultValues = {
    dateRange: {
        period: calendarPeriods.week,
        startDate: moment().subtract(7, 'days'),
        endDate: moment().subtract(1, 'day'),
    },
};

export const prepareInitialValues = (widget) => {
    const { folders = [], sensors = [] } =
        widget?.configuration?.calculators?.pipe_monitoring || {};

    return {
        ...prepareBaseInitialValues(widget, defaultValues),
        folders,
        sensors,
    };
};

export const prepareConfigurationPayload = (values) => {
    const payload = prepareBaseConfigurationPayload(values);

    return {
        ...payload,
        calculators: {
            pipe_monitoring: {
                folders: values?.folders,
                sensors: values?.sensors,
            },
        },
    };
};
