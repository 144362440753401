import { useAppDispatch } from '@infogrid/core-ducks';
import { useSelectorWithArgs } from '@infogrid/utils-hooks';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import FolderTreeSensor from 'components/Folders/FolderTree/FolderTreeSensor';
import InfiniteScrollContainer from 'containers/InfiniteScrollContainer';
import { fetchFolderSensors } from 'sagas/folders/fetchFolderSensors';
import { selectEntityCollectionIds } from 'schemas/entity';
import { relatedSensorsKeyFn } from 'schemas/folder';
import { getFolderSensorsKeyOptions } from 'utils/folderTree';
import {
    useSensorLabelsFilter,
    useSensorReadingTypesFilter,
    useSensorTypeFilter,
} from 'utils/sensor';

const FolderTreeFolderSensors = ({
    folderId,
    basePath,
    depth,
    sensorTypeFilter,
    labelsFilter,
    readingTypesFilter,
    nodeClassName,
    selectIsSelected,
    selectSecondaryLabel,
    scrollParentRef,
    onNodeClick,
}) => {
    const dispatch = useAppDispatch();

    const labelsQuery = useSensorLabelsFilter(labelsFilter);
    const readingTypesQuery = useSensorReadingTypesFilter(readingTypesFilter);
    const { sensorQuery: sensorTypeQuery } = useSensorTypeFilter(sensorTypeFilter);

    // TODO: WEB-2887
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const query = {
        ...labelsQuery,
        ...sensorTypeQuery,
        ...readingTypesQuery,
    };

    const keyOptions = useMemo(
        () => getFolderSensorsKeyOptions(folderId, query),
        [folderId, query],
    );
    const sensorCollectionKey = relatedSensorsKeyFn(keyOptions);
    const sensorIds = useSelectorWithArgs(selectEntityCollectionIds, sensorCollectionKey);

    const fetchMoreSensors = useCallback(
        (payload, meta) => {
            dispatch(
                fetchFolderSensors(
                    {
                        ...payload,
                        kwargs: { folderId, ...payload?.kwargs },
                        query: { ...payload?.query, ...query },
                    },
                    {
                        ...meta,
                        keyOptions,
                    },
                ),
            );
        },
        [dispatch, folderId, query, keyOptions],
    );
    const scrollerProps = useMemo(
        () =>
            scrollParentRef
                ? {
                      getScrollParent: () => {
                          return scrollParentRef.current;
                      },
                  }
                : undefined,
        [scrollParentRef],
    );

    return (
        <InfiniteScrollContainer
            entityKey={sensorCollectionKey}
            hasContent={Boolean(sensorIds?.length)}
            onFetchMore={fetchMoreSensors}
            doInitialFetch
            emptyState={null}
            hideLoader
            scrollerProps={scrollerProps}
        >
            {(sensorIds || []).map((sensorId) => (
                <FolderTreeSensor
                    key={`sensor-${sensorId}`}
                    sensorId={sensorId}
                    basePath={basePath}
                    depth={depth}
                    nodeClassName={nodeClassName}
                    selectIsSelected={selectIsSelected}
                    selectSecondaryLabel={selectSecondaryLabel}
                    onNodeClick={onNodeClick}
                />
            ))}
        </InfiniteScrollContainer>
    );
};

FolderTreeFolderSensors.propTypes = {
    folderId: PropTypes.number.isRequired,
    basePath: PropTypes.string.isRequired,
    // Depth set for sensors within, do <FolderTreeFolderSensors depth={parentDepth + 1}
    depth: PropTypes.number.isRequired,
    sensorTypeFilter: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    labelsFilter: PropTypes.arrayOf(PropTypes.number),
    readingTypesFilter: PropTypes.arrayOf(PropTypes.string),
    nodeClassName: PropTypes.string,
    selectIsSelected: PropTypes.func,
    selectSecondaryLabel: PropTypes.func,
    scrollParentRef: PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
    onNodeClick: PropTypes.func,
};
FolderTreeFolderSensors.defaultProps = {
    sensorTypeFilter: undefined,
    labelsFilter: undefined,
    readingTypesFilter: undefined,
    nodeClassName: undefined,
    selectIsSelected: undefined,
    selectSecondaryLabel: undefined,
    scrollParentRef: undefined,
    onNodeClick: undefined,
};

export default FolderTreeFolderSensors;
