import { Modal } from '@infogrid/components-material-ui';
import { Button, Typography, TextField } from '@material-ui/core';
import type { FormikProps } from 'formik';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useIdentifyFormStyles } from './styles';

export interface IdentifyFormValues {
    deviceId: string;
    serialNumber?: string;
}

export interface IdentifyFormProps {
    formik: FormikProps<IdentifyFormValues>;
    helpText?: string;
    helpImage?: string;
    deviceIdLabel?: string;
    hasSerialNumber?: boolean;
    onClickBack: () => void;
    isLoading?: boolean;
}

const IdentifyForm: FC<IdentifyFormProps> = ({
    formik: {
        values: { deviceId, serialNumber },
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
    },
    helpText,
    helpImage,
    deviceIdLabel,
    hasSerialNumber,
    isLoading,
    onClickBack,
}) => {
    const { t } = useTranslation('sensors');
    const styles = useIdentifyFormStyles();

    return (
        <form onSubmit={handleSubmit}>
            <Modal.Title>{t('Manually identify a device')}</Modal.Title>
            <Modal.Content className={styles.content}>
                {helpText && (
                    <Typography color="textSecondary" data-cypress="instruction-text">
                        {t(helpText)}
                    </Typography>
                )}
                {helpImage && (
                    <img
                        src={helpImage}
                        alt="How to find your device ID"
                        className={styles.image}
                    />
                )}
                {hasSerialNumber && (
                    <TextField
                        fullWidth
                        label={t('Serial number')}
                        variant="outlined"
                        name="serialNumber"
                        value={serialNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.serialNumber && !!errors.serialNumber}
                        helperText={touched.serialNumber && errors.serialNumber}
                        inputProps={{ 'aria-label': 'Serial number' }}
                        data-cypress="serial-number"
                    />
                )}
                <TextField
                    fullWidth
                    label={deviceIdLabel || t('Device ID')}
                    variant="outlined"
                    name="deviceId"
                    value={deviceId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.deviceId && !!errors.deviceId}
                    helperText={touched.deviceId && errors.deviceId}
                    inputProps={{ 'aria-label': 'Device ID' }}
                    data-cypress="device-id"
                />
            </Modal.Content>
            <Modal.Actions className={styles.actions}>
                <Button onClick={onClickBack} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={
                        !deviceId || (hasSerialNumber && !serialNumber) || isLoading
                    }
                    data-cypress="submit"
                >
                    {t('Next')}
                </Button>
            </Modal.Actions>
        </form>
    );
};

export default IdentifyForm;
