import { Chip } from '@infogrid/components-material-ui';
import { useAppDispatch, toastSuccess } from '@infogrid/core-ducks';
import type { EventsWebhook } from '@infogrid/core-types';
import { useIsMobile, useIsTablet } from '@infogrid/utils-hooks';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import {
    Button,
    Card,
    CardContent,
    Divider,
    FormControlLabel,
    InputAdornment,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import copy from 'copy-to-clipboard';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ShowSecretButton from './ShowSecret/ShowSecret';
import { useWebhookCardStyles } from './styles';

interface WebhookCardProps {
    webhook: EventsWebhook;
    onClickEditWebhook: (webhook: EventsWebhook) => void;
    onToggleWebhook: (webhook: EventsWebhook) => void;
    isToggleSubmitting: boolean;
}

const WebhookCard = ({
    webhook,
    onClickEditWebhook,
    onToggleWebhook,
    isToggleSubmitting,
}: WebhookCardProps) => {
    const { t } = useTranslation('integrations');
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const styles = useWebhookCardStyles({
        isMobileView: isMobile,
        isTabletView: isTablet,
    });
    const dispatch = useAppDispatch();
    const [showSecretKey, setShowSecretKey] = useState<boolean>(false);

    const authText = useMemo(() => {
        if (!webhook) {
            return '';
        }

        return webhook.send_signature
            ? registerTranslationKey('send signature in headers')
            : registerTranslationKey('send key in headers');
    }, [webhook]);

    const copyToClipboard = () => {
        copy(webhook.secret);
        dispatch(
            toastSuccess({
                message: t('Secret Key copied to clipboard.'),
            }),
        );
    };

    const eventTypesFieldValue = useMemo(() => {
        if (webhook.event_types.length === 0) {
            return t('All types selected');
        }

        return t('{{count}} types selected', { count: webhook.event_types.length });
    }, [webhook, t]);

    const toggleLabel = useMemo(
        () => (webhook.is_enabled ? t('Active') : t('Inactive')),
        [t, webhook.is_enabled],
    );

    return (
        <Card variant="elevation" elevation={0} data-cypress="event-webhook-card">
            <CardContent className={styles.webhookCardContainer}>
                <div className={styles.titleContainer}>
                    <div className={styles.webhookNameContainer}>
                        <Typography
                            component="h1"
                            variant="h4"
                            className={styles.webhookTitle}
                            data-cypress={'events-webhook-name'}
                        >
                            {webhook.name || 'Webhook'}
                        </Typography>
                        {!webhook.is_enabled && <Chip label="Deactivated" />}
                    </div>
                    <div className={styles.webhookActions}>
                        <FormControlLabel
                            className={styles.activeSwitch}
                            control={
                                <Switch
                                    data-cypress={'toggle-webhook-switch'}
                                    data-testid={'toggle-webhook-switch'}
                                    disabled={isToggleSubmitting}
                                    onChange={() => onToggleWebhook(webhook)}
                                    checked={webhook.is_enabled}
                                    color="primary"
                                />
                            }
                            label={toggleLabel}
                            labelPlacement={'start'}
                        />
                        <Button
                            onClick={() => onClickEditWebhook(webhook)}
                            variant="outlined"
                            color="primary"
                            data-testid="edit-webhook-button"
                        >
                            {t('Edit webhook')}
                        </Button>
                    </div>
                </div>
                <Divider />
                <div className={styles.webhookInfoContainer}>
                    <div className={styles.textFieldContainer}>
                        <Typography
                            className={styles.textFieldTitle}
                            component="h1"
                            variant="h4"
                        >
                            {t('Webhook URL')}
                        </Typography>
                        <Typography
                            className={styles.textFieldValue}
                            variant="body2"
                            data-cypress={'events-webhook-url'}
                        >
                            {webhook.url}
                        </Typography>
                    </div>
                    <div className={styles.textFieldContainer}>
                        <Typography
                            className={styles.textFieldTitle}
                            component="h1"
                            variant="h4"
                        >
                            {t('Event types sent to URL')}
                        </Typography>
                        <Typography
                            className={styles.textFieldValue}
                            variant="body2"
                            data-cypress={'events-webhook-types'}
                        >
                            {eventTypesFieldValue}
                        </Typography>
                    </div>
                    <div className={styles.authFieldContainer}>
                        <Typography
                            className={styles.textFieldTitle}
                            component="h1"
                            variant="h4"
                            data-cypress={'events-webhook-method'}
                        >
                            {t('Authentication method: {{auth_type}}', {
                                auth_type: authText,
                            })}
                        </Typography>
                        <div
                            data-testid={'secret-key-input'}
                            className={styles.authField}
                        >
                            <TextField
                                className={styles.authFieldInput}
                                label={t('Secret key')}
                                id="secret"
                                type={showSecretKey ? 'text' : 'password'}
                                value={webhook.secret}
                                variant="outlined"
                                data-cypress={'events-webhook-secret'}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <ShowSecretButton
                                                showSecretKey={showSecretKey}
                                                toggleSecretKey={() => {
                                                    setShowSecretKey(!showSecretKey);
                                                }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                data-cypress="events-webhook-secret-copy"
                                data-testid="events-webhook-secret-copy"
                                onClick={copyToClipboard}
                                color="primary"
                            >
                                {t('COPY KEY')}
                            </Button>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default WebhookCard;
