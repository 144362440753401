import { makeStyles } from '@material-ui/core';

export const useFloorPlanWidgetContentStyles = makeStyles({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    progress: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
