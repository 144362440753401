import { destructurable } from '@infogrid/core-types';
import { SENSOR_EVENT_TYPES_V2 } from '@infogrid/sensors-constants';
import { useEventConfig } from '@infogrid/sensors-events';
import type { AirQualityMetric } from '@infogrid/solution-views-types';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import round from 'lodash/round';
import { useTranslation } from 'react-i18next';

const virusRiskDisplayFormat = registerTranslationKey('{{value: number}}/10', {
    ns: 'sensor-events',
});

const getDisplayFormat = (metric: AirQualityMetric, defaultFormat: string) => {
    switch (metric) {
        case SENSOR_EVENT_TYPES_V2.VIRUS_RISK:
            return virusRiskDisplayFormat;
        default:
            return defaultFormat;
    }
};

export const useMetricValue = (
    metric: AirQualityMetric,
    value: number | null,
): string => {
    const { t } = useTranslation('sensor-events');

    const { displayFormat: metricDisplayFormat, unit } = destructurable(
        useEventConfig(metric),
    );

    if (value === null) {
        return t('N/A');
    }

    const unitSymbol = unit?.symbol ? t(unit.symbol, { ns: 'sensor-events' }) : '';

    const displayFormat = getDisplayFormat(metric, metricDisplayFormat);

    return t(displayFormat, {
        value: round(value, 1),
        unit: unitSymbol,
    });
};
