import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    rulesRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: theme.spacing(1),
    },

    labelText: {
        margin: theme.spacing(1, 0),
        flex: 2.5,
        maxWidth: 190,
    },

    rulesSelect: {
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        flex: 1.25,
        maxWidth: 94,
    },

    ruleSymbol: {
        width: 10,
        height: 10,
        marginRight: theme.spacing(1),
        borderRadius: '50%',
        display: 'inline-block',
        alignSelf: 'center',
    },

    usesLabel: {
        margin: theme.spacing(1, 0),
        flex: 1,
    },

    autocomplete: {
        marginRight: theme.spacing(1),
    },

    errorMessage: {
        marginTop: theme.spacing(1),
        maxWidth: 350,
    },
}));
