import {
    SEEN_SENSORS_QUERY_KEY,
    FOLDER_SENSORS_LIST_QUERY_KEY,
    SOURCE_FOR_SENSORS_LIST_KEY,
    SENSORS_LIST_QUERY_KEY,
} from 'apiHooks/base/queryKeys';

import { getSensorKey } from '../getQueryKeys';

export const COUNT_OF_PAGES_TO_TRIGGER_LOOKUP = 5;

export const SENSORS_IN_THE_LIST_KEYS = [
    FOLDER_SENSORS_LIST_QUERY_KEY,
    SEEN_SENSORS_QUERY_KEY,
    SOURCE_FOR_SENSORS_LIST_KEY,
    SENSORS_LIST_QUERY_KEY,
];

export const SINGLE_SENSORS_KEYS_GETTERS = [getSensorKey];
