import { useDashboard, useUpdateWidget, useWidgetData } from '@infogrid/dashboards-hooks';
import type { SensorShape } from '@infogrid/sensors-constants';
import { useIsMobile, useIsOpenState } from '@infogrid/utils-hooks';
import { CircularProgress } from '@material-ui/core';
import { memo, useRef } from 'react';
import type { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router';

import {
    GenericActions,
    GenericDetails,
    GenericFooter,
    GenericTitle,
    Widget,
} from 'views/dashboards/components/Widget';
import { configurationDefaults } from 'views/dashboards/components/Widget/GenericWidget/constants';
import {
    useGenericWidgetContainerStyles,
    useGenericWidgetStyles,
} from 'views/dashboards/components/Widget/GenericWidget/styles';
import type { ConfigurationProps } from 'views/dashboards/components/Widget/GenericWidget/types';
import { DeleteWidgetModal } from 'views/dashboards/components/modals';
import ConfigureWidgetModal from 'views/dashboards/components/modals/ConfigureWidgetModal';
import { canChangeDashboard, hasUserPermissions } from 'views/dashboards/utils/helpers';
import { useConfigureWidgetModalOpenState } from 'views/dashboards/utils/hooks';

import { generateGenericFooterLabels } from '../utils';
import Content from './Content';
import { widgetConfigurationModalTabs } from './constants';
import type {
    TouchCountData,
    TouchCountValues,
    TouchCountWidget as TouchCountWidgetType,
} from './types';
import {
    prepareConfigurationPayload,
    prepareInitialValues,
    extractTotalTouchEventsCount,
} from './utils';

export interface TouchCountWidgetProps {
    canDragAndResize: boolean;
    configuration?: ConfigurationProps;
    layout: Layout;
    useIntersectionObserverLoader?: boolean;
    widget: TouchCountWidgetType;
}

const TouchCountWidget = ({
    canDragAndResize,
    configuration = configurationDefaults,
    layout,
    useIntersectionObserverLoader = true,
    widget,
}: TouchCountWidgetProps) => {
    const containerStyles = useGenericWidgetContainerStyles();
    const genericWidgetStyles = useGenericWidgetStyles();

    const { t, i18n } = useTranslation('dashboard');

    const { dashboardId } = useParams<{ dashboardId: string }>();

    const isMobile = useIsMobile();

    const { data: dashboard } = useDashboard(+dashboardId);

    const {
        handleConfigureWidgetModalClose,
        handleConfigureWidgetModalOpen,
        isConfigureWidgetModalOpen,
    } = useConfigureWidgetModalOpenState(widget.id, widget.type);

    const isHardRefreshData = useRef(false);

    const [
        deleteWidgetModalOpen,
        handleDeleteWidgetModalClose,
        handleDeleteWidgetModalOpen,
    ] = useIsOpenState();

    const { mutate: updateWidget, isLoading: isUpdatingWidget } = useUpdateWidget();

    /**
     * INFO: this check is enough for now, when user selects
     * sensor and clicks save - all the date range / display options
     * defaults are generated & saved as well
     */
    const isInitiallyConfigured = Boolean(widget.configuration.time_period_range);

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: '100px 0px',
        skip: !useIntersectionObserverLoader,
        initialInView: !useIntersectionObserverLoader,
        triggerOnce: true,
    });

    const { data, isError, isFetching, isLoading, isSuccess, refetch } =
        useWidgetData<TouchCountData>(+dashboardId, widget.id, {
            getQueryParams: () => ({
                refresh: isHardRefreshData.current || undefined,
            }),
            options: {
                enabled: inView && isInitiallyConfigured,
                onSettled: () => {
                    isHardRefreshData.current = false;
                },
            },
        });

    const onUpdateWidgetTitle = (title: string) => {
        updateWidget({
            dashboardId: +dashboardId,
            widgetId: widget.id,
            data: { name: title },
        });
    };

    const onUpdateConfiguration = (
        values: TouchCountValues,
        sensorsData: Record<string, SensorShape>,
    ) => {
        updateWidget({
            dashboardId: +dashboardId,
            widgetId: widget.id,
            data: {
                ...widget,
                configuration: prepareConfigurationPayload(values, sensorsData),
            },
        });
    };

    const widgetConfigurationInitialValues = prepareInitialValues(widget);

    const { periodLabel, refreshRateLabel, sensorsLabel } = generateGenericFooterLabels(
        widget,
        widgetConfigurationInitialValues,
        t,
        i18n,
    );

    const {
        actionsEnabled,
        isActionsEnabled,
        isEnabled: isConfigureWidgetModalEnabled,
    } = configuration;

    const widgetCardRef = useRef(null);

    const onRefresh = () => {
        isHardRefreshData.current = true;
        refetch();
    };

    const canUserEdit =
        !!dashboard && hasUserPermissions(dashboard) && canChangeDashboard(dashboard);

    return (
        <div ref={ref} className={containerStyles.container} data-cypress="widget">
            <Widget>
                <Widget.Bar canDragAndResize={canDragAndResize}>
                    <GenericTitle
                        onUpdateTitle={onUpdateWidgetTitle}
                        title={widget.name}
                        titleEditable={canUserEdit}
                        titleIcon={<i className="far fa-hand-point-up" />}
                    />

                    {((actionsEnabled && !isMobile) || isActionsEnabled) && (
                        <GenericActions
                            canUserEdit={canUserEdit}
                            configurableProps={{
                                isConfigureWidgetAvailable: true,
                                isDuplicateDisabled: !isInitiallyConfigured,
                                onConfigure: handleConfigureWidgetModalOpen,
                                onDelete: handleDeleteWidgetModalOpen,
                            }}
                            dashboardId={dashboardId}
                            refreshableProps={{
                                enabled: isInitiallyConfigured,
                                isLoading: isFetching && isSuccess,
                                onRefresh,
                            }}
                            widgetConfiguration={widget.configuration}
                            widgetName={widget.name}
                            widgetType={widget.type}
                        />
                    )}
                </Widget.Bar>

                {isLoading && (
                    <div className={genericWidgetStyles.progress}>
                        <CircularProgress
                            color="primary"
                            data-testid="widget-circular-loader"
                            size={40}
                        />
                    </div>
                )}

                {inView && !isLoading && (
                    <>
                        <GenericDetails
                            details={[
                                {
                                    label: t('Total touch event count:'),
                                    value: extractTotalTouchEventsCount({
                                        widgetData: data,
                                    }),
                                },
                            ]}
                        />

                        <Widget.Content ref={widgetCardRef} layout={layout}>
                            <Content
                                data={data}
                                isConfigured={isInitiallyConfigured}
                                isLoadingDataError={isError}
                                widget={widget}
                            />
                        </Widget.Content>
                    </>
                )}

                <GenericFooter
                    periodLabel={periodLabel}
                    refreshRateLabel={refreshRateLabel}
                    sensorsLabel={sensorsLabel}
                />

                {isConfigureWidgetModalEnabled && isConfigureWidgetModalOpen && (
                    <ConfigureWidgetModal
                        initialValues={widgetConfigurationInitialValues}
                        loading={isUpdatingWidget}
                        onClose={handleConfigureWidgetModalClose}
                        onUpdateConfiguration={onUpdateConfiguration}
                        open={isConfigureWidgetModalOpen}
                        tabs={widgetConfigurationModalTabs}
                    />
                )}

                <DeleteWidgetModal
                    dashboardId={dashboardId}
                    open={deleteWidgetModalOpen}
                    onClose={handleDeleteWidgetModalClose}
                    widgetId={widget.id}
                    widgetName={widget.name}
                    widgetType={widget.type}
                />
            </Widget>
        </div>
    );
};

export default memo(TouchCountWidget);
