import { makeStyles } from '@material-ui/core';

export const useStylesFloorMap = makeStyles(() => ({
    mapContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        '& .ol-control': {
            display: 'none !important',
        },
    },
    spinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));
