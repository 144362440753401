import { registerTranslationKey } from '@infogrid/utils-i18n';
import loadable from '@loadable/component';
import type { RouteConfig } from 'react-router-config';

import AlertConfigurationRedirect from 'components/AlertConfigurationRedirect';
import RenderChildren from 'components/RenderChildren';
import { userIsAuthenticated } from 'decorators/auth';
import { fetchFolderSensorsWatcher } from 'sagas/folders/fetchFolderSensors';
import { fetchFolderSubfoldersWatcher } from 'sagas/folders/fetchFolderSubFolders';

const AlertFeedView = loadable(
    () => import(/* webpackChunkName: 'views-feed-alert' */ 'views/alerts/AlertFeedView'),
);

const TaskFeedView = loadable(
    () => import(/* webpackChunkName: 'views-tasks-feed' */ 'views/tasks/TaskFeedView'),
);

const AlertConfigurationsListView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-feed-alert' */ 'views/alerts/AlertConfigurationsListView'
        ),
);

const AlertCreateView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-create-alert' */ 'views/alerts/AlertCreateView'
        ),
);

const AlertTemplatesView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-templates-alert' */ 'views/alerts/AlertTemplatesView'
        ),
);

const AlertTemplatesCreateView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-templates-alert' */ 'views/alerts/AlertTemplatesCreateView'
        ),
);

const AlertEditView = loadable(
    () => import(/* webpackChunkName: 'views-edit-alert' */ 'views/alerts/AlertEditView'),
);

const AlertUnauthorizedAcknowledgeView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-alert-unauth-acknowledge' */ 'views/alerts/AlertUnauthorizedAcknowledgeView'
        ),
);

export const folderDetailWatcherSagas = [
    fetchFolderSensorsWatcher,
    fetchFolderSubfoldersWatcher,
];

export const createAlertRoutes = (PageNotFoundRoute: RouteConfig): RouteConfig => ({
    path: '/alerts',
    name: 'alert',
    component: userIsAuthenticated(RenderChildren),
    routes: [
        {
            path: '/alerts',
            exact: true,
            name: 'feed',
            pageName: registerTranslationKey('Alert feed'),
            component: AlertFeedView,
        },
        {
            path: '/alerts/tasks',
            exact: true,
            name: 'task-feed',
            pageName: registerTranslationKey('Task feed'),
            component: TaskFeedView,
        },
        {
            path: '/alerts/overview',
            exact: true,
            name: 'configurations-list',
            pageName: registerTranslationKey('Alerts overview'),
            component: AlertConfigurationsListView,
        },
        {
            path: '/alerts/create',
            exact: true,
            name: 'create',
            pageName: registerTranslationKey('Create a new alert / state'),
            component: AlertCreateView,
            watcher: folderDetailWatcherSagas,
        },
        {
            path: '/alerts/templates',
            exact: true,
            name: 'templates',
            pageName: registerTranslationKey('Create new Alert'),
            component: AlertTemplatesView,
        },
        {
            path: '/alerts/templates/:templateId',
            exact: true,
            name: 'create-template',
            pageName: registerTranslationKey('Create new alert from template'),
            component: AlertTemplatesCreateView,
        },
        {
            path: '/alerts/:alertConfigId',
            exact: true,
            name: 'edit',
            pageName: registerTranslationKey('Edit an alert / state'),
            component: AlertEditView,
            watcher: folderDetailWatcherSagas,
        },
        PageNotFoundRoute,
    ],
});

export const createAcknowledgeRoutes = (PageNotFoundRoute: RouteConfig): RouteConfig => ({
    path: '/acknowledge',
    name: 'acknowledge',
    component: RenderChildren,
    routes: [
        {
            path: '/acknowledge/:alertActionType/:passkey',
            exact: true,
            name: 'unauthorized-acknowledge',
            pageName: 'Acknowledge alert',
            component: AlertUnauthorizedAcknowledgeView,
        },
        PageNotFoundRoute,
    ],
});

export const createAlertConfigurationRoutes = (): RouteConfig => ({
    path: '/alert-configurations',
    name: 'alertConfiguration',
    component: AlertConfigurationRedirect,
});
