import { wrapResponsePromise } from '@infogrid/core-api';
import { useAppDispatch, toastError } from '@infogrid/core-ducks';
import type { IPagination, AxiosParsedError } from '@infogrid/core-types';
import { spaceControllers, getSpaceSensorsKey } from '@infogrid/locations-api';
import type { SensorShape, SensorType } from '@infogrid/sensors-constants';
import { useTranslation } from 'react-i18next';
import type { UseInfiniteQueryOptions, UseInfiniteQueryResult } from 'react-query';
import { useInfiniteQuery } from 'react-query';

interface Params {
    page_size: number;
    sensor_type?: SensorType[];
}

export const useSpaceSensors = (
    spaceId?: number,
    params?: Params,
    options?: UseInfiniteQueryOptions<
        IPagination<SensorShape>,
        AxiosParsedError,
        IPagination<SensorShape>
    >,
): UseInfiniteQueryResult<IPagination<SensorShape>, AxiosParsedError> => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('estate');

    return useInfiniteQuery(
        getSpaceSensorsKey(spaceId),
        ({ signal, pageParam = {} }) =>
            wrapResponsePromise(
                spaceControllers.getSpaceSensors(spaceId as number, signal, {
                    ...pageParam,
                    ...params,
                }),
            ),
        {
            enabled: !!spaceId,
            onError() {
                dispatch(toastError({ message: t('Failed to load space sensors') }));
            },
            staleTime: 1000 * 60 * 5, // 5 mins
            getNextPageParam: (lastPage) => lastPage?.next,
            ...options,
        },
    );
};
