import { Auth0Provider } from '@auth0/auth0-react';
import { FocusStyleManager } from '@blueprintjs/core';
import { theme, ErrorBoundary } from '@infogrid/components-material-ui';
import { queryClient } from '@infogrid/core-api';
import { useAppDispatch } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { useNetwork } from '@infogrid/utils-hooks';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import type { RouteConfigComponentProps } from 'react-router-config';

import DefaultHeader from 'components/DefaultHeader';
import ErrorComponent from 'components/ErrorComponent';
import LazyLoadErrorBoundary from 'components/LazyLoadErrorBoundary';
import { updateNetworkConnection } from 'sagas/network/updateConnection';
import { onComponentError } from 'services/sentry';
import SETTINGS from 'settings';
import getWebclientURI from 'utils/getWebclientURI';
import { useAnalytics } from 'utils/hooks/analytics';
import { useHasAcceptedTerms } from 'utils/hooks/terms';

import AppContainer from './AppContainer';
import CubeProvider from './CubeProvider';

// Load main styles
import 'styles/main.scss';

// This is required to avoid CSS outlines on various BlueprintJS elements when clicking on them.
FocusStyleManager.onlyShowFocusOnTabs();

const App = ({ route, match }: RouteConfigComponentProps) => {
    const dispatch = useAppDispatch();

    const updateNetworkConnectionStatus = useCallback(() => {
        dispatch(updateNetworkConnection());
    }, [dispatch]);

    useHasAcceptedTerms();

    useNetwork(updateNetworkConnectionStatus);

    useAnalytics();

    return (
        <CubeProvider>
            <QueryClientProvider client={queryClient}>
                <ErrorBoundary
                    onComponentError={onComponentError}
                    ErrorHandler={ErrorComponent}
                >
                    <MuiThemeProvider theme={theme}>
                        <LazyLoadErrorBoundary>
                            <Auth0Provider
                                redirectUri={getWebclientURI(
                                    routesManager.resolvePath('auth:login-sso').pathname,
                                )}
                                domain={SETTINGS.AUTH0_DOMAIN}
                                clientId={SETTINGS.AUTH0_CLIENT_ID}
                                audience={SETTINGS.BACKEND_SITE_URL}
                                useRefreshTokens
                                useCookiesForTransactions
                            >
                                <DefaultHeader canonical={match.url} />
                                <AppContainer route={route} />
                            </Auth0Provider>
                        </LazyLoadErrorBoundary>
                    </MuiThemeProvider>
                </ErrorBoundary>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </CubeProvider>
    );
};

export default Sentry.withProfiler(App);
