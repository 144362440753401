import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo } from 'react';

import { useWidgetDetailsStyles } from './styles';

interface WidgetDetailsProps {
    children: ReactNode;
    className?: string;
}

const WidgetDetails = ({ children, className }: WidgetDetailsProps) => {
    const styles = useWidgetDetailsStyles();

    return <div className={classNames(styles.container, className)}>{children}</div>;
};

export default memo(WidgetDetails);
