import { Icon, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { useNavbarLinkStyles } from './styles';

const textTransitionResolver = (transitionValue) => `opacity ${transitionValue}ms`;

const NavbarLink = (props) => {
    const {
        icon,
        IconComponent,
        className,
        navbarOpened,
        onClick,
        text,
        to,
        transitionDuration,
    } = props;

    const textTransition = textTransitionResolver(transitionDuration);

    const navbarLinkClasses = useNavbarLinkStyles({ textTransition });
    const rootClasses = classNames(
        navbarLinkClasses.root,
        navbarOpened && navbarLinkClasses.textVisible,
    );
    const iconClasses = classNames(icon, navbarLinkClasses.icon, className);

    const TooltipTitle = useMemo(
        () =>
            navbarOpened ? (
                ''
            ) : (
                <Typography className={navbarLinkClasses.tooltip} variant="subtitle1">
                    {text}
                </Typography>
            ),
        [text, navbarOpened, navbarLinkClasses.tooltip],
    );

    return (
        <Tooltip title={TooltipTitle} placement="right">
            <NavLink
                to={to}
                className={rootClasses}
                activeClassName={navbarLinkClasses.linkActive}
                onClick={onClick}
            >
                {IconComponent || (icon && <Icon className={iconClasses} />)}
                <Typography className={navbarLinkClasses.text} variant="subtitle1">
                    {text}
                </Typography>
            </NavLink>
        </Tooltip>
    );
};

NavbarLink.propTypes = {
    icon: PropTypes.string,
    IconComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    className: PropTypes.string,
    navbarOpened: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]).isRequired,
    transitionDuration: PropTypes.number.isRequired,
};

NavbarLink.defaultProps = {
    className: '',
    onClick: null,
    icon: '',
    IconComponent: null,
};

export default NavbarLink;
