import { makeStyles } from '@material-ui/core';

export const useDashboardRenameModalStyles = makeStyles((theme) => ({
    spinner: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(1.25),
    },
    renameModalTitle: {
        marginBottom: theme.spacing(0.75),
    },
    content: {
        padding: theme.spacing(3),
    },
}));
