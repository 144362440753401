import { applicationSelectors, Constance } from '@infogrid/core-ducks';
import { useWidgetData } from '@infogrid/dashboards-hooks';
import { useSelectorWithArgs } from '@infogrid/utils-hooks';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const LastUpdatedAt = ({ widgetId }) => {
    const { t } = useTranslation();

    const livePollingDelay = useSelectorWithArgs(
        applicationSelectors.getConstanceValue,
        Constance.POLL_EVENTS_IN_FRONTEND,
    );
    const { dashboardId } = useParams();

    const { dataUpdatedAt } = useWidgetData(+dashboardId, widgetId, {
        options: {
            refetchInterval: livePollingDelay * 1000 || false, // 10 seconds * 1000 = 10000 milliseconds
        },
    });

    const lastUpdatedAtString = useMemo(() => new Date(dataUpdatedAt), [dataUpdatedAt]);

    return (
        <>{`${t('Updated')}: ${format(lastUpdatedAtString, 'HH:mm:ss - D MMMM YYYY')}`}</>
    );
};

LastUpdatedAt.propTypes = {
    widgetId: PropTypes.number.isRequired,
};

export default memo(LastUpdatedAt);
