import type { Timezone } from '@infogrid/core-types';
import { TextField } from '@material-ui/core';
import type { TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import type { AutocompleteProps } from '@material-ui/lab';
import { memo, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getTimezoneByValue } from 'utils/getTimezoneByValue';

const getTimezoneOptionLabel = (option: Timezone) => option.label;

export interface Props<
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
> extends Omit<
        AutocompleteProps<Timezone, Multiple, DisableClearable, FreeSolo>,
        'options' | 'renderInput'
    > {
    timezone: string | null;
    timezones: Timezone[];
    inputError?: boolean;
    inputHelperText?: React.ReactNode;
    inputName?: TextFieldProps['name'];
    inputId?: TextFieldProps['id'];
    renderInput?: AutocompleteProps<
        Timezone,
        Multiple,
        DisableClearable,
        FreeSolo
    >['renderInput'];
    ['data-cypress']?: string;
    inputDataCypress?: string;
}

const TimezoneAutocomplete = ({
    onChange,
    renderInput,
    timezone,
    timezones,
    inputError,
    inputHelperText,
    inputName,
    inputId,
    classes,
    onBlur,
    'data-cypress': dataCypress,
    inputDataCypress,
    ...props
}: Props) => {
    const { t } = useTranslation();

    const autocompleteValue = useMemo(() => {
        const label = getTimezoneByValue(timezones, timezone)?.label;

        if (timezone && label) {
            return {
                value: timezone,
                label,
            };
        }

        return null;
    }, [timezone, timezones]);

    const renderTimezonesInput = (params: TextFieldProps): JSX.Element => (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            id={inputId ?? 'timezone'}
            name={inputName ?? 'timezone'}
            placeholder={t('Select timezone')}
            value={timezone}
            variant="outlined"
            error={inputError}
            InputLabelProps={{ shrink: true }}
            inputProps={{
                'data-cypress': inputDataCypress,

                ...params.inputProps,
            }}
            label={t('Time zone')}
            helperText={inputHelperText}
        />
    );

    const getTimeZoneOptionSelected = useCallback(
        (option: Timezone) => {
            return option.value === autocompleteValue?.value;
        },
        [autocompleteValue],
    );

    return (
        <Autocomplete<Timezone>
            data-cypress={dataCypress}
            options={timezones}
            onChange={onChange}
            renderInput={renderInput ?? renderTimezonesInput}
            value={autocompleteValue}
            onBlur={onBlur}
            getOptionLabel={getTimezoneOptionLabel}
            getOptionSelected={getTimeZoneOptionSelected}
            classes={classes}
            id={inputId ?? 'timezone'}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
};

export default memo(TimezoneAutocomplete);
