import { ViewWrapper } from '@infogrid/components-material-ui';
import type { Floor } from '@infogrid/locations-types';
import { Icon, Button, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import FloorsList from './FloorsList';
import { useCreateFloorButtonStyles, useFloorNavigationStyles } from './styles';

interface Props {
    activeFloorId: number | null;
    isBuildingEditable?: boolean;
    isFullView?: boolean;
    onCreateFloor: () => void;
    onEditFloor: (floor: Floor) => void;
    onFloorClick: (floor: Floor) => void;
}

const FloorNavigation = ({
    activeFloorId = null,
    isBuildingEditable = true,
    isFullView = false,
    onCreateFloor,
    onEditFloor,
    onFloorClick,
}: Props) => {
    const createFloorButtonStyles = useCreateFloorButtonStyles();
    const floorNavigationStyles = useFloorNavigationStyles({
        isCreateFloorButtonAvailable: isBuildingEditable,
    });

    const { t } = useTranslation('floorplan');

    return (
        <div className={floorNavigationStyles.container}>
            <ViewWrapper
                isVisible={isBuildingEditable}
                className={floorNavigationStyles.createFloorContainer}
            >
                <Button
                    variant="contained"
                    classes={createFloorButtonStyles}
                    data-cypress="add-floor"
                    onClick={onCreateFloor}
                >
                    <Icon
                        className={classNames(
                            'fal fa-plus',
                            floorNavigationStyles.addFloorIcon,
                        )}
                    />

                    {isFullView && (
                        <Typography
                            variant="body2"
                            component="span"
                            className={floorNavigationStyles.createFloorButtonLabel}
                        >
                            {t('Add New Floor')}
                        </Typography>
                    )}
                </Button>
            </ViewWrapper>

            <div className={floorNavigationStyles.floorsListContainer}>
                <FloorsList
                    onFloorClick={onFloorClick}
                    onEditFloorClick={onEditFloor}
                    activeFloorId={activeFloorId}
                    isFullView={isFullView}
                    isBuildingEditable={isBuildingEditable}
                />
            </div>
        </div>
    );
};

export default memo(FloorNavigation);
