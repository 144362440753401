import { LanguageDropdown, Modal } from '@infogrid/components-material-ui';
import { useAppSelector } from '@infogrid/core-ducks';
import type { Building, FloorSensorLocation } from '@infogrid/locations-types';
import type { SensorType } from '@infogrid/sensors-constants';
import { selectActiveTimeZone } from '@infogrid/user-ducks';
import { formatDayOfMonth } from '@infogrid/utils-dates';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import truncate from 'lodash/truncate';
import ChipInput from 'material-ui-chip-input';
import moment from 'moment';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useFloor, useFloors } from 'apiHooks/floorPlan/floors/hooks';
import ButtonSpinner from 'components/ButtonSpinner';
import type { MappedSensor } from 'components/floorPlans/types';
import getAppURI from 'utils/getAppURI';

import type {
    ReportConfigurationFormSection,
    ScheduleReportModalProps,
} from '../../types';
import FloorPlanWrapper from '../FloorPlan/FloorPlanWrapper';
import {
    useBuildReportModalEmailInputClasses,
    useInputStyles,
    useFrequencyInputStyles,
    useTimeInputStyles,
    useReportConfigurationStyles,
} from './styles';

type Props = Omit<ScheduleReportModalProps, 'handleDelete'> & {
    handleClose: () => void;
    buildings?: Building[];
    showDeleteButton: boolean;
    handleDelete?: () => void;
    submitButtonText?: string;
};

type FloorPlanSensor = FloorSensorLocation & { type: SensorType };

const ReportConfigurationForm = ({
    handleSubmit,
    handleChange,
    handleDelete,
    isSubmitting,
    setFieldValue,
    handleBlur,
    handleClose,
    setFieldTouched,
    enabledSections,
    errors,
    touched,
    showDeleteButton,
    name,
    language,
    emailRecipients,
    emailReportAt,
    showSensorList,
    pageSize,
    floorplanPageConfiguration,
    disabled,
    buildingId,
    buildings,
    floorIds,
    zoom,
    sensorIconScale,
    submitButtonText = 'Save',
    hideOptionalSectionDescriptions = false,
}: Props) => {
    const { t, i18n } = useTranslation('solutions');
    const styles = useReportConfigurationStyles();

    const emailInputStyles = useBuildReportModalEmailInputClasses();
    const inputClasses = useInputStyles();
    const frequencyInputClasses = useFrequencyInputStyles();
    const timeInputClasses = useTimeInputStyles();

    const userTimeZone = useAppSelector(selectActiveTimeZone);

    const INPUT_REQUIRED_LABEL = t('<input required>');

    const formattedOverviewDate = formatDayOfMonth(
        moment().add(1, 'd'),
        userTimeZone,
        i18n.language,
    );

    const handleEmailAdd = useCallback(
        (email) => {
            setFieldTouched('emailRecipients', true, false);
            setFieldValue('emailRecipients', [...emailRecipients, email]);
        },
        [emailRecipients, setFieldValue, setFieldTouched],
    );

    const handleEmailDelete = useCallback(
        (_, index) => {
            setFieldTouched('emailRecipients', true, false);

            setFieldValue(
                'emailRecipients',
                emailRecipients.filter((_1, i) => i !== index),
            );
        },
        [emailRecipients, setFieldValue, setFieldTouched],
    );

    const { floors, isLoading: isFloorsLoading } = useFloors(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        buildingId!,
        {},
        {
            refetchOnMount: true,
            enabled:
                !!buildingId &&
                !!buildings &&
                !!buildings.find((x) => x.id === buildingId),
        },
    );

    const availableFloors = floors.filter(
        (f) => f.sensors_count > 0 && f.spaces_count > 0,
    );

    // A single floor is used to display the floorplan for icon sizing. Display basement over first floor etc.
    const orderedFloorIds = sortBy(
        floorIds,
        (floorId_) =>
            availableFloors.find((floor_) => floor_.id === floorId_)?.order_index,
    );
    const floorIdForIconScaling = orderedFloorIds[0];

    const { data: floor, isLoading: isFloorLoading } = useFloor(floorIdForIconScaling, {
        refetchOnMount: true,
        enabled:
            !!floorIdForIconScaling &&
            !!floors &&
            !!floors.find((x) => x.id === floorIdForIconScaling),
    });

    const isLoading = isFloorsLoading || isFloorLoading;

    const receivingOverviewEmailReportAt = emailReportAt || INPUT_REQUIRED_LABEL;
    const receivingOverview = t(
        'Your next daily report will be sent at {{emailReportAt}} on {{date}}.',
        {
            emailReportAt: receivingOverviewEmailReportAt,
            date: formattedOverviewDate,
            defaultValue: `Your next daily report will be sent at
                ${receivingOverviewEmailReportAt} on ${formattedOverviewDate}.`,
        },
    );

    const mapSensors: MappedSensor[] = useMemo(
        () =>
            floor?.sensors
                ? (floor.sensors as FloorPlanSensor[]).map((sensor) => ({
                      id: sensor.sensor_id,
                      uuid: uuid(),
                      coordinates: { x: sensor.x, y: sensor.y },
                      type_code: sensor.type,
                      is_planned: false,
                      is_saved: true,
                  }))
                : [],
        [floor],
    );

    const noFloorsAvailableForBuilding =
        !isFloorsLoading && availableFloors.length === 0 && !!buildingId;

    const isEnabledSection = (section: ReportConfigurationFormSection) => {
        // if we don't have a list of enabled fields, don't filter out fields
        if (!enabledSections) {
            return true;
        }

        return enabledSections.includes(section);
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <Modal.Content className={classNames(styles.content)}>
                {isEnabledSection('title') && (
                    <section>
                        <Typography className={styles.title} variant="body1">
                            {t('Report title')}
                        </Typography>
                        {!hideOptionalSectionDescriptions && (
                            <Typography className={styles.description} variant="body2">
                                {t(
                                    'Give the report a title; this will be visible to the recipient.',
                                )}
                            </Typography>
                        )}
                        <TextField
                            classes={inputClasses}
                            fullWidth
                            id="report-name"
                            data-testid="report-name"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={name}
                            variant="outlined"
                            error={!!(errors.name && touched.name)}
                            helperText={
                                errors.name &&
                                touched.name &&
                                errors.name &&
                                t(errors.name)
                            }
                        />
                    </section>
                )}

                {isEnabledSection('locations') && (
                    <section className={styles.formSection}>
                        <Typography className={styles.title} variant="body1">
                            {t('Locations to report on')}
                        </Typography>
                        <Typography className={styles.description} variant="body2">
                            {t(
                                'Select the building, then floor or space type(s) within it.',
                            )}
                        </Typography>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="report-building-label">
                                {t('Building')}
                            </InputLabel>
                            <Select
                                labelId="report-building-label"
                                id="report-building-select"
                                name="buildingId"
                                value={buildingId ?? ''}
                                label={t('Building')}
                                onChange={(e) => {
                                    setFieldValue('floorIds', []);
                                    handleChange(e);
                                }}
                                error={
                                    !!(errors.buildingId && touched.buildingId) ||
                                    noFloorsAvailableForBuilding
                                }
                            >
                                {buildings &&
                                    buildings.map((building) => (
                                        <MenuItem key={building.id} value={building.id}>
                                            {truncate(building.name, { length: 90 })}
                                        </MenuItem>
                                    ))}
                            </Select>
                            {errors.buildingId && touched.buildingId && (
                                <Typography
                                    className={styles.errorDescription}
                                    variant="caption"
                                >
                                    {errors.buildingId}
                                </Typography>
                            )}
                            {noFloorsAvailableForBuilding && (
                                <Typography
                                    className={styles.errorDescription}
                                    variant="caption"
                                >
                                    {t(
                                        'The building must have at least one floor with at least one sensor installed.',
                                    )}
                                </Typography>
                            )}
                        </FormControl>

                        {!isFloorsLoading && availableFloors.length > 0 && (
                            <FormControl
                                variant="outlined"
                                fullWidth
                                className={styles.selectInput}
                            >
                                <InputLabel id="report-floor-label">
                                    {t('Floors')}
                                </InputLabel>
                                <Select
                                    labelId="report-floor-label"
                                    id="report-floor-checkbox"
                                    name="floorIds"
                                    multiple
                                    value={floorIds}
                                    label={t('Floors')}
                                    onChange={handleChange}
                                    renderValue={(selectedIds) => {
                                        const count = (selectedIds as number[]).length;

                                        return t('{{count: number}} {{floor}}', {
                                            count,
                                            floor: count === 1 ? 'floor' : 'floors',
                                            defaultValue__one: `${count} floor`,
                                            defaultValue__other: `${count} floors`,
                                        }).toString();
                                    }}
                                    error={!!(errors.floorIds && touched.floorIds)}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    {availableFloors.map((f) => (
                                        <MenuItem key={f.id} value={f.id}>
                                            <Checkbox
                                                checked={floorIds.indexOf(f.id) > -1}
                                                color="primary"
                                            />
                                            <ListItemText
                                                primary={truncate(f.name, { length: 90 })}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.floorIds && touched.floorIds && (
                                    <Typography
                                        className={styles.errorDescription}
                                        variant="caption"
                                    >
                                        {errors.floorIds}
                                    </Typography>
                                )}
                            </FormControl>
                        )}
                    </section>
                )}

                {isEnabledSection('schedule') && (
                    <section className={styles.formSection}>
                        <Typography className={styles.title} variant="body1">
                            {t('Report scheduling')}
                        </Typography>
                        <Typography className={styles.description} variant="body2">
                            {t(
                                'What time should the report be sent every day? (Note that this will be the building’s local time.)',
                            )}
                        </Typography>
                        <div className={styles.schedulingContainer}>
                            <div className={styles.schedulingContainerRow}>
                                <TextField
                                    classes={timeInputClasses}
                                    label={t('Send report at')}
                                    id="email-report-at"
                                    name="emailReportAt"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="time"
                                    value={emailReportAt}
                                    variant="outlined"
                                    error={
                                        !!(errors.emailReportAt && touched.emailReportAt)
                                    }
                                    disabled={disabled}
                                    helperText={
                                        touched.emailReportAt &&
                                        errors.emailReportAt &&
                                        t(errors.emailReportAt)
                                    }
                                />
                                <FormControlLabel
                                    className={styles.doNotSendReportSwitch}
                                    control={
                                        <Switch
                                            id="disabled"
                                            checked={disabled}
                                            onChange={handleChange}
                                            color="primary"
                                        />
                                    }
                                    label="Do not send report"
                                />
                            </div>
                        </div>
                        <Typography
                            data-testid="secondary-description"
                            className={styles.scheduleDescription}
                            variant="body2"
                        >
                            {disabled
                                ? t(
                                      'Your report is temporarily disabled and won’t be sent out.',
                                  )
                                : receivingOverview}
                        </Typography>
                    </section>
                )}

                {isEnabledSection('language') && (
                    <section>
                        <Typography className={styles.title} variant="body1">
                            {t('Report language')}
                        </Typography>
                        {!hideOptionalSectionDescriptions && (
                            <Typography className={styles.description} variant="body2">
                                {t('Which language should the email and attachment use?')}
                            </Typography>
                        )}
                        <div className={styles.schedulingContainer}>
                            <div className={styles.schedulingContainerRow}>
                                <LanguageDropdown
                                    variant="outlined"
                                    classes={frequencyInputClasses}
                                    labelId="language-label"
                                    inputId="language"
                                    name="language"
                                    onChange={handleChange}
                                    value={language}
                                />
                            </div>
                        </div>
                    </section>
                )}

                {isEnabledSection('recipients') && (
                    <section className={styles.formSection}>
                        <Typography className={styles.title} variant="body1">
                            {t('Recipients')}
                        </Typography>
                        <Typography className={styles.description} variant="body2">
                            {t('Add the email addresses of recipients for this report')}
                        </Typography>
                        <ChipInput
                            blurBehavior="add"
                            fullWidth
                            fullWidthInput
                            id="email-recipients"
                            data-cypress="email-recipients"
                            onAdd={handleEmailAdd}
                            onDelete={handleEmailDelete}
                            value={emailRecipients}
                            variant="outlined"
                            classes={emailInputStyles}
                            error={!!errors.emailRecipients && !!touched.emailRecipients}
                            helperText={
                                !!touched.emailRecipients &&
                                !!errors.emailRecipients &&
                                t(
                                    Array.isArray(errors.emailRecipients)
                                        ? (errors.emailRecipients.find(
                                              (x) => !!x,
                                          ) as string)
                                        : errors.emailRecipients,
                                )
                            }
                        />
                    </section>
                )}

                {isEnabledSection('format') && (
                    <section className={styles.formSection}>
                        <Typography className={styles.title} variant="body1">
                            {t('Report format')}
                        </Typography>
                        <Typography
                            className={styles.reportParagraph}
                            color="textSecondary"
                            variant="body2"
                        >
                            {t('Select the paper size of the PDF ')}
                        </Typography>

                        <FormControl component="fieldset">
                            <RadioGroup
                                className={styles.paperSizeRadio}
                                row
                                aria-label="page size"
                                name="pageSize"
                                value={pageSize}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    className={styles.letterPageSize}
                                    value="us_letter"
                                    control={<Radio color="primary" />}
                                    label={
                                        <Typography color="textSecondary" variant="body2">
                                            {t('US Letter (8.5” x 11”)')}
                                        </Typography>
                                    }
                                />

                                <FormControlLabel
                                    value="a4"
                                    control={<Radio color="primary" />}
                                    label={
                                        <Typography color="textSecondary" variant="body2">
                                            {t('A4 (210mm x 297mm)')}
                                        </Typography>
                                    }
                                />
                            </RadioGroup>
                        </FormControl>

                        <Typography
                            className={styles.reportParagraph}
                            color="textSecondary"
                            variant="body2"
                        >
                            {t(
                                'Would you like a list of sensor names (related to spaces and desks to be cleaned) to be included in the report?',
                            )}
                        </Typography>
                        <FormControlLabel
                            className={styles.reportCheckbox}
                            control={
                                <Checkbox
                                    checked={showSensorList}
                                    onChange={handleChange}
                                    name="showSensorList"
                                    color="primary"
                                />
                            }
                            label={
                                <Typography color="textSecondary" variant="body2">
                                    {t('Include list of sensors in the report')}
                                </Typography>
                            }
                        />

                        <Typography
                            className={styles.reportParagraph}
                            color="textSecondary"
                            variant="body2"
                        >
                            {t(
                                'Do you want the floorplan image to be scaled to fit on one page in the report? This is usually the best option, unless your building is much longer than it is wide.',
                            )}
                        </Typography>
                        <FormControl component="fieldset">
                            <RadioGroup
                                className={styles.paperSizeRadio}
                                row
                                aria-label="force single page floorplan"
                                name="floorplanPageConfiguration"
                                value={floorplanPageConfiguration}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    className={styles.floorplanPageConfiguration}
                                    value="one_page"
                                    control={<Radio color="primary" />}
                                    label={
                                        <Typography color="textSecondary" variant="body2">
                                            {t('Fit on one page')}
                                        </Typography>
                                    }
                                />

                                <FormControlLabel
                                    value="multiple_pages"
                                    control={<Radio color="primary" />}
                                    label={
                                        <Typography color="textSecondary" variant="body2">
                                            {t('Tile across multiple pages')}
                                        </Typography>
                                    }
                                />
                            </RadioGroup>
                        </FormControl>
                    </section>
                )}

                {!isLoading && floor && floor.image && isEnabledSection('floor') && (
                    <section>
                        <Typography className={styles.title} variant="body1">
                            {t('Set the size of the icons')}
                        </Typography>
                        <Typography className={styles.description} variant="body2">
                            {t(
                                'Use the controls to make sure the icons on the floorplan will be easy to see in the report (one floor shown as an example).',
                            )}
                        </Typography>
                        <div className={styles.mapWrapper}>
                            <FloorPlanWrapper
                                floor={floor}
                                sensors={mapSensors}
                                imageUrl={getAppURI(floor.image.image_file)}
                                imageWidth={floor.image.width}
                                imageHeight={floor.image.height}
                                zoom={zoom}
                                sensorIconScale={sensorIconScale}
                                saveConfig={({
                                    zoom: zoom_,
                                    scale,
                                }: {
                                    zoom: number;
                                    scale: number;
                                }) => {
                                    setFieldValue('sensorIconScale', scale);
                                    setFieldValue('zoom', zoom_);
                                }}
                            />
                        </div>
                    </section>
                )}
            </Modal.Content>

            <Modal.Actions justify="space-between">
                {showDeleteButton ? (
                    <Button
                        onClick={handleDelete}
                        color="secondary"
                        data-cypress="delete-report"
                    >
                        {t('Delete')}
                    </Button>
                ) : (
                    <div></div>
                )}
                <div className={styles.actionButtonContainer}>
                    <div className={styles.actionButtonContainerRow}>
                        <Button onClick={handleClose} color="primary">
                            {t('Cancel')}
                        </Button>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting}
                            data-cypress="submit-report"
                        >
                            {isSubmitting ? <ButtonSpinner /> : t(submitButtonText)}
                        </Button>
                    </div>
                </div>
            </Modal.Actions>
        </form>
    );
};

export default memo(ReportConfigurationForm);
