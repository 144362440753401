import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import HealthyBuildingScorePreview from 'styles/images/widgets/healthy-building-score-preview.jpg';

import HealthyBuildingScoreWidget from './HealthyBuildingScoreWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('Healthy Building Score', { ns: 'dashboard' }),
    type: WIDGET_TYPE.HEALTHY_BUILDING_SCORE,
    featureFlags: [],
    Component: HealthyBuildingScoreWidget,
    image: HealthyBuildingScorePreview,
    order: 10,
    gridConfig: {
        ...DEFAULT_GRID_ITEM_CONFIG,
        maxH: 1,
        maxW: 1,
        isResizable: false,
    },
};

export default HealthyBuildingScoreWidget;
