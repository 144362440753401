import { formatDate } from '@infogrid/utils-dates';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useCallback, memo } from 'react';

import { useUpdateDateInterval } from './useUpdateDateInterval';

const TimeSpentTooltip = ({ date, children, timeZone, autoUpdate, ...tooltipProps }) => {
    const { currentDate, formattedDiffDate } = useUpdateDateInterval({
        initialDate: date,
        intervalTime: 5000,
        isRunning: autoUpdate,
    });

    const formattedDate = formatDate(date, timeZone);

    const childrenFunction = useCallback(() => {
        if (children && typeof children === 'function') {
            return children({ currentDate, formattedDate, formattedDiffDate });
        }

        if (children && typeof children !== 'function') {
            return children;
        }

        return formattedDiffDate;
    }, [children, formattedDiffDate, formattedDate, currentDate]);

    return (
        <Tooltip
            title={formattedDate}
            placement="top"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...tooltipProps}
        >
            <div>{childrenFunction()}</div>
        </Tooltip>
    );
};

TimeSpentTooltip.propTypes = {
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    timeZone: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    autoUpdate: PropTypes.bool,
};

TimeSpentTooltip.defaultProps = {
    date: new Date(),
    children: null,
    timeZone: null,
    autoUpdate: true,
};

export default memo(TimeSpentTooltip);
