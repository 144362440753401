import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useTableStyles = makeStyles((theme) => ({
    displayName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    headerCell: {
        paddingTop: 0,
    },
    table: {
        tableLayout: 'fixed',
    },
    totalRow: {
        fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
    },
}));
