import { calendarPeriods, dataFrequencies } from '@infogrid/dashboards-constants';
import type { DataFrequency } from '@infogrid/dashboards-constants';
import { yesterday, days7ago } from '@infogrid/utils-dates';
import type { Moment } from 'moment';
import type { TFunction } from 'react-i18next';

import {
    periodDurationInDays,
    prepareBaseConfigurationPayload,
    prepareBaseInitialValues,
} from 'views/dashboards/widgets/utils';

import type {
    DeskOccupancyData,
    DeskOccupancyInitialValues,
    DeskOccupancyValues,
    DeskOccupancyWidget,
    DeskOccupancyWidgetConfiguration,
} from './types';

const defaultValues = {
    dateRange: {
        period: calendarPeriods.week,
        startDate: days7ago,
        endDate: yesterday,
        workHoursFrom: '09:00',
        workHoursTo: '17:00',
    },
};

export const prepareInitialValues = (
    widget: DeskOccupancyWidget,
): DeskOccupancyInitialValues => {
    const { data_frequency: dataFrequency = dataFrequencies.daily.value } =
        widget?.configuration;

    const {
        folders = [],
        sensors = [],
        show_desks_occupied: showCountOfDesksOccupied,
    } = widget?.configuration?.calculators?.desk_occupancy_percentage || {};

    return {
        ...prepareBaseInitialValues(widget, defaultValues),
        folders,
        sensors,
        displayOptions: {
            dataFrequency,
            showCountOfDesksOccupied,
        },
    };
};

export const prepareConfigurationPayload = (
    values: DeskOccupancyValues,
): DeskOccupancyWidgetConfiguration => {
    const payload = prepareBaseConfigurationPayload(values);

    const { dataFrequency } = values?.displayOptions;

    return {
        ...payload,
        data_frequency: dataFrequency,
        calculators: {
            desk_occupancy_percentage: {
                folders: values?.folders,
                sensors: values?.sensors,
                show_desks_occupied: values?.displayOptions?.showCountOfDesksOccupied,
            },
        },
    };
};

export const extractAverageOccupancy = (
    {
        widget,
        widgetData,
    }: { widget: DeskOccupancyWidget; widgetData?: DeskOccupancyData },
    t: TFunction,
): string => {
    const averageOccupancy = widgetData?.average_occupancy?.toFixed(2) || 0;

    const showDesksOccupied =
        widget?.configuration?.calculators?.desk_occupancy_percentage
            ?.show_desks_occupied;

    if (!showDesksOccupied) {
        return `${averageOccupancy}%`;
    }

    const desksOccupied = widgetData?.desks_occupied || 0;

    const desksOccupiedLabel = t('desk', {
        count: desksOccupied,
        defaultValue___one: 'desk',
        defaultValue___other: 'desks',
        ns: 'dashboard',
    });

    return `${averageOccupancy || 0}% / ${desksOccupied} ${desksOccupiedLabel}`;
};

export const calculateDisabledFrequencies = ({
    startDate,
    endDate,
}: {
    startDate: Moment;
    endDate: Moment;
}): DataFrequency[] => {
    const duration = periodDurationInDays(startDate, endDate);

    if (duration <= 90) {
        return [];
    }

    if (duration > 90 && duration <= 6 * 365) {
        return [dataFrequencies.hourly];
    }

    return [dataFrequencies.hourly, dataFrequencies.daily];
};
