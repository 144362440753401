import { Modal } from '@infogrid/components-material-ui';
import { Link, Button } from '@material-ui/core';
import classNames from 'classnames';
import { memo, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useInstallationFlowStyles } from 'components/InstallationFlow/styles';
import { TEMPERATURE_OFFSET_GUIDE_URL } from 'constants/urls';
import { InstallationFlowTempOffsetHelp } from 'utils/Images';

import { useHelpStyles } from './styles';

interface InstructionProps {
    parent: string;
    className: string;
}

const INSTRUCTION_TEXT = [
    {
        id: 0,
        text: ({ parent, className }: InstructionProps) => (
            <Trans
                parent={parent}
                i18nKey="temperature_offset_instruction_1"
                ns="sensors"
                className={className}
            >
                <strong>Run the water from the tap</strong>
                until the water temperature feels constant.
            </Trans>
        ),
    },
    {
        id: 1,
        text: ({ parent, className }: InstructionProps) => (
            <Trans
                parent={parent}
                i18nKey="temperature_offset_instruction_2"
                ns="sensors"
                className={className}
            >
                <strong>Hold the probe in the running water</strong>, until the
                temperature reading is steady or has only minor fluctuations.
            </Trans>
        ),
    },
    {
        id: 2,
        text: ({ parent, className }: InstructionProps) => (
            <Trans
                parent={parent}
                i18nKey="temperature_offset_instruction_3"
                ns="sensors"
                className={className}
            >
                <strong>Record the temperature reading</strong>
                to the nearest 1 decimal place. If not entering the reading immediately,
                also record the time.
            </Trans>
        ),
    },
];

interface Props {
    onBack: () => void;
}

const TemperatureOffsetHelp = ({ onBack }: Props) => {
    const { t } = useTranslation('sensors');
    const installationFlowStyles = useInstallationFlowStyles();
    const helpStyles = useHelpStyles();

    return (
        <>
            <Modal.Title className={helpStyles.title}>
                {t('Take a probe thermometer reading')}
                <Button
                    onClick={onBack}
                    variant="text"
                    className={helpStyles.backBtn}
                    color="primary"
                >
                    {t('Back', { ns: 'common' })}
                </Button>
            </Modal.Title>
            <Modal.Content>
                <img
                    src={InstallationFlowTempOffsetHelp}
                    alt="Temperature offset"
                    className={classNames(
                        installationFlowStyles.centeredImage,
                        helpStyles.image,
                    )}
                />
                <ol className={helpStyles.instruction}>
                    {INSTRUCTION_TEXT.map(({ text, id }) => (
                        <Fragment key={id}>
                            {text({ parent: 'li', className: helpStyles.paragraph })}
                        </Fragment>
                    ))}
                </ol>
                <Link
                    href={TEMPERATURE_OFFSET_GUIDE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t('Read more about temperature off-sets')}
                </Link>
            </Modal.Content>
        </>
    );
};

export default memo(TemperatureOffsetHelp);
