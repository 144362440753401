import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useFormToast, useFloorPlanParams } from 'apiHooks/base/hooks';
import { useCreateBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import { useCreateFloor } from 'apiHooks/floorPlan/floors/hooks';
import { useCreateFolder, useConvertSubfolders } from 'apiHooks/floorPlan/folders/hooks';
import {
    selectCreatingBuildingStatus,
    toggleCreatingBuilding,
    toggleCreatingFloor,
} from 'ducks/floorPlan';

const initialState = {
    renderChoosingFolderBuilding: false,
    renderChoosingFolderFloor: false,

    renderBuildingDetails: false,
    renderFinishingCreating: false,
    renderCreatingPreview: false,

    renderCreatingFolderBuilding: false,
    renderCreatingFolderFloor: false,
};

export const useModals = () => {
    const dispatch = useAppDispatch();
    const isOpened = useAppSelector(selectCreatingBuildingStatus);

    const [
        {
            renderChoosingFolderBuilding,
            renderChoosingFolderFloor,

            renderBuildingDetails,
            renderFinishingCreating,
            renderCreatingPreview,

            renderCreatingFolderBuilding,
            renderCreatingFolderFloor,
        },
        setRenders,
    ] = useState(initialState);

    const goTo = useCallback(
        (renderProperty) => {
            setRenders({ ...initialState, [renderProperty]: true });
        },
        [setRenders],
    );

    const goToChoosingFolderBuilding = useCallback(() => {
        goTo('renderChoosingFolderBuilding');
    }, [goTo]);

    const goToBuildingDetails = useCallback(() => {
        goTo('renderBuildingDetails');
    }, [goTo]);

    const goToCreatingFolderBuilding = useCallback(() => {
        goTo('renderCreatingFolderBuilding');
    }, [goTo]);

    const goToCreatingFolderFloor = useCallback(() => {
        goTo('renderCreatingFolderFloor');
    }, [goTo]);

    const goToChoosingFolderFloor = useCallback(() => {
        goTo('renderChoosingFolderFloor');
    }, [goTo]);

    const goToFinishCreating = useCallback(() => {
        goTo('renderFinishingCreating');
    }, [goTo]);

    const goToCreatingPreview = useCallback(() => {
        goTo('renderCreatingPreview');
    }, [goTo]);

    const onClose = useCallback(() => {
        if (renderCreatingFolderBuilding) {
            goToChoosingFolderBuilding();

            return;
        }

        if (renderCreatingFolderFloor) {
            goToChoosingFolderFloor();

            return;
        }

        dispatch(toggleCreatingBuilding(false));
    }, [
        dispatch,
        renderCreatingFolderBuilding,
        renderCreatingFolderFloor,
        goToChoosingFolderBuilding,
        goToChoosingFolderFloor,
    ]);

    const onOpenCreatingFloor = useCallback(() => {
        dispatch(toggleCreatingFloor(true));
    }, [dispatch]);

    return {
        goToChoosingFolderBuilding,
        goToBuildingDetails,
        goToChoosingFolderFloor,
        goToCreatingPreview,
        goToFinishCreating,
        goToCreatingFolderBuilding,
        goToCreatingFolderFloor,

        renderChoosingFolderBuilding,
        renderCreatingPreview,
        renderBuildingDetails,
        renderChoosingFolderFloor,
        renderFinishingCreating,
        renderCreatingFolderBuilding,
        renderCreatingFolderFloor,

        isOpened,
        onClose,
        onOpenCreatingFloor,
    };
};

export const useBuildingDetails = ({
    goToChoosingFolderFloor,
    goToFinishCreating,
    selectedFolder,
}) => {
    const history = useHistory();

    const { mutate: createBuilding } = useCreateBuilding();
    const { handleFormToast } = useFormToast();

    const resolveNextModal = useCallback(() => {
        if (selectedFolder?.contentLength) {
            goToChoosingFolderFloor();
        } else {
            goToFinishCreating();
        }
    }, [goToFinishCreating, goToChoosingFolderFloor, selectedFolder]);

    const handleCreateBuilding = useCallback(
        (submitValues, formik) => {
            const data = {
                name: submitValues.name,
                location: {
                    address: submitValues.address,
                    country_code: submitValues.countryCode,
                    latitude: submitValues.latitude,
                    longitude: submitValues.longitude,
                    city: submitValues.city,
                },
                folder: selectedFolder?.id,
            };

            createBuilding(data, {
                onSuccess: ({ data: building }) => {
                    history.push(
                        routesManager.resolvePath('buildings:building', {
                            buildingId: building.id,
                        }),
                    );

                    resolveNextModal();
                },
                onError: (err) => {
                    handleFormToast({
                        formCallback: (_0, extractedError) => {
                            if (extractedError) {
                                formik.setErrors(extractedError);
                            }
                        },
                        submitValues,
                        error: err.parsedError,
                    });
                },
                onSettled: () => {
                    formik.setSubmitting(false);
                },
            });
        },
        [createBuilding, resolveNextModal, selectedFolder, history, handleFormToast],
    );

    return {
        handleCreateBuilding,
    };
};

export const useCreatingFolder = ({
    renderCreatingFolderBuilding,
    goToCreatingFolderBuilding,
    goToCreatingFolderFloor,
    goToChoosingFolderFloor,
    goToBuildingDetails,
    selectedFolder,
    setSelectedFolder,
}) => {
    const [activeParentFolderId, setActiveParentFolderId] = useState(null);

    const { mutate } = useCreateFolder();

    const resolveNextModal = useCallback(() => {
        if (renderCreatingFolderBuilding) {
            goToBuildingDetails();
        } else {
            goToChoosingFolderFloor();
        }
    }, [renderCreatingFolderBuilding, goToChoosingFolderFloor, goToBuildingDetails]);

    const openCreateFolderBuilding = useCallback(
        ({ parentId }) => {
            goToCreatingFolderBuilding();
            setActiveParentFolderId(parentId);
        },
        [goToCreatingFolderBuilding, setActiveParentFolderId],
    );

    const openCreateFolderFloor = useCallback(() => {
        goToCreatingFolderFloor();
        setActiveParentFolderId(selectedFolder?.id);
    }, [goToCreatingFolderFloor, setActiveParentFolderId, selectedFolder]);

    const handleCreatingFolder = useCallback(
        (submitValues, { setErrors, setSubmitting }) => {
            const payload = {
                name: submitValues.name,
                parent: activeParentFolderId,
            };

            mutate(
                { data: payload },
                {
                    onSuccess(createdFolder) {
                        const { id, name } = createdFolder;

                        setSelectedFolder({ id, name });
                        resolveNextModal();
                    },
                    onError(err) {
                        const message =
                            err?.parsedError?.message || 'Something went wrong.';

                        setErrors({
                            name: message,
                        });
                    },
                    onSettled: () => {
                        setSubmitting(false);
                    },
                },
            );
        },
        [activeParentFolderId, mutate, setSelectedFolder, resolveNextModal],
    );

    return {
        handleCreatingFolder,
        openCreateFolderBuilding,
        openCreateFolderFloor,
    };
};

export const useBuildingSubFolders = ({ goToFinishCreating }) => {
    const history = useHistory();

    const { buildingId } = useFloorPlanParams();

    const { mutate } = useConvertSubfolders({ buildingId });

    const convertingSubFoldersCallback = useCallback(
        (selectedFolders) => {
            if (selectedFolders?.length) {
                const floorId = selectedFolders[0];

                history.push(
                    routesManager.resolvePath('buildings:floor', {
                        buildingId,
                        floorId,
                    }),
                );
            }

            goToFinishCreating();
        },
        [history, goToFinishCreating, buildingId],
    );

    const handleSubfoldersConverting = useCallback(
        (_0, formikProps, selectedFolders) => {
            if (!selectedFolders?.length) {
                convertingSubFoldersCallback(selectedFolders);

                return;
            }

            const data = {
                building: buildingId,
                folders: selectedFolders,
            };

            mutate(
                { data },
                {
                    onSuccess() {
                        convertingSubFoldersCallback(selectedFolders);
                    },
                },
            );
        },
        [buildingId, convertingSubFoldersCallback, mutate],
    );

    return {
        handleSubfoldersConverting,
    };
};

export const useCreateBuildingFloor = ({ selectedFolder, goToChoosingFolderFloor }) => {
    const { mutate } = useCreateFloor(selectedFolder?.id);

    const handleCreatingFloor = useCallback(
        (submitValues, { setErrors, setSubmitting }) => {
            const payload = {
                name: submitValues.name,
                folder: selectedFolder?.id,
            };

            mutate(payload, {
                onSuccess() {
                    goToChoosingFolderFloor();
                },
                onError(err) {
                    const message = err?.parsedError?.message || 'Something went wrong.';

                    setErrors({
                        name: message,
                    });
                },
                onSettled: () => {
                    setSubmitting(false);
                },
            });
        },
        [mutate, selectedFolder, goToChoosingFolderFloor],
    );

    return { handleCreatingFloor };
};
