import { useAppDispatch, toastError } from '@infogrid/core-ducks';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { getFloorDetail, setFloorDetail } from '../accessors';
import { controllers } from '../controllers';

export const useEditMapState = ({ floorId }) => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('floorplan');

    const { isLoading, mutate: editMapState } = useMutation(
        ({ data }) => controllers.editFloor(floorId, data),
        {
            onMutate({ data }) {
                const floor = getFloorDetail(floorId);

                const floorData = { ...floor, ...data };

                setFloorDetail(floorId, floorData);

                return { floor };
            },
            onError(_0, _1, { floor }) {
                setFloorDetail(floorId, floor);

                dispatch(
                    toastError({
                        message: t('Error when update map'),
                    }),
                );
            },
        },
    );

    return {
        isLoading,
        editMapState,
    };
};
