export const returnFirstArg = (val) => val;
export const getNextBooleanValue = (e) =>
    e.target.value === 'true' || e.target.value === true;

/**
 * Toggles a member in an array
 * @param {*[]} originalArray
 * @param {*} value
 * @param {boolean} add - Whether you are adding this member or removing the member
 * @returns {*[]}
 */
export const toggleArrayMember = (originalArray, value, add) => {
    if (!add && originalArray.includes(value)) {
        return originalArray.filter((item) => item !== value);
    }

    if (add && !originalArray.includes(value)) {
        return [...originalArray, value];
    }

    return originalArray;
};

/**
 * Toggles multiple members in an array
 * @param {*[]} originalArray
 * @param {*[]} values
 * @param {boolean} add - Whether you are adding this member or removing the member
 * @returns {*[]}
 */
export const toggleArrayMembers = (originalArray, values, add) => {
    if (!add) {
        return originalArray.filter((item) => !values.includes(item));
    }

    const valuesToAdd = values.filter((value) => !originalArray.includes(value));

    if (valuesToAdd.length > 0) {
        return [...originalArray, ...valuesToAdd];
    }

    return originalArray;
};

/**
 * Toggles a member of an array where the array consists of objects with a unique `id` field
 * @param array - array of selected nodes
 * @param memberToToggle - node
 * @returns {*[]|*}
 */
export const toggleObjArrayMember = (array, memberToToggle) => {
    if (!array.find((item) => item.id === memberToToggle.id)) {
        return [...array, memberToToggle];
    }

    return array.filter((item) => item.id !== memberToToggle.id);
};

/**
 * Updates an array of objects with the new values in `membersToToggle`.
 * For each object in `membersToToggle`:
 *      - if an item with that `id` already exists in `array`, it gets replaced with the new value in `membersToToggle`
 *      - if the item doesn't exist, it is added to the original `array`
 * @param array - original array of objects (with a unique `id` field)
 * @param membersToToggle - list of objects that should be updated or added (with a unique `id` field)
 * @returns {*[]} - original array with the items in it updated or added
 *      For example, given an input array:
 *          [{id: 1, value: 'peach'}]
 *      and membersToToggle:
 *          [{id: 1, value: 'banana'}, {id: 2, value: 'pear'}]
 *      it returns
 *          [{id: 1, value: 'banana'}, {id: 2, value: 'pear'}]
 */
export const updateObjArrayMembers = (array, membersToToggle) => {
    const membersToToggleIds = membersToToggle.map((item) => item.id);

    return [
        ...array.filter((item) => !membersToToggleIds.includes(item.id)),
        ...membersToToggle,
    ];
};
