import TreeView from '@material-ui/lab/TreeView';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback, useState } from 'react';

import TreeViewItem from './TreeViewItem';
import { useBaseTreeViewStyles } from './styles';

const getDefaultSelectedValues = (defaultValue, multiSelect) => {
    if (multiSelect) {
        return Array.isArray(defaultValue) ? defaultValue : [defaultValue];
    }

    return defaultValue[0] || '';
};

const BaseTreeView = (props) => {
    const {
        classes,
        data,
        defaultExpanded,
        defaultSelected,
        disableDefaultSelection,
        multiSelect,
        onAfterExpand,
        onAfterSelect,
        onBeforeExpand,
        onBeforeSelect,
        TreeItemComponent,

        // props extractors
        expandableTypes,
        keyExtractor,
        labelIconExtractor,
        valueExtractor,
        nodeTypeExtractor,
        nodePermissionsExtractor,
        subitemsExtractor,

        ...nativeProps
    } = props;

    const baseTreeViewClasses = useBaseTreeViewStyles();
    const defaultSelectedValues = getDefaultSelectedValues(defaultSelected, multiSelect);

    const [expanded, setExpanded] = useState(defaultExpanded);
    const [selected, setSelected] = useState(defaultSelectedValues);

    const treeViewRootClasses = classNames(
        baseTreeViewClasses.treeViewRoot,
        classes.treeViewRoot,
    );

    const checkIfExpanded = useCallback(
        (nodeKey) => expanded.includes(nodeKey),
        [expanded],
    );

    const checkIfSelected = useCallback(
        (nodeKey) => (multiSelect ? selected.includes(nodeKey) : selected === nodeKey),
        [multiSelect, selected],
    );

    const handleToggle = useCallback(
        (event, nodeKeys) => {
            onBeforeExpand({ expanded });
            setExpanded(nodeKeys);
            onAfterExpand({ expanded: nodeKeys });
        },
        [expanded, onAfterExpand, onBeforeExpand, setExpanded],
    );

    const handleSelect = useCallback(
        (nodeKey, dataNode) => {
            let result = null;

            const isAlreadySelected = checkIfSelected(nodeKey);

            onBeforeSelect(selected);

            if (multiSelect) {
                setSelected((selectedIds) => {
                    result = isAlreadySelected
                        ? selectedIds.filter((itemId) => itemId !== nodeKey)
                        : [...selectedIds, nodeKey];

                    return result;
                });
            } else {
                result = nodeKey;
                setSelected(result);
            }

            onAfterSelect(result, dataNode);
        },
        [
            multiSelect,
            onAfterSelect,
            onBeforeSelect,
            selected,
            setSelected,
            checkIfSelected,
        ],
    );

    const toggleExpandedStateById = useCallback(
        (nodeKey) => {
            const isAlreadyExpanded = expanded.includes(nodeKey);

            onBeforeExpand({ expanded });
            setExpanded((expandedIds) => {
                const result = isAlreadyExpanded
                    ? expandedIds.filter((itemId) => itemId !== nodeKey)
                    : [...expandedIds, nodeKey];

                onAfterExpand({ expanded: result });

                return result;
            });
        },
        [expanded, onAfterExpand, onBeforeExpand],
    );

    const treeItemProps = {
        setExpanded,
        checkIfExpanded,
        checkIfSelected,
        setSelected,
        toggleExpandedStateById,
        handleSelect,

        expandableTypes,
        keyExtractor,
        labelIconExtractor,
        valueExtractor,
        nodeTypeExtractor,
        nodePermissionsExtractor,
        subitemsExtractor,
    };

    const renderTree = (node) =>
        Array.isArray(node) ? (
            node.map((item) => (
                <TreeItemComponent
                    key={keyExtractor(item)}
                    node={item}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...treeItemProps}
                />
            ))
        ) : (
            <TreeItemComponent
                node={node}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...treeItemProps}
            />
        );

    const defaultSelectHandler = useCallback(
        (event, nodeKeys) => !disableDefaultSelection && setSelected(nodeKeys),
        [disableDefaultSelection, setSelected],
    );

    return (
        <TreeView
            className={treeViewRootClasses}
            expanded={expanded}
            multiSelect={multiSelect}
            onNodeToggle={handleToggle}
            onNodeSelect={defaultSelectHandler}
            selected={selected}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...nativeProps}
        >
            {renderTree(data)}
        </TreeView>
    );
};

BaseTreeView.propTypes = {
    classes: PropTypes.shape({
        treeViewRoot: PropTypes.string,
    }),
    data: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]).isRequired,
    defaultExpanded: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    defaultSelected: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ),
    disableDefaultSelection: PropTypes.bool,
    multiSelect: PropTypes.bool,
    onAfterExpand: PropTypes.func,
    onAfterSelect: PropTypes.func,
    onBeforeExpand: PropTypes.func,
    onBeforeSelect: PropTypes.func,
    TreeItemComponent: PropTypes.elementType.isRequired,

    expandableTypes: PropTypes.arrayOf(PropTypes.string),
    keyExtractor: PropTypes.func,
    labelIconExtractor: PropTypes.func,
    valueExtractor: PropTypes.func,
    nodeTypeExtractor: PropTypes.func,
    nodePermissionsExtractor: PropTypes.func,
    subitemsExtractor: PropTypes.func,
};

BaseTreeView.defaultProps = {
    classes: {
        treeViewRoot: '',
    },
    defaultExpanded: [],
    defaultSelected: [],
    disableDefaultSelection: false,
    multiSelect: false,
    onAfterExpand: () => {},
    onAfterSelect: () => {},
    onBeforeExpand: () => {},
    onBeforeSelect: () => {},

    expandableTypes: null,
    keyExtractor: (node) => node.id,
    labelIconExtractor: (node) => node.meta.icon,
    valueExtractor: (node) => node.name,
    nodeTypeExtractor: (node) => node.meta.type,
    nodePermissionsExtractor: (node) => node.meta.edit,
    subitemsExtractor: (node) => node.items,
};

const BaseTreeViewComponent = memo(BaseTreeView);

BaseTreeViewComponent.Item = TreeViewItem;

export default BaseTreeViewComponent;
