import { Modal } from '@infogrid/components-material-ui';
import { useAppSelector } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { COLD_LEGIONNAIRE_SUB_TYPES } from '@infogrid/remote-monitoring-types';
import { getSensor, getSensorConfig } from '@infogrid/sensors-configuration';
import type {
    LegionnaireSensorConfigurationShape,
    SensorShape,
    TemperatureOffsetMode,
} from '@infogrid/sensors-constants';
import {
    TEMPERATURE_OFFSET_MODES,
    TEMPERATURE_OFFSET_STATUSES,
} from '@infogrid/sensors-constants';
import { selectActiveTimeZone, selectUserPreferredTempUnit } from '@infogrid/user-ducks';
import { convertDateTimeInputsToISO, getNowDateTime } from '@infogrid/utils-dates';
import {
    TEMP_UNIT,
    convertFahrenheitDeltaToCelsius,
    convertFahrenheitToCelsius,
    getTempInUserUnits,
} from '@infogrid/utils-measurements';
import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import round from 'lodash/round';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';

import { useUpdateSensorConfiguration } from 'apiHooks/sensors/hooks';
import { useCalibratePipeOffset } from 'apiHooks/sensors/installation/hooks';
import {
    TEMP_OFFSET_READING_TYPES,
    TemperatureOffsetConfigurationForm,
    TemperatureOffsetHelpContent,
    temperatureOffsetConfigurationSchema,
    validateTempOffsetDateOfReading,
    validateTempOffsetErrors,
} from 'components/InstallationFlow/TemperatureOffsetConfiguration';
import type { TemperatureOffsetParams } from 'utils/types/ts/pipeMonitoringSensors';

import TemperatureOffsetZeroCalibration from '../TemperatureOffsetZeroCalibration';

interface Props {
    sensor: SensorShape;
    isOpen?: boolean;
    onSuccess?: () => void;
    onCancel: () => void;
}

const TemperatureOffsetModal = ({
    sensor,
    isOpen = false,
    onSuccess,
    onCancel,
}: Props) => {
    const { t } = useTranslation('sensors');
    const [isLoading, toggleLoading] = useToggle(false);
    const [isHelpModalOpen, toggleHelp] = useToggle(false);
    const deviceName = sensor.device_name;
    const deviceType = sensor.type_code;
    const sensorConfiguration =
        sensor.sensor_configuration as LegionnaireSensorConfigurationShape;
    const sensorLabel = getSensorConfig(deviceType).label;
    const supportsLiveCalibration = getSensor(sensor).supportsLiveCalibration();
    const supportsZeroCalibration = COLD_LEGIONNAIRE_SUB_TYPES.includes(
        sensorConfiguration?.sub_type,
    );
    const [isZeroCalibrationOpen, setIsZeroCalibrationOpen] = useState(
        supportsZeroCalibration,
    );

    const onCancelInternal = useCallback(() => {
        onCancel();
        setIsZeroCalibrationOpen(supportsZeroCalibration);
    }, [onCancel, setIsZeroCalibrationOpen, supportsZeroCalibration]);
    const timeZone = useAppSelector(selectActiveTimeZone);
    const userPreferredTempUnit = useAppSelector(selectUserPreferredTempUnit);

    const { mutate: calibratePipeOffset } = useCalibratePipeOffset({
        successMessage: supportsLiveCalibration
            ? t('Sensor configuration submitted. Temperature offset will update shortly.')
            : undefined,
    });
    const { mutate: updateSensorConfiguration } =
        useUpdateSensorConfiguration(deviceName);

    const formik = useFormik<TemperatureOffsetParams>({
        initialValues: {
            ...getNowDateTime(),
            reading_type: TEMP_OFFSET_READING_TYPES.NOW.value,
            // `temperature` can be either a probe reading (gen2) or temperature offset (gen1)
            temperature: supportsLiveCalibration
                ? undefined
                : getTempInUserUnits(
                      sensorConfiguration?.temperature_offset,
                      userPreferredTempUnit,
                      true,
                  ),
        },
        onSubmit: (formValues) => {
            toggleLoading();

            const timeOfReading = convertDateTimeInputsToISO(
                formValues.date_of_reading as string,
                formValues.time_of_reading,
                timeZone,
            );

            const convertFn = supportsLiveCalibration
                ? convertFahrenheitToCelsius
                : convertFahrenheitDeltaToCelsius;

            const temperature =
                userPreferredTempUnit === TEMP_UNIT.FAHRENHEIT
                    ? round(convertFn(Number(formValues.temperature)), 2)
                    : Number(formValues.temperature);

            const updateOptions = {
                onSuccess: () => {
                    onSuccess?.();
                    onCancelInternal(); // Close the modal
                },
                onError: (err: AxiosParsedError) => {
                    if (err.parsedError) {
                        const { errors, message } = err.parsedError;

                        if (errors) {
                            formik.setErrors(errors);
                        }

                        if (message) {
                            formik.setStatus({ message });
                        }
                    }
                },
                onSettled: () => {
                    toggleLoading();
                },
            };

            if (supportsLiveCalibration) {
                calibratePipeOffset(
                    {
                        deviceName,
                        data: {
                            temperature,
                            time_of_reading: timeOfReading,
                        },
                    },
                    updateOptions,
                );
            } else {
                updateSensorConfiguration(
                    {
                        data: {
                            sensor_configuration: {
                                ...sensor.sensor_configuration,
                                temperature_offset: temperature,
                                offset_mode: TEMPERATURE_OFFSET_MODES.MANUAL,
                                offset_status: TEMPERATURE_OFFSET_STATUSES.SET,
                            } as LegionnaireSensorConfigurationShape,
                        },
                    },
                    updateOptions,
                );
            }
        },
        validate: (formValues) => ({
            ...validateTempOffsetDateOfReading(formValues, timeZone),
            ...validateTempOffsetErrors(formValues),
        }),
        validationSchema: temperatureOffsetConfigurationSchema,
    });
    const applyZeroCalibration = useCallback(
        (mode: TemperatureOffsetMode) => {
            if (mode === TEMPERATURE_OFFSET_MODES.MANUAL) {
                setIsZeroCalibrationOpen(false);
                return;
            }

            toggleLoading();
            updateSensorConfiguration(
                {
                    data: {
                        sensor_configuration: {
                            ...sensor.sensor_configuration,
                            temperature_offset: 0,
                            offset_mode: mode,
                            offset_status: TEMPERATURE_OFFSET_STATUSES.SET,
                        } as LegionnaireSensorConfigurationShape,
                    },
                },
                {
                    onSuccess: () => {
                        onSuccess?.();
                        onCancelInternal(); // Close the modal
                    },
                    onError: (err: AxiosParsedError) => {
                        if (err.parsedError) {
                            const { errors, message } = err.parsedError;

                            if (errors) {
                                formik.setErrors(errors);
                            }

                            if (message) {
                                formik.setStatus({ message });
                            }
                        }
                    },
                    onSettled: () => {
                        toggleLoading();
                    },
                },
            );
        },
        [
            formik,
            onCancelInternal,
            onSuccess,
            sensor.sensor_configuration,
            toggleLoading,
            updateSensorConfiguration,
        ],
    );

    const renderActions = useCallback(
        () => <Button onClick={onCancelInternal}>{t('Cancel', { ns: 'common' })}</Button>,
        [onCancelInternal, t],
    );

    return (
        <Modal
            open={isOpen}
            onClose={onCancelInternal}
            hideCloseButton={isHelpModalOpen}
            loading={isLoading}
        >
            {isHelpModalOpen && <TemperatureOffsetHelpContent onBack={toggleHelp} />}
            {!isHelpModalOpen && isZeroCalibrationOpen && (
                <TemperatureOffsetZeroCalibration
                    value={
                        (
                            sensor.sensor_configuration as LegionnaireSensorConfigurationShape
                        ).offset_mode
                    }
                    onCancel={onCancelInternal}
                    onChange={applyZeroCalibration}
                />
            )}
            {!isHelpModalOpen && !isZeroCalibrationOpen && (
                <TemperatureOffsetConfigurationForm
                    onHelp={toggleHelp}
                    sensor={sensor}
                    formik={formik}
                    sensorName={sensorLabel}
                    sensorType={deviceType}
                    supportsLiveCalibration={supportsLiveCalibration}
                    renderActions={renderActions}
                />
            )}
        </Modal>
    );
};

export default memo(TemperatureOffsetModal);
