import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useIntegrationsStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 100%',
    },
    pageNameText: {
        whiteSpace: 'nowrap',
        fontSize: 20,
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
}));
