import { useAppSelector } from '@infogrid/core-ducks';
import type { HierarchyFloor } from '@infogrid/locations-types';
import type { SensorType } from '@infogrid/sensors-constants';
import { selectUserLastInstallationConf } from '@infogrid/user-ducks';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type {
    HierarchyBuildings,
    HierarchyFloorMap,
} from 'apiHooks/floorPlan/buildings/types';

import { configurationFormValidationSchema } from '../utils/configurationFormValidationSchema';
import { NO_SPACE_OPTION } from './consts';
import type { ConfigurationFormValues } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useConfigurationFormik = ({
    deviceType,
    sensorName = '',
    floorMap,
    buildingsData,
    onSubmit,
}: {
    deviceType: SensorType;
    sensorName?: string;
    floorMap: HierarchyFloorMap;
    buildingsData: HierarchyBuildings;
    onSubmit: (formValues: ConfigurationFormValues) => void;
}) => {
    const { t } = useTranslation('sensors');

    // Used to pre-populate the form fields based on what the user chose the last time they installed sensors
    const lastConfig = useAppSelector(selectUserLastInstallationConf);

    const defaultBuilding = useMemo(
        () =>
            buildingsData?.find(
                (building) => building.id === lastConfig?.last_saved_building,
            ),
        [lastConfig, buildingsData],
    );

    const defaultFloor = useMemo(() => {
        if (!defaultBuilding) {
            return null;
        }

        return floorMap[defaultBuilding.id].find(
            (floor: HierarchyFloor) => floor.id === lastConfig?.last_saved_floor,
        );
    }, [floorMap, defaultBuilding, lastConfig]);

    const initialValues: ConfigurationFormValues = {
        building: defaultBuilding || null,
        floor: defaultFloor || null,
        space: NO_SPACE_OPTION,
        device_name: sensorName,
        sub_type: '',
        temperature_offset: null,
        labels: [],
    };

    return useFormik<ConfigurationFormValues>({
        initialValues,
        enableReinitialize: true,
        validationSchema: configurationFormValidationSchema(deviceType, t),
        onSubmit,
    });
};
