import { useAppSelector } from '@infogrid/core-ducks';
import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import type { Widget } from '@infogrid/dashboards-constants';
import { selectUserPreferredTempUnit } from '@infogrid/user-ducks';
import { TableContainer } from '@material-ui/core';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useThresholds } from 'apiHooks/settings/solutions/hooks';

import WidgetAlert from '../../common/WidgetAlert';
import type { PipeMonitoringWidgetData } from '../types';
import Table from './Table';
import ThresholdsNotSetAlert from './ThresholdsNotSetAlert';
import { useContentStyles } from './styles';
import { getTableRowsAttributes } from './utils';

interface Props {
    isConfigured: boolean;
    isLoadingDataError: boolean;
    widget: Widget;
    data: PipeMonitoringWidgetData;
}

const Content = ({ data, widget, isConfigured, isLoadingDataError }: Props) => {
    const { t } = useTranslation('dashboard');
    const styles = useContentStyles();

    const userPreferredTempUnit = useAppSelector(selectUserPreferredTempUnit);

    const { pipe_monitoring_graph_rows: rows, no_data: isEmptyTableData } = data;

    const { data: pipeThresholds } = useThresholds();

    const isThresholdsNotSet =
        !pipeThresholds?.pipe_monitoring_temperature_thresholds[0]?.updated_by;

    const shouldRedirectToThresholds = isThresholdsNotSet;

    const rowsAttributes = useMemo(
        () => getTableRowsAttributes(t, userPreferredTempUnit),
        [t, userPreferredTempUnit],
    );

    if (shouldRedirectToThresholds) {
        return <ThresholdsNotSetAlert />;
    }

    if (!isConfigured) {
        return (
            <WidgetAlert severity="warning">
                {t(WidgetErrorMessages.notConfigured)}
            </WidgetAlert>
        );
    }

    if (isLoadingDataError) {
        return (
            <WidgetAlert severity="error">
                {t(WidgetErrorMessages.dataLoadError)}
            </WidgetAlert>
        );
    }

    if (isEmptyTableData || !rows) {
        return <WidgetAlert severity="info">{t(WidgetErrorMessages.noData)}</WidgetAlert>;
    }

    return (
        <TableContainer className={styles.tableContainer}>
            <Table widgetId={widget.id} rowsAttributes={rowsAttributes} rows={rows} />
        </TableContainer>
    );
};

export default memo(Content);
