export const gridBreakpoints = {
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
};

export const gridColumns = {
    lg: 3,
    md: 2,
    sm: 1,
    xs: 1,
    xxs: 1,
};

export const draggableHandleClassName = 'widgets-grid-drag-handle';
