import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError } from '@infogrid/core-ducks';
import { useTranslation } from 'react-i18next';

import { controllers } from '../controllers';

export const useConfigureSensor = () => {
    const { t } = useTranslation('sensors');
    const dispatch = useAppDispatch();

    return useMutationWithToast(
        ({ deviceName, data }) =>
            wrapResponsePromise(controllers.configureSensor({ data, id: deviceName })),
        {
            onError: () => {
                dispatch(toastError({ message: t('Error saving sensor configuration') }));
            },
        },
        { toastMessage: t('Updating sensor configuration is in progress') },
    );
};
