import { HEADER_HEIGHT } from '@infogrid/components-material-ui';
import { makeStyles } from '@material-ui/core';

import { DEFAULT_SPACE_SIDEBAR_WIDTH } from './consts';

export const useUnassignedSensorsSidebarStyles = makeStyles((theme) => ({
    sidebar: {
        flexShrink: 0,
        height: '100%',
        position: 'sticky',
    },
    collapsedSidebar: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[3],
        height: '100vh',
        position: 'fixed',
        right: 0,
        top: 0,
        width: 15,
    },
    sidebarCollapseButton: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        left: 'auto',
        padding: theme.spacing(0.5),
        top: '50%',

        '&:hover': {
            backgroundColor: theme.palette.background.grey,
            opacity: 1,
        },
    },
    sidebarCollapseIcon: {
        color: theme.palette.common.black,
        fontSize: 26,
    },
    drawer: {
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[3],
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        overflowY: 'auto',
        width: DEFAULT_SPACE_SIDEBAR_WIDTH,
    },
}));
