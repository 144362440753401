import { makeStyles } from '@material-ui/core';

export const useListItemStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        minHeight: 40,
        justifyContent: 'space-between',
    },
    subcontainer: {
        alignItems: 'center',
        display: 'flex',
    },
    icon: {
        alignItems: 'center',
        color: theme.palette.common.gray6,
        display: 'flex',
        fontSize: 17,
        height: 19,
        justifyContent: 'center',
        width: 20,
    },
    arrowIcon: {
        cursor: 'pointer',
    },
    label: {
        lineHeight: '24px',
        marginLeft: theme.spacing(1),
    },
    sensorsCount: {
        minWidth: 30,
    },
}));
