import { i18n } from '@infogrid/utils-i18n';
import type { AxiosResponse } from 'axios';

import type { InstallSensorErrorResponse } from 'apiHooks/sensors/installation/hooks';

// Using i18n.t() here because we’re using interpolation and
// don’t have access to the values at the point of rendering text
const { t } = i18n;

const DEFAULT_USER_MESSAGE = t(
    'An error has occurred during installation. Please try again.',
    { ns: 'sensors' },
);

export const INSTALL_ERRORS: Record<string, InstallSensorErrorResponse> = {
    network_error: {
        error_code: 'network_error',
        user_title: t('Network request has failed', {
            ns: 'sensors',
        }),
        user_message: DEFAULT_USER_MESSAGE,
        can_retry: true,
    },
    server_error: {
        error_code: 'server_error',
        user_title: t('Something has gone wrong!', {
            ns: 'sensors',
        }),
        user_message: DEFAULT_USER_MESSAGE,
    },
    already_installed_error: {
        error_code: 'already_installed_error',
        user_title: t('This device has already been installed', {
            ns: 'sensors',
        }),
        user_message: t('Scan a different device instead.', { ns: 'sensors' }),
    },
    device_match_error: {
        error_code: 'device_match_error',
        user_title: t("Device doesn't match"),
        user_message: t(
            "The device you have identified does not match the expected sensor type. Please check the device you're using is appropriate for this solution.",
        ),
    },
};

/**
 * Returns a correctly structured installation error from an axios response object
 */
export const getInstallError = ({
    data,
    status,
}: Partial<AxiosResponse> = {}): InstallSensorErrorResponse => {
    if (!data) {
        return status ? INSTALL_ERRORS.server_error : INSTALL_ERRORS.network_error;
    }

    if (data.user_title) {
        return data;
    }

    if (data.error) {
        // Legacy response without a user_message
        return {
            ...INSTALL_ERRORS.server_error,
            user_message: data.error,
        };
    }

    // Fall back to generic server error
    return INSTALL_ERRORS.server_error;
};

export type Breadcrumbs = {
    id: number;
    name: string;
    folder_type: 'folder' | 'building' | 'floor' | 'space';
}[];

export const getAlreadyInstalledError = (
    breadcrumbs: Breadcrumbs,
): InstallSensorErrorResponse => {
    if (!breadcrumbs) {
        return INSTALL_ERRORS.already_installed_error;
    }

    const locationString = breadcrumbs.map((b) => b.name).join(' / ');

    return {
        ...INSTALL_ERRORS.already_installed_error,
        user_message: t(
            'Existing location: {{location}}. Scan a different device instead.',
            {
                ns: 'sensors',
                location: locationString,
            },
        ),
    };
};
