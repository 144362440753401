import { FormControlLabel, Grid, Switch, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useOccupancySectionStyles } from './styles';

const minInputProps = {
    min: 0,
};

const PeopleOccupancySection = ({
    allowEqualThresholdLimits,
    color,
    disabledMax,
    disabledMin,
    errors,
    handleChange,
    maxThreshold,
    message,
    minThreshold,
    name,
    onMaxChange,
    onMinChange,
    showOccupancyCount,
    title,
}) => {
    const { t } = useTranslation('dashboard');
    const styles = useOccupancySectionStyles({ color });

    const maxInputProps = {
        'data-testid': `${name}-max-threshold-input`,
        min: allowEqualThresholdLimits ? minThreshold : minThreshold + 1,
    };

    return (
        <div>
            <div className={styles.titleContainer}>
                <i
                    className={classNames('fas fa-circle', styles.colorCircle)}
                    style={{ color }}
                />
                <Typography className={styles.title} variant="subtitle1">
                    {t(title)}
                </Typography>
            </div>
            <Grid className={styles.outerGrid} container spacing={2}>
                <Grid item xs={5}>
                    <Typography variant="body2">{t('Occupancy thresholds')}</Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant="body2">
                        {t('Message', { ns: 'common' })}
                    </Typography>
                </Grid>
                <Grid item xs={5}>
                    <Grid alignItems="center" container>
                        <Grid item xs={5}>
                            <TextField
                                disabled={disabledMin}
                                id="minThreshold"
                                data-cypress={`${name}-minThreshold`}
                                inputProps={minInputProps}
                                name={`displayOptions.${name}.minThreshold`}
                                onChange={onMinChange || handleChange}
                                size="small"
                                type="number"
                                value={minThreshold}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid className={styles.gridItemCentered} item xs={2}>
                            —
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                disabled={disabledMax}
                                id="maxThreshold"
                                data-cypress={`${name}-maxThreshold`}
                                inputProps={maxInputProps}
                                name={`displayOptions.${name}.maxThreshold`}
                                onChange={onMaxChange || handleChange}
                                size="small"
                                type="number"
                                value={maxThreshold}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={7}>
                    <TextField
                        error={!!errors?.message}
                        fullWidth
                        helperText={errors?.message}
                        id={`${name}-message`}
                        inputProps={{
                            'data-testid': `${name}-message-input`,
                        }}
                        name={`displayOptions.${name}.message`}
                        data-cypress={`${name}-message`}
                        onChange={handleChange}
                        size="small"
                        value={message}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <FormControlLabel
                className={styles.formControlLabel}
                control={
                    <Switch
                        checked={showOccupancyCount}
                        color="primary"
                        id="showOccupancyCount"
                        name={`displayOptions.${name}.showOccupancyCount`}
                        onChange={handleChange}
                    />
                }
                label={t('Show occupancy count')}
            />
        </div>
    );
};

PeopleOccupancySection.propTypes = {
    allowEqualThresholdLimits: PropTypes.bool,
    color: PropTypes.string.isRequired,
    disabledMax: PropTypes.bool,
    disabledMin: PropTypes.bool,
    errors: PropTypes.shape({
        message: PropTypes.string,
    }),
    handleChange: PropTypes.func.isRequired,
    maxThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    message: PropTypes.string,
    minThreshold: PropTypes.number,
    name: PropTypes.string.isRequired,
    onMaxChange: PropTypes.func,
    onMinChange: PropTypes.func,
    showOccupancyCount: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

PeopleOccupancySection.defaultProps = {
    allowEqualThresholdLimits: false,
    disabledMax: false,
    disabledMin: false,
    errors: {},
    maxThreshold: 0,
    message: '',
    minThreshold: 0,
    onMaxChange: null,
    onMinChange: null,
    showOccupancyCount: false,
};

export default memo(PeopleOccupancySection);
