import { makeStyles } from '@material-ui/core';

export const useAddBuildingModalStyles = makeStyles((theme) => ({
    form: {
        width: 520,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    linkLabel: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        display: 'inline',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    checkboxFormControl: {
        margin: theme.spacing(1, 0, 0),
    },
    saveAndAddAnotherButton: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    saveButton: {
        minWidth: 90,
    },
}));
