import { DIRECTION } from './consts';
import type { Direction, OrderBy, OrderByColumn, OrderByDirection } from './types';

export const parseSorting = (
    column: OrderByColumn,
    direction: OrderByDirection,
): null | string => {
    if (!column) {
        return null;
    }

    return direction === DIRECTION.DESC ? `-${column}` : column;
};

const DESC_FIRST_DIRECTIONS = [DIRECTION.DESC, DIRECTION.ASC, null];
const ASC_FIRST_DIRECTION = [DIRECTION.ASC, DIRECTION.DESC, null];

export const calculateSorting = (
    currentSorting: OrderBy,

    { column, ascendingFirst }: { column: OrderByColumn; ascendingFirst?: boolean },
):
    | { column: null; direction: null }
    | { column: string; direction: Direction }
    | null => {
    const directions = ascendingFirst ? ASC_FIRST_DIRECTION : DESC_FIRST_DIRECTIONS;

    if (!column) {
        return null;
    }

    if (currentSorting.column !== column) {
        return {
            column,
            direction: directions[0] as Direction,
        };
    }

    const currentDirectionIdx = directions.findIndex(
        (value) => value === currentSorting.direction,
    );
    const newDirectionIdx = (currentDirectionIdx + 1) % directions.length;
    const newDirection = directions[newDirectionIdx];

    if (newDirection === null) {
        return null;
    }

    return {
        column,
        direction: newDirection,
    };
};
