import { Select } from '@infogrid/components-material-ui';
import { List, ListItem, Typography } from '@material-ui/core';
import { memo } from 'react';

import { useSelectStyles } from './styles';

type SelectItem = {
    id: number;
    name: string;
};

type Props = {
    selectedItemId?: number | null;
    items: SelectItem[];
    onSelect?: (id: number) => void;
    defaultLabel?: string;
    disabled?: boolean;
};

const FloorSelect = ({
    items,
    onSelect = () => {},
    selectedItemId = null,
    defaultLabel = 'None',
    disabled = false,
}: Props) => {
    const styles = useSelectStyles();

    const renderValue = (
        <Typography className={styles.renderValue} component="span" variant="body2">
            {items.find((x) => x.id === selectedItemId)?.name || defaultLabel}
        </Typography>
    );

    return (
        <Select<SelectItem>
            classes={{
                container: styles.selectContainer,
                content: styles.selectContent,
            }}
            renderValue={renderValue}
            disabled={disabled}
            renderInPortal
        >
            {() => {
                return (
                    <List className={styles.menuList}>
                        {items.map(({ id, name }) => {
                            return (
                                <ListItem
                                    button
                                    key={id}
                                    selected={id === selectedItemId}
                                    onClick={() => {
                                        onSelect(id);
                                    }}
                                >
                                    <Typography component="span" variant="body2">
                                        {name}
                                    </Typography>
                                </ListItem>
                            );
                        })}
                    </List>
                );
            }}
        </Select>
    );
};

export default memo(FloorSelect);
