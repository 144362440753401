import type { ChipProps } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { memo } from 'react';

import type { ChipType } from 'views/dashboards/widgets/PipeMonitoringAtRiskWidget/types';

const HotChip = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
    },
}))(Chip);

const ColdChip = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
    },
}))(Chip);

const CaloriferChip = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
    },
}))(Chip);

const DefaultChip = withStyles((theme: Theme) => ({
    root: {
        color: theme.palette.grey[600],
        borderColor: theme.palette.grey[600],
    },
}))(Chip);

interface SensorConfigurationChipProps extends Omit<ChipProps, 'color'> {
    chipType: ChipType;
}

const SubTypeChip = ({
    chipType,
    ...props
}: SensorConfigurationChipProps): JSX.Element => {
    if (chipType === 'hot') {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <HotChip variant="outlined" {...props} />;
    }

    if (chipType === 'cold') {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <ColdChip variant="outlined" {...props} />;
    }

    if (chipType === 'calorifier') {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <CaloriferChip variant="outlined" {...props} />;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <DefaultChip variant="outlined" {...props} />;
};

export default memo(SubTypeChip);
