import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import classNames from 'classnames';
import { memo } from 'react';

import { useArrowStyles } from './styles';

interface Props {
    className?: string;
}

const Arrow = ({ className }: Props): JSX.Element => {
    const styles = useArrowStyles();

    return (
        <div className={classNames(className, styles.root)}>
            <ArrowBackIosRoundedIcon className={styles.arrow} />
            <svg
                className={styles.line}
                strokeLinecap="round"
                viewBox="0 0 100 1"
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line
                    strokeLinecap="round"
                    pathLength="100"
                    x1="0"
                    y1="1"
                    x2="100"
                    y2="1"
                />
            </svg>
        </div>
    );
};

export default memo(Arrow);
