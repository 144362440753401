import {
    wrapResponsePromise,
    INFINITE_QUERY_DEFAULT_INITIAL_DATA,
} from '@infogrid/core-api';
import { applicationSelectors, Constance } from '@infogrid/core-ducks';
import type { AxiosParsedError, IPagination, MaybeArray } from '@infogrid/core-types';
import type { SensorEventsV2, SensorShape } from '@infogrid/sensors-constants';
import { useSelectorWithArgs } from '@infogrid/utils-hooks';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import type { UseInfiniteQueryOptions, UseInfiniteQueryResult } from 'react-query';

import { mutateSensor } from 'utils/sensor';

import { controllers } from '../controllers';
import { getSensorsLatestEventsKey } from '../getQueryKeys';
import { selectLatestEvents } from '../selectors';

export interface SensorEvent {
    id: number;
    device_name: string;
    update_time?: Date;
}

export type UseLatestSensorsEventsOptions = UseInfiniteQueryOptions<
    IPagination<SensorEventsV2>,
    AxiosParsedError,
    IPagination<SensorEventsV2>
> & {
    onFetch?: ({ events }: { events: SensorEvent[] }) => void;
};

export const useLatestSensorsEvents = (
    ids: MaybeArray<string>,
    sensors: SensorShape[] = [],
    params: UseLatestSensorsEventsOptions,
): UseInfiniteQueryResult => {
    const { enabled = true, onFetch = () => null, ...queryParams } = params;

    const livePollingDelay = useSelectorWithArgs(
        // @ts-expect-error: to fix
        applicationSelectors.getConstanceValue,
        Constance.POLL_EVENTS_IN_FRONTEND,
    ) as number;

    const deviceIds = Array.isArray(ids) ? ids : [ids];

    const { hasNextPage, isFetchingNextPage, fetchNextPage, ...infiniteQuery } =
        useInfiniteQuery(
            getSensorsLatestEventsKey(deviceIds),
            ({ signal, pageParam = {} }) => {
                return wrapResponsePromise(
                    controllers.getLatestEvents(deviceIds, signal, { ...pageParam }),
                    (response) => {
                        const { data } = response;

                        const events = Object.entries(data);

                        const result = {
                            events: events.map(([deviceName, latestEvents]) => {
                                const sensor =
                                    sensors?.filter(
                                        (item) => item?.device_name === deviceName,
                                    )[0] || null;

                                const out = sensor
                                    ? mutateSensor({
                                          ...sensor,
                                          latest_events_v2: latestEvents,
                                      })
                                    : { latest_events_v2: latestEvents };

                                return out;
                            }),
                            next: data.next,
                            results: data.results,
                            previous: data.previous,
                        };

                        if (isFunction(onFetch)) {
                            onFetch(result);
                        }

                        return result;
                    },
                );
            },
            {
                retry: false,
                initialData: INFINITE_QUERY_DEFAULT_INITIAL_DATA,
                keepPreviousData: false,
                refetchInterval: livePollingDelay * 1000,
                enabled: !!(
                    enabled &&
                    !isEmpty(compact(deviceIds)) &&
                    livePollingDelay > 0
                ),
                cacheTime: 0,
                getNextPageParam: (lastPage) => lastPage?.next,
                select: selectLatestEvents,
                ...queryParams,
            },
        );

    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    return {
        ...infiniteQuery,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
