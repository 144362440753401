import { makeStyles } from '@material-ui/core';

export const useIdentifySensorStyles = makeStyles((theme) => ({
    tryAgainButton: {
        marginLeft: 'auto',
        color: theme.palette.error.dark,
    },
    alertText: {
        marginBottom: theme.spacing(2),

        '&:last-of-type': {
            marginBottom: theme.spacing(0),
        },
    },
    code: {
        fontSize: 10,
    },
    instruction: {
        color: theme.palette.text.grey.default,
        marginBottom: theme.spacing(1.5),
        display: 'block',
    },
}));
