import { Modal } from '@infogrid/components-material-ui';
import type { SensorShape } from '@infogrid/sensors-constants';
import { Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import type { FC } from 'react';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import SensorInfoCard from 'components/material-ui/Sensors/SensorInfoCard';

import { useSensorOfflineStyles } from './styles';

interface SensorOfflineProps {
    identifiedSensor: SensorShape;
    statusLoading: boolean;
    onClickBack: () => void;
    onClickContinue: () => void;
}

const SensorOffline: FC<SensorOfflineProps> = ({
    identifiedSensor,
    statusLoading,
    onClickBack,
    onClickContinue,
}) => {
    const { t } = useTranslation('sensors');
    const styles = useSensorOfflineStyles();

    return (
        <>
            <Modal.Title>{t('This sensor is offline!')}</Modal.Title>
            <Modal.Content>
                <SensorInfoCard
                    sensor={identifiedSensor}
                    statusLoading={statusLoading}
                    variant="inline"
                />
                <Alert
                    severity="warning"
                    className={styles.warning}
                    data-cypress="signal-warning"
                >
                    <Typography variant="body1" paragraph gutterBottom>
                        {t(
                            'Check the signal of the device using the bars above. It could take a few minutes to come online.',
                        )}
                        <br />
                        {t(
                            'We recommend you complete the installation once the sensor is online.',
                        )}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <Trans t={t}>
                            Not online? See{' '}
                            <a
                                href="https://help.infogrid.io/en/articles/5940857-disruptive-technologies-sensor-troubleshooting"
                                target="_blank"
                                rel="noreferrer"
                            >
                                troubleshooting
                            </a>
                        </Trans>
                    </Typography>
                </Alert>
            </Modal.Content>

            <Modal.Actions justify="space-between">
                <Button
                    data-cypress="install-offline-back"
                    onClick={onClickBack}
                    color="primary"
                >
                    {t('Back', { ns: 'common' })}
                </Button>
                <Button
                    data-cypress="install-offline-continue"
                    onClick={onClickContinue}
                    variant="outlined"
                    color="primary"
                >
                    {t('Continue offline')}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(SensorOffline);
