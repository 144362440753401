import { makeStyles } from '@material-ui/core';

export const useLocationsStyles = makeStyles((theme) => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3, 3.75),
        width: '100%',
        height: '100%',

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2.5, 2),
        },
    },
    pageTitle: {
        fontWeight: 500,
        fontSize: 20,
        letterSpacing: 0.15,
    },
    header: {
        paddingBottom: theme.spacing(3.75),
    },
    closeIconButton: ({ isSuccess }: { isSuccess: boolean }) => ({
        display: isSuccess ? 'none' : '',
    }),
    selectedLocationsCard: {
        padding: theme.spacing(2, 2.5),
        display: 'flex',
        justifyContent: 'space-between',

        '& p': {
            padding: theme.spacing(1),
        },

        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',

            '& button': {
                width: 184,
            },

            '& p': {
                paddingBottom: theme.spacing(2.5),
            },
        },
    },
    container: ({ isSuccess }: { isSuccess: boolean }) => ({
        width: isSuccess ? '600px' : '80%',

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    }),
    description: {
        marginTop: theme.spacing(1),
    },
    locationPicker: {
        height: '75vh',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
        },
    },
    locationPickerContainer: {
        height: '100%',
        width: '100%',
    },
    modalTitle: {
        fontSize: '1.25rem',
        fontWeight: 500,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    modalContent: {
        height: 'calc(100% - 190px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100% - 214px)',
        },
    },
    modalFooter: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
}));
