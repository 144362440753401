import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import SETTINGS from 'settings';
import type { CorrigoCredentials } from 'views/integrations/components/corrigo/types';

import { useCorrigoStyling } from './styles';

interface Props {
    onClickUpload: (credentials: CorrigoCredentials) => void;
}

const CorrigoCredentialsForm = ({ onClickUpload }: Props) => {
    const styles = useCorrigoStyling();
    const { t } = useTranslation('integrations');

    const isProd = String(SETTINGS.VERBOSE).toLowerCase() !== 'true';

    const initialValues: CorrigoCredentials = {
        client_id: '',
        client_secret: '',
        company_name: '',
        auth_url: isProd ? 'https://oauth-pro-v2.corrigo.com/OAuth/token' : '',
        base_url: isProd ? 'https://am-ce98f.corrigo.com' : '',
    };

    const validationSchema = Yup.object().shape({
        client_id: Yup.string().required(),
        client_secret: Yup.string().required(),
        company_name: Yup.string().required(),
        auth_url: Yup.string().required(),
        base_url: Yup.string().required(),
    });

    return (
        <>
            <Alert severity="info">
                {t(
                    'Required fields can be found under Settings in the Corrigo web interface.',
                )}
            </Alert>
            <Formik
                onSubmit={(values, { resetForm }) => {
                    onClickUpload(values);
                    resetForm({ values });
                }}
                validationSchema={validationSchema}
                initialValues={initialValues}
            >
                {(formik) => {
                    const { values } = formik;

                    return (
                        <Form
                            className={styles.credentialsForm}
                            aria-label="form-corrigo-credentials"
                            data-testid="corrigo-credentials-form"
                        >
                            <TextField
                                data-cypress="client_id"
                                disabled={formik.isSubmitting}
                                id="client_id"
                                label={t('Client ID')}
                                name="client_id"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={values.client_id}
                                variant="outlined"
                                error={
                                    formik.touched?.client_id &&
                                    !!formik.errors?.client_id
                                }
                            />
                            <TextField
                                data-cypress="client_secret"
                                disabled={formik.isSubmitting}
                                id="client_secret"
                                label={t('Client secret')}
                                name="client_secret"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={values.client_secret}
                                variant="outlined"
                                error={
                                    formik.touched?.client_secret &&
                                    !!formik.errors?.client_secret
                                }
                            />
                            <TextField
                                data-cypress="company_name"
                                disabled={formik.isSubmitting}
                                id="company_name"
                                label={t('Company name')}
                                name="company_name"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={values.company_name}
                                variant="outlined"
                                error={
                                    formik.touched?.company_name &&
                                    !!formik.errors?.company_name
                                }
                            />
                            <TextField
                                data-cypress="auth_url"
                                disabled={formik.isSubmitting}
                                id="auth_url"
                                label={t('Authentication URL')}
                                name="auth_url"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={values.auth_url}
                                variant="outlined"
                                error={
                                    formik.touched?.auth_url && !!formik.errors?.auth_url
                                }
                            />
                            <TextField
                                data-cypress="base_url"
                                disabled={formik.isSubmitting}
                                id="base_url"
                                label={t('Base URL')}
                                name="base_url"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="text"
                                value={values.base_url}
                                variant="outlined"
                                error={
                                    formik.touched?.base_url && !!formik.errors?.base_url
                                }
                            />
                            <Button
                                size="large"
                                type="submit"
                                data-cypress="submit"
                                className={styles.credentialsFormButton}
                                disabled={formik.isSubmitting}
                                variant="contained"
                                color="primary"
                                disableElevation
                            >
                                Authentication
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default memo(CorrigoCredentialsForm);
