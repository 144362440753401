import type { FloorDetail } from '@infogrid/locations-types';
import { Divider } from '@material-ui/core';
import { memo } from 'react';

import { useSpace } from 'apiHooks/floorPlan/spaces/hooks';

import SpaceSidebarHeader from './SpaceSidebarHeader';
import SpaceSidebarSensors from './SpaceSidebarSensors';
import { useSpaceSidebarContentStyles } from './styles';

interface Props {
    buildingId: number;
    floor: FloorDetail;
    setSelectedSpaceId: (id: number | null) => void;
    spaceId: number;
}

const SpaceSidebarContent = ({
    buildingId,
    floor,
    spaceId,
    setSelectedSpaceId,
}: Props) => {
    const styles = useSpaceSidebarContentStyles();

    const { data: space } = useSpace(spaceId);

    return (
        <div className={styles.drawer} data-cypress="space-sidebar">
            <SpaceSidebarHeader space={space} setSelectedSpaceId={setSelectedSpaceId} />

            <Divider className={styles.divider} />
            <SpaceSidebarSensors
                buildingId={buildingId}
                floor={floor}
                spaceId={spaceId}
            />
        </div>
    );
};

export default memo(SpaceSidebarContent);
