import debounce from 'lodash/debounce';
import { useContext, useEffect, memo } from 'react';

import Context from './MapContext';
import type { MapContext } from './MapContext';

interface Props {
    rotation?: number;
    onChange?: (rotation: number) => void;
}

const Rotation = ({ rotation, onChange }: Props) => {
    const { map } = useContext<MapContext>(Context);

    useEffect(() => {
        if (!map) {
            return;
        }

        const mapView = map.getView();

        if (typeof rotation === 'number') {
            mapView.setRotation(rotation);
        }
    }, [map, rotation]);

    useEffect(() => {
        if (!map || !onChange) {
            return () => {};
        }

        const mapView = map.getView();

        const onMoveEnd = debounce(() => {
            onChange(mapView.getRotation());
        }, 250);

        mapView.on('change:rotation', onMoveEnd);

        return () => {
            mapView.un('change:rotation', onMoveEnd);
        };
    }, [map, onChange]);

    return null;
};

export default memo(Rotation);
