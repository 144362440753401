import { useAppSelector } from '@infogrid/core-ducks';
import type { WidgetProps } from '@infogrid/dashboards-constants';
import { calendarPeriods } from '@infogrid/dashboards-constants';
import { useDashboard, useUpdateWidget, useWidgetData } from '@infogrid/dashboards-hooks';
import { selectActiveTimeZone } from '@infogrid/user-ducks';
import { defaultRollingCalendarPeriods } from '@infogrid/utils-dates';
import { useIsMobile, useIsOpenState } from '@infogrid/utils-hooks';
import isNil from 'lodash/isNil';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import { MONITORING_TYPES_ALL_KEYS } from 'components/material-ui/PipeMonitoringTypeSelection/utils';
import { GenericActions, GenericTitle, Widget } from 'views/dashboards/components/Widget';
import { GenericFooter } from 'views/dashboards/components/Widget/GenericWidget';
import type { ConfigurableProps } from 'views/dashboards/components/Widget/GenericWidget/GenericActions/types';
import { useGenericWidgetContainerStyles } from 'views/dashboards/components/Widget/GenericWidget/styles';
import { DeleteWidgetModal } from 'views/dashboards/components/modals';
import { canChangeDashboard, hasUserPermissions } from 'views/dashboards/utils/helpers';
import { useConfigureWidgetModalOpenState } from 'views/dashboards/utils/hooks';

import ConfigureWidgetModal from './ConfigureWidgetModal/ConfigureWidgetModal';
import Content from './Content';
import { widgetConfigurationModalTabs } from './constants';
import { useWidgetStyles } from './styles';
import type {
    PipeMonitoringAtRiskWidgetType,
    WidgetSettings,
    PipeMonitoringRiskWidgetData,
} from './types';
import {
    validateConfiguration,
    prepareConfigurationPayload,
    getInitialConfiguration,
} from './utils';

const PipeMonitoringAtRiskWidget = ({
    canDragAndResize,
    layout,
    widget,
}: WidgetProps<PipeMonitoringAtRiskWidgetType>) => {
    const { t } = useTranslation('dashboard');
    const containerStyles = useGenericWidgetContainerStyles();
    const widgetStyles = useWidgetStyles();

    const { dashboardId } = useParams<{ dashboardId: string }>();
    const [timePeriodRange, setTimePeriodRange] = useState<string | undefined>(
        widget.configuration.time_period_range,
    );
    const timezone = useAppSelector(selectActiveTimeZone);

    const isMobile = useIsMobile();

    const { data: dashboard } = useDashboard(+dashboardId);

    const {
        handleConfigureWidgetModalClose,
        handleConfigureWidgetModalOpen,
        isConfigureWidgetModalOpen,
    } = useConfigureWidgetModalOpenState(widget.id, widget.type);

    const isHardRefreshData = useRef(false);

    const [
        deleteWidgetModalOpen,
        handleDeleteWidgetModalClose,
        handleDeleteWidgetModalOpen,
    ] = useIsOpenState();

    const { mutate: updateWidget } = useUpdateWidget<PipeMonitoringAtRiskWidgetType>();

    const isValidConfiguration = validateConfiguration(
        widget.configuration.calculators.pipe_monitoring_risk.locations,
    );

    const { ref, inView } = useInView({
        threshold: 0,
        rootMargin: '100px 0px',
        skip: false,
        initialInView: false,
        triggerOnce: true,
    });

    const {
        data: widgetData,
        isFetching: fetchingWidgetData,
        refetch: refetchWidgetData,
        isError: isLoadingWidgetDataError,
    } = useWidgetData<PipeMonitoringRiskWidgetData>(+dashboardId, widget.id, {
        getQueryParams: () => ({
            refresh: isHardRefreshData.current || undefined,
        }),
        options: {
            enabled: inView && isValidConfiguration,
            onSettled: () => {
                isHardRefreshData.current = false;
            },
        },
    });

    const onUpdateWidgetTitle = useCallback(
        (title: string) => {
            updateWidget({
                dashboardId: +dashboardId,
                widgetId: widget.id,
                data: { name: title },
            });
        },
        [dashboardId, updateWidget, widget.id],
    );

    const onUpdateConfiguration = useCallback(
        (values: WidgetSettings) => {
            updateWidget({
                dashboardId: +dashboardId,
                widgetId: widget.id,
                data: {
                    ...widget,
                    configuration: prepareConfigurationPayload(values),
                },
            });
            setTimePeriodRange(values.time_period_range);
        },
        [dashboardId, updateWidget, widget],
    );

    const configurableProps: ConfigurableProps = {
        onConfigure: handleConfigureWidgetModalOpen,
        onDelete: handleDeleteWidgetModalOpen,
        isDuplicateDisabled: true,
        isConfigureWidgetAvailable: true,
    };

    const widgetCardRef = useRef(null);

    const onRefresh = useCallback(() => {
        isHardRefreshData.current = true;
        refetchWidgetData();
    }, [refetchWidgetData]);

    const canUserEdit = useMemo(() => {
        if (!isNil(configurableProps.canUserEdit)) {
            return configurableProps.canUserEdit;
        }

        return (
            !!dashboard && hasUserPermissions(dashboard) && canChangeDashboard(dashboard)
        );
    }, [configurableProps.canUserEdit, dashboard]);

    const rollingPeriodLabel = useMemo(() => {
        return (
            defaultRollingCalendarPeriods.find(
                (periodItem) => periodItem.value === timePeriodRange,
            )?.label || t('unknown')
        );
    }, [t, timePeriodRange]);

    const pipeMonitoringFailureTypes =
        widget.configuration.calculators.pipe_monitoring_risk
            .pipe_monitoring_failure_types ?? MONITORING_TYPES_ALL_KEYS;

    return (
        <div ref={ref} className={containerStyles.container} data-cypress="widget">
            <Widget>
                <Widget.Bar
                    canDragAndResize={canDragAndResize}
                    className={widgetStyles.header}
                >
                    <GenericTitle
                        onUpdateTitle={onUpdateWidgetTitle}
                        title={widget.name}
                        titleEditable={true}
                        titleIcon={<i className="far fa-sink" />}
                    />
                    {!isMobile && (
                        <GenericActions
                            configurableProps={configurableProps}
                            refreshableProps={{
                                enabled: isValidConfiguration,
                                onRefresh,
                            }}
                            canUserEdit={canUserEdit}
                            widgetType={widget.type}
                            widgetName={widget.name}
                            dashboardId={dashboardId}
                            widgetConfiguration={widget.configuration}
                        />
                    )}
                </Widget.Bar>

                {inView && (
                    <Widget.Content
                        ref={widgetCardRef}
                        layout={layout}
                        className={widgetStyles.content}
                    >
                        <Content
                            pipeMonitoringFailureTypes={pipeMonitoringFailureTypes}
                            onUpdateConfiguration={onUpdateConfiguration}
                            widgetData={widgetData}
                            canUserEdit={canUserEdit}
                            isLoading={fetchingWidgetData}
                            isLoadingDataError={isLoadingWidgetDataError}
                            timePeriodRange={timePeriodRange ?? calendarPeriods.week}
                        />
                    </Widget.Content>
                )}

                <GenericFooter periodLabel={t(rollingPeriodLabel)} />

                {isConfigureWidgetModalOpen && (
                    <ConfigureWidgetModal
                        initialValues={getInitialConfiguration({
                            ...widget.configuration.calculators.pipe_monitoring_risk,
                            time_period_range: widget.configuration.time_period_range,
                            time_zone: widget.configuration.time_zone ?? timezone,
                        })}
                        loading={false}
                        onClose={handleConfigureWidgetModalClose}
                        onUpdateConfiguration={onUpdateConfiguration}
                        open={isConfigureWidgetModalOpen}
                        tabs={widgetConfigurationModalTabs}
                    />
                )}

                <DeleteWidgetModal
                    onClose={handleDeleteWidgetModalClose}
                    open={deleteWidgetModalOpen}
                    widgetName={widget.name}
                    dashboardId={dashboardId}
                    widgetId={widget.id}
                    widgetType={widget.type}
                />
            </Widget>
        </div>
    );
};

export default memo(PipeMonitoringAtRiskWidget);
