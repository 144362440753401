import type { SensorType } from '@infogrid/sensors-constants';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type { TFunction } from 'react-i18next';
import * as Yup from 'yup';

const roomValidationShape = {
    id: Yup.number(),
    name: Yup.string(),
};

const spaceValidationShape = {
    id: Yup.number(),
    name: Yup.string(),
};

const floorValidationShape = {
    id: Yup.number(),
    name: Yup.string(),
    rooms: Yup.array(Yup.object().shape(roomValidationShape)),
    spaces: Yup.array(Yup.object().shape(spaceValidationShape)),
};

const buildingValidationShape = {
    id: Yup.number(),
    name: Yup.string(),
    floors: Yup.array(Yup.object().shape(floorValidationShape)),
};

const labelValidationShape = {
    id: Yup.number(),
    name: Yup.string(),
    applied_to: Yup.string(),
    isUniq: Yup.boolean(),
};

/* eslint-disable react/forbid-prop-types */
export const configurationFormValidationSchema = (
    deviceType: SensorType,
    t: TFunction,
): Yup.ObjectSchema =>
    Yup.object().shape({
        building: Yup.object()
            .shape(buildingValidationShape)
            .nullable()
            .required()
            .label('Building'),
        floor: Yup.object()
            .shape(floorValidationShape)
            .nullable()
            .required()
            .label('Floor'),
        space: Yup.object()
            .shape(spaceValidationShape)
            .nullable()
            .required()
            .label('Space'),
        device_name: Yup.string().required().label('Device name'),
        sub_type:
            deviceType === SENSOR_TYPE.TYPE_LEGIONNAIRE
                ? Yup.string().required().label('Pipe type')
                : Yup.string().nullable(),
        temperature_offset: Yup.number()
            .nullable()
            .typeError(t('Offset must be a number', { ns: 'sensors' }))
            .test(
                'is-decimal',
                t(
                    'Please ensure the number has no more than 2 decimal places and no more than 5 digits total',
                    { ns: 'sensors' },
                ),
                (value) =>
                    value !== 0 && !value
                        ? true
                        : !!`${value}`.match(/^[+-]?\d{1,5}(?:\.\d{1,2})?$/),
            ),
        labels: Yup.array(Yup.object().shape(labelValidationShape)),
    });
/* eslint-enable react/forbid-prop-types */
