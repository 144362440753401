import type { AxiosParsedError } from '@infogrid/core-types';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type {
    PaginatedTreeChildNode,
    PaginatedTreeCurrentNode,
} from '../components/PaginatedTreeSelector/types';
import type { ActivatedDeactivedNodes } from '../types';
import { controllers } from './controller';
import {
    getSmartCleaningHierarchyKey,
    getSmartCleaningHierarchyLocationsKey,
} from './getQueryKeys';
import type {
    SmartCleaningHierarchyParams,
    SmartCleaningHierarchyResponse,
} from './types';

export const useGetSmartCleaningHierarchy = (
    params: SmartCleaningHierarchyParams,
    options?: UseQueryOptions<SmartCleaningHierarchyResponse, AxiosParsedError>,
): UseQueryResult<SmartCleaningHierarchyResponse, AxiosParsedError> => {
    return useQuery(
        getSmartCleaningHierarchyKey(params),
        () => controllers.getSmartCleaningHierarchy(params),
        options,
    );
};
export const useGetSmartCleaningHierarchyLocations = (
    params: SmartCleaningHierarchyParams,
    newlySelectedOrDeselectedNodes: ActivatedDeactivedNodes,
    options?: UseQueryOptions<
        PaginatedTreeCurrentNode<PaginatedTreeChildNode>,
        AxiosParsedError
    >,
): UseQueryResult<PaginatedTreeCurrentNode<PaginatedTreeChildNode>, AxiosParsedError> => {
    return useQuery(
        getSmartCleaningHierarchyLocationsKey(params),
        () =>
            controllers.getSmartCleaningHierarchyLocations(
                params,
                newlySelectedOrDeselectedNodes,
            ),
        options,
    );
};
