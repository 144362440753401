import { useRouteMatch } from 'react-router-dom';

interface FloorPlanParams {
    buildingId: number | null;
    floorId: number | null;
}

export const useFloorPlanParams = (): FloorPlanParams => {
    const match = useRouteMatch<{ buildingId: string; floorId: string }>([
        '/buildings/:buildingId/floors/:floorId',
        '/buildings/:buildingId',
    ]);

    return {
        buildingId: match?.params?.buildingId ? +match?.params?.buildingId : null,
        floorId: match?.params?.floorId ? +match?.params?.floorId : null,
    };
};
