import { routesManager } from '@infogrid/core-routing';
import type { Floor, BuildingDetail } from '@infogrid/locations-types';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { Button, Divider, Icon, Tooltip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo, useMemo, Fragment, useCallback } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import type { DropResult, OnDragEndResponder } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { useReorderFloor } from 'apiHooks/floorPlan/floors/hooks';
import { FloorList } from 'components/floors';
import FloorSearchInput from 'components/floors/FloorSearchInput/FloorSearchInput';
import { useTextField } from 'utils/hooks/useTextField';
import AddFloorModal from 'views/estate/components/modals/AddFloorModal';

import FloorRow from './FloorRow';
import { useFloorsListStyles } from './styles';

interface Props {
    activeFloorId?: number;
    building: BuildingDetail;
    floors: Floor[];
    onFloorClick?: () => void;
    showHeader?: boolean;
}

const FloorsList = ({
    activeFloorId,
    building,
    floors,
    onFloorClick,
    showHeader = true,
}: Props): JSX.Element => {
    const styles = useFloorsListStyles();

    const { t } = useTranslation('estate');

    const [floorsSearchQuery, handleSearch, clearSearch] = useTextField();

    const [isAddFloorModalOpen, _1, _2, toggleAddFloorModal] = useIsOpenState();

    const { mutate: reorderFloor } = useReorderFloor(building.id);

    const filteredFloors = useMemo(
        () =>
            floors.filter((floor) =>
                floor.name.toLowerCase().includes(floorsSearchQuery.toLowerCase()),
            ),
        [floors, floorsSearchQuery],
    );

    const handleDragEnd: OnDragEndResponder = useCallback(
        (result: DropResult) => {
            if (
                !Number.isInteger(result?.destination?.index) ||
                !Number.isInteger(result?.source?.index)
            ) {
                return;
            }

            const {
                draggableId: floorId,
                destination: { index: floorDestinationIndex } = {},
                source: { index: floorSourceIndex },
            } = result;

            if (floorDestinationIndex === floorSourceIndex) {
                return;
            }

            /* Remove after useReorderFloor is rewritten to TypeScript */
            /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
            /* @ts-ignore */
            reorderFloor({
                floorId,
                data: {
                    floorDestinationIndex,
                    floorSourceIndex,
                },
            });
        },
        [reorderFloor],
    );

    const listRenderer = useCallback(
        () =>
            floors?.length > 0 ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="floors-droppable">
                        {(provided) => (
                            <div
                                className={styles.floorsList}
                                ref={provided.innerRef}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...provided.droppableProps}
                            >
                                {filteredFloors.map((floor, index) => {
                                    const isActive = activeFloorId === floor.id;

                                    const isLast =
                                        index === floors.length - 1 && floors.length > 1;

                                    const showDivider =
                                        index !== floors.length - 1 && !isActive;

                                    return (
                                        <Fragment key={floor.id}>
                                            <FloorRow
                                                building={building}
                                                floor={floor}
                                                floorLink={routesManager.resolvePath(
                                                    'estate:estate-building-floor',
                                                    {
                                                        buildingId: building.id,
                                                        floorId: floor.id,
                                                    },
                                                )}
                                                index={index}
                                                isActive={isActive}
                                                isLast={isLast}
                                                onFloorClick={onFloorClick}
                                            />
                                            {showDivider && (
                                                <Divider className={styles.divider} />
                                            )}
                                        </Fragment>
                                    );
                                })}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <></>
            ),
        [
            activeFloorId,
            building,
            filteredFloors,
            floors.length,
            handleDragEnd,
            onFloorClick,
            styles,
        ],
    );

    return (
        <>
            {showHeader && (
                <>
                    <div className={styles.floorsListHeader} data-cypress="floor-summary">
                        <Typography className={styles.numberOfFloors} variant="subtitle1">
                            {t('{{count}} Floors', {
                                count: floors.length,
                                defaultValue___one: `${floors.length} Floor`,
                                defaultValue___other: `${floors.length} Floors`,
                            })}
                        </Typography>
                        <Tooltip
                            placement="top"
                            title={building.user_actions.edit?.reason || ''}
                        >
                            <div className={styles.addFloorButtonWrapper}>
                                <Button
                                    color="primary"
                                    data-cypress="add-floor"
                                    disabled={!building.user_actions.edit?.allowed}
                                    onClick={toggleAddFloorModal}
                                    size="small"
                                    variant="outlined"
                                >
                                    <Icon
                                        className={classNames(
                                            'fal fa-plus',
                                            styles.addFloorButtonIcon,
                                        )}
                                    />
                                    {t('Add floor')}
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                    <Divider className={styles.divider} />
                </>
            )}
            <FloorSearchInput
                className={styles.searchInput}
                data-cypress="search-floors"
                disabled={floors?.length === 0}
                onChange={handleSearch}
                value={floorsSearchQuery}
                clearSearch={clearSearch}
            />
            <FloorList
                hasFloors={!!floors.length}
                filteredFloors={filteredFloors}
                activeFloorId={activeFloorId}
                onFloorClick={onFloorClick}
                building={building}
                renderList={listRenderer}
            />
            {isAddFloorModalOpen && (
                <AddFloorModal
                    buildingId={building.id}
                    enableSaveAndAddAnother={Boolean(activeFloorId)}
                    onClose={toggleAddFloorModal}
                    open={isAddFloorModalOpen}
                />
            )}
        </>
    );
};

export default memo(FloorsList);
