import { makeStyles } from '@material-ui/core';

export const useEstateViewStyles = makeStyles((theme) => ({
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3, 2),
        },
    },
    divider: {
        margin: theme.spacing(3, 0),
    },
}));
