import { makeStyles } from '@material-ui/core';

export const useSpaceItemStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        minHeight: 40,
        justifyContent: 'space-between',
    },
    subcontainer: {
        alignItems: 'center',
        display: 'flex',
    },
    icon: {
        alignItems: 'center',
        color: theme.palette.common.gray6,
        display: 'flex',
        fontSize: 17,
        height: 19,
        justifyContent: 'center',
        width: 20,
    },
    lockIcon: {
        color: theme.palette.common.gray4,
        marginLeft: theme.spacing(1.2),
        marginRight: theme.spacing(1),
    },
    label: {
        lineHeight: '24px',
        marginLeft: theme.spacing(0.5),
    },
    sensorsCount: {
        minWidth: 30,
    },
    radioPlaceholder: {
        width: 37,
    },
}));
