import type { UseCubeOptions } from '@infogrid/core-cube';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAllBuildings } from 'apiHooks/floorPlan/buildings/hooks';
import {
    useAllBuildingScore,
    type QueryParams as BuildingScoreQueryParams,
    HEALTHY_BUILDING_PILLAR,
} from 'views/dashboards/apiHooks/widgets/hooks';
import { GenericWidgetContainer } from 'views/dashboards/components/Widget/GenericWidget';

import Content from './Content';
import Footer from './Footer';
import type { BuildingScoreInfoBase, HealthyBuildingScoreWidget } from './types';

interface Props {
    widget: HealthyBuildingScoreWidget;
}

const useHealthyBuildingWidgetData = (
    _dashboardId: number,
    widgetId: number,
    params: {
        options?: UseCubeOptions<BuildingScoreInfoBase>;
        getQueryParams?: () => BuildingScoreQueryParams;
    } = {},
) => {
    const { data, ...rest } = useAllBuildingScore(widgetId, params, [
        HEALTHY_BUILDING_PILLAR.AIR_QUALITY,
        HEALTHY_BUILDING_PILLAR.THERMAL_HEALTH,
    ]);

    return {
        data: data ? { all_buildings_score: data } : undefined,
        ...rest,
    };
};

const HealthyBuildingWidget = ({ widget, ...props }: Props) => {
    const { buildings, isLoading: isBuildingLoading } = useAllBuildings(
        {},
        {
            refetchOnMount: true,
        },
    );

    const { t } = useTranslation('dashboard');

    const titleProps = useMemo(
        () => ({
            titleIcon: <i className="far fa-heartbeat" />,
            helpInfo: t(
                'The proportion of time a building had healthy conditions for its occupants in the last 30 days. e.g. A score of 70% means that conditions were optimal roughly 70% of the time.',
            ),
        }),
        [t],
    );

    const queryParams = useMemo(() => {
        const { buildings_ids: buildingIds } = widget.configuration.healthy_building;
        return buildingIds?.length ? { building_ids: buildingIds } : {};
    }, [widget.configuration]);

    return (
        <GenericWidgetContainer
            actionsProps={{
                configurableProps: {
                    forceDuplicateEnabled: true,
                    isConfigureWidgetAvailable: false,
                },
                refreshableProps: {
                    enabled: true,
                },
                widgetConfiguration: widget.configuration,
                widgetName: widget.name,
                widgetType: widget.type,
            }}
            baseQueryParams={queryParams}
            configuration={{
                isEnabled: false,
            }}
            contentComponent={Content}
            footerComponent={Footer}
            footerProps={{
                // @ts-expect-error we have to update GenericWidgetContainer
                buildings,
                isBuildingLoading,
            }}
            titleProps={titleProps}
            useWidgetData={useHealthyBuildingWidgetData}
            widget={widget}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
    );
};

export default memo(HealthyBuildingWidget);
