import { ClickAwayListener, Collapse, Icon, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo, useCallback, useState } from 'react';

import { useActionMenuStyles } from './styles';

const MENU_ICON_NAME = 'fal fa-ellipsis-h';

type Toggle = (x: boolean) => void;

type HandlerParam = {
    onToggle: Toggle;
    opened: boolean;
};

type ChildrenParam = {
    onToggle: Toggle;
};

interface Props {
    children: (param: ChildrenParam) => ReactNode;
    dataCypress?: string;
    menuDataCypress?: string;
    handler?: (param: HandlerParam) => ReactNode;
    classes?: {
        menuContainer?: string;
        menuButton?: string;
        menuButtonIcon?: string;
        menuContent?: string;
    };
}

const DefaultClasses = {
    menuContainer: '',
    menuButton: '',
    menuButtonIcon: '',
    menuContent: '',
};

const ActionsMenu = ({
    children,
    classes = DefaultClasses,
    dataCypress = 'actions-menu',
    menuDataCypress = 'actions-menu-dropdown',
    handler,
}: Props) => {
    const [opened, setOpened] = useState(false);

    const toggleMenu = () => {
        setOpened((currState) => !currState);
    };

    const outsideClickHandler = useCallback(() => setOpened(false), [setOpened]);

    const onToggle = useCallback(
        (v) => {
            setOpened(!!v);
        },
        [setOpened],
    );

    const actionMenuStyles = useActionMenuStyles();

    const menuContainerClasses = classNames(
        actionMenuStyles.menuContainer,
        classes.menuContainer,
    );
    const menuButtonIconClasses = classNames(
        MENU_ICON_NAME,
        actionMenuStyles.menuButtonIcon,
        classes.menuButtonIcon,
    );
    const menuContentClasses = classNames(
        actionMenuStyles.menuContent,
        classes.menuContent,
    );
    const menuButtonClasses = classNames(actionMenuStyles.menuButton, classes.menuButton);

    return (
        <ClickAwayListener onClickAway={outsideClickHandler}>
            <section className={menuContainerClasses}>
                {handler ? (
                    handler({ onToggle, opened })
                ) : (
                    <IconButton
                        color="primary"
                        className={menuButtonClasses}
                        data-cypress={dataCypress}
                        onClick={toggleMenu}
                    >
                        <Icon color="primary" className={menuButtonIconClasses} />
                    </IconButton>
                )}
                <div className={menuContentClasses}>
                    <Collapse
                        className={actionMenuStyles.dropdown}
                        data-cypress={menuDataCypress}
                        in={opened}
                        timeout="auto"
                        unmountOnExit
                    >
                        {children({ onToggle })}
                    </Collapse>
                </div>
            </section>
        </ClickAwayListener>
    );
};

export default memo(ActionsMenu);
