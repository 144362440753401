import { makeStyles } from '@material-ui/core';

const ICON_COLOR = '#918D85';

export const useStylesBaseCard = makeStyles((theme) => ({
    floorPlanCard: {
        height: '400px',
        width: '400px',
        padding: '35px 32px',
    },

    floorPlanCardContainer: {
        display: 'flex',
        height: '100%',
        padding: '0',

        alignItems: 'center',
        justifyContent: 'center',

        '&:last-child': {
            padding: '0',
        },
    },

    floorPlanCardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    floorPlanCardText: {
        marginBottom: '24px',
        alignSelf: 'flex-start',
        color: theme.palette.common.gray1,
    },

    floorPlanCardTitle: {
        marginBottom: '24px',
    },

    floorPlanIcon: {
        marginBottom: '45px',
        opacity: '0.4',

        fontSize: '82px',
        lineHeight: '1.1em',
        color: ICON_COLOR,
        textAlign: 'center',
    },

    icon: {
        fontSize: '19px',
        lineHeight: '1.1em',
        marginRight: '10px',
    },
}));
