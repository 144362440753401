import { Modal } from '@infogrid/components-material-ui';
import { Button, Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteSpace } from 'apiHooks/floorPlan/spaces/hooks';

import { useDeleteSpaceModalStyles } from './styles';

interface Props {
    buildingId: number;
    floorId: number;
    onClose: () => void;
    onConfirm?: () => void;
    open: boolean;
    spaceId?: number;
}

const DeleteSpaceModal = ({
    buildingId,
    floorId,
    onClose,
    onConfirm,
    open,
    spaceId,
}: Props) => {
    const styles = useDeleteSpaceModalStyles();

    const { t } = useTranslation('estate');

    const { isLoading: isSubmitting, mutate: deleteSpace } = useDeleteSpace(buildingId, {
        floor: floorId,
    });

    const handleConfirm = () => {
        if (!spaceId) {
            return;
        }

        deleteSpace(
            { id: spaceId, floorId },
            {
                onSuccess() {
                    onClose();

                    if (onConfirm) {
                        onConfirm();
                    }
                },
            },
        );
    };

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Title>{t('Confirm delete space')}</Modal.Title>
            <Modal.Content className={styles.modalContent}>
                <Typography className={styles.message} variant="body1">
                    {t(
                        'Deleting a space removes it permanently and can’t be undone. Are you sure you want to delete this space?',
                    )}
                </Typography>
            </Modal.Content>
            <Modal.Actions justify="space-between">
                <Button
                    color="primary"
                    disabled={isSubmitting}
                    data-cypress="not-delete-space"
                    onClick={onClose}
                >
                    {t("No, don't delete")}
                </Button>
                <Button
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={handleConfirm}
                    type="submit"
                    variant="contained"
                    data-cypress="submit-delete-space"
                >
                    {t('Yes, delete space')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(DeleteSpaceModal);
