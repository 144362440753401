import { makeStyles } from '@material-ui/core';

export const useReportsStyles = makeStyles((theme) => ({
    topContainer: {
        borderBottom: `1px solid ${theme.palette.background.grey}`,
        paddingBottom: theme.spacing(4),
    },
    titleContainer: {
        marginBottom: theme.spacing(2),
    },
    buildingContainer: {
        flexGrow: 1,
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 220px)',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(3, 3.75),
        width: '100%',
        margin: '0 auto',

        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2.5, 2),
        },
    },
    description: {
        marginTop: theme.spacing(1),
        maxWidth: 700,
    },
    loader: {
        margin: 'auto',
        marginTop: theme.spacing(4),
    },
}));
