import { MOBILE_NAVBAR_HEIGHT } from '@infogrid/utils-constants';
import { makeStyles } from '@material-ui/core';

export const useMobileNavbarStyles = makeStyles({
    root: {
        overflow: 'scroll',
        height: '100%',
    },
    navbarContentWrapper: {
        width: '100%',
        zIndex: 3,

        height: MOBILE_NAVBAR_HEIGHT,

        position: 'sticky',
        top: 0,
    },
    sidebarWrapper: ({ navbarVisible, transition }) => ({
        width: 0,

        position: 'relative',
        left: navbarVisible ? 0 : '-100%',

        height: `calc(100vh - ${MOBILE_NAVBAR_HEIGHT}px)`,
        transition,

        zIndex: -1,
    }),
    backgroundOverlay: ({ navbarVisible, overlayTransition }) => ({
        position: 'absolute',

        backgroundColor: navbarVisible ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',

        height: '100vh',
        width: '100%',

        zIndex: -1,
        left: navbarVisible ? 0 : '-100%',

        transition: overlayTransition,
    }),
    logoContainer: {
        height: '100%',
    },
    logo: {
        borderRadius: '50%',
    },
    logoText: ({ navbarVisible }) => ({
        display: '-webkit-box',
        fontSize: '16px',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        lineHeight: '18px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'unset',
        width: navbarVisible ? 'calc(100vw - 120px)' : 0,
    }),
});
