import {
    LineAndColumnChart,
    buildHighchartsTooltip,
} from '@infogrid/components-highcharts';
import { sensorsColors, WidgetErrorMessages } from '@infogrid/dashboards-constants';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { generateChartData as generateProximityCountData } from 'views/dashboards/widgets/ProximityCountWidget/utils';
import {
    tooltipPointColorExtractor,
    tooltipPointNameExtractor,
    tooltipPointValueExtractor,
} from 'views/dashboards/widgets/TouchCountWidget/Content/utils';
import { generateChartData as generateTouchCountData } from 'views/dashboards/widgets/TouchCountWidget/utils';
import Tooltip from 'views/dashboards/widgets/common/Tooltip';
import {
    getAxisTimeFormatByDataFrequency,
    getTooltipTimeFormatByDataFrequency,
} from 'views/dashboards/widgets/utils';

import WidgetAlert from '../../common/WidgetAlert';

const Content = ({ data, widget, isConfigured, isLoadingDataError }) => {
    const { t } = useTranslation('dashboard');

    const {
        proximity_count_graph_rows,
        proximity_count_sensor_names,
        proximity_counter_graph_rows,
        proximity_counter_sensor_names,
        touch_count_graph_rows,
        touch_counter_graph_rows,
        touch_count_sensor_names,
        touch_counter_sensor_names,
        no_data: isEmptyChartData,
    } = data;

    const yAxes = useMemo(
        () => [
            {
                allowDecimals: false,
                title: {
                    enabled: true,
                    text: t('Touch events count'),
                },
            },
            {
                allowDecimals: false,
                title: {
                    enabled: true,
                    text: t('Door events count'),
                },
            },
        ],
        [t],
    );

    const {
        calculators: { touch_count, touch_counter } = {},
        data_frequency: dataFrequency,
        time_zone: timeZone,
        time_period_from: startDate,
        time_period_to: endDate,
    } = widget?.configuration || {};

    const touchCountData = useMemo(
        () =>
            generateTouchCountData({
                touch_count,
                touch_counter,
                touch_counter_graph_rows,
                touch_count_graph_rows,
                touch_count_sensor_names,
                touch_counter_sensor_names,
            }).map((entry) => ({ ...entry, type: 'column', yAxis: 0 })),
        [
            touch_count,
            touch_counter,
            touch_counter_graph_rows,
            touch_count_graph_rows,
            touch_count_sensor_names,
            touch_counter_sensor_names,
        ],
    );

    const proximityCountData = useMemo(() => {
        const proximityData = generateProximityCountData({
            proximity_count_graph_rows,
            proximity_count_sensor_names,
            proximity_counter_graph_rows,
            proximity_counter_sensor_names,
        });

        return [
            {
                name: t('Count of open events'),
                data: proximityData,
                type: 'spline',
                color: sensorsColors.blue.color,
                yAxis: 1,
            },
        ];
    }, [
        t,
        proximity_count_graph_rows,
        proximity_count_sensor_names,
        proximity_counter_graph_rows,
        proximity_counter_sensor_names,
    ]);

    const transformedData = useMemo(
        () => [...touchCountData, ...proximityCountData],
        [touchCountData, proximityCountData],
    );

    const chartProps = useMemo(() => {
        const timeFormat = getAxisTimeFormatByDataFrequency(
            dataFrequency,
            startDate,
            endDate,
        );
        const tooltipTimeFormat = getTooltipTimeFormatByDataFrequency(dataFrequency);

        return {
            xAxis: {
                labels: {
                    formatter({ chart }) {
                        // eslint-disable-next-line react/no-this-in-sfc
                        return chart.time.dateFormat(timeFormat, this.value);
                    },
                },
                startOnTick: false,
                type: 'datetime',
            },
            tooltip: {
                formatter({ chart }) {
                    return buildHighchartsTooltip({
                        content: (
                            <Tooltip
                                data={this}
                                pointColorExtractor={tooltipPointColorExtractor}
                                pointKeyExtractor={tooltipPointNameExtractor}
                                pointNameExtractor={tooltipPointNameExtractor}
                                pointValueExtractor={tooltipPointValueExtractor}
                            />
                        ),
                        periodLabel: chart.time.dateFormat(
                            tooltipTimeFormat,
                            // eslint-disable-next-line react/no-this-in-sfc
                            this.points[0].key,
                        ),
                    });
                },
            },
            time: {
                timezone: timeZone,
            },
            series: transformedData,
        };
    }, [dataFrequency, startDate, endDate, timeZone, transformedData]);

    if (!isConfigured) {
        return (
            <WidgetAlert severity="warning">
                {t(WidgetErrorMessages.notConfigured)}
            </WidgetAlert>
        );
    }

    if (isLoadingDataError) {
        return (
            <WidgetAlert severity="error">
                {t(WidgetErrorMessages.dataLoadError)}
            </WidgetAlert>
        );
    }

    if (isEmptyChartData) {
        return <WidgetAlert severity="info">{t(WidgetErrorMessages.noData)}</WidgetAlert>;
    }

    return (
        <LineAndColumnChart
            data={transformedData}
            chartProps={chartProps}
            yAxes={yAxes}
        />
    );
};

Content.propTypes = {
    data: PropTypes.shape({
        proximity_count_graph_rows: PropTypes.arrayOf(
            PropTypes.shape({
                timestamp: PropTypes.string,
            }),
        ),
        proximity_count_sensor_names: PropTypes.shape({}),
        proximity_counter_graph_rows: PropTypes.arrayOf(
            PropTypes.shape({
                timestamp: PropTypes.string,
            }),
        ),
        proximity_counter_sensor_names: PropTypes.shape({}),
        touch_count_graph_rows: PropTypes.arrayOf(PropTypes.shape({})),
        touch_counter_graph_rows: PropTypes.arrayOf(PropTypes.shape({})),
        touch_count_sensor_names: PropTypes.shape({}),
        touch_counter_sensor_names: PropTypes.shape({}),
        no_data: PropTypes.bool,
    }).isRequired,
    widget: PropTypes.shape({
        configuration: PropTypes.shape({
            calculators: PropTypes.shape({}),
            data_frequency: PropTypes.string,
            time_zone: PropTypes.string,
        }),
    }).isRequired,
    isConfigured: PropTypes.bool.isRequired,
    isLoadingDataError: PropTypes.bool.isRequired,
};

export default memo(Content);
