import ErrorComponent from 'components/ErrorComponent';
import LazyLoadErrorBoundary from 'components/LazyLoadErrorBoundary';
import NotFound from 'components/NotFound';
import View from 'components/View';
import type { ViewProps } from 'components/View';
import { onComponentError } from 'services/sentry';

const defaultOptions = {
    onComponentError,
    NotFoundComponent: NotFound,
    Fallback: ErrorComponent,
};

export interface TProps {
    [key: string]: unknown;
}

const withView =
    (options: ViewProps = defaultOptions) =>
    (
        // @ts-expect-error: this screams in places where `withView` is used because in those places we
        //  do know the actual props, but in here we don't.
        Component: (props) => JSX.Element,
    ) =>
    (props: TProps): JSX.Element =>
        (
            <View
                onComponentError={
                    options.onComponentError || defaultOptions.onComponentError
                }
                NotFoundComponent={
                    options.NotFoundComponent || defaultOptions.NotFoundComponent
                }
                Fallback={options.Fallback || defaultOptions.Fallback}
            >
                {/*
          View component adds a hard-coded error boundary around the view.
          But we want LazyLoadErrorBoundary to catch ChunkLoadError's before that.
          So we wrap the inner component with LazyLoadErrorBoundary.
        */}
                <LazyLoadErrorBoundary>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Component {...props} />
                </LazyLoadErrorBoundary>
            </View>
        );

export default withView;
