import { useState } from 'react';

import withView from 'decorators/withView';
import type { SmartCleaningHierarchyResponse } from 'views/solutionSettings/apiHooks/types';
import { useGetSmartCleaningHierarchy } from 'views/solutionSettings/apiHooks/useGetSmartCleaningHierarchy';

import type { LocationSelectionMethod } from '../../types';
import SelectLocations from './SelectLocations';

interface Props {
    hidden: boolean;
    setSelectedLocations: () => null;
    selectionMethod: LocationSelectionMethod;
    setSelectionMethod: (
        method: LocationSelectionMethod,
        locations?: SmartCleaningHierarchyResponse,
    ) => null;
}

const SelectLocationsContainer = ({
    hidden,
    setSelectedLocations,
    selectionMethod,
    setSelectionMethod,
}: Props) => {
    const [currentPageId] = useState<number>();

    const { data: locations, isLoading: locationsIsLoading } =
        useGetSmartCleaningHierarchy({
            rules: true,
            folder_id: currentPageId,
        });

    return (
        <SelectLocations
            hidden={hidden}
            locationsIsLoading={locationsIsLoading}
            locations={locations}
            setSelectedLocations={setSelectedLocations}
            selectionMethod={selectionMethod}
            setSelectionMethod={setSelectionMethod}
        />
    );
};

export default withView()(SelectLocationsContainer);
