/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

export const AlertLogShape = PropTypes.shape({
    alert_id: PropTypes.string,
    acknowledged_by: PropTypes.number,
    acknowledged_at: PropTypes.instanceOf(Date),
    acknowledgement_message: PropTypes.string,
    alert_configuration_application_uuid: PropTypes.string,
    profile_uuid: PropTypes.string,
    alert_end: PropTypes.instanceOf(Date),
    alert_start: PropTypes.instanceOf(Date),
});
