import { queryClient } from '@infogrid/core-api';
import { toastDefaultMeta } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { removeAuthTokens } from '@infogrid/user-cookies';
import { resetUser } from '@infogrid/user-ducks';
import * as Sentry from '@sentry/react';
import { createSaveAction } from '@thorgate/spa-forms';
import { getLocation, replace } from 'connected-react-router';
import qs from 'qs';
import { put, select, delay, takeLatest } from 'redux-saga/effects';

import { getTimezonesKey } from 'apiHooks/timezones/getQueryKeys';
import { closeSocket } from 'sagas/socket/actionTypes';

export const logout = createSaveAction('sagas/auth/LOGOUT', toastDefaultMeta);

/*
 Note: This logic should most likely be moved into the Logout view eventually.
 */
function* logoutWorker() {
    const location = yield select(getLocation);

    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const url = query.next || routesManager.resolvePath('auth:login');

    Sentry.setUser(null);
    removeAuthTokens();

    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('Logging out, next url:', url);
    }

    queryClient.removeQueries({
        predicate: (x) => x.queryKey !== getTimezonesKey(),
    });

    yield put(resetUser());
    yield put(closeSocket());
    yield delay(100); // TODO temporary fix, to be resolved with https://github.com/thorgate/tg-spa-utils/issues/92
    yield put(replace(url));
}

export function* logoutWatcher(match) {
    yield takeLatest(logout.getType(), logoutWorker, match);
}
