import { createRequest } from '@infogrid/core-api';
import type {
    SensorType,
    PartialElectricitySensorUpdate,
    SensorShape,
    PartialElectricityLEDPulseSensorUpdate,
} from '@infogrid/sensors-constants';
import type { AxiosPromise } from 'axios';
import pick from 'lodash/pick';

import type { VirtualSensorType } from '../hooks';

const updateSensorProfile = ({
    id,
    data,
}: {
    id: string;
    data: Partial<SensorShape>;
}): AxiosPromise<SensorShape> => {
    // make sure that only profile field will be updated
    const profile = pick(data, 'profile');

    return createRequest({
        method: 'patch',
        url: `sensors/${id}`,
        options: { data: profile },
    });
};

const updateSensorConfiguration = ({
    id,
    data,
}: {
    id: string;
    data: Partial<SensorShape>;
}): AxiosPromise<SensorShape> => {
    // make sure that only configuration field will be updated
    const sensorConfiguration = pick(data, 'sensor_configuration');

    return createRequest({
        method: 'patch',
        url: `sensors/${id}`,
        options: {
            data: { ...sensorConfiguration },
        },
    });
};

const updateElectricitySensorConfiguration = ({
    id,
    typeCode,
    data,
}: {
    id: string;
    typeCode: SensorType;
    data: PartialElectricitySensorUpdate | PartialElectricityLEDPulseSensorUpdate;
}): AxiosPromise<SensorShape> => {
    return createRequest({
        method: 'patch',
        url: `sensors/${id}`,
        options: {
            data: {
                sensor_configuration: {
                    ...data?.sensor_configuration,
                    sensor_configuration_type: typeCode,
                },
                profile: { ...data.profile },
            },
        },
    });
};

const updateSensorType = ({
    id,
    typeCode,
}: {
    id: string;
    typeCode: SensorType;
}): AxiosPromise<SensorShape> => {
    return createRequest({
        method: 'patch',
        url: `sensors/${id}`,
        options: { data: { type_code: typeCode } },
    });
};

const createVirtualSensor = (
    sensorType: VirtualSensorType,
    id: string,
): AxiosPromise<SensorShape> =>
    createRequest({
        method: 'post',
        url: `sensors/virtual/${sensorType}`,
        options: {
            data: { device_name: id },
            retry: false,
        },
    });

export const controllers = {
    updateSensorConfiguration,
    updateElectricitySensorConfiguration,
    updateSensorProfile,
    updateSensorType,
    createVirtualSensor,
};
