import { Modal } from '@infogrid/components-material-ui';
import type { EventsWebhook } from '@infogrid/core-types';
import type { SensorEventType } from '@infogrid/sensors-constants';
import { useIsMobile, useIsOpenState } from '@infogrid/utils-hooks';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import {
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import type { EventWebhookHeaderType } from 'apiHooks/eventsWebhook/constants';
import { EventWebhookHeaderTypes } from 'apiHooks/eventsWebhook/constants';
import type {
    CreateEventsWebhookPayload,
    EventsWebhooksConfigResult,
    UpdateEventsWebhook,
} from 'apiHooks/eventsWebhook/types';
import ButtonSpinner from 'components/ButtonSpinner/ButtonSpinner';

import { DeleteWebhookModal } from '../DeleteWebhookModal';
import { EventTypeSelector } from './EventTypeSelector';
import type { WebhookFormMode } from './constants';
import { WEBHOOK_FORM_MODE, WEBHOOK_HEADER_TYPE } from './constants';
import { useWebhookFormModalStyles } from './styles';

interface WebhookFormModalProps {
    mode: WebhookFormMode;
    open: boolean;
    onClose: () => void;
    onSubmit: (
        values: CreateEventsWebhookPayload | UpdateEventsWebhook,
        resetForm: () => void,
    ) => void;
    deleteWebhook: () => void;
    webhookConfig: EventsWebhooksConfigResult;
    isSubmitting: boolean;
    existingWebhook?: EventsWebhook;
}

interface MutateWebhookFormValues {
    name: string;
    url: string;
    eventTypes: SensorEventType[];
    authMethod: EventWebhookHeaderType;
}

const WEBHOOK_FORM_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required(
        registerTranslationKey('Give the webhook a name for future reference', {
            ns: 'integrations',
        }),
    ),
    url: Yup.string()
        .url(
            registerTranslationKey('Enter a valid URL, including the protocol', {
                ns: 'integrations',
            }),
        )
        .required(
            registerTranslationKey('This field may not be blank.', {
                ns: 'integrations',
            }),
        ),
    eventTypes: Yup.array<string>().required(
        registerTranslationKey('At least one event type must be selected.', {
            ns: 'integrations',
        }),
    ),
});

export const WebhookFormModal = ({
    mode,
    open,
    onClose,
    onSubmit,
    deleteWebhook,
    webhookConfig,
    isSubmitting,
    existingWebhook,
}: WebhookFormModalProps) => {
    const { t } = useTranslation('integrations');
    const styles = useWebhookFormModalStyles();
    const isMobile = useIsMobile();
    const handleClose = useCallback(
        (resetForm: () => void, reason?: 'escapeKeyDown' | 'backdropClick') => {
            if (reason === 'backdropClick') {
                return;
            }

            onClose();
            resetForm();
        },
        [onClose],
    );
    const [showDeleteModal, closeDeleteModal, openDeleteModal] = useIsOpenState(false);

    const mutate = (
        values: MutateWebhookFormValues,
        { resetForm }: FormikHelpers<MutateWebhookFormValues>,
    ) => {
        const mutateValues = {
            name: values.name,
            url: values.url,
            // event_types = [] denotes we don't want to filter by event type, so give us everything
            event_types:
                values.eventTypes.length === webhookConfig.allowed_events.length
                    ? []
                    : values.eventTypes,
            is_enabled: existingWebhook ? existingWebhook.is_enabled : true,
            send_signature: values.authMethod === EventWebhookHeaderTypes.SIGNATURE,
            organization_id: webhookConfig.org_id,
        };

        onSubmit(mutateValues, resetForm);
    };

    const getInitialValues = () => {
        if (mode === WEBHOOK_FORM_MODE.EDIT && existingWebhook) {
            const getEventTypesFromExistingWebhook = (): SensorEventType[] => {
                if (existingWebhook.event_types.length === 0) {
                    return webhookConfig.allowed_events as SensorEventType[];
                }

                return existingWebhook.event_types as SensorEventType[];
            };

            return {
                name: existingWebhook.name || '',
                url: existingWebhook.url,
                eventTypes: getEventTypesFromExistingWebhook(),
                authMethod: existingWebhook.send_signature
                    ? WEBHOOK_HEADER_TYPE.SIGNATURE
                    : WEBHOOK_HEADER_TYPE.KEY,
            };
        }

        return {
            name: '',
            url: 'https://',
            eventTypes: [] as SensorEventType[],
            authMethod: WEBHOOK_HEADER_TYPE.KEY,
        };
    };

    const cancelDeleteModal = () => {
        onClose();
        closeDeleteModal(); // need to close the delete modal afterwards or the form will pop up briefly.
    };

    const confirmDeleteModal = () => {
        onClose();
        deleteWebhook();
        closeDeleteModal();
    };

    return (
        <Formik<MutateWebhookFormValues>
            initialValues={getInitialValues()}
            onSubmit={mutate}
            enableReinitialize
            validateOnBlur={false}
            validationSchema={WEBHOOK_FORM_VALIDATION_SCHEMA}
        >
            {({
                values,
                handleChange,
                setFieldValue,
                handleSubmit,
                handleBlur,
                errors,
                touched,
                resetForm,
            }) => (
                <Modal
                    classes={{ closeIconButton: styles.titleCloseButton }}
                    onClose={(_e, reason) => handleClose(resetForm, reason)}
                    open={open && !showDeleteModal}
                    fullScreen={isMobile}
                    maxWidth="sm"
                    fullWidth
                    keepMounted
                >
                    {showDeleteModal && (
                        <DeleteWebhookModal
                            loading={false}
                            onClose={cancelDeleteModal}
                            onConfirm={confirmDeleteModal}
                        />
                    )}
                    <Modal.Title className={styles.titleContainer}>
                        {mode === WEBHOOK_FORM_MODE.CREATE
                            ? t('Add a new webhook')
                            : t('Edit webhook')}
                    </Modal.Title>
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <Modal.Content className={styles.content}>
                            <section className={styles.formSection}>
                                <Typography className={styles.title} variant="body1">
                                    {t('Webhook Details')}
                                </Typography>
                                <TextField
                                    data-testid="webhook-name"
                                    label={t('Name of webhook')}
                                    id="name"
                                    variant="outlined"
                                    value={values.name}
                                    inputProps={{
                                        'data-cypress': 'events-webhook-filterable-name',
                                        'data-testid': 'events-webhook-filterable-name',
                                    }}
                                    error={Boolean(errors.name && touched.name)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={
                                        errors.name && touched.name && t(errors.name)
                                    }
                                />
                                <TextField
                                    data-testid="webhook-url"
                                    id="url"
                                    label={t('Webhook URL')}
                                    variant="outlined"
                                    value={values.url}
                                    inputProps={{
                                        'data-cypress': 'events-webhook-filterable-url',
                                        'data-testid': 'events-webhook-filterable-url',
                                    }}
                                    error={Boolean(errors.url && touched.url)}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={
                                        errors.url && touched.url && t(errors.url)
                                    }
                                />
                            </section>

                            <section className={styles.formSection}>
                                <div>
                                    <Typography className={styles.title} variant="body1">
                                        {t('Event types')}
                                    </Typography>
                                    <Typography
                                        className={styles.description}
                                        variant="body2"
                                    >
                                        {t(
                                            'Select the event types to be sent to the webhook URL.',
                                        )}
                                    </Typography>
                                </div>
                                <EventTypeSelector
                                    allEventTypes={
                                        webhookConfig.allowed_events as SensorEventType[]
                                    }
                                    eventTypesSelected={values.eventTypes}
                                    error={
                                        errors.eventTypes && touched.eventTypes
                                            ? errors.eventTypes
                                            : undefined
                                    }
                                    setFieldValue={setFieldValue}
                                />
                            </section>
                            {mode === WEBHOOK_FORM_MODE.CREATE && (
                                <section className={styles.formSection}>
                                    <div>
                                        <Typography
                                            className={styles.title}
                                            variant="body1"
                                        >
                                            {t('Authentication method')}
                                        </Typography>
                                        <Typography
                                            className={styles.description}
                                            variant="body2"
                                        >
                                            {t(
                                                'Select your preferred method for authentication.',
                                            )}
                                        </Typography>
                                    </div>
                                    <RadioGroup
                                        aria-labelledby="radio-buttons-auth-method"
                                        value={values.authMethod}
                                        name="authMethod"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            className={styles.radioButtonControl}
                                            value="key"
                                            control={<Radio color="primary" />}
                                            label={
                                                <div className={styles.radioButtonLabel}>
                                                    <Typography>
                                                        {t('Send key in headers')}
                                                    </Typography>
                                                    <Typography
                                                        className={styles.description}
                                                        variant="body2"
                                                    >
                                                        {t(
                                                            'The secret key will be included in the headers we send to you (X-Infogrid-Secret-Key). Make sure the key we send in the headers matches your copy of the secret key.',
                                                        )}
                                                    </Typography>
                                                </div>
                                            }
                                            data-cypress="events-webhook-filterable-auth-method-key-radio"
                                            data-testid="events-webhook-filterable-auth-method-key-radio"
                                        />
                                        <FormControlLabel
                                            className={styles.radioButtonControl}
                                            value="signature"
                                            control={<Radio color="primary" />}
                                            label={
                                                <div className={styles.radioButtonLabel}>
                                                    <Typography>
                                                        {t('Send signature in headers')}
                                                    </Typography>
                                                    <Typography
                                                        className={styles.description}
                                                        variant="body2"
                                                    >
                                                        {t(
                                                            'The headers we send to you will include an HMAC signature (X-Infogrid-Signature) and the hash algorithm used to generate it (X-Infogrid-Signature-Algorithm). You will need to verify the signature using your local copy of the secret key.',
                                                        )}
                                                    </Typography>
                                                </div>
                                            }
                                            data-cypress="events-webhook-filterable-auth-method-signature-radio"
                                            data-testid="events-webhook-filterable-auth-method-signature-radio"
                                        />
                                    </RadioGroup>
                                </section>
                            )}
                        </Modal.Content>

                        <Modal.Actions>
                            <div className={styles.actionGroup}>
                                <div className={styles.buttonGroup}>
                                    <Button
                                        onClick={() => handleClose(resetForm)}
                                        color="primary"
                                    >
                                        {t('Cancel')}
                                    </Button>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        disabled={isSubmitting}
                                        data-cypress="submit-webhook"
                                        data-testid="submit-webhook"
                                    >
                                        {isSubmitting ? <ButtonSpinner /> : t('Save')}
                                    </Button>
                                </div>
                                {mode === WEBHOOK_FORM_MODE.EDIT && (
                                    <Button
                                        onClick={() => openDeleteModal()}
                                        color="secondary"
                                        data-cypress="delete-webhook"
                                        data-testid="delete-webhook"
                                    >
                                        {t('Delete')}
                                    </Button>
                                )}
                            </div>
                        </Modal.Actions>
                    </form>
                </Modal>
            )}
        </Formik>
    );
};
