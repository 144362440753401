import { isNumber } from 'is-what';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useEditMapState } from 'apiHooks/floorPlan/floors/hooks';
import { prettifyCoordinate } from 'utils/math';

const DEFAULT_STATE = { orientation: null, zoom: null };
const MAP_STATE_TIMEOUT = 100;

export const useInitializeMapState = ({ floorId }: { floorId: number }): void => {
    const [mapState, setMapState] = useState({ ...DEFAULT_STATE, floorId });
    const [debouncedState] = useDebounce(mapState, MAP_STATE_TIMEOUT);
    const { editMapState } = useEditMapState({
        floorId,
    });

    useEffect(() => {
        const { orientation, zoom, floorId: stateFloorId } = debouncedState;

        if (floorId !== stateFloorId) {
            setMapState({ ...DEFAULT_STATE, floorId });

            return;
        }

        if (orientation === null && zoom === null) {
            return;
        }

        const preparedOrientation = prettifyCoordinate(orientation, 6);
        const preparedZoom = prettifyCoordinate(zoom, 6);

        const data = {
            ...(isNumber(preparedOrientation) && { orientation: preparedOrientation }),
            ...(isNumber(preparedZoom) && { zoom: preparedZoom }),
        };

        // TODO will probably have to migrated this to TS
        editMapState({
            floorId,
            data,
        } as unknown as void);
    }, [debouncedState, floorId, editMapState]);
};
