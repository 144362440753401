import { queryClient, wrapResponsePromise } from '@infogrid/core-api';
import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getTimezonesKey } from '../getQueryKeys';

const queryParams = {
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnMount: false,
};
const queryFn = ({ signal }) =>
    wrapResponsePromise(controllers.getTimezones(signal), ({ data }) =>
        (data ?? []).map((t) => t.name),
    );

// deprecated, use ts version instead
export const useTimezones = (options = {}) => {
    return useQuery(getTimezonesKey(), queryFn, {
        ...queryParams,
        select: useCallback((data) => {
            return data ?? [];
        }, []),
        ...options,
    });
};

export const prefetchTimezones = () => {
    queryClient.prefetchQuery(getTimezonesKey(), queryFn, queryParams);
};
