import { MemoizedDivider } from '@infogrid/components-material-ui';
import { openClosedOptions } from '@infogrid/dashboards-constants';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Container from 'views/dashboards/widgets/common/DisplayOptions/Container';
import DataFrequency from 'views/dashboards/widgets/common/DisplayOptions/DataFrequency';
import SensorsColors from 'views/dashboards/widgets/common/DisplayOptions/SensorsColors';

import { useDisplayOptionsStyles } from './styles';

const countEventsOptions = [openClosedOptions.open, openClosedOptions.closed];

const DisplayOptions = ({
    calculateDisabledFrequencies,
    colorSelectableTypes,
    formik,
    frequencies,
}) => {
    const { t } = useTranslation('dashboard');
    const styles = useDisplayOptionsStyles();

    const {
        dateRange: { startDate, endDate } = {},
        displayOptions: { countEventsWhenSensor, dataFrequency, sensorsAttributes } = {},
        sensors = [],
        sensorsData = {},
    } = formik?.values || {};

    const disabledFrequencies = useMemo(
        () => calculateDisabledFrequencies({ startDate, endDate }),
        [calculateDisabledFrequencies, startDate, endDate],
    );

    return (
        <Container>
            <DataFrequency
                className={styles.dataFrequency}
                disabledFrequencies={disabledFrequencies}
                frequencies={frequencies}
                handleChange={formik.handleChange}
                value={dataFrequency}
            />
            <MemoizedDivider />
            <SensorsColors
                colorSelectableTypes={colorSelectableTypes}
                handleChange={formik.handleChange}
                sensors={sensors}
                sensorsData={sensorsData}
                sensorsAttributes={sensorsAttributes}
            />
            <MemoizedDivider />
            <div className={styles.wrapper1}>
                <Typography variant="subtitle1">
                    {t('Count events when proximity sensor is')}
                </Typography>
                <FormControl size="small" variant="outlined">
                    <Select
                        className={styles.countEventsSelect}
                        id="count-events-when-proximity-sensor"
                        inputProps={{
                            'data-testid': 'count-events-when-sensor',
                        }}
                        name="displayOptions.countEventsWhenSensor"
                        onChange={formik.handleChange}
                        variant="outlined"
                        value={countEventsWhenSensor}
                    >
                        {countEventsOptions.map((option) => (
                            <MenuItem
                                key={option.value}
                                value={option.value}
                                data-cypress="count-events"
                            >
                                {t(option.label)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </Container>
    );
};

DisplayOptions.propTypes = {
    calculateDisabledFrequencies: PropTypes.func,
    colorSelectableTypes: PropTypes.arrayOf(PropTypes.string),
    formik: PropTypes.shape({
        handleChange: PropTypes.func.isRequired,
        values: PropTypes.shape({
            displayOptions: PropTypes.shape({
                countEventsWhenSensor: PropTypes.bool,
                dataFrequency: PropTypes.string,
                sensorsAttributes: PropTypes.shape({}),
            }),
            sensors: PropTypes.arrayOf(PropTypes.string),
        }),
    }).isRequired,
    frequencies: PropTypes.arrayOf(PropTypes.shape({})),
};

DisplayOptions.defaultProps = {
    calculateDisabledFrequencies: () => [],
    colorSelectableTypes: [],
    frequencies: [],
};

export default memo(DisplayOptions);
