export const SEND_MESSAGE = 'application/socket/SEND';

export const RECV_MESSAGE = 'application/socket/RECV';

export const CLOSED = 'application/socket/CLOSED';

export const CLOSE = 'application/socket/CLOSE';

export const recvSocketMessage = (response) => ({
    type: RECV_MESSAGE,
    response,
});

export const sendSocketMessage = (stream, payload, requestId) => ({
    type: SEND_MESSAGE,
    stream,
    payload,
    requestId,
});

export const closeSocket = () => ({ type: CLOSE });
