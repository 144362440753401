import { makeStyles } from '@material-ui/core';

export const useFooterStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(0.5, 3),
        minHeight: 41,
    },
    selector: {
        maxWidth: 150,
        fontSize: 12,
    },
}));
