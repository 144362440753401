import classNames from 'classnames';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CellMeasurerCache, Grid } from 'react-virtualized';

import type { TableContentProps } from '../../constants';
import { defaultCellMeasurerCache } from '../../constants';
import TableCell from '../TableCell';
import TableHeaderCell from '../TableHeaderCell';
import { useTableContentStyles } from './styles';

const DEFAULT_HANDLER = () => null;

const TableContent = <Value,>({
    data,
    columns,
    headerRef,
    columnWidthMeasurer,
    autosizerProps,
    emptyStateRenderer,
    gridRef,
    rowHeight,
    scrollLeft,
    onScroll,
    columnCount,
    cellMeasurerCache,
    onRowsRendered,
    cellPadding,
    leftPadding = 0,
    onScrollbarPresenceChange,
    isVerticalScrollPresent,
    selectedItemIdx,
    onCellClick = DEFAULT_HANDLER,
    onEnterClick = DEFAULT_HANDLER,
    headerMinHeight,
    variant,
    gridInstance,
}: TableContentProps<Value>) => {
    const classes = useTableContentStyles({ cellPadding, leftPadding, variant });
    const headerClasses = useMemo(
        () =>
            classNames(classes.header, {
                [classes.scrollableHeader]: isVerticalScrollPresent,
            }),
        [isVerticalScrollPresent, classes.header, classes.scrollableHeader],
    );

    const [isTableFocused, setIsTableFocused] = useState(false);
    const [headerRendererCache] = useState(
        new CellMeasurerCache({ ...defaultCellMeasurerCache }),
    );

    const getHeaderRowHeight = useCallback(
        (row) => {
            const height = headerRendererCache.rowHeight(row);

            return height < headerMinHeight ? headerMinHeight : height;
        },
        [headerRendererCache, headerMinHeight],
    );

    const [headerHeight, setHeaderHeight] = useState(getHeaderRowHeight({ index: 0 }));

    const onHeaderSectionRendered = useCallback(() => {
        setHeaderHeight(getHeaderRowHeight({ index: 0 }));
    }, [getHeaderRowHeight]);

    // Callback invoked with information about the section of the Grid that was just rendered.
    // This callback is only invoked when visible rows have changed
    const onSectionRendered = useCallback(
        ({ columnStartIndex, columnStopIndex, rowStartIndex, rowStopIndex }) => {
            const startIndex = rowStartIndex * columns.length + columnStartIndex;
            const stopIndex = rowStopIndex * columns.length + columnStopIndex;

            onRowsRendered({
                startIndex,
                stopIndex,
            });
        },
        [columns, onRowsRendered],
    );

    const getCellClasses = useCallback(
        ({ rowIndex, columnIndex }, isHeaderCell) => {
            const shouldCellBeShaded =
                variant === 'striped' && !isHeaderCell && rowIndex % 2 === 0;

            return classNames(classes.cell, {
                [classes.selectedCell]: selectedItemIdx === rowIndex && !isHeaderCell,
                [classes.firstCellOfSelectedRow]:
                    selectedItemIdx === rowIndex && columnIndex === 0 && !isHeaderCell,
                [classes.cellWithLeftPadding]: leftPadding && columnIndex === 0,
                [classes.cellWithRegularPadding]: !leftPadding || columnIndex !== 0,
                [classes.shadedCell]: shouldCellBeShaded,
            });
        },
        [
            variant,
            classes.cell,
            classes.selectedCell,
            classes.firstCellOfSelectedRow,
            classes.cellWithLeftPadding,
            classes.cellWithRegularPadding,
            classes.shadedCell,
            selectedItemIdx,
            leftPadding,
        ],
    );

    useEffect(() => {
        if (isTableFocused) {
            gridInstance?.scrollToCell({ rowIndex: selectedItemIdx });
        }
    }, [data, gridInstance, selectedItemIdx, isTableFocused]);

    // scroll to the 1st item when selection has been reset
    useEffect(() => {
        if (typeof selectedItemIdx === 'number' && selectedItemIdx >= 0) {
            gridInstance?.scrollToCell({ rowIndex: selectedItemIdx });
        }
    }, [selectedItemIdx, gridInstance]);

    const containerProps = useMemo(
        () => ({
            onKeyUp: (e: KeyboardEvent) => {
                setIsTableFocused(true);

                const target = e.target as HTMLElement;

                if (target.tagName === 'INPUT') {
                    return;
                }

                switch (e.key) {
                    case 'ArrowDown': {
                        e.preventDefault();

                        if (selectedItemIdx == null) {
                            return;
                        }

                        if (selectedItemIdx < data.length - 1) {
                            onCellClick(selectedItemIdx + 1);
                        }

                        break;
                    }
                    case 'ArrowUp': {
                        e.preventDefault();

                        if (selectedItemIdx == null) {
                            return;
                        }

                        if (selectedItemIdx > 0) {
                            onCellClick(selectedItemIdx - 1);
                        }

                        break;
                    }
                    case 'Enter': {
                        if (selectedItemIdx == null) {
                            return;
                        }

                        e.preventDefault();
                        onEnterClick(data[selectedItemIdx]);
                        break;
                    }
                    default: {
                        break;
                    }
                }
            },
            onBlur: () => {
                setIsTableFocused(false);
            },
        }),
        [onCellClick, data, selectedItemIdx, onEnterClick],
    );

    const commonProps = {
        columns,
        columnWidth: columnWidthMeasurer,
        width: autosizerProps.width,
        classes,
        getCellClasses,
        scrollLeft,
        onScroll,
        columnCount,
    };

    return (
        <>
            <Grid
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...commonProps}
                ref={headerRef}
                height={headerHeight}
                rowCount={1}
                cellRenderer={TableHeaderCell}
                deferredMeasurementCache={headerRendererCache}
                className={classNames(headerClasses, classes.grid)}
                rowHeight={getHeaderRowHeight}
                onSectionRendered={onHeaderSectionRendered}
            />
            <Grid
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...commonProps}
                ref={gridRef}
                onScrollbarPresenceChange={onScrollbarPresenceChange}
                height={autosizerProps.height - headerHeight}
                deferredMeasurementCache={cellMeasurerCache}
                rowCount={data.length}
                cellRenderer={TableCell}
                onSectionRendered={onSectionRendered}
                noContentRenderer={emptyStateRenderer}
                rowHeight={rowHeight}
                data={data}
                onCellClick={onCellClick}
                className={classes.grid}
                containerProps={containerProps}
                scrollToAlignment="center"
            />
        </>
    );
};

export default memo(TableContent) as typeof TableContent;
