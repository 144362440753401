import { Checkbox, Icon, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { useFoldersPickerStyles } from '../styles';

const FOLDER_ICON = 'fa fa-folder';
const FLOOR_ICON = 'far fa-layer-group';

const Folder = (props) => {
    const { active, id, isFloor, name, onSelect } = props;

    const styles = useFoldersPickerStyles({ active });

    const folderIconClassName = classNames(
        active || isFloor ? FLOOR_ICON : FOLDER_ICON,
        styles.folderIcon,
    );

    const handleFolderSelect = () => {
        onSelect(id);
    };

    return (
        <div className={styles.folderContainer}>
            <div className={styles.folderInnerWrapper}>
                <Icon className={folderIconClassName} />
                <Typography
                    className={styles.folderLabel}
                    variant="subtitle1"
                    data-cypress="folder-floor"
                >
                    {name}
                </Typography>
            </div>
            {!isFloor && (
                <Checkbox
                    className={styles.folderCheck}
                    checked={active}
                    color="primary"
                    data-cypress="add-folder-checkbox"
                    onChange={handleFolderSelect}
                    size="small"
                />
            )}
        </div>
    );
};

Folder.propTypes = {
    active: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    isFloor: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
};

Folder.defaultProps = {
    isFloor: false,
    onSelect() {},
};

export default memo(Folder);
