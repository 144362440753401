import { theme } from '@infogrid/components-material-ui';
import type { SensorType } from '@infogrid/sensors-constants';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import {
    AirQualityIcon,
    AirThingsHubIcon,
    CleaningConfirmationIcon,
    ColdStorageIcon,
    CountingDoorIcon,
    CountingTouchIcon,
    DTHubIcon,
    DeskIcon,
    DoorIcon,
    ElectricityIcon,
    EmergencyLightIcon,
    FeedbackIcon,
    FludiaHubIcon,
    HumidityIcon,
    MonnitHubIcon,
    PeopleIcon,
    PipeMonitorIcon,
    PresenceIcon,
    SiluxHubIcon,
    SmartSpaceIcon,
    SmartSpaceMaxIcon,
    SpaceIcon,
    TemperatureAverageIcon,
    TemperatureIcon,
    TouchIcon,
    WaterDetectionIcon,
    UrbanHubIcon,
} from '@infogrid/sensors-icons';
import { lighten } from '@material-ui/core/styles';
import type { FC, SVGProps } from 'react';

// Map from sensor type to icon component
export const SENSOR_TYPE_ICONS: Record<SensorType, FC<SVGProps<SVGSVGElement>>> = {
    [SENSOR_TYPE.TYPE_AIR_QUALITY]: AirQualityIcon,
    [SENSOR_TYPE.TYPE_AIRTHINGS_HUB]: AirThingsHubIcon,
    [SENSOR_TYPE.TYPE_AVERAGE_TEMPERATURE]: TemperatureAverageIcon,
    [SENSOR_TYPE.TYPE_CCON]: DTHubIcon,
    [SENSOR_TYPE.TYPE_COLD_STORAGE]: ColdStorageIcon,
    [SENSOR_TYPE.TYPE_DESK_OCCUPANCY]: DeskIcon,
    [SENSOR_TYPE.TYPE_ELECTRICITY_CT_CLAMP]: ElectricityIcon,
    [SENSOR_TYPE.TYPE_ELECTRICITY_DRY_PULSE_COUNTER]: ElectricityIcon,
    [SENSOR_TYPE.TYPE_ELECTRICITY_LED_PULSE_METER]: ElectricityIcon,
    [SENSOR_TYPE.TYPE_ELECTRICITY_METER]: ElectricityIcon,
    [SENSOR_TYPE.TYPE_EMERGENCY_LIGHT]: EmergencyLightIcon,
    [SENSOR_TYPE.TYPE_FEEDBACK_PANEL]: FeedbackIcon,
    [SENSOR_TYPE.TYPE_FLUDIA_HUB]: FludiaHubIcon,
    [SENSOR_TYPE.TYPE_GAS_METER]: ElectricityIcon,
    [SENSOR_TYPE.TYPE_HUMIDITY]: HumidityIcon,
    [SENSOR_TYPE.TYPE_LEGIONNAIRE]: PipeMonitorIcon,
    [SENSOR_TYPE.TYPE_MONNIT_HUB]: MonnitHubIcon,
    [SENSOR_TYPE.TYPE_PRESENCE]: PresenceIcon,
    [SENSOR_TYPE.TYPE_PROXIMITY_COUNTER]: CountingDoorIcon,
    [SENSOR_TYPE.TYPE_PROXIMITY]: DoorIcon,
    [SENSOR_TYPE.TYPE_SIGMA]: PeopleIcon,
    [SENSOR_TYPE.TYPE_SILUX_HUB]: SiluxHubIcon,
    [SENSOR_TYPE.TYPE_SMART_SPACE]: SmartSpaceIcon,
    [SENSOR_TYPE.TYPE_SMART_SPACE_MAX]: SmartSpaceMaxIcon,
    [SENSOR_TYPE.TYPE_SPACE]: SpaceIcon,
    [SENSOR_TYPE.TYPE_TEMPERATURE]: TemperatureIcon,
    [SENSOR_TYPE.TYPE_TOUCH_COUNTER]: CountingTouchIcon,
    [SENSOR_TYPE.TYPE_TOUCH]: TouchIcon,
    [SENSOR_TYPE.TYPE_CLEANING_CONFIRMATION]: CleaningConfirmationIcon,
    [SENSOR_TYPE.TYPE_WATER_DETECTOR]: WaterDetectionIcon,
    [SENSOR_TYPE.TYPE_URBAN_HUB]: UrbanHubIcon,
};

export type SensorIconSize = 'xs' | 'small' | 'medium' | 'large';
export type SensorIconState = 'default' | 'hover' | 'selected';
export type SensorIconVariant =
    | 'blue'
    | 'green'
    | 'amber'
    | 'red'
    | 'slate'
    | 'planned'
    | 'offline';

export const SENSOR_ICON_SIZES: Record<SensorIconSize, number> = {
    xs: 24,
    small: 32,
    medium: 40,
    large: 48,
};

export const SENSOR_ICON_SCALE: Record<SensorIconSize, number> = {
    xs: 0.65,
    small: 0.8,
    medium: 1,
    large: 1.1,
};

export const HUB_BORDER_RADIUS: Record<SensorIconSize, number> = {
    xs: 6,
    small: 8,
    medium: 10,
    large: 12,
};

export const ALERT_DOT_SIZE: Record<SensorIconSize, number> = {
    xs: 8,
    small: 10,
    medium: 12,
    large: 14,
};

export const OPAQUE_BORDER_VARIANTS: SensorIconVariant[] = [
    'slate',
    'planned',
    'offline',
];

export const SENSOR_ICON_STYLES: Record<
    SensorIconVariant,
    Record<SensorIconState, SVGProps<SVGSVGElement>>
> = {
    blue: {
        default: {
            color: theme.palette.primary.main,
            fill: lighten(theme.palette.primary.main, 0.9),
        },
        hover: {
            color: theme.palette.primary.main,
            fill: theme.palette.common.white,
        },
        selected: {
            color: theme.palette.common.white,
            fill: theme.palette.primary.main,
        },
    },
    green: {
        default: {
            color: theme.palette.success.main,
            fill: lighten(theme.palette.success.main, 0.9),
        },
        hover: {
            color: theme.palette.success.main,
            fill: theme.palette.common.white,
        },
        selected: {
            color: theme.palette.common.white,
            fill: theme.palette.success.main,
        },
    },
    amber: {
        default: {
            color: theme.palette.common.yellow2,
            fill: lighten(theme.palette.common.yellow2, 0.9),
        },
        hover: {
            color: theme.palette.common.yellow2,
            fill: theme.palette.common.white,
        },
        selected: {
            color: theme.palette.common.white,
            fill: theme.palette.common.yellow2,
        },
    },
    red: {
        default: {
            color: theme.palette.common.red2,
            fill: lighten(theme.palette.common.red2, 0.9),
        },
        hover: {
            color: theme.palette.common.red2,
            fill: theme.palette.common.white,
        },
        selected: {
            color: theme.palette.common.white,
            fill: theme.palette.common.red2,
        },
    },
    slate: {
        default: {
            color: theme.palette.common.gray8,
            fill: lighten(theme.palette.common.gray5, 0.9),
        },
        hover: {
            color: theme.palette.common.gray8,
            fill: theme.palette.common.gray5,
        },
        selected: {
            color: theme.palette.common.white,
            fill: theme.palette.common.gray11,
        },
    },
    planned: {
        default: {
            color: theme.palette.primary.main,
            fill: theme.palette.common.white,
            strokeDasharray: '5,5',
        },
        hover: {
            color: theme.palette.primary.main,
            fill: lighten(theme.palette.primary.main, 0.9),
            strokeDasharray: '5,5',
        },
        selected: {
            color: theme.palette.common.white,
            fill: theme.palette.primary.main,
            strokeDasharray: '5,5',
        },
    },
    offline: {
        default: {
            color: theme.palette.common.gray11,
            fill: theme.palette.common.gray5,
        },
        hover: {
            color: theme.palette.common.gray11,
            fill: theme.palette.common.white,
        },
        selected: {
            color: theme.palette.common.white,
            fill: theme.palette.common.gray11,
        },
    },
};
