import { Modal } from '@infogrid/components-material-ui';
import type { AxiosParsedError } from '@infogrid/core-types';
import {
    CTRatioInput,
    VoltageRatioInput,
    PulseRatioInput,
} from '@infogrid/electricity-components';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import type { SensorConfigurationShape } from '@infogrid/sensors-constants';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { invalidateSensor } from 'apiHooks/sensors/accessors';
import { useUpdateElectricitySensorConfiguration } from 'apiHooks/sensors/hooks';
import { SENSOR_ID_FIELD } from 'utils/sensor';
import { trackInstallFlowUserEvent } from 'views/buildings/pages/installFlow/utils/analytics';

import SensorDisplay from '../SensorDisplay';
import { useInstallationFlowContext } from '../context';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../reducer';
import { useInstallationFlowStyles } from '../styles';
import { useFludiaTechnicalConfigurationStyles } from './styles';
import type { FludiaTechnicalConfigurationFormValues } from './types';
import { useFludiaConfigurationFormik } from './useFludiaConfigurationFormik';

const FludiaTechnicalConfiguration = (): JSX.Element => {
    const isMobile = useIsMobile();
    const classes = useFludiaTechnicalConfigurationStyles({ isMobileView: isMobile });
    const { t } = useTranslation('sensors');
    const { sensor, folderId } = useInstallationFlowContext();
    const installationFlowStyles = useInstallationFlowStyles();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    const sensorConfig = useMemo(
        () => getSensorConfig(SENSOR_TYPE.TYPE_ELECTRICITY_LED_PULSE_METER),
        [],
    );

    const onBack = useCallback(() => {
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.GO_BACK,
        });
    }, [dispatchInstallationFlowContext]);

    const { mutate: updateSensorConfiguration } = useUpdateElectricitySensorConfiguration(
        SENSOR_TYPE.TYPE_ELECTRICITY_LED_PULSE_METER,
    );

    const onClick = () => {
        trackInstallFlowUserEvent(
            USER_ACTIONS.SUBMIT,
            'Fludia: voltage/ratio/kWh per pulse',
        );
    };

    const formik = useFludiaConfigurationFormik({
        onSubmit: (formValues: FludiaTechnicalConfigurationFormValues) => {
            if (!sensor) {
                return;
            }

            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
            });

            updateSensorConfiguration(
                {
                    id: sensor?.[SENSOR_ID_FIELD] as string,
                    data: {
                        sensor_configuration: {
                            ...(sensor?.sensor_configuration as SensorConfigurationShape),
                            kilowatt_hour_per_pulse: formValues.pulse_size as number,
                            current_ratio: formValues.current_ratio,
                            voltage_ratio: formValues.voltage_ratio,
                        },
                    },
                },
                {
                    onSuccess: () => {
                        const updatedSensor = {
                            ...sensor,
                            sensor_configuration: {
                                ...sensor.sensor_configuration,
                                kilowatt_hour_per_pulse: formValues.pulse_size as number,
                                current_ratio: formValues.current_ratio,
                                voltage_ratio: formValues.voltage_ratio,
                            } as SensorConfigurationShape,
                        };

                        invalidateSensor(sensor?.[SENSOR_ID_FIELD]);
                        dispatchInstallationFlowContext({
                            type: INSTALLATION_FLOW_ACTIONS.ELECTRICITY_MONITORING_CONFIGURATION,
                            sensor: updatedSensor,
                            sensorName: sensor?.device_name,
                            folderId,
                        });
                    },
                    onError: (
                        err: AxiosParsedError<FludiaTechnicalConfigurationFormValues>,
                    ) => {
                        if (err.parsedError) {
                            const { errors: formikErrors, message } = err.parsedError;

                            if (formikErrors) {
                                formik.setErrors(formikErrors);
                            }

                            if (message) {
                                formik.setStatus({ message });
                            }
                        }
                    },
                    onSettled: () => {
                        dispatchInstallationFlowContext({
                            type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
                        });
                    },
                },
            );
        },
    });

    const { errors, handleSubmit } = formik;

    return (
        <>
            <Modal.Title>{t('Configure the device')}</Modal.Title>
            <Modal.Content>
                <form
                    id="fludiaTechnicalConfigurationForm"
                    onSubmit={handleSubmit}
                    className={installationFlowStyles.form}
                >
                    {sensor && sensorConfig ? (
                        <SensorDisplay
                            sensor={sensor}
                            label={sensorConfig.label}
                            deviceType={SENSOR_TYPE.TYPE_ELECTRICITY_LED_PULSE_METER}
                        />
                    ) : null}
                    <Alert color="info" severity="info" className={classes.info}>
                        {t(
                            'The information below captures the technical details of the meter this sensor will be monitoring.',
                        )}
                    </Alert>

                    <CTRatioInput formik={formik} finder={(f) => f.current_ratio} />
                    <VoltageRatioInput formik={formik} finder={(f) => f.voltage_ratio} />
                    <PulseRatioInput formik={formik} finder={(f) => f.pulse_size} />
                </form>
            </Modal.Content>

            <Modal.Actions
                justify="space-between"
                className={installationFlowStyles.actions}
            >
                <Button onClick={onBack} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    data-cypress="save"
                    type="submit"
                    form="fludiaTechnicalConfigurationForm"
                    disabled={!isEmpty(errors)}
                    onClick={onClick}
                >
                    {t('Save')}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default FludiaTechnicalConfiguration;
