import { createRequest } from '@infogrid/core-api';
import { history } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import type { AxiosParsedError } from '@infogrid/core-types';
import {
    getAuthToken,
    getOrganizationId,
    removeAuthTokens,
    saveAuthTokens,
} from '@infogrid/user-cookies';
import { i18n } from '@infogrid/utils-i18n';
import * as Sentry from '@sentry/react';
import type { AxiosError, AxiosPromise } from 'axios';
import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';

import { COREAPI_URL } from 'apiHooks/base/services';
import SETTINGS from 'settings';
import { handleError } from 'utils/parsers/handleError';

import { setLocalStorageBackendVersion } from './hooks/useBackendVersion';

export type AxiosParameters = URLSearchParams | Record<string, unknown>;
export type AxiosData =
    | string
    | Record<keyof never, unknown>
    | ArrayBuffer
    | ArrayBufferView
    | URLSearchParams
    | FormData
    | File
    | Blob;

const INVALID_REFRESH_TOKEN = 'token_not_valid';

const logError = (error: AxiosError) => {
    const shouldIgnoreError =
        !error.response ||
        [400, 401, 403, 404, 409, 422].includes(error.response.status) ||
        error.response.status >= 500;

    if (shouldIgnoreError) {
        return;
    }

    if (process.env.NODE_ENV === 'production') {
        Sentry.captureException(error);
    } else {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

export const configureAxios = (): void => {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common.Accept = 'application/json';
    axios.defaults.headers.common['X-CSRFToken'] =
        Cookies.get(SETTINGS.CSRF_COOKIE_NAME) ?? '';
    axios.defaults.headers.common['X-Timezone'] =
        Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (SETTINGS.CUBE_SERVICE_URL) {
        axios.defaults.headers.common['x-infogrid-cube-base-url'] =
            SETTINGS.CUBE_SERVICE_URL;
    }

    if (SETTINGS.CUBE_SERVICE_SECRET) {
        axios.defaults.headers.common['x-infogrid-cube-api-secret'] =
            SETTINGS.CUBE_SERVICE_SECRET;
    }

    axios.defaults.baseURL = COREAPI_URL;
    axios.defaults.timeout = SETTINGS.LONG_SAGA_TIMEOUT;

    axios.defaults.paramsSerializer = (params) =>
        qs.stringify(params, { arrayFormat: 'comma', encode: false });

    let refreshRequest: AxiosPromise<{ access: string }> | null = null;

    axios.interceptors.request.use(
        (config) => {
            // Skip adding token if we already have Authorization header set
            if (config?.headers?.Authorization) {
                return config;
            }

            // Allow overriding the token for local development
            if (SETTINGS.TOKEN) {
                console.debug('Overriding AuthToken');
            }

            const token = SETTINGS.TOKEN ?? getAuthToken();

            if (!token) {
                return config;
            }

            return {
                ...config,
                headers: {
                    ...(config.headers || {}),
                    Authorization: `Bearer ${token}`,
                },
            };
        },
        (e) => Promise.reject(e),
    );

    axios.interceptors.request.use(
        (config) => {
            const organizationId = getOrganizationId();

            if (organizationId === undefined) {
                return config;
            }

            return {
                ...config,
                headers: {
                    ...(config.headers || {}),
                    // Pass selected organization id to backend
                    [SETTINGS.ORGANIZATION_ID_HEADER_NAME]: organizationId,
                },
            };
        },
        (e) => Promise.reject(e),
    );

    axios.interceptors.request.use(
        (config) => {
            const activeLanguage = i18n.language;

            return {
                ...config,
                headers: {
                    ...(config.headers || {}),
                    'Accept-Language': activeLanguage || SETTINGS.DEFAULT_LANGUAGE,
                },
            };
        },
        (e) => Promise.reject(e),
    );

    axios.interceptors.response.use(
        (response) => {
            setLocalStorageBackendVersion(response);

            return response;
        },
        async (error: AxiosError) => {
            const errorCode = error.response?.data?.code;

            if (error?.response?.status === 401 && errorCode === INVALID_REFRESH_TOKEN) {
                refreshRequest = null;

                removeAuthTokens();

                history.push(routesManager.resolvePath('auth:logout'));
            }

            const refreshToken = localStorage.getItem(SETTINGS.AUTH_REFRESH_TOKEN_NAME);

            if (!refreshToken || error?.response?.status !== 401 || error.config.retry) {
                logError(error);

                const parsedError = handleError(error);

                // TODO rewrite handleError to typescript
                // @ts-expect-error: we have to rewrite handleError to typescript
                if (parsedError.errors || parsedError.message) {
                    // eslint-disable-next-line no-throw-literal

                    // TODO create custom Error type with  parsedError field
                    // eslint-disable-next-line no-throw-literal
                    throw { ...error, parsedError } as AxiosParsedError;
                }

                throw error;
            }

            if (!refreshRequest) {
                // for query which will send at the same time
                refreshRequest = createRequest({
                    method: 'post',
                    url: '/auth/token/refresh/',
                    options: {
                        data: { refresh: refreshToken },
                    },
                });
            }

            const {
                data: { access },
            } = await refreshRequest;

            refreshRequest = null;

            saveAuthTokens(access);

            const newRequest = {
                ...error.config,
                retry: true,
            };

            return axios(newRequest);
        },
    );
};

export const getBaseUrl = (): string | undefined => axios.defaults.baseURL;
