import { ORGANIZATION_FEATURE_FLAG } from '@infogrid/core-feature-flags';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import loadable from '@loadable/component';
import type { RouteConfig } from 'react-router-config';

import RenderChildren from 'components/RenderChildren';
import { folderDetailWatcherSagas } from 'configuration/routes/folders';
import { featureEnabled, userIsAuthenticated } from 'decorators/auth';
import { refreshToken } from 'sagas/auth/authMiddleware';
import { authRequired } from 'sagas/helpers/initialSaga';

const SensorsView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-sensors-view' */ 'views/sensors/SensorsView'
        ),
);

const SensorListView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-sensor-list-view' */ 'views/sensors/SensorListView'
        ),
);

const OfflineSensors = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-offline-sensors' */ 'views/sensors/SensorHealth/pages/OfflineSensors'
        ),
);

const NotConfiguredSensors = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-not-configured-sensors' */ 'views/sensors/SensorHealth/pages/NotConfiguredSensors'
        ),
);

const SensorHealthRedirect = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-health-redirect' */ 'views/sensors/SensorHealth/pages/SensorHealthRedirect'
        ),
);

const SensorDetailsView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-sensor-details' */ 'views/sensors/SensorDetails'
        ),
);

const SensorEventLogView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-sensors-event-log' */ 'views/sensors/SensorEventLog'
        ),
);

const SensorAlertsLogView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-sensor-alert-log' */ 'views/sensors/SensorAlertLog'
        ),
);

const SensorPermissionsView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-sensor-permissions' */ 'views/sensors/SensorPermissions'
        ),
);

const SensorConfigurationView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-sensor-configuration' */ 'views/sensors/SensorConfiguration'
        ),
);

const SeenSensorsView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-seen-sensors' */ 'views/sensors/SeenSensors'
        ),
);

const SensorDataView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-sensor-data' */ 'views/sensors/SensorData'
        ),
);

const IdentifySensorView = loadable(
    () =>
        import(/* webpackChunkName: 'views-sensors-identify' */ 'views/sensors/Identify'),
);
const LabelManagementView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-sensors-label-management' */ 'views/sensors/LabelManagement'
        ),
);

const SENSORS_VIEW_TABS = {
    LIST: '/sensors/',
    OFFLINE: '/sensors/offline/',
    NOT_CONFIGURED: '/sensors/not-configured/',
};

export const createSensorRoutes = (PageNotFoundRoute: RouteConfig): RouteConfig => ({
    path: '/sensors/',
    name: 'sensors',
    component: userIsAuthenticated(RenderChildren),
    routes: [
        {
            path: Object.values(SENSORS_VIEW_TABS),
            exact: true,
            pageName: registerTranslationKey('Sensors', { ns: 'sensors' }),
            component: SensorsView,
            routes: [
                {
                    path: SENSORS_VIEW_TABS.LIST,
                    exact: true,
                    name: 'list',
                    component: SensorListView,
                },
                {
                    path: SENSORS_VIEW_TABS.OFFLINE,
                    exact: true,
                    name: 'offline',
                    pageName: registerTranslationKey('Offline hubs and sensors', {
                        ns: 'sensors',
                    }),
                    component: featureEnabled(ORGANIZATION_FEATURE_FLAG.SENSOR_HEALTH)(
                        OfflineSensors,
                    ),
                },
                {
                    path: SENSORS_VIEW_TABS.NOT_CONFIGURED,
                    exact: true,
                    name: 'not-configured',
                    pageName: registerTranslationKey('Unconfigured sensors', {
                        ns: 'sensors',
                    }),
                    component: featureEnabled(ORGANIZATION_FEATURE_FLAG.SENSOR_HEALTH)(
                        NotConfiguredSensors,
                    ),
                },
            ],
        },
        {
            path: '/sensors/labels/',
            exact: true,
            name: 'labels',
            component: LabelManagementView,
        },
        {
            path: '/sensors/identify/',
            exact: true,
            name: 'identify',
            pageName: registerTranslationKey('Sensors Identify', { ns: 'sensors' }),
            initial: authRequired(refreshToken),
            component: IdentifySensorView,
        },
        {
            path: '/sensors/health/',
            name: 'health',
            component: SensorHealthRedirect,
        },
        {
            path: '/sensors/:deviceName/',
            name: 'details',
            pageName: registerTranslationKey('Sensor Details', { ns: 'sensors' }),
            component: SensorDetailsView,
            routes: [
                {
                    path: '/sensors/:deviceName/events/',
                    exact: true,
                    name: 'events',
                    component: SensorEventLogView,
                },
                {
                    path: '/sensors/:deviceName/alerts/',
                    exact: true,
                    name: 'alerts',
                    component: SensorAlertsLogView,
                },
                {
                    path: '/sensors/:deviceName/permissions/',
                    exact: true,
                    name: 'permissions',
                    component: SensorPermissionsView,
                },
                {
                    path: '/sensors/:deviceName/configuration/',
                    exact: true,
                    name: 'configuration',
                    component: SensorConfigurationView,
                    watcher: folderDetailWatcherSagas,
                },
                {
                    path: '/sensors/:deviceName/seen-sensors/',
                    exact: true,
                    name: 'seen-sensors',
                    component: SeenSensorsView,
                },
                {
                    path: '/sensors/:deviceName/',
                    name: 'data',
                    component: SensorDataView,
                },
            ],
        },
        PageNotFoundRoute,
    ],
});
