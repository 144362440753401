import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    alert: {
        margin: theme.spacing(1, 0),
    },
    labelText: {
        margin: theme.spacing(1, 0),
    },
}));

export const useModalStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 600,

        [theme.breakpoints.down('xs')]: {
            maxWidth: 'none',
            width: '100%',
        },
    },
    content: {
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
}));
