import { isValidElectricityEventType } from '@infogrid/sensors-configuration';
import { SENSOR_EVENT_TYPES_V2, SENSOR_EVENT_V2_KEY } from '@infogrid/sensors-constants';
import type { SensorEventV2Shape } from '@infogrid/sensors-constants';
import round from 'lodash/round';

import type {
    AverageDailyElectricityReadingsInDaysParams,
    AverageDailyElectricityReadingsParams,
} from '../types';
import {
    getEventsInWorkingDays,
    getEventsInNonWorkingDays,
    sumEventsReadings,
    getNonWorkingDaysWithinRange,
    getWorkingDaysCountWithinRange,
} from '../utils';

export const averageDailyElectricityReadings = ({
    events = [],
    eventTypeKey,
    days = 1,
}: AverageDailyElectricityReadingsParams): number => {
    const readingsTotal = sumEventsReadings({
        events,
        eventTypeKey,
    });

    return events.length > 0 && days > 0 ? round(readingsTotal / days) : 0;
};

const getAverageDailyElectricityReadingsInWorkingDays = ({
    sensorEvents,
    timeZone,
    timeRange,
    workingHoursConfiguration,
}: AverageDailyElectricityReadingsInDaysParams): number =>
    averageDailyElectricityReadings({
        events: getEventsInWorkingDays({
            events: sensorEvents,
            timeZone,
            workingHoursConfiguration,
        }),
        eventTypeKey: SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS,
        days: timeRange
            ? getWorkingDaysCountWithinRange({
                  from: timeRange.from,
                  to: timeRange.to,
                  workingHoursConfiguration,
                  timeZone,
              })
            : 1,
    });

const getAverageDailyElectricityReadingsInWorkingDaysV2 = ({
    sensorEvents,
    timeZone,
    timeRange,
    workingHoursConfiguration,
}: AverageDailyElectricityReadingsInDaysParams): number =>
    averageDailyElectricityReadings({
        events: getEventsInWorkingDays({
            events: (sensorEvents as SensorEventV2Shape[])?.filter((event) =>
                isValidElectricityEventType(event?.type),
            ),
            timeZone,
            workingHoursConfiguration,
        }),
        eventTypeKey: SENSOR_EVENT_V2_KEY,
        days: timeRange
            ? getWorkingDaysCountWithinRange({
                  from: timeRange.from,
                  to: timeRange.to,
                  workingHoursConfiguration,
                  timeZone,
              })
            : 1,
    });

export const getAverageDailyElectricityReadingsInWorkingDaysCalculator = (
    isEventsV2: boolean,
): ((params: AverageDailyElectricityReadingsInDaysParams) => number) => {
    return isEventsV2
        ? getAverageDailyElectricityReadingsInWorkingDaysV2
        : getAverageDailyElectricityReadingsInWorkingDays;
};

const getAverageDailyElectricityReadingsInNonWorkingDays = ({
    sensorEvents,
    timeZone,
    timeRange,
    workingHoursConfiguration,
}: AverageDailyElectricityReadingsInDaysParams): number =>
    averageDailyElectricityReadings({
        events: getEventsInNonWorkingDays({
            events: sensorEvents,
            timeZone,
            workingHoursConfiguration,
        }),
        eventTypeKey: SENSOR_EVENT_TYPES_V2.KILOWATT_HOURS,
        days: timeRange
            ? getNonWorkingDaysWithinRange({
                  from: timeRange.from,
                  to: timeRange.to,
                  workingHoursConfiguration,
                  timeZone,
              })
            : 1,
    });

const getAverageDailyElectricityReadingsInNonWorkingDaysV2 = ({
    sensorEvents,
    timeZone,
    timeRange,
    workingHoursConfiguration,
}: AverageDailyElectricityReadingsInDaysParams): number =>
    averageDailyElectricityReadings({
        events: getEventsInNonWorkingDays({
            events: (sensorEvents as SensorEventV2Shape[])?.filter((event) =>
                isValidElectricityEventType(event?.type),
            ),
            timeZone,
            workingHoursConfiguration,
        }),
        eventTypeKey: SENSOR_EVENT_V2_KEY,
        days: timeRange
            ? getNonWorkingDaysWithinRange({
                  from: timeRange.from,
                  to: timeRange.to,
                  workingHoursConfiguration,
                  timeZone,
              })
            : 1,
    });

export const getAverageDailyElectricityReadingsInNonWorkingDaysCalculator = (
    isEventsV2: boolean,
): ((params: AverageDailyElectricityReadingsInDaysParams) => number) => {
    return isEventsV2
        ? getAverageDailyElectricityReadingsInNonWorkingDaysV2
        : getAverageDailyElectricityReadingsInNonWorkingDays;
};
