import { makeStyles } from '@material-ui/core';

export const useTrendLineChartStyles = makeStyles((theme) => ({
    chartContainer: {
        height: '100%',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.common.gray7}`,
        '& > div': {
            height: '100%',
        },
    },
}));
