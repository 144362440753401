import { Tooltip, Typography } from '@material-ui/core';
import { memo } from 'react';

import { FloorShape } from 'utils/types/floors';

import { useSelectOutputStyles } from './styles';

const FloorSwitcherOutput = ({ currentFloor }) => {
    const styles = useSelectOutputStyles();

    return (
        <Tooltip title={currentFloor.name}>
            <Typography
                className={styles.floorSwitcherText}
                data-cypress="floor-name"
                component="h6"
            >
                {currentFloor.name}
            </Typography>
        </Tooltip>
    );
};

FloorSwitcherOutput.propTypes = {
    currentFloor: FloorShape.isRequired,
};

FloorSwitcherOutput.defaultProps = {};

export default memo(FloorSwitcherOutput);
