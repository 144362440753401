import { createRequest } from '@infogrid/core-api';
import type { FloorSensor, NullableMapItemCoordinates } from '@infogrid/locations-types';
import type { PlannedSensor, SensorShape, SensorType } from '@infogrid/sensors-constants';
import type { AxiosPromise } from 'axios';

import { SENSOR_PLANNING_API_URL } from 'apiHooks/base/services';

import type {
    CreatePlannedSensor,
    FloorplanSensorsAlertsResponse,
    LockResponse,
    SensorAndHubTypes,
} from './types';

interface EditMapSensorProps {
    floorId: number;
    sensorDeviceName: string;
    data: NullableMapItemCoordinates;
}

type EditMapSensorReturn = AxiosPromise<{
    data: SensorShape;
    sensorDeviceName: string;
}>;

const editMapSensor = ({
    floorId,
    sensorDeviceName,
    data,
}: EditMapSensorProps): EditMapSensorReturn =>
    createRequest({
        method: 'patch',
        url: `floorplan/floors/${floorId}/sensors/${sensorDeviceName}`,
        options: {
            data,
        },
    });

interface GetSensorsProps {
    floorId: number;
    signal?: AbortSignal;
}

/**
 * @deprecated
 */
const getMappedSensors = ({
    floorId,
    signal,
}: GetSensorsProps): AxiosPromise<SensorShape[]> =>
    createRequest({
        method: 'get',
        url: `floorplan/floors/${floorId}/mapped-sensors`,
        signal,
    });

interface GetFloorSensorsProps {
    floorId: number;
    signal: AbortSignal | undefined;
    params?: { sensor_type?: SensorType[] };
}

const getFloorSensors = ({
    floorId,
    signal,
    params,
}: GetFloorSensorsProps): AxiosPromise<FloorSensor[]> =>
    createRequest({
        method: 'get',
        url: `locations/floors/${floorId}/sensors`,
        signal,
        options: { params },
    });

interface GetFloorplanSensorsAlertsProps {
    floorId: number;
    signal?: AbortSignal;
    params?: { acknowledged: boolean };
}

const getFloorplanSensorsAlerts = ({
    floorId,
    params,
    signal,
}: GetFloorplanSensorsAlertsProps): AxiosPromise<FloorplanSensorsAlertsResponse> =>
    createRequest({
        method: 'get',
        url: `locations/floors/${floorId}/alerts`,
        signal,
        options: {
            params,
        },
    });

interface GetSensorsTypeAndHubsProps {
    floorId: number;
}

const getSensorsTypeAndHubs = ({
    floorId,
}: GetSensorsTypeAndHubsProps): AxiosPromise<SensorAndHubTypes> =>
    createRequest({
        method: 'get',
        url: `v1/planning/${floorId}/available-use-cases`,
        options: {
            baseURL: SENSOR_PLANNING_API_URL,
        },
    });

interface UpdatePlannedSensorProps {
    floorId: number;
    deviceId?: string;
    data: {
        name: string;
        location: PlannedSensor['location'];
        label_ids: number[];
        coordinates: PlannedSensor['coordinates'];
    };
}

const updatePlannedSensor = ({
    floorId,
    deviceId,
    data,
}: UpdatePlannedSensorProps): AxiosPromise<PlannedSensor> =>
    createRequest({
        method: 'put',
        url: `v1/planning/${floorId}/devices/${deviceId}`,
        options: {
            baseURL: SENSOR_PLANNING_API_URL,
            data,
        },
    });

const getPlannedSensors = ({ floorId, signal }: GetSensorsProps) =>
    createRequest({
        method: 'get',
        url: `v1/planning/${floorId}/devices`,
        signal,
        options: {
            baseURL: SENSOR_PLANNING_API_URL,
        },
    });

interface DeletePlannedSensorProps {
    floorId: number;
    deviceId: string;
}

const deletePlannedSensor = ({ floorId, deviceId }: DeletePlannedSensorProps) =>
    createRequest({
        method: 'delete',
        url: `v1/planning/${floorId}/devices/${deviceId}`,
        options: {
            baseURL: SENSOR_PLANNING_API_URL,
        },
    });

interface AcquireSensorPlanningLockProps {
    floorId: number;
}

const acquireSensorPlanningLock = ({
    floorId,
}: AcquireSensorPlanningLockProps): AxiosPromise<LockResponse> =>
    createRequest({
        method: 'post',
        url: `v1/planning/${floorId}/lock`,
        options: {
            baseURL: SENSOR_PLANNING_API_URL,
        },
    });

interface DeleteSensorPlanningLockProps {
    floorId: number;
}

const deleteSensorPlanningLock = ({ floorId }: DeleteSensorPlanningLockProps) =>
    createRequest({
        method: 'delete',
        url: `v1/planning/${floorId}/lock`,
        options: {
            baseURL: SENSOR_PLANNING_API_URL,
        },
    });

interface GetSensorPlanningLockProps {
    floorId: number;
    signal?: AbortSignal;
}

const getSensorPlanningLock = ({ floorId, signal }: GetSensorPlanningLockProps) =>
    createRequest({
        method: 'get',
        url: `v1/planning/${floorId}/lock`,
        signal,
        options: {
            baseURL: SENSOR_PLANNING_API_URL,
        },
    });

interface BatchCreatePlannedSensorsProps {
    floorId: number;
    sensors: CreatePlannedSensor[];
}

const batchCreatePlannedSensors = ({
    floorId,
    sensors,
}: BatchCreatePlannedSensorsProps) =>
    createRequest({
        method: 'post',
        url: `v1/planning/${floorId}/devices/bulk`,
        options: {
            baseURL: SENSOR_PLANNING_API_URL,
            data: sensors,
        },
    });

export const controllers = {
    acquireSensorPlanningLock,
    batchCreatePlannedSensors,
    deletePlannedSensor,
    deleteSensorPlanningLock,
    editMapSensor,
    getFloorSensors,
    getFloorplanSensorsAlerts,
    getMappedSensors,
    getPlannedSensors,
    getSensorsTypeAndHubs,
    getSensorPlanningLock,
    updatePlannedSensor,
};
