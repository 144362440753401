import {
    getFlatEntitiesFromPages,
    INFINITE_QUERY_DEFAULT_INITIAL_DATA,
} from '@infogrid/core-api';
import type { IPagination } from '@infogrid/core-types';
import type { FeedbackPanelTemplate } from '@infogrid/sensors-constants';
import type { InfiniteData } from 'react-query';

interface SelectFeedbackPanelTemplatesResult
    extends InfiniteData<IPagination<FeedbackPanelTemplate>> {
    feedbackPanelTemplates: FeedbackPanelTemplate[];
}

export const selectFeedbackPanelTemplates = (
    data: InfiniteData<IPagination<FeedbackPanelTemplate>> | undefined,
): SelectFeedbackPanelTemplatesResult => ({
    ...INFINITE_QUERY_DEFAULT_INITIAL_DATA,
    feedbackPanelTemplates: getFlatEntitiesFromPages({
        pages: data?.pages,
        entitiesKey: 'results',
        uniqByKey: 'id',
    }),
    ...data,
});
