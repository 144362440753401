import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo } from 'react';

import { useWidgetOptionStyles } from './styles';

interface WidgetOptionProps {
    children: ReactNode;
    className?: string;
    ['data-testid']?: string;
}

const WidgetOption = ({
    children,
    className,
    'data-testid': dataTestId,
}: WidgetOptionProps) => {
    const styles = useWidgetOptionStyles();

    return (
        <div className={classNames(styles.root, className)} data-testid={dataTestId}>
            {children}
        </div>
    );
};

export default memo(WidgetOption);
