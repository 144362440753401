import { makeStyles } from '@material-ui/core';

export const useTreeViewItemStyles = makeStyles({
    treeViewItemRoot: {
        '& .MuiTreeItem-content': {
            backgroundColor: 'unset',
        },

        '&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'unset',
        },

        '&.MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'unset',
        },

        '& .MuiTreeItem-label': {
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
    },
});
