import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import Sensors from 'views/dashboards/components/modals/ConfigureWidgetModal/Sensors';

import Stoplight from '../common/DisplayOptions/Stoplight';
import DisplayOptions from './DisplayOptions';

export const GREEN_MESSAGE = registerTranslationKey('Welcome', { ns: 'dashboard' });
export const YELLOW_MESSAGE = registerTranslationKey('Near capacity', {
    ns: 'dashboard',
});
export const RED_MESSAGE = registerTranslationKey('Please wait', { ns: 'dashboard' });

export const MAX_COLOR_MESSAGE_LENGTH = 50;

export const widgetConfigurationModalTabs = [
    {
        id: 'sensors',
        name: registerTranslationKey('Sensors', { ns: 'dashboard' }),
        component: Sensors,
        attributes: {
            sensorTypeFilter: SENSOR_TYPE.TYPE_PROXIMITY,
            maxSelectedSensors: 250,
        },
    },
    {
        id: 'display-options',
        name: registerTranslationKey('Display Options', { ns: 'dashboard' }),
        component: DisplayOptions,
        attributes: {
            sensorTypeFilter: SENSOR_TYPE.TYPE_PROXIMITY,
        },
    },
    {
        id: 'stoplight',
        name: registerTranslationKey('Stoplight Text', { ns: 'dashboard' }),
        component: Stoplight,
    },
];
