import { Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import type { BreadcrumbProps } from './types';

const Breadcrumb = ({
    id,
    to,
    text,
    icon,
    className,
    children,
}: BreadcrumbProps): JSX.Element => (
    <li key={id}>
        <Link
            className={classNames(className, 'bp4-breadcrumb d-flex align-items-center')}
            to={to}
        >
            {icon ? <Icon icon={icon} className="mr-2" /> : null}
            {text ? <span>{text}</span> : null}
            {children}
        </Link>
    </li>
);

export default Breadcrumb;
