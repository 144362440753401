import { MODAL_STAGES } from './types';
import type { BySpaceTypeModalFormValues } from './types';

export { VALIDATION_SCHEMA } from 'views/solutionSettings/pages/smartCleaning/CleaningRules/components/RulesModal/constants';

export const INITIAL_VALUES: BySpaceTypeModalFormValues = {
    might_need_cleaning_value: 6,
    must_be_cleaned_value: 10,
    locations: [],
    selected_space_types: {},
    selection_method: 'all',
    modal_stage: MODAL_STAGES.SELECT_SPACE_TYPES,
};
