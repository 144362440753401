import { Modal } from '@infogrid/components-material-ui';
import type { FeedbackPanelTemplate } from '@infogrid/sensors-constants';
import { Button } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import FeedbackPanelSensorOptions from 'components/sensors/FeedbackPanelSensorOptions';
import type { FeedbackPanelSensorOptionFormValue } from 'components/sensors/FeedbackPanelSensorOptions';

import { useInstallationFlowStyles } from '../../styles';

interface FeedbackPanelTouchIdentifierProps {
    feedbackPanelTemplate?: FeedbackPanelTemplate | null;
    sensorOptionValues: FeedbackPanelSensorOptionFormValue[];
    onPrevious: () => void;
    onIdentifySuccess: (successPayload: {
        deviceName: string;
        sensorOptionIndex: number;
    }) => void;
    canClickNext: boolean;
    onNext: () => void;
}

const FeedbackPanelTouchIdentifier = ({
    feedbackPanelTemplate,
    sensorOptionValues,
    onPrevious,
    onIdentifySuccess,
    canClickNext,
    onNext,
}: FeedbackPanelTouchIdentifierProps) => {
    const { t } = useTranslation('sensors');
    const installationFlowStyles = useInstallationFlowStyles();

    if (!feedbackPanelTemplate) {
        return null;
    }

    // FeedbackPanelSensorOptions is a shared component because we will need the same logic in WEB-4039
    return (
        <>
            <Modal.Title>
                {t('{{name}} panel setup', {
                    name: feedbackPanelTemplate?.name,
                    defaultValue: `${feedbackPanelTemplate?.name} panel setup`,
                })}
            </Modal.Title>
            <Modal.Content>
                <FeedbackPanelSensorOptions
                    feedbackPanelTemplate={feedbackPanelTemplate}
                    values={sensorOptionValues}
                    onIdentifySuccess={onIdentifySuccess}
                />
            </Modal.Content>
            <Modal.Actions
                justify="space-between"
                className={installationFlowStyles.actions}
            >
                <Button onClick={onPrevious} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
                <Button
                    onClick={onNext}
                    color="primary"
                    data-cypress="next"
                    variant="contained"
                    disabled={!canClickNext}
                >
                    {t('Next')}
                </Button>
            </Modal.Actions>
        </>
    );
};

export default memo(FeedbackPanelTouchIdentifier);
