import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import IndoorAirQualityPreview from 'styles/images/widgets/indoor-air-quality.png';

import IndoorAirQualityWidget from './IndoorAirQualityWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('Indoor Air Quality', { ns: 'dashboard' }),
    type: WIDGET_TYPE.INDOOR_AIR_QUALITY,
    featureFlags: [],
    Component: IndoorAirQualityWidget,
    image: IndoorAirQualityPreview,
    order: 7,
    gridConfig: DEFAULT_GRID_ITEM_CONFIG,
};

export default IndoorAirQualityWidget;
