import type { Space } from '@infogrid/locations-types';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Icon, Typography, Tooltip, Button } from '@material-ui/core';
import classNames from 'classnames';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Table, { COLUMN_TYPES } from 'components/material-ui/Table';
import { useSpaceSortableOrderBy } from 'utils/filtering/space';
import { useSpaceModalContext } from 'views/estate/components/modals/EditSpaceModal/context';

import { useSpacesTableStyles } from './styles';

interface Props {
    fetchNextPage: () => void;
    isLoading: boolean;
    selectedSpaceId: number | null;
    setActiveSpace: (spaceIndex: number | null) => void;
    spaces: Space[];
}

const SpacesTable = ({
    fetchNextPage,
    isLoading,
    selectedSpaceId,
    setActiveSpace,
    spaces,
}: Props): JSX.Element => {
    const styles = useSpacesTableStyles();

    const { t } = useTranslation('estate');

    const isMobile = useIsMobile();

    const [orderBy, changeOrder] = useSpaceSortableOrderBy();

    const selectedSpaceIndex = useMemo(() => {
        return spaces.findIndex((space) => space.id === selectedSpaceId);
    }, [selectedSpaceId, spaces]);

    const { setSpaceToEdit } = useSpaceModalContext();

    const columns = useMemo(
        () =>
            [
                {
                    renderHeader: () => (
                        <Table.SortableTableHeader
                            classes={{
                                sortableHeader: styles.nameHeader,
                            }}
                            name="name"
                            onOrderChange={changeOrder}
                            orderBy={orderBy}
                        >
                            {t('Space name')}
                        </Table.SortableTableHeader>
                    ),
                    render: (space: Space) => (
                        <Typography
                            className={styles.nameContent}
                            data-cypress="space-name"
                            variant="subtitle2"
                        >
                            {space.name}
                        </Typography>
                    ),
                    type: COLUMN_TYPES.FLEXIBLE,
                    width: 4,
                },
                {
                    renderHeader: () => (
                        <Table.SortableTableHeader
                            name="space_type"
                            onOrderChange={changeOrder}
                            orderBy={orderBy}
                        >
                            {t('Type')}
                        </Table.SortableTableHeader>
                    ),
                    render: (space: Space) => (
                        <Typography
                            className={styles.spaceTypeLabel}
                            data-cypress="space-type"
                        >
                            {space.space_type_display}
                        </Typography>
                    ),
                    type: COLUMN_TYPES.FLEXIBLE,
                    width: 1,
                },
                {
                    renderHeader: () => (
                        <Table.SortableTableHeader
                            name="sensors_count"
                            onOrderChange={changeOrder}
                            orderBy={orderBy}
                        >
                            {t('Sensors')}
                        </Table.SortableTableHeader>
                    ),
                    render: (space: Space) => (
                        <div
                            className={styles.sensorsCountWrapper}
                            data-cypress="space-sensors"
                        >
                            <Icon
                                className={classNames(
                                    'far fa-sensor',
                                    styles.sensorsCountIcon,
                                )}
                            />
                            <Typography className={styles.sensorsCountLabel}>
                                {space.sensors_count}
                            </Typography>
                        </div>
                    ),
                    type: COLUMN_TYPES.FLEXIBLE,
                    width: 1,
                },
                {
                    renderHeader: () => (
                        <Table.SortableTableHeader
                            name="capacity"
                            onOrderChange={changeOrder}
                            orderBy={orderBy}
                        >
                            {t('Capacity')}
                        </Table.SortableTableHeader>
                    ),
                    render: (space: Space) => (
                        <div data-cypress="space-capacity">
                            <Typography className={styles.sensorsCountLabel}>
                                {space.occupant_capacity || '-'}
                            </Typography>
                        </div>
                    ),
                    type: COLUMN_TYPES.FLEXIBLE,
                    width: 1,
                },
                !isMobile && {
                    renderHeader: () => null,
                    render: (space: Space) => (
                        <Tooltip
                            placement="top"
                            title={
                                !space.user_actions.edit?.allowed &&
                                space.user_actions.edit?.reason
                                    ? space.user_actions.edit?.reason
                                    : ''
                            }
                        >
                            <Button
                                disabled={!space.user_actions.edit?.allowed}
                                color="primary"
                                data-cypress="space-edit"
                                onClick={() => setSpaceToEdit(space)}
                            >
                                {t('Edit')}
                            </Button>
                        </Tooltip>
                    ),
                    type: COLUMN_TYPES.FIXED,
                    width: 100,
                },
            ].filter(Boolean),
        [
            changeOrder,
            isMobile,
            orderBy,
            setSpaceToEdit,
            styles.nameContent,
            styles.nameHeader,
            styles.spaceTypeLabel,
            styles.sensorsCountWrapper,
            styles.sensorsCountIcon,
            styles.sensorsCountLabel,
            t,
        ],
    );

    const emptyStateRenderer = () => (
        <Typography
            className={styles.noSearchResultsText}
            data-cypress="no-search-result-text"
            variant="body2"
        >
            {t('No matching search results. Try broadening your search.')}
        </Typography>
    );

    return (
        <div className={styles.container}>
            <Table
                classes={{
                    container: styles.tableContainer,
                }}
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                columns={columns}
                data={spaces}
                dataCypress="spaces-table"
                EmptyStateRenderer={emptyStateRenderer}
                isLoading={isLoading}
                onCellClick={setActiveSpace}
                onEnterClick={() => {}}
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                onFetchMore={fetchNextPage}
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                selectedItemIdx={selectedSpaceIndex}
            />
        </div>
    );
};

export default memo(SpacesTable);
