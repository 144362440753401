import { makeStyles } from '@material-ui/core';

export const useNavbarStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.navbar,
        boxShadow: theme.shadows[1],
        height: '100vh',
        zIndex: 3,

        position: 'sticky',
        left: 0,
        top: 0,
    },
}));

export const useNavbarCollapseButtonStyles = makeStyles({
    root: {
        padding: '4px',
        opacity: 0,

        backgroundColor: '#474B58',
        color: '#CDD0DD',

        '&:hover': {
            backgroundColor: '#474B58',
        },
    },
    icon: {
        fontSize: '26px',
    },
});
