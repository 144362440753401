import type { FloorDetail, Space } from '@infogrid/locations-types';
import { CircularProgress } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ListTitle from '../components/ListTitle';
import SpaceItem from './SpaceItem';
import { useSpacesListStyles } from './styles';

interface Props {
    disableNotInSpaces?: boolean;
    floor?: FloorDetail;
    isLoading: boolean;
    originSpaceId?: number;
    selectedSpace?: Space | null;
    setSelectedSpace: (space: Space | null) => void;
    spaces: Space[];
    unassignedSensorsCount: number;
}

const SpacesList = ({
    disableNotInSpaces = false,
    floor,
    isLoading,
    originSpaceId,
    selectedSpace,
    setSelectedSpace,
    spaces,
    unassignedSensorsCount,
}: Props) => {
    const styles = useSpacesListStyles();

    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <ListTitle dataCypress="spaces-count">
                {t('{{count}} spaces', {
                    count: spaces.length,
                    defaultValue___one: `${spaces.length} space`,
                    defaultValue___other: `${spaces.length} spaces`,
                })}
            </ListTitle>
            {!isLoading && (
                <div className={styles.list}>
                    {spaces.map((space) => (
                        <SpaceItem
                            isLocked={!space?.user_actions?.edit?.allowed}
                            isOriginSpace={originSpaceId === space.id}
                            isSelected={selectedSpace?.id === space.id}
                            key={space.id}
                            onSelectSpace={setSelectedSpace}
                            space={space}
                        />
                    ))}
                    <SpaceItem
                        isLocked={
                            !floor?.user_actions?.edit?.allowed || disableNotInSpaces
                        }
                        isSelected={selectedSpace === null}
                        label={t('Not in spaces', { ns: 'estate' })}
                        key={0}
                        onSelectSpace={setSelectedSpace}
                        sensorsCount={unassignedSensorsCount}
                    />
                </div>
            )}
            {isLoading && (
                <div className={styles.spinnerWrapper}>
                    <CircularProgress color="primary" />
                </div>
            )}
        </div>
    );
};

export default memo(SpacesList);
