import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { DEFAULT_GRID_ITEM_CONFIG, WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import Preview from 'styles/images/widgets/live-indoor-air-quality.png';

import LiveIndoorAirQualityWidget from './LiveIndoorAirQualityWidget';

export const WidgetMetadata: WidgetMetadataType = {
    label: registerTranslationKey('Indoor Air Quality - Live', { ns: 'dashboard' }),
    type: WIDGET_TYPE.LIVE_INDOOR_AIR_QUALITY,
    featureFlags: [],
    Component: LiveIndoorAirQualityWidget,
    image: Preview,
    order: 15,
    gridConfig: DEFAULT_GRID_ITEM_CONFIG,
};

export default LiveIndoorAirQualityWidget;
