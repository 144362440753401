import { Modal } from '@infogrid/components-material-ui';
import { routesManager } from '@infogrid/core-routing';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useSupportDialogStyles } from './styles';

interface SupportDialogProps {
    open: boolean;
    onClose: () => void;
}

const SupportDialog = ({ onClose, open }: SupportDialogProps): JSX.Element => {
    const styles = useSupportDialogStyles();
    const { t } = useTranslation();

    return (
        <Modal onClose={onClose} open={open}>
            <Modal.Title>{t('Need help?')}</Modal.Title>
            <Modal.Content className={styles.modalContent}>
                <Modal.ContentItem>
                    <div className={styles.supportCentreBlock}>
                        <div>
                            {t(
                                'You can find detailed user guides and answers to frequently asked questions ' +
                                    'in our Support Center.',
                            )}
                        </div>
                        <Button
                            color="primary"
                            variant="contained"
                            className={styles.supportCentreButton}
                        >
                            <a
                                href="https://help.infogrid.io/en"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.supportCentreLink}
                            >
                                {t('Go to')}
                                <br />
                                {t('Support Center')}
                            </a>
                        </Button>
                    </div>
                    <div>
                        <a
                            href="https://infogrid.io/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('Our privacy policy')}
                        </a>
                    </div>
                    <Link to={routesManager.resolvePath('terms')} onClick={onClose}>
                        {t('Our terms and conditions')}
                    </Link>
                </Modal.ContentItem>
            </Modal.Content>
        </Modal>
    );
};

export default SupportDialog;
