import PropTypes from 'prop-types';
import { useContext, useEffect, memo } from 'react';

import MapContext from './MapContext';

// Layer - https://openlayers.org/en/latest/apidoc/module-ol_layer_Layer-Layer.html

const MapLayer = ({ Layer, options, listeners, zIndex }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) {
            return () => {};
        }

        const layer = new Layer(options);

        map.addLayer(layer);

        layer.setZIndex(zIndex);

        const events = Object.keys(listeners);

        events.forEach((eventName) => {
            layer.on(eventName, listeners[eventName]);
        });

        return () => {
            events.forEach((eventName) => {
                layer.un(eventName, listeners[eventName]);
            });

            map.removeLayer(layer);
        };
    }, [map, Layer, options, listeners, zIndex]);

    return null;
};

MapLayer.propTypes = {
    Layer: PropTypes.func.isRequired,
    options: PropTypes.shape({}).isRequired,
    listeners: PropTypes.shape({}),
    zIndex: PropTypes.number,
};

MapLayer.defaultProps = {
    zIndex: 0,
    listeners: {},
};

export default memo(MapLayer);
