import { makeStyles } from '@material-ui/core';

export const useAppUpdatedErrorStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: 500,
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
            padding: theme.spacing(2),
        },
    },
    logoContainer: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: theme.spacing(3),
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(6.75),
            marginBottom: theme.spacing(8.75),
        },
    },
    buttonContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2.5),
    },
    logo: {
        height: 50,
        maxWidth: 200,
    },
}));
