import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { useCallback, memo } from 'react';
import { useHistory } from 'react-router-dom';

import { useFormToast, useFloorPlanParams } from 'apiHooks/base/hooks';
import { useDeleteFloor, useFloor } from 'apiHooks/floorPlan/floors/hooks';
import {
    selectDeletingFloorStatus,
    selectEditFloorId,
    toggleDeletingFloor,
} from 'ducks/floorPlan';

import DeleteFloorModal from './DeleteFloorModal';

const useOnClose = () => {
    const dispatch = useAppDispatch();

    const onClose = useCallback(() => {
        dispatch(toggleDeletingFloor(false));
    }, [dispatch]);

    return {
        onClose,
    };
};

const DeleteFloorModalContainer = () => {
    const history = useHistory();

    const { buildingId, floorId } = useFloorPlanParams();

    const isOpened = useAppSelector(selectDeletingFloorStatus);
    const editFloorId = useAppSelector(selectEditFloorId);

    const { onClose } = useOnClose();

    const { data: floor, isLoading } = useFloor(editFloorId);
    const { isLoading: isDeletingLoading, mutate } = useDeleteFloor();
    const { triggerErrorToast } = useFormToast();

    const gotToNextFloor = useCallback(
        (floors) => {
            if (editFloorId !== floorId) {
                return;
            }

            if (floors.length) {
                const redirectFloorId = floors?.[0]?.id;

                history.push(
                    routesManager.resolvePath('buildings:floor', {
                        floorId: redirectFloorId,
                        buildingId,
                    }),
                );
            } else {
                history.push(
                    routesManager.resolvePath('buildings:building', {
                        buildingId,
                    }),
                );
            }
        },
        [editFloorId, floorId, history, buildingId],
    );

    const handleDeleteFloor = useCallback(
        (e) => {
            e.preventDefault();

            mutate(
                { floorId: editFloorId, buildingId },
                {
                    onSuccess(newFloors) {
                        gotToNextFloor(newFloors);
                        onClose();
                    },
                    onError() {
                        triggerErrorToast({ message: 'Error delete floor.' });
                    },
                },
            );
        },
        [onClose, editFloorId, buildingId, gotToNextFloor, mutate, triggerErrorToast],
    );

    return (
        <DeleteFloorModal
            isOpened={isOpened}
            handleSubmit={handleDeleteFloor}
            floorName={floor?.name}
            onClose={onClose}
            handleCancel={onClose}
            isSubmitting={isDeletingLoading}
            isFetching={isLoading}
        />
    );
};

export default memo(DeleteFloorModalContainer);
