import { useMutation } from 'react-query';

import { invalidateFloors } from '../../floors/accessors';
import type { ConvertSubfoldersData } from '../controllers';
import { controllers } from '../controllers';

export const useConvertSubfolders = ({ buildingId }: { buildingId: number | null }) => {
    return useMutation(
        ({ data }: { data: ConvertSubfoldersData }) =>
            controllers.convertSubfolders(data),
        {
            async onSuccess() {
                await invalidateFloors(buildingId);
            },
        },
    );
};
