import { ViewWrapper } from '@infogrid/components-material-ui';
import { useAppDispatch } from '@infogrid/core-ducks';
import { useIsMobile } from '@infogrid/utils-hooks';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

import ActionsMenu from 'components/ActionsMenu';
import { toggleEditingBuilding } from 'ducks/floorPlan';

import EditBuildingMenuController from './EditBuildingMenuController';
import { useActionsMenuStyles } from './styles';

const EditBuildingMenu = ({ className, isBuildingEditable }) => {
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();

    const actionsMenuStyles = useActionsMenuStyles({ isMobile });

    const openEditBuildingHandler = useCallback(() => {
        dispatch(toggleEditingBuilding(true));
    }, [dispatch]);

    const renderActionsMenu = useCallback(
        ({ onToggle }) => {
            const editBuilding = () => {
                openEditBuildingHandler();
                onToggle(false);
            };

            return <EditBuildingMenuController editBuilding={editBuilding} />;
        },
        [openEditBuildingHandler],
    );

    return (
        <ViewWrapper isVisible={isBuildingEditable} className={className}>
            <ActionsMenu
                classes={{
                    menuButtonIcon: actionsMenuStyles.menuButtonIcon,
                    menuContent: actionsMenuStyles.menuContent,
                }}
            >
                {renderActionsMenu}
            </ActionsMenu>
        </ViewWrapper>
    );
};

EditBuildingMenu.propTypes = {
    className: PropTypes.string,
    isBuildingEditable: PropTypes.bool,
};
EditBuildingMenu.defaultProps = {
    className: '',
    isBuildingEditable: false,
};

export default memo(EditBuildingMenu);
