import { createRequest } from '@infogrid/core-api';
import type { AxiosPromise } from 'axios';

import type { ResponseTimezones } from './types';

const getTimezones = (signal: AbortSignal | undefined): AxiosPromise<ResponseTimezones> =>
    createRequest({ method: 'get', url: '/user/timezones', signal });

export const controllers = {
    getTimezones,
};
