import { useAppDispatch } from '@infogrid/core-ducks';
import { useCallback } from 'react';

import type { InstallStep } from 'ducks/installFlow';
import { clearInstallError, goToStep } from 'ducks/installFlow';

interface Options {
    clearError?: boolean;
}

export const useGoToStep = (step: InstallStep, options?: Options): (() => void) => {
    const dispatch = useAppDispatch();

    return useCallback(() => {
        if (options?.clearError) {
            dispatch(clearInstallError());
        }

        dispatch(goToStep(step));
    }, [options?.clearError, dispatch, step]);
};
