import { registerTranslationKey } from '@infogrid/utils-i18n';
import loadable from '@loadable/component';
import type { RouteConfig } from 'react-router-config';

import RenderChildren from 'components/RenderChildren';
import { userIsAuthenticated } from 'decorators/auth';

const Electricity = loadable(() => import('@infogrid/solution-views-electricity'), {
    resolveComponent: (mod) => mod.Electricity,
});
const Occupancy = loadable(() => import('@infogrid/solution-views-occupancy'), {
    resolveComponent: (mod) => mod.Occupancy,
});
const SolutionsRoot = loadable(() => import('views/solutions/pages/solutionsRoot'));

const IndoorAirQuality = loadable(
    () => import('@infogrid/solution-views-indoor-air-quality'),
    {
        resolveComponent: (mod) => mod.IndoorAirQuality,
    },
);

const SmartCleaning = loadable(() => import('@infogrid/solution-views-smart-cleaning'), {
    resolveComponent: (mod) => mod.SmartCleaning,
});

export const createSolutionsRoutes = (PageNotFoundRoute: RouteConfig): RouteConfig => ({
    path: '/solutions/',
    name: 'solutions',
    component: userIsAuthenticated(RenderChildren),
    routes: [
        {
            path: '/solutions/',
            exact: true,
            name: 'root-redirect',
            component: SolutionsRoot,
        },
        {
            path: '/solutions/occupancy/',
            exact: true,
            name: 'occupancy',
            pageName: registerTranslationKey('Occupancy'),
            component: Occupancy,
        },
        {
            path: '/solutions/smart-cleaning/',
            exact: true,
            name: 'smart-cleaning',
            pageName: registerTranslationKey('Smart Cleaning'),
            component: SmartCleaning,
        },
        {
            path: '/solutions/electricity/',
            exact: true,
            name: 'electricity',
            pageName: registerTranslationKey('Electricity Monitoring'),
            component: Electricity,
        },
        {
            path: '/solutions/indoor-air-quality/',
            exact: true,
            name: 'indoor-air-quality',
            pageName: registerTranslationKey('Indoor Air Quality'),
            component: IndoorAirQuality,
        },
        PageNotFoundRoute,
    ],
});
