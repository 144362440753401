import type { EventsWebhook, AxiosParsedError } from '@infogrid/core-types';
import { useIsOpenState } from '@infogrid/utils-hooks';
import type { FormikErrors, FormikHelpers } from 'formik';
import { FormikContext, useFormik } from 'formik';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { MutateOptions } from 'react-query';
import * as Yup from 'yup';

import EventsWebhookForm from './EventsWebhookForm';
import { handleEventsWebhookErrorResponse } from './errors';
import type { BaseEventsWebhookVariables } from './types';

interface Props<TVariables> {
    initialValues?: TVariables;
    onSubmit: (
        variables: TVariables,
        options?: MutateOptions<EventsWebhook, AxiosParsedError, TVariables>,
    ) => void;
    canChangeSecret: boolean;
    showSecret: boolean;
    canChangeURL: boolean;
    canChangeSendSignature: boolean;
    showCreateButton: boolean;
    disableIsEnabledField: boolean;
    children?: ReactNode;
}

const EventsWebhookFormContainer = <
    TVariables extends BaseEventsWebhookVariables = BaseEventsWebhookVariables,
>({
    initialValues: initialValuesBase,
    onSubmit: onSubmitBase,
    canChangeSecret,
    showSecret,
    canChangeURL,
    canChangeSendSignature,
    showCreateButton,
    disableIsEnabledField,
    children,
}: Props<TVariables>): JSX.Element => {
    const { t } = useTranslation('integrations');

    const eventsWebhookValidationSchema = useMemo(() => {
        return Yup.object().shape({
            url: Yup.string()
                .max(2048, t('URL must be at most {{num}} characters.', { num: 2048 }))
                .url(t('Enter a valid URL including the protocol.'))
                .required(t('This field may not be blank.', { ns: 'common' })),
            send_signature: Yup.boolean().required(
                t('This field may not be blank.', { ns: 'common' }),
            ),
        });
    }, [t]);

    const [
        isDeactivationConfirmModalOpen,
        closeDeactivationConfirmModal,
        openDeactivationConfirmModal,
    ] = useIsOpenState();

    const onSubmit = useCallback(
        (
            data: TVariables,
            { setSubmitting, setErrors, setStatus }: FormikHelpers<TVariables>,
        ) => {
            onSubmitBase(data, {
                onSuccess: () => {
                    closeDeactivationConfirmModal();
                },
                onError: (errOriginal: AxiosParsedError) => {
                    const err = handleEventsWebhookErrorResponse(errOriginal);

                    const errors = err?.parsedError?.errors as
                        | FormikErrors<TVariables>
                        | undefined;

                    const message = err?.parsedError?.message;

                    if (errors) {
                        setErrors(errors);
                    }

                    if (message) {
                        setStatus({ message });
                    }
                },
                onSettled: () => {
                    setSubmitting(false);
                },
            });
        },
        [onSubmitBase, closeDeactivationConfirmModal],
    );

    const defaultInitialValues: BaseEventsWebhookVariables = {
        url: 'https://',
        is_enabled: false,
    };
    const initialValues = initialValuesBase ?? (defaultInitialValues as TVariables);

    const formik = useFormik<TVariables>({
        initialValues,
        onSubmit,
        enableReinitialize: true,
        validationSchema: eventsWebhookValidationSchema,
        validateOnMount: true,
    });
    const { handleSubmit, values, isSubmitting } = formik;

    const onReset = () => {
        formik.setFieldValue('id', 0);
        formik.setFieldValue('url', '');
        closeDeactivationConfirmModal();
    };

    useEffect(() => {
        if (
            !disableIsEnabledField &&
            !isSubmitting &&
            initialValues &&
            values.is_enabled !== initialValues.is_enabled
        ) {
            handleSubmit();
        }
    }, [
        values.is_enabled,
        initialValues?.is_enabled,
        handleSubmit,
        initialValues,
        disableIsEnabledField,
        isSubmitting,
    ]);

    return (
        <FormikContext.Provider value={formik}>
            <EventsWebhookForm
                isDeactivationConfirmModalOpen={isDeactivationConfirmModalOpen}
                closeDeactivationConfirmModal={closeDeactivationConfirmModal}
                openDeactivationConfirmModal={openDeactivationConfirmModal}
                onReset={onReset}
                disableIsEnabledField={disableIsEnabledField}
                canChangeSecret={canChangeSecret}
                showSecret={showSecret}
                canChangeURL={canChangeURL}
                canChangeSendSignature={canChangeSendSignature}
                showCreateButton={showCreateButton}
            >
                {children}
            </EventsWebhookForm>
        </FormikContext.Provider>
    );
};

export default EventsWebhookFormContainer;
