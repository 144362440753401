import { makeStyles } from '@material-ui/core';

export const useStylesMapControls = makeStyles((theme) => ({
    controls: {
        alignItems: 'center',
        background: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
        minHeight: '47px',
        padding: theme.spacing(0, 1.25),
    },
    customControls: {
        flexGrow: 1,
    },
    buttonsContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    controlIconButton: {
        height: '45px',
        width: '45px',
    },
    controlIcon: {
        color: theme.palette.text.grey.default,
        fontSize: '21px',
        lineHeight: '1.1em',
        width: '1.2em',
    },
    disabledControlIcon: {
        color: theme.palette.text.grey.light,
    },

    actionsMenuItem: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize',
        whiteSpace: 'nowrap',
    },
}));

export const useActionsMenuStyles = makeStyles((theme) => ({
    menuButton: {
        padding: theme.spacing(1.5),
    },
}));
