import type { Breadcrumb } from '@infogrid/core-types';
import { Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import FolderBreadcrumbs from 'components/FolderBreadcrumbs';

import { usePreviouslyInstalledWarningStyles } from './styles';

interface Props {
    breadcrumbs?: Breadcrumb[];
    sensorName?: string;
}

const PreviouslyInstalledWarningContent = ({
    breadcrumbs = [],
    sensorName = '',
}: Props) => {
    const { t } = useTranslation('sensors');
    const classes = usePreviouslyInstalledWarningStyles();

    return (
        <>
            <Typography>{t('The device has already been configured.')}</Typography>
            <Typography className={classes.deviceName} data-cypress="sensor-name">
                {t('Existing name')}: {sensorName}
            </Typography>
            <Typography data-cypress="location">{t('Existing location')}:</Typography>
            <FolderBreadcrumbs items={breadcrumbs} classes={classes} disabled />
            <Typography>{t('Are you sure you want to overwrite this?')}</Typography>
        </>
    );
};

export default memo(PreviouslyInstalledWarningContent);
