import type { BuildingDetail } from '@infogrid/locations-types';
import { CircularProgress } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ListItem from '../components/ListItem';
import ListTitle from '../components/ListTitle';
import { useBuildingsListStyles } from './styles';

interface Props {
    buildings: BuildingDetail[];
    disablePermissionLock?: boolean;
    isLoading: boolean;
    setSelectedBuilding: (building: BuildingDetail | null) => void;
}

const BuildingsList = ({
    buildings,
    disablePermissionLock = false,
    isLoading,
    setSelectedBuilding,
}: Props) => {
    const styles = useBuildingsListStyles();

    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <ListTitle dataCypress="buildings-count">
                {t('{{count}} buildings', {
                    count: buildings.length,
                    defaultValue___one: `${buildings.length} building`,
                    defaultValue___other: `${buildings.length} buildings`,
                })}
            </ListTitle>
            {!isLoading && (
                <div className={styles.list}>
                    {buildings.map((building) => (
                        <ListItem
                            icon="far fa-building"
                            isLocked={
                                !disablePermissionLock &&
                                !building.user_actions?.edit?.allowed
                            }
                            key={building.id}
                            name={building.name}
                            onSelectItem={() => setSelectedBuilding(building)}
                            hubsAndSensorsCount={building.sensors_count}
                        />
                    ))}
                </div>
            )}
            {isLoading && (
                <div className={styles.spinnerWrapper}>
                    <CircularProgress color="primary" />
                </div>
            )}
        </div>
    );
};

export default memo(BuildingsList);
