import { createRequest } from '@infogrid/core-api';
import type { EventsWebhook } from '@infogrid/core-types';
import type { AxiosPromise } from 'axios';
import type { ParsedQs } from 'qs';

import { WEBHOOK_SERVICE_URL } from 'apiHooks/base/services';

import type {
    CreateEventsWebhookPayload,
    EventsWebhooksResult,
    UpdateEventsWebhook,
} from './types';

const createEventsWebhook = (
    data: CreateEventsWebhookPayload,
): AxiosPromise<EventsWebhook> =>
    createRequest({
        method: 'post',
        url: '/webhooks/',
        options: {
            data,
            baseURL: WEBHOOK_SERVICE_URL,
        },
    });

const getEventsWebhooks = (
    signal: AbortSignal | undefined,
    params: ParsedQs,
): AxiosPromise<EventsWebhooksResult> =>
    createRequest({
        method: 'get',
        url: `/webhooks/`,
        signal,
        options: { params, baseURL: WEBHOOK_SERVICE_URL },
    });

const updateEventsWebhook = (
    eventsWebhookId: number,
    data: UpdateEventsWebhook,
): AxiosPromise<EventsWebhook> =>
    createRequest({
        method: 'patch',
        url: `/webhooks/${eventsWebhookId}`,
        options: { data, baseURL: WEBHOOK_SERVICE_URL },
    });

const deleteEventsWebhook = (eventsWebhookId: number): AxiosPromise<void> =>
    createRequest({
        method: 'delete',
        url: `/webhooks/${eventsWebhookId}`,
        options: { baseURL: WEBHOOK_SERVICE_URL },
    });

const getEventsWebhookConfig = (
    signal: AbortSignal | undefined,
    params: ParsedQs,
): AxiosPromise<unknown> =>
    createRequest({
        method: 'get',
        url: '/webhooks/config',
        signal,
        options: { params, baseURL: WEBHOOK_SERVICE_URL },
    });

export const controllers = {
    createEventsWebhook,
    getEventsWebhooks,
    updateEventsWebhook,
    deleteEventsWebhook,
    getEventsWebhookConfig,
};
