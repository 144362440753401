import { Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import type { FieldInputProps, FieldMetaProps } from 'formik';

import { useThresholdCardStyles } from './styles';

interface ThresholdInputProps {
    cypressId: string;
    id: string;
    label: string;
    field: FieldInputProps<number>;
    meta: FieldMetaProps<number>;
    hasError: boolean;
    unitSymbol: string;
}

const ThresholdInput = ({
    cypressId,
    id,
    label,
    field,
    meta,
    hasError,
    unitSymbol,
}: ThresholdInputProps) => {
    const styles = useThresholdCardStyles();

    return (
        <Grid item>
            <TextField
                data-cypress={cypressId}
                id={id}
                label={label}
                name={field.name}
                placeholder={label}
                type="number"
                value={meta.value ?? ''}
                onChange={field.onChange}
                variant="outlined"
                error={!!meta.error && hasError}
                helperText={meta.error}
                InputProps={{
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            disableTypography
                            className={styles.tempUnit}
                        >
                            {unitSymbol}
                        </InputAdornment>
                    ),
                }}
                className={styles.value}
            />
        </Grid>
    );
};

export default ThresholdInput;
