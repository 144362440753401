import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMappedSensorsWarning } from './styles';

const MappedSensorsWarning = () => {
    const { t } = useTranslation('estate');

    const styles = useMappedSensorsWarning();

    return (
        <Alert className={styles.container} severity="warning">
            <Typography variant="body1">
                {t(
                    'One or more of the sensors you’re moving are mapped to a floor plan. If you move these sensors to a different floor, they will be unmapped from the current floor plan.',
                )}
            </Typography>
        </Alert>
    );
};

export default memo(MappedSensorsWarning);
