import { useAppSelector } from '@infogrid/core-ducks';
import { selectAllSolutions } from '@infogrid/solution-views-ducks';
import type { Solution } from '@infogrid/solution-views-types';
import { getUser, selectActiveTimeZone, selectOrganization } from '@infogrid/user-ducks';
import { Analytic } from '@infogrid/utils-analytics';
import trimEnd from 'lodash/trimEnd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const formatUserSolutions = (solutions: Solution[]) =>
    solutions.reduce((acc, status) => {
        const name = trimEnd(status.name, '_sv');
        const title = `${name}_solution_view_is_enabled`;

        return {
            ...acc,
            [title]: status.is_enabled,
        };
    }, {});

export const useIdentifyUser = (): void => {
    const location = useLocation();

    const user = useAppSelector(getUser);
    const userSolutions: Solution[] = useAppSelector(selectAllSolutions);
    const timezone = useAppSelector(selectActiveTimeZone);
    const organization = useAppSelector(selectOrganization);

    useEffect(() => {
        if (user && userSolutions.length) {
            const solutions = formatUserSolutions(userSolutions);

            Analytic.identifyUser(user.id, {
                name: user.name,
                email: user.email,
                job_role: user.job_role,
                job_role_other: user.job_role_other,
                is_superuser: user.is_superuser,
                organization_id: user.active_organization_id,
                organization_name: organization?.name,
                timezone,
                ...solutions,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        location,
        organization?.name,
        timezone,
        user?.id,
        user?.name,
        user?.email,
        user?.active_organization_id,
        userSolutions,
    ]);
};
