import type { FloorPlanLocation } from '@infogrid/locations-types';
import { Typography } from '@material-ui/core';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSpaceName } from 'apiHooks/floorPlan/spaces/hooks';

import { useStylesMapTooltip } from './styles';

interface Props {
    sensor: {
        location?: FloorPlanLocation;
        is_planned?: boolean;
        name?: string;
    };
}

const FloorPlanSensorTooltip: React.FC<Props> = ({ sensor }) => {
    const { t } = useTranslation('floor-map');
    const styles = useStylesMapTooltip();
    const spaceName = useSpaceName(sensor.location);

    const spaceNameLabel = useMemo(() => {
        return sensor.location?.space_id
            ? `${t('Space')}: ${spaceName || t('Loading', { ns: 'common' })}`
            : t('Not in a space');
    }, [sensor, spaceName, t]);

    if (!sensor.is_planned) {
        return null;
    }

    return (
        <div className={styles.tooltip} data-testid="floor-map-tooltip">
            <Typography className={styles.sensorName} variant="body2">
                {sensor.name}
            </Typography>
            <Typography variant="body2">{t('Not installed')}</Typography>
            <Typography variant="body2" className={styles.space}>
                {spaceNameLabel}
            </Typography>
        </div>
    );
};

export default memo(FloorPlanSensorTooltip);
