import { Header } from '@infogrid/components-material-ui';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Typography } from '@material-ui/core';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import type { RouteConfigComponentProps } from 'react-router-config';

import EstateView from './EstateView';
import { useEstateStyles } from './styles';

interface Props {
    route?: RouteConfigComponentProps['route'];
}

const Estate = ({ route }: Props): JSX.Element => {
    const { t } = useTranslation('estate');

    const styles = useEstateStyles();

    const isMobile = useIsMobile();

    const renderPageNameRow = useCallback(
        () =>
            !isMobile && (
                <>
                    <Typography className={styles.pageNameText} variant="subtitle1">
                        {t('Estate Management')}
                    </Typography>
                </>
            ),
        [isMobile, styles, t],
    );

    return (
        <>
            <Helmet defaultTitle={t('Estate Management')} />

            {!isMobile && (
                <Header
                    renderPageNameRowContent={renderPageNameRow}
                    pageName={route?.pageName}
                />
            )}

            <EstateView />
        </>
    );
};

export default Estate;
