import { useIsMobile } from '@infogrid/utils-hooks';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import { Typography, Button } from '@material-ui/core';
import classNames from 'classnames';
import type { MouseEvent } from 'react';
import { useState, useCallback, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { QrReader } from 'react-qr-reader';
import { useTimeoutFn } from 'react-use';

import QRCodeScannerError from './QRCodeScannerError';
import ViewFinder from './ViewFinder';
import { useQRCodeScannerStyles, useReaderStyles } from './styles';

const SCAN_TIMEOUT = 10000;
const IGNORE_READER_ERRORS = ['No MultiFormat Readers were able to detect the code.'];

const ERROR_MESSAGES: Record<string, string> = {
    ScanTimeout: registerTranslationKey('No QR code detected. Check code is in focus.', {
        ns: 'sensors',
    }),
    NotAllowedError: registerTranslationKey(
        'Please grant your web browser permission to use your device’s camera.',
        { ns: 'sensors' },
    ),
};

interface Props {
    onScan: (data: string) => void;
    deviceName?: string;
    deviceType?: string;
    apiError?: string;
    onClickManualIdentify?: (e: MouseEvent) => void;
}

const QRCodeScanner = ({
    onScan,
    deviceName,
    deviceType,
    apiError,
    onClickManualIdentify,
}: Props) => {
    const { t } = useTranslation('sensors');
    const isMobile = useIsMobile();
    const classes = useQRCodeScannerStyles({
        isMobile,
        hasFooterContent: !!onClickManualIdentify,
    });
    const readerStyles = useReaderStyles({ isMobile });
    const [error, setError] = useState('');

    const [_0, cancelScanTimeout] = useTimeoutFn(() => {
        if (!error) {
            setError(t(ERROR_MESSAGES.ScanTimeout));
        }
    }, SCAN_TIMEOUT);

    const handleResult = useCallback(
        (result, err) => {
            if (result) {
                setError('');
                cancelScanTimeout();
                onScan(result.text);
            }

            if (err) {
                if (ERROR_MESSAGES[err.name]) {
                    setError(t(ERROR_MESSAGES[err.name]));
                } else if (!IGNORE_READER_ERRORS.includes(err.message)) {
                    setError(err.message);
                }
            }
        },
        [cancelScanTimeout, onScan, setError, t],
    );

    const computedError = useMemo(() => apiError || error, [apiError, error]);
    const longDeviceName = deviceName && deviceName.length >= 25;

    return (
        <div className={classes.root} data-cypress="qr-reader-view">
            <QrReader
                containerStyle={readerStyles.CONTAINER}
                videoContainerStyle={readerStyles.VIDEO_CONTAINER}
                videoStyle={readerStyles.VIDEO}
                onResult={handleResult}
                ViewFinder={ViewFinder}
                multiFormat
                constraints={{ facingMode: { ideal: 'environment' } }}
                scanDelay={200}
            />
            {computedError && (
                <QRCodeScannerError error={apiError || error} className={classes.error} />
            )}
            <div className={classes.header}>
                <Typography
                    data-cypress="instruction-heading"
                    align="center"
                    variant={!longDeviceName ? 'h5' : undefined}
                    className={classNames(classes.text, classes.heading)}
                >
                    {deviceName
                        ? t('Identify {{deviceName}}', {
                              deviceName,
                              defaultValue: `Identify ${deviceName}`,
                          })
                        : t('Identify device')}
                </Typography>
                {deviceType && (
                    <Typography
                        data-cypress="device-type"
                        align="center"
                        variant="caption"
                        className={classNames(classes.text, classes.subtleText)}
                    >
                        {deviceType}
                    </Typography>
                )}
                <Typography
                    data-cypress="instruction-text"
                    align="center"
                    variant="caption"
                    className={classes.text}
                >
                    {t(
                        'Position the QR code or data matrix printed on the device within this frame to begin installation. Make sure the QR code is in focus, well lit and kept still.',
                    )}
                </Typography>
            </div>
            <div className={classes.footer}>
                {onClickManualIdentify && (
                    <>
                        <Typography
                            data-cypress="troubleshooting-text"
                            align="center"
                            variant="caption"
                            className={classes.text}
                        >
                            {t('Having trouble scanning the QR code?')}
                            <br />
                            {t('Identify your device manually')}
                        </Typography>
                        <Button
                            color="primary"
                            data-cypress="manually-identify-devices"
                            variant="contained"
                            onClick={onClickManualIdentify}
                        >
                            {t('Manually identify device')}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default memo(QRCodeScanner);
