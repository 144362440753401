import {
    getSensorIconVariant,
    getSensorIsOnline,
    getSensorTypeGroup,
} from '@infogrid/sensors-configuration';
import type { PlannedSensor, SensorType } from '@infogrid/sensors-constants';
import { DUAL_SENSOR_TYPES } from '@infogrid/sensors-constants';
import type { MapBrowserEvent } from 'ol';
import { Feature } from 'ol';
import {
    singleClick,
    shiftKeyOnly,
    altKeyOnly,
    platformModifierKeyOnly,
} from 'ol/events/condition';
import { Point } from 'ol/geom';
import { DragPan, MouseWheelZoom, defaults as defaultInteractions } from 'ol/interaction';
import { Style, Icon } from 'ol/style';
import type { ReactElement } from 'react';
import ReactDOMServer from 'react-dom/server';

import type { PlannedSensorsData } from 'apiHooks/floorPlan/sensors/hooks';
import type {
    SensorIconState,
    SensorIconVariant,
} from 'components/sensors/SensorIcon/consts';
import { getSensorIcon } from 'components/sensors/SensorIcon/getSensorIcon';
import type { SensorIconProps } from 'components/sensors/SensorIcon/types';

import type {
    MapScale,
    BaseMapItem,
    MapFeature,
    MappedSensor,
    MappablePlannedSensor,
    FeatureStyleFunc,
    MapMode,
    SensorStateFilter,
} from './types';

export const translateSelectOptions = {
    condition: (mapBrowserEvent: MapBrowserEvent): boolean => {
        return (
            singleClick(mapBrowserEvent) &&
            !shiftKeyOnly(mapBrowserEvent) &&
            altKeyOnly(mapBrowserEvent)
        );
    },
};

export const BASE_MAP_INTERACTIONS = defaultInteractions({
    pinchRotate: false,
});

// We need a custom implementation of the component to avoid accidentally scaling the map while scrolling the page.
// More info here: https://openlayers.org/en/latest/examples/two-finger-pan-scroll.html

export const CUSTOM_SCROLL_INTERACTIONS = defaultInteractions({
    dragPan: false,
    mouseWheelZoom: false,
    pinchRotate: false,
}).extend([
    new DragPan({
        condition(event) {
            return this.getPointerCount() === 2 || platformModifierKeyOnly(event);
        },
    }),
    new MouseWheelZoom({
        condition: platformModifierKeyOnly,
    }),
]);

export const getIconSource = (IconComponent: ReactElement): string => {
    const renderedSVG = ReactDOMServer.renderToStaticMarkup(IconComponent);

    return 'data:image/svg+xml,' + encodeURIComponent(renderedSVG);
};

export const selectInteractionOptions = {
    condition: (mapBrowserEvent: MapBrowserEvent): boolean => {
        return (
            singleClick(mapBrowserEvent) &&
            !shiftKeyOnly(mapBrowserEvent) &&
            !altKeyOnly(mapBrowserEvent)
        );
    },
};

export const featureDataExtractor = <T extends BaseMapItem>(feature: {
    get: (key: string) => T;
    itemData?: T;
}): T => feature.get('itemData');

export const createItemFeature = <T extends BaseMapItem>(item: T): MapFeature<T> => {
    const feature = new Feature({
        geometry: new Point([item.coordinates.x, item.coordinates.y]),
        itemData: item,
    }) as MapFeature<T>;

    feature.setId(item.uuid);

    return feature;
};

// This is currently needed because Modify (used in DragInteraction) adds duplicate features.
// TODO remove function and implementations on successful completion of DEP-201
export const isDuplicateFeatureFromModify = <T extends BaseMapItem>(feature: {
    get: (key: string) => T;
    itemData?: T;
}): feature is MapFeature<T> => !featureDataExtractor(feature);

export const scaleToNumber = (scale: MapScale): number =>
    Array.isArray(scale) ? scale[0] : scale;

export const scaleToRelativeMapSize = (
    numberScale: number,
    scale?: number,
    zoom?: number,
): number => (scale && zoom !== undefined ? (scale * 2 ** (zoom || 0)) / numberScale : 0);

/**
 * The sensor passed here can be either PlannedSensor or SensorShapeWithCoordinates *but* the internal logic
 * for this function only requires an optional is_planned property. Due to TS eccentricities we need to define
 * the property subsets in the arguments in an odd way but this should allow this function to be as flexible as
 * possible.
 *
 * This function is a user-defined type guard for PlannedSensor.
 * https://basarat.gitbook.io/typescript/type-system/typeguard#user-defined-type-guards
 *
 * ```
 if (isPlannedSensor(sensor)) {
     // sensor is guaranteed to be PlannedSensor in here
    }
 * ```
*/
export const isPlannedSensor = (
    sensor: BaseMapItem & MappedSensor,
): sensor is PlannedSensor & MappedSensor => !!sensor.is_planned;

export const isDualPlannedSensor = (
    sensor: BaseMapItem & MappedSensor,
): sensor is PlannedSensor & MappedSensor =>
    !!(
        sensor.is_planned &&
        DUAL_SENSOR_TYPES.includes(
            (sensor.device_type?.name || sensor.type_code) as SensorType,
        )
    );

const getSensorFeatureVariant = (
    item: BaseMapItem & MappedSensor,
    mapMode: string,
): SensorIconVariant => {
    const sensorType = item.type_code || item.device_type?.name;

    if (!sensorType) {
        return 'blue';
    }

    const state = getSensorIconVariant({ ...item, type_code: sensorType }, mapMode);

    if (state === 'gray') {
        return 'slate';
    }

    if (state === 'base') {
        return 'blue';
    }

    return state;
};

const getSensorFeatureState = (isActive: boolean, isHover: boolean): SensorIconState => {
    if (isActive) {
        return 'selected';
    }

    return isHover ? 'hover' : 'default';
};

const defaultSensorStateFilter: SensorStateFilter = {
    showOfflineStatus: false,
    showDefaultRAGStatus: true,
    showInstalledFilterInteractions: true,
};

export const createSensorFeatureStyle =
    <T extends MappedSensor>(
        mapMode: MapMode,
        sensorStateFilter?: SensorStateFilter,
        variantOverride?: SensorIconVariant,
    ): FeatureStyleFunc<T> =>
    ({ item: sensor, scale, zoom, isActive = false, isHover = false }): Style[] => {
        const isPlanned = isPlannedSensor(sensor);
        const numberScale = scaleToNumber(scale);
        const type = (sensor.device_type?.name || sensor.type_code) as SensorType;
        const plannedSensor = isPlanned ? (sensor as PlannedSensor) : undefined;
        const isDual = isDualPlannedSensor(sensor);
        const areaScale =
            isDual && zoom !== undefined && plannedSensor?.coordinates.scale
                ? scaleToRelativeMapSize(
                      numberScale,
                      plannedSensor.coordinates.scale,
                      zoom,
                  )
                : 0;

        const {
            showDefaultRAGStatus,
            showOfflineStatus,
            showInstalledFilterInteractions,
        } = sensorStateFilter
            ? { ...defaultSensorStateFilter, ...sensorStateFilter }
            : defaultSensorStateFilter;

        const sensorIconConfig: SensorIconProps = {
            type,
            isHub: getSensorTypeGroup(type) === 'hub',
            isDirectional: isDual,
            rotation: plannedSensor?.coordinates.rotation || 0,
            scale: areaScale,
            ...(isPlanned
                ? {
                      state: getSensorFeatureState(isActive, isHover),
                      variant: 'planned',
                  }
                : {
                      state: showInstalledFilterInteractions
                          ? getSensorFeatureState(isActive, isHover)
                          : undefined,
                      variant:
                          variantOverride ||
                          (showDefaultRAGStatus
                              ? getSensorFeatureVariant(sensor, mapMode)
                              : undefined),
                      isOffline: showOfflineStatus
                          ? !getSensorIsOnline({ ...sensor, type_code: type })
                          : undefined,
                      isAlert: !!sensor.alerts_count,
                  }),
        };

        const { sensorIconView, sensorIconFrame, sensorIcon, extras } =
            getSensorIcon(sensorIconConfig);

        const mapStyleConfig = {
            anchor: [0.5, 0.5],
            opacity: mapMode === 'plan' && !isPlanned ? 0.7 : 1,
            scale: numberScale,
            crossOrigin: 'Anonymous',
        };

        return [sensorIconView, sensorIconFrame, sensorIcon, extras].map(
            (iconPart) =>
                new Style({
                    image: new Icon({
                        ...mapStyleConfig,
                        src: iconPart ? getIconSource(iconPart) : undefined,
                    }),
                }),
        );
    };

export const expandPlannedSensorsToMappedSensors = (
    data: PlannedSensorsData,
): PlannedSensorsData<MappablePlannedSensor> => ({
    ...data,
    results: data.results.map((sensor) => ({
        ...sensor,
        device_name: sensor.name,
        type_code: sensor.device_type.name,
        uuid: sensor.id || '',
        is_saved: true,
    })),
});
