import type { FloorPlanLocation } from '@infogrid/locations-types';
import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import type { CardProps } from '@material-ui/core';
import { Card, CardContent, Typography } from '@material-ui/core';
import classNames from 'classnames';
import type { FC, ReactElement } from 'react';
import { useCallback, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SensorBatteryLevel from 'components/material-ui/Sensors/SensorBatteryLevel';
import SensorLastReading from 'components/material-ui/Sensors/SensorLastReading';
import SensorLocationString from 'components/material-ui/Sensors/SensorLocationString';
import SensorSignalLevel from 'components/material-ui/Sensors/SignalLevel';
import { SensorIconContainer } from 'components/sensors/SensorIcon';
import type { SensorIconVariant } from 'components/sensors/SensorIcon/consts';
import type { SensorIconConfig } from 'components/sensors/SensorIcon/types';

import { useSensorInfoCardStyles } from './styles';

export interface SensorInfoCardProps {
    sensor: GenericSensorShape & {
        profile: {
            name?: string;
            uuid: string;
        };
        floorplan_location?: FloorPlanLocation;
    };
    showStatusIcons?: boolean;
    iconConfig?: SensorIconConfig;
    iconVariant?: SensorIconVariant;
    statusLoading?: boolean;
    variant?: 'default' | 'inline' | 'list';
    onClick?: () => void;
    renderLatestReading?: () => ReactElement;
}

const SensorInfoCard: FC<SensorInfoCardProps> = ({
    sensor,
    showStatusIcons = true,
    statusLoading,
    iconConfig,
    iconVariant,
    variant = 'default',
    onClick,
    renderLatestReading,
}) => {
    const { t } = useTranslation('sensors');
    const styles = useSensorInfoCardStyles();
    const sensorTypeConfig = getSensorConfig(sensor.type_code);
    const sensorType = sensorTypeConfig.label;

    const statusIcons = useMemo(
        () => (
            <div className={styles.statusIcons}>
                <SensorBatteryLevel withTooltip sensor={sensor} size={20} />
                <SensorSignalLevel sensor={sensor} size={18} isLoading={statusLoading} />
            </div>
        ),
        [sensor, statusLoading, styles],
    );

    const sensorIcon = (
        <SensorIconContainer
            sensor={sensor}
            className={styles.sensorTypeIcon}
            iconConfig={iconConfig}
            variant={iconVariant}
        />
    );

    const sensorName = (
        <Typography
            className={classNames(styles.sensorName, {
                [styles.listSensorName]: variant === 'list',
            })}
            data-cypress="sensor-name"
        >
            {sensor.profile.name || sensor.device_name}
        </Typography>
    );

    const latestReading = useCallback(() => {
        if (renderLatestReading) {
            return renderLatestReading();
        }

        return (
            <SensorLastReading
                sensor={sensor}
                withTime
                readingFallback={t('Pending')}
                timeFallback={t('No readings available')}
                classes={{
                    wrapper: styles.lastReadingWrapper,
                    divider: styles.lastReadingDivider,
                    reading: classNames(styles.lastReading, {
                        [styles.listLastReading]: variant === 'list',
                    }),
                    readingTime: classNames(styles.lastReadingTime, {
                        [styles.listLastReadingTime]: variant === 'list',
                    }),
                }}
            />
        );
    }, [renderLatestReading, sensor, styles, t, variant]);

    const cardProps = useMemo<Partial<CardProps>>(
        () => ({
            className: classNames({ [styles.clickableCard]: onClick }),
            onClick,
            role: onClick ? 'button' : undefined,
            variant: 'outlined',
        }),
        [onClick, styles.clickableCard],
    );

    if (variant === 'inline') {
        return (
            <Card
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...cardProps}
            >
                <CardContent className={classNames(styles.content, styles.inlineContent)}>
                    {sensorIcon}
                    <div className={styles.inlineText}>
                        <header className={styles.inlineHeader}>
                            {sensorName}
                            {statusIcons}
                        </header>
                        {sensor.floorplan_location && (
                            <SensorLocationString location={sensor.floorplan_location} />
                        )}
                    </div>
                </CardContent>
            </Card>
        );
    }

    if (variant === 'list') {
        return (
            <Card
                square
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...cardProps}
            >
                <CardContent
                    className={classNames(
                        styles.content,
                        styles.inlineContent,
                        styles.listContent,
                    )}
                >
                    {sensorIcon}
                    <div className={styles.inlineText}>
                        <header className={styles.inlineHeader}>
                            {sensorName}
                            {statusIcons}
                        </header>
                        {latestReading()}
                    </div>
                </CardContent>
            </Card>
        );
    }

    return (
        <Card
            data-cypress="installed-sensor-info"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...cardProps}
        >
            <CardContent className={styles.content}>
                <header className={styles.header}>
                    {sensorIcon}
                    {latestReading()}
                    {showStatusIcons && statusIcons}
                </header>
                {sensorName}
                <Typography
                    className={styles.sensorType}
                    variant="body2"
                    color="textSecondary"
                    data-cypress="sensor-type"
                >
                    {sensorType}
                    {sensor.profile.name && ` | ${sensor.device_name}`}
                </Typography>
                {sensor.floorplan_location && (
                    <SensorLocationString location={sensor.floorplan_location} />
                )}
            </CardContent>
        </Card>
    );
};

export default memo(SensorInfoCard) as typeof SensorInfoCard;
