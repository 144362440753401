import { makeStyles } from '@material-ui/core';

export const useActionItemStyles = makeStyles({
    text: {
        textTransform: 'capitalize',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        overflow: 'hidden',
    },
});
