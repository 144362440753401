import { useIsOpenState } from '@infogrid/utils-hooks';

import SupportDialog from 'components/SupportDialog';

import NavbarButton from './NavbarButton';

interface NavbarButtonModalProps {
    transitionDuration: number;
    navbarOpened: boolean;
    modalName: string;
    icon: string;
    text: string;
}

const NavbarButtonModal = ({
    transitionDuration,
    navbarOpened,
    modalName,
    icon,
    text,
}: NavbarButtonModalProps): JSX.Element => {
    const [isOpen, onClose, onOpen] = useIsOpenState();

    return (
        <>
            <NavbarButton
                transitionDuration={transitionDuration}
                navbarOpened={navbarOpened}
                icon={icon}
                text={text}
                onClick={onOpen}
            />
            {modalName === 'Support' ? (
                <SupportDialog onClose={onClose} open={isOpen} />
            ) : null}
        </>
    );
};

export default NavbarButtonModal;
