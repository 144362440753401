import { useAppDispatch } from '@infogrid/core-ducks';
import type { Floor } from '@infogrid/locations-types';
import { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import { useNavigateFloor } from 'apiHooks/floorPlan/floors/hooks';
import { putEditFloorId, toggleCreatingFloor, toggleEditingFloor } from 'ducks/floorPlan';
import type { FloorPlanParams } from 'views/buildings/types';

import FloorNavigation from './FloorNavigation';

interface Props {
    isFullView: boolean;
}

const FloorNavigationContainer = ({ isFullView }: Props) => {
    const dispatch = useAppDispatch();

    const { floorId: activeFloorId, buildingId } = useParams<FloorPlanParams>();
    const { building } = useBuilding(+buildingId);

    const { navigateToFloor } = useNavigateFloor();

    const openCreatingFloorHandler = useCallback(() => {
        dispatch(toggleCreatingFloor(true));
    }, [dispatch]);

    const editFloorHandler = useCallback(
        (floor: Floor) => {
            if (floor?.id) {
                dispatch(toggleEditingFloor(true));
                dispatch(putEditFloorId(floor.id));
            }
        },
        [dispatch],
    );

    return (
        <FloorNavigation
            onFloorClick={navigateToFloor}
            onCreateFloor={openCreatingFloorHandler}
            onEditFloor={editFloorHandler}
            isFullView={isFullView}
            activeFloorId={+activeFloorId}
            isBuildingEditable={building?.user_actions?.edit?.allowed}
        />
    );
};

export default memo(FloorNavigationContainer);
