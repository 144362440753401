import { Modal } from '@infogrid/components-material-ui';
import type { SortDirection } from '@infogrid/core-types';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Button, CircularProgress } from '@material-ui/core';
import { memo, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAllBuildings } from 'apiHooks/floorPlan/buildings/hooks';

import WidgetAlert from '../../../common/WidgetAlert';
import type { HistoricCompareData } from '../../types';
import { getScoreDelta } from '../utils';
import DesktopContent from './DesktopContent';
import { useCompareModalStyles } from './styles';
import { calculateSortDirection, sortBuildings, mapBuildingsScores } from './utils';

interface Props {
    onClose: () => void;
    widgetData: HistoricCompareData;
    selectedBuildings: number[];
    isLoading: boolean;
    isError: boolean;
}

const CompareModal = ({
    onClose,
    widgetData,
    selectedBuildings,
    isError,
    isLoading,
}: Props) => {
    const isMobile = useIsMobile();
    const styles = useCompareModalStyles();

    const { t } = useTranslation();

    const { buildings, isLoading: isBuildingLoading } = useAllBuildings();
    const { scores: buildingsScores, all_buildings_score: allBuildingsScore } =
        widgetData;

    const [sortInfo, setSortInfo] = useState<{
        column: string;
        direction: SortDirection | undefined;
    }>({
        column: '',
        direction: undefined,
    });

    const changeSortDirection = useCallback(
        (column: string) => {
            const newSortDirection = calculateSortDirection(
                column,
                sortInfo.column,
                sortInfo.direction,
            );

            setSortInfo({
                column,
                direction: newSortDirection,
            });
        },
        [sortInfo],
    );

    const allBuildingsInfo = useMemo(() => {
        return {
            score: allBuildingsScore.score_today,
            delta: getScoreDelta(
                allBuildingsScore.score_today,
                allBuildingsScore.score_yesterday,
            ),
            historicalScores: allBuildingsScore.historical_scores.map(
                ({ score }) => score,
            ),
        };
    }, [allBuildingsScore]);

    const buildingsInfo = useMemo(
        () => mapBuildingsScores(buildingsScores, buildings),
        [buildings, buildingsScores],
    );

    const sortedBuildingsInfo = useMemo(
        () => sortBuildings(buildingsInfo, sortInfo.column, sortInfo.direction),
        [buildingsInfo, sortInfo],
    );

    return (
        <Modal
            open
            onClose={onClose}
            fullWidth
            fullScreen={isMobile}
            maxWidth="sm"
            data-testid="compare-buildings-modal"
        >
            <Modal.Title>{t('Compare Buildings', { ns: 'dashboard' })}</Modal.Title>
            <Modal.Content className={styles.modalContent}>
                {isError && !isLoading && (
                    <div className={styles.loader}>
                        <WidgetAlert severity="error">
                            {t(
                                `Error loading comparison data. Please reload the page or check the widget's configuration.`,
                            )}
                        </WidgetAlert>
                    </div>
                )}
                {(isBuildingLoading || isLoading) && (
                    <div className={styles.loader}>
                        <CircularProgress color="primary" size={40} />
                    </div>
                )}
                {!isBuildingLoading && !isLoading && !isError && (
                    <DesktopContent
                        sortDirection={sortInfo.direction}
                        activeSortColumn={sortInfo.column}
                        changeSortDirection={changeSortDirection}
                        allBuildingsInfo={allBuildingsInfo}
                        buildingsInfo={sortedBuildingsInfo}
                        selectedBuildings={selectedBuildings}
                    />
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} data-cypress="close-btn">
                    {t('Close')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(CompareModal);
