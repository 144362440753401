import { wrapResponsePromise } from '@infogrid/core-api';
import type { FloorSensorLocation } from '@infogrid/locations-types';
import { useMutation } from 'react-query';

import { addFloorSensor } from '../../floors/accessors';
import { invalidateFloorSensors } from '../accessors';
import { controllers } from '../controllers';

interface UseAddMapSensorProps {
    floorId: number;
}

export const useAddMapSensor = ({ floorId }: UseAddMapSensorProps) => {
    const { mutate: mapSensor } = useMutation(
        ({
            data,
            sensorDeviceName,
        }: {
            data: FloorSensorLocation;
            sensorDeviceName: string;
        }) =>
            wrapResponsePromise(
                controllers.editMapSensor({ floorId, data, sensorDeviceName }),
            ),
        {
            async onMutate({ data }: { data: FloorSensorLocation }) {
                addFloorSensor(floorId, data);
                invalidateFloorSensors({ floorId });
            },
        },
    );

    return {
        mapSensor,
    };
};
