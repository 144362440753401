import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import { Locations } from 'views/dashboards/components/modals/ConfigureWidgetModal/Locations';

export const widgetConfigurationModalTabs = [
    {
        id: 'locations',
        name: registerTranslationKey('Locations', { ns: 'dashboard' }),
        component: Locations,
        attributes: {
            sensorTypes: [
                SENSOR_TYPE.TYPE_AIR_QUALITY,
                SENSOR_TYPE.TYPE_TEMPERATURE,
                SENSOR_TYPE.TYPE_HUMIDITY,
            ],
            isMappedSensors: false,
        },
    },
];
