import { makeStyles } from '@material-ui/core';

export const useListTitleStyles = makeStyles((theme) => ({
    label: {
        color: theme.palette.common.gray6,
        marginBottom: theme.spacing(1.5),
    },
    divider: {
        backgroundColor: theme.palette.common.shell,
    },
}));
