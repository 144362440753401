import { getSensorConfig } from '@infogrid/sensors-configuration';
import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import type {
    SensorEventsV2,
    SensorEventV2Shape,
    SensorElectricityEventsV2Shape,
    SensorEmergencyLightEventsV2Shape,
    SensorType,
} from '@infogrid/sensors-constants';
import {
    zipObjectWithStaticValue,
    ELECTRICITY_SENSOR_TYPES_EVENTSV2,
} from '@infogrid/solution-views-electricity';

interface SensorTypeHandlersSensorArg {
    latest_events_v2?: SensorEventsV2;
}

const sensorTypeHandlers: {
    [key: string]: (
        sensor: SensorTypeHandlersSensorArg,
    ) => SensorEventV2Shape | SensorEventsV2 | undefined;
} = {
    [SENSOR_TYPE.TYPE_MONNIT_HUB]: (sensor) => sensor.latest_events_v2?.connection_signal,
    ...zipObjectWithStaticValue(
        ELECTRICITY_SENSOR_TYPES_EVENTSV2,
        (sensor: SensorTypeHandlersSensorArg) =>
            (sensor.latest_events_v2 as SensorElectricityEventsV2Shape)?.kilowatt_hours,
    ),
    [SENSOR_TYPE.TYPE_AIR_QUALITY]: (sensor) => sensor.latest_events_v2,
    [SENSOR_TYPE.TYPE_EMERGENCY_LIGHT]: (sensor) =>
        (sensor.latest_events_v2 as SensorEmergencyLightEventsV2Shape)
            ?.emergency_light_test,
    [SENSOR_TYPE.TYPE_CCON]: (sensor) => sensor.latest_events_v2?.connection_type,
};

interface UseCurrentLatestSensorEventSensorArg {
    latest_events_v2?: SensorEventsV2;
    type_code: SensorType;
}

export const useCurrentLatestSensorEvent = (
    sensor: UseCurrentLatestSensorEventSensorArg,
): SensorEventV2Shape | SensorEventsV2 | null | undefined => {
    if (!sensor || !sensor.latest_events_v2) {
        return undefined;
    }

    const handler = sensorTypeHandlers[sensor.type_code] ?? null;

    const { lastReadingEventType } = getSensorConfig(sensor.type_code);

    if (handler) {
        return handler(sensor);
    }

    return lastReadingEventType && sensor.latest_events_v2[lastReadingEventType];
};
