import { Modal } from '@infogrid/components-material-ui';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Typography, Button } from '@material-ui/core';
import noop from 'lodash/noop';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { IconeIndex } from '../../types';
import { getRiskMetadataByIconeIndex } from '../../utils';
import { useTutorialModalStyles } from './styles';

interface Props {
    onClose?: () => void;
    open?: boolean;
}

const TutorialModal = ({ onClose = noop, open = true }: Props) => {
    const isMobile = useIsMobile();

    const styles = useTutorialModalStyles();

    const { t } = useTranslation('dashboard');

    return (
        <Modal
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            disableEnforceFocus
            fullScreen={isMobile}
            data-testid="co2-tutorial-modal"
        >
            <Modal.Title>{t('How does it work?')}</Modal.Title>
            <Modal.Content className={styles.modalContent}>
                <section className={styles.section}>
                    <Typography variant="h6" className={styles.header} component="p">
                        {t('CO2 Concentration')}
                    </Typography>
                    <Typography className={styles.description} variant="body2">
                        {t(
                            `Use this widget to get an overview of the CO2 concentration for one or many sensors. Scores are based on the ICONE index developed by the French ministry for ecological transition, which is one of the leading measurements for air safety in the world.`,
                        )}
                    </Typography>
                </section>
                <section className={styles.section}>
                    <Typography variant="body2">
                        {t('How do I read my score?')}
                    </Typography>
                    <Typography
                        className={styles.scoreDescription}
                        variant="body2"
                        color="textSecondary"
                    >
                        {t(
                            'Our widget visualises your score using a colour coded ring to help you:',
                        )}
                    </Typography>
                    <div className={styles.framedContent}>
                        <Typography variant="body2" className={styles.subtitle}>
                            {t('Aim for the lowest possible number')}
                        </Typography>

                        <Typography
                            variant="caption"
                            color="textSecondary"
                            align="left"
                            component="p"
                        >
                            {t(
                                `CO2 concentration is ranked from 0–5 and our widget uses a clear, colour coded ring to show your score. Scores represent the last hour of data, covering week days 6am-6pm based on the building location.`,
                            )}
                        </Typography>

                        <ul className={styles.risksList}>
                            {[...Array(6).keys()].map((index) => {
                                const { color, label } = getRiskMetadataByIconeIndex(
                                    index as IconeIndex,
                                );

                                return (
                                    <li className={styles.riskLevelContainer} key={index}>
                                        <div
                                            className={styles.riskLevel}
                                            style={{ backgroundColor: color }}
                                        >
                                            <Typography variant="caption">
                                                {index}
                                            </Typography>
                                        </div>
                                        <Typography
                                            color="textPrimary"
                                            variant="caption"
                                            align="center"
                                        >
                                            {t(label)}
                                        </Typography>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </section>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose} data-cypress="close-btn">
                    {t('Close', { ns: 'common' })}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(TutorialModal);
