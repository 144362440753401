import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import { WIDGET_TYPE } from '@infogrid/dashboards-constants';
import { getSensorConfig } from '@infogrid/sensors-configuration';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Slider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { memo, useMemo, lazy, Suspense, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { useDebounce } from 'use-debounce';

import SensorsChecklist from 'components/SensorsChecklist';
import { prettifyCoordinate } from 'utils/math';
import { SensorShape } from 'utils/types';

import { changeMapStateEvent } from '../../../../utils/analytics';
import Select from '../../../common/FloorPlan/Select';
import { useFloorPlanStyles } from './styles';

const LegacyFloorMap = lazy(() =>
    import(
        /* webpackChunkName: "LegacyFloorMap", webpackPreload: true */ 'components/floorPlans/LegacyFloorMap'
    ),
);

const sensorCheckListKeyExtractor = (x) => x.key;
const sensorCheckListValueExtractor = (x) => x.name;

const BASE_CONTROLS = ['ZOOM_IN', 'ZOOM_OUT', 'CENTER'];
const MAP_STATE_TIMEOUT = 500;

const FloorPlan = ({
    buildingId,
    buildings,
    canUserEdit,
    floorId,
    floors,
    activeSensorTypes: activeSensorTypesOnServer,
    saveConfig,
    imageUrl,
    imageWidth,
    imageHeight,
    orientation,
    zoom,
    sensorIconScale,
    sensors,
    spaceTypes,
}) => {
    const styles = useFloorPlanStyles();
    const isMobile = useIsMobile();

    const { t } = useTranslation('floor-map');

    const [mapState, setMapState] = useState({
        orientation,
        zoom,
        scale: sensorIconScale,
    });

    const [debouncedState] = useDebounce(mapState, MAP_STATE_TIMEOUT);

    useUpdateEffect(() => {
        if (canUserEdit) {
            saveConfig({
                orientation: prettifyCoordinate(debouncedState.orientation, 6),
                zoom: prettifyCoordinate(debouncedState.zoom, 6),
                scale: debouncedState.scale,
            });
        }

        changeMapStateEvent({ widgetType: WIDGET_TYPE.FLOOR_PLAN });
    }, [debouncedState]);

    const changeBuilding = useCallback(
        (id) => {
            saveConfig({ building_id: id });
        },
        [saveConfig],
    );

    const changeFloor = useCallback(
        (id) => {
            saveConfig({ floor_id: id });
        },
        [saveConfig],
    );

    const changeZoom = useCallback((value) => {
        setMapState((state) => ({ ...state, zoom: value }));
    }, []);

    const changeRotation = useCallback((value) => {
        setMapState((state) => ({ ...state, orientation: value }));
    }, []);

    const sensorsTypesSet = useMemo(() => {
        return new Set(sensors.map(({ type_code }) => type_code));
    }, [sensors]);

    const sensorsTypes = useMemo(() => {
        const types = [...sensorsTypesSet];

        return types.map((type) => {
            const { label } = getSensorConfig(type);

            return { key: type, name: label };
        });
    }, [sensorsTypesSet]);

    const [activeSensorTypes, changeActiveSensors] = useState(
        activeSensorTypesOnServer.filter((x) => sensorsTypesSet.has(x)),
    );

    useEffect(() => {
        changeActiveSensors(
            activeSensorTypesOnServer.filter((x) => sensorsTypesSet.has(x)),
        );
    }, [activeSensorTypesOnServer, sensorsTypesSet]);

    const changeActiveSensorTypes = useCallback(
        (type) => {
            const isAlreadyChecked = activeSensorTypes.includes(type);

            const types = isAlreadyChecked
                ? activeSensorTypes.filter((e) => e !== type)
                : [...activeSensorTypes, type];

            changeActiveSensors(types);
        },
        [activeSensorTypes],
    );

    const toggleAllSensorTypes = useCallback(
        (checkAll) => {
            const types = checkAll ? sensorsTypes.map((x) => x.key) : [];

            changeActiveSensors(types);
        },
        [sensorsTypes, changeActiveSensors],
    );

    const saveActiveSensorTypes = useCallback(() => {
        if (
            activeSensorTypesOnServer.length !== activeSensorTypes.length ||
            !activeSensorTypes.every((x) => activeSensorTypesOnServer.includes(x))
        ) {
            saveConfig({ selected_sensor_types: activeSensorTypes });
        }
    }, [activeSensorTypes, saveConfig, activeSensorTypesOnServer]);

    const activeSensors = useMemo(() => {
        return activeSensorTypes.length
            ? sensors.filter((x) => activeSensorTypes.includes(x.type_code))
            : sensors;
    }, [activeSensorTypes, sensors]);

    const sensorsChecklistClasses = useMemo(
        () => ({ itemsList: styles.sensorsChecklist }),
        [styles.sensorsChecklist],
    );

    const controlsProps = useMemo(() => {
        return {
            enabled: !isMobile,
            className: styles.controlsContainer,
            baseControls: BASE_CONTROLS,
            customControls: (
                <div className={styles.mapCustomControlsContainer}>
                    <Select
                        items={buildings}
                        selectedItemId={buildingId}
                        onSelect={changeBuilding}
                        disabled={!canUserEdit}
                    />

                    <Select
                        items={floors}
                        selectedItemId={floorId}
                        onSelect={changeFloor}
                        disabled={!canUserEdit}
                    />

                    {!!sensorsTypes.length && (
                        <SensorsChecklist
                            classes={sensorsChecklistClasses}
                            disabled={!canUserEdit}
                            sensorTypes={sensorsTypes}
                            checkedSensorTypes={activeSensorTypes.filter((x) =>
                                sensorsTypesSet.has(x),
                            )}
                            toggleSensorTypeHandler={changeActiveSensorTypes}
                            toggleAllSensorsTypesHandler={toggleAllSensorTypes}
                            keyExtractor={sensorCheckListKeyExtractor}
                            valueExtractor={sensorCheckListValueExtractor}
                            onClose={saveActiveSensorTypes}
                            renderInPortal
                        />
                    )}

                    {!!sensorsTypes.length && (
                        <Slider
                            className={styles.sensorIconSizeSlider}
                            disabled={!canUserEdit}
                            value={mapState.scale}
                            data-cypress="sensor-size-slider"
                            onChange={(event, newValue) => {
                                setMapState((state) => ({ ...state, scale: newValue }));
                            }}
                            min={0.3}
                            max={2}
                            step={0.1}
                        />
                    )}
                </div>
            ),
        };
    }, [
        activeSensorTypes,
        buildingId,
        buildings,
        canUserEdit,
        changeActiveSensorTypes,
        changeBuilding,
        changeFloor,
        floorId,
        floors,
        mapState.scale,
        saveActiveSensorTypes,
        sensorsChecklistClasses,
        sensorsTypes,
        sensorsTypesSet,
        styles.controlsContainer,
        styles.mapCustomControlsContainer,
        styles.sensorIconSizeSlider,
        toggleAllSensorTypes,
        isMobile,
    ]);

    return (
        <Suspense
            fallback={
                <LoadingPlaceholder
                    titleVariant="body1"
                    progressSize={56}
                    text={t('Loading Map...')}
                />
            }
        >
            <LegacyFloorMap
                mapClassName={styles.mapContainer}
                imageUrl={imageUrl}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                onZoomChange={changeZoom}
                onRotationChange={changeRotation}
                zoom={mapState.zoom}
                rotation={mapState.orientation}
                sensors={activeSensors}
                isDragSensorAvailable={false}
                isAddSensorAvailable={false}
                isSelectSensorAvailable={false}
                controlsProps={controlsProps}
                featureScale={mapState.scale}
                isDisableZoomByScroll
                isWidget
                spaceTypes={spaceTypes}
            />
        </Suspense>
    );
};

FloorPlan.propTypes = {
    buildingId: PropTypes.number.isRequired,
    canUserEdit: PropTypes.bool.isRequired,
    floorId: PropTypes.number.isRequired,
    activeSensorTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    saveConfig: PropTypes.func.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageWidth: PropTypes.number.isRequired,
    imageHeight: PropTypes.number.isRequired,
    orientation: PropTypes.number.isRequired,
    zoom: PropTypes.number.isRequired,
    sensorIconScale: PropTypes.number.isRequired,
    buildings: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
    ).isRequired,
    floors: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
    ).isRequired,
    sensors: PropTypes.arrayOf(SensorShape).isRequired,
    spaceTypes: PropTypes.arrayOf(
        PropTypes.shape({
            display_name: PropTypes.string,
            id: PropTypes.number,
            name: PropTypes.string,
            space_count: PropTypes.number,
        }),
    ).isRequired,
};

export default memo(FloorPlan);
