import { AirQualityMetricSelector as MetricSelector } from '@infogrid/solution-views-components';
import type { AirQualityMetricSelectOption } from '@infogrid/solution-views-types';
import {
    OVERALL_AIR_QUALITY_METRIC_ID,
    NONE_METRIC_ID,
} from '@infogrid/solution-views-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type {
    LiveIndoorAirQualityWidget,
    WidgetSettings,
    LiveIndoorAirQualityWidgetData,
} from '../types';
import MetricContent from './MetricContent';
import { useContentStyles, useSelectMenuStyles, useSelectStyles } from './styles';

export interface ContentProps {
    canUserEdit?: boolean;
    widget: LiveIndoorAirQualityWidget;
    widgetData: LiveIndoorAirQualityWidgetData | undefined;
    onUpdateConfiguration: (config: WidgetSettings) => void;
    isLoading: boolean;
    isLoadingDataError: boolean;
}

const IGNORE_METRICS: AirQualityMetricSelectOption[] = [
    NONE_METRIC_ID,
    OVERALL_AIR_QUALITY_METRIC_ID,
];

const Content = ({
    onUpdateConfiguration,
    widget,
    widgetData,
    canUserEdit = false,
    isLoading,
    isLoadingDataError,
}: ContentProps) => {
    const styles = useContentStyles();
    const selectMenuClasses = useSelectMenuStyles();
    const selectClasses = useSelectStyles();

    const { t } = useTranslation('dashboard');

    const widgetSettings = widget.configuration.calculators.live_indoor_air_quality;

    const changeMetric = (metric: AirQualityMetricSelectOption) => {
        if (metric !== OVERALL_AIR_QUALITY_METRIC_ID && metric !== NONE_METRIC_ID) {
            onUpdateConfiguration({
                ...widgetSettings,
                metric,
            });
        }
    };

    return (
        <div className={styles.root}>
            <MetricSelector
                classes={selectClasses}
                value={widgetSettings.metric}
                label={t('Metric')}
                onChange={changeMetric}
                ignoreMetrics={IGNORE_METRICS}
                disabled={!canUserEdit}
                size="small"
                menuClasses={selectMenuClasses}
            />
            <div className={styles.contentContainer}>
                <MetricContent
                    widget={widget}
                    widgetData={widgetData}
                    isLoading={isLoading}
                    isLoadingDataError={isLoadingDataError}
                />
            </div>
        </div>
    );
};

export default memo(Content);
