import { NoImageIcon } from '@infogrid/sensors-icons';
import { ClickAwayListener } from '@material-ui/core';
import classNames from 'classnames';
import { memo, useCallback, useState } from 'react';

import { useIconsPickerStyles } from './styles';

interface Props {
    icons: string[];
    onChange: (icon: string) => void;
    selectedIcon?: string;
}

const IconsPicker = ({ icons, onChange, selectedIcon }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const styles = useIconsPickerStyles();

    const toggleOpen = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen, setIsOpen]);

    const close = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const deselectIcon = useCallback(() => {
        onChange('');
        close();
    }, [close, onChange]);

    return (
        <ClickAwayListener onClickAway={close}>
            <div className={styles.container}>
                <div
                    className={styles.input}
                    onClick={toggleOpen}
                    onKeyDown={toggleOpen}
                    role="button"
                >
                    {selectedIcon ? (
                        <i
                            className={classNames(styles.icon, selectedIcon)}
                            data-testid="icons-picker-selected-icon"
                        />
                    ) : (
                        <NoImageIcon
                            className={styles.icon}
                            data-testid="icons-picker-selected-icon"
                        />
                    )}
                </div>
                {isOpen && (
                    <div className={styles.dropdown}>
                        <NoImageIcon
                            className={classNames(styles.icon, styles.iconHover)}
                            onClick={deselectIcon}
                        />
                        {icons.map((icon) => {
                            const onIconClick = () => {
                                onChange(icon);
                                close();
                            };

                            return (
                                <i
                                    className={classNames(
                                        styles.icon,
                                        styles.iconHover,
                                        icon,
                                    )}
                                    key={icon}
                                    onClick={onIconClick}
                                    onKeyDown={onIconClick}
                                    role="button"
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};

export default memo(IconsPicker);
