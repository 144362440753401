import { Icon, InputAdornment, TextField } from '@material-ui/core';
import classNames from 'classnames';
import type { ChangeEvent } from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFloorSearchInputStyles } from './styles';

interface FloorSearchInputProps {
    className?: string;
    value: string;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    clearSearch: () => void;
    'data-cypress'?: string;
}

/**
 * Floor search input for use alongside the FloorList.
 */
const FloorSearchInput = ({
    className,
    disabled,
    value,
    onChange,
    clearSearch,
    ...dataAttr
}: FloorSearchInputProps) => {
    const styles = useFloorSearchInputStyles();
    const { t } = useTranslation('estate');

    return (
        <TextField
            className={className}
            data-cypress={dataAttr['data-cypress'] || 'search-floors'}
            disabled={disabled}
            onChange={onChange}
            placeholder={t('Search floors…')}
            size="small"
            value={value}
            variant="outlined"
            // For the <input/> element
            inputProps={{
                'aria-label': t('Search floors…'),
            }}
            // For the Input component
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {value && (
                            <Icon
                                className={classNames(
                                    'fal fa-times-circle',
                                    styles.flexIcon,
                                    styles.clearSearchIcon,
                                )}
                                data-testid="clear-floors-search-icon"
                                onClick={clearSearch}
                                onKeyDown={clearSearch}
                                role="button"
                            />
                        )}
                        {!value && (
                            <Icon
                                className={classNames(
                                    'fal fa-search',
                                    styles.flexIcon,
                                    styles.searchIcon,
                                )}
                                data-testid="search-floors-icon"
                            />
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default memo(FloorSearchInput);
