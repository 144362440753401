import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';

import { SHARED_WITH_ME_FOLDER_ID } from 'utils/folder';
import { getNodeItemType } from 'utils/folderTree';

import FolderTreeFolder from './FolderTreeFolder';

/**
 * Folder tree: renders a tree component of all folders and sensors.
 * If selection related props are passed then component functions as multiselect.
 * Underlying subfolders and sensors are selected by cascade,
 *   and unnecessary items can be unselected if needed.
 * Numbers in parentheses show the number of selected sensors
 */
export const FolderTree = ({
    toggleSelected,
    sensorTypeFilter,
    labelsFilter,
    readingTypesFilter,
    containerClassName,
    nodeClassName,
    selectIsSelected,
    selectSecondaryLabel,
    scrollParentRef,
    allowFoldersSelection,
    ...props
}) => {
    // 'Shared with me' folder will be automatically expanded
    const [expanded, setExpanded] = useState(() => ({
        [`folder-${SHARED_WITH_ME_FOLDER_ID}`]: true,
    }));
    const selectIsExpanded = useCallback(
        (nodeId) => expanded[nodeId] === true,
        [expanded],
    );

    const handleNodeExpansion = useCallback(
        (nodeId, isExpanded) => {
            setExpanded((prev) => ({
                ...prev,
                [nodeId]: isExpanded,
            }));
        },
        [setExpanded],
    );
    const onNodeCollapse = useCallback(
        (nodeId) => handleNodeExpansion(nodeId, false),
        [handleNodeExpansion],
    );
    const onNodeExpand = useCallback(
        (nodeId) => handleNodeExpansion(nodeId, true),
        [handleNodeExpansion],
    );

    const onNodeClick = useCallback(
        (nodeId, nodeWasSelected, entity) => {
            if (!toggleSelected) {
                return;
            }

            const nodeType = getNodeItemType(nodeId);

            const isProperNodeType = allowFoldersSelection
                ? nodeType === 'sensor' || nodeType === 'folder'
                : nodeType === 'sensor';

            if (isProperNodeType) {
                toggleSelected(nodeId, nodeWasSelected, entity);
            }
        },
        [allowFoldersSelection, toggleSelected],
    );

    const treeContainerRef = useRef(null);

    // Renders one `FolderTreeFolder` - the 'Shared with me' folder
    // See FolderTreeFolder for more details
    return (
        <div
            className={classNames('tree-container', Classes.TREE, containerClassName)}
            ref={treeContainerRef}
        >
            <ul className={classNames(Classes.TREE_NODE_LIST)} data-cypress="folder-tree">
                <FolderTreeFolder
                    folderId={SHARED_WITH_ME_FOLDER_ID}
                    depth={0}
                    basePath={`${SHARED_WITH_ME_FOLDER_ID}`}
                    nodeClassName={nodeClassName}
                    sensorTypeFilter={sensorTypeFilter}
                    labelsFilter={labelsFilter}
                    readingTypesFilter={readingTypesFilter}
                    selectIsExpanded={selectIsExpanded}
                    selectIsSelected={selectIsSelected}
                    selectSecondaryLabel={selectSecondaryLabel}
                    scrollParentRef={scrollParentRef || treeContainerRef}
                    onNodeClick={onNodeClick}
                    onNodeExpand={onNodeExpand}
                    onNodeCollapse={onNodeCollapse}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                />
            </ul>
        </div>
    );
};

FolderTree.propTypes = {
    selectedFolders: PropTypes.arrayOf(PropTypes.number), // saved selected folder ids
    selectedSensors: PropTypes.arrayOf(PropTypes.string), // saved selected sensor device names
    toggleSelected: PropTypes.func,
    sensorTypeFilter: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    labelsFilter: PropTypes.arrayOf(PropTypes.number),
    readingTypesFilter: PropTypes.arrayOf(PropTypes.string),
    containerClassName: PropTypes.string,
    nodeClassName: PropTypes.string,
    selectIsSelected: PropTypes.func,
    selectSecondaryLabel: PropTypes.func,
    scrollParentRef: PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
    allowFoldersSelection: PropTypes.bool,
};

FolderTree.defaultProps = {
    selectedFolders: [],
    selectedSensors: [],
    toggleSelected: undefined,
    sensorTypeFilter: undefined,
    labelsFilter: undefined,
    readingTypesFilter: undefined,
    containerClassName: '',
    nodeClassName: '',
    selectIsSelected: undefined,
    selectSecondaryLabel: undefined,
    scrollParentRef: undefined,
    allowFoldersSelection: true,
};
