import { useAppSelector } from '@infogrid/core-ducks';
import { getSensor, getSensorConfig } from '@infogrid/sensors-configuration';
import { selectActiveTimeZone, selectUserPreferredTempUnit } from '@infogrid/user-ducks';
import { convertDateTimeInputsToISO, getNowDateTime } from '@infogrid/utils-dates';
import {
    TEMP_UNIT,
    convertFahrenheitDeltaToCelsius,
    convertFahrenheitToCelsius,
} from '@infogrid/utils-measurements';
import { Button } from '@material-ui/core';
import { useFormik } from 'formik';
import round from 'lodash/round';
import { useCallback, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCalibratePipeOffset } from 'apiHooks/sensors/installation/hooks';
import type { TemperatureOffsetParams } from 'utils/types/ts/pipeMonitoringSensors';

import InstallationFlowWarning from '../InstallationFlowWarning';
import { DISCARD_MODAL_SETTINGS } from '../constants';
import { useInstallationFlowContext } from '../context';
import {
    INSTALLATION_FLOW_ACTIONS,
    useInstallationFlowContextDispatcher,
} from '../reducer';
import TemperatureOffsetReminder from './TemperatureOffsetReminder';
import {
    TEMP_OFFSET_READING_TYPES,
    temperatureOffsetConfigurationSchema,
    validateTempOffsetDateOfReading,
    validateTempOffsetErrors,
} from './utils';

const TemperatureOffsetConfigurationContainer = () => {
    const { t } = useTranslation('sensors');
    const { sensor } = useInstallationFlowContext();
    const dispatchInstallationFlowContext = useInstallationFlowContextDispatcher();

    const deviceName = sensor?.device_name ?? '';
    const deviceType = sensor?.type_code;
    const sensorConfig = deviceType && getSensorConfig(deviceType);
    const supportsLiveCalibration = sensor
        ? getSensor(sensor).supportsLiveCalibration()
        : false;

    const timeZone = useAppSelector(selectActiveTimeZone);
    const userPreferredTempUnit = useAppSelector(selectUserPreferredTempUnit);

    const { mutate: calibratePipeOffset } = useCalibratePipeOffset();
    const [isDiscardChangesWarningOpen, setIsDiscardChangesWarningOpen] = useState(false);

    const formik = useFormik<TemperatureOffsetParams>({
        initialValues: {
            reading_type: TEMP_OFFSET_READING_TYPES.NOW.value,
            // `temperature` can be either a probe reading (gen2) or temperature offset (gen1)
            temperature: undefined,
            ...getNowDateTime(),
        },
        onSubmit: (formValues) => {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
            });

            const timeOfReading = convertDateTimeInputsToISO(
                formValues.date_of_reading as string,
                formValues.time_of_reading,
                timeZone,
            );

            const convertFn = supportsLiveCalibration
                ? convertFahrenheitToCelsius
                : convertFahrenheitDeltaToCelsius;

            const temperature =
                userPreferredTempUnit === TEMP_UNIT.FAHRENHEIT
                    ? round(convertFn(Number(formValues.temperature)), 2)
                    : Number(formValues.temperature);

            calibratePipeOffset(
                {
                    deviceName,
                    data: {
                        temperature,
                        time_of_reading: timeOfReading,
                    },
                },
                {
                    onSuccess: () => {
                        dispatchInstallationFlowContext({
                            type: INSTALLATION_FLOW_ACTIONS.INSTALLATION_SUCCEED,
                        });
                    },
                    onError: (err) => {
                        if (err.parsedError) {
                            const { errors, message } = err.parsedError;

                            if (errors) {
                                formik.setErrors(errors);
                            }

                            if (message) {
                                formik.setStatus({ message });
                            }
                        }
                    },
                    onSettled: () => {
                        dispatchInstallationFlowContext({
                            type: INSTALLATION_FLOW_ACTIONS.TOGGLE_LOADING,
                        });
                    },
                },
            );
        },
        validate: (formValues) => ({
            ...validateTempOffsetDateOfReading(formValues, timeZone),
            ...validateTempOffsetErrors(formValues),
        }),
        validationSchema: temperatureOffsetConfigurationSchema,
    });

    const onResume = useCallback(() => {
        setIsDiscardChangesWarningOpen(false);
    }, []);

    const onDiscardChangesWarning = useCallback(() => {
        setIsDiscardChangesWarningOpen(false);
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.CONFIGURE_DEVICE,
        });
    }, [dispatchInstallationFlowContext]);

    const onBack = useCallback(() => {
        setIsDiscardChangesWarningOpen(true);
    }, []);

    const onSkipStep = useCallback(() => {
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.INSTALLATION_SUCCEED,
        });
    }, [dispatchInstallationFlowContext]);

    const renderActions = useCallback(
        () => (
            <>
                <Button onClick={onBack} color="primary" data-cypress="back">
                    {t('Back')}
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    data-cypress="later"
                    onClick={onSkipStep}
                >
                    {t('Save')}
                </Button>
            </>
        ),
        [onBack, onSkipStep, t],
    );

    if (!sensor || !sensorConfig) {
        return null;
    }

    return (
        <>
            <TemperatureOffsetReminder
                sensor={sensor}
                sensorName={sensorConfig.label}
                sensorType={deviceType}
                renderActions={renderActions}
            />
            <InstallationFlowWarning
                isOpen={isDiscardChangesWarningOpen}
                onResume={onResume}
                onDiscard={onDiscardChangesWarning}
                warningMessage={DISCARD_MODAL_SETTINGS.WARNING_MESSAGE}
                onDiscardButtonText={DISCARD_MODAL_SETTINGS.ON_DISCARD_BUTTON_TEXT}
            />
        </>
    );
};

export default memo(TemperatureOffsetConfigurationContainer);
