import type { EventsWebhook } from '@infogrid/core-types';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { Button, TextField } from '@material-ui/core';
import classNames from 'classnames';
import type { FormikProps } from 'formik';
import { useField } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ResetEventsWebhookModal from '../ResetEventsWebhookModal';
import { useUrlWithButtonsFieldStyles } from './styles';

interface UrlWithButtonsFieldProps {
    formik: FormikProps<EventsWebhook>;
    name: string;
    showReset?: boolean;
    onReset?: () => void;
    classes?: {
        root?: string;
        field?: string;
        buttonReset?: string;
    };
}

const UrlWithButtonsField = ({
    formik,
    name,
    showReset = false,
    onReset,
    classes,
}: UrlWithButtonsFieldProps): JSX.Element => {
    const styles = useUrlWithButtonsFieldStyles();

    const { t } = useTranslation('integrations');

    const [
        isResetEventsWebhookModalOpen,
        closeResetEventsWebhookModal,
        openResetEventsWebhookModal,
    ] = useIsOpenState();

    const [{ value, onBlur, onChange }, { error, touched }] = useField<string>(name);

    const onConfirm = () => {
        if (onReset) {
            onReset();
        }

        closeResetEventsWebhookModal();
    };

    return (
        <div className={classNames(styles.root, classes?.root)}>
            <TextField
                error={!!touched && !!error}
                helperText={touched && error && t(error)}
                name={name}
                label={t('URL')}
                value={value}
                inputProps={{
                    'data-cypress': 'events-webhook-url',
                    'data-testid': 'events-webhook-url',
                }}
                type="text"
                className={classNames(classes?.field)}
                variant="outlined"
                onChange={onChange}
                onBlur={onBlur}
                disabled={showReset}
            />
            {showReset && (
                <Button
                    data-cypress="events-webhook-secret-reset"
                    data-testid="events-webhook-secret-reset"
                    className={classNames(classes?.buttonReset)}
                    onClick={openResetEventsWebhookModal}
                    color="secondary"
                >
                    {t('RESET URL')}
                </Button>
            )}
            {isResetEventsWebhookModalOpen && (
                <ResetEventsWebhookModal
                    eventsWebhookId={formik.values.id}
                    onClose={closeResetEventsWebhookModal}
                    onConfirm={onConfirm}
                    open={isResetEventsWebhookModalOpen}
                />
            )}
        </div>
    );
};

export default memo(UrlWithButtonsField);
