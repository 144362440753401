import { makeStyles } from '@material-ui/core';

export const ICONS = {
    FULLSCREEN_ICON: 'fal fa-expand-arrows',
    EXPAND_ICON: 'fal fa-expand-alt',
    COLLAPSE_ICON: 'fal fa-compress-alt',
    REFRESH_ICON: 'fal fa-sync-alt',
    CONFIGURE_ICON: 'fal fa-cog',
};

export const useGenericActionsStyles = makeStyles({
    actionsMenuContent: {
        transform: 'translate(-90%, 0)',
        '& > div': {
            minWidth: '120px',
        },
    },
});
