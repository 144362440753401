import { Typography } from '@material-ui/core';
import classNames from 'classnames';

import { usePublicDisplayWidgetStyles } from './styles';

export interface PublicDisplayWidgetProps {
    children: JSX.Element;
    icon: string;
    title: string | JSX.Element;
}

export const PublicDisplayWidget = ({
    children,
    icon,
    title,
}: PublicDisplayWidgetProps): JSX.Element => {
    const styles = usePublicDisplayWidgetStyles();

    return (
        <div className={styles.container}>
            <div className={styles.titleRow}>
                <Typography variant="h3">{title}</Typography>
                <i className={classNames(icon, styles.icon)} />
            </div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};
