import type { AxiosParsedError } from '@infogrid/core-types';
import { selectBuildings } from '@infogrid/locations-api';
import type { Building } from '@infogrid/locations-types';
import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { usePaginatedBuildings } from 'apiHooks/floorPlan/buildings/hooks';
import type { BuildingsParams } from 'apiHooks/floorPlan/buildings/types';

interface UseBuildingsTableDataResult {
    queryParams: BuildingsParams;
    buildings: Building[];
    buildingsCount: number;
    fetchMoreBuildings: () => Promise<void>;
    /**
     * The initial loading state, while the table is being populated for the first time
     */
    isLoadingBuildings: boolean;
    /**
     * Additional loading events including infinite scroll pagination, sorting, filtering and
     * background fetching while cached data is displayed
     */
    isFetchingBuildings: boolean;
    isError: boolean;
    error?: AxiosParsedError<{ detail: string }>;
}

interface UseBuildingsTableDataProps {
    includeAlerts?: boolean;
}

export const useBuildingsTableData = (
    props?: UseBuildingsTableDataProps,
): UseBuildingsTableDataResult => {
    const location = useLocation();
    const queryParams = useMemo(() => {
        const defaultParams = {
            'include-user-actions': true,
            include_alerts: props?.includeAlerts,
        };

        if (!location.search) {
            return defaultParams;
        }

        const query = qs.parse(location.search, { ignoreQueryPrefix: true });

        return {
            ...defaultParams,
            q: query?.q,
            building_types: query?.building_types,
            countries: query?.countries,
            ordering: query?.ordering,
            page_size: 25,
        };
    }, [location.search, props?.includeAlerts]);

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        isFetchedAfterMount,
        isPreviousData,
        isError,
        error,
    } = usePaginatedBuildings(queryParams);

    const isLoadingFirstPage = isFetching && !isFetchedAfterMount;

    const { buildings, buildingsCount } = useMemo(() => selectBuildings(data), [data]);

    const hasContent = !!buildingsCount;

    const fetchMoreBuildings = useCallback(async () => {
        if (isFetchingNextPage || !hasNextPage) {
            return;
        }

        fetchNextPage();
    }, [fetchNextPage, isFetchingNextPage, hasNextPage]);

    return {
        queryParams,
        buildings,
        buildingsCount,
        fetchMoreBuildings,
        isError,
        isLoadingBuildings: isLoadingFirstPage && !hasContent && !isPreviousData,
        isFetchingBuildings: isFetching,
        error: error || undefined,
    };
};
