import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { spaceControllers } from '@infogrid/locations-api';
import type { SpacesParams, UpdateSpaceParams } from '@infogrid/locations-api';
import type { Space } from '@infogrid/locations-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import {
    cancelSpace,
    cancelSpaces,
    invalidateSpace,
    invalidateSpaces,
} from '../accessors';

export const useUpdateSpace = (
    spacesParams: SpacesParams,
): UseMutationResult<Space, AxiosParsedError, UpdateSpaceParams> => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('estate');

    return useMutationWithToast<Space, AxiosParsedError, UpdateSpaceParams>(
        ({ id, data }) =>
            wrapResponsePromise<Space>(spaceControllers.updateSpace(id, data)),
        {
            onSuccess: async ({ id }) => {
                await cancelSpace(id);
                await invalidateSpace(id);
                await cancelSpaces(spacesParams);
                await invalidateSpaces(spacesParams);

                dispatch(
                    toastSuccess({
                        message: t('Space updated successfully'),
                    }),
                );
            },
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error updating space'),
                    }),
                );
            },
        },
        {
            toastMessage: t('Updating space is in progress'),
        },
    );
};
