import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { useCreateWidget } from '@infogrid/dashboards-hooks';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { Button } from '@material-ui/core';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import AddWidgetModal from 'views/dashboards/components/modals/AddWidgetModal/AddWidgetModal';
import { trackDashboardEvent } from 'views/dashboards/utils/analytics';
import { useDashboardContext } from 'views/dashboards/utils/context';

import { useAddWidgetStyles } from './styles';

export interface AddWidgetProps {
    dashboardId: number;
}

const AddWidget = ({ dashboardId }: AddWidgetProps) => {
    const styles = useAddWidgetStyles();
    const { t } = useTranslation('dashboard');

    const [addWidgetModalOpen, closeAddWidgetModal, openAddWidgetModal] =
        useIsOpenState();

    const { mutate: createWidget, isLoading } = useCreateWidget();

    const { setConfigurationModalWidgetId } = useDashboardContext();

    const openWidgetConfigurationModalForId = useCallback(
        (widgetId) => {
            setConfigurationModalWidgetId(widgetId, true);
        },
        [setConfigurationModalWidgetId],
    );

    const onConfirmClick = useCallback(
        (widget: WidgetMetadataType) => {
            trackDashboardEvent(USER_ACTIONS.PRESSED, 'add widget', {
                type: widget.type,
                widgetName: widget.defaultName ? widget.defaultName : widget.label,
                dashboardId,
            });

            createWidget(
                {
                    dashboardId,
                    data: {
                        name: widget.defaultName
                            ? t(widget.defaultName)
                            : t(widget.label),
                        type: widget.type,
                    },
                },

                {
                    onSuccess: (newWidget) => {
                        closeAddWidgetModal();
                        openWidgetConfigurationModalForId(newWidget.id);
                    },
                },
            );
        },
        [
            t,
            closeAddWidgetModal,
            createWidget,
            dashboardId,
            openWidgetConfigurationModalForId,
        ],
    );

    const buttonClasses = {
        root: styles.addWidgetButton,
    };

    return (
        <div className={styles.container}>
            <Button
                classes={buttonClasses}
                color="primary"
                data-cypress="add-widget-btn"
                onClick={openAddWidgetModal}
                startIcon={<i className="far fa-plus" aria-hidden />}
                variant="contained"
            >
                {t('Add widget')}
            </Button>

            <AddWidgetModal
                loading={isLoading}
                onClose={closeAddWidgetModal}
                onConfirm={onConfirmClick}
                open={addWidgetModalOpen}
            />
        </div>
    );
};

export default memo(AddWidget);
