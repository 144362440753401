import { setUserSolutions } from '@infogrid/solution-views-ducks';
import { put } from 'redux-saga/effects';

import { fetchGuardInitial } from 'sagas/helpers/fetchingGuard';
import api from 'services/api';

import { getSortedSolutions } from './utils';

const fetchUserSolutions = fetchGuardInitial(
    function* loadUserSolutions() {
        const { results } = yield api.solutions.fetch();

        const sortedResults = getSortedSolutions({ data: results });

        yield put(setUserSolutions(sortedResults));
    },
    () => 'solutions-solutions',
);

export default fetchUserSolutions;
