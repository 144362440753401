import { Icon, IconButton, Button } from '@material-ui/core';
import classNames from 'classnames';
import { useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import InstallationFlow from 'components/InstallationFlow';

import NavbarLogo from '../../NavbarLogo';
import NavbarMobileContext from '../context';
import { useNavbarHeaderStyles } from './styles';

const MENU_ICON_NAME = 'far fa-bars';
const IDENTIFY_BUTTON_ICON_NAME = 'far fa-sensor';

export const NAVBAR_HEADER_PORTAL_TARGET_ID = 'navbarHeader';

export interface NavbarHeaderMobileProps {
    classes: {
        container: string;
        image: string;
        text: string;
    };
    logo: string;
    logoText: string;
    navbarVisible: boolean;
    onButtonClick: (visible: boolean) => void;
    sensorsLink: Location;
}

const NavbarHeader = ({
    classes,
    logo,
    logoText,
    navbarVisible,
    onButtonClick,
    sensorsLink,
}: NavbarHeaderMobileProps): JSX.Element => {
    const { setHeaderRenderStatus } = useContext(NavbarMobileContext);
    const history = useHistory();
    const { t } = useTranslation('common');

    // TODO revise how to use portals instead of condition
    const { pathname } = useLocation();
    const isSensorsView = ['/sensors/', '/sensors'].includes(pathname);

    useEffect(() => {
        setHeaderRenderStatus(true);
    }, [setHeaderRenderStatus]);

    const headerClasses = useNavbarHeaderStyles({ navbarVisible });
    const iconClasses = classNames(MENU_ICON_NAME, headerClasses.menuIcon);
    const identifyButtonIconClasses = classNames(
        IDENTIFY_BUTTON_ICON_NAME,
        headerClasses.identifyButtonIcon,
    );

    const clickHandler = useCallback(() => {
        onButtonClick(!navbarVisible);
    }, [navbarVisible, onButtonClick]);

    const goToMainPage = useCallback(() => {
        history.push('/');
    }, [history]);

    const goToIdentifySensor = useCallback(() => {
        history.push(sensorsLink);
    }, [sensorsLink, history]);

    return (
        <header className={headerClasses.root}>
            <IconButton className={headerClasses.menuIconButton} onClick={clickHandler}>
                <Icon className={iconClasses} />
            </IconButton>

            <NavbarLogo
                classes={{
                    container: classNames(headerClasses.logoContainer, classes.container),
                    image: classNames(headerClasses.logoImage, classes.image),
                    text: classNames(headerClasses.logoText, classes.text),
                }}
                logo={logo}
                logoText={logoText}
                titleVisible={navbarVisible}
                onClick={goToMainPage}
            />

            <div
                className={headerClasses.contentFromPage}
                id={NAVBAR_HEADER_PORTAL_TARGET_ID}
            />

            {!navbarVisible && isSensorsView && (
                <div className={headerClasses.installationButtons}>
                    <InstallationFlow.Button />
                    <Button
                        className={headerClasses.identifyButton}
                        onClick={goToIdentifySensor}
                        variant="outlined"
                    >
                        <Icon className={identifyButtonIconClasses} />
                        {t('Identify')}
                    </Button>
                </div>
            )}
        </header>
    );
};

export default NavbarHeader;
