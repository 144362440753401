import classNames from 'classnames';
import type { ReactNode } from 'react';

interface Props {
    className?: string;
    small?: boolean;
    large?: boolean;
    title: string;
    content?: ReactNode;
    rightElement?: ReactNode;
}

const TitleBar = ({
    className = '',
    small = false,
    large = false,
    title,
    content = null,
    rightElement = null,
}: Props) => {
    let Heading;
    let marginBottomClass;

    if (large) {
        Heading = 'h3';
        marginBottomClass = 'mb-5';
    } else if (small) {
        Heading = 'h5';
        marginBottomClass = 'mb-3';
    } else {
        Heading = 'h4';
        marginBottomClass = 'mb-4';
    }

    return (
        <div
            className={classNames(
                'd-flex',
                'align-items-center',
                marginBottomClass,
                className,
            )}
        >
            {/* @ts-expect-error: todor, minor */}
            <Heading className="mr-5 mb-0">{title}</Heading>
            {content}
            {rightElement ? <div className="ml-auto">{rightElement}</div> : null}
        </div>
    );
};

export default TitleBar;
