import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import { cleanInactiveAlertConfigurationsQuery } from 'apiHooks/alertConfigurations/accessors';

import { controllers } from './controller';
import type { SmartCleaningReportPayload } from './types';

interface Params {
    id: number;
    data: SmartCleaningReportPayload;
}

export const useUpdateSmartCleaningReport = (): UseMutationResult<
    SmartCleaningReportPayload,
    AxiosParsedError,
    Params
> => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('solutions');

    return useMutationWithToast(
        ({ id, data }) =>
            wrapResponsePromise(controllers.updateSmartCleaningReport(id, data)),
        {
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error updating smart cleaning report'),
                    }),
                );
            },
            onSuccess: () => {
                cleanInactiveAlertConfigurationsQuery();
                dispatch(
                    toastSuccess({
                        message: t('Smart cleaning report was updated'),
                    }),
                );
            },
        },
        {
            toastMessage: t('Updating smart cleaning report'),
        },
    );
};
