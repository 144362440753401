import { makeStyles } from '@material-ui/core';

export const useTrendTooltipStyles = makeStyles(() => ({
    value: {
        width: 35,
    },
}));

export const useProgressBubbleStyles = makeStyles(() => ({
    root: {
        width: 35,
    },
}));

export const useMenuItemStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

export const useTooltipStyles = makeStyles((theme) => ({
    tooltip: {
        width: 150,
        backgroundColor: theme.palette.common.white,
        boxShadow: theme.shadows[3],
        color: theme.palette.text.primary,
    },
}));
