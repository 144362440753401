import { StackedBarChart, buildHighchartsTooltip } from '@infogrid/components-highcharts';
import { WidgetErrorMessages } from '@infogrid/dashboards-constants';
import type { Options } from 'highcharts';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { generateChartData } from 'views/dashboards/widgets/TouchCountWidget/utils';
import {
    getAxisTimeFormatByDataFrequency,
    getTooltipTimeFormatByDataFrequency,
} from 'views/dashboards/widgets/utils';

import Tooltip from '../../common/Tooltip';
import WidgetAlert from '../../common/WidgetAlert';
import type { TouchCountData, TouchCountWidget } from '../types';
import {
    tooltipPointColorExtractor,
    tooltipPointNameExtractor,
    tooltipPointValueExtractor,
} from './utils';

interface ContentProps {
    data?: TouchCountData;
    isConfigured: boolean;
    isLoadingDataError: boolean;
    widget: TouchCountWidget;
}

const Content = ({ data, widget, isConfigured, isLoadingDataError }: ContentProps) => {
    const { t } = useTranslation('dashboard');

    const {
        calculators: { touch_count, touch_counter },
        data_frequency: dataFrequency,
        time_zone: timeZone,
        time_period_from: startDate,
        time_period_to: endDate,
    } = widget?.configuration;

    const transformedData = generateChartData({
        touch_count,
        touch_counter,
        touch_count_graph_rows: data?.touch_count_graph_rows,
        touch_counter_graph_rows: data?.touch_counter_graph_rows,
        touch_count_sensor_names: data?.touch_count_sensor_names,
        touch_counter_sensor_names: data?.touch_counter_sensor_names,
    });

    const timeFormat = getAxisTimeFormatByDataFrequency(
        dataFrequency,
        startDate,
        endDate,
    );
    const tooltipTimeFormat = getTooltipTimeFormatByDataFrequency(dataFrequency);

    const chartProps: Options = {
        xAxis: {
            labels: {
                formatter({ chart }) {
                    // We know that this is a number, but the types don't as it can be a number or string
                    return chart.time.dateFormat(timeFormat, this.value as number);
                },
            },
            startOnTick: false,
            type: 'datetime',
        },
        yAxis: {
            allowDecimals: false,
        },
        tooltip: {
            formatter({ chart }) {
                return buildHighchartsTooltip({
                    content: (
                        <Tooltip
                            // @ts-expect-error: INS-781 Refactor tooltips to accept
                            // extended TooltipFormatterContextObject or pass in a smaller object
                            // related to the tooltip only
                            data={this}
                            pointColorExtractor={tooltipPointColorExtractor}
                            pointKeyExtractor={tooltipPointNameExtractor}
                            pointNameExtractor={tooltipPointNameExtractor}
                            pointValueExtractor={tooltipPointValueExtractor}
                        />
                    ),
                    periodLabel: chart.time.dateFormat(
                        tooltipTimeFormat,
                        // We know that this is not a string, but the types think it could be
                        this.points?.[0].x as number | undefined,
                    ),
                });
            },
        },
        time: {
            timezone: timeZone,
        },
        series: transformedData,
    };

    if (!isConfigured) {
        return (
            <WidgetAlert severity="warning">
                {t(WidgetErrorMessages.notConfigured)}
            </WidgetAlert>
        );
    }

    if (isLoadingDataError) {
        return (
            <WidgetAlert severity="error">
                {t(WidgetErrorMessages.dataLoadError)}
            </WidgetAlert>
        );
    }

    if (!data || data.no_data) {
        return <WidgetAlert severity="info">{t(WidgetErrorMessages.noData)}</WidgetAlert>;
    }

    return <StackedBarChart chartProps={chartProps} />;
};

export default memo(Content);
