import type { SeriesPoint } from '@infogrid/dashboards-constants';
import { i18n } from '@infogrid/utils-i18n';

export const tooltipPointColorExtractor = (point: SeriesPoint): string => point.color;

export const tooltipPointNameExtractor = (point: SeriesPoint): string =>
    point.series.name;

export const tooltipPointValueExtractor = (point: SeriesPoint): string | number => {
    const { desksOccupiedData, showDesksOccupied } = point.series.options;
    const { index } = point.point;

    const desksOccupied = desksOccupiedData[index] || 0;

    const desksOccupiedLabel = showDesksOccupied
        ? i18n.t(` / {{desksOccupied}} desks`, {
              desksOccupied,
              count: desksOccupied,
              defaultValue___one: ` / ${desksOccupied} desk`,
              defaultValue___other: ` / ${desksOccupied} desks`,
              ns: 'dashboard',
          })
        : '';

    return `${point.y}%${desksOccupiedLabel}`;
};
