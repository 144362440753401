import type { SensorVendor } from '@infogrid/sensors-constants';
import { SENSOR_VENDORS } from '@infogrid/sensors-constants';

import type { InstallationFlowVendorStep } from 'components/InstallationFlow/constants';
import {
    INSTALLATION_FLOW_STEPS,
    INSTALLATION_FLOW_VENDORS,
} from 'components/InstallationFlow/constants';

import type { RegisterQueryParams } from '../hooks/useRegisterSensor';

const buildRegisterQueryParams = (
    vendorStep: InstallationFlowVendorStep,
    registerData: string,
): RegisterQueryParams => {
    if (vendorStep === INSTALLATION_FLOW_STEPS.qrCodeScanner) {
        return {
            qr_code: registerData,
        };
    }

    const sensorVendor = INSTALLATION_FLOW_VENDORS[vendorStep] as SensorVendor;

    if (
        sensorVendor === SENSOR_VENDORS.AIRTHINGS ||
        sensorVendor === SENSOR_VENDORS.URB
    ) {
        // When manually identifying Airthings sensors, registerData contains the
        // serial number and device ID separated by a space, to match the QR code
        const [serialNumber, deviceId] = registerData.split(' ');

        return {
            vendor: sensorVendor,
            serial_number: serialNumber,
            airthings_device_id: deviceId,
        };
    }

    return {
        vendor: sensorVendor,
        serial_number: registerData,
    };
};

export default buildRegisterQueryParams;
