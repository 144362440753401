import { registerTranslationKey } from '@infogrid/utils-i18n';

// Keep these synced with consumer in sensors/consumers.py
export const IDENTIFY_STATUS = {
    IN_PROGRESS: 0,
    BEGIN: 1,
    IDENTIFY: 2,
    CONFIRM: 3,
    SHOW_MANAGERS: 5,
    SHOW_UNKNOWN: 6,
    SHOW_SENSOR_VIEW: 7,
    SHOW_OTHER_ORG: 8,
};

export const IDENTIFY_STREAM_NAME = 'identifySensor';

export const IDENTIFY_INSTRUCTIONS = Object.freeze({
    EMPTY: '',
    INITIAL: registerTranslationKey('Please wait, instructions will follow...', {
        ns: 'sensors',
    }),
    START: registerTranslationKey(
        'Press and hold the DT device for 3 seconds to identify.',
        { ns: 'sensors' },
    ),
    IN_PROGRESS: registerTranslationKey('Identification in progress, please wait ...', {
        ns: 'sensors',
    }),
    IDENTIFY: registerTranslationKey('Press the sensor again to confirm', {
        ns: 'sensors',
    }),
    CONFIRM: registerTranslationKey(
        'Sensor identified, please wait, sensor information will be shown soon ...',
        { ns: 'sensors' },
    ),
});

export const IDENTIFY_DISCONNECT_HELP_MESSAGE = Object.freeze({
    title: registerTranslationKey('Unable to establish connection', { ns: 'sensors' }),
    info: [
        registerTranslationKey(
            'This could be due to an authorisation failure. Try again to attempt to complete identification.',
            { ns: 'sensors' },
        ),
        registerTranslationKey(
            'If repeated attempts fail, try logging out, clearing your cookies and then logging back in. Alternatively try using a different browser.',
            { ns: 'sensors' },
        ),
    ],
    level: 'error',
});

export const IDENTIFY_DISCONNECT_TIMEOUT_HELP_MESSAGE = Object.freeze({
    title: registerTranslationKey('Identification process has timed out', {
        ns: 'sensors',
    }),
    info: [
        registerTranslationKey(
            'Try again to re-start the manual identification process.',
            { ns: 'sensors' },
        ),
    ],
    level: 'error',
});
