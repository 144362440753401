import { queryClient } from '@infogrid/core-api';

import {
    getFolderKey,
    getSubFolderKey,
    getSubfoldersKey,
    getFoldersKey,
} from './getQueryKeys';

export const getFolder = (id) => queryClient.getQueryData(getFolderKey(id));

export const invalidateSubfolders = (folderId) =>
    queryClient.invalidateQueries(getSubFolderKey(folderId));

export const invalidateFolder = (folderId) =>
    queryClient.invalidateQueries(getFolderKey(folderId));

export const updateFolderName = (folderId, name) =>
    queryClient.setQueryData(getFolderKey(folderId), (old) => ({ ...old, name }));

export const cleanInactiveSubfolders = (folderId) =>
    queryClient.removeQueries(getSubFolderKey(folderId), {
        active: false,
        inactive: true,
    });

export const cleanAllInactiveSubfolders = () =>
    queryClient.removeQueries(getSubfoldersKey(), {
        active: false,
        inactive: true,
    });

export const cleanAllInactiveFolders = () =>
    queryClient.removeQueries(getFoldersKey(), {
        active: false,
        inactive: true,
    });

export const invalidateAllSubfolders = () =>
    queryClient.invalidateQueries(getSubfoldersKey());

export const invalidateAllFolders = () => queryClient.invalidateQueries(getFoldersKey());
