import { sagaHotReloader, store } from '@infogrid/core-ducks';

import rootSaga from 'sagas';

import { addReducers } from './addReducers';

export const configureStore = () => {
    addReducers();

    // This is a fake action to ensure that reducers
    // have been initialised to ensure we don't hit
    // errors in the Redux sagas when accessing selectors
    store.dispatch({ type: 'INIT_REDUCERS' });

    sagaHotReloader.startRootSaga(rootSaga);
};
