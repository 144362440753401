import { memo } from 'react';

import { useAllBuildings } from 'apiHooks/floorPlan/buildings/hooks';
import {
    useBuildingScores,
    useAllBuildingScore,
} from 'views/dashboards/apiHooks/widgets/hooks';

import type { HealthyBuildingScoreWidget } from '../../types';
import CompareModal from './CompareModal';

interface Props {
    onClose: () => void;
    widget: HealthyBuildingScoreWidget;
}

const CompareModalContainer = ({ widget, onClose }: Props) => {
    const { buildings } = useAllBuildings();

    const allBuildingIds = buildings.map(({ id }) => id);

    const { buildings_ids: buildingIds } = widget.configuration.healthy_building;
    const getQueryParams = () => {
        return {
            building_ids: buildingIds.length === 1 ? allBuildingIds : buildingIds,
        };
    };

    const {
        data: perBuildingScores,
        isLoading: isPerBuildingLoading,
        isError: isPerBuildingError,
    } = useBuildingScores(widget.id, {
        getQueryParams,
    });
    const {
        data: allBuildingScore,
        isLoading: isAllBuildingLoading,
        isError: isAllBuildingError,
    } = useAllBuildingScore(widget.id, {
        getQueryParams,
    });

    if (!allBuildingScore || !perBuildingScores) {
        return null;
    }

    const widgetData = {
        scores: perBuildingScores,
        all_buildings_score: allBuildingScore,
    };

    return (
        <CompareModal
            selectedBuildings={buildingIds}
            widgetData={widgetData}
            onClose={onClose}
            isError={isPerBuildingError || isAllBuildingError}
            isLoading={isPerBuildingLoading || isAllBuildingLoading}
        />
    );
};

export default memo(CompareModalContainer);
