import { combineReducers } from 'redux';

export const STATE_KEY = 'sensors';

const SET_PERMISSIONS_DIALOG_OPEN = `${STATE_KEY}/SET_PERMISSIONS_DIALOG_OPEN`;
const SET_SAVE_BUTTON_DISABLED = `${STATE_KEY}/SET_SAVE_BUTTON_DISABLED`;
const TOGGLE_SENSOR_EXTRA_DETAIL_OPEN = `${STATE_KEY}/TOGGLE_SENSOR_EXTRA_DETAIL_OPEN`;

export const OrderingName = 'sensorList';

const initialState = {
    permissionsDialogOpen: false,
    isSaveButtonDisabled: false,
};

export function permissionsDialogOpenReducer(
    state = initialState.permissionsDialogOpen,
    action,
) {
    switch (action.type) {
        case SET_PERMISSIONS_DIALOG_OPEN:
            return action.permissionsDialogOpen;

        default:
            return state;
    }
}

export function saveButtonDisabledReducer(
    state = initialState.isSaveButtonDisabled,
    action,
) {
    switch (action.type) {
        case SET_SAVE_BUTTON_DISABLED:
            return action.isSaveButtonDisabled;

        default:
            return state;
    }
}

export function sensorExtraDetailOpenReducer(state = {}, action) {
    switch (action.type) {
        case TOGGLE_SENSOR_EXTRA_DETAIL_OPEN: {
            const newState = { ...state };

            if (typeof newState[action.deviceName] !== 'undefined') {
                delete newState[action.deviceName];
            } else {
                newState[action.deviceName] = true;
            }

            return newState;
        }

        default:
            return state;
    }
}

const allReducers = {
    permissionsDialogOpen: permissionsDialogOpenReducer,
    saveButtonDisabled: saveButtonDisabledReducer,
    sensorExtraDetailOpen: sensorExtraDetailOpenReducer,
};

export default combineReducers(allReducers);

export const openPermissionsDialog = () => ({
    type: SET_PERMISSIONS_DIALOG_OPEN,
    permissionsDialogOpen: true,
});
export const closePermissionsDialog = () => ({
    type: SET_PERMISSIONS_DIALOG_OPEN,
    permissionsDialogOpen: false,
});
export const setSaveButtonDisabled = () => ({
    type: SET_SAVE_BUTTON_DISABLED,
    isSaveButtonDisabled: true,
});
export const setSaveButtonEnabled = () => ({
    type: SET_SAVE_BUTTON_DISABLED,
    isSaveButtonDisabled: false,
});
export const toggleSensorExtraDetailOpen = (deviceName) => ({
    type: TOGGLE_SENSOR_EXTRA_DETAIL_OPEN,
    deviceName,
});

export const selectPermissionsDialogOpen = (state) =>
    state[STATE_KEY].permissionsDialogOpen;
export const selectSaveButtonDisabled = (state) => state[STATE_KEY].saveButtonDisabled;
export const selectSensorExtraDetailOpen = (state, deviceName) =>
    state[STATE_KEY].sensorExtraDetailOpen[deviceName] || false;

export const sensorSelectors = {
    permissionsDialogOpen: selectPermissionsDialogOpen,
    saveButtonDisabled: selectSaveButtonDisabled,
    sensorExtraDetailOpen: selectSensorExtraDetailOpen,
};
