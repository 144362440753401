import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { defaultRollingCalendarPeriods } from '@infogrid/utils-dates';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import type { Moment } from 'moment';
import moment from 'moment';

import DateRange from 'views/dashboards/components/modals/ConfigureWidgetModal/DateRange';
import Sensors from 'views/dashboards/components/modals/ConfigureWidgetModal/Sensors';

import DisplayOptions from './DisplayOptions';

// Date (day) from which dates should be enabled for Pipe Monitoring Widget (SM-286)
// This should be removed in Feb/Mar 2022 as part of SM-287
const PIPE_MONITORING_AVAILABLE_DATE_START = '2021-10-01';

export const widgetConfigurationModalTabs = [
    {
        id: 'sensors',
        name: registerTranslationKey('Sensors', { ns: 'common' }),
        component: Sensors,
        attributes: {
            sensorTypeFilter: SENSOR_TYPE.TYPE_LEGIONNAIRE,
            maxSelectedSensors: 250,
        },
    },
    {
        id: 'date-range',
        name: registerTranslationKey('Date Range', { ns: 'common' }),
        component: DateRange,
        attributes: {
            enableExcludeWeekends: false,
            enableWorkHours: false,
            dateRangePickerProps: {
                isDayBlocked: (day: Moment): boolean =>
                    moment(day).isBefore(PIPE_MONITORING_AVAILABLE_DATE_START),
                periods: defaultRollingCalendarPeriods.slice(0, -1),
                readOnly: true,
            },
            // TODO: SM-287 remove enableDateRangeAlert prop
            enableDateRangeAlert: true,
        },
    },
    {
        id: 'display-options',
        name: registerTranslationKey('Display Options', { ns: 'common' }),
        component: DisplayOptions,
    },
];
