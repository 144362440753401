import { selectOrganizationId } from '@infogrid/user-ducks';
import { select } from 'redux-saga/effects';

function* organizationMutateKwargs() {
    const organizationId = yield select(selectOrganizationId);

    return {
        org_pk: organizationId,
    };
}

export { organizationMutateKwargs };
