import loadable from '@loadable/component';
import type { RouteConfig } from 'react-router-config';

import RenderChildren from 'components/RenderChildren';
import { userIsManager } from 'decorators/auth';

const WelcomeRoot = loadable(() => import('views/welcome/pages/welcomeRoot'));

export const createSettingsRoutes = (PageNotFoundRoute: RouteConfig): RouteConfig => ({
    path: '/settings/',
    name: 'settings',
    component: userIsManager(RenderChildren),
    routes: [
        {
            path: '/settings/',
            exact: true,
            name: 'root-redirect',
            pageName: 'Get started',
            component: WelcomeRoot,
        },
        PageNotFoundRoute,
    ],
});
