import { makeStyles } from '@material-ui/core';

export const useInstallationSuccessView = makeStyles((theme) => ({
    finishButton: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    nextButtonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    actions: {
        alignItems: 'flex-end',
    },
}));
