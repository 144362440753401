import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import { routesManager } from '@infogrid/core-routing';
import { setUser, getUser } from '@infogrid/user-ducks';
import { entitiesActions } from '@thorgate/spa-entities';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useFormToast, useFloorPlanParams } from 'apiHooks/base/hooks';
import { useDeleteBuilding, useBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import { selectDeletingBuildingStatus, toggleDeletingBuilding } from 'ducks/floorPlan';
import { buildingSchema } from 'schemas/buildings';

import DeleteBuildingModal from './DeleteBuildingModal';

const DeleteBuildingContainer = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const currentUser = useAppSelector(getUser);
    const isOpened = useAppSelector(selectDeletingBuildingStatus);

    const onClose = useCallback(() => {
        dispatch(toggleDeletingBuilding(false));
    }, [dispatch]);

    const { buildingId } = useFloorPlanParams();

    const { mutate, isLoading: deleteBuildingLoading } = useDeleteBuilding();
    const { building, isFetching } = useBuilding(buildingId);
    const { triggerErrorToast } = useFormToast();

    const updateUser = useCallback(
        (nextBuildingId, deletedBuildingId) => {
            dispatch(
                entitiesActions.purgeEntities({
                    key: buildingSchema.key,
                    ids: [deletedBuildingId],
                }),
            );

            dispatch(
                setUser({
                    user: {
                        ...currentUser,
                        default_building: nextBuildingId || null,
                    },
                    isAuthenticated: true,
                }),
            );
        },
        [dispatch, currentUser],
    );

    const goToNextBuilding = useCallback(
        (nextBuildingId) => {
            if (nextBuildingId) {
                history.push(
                    routesManager.resolvePath('buildings:building', {
                        buildingId: nextBuildingId,
                    }),
                );
            } else {
                history.push(routesManager.resolvePath('buildings'));
            }
        },
        [history],
    );

    const handleDeleteBuilding = useCallback(
        (e) => {
            e.preventDefault();

            mutate(
                { building },
                {
                    onSuccess({ data: nextBuilding }) {
                        updateUser(nextBuilding?.id, building.id);
                        goToNextBuilding(nextBuilding?.id);
                        onClose();
                    },
                    onError() {
                        triggerErrorToast({ message: 'Error delete building' });
                    },
                },
            );
        },
        [goToNextBuilding, onClose, updateUser, building, mutate, triggerErrorToast],
    );

    return (
        <DeleteBuildingModal
            handleSubmit={handleDeleteBuilding}
            isSubmitting={deleteBuildingLoading}
            buildingName={building?.name}
            handleCancel={onClose}
            onClose={onClose}
            isOpened={isOpened}
            isFetching={isFetching}
        />
    );
};

DeleteBuildingContainer.propTypes = {};
DeleteBuildingContainer.defaultProps = {};

export default DeleteBuildingContainer;
