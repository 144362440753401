import { ConfirmModal, theme } from '@infogrid/components-material-ui';
import {
    Box,
    CircularProgress,
    FormControlLabel,
    Switch,
    Typography,
} from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    isConfirmModalOpen: boolean;
    closeConfirmModal: () => void;
    openConfirmModal: () => void;
    disabled?: boolean;
}

const IsEnabledSwitch = ({
    isConfirmModalOpen,
    closeConfirmModal,
    openConfirmModal,
    disabled = false,
}: Props) => {
    const { t } = useTranslation('integrations');

    const [{ name, value, onBlur, onChange: onChangeUpdateValue }, , { setValue }] =
        useField<boolean>('is_enabled');
    const { isSubmitting } = useFormikContext();

    const onChange = useCallback(
        (e) => {
            if (disabled) {
                return;
            }

            if (value) {
                openConfirmModal();
            } else {
                onChangeUpdateValue(e);
            }
        },
        [onChangeUpdateValue, openConfirmModal, value, disabled],
    );

    const onConfirmDeactivation = useCallback(() => {
        if (disabled) {
            closeConfirmModal();

            return;
        }

        setValue(false);
    }, [setValue, disabled, closeConfirmModal]);

    return (
        <>
            <FormControlLabel
                control={
                    <Switch
                        name={name}
                        checked={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        color="primary"
                        data-testid="events-webhook-is-enabled"
                        data-cypress="events-webhook-is-enabled"
                        disabled={disabled}
                    />
                }
                label={
                    <Box width={60} display="inlineBlock" textAlign="right">
                        <Typography variant="body1">
                            {value ? t('Active') : t('Inactive')}
                        </Typography>
                    </Box>
                }
                labelPlacement="end"
            />
            <ConfirmModal
                title={t('Confirm deactivation')}
                content={
                    <Typography variant="body1">
                        {t(
                            'Deactivating this webhook means data will no longer be sent to the specified url. ' +
                                'You can reactivate the webhook at any time. ' +
                                'Are you sure you wish to deactivate the webhook?',
                        )}
                    </Typography>
                }
                confirmButtonText={
                    isSubmitting ? (
                        <CircularProgress
                            style={{ color: theme.palette.primary.contrastText }}
                            size={24}
                        />
                    ) : (
                        t('Yes, deactivate')
                    )
                }
                cancelButtonText={t("No, don't deactivate")}
                cancelButtonProps={{ color: 'primary' }}
                onClose={closeConfirmModal}
                onConfirm={onConfirmDeactivation}
                open={isConfirmModalOpen}
            />
        </>
    );
};

export default memo(IsEnabledSwitch);
