import {
    ConnectionStatus,
    applicationSelectors,
    SOCKET_STATUS,
} from '@infogrid/core-ducks';
import { select, take } from 'redux-saga/effects';

export function* SocketConnected() {
    const isConnected = yield select(applicationSelectors.isSocketConnected);

    if (isConnected) {
        return;
    }

    yield take(
        (action) =>
            action.type === SOCKET_STATUS && action.status === ConnectionStatus.Connected,
    );

    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('Socket monitor connected');
    }
}

export function* SocketDisconnected() {
    yield take(
        (action) =>
            action.type === SOCKET_STATUS &&
            action.status === ConnectionStatus.Disconnected,
    );

    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('Socket monitor disconnected');
    }
}
