import type { AxiosParsedError } from '@infogrid/core-types';
import type { SensorShape } from '@infogrid/sensors-constants';
import { useIsMobile } from '@infogrid/utils-hooks';
import type { ReactElement } from 'react';
import { useEffect, useState, useCallback, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { useRegisterSensor } from 'apiHooks/sensors/installation/hooks';
import { buildRegisterQueryParams } from 'apiHooks/sensors/installation/utils';
import { useConnectedIsOpenState } from 'utils/hooks';

import { FeedbackPanelConfiguration, DefaultConfiguration } from './Configuration';
import ConnectionCheck from './ConnectionCheck';
import ElectricityMonitoringConfiguration from './ElectricityMonitoringConfiguration';
import FeedbackPanelTemplateSelector from './FeedbackPanel/FeedbackPanelTemplateSelector';
import FeedbackPanelTouchIdentifier from './FeedbackPanel/FeedbackPanelTouchIdentifier';
import FeedbackPanelInstallationSuccessView from './FeedbackPanelInstallationSuccessView';
import FludiaConfiguration from './FludiaConfiguration';
import FludiaTechnicalConfiguration from './FludiaTechnicalConfiguration';
import InstallationFlowButton from './InstallationFlowButton';
import InstallationFlowModal from './InstallationFlowModal';
import InstallationFlowWarning from './InstallationFlowWarning';
import InstallationSuccessView from './InstallationSuccessView';
import AirthingsIdentifier from './ManualDeviceIdentifiers/AirthingsIdentifier';
import BEAIdentifier from './ManualDeviceIdentifiers/BEAIdentifier';
import BTLIdentifier from './ManualDeviceIdentifiers/BTLIdentifier';
import DTIdentifier from './ManualDeviceIdentifiers/DTIdentifier';
import EmergencyLightIdentifier from './ManualDeviceIdentifiers/EmergencyLightIdentifier';
import FludiaIdentifier from './ManualDeviceIdentifiers/FludiaIdentifier';
import MonnitIdentifier from './ManualDeviceIdentifiers/MonnitIdentifier';
import SiluxHubIdentifier from './ManualDeviceIdentifiers/SiluxHubIdentifier';
import SmartSpaceIdentifier from './ManualDeviceIdentifiers/SmartSpaceIdentifier';
import UrbanHubIdentifier from './ManualDeviceIdentifiers/UrbanHubIdentifier';
import MonnitConfiguration from './MonnitConfiguration/MonnitConfiguration';
import MonnitDryPulseConfiguration from './MonnitDryPulseConfiguration';
import QRCodeScannerContainer from './QRCodeScanner/QRCodeScannerContainer';
import StepsManager from './StepsManager';
import TemperatureOffsetConfiguration from './TemperatureOffsetConfiguration';
import TemperatureOffsetHelp from './TemperatureOffsetConfiguration/TemperatureOffsetHelp';
import TemperatureOffsetMode from './TemperatureOffsetMode';
import TemperatureUseCaseSelector from './TemperatureUseCaseSelector';
import VendorSelector from './VendorSelector/VendorSelector';
import {
    INSTALLATION_FLOW_STEPS,
    INSTALLATION_FLOW_STEPS_WITH_HIDDEN_CLOSE_BUTTON,
    DISCARD_MODAL_DEFAULTS,
    DEVICE_IDENTIFICATION_ERRORS,
    PORTRAIT_ORIENTATION_MEDIA,
    REQUIRE_DISCARD_WARNING_STEPS,
} from './constants';
import InstallationFlowContext, { INSTALLATION_FLOW_INITIAL_CONTEXT } from './context';
import InstallationFlowContextDispatcher, {
    installationFlowReducer,
    INSTALLATION_FLOW_ACTIONS,
} from './reducer';
import { useInstallationFlowStyles } from './styles';
import { getSensorLastSignalEvent } from './utils';

const INSTALLATION_FLOW_COMPONENTS = {
    [INSTALLATION_FLOW_STEPS.qrCodeScanner]: QRCodeScannerContainer,
    [INSTALLATION_FLOW_STEPS.tempUseCaseSelector]: TemperatureUseCaseSelector,
    [INSTALLATION_FLOW_STEPS.vendorSelector]: VendorSelector,
    [INSTALLATION_FLOW_STEPS.airthingsIdentifier]: AirthingsIdentifier,
    [INSTALLATION_FLOW_STEPS.BEAIdentifier]: BEAIdentifier,
    [INSTALLATION_FLOW_STEPS.BTLIdentifier]: BTLIdentifier,
    [INSTALLATION_FLOW_STEPS.DTIdentifier]: DTIdentifier,
    [INSTALLATION_FLOW_STEPS.feedbackPanelTemplateSelector]:
        FeedbackPanelTemplateSelector,
    [INSTALLATION_FLOW_STEPS.feedbackPanelTouchIdentifier]: FeedbackPanelTouchIdentifier,
    [INSTALLATION_FLOW_STEPS.connectionCheck]: ConnectionCheck,
    [INSTALLATION_FLOW_STEPS.successView]: InstallationSuccessView,
    [INSTALLATION_FLOW_STEPS.feedbackPanelSuccessView]:
        FeedbackPanelInstallationSuccessView,
    [INSTALLATION_FLOW_STEPS.tempOffsetConfiguration]: TemperatureOffsetConfiguration,
    [INSTALLATION_FLOW_STEPS.tempOffsetMode]: TemperatureOffsetMode,
    [INSTALLATION_FLOW_STEPS.tempOffsetConfigurationHelp]: TemperatureOffsetHelp,
    [INSTALLATION_FLOW_STEPS.configuration]: DefaultConfiguration,
    [INSTALLATION_FLOW_STEPS.feedbackPanelConfiguration]: FeedbackPanelConfiguration,
    [INSTALLATION_FLOW_STEPS.siluxHubIdentifier]: SiluxHubIdentifier,
    [INSTALLATION_FLOW_STEPS.emergencyLightIdentifier]: EmergencyLightIdentifier,
    [INSTALLATION_FLOW_STEPS.monnitIdentifier]: MonnitIdentifier,
    [INSTALLATION_FLOW_STEPS.monnitConfiguration]: MonnitConfiguration,
    [INSTALLATION_FLOW_STEPS.fludiaIdentifier]: FludiaIdentifier,
    [INSTALLATION_FLOW_STEPS.fludiaConfiguration]: FludiaConfiguration,
    [INSTALLATION_FLOW_STEPS.fludiaTechnicalConfiguration]: FludiaTechnicalConfiguration,
    [INSTALLATION_FLOW_STEPS.electricityMonitoringConfiguration]:
        ElectricityMonitoringConfiguration,
    [INSTALLATION_FLOW_STEPS.monnitDryPulseConfiguration]: MonnitDryPulseConfiguration,
    [INSTALLATION_FLOW_STEPS.urbanHubIdentifier]: UrbanHubIdentifier,
    [INSTALLATION_FLOW_STEPS.smartSpaceIdentifier]: SmartSpaceIdentifier,
};

const InstallationFlow = (): ReactElement | null => {
    const { t } = useTranslation('sensors');
    /*
     If you need to add any state that's preserved across steps, use InstallationFlowContext.
     You can get this with useInstallationFlowContext().
     You can update this with useInstallationFlowContextDispatcher().
    */
    const [installationFlowContext, dispatchInstallationFlowContext] = useReducer(
        installationFlowReducer,
        INSTALLATION_FLOW_INITIAL_CONTEXT,
    );

    const { registerInfo, sensor, vendorTypeStep, isLoading, path } =
        installationFlowContext;
    const step = StepsManager.getStep(path);

    const ChildComponent = INSTALLATION_FLOW_COMPONENTS[step];
    const [isOpen, handleClose] = useConnectedIsOpenState('installationFlow');

    const [isDiscardChangesWarningOpen, setIsDiscardChangesWarningOpen] = useState(false);

    const isMobile = useIsMobile();
    const isPortraitOrientation = useMedia(PORTRAIT_ORIENTATION_MEDIA);
    const fullScreenMode = useMemo(
        () => isPortraitOrientation || isMobile,
        [isPortraitOrientation, isMobile],
    );
    const classes = useInstallationFlowStyles({
        isQRScannerOpen: step === INSTALLATION_FLOW_STEPS.qrCodeScanner,
    });

    const [errorMessage, setErrorMessage] = useState('');

    const setSensor = (fetchedSensor: SensorShape) => {
        setErrorMessage('');

        if (fetchedSensor.can_edit) {
            const lastSignalEvent = getSensorLastSignalEvent(fetchedSensor);

            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.SET_SENSOR,
                sensor: fetchedSensor,
                lastSignalEvent,
            });
        } else {
            setErrorMessage(t(DEVICE_IDENTIFICATION_ERRORS.ACCESS_DENIED));
        }
    };

    const onNotFoundError = (error: AxiosParsedError) => {
        if (error?.response?.status === 404) {
            setErrorMessage(t(DEVICE_IDENTIFICATION_ERRORS.NOT_FOUND));
        }
    };

    const { mutate: registerSensor, isLoading: isRegistrationInProgress } =
        useRegisterSensor({
            onSuccess: setSensor,
            onError: (error: AxiosParsedError) => {
                if (error?.response?.data?.error) {
                    setErrorMessage(error?.response?.data?.error);
                }

                onNotFoundError(error);
            },
        });

    useEffect(() => {
        if (registerInfo && vendorTypeStep) {
            registerSensor(buildRegisterQueryParams(vendorTypeStep, registerInfo));
        }
    }, [registerSensor, registerInfo, vendorTypeStep]);

    useEffect(() => {
        setErrorMessage('');

        // If registerInfo is reset by an installation flow back action, reset the sensor as well
        if (!registerInfo && sensor) {
            dispatchInstallationFlowContext({
                type: INSTALLATION_FLOW_ACTIONS.SET_SENSOR,
                sensor: null,
                lastSignalEvent: null,
            });
        }
    }, [registerInfo, sensor]);

    const resetProgress = useCallback(() => {
        dispatchInstallationFlowContext({
            type: INSTALLATION_FLOW_ACTIONS.RESET_PROGRESS,
        });
    }, []);

    // finish installation and close modal
    const onComplete = useCallback(() => {
        resetProgress();
        handleClose();
    }, [handleClose, resetProgress]);

    // finish installation, close warning and modal
    const onDiscard = useCallback(() => {
        setIsDiscardChangesWarningOpen(false);
        onComplete();
    }, [onComplete]);

    const onClose = useCallback(() => {
        // automatically discard changes for the first two steps and success view
        if (REQUIRE_DISCARD_WARNING_STEPS.includes(step)) {
            onDiscard();

            return;
        }

        // show discard changes warning
        setIsDiscardChangesWarningOpen(true);
    }, [step, onDiscard]);

    const onResume = useCallback(() => setIsDiscardChangesWarningOpen(false), []);

    const maxModalWidth = useMemo(
        () => (step === INSTALLATION_FLOW_STEPS.qrCodeScanner ? false : 'sm'),
        [step],
    );

    const hideCloseButton =
        INSTALLATION_FLOW_STEPS_WITH_HIDDEN_CLOSE_BUTTON.includes(step);

    return isOpen ? (
        <InstallationFlowContextDispatcher.Provider
            value={dispatchInstallationFlowContext}
        >
            <InstallationFlowContext.Provider value={installationFlowContext}>
                <InstallationFlowModal
                    onClose={onClose}
                    isLoading={isLoading || isRegistrationInProgress}
                    classes={classes}
                    maxWidth={maxModalWidth}
                    fullScreenMode={fullScreenMode}
                    hideCloseButton={hideCloseButton}
                >
                    <ChildComponent
                        onComplete={onComplete}
                        resetProgress={resetProgress}
                        errorMessage={errorMessage}
                    />
                </InstallationFlowModal>
                <InstallationFlowWarning
                    isOpen={isDiscardChangesWarningOpen}
                    onResume={onResume}
                    onDiscard={onDiscard}
                    warningMessage={t(DISCARD_MODAL_DEFAULTS.WARNING_MESSAGE)}
                    onDiscardButtonText={t(DISCARD_MODAL_DEFAULTS.ON_DISCARD_BUTTON_TEXT)}
                />
            </InstallationFlowContext.Provider>
        </InstallationFlowContextDispatcher.Provider>
    ) : null;
};

InstallationFlow.Button = InstallationFlowButton;

export default InstallationFlow;
