import PropTypes from 'prop-types';
import { useContext, useEffect, memo } from 'react';

import MapContext from './MapContext';

// Control - https://openlayers.org/en/latest/apidoc/module-ol_control.html

const MapControl = ({ Control, listeners, options }) => {
    const { map } = useContext(MapContext);

    useEffect(() => {
        if (!map) {
            return () => {};
        }

        const control = new Control(options);

        map.addControl(control);

        const events = Object.keys(listeners);

        events.forEach((eventName) => {
            control.on(eventName, listeners[eventName]);
        });

        return () => {
            events.forEach((eventName) => {
                control.un(eventName, listeners[eventName]);
            });

            map.removeControl(control);
        };
    }, [map, Control, listeners, options]);

    return null;
};

MapControl.propTypes = {
    Control: PropTypes.func.isRequired,
    listeners: PropTypes.shape({}),
    options: PropTypes.shape({}),
};

MapControl.defaultProps = {
    listeners: {},
    options: {},
};

export default memo(MapControl);
