import { wrapResponsePromise, useMutationWithToast } from '@infogrid/core-api';
import { useAppDispatch, toastError, toastSuccess } from '@infogrid/core-ducks';
import type { AxiosParsedError } from '@infogrid/core-types';
import { useTranslation } from 'react-i18next';
import type { UseMutationResult } from 'react-query';

import { cleanInactiveAlertConfigurationsQuery } from 'apiHooks/alertConfigurations/accessors';

import { controllers } from './controller';

interface Params {
    id: number;
}

export const useDeleteSmartCleaningReport = (): UseMutationResult<
    Record<string, never>,
    AxiosParsedError,
    Params
> => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('solutions');

    return useMutationWithToast(
        ({ id }) => wrapResponsePromise(controllers.deleteSmartCleaningReport(id)),
        {
            onError: () => {
                dispatch(
                    toastError({
                        message: t('Error deleting smart cleaning report'),
                    }),
                );
            },
            onSuccess: () => {
                cleanInactiveAlertConfigurationsQuery();
                dispatch(
                    toastSuccess({
                        message: t('Smart cleaning report was deleted'),
                    }),
                );
            },
        },
        {
            toastMessage: t('Deleting smart cleaning report'),
        },
    );
};
