import type { FeedbackPanelTemplate } from '@infogrid/sensors-constants';
import { Button, Typography } from '@material-ui/core';
import { Fragment, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useFeedbackPanelTemplateSelectionStyles } from './styles';

interface FeedbackPanelTemplateSelectionProps {
    feedbackPanelTemplate: FeedbackPanelTemplate;
    onSelect: (feedbackPanelTemplate: FeedbackPanelTemplate) => void;
}

const FeedbackPanelTemplateSelection = ({
    feedbackPanelTemplate,
    onSelect,
}: FeedbackPanelTemplateSelectionProps) => {
    const { t } = useTranslation('sensors');
    const styles = useFeedbackPanelTemplateSelectionStyles();
    const onSelectCallback = useCallback(
        () => onSelect(feedbackPanelTemplate),
        [onSelect, feedbackPanelTemplate],
    );

    return (
        <div data-cypress="feedback-panel-template">
            <Typography className={styles.name} variant="h6">
                {feedbackPanelTemplate.name}
            </Typography>
            <Typography className={styles.templateOptions}>
                {feedbackPanelTemplate.template_options.map((templateOption, index) => (
                    <Fragment key={templateOption.id}>
                        {index !== 0 && ' | '}
                        {templateOption.display}
                    </Fragment>
                ))}
            </Typography>
            <Typography className={styles.sensorsCount}>
                {t('{{count}} sensors', {
                    count: feedbackPanelTemplate.template_options.length,
                    defaultValue___one: `${feedbackPanelTemplate.template_options.length} sensor`,
                    defaultValue___other: `${feedbackPanelTemplate.template_options.length} sensors`,
                })}
            </Typography>
            <Button
                classes={{ label: styles.selectButton }}
                onClick={onSelectCallback}
                color="primary"
                variant="contained"
                data-cypress={`select-${feedbackPanelTemplate.id}`}
            >
                {t('Select')}
            </Button>
        </div>
    );
};

export default memo(FeedbackPanelTemplateSelection);
