import { Header } from '@infogrid/components-material-ui';
import { routesManager } from '@infogrid/core-routing';
import { useIsDesktop, useIsMobile, useIsOpenState } from '@infogrid/utils-hooks';
import { CircularProgress, Typography } from '@material-ui/core';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import { useBuilding } from 'apiHooks/floorPlan/buildings/hooks';
import { useFloor, useFloors } from 'apiHooks/floorPlan/floors/hooks';
import ErrorView from 'components/estates/ErrorView';
import BuildingSidebar from 'views/estate/components/BuildingSidebar';
import MobileSpaceSidebar from 'views/estate/components/MobileSpaceSidebar';
import SpaceSidebar from 'views/estate/components/SpaceSidebar';
import UnassignedSensorsSidebar from 'views/estate/components/UnassignedSensorsSidebar';
import MobileUnassignedSensorsSidebar from 'views/estate/components/UnassignedSensorsSidebar/MobileUnassignedSensorsSidebar';
import { SpaceModalProvider } from 'views/estate/components/modals/EditSpaceModal/context';
import type { EstateFloorParams } from 'views/estate/pages/types';

import EstateFloorView from './EstateFloorView';
import { useEstateFloorStyles } from './styles';

const EstateFloor = (): JSX.Element => {
    const { t } = useTranslation('estate');

    const styles = useEstateFloorStyles();

    const isMobile = useIsMobile();
    const isDesktop = useIsDesktop();

    // Pre-open the unassigned sidebar if URL contains ?sidebar=unassigned
    const sidebar = useSearchParam('sidebar');
    const initialUnassignedSidebar = sidebar === 'unassigned';

    const { buildingId, floorId } = useParams<EstateFloorParams>();

    const { building, isLoading: isLoadingBuilding } = useBuilding(Number(buildingId));

    const { floors, isLoading: isLoadingFloors } = useFloors(Number(buildingId));

    const { data: floor, isFetching: isFetchingFloor } = useFloor(Number(floorId));

    const [selectedSpaceId, _setSelectedSpaceId] = useState<number | null>(null);
    const [
        isUnassignedSensorsSidebarOpen,
        closeUnassignedSensorsSidebar,
        _1,
        _toggleUnassignedSensorsSidebar,
    ] = useIsOpenState(initialUnassignedSidebar);

    const toggleUnassignedSensorsSidebar = () => {
        _setSelectedSpaceId(null);
        _toggleUnassignedSensorsSidebar();
    };

    const setSelectedSpaceId = (id: number | null) => {
        closeUnassignedSensorsSidebar();
        _setSelectedSpaceId(id);
    };

    const renderPageNameRow = useCallback(
        () =>
            !isMobile && (
                <>
                    <Typography className={styles.pageNameText} variant="subtitle1">
                        {t('Estate Management')}
                    </Typography>
                </>
            ),
        [isMobile, styles, t],
    );

    if (building && !floorId && floors.length !== 0) {
        return (
            <Redirect
                to={routesManager.resolvePath('estate:estate-building-floor', {
                    buildingId,
                    floorId: floors[0].id,
                })}
            />
        );
    }

    if (!building && !isLoadingBuilding) {
        return (
            <ErrorView
                // TODO: Update path to estate:estate-buildings when that view is available
                buttonLink={routesManager.resolvePath('estate')}
                buttonText={t('View all buildings')}
                description={t(
                    'Building does not exist. Please choose another building from your Estate.',
                )}
                icon={'fal fa-building'}
            />
        );
    }

    return (
        <SpaceModalProvider>
            <div className={styles.container}>
                <Header renderPageNameRowContent={renderPageNameRow} />

                <div className={styles.content}>
                    <BuildingSidebar setSelectedSpaceId={setSelectedSpaceId} />
                    {(isFetchingFloor || isLoadingFloors) && (
                        <div className={styles.loaderWrapper}>
                            <div className={styles.loader}>
                                <CircularProgress />
                            </div>
                        </div>
                    )}

                    {!floor && !isFetchingFloor && !isLoadingFloors && (
                        <ErrorView
                            classes={{
                                container: styles.errorView,
                            }}
                            description={t(
                                'Floor does not exist. Please choose another floor within this building. For access queries, please contact your organisation’s Manager.',
                            )}
                            icon="fal fa-layer-group"
                        />
                    )}
                    {floor && !isFetchingFloor && (
                        <EstateFloorView
                            buildingId={+buildingId}
                            buildingName={building?.name}
                            floorId={+floorId}
                            floor={floor}
                            isUnassignedSensorsSidebarOpen={
                                isUnassignedSensorsSidebarOpen
                            }
                            selectedSpaceId={selectedSpaceId}
                            setSelectedSpaceId={setSelectedSpaceId}
                            toggleUnassignedSensorsSidebar={
                                toggleUnassignedSensorsSidebar
                            }
                        />
                    )}
                    {isDesktop && !!selectedSpaceId && floor && (
                        <SpaceSidebar
                            buildingId={+buildingId}
                            floor={floor}
                            setSelectedSpaceId={setSelectedSpaceId}
                            spaceId={selectedSpaceId}
                        />
                    )}
                    {!isDesktop && !!selectedSpaceId && floor && (
                        <MobileSpaceSidebar
                            buildingId={+buildingId}
                            floor={floor}
                            setSelectedSpaceId={setSelectedSpaceId}
                            spaceId={selectedSpaceId}
                        />
                    )}
                    {isDesktop && isUnassignedSensorsSidebarOpen && floor && (
                        <UnassignedSensorsSidebar
                            buildingId={+buildingId}
                            floor={floor}
                            onCloseSidebar={toggleUnassignedSensorsSidebar}
                        />
                    )}
                    {!isDesktop && isUnassignedSensorsSidebarOpen && floor && (
                        <MobileUnassignedSensorsSidebar
                            buildingId={+buildingId}
                            floor={floor}
                            onCloseSidebar={toggleUnassignedSensorsSidebar}
                        />
                    )}
                </div>
            </div>
        </SpaceModalProvider>
    );
};

export default memo(EstateFloor);
