import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useBuildingsTableStyles = makeStyles((theme) => ({
    tableRoot: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    tableContainer: {
        height: '100%',
        minHeight: 300,
    },
    buildingName: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
    cellLabel: {
        color: theme.palette.common.gray6,
        wordBreak: 'break-word',
    },
    contextMenuContent: {
        left: 'unset',
        right: 0,
        '& > div': {
            minWidth: 'unset',
        },
    },
    edit: {
        left: 'unset',
        right: 0,
    },
    menuListContainer: {
        minWidth: 'unset',
    },
    contextMenuItem: {
        textTransform: 'unset',
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    deleteLabel: {
        color: theme.palette.secondary.main,
    },
    emptyStateRenderer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    noBuildings: {
        color: theme.palette.common.gray6,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(4),
        textAlign: 'center',
    },
    addBuildingIcon: {
        alignItems: 'center',
        display: 'flex',
        fontSize: 14,
        height: 16,
        marginRight: theme.spacing(0.5),
        marginTop: theme.spacing(0.25),
    },
    filtersContainer: {
        marginBottom: theme.spacing(3),
    },
    link: {
        color: theme.palette.text.primary,
        marginLeft: theme.spacing(1),

        '&:hover': {
            color: theme.palette.text.primary,
        },
    },
    nameHeader: {
        paddingLeft: theme.spacing(1),
    },
    errorMessage: {
        maxWidth: 664,
        margin: 'auto',
        marginTop: theme.spacing(5),
    },
    overlayContainer: {
        position: 'relative',
        flexGrow: 1,
    },
    overlayLoader: {
        zIndex: 1,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
}));
