const getUniqsByField = (arr, field) => {
    const uniqs = {};

    arr.forEach((item) => {
        uniqs[item[field]] = uniqs[item[field]] === undefined;
    });

    return uniqs;
};

export const addIsUniqFieldToEntities = (field) => (entities) => {
    // clean entities from empty items
    const filledEntities = entities.filter((entity) => !!entity);

    const uniqs = getUniqsByField(filledEntities, field);

    return filledEntities.map((entity) => ({
        ...entity,
        isUniqName: uniqs[entity[field]],
    }));
};
