import { makeStyles } from '@material-ui/core';

export const useNoResultsStyles = makeStyles((theme) => ({
    contentNoRiskContainer: {
        paddingTop: theme.spacing(2),
        margin: 'auto',
        width: '300px',
        textAlign: 'center',
    },
}));
