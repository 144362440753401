import { MemoizedDivider } from '@infogrid/components-material-ui';
import { sensorsColors } from '@infogrid/dashboards-constants';
import { useDashboardSensorsCount } from '@infogrid/dashboards-hooks';
import type { SensorType } from '@infogrid/sensors-constants';
import { Box, Typography } from '@material-ui/core';
import type { FormikProps } from 'formik';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Slider from 'components/Slider';

import type { CubicleOccupancyStoplightValues } from '../types';
import CubicleOccupancySection from './CubicleOccupancySection';

interface DisplayOptionsProps {
    formik: FormikProps<CubicleOccupancyStoplightValues>;
    isTabActive: boolean;
    sensorTypeFilter: SensorType;
}

const DisplayOptions = ({
    formik,
    isTabActive,
    sensorTypeFilter,
}: DisplayOptionsProps) => {
    const { t } = useTranslation('dashboard');

    const {
        displayOptions: { green: greenDisplay, yellow: yellowDisplay, red: redDisplay },
        folders,
        sensors,
    } = formik.values;

    const errors = formik?.errors;
    const redErrors = errors?.displayOptions?.red;
    const yellowErrors = errors?.displayOptions?.yellow;
    const greenErrors = errors?.displayOptions?.green;

    const { data: sensorsCountData, refetch: refetchSensorsCountData } =
        useDashboardSensorsCount(
            { folders, sensor_type: sensorTypeFilter },
            { enabled: Boolean(folders.length > 0 && sensorTypeFilter) },
        );

    useEffect(() => {
        refetchSensorsCountData();
    }, [folders, refetchSensorsCountData]);

    const sliderValue = useMemo(
        () => [redDisplay.maxThreshold || 0, yellowDisplay.maxThreshold || 1],
        [redDisplay.maxThreshold, yellowDisplay.maxThreshold],
    );

    const totalSensorsCount = useMemo(
        () => sensors?.length + (sensorsCountData?.count || 0),
        [sensors, sensorsCountData],
    );

    const handleSliderChange = useCallback(
        (thresholds) => {
            formik.setFieldValue('displayOptions.red.maxThreshold', thresholds[0]);
            formik.setFieldValue('displayOptions.yellow.maxThreshold', thresholds[1]);
        },
        [formik],
    );

    return (
        <div>
            {totalSensorsCount > 0 && isTabActive && (
                <>
                    <Typography variant="subtitle1">
                        {t(
                            'Use the slider to set what message is shown based on how many cubicles are currently occupied.',
                        )}
                    </Typography>
                    <Box my={3}>
                        <Slider
                            min={0}
                            max={totalSensorsCount}
                            onChange={handleSliderChange}
                            value={sliderValue}
                        />
                    </Box>
                </>
            )}
            <MemoizedDivider />
            <CubicleOccupancySection
                color={sensorsColors.red.color}
                errors={redErrors}
                handleChange={formik.handleChange}
                message={redDisplay.message}
                name="red"
                showCubicleCount={redDisplay.showCubicleCount}
                title={t('Red display')}
            />
            <MemoizedDivider />
            <CubicleOccupancySection
                color={sensorsColors.yellow.color}
                errors={yellowErrors}
                handleChange={formik.handleChange}
                message={yellowDisplay.message}
                name="yellow"
                showCubicleCount={yellowDisplay.showCubicleCount}
                title={t('Yellow display')}
            />
            <MemoizedDivider />
            <CubicleOccupancySection
                color={sensorsColors.green.color}
                errors={greenErrors}
                handleChange={formik.handleChange}
                message={greenDisplay.message}
                name="green"
                showCubicleCount={greenDisplay.showCubicleCount}
                title={t('Green display')}
            />
        </div>
    );
};

export default memo(DisplayOptions);
