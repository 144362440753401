import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useAddSpaceListItemStyles = makeStyles((theme) => ({
    additionalCount: {
        fontSize: 14,
        padding: theme.spacing(0, 1.5),
    },
    container: {
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.common.shell}`,
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 0),
    },
    changeCountButton: {
        minWidth: 'unset',
    },
    changeCountButtonIcon: {
        fontSize: 13,
    },
    countSection: {
        alignItems: 'center',
        display: 'flex',
    },
    name: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
    },
    text: {
        fontSize: 14,
    },
}));
