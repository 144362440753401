import { makeStyles } from '@material-ui/core';

export const useStylesFloorPlan = makeStyles((theme) => ({
    floor: {
        display: 'flex',
        width: '100%',
    },
    mapContainer: {
        flexGrow: 1,
    },
    mapLoaderContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mapLoaderText: {
        color: theme.palette.text.grey.default,
        marginTop: theme.spacing(3),
    },
    floorPlanContainer: {
        display: 'flex',
        flex: '1',
    },
    uploadCardContainer: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
