import type { FormikContextType } from 'formik';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { DEFAULT_ELECTRICITY_MONITORED_SPACE } from './constants';
import { electricityMonitoringConfigurationFormValidationSchema } from './electricityMonitoringConfigurationFormValidationSchema';
import type { ElectricityMonitoringConfigurationFormValues } from './types';

export const useElectricityMonitoringConfigurationFormik = ({
    onSubmit,
}: {
    onSubmit: (formValues: ElectricityMonitoringConfigurationFormValues) => void;
}): FormikContextType<ElectricityMonitoringConfigurationFormValues> => {
    const { t } = useTranslation('sensors');

    const initialValues: ElectricityMonitoringConfigurationFormValues = {
        monitoring_locations: [],
        end_use: undefined,
        kwh_conversion_factor_sqm: DEFAULT_ELECTRICITY_MONITORED_SPACE,
    };

    return useFormik<ElectricityMonitoringConfigurationFormValues>({
        initialValues,
        enableReinitialize: true,
        validationSchema: electricityMonitoringConfigurationFormValidationSchema(t),
        onSubmit,
    });
};
