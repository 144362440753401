import type { IColumn, IRow } from '@infogrid/components-material-ui';
import { BaseTable } from '@infogrid/components-material-ui';
import type { LocationFilter } from '@infogrid/core-types';
import type { AirQualityMetric } from '@infogrid/solution-views-types';
import { useIsDesktop } from '@infogrid/utils-hooks';
import { Icon, TableCell, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMetricValue } from '../Content/hooks';
import type { WidgetData, Location } from '../types';
import { useLocationsTableStyles } from './styles';

type LocationsTablePropTypes = {
    data: WidgetData;
    metric: AirQualityMetric;
    isMultipleSLocations: boolean;
    spaceType: LocationFilter;
};

const LocationsTable = ({
    data,
    metric,
    isMultipleSLocations,
    spaceType,
}: LocationsTablePropTypes) => {
    const styles = useLocationsTableStyles();
    const { t } = useTranslation('dashboard');
    const isDesktop = useIsDesktop();

    const noDataLabel = t('N/A');
    const tooltipText = t(
        `This reading is an average because the {{spaceType}} has more than 1 sensor`,
        { spaceType },
    );
    const readingLabel = isMultipleSLocations ? t('Reading') : '';

    //handle this better in HB-954
    if ('locations' in data) {
        const columns: IColumn[] = [
            {
                key: 'location',
                name: t('Space Name'),
                sortable: true,
                DataCell: ({ row, column }) => (
                    <TableCell
                        className={styles.locationString}
                        key={column.key}
                        style={column.style}
                    >
                        <span>{String(row[column.key])}</span>
                    </TableCell>
                ),
            },
            {
                key: 'average',
                name: readingLabel,
                sortable: true,
                style: { width: isDesktop ? '27.5%' : '20%' },
                DataCell: ({ row, column }) => {
                    const { average, rating, sensor_count } = row as unknown as Location;
                    const isMultipleSensors = sensor_count > 1;

                    const metricValueLabel = useMetricValue(metric, average);

                    return average ? (
                        <TableCell style={column.style}>
                            <span>{metricValueLabel}</span>
                            {rating && !isMultipleSensors && (
                                <span
                                    className={classNames(styles.bubble, styles[rating])}
                                />
                            )}
                            {rating && isMultipleSensors && (
                                <Tooltip
                                    title={tooltipText}
                                    enterTouchDelay={50}
                                    leaveTouchDelay={3000}
                                >
                                    <Icon
                                        data-cypress="score-trend-info-icon"
                                        className={classNames(
                                            'fal fa-info-circle',
                                            styles.tooltipIcon,
                                        )}
                                    />
                                </Tooltip>
                            )}
                        </TableCell>
                    ) : (
                        <TableCell style={column.style}>{noDataLabel}</TableCell>
                    );
                },
            },
        ];

        return (
            <BaseTable
                classes={{
                    table: styles.table,
                }}
                columns={columns}
                rows={data.locations as unknown as IRow[]}
            />
        );
    }

    return null;
};

export default memo(LocationsTable);
