import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import type { Report } from '@infogrid/reports-types';
import { useCallback, useState } from 'react';
import type { TFunction } from 'react-i18next';

import { ASYNC_STATUS } from 'utils/asyncStatus';
import { getBaseUrl } from 'utils/axios';

export const REPORT_TYPE = {
    EVENT_READING: 'event_reading',
    PIPE_MONITORING: 'pipe_monitoring',
};

interface ReportTypeState {
    reportType: string;
    openReportType: (reportTypeToOpen: string) => void;
    openEventReading: () => void;
}

export const useReportTypeState = (handleOpen: () => void): ReportTypeState => {
    const [reportType, setReportType] = useState(REPORT_TYPE.EVENT_READING);
    const openReportType = useCallback(
        (reportTypeToOpen) => {
            setReportType(reportTypeToOpen);
            handleOpen();
        },
        [setReportType, handleOpen],
    );
    const openEventReading = useCallback(
        () => openReportType(REPORT_TYPE.EVENT_READING),
        [openReportType],
    );

    return { reportType, openReportType, openEventReading };
};

// Used as id for report creation success toast. See createReport
export const reportCreatedKey = (reportId: number): string =>
    `report-created-${reportId}`;

// Used as id for report configuration creation success toast. See createReportConfiguration
export const reportConfigurationCreatedKey = (reportConfigurationId: number): string =>
    `report-configuration-created-${reportConfigurationId}`;

export const getReportGenerationFinishedToast = (
    t: TFunction,
    report: Report,
): {
    icon: string;
    intent: string;
    messageBase: string;
} => {
    const {
        name,
        missing_sensor_count: missingSensorCount,
        generation_status: generationStatus,
    } = report;

    const missingSensorsText = t('{{count}} sensors', {
        ns: 'reports',
        count: missingSensorCount,
        defaultValue___one: `${missingSensorCount} sensor`,
        defaultValue___other: `${missingSensorCount} sensors`,
    });

    switch (generationStatus) {
        case ASYNC_STATUS.DONE:
            return {
                icon: missingSensorCount ? IconNames.WARNING_SIGN : IconNames.TICK,
                intent: missingSensorCount ? Intent.WARNING : Intent.SUCCESS,
                messageBase: missingSensorCount
                    ? t(
                          'Report "{{name}}" has successfully generated, however {{missingSensorsText}} from your selection were not included because they did not match the report type.',
                          {
                              ns: 'reports',
                              name,
                              missingSensorsText,
                              defaultValue: `Report "${name}" has successfully generated, however ${missingSensorsText} from your selection were not included because they did not match the report type.`,
                          },
                      )
                    : t('Report "{{name}}" has successfully finished generation', {
                          ns: 'reports',
                          name,
                      }),
            };
        case ASYNC_STATUS.ERROR:
            return {
                icon: IconNames.ERROR,
                intent: Intent.DANGER,
                messageBase: t('Report "{{name}}" generation has failed', {
                    ns: 'reports',
                    name,
                    defaultValue: `Report "${name}" generation has failed`,
                }),
            };
        default:
            return {
                icon: IconNames.WARNING_SIGN,
                intent: Intent.WARNING,
                messageBase: t(
                    'Report "{{name}}" has finished generation with unknown status {{generationStatus}}',
                    {
                        ns: 'reports',
                        name,
                        generationStatus,
                        defaultValue: `Report "${name}" has finished generation with unknown status ${generationStatus}`,
                    },
                ),
            };
    }
};

export const getReportDownloadHref = (id: number): string =>
    `${getBaseUrl()}reports/${id}/download`;
