import { useAppSelector, useAppDispatch } from '@infogrid/core-ducks';
import type { SpaceType } from '@infogrid/locations-types';
import type { MappableSensor, SensorType } from '@infogrid/sensors-constants';
import { isNumber } from 'is-what';
import { useCallback, memo, useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import { useFloorPlanParams } from 'apiHooks/base/hooks';
import {
    useFloor,
    useSelectFloor,
    useEditMapState,
} from 'apiHooks/floorPlan/floors/hooks';
import { useAddMapSensor, useRemoveMapSensor } from 'apiHooks/floorPlan/sensors/hooks';
import {
    toggleReplaceFloorImage,
    toggleEditFloorImage,
    selectShouldKeepSensorsMapped,
} from 'ducks/floorPlan';
import { prettifyCoordinate } from 'utils/math';

import type { FloorPlanSensor } from './FloorPlan';
import FloorPlan from './FloorPlan';

const MAP_STATE_TIMEOUT = 100;

const DEFAULT_STATE = { orientation: null, zoom: null };

interface FloorPlanContainerProps {
    activeSensorDeviceId?: string | number;
    activeSensorTypes: SensorType[];
    allSensors?: FloorPlanSensor[];
    changeActiveSensorTypes: (types: SensorType[]) => void;
    mappedSensors?: MappableSensor<FloorPlanSensor>[];
    onSensorSelect: (id: string | number | null) => void;
    sensorTypes: SensorType[];
    spaceTypes: SpaceType[];
}

const FloorPlanContainer = (props: FloorPlanContainerProps) => {
    const {
        activeSensorDeviceId,
        activeSensorTypes,
        allSensors = [],
        changeActiveSensorTypes,
        mappedSensors = [],
        onSensorSelect,
        sensorTypes,
        spaceTypes,
    } = props;

    const { floorId } = useFloorPlanParams();

    const dispatch = useAppDispatch();

    const shouldKeepSensorsMapped = useAppSelector(selectShouldKeepSensorsMapped);

    const { isFetching, data: floor } = useFloor(floorId ?? undefined);
    const { floorPlan } = useSelectFloor({ floorId: floorId ?? undefined });

    // This is probably OK as the id should be available at mutation time. We could refactor
    // these hooks to receive the floorId as an arg in the mutation function. That way we can
    // ensure the floorId is available at that time.
    const { mapSensor } = useAddMapSensor({ floorId: floorId as number });
    const { unmapSensor } = useRemoveMapSensor({ floorId: floorId as number });

    const { editMapState } = useEditMapState({ floorId });

    const [mapState, setMapState] = useState({ ...DEFAULT_STATE, floorId });
    const [debouncedState] = useDebounce(mapState, MAP_STATE_TIMEOUT);

    const [isSensorMoveEnabled, setIsSensorMoveEnabled] = useState(false);

    const onImageUpdateSuccess = useCallback(() => {
        dispatch(toggleReplaceFloorImage(false));
        dispatch(toggleEditFloorImage(true, true));
    }, [dispatch]);

    useEffect(() => {
        const { orientation, zoom, floorId: stateFloorId } = debouncedState;

        if (floorId !== stateFloorId) {
            setMapState({ ...DEFAULT_STATE, floorId });

            return;
        }

        if (orientation === null && zoom === null) {
            return;
        }

        const preparedOrientation = prettifyCoordinate(orientation, 6);
        const preparedZoom = prettifyCoordinate(zoom, 6);

        const data = {
            ...(isNumber(preparedOrientation) && { orientation: preparedOrientation }),
            ...(isNumber(preparedZoom) && { zoom: preparedZoom }),
        };

        // @ts-expect-error needs to be migrated to TS
        editMapState({
            floorId,
            data,
        });
    }, [debouncedState, floorId, editMapState]);

    const changeSensorsState = useCallback(
        (sensorDeviceId, coordinates) => {
            const prettyCoordinates = coordinates?.map(prettifyCoordinate);

            const shouldBeRemoved = !coordinates;

            if (shouldBeRemoved) {
                unmapSensor({ sensorDeviceName: sensorDeviceId });

                setIsSensorMoveEnabled(false);
            } else {
                const matchedSensor = allSensors.find(
                    (sensor) => sensor.id === parseInt(sensorDeviceId),
                );

                if (!matchedSensor) {
                    return;
                }

                mapSensor({
                    sensorDeviceName: matchedSensor.device_name,
                    data: {
                        device_name: matchedSensor.device_name,
                        // TODO we shouldn't attempt to mapSensor() with null coordinates. Map sensor and its
                        // dependencies should become MapItemCoordinates instead of NullableMapItemCoordinates
                        x: prettyCoordinates?.[0] ?? null,
                        y: prettyCoordinates?.[1] ?? null,
                    },
                });
            }
        },
        [allSensors, mapSensor, unmapSensor],
    );

    const rotationChangeHandler = useCallback((value) => {
        setMapState((state) => ({ ...state, orientation: value || null }));
    }, []);

    const zoomChangeHandler = useCallback((value) => {
        setMapState((state) => ({ ...state, zoom: value || null }));
    }, []);

    const changeSensorIconScale = useCallback(
        (scale) => {
            const data = {
                floorId,
                data: {
                    scale,
                },
            };

            // @ts-expect-error needs to be migrated to TS
            editMapState(data);
        },
        [editMapState, floorId],
    );

    return floorId ? (
        <FloorPlan
            floor={floor}
            isEditAvailable={floor?.user_actions?.edit?.allowed}
            activeSensorDeviceId={activeSensorDeviceId}
            onSensorSelect={onSensorSelect}
            floorId={floorId}
            isFetching={isFetching}
            floorPlan={floorPlan ?? undefined}
            mappedSensors={mappedSensors}
            sensorTypes={sensorTypes}
            onZoomChange={zoomChangeHandler}
            onRotationChange={rotationChangeHandler}
            deleteSensorFromMap={changeSensorsState}
            addSensorOnMap={changeSensorsState}
            changeSensorPosition={changeSensorsState}
            changeSensorIconScale={changeSensorIconScale}
            activeSensorTypes={activeSensorTypes}
            changeActiveSensorTypes={changeActiveSensorTypes}
            onImageUpdateSuccess={onImageUpdateSuccess}
            shouldKeepSensorsMapped={shouldKeepSensorsMapped}
            isSensorMoveEnabled={isSensorMoveEnabled}
            setIsSensorMoveEnabled={setIsSensorMoveEnabled}
            spaceTypes={spaceTypes}
        />
    ) : null;
};

export default memo(FloorPlanContainer);
