import { Button, Icon } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BaseTreeView from 'components/BaseTreeView';

import { useSubtreeActionStyles } from './styles';

const CREATE_BUILDING_ICON_NAME = 'fal fa-plus';

const SubtreeAction = ({ classes, handler, color }) => {
    const subtreeActionStyles = useSubtreeActionStyles();
    const { t } = useTranslation();
    const subtreeActionClasses = useMemo(
        () => ({
            treeViewItemRoot: subtreeActionStyles.actionListItem,
        }),
        [subtreeActionStyles.actionListItem],
    );

    const renderLabel = useMemo(
        () => (
            <Button
                className={classNames(
                    subtreeActionStyles.actionButton,
                    classes.actionButton,
                )}
                color={color}
                onClick={handler}
                data-cypress="create-folder"
                startIcon={
                    <Icon
                        className={classNames(
                            CREATE_BUILDING_ICON_NAME,
                            subtreeActionStyles.actionButtonIcon,
                            classes.actionButtonIcon,
                        )}
                    />
                }
            >
                {t('Create folder')}
            </Button>
        ),
        [
            handler,
            t,
            subtreeActionStyles.actionButton,
            subtreeActionStyles.actionButtonIcon,
            classes.actionButton,
            classes.actionButtonIcon,
            color,
        ],
    );

    return (
        <BaseTreeView.Item
            classes={subtreeActionClasses}
            nodeId="createFolder"
            LabelComponent={renderLabel}
        />
    );
};

SubtreeAction.propTypes = {
    classes: PropTypes.shape({
        actionButton: PropTypes.string,
        actionButtonIcon: PropTypes.string,
    }),
    color: PropTypes.string,
    handler: PropTypes.func.isRequired,
};

SubtreeAction.defaultProps = {
    classes: {
        actionButton: '',
        actionButtonIcon: '',
    },
    color: 'primary',
};

export default memo(SubtreeAction);
