import { Tooltip } from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { InfoOutlined } from '@material-ui/icons';
import classNames from 'classnames';
import type { PropsWithChildren } from 'react';
import { useEffect, useCallback, memo, useState } from 'react';

import { DIRECTION } from 'utils/filtering';
import type { OrderBy, Direction } from 'utils/filtering/types';

import { useStyles } from './styles';

const ARIA_SORT_DIRECTIONS = Object.freeze({
    [DIRECTION.ASC]: 'ascending',
    [DIRECTION.DESC]: 'descending',
});

interface SortableTableHeaderProps {
    classes?: {
        headerTitle?: string;
        sortableHeader?: string;
        sortableHeaderTitle?: string;
    };
    orderBy?: OrderBy;
    name: string;
    onOrderChange?: (name: string, ascendingFirstOverride?: boolean) => void;
    initialDirection?: Direction;
    tooltip?: string;
}

const SortableTableHeader = ({
    classes = {},
    orderBy,
    name,
    onOrderChange,
    children,
    initialDirection = DIRECTION.DESC,
    tooltip,
}: PropsWithChildren<SortableTableHeaderProps>): JSX.Element => {
    const [isSortLabelActive, setSortLabelActive] = useState(false);
    const styles = useStyles({ isSortLabelActive });
    const handleSort = useCallback(
        (e) => {
            if ((e.key === 'Enter' || !e.key) && onOrderChange) {
                onOrderChange(name, initialDirection === DIRECTION.ASC);
            }
        },
        [onOrderChange, name, initialDirection],
    );

    const ariaSort = (
        orderBy?.column === name ? ARIA_SORT_DIRECTIONS[orderBy?.direction] : 'descending'
    ) as 'ascending' | 'descending';

    useEffect(() => {
        if (orderBy?.column !== name) {
            setSortLabelActive(false);
        }
    }, [name, orderBy?.column]);

    return (
        <div
            onClick={handleSort}
            onKeyPress={handleSort}
            onMouseEnter={() => setSortLabelActive(true)}
            onMouseLeave={() => setSortLabelActive(false)}
            onFocus={() => setSortLabelActive(true)}
            onBlur={() => setSortLabelActive(false)}
            role="columnheader"
            aria-sort={ariaSort}
            tabIndex={0}
            className={classNames(styles.sortableHeader, classes.sortableHeader)}
            data-cypress={`${name}-header`}
        >
            <Tooltip title={tooltip || children || ''} placement="top">
                <span>
                    <span
                        className={classNames(
                            styles.sortableHeaderTitle,
                            classes.headerTitle,
                            classes.sortableHeaderTitle,
                        )}
                    >
                        {children}
                        {tooltip && <InfoOutlined className={styles.infoIcon} />}
                    </span>
                </span>
            </Tooltip>
            <TableSortLabel
                tabIndex="-1"
                role="none"
                className={styles.icon}
                active={orderBy?.column === name || isSortLabelActive}
                direction={
                    orderBy?.column === name ? orderBy?.direction : initialDirection
                }
            />
        </div>
    );
};

export default memo(SortableTableHeader);
