import { LoadingPlaceholder } from '@infogrid/components-material-ui';
import { memo, lazy, Suspense, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { FloorMapProps, FloorMapType } from '../FloorMap/FloorMap';
import type { BaseMapItem } from '../types';
import { useStylesFloorPlan } from './styles';

// Assignment required to preserve Generic inference as TypeScript doesn't have Higher Kinded Types.
const FloorMap = lazy(
    () => import(/* webpackChunkName: "FloorMap", webpackPreload: true */ '../FloorMap'),
) as FloorMapType;

const FloorPlan = <T extends BaseMapItem>(
    floorMapProps: Omit<FloorMapProps<T>, 'outerContainerRef'>,
) => {
    const { t } = useTranslation('floorplan');
    const styles = useStylesFloorPlan();

    const container = useRef<HTMLDivElement>(null);

    return (
        <div className={styles.floorPlanContainer}>
            <div className={styles.uploadCardContainer} data-cypress="floor-card"></div>

            <div className={styles.floor}>
                <div className={styles.mapContainer} ref={container}>
                    <Suspense
                        fallback={
                            <LoadingPlaceholder
                                classes={{
                                    container: styles.mapLoaderContainer,
                                    title: styles.mapLoaderText,
                                }}
                                titleVariant="body1"
                                progressSize={56}
                                text={t('Loading Map...')}
                            />
                        }
                    >
                        <FloorMap
                            outerContainerRef={container}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...floorMapProps}
                        />
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

// Assignment required to preserve Generic inference as TypeScript doesn't have Higher Kinded Types.
export default memo(FloorPlan) as typeof FloorPlan;
