import { queryClient } from '@infogrid/core-api';
import {
    getFloorSpaceCountByTypeKey,
    getSpaceKey,
    getSpacesKey,
    getSpaceSensorsKey,
} from '@infogrid/locations-api';
import type { SpacesParams } from '@infogrid/locations-api';

export const cancelSpaceCountByType = (floorId: number): Promise<void> =>
    queryClient.cancelQueries(getFloorSpaceCountByTypeKey(floorId));

export const invalidateSpaceCountByType = (floorId: number): Promise<void> =>
    queryClient.invalidateQueries(getFloorSpaceCountByTypeKey(floorId));

export const cancelSpaces = (params: SpacesParams): Promise<void> =>
    queryClient.cancelQueries(getSpacesKey(params));

export const invalidateSpaces = (params: SpacesParams): Promise<void> =>
    queryClient.invalidateQueries(getSpacesKey(params));

export const cancelSpace = (spaceId: number): Promise<void> =>
    queryClient.cancelQueries(getSpaceKey(spaceId));

export const invalidateSpace = (spaceId: number): Promise<void> =>
    queryClient.invalidateQueries(getSpaceKey(spaceId));

export const invalidateSpaceSensors = (spaceId: number): Promise<void> =>
    queryClient.invalidateQueries(getSpaceSensorsKey(spaceId));
