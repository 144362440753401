import type { WidgetType, WidgetMetadataType } from '@infogrid/dashboards-constants';
import { WIDGET_TYPE } from '@infogrid/dashboards-constants';

import { WidgetMetadata as CO2ConcentrationWidgetMetadata } from 'views/dashboards/widgets/CO2ConcentrationWidget';
import { WidgetMetadata as CubicleOccupancyStoplightWidgetMetadata } from 'views/dashboards/widgets/CubicleOccupancyStoplightWidget';
import { WidgetMetadata as DeskOccupancyWidgetMetadata } from 'views/dashboards/widgets/DeskOccupancyWidget';
import { WidgetMetadata as FloorPlanWidgetMetadata } from 'views/dashboards/widgets/FloorPlanWidget';
import { WidgetMetadata as HealthyBuildingScoreWidgetMetadata } from 'views/dashboards/widgets/HealthyBuildingScoreWidget';
import { WidgetMetadata as IndoorAirQualityWidgetMetadata } from 'views/dashboards/widgets/IndoorAirQualityWidget';
import { WidgetMetadata as LiveIndoorAirQualityWidget } from 'views/dashboards/widgets/LiveIndoorAirQualityWidget';
import { WidgetMetadata as MeetingRoomOccupancyWidgetMetadata } from 'views/dashboards/widgets/MeetingRoomOccupancyWidget';
import { WidgetMetadata as PeopleCountingStoplightWidgetMetadata } from 'views/dashboards/widgets/PeopleCountingStoplightWidget';
import { WidgetMetadata as PipeMonitoringWidgetAtRiskMetadata } from 'views/dashboards/widgets/PipeMonitoringAtRiskWidget';
import { WidgetMetadata as PipeMonitoringWidgetMetadata } from 'views/dashboards/widgets/PipeMonitoringWidget';
import { WidgetMetadata as ProximityAndTouchCountWidgetMetadata } from 'views/dashboards/widgets/ProximityAndTouchCountWidget';
import { WidgetMetadata as ProximityCountWidgetMetadata } from 'views/dashboards/widgets/ProximityCountWidget';
import { WidgetMetadata as TouchCountWidgetMetadata } from 'views/dashboards/widgets/TouchCountWidget';

type ContainerType = { [key in WidgetType]: WidgetMetadataType };

const Container: ContainerType = {
    [WIDGET_TYPE.CUBICLE_OCCUPANCY_STOPLIGHT]: CubicleOccupancyStoplightWidgetMetadata,
    [WIDGET_TYPE.DESK_OCCUPANCY]: DeskOccupancyWidgetMetadata,
    [WIDGET_TYPE.FLOOR_PLAN]: FloorPlanWidgetMetadata,
    [WIDGET_TYPE.HEALTHY_BUILDING_SCORE]: HealthyBuildingScoreWidgetMetadata,
    [WIDGET_TYPE.INDOOR_AIR_QUALITY]: IndoorAirQualityWidgetMetadata,
    [WIDGET_TYPE.LIVE_INDOOR_AIR_QUALITY]: LiveIndoorAirQualityWidget,
    [WIDGET_TYPE.PEOPLE_COUNTING_STOPLIGHT]: PeopleCountingStoplightWidgetMetadata,
    [WIDGET_TYPE.PIPE_MONITORING]: PipeMonitoringWidgetMetadata,
    [WIDGET_TYPE.PIPE_MONITORING_RISK]: PipeMonitoringWidgetAtRiskMetadata,
    [WIDGET_TYPE.PROXIMITY_AND_TOUCH_COUNT]: ProximityAndTouchCountWidgetMetadata,
    [WIDGET_TYPE.PROXIMITY_COUNT]: ProximityCountWidgetMetadata,
    [WIDGET_TYPE.SPACE_OCCUPANCY]: MeetingRoomOccupancyWidgetMetadata,
    [WIDGET_TYPE.TOUCH_COUNT]: TouchCountWidgetMetadata,
    [WIDGET_TYPE.CO2_CONCENTRATION]: CO2ConcentrationWidgetMetadata,
};

export const getWidgetMetadataByType = (type: WidgetType): WidgetMetadataType => {
    const widgetMetadata = Container[type];

    if (!widgetMetadata) {
        throw new Error(`Invalid widget type: ${type}`);
    }

    return widgetMetadata;
};

export const WidgetsMetadata = Object.values(Container).sort((a, b) => a.order - b.order);
