import type { CreateSpaceFormValues } from '@infogrid/locations-types';
import { Formik } from 'formik';
import { memo } from 'react';

import {
    useFloorSpaceCountByType,
    useCreateBulkSpaces,
} from 'apiHooks/floorPlan/spaces/hooks';

import AddSpace from './AddSpaceModal';

export interface AddSpaceModalProps {
    buildingId: number;
    floorId: number;
    isOpen: boolean;
    onClose: () => void;
}

const INITIAL_FORM_VALUES = {};

const AddSpaceModal = ({ buildingId, floorId, isOpen, onClose }: AddSpaceModalProps) => {
    const { data, isLoading } = useFloorSpaceCountByType(floorId);

    const groups = data ?? [];

    const { mutate: addSpaces } = useCreateBulkSpaces(buildingId);

    const onSubmit = (spaces: CreateSpaceFormValues) =>
        addSpaces({
            floor: floorId,
            spaces: Object.values(spaces),
        });

    return (
        <Formik<CreateSpaceFormValues>
            initialValues={INITIAL_FORM_VALUES}
            onSubmit={onSubmit}
        >
            {(formik) => (
                <AddSpace
                    formik={formik}
                    groups={groups}
                    isLoading={isLoading}
                    isOpen={isOpen}
                    onClose={onClose}
                />
            )}
        </Formik>
    );
};

export default memo(AddSpaceModal);
