import { call } from 'redux-saga/effects';

import { fetchTeamWorkerBase } from 'sagas/account/fetchTeam';
import { invalidateCache } from 'sagas/helpers/fetchingGuard';
import { teamsSchema } from 'schemas/teams';

export function* teamWorker({ action, team }) {
    if (action === 'update') {
        yield fetchTeamWorkerBase.saveEffect(team, { mergeEntities: true });
    } else {
        yield call(invalidateCache, teamsSchema.key);
    }
}
