import { ensureStatusAndJson } from 'utils/parsers/ensureStatusAndJson';
import { formErrorsHandler } from 'utils/parsers/formError';

export function handleError(error) {
    const errorEntity = ensureStatusAndJson(error);

    if (errorEntity) {
        return formErrorsHandler({
            error: errorEntity,
        });
    }

    return {};
}
