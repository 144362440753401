import { makeStyles } from '@material-ui/core';

export const useInstallationFlowModalStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 600,
        fontSize: '20px',
    },
    titleSuccess: {
        color: theme.palette.success.main,
    },
    titleError: {
        color: theme.palette.error.main,
    },
}));
