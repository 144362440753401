import { wrapResponsePromise } from '@infogrid/core-api';
import type { IPagination, AxiosParsedError } from '@infogrid/core-types';
import { spaceControllers, getSpacesKey } from '@infogrid/locations-api';
import type { SpacesParams } from '@infogrid/locations-api';
import type { Space } from '@infogrid/locations-types';
import isNil from 'lodash/isNil';
import type { UseQueryResult, UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

const PAGE_SIZE = 1000; // Big enough to ensure we fetch all spaces on a floor
const STALE_TIME = 1000 * 60 * 5; // 5 mins

interface Config<TData> {
    params?: SpacesParams;
    options?: UseQueryOptions<Space, AxiosParsedError, TData>;
}

export const useAllSpaces = <TData = IPagination<Space>>({
    params,
    options = {},
}: Config<TData>): UseQueryResult<TData, AxiosParsedError> => {
    const queryParams = { floor: null, page_size: PAGE_SIZE, ...params };

    return useQuery(
        getSpacesKey(queryParams),
        ({ signal }) =>
            wrapResponsePromise(spaceControllers.getSpaces(signal, queryParams)),
        {
            staleTime: STALE_TIME,
            enabled: !isNil(params?.floor),
            refetchOnMount: true,
            ...options,
        },
    );
};
