import type { GenericSensorShape } from '@infogrid/sensors-configuration';
import { getSensor } from '@infogrid/sensors-configuration';

export interface SensorBatteryReturnType {
    isAvailable: boolean;
    isApplicable: boolean;
    level: number;
}

const useSensorBattery = (sensor: GenericSensorShape): SensorBatteryReturnType => {
    const sensorConfig = getSensor(sensor);

    const level = sensorConfig.getBatteryLevel();

    return {
        isAvailable: sensorConfig.isBatteryLevelAvailable,
        isApplicable: !Number.isNaN(level),
        level,
    } as SensorBatteryReturnType;
};

export default useSensorBattery;
