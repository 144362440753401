import { createDetailSchemaSelector, createSchemaSelector } from '@thorgate/spa-entities';
import { schema } from 'normalizr';
import { createSelector } from 'reselect';

import { addIsUniqFieldToEntities } from 'utils/addIsUniqFieldToEntities';
import { createFilteredKeyFn } from 'utils/relations';

export const orgUserSchema = new schema.Entity('orgUsers');

export const orgUsersKeyFn = createFilteredKeyFn(orgUserSchema.key);

export const selectOrgUsersWithSchema = createSchemaSelector(
    orgUserSchema,
    orgUsersKeyFn,
);

export const selectOrgUser = createDetailSchemaSelector(orgUserSchema);

export const selectOrgUsers = createSelector(
    selectOrgUsersWithSchema,
    addIsUniqFieldToEntities('name'),
);
