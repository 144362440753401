import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';
import isNil from 'lodash/isNil';

import { RATING_COLOURS } from '../../constants';
import type { AirQualityValue } from '../../types';

interface Props {
    performance?: AirQualityValue | null;
}

export const useUnitValueStyles = makeStyles((theme) => ({
    text: ({ performance }: Props) => ({
        color: isNil(performance) ? undefined : RATING_COLOURS[performance],
        fontWeight: theme.typography.fontWeightLight as FontWeightProperty,
    }),
    unit: {
        fontSize: 64,
    },
    value: {
        fontSize: 120,
        lineHeight: '151px',
    },
}));
