import { useAppSelector } from '@infogrid/core-ducks';
import type { EventsWebhook } from '@infogrid/core-types';
import { selectOrganizationId } from '@infogrid/user-ducks';
import pick from 'lodash/pick';
import { memo, useCallback } from 'react';

import useCreateEventsWebhook from 'apiHooks/eventsWebhook/hooks/useCreateEventsWebhook';
import type { CreateEventsWebhookPayload } from 'apiHooks/eventsWebhook/types';

import EventsWebhookFormContainer from './EventsWebhookForm.container';
import type { CreateEventsWebhookVariables } from './types';

const getEventsWebhookCreateValues = (
    eventsWebhook: EventsWebhook,
): CreateEventsWebhookPayload =>
    pick(eventsWebhook, ['url', 'is_enabled', 'send_signature', 'organization_id']);

export const CREATE_EVENTS_WEBHOOK_INITIAL_VALUES: CreateEventsWebhookVariables = {
    url: 'https://',
    secret: '',
    is_enabled: false,
    send_signature: undefined,
};

const CreateEventsWebhookForm = () => {
    const { mutate: createEventsWebhook } = useCreateEventsWebhook();

    const organizationId = useAppSelector(selectOrganizationId);

    const onSubmit = useCallback(
        (data, options) => {
            createEventsWebhook(
                getEventsWebhookCreateValues({
                    organization_id: organizationId,
                    ...data,
                    is_enabled: true,
                }),
                options,
            );
        },
        [createEventsWebhook, organizationId],
    );

    return (
        <EventsWebhookFormContainer<CreateEventsWebhookVariables>
            initialValues={CREATE_EVENTS_WEBHOOK_INITIAL_VALUES}
            onSubmit={onSubmit}
            canChangeSecret={false}
            showSecret={false}
            canChangeURL
            canChangeSendSignature
            showCreateButton
            disableIsEnabledField
        />
    );
};

export default memo(CreateEventsWebhookForm);
