import type { Dashboard } from '@infogrid/dashboards-constants';

type ComparatorResult = 1 | -1 | 0;

export const sortDashboards = (d1: Dashboard, d2: Dashboard): ComparatorResult => {
    if (d1.is_default && !d2.is_default) {
        return -1;
    }

    if (d2.is_default && !d1.is_default) {
        return 1;
    }

    return 0;
};
