import loadable from '@loadable/component';
import type { RouteConfig } from 'react-router-config';

import { userIsAuthenticated, userIsManager } from 'decorators/auth';
import RootBuildings from 'views/buildings/pages/floorView';

const BuildingsView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-buildings-pages-buildings-buildings' */ 'views/buildings/pages/buildings'
        ),
);

const BuildingView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-buildings-pages-building' */ 'views/buildings/pages/building'
        ),
);

const FloorPlanView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-buildings-pages-floorplan' */ 'views/buildings/pages/floorPlan'
        ),
);

const SensorPlanningView = loadable(
    () =>
        import(
            /* webpackChunkName: 'views-buildings-pages-floorplan' */ 'views/buildings/pages/sensorPlanning'
        ),
);

export const createBuildingRoutes = (PageNotFoundRoute: RouteConfig): RouteConfig => ({
    path: '/buildings/',
    name: 'buildings',
    component: userIsAuthenticated(RootBuildings),
    routes: [
        {
            path: '/buildings/',
            exact: true,
            name: 'buildingList',
            pageName: 'Buildings',
            component: BuildingsView,
        },
        {
            path: '/buildings/:buildingId/',
            exact: true,
            name: 'building',
            pageName: 'Floor View',
            component: BuildingView,
        },
        {
            path: '/buildings/:buildingId/floors/:floorId',
            exact: true,
            name: 'floor',
            pageName: 'Floor View',
            component: FloorPlanView,
        },
        {
            path: '/buildings/:buildingId/floors/:floorId/plan',
            exact: true,
            name: 'planning',
            pageName: 'Sensor Planning View',
            component: userIsManager(SensorPlanningView),
        },
        PageNotFoundRoute,
    ],
});
