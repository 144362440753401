import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

import { nodeContainsBuilding } from 'views/buildings/helpers/nodeContainsBuildings';

import TreeNode from './TreeNode';

const FoldersSubtree = (props) => {
    const { node, folderPath, ...rest } = props;
    const {
        renderActions,
        checkIfExpanded,
        checkIfSelected,
        keyExtractor,
        labelIconExtractor,
        valueExtractor,
        nodeTypeExtractor,
        nodePermissionsExtractor,
        subitemsExtractor,
        expandableTypes,
    } = rest;

    const nodeId = keyExtractor(node);
    const genericNodeEditable = nodePermissionsExtractor(node) ?? false;
    const nodeEditable =
        (nodePermissionsExtractor(node) && !nodeContainsBuilding(node)) ?? false;
    const nodeIcon = labelIconExtractor(node);
    const nodeType = nodeTypeExtractor(node);
    const nodeValue = valueExtractor(node);
    const nodeChildren = subitemsExtractor(node);

    const isOfExpandableType = expandableTypes?.includes(nodeType) ?? true;

    const isExpanded = checkIfExpanded(nodeId);
    const isSelected = checkIfSelected(nodeId);

    const nodeContentLength = nodeChildren?.length ?? 0;

    const isExpandable = isOfExpandableType && (nodeEditable || !!nodeContentLength);

    const path = useMemo(() => [...folderPath, nodeId], [folderPath, nodeId]);

    const actionsProps = useMemo(
        () => ({
            folderPath: path,
            nodeId,
            nodeEditable: genericNodeEditable,
            nodeType,
            nodeValue,
            nodeChildren,
            isOfExpandableType,
        }),
        [
            nodeId,
            genericNodeEditable,
            nodeType,
            nodeValue,
            nodeChildren,
            isOfExpandableType,
            path,
        ],
    );

    return (
        <TreeNode
            contentLength={nodeContentLength}
            id={nodeId}
            isEditable={nodeEditable}
            isExpandable={isExpandable}
            isExpanded={isExpandable && isExpanded}
            isSelected={isSelected}
            nodeIcon={nodeIcon}
            nodeName={nodeValue}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
        >
            {renderActions(actionsProps)}

            {Array.isArray(nodeChildren) &&
                nodeChildren.map((item) => (
                    <FoldersSubtree
                        key={keyExtractor(item)}
                        node={item}
                        folderPath={path}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...rest}
                    />
                ))}
        </TreeNode>
    );
};

FoldersSubtree.propTypes = {
    checkIfExpanded: PropTypes.func.isRequired,
    checkIfSelected: PropTypes.func.isRequired,
    node: PropTypes.shape({}).isRequired,
    folderPath: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ).isRequired,
    renderActions: PropTypes.func.isRequired,
    expandableTypes: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.oneOf([null]),
    ]).isRequired,
};

export default memo(FoldersSubtree);
