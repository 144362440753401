import classnames from 'classnames';
import { memo } from 'react';

import { useViewFinderStyles } from './styles';

const ViewFinder = () => {
    const classes = useViewFinderStyles();

    return (
        <div className={classes.viewFinder}>
            <div
                className={classnames(
                    classes.viewFinderCorner,
                    classes.viewFinderTopLeft,
                )}
            />
            <div
                className={classnames(
                    classes.viewFinderCorner,
                    classes.viewFinderTopRight,
                )}
            />
            <div
                className={classnames(
                    classes.viewFinderCorner,
                    classes.viewFinderBottomRight,
                )}
            />
            <div
                className={classnames(
                    classes.viewFinderCorner,
                    classes.viewFinderBottomLeft,
                )}
            />
        </div>
    );
};

export default memo(ViewFinder);
