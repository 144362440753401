import { wrapResponsePromise } from '@infogrid/core-api';
import { useMutation } from 'react-query';

import { removeFloorSensor } from '../../floors/accessors';
import { invalidateFloorSensors } from '../accessors';
import { controllers } from '../controllers';

interface UseRemoveMapSensorsProps {
    floorId: number;
}

export const useRemoveMapSensor = ({ floorId }: UseRemoveMapSensorsProps) => {
    const { mutate: unmapSensor } = useMutation(
        ({ sensorDeviceName }: { sensorDeviceName: string }) =>
            wrapResponsePromise(
                controllers.editMapSensor({
                    floorId,
                    data: { x: null, y: null },
                    sensorDeviceName,
                }),
            ),
        {
            async onMutate({ sensorDeviceName }) {
                removeFloorSensor(floorId, sensorDeviceName);
                invalidateFloorSensors({ floorId });
            },
        },
    );

    return {
        unmapSensor,
    };
};
