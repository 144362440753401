import { useAppDispatch, toastSuccess } from '@infogrid/core-ducks';
import type { EventsWebhook } from '@infogrid/core-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import type { FormikProps } from 'formik';
import { useField } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSecretWithCopyFieldStyles } from './styles';

interface SecretWithCopyFieldProps {
    formik: FormikProps<EventsWebhook>;
    name: string;
    classes?: {
        root?: string;
        field?: string;
        button?: string;
    };
    disabled?: boolean;
}

const SecretWithCopyField = ({
    formik,
    name,
    classes,
    disabled = false,
}: SecretWithCopyFieldProps): JSX.Element => {
    const styles = useSecretWithCopyFieldStyles();

    const { t } = useTranslation('integrations');
    const dispatch = useAppDispatch();

    const [{ value, onBlur, onChange }, { error, touched }] = useField<string>(name);

    const copyToClipboard = () => {
        copy(formik.values.secret);
        dispatch(
            toastSuccess({
                message: t('Secret Key copied to clipboard.'),
            }),
        );
    };

    return (
        <div className={classNames(styles.root, classes?.root)}>
            <TextField
                error={!!touched && !!error}
                helperText={touched && error && t(error)}
                name={name}
                label={t('Secret')}
                value={value}
                inputProps={{
                    'data-cypress': 'events-webhook-secret',
                    'data-testid': 'events-webhook-secret',
                }}
                type="text"
                className={classNames(classes?.field)}
                variant="outlined"
                disabled={disabled}
                onChange={onChange}
                onBlur={onBlur}
            />
            <Button
                data-cypress="events-webhook-secret-copy"
                data-testid="events-webhook-secret-copy"
                className={classes?.button}
                onClick={copyToClipboard}
                color="primary"
            >
                {t('COPY SECRET KEY')}
            </Button>
        </div>
    );
};

export default memo(SecretWithCopyField);
