import type { BuildingsHierarchyQueryParams } from '@infogrid/locations-api';
import type { SensorType } from '@infogrid/sensors-constants';
import isEmpty from 'lodash/isEmpty';
import type { QueryKey } from 'react-query';

import {
    BUILDINGS_KEY,
    DEFAULT_BUILDING,
    BUILDINGS_HIERARCHY_QUERY_KEY,
    BUILDINGS_HIERARCHY_FOR_USER_QUERY_KEY,
    BUILDINGS_COUNT_QUERY_KEY,
    BUILDINGS_ALERT_COUNTS_QUERY_KEY,
    BUILDINGS_FILTERS_QUERY_KEY,
    BUILDING_TYPES_QUERY_KEY,
    PAGINATED_BUILDINGS_KEY,
} from 'apiHooks/base/queryKeys';

import type { BuildingsParams } from './types';

export const getBuildingsKey = (params?: BuildingsParams): QueryKey => [
    BUILDINGS_KEY,
    params,
];

export const getPaginatedBuildingsKey = (params?: BuildingsParams): QueryKey => [
    PAGINATED_BUILDINGS_KEY,
    params,
];

export const getBuildingKey = (buildingId: number | null): QueryKey => [
    BUILDINGS_KEY,
    buildingId,
];

export const getDefaultBuildingKey = (): QueryKey => [DEFAULT_BUILDING];

export const getBuildingsHierarchyKey = (): QueryKey => [BUILDINGS_HIERARCHY_QUERY_KEY];

export const getBuildingsHierarchyForUserKey = (
    params?: BuildingsHierarchyQueryParams,
): QueryKey => {
    if (isEmpty(params)) {
        return [BUILDINGS_HIERARCHY_FOR_USER_QUERY_KEY];
    }

    return [BUILDINGS_HIERARCHY_FOR_USER_QUERY_KEY, params];
};

export const getBuildingCountKey = (params: { id: string }): QueryKey => [
    BUILDINGS_COUNT_QUERY_KEY,
    params,
];

export const getBuildingAlertCountsKey = (
    buildingId: number,
    params?: { sensor_type?: SensorType[] },
): QueryKey => [BUILDINGS_ALERT_COUNTS_QUERY_KEY, buildingId, params];

export const getBuildingsFiltersKey = (): QueryKey => [BUILDINGS_FILTERS_QUERY_KEY];

export const getBuildingTypesKey = (): QueryKey => [BUILDING_TYPES_QUERY_KEY];
