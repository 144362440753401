import { useIsMobile } from '@infogrid/utils-hooks';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import { memo } from 'react';

import { useStylesContainer } from './styles';

export interface PageContainerProps {
    children: ReactNode;
    className?: string;
    dataAtr?: { [key: string]: unknown };
}

const DEFAULT_DATA_ATR = {};

const PageContainer = ({
    className = '',
    children,
    dataAtr = DEFAULT_DATA_ATR,
}: PageContainerProps) => {
    const isMobile = useIsMobile();

    const stylesPageContainer = useStylesContainer({ isMobile });

    return (
        <div
            className={classNames(stylesPageContainer.childrenContent, className)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...dataAtr}
        >
            {children}
        </div>
    );
};

export default memo(PageContainer);
