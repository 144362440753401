import { makeStyles } from '@material-ui/core';

export const useWidgetStyles = makeStyles((theme) => ({
    header: {
        borderBottom: `1px solid #E0E0E0`,
    },
    content: {
        paddingTop: theme.spacing(1),
        overflow: 'auto',
    },
    tableNameCell: {
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        color: theme.palette.primary.main,
    },
}));
