import { call, take, put } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { SocketConnected } from './SocketMonitor';
import { RECV_MESSAGE, sendSocketMessage } from './actionTypes';

export function* sendSocket(stream, message, noConfirm = false) {
    const requestId = uuid();

    // Ensure socket is connected
    yield call(SocketConnected);

    // Send message action to socket write IO listener
    yield put(sendSocketMessage(stream, message, requestId));

    if (noConfirm) {
        return null;
    }

    const action = yield take(
        ({ type, response }) =>
            type === RECV_MESSAGE &&
            response?.payload &&
            response.payload.request_id === requestId,
    );

    return action.response?.payload;
}
