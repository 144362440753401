/* eslint-disable id-denylist */

import { makeStyles } from '@material-ui/core';

import { SCROLLBAR_WIDTH } from 'components/material-ui/Table/constants';

export const useBuildingSwitcherStyles = makeStyles((theme) => ({
    autoSizerContainer: {
        display: 'flex',
        flex: 1,
        height: '100%',
    },
    container: ({ isMobile }) => ({
        maxWidth: isMobile ? '100%' : 500,
        width: '100%',

        order: 2,

        ...(isMobile && {
            border: '1px solid rgba(145, 141, 133, 0.4)',
            borderRadius: '4px',
        }),
    }),
    content: ({ isMobile }) => ({
        '&:before, &:after': {
            display: isMobile ? 'none' : 'block',
        },
    }),
    renderValueIcon: ({ isMobile }) => ({
        marginLeft: isMobile && 'auto',
    }),
    actionsSection: {
        paddingBottom: 0,
    },
    actionsSectionItem: {
        color: theme.palette.primary.main,
    },
    actionsSectionItemIcon: {
        fontSize: '18px',
    },
    itemText: {
        textTransform: 'capitalize',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',

        overflow: 'hidden',

        display: '-webkit-box',

        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
    },
    activeBuilding: {
        pointerEvents: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    scrollbar: {
        scrollbarColor: `${theme.palette.common.gray4} transparent`,
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: SCROLLBAR_WIDTH,
            height: SCROLLBAR_WIDTH,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.common.gray4,
            borderRadius: theme.shape.borderRadius,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-corner': {
            background: 'transparent',
        },
    },
    buildingsList: {
        marginBottom: 0,
        padding: 0,
        overflowY: 'auto',
    },
    menuListContainer: {
        height: '280px',
        overflowY: 'visible',
    },
}));
