import {
    RequestValidationError,
    InvalidResponseCode,
    NetworkError,
    BaseValidationError,
    SingleValidationError,
    ListValidationError,
    ValidationError,
    hasValue,
    isArray,
    isObject,
    isString,
    isStringArray,
    isStatusCode,
} from './errors';

/**
 * Convert an error into a subclass of BaseValidationError
 *
 * @param {*} err
 * @param {Object} parentConfig
 */
export function prepareError(err, parentConfig = null) {
    if (isString(err)) {
        // Note: SingleValidationError contains a list of errors per field
        return new SingleValidationError([err || '#$empty-message$#']);
    }

    if (isArray(err)) {
        // If the array contains only strings, turn it into a SingleValidationError
        if (isStringArray(err)) {
            return new SingleValidationError(err);
        }

        // Parse children of the error and continue
        const errors = err.map((x) =>
            parentConfig ? parentConfig.prepareError(x, parentConfig) : prepareError(x),
        );

        // Should be a nested error, turn it into ListValidationError
        return new ListValidationError(errors);
    }

    if (isObject(err)) {
        let errors = err;

        if ('errors' in errors && hasValue(errors.errors)) {
            ({ errors } = errors);
        }

        let resNonField = null;
        const resErrors = {};

        Object.keys(errors).forEach((key) => {
            const error = parentConfig
                ? parentConfig.prepareError(errors[key], parentConfig)
                : prepareError(errors[key]);

            if (key === 'non_field_errors') {
                resNonField = error;
            } else if (error !== null) {
                // note: we only add errors as fields if we can parse the field error (or need to)
                resErrors[key] = error;
            }
        });

        // if no errors ensure at least a default message is set
        if (Object.keys(resErrors).length === 0 && !resNonField) {
            // Should only happen with custom error parsers
            if (Object.keys(err).length === 0) {
                // We are probably inside a ListValidationError where
                // this item is not an error, represented as `{}` by DRF
                return new ValidationError({});
            }

            // Should only happen with custom error parsers
            resNonField = new SingleValidationError([
                hasValue(err) ? JSON.stringify(err) : '#$empty-message$#',
            ]);
        }

        return new ValidationError(resErrors, resNonField);
    }

    // If undefined/null, lets turn it into a null
    if (!hasValue(err)) {
        return null;
    }

    // Everything else gets turned into a string (bools, numbers, binary)
    // Note: Empty string means there is no error
    const errorString = `${err}`;

    // only custom classes w/ custom toString
    if (errorString) {
        return new SingleValidationError([errorString]);
    }

    // only custom classes w/ custom toString
    return null;
}

/**
 * Convert errorText (json or normal text) to a ValidationError
 *
 * @param {*} errorText
 * @param {Object} parentConfig
 */
export function parseErrors(errorText, parentConfig = null) {
    let error;

    if (!isObject(errorText)) {
        if (errorText) {
            try {
                error = JSON.parse(errorText);
            } catch (e) {
                // if json parsing fails, handle as text
                error = errorText;
            }
        } else {
            error = '';
        }
    } else {
        error = errorText;
    }

    // force undefined to be a string
    if (error === undefined) {
        error = `${undefined}`;
    }

    const result = parentConfig
        ? parentConfig.prepareError(error, parentConfig)
        : prepareError(error);

    if (!result) {
        return result;
    }

    //  can only happen w/ custom prepareError
    if (result instanceof BaseValidationError) {
        return result.hasError() ? result : null;
    }

    // For anything else, just return the result (why would anyone do this though?)
    // can only happen w/ custom prepareError
    return result;
}

const config = {
    parseErrors,
    prepareError,
    statusSuccess: [200, 201, 204],
    statusValidationError: [400],
};

export function ensureStatusAndJson(error) {
    if (error.response) {
        if (isStatusCode(config.statusValidationError, error.response.status)) {
            return new RequestValidationError(
                error.response.status,
                error.response.data,
                error.response.statusText,
                config,
            );
        }

        return new InvalidResponseCode(error.response.status, error.response.data);
    }

    if (error.request) {
        const { message } = error;

        return new NetworkError(
            message ||
                'Something went awfully wrong with the request, check network log.',
        );
    }

    // Something happened in setting up the request that triggered an Error
    return error.message;
}
