import type { USER_ACTIONS } from '@infogrid/utils-analytics';
import { Analytic } from '@infogrid/utils-analytics';

export const trackInstallFlowUserEvent = (
    userAction: USER_ACTIONS,
    eventName: string,
    properties?: Record<string, unknown>,
): void => {
    Analytic.trackActivity('InstallFlow', `${userAction} ${eventName}`, properties);
};
