import { makeStyles } from '@material-ui/core';

export const useErrorViewStyles = makeStyles((theme) => ({
    errorButton: {
        marginTop: theme.spacing(3),
    },
    errorContainer: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(0, 2),
    },
    errorIcon: {
        color: theme.palette.common.gray4,
        cursor: 'pointer',
        fontSize: 72,
        marginBottom: theme.spacing(2),
        overflow: 'visible',
    },
    errorText: {
        color: theme.palette.common.gray4,
        maxWidth: '550px',
        textAlign: 'center',
    },
}));
