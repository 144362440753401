import { SENSOR_TYPE } from '@infogrid/sensors-constants';
import { registerTranslationKey } from '@infogrid/utils-i18n';

import Sensors from 'views/dashboards/components/modals/ConfigureWidgetModal/Sensors';

export const NO_RISK = registerTranslationKey('No risk', {
    ns: 'dashboard',
});

export const LOW_RISK = registerTranslationKey('Low risk', {
    ns: 'dashboard',
});

export const AVERAGE_RISK = registerTranslationKey('Average risk', {
    ns: 'dashboard',
});

export const HIGH_RISK = registerTranslationKey('High risk', {
    ns: 'dashboard',
});

export const VERY_HIGH_RISK = registerTranslationKey('Very high risk', {
    ns: 'dashboard',
});

export const EXTREME_RISK = registerTranslationKey('Extreme risk', {
    ns: 'dashboard',
});

export const NO_RISK_COLOR = '#A2CF6E';
export const LOW_RISK_COLOR = '#AAAF60';
export const AVERAGE_RISK_COLOR = '#B09354';
export const HIGH_RISK_COLOR = '#B67547';
export const VERY_HIGH_RISK_COLOR = '#BC583A';
export const EXTREME_RISK_COLOR = '#D32F2F';

export const WidgetConfigurationModalTabs = [
    {
        id: 'sensors',
        name: registerTranslationKey('Sensors', { ns: 'common' }),
        component: Sensors,
        attributes: {
            allowFoldersSelection: true,
            sensorTypeFilter: [SENSOR_TYPE.TYPE_AIR_QUALITY],
        },
    },
];
