import type { RouteConfig } from 'react-router-config';

import RenderChildren from 'components/RenderChildren';
import { folderDetailWatcherSagas } from 'configuration/routes/folders';
import { userIsAuthenticated } from 'decorators/auth';
import Dashboard from 'views/dashboards/pages/Dashboard';
import DashboardsRoot from 'views/dashboards/pages/DashboardsRoot';

export const createDashboardRoutes = (PageNotFoundRoute: RouteConfig): RouteConfig => ({
    path: '/dashboards/',
    name: 'dashboards',
    component: userIsAuthenticated(RenderChildren),
    routes: [
        {
            path: '/dashboards/',
            exact: true,
            name: 'root-redirect',
            component: DashboardsRoot,
        },
        {
            path: '/dashboards/:dashboardId/',
            exact: true,
            name: 'details',
            pageName: 'Dashboard',
            component: Dashboard,
            watcher: folderDetailWatcherSagas,
        },
        PageNotFoundRoute,
    ],
});
