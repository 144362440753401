import { Modal } from '@infogrid/components-material-ui';
import { Button, Icon } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useStylesFinishingCreating } from './styles';

const FinishingCreating = ({
    title,
    newBuildingName,
    onClose,
    createAnotherBuilding,
}) => {
    const styles = useStylesFinishingCreating();

    const { t } = useTranslation('floorplan');

    return (
        <>
            <Modal.Title>{title}</Modal.Title>

            <Modal.Description>
                {t(
                    'Congratulations! Your new building {{newBuildingName}} has been created and linked to your existing folder',
                    {
                        newBuildingName,
                        defaultValue: `Congratulations! Your new building ${newBuildingName} has been created and linked to your existing folder`,
                    },
                )}
            </Modal.Description>

            <Modal.Actions justify="space-between">
                <Button color="primary" onClick={createAnotherBuilding}>
                    <Icon className={classNames('fal fa-plus', styles.addIcon)} />
                    {t('Create another building')}
                </Button>

                <Button
                    color="primary"
                    data-cypress="finish-btn"
                    variant="contained"
                    onClick={onClose}
                >
                    {t('Finish')}
                </Button>
            </Modal.Actions>
        </>
    );
};

FinishingCreating.propTypes = {
    onClose: PropTypes.func.isRequired,
    createAnotherBuilding: PropTypes.func.isRequired,
    newBuildingName: PropTypes.string,
    title: PropTypes.string,
};
FinishingCreating.defaultProps = {
    title: '',
    newBuildingName: '',
};

export default memo(FinishingCreating);
