import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { AirQualityWidgetTab, SeriesPoint } from '../../types';
import { generateFilteredSensorsUrl } from '../utils';
import { useTooltipStyles } from './styles';

interface Props {
    data: {
        points: SeriesPoint[];
    };
    pointColorExtractor: (point: SeriesPoint) => string;
    pointDisplayNameExtractor: (point: SeriesPoint) => string;
    pointKeyExtractor: (point: SeriesPoint) => string;
    pointNameExtractor: (point: SeriesPoint) => string;
    tabId: AirQualityWidgetTab;
    widgetId: number;
}

function Tooltip({
    data,
    pointColorExtractor,
    pointDisplayNameExtractor,
    pointKeyExtractor,
    pointNameExtractor,
    tabId,
    widgetId,
}: Props): JSX.Element {
    const { t } = useTranslation('dashboard');
    const styles = useTooltipStyles();

    const { points } = data;

    return (
        <div id="tooltip">
            <div className={classNames(styles.row, styles.header)}>
                <Typography id="tooltip-body" className={styles.label} variant="body2">
                    {t('State')}
                </Typography>
                <Typography className={styles.value} variant="body2">
                    {t('% of time')}
                </Typography>
            </div>
            {points.map((point: SeriesPoint) => {
                const pointIndex = point.point.index;
                const { timestamps } = point.series.options;

                const link = generateFilteredSensorsUrl({
                    // eslint-disable-next-line no-restricted-globals
                    baseUrl: location.origin,
                    widgetId,
                    rating: pointNameExtractor(point),
                    timestamp: timestamps[pointIndex],
                    airType: tabId === 'overall' ? undefined : tabId,
                });

                return (
                    <div
                        id={`tooltip-row-${point.series.name}`}
                        className={styles.row}
                        key={pointKeyExtractor(point)}
                    >
                        <div className={styles.wrapper}>
                            <div
                                className={styles.box}
                                data-testid={`point-color-box-${point.series.name}`}
                                style={{ backgroundColor: pointColorExtractor(point) }}
                            />
                            <a
                                className={styles.link}
                                href={link}
                                rel="noopener noreferrer"
                                target="_blank"
                                id={`state-${point.series.name}`}
                            >
                                <Typography className={styles.label} variant="body2">
                                    {pointDisplayNameExtractor(point)}
                                </Typography>
                            </a>
                        </div>
                        <a
                            className={styles.link}
                            href={link}
                            rel="noopener noreferrer"
                            target="_blank"
                            id="percentage-number"
                        >
                            <Typography className={styles.value} variant="body2">
                                {t('{{count}}% of time', { count: point.y })}
                            </Typography>
                        </a>
                    </div>
                );
            })}
        </div>
    );
}

const TooltipComponent = memo(Tooltip) as typeof Tooltip;

export default memo(TooltipComponent);
