import { makeStyles } from '@material-ui/core';

export const useWidgetAlertStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
    },
});
