import { ViewWrapper, MenuList, Select } from '@infogrid/components-material-ui';
import { useAppDispatch } from '@infogrid/core-ducks';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Typography, Icon } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ActionsMenu from 'components/ActionsMenu';
import PermissionsWrapper from 'components/PermissionsWrapper';
import { toggleCreatingFloor, toggleEditingFloor } from 'ducks/floorPlan';
import { FloorListShape } from 'utils/types';

import FloorSwitcherController from '../FloorSwitcherController';
import FloorSwitcherOutput from '../FloorSwitcherOutput';
import { useActionsMenuStyles, useFloorSwitcherStyles } from './styles';

const MENU_ADD_ACTION_ICON = 'fal fa-plus';

const FloorSwitcher = ({
    className,
    onSelectHandler,
    floors,
    isFloorEditable,
    selectedFloorId,
}) => {
    const dispatch = useAppDispatch();
    const isMobile = useIsMobile();

    const actionsMenuStyles = useActionsMenuStyles({ isMobile });
    const floorSwitcherStyles = useFloorSwitcherStyles({ isMobile });

    const { t } = useTranslation('floorplan');

    const openEditFloorHandler = useCallback(() => {
        dispatch(toggleEditingFloor(true));
    }, [dispatch]);

    const openCreatingFloorHandler = useCallback(() => {
        dispatch(toggleCreatingFloor(true));
    }, [dispatch]);

    const currentFloor = useMemo(
        () => floors.find((b) => b.id === selectedFloorId),
        [floors, selectedFloorId],
    );

    const renderSelectOutput = useCallback(
        () => !!currentFloor && <FloorSwitcherOutput currentFloor={currentFloor} />,
        [currentFloor],
    );

    const classes = useMemo(
        () => ({
            container: classNames(floorSwitcherStyles.container, className),
            content: floorSwitcherStyles.content,
            renderValueIcon: floorSwitcherStyles.renderValueIcon,
        }),
        [className, floorSwitcherStyles],
    );

    const renderContent = useCallback(
        ({ onSelect, onToggle }) => {
            const createFloor = () => {
                openCreatingFloorHandler();
                onToggle(false);
            };

            return (
                <>
                    <PermissionsWrapper>
                        <MenuList className={floorSwitcherStyles.actionsSection}>
                            <MenuList.Item
                                className={floorSwitcherStyles.actionsSectionItem}
                                onClick={createFloor}
                            >
                                <Icon
                                    className={classNames(
                                        MENU_ADD_ACTION_ICON,
                                        floorSwitcherStyles.actionsSectionItemIcon,
                                    )}
                                />
                                <Typography component="span" variant="subtitle1">
                                    {t('Add New Floor')}
                                </Typography>
                            </MenuList.Item>
                        </MenuList>
                    </PermissionsWrapper>

                    <MenuList.Divider />

                    <MenuList>
                        {floors.map((floor) => {
                            const contentClasses = classNames({
                                [floorSwitcherStyles.activeFloor]:
                                    floor.id === currentFloor,
                            });

                            const selectFloor = () => {
                                onSelect(floor);
                            };

                            return (
                                <MenuList.Item
                                    key={floor?.id}
                                    onClick={selectFloor}
                                    className={contentClasses}
                                >
                                    <Typography
                                        className={floorSwitcherStyles.itemText}
                                        component="span"
                                    >
                                        {floor?.name}
                                    </Typography>
                                </MenuList.Item>
                            );
                        })}
                    </MenuList>
                </>
            );
        },
        [openCreatingFloorHandler, floorSwitcherStyles, floors, currentFloor, t],
    );

    const renderActionsMenu = useCallback(
        ({ onToggle }) => {
            const editFloor = () => {
                openEditFloorHandler();
                onToggle(false);
            };

            return <FloorSwitcherController editFloor={editFloor} />;
        },
        [openEditFloorHandler],
    );

    return (
        <>
            <Select
                classes={classes}
                onSelect={onSelectHandler}
                renderValue={renderSelectOutput}
            >
                {renderContent}
            </Select>

            <ViewWrapper
                isVisible={isFloorEditable}
                className={actionsMenuStyles.menuContainer}
            >
                <ActionsMenu
                    classes={{
                        menuButtonIcon: actionsMenuStyles.menuButtonIcon,
                        menuContent: actionsMenuStyles.menuContent,
                    }}
                >
                    {renderActionsMenu}
                </ActionsMenu>
            </ViewWrapper>
        </>
    );
};

FloorSwitcher.propTypes = {
    className: PropTypes.string,
    floors: FloorListShape.isRequired,
    onSelectHandler: PropTypes.func.isRequired,
    selectedFloorId: PropTypes.number.isRequired,
    isFloorEditable: PropTypes.bool,
};
FloorSwitcher.defaultProps = {
    className: '',
    isFloorEditable: false,
};

export default memo(FloorSwitcher);
