import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useHelpStyles = makeStyles((theme) => ({
    title: {
        position: 'relative',
    },
    image: {
        marginBottom: theme.spacing(2.5),
    },
    instruction: {
        paddingInlineStart: theme.spacing(2),
    },
    paragraph: {
        ...theme.typography.body2,
        '& > strong': {
            fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
        },
    },
    backBtn: {
        position: 'absolute',
        right: '5px',
        zIndex: 2,
        top: '50%',
        transform: 'translateY(-50%)',
    },
}));
