import { makeStyles } from '@material-ui/core';

export const useContentStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },

    headerSection: {
        paddingLeft: theme.spacing(2.5),
        zIndex: 1,
    },
    headerInformationContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '80px',
    },
    headerInformationTitle: {
        lineHeight: '28px',
        fontWeight: 'bold',
    },
    headerTooltip: {
        display: 'inline',
        marginLeft: theme.spacing(-0.5),
    },

    buildingsAtRiskContainer: {},
    buildingsAtRiskCircleContainer: {
        display: 'flex',
        gap: '4px',
    },
    buildingsAtRiskCircle: {
        height: '42px',
        color: 'white',
        minWidth: '42px',
        borderRadius: '50%',
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: '42px',
        fontSize: theme.typography.pxToRem(20),
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    buildingsAtRiskCircleWithNoRisk: {
        backgroundColor: theme.palette.common.green1,
    },
    buildingsAtRiskCircleWithWithRisk: {
        backgroundColor: theme.palette.common.red2,
    },
    buildingsAtRiskRemainder: {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: '42px',
    },

    contentHeaderText: {
        marginTop: '25px',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold',
        marginBottom: '-10px',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0.5,
        paddingLeft: theme.spacing(0.5),
    },

    helpIcon: {
        paddingLeft: theme.spacing(1),
        color: theme.palette.common.gray6,
    },
}));
