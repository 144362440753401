import { Modal } from '@infogrid/components-material-ui';
import { routesManager } from '@infogrid/core-routing';
import { Button, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { ChangedLocationsCount } from 'views/solutionSettings/types';

import { useSuccessModalStyles } from './styles';

interface SucessModalProps {
    handleSuccessOnClick: () => void;
    locationChangeCount: ChangedLocationsCount;
}

export const SuccessModal = ({
    handleSuccessOnClick,
    locationChangeCount,
}: SucessModalProps) => {
    const { t } = useTranslation('solutions');
    const styles = useSuccessModalStyles();
    const { spaceCountChange, floorCountChange, buildingCountChange } =
        locationChangeCount;

    const moreThanOneFloorSpace = floorCountChange > 1 || spaceCountChange > 1;

    const buildingCountCopy = t('', {
        count: buildingCountChange,
        defaultValue___zero: ``,
        defaultValue___other: `added seperately`,
    });

    const atleastOneLocationAdded = (changedLocations: ChangedLocationsCount) => {
        if (
            changedLocations.spaceCountChange > 0 ||
            changedLocations.floorCountChange > 0 ||
            changedLocations.buildingCountChange > 0
        ) {
            return true;
        }

        return false;
    };

    const oneOrMoreLocationsAdded = atleastOneLocationAdded(locationChangeCount);

    useEffect(() => {
        if (!oneOrMoreLocationsAdded) {
            handleSuccessOnClick();
        }
    }, [handleSuccessOnClick, oneOrMoreLocationsAdded]);

    return oneOrMoreLocationsAdded ? (
        <div className={styles.successModal}>
            <Modal.Title className={styles.successModalTitle}>
                {!buildingCountChange &&
                    !moreThanOneFloorSpace &&
                    t('Location successfully added')}
                {(buildingCountChange || moreThanOneFloorSpace) &&
                    t('Locations successfully added')}
            </Modal.Title>
            <Modal.Content className={styles.successModalContent}>
                <div className={styles.successModalTextContent}>
                    <div className={styles.successModalMainText}>
                        <CheckCircleOutlineIcon className={styles.icon} />
                        <Typography variant="body1">
                            {buildingCountChange === 0 && !moreThanOneFloorSpace && (
                                <>
                                    {t(
                                        'Your location has been added to the Smart Cleaning solution.',
                                    )}
                                </>
                            )}
                            {(buildingCountChange > 0 || moreThanOneFloorSpace) && (
                                <>
                                    {t(
                                        'Your locations have been added to the Smart Cleaning solution.',
                                    )}
                                </>
                            )}
                        </Typography>
                    </div>
                    <br />
                    <div className={styles.successModalInfo}>
                        <Typography variant="subtitle1" className={styles.boldText}>
                            {t('Note: cleaning rules are defined automatically')}
                        </Typography>
                        <br />

                        {buildingCountChange > 0 && (
                            <>
                                <Typography variant="subtitle1">
                                    A building added to the Smart Cleaning solution will
                                    have our default cleaning rule automatically applied
                                    to everything within it.
                                </Typography>
                                <br />
                            </>
                        )}
                        <Typography variant="subtitle1">
                            {t(
                                `Each new space ${buildingCountCopy} inherits the cleaning rules that were applied to its
                    building, floor or space type - whichever was most recent. A floor
                    will inherit the cleaning rule that was last applied to its building.`,
                            )}
                        </Typography>
                        <br />
                        <Typography variant="subtitle1">
                            {buildingCountChange > 0 && (
                                <>
                                    {t(`Go to `)}
                                    <Link
                                        to={routesManager.resolvePath(
                                            'solution-settings:smart-cleaning-rules',
                                        )}
                                    >
                                        {t('Manage cleaning rules')}
                                    </Link>
                                    {t(
                                        ' to edit the rules to suit your cleaning requirements.',
                                    )}
                                </>
                            )}
                            {buildingCountChange === 0 && !moreThanOneFloorSpace && (
                                <>
                                    {t(
                                        `View and edit the rules of your new location in `,
                                    )}
                                    <Link
                                        to={routesManager.resolvePath(
                                            'solution-settings:smart-cleaning-rules',
                                        )}
                                    >
                                        {t('Manage cleaning rules')}
                                    </Link>
                                </>
                            )}
                            {buildingCountChange === 0 && moreThanOneFloorSpace && (
                                <>
                                    {t(`View and edit the rules of each location in `)}
                                    <Link
                                        to={routesManager.resolvePath(
                                            'solution-settings:smart-cleaning-rules',
                                        )}
                                    >
                                        {t('Manage cleaning rules')}
                                    </Link>
                                </>
                            )}
                        </Typography>
                    </div>
                </div>
                <footer className={styles.successModalFooter}>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        onClick={handleSuccessOnClick}
                    >
                        {t('Ok')}
                    </Button>
                </footer>
            </Modal.Content>
        </div>
    ) : null;
};
