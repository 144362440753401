import { i18n } from '@infogrid/utils-i18n';
import * as Yup from 'yup';

import type { CleaningValues } from './types';

export const INITIAL_VALUES: CleaningValues = {
    might_need_cleaning_value: 6,
    must_be_cleaned_value: 10,
};

export const VALIDATION_SCHEMA = Yup.object().shape({
    might_need_cleaning_value: Yup.mixed().test(
        'custom number validation for "might need cleaning" value',
        i18n.t(
            'You must provide a value for the number of uses before a space must be cleaned',
            { ns: 'solutions' },
        ),
        function (value) {
            return (
                typeof value === 'number' ||
                value === i18n.t('N/A', { ns: 'solutions' }) ||
                value === undefined
            );
        },
    ),
    must_be_cleaned_value: Yup.mixed()
        .required(
            i18n.t(
                'You must provide a value for the number of uses before a space must be cleaned',
                { ns: 'solutions' },
            ),
        )
        .test(
            'custom number validation for "must need cleaning" value',
            i18n.t(
                'You must provide a value for the number of uses before a space must be cleaned',
                { ns: 'solutions' },
            ),
            function (value) {
                return typeof value === 'number';
            },
        )
        .test(
            'check the "must need" value is higher than the "might need" value',
            i18n.t(
                'The number of uses after which a space ‘must be cleaned’ should be higher than ‘might need cleaning’.',
                { ns: 'solutions' },
            ),
            function (value) {
                if (
                    typeof value !== 'number' ||
                    typeof this.parent.might_need_cleaning_value !== 'number' ||
                    isNaN(this.parent.might_need_cleaning_value)
                ) {
                    return true;
                }

                return value > this.parent.might_need_cleaning_value;
            },
        ),
});
