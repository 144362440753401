import { ICONS } from '@infogrid/components-icons';
import { Icon } from '@material-ui/core';
import classNames from 'classnames';
import { memo } from 'react';

import { useBatteryLevelStyles } from './styles';

type BatteryIconClass =
    | typeof ICONS.SENSORS.BATTERY_EMPTY
    | typeof ICONS.SENSORS.BATTERY_QUARTER
    | typeof ICONS.SENSORS.BATTERY_HALF
    | typeof ICONS.SENSORS.BATTERY_THREE_QUARTERS
    | typeof ICONS.SENSORS.BATTERY_FULL;

const getBatteryClass = (level: number | null | undefined): BatteryIconClass => {
    if (!level || level < 0) {
        return ICONS.SENSORS.BATTERY_EMPTY;
    }

    if (level <= 25) {
        return ICONS.SENSORS.BATTERY_QUARTER;
    }

    if (level <= 50) {
        return ICONS.SENSORS.BATTERY_HALF;
    }

    if (level < 75) {
        return ICONS.SENSORS.BATTERY_THREE_QUARTERS;
    }

    return ICONS.SENSORS.BATTERY_FULL;
};

export interface BatteryLevelIconProps {
    level?: number | null;
    size?: number;
    className?: string;
}

const BatteryLevelIcon = ({
    level = 0,
    size = 20,
    className = '',
}: BatteryLevelIconProps) => {
    const styles = useBatteryLevelStyles();

    const iconClass = classNames('fal', getBatteryClass(level));

    const levelClass = classNames({
        [styles.badLevel]: !level || level < 0 || (level > 0 && level <= 25),
        [styles.middleLevel]: !!level && level > 25 && level <= 50,
        [styles.goodLevel]: !!level && level > 50,
    });

    return (
        <Icon
            className={classNames(styles.root, iconClass, levelClass, className)}
            style={{ fontSize: size }}
            data-cypress="battery-level"
            data-testid="battery-level-icon"
        />
    );
};

export default memo(BatteryLevelIcon);
