export const SPACE_TYPES = {
    BUILDING: 'building',
    FLOOR: 'floor',
    SPACE: 'space',
} as const;

export type SpaceTypeId = typeof SPACE_TYPES[keyof typeof SPACE_TYPES];

export interface SmartCleaningDescendantCount {
    floors: number;
    buildings: number;
}

export interface FlattenedNode {
    id: number;
    type: SpaceTypeId | null;
    name: string;
    parent: number | null;
    children: number[];
    hasChildren: boolean;
    descendants: SmartCleaningDescendantCount;
}

export interface FlattenedNodes<FlattenedNodeType extends FlattenedNode> {
    [key: number | string]: FlattenedNodeType;
}

export interface NodeSelectionStruct {
    [key: number | string]: SelectionStateId;
}

export const SELECTION_STATES = {
    NONE: 'none',
    SOME: 'some',
    ALL: 'all',
} as const;

export type SelectionStateId = typeof SELECTION_STATES[keyof typeof SELECTION_STATES];

export interface SmartCleaningActivatedCount extends SmartCleaningDescendantCount {
    spaces?: number;
}

export interface NodeIdAndSelectedState {
    nodeIds: number[];
    selected: boolean;
}

export interface ActivatedDeactivedNodes {
    activated: Set<number>;
    deactivated: Set<number>;
}

export interface AddNewSelectedDeselectedNode extends NodeIdAndSelectedState {
    activatedDeactivedNodes: ActivatedDeactivedNodes;
}

export interface ChangedLocationsCount {
    spaceCountChange: number;
    floorCountChange: number;
    buildingCountChange: number;
}
