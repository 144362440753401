import { makeStyles } from '@material-ui/core';

export const useArrowStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    arrow: {
        width: 13,
        stroke: theme.palette.common.gray4,
        color: theme.palette.common.gray4,
    },
    line: {
        marginLeft: -8,
        marginTop: -2,
        height: 4,
        stroke: theme.palette.common.gray4,
    },
}));
