import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

import { RATING_COLOURS } from '../../constants';
import type { AirQualityValue } from '../../types';

interface Props {
    value?: AirQualityValue;
}

export const useAirQualityStyles = makeStyles((theme) => ({
    value: ({ value }: Props) => ({
        color: value && RATING_COLOURS[value],
        fontSize: 120,
        fontWeight: theme.typography.fontWeightLight as FontWeightProperty,
        lineHeight: '151px',
    }),
}));
