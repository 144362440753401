import { Divider, List } from '@material-ui/core';
import classNames from 'classnames';
import type { ReactNode } from 'react';

import ChecklistItem from '../ChecklistItem/ChecklistItem';
import { useChecklistMenuStyles } from './styles';

export type ChecklistMenuProps<T> = {
    classes?: {
        actionsList?: string;
        itemsList?: string;
    };
    checkedItemsKeys: (string | number)[];
    disabled?: boolean;
    selectionDisabled?: boolean;
    items: T[];
    keyExtractor: (x: T) => string | number;
    valueExtractor: (x: T) => string;
    onOptionCheck: (id: string | number) => void;
    onClose?: () => void;
    actions?: ReactNode;
    renderInPortal?: boolean;
};

const defaultClasses = { actionsList: '', itemsList: '' };

function ChecklistMenu<T>({
    actions,
    classes = defaultClasses,
    checkedItemsKeys,
    items,
    selectionDisabled,
    onOptionCheck,
    keyExtractor,
    valueExtractor,
}: ChecklistMenuProps<T>) {
    const styles = useChecklistMenuStyles();

    return (
        <div>
            {actions && (
                <>
                    <List
                        className={classNames(
                            styles.checkListMenuList,
                            classes.actionsList,
                        )}
                    >
                        {actions}
                    </List>
                    {items.length > 0 && <Divider />}
                </>
            )}

            {items.length > 0 && (
                <List className={classNames(styles.checkListMenuList, classes.itemsList)}>
                    {items.map((item) => {
                        const itemKey = keyExtractor(item);

                        return (
                            <ChecklistItem
                                key={itemKey}
                                disabled={selectionDisabled}
                                checked={checkedItemsKeys.includes(itemKey)}
                                id={itemKey}
                                onOptionCheck={onOptionCheck}
                                text={valueExtractor(item)}
                            />
                        );
                    })}
                </List>
            )}
        </div>
    );
}

export default ChecklistMenu;
