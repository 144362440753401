import { memo, useState, useEffect, useCallback } from 'react';

import CreateBuildingModal from './CreateBuildingModal';
import {
    useBuildingDetails,
    useModals,
    useCreatingFolder,
    useBuildingSubFolders,
    useCreateBuildingFloor,
} from './hooks';

const CreateBuildingModalContainer = () => {
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [expandedFolderPath, setExpandedFolderPath] = useState([]);

    const handleSelectFolder = useCallback(
        (id, { contentLength, nodeName }) => {
            setSelectedFolder({ id: +id, name: nodeName, contentLength });
        },
        [setSelectedFolder],
    );

    const {
        goToChoosingFolderBuilding,
        goToBuildingDetails,
        goToChoosingFolderFloor,
        goToCreatingPreview,
        goToFinishCreating,
        goToCreatingFolderBuilding,
        goToCreatingFolderFloor,

        renderChoosingFolderBuilding,
        renderCreatingPreview,
        renderBuildingDetails,
        renderChoosingFolderFloor,
        renderFinishingCreating,
        renderCreatingFolderBuilding,
        renderCreatingFolderFloor,

        isOpened,
        onClose,
        onOpenCreatingFloor,
    } = useModals();

    const { handleCreateBuilding } = useBuildingDetails({
        goToChoosingFolderFloor,
        goToFinishCreating,
        selectedFolder,
    });

    const { handleCreatingFolder, openCreateFolderBuilding, openCreateFolderFloor } =
        useCreatingFolder({
            renderCreatingFolderBuilding,
            goToCreatingFolderBuilding,
            goToCreatingFolderFloor,
            goToChoosingFolderFloor,
            goToBuildingDetails,
            selectedFolder,
            setSelectedFolder,
        });

    const { handleSubfoldersConverting } = useBuildingSubFolders({
        goToFinishCreating,
    });

    const { handleCreatingFloor } = useCreateBuildingFloor({
        selectedFolder,
        goToChoosingFolderFloor,
    });

    const handleReset = useCallback(() => {
        setSelectedFolder(null);
        setExpandedFolderPath([]);
    }, [setSelectedFolder]);

    const onExitedModal = useCallback(() => {
        handleReset();
        goToCreatingPreview();
    }, [handleReset, goToCreatingPreview]);

    useEffect(() => {
        goToCreatingPreview();
    }, [goToCreatingPreview]);

    return (
        <CreateBuildingModal
            isOpened={isOpened}
            onClose={onClose}
            onExitedModal={onExitedModal}
            handleReset={handleReset}
            onOpenCreatingFloor={onOpenCreatingFloor}
            handleCreateBuilding={handleCreateBuilding}
            handleSubfoldersConverting={handleSubfoldersConverting}
            //
            goToBuildingDetails={goToBuildingDetails}
            renderBuildingDetails={renderBuildingDetails}
            //
            renderCreatingPreview={renderCreatingPreview}
            goToCreatingPreview={goToCreatingPreview}
            //
            renderChoosingFolderBuilding={renderChoosingFolderBuilding}
            goToChoosingFolderBuilding={goToChoosingFolderBuilding}
            //
            renderChoosingFolderFloor={renderChoosingFolderFloor}
            goToChoosingFolderFloor={goToChoosingFolderFloor}
            //
            handleSelectFolder={handleSelectFolder}
            selectedFolder={selectedFolder}
            //
            renderFinishingCreating={renderFinishingCreating}
            goToFinishCreating={goToFinishCreating}
            //
            renderCreatingFolderBuilding={renderCreatingFolderBuilding}
            openCreateFolderBuilding={openCreateFolderBuilding}
            //
            renderCreatingFolderFloor={renderCreatingFolderFloor}
            goToCreatingFolderFloor={goToCreatingFolderFloor}
            //
            openCreateFolderFloor={openCreateFolderFloor}
            handleCreatingFolder={handleCreatingFolder}
            //
            handleCreatingFloor={handleCreatingFloor}
            expandedFolderPath={expandedFolderPath}
            setExpandedFolderPath={setExpandedFolderPath}
        />
    );
};

export default memo(CreateBuildingModalContainer);
