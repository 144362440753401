import type { BuildingDetailsWorkingHours, Weekday } from '@infogrid/locations-types';
import { registerTranslationKey } from '@infogrid/utils-i18n';
import * as Yup from 'yup';

export const WEEKDAY_KEYS: Weekday[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

export const INITIAL_LOCATION = {
    address: '',
    city: '',
    latitude: 0,
    longitude: 0,
    country_name: '',
    country_code: '',
};

export const INITIAL_WORKING_HOURS: BuildingDetailsWorkingHours = {
    monday: { enabled: true, from_hour: '09:00', to_hour: '18:00' },
    tuesday: { enabled: true, from_hour: '09:00', to_hour: '18:00' },
    wednesday: { enabled: true, from_hour: '09:00', to_hour: '18:00' },
    thursday: { enabled: true, from_hour: '09:00', to_hour: '18:00' },
    friday: { enabled: true, from_hour: '09:00', to_hour: '18:00' },
    saturday: { enabled: false, from_hour: '09:00', to_hour: '18:00' },
    sunday: { enabled: false, from_hour: '09:00', to_hour: '18:00' },
};

const BLANK_FIELD_ERROR = registerTranslationKey('This field may not be blank.');
const CHAR_LIMIT_ERROR = registerTranslationKey('Must be 100 characters or less.');

const isMidnight = (value: string | undefined) =>
    value === '00:00' || value === '00:00:00';

const workingDayValidator = Yup.object()
    .shape({
        enabled: Yup.boolean(),
        from_hour: Yup.string()
            .required()
            .matches(/^\d{2}:(00|15|30|45)(:00)?$/, {
                excludeEmptyString: true,
                message: registerTranslationKey(
                    'Working hours must be to the nearest 15 minutes',
                ),
            }),
        to_hour: Yup.string()
            .required()
            .matches(/^\d{2}:(00|15|30|45)(:00)?$/, {
                excludeEmptyString: true,
                message: registerTranslationKey(
                    'Working hours must be to the nearest 15 minutes',
                ),
            }),
    })
    .test(
        'overlapping-time',
        registerTranslationKey('End time must be after start time'),
        (value) =>
            !value || isMidnight(value?.to_hour) || value?.from_hour < value?.to_hour,
    );

export const buildingDetailsValidationSchema = Yup.object().shape({
    name: Yup.string().required(BLANK_FIELD_ERROR).max(100, CHAR_LIMIT_ERROR).trim(),
    building_type: Yup.number().required(BLANK_FIELD_ERROR),
    location: Yup.object().shape({
        address: Yup.string().required(BLANK_FIELD_ERROR),
        city: Yup.string().required(BLANK_FIELD_ERROR),
        country_name: Yup.string().required(BLANK_FIELD_ERROR),
    }),
    working_hours: Yup.object().shape({
        monday: workingDayValidator,
        tuesday: workingDayValidator,
        wednesday: workingDayValidator,
        thursday: workingDayValidator,
        friday: workingDayValidator,
        saturday: workingDayValidator,
        sunday: workingDayValidator,
    }),
});
