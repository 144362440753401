import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useStoplightSectionStyles = makeStyles((theme) => ({
    title: {
        fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
        marginBottom: theme.spacing(2),
    },
    wrapper: {
        alignItems: 'center',
        display: 'flex',
    },
    imageText: {
        marginBottom: theme.spacing(3),
    },
    iconsPickerWrapper: {
        margin: theme.spacing(0, 3, 2.5, 3),
    },
}));
