import { Classes, TreeNode } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { BottomLoader } from '@infogrid/components-material-ui';
import { useEffectOnMount, useSelectorWithArgs } from '@infogrid/utils-hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FolderTreeFolderEmptyState } from 'components/Folders/FolderTree/FolderTreeFolderEmptyState';
import FolderTreeFolderSensors from 'components/Folders/FolderTree/FolderTreeFolderSensors';
import { selectFolder } from 'schemas/folder';
import {
    isRootFolder,
    selectFolderCascadingSensorsCount,
    selectFolderIsEmpty,
    selectFolderIsLoading,
    usePrefetchFolderSensorsCount,
} from 'utils/folderTree';
import { useSensorTypeFilter } from 'utils/sensor';

// If this ends up causing problems, you can try to:
//  add `subfoldersComponent` prop and pass that from outer components
// eslint-disable-next-line import/no-cycle
import FolderTreeFolderSubfolders from './FolderTreeFolderSubfolders';

const returnTrue = () => true;

const FolderTreeFolder = ({
    folderId,
    basePath,
    depth,
    nodeClassName,
    sensorTypeFilter,
    labelsFilter,
    readingTypesFilter,
    selectIsExpanded,
    selectIsSelected,
    selectSecondaryLabel,
    scrollParentRef,
    onNodeClick,
    onNodeCollapse,
    onNodeExpand,
    getShouldNodeBeHighlighted,
}) => {
    const nodeId = `folder-${folderId}`;
    const path = basePath ? `${basePath}/${nodeId}` : `${nodeId}`;
    const folder = useSelectorWithArgs(selectFolder, folderId);
    const { sensorQuery } = useSensorTypeFilter(sensorTypeFilter);
    const sensorsCount = useSelectorWithArgs(
        selectFolderCascadingSensorsCount,
        folderId,
        sensorQuery,
    );

    const { t } = useTranslation();

    const folderCountLabel = sensorsCount ? ` (${sensorsCount})` : '';
    // We don't need to wait for the 'Shared with me' folder to load its name
    const label =
        folderId !== 0 ? `${folder.name}${folderCountLabel}` : t('Shared with me');
    // We assume 'Shared with me' folder is always expanded
    const isExpanded =
        folderId === 0 || (selectIsExpanded ? selectIsExpanded(nodeId) : false);
    const isSelected = selectIsSelected ? selectIsSelected(nodeId, folder) : false;
    const isHighlighted =
        isSelected || (!isExpanded && getShouldNodeBeHighlighted(folder));
    const hasCaret = folderId !== 0;
    const isLoading = useSelectorWithArgs(selectFolderIsLoading, folderId, sensorQuery);
    const isEmpty = useSelectorWithArgs(selectFolderIsEmpty, folderId, sensorQuery);
    // Generally children should have a depth of (depth + 1)
    // But for 'Shared with me', we set children depth to also be 0
    const childrenDepth = folderId !== 0 ? depth + 1 : 0;

    const handleClick = useCallback(() => {
        if (onNodeClick) {
            onNodeClick(nodeId, isSelected, folder);
        }
    }, [onNodeClick, nodeId, isSelected, folder]);
    const handleCollapse = useCallback(() => {
        if (onNodeCollapse) {
            onNodeCollapse(nodeId);
        }
    }, [onNodeCollapse, nodeId]);
    const handleExpand = useCallback(() => {
        if (onNodeExpand) {
            onNodeExpand(nodeId);
        }
    }, [onNodeExpand, nodeId]);

    // Automatically open root folder
    useEffectOnMount(() => {
        if (!isExpanded && isRootFolder(folder)) {
            handleExpand();
        }
    });

    usePrefetchFolderSensorsCount({ folderId, sensorQuery });

    const secondaryLabel = selectSecondaryLabel
        ? selectSecondaryLabel(nodeId, folder)
        : undefined;

    // Note: You can extract this `TreeNode` component to be a prop/make it customizable instead
    return (
        <TreeNode
            id={nodeId}
            key={nodeId}
            depth={depth}
            path={path}
            icon={IconNames.FOLDER_CLOSE}
            label={label}
            secondaryLabel={secondaryLabel}
            className={classNames(nodeClassName, {
                '--hide-invisible-caret': folderId === 0,
            })}
            hasCaret={hasCaret}
            isExpanded={isExpanded}
            isSelected={isHighlighted}
            onClick={handleClick}
            onCollapse={handleCollapse}
            onExpand={handleExpand}
        >
            <ul className={classNames(Classes.TREE_NODE_LIST)} data-cypress="folder-tree">
                <FolderTreeFolderSubfolders
                    folderId={folderId}
                    basePath={path}
                    depth={childrenDepth}
                    // Pass-through
                    nodeClassName={nodeClassName}
                    sensorTypeFilter={sensorTypeFilter}
                    labelsFilter={labelsFilter}
                    readingTypesFilter={readingTypesFilter}
                    selectIsExpanded={selectIsExpanded}
                    selectIsSelected={isSelected ? returnTrue : selectIsSelected}
                    selectSecondaryLabel={selectSecondaryLabel}
                    scrollParentRef={scrollParentRef}
                    onNodeClick={onNodeClick}
                    onNodeCollapse={onNodeCollapse}
                    onNodeExpand={onNodeExpand}
                    getShouldNodeBeHighlighted={getShouldNodeBeHighlighted}
                />
                <FolderTreeFolderSensors
                    folderId={folderId}
                    depth={childrenDepth}
                    basePath={path}
                    // Pass-through
                    sensorTypeFilter={sensorTypeFilter}
                    labelsFilter={labelsFilter}
                    readingTypesFilter={readingTypesFilter}
                    selectIsSelected={isSelected ? returnTrue : selectIsSelected}
                    selectSecondaryLabel={selectSecondaryLabel}
                    scrollParentRef={scrollParentRef}
                    onNodeClick={onNodeClick}
                />
                {isEmpty ? <FolderTreeFolderEmptyState depth={childrenDepth} /> : null}
                <BottomLoader isLoading={isLoading} />
            </ul>
        </TreeNode>
    );
};

FolderTreeFolder.propTypes = {
    folderId: PropTypes.number.isRequired,
    basePath: PropTypes.string.isRequired,
    depth: PropTypes.number.isRequired,
    nodeClassName: PropTypes.string,
    sensorTypeFilter: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    labelsFilter: PropTypes.arrayOf(PropTypes.number),
    readingTypesFilter: PropTypes.arrayOf(PropTypes.string),
    selectIsExpanded: PropTypes.func,
    selectIsSelected: PropTypes.func,
    selectSecondaryLabel: PropTypes.func,
    scrollParentRef: PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
    onNodeClick: PropTypes.func,
    onNodeCollapse: PropTypes.func,
    onNodeExpand: PropTypes.func,
    getShouldNodeBeHighlighted: PropTypes.func,
};
FolderTreeFolder.defaultProps = {
    nodeClassName: undefined,
    sensorTypeFilter: undefined,
    labelsFilter: undefined,
    readingTypesFilter: undefined,
    selectIsExpanded: undefined,
    selectIsSelected: undefined,
    selectSecondaryLabel: undefined,
    scrollParentRef: undefined,
    onNodeClick: undefined,
    onNodeCollapse: undefined,
    onNodeExpand: undefined,
    getShouldNodeBeHighlighted: () => false,
};

const FolderTreeFolderMemo = memo(FolderTreeFolder);

FolderTreeFolderMemo.propTypes = FolderTreeFolder.propTypes;
FolderTreeFolderMemo.defaultProps = FolderTreeFolder.defaultProps;

export default FolderTreeFolderMemo;
