import { createRequest } from '@infogrid/core-api';
import type { OrganizationIdType, IPagination } from '@infogrid/core-types';
import type { BuildingAtRiskWithFloors } from '@infogrid/remote-monitoring-types';
import type { AxiosPromise } from 'axios';

interface AtRiskBuildings {
    buildings: BuildingAtRiskWithFloors[];
}

export interface AtRiskBuildingsPaginated
    extends Omit<IPagination<AtRiskBuildings>, 'results'> {
    results: AtRiskBuildings;
}

const getAtRiskBuilding = (
    organizationId: OrganizationIdType,
    signal: AbortSignal | undefined,
    params: { [key: string]: unknown },
): AxiosPromise<AtRiskBuildingsPaginated> =>
    createRequest({
        method: 'get',
        url: `org/${organizationId}/pipe_monitoring_building_risk`,
        signal,
        options: { params },
    });

export const controllers = {
    getAtRiskBuilding,
};
