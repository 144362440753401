import { makeStyles } from '@material-ui/core';

export const useContentStyles = makeStyles((theme) => ({
    content: {
        height: '100%',
    },
    ringContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    tutorialBtn: {
        textTransform: 'none',
        marginTop: theme.spacing(1),
        fontSize: '12px',
        lineHeight: '16px',
        textDecoration: 'underline',
        color: theme.palette.text.secondary,
        fontWeight: 'normal',
    },
    scoreTrend: {
        marginBottom: theme.spacing(3),
    },
    compareBuildingsBtn: {
        marginTop: theme.spacing(0.75),
    },
}));

export const useProgressRingStyles = makeStyles((theme) => ({
    root: {
        fontSize: 42,

        [theme.breakpoints.down('xs')]: {
            fontSize: 25,
        },
    },
}));
