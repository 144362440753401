import { Box, Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';
import type { ChangeEvent, MutableRefObject } from 'react';
import { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSensorAssetUpload } from 'apiHooks/settings/integrations/corrigo/hooks/useSensorAssetUpload';
import { useSensorTemplateDownload } from 'apiHooks/settings/integrations/corrigo/hooks/useSensorTemplateDownload';

import { useCorrigoStyling } from './styles';

const AssetMapping = () => {
    const styles = useCorrigoStyling();
    const { mutate: onClickDownload } = useSensorTemplateDownload();
    const { mutate: upload } = useSensorAssetUpload();
    const { t } = useTranslation('integrations');
    const fileUploadRef: MutableRefObject<HTMLInputElement | null> = useRef(null);

    const onUpload = (e: ChangeEvent<HTMLInputElement>) => {
        if (!!e?.target?.files) {
            upload(e.target.files[0]);
        }
    };

    const onClickUpload = () => {
        if (!!fileUploadRef.current) {
            fileUploadRef.current.click();
        }
    };

    return (
        <Paper className={styles.wrapper}>
            <Alert severity="info">
                {t(
                    'Follow the step by step process below to complete mapping between Corrigo and Infogrid. If you have any problems please reach out to your Corrigo CPA contact. A successful upload will overwrite all previous sensor asset mappings.',
                )}
            </Alert>
            <Typography variant="h5" data-cypress="template-name">
                {t('Map assets between Corrigo and Infogrid')}
            </Typography>
            <div className={styles.steps}>
                <Box className={styles.step}>
                    <Typography variant="h6" data-cypress="template-name">
                        {t('1. Download a spreadsheet containing sensor assets')}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={onClickDownload}>
                        {t('DOWNLOAD')}
                    </Button>
                </Box>
                <Box className={styles.step}>
                    <Typography variant="h6" data-cypress="template-name">
                        {t(
                            '2. Fill out spreadsheet and map with Corrigo assets to Infogrid sensors',
                        )}
                    </Typography>
                </Box>
                <Box className={styles.step}>
                    <input
                        ref={fileUploadRef}
                        accept=".xlsx"
                        type="file"
                        className={styles.uploadInput}
                        onChange={onUpload}
                    />
                    <Typography variant="h6" data-cypress="template-name">
                        {t('3. Upload completed spreadsheet to Infogrid')}
                    </Typography>
                    <Button variant="contained" color="primary" onClick={onClickUpload}>
                        {t('UPLOAD')}
                    </Button>
                </Box>
            </div>
        </Paper>
    );
};

export default memo(AssetMapping);
