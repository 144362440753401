import { CircularProgress, Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import withView from 'decorators/withView';
import type {
    SmartCleaningHierarchyResponse,
    SmartCleaningHierarchyNode,
} from 'views/solutionSettings/apiHooks/types';
import type { PaginatedTreeCurrentNode } from 'views/solutionSettings/components/PaginatedTreeSelector';
import PaginatedTreeSelector from 'views/solutionSettings/components/PaginatedTreeSelector';
import type { LocationsCount } from 'views/solutionSettings/pages/smartCleaning/utils/selectionUtils';
import {
    getSelected,
    getOptionLabelFromType,
    updateNodeSelectionStateOnPage,
    updateNodeSelectionStateOnSelect,
} from 'views/solutionSettings/pages/smartCleaning/utils/selectionUtils';
import type { FlattenedNodes, NodeSelectionStruct } from 'views/solutionSettings/types';
import { SELECTION_STATES } from 'views/solutionSettings/types';

import type {
    ManageRulesFlattenedNode,
    ManageRulesChildNode,
    ManageRulesDescendantCount,
} from '../../types';
import { useCleaningRulesStyles } from './styles';

const nodeHasDescendants = (node: SmartCleaningHierarchyNode) => {
    return (
        node.descendants?.length > 0 &&
        node.descendants?.filter((type) => type.count > 0).length > 0
    );
};

interface UpdateLocationsCountParams {
    selectedNodes: NodeSelectionStruct;
    knownNodes: FlattenedNodes<ManageRulesFlattenedNode>;
}

/*
 * Calculates how many buildings and floors are selected from a node down
 * TODO: this method doesnt handle spaces, but this view should count them. This will be updated in a separate MR
 *       which will take the new BE counting solution into consideration as well
 */
const updateLocationsCount = ({
    selectedNodes,
    knownNodes,
}: UpdateLocationsCountParams): ManageRulesDescendantCount =>
    Object.keys(selectedNodes).reduce(
        (newCount: ManageRulesDescendantCount, nodeId: string) => {
            const thisNode = knownNodes[nodeId];
            // if the parent is not selected and this node is, add the descendants to the count
            if (
                // if no parent and selected
                (thisNode.parent === null &&
                    selectedNodes[thisNode.id] === SELECTION_STATES.ALL) ||
                // or the parent is not selected and this node is
                (typeof thisNode.parent === 'number' &&
                    selectedNodes[thisNode.parent] !== SELECTION_STATES.ALL &&
                    selectedNodes[thisNode.id] === SELECTION_STATES.ALL)
            ) {
                return {
                    floors:
                        newCount.floors +
                        thisNode.descendants.floors +
                        (thisNode.type === 'floor' ? 1 : 0),
                    buildings:
                        newCount.buildings +
                        thisNode.descendants.buildings +
                        (thisNode.type === 'building' ? 1 : 0),
                    spaces:
                        newCount.spaces +
                        thisNode.descendants.spaces +
                        (thisNode.type === 'space' ? 1 : 0),
                };
            }

            // if it's set to `some`, we don't know what's been added from it's children, but it still counts.
            // e.g. if a floor has five spaces and one space is selected, we want to read `1 space in 1 floor`.
            if (selectedNodes[thisNode.id] === SELECTION_STATES.SOME) {
                if (!thisNode.type) {
                    return newCount;
                }

                const out = { ...newCount };
                switch (thisNode.type) {
                    case 'floor':
                        out.floors += 1;
                        break;
                    case 'building':
                        out.buildings += 1;
                        break;
                    case 'space':
                        out.spaces += 1;
                        break;
                    default:
                        // do nothing
                        break;
                }

                return out;
            }

            // if the parent is selected, skip this node otherwise we get duplicate counts
            return newCount;
        },
        { floors: 0, buildings: 0, spaces: 0 },
    );

interface FlattenResponseLocationsProps {
    response: SmartCleaningHierarchyResponse;
    currentFlattenedLocations: FlattenedNodes<ManageRulesFlattenedNode>;
}

// TODO: move this to utils and test, maybe make it more generic so it can be shared
const flattenResponseLocations = ({
    response,
    currentFlattenedLocations,
}: FlattenResponseLocationsProps): FlattenedNodes<ManageRulesFlattenedNode> => {
    const existingCurrentNode: ManageRulesFlattenedNode | undefined =
        currentFlattenedLocations[response.current.id];
    const currentNode = {
        id: response.current.id,
        name: response.current.name,
        type: response.current.floorplan_type || null,
        parent: response.parent?.id || null,
        children: response.folders.map((folder) => folder.id),
        hasChildren: response.folders.length > 0,
        descendants: {
            floors:
                response.current.descendants?.find(
                    (dsc) => dsc.floorplan_type === 'floor',
                )?.count || 0,
            buildings:
                response.current.descendants?.find(
                    (dsc) => dsc.floorplan_type === 'building',
                )?.count || 0,
            spaces:
                response.current.descendants?.find(
                    (dsc) => dsc.floorplan_type === 'space',
                )?.count || 0,
        },
        mightNeedCleaning: existingCurrentNode
            ? existingCurrentNode.mightNeedCleaning
            : undefined,
        doesNeedCleaning: existingCurrentNode
            ? existingCurrentNode.doesNeedCleaning
            : undefined,
    };

    return {
        ...currentFlattenedLocations,
        current: currentNode, // store this for easy access
        [response.current.id]: currentNode,
        ...response.folders.reduce(
            (a, folder) => ({
                ...a,
                [folder.id]: {
                    id: folder.id,
                    name: folder.name,
                    type: folder.floorplan_type || null,
                    parent: response.current.id,
                    children: currentFlattenedLocations[folder.id]?.children || [],
                    mightNeedCleaning: folder.might_need_cleaning_threshold,
                    doesNeedCleaning: folder.does_need_cleaning_threshold,
                    hasChildren: nodeHasDescendants(folder),
                    descendants: {
                        floors:
                            folder.descendants.find(
                                (dsc) => dsc.floorplan_type === 'floor',
                            )?.count || 0,
                        buildings:
                            folder.descendants.find(
                                (dsc) => dsc.floorplan_type === 'building',
                            )?.count || 0,
                        spaces:
                            folder.descendants?.find(
                                (dsc) => dsc.floorplan_type === 'space',
                            )?.count || 0,
                    },
                },
            }),
            {},
        ),
    };
};

interface GetCurrentNodeProps {
    flattenedLocations: FlattenedNodes<ManageRulesFlattenedNode>;
    selections: NodeSelectionStruct;
}

// TODO: move this to utils and test, maybe make it more generic so it can be shared
const getCurrentNodeTree = ({
    flattenedLocations,
    selections,
}: GetCurrentNodeProps): PaginatedTreeCurrentNode<ManageRulesChildNode> => {
    const currentLocation = flattenedLocations.current;
    const children = currentLocation.children.map((id: number) => ({
        id: flattenedLocations[id].id,
        name: flattenedLocations[id].name,
        type: flattenedLocations[id].type,
        hasChildren: flattenedLocations[id].hasChildren,
        selected: getSelected(selections[id]),
        mightNeedCleaning: flattenedLocations[id].mightNeedCleaning,
        doesNeedCleaning: flattenedLocations[id].doesNeedCleaning,
    }));
    const parent = currentLocation.parent && {
        id: flattenedLocations[currentLocation.parent].id,
        name: flattenedLocations[currentLocation.parent].name,
        type: flattenedLocations[currentLocation.parent].type,
        selected: getSelected(selections[currentLocation.parent]),
    };

    return {
        id: currentLocation.id,
        name: currentLocation.name,
        type: currentLocation.type,
        selected: getSelected(selections[currentLocation.id]),
        ...(parent && { parent }),
        children,
    };
};

interface Props {
    locations: SmartCleaningHierarchyResponse;
    locationsIsLoading: boolean;
    currentPageId: number;
    changePage: (id: number) => void;
    setLocationCount: (count: LocationsCount) => void;
    setSelectedSpaceIds: (ids: number[]) => void;
    hideCustomColumns?: boolean;
    setKnownNodesCallback?: (nodes: FlattenedNodes<ManageRulesFlattenedNode>) => void;
}

const CleaningRulesSelector = ({
    locations,
    locationsIsLoading,
    changePage,
    currentPageId,
    setLocationCount,
    setSelectedSpaceIds,
    hideCustomColumns,
    setKnownNodesCallback,
}: Props) => {
    const { t } = useTranslation('solutions');
    const styles = useCleaningRulesStyles();
    const theme = useTheme();

    const [flattenedLocations, setFlattenedLocations] =
        useState<FlattenedNodes<ManageRulesFlattenedNode>>();
    const [currentNode, setCurrentNode] =
        useState<PaginatedTreeCurrentNode<ManageRulesChildNode> | null>();
    const [selectedNodes, setSelectedNodes] = useState<NodeSelectionStruct>({});

    useEffect(() => {
        if (locations) {
            setFlattenedLocations((oldFlattenedLocations) => {
                const newFlattedNodes = flattenResponseLocations({
                    response: locations,
                    currentFlattenedLocations: oldFlattenedLocations || {},
                });

                if (setKnownNodesCallback) {
                    setKnownNodesCallback(newFlattedNodes);
                }

                return newFlattedNodes;
            });
        }
    }, [locations, setKnownNodesCallback]);

    useEffect(() => {
        setCurrentNode(null);
    }, [currentPageId]);

    useEffect(() => {
        if (currentNode && flattenedLocations) {
            setLocationCount(
                updateLocationsCount({
                    knownNodes: flattenedLocations,
                    selectedNodes,
                }),
            );
        }
    }, [selectedNodes, currentNode, flattenedLocations, setLocationCount]);

    useEffect(() => {
        if (flattenedLocations) {
            setSelectedNodes((oldSelectedNodes) =>
                updateNodeSelectionStateOnPage({
                    newKnownNodes: flattenedLocations,
                    currentlySelectedNodes: oldSelectedNodes,
                }),
            );
        }
    }, [flattenedLocations]);

    useEffect(() => {
        if (flattenedLocations) {
            setCurrentNode(
                getCurrentNodeTree({
                    flattenedLocations,
                    selections: selectedNodes,
                }),
            );
        }
    }, [selectedNodes, flattenedLocations]);

    useEffect(() => {
        setSelectedSpaceIds(
            Object.keys(selectedNodes)
                .filter((nodeId) => selectedNodes[nodeId] === SELECTION_STATES.ALL)
                .map((id) => Number(id)),
        );
    }, [setSelectedSpaceIds, selectedNodes]);

    const changeSelection = ({
        selected,
        nodeIds,
    }: {
        selected: boolean;
        nodeIds: number[];
    }) => {
        setSelectedNodes(
            updateNodeSelectionStateOnSelect({
                currentlySelectedNodes: selectedNodes,
                knownNodes: flattenedLocations || {},
                newSelectedState: selected ? SELECTION_STATES.ALL : SELECTION_STATES.NONE,
                selectedNodeIds: nodeIds,
            }),
        );
    };

    const cleanedAfterContent = (cleaningCount?: string) => {
        if (cleaningCount === undefined || cleaningCount === null) {
            return t('N/A');
        }

        if (cleaningCount === 'mixed') {
            return t('Mixed');
        }

        return t('{{cleaningCount}} uses', {
            cleaningCount,
        });
    };

    const customColumns = [
        {
            HeaderCell: () => (
                <>
                    <span
                        className={styles.ruleSymbol}
                        style={{ backgroundColor: theme.palette.common.yellow2 }}
                    />{' '}
                    {t('Might need clean after')}
                </>
            ),
            DataCell: (row: ManageRulesChildNode) => (
                <Typography variant="body2">
                    {cleanedAfterContent(row.mightNeedCleaning)}
                </Typography>
            ),
            key: 'mightNeedCleaningCell',
        },
        {
            HeaderCell: () => (
                <>
                    <span
                        className={styles.ruleSymbol}
                        style={{ backgroundColor: theme.palette.common.red2 }}
                    />
                    {t('Must be cleaned after')}
                </>
            ),
            DataCell: (row: ManageRulesChildNode) => (
                <Typography variant="body2">
                    {cleanedAfterContent(row.doesNeedCleaning)}
                </Typography>
            ),
            key: 'doesNeedCleaningell',
        },
    ];

    return (
        <>
            {locationsIsLoading && (
                <div className={styles.skeletonContainer}>
                    <Skeleton classes={{ root: styles.skeleton }} animation={false} />
                    <div className={styles.loadingSpinnerContainer}>
                        <CircularProgress
                            className={styles.loader}
                            color="primary"
                            size={30}
                        />
                    </div>
                </div>
            )}
            {!locationsIsLoading && currentNode && (
                <PaginatedTreeSelector<
                    ManageRulesChildNode,
                    PaginatedTreeCurrentNode<ManageRulesChildNode>
                >
                    content={currentNode}
                    getLabelFromType={() => getOptionLabelFromType(currentNode)}
                    onChangeSelection={changeSelection}
                    onChangePage={changePage}
                    customColumns={hideCustomColumns ? [] : customColumns}
                    dataCypress="manage-rules-location-picker"
                />
            )}
        </>
    );
};

export default withView()(CleaningRulesSelector);
