import { Modal } from '@infogrid/components-material-ui';
import { useAppSelector } from '@infogrid/core-ducks';
import type { WidgetMetadataType } from '@infogrid/dashboards-constants';
import { selectOrganizationFeatures } from '@infogrid/user-ducks';
import { USER_ACTIONS } from '@infogrid/utils-analytics';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { trackDashboardEvent } from 'views/dashboards/utils/analytics';

import { WidgetsMetadata } from '../../../utils/widgets';
import WidgetPreview from './WidgetPreview';
import { useAddWidgetModalStyles } from './styles';
import { isWidgetEnabled } from './utils';

export interface AddWidgetModalProps {
    loading: boolean;
    open: boolean;
    onClose: () => void;
    onConfirm: (widget: WidgetMetadataType) => void;
}

const AddWidgetModal = ({ loading, onClose, onConfirm, open }: AddWidgetModalProps) => {
    const { t } = useTranslation('dashboard');

    const styles = useAddWidgetModalStyles();

    const userFeatureFlags = useAppSelector(selectOrganizationFeatures);

    const [selectedWidget, setSelectedWidget] = useState<WidgetMetadataType | null>(null);

    const handleWidgetSelect = useCallback(
        (widget) => {
            trackDashboardEvent(
                USER_ACTIONS.PRESSED,
                `select widget in add widget modal`,
                {
                    type: widget.type,
                    label: widget.label,
                },
            );
            setSelectedWidget(widget);
        },
        [setSelectedWidget],
    );

    const handleConfirmClick = () => {
        if (selectedWidget) {
            onConfirm(selectedWidget);
        }
    };

    const availableWidgets = useMemo(
        () =>
            WidgetsMetadata.filter((widget) =>
                isWidgetEnabled(widget.featureFlags, userFeatureFlags, widget.deprecated),
            ),
        [userFeatureFlags],
    );

    const handleOnClose = () => {
        trackDashboardEvent(USER_ACTIONS.PRESSED, 'close add widget modal');

        return onClose();
    };

    return (
        <Modal onClose={onClose} open={open} fullWidth maxWidth="sm">
            <Modal.Title>{t('Add Widget')}</Modal.Title>
            <Modal.Description>
                {t('Choose the type of widget you would like to add to your dashboard.')}
            </Modal.Description>
            <Modal.Content className={styles.content}>
                <Grid container>
                    {availableWidgets.map((widget) => (
                        <Grid
                            key={widget.label}
                            item
                            sm={6}
                            xs={12}
                            className={styles.gridItem}
                        >
                            <WidgetPreview
                                active={selectedWidget?.type === widget.type}
                                onClick={handleWidgetSelect}
                                widget={widget}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Modal.Content>
            <Modal.Actions justify="space-between">
                <Button onClick={handleOnClose}>{t('Cancel', { ns: 'common' })}</Button>
                <Button
                    color="primary"
                    disabled={!selectedWidget || loading}
                    onClick={handleConfirmClick}
                    variant="contained"
                    data-cypress="next-btn"
                >
                    {loading && (
                        <CircularProgress
                            className={styles.spinner}
                            size={18}
                            color="inherit"
                        />
                    )}
                    {t('Next', { ns: 'common' })}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default memo(AddWidgetModal);
