import { makeStyles } from '@material-ui/core';
import type { FontWeightProperty } from 'csstype';

export const useBannerStyles = makeStyles((theme) => ({
    container: {
        alignItems: 'center',
        backgroundColor: theme.palette.common.gray5,
        display: 'flex',
        height: 48,
        maxWidth: 600,
        minHeight: 48,
        padding: theme.spacing(0, 3),
    },
    moveToLabel: {
        fontSize: 14,
        '& > span': {
            fontWeight: theme.typography.fontWeightMedium as FontWeightProperty,
        },
    },
    selectLocationLabel: {
        color: theme.palette.common.gray6,
        fontSize: 14,
    },
}));
