import { useCallback, useState } from 'react';
import { useLocalStorage } from 'react-use';

import type { FloorPlanControls, FloorPlanControlSetters, MapScale } from '../types';

export const useFloorPlanControls = ({
    floorId,
    initialState,
    isEditAvailable,
    onRotationChange,
    onZoomChange,
}: {
    floorId: number;
    initialState: FloorPlanControls;
    isEditAvailable: boolean;
    onRotationChange: (value: FloorPlanControls['orientation']) => void;
    onZoomChange: (value: FloorPlanControls['zoom']) => void;
}): FloorPlanControls &
    FloorPlanControlSetters & {
        zoomChange: (value?: FloorPlanControls['zoom']) => void;
        changeRotation: (value?: FloorPlanControls['orientation']) => void;
    } => {
    const [storedScales, setStoredScales] =
        useLocalStorage<Record<number, MapScale>>('map-scales');
    const scale = storedScales?.[floorId] || initialState.scale;
    const setScale = useCallback(
        (newScale) => {
            setStoredScales({ ...storedScales, [floorId]: newScale });
        },
        [floorId, setStoredScales, storedScales],
    );

    const [zoom, setZoom] = useState(initialState.zoom);
    const [orientation, setOrientation] = useState(initialState.orientation);

    const zoomChange = useCallback(
        (lvl) => {
            setZoom(lvl);

            if (isEditAvailable) {
                onZoomChange(lvl);
            }
        },
        [isEditAvailable, onZoomChange, setZoom],
    );

    const changeRotation = useCallback(
        (rotation) => {
            setOrientation(rotation);

            if (isEditAvailable) {
                onRotationChange(rotation);
            }
        },
        [isEditAvailable, onRotationChange, setOrientation],
    );

    return {
        scale,
        setScale,
        zoom,
        setZoom,
        orientation,
        setOrientation,
        zoomChange,
        changeRotation,
    };
};
