import type { FloorDetail } from '@infogrid/locations-types';
import { useIsOpenState } from '@infogrid/utils-hooks';
import { Dialog } from '@material-ui/core';
import { memo, useCallback } from 'react';

import SpaceSidebarContent from 'views/estate/components/SpaceSidebar/SpaceSidebarContent';

interface Props {
    buildingId: number;
    floor: FloorDetail;
    setSelectedSpaceId: (id: number | null) => void;
    spaceId: number;
}

const MobileSpaceSidebar = ({
    buildingId,
    floor,
    setSelectedSpaceId,
    spaceId,
}: Props) => {
    const [isOpen, handleClose] = useIsOpenState(!!spaceId);

    const onClose = useCallback(() => {
        handleClose();
        setSelectedSpaceId(null);
    }, [handleClose, setSelectedSpaceId]);

    return (
        <Dialog fullScreen open={isOpen} onClose={onClose}>
            <SpaceSidebarContent
                buildingId={buildingId}
                floor={floor}
                setSelectedSpaceId={setSelectedSpaceId}
                spaceId={spaceId}
            />
        </Dialog>
    );
};

export default memo(MobileSpaceSidebar);
