import { createRequest } from '@infogrid/core-api';
import type { IPagination } from '@infogrid/core-types';
import type {
    CreateFloorRequestPayload,
    EditFloorRequestPayload,
    Floor,
    FloorDetail,
    FloorPlanImage,
    ReorderFloorParams,
    ReorderFloorResult,
    ResetFloorSensorsResult,
} from '@infogrid/locations-types';
import type { AxiosPromise } from 'axios';

const getFloors = (
    buildingId: number,
    signal: AbortSignal | undefined,
    params?: { [key: string]: unknown },
): AxiosPromise<IPagination<Floor>> =>
    createRequest({
        method: 'get',
        url: `floorplan/buildings/${buildingId}/floors`,
        signal,
        options: {
            params,
        },
    });

const getFloor = (
    floorId: number,
    signal: AbortSignal | undefined,
): AxiosPromise<FloorDetail> =>
    createRequest({ method: 'get', url: `floorplan/floors/${floorId}`, signal });

const reorderFloor = (
    floorId: number,
    data: ReorderFloorParams,
): AxiosPromise<ReorderFloorResult[]> =>
    createRequest({
        method: 'put',
        url: `floorplan/floors/reorder`,
        options: {
            data: {
                floor: floorId,
                new_order_index: data.floorDestinationIndex,
            },
        },
    });

const editFloor = (floorId: number, data: EditFloorRequestPayload): AxiosPromise<Floor> =>
    createRequest({
        method: 'patch',
        url: `floorplan/floors/${floorId}`,
        options: {
            data,
        },
    });

const deleteFloor = (floorId: number): AxiosPromise<Floor[]> =>
    createRequest({ method: 'delete', url: `floorplan/floors/${floorId}` });

const createFloor = (data: CreateFloorRequestPayload): AxiosPromise<FloorDetail> =>
    createRequest({
        method: 'post',
        url: 'floorplan/floors',
        options: {
            data,
        },
    });

const uploadFloorplan = (floorId: number, data: FormData): AxiosPromise<FloorPlanImage> =>
    createRequest({
        method: 'post',
        url: `floorplan/floors/${floorId}/image`,
        options: {
            data,
        },
    });

const resetSmartCleaningSensors = (
    floorId: number,
): AxiosPromise<ResetFloorSensorsResult> =>
    createRequest({ method: 'post', url: `floorplan/floors/${floorId}/reset_all` });

const getSmartCleaningHistory = (
    floorId: number,
    signal: AbortSignal | undefined,
): AxiosPromise<BlobPart> =>
    createRequest({
        method: 'get',
        url: `floorplan/floors/${floorId}/cleaning-history`,
        options: {
            responseType: 'blob',
        },
        signal,
    });

const getLensSensors = (
    floorId?: number,
    statusEndpointKey?: string,
    signal?: AbortSignal,
): AxiosPromise<BlobPart> =>
    createRequest({
        method: 'get',
        url: `locations/floors/${floorId}/lens/${statusEndpointKey}`,
        signal,
    });

const getLensFloors = (
    buildingId: number,
    signal: AbortSignal | undefined,
    params?: { [key: string]: unknown },
): AxiosPromise<IPagination<Floor>> =>
    createRequest({
        method: 'get',
        url: `locations/buildings/${buildingId}/floors`,
        signal,
        options: {
            params,
        },
    });

export const controllers = {
    createFloor,
    deleteFloor,
    editFloor,
    getFloor,
    getFloors,
    reorderFloor,
    resetSmartCleaningSensors,
    uploadFloorplan,
    getSmartCleaningHistory,
    getLensSensors,
    getLensFloors,
};
