import classnames from 'classnames';

const isGoodAvailability = (nearCapacity, outOfCapacity) =>
    !nearCapacity && !outOfCapacity;
const isNearCapacity = (nearCapacity, outOfCapacity) => nearCapacity && !outOfCapacity;
const isOutOfCapacity = (nearCapacity, outOfCapacity) => nearCapacity && outOfCapacity;

export const getAvailabilityClassname = (
    styles,
    nearCapacity,
    outOfCapacity,
    type,
    property,
) =>
    classnames({
        [styles[`${type}GoodAvailability${property}`]]: isGoodAvailability(
            nearCapacity,
            outOfCapacity,
        ),
        [styles[`${type}NearCapacity${property}`]]: isNearCapacity(
            nearCapacity,
            outOfCapacity,
        ),
        [styles[`${type}OutOfCapacity${property}`]]: isOutOfCapacity(
            nearCapacity,
            outOfCapacity,
        ),
    });

export const getLargeIconClassname = (nearCapacity, outOfCapacity) =>
    classnames({
        'fal fa-arrow-circle-up': isGoodAvailability(nearCapacity, outOfCapacity),
        'fal fa-exclamation-triangle': isNearCapacity(nearCapacity, outOfCapacity),
        'fal fa-hand-paper al fa-hand-paper fa-flip-horizontal': isOutOfCapacity(
            nearCapacity,
            outOfCapacity,
        ),
    });
