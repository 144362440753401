import { wrapResponsePromise } from '@infogrid/core-api';
import type { AxiosParsedError } from '@infogrid/core-types';
import type { UseQueryResult, UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import { controllers } from '../controllers';
import { getBuildingsFiltersKey } from '../getQueryKeys';
import type { BuildingsFiltersResponse } from '../types';

export const useBuildingsFilters = (
    options: UseQueryOptions<
        BuildingsFiltersResponse,
        AxiosParsedError,
        BuildingsFiltersResponse
    > = {},
): UseQueryResult<BuildingsFiltersResponse, AxiosParsedError> =>
    useQuery(
        getBuildingsFiltersKey(),
        () =>
            wrapResponsePromise<BuildingsFiltersResponse>(
                controllers.getBuildingsFilters(),
            ),
        {
            ...options,
        },
    );
