import { makeStyles } from '@material-ui/core';

export const useConfigurePipeMonitoringStyles = makeStyles((theme) => ({
    content: {
        '& > *': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            maxWidth: '100%',
            width: '100%',
        },
    },
    actions: {
        justifyContent: 'space-between',
    },
    form: {
        flexGrow: 1,
        display: 'inherit',
        flexDirection: 'inherit',
    },
}));
