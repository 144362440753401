import { useAppDispatch } from '@infogrid/core-ducks';
import { useSelectorWithArgs } from '@infogrid/utils-hooks';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';

import LoadMoreContainer from 'containers/LoadMoreContainer';
import { fetchFolderSubfolders } from 'sagas/folders/fetchFolderSubFolders';
import { selectEntityCollectionIds } from 'schemas/entity';
import { relatedSubfoldersKeyFn } from 'schemas/folder';

// If this ends up causing problems, you can try to:
//  add `folderComponent` prop and pass that from FolderTreeFolder
// eslint-disable-next-line import/no-cycle
import FolderTreeFolder from './FolderTreeFolder';
import TreeNodeButton from './TreeNodeButton';

const FolderTreeFolderSubfolders = ({
    folderId,
    basePath,
    depth,
    nodeClassName,
    sensorTypeFilter,
    labelsFilter,
    readingTypesFilter,
    selectIsExpanded,
    selectIsSelected,
    selectSecondaryLabel,
    scrollParentRef,
    onNodeClick,
    onNodeCollapse,
    onNodeExpand,
    getShouldNodeBeHighlighted,
}) => {
    const dispatch = useAppDispatch();

    const subfoldersKey = relatedSubfoldersKeyFn({
        folderId,
    });
    const subfolderIds = useSelectorWithArgs(selectEntityCollectionIds, subfoldersKey);

    const fetchMoreSubfolders = useCallback(
        (payload, meta) => {
            dispatch(
                fetchFolderSubfolders(
                    {
                        ...payload,
                        kwargs: { folderId, ...payload?.kwargs },
                    },
                    {
                        keyOptions: {
                            folderId,
                            filtered: false,
                        },
                        ...meta,
                    },
                ),
            );
        },
        [dispatch, folderId],
    );
    const loadMoreProps = useMemo(
        () => ({
            depth,
            variant: 'contained',
            size: 'small',
            color: 'primary',
        }),
        [depth],
    );

    return (
        <LoadMoreContainer
            entityKey={subfoldersKey}
            hasContent={Boolean(subfolderIds?.length)}
            onFetchMore={fetchMoreSubfolders}
            doInitialFetch
            refetchOnReset
            emptyState={null}
            hideLoader
            loadMoreComponent={TreeNodeButton}
            loadMoreProps={loadMoreProps}
        >
            {(subfolderIds || []).map((subfolderId) => (
                <FolderTreeFolder
                    key={`folder-${subfolderId}`}
                    folderId={subfolderId}
                    // Pass-through
                    basePath={basePath}
                    depth={depth}
                    nodeClassName={nodeClassName}
                    sensorTypeFilter={sensorTypeFilter}
                    labelsFilter={labelsFilter}
                    readingTypesFilter={readingTypesFilter}
                    selectIsExpanded={selectIsExpanded}
                    selectIsSelected={selectIsSelected}
                    selectSecondaryLabel={selectSecondaryLabel}
                    scrollParentRef={scrollParentRef}
                    onNodeClick={onNodeClick}
                    onNodeCollapse={onNodeCollapse}
                    onNodeExpand={onNodeExpand}
                    getShouldNodeBeHighlighted={getShouldNodeBeHighlighted}
                />
            ))}
        </LoadMoreContainer>
    );
};

FolderTreeFolderSubfolders.propTypes = {
    folderId: PropTypes.number.isRequired,
    basePath: PropTypes.string.isRequired,
    // Depth set for sensors within, do <FolderTreeSensors depth={parentDepth + 1}
    depth: PropTypes.number.isRequired,
    nodeClassName: PropTypes.string,
    sensorTypeFilter: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    labelsFilter: PropTypes.arrayOf(PropTypes.number),
    readingTypesFilter: PropTypes.arrayOf(PropTypes.string),
    selectIsExpanded: PropTypes.func,
    selectIsSelected: PropTypes.func,
    selectSecondaryLabel: PropTypes.func,
    scrollParentRef: PropTypes.shape({ current: PropTypes.any }), // eslint-disable-line react/forbid-prop-types
    onNodeClick: PropTypes.func,
    onNodeCollapse: PropTypes.func,
    onNodeExpand: PropTypes.func,
    getShouldNodeBeHighlighted: PropTypes.func,
};
FolderTreeFolderSubfolders.defaultProps = {
    nodeClassName: undefined,
    sensorTypeFilter: undefined,
    labelsFilter: undefined,
    readingTypesFilter: undefined,
    selectIsExpanded: undefined,
    selectIsSelected: undefined,
    selectSecondaryLabel: undefined,
    scrollParentRef: undefined,
    onNodeClick: undefined,
    onNodeCollapse: undefined,
    onNodeExpand: undefined,
    getShouldNodeBeHighlighted: null,
};

export default FolderTreeFolderSubfolders;
