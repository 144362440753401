import { makeStyles } from '@material-ui/core';

export const useStylesFloorPlanControl = makeStyles((theme) => ({
    mapCustomControlsContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    controlIcon: {
        fontSize: '21px',
        color: theme.palette.text.grey.default,
        lineHeight: '1.1em',
        width: '1.2em',
    },
    deleteItemBtn: {
        fontSize: '14px',
        lineHeight: '20px',
        marginRight: '15px',
    },
    hintsIcon: {
        color: theme.palette.primary.main,
        margin: `0 ${theme.spacing(1.5)}px`,
    },
    mapCustomControlsBtns: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    itemIconSizeSlider: {
        width: '110px',
    },
}));
