import { makeStyles } from '@material-ui/core';

export const useSelectOutputStyles = makeStyles((theme) => ({
    floorSwitcherText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        textTransform: 'capitalize',

        marginRight: theme.spacing(2),
    },
}));
