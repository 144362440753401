import { EditImageModal } from '@infogrid/components-material-ui';
import { useAppSelector, useAppDispatch, toastError } from '@infogrid/core-ducks';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFloorPlanParams } from 'apiHooks/base/hooks';
import { useSelectFloor, useUploadFloorplan } from 'apiHooks/floorPlan/floors/hooks';
import {
    selectEditingFloorImageStatus,
    selectShouldKeepSensorsMapped,
    toggleEditFloorImage,
    toggleShouldKeepSensorsMapped,
} from 'ducks/floorPlan';

import UpdateImageConfirmModal from './UpdateImageConfirmModal/UpdateImageConfirmModal';

const EditFloorImageModalContainer = () => {
    const dispatch = useAppDispatch();

    const { floorId } = useFloorPlanParams();

    const { isOpen, isOpenFromReplaceModal } = useAppSelector(
        selectEditingFloorImageStatus,
    );
    const { t } = useTranslation('common');
    const shouldKeepSensorsMapped = useAppSelector(selectShouldKeepSensorsMapped);

    const { floorPlan } = useSelectFloor({ floorId });

    const [modalsState, setModalsState] = useState({
        isEditModalOpen: isOpen,
        isWarningModalOpen: false,
    });

    useEffect(() => {
        setModalsState({
            isEditModalOpen: isOpen,
            isWarningModalOpen: false,
        });
    }, [isOpen]);

    const [fileBlob, setFileBlob] = useState(null);

    const { uploadFloorPlan, isLoading } = useUploadFloorplan();

    const closeModalHandler = useCallback(() => {
        dispatch(toggleEditFloorImage(false));
    }, [dispatch]);

    const updateImage = useCallback((blob) => {
        setModalsState({
            isEditModalOpen: false,
            isWarningModalOpen: true,
        });

        setFileBlob(blob);
    }, []);

    const uploadNewImage = useCallback(
        (unmapSensors) => {
            const formData = new FormData();

            formData.append('raw_image_file', fileBlob, 'floor_plan.png');
            formData.append('unmap_sensors', unmapSensors);

            uploadFloorPlan(
                { floorId, data: formData },
                {
                    onSuccess: () => {
                        dispatch(toggleEditFloorImage(false));
                    },
                    onError: (error) => {
                        const message = error?.description || t('Something went wrong');

                        dispatch(toastError({ message }));

                        setModalsState({
                            isEditModalOpen: true,
                            isWarningModalOpen: false,
                        });

                        setFileBlob(null);
                    },
                },
            );
        },
        [dispatch, fileBlob, floorId, t, uploadFloorPlan],
    );

    const updateImageWithoutWarning = useCallback(
        (blob) => {
            const formData = new FormData();

            formData.append('raw_image_file', blob, 'floor_plan.png');
            formData.append('unmap_sensors', !shouldKeepSensorsMapped);
            uploadFloorPlan(
                { floorId, data: formData },
                {
                    onSuccess: () => {
                        dispatch(toggleEditFloorImage(false));
                        dispatch(toggleShouldKeepSensorsMapped(false));
                    },
                    onError: (error) => {
                        const message = error?.description || t('Something went wrong');

                        dispatch(toastError({ message }));
                    },
                },
            );
        },
        [dispatch, floorId, shouldKeepSensorsMapped, t, uploadFloorPlan],
    );

    return (
        <>
            {isOpenFromReplaceModal && (
                <EditImageModal
                    isOpen={modalsState.isEditModalOpen}
                    imageUrl={floorPlan.image_file}
                    onClose={closeModalHandler}
                    onSave={updateImageWithoutWarning}
                    isLoading={isLoading}
                />
            )}

            {!isOpenFromReplaceModal && (
                <>
                    <EditImageModal
                        isOpen={modalsState.isEditModalOpen}
                        imageUrl={floorPlan.image_file}
                        onClose={closeModalHandler}
                        onSave={updateImage}
                        isLoading={false}
                    />
                    <UpdateImageConfirmModal
                        isOpened={modalsState.isWarningModalOpen}
                        onSubmit={uploadNewImage}
                        onCancel={closeModalHandler}
                        isUploading={isLoading}
                        onClose={closeModalHandler}
                    />
                </>
            )}
        </>
    );
};

export default EditFloorImageModalContainer;
