import { Modal, TimePicker } from '@infogrid/components-material-ui';
import { useIsMobile } from '@infogrid/utils-hooks';
import { Typography, Button } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';
import { useFormik } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { useSetSensorResetTimes } from 'views/solutionSettings/apiHooks/useSetSensorResetTimes';
import type { FlattenedNodes } from 'views/solutionSettings/types';

import type { ResetTimesFlattenedNode } from '../../types';
import { useSensorResetModalStyles, useModalStyles } from './styles';

interface GetDefaultValueProps {
    knownNodes: FlattenedNodes<ResetTimesFlattenedNode>;
    locations: number[];
}

const getDefaultValue = ({ knownNodes, locations }: GetDefaultValueProps): string => {
    const DEFAULT_TIME = '23:30';
    const selectedNodes = locations.map((k) => knownNodes[k]).filter((n) => n.id !== 1); // filter out top level org

    if (new Set(selectedNodes.map((s) => s.resetTime)).size === 1) {
        if (selectedNodes[0].resetTime === 'mixed') {
            return DEFAULT_TIME;
        }

        return selectedNodes[0].resetTime || DEFAULT_TIME;
    }

    return DEFAULT_TIME;
};

interface Props {
    open: boolean;
    handleClose: () => void;
    locations: number[];
    refetch: () => void;
    knownNodes: FlattenedNodes<ResetTimesFlattenedNode>;
}

export const SensorResetModal = memo(function SensorResetModal({
    open,
    handleClose,
    locations,
    refetch,
    knownNodes,
}: Props): JSX.Element {
    const { mutate: updateSensorResetTimes } = useSetSensorResetTimes();
    const { t } = useTranslation('solutions');
    const styles = useSensorResetModalStyles();
    const modalStyles = useModalStyles();

    const isMobile = useIsMobile();

    const formik = useFormik({
        initialValues: {
            resetTime: getDefaultValue({
                knownNodes,
                locations,
            }),
        },
        validationSchema: Yup.object().shape({
            resetTime: Yup.string().required(),
        }),

        onSubmit: (formValues) => {
            updateSensorResetTimes(
                { reset_time: formValues.resetTime, locations: locations.join(',') },
                {
                    onSuccess: () => {
                        refetch();
                        handleClose();
                    },
                },
            );
        },
    });

    const { values, errors, isValid, handleSubmit } = formik;

    return (
        <Modal
            classes={modalStyles}
            onClose={handleClose}
            open={open}
            fullScreen={isMobile}
            data-testid="insights-card-location-modal"
        >
            <Modal.Title>{t('Set sensor reset time')}</Modal.Title>
            <Modal.Content className={modalStyles.content}>
                <Alert
                    className={styles.alert}
                    icon={<InfoIcon fontSize="inherit" />}
                    color="info"
                >
                    {t(
                        'Note that this will overwrite any existing reset times applied to all selected locations.',
                    )}
                </Alert>
                <Typography
                    className={styles.labelText}
                    color="textSecondary"
                    variant="body2"
                >
                    {t('Reset the sensors every day at')}
                </Typography>
                <TimePicker
                    name={'resetTime'}
                    onChange={formik.handleChange}
                    value={values.resetTime}
                    error={!!errors.resetTime}
                ></TimePicker>
            </Modal.Content>
            <Modal.Actions justify="right">
                <Button variant="text" color="primary" onClick={handleClose}>
                    {t('Cancel')}
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    onClick={() => handleSubmit()}
                >
                    {t('Save')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
});
