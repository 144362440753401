import type {
    ConfigurationProps,
    WeekdayWorkingHoursConfigurationValues,
} from '@infogrid/electricity-components';
import type { SensorConfigurationWithWorkingHours } from '@infogrid/sensors-constants';
import { IconButton, Typography, Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useCallback, useMemo, useState, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTimezones } from 'apiHooks/timezones/ts/hooks';

import WorkingHoursConfigurationModal from './WorkingHoursConfigurationModal';
import { DEFAULT_START_TIME, DEFAULT_END_TIME } from './consts';
import { useWorkingHoursConfigurationStyles } from './styles';
import { getIsDefaultSensorWorkingHoursSettings, getInitialWorkingHours } from './utils';

type WorkingHoursConfigurationProps = {
    sensorConfiguration: SensorConfigurationWithWorkingHours;
} & ConfigurationProps<WeekdayWorkingHoursConfigurationValues>;

const WorkingHoursConfiguration = ({
    handleSubmit,
    sensorConfiguration,
    canEdit,
    validationSchema,
}: WorkingHoursConfigurationProps) => {
    const { t } = useTranslation('sensors');
    const styles = useWorkingHoursConfigurationStyles();
    const [isModalOpened, setIsModalOpened] = useState(false);

    const { data: timezones = [] } = useTimezones();

    const initialWorkingHours = useMemo(() => {
        const workingHours = getInitialWorkingHours(sensorConfiguration.working_hours);

        return {
            sensor_configuration_type: sensorConfiguration?.sensor_configuration_type,
            working_hours: workingHours,
            time_zone: sensorConfiguration.time_zone,
        };
    }, [sensorConfiguration]);

    const timezoneLabel =
        timezones.find((tz) => tz.value === initialWorkingHours.time_zone)?.label ??
        initialWorkingHours.time_zone;

    const isDefaultSettings = getIsDefaultSensorWorkingHoursSettings(
        initialWorkingHours.working_hours,
    );

    const onSubmit = useCallback(
        (values, formik) => {
            handleSubmit(values, formik, {
                onSettled: () => {
                    setIsModalOpened(false);
                },
            });
        },
        [handleSubmit],
    );

    const onClose = useCallback(() => setIsModalOpened(false), []);

    return (
        <Box
            display="flex"
            alignItems="center"
            data-testid="working-hours-configuration"
            data-cypress="working-hours-configuration"
        >
            <Typography variant="body2" color="textSecondary">
                {t('Working Hours:')}
            </Typography>
            <Typography variant="body2" color="textPrimary" className={styles.value}>
                {isDefaultSettings
                    ? t('Weekdays {{startTime}} — {{endTime}} ({{timezone}})', {
                          startTime: DEFAULT_START_TIME,
                          endTime: DEFAULT_END_TIME,
                          timezone: timezoneLabel || '',
                          defaultValue: `Weekdays ${DEFAULT_START_TIME} — ${DEFAULT_END_TIME} (${timezoneLabel})`,
                      })
                    : t('Custom')}
            </Typography>
            {canEdit && (
                <IconButton
                    data-testid="working-hours-edit-button"
                    data-cypress="working-hours-edit-button"
                    size="small"
                    disabled={!canEdit}
                    onClick={() => setIsModalOpened(true)}
                >
                    <EditIcon fontSize="small" />
                </IconButton>
            )}
            {canEdit && (
                <WorkingHoursConfigurationModal
                    validationSchema={validationSchema}
                    initialValues={initialWorkingHours}
                    isOpened={isModalOpened}
                    onSubmit={onSubmit}
                    onClose={onClose}
                />
            )}
        </Box>
    );
};

export default memo(WorkingHoursConfiguration);
