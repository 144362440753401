import { Modal } from '@infogrid/components-material-ui';
import { Button, Icon, Typography, Link } from '@material-ui/core';
import classnames from 'classnames';
import noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';

import { SENSOR_INSTALLATION_GUIDE_URL } from 'constants/urls';

import { useInstallationFlowStyles } from '../styles';
import { useInstallationSuccessView } from './styles';

interface Props {
    onComplete: () => void;
    resetProgress: () => void;
}

const FeedbackPanelInstallationSuccessView = ({
    onComplete = noop,
    resetProgress = noop,
}: Props): JSX.Element => {
    const { t } = useTranslation('sensors');
    const installationFlowStyles = useInstallationFlowStyles();
    const classes = useInstallationSuccessView();

    return (
        <>
            <Modal.Title>{t('Success! The panel is setup')}</Modal.Title>
            <Modal.Content>
                <Typography className={installationFlowStyles.paragraph}>
                    {t('Now physically install the panel!')}
                </Typography>
                <Typography
                    data-cypress="installation-instructions"
                    className={installationFlowStyles.paragraph}
                >
                    {t('As a reminder, you can find')}
                    <Link
                        href={SENSOR_INSTALLATION_GUIDE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={installationFlowStyles.inlineHelpLink}
                    >
                        {t('installation instructions for all devices here.')}
                    </Link>
                </Typography>
            </Modal.Content>

            <Modal.Actions
                justify="right"
                className={classnames(installationFlowStyles.actions, classes.actions)}
            >
                <div className={classes.nextButtonsContainer}>
                    <Button
                        onClick={resetProgress}
                        variant="outlined"
                        color="primary"
                        data-cypress="add-device"
                        startIcon={<Icon className="fal fa-plus" />}
                    >
                        {t('Setup another panel')}
                    </Button>
                    <Button
                        onClick={onComplete}
                        variant="contained"
                        color="primary"
                        data-cypress="finish"
                        className={classes.finishButton}
                        autoFocus
                    >
                        {t('Finish')}
                    </Button>
                </div>
            </Modal.Actions>
        </>
    );
};

export default FeedbackPanelInstallationSuccessView;
