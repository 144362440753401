import { routesManager } from '@infogrid/core-routing';
import type { Floor } from '@infogrid/locations-types';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useFloorPlanParams } from 'apiHooks/base/hooks';

export const useNavigateFloor = (): { navigateToFloor: (floor: Floor) => void } => {
    const { buildingId } = useFloorPlanParams();
    const history = useHistory();

    const navigateToFloor = useCallback(
        ({ id }) => {
            history.push(
                routesManager.resolvePath('buildings:floor', {
                    // @ts-expect-error: buildingId can't be null
                    buildingId,
                    floorId: id,
                }),
            );
        },
        [buildingId, history],
    );

    return {
        navigateToFloor,
    };
};
