import { makeStyles } from '@material-ui/core';

export const useActionMenuStyles = makeStyles((theme) => ({
    menuContainer: {
        position: 'relative',

        display: 'flex',
        alignItems: 'center',
    },
    dropdown: {
        boxShadow: theme.shadows[6],
        borderRadius: '4px',
        background: theme.palette.background.default,
        overflow: 'hidden',

        minWidth: '160px',
    },
    menuContent: {
        position: 'absolute',
        top: '30px',
        left: '0px',
        zIndex: 2,
    },
    menuButton: {
        padding: '4px',
    },
    menuButtonIcon: {
        position: 'relative',
        top: '1px',
        height: '100%',
        margin: 0,
        color: '#918D85',

        fontSize: '20px',
    },
}));
