import { Box, TextField, Typography } from '@material-ui/core';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { InstallationFlowComponentProps } from 'components/InstallationFlow/types';
import { InstallationFlowAirthingsSerial } from 'utils/Images';

import { useInstallationFlowStyles } from '../../styles';
import DeviceIdentifier from '../DeviceIdentifier';
import { useDeviceIdentifierStyles } from './styles';

type Props = Pick<InstallationFlowComponentProps, 'errorMessage'>;

const AirthingsIdentifier = ({ errorMessage }: Props) => {
    const { t } = useTranslation('sensors');
    const installationFlowStyles = useInstallationFlowStyles();
    const deviceIdentifierStyles = useDeviceIdentifierStyles();

    return (
        <DeviceIdentifier
            errorMessage={errorMessage}
            requiresDeviceId
            renderFormContent={({
                onChangeSerialNumber,
                onChangeDeviceId,
                errorText,
            }) => (
                <Box>
                    <Typography
                        variant="body1"
                        className={installationFlowStyles.paragraph}
                        data-cypress="instruction-text"
                    >
                        {t(
                            'Enter the Serial Number which can be found under the mounting on the reverse of both the Hub and Wave Plus devices.',
                        )}
                    </Typography>
                    <Box className={deviceIdentifierStyles.helpImageContainer}>
                        <img
                            src={InstallationFlowAirthingsSerial}
                            alt="Pointer to Airthings device ID"
                            className={installationFlowStyles.centeredImage}
                        />
                    </Box>
                    <TextField
                        fullWidth
                        label={t('Serial number')}
                        variant="outlined"
                        name="serialNumber"
                        onInput={onChangeSerialNumber}
                        error={!!errorText}
                        helperText={errorText}
                        className={deviceIdentifierStyles.serialNumberInput}
                        inputProps={{ 'aria-label': 'Serial number' }} // Required for unit tests
                        data-cypress="serial-number"
                    />
                    <TextField
                        fullWidth
                        label={t('Device ID')}
                        variant="outlined"
                        name="deviceId"
                        onInput={onChangeDeviceId}
                        error={!!errorText}
                        helperText={errorText}
                        className={deviceIdentifierStyles.deviceIdInput}
                        inputProps={{ 'aria-label': 'Device ID' }} // Required for unit tests
                        data-cypress="device-id"
                    />
                </Box>
            )}
        />
    );
};

export default memo(AirthingsIdentifier);
